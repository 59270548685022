import { useState, useEffect } from "react";
import IGoogleCampaign from "../interfaces/IGoogleCampaign";
import IDynamicCampaignFormValue from "../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import IEntity from "../interfaces/IEntity";
import IMicrosoftCampaign from "../interfaces/IMicrosoftCampaign";

type UseCampaignReturn = [
    IGoogleCampaign | IMicrosoftCampaign,
    React.Dispatch<React.SetStateAction<IGoogleCampaign | IMicrosoftCampaign>>
];

function useCampaign(
    dynamicCampaign: IDynamicCampaignFormValue,
    campaigns: IEntity<IGoogleCampaign | IMicrosoftCampaign>,
    key: string
): UseCampaignReturn {
    const [campaign, setCampaign] = useState<IGoogleCampaign | IMicrosoftCampaign>(
        {} as IGoogleCampaign | IMicrosoftCampaign
    );

    useEffect(() => {
        const currentCampaign =
            campaigns && dynamicCampaign[key]
                ? campaigns[dynamicCampaign[key]]
                : ({} as IGoogleCampaign | IMicrosoftCampaign);
        setCampaign(currentCampaign);
    }, [campaigns]);

    return [campaign, setCampaign];
}

export default useCampaign;
