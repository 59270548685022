import React, { ReactElement } from "react";
import logo from "../../assets/fuel-logo.svg";
interface IProps {
    children: ReactElement<any>;
}

const RequestResetPassword: React.FunctionComponent<IProps> = ({ children }) => {
    return (
        <div className="flex flex-wrap h-screen content-center justify-center">
            <div className="relative bg-white flex flex-col md:w-3/5 lg:w-1/3 xl:w-1/4 w-full shadow-md rounded p-4 pb-4 mb-4 self-center">
                <div className="w-full flex justify-center mt-4">
                    <img src={logo} alt="FUEL - Dealer Inspire" style={{ width: "250px" }} />
                </div>

                <div className="m-4">{children}</div>
            </div>
        </div>
    );
};

export default RequestResetPassword;
