const NotificationsMap: {
    [key: string]: string;
} = {
    "All Notifications": "",
    "Dynamic Campaigns": "dynamicCampaigns",
    Inventory: "Inventory",
    "0 Spends": "ClientCampaignZeroSpendNotification",
    "Negative Keywords": "NegativeKeyword",
    "Placement Exclusions": "PlacementExclusion"
};

export default NotificationsMap;
