import { INotification } from "../interfaces/Notifications/INotification";
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const REQUEST_LOAD_NOTIFICATIONS = "REQUEST_LOAD_NOTIFICATIONS";
export const FAILED_LOAD_NOTIFICATIONS = "FAILED_LOAD_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const REMOVE_ALL_NOTIFICATIONS = "REMOVE_ALL_NOTIFICATIONS ";
export const MARK_AS_READ = "MARK_AS_READ ";
export const MARK_ALL_AS_READ = "MARK_ALL_AS_READ ";
export const FAILED_MARK_ALL_AS_READ = "FAILED_MARK_ALL_AS_READ";
export const FAILED_MARK_AS_READ = "FAILED_MARK_AS_READ";
export const DONE_LOADING_PAGES = "DONE_LOADING_PAGES";
export const NOTIFICATION_FILTER_CHANGED = "NOTIFICATION_FILTER_CHANGED";

export type FetchNotificationActionType = {
    url: string;
    page?: number;
    limit?: number;
    filter?: string;
};

export type NotificationFilterChangedActionType = {
    type: string;
    filterValue: string;
};

export const fetchNotifications = (url: string, page?: number, limit?: number, filter?: string) => ({
    type: REQUEST_LOAD_NOTIFICATIONS,
    url,
    page,
    limit,
    filter
});

export const filterChanged = (filterValue: string): NotificationFilterChangedActionType => ({
    type: NOTIFICATION_FILTER_CHANGED,
    filterValue
});

export const markAllAsRead = () => ({
    type: MARK_ALL_AS_READ
});

export const markAsRead = (notification: INotification) => ({
    type: MARK_AS_READ,
    notification
});

export const updateNotification = (notification: INotification) => ({
    type: UPDATE_NOTIFICATION,
    notification
});

export const removeNotification = (notification: INotification) => ({
    type: REMOVE_NOTIFICATION,
    id: notification.id
});

export const removeAllNotifications = () => ({
    type: REMOVE_ALL_NOTIFICATIONS
});

export const undoMarkAllAsRead = () => ({
    type: FAILED_MARK_ALL_AS_READ
});

export const undoMarkAsRead = (notification: INotification) => ({
    type: FAILED_MARK_AS_READ,
    notification
});
