import { call, put, takeEvery } from "redux-saga/effects";
import { redirect } from "react-router-dom";
import { decamelizeKeys } from "humps";
import { FormikActions } from "formik";
import { callApi, doDeleteRequest } from "../../middleware/api";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";
import { addFlashMessage } from "../../actions/flashMessageActions";
import * as facebookAutoActions from "../../actions/remarketing/facebookAutoExportActions";
import * as actions from "../../actions/remarketing/inventoryExportsActions";
import * as Constants from "../../constants/InventoryExportsConstants";
import { IRemarketingCondition, IFacebookAutoExport } from "../../interfaces/IRemarketing";
import { IInventoryExport } from "../../interfaces/IRemarketing";
import { IGoogleAutoExportFormValues } from "../../components/Client/Remarketing/GoogleExport";
import IFacebookAutoExportType from "../../interfaces/IFacebookAutoExport";
import schemas from "../../middleware/schemas/remarketing";
import IEntity from "../../interfaces/IEntity";
import IFacebookLog from "../../interfaces/IFacebookLog";
import { IFetchEntitiesResponse } from "../../interfaces/IFetchEntitiesResponse";
import IPagedResponse from "../../interfaces/IPagedResponse";

const url = (clientId: number) => `/clients/${clientId}/remarketing-inventory-exports`;
const facebookExportUrl = (clientId: number) => `/clients/${clientId}/fb-auto-remarketing-exports`;

export interface IInventoryExportState {
    id?: number;
    name: string;
    inventorySource: string;
    mileageUnit: string;
    currency: string;
    conditions: IRemarketingCondition[];
    ignoredImages: string[];
    fuelInventoryId: string | null;
    fuelInventory?: {
        id: number;
        slug: string;
    };
    saving?: boolean;
    errors?: any;
}

interface IInventoryExportApi {
    inventory_source: string;
    name: string;
    mileage_unit: string;
    currency: string;
    conditions: IRemarketingCondition[] | null;
    ignored_images: string[] | null;
    fuel_inventory_id: string | null;
}

const parseStateToRequest = (inventoryExport: IInventoryExportState): IInventoryExportApi => {
    return {
        inventory_source: inventoryExport.inventorySource,
        name: inventoryExport.name,
        mileage_unit: inventoryExport.mileageUnit,
        currency: inventoryExport.currency,
        conditions: inventoryExport.conditions ? inventoryExport.conditions : null,
        ignored_images: inventoryExport.ignoredImages.length === 0 ? null : inventoryExport.ignoredImages,
        fuel_inventory_id: null
    };
};

export function* fetchAll({ clientId }: any) {
    try {
        const response: Generator = yield call(
            callApi,
            `${url(
                clientId
            )}?expand[facebookAutoRemarketingExportMappings]=*&expand[adwordsRemarketingExportMappings]=*&expand[fuelInventory]=*`,
            CLIENT_SCHEMAS.REMARKETING.REMARKETING_INVENTORY_EXPORT_ARRAY
        );
        yield put({ type: Constants.LOAD_INVENTORY_EXPORTS, response });
    } catch (e) {
        //todo: fix this by logging error and displaying refresh
        console.log(e);
    }
}

interface ICreateFacebookAutoExportAction {
    type: string;
    payload: {
        clientId: number;
        inventoryExportId: number;
        facebookExport: IFacebookAutoExport;
        formikActions: FormikActions<IGoogleAutoExportFormValues>;
    };
}

interface IFacebookAutoExportResponse {
    entities: {
        facebookAutoExports: { [key: number]: IFacebookAutoExport };
    };
    result: number;
}

function* createFacebookAutoExport({ payload }: ICreateFacebookAutoExportAction) {
    const { clientId, inventoryExportId, facebookExport, formikActions } = payload;
    facebookExport.remarketingInventoryExportId = inventoryExportId;
    try {
        const response: IFacebookAutoExportResponse = yield call(
            callApi,
            facebookExportUrl(clientId),
            CLIENT_SCHEMAS.REMARKETING.FACEBOOK_AUTO_EXPORT,
            "POST",
            decamelizeKeys(facebookExport)
        );
        yield put(actions.createFacebookExportSuccess(response.entities.facebookAutoExports[response.result]));
        yield put(addFlashMessage({ type: "success", text: "Successfully created." }));
        formikActions.setSubmitting(false);
        formikActions.setStatus("saved");
    } catch (e) {
        yield put(actions.createFacebookExportFail());
        yield put(addFlashMessage({ type: "danger", text: e.message || "Something went wrong." }));
        formikActions.setSubmitting(false);
        formikActions.setErrors({ apiErrors: e.message } as any);
    }
}

interface IUpdateFacebookExportAction {
    type: string;
    payload: {
        clientId: number;
        facebookExport: IFacebookAutoExportType;
        inventoryExportId: number;
        formikActions: FormikActions<IGoogleAutoExportFormValues>;
    };
}

function* updateFacebookAutoExport({ payload }: IUpdateFacebookExportAction): Generator<any, any, any> {
    const { clientId, facebookExport, formikActions } = payload;
    delete facebookExport.fileLocation;
    delete facebookExport.remarketingInventoryExport;
    delete facebookExport.saving;
    try {
        const response = yield call(
            callApi,
            `${facebookExportUrl(clientId)}/${facebookExport.id}`,
            CLIENT_SCHEMAS.REMARKETING.FACEBOOK_AUTO_EXPORT,
            "PUT",
            decamelizeKeys(facebookExport)
        );
        yield put(actions.updateFacebookExportSuccess(response.entities.facebookAutoExports[response.result]));
        yield put(addFlashMessage({ type: "success", text: "Successfully updated." }));
        formikActions.setSubmitting(false);
        formikActions.setStatus("saved");
    } catch (e) {
        yield put(actions.updateFacebookExportFail());
        yield put(addFlashMessage({ type: "danger", text: e.message || "Something went wrong." }));
        formikActions.setSubmitting(false);
        formikActions.setErrors({ apiErrors: e.message } as any);
    }
}
interface IDeleteFacebookExportAction {
    type: string;
    payload: {
        clientId: number;
        facebookExportId: number;
    };
}

function* deleteFacebookAutoExport({ payload }: IDeleteFacebookExportAction) {
    const { clientId, facebookExportId } = payload;
    try {
        const response: Generator = yield call(
            callApi,
            `${facebookExportUrl(clientId)}/${facebookExportId}`,
            CLIENT_SCHEMAS.REMARKETING.FACEBOOK_AUTO_EXPORT,
            "DELETE"
        );
        yield put(actions.deleteFacebookExportSuccess(facebookExportId));
        yield put(addFlashMessage({ type: "success", text: "Successfully deleted." }));
    } catch (e) {
        yield put(actions.deleteFacebookExportFail());
        yield put(addFlashMessage({ type: "danger", text: e.message || "Something went wrong." }));
    }
}

function* getUpdatedInventoryExport(updatedEntity: IInventoryExport, clientId: number, inventoryId: number) {
    if (!updatedEntity.fuelInventoryId && !updatedEntity.fuelInventory) {
        return updatedEntity;
    }
    try {
        // re-query single entity to ensure fuelInventory data exists
        const response: IInventoryExportResponse = yield call(
            callApi,
            `${url(
                clientId
            )}/${inventoryId}/?expand[facebookAutoRemarketingExportMappings]=*&expand[adwordsRemarketingExportMappings]=*&expand[fuelInventory]=*`,
            CLIENT_SCHEMAS.REMARKETING.REMARKETING_INVENTORY_EXPORT
        );
        const entity = response.entities.remarketingInventoryExports[response.result];
        return entity;
    } catch (error) {
        return updatedEntity;
    }
}

export function* create({ inventoryExport, clientId, setErrors }: any) {
    const body = parseStateToRequest(inventoryExport);
    try {
        const response: IPagedResponse = yield call(
            callApi,
            url(clientId),
            CLIENT_SCHEMAS.REMARKETING.REMARKETING_INVENTORY_EXPORT,
            "POST",
            body
        );

        // fuelInventory will not be expanded so we need to re-query it
        const updatedEntity: IInventoryExport = yield getUpdatedInventoryExport(
            response.entities.remarketingInventoryExports[response.result as unknown as number],
            clientId,
            response.result as unknown as number
        );
        yield put(actions.createInventoryExportSuccess(updatedEntity));
        yield put(addFlashMessage({ type: "success", text: `Created` }));
        yield redirect(`/client/${clientId}/remarketing/${response.result}/edit`);
    } catch (e) {
        yield call(setErrors, e.errors);
    }
}

export function* deleteExport({ clientId, exportId }: any) {
    try {
        yield call(doDeleteRequest, `${url(clientId)}/${exportId}`);
        yield put(actions.removeAction(exportId));
    } catch (e) {
        console.log(e);
    }
}

interface IInventoryExportResponse {
    entities: { remarketingInventoryExports: { [key: number]: IInventoryExport } };
    result: number;
}

interface IInventoryExportResponse {
    entities: { remarketingInventoryExports: { [key: number]: IInventoryExport } };
    result: number;
}

interface IGetFuelInventoryFieldsAction {
    type: string;
    payload: {
        inventoryExportId: number;
        slug: string;
    };
}

export function* update({ inventoryExport, clientId, setErrors }: any) {
    const body = parseStateToRequest(inventoryExport);

    try {
        const response: IInventoryExportResponse = yield call(
            callApi,
            `${url(clientId)}/${inventoryExport.id}`,
            CLIENT_SCHEMAS.REMARKETING.REMARKETING_INVENTORY_EXPORT,
            "PUT",
            body
        );

        // fuelInventory will not be expanded so we need to re-query it
        const updatedEntity: IInventoryExport = yield getUpdatedInventoryExport(
            response.entities.remarketingInventoryExports[response.result],
            clientId,
            response.result
        );

        yield put(actions.updateInventoryExportSuccess(updatedEntity));
        yield put(addFlashMessage({ type: "success", text: `Saved` }));
    } catch (e) {
        yield call(setErrors, e.errors);
    }
}

interface IGoogleExportAction {
    type: string;
    payload: {
        clientId: number;
        inventoryExportId: number;
        googleExport: IGoogleAutoExportFormValues;
        formikActions: FormikActions<IGoogleAutoExportFormValues>;
    };
}

interface IGoogleAutoExportRequestValues {
    id?: number;
    id_template: string;
    id2_template: string;
    item_title_template: string;
    itemDescription_template: string;
    contextual_keywords_template: string;
    price_template: string;
    sale_price_template: string;
    image_url_template: string;
    final_url_template: string;
    remarketing_inventory_export_id: number;
}

const mapFormToValuesRequestValues = (
    googleExport: IGoogleAutoExportFormValues,
    inventoryExportId: number
): IGoogleAutoExportRequestValues => {
    return decamelizeKeys({
        ...googleExport,
        remarketingInventoryExportId: inventoryExportId
    }) as IGoogleAutoExportRequestValues;
};

function* createGoogleExport({ payload }: IGoogleExportAction) {
    const { clientId, inventoryExportId, googleExport, formikActions } = payload;
    const url = `/clients/${clientId}/ad-words-remarketing-exports`;
    try {
        const response: IGoogleExportSuccessResponse = yield call(
            callApi,
            url,
            CLIENT_SCHEMAS.REMARKETING.GOOGLE_AUTO_EXPORT,
            "POST",
            mapFormToValuesRequestValues(googleExport, inventoryExportId)
        );
        yield put(actions.createGoogleExportSuccess(response.entities.googleAutoExports[response.result]));
        yield put(addFlashMessage({ type: "success", text: "Successfully created." }));
        formikActions.setSubmitting(false);
        formikActions.setStatus("saved");
    } catch (e) {
        yield put(actions.createFacebookExportFail());
        yield put(addFlashMessage({ type: "danger", text: e.message || "Something went wrong." }));
        formikActions.setSubmitting(false);
        formikActions.setErrors({ apiErrors: e.message } as any);
    }
}
interface IGoogleExportSuccessResponse {
    entities: {
        googleAutoExports: { [key: number]: IGoogleAutoExportFormValues };
    };
    result: number;
}

function* updateGoogleExport({ payload }: IGoogleExportAction) {
    const { clientId, inventoryExportId, googleExport, formikActions } = payload;
    const url = `/clients/${clientId}/ad-words-remarketing-exports/${googleExport.id}`;
    try {
        const response: IGoogleExportSuccessResponse = yield call(
            callApi,
            url,
            CLIENT_SCHEMAS.REMARKETING.GOOGLE_AUTO_EXPORT,
            "PUT",
            mapFormToValuesRequestValues(googleExport, inventoryExportId)
        );
        yield put(actions.updateGoogleExportSuccess(response.entities.googleAutoExports[response.result]));
        yield put(addFlashMessage({ type: "success", text: "Successfully Updated." }));
        formikActions.setSubmitting(false);
        formikActions.setStatus("saved");
    } catch (e) {
        yield put(actions.updateFacebookExportFail());
        yield put(addFlashMessage({ type: "danger", text: e.message || "Something went wrong." }));
        formikActions.setSubmitting(false);
        formikActions.setErrors({ apiErrors: e.message } as any);
    }
}

interface IDeleteGoogleExportAction {
    type: string;
    payload: { clientId: number; googleExportId: number };
}

function* deleteGoogleAutoExport({ payload }: IDeleteGoogleExportAction) {
    const { clientId, googleExportId } = payload;
    try {
        yield call(callApi, `/clients/${clientId}/ad-words-remarketing-exports/${googleExportId}`, {}, "DELETE");
        yield put(actions.deleteGoogleExportSuccess(googleExportId));
        yield put(addFlashMessage({ type: "success", text: "Successfully deleted." }));
    } catch (e) {
        yield put(actions.deleteGoogleExportFail());
        yield put(addFlashMessage({ type: "danger", text: e.message || "Something went wrong." }));
    }
}

interface IFetchGoogleLogs {
    type: string;
    payload: {
        clientId: number;
        exportId: number;
    };
}

interface IFetchGoogleLogsResponse {
    entities: { googleAutoExportLogs: IEntity<IFacebookLog> };
    result: { data: number[] };
}

function* fetchGoogleLogs({ payload: { clientId, exportId } }: IFetchGoogleLogs) {
    try {
        const { entities, result }: IFetchGoogleLogsResponse = yield call(
            callApi,
            Constants.GOOGLE_LOGS_URL(clientId, exportId),
            schemas.GOOGLE_AUTO_EXPORT_LOG_ARRAY
        );
        yield put(
            actions.fetchGoogleLogsSuccess(
                "googleAutoExportLogs" in entities ? entities.googleAutoExportLogs : {},
                result.data
            )
        );
    } catch (error) {
        yield put(actions.fetchGoogleLogsFailure({ error }));
    }
}
interface IFetchFacebookLogs {
    type: string;
    payload: {
        clientId: number;
        exportId: number;
    };
}

interface IFetchFacebookLogsResponse {
    entities: { facebookAutoExportLogs: IEntity<IFacebookLog> } | {};
    result: { data: number[] | [] };
}

function* fetchFacebookLogs({ payload: { clientId, exportId } }: IFetchFacebookLogs) {
    try {
        const { entities, result }: IFetchFacebookLogsResponse = yield call(
            callApi,
            Constants.FACEBOOK_LOGS_URL(clientId, exportId),
            schemas.FACEBOOK_AUTO_EXPORT_LOG_ARRAY
        );
        yield put(
            facebookAutoActions.fetchFacebookLogsSuccess(
                "facebookAutoExportLogs" in entities ? entities.facebookAutoExportLogs : {},
                result.data
            )
        );
    } catch (error) {
        yield put(facebookAutoActions.fetchFacebookLogsFailure({ error }));
    }
}

function* sagas() {
    yield takeEvery(Constants.REQUEST_CREATE_INVENTORY_EXPORT, create);
    yield takeEvery(Constants.REQUEST_LOAD_INVENTORY_EXPORTS, fetchAll);
    yield takeEvery(Constants.REQUEST_UPDATE_INVENTORY_EXPORT, update);
    yield takeEvery(Constants.REQUEST_REMOVE_INVENTORY_EXPORT, deleteExport);
    yield takeEvery(Constants.REQUEST_CREATE_FACEBOOK_EXPORT, createFacebookAutoExport);
    yield takeEvery(Constants.REQUEST_UPDATE_FACEBOOK_EXPORT, updateFacebookAutoExport);
    yield takeEvery(Constants.REQUEST_DELETE_FACEBOOK_EXPORT, deleteFacebookAutoExport);
    yield takeEvery(Constants.REQUEST_CREATE_GOOGLE_EXPORT, createGoogleExport);
    yield takeEvery(Constants.REQUEST_UPDATE_GOOGLE_EXPORT, updateGoogleExport);
    yield takeEvery(Constants.REQUEST_DELETE_GOOGLE_EXPORT, deleteGoogleAutoExport);
    yield takeEvery(Constants.FETCH_FACEBOOK_LOGS, fetchFacebookLogs);
    yield takeEvery(Constants.FETCH_GOOGLE_LOGS, fetchGoogleLogs);
}

export default sagas;
