import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { array, number, object, string } from "yup";
import { upperCase } from "lodash";
import IRemarketing from "../../../../interfaces/DealerSetup/IRemarketingFields";
import FieldErrors from "../Fields/FieldErrors";
import StateList from "../../../../utils/StateList";
import TrashIcon from "../../../Shared/Icons/TrashIcon";
import IClient from "../../../../interfaces/IClient";

interface IProps {
    path: string;
    formValues: any;
    client: IClient;
    savePage(path: string, validatePage: () => void): void;
}

const Remarketing = forwardRef(({ formValues, client, savePage, path }: IProps, ref: React.Ref<{}>) => {
    const defaults: IRemarketing = {
        fields: [
            {
                name: {
                    value: "Remarketing"
                },
                address: {
                    value: client.dealerAddress1 || ""
                },
                city: {
                    value: client.dealerCity || ""
                },
                state: {
                    value: client.dealerState || "AL"
                },
                zip: {
                    value: client.dealerZip || ""
                },
                country: {
                    value: client.dealerCountryCode || "US"
                },
                radius: {
                    value: 90
                },
                distanceUnit: {
                    value: client.dealerCountryCode === "CA" ? "kilometers" : "miles"
                },
                negative_keyword_lists: {
                    value: []
                }
            }
        ]
    };

    const schema = object({
        name: string().required("The name field is required.").min(3),
        address: string().required("The address field is required.").min(1),
        city: string().required("The city field is required.").min(1),
        state: string().required("The state field is required.").min(1),
        zip: string().required("The zip field is required.").min(1),
        country: string().required("The country field is required.").min(1),
        radius: number().required("The radius field is required.").positive().integer(),
        distanceUnit: string().required("The unit field is required.").min(1),
        negative_keyword_lists: array().of(number())
    });

    const [values, handleFieldChange, changePage] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldChange(event.target.name, event.target.value);
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        handleFieldChange(event.target.name, event.target.value);
        handleFieldChange(
            `${event.target.name.split(".")[0]}.state`,
            upperCase(StateList[event.target.value].states[0].abbreviation)
        );
        handleFieldChange(
            `${event.target.name.split(".")[0]}.distanceUnit`,
            event.target.value === "US" ? "miles" : "kilometers"
        );
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        handleFieldChange(event.target.name, upperCase(event.target.value));
    };

    const handleAddCampaign = () => {
        handleFieldChange(`[${values.fields.length}]`, defaults.fields[0], true);
    };

    const handleDeleteCampaign = (index: number) => {
        values.fields.splice(index, 1);
        handleFieldChange(``, values.fields, true);
    };

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Remarketing Campaign Setup</div>

            {values.fields.map((fieldGroup: any, index: number) => (
                <div className={`w-full mt-4 ${index ? "border-t pt-4" : ""} pb-4`} key={index}>
                    <div className={`w-full relative`}>
                        {values.fields.length > 1 ? (
                            <button
                                className="float-right text-gray-600 hover:text-gray-800"
                                onClick={() => handleDeleteCampaign(index)}
                            >
                                <TrashIcon className="w-8 h-8 text-xl" />
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className={`w-full`}>
                        <div className="w-2/3 py-3 font-bold text-base">Campaign Name*</div>
                        <div className="w-full py-3 flex">
                            <input
                                value={fieldGroup.name.value || ""}
                                type="text"
                                name={`[${index}].name`}
                                onChange={handleInputChange}
                                className={`text-base border flex-1 py-2 px-4 rounded`}
                            />
                        </div>
                        <FieldErrors errors={fieldGroup.name.errors} />
                    </div>

                    <div className={`w-full flex justify-between`}>
                        <div className={`flex-auto mr-3`}>
                            <div className="w-2/3 py-3 font-bold text-base">Address*</div>
                            <div className="w-full py-3 flex">
                                <input
                                    value={fieldGroup.address.value || ""}
                                    type="text"
                                    name={`[${index}].address`}
                                    onChange={handleInputChange}
                                    className={`text-base border flex-1 py-2 px-4 rounded`}
                                />
                            </div>
                            <FieldErrors errors={fieldGroup.address.errors} />
                        </div>
                        <div className={`flex-auto ml-3`}>
                            <div className="w-2/3 py-3 font-bold text-base">City*</div>
                            <div className="w-full py-3 flex">
                                <input
                                    value={fieldGroup.city.value || ""}
                                    type="text"
                                    name={`[${index}].city`}
                                    onChange={handleInputChange}
                                    className={`text-base border flex-1 py-2 px-4 rounded`}
                                />
                            </div>
                            <FieldErrors errors={fieldGroup.city.errors} />
                        </div>
                    </div>

                    <div className={`w-full flex justify-between`}>
                        <div className={`flex-auto w-1/3 mr-3`}>
                            <div className="w-2/3 py-3 font-bold text-base">Zip*</div>
                            <div className="w-full py-3 flex">
                                <input
                                    value={fieldGroup.zip.value || ""}
                                    type="text"
                                    name={`[${index}].zip`}
                                    onChange={handleInputChange}
                                    className={`text-base border flex-1 py-2 px-4 rounded`}
                                />
                            </div>
                            <FieldErrors errors={fieldGroup.zip.errors} />
                        </div>

                        <div className={`flex-auto w-1/3 mx-3`}>
                            <div className="w-2/3 py-3 font-bold text-base">State*</div>
                            <div className="w-full py-3 flex">
                                <div className="relative w-full">
                                    <select
                                        className={`text-base border w-full py-1 bg-white rounded cursor-pointer px-4 leading-loose appearance-none`}
                                        name={`[${index}].state`}
                                        onChange={handleStateChange}
                                        value={fieldGroup.state.value}
                                    >
                                        {StateList[fieldGroup.country.value].states.map((state) => (
                                            <option value={state.abbreviation} key={state.abbreviation}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8 pl-4 py-2 my-3 border-l text-gray">
                                        <svg
                                            className="fill-current h-8 w-8"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <FieldErrors errors={fieldGroup.state.errors} />
                        </div>

                        <div className={`flex-auto w-1/3 ml-3`}>
                            <div className="w-2/3 py-3 font-bold text-base">Country*</div>
                            <div className="w-full py-3 flex">
                                <div className="relative w-full">
                                    <select
                                        className={`text-base border w-full py-1 bg-white rounded cursor-pointer px-4 leading-loose appearance-none`}
                                        name={`[${index}].country`}
                                        onChange={handleCountryChange}
                                        value={fieldGroup.country.value}
                                    >
                                        {Object.values(StateList).map((country) => (
                                            <option value={country.abbreviation} key={country.abbreviation}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8 pl-4 py-2 my-3 border-l text-gray">
                                        <svg
                                            className="fill-current h-8 w-8"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <FieldErrors errors={fieldGroup.country.errors} />
                        </div>
                    </div>

                    <div className={`w-1/3`}>
                        <div className="w-full py-3 font-bold text-base">Geotargeting Radius*</div>
                        <div className="w-1/2 py-3 flex">
                            <input
                                value={fieldGroup.radius.value || ""}
                                type="text"
                                name={`[${index}].radius`}
                                onChange={handleInputChange}
                                className={`text-base border flex-1 py-2 pl-4 rounded rounded-r-none`}
                            />
                            <div
                                className={
                                    "flex-none text-base bg-gray-400 py-2 text-gray-600 rounded rounded-l-none px-6"
                                }
                            >
                                {fieldGroup.distanceUnit.value}
                            </div>
                        </div>
                        <FieldErrors errors={fieldGroup.radius.errors} />
                    </div>
                </div>
            ))}

            <button className="text-base font-bold text-blue-500 my-4 pb-3" onClick={handleAddCampaign}>
                + Add Campaign
            </button>

            {pager()}
        </div>
    );
});

export default Remarketing;
