import React, { FunctionComponent } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
interface ITabProps {
    show?: boolean;
    path: string;
    label: string;
}

const Tab: FunctionComponent<ITabProps> = ({ label, path }: ITabProps) => {
    const resolvedPath = useResolvedPath(path);
    const isExact = !!useMatch(resolvedPath.pathname);
    const tabClass = isExact ? "active-tab text-blue-600" : "inactive-tab text-gray-600";

    return (
        <Link className={`bg-white inline-block rounded-t py-2 px-4 font-semibold ${tabClass}`} to={`${path}`}>
            {label}
        </Link>
    );
};

const TabLinks: FunctionComponent<{
    tabs?: ITabProps[];
    params?: any;
}> = ({ tabs }) => {
    return (
        <ul className="flex border-b text-base">
            {tabs &&
                tabs.map((tab, i) => {
                    return (
                        tab.show && (
                            <li key={i} className="-mb-px mr-1">
                                <Tab {...{ ...tab }} />
                            </li>
                        )
                    );
                })}
        </ul>
    );
};

export default TabLinks;
