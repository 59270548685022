import * as actions from "../../actions/facebookActions";
import { AnyAction } from "redux";

const reducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.SUBSCRIBE_PAGE_TO_APP_SUCCESS:
        case actions.SUBSCRIBE_PAGE_TO_APP_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload?.message
            };
        case actions.SUBSCRIBE_PAGE_TO_APP:
            return {
                ...state,
                loading: true,
                error: action.payload?.message
            };
        default:
            return state;
    }
};

export default reducer;
