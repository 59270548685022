import React, { useState } from "react";
import { connect } from "react-redux";
import { string, object, mixed } from "yup";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import { requestDuplicateDynamicCampaign } from "../../../../actions/dynamicCampaigns/dynamicCampaignActions";
import CopyIcon from "../../../Shared/Icons/CopyIcon";
import Modal from "../../../Shared/Dialogs/Modal";
import Button from "../../../Shared/Button";

interface IProps {
    isOpen: boolean;
    closeModal: () => void;
    dynamicCampaign: any;
    adwordsCampaigns: { label: string; value: string; name?: string }[];
    clientId: number;
    requestDuplicateDynamicCampaign: (
        dynamicCampaignId: number,
        clientId: number,
        name: string,
        adwordsCampaignId: string,
        action: {
            resetModal: () => void;
            displayErrors: (error: any) => void;
        }
    ) => void;
}

interface IApiErrors {
    message: string;
    errors: {
        [key: string]: any;
    };
}

const DuplicateModal: React.FC<IProps> = ({
    isOpen,
    closeModal,
    dynamicCampaign,
    adwordsCampaigns,
    clientId,
    requestDuplicateDynamicCampaign
}) => {
    const [apiErrors, setApiErrors] = useState<IApiErrors>({ message: "", errors: {} });
    const [submissionInProgress, setSubmissionInProgress] = useState(false);
    const errorStyles = "text-red-500 text-sm italic uppercase mt-2";

    const formSchema = object().shape({
        name: string().required("The name field is required."),
        adwordsCampaign: mixed().required("The campaign field is required.")
    });

    const displayErrors = (e: any) => {
        setApiErrors(e);
        setSubmissionInProgress(false);
    };

    const resetModal = () => {
        setApiErrors({ message: "", errors: {} });
        setSubmissionInProgress(false);
        closeModal();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Modal hide={resetModal}>
            <Formik
                initialValues={{
                    name: "",
                    adwordsCampaign: { label: "", value: "" }
                }}
                validationSchema={formSchema}
                onSubmit={(values) => {
                    setSubmissionInProgress(true);
                    requestDuplicateDynamicCampaign(
                        dynamicCampaign.id,
                        clientId,
                        values.name,
                        (values?.adwordsCampaign?.value || "") as string,
                        { resetModal, displayErrors }
                    );
                }}
                render={({ values, touched, errors, setFieldValue }) => (
                    <Form>
                        <div className="text-2xl mt-2 text-blue-800">
                            <CopyIcon className="w-6 h-6 inline-block" /> Replicate Dynamic Campaign -{" "}
                            {dynamicCampaign.name}
                        </div>
                        <div>
                            {Object.keys(apiErrors?.errors).length !== 0 && [
                                <span key={apiErrors?.message} className={errorStyles}>
                                    {apiErrors?.message}
                                </span>,
                                Object.keys(apiErrors?.errors).map((key) => {
                                    const errorList = apiErrors?.errors?.[key];
                                    return (
                                        <div key={key}>
                                            {errorList.map((error: string) => {
                                                return (
                                                    <span key={error} className={errorStyles}>
                                                        {error}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    );
                                })
                            ]}
                            <label className="block uppercase tracking-wider text-gray-800 mt-8 mb-2">
                                <span>Name*</span>
                            </label>
                            <Field name="name" type="text" className="p-2 w-full border border-gray-400 rounded" />
                            {errors.name && touched.name ? <div className={errorStyles}>{errors.name}</div> : null}
                            <label className="block uppercase tracking-wider text-gray-800 mt-8 mb-2">
                                <span>Google Ads Campaign*</span>
                            </label>
                            <Select
                                name="adwordsCampaign"
                                options={Object.keys(adwordsCampaigns).map((key) => {
                                    return { value: key, label: adwordsCampaigns[Number(key)].name || "No Label" };
                                })}
                                onChange={(option) => {
                                    setFieldValue("adwordsCampaign", option);
                                }}
                                value={values.adwordsCampaign}
                            />
                            {errors.adwordsCampaign && touched.adwordsCampaign ? (
                                <div className={errorStyles}>{errors.adwordsCampaign}</div>
                            ) : null}
                        </div>
                        <div className="mt-5">
                            <Button type={"submit"} styleType={"primary"} disabled={submissionInProgress}>
                                Save
                            </Button>
                            <Button onClick={resetModal} styleType={"secondary"}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            />
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        adwordsCampaigns: state.adwordsCampaigns.entities,
        clientId: state.clients.currentClient
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestDuplicateDynamicCampaign: (
            dynamicCampaignId: number,
            clientId: number,
            name: string,
            adwordsCampaignId: string,
            actions: any
        ) => dispatch(requestDuplicateDynamicCampaign(dynamicCampaignId, clientId, name, adwordsCampaignId, actions))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateModal);
