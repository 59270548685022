import React from "react";
import { Link, useLocation, useMatch } from "react-router-dom";
interface IProps {
    collapsed: boolean;
    item: INavItemOption;
    uri?: string;
}
export interface INavItemOption {
    url: string;
    icon: any;
    title: string;
    shouldShow: boolean;
}
const defaultNavlinkStyles =
    "flex items-center hover:text-blue-200 focus-visible:text-blue-200 no-underline hover:bg-blue-900 focus-visible:bg-blue-900 hover:border-di-blue-300 focus-visible:border-di-blue-300 border-l-4 border-transparent py-3 focus:outline-none";
const activeStyles = `text-blue-200 bg-blue-800 border-di-blue-300 ${defaultNavlinkStyles}`;
const inActiveStyles = `text-blue-100 ${defaultNavlinkStyles}`;

const NavItem = ({ item, collapsed }: IProps) => {
    const match = useMatch(`${item.url}/*`);
    const isActuallyActive = match && item.title != "Dashboard";

    return (
        <div
            className="border-t"
            style={{
                borderColor: "rgb(48 108 158)"
            }}
        >
            <Link to={item.url} className={isActuallyActive ? activeStyles : inActiveStyles}>
                <span className={`flex flex-wrap items-center w-full ml-4`}>
                    {item.icon}
                    {!collapsed && <span className="flex-grow tracking-wider mx-2">{item.title}</span>}
                </span>
            </Link>
        </div>
    );
};

export default NavItem;
