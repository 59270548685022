import React, { useRef, useState, useReducer } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ISalesDataImport, { ISalesDataFormInputs } from "../../../interfaces/ISalesDataImport";
import Message from "../../../utils/Message";
import { getCsvHeaders } from "../../../utils/FileUtils";
import IMessage from "../../../interfaces/IMessage";
import DynamicSelectFields from "./DynamicSelectFields";
import Label, { labelStyles } from "../../Shared/Form/Label";
import {
    buttonDisabled,
    buttonStyles,
    cancelHover,
    cancelStyles,
    fieldStyles,
    submitHover
} from "../../../styles/FormStyles";
import FormErrorBoundary from "../../../utils/FormErrorBoundary";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import { LOAD_SALES_DATA } from "../../../actions/salesData";
import arrayHasUniqueValues from "../../../utils/arrayHasUniqueValues";

interface IProps {
    onSave: (payload: ISalesDataImport) => void;
    clientId: number;
}

const SALES_FILE_INPUT_NAME = "sales-file";

// Change this to only require zip code. Or dealer city and state if they don't have the zip code.
const SalesDataSchema = Yup.object().shape({
    buyer_zip: Yup.string().required("Required")
});

const initialValues: ISalesDataFormInputs = {
    [SALES_FILE_INPUT_NAME]: "",
    deal_number: "",
    deal_date: "",
    type: "",
    year: "",
    make: "",
    model: "",
    trim: "",
    buyer_address: "",
    buyer_city: "",
    buyer_state: "",
    buyer_zip: ""
};

const defaultHeaderValues: ISalesDataFormInputs = {
    deal_number: "ID",
    deal_date: "Date",
    type: "Type",
    year: "Year",
    make: "Make",
    model: "Model",
    trim: "Trim",
    buyer_address: "Address",
    buyer_city: "City",
    buyer_state: "State",
    buyer_zip: "Zip|Postal"
};

interface IState extends IMessage {
    decoding: boolean;
}

const onFileChange = ({
    setFieldValue,
    setDecoding,
    fileField,
    setError,
    setMessage,
    setFileHeaders
}: {
    setDecoding: (decoding: boolean) => void;
    setError: (error: boolean) => void;
    setMessage: (message: string) => void;
    setFileHeaders: (headers: string[]) => void;
    setFieldValue: (fieldName: string, value: any) => void;
    fileField: any;
}) => {
    return new Promise(async (resolve, reject) => {
        setDecoding(true);
        try {
            const headers = await getCsvHeaders(fileField);
            const hasUniqueHeaders = arrayHasUniqueValues(headers);
            if (!hasUniqueHeaders) {
                const error =
                    "Duplicate headers found, please review the file and remove duplicated columns before trying again.";
                setError(true);
                setMessage(error);
                setDecoding(false);
                reject(error);
                return;
            }

            setFileHeaders(headers);
            for (const index in defaultHeaderValues) {
                if (!defaultHeaderValues[index]) {
                    continue;
                }

                const matchedHeaders = headers.filter((header: string) => {
                    const defaultHeaderParts = defaultHeaderValues[index].split("|");
                    const returnable = defaultHeaderParts.filter((defaultValue: string) =>
                        // We have to toLowerCase things otherwise includes is case sensitive...
                        header.toLowerCase().includes(defaultValue.toLowerCase())
                    );
                    return returnable.length > 0;
                });

                if (matchedHeaders.length === 0) {
                    setFieldValue(index, "");
                } else {
                    setFieldValue(index, headers.indexOf(matchedHeaders[0]));
                }
            }

            setMessage("");
            setError(false);
            setFieldValue(SALES_FILE_INPUT_NAME, SALES_FILE_INPUT_NAME);
            setDecoding(false);
            resolve("");
        } catch (error) {
            setError(true);
            setMessage(error?.message);
            console.error(error);
            setDecoding(false);
            reject(error);
        }
    });
};

const cancelChanges = ({ setFieldValue, fileField, setDecoding, setError, setFileHeaders }: any) => {
    setFileHeaders([]);
    setDecoding(false);
    fileField.current.value = null;
    for (const value in initialValues) {
        setFieldValue(value, null); // Empty the form.
    }
};

const SalesDataImport: React.FunctionComponent<IProps> = ({ onSave, clientId }) => {
    const formikRef = useRef(null) as any;
    const fileField = useRef(null) as any;
    const [fileHeaders, setFileHeaders] = useState<string[]>([]);
    const [decoding, setDecoding] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    return (
        <Formik
            ref={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={SalesDataSchema}
            onSubmit={(headers: ISalesDataFormInputs, { setSubmitting, setErrors, setFieldValue }) => {
                setSubmitting(true);
                // Delete the extra file input so we don't send the file twice...
                delete headers[SALES_FILE_INPUT_NAME];

                onSave({
                    clientId,
                    headers,
                    fileInput: fileField.current,
                    callback() {
                        setSubmitting(false);
                        cancelChanges({ setFieldValue, fileField, setDecoding, setFileHeaders });
                        setSuccess(true);
                        setMessage("Successfully uploaded sales data!");
                    },
                    setErrors: (response: any) => {
                        setSuccess(true);
                        setMessage(response.message || "Whoops! An unknown error occurred!");
                        setErrors(response.errors || {});
                        setSubmitting(false);
                    }
                });
            }}
            render={({ dirty, errors, isValid, isSubmitting, setFieldValue }) => {
                const submitDisabled: boolean = !dirty || !isValid || isSubmitting;
                return (
                    <Form className="text-base bg-white p-4 rounded shadow mb-4">
                        <div className="mb-4 -mt-4">
                            <Label label="File to Upload" />
                            <FormErrorBoundary errors={errors} field={SALES_FILE_INPUT_NAME}>
                                <input
                                    ref={fileField}
                                    onChange={async (e) =>
                                        await onFileChange({
                                            setFieldValue,
                                            setDecoding,
                                            fileField: e.target,
                                            setFileHeaders,
                                            setError,
                                            setMessage
                                        })
                                    }
                                    disabled={isSubmitting}
                                    name={SALES_FILE_INPUT_NAME}
                                    data-testid={SALES_FILE_INPUT_NAME}
                                    type="file"
                                    accept=".tsv,.csv"
                                    className={`${fieldStyles} bg-gray-100`}
                                />
                            </FormErrorBoundary>
                        </div>
                        <DynamicSelectFields
                            decoding={decoding}
                            loading={fileHeaders.length === 0}
                            selectableFields={defaultHeaderValues}
                            disabled={isSubmitting}
                            options={fileHeaders}
                            errors={errors}
                        />
                        <hr className="fancy" />
                        <Message
                            error={error}
                            success={success}
                            message={message === "Failed to fetch" ? "Whoops! An unknown error occurred!" : message}
                        />
                        <div className="flex items-center justify-end mt-4">
                            <button
                                disabled={!dirty}
                                className={`${cancelStyles} ${!dirty ? buttonDisabled : cancelHover}`}
                                type="button"
                                onClick={(e) =>
                                    cancelChanges({ setFieldValue, fileField, setDecoding, setFileHeaders })
                                }
                            >
                                Clear form
                            </button>
                            <button
                                disabled={submitDisabled}
                                className={`flex items-center ${buttonStyles} ${
                                    submitDisabled ? buttonDisabled : submitHover
                                }`}
                                type="submit"
                            >
                                {isSubmitting ? <RefreshIcon className="w-6 h-6" /> : ""}
                                Import{isSubmitting ? "ing" : ""}
                            </button>
                        </div>
                    </Form>
                );
            }}
        />
    );
};

export default SalesDataImport;
