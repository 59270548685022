import React from "react";
import EditIcon from "../../Shared/Icons/EditIcon";
import IUser from "../../../interfaces/IUser";
import TableActionButton from "../../Shared/Table/TableActionButton";
import TableActionDeleteButton from "../../Shared/Table/TableActionDeleteButton";

interface IProps {
    user: IUser;
    editUser: (user: IUser) => void;
    deleteUser: (user: IUser) => void;
}

const UserActionCell: React.FunctionComponent<IProps> = ({ editUser, deleteUser, user }) => {
    return (
        <div className="inline-flex text-gray-500">
            <TableActionButton onClick={() => editUser(user)} Icon={EditIcon} />
            <TableActionDeleteButton
                deletionMessage="Are you sure you want to delete this user?"
                onDelete={() => deleteUser(user)}
            />
        </div>
    );
};

export const buildUsersColumns = (editUser: (user: IUser) => void, deleteUser: (user: IUser) => void) => {
    return [
        {
            Header: "Actions",
            accessor: "actions",
            Cell: (props: { original: IUser }) => (
                <UserActionCell editUser={editUser} deleteUser={deleteUser} user={props.original} />
            ),
            sortable: false,
            resizable: false,
            width: 75
        },
        {
            Header: "Email",
            accessor: "email",
            resizable: false
        },
        {
            Header: "First Name",
            accessor: "firstName",
            resizable: false
        },
        {
            Header: "Last Name",
            accessor: "lastName",
            resizable: false
        },
        {
            Header: "Language",
            accessor: "language",
            resizable: false
        }
    ];
};
