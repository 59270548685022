import { call, CallEffect } from "redux-saga/effects";
import { Bugsnag } from "../../bugsnag";
import IGAEventAttributes from "./IGAEventAttributes";
import Config from "../../config";
const configs = Config.getInstance();

function sendEvent({ category, action, label, value }: IGAEventAttributes) {
    const gaProfile = configs.get("GA_TRACKING_PROFILE");

    if (typeof window === "undefined" || gaProfile === undefined) {
        return;
    }

    if (typeof (window as any).gtag !== "function" || !!gaProfile === false) {
        console.log({
            event_action: action,
            event_category: category,
            event_label: label,
            ...(value !== undefined ? { value: value } : {})
        });
        return;
    }

    (window as any).gtag("event", action, {
        event_category: category,
        event_label: label,
        ...(value !== undefined ? { value: value } : {})
    });
}

export default function* trackEvent(eventArgs: IGAEventAttributes): Generator<CallEffect, any> {
    try {
        yield call(sendEvent, eventArgs);
    } catch (e) {
        Bugsnag.notify(e);
    }
}
