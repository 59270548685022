import React, { forwardRef } from "react";
import { array, object, string } from "yup";
import IGenericCampaign, {
    IGenericCampaignField,
    IGenericCampaignFormValue
} from "../../../../interfaces/DealerSetup/IGenericCampaign";
import { useDealerSetupForm, usePager, changeFcaCampaignType, hasFcaCampaignType } from "../DealerSetupUtils";
import { intersection, isEqual } from "lodash";
import FieldErrors from "../Fields/FieldErrors";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";
import MAZDA_GENERIC_CAMPAIGNS from "../Settings/Mazda/GenericCampaigns";
import MAZDA_RGTMPLUS_GENERIC_CAMPAIGNS from "../Settings/MazdaRGTM+/GenericCampaigns";
import TrashIcon from "../../../Shared/Icons/TrashIcon";
import IClient from "../../../../interfaces/IClient";

type HandleFieldChange = (
    key: string,
    value: { name: string; keywords: string[] } | IGenericCampaignField | IGenericCampaignField[],
    withoutValidation?: boolean
) => void;
type ChangePage = () => () => (path: string, validatePage: () => void) => void;
type UseDealerSetupForm = [IGenericCampaign, HandleFieldChange, ChangePage];

interface IProps {
    path: string;
    formValues: IFormValues;
    savePage(path: string, validate: () => void): void;
    client: IClient;
}

const GenericCampaigns = forwardRef(({ formValues, path, savePage, client }: IProps, ref) => {
    const schema = object({
        campaigns: array().of(
            object({
                name: string().required("The Name field is required.").min(1)
            })
        )
    });

    const clientManufacturers = client.automobileManufacturers ? client.automobileManufacturers : [];
    const brands: string[] = clientManufacturers.map((f) => f.name);
    const defaults: IGenericCampaign = {
        fields: {
            campaigns: getDefaultBrandCampaigns(brands, client.oemProgram)
        }
    };

    getDefaultsOverrides(defaults, client);

    const [values, handleFieldChange, changePage]: UseDealerSetupForm = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleAddCampaign = () => {
        handleFieldChange(
            `campaigns[${values.fields.campaigns.length}]`,
            { value: defaultBrandCampaigns.default[0] },
            true
        );
    };

    const handleApplyManufacturerDefaults = () => {
        handleFieldChange(`campaigns`, defaults.fields.campaigns, true);
    };

    const handleDeleteCampaign = (index: number) => {
        values.fields.campaigns.splice(index, 1);
        handleFieldChange(`campaigns`, values.fields.campaigns, true);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value, type } = event.target;
        let updated: string | string[] = value;
        if (type === "textarea") {
            updated = value.split("\n");
        }
        handleFieldChange(`campaigns[${index}]`, { ...values.fields.campaigns[index].value, [name]: updated });
    };

    const deletable = values.fields.campaigns.length > 1;
    const usingDefaults = intersection(brands, Object.keys(defaultBrandCampaigns)).length
        ? isEqual(
              values.fields.campaigns.map(({ value }) => ({ value })),
              defaults.fields.campaigns
          )
        : true;

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Generic Campaigns Setup</div>

            {values.fields.campaigns.map(({ value, errors, isTouched }, i) => {
                const { name, keywords } = value;
                const content = keywords.length ? keywords.join("\n") : "";
                return (
                    <div key={i} className={`w-full mt-4 ${i ? "border-t pt-4" : ""} py-4`}>
                        <div className={`w-full relative`}>
                            {deletable && (
                                <button
                                    className="float-right text-gray-600 hover:text-gray-800"
                                    onClick={() => handleDeleteCampaign(i)}
                                >
                                    <TrashIcon className="w-6 h-6 text-xl" />
                                </button>
                            )}
                        </div>
                        <div className={`w-full`}>
                            <div className="w-2/3 py-2 font-bold text-base">Campaign Name*</div>
                            <div className="w-full py-2 flex">
                                <input
                                    value={name || ""}
                                    type="text"
                                    name={`name`}
                                    onChange={(e) => handleInputChange(e, i)}
                                    className={`text-sm border flex-1 py-2 px-4 rounded`}
                                    data-testid="name"
                                />
                            </div>
                            {client.oemProgram === "fca" && (
                                <div className="w-full flex">
                                    <div className="mr-3">
                                        <input
                                            type="radio"
                                            checked={hasFcaCampaignType(
                                                i,
                                                "always",
                                                values.fields.campaigns[i].value.name
                                            )}
                                            id="always"
                                            name={`fcaCampaignType[${i}]`}
                                            className="text-sm pr-3"
                                            onChange={(event) =>
                                                handleFieldChange(`campaigns[${i}]`, {
                                                    ...values.fields.campaigns[i].value,
                                                    name: changeFcaCampaignType(
                                                        event.target.id,
                                                        values.fields.campaigns[i].value.name
                                                    )
                                                })
                                            }
                                        />
                                        <span className="text-sm ml-3">Always</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={hasFcaCampaignType(
                                                i,
                                                "boost",
                                                values.fields.campaigns[i].value.name
                                            )}
                                            id="boost"
                                            name={`fcaCampaignType[${i}]`}
                                            className="text-sm pr-3"
                                            onChange={(event) =>
                                                handleFieldChange(`campaigns[${i}]`, {
                                                    ...values.fields.campaigns[i].value,
                                                    name: changeFcaCampaignType(
                                                        event.target.id,
                                                        values.fields.campaigns[i].value.name
                                                    )
                                                })
                                            }
                                        />
                                        <span className="text-sm ml-3">Boost</span>
                                    </div>
                                </div>
                            )}
                            <FieldErrors errors={errors} />
                        </div>
                        <div className={`w-full`}>
                            <div className="w-2/3 py-2 font-bold text-base">Keywords</div>
                            <span className="font-normal italic text-xs pb-2">One keyword per line</span>
                            <div className="w-full py-2 flex">
                                <textarea
                                    value={content || ""}
                                    name={`keywords`}
                                    onChange={(e) => handleInputChange(e, i)}
                                    className={`text-sm border flex-1 py-2 px-4 rounded h-64`}
                                    data-testid="keywords"
                                />
                            </div>
                        </div>
                    </div>
                );
            })}

            <button onClick={handleAddCampaign} className="text-base font-bold text-blue-500 my-4 pb-3">
                + Add Campaign
            </button>

            {usingDefaults || (
                <button
                    className="text-base font-bold text-blue-500 hover:text-blue-600 my-4 pb-3 float-right"
                    onClick={handleApplyManufacturerDefaults}
                >
                    Use Manufacturer Defaults
                </button>
            )}

            {pager()}
        </div>
    );
});

const defaultBrandCampaigns: { [key: string]: IGenericCampaignFormValue[] } = {
    default: [
        {
            name: "",
            keywords: [],
            negative_keyword_lists: []
        }
    ],
    Mazda: MAZDA_GENERIC_CAMPAIGNS
};

const getDefaultBrandCampaigns = (brands: string[], oemProgram: string): IGenericCampaignField[] => {
    const campaignValues = brands.reduce((brandCampaigns: any, brand: string): IGenericCampaignField[] => {
        if (brand in defaultBrandCampaigns) {
            return brandCampaigns.concat(
                defaultBrandCampaigns[brand].map((campaign: IGenericCampaignFormValue) => ({ value: campaign }))
            );
        }
        return brandCampaigns;
    }, []);

    return campaignValues.length
        ? campaignValues
        : defaultBrandCampaigns.default.map((campaign: IGenericCampaignFormValue) => ({ value: campaign }));
};

const getDefaultsOverrides = (defaults: IGenericCampaign, client: IClient) => {
    //FUEL-6252 default Keywords for all mazda RGTM+ All Model Static
    if (client.oemProgram === "mazdaRgtmPlus") {
        const ALL_MODELS_STATIC = "All Models Static";
        const mazdaRgtmPlusAllModelsStatic = MAZDA_RGTMPLUS_GENERIC_CAMPAIGNS.find(
            (campaign: any) => campaign.name === ALL_MODELS_STATIC
        );
        const currentAllModelStatic = defaults.fields.campaigns.find(
            (campaign: any) => campaign.value.name === ALL_MODELS_STATIC
        );
        if (currentAllModelStatic?.value?.keywords) {
            currentAllModelStatic.value.keywords = mazdaRgtmPlusAllModelsStatic?.keywords || [];
        }
    }
};

export default GenericCampaigns;
