import React, { FunctionComponent } from "react";
import AddIcon from "../../Shared/Icons/AddIcon";
import Button from "../../Shared/Button";
import WarningIcon from "../../Shared/Icons/WarningIcon";
import ChevronUpIcon from "../../Shared/Icons/ChevronUpIcon";
import SpecialOfferTemplatePriorityToggle from "./SpecialOfferTemplates/SpecialOfferTemplatePriorityToggle";

interface IEtaSectionProps {
    title: string;
    children: any;
    isOpen: boolean;
    hasErrors: boolean;
    stripe?: boolean;
    onToggleCollapse(): void;
    hasSpecialOffers: boolean;
    specialOfferPrioritization: boolean;
    onToggleSpecialOfferPrioritization(): void;
}
const ExpandedTextAdsSection: FunctionComponent<IEtaSectionProps> = ({
    title,
    children,
    stripe,
    onToggleCollapse,
    isOpen,
    hasErrors,
    hasSpecialOffers,
    specialOfferPrioritization,
    onToggleSpecialOfferPrioritization
}) => {
    return (
        <div className={`my-4 shadow ${stripe ? "bg-blue-200" : "bg-white"} rounded`}>
            <div className={`flex justify-between p-4`}>
                <div className="text-2xl my-2 text-blue-800 cursor-pointer w-3/4" onClick={onToggleCollapse}>
                    {title}
                </div>
                <div className="flex justify-end items-center">
                    {hasErrors && (
                        <div className="text-red-500 text-base mx-4 content-center flex">
                            <div className="flex items-center">
                                <WarningIcon className="w-6 h-6" />
                                <span className="ml-2">Attention Needed.</span>
                            </div>
                        </div>
                    )}

                    {hasSpecialOffers && (
                        <div className="flex mx-4">
                            <SpecialOfferTemplatePriorityToggle
                                onToggle={onToggleSpecialOfferPrioritization}
                                value={specialOfferPrioritization}
                            />
                        </div>
                    )}

                    <div className="text-2xl text-cerulean-500 cursor-pointer" onClick={onToggleCollapse}>
                        <ChevronUpIcon
                            className="w-6 h-6"
                            style={{ transition: ".2s ease", ...(isOpen ? {} : { transform: "rotate(180deg)" }) }}
                        />
                    </div>
                </div>
            </div>
            <div className={`px-4 pb-4 border-t border-gray-400 bg-gray-100 ${isOpen ? "" : "hidden"}`}>{children}</div>
        </div>
    );
};

export default ExpandedTextAdsSection;
