import React from "react";

const useDebouncedCallback = (callback: Function, delay: number, dependencies?: any[]) => {
    const timeout = React.useRef<any>();

    return React.useCallback(
        (...args) => {
            if (timeout.current != null) {
                clearTimeout(timeout.current);
            }

            timeout.current = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay]
    );
};

export default useDebouncedCallback;
