import React, { FunctionComponent } from "react";

interface IItemProperties {
    item: { [key: string]: any };
    errors: string[];
}
const ItemProperties: FunctionComponent<IItemProperties> = ({ item, errors }) => {
    return (
        <div className="bg-gray-200 px-2 overflow-y-auto" style={{ maxHeight: "60vh" }}>
            {Object.entries(item).map(([field, value]) => {
                let formattedValue = value;
                switch (typeof value) {
                    case "object":
                        formattedValue = JSON.stringify(value);
                        break;
                    default:
                        formattedValue = value;
                }
                return (
                    <span
                        className="text-xs p-2"
                        style={{ backgroundColor: errors.includes(field) ? "yellow" : "inherit" }}
                        key={field}
                    >
                        {field}: {formattedValue}
                        <br />
                    </span>
                );
            })}
        </div>
    );
};

export default ItemProperties;
