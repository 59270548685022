import React from "react";
import { ISpecialOfferTemplatePart } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import SpecialOfferTemplatePart from "./SpecialOfferTemplatePart";

interface IProps {
    parts: ISpecialOfferTemplatePart[];
    templateIndex: number;
    size: string;
}

const SpecialOfferTemplateParts: React.FC<IProps> = ({ parts, templateIndex, size }) => {
    return (
        <React.Fragment>
            {parts &&
                parts.map((item: ISpecialOfferTemplatePart, i: number) => {
                    return (
                        <SpecialOfferTemplatePart
                            item={item}
                            index={i}
                            key={item.id}
                            templateIndex={templateIndex}
                            size={size}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default SpecialOfferTemplateParts;
