import { useState } from "react";

function useTimeToSubmitMetric() {
    const [startTime, setStartTime] = useState(Date.now());

    const resetTimer = () => {
        setStartTime(Date.now());
    };

    const inSeconds = (milliseconds: number) => {
        return Math.round(milliseconds / 1000);
    };

    return (reset: boolean = true) => {
        const difference = Date.now() - startTime;

        if (reset) {
            resetTimer();
        }

        return inSeconds(difference);
    };
}

export default useTimeToSubmitMetric;
