import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClientForm from "./ClientForm";
import { saveClient, refreshExternalData, deleteClient, fetchClient } from "../../../actions/clientActions";
import { isUserInternal, canScheduleCancellation as canScheduleCancel } from "../../../reducers/currentUser";
import { fetchManufacturerList } from "../../../actions/ManufacturerActions";
import { useParams } from "react-router-dom";
import IClient from "../../../interfaces/IClient";
import IAppState from "../../../interfaces/IAppState";

const Container: FunctionComponent = () => {
    const { clientId } = useParams();
    const dispatch = useDispatch();
    const {
        clientRoles,
        facebookAccounts,
        facebookPages,
        currentUser,
        automobileManufacturers: { entities: automobileManufacturers },
        clients: { entities: clients, loading }
    } = useSelector((state: IAppState) => state);

    const props = {
        clientId,
        client: clients[clientId || 0],
        clients,
        loading,
        canScheduleCancellation: canScheduleCancel(currentUser),
        clientRoles,
        isInternalUser: isUserInternal(currentUser),
        facebookAccounts,
        facebookPages,
        automobileManufacturers,
        onFetchClient: (clientId: string) => dispatch(fetchClient(clientId)),
        onSave: (client: IClient, changedFields: any) => dispatch(saveClient(client, changedFields)),
        onRefreshSalesforceData: (client: IClient) => dispatch(refreshExternalData(client)),
        onDelete: (client: IClient) => !!client?.id && dispatch(deleteClient(client.id)),
        fetchManufacturers: () => dispatch(fetchManufacturerList())
    };

    return <ClientForm {...props} />;
};

export default Container;
