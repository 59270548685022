import { Reducer, combineReducers } from "redux";
import UsersConstants from "../constants/UsersConstants";

const entities: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case UsersConstants.FETCH_USERS_SUCCESS:
            return action.payload.users;
        case UsersConstants.FETCH_USERS:
            return {};
        case UsersConstants.REQUEST_DELETE_USER_SUCCESS: {
            delete state[action.payload.userId];
            return { ...state };
        }
        default:
            return state;
    }
};

const loading: Reducer<any> = (state = false, action) => {
    switch (action.type) {
        case UsersConstants.FETCH_USERS:
        case UsersConstants.REQUEST_USER:
        case UsersConstants.REQUEST_USER_ROLES:
        case UsersConstants.REQUEST_CREATE_USER:
        case UsersConstants.REQUEST_UPDATE_USER:
        case UsersConstants.REQUEST_DELETE_USER:
            return true;
        case UsersConstants.FETCH_USERS_FAIL:
        case UsersConstants.FETCH_USERS_SUCCESS:
        case UsersConstants.REQUEST_USER_FAIL:
        case UsersConstants.REQUEST_USER_SUCCESS:
        case UsersConstants.REQUEST_DELETE_USER_FAIL:
        case UsersConstants.REQUEST_DELETE_USER_SUCCESS:
        case UsersConstants.REQUEST_USER_ROLES_FAIL:
        case UsersConstants.REQUEST_USER_ROLES_SUCCESS:
        case UsersConstants.REQUEST_CREATE_USER_FAIL:
        case UsersConstants.REQUEST_UPDATE_USER_FAIL:
            return false;
        default:
            return state;
    }
};

const isSubmitting: Reducer<any> = (state = false, action) => {
    switch (action.type) {
        case UsersConstants.REQUEST_CREATE_USER:
        case UsersConstants.REQUEST_UPDATE_USER:
        case UsersConstants.REQUEST_ACTIVATE_USER:
        case UsersConstants.REQUEST_SAVE_SETTINGS:
        case UsersConstants.REQUEST_RESET_PASSWORD:
        case UsersConstants.REQUEST_RESET_PASSWORD_EMAIL:
            return true;
        case UsersConstants.REQUEST_CREATE_USER_FAIL:
        case UsersConstants.REQUEST_CREATE_USER_SUCCESS:
        case UsersConstants.REQUEST_UPDATE_USER_FAIL:
        case UsersConstants.REQUEST_UPDATE_USER_SUCCESS:
        case UsersConstants.REQUEST_ACTIVATE_USER_FAIL:
        case UsersConstants.REQUEST_ACTIVATE_USER_SUCCESS:
        case UsersConstants.REQUEST_SAVE_SETTINGS_FAIL:
        case UsersConstants.REQUEST_SAVE_SETTINGS_SUCCESS:
        case UsersConstants.REQUEST_RESET_PASSWORD_FAIL:
        case UsersConstants.REQUEST_RESET_PASSWORD_SUCCESS:
        case UsersConstants.REQUEST_RESET_PASSWORD_EMAIL_FAIL:
        case UsersConstants.REQUEST_RESET_PASSWORD_EMAIL_SUCCESS:
            return false;
        default:
            return state;
    }
};

const error: Reducer<any> = (state = null, action) => {
    switch (action.type) {
        case UsersConstants.FETCH_USERS:
        case UsersConstants.REQUEST_USER_ROLES:
        case UsersConstants.REQUEST_USER:
        case UsersConstants.REQUEST_USER_SUCCESS:
        case UsersConstants.REQUEST_DELETE_USER:
        case UsersConstants.REQUEST_CREATE_USER:
        case UsersConstants.REQUEST_CREATE_USER_SUCCESS:
        case UsersConstants.REQUEST_UPDATE_USER:
        case UsersConstants.REQUEST_UPDATE_USER_SUCCESS:
            return null;
        case UsersConstants.REQUEST_USER_FAIL:
        case UsersConstants.REQUEST_DELETE_USER_FAIL:
        case UsersConstants.REQUEST_USER_ROLES_FAIL:
        case UsersConstants.REQUEST_CREATE_USER_FAIL:
        case UsersConstants.REQUEST_UPDATE_USER_FAIL:
        case UsersConstants.REQUEST_ACTIVATE_USER_FAIL:
        case UsersConstants.REQUEST_RESET_PASSWORD_FAIL:
        case UsersConstants.REQUEST_RESET_PASSWORD_EMAIL_FAIL:
            return action.payload.error;
        default:
            return state;
    }
};

const roles: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case UsersConstants.REQUEST_USER_ROLES:
            return {};
        case UsersConstants.REQUEST_USER_ROLES_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

const loadedAt: Reducer<any> = (state = null, action) => {
    switch (action.type) {
        case UsersConstants.FETCH_USERS_SUCCESS:
            return new Date();
        case UsersConstants.REQUEST_CREATE_USER_SUCCESS:
        case UsersConstants.REQUEST_UPDATE_USER_SUCCESS:
        case UsersConstants.FETCH_USERS:
            return null;
        default:
            return state;
    }
};

const selectedUser: Reducer<any> = (state = null, action) => {
    switch (action.type) {
        case UsersConstants.REQUEST_USER:
        case UsersConstants.REQUEST_UPDATE_USER_SUCCESS:
        case UsersConstants.REQUEST_USER_FAIL:
            return null;
        case UsersConstants.REQUEST_USER_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    entities,
    selectedUser,
    loading,
    roles,
    error,
    isSubmitting,
    loadedAt
});
