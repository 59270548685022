import React from "react";
import FieldErrors from "../Fields/FieldErrors";
import IRemarketing, { IRemarketingFields } from "../../../../interfaces/DealerSetup/IRemarketingFields";

const RemarketingSection: React.FC<IRemarketing> = ({ fields }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    {fields.map((campaign: IRemarketingFields, index: number) => {
                        const { name, address, city, state, zip, country, radius, distanceUnit } = campaign;
                        return (
                            <div key={index} className={`m-4 ${index ? "pt-6" : ""}`}>
                                <div className="font-bold">
                                    Campaign Name: <span className="font-normal">{name.value}</span>
                                </div>
                                <FieldErrors errors={name.errors} />

                                <div className="font-bold">
                                    Geotarget:{" "}
                                    <span className="font-normal">
                                        {`
                                            ${address.value ? `${address.value}, ` : ""}
                                            ${city.value ? `${city.value}, ` : ""}
                                            ${state.value ? `${state.value} ` : ""}
                                            ${zip.value ? `${zip.value} ` : ""}
                                            ${country.value ? `${country.value} ` : ""}
                                        `}
                                    </span>
                                </div>
                                <FieldErrors errors={address.errors} />
                                <FieldErrors errors={city.errors} />
                                <FieldErrors errors={state.errors} />
                                <FieldErrors errors={zip.errors} />
                                <FieldErrors errors={country.errors} />

                                <div className="font-bold">
                                    Geotarget Radius:{" "}
                                    <span className="font-normal">
                                        {radius.value} {distanceUnit.value}
                                    </span>
                                </div>
                                <FieldErrors errors={distanceUnit.errors} />
                            </div>
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default RemarketingSection;
