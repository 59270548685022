/*
 * This function makes it pretty easy to handle the dynamic case of fields being set on one client and not on the other
 * by letting us filter through all the parts. So long as some truthy value is returned in the `parts` then it will show up in the ui.
 */
const joinTrimAndFilterArrayOfParts = (
    parts: any[],
    glue: null | undefined | string = " / ",
    defaultValue: any = ""
) => {
    const possibleHeader = parts
        .filter((item) => !!item)
        .join(glue || "")
        .trim();

    if (!possibleHeader) {
        return defaultValue;
    }

    return possibleHeader;
};

export default joinTrimAndFilterArrayOfParts;
