import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FormikActions, Form, Formik } from "formik";
import IEntity from "../../../interfaces/IEntity";
import IDynamicCampaignFormValue from "../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import IGoogleCampaign from "../../../interfaces/IGoogleCampaign";
import ICollapsible from "../../../interfaces/ICollapsible";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import TableLoader from "../../Shared/Table/TableLoader";
import { CurrencyInput } from "../../Shared/Form/CurrencyField";
import Label from "../../Shared/Form/Label";
import { Notification } from "../../Shared";
import TextField from "../../Shared/Form/Blocks/TextField";
import SelectField from "../../Shared/Form/Blocks/SelectField";
import StatusIndicator from "../../Shared/StatusIndicator";
import Button, { baseStyles, secondaryClasses, PRIMARY_BUTTON } from "../../Shared/Button";
import DynamicCampaignStatusDetails from "./DynamicCampaignStatusDetails";
import RefreshSelector from "./RefreshSelector";
import InventoryGroup from "./InventoryGroup";
import InventoryFilters from "./InventoryFilters";
import InventoryGroupFilter from "./InventoryGroupFilter";
import BidModifierList from "./AdGroupBidModifiers/BidModifierList";
import ExpandedTextAds from "./ExpandedTextAds/ExpandedTextAds";
import NotRunningError from "./NotRunningError";
import KeywordTemplates from "./Keywords/KeywordTemplates/KeywordTemplates";
import KeywordBids from "./Keywords/KeywordBids/KeywordBids";
import Section from "./Section";
import Generator from "./Keywords/KeywordGenerator/Generator";
import {
    getPrepopulatedFields,
    determineInventoryFiltersPrefillFields,
    mapErrorsToSection,
    getFieldErrors,
    formatCampaignsAsOptions
} from "../../../utils/DynamicCampaignUtils";
import { getInventoryFieldsDI } from "../../../utils/InventoryUtils";
import { addDynamicCampaignStatusListener, removeDynamicCampaignStatusListener } from "../../../EchoListeners";
import useCampaign from "../../../hooks/useCampaign";
import { statusIndicatorSteps } from "../../../constants/DynamicCampaignConstants";
import { Schema } from "./DynamicCampaignSchemas";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import IMicrosoftCampaign from "../../../interfaces/IMicrosoftCampaign";
import PurgeButton from "./PurgeButton";
import ChevronDownIcon from "../../Shared/Icons/ChevronDownIcon";
import UnlockButton from "./UnlockButton";
import { setNestedObjectValues } from "formik";
import LockIcon from "../../Shared/Icons/LockIcon";
import moment from "moment";
import useTimeToSubmitMetric from "../../../hooks/useTimeToSubmitMetric";
import UnchainIcon from "../../Shared/Icons/UnchainIcon";
import SpecialOfferTemplateAds from "./SpecialOfferTemplates/SpecialOfferTemplateAds";
import DynamicCampaignInventoryVisualizer from "./DynamicCampaignInventoryVisualizer";
import { connect } from "react-redux";
import IAppState from "../../../interfaces/IAppState";
import { ISpecialOfferTemplateAttachedToDynamicCampaignPayload } from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import ExpandedTextAdsSection from "./ExpandedTextAdsSection";
import DynamicCampaignPushSummary from "./Preview/DynamicCampaignPushSummary";

interface IProps {
    googleCampaigns: IEntity<IGoogleCampaign>;
    microsoftCampaigns: IEntity<IMicrosoftCampaign>;
    onSave: (
        dynamicCampaign: IDynamicCampaignFormValue,
        clientId: number,
        actions: FormikActions<IDynamicCampaignFormValue>,
        timeToSubmitMetric: number,
        sotsToDetach: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[]
    ) => void;
    client: any;
    clientId: number;
    dynamicCampaign: IDynamicCampaignFormValue;
    dynamicCampaignStatuses?: any;
    onPushCampaign: (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus" | "setSubmitting">
    ) => void;
    onPurgeCampaign?: (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus">
    ) => void;
    onUnlockCampaign?: (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus">
    ) => void;
    isUserDeveloper?: any;
    unlockCampaignOnRunFailure?: (dynamicCampaignId: number) => void;
    sotsToDetach?: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[];
}

const adRotationOptions = [
    { value: "OPTIMIZE", label: "Optimze" },
    { value: "ROTATE_FOREVER", label: "Rotate" }
];
const inventorySelectFields = getInventoryFieldsDI().map((field) => ({ label: field, value: field }));

const DynamicCampaignForm: React.SFC<IProps> = ({
    googleCampaigns,
    microsoftCampaigns,
    onSave,
    client,
    clientId,
    dynamicCampaign,
    onPushCampaign,
    onPurgeCampaign,
    onUnlockCampaign,
    isUserDeveloper,
    unlockCampaignOnRunFailure,
    sotsToDetach
}) => {
    const formRef = useRef(null) as any;
    const isInitialValid = Schema.isValidSync(dynamicCampaign);
    const initialIndicatorAction = dynamicCampaign.lockedByAction
        ? dynamicCampaign.lockedByAction.status
        : dynamicCampaign.previousAction && dynamicCampaign.previousAction.status;
    const [googleCampaign, setGoogleCampaign] = useCampaign(dynamicCampaign, googleCampaigns, "adwordsCampaignId");
    const [microsoftCampaign, setMicrosoftCampaign] = useCampaign(
        dynamicCampaign,
        microsoftCampaigns,
        "microsoftCampaignId"
    );

    const microsoftCampaignOptions = formatCampaignsAsOptions(microsoftCampaigns);
    const googleCampaignOptions = formatCampaignsAsOptions(googleCampaigns);

    const collapsibleSections: ICollapsible = {
        information: { isOpen: true, hasErrors: false },
        inventory: { isOpen: true, hasErrors: false },
        adGroup: { isOpen: true, hasErrors: false },
        keywords: { isOpen: true, hasErrors: false },
        eta: { isOpen: true, hasErrors: false }
    };

    const initialCollapsedState = Object.keys(collapsibleSections).reduce((sections, key) => {
        // if user is editing campaign, set all to collapsed by default
        if (dynamicCampaign.id) {
            sections[key].isOpen = false;
        }
        return sections;
    }, collapsibleSections);

    const [collapsed, setCollapsed] = useState<ICollapsible>(initialCollapsedState);
    const allCollapsed = Object.values(collapsed).every((section) => !section.isOpen);

    const handleToggleCollapse = (key: string) => {
        setCollapsed((col) => {
            return { ...col, [key]: { ...col[key], isOpen: !col[key].isOpen } };
        });
    };

    const handleCollapseAll = () => {
        setCollapsed((currentCollapsed) => {
            return Object.keys(currentCollapsed).reduce((acc, curr) => {
                acc[curr] = { ...acc[curr], isOpen: allCollapsed };
                return acc;
            }, {} as ICollapsible);
        });
    };

    const [lockedDiffForHumans, setLockedDiffForHumans] = useState("");

    useEffect(() => {
        if (!formRef.current || !dynamicCampaign.errors) {
            return;
        }
        if (Object.keys(dynamicCampaign.errors).length) {
            formRef.current.setErrors(dynamicCampaign.errors);
            formRef.current.setStatus({
                warning: "Some items may not have been saved. Please check the form for additional errors."
            });
        }
    }, []);

    useEffect(() => {
        if (dynamicCampaign.id) {
            const formikComputedProps = formRef.current.getFormikComputedProps();
            if (!formikComputedProps.dirty) {
                const asyncValidate = async () => {
                    return await formRef.current.validateForm(formikComputedProps.initialValues);
                };
                const formikErrors: any = asyncValidate();
                formRef.current.setFormikState(() => ({
                    errors: formikErrors,
                    touched: setNestedObjectValues(formikComputedProps.initialValues, true)
                }));
            }
        }
    });

    useEffect(() => {
        if (dynamicCampaign.lockedAt === null) {
            return;
        }

        setLockedDiffForHumans(moment().to(dynamicCampaign.lockedAt));

        const updateTime = setInterval(() => {
            setLockedDiffForHumans(moment().to(dynamicCampaign.lockedAt));
        }, 5000);

        return () => {
            clearInterval(updateTime);
        };
    }, [dynamicCampaign.lockedAt]);

    const getTimeToSubmitMetric = useTimeToSubmitMetric();

    return (
        <React.Fragment>
            <StatusIndicator
                id={dynamicCampaign.id}
                statuses={statusIndicatorSteps}
                initialAction={initialIndicatorAction}
                finishedComponent={DynamicCampaignStatusDetails}
                addListener={addDynamicCampaignStatusListener}
                removeListener={removeDynamicCampaignStatusListener}
                unlockCampaignOnRunFailure={unlockCampaignOnRunFailure}
            />
            <PageTitle title={`${dynamicCampaign.id ? dynamicCampaign.name : "New Dynamic Campaign"}`}>
                <Button
                    onClick={handleCollapseAll}
                    styles="flex justify-between"
                    type="button"
                    style={{ paddingRight: "0px" }}
                >
                    <span className="self-end font-sans">{allCollapsed ? "Expand All" : "Collapse All"}</span>
                    <ChevronDownIcon
                        className="w-6 h-6 mx-4 text-cerulean-500 hover:text-cerulean-700 self-center"
                        style={{ transition: ".2s ease", ...(allCollapsed ? {} : { transform: "rotate(-180deg)" }) }}
                    />
                </Button>
            </PageTitle>

            <div>
                <NotRunningError client={client} />

                <Formik
                    ref={formRef}
                    enableReinitialize={false}
                    validationSchema={Schema}
                    isInitialValid={isInitialValid}
                    initialValues={dynamicCampaign}
                    validateOnChange={false}
                    validateOnBlur={true}
                    onSubmit={(
                        values: IDynamicCampaignFormValue,
                        actions: FormikActions<IDynamicCampaignFormValue>
                    ) => {
                        actions.setStatus({ message: "Saving Dynamic Campaign" });
                        onSave(values, clientId, actions, getTimeToSubmitMetric(), sotsToDetach || []);
                    }}
                    render={({
                        status,
                        values,
                        dirty,
                        isValid,
                        setStatus,
                        setFieldValue,
                        setValues,
                        setSubmitting,
                        setErrors,
                        isSubmitting,
                        touched,
                        errors,
                        setFieldTouched
                    }) => {
                        const toggleSpecialOfferPrioritization = (): void => {
                            const currentValue = !!values.prioritizeSpecialOffers;
                            setFieldValue("prioritizeSpecialOffers", !currentValue);
                        };

                        const handleAdWordsCampaignChange = (item: IGoogleCampaign) => {
                            setFieldValue("adwordsCampaignId", item.id);
                            setGoogleCampaign(item);

                            if (values.name === "") {
                                setFieldValue("name", item.name);
                                const mockEvent = {
                                    target: { value: item.name, name: "name" }
                                } as React.ChangeEvent<HTMLInputElement>;
                                handleNameBlur(mockEvent);
                            }
                        };

                        const handleMicrosoftCampaignChange = (item: IMicrosoftCampaign) => {
                            setFieldValue("microsoftCampaignId", item.id);
                            setMicrosoftCampaign(item);

                            if (values.name === "") {
                                setFieldValue("name", item.name);
                                const mockEvent = {
                                    target: { value: item.name, name: "name" }
                                } as React.ChangeEvent<HTMLInputElement>;
                                handleNameBlur(mockEvent);
                            }
                        };

                        const handleNameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
                            const { value, name } = event.target;
                            setFieldValue(name, value);
                            setFieldTouched(name, true);
                            const prePopulatedFields = getPrepopulatedFields(value);
                            if (prePopulatedFields) {
                                const inventoryFilters = determineInventoryFiltersPrefillFields(
                                    values.inventoryFilters,
                                    prePopulatedFields.inventoryFilters
                                );
                                setFieldValue("inventoryFilters", inventoryFilters);
                                setFieldValue("inventoryGroupFields", prePopulatedFields.inventoryGroupFields);
                                setFieldValue("inventoryGroupComparator", prePopulatedFields.inventoryGroupComparator);
                                setFieldValue("inventoryGroupCount", prePopulatedFields.inventoryGroupCount);
                            }
                        };

                        const handlePushToAdwords = () => {
                            if (!dynamicCampaign.id) {
                                return;
                            }

                            onPushCampaign(clientId, dynamicCampaign.id, { setErrors, setSubmitting, setStatus });
                        };

                        const handlePurgeCampaign = () => {
                            if (!dynamicCampaign.id || onPurgeCampaign === undefined) {
                                return;
                            }
                            setStatus({ message: "Purging Dynamic Campaign" });
                            onPurgeCampaign(clientId, dynamicCampaign.id, { setErrors, setStatus });
                        };

                        const handleUnlockCampaign = () => {
                            if (!dynamicCampaign.id || onUnlockCampaign === undefined) {
                                return;
                            }
                            setStatus({ message: "Unlocking Dynamic Campaign" });
                            onUnlockCampaign(clientId, dynamicCampaign.id, { setErrors, setStatus });
                        };

                        const canSave = [!dynamicCampaign.lockedAt, !isSubmitting, dirty, isValid].every(Boolean);
                        const isLocked = !!dynamicCampaign.lockedAt;
                        const canPush = [
                            dynamicCampaign.id,
                            !dynamicCampaign.lockedAt,
                            !isSubmitting,
                            !dirty,
                            isValid
                        ].every(Boolean);

                        mapErrorsToSection(errors, touched, collapsed);

                        return (
                            <div className="flex-col">
                                <Form>
                                    <div className="relative">
                                        {status && status.message && (
                                            <TableLoader className="" message={status.message} />
                                        )}

                                        <Section
                                            title="Campaign Information"
                                            onToggleCollapse={() => handleToggleCollapse("information")}
                                            isOpen={collapsed.information.isOpen}
                                            hasErrors={collapsed.information.hasErrors}
                                        >
                                            <div className="flex flex-col">
                                                <div className="flex flex-wrap items-end">
                                                    <div className="h-8 w-8 mb-1">
                                                        {googleCampaign && googleCampaign.id && (
                                                            <button
                                                                className="hover:text-red-500 text-gray-600 mb-1"
                                                                onClick={() => {
                                                                    handleAdWordsCampaignChange({
                                                                        id: null,
                                                                        name: ""
                                                                    } as any);
                                                                }}
                                                                type="button"
                                                                title="Unlink this dynamic campaign from this Google Campaign"
                                                            >
                                                                <UnchainIcon className="w-6" />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className={"flex-1 ml-2"}>
                                                        <SelectField
                                                            name="adwordsCampaignId"
                                                            label="Google Campaign"
                                                            options={googleCampaignOptions}
                                                            handleBlur={setFieldTouched}
                                                            errors={getFieldErrors("adwordsCampaignId", errors)}
                                                            value={googleCampaign ? String(googleCampaign.id) : ""}
                                                            handleChange={({ value }: IReactSelectValue) => {
                                                                handleAdWordsCampaignChange(googleCampaigns[value]);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={"flex-1 ml-4"}>
                                                        <TextField
                                                            name="name"
                                                            label="Name"
                                                            required={true}
                                                            handleChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>
                                                            ) => setFieldValue("name", event.target.value)}
                                                            handleBlur={handleNameBlur}
                                                            value={values.name}
                                                            touched={touched}
                                                            errors={getFieldErrors("name", errors)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap items-end">
                                                    <div className="h-8 w-8 mb-1">
                                                        {microsoftCampaign && microsoftCampaign.id && (
                                                            <button
                                                                onClick={() => {
                                                                    handleMicrosoftCampaignChange({
                                                                        id: null,
                                                                        name: ""
                                                                    } as any);
                                                                }}
                                                                type="button"
                                                                title="Unlink this dynamic campaign from this Microsoft Campaign"
                                                                className="hover:text-red-500 text-gray-600"
                                                            >
                                                                <UnchainIcon className="w-6" />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className={"flex-1 ml-2"}>
                                                        <SelectField
                                                            name="microsoftCampaignId"
                                                            label="Microsoft Campaign"
                                                            options={microsoftCampaignOptions}
                                                            handleBlur={setFieldTouched}
                                                            errors={getFieldErrors("microsoftCampaignId", errors)}
                                                            value={
                                                                microsoftCampaign ? String(microsoftCampaign.id) : ""
                                                            }
                                                            handleChange={({ value }: IReactSelectValue) => {
                                                                handleMicrosoftCampaignChange(
                                                                    microsoftCampaigns[value]
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={"flex-1 ml-4"}>
                                                        <SelectField
                                                            name="adRotation"
                                                            label="Ad Rotation"
                                                            options={adRotationOptions}
                                                            handleBlur={setFieldTouched}
                                                            errors={getFieldErrors("adRotation", errors)}
                                                            value={values.adRotation}
                                                            handleChange={({ value }: IReactSelectValue) => {
                                                                setFieldValue("adRotation", value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>

                                        <Section
                                            title="Inventory"
                                            onToggleCollapse={() => handleToggleCollapse("inventory")}
                                            isOpen={collapsed.inventory.isOpen}
                                            hasErrors={collapsed.inventory.hasErrors}
                                        >
                                            <div className="flex flex-col">
                                                <div className="w-1/2 flex flex-row">
                                                    <div className="w-1/3">
                                                        <SelectField
                                                            label="Primary Key"
                                                            name="primaryKeyField"
                                                            options={inventorySelectFields}
                                                            value={values.primaryKeyField}
                                                            handleChange={(item: any) =>
                                                                setFieldValue("primaryKeyField", item.value)
                                                            }
                                                            handleBlur={() => setFieldTouched("primaryKeyField", true)}
                                                        />
                                                    </div>
                                                    <div className="w-2/3 flex flex-row">
                                                        <RefreshSelector
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full pr-8">
                                                    <Label label="Conditional Logic" />
                                                    <p className="text-blue-400">Filter Inventory Items</p>
                                                    <InventoryFilters
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        errors={errors}
                                                        setFieldTouched={setFieldTouched}
                                                    />
                                                </div>
                                                <div className="w-1/2 pl-8" />
                                                <DynamicCampaignInventoryVisualizer
                                                    dynamicCampaign={dynamicCampaign as any}
                                                    disabled={dirty}
                                                />
                                            </div>
                                        </Section>

                                        <Section
                                            title="Ad Groups"
                                            onToggleCollapse={() => handleToggleCollapse("adGroup")}
                                            isOpen={collapsed.adGroup.isOpen}
                                            hasErrors={collapsed.adGroup.hasErrors}
                                        >
                                            <Label label="Default CPC" required={true} />
                                            <CurrencyInput
                                                disabled={isSubmitting}
                                                fieldName="maxCpc"
                                                touched={touched}
                                                errors={errors}
                                            />
                                            <Label label="Grouping" />
                                            <p className="text-blue-400">
                                                This will determine how inventory is grouped into Ad Groups
                                            </p>
                                            <InventoryGroup
                                                values={values}
                                                onChange={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                            />

                                            <Label label="Exclude Grouping" />
                                            <p className="text-blue-400">
                                                Remove Ad Groups where any vehicle matches the condition below
                                            </p>
                                            <InventoryGroupFilter
                                                comparator={values.inventoryGroupFilterComparator}
                                                field={values.inventoryGroupFilterField}
                                                value={values.inventoryGroupFilterValue}
                                                onChange={(filterValues) => {
                                                    setValues({ ...values, ...filterValues });
                                                }}
                                                errors={errors}
                                                setFieldTouched={setFieldTouched}
                                            />

                                            <Label className="mt-12" label="Conditional Bidding" />
                                            <p className="text-blue-400">Use this to bid up on specific models</p>
                                            <BidModifierList
                                                modifiers={values.adGroupBidModifiers}
                                                errors={
                                                    errors && "adGroupBidModifiers" in errors
                                                        ? errors.adGroupBidModifiers
                                                        : null
                                                }
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Section>

                                        <Section
                                            title="Keywords"
                                            onToggleCollapse={() => handleToggleCollapse("keywords")}
                                            isOpen={collapsed.keywords.isOpen}
                                            hasErrors={collapsed.keywords.hasErrors}
                                        >
                                            <Label label="Generator" />
                                            <Generator />

                                            <Label label="Keyword Templates" />
                                            <KeywordTemplates />

                                            <Label label="Keyword Bids" />
                                            <KeywordBids
                                                dynamicCampaignId={values.id}
                                                keywordBidModifiers={values.keywordBidModifiers}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Section>

                                        <ExpandedTextAdsSection
                                            title="Expanded Text Ads"
                                            onToggleCollapse={() => handleToggleCollapse("eta")}
                                            isOpen={collapsed.eta.isOpen}
                                            hasErrors={collapsed.eta.hasErrors}
                                            stripe={true}
                                            specialOfferPrioritization={!!values.prioritizeSpecialOffers}
                                            onToggleSpecialOfferPrioritization={toggleSpecialOfferPrioritization}
                                            hasSpecialOffers={values.specialOfferTemplates.length > 0}
                                        >
                                            {!!dynamicCampaign.id && (
                                                <SpecialOfferTemplateAds
                                                    values={values.specialOfferTemplates}
                                                    etas={values.expandedTextAdTemplates}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}

                                            <ExpandedTextAds
                                                values={values.expandedTextAdTemplates}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                setFieldTouched={setFieldTouched}
                                                editForm={!!dynamicCampaign.id}
                                            />
                                        </ExpandedTextAdsSection>
                                    </div>
                                    {status && status.success && (
                                        <Notification
                                            color="green"
                                            title={""}
                                            message={status.success}
                                            onClose={() => {
                                                setStatus(null);
                                            }}
                                            children={null}
                                        />
                                    )}
                                    {status && status.warning && (
                                        <Notification
                                            color="orange"
                                            title={""}
                                            message={status.warning}
                                            onClose={() => {
                                                setStatus(null);
                                            }}
                                            children={null}
                                        />
                                    )}
                                    {errors.noField && (
                                        <Notification
                                            color="red"
                                            title={""}
                                            message={errors.noField as string}
                                            onClose={() => {
                                                setErrors({});
                                            }}
                                            children={null}
                                        />
                                    )}
                                    <div className="flex justify-between mt-4">
                                        <div>
                                            <PurgeButton hidden={!dynamicCampaign.id} onConfirm={handlePurgeCampaign} />
                                            {isUserDeveloper && (
                                                <UnlockButton
                                                    hidden={
                                                        !dynamicCampaign.id || !dynamicCampaign.lockedAt || isSubmitting
                                                    }
                                                    onConfirm={handleUnlockCampaign}
                                                />
                                            )}
                                        </div>

                                        <div className="flex items-center mb-12">
                                            {!!dynamicCampaign.id && (
                                                <Link to="log" className={`${baseStyles} ${secondaryClasses}`}>
                                                    View Log
                                                </Link>
                                            )}
                                            <Link to=".." className={`${baseStyles} ${secondaryClasses} ml-4`}>
                                                Cancel
                                            </Link>

                                            <DynamicCampaignPushSummary
                                                dynamicCampaign={dynamicCampaign}
                                                canCampaignPush={canPush}
                                                pushDynamicCampaign={handlePushToAdwords}
                                                loading={isSubmitting}
                                            />

                                            <Button
                                                testId="save-button"
                                                type="submit"
                                                disabled={!canSave}
                                                styleType={PRIMARY_BUTTON}
                                            >
                                                {!isLocked && (
                                                    <div className="flex items-center">
                                                        <span className="ml-2">Save</span>
                                                    </div>
                                                )}
                                                {isLocked && (
                                                    <div className="flex items-center">
                                                        <LockIcon className="w-6 h-6" />
                                                        <span className="ml-2">Locked {lockedDiffForHumans}</span>
                                                    </div>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        );
                    }}
                />
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IAppState) => ({
    sotsToDetach: state.dynamicCampaigns.detach
});

export default connect(mapStateToProps, null)(DynamicCampaignForm);
