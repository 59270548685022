interface EnvConfig {
    API_URL: string;
    BUGSNAG_STAGE: string;
    BUGSNAG_KEY: string;
    PUSHER_KEY: string;
    PUSHER_CLUSTER: string;
    GA_TRACKING_PROFILE?: string;
}
type ConfigurationsType = {
    prod: EnvConfig;
    local: EnvConfig;
    staging: EnvConfig;
};
enum Environments {
    prod = "prod",
    staging = "staging",
    local = "local"
}
const OVERRIDE_KEY = "overrideEnv";

const configurations: ConfigurationsType = {
    prod: {
        API_URL: "https://fuel-api.dealerinspire.com",
        BUGSNAG_STAGE: "production",
        BUGSNAG_KEY: "da6d054ded6c99033db93fdccb18f20c",
        PUSHER_KEY: "7c08f6434f619cc7233e",
        PUSHER_CLUSTER: "us2",
        GA_TRACKING_PROFILE: "UA-140809191-1"
    },
    local: {
        API_URL: "https://fuel-api-dev.dealerinspire.com",
        BUGSNAG_STAGE: "local",
        BUGSNAG_KEY: process.env.REACT_APP_BUGSNAG as string,
        PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY as string,
        PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER as string,
        GA_TRACKING_PROFILE: process.env.REACT_APP_GA_TRACKING_PROFILE as string
    },
    staging: {
        API_URL: "https://fuel-api-test.dealerinspire.com",
        BUGSNAG_STAGE: "test",
        BUGSNAG_KEY: "da6d054ded6c99033db93fdccb18f20c",
        PUSHER_KEY: "5a48cce7f087cbd92f33",
        PUSHER_CLUSTER: "us2",
        GA_TRACKING_PROFILE: undefined
    }
};
class Config {
    config: EnvConfig;
    env: Environments;
    originalEnv: Environments;

    constructor() {
        this.env = this.determineEnv();
        this.originalEnv = this.env;
        this.checkForOverride();
        this.config = this.buildConfiguration();
    }

    determineEnv(): Environments {
        switch (window.location.hostname) {
            case "localhost":
            case "fuel-dev.dealerinspire.com":
                return Environments.local;
            case "test.fuel.dealerinspire.com":
            case "fuel-test.dealerinspire.com":
                return Environments.staging;
            default:
                return Environments.prod;
        }
    }

    checkForOverride() {
        //check url for override
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(OVERRIDE_KEY)) {
            const newEnv = urlParams.get(OVERRIDE_KEY) as Environments;
            this.overrideEnv(newEnv);
        }

        // grab env from session if set
        if (sessionStorage.getItem(OVERRIDE_KEY) !== null) {
            this.env = sessionStorage.getItem(OVERRIDE_KEY) as Environments;
        }
    }

    buildConfiguration(): EnvConfig {
        let config: EnvConfig = configurations[this.originalEnv];
        if (this.env !== this.originalEnv) {
            const overrideConfig: EnvConfig = configurations[this.env];
            const overrides = {
                API_URL: overrideConfig["API_URL"],
                PUSHER_KEY: overrideConfig["PUSHER_KEY"],
                PUSHER_CLUSTER: overrideConfig["PUSHER_CLUSTER"]
            };

            return { ...config, ...overrides };
        }

        return config;
    }

    get(key: keyof EnvConfig): string {
        return this.config[key] || "";
    }

    set(key: keyof EnvConfig, value: string) {
        this.config[key] = value;
    }

    overrideEnv(env: Environments) {
        sessionStorage.setItem(OVERRIDE_KEY, env);
    }

    removeOverride() {
        sessionStorage.removeItem(OVERRIDE_KEY);
        this.env = this.originalEnv;
    }
}

export default (() => {
    let config: Config;
    const createInstance = () => new Config();

    return {
        getInstance: () => {
            if (!config) {
                config = createInstance();
            }

            return config;
        }
    };
})();
