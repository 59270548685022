import { reportDownloadClicked } from "../../../../actions/facebookActions";
import trackEvent from "../../../../utils/Analytics/trackEvent";

function* downloadReportClicked(action: ReturnType<typeof reportDownloadClicked>) {
    yield trackEvent({
        category: "Facebook Report Index Page",
        action: `facebook_report_download_clicked_${action.clientId}`,
        label: "Facebook Report Download"
    });
}

export default {
    downloadReportClicked
};
