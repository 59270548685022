import { useEffect } from "react";
import useLocalStorage from "./useLocalStorage";
import { CURRENT_USER } from "../api";
import IUser from "../interfaces/IUser";
export function useCurrentUser(currentUser: IUser | null = null) {
    const [user, setUser] = useLocalStorage(CURRENT_USER, currentUser);

    useEffect(() => {
        // tslint:disable-next-line:no-unused-expression
        currentUser !== null && setUser(currentUser);
    }, [currentUser]);
    return user;
}
