import React, { FunctionComponent, ChangeEvent, useEffect, useState } from "react";
import IClient from "../../../../interfaces/IClient";
import TextField from "../../../Shared/Form/Blocks/TextField";
import Select from "react-select";
import useInventorySearchAds from "../../../../hooks/inventorySearchAds/useInventorySearchAds";
import Button, { PRIMARY_BUTTON } from "../../../Shared/Button";
import Errors from "../../../Shared/Form/Elements/Errors";
interface IGeneral {
    client: IClient;
    onValueChange(event: ChangeEvent<HTMLInputElement>): void;
    onCreateAccountClick(event: MouseEvent): void;
    hasAccount?: boolean;
    allowEdit?: boolean;
    errors: {
        [key: string]: string[] | undefined;
    };
}

const Koddi: FunctionComponent<IGeneral> = ({
    client,
    onValueChange,
    onCreateAccountClick,
    errors,
    allowEdit = false
}) => {
    const [editingKoddiId, setEditingKoddiId] = useState(false);

    const { allPossibleChannelsAndPublishedInSelectFormat, channelsAndPublishers, selectedChannelsAndPublishers } =
        useInventorySearchAds();

    const handleChannelsAndPublishersChange = (inputValue: any) => {
        const value = inputValue && Array.isArray(inputValue) ? inputValue.map((val) => val.value) : inputValue;
        onValueChange({
            target: {
                name: "channelsandpublishers",
                value
            }
        } as ChangeEvent<HTMLInputElement>);
    };

    const handleKoddiIdChange = (inputValue: any) => {
        setEditingKoddiId(true);
        onValueChange(inputValue);
    };

    const { createKoddiRequested, name, carsSellerId, salesforceId, koddiId, isaAdvertiserName } = client;

    const canCreate = !!carsSellerId && !!salesforceId && !koddiId;

    useEffect(() => {
        if (createKoddiRequested) {
            const defaultIsaAdvertiserName = `${name}${carsSellerId ? ` | ${carsSellerId}` : ""}${
                salesforceId ? ` | ${salesforceId}` : ""
            }`;
            onValueChange({
                target: { name: "isaAdvertiserName", value: defaultIsaAdvertiserName }
            } as ChangeEvent<HTMLInputElement>);
            return;
        }
        if (canCreate) {
            onValueChange({
                target: { name: "isaAdvertiserName", value: "" }
            } as ChangeEvent<HTMLInputElement>);
        }
    }, [createKoddiRequested, name, carsSellerId, salesforceId, canCreate, onValueChange]);

    const renderKoddiBlock = () => {
        return (
            <>
                <label className="pt-4 block uppercase tracking-wider text-blue-800 text-base ">
                    <span>Advertiser Name:</span>
                </label>
                <span className="text-lg text-red">{isaAdvertiserName} &nbsp; </span>
                <label className="pt-4 block uppercase tracking-wider text-blue-800 text-base ">
                    <span>Channels and Publishers:</span>
                </label>
                <span className="text-lg text-red">Sponsored Listings - Cars</span>
            </>
        );
    };

    if (!canCreate && createKoddiRequested)
        return (
            <Errors
                errors={[
                    "Please ensure both a Cars.com Seller ID (CCID) and Sales Force Id are entered in the General tab, then save the client to create an ISA Advertiser"
                ]}
            />
        );

    if (allowEdit) {
        if (editingKoddiId || koddiId) {
            return (
                <>
                    <TextField
                        label="Koddi ID:"
                        value={String(koddiId)}
                        handleChange={handleKoddiIdChange}
                        errors={errors.koddiId}
                        name="koddiId"
                        className="-mt-8"
                    />
                    {renderKoddiBlock()}
                </>
            );
        }
        if (createKoddiRequested) {
            return (
                <div className="flex flex-wrap justify-between" data-test-id="koddi-ui">
                    <div className="w-full lg:w-1/2 lg:pr-4">
                        <TextField label="Name" name="name" value={name || ""} required readOnly />

                        <TextField
                            label="Cars Seller ID (CCID)"
                            name="carsSellerId"
                            value={client.carsSellerId?.toString() || ""}
                            required
                            readOnly
                        />

                        <TextField
                            label="Salesforce ID"
                            name="salesforceId"
                            value={salesforceId || ""}
                            required
                            readOnly
                        />

                        <TextField
                            label="Advertiser Name"
                            name="isaAdvertiserName"
                            value={isaAdvertiserName || ""}
                            handleChange={onValueChange}
                            errors={errors?.isaAdvertiserName}
                            required
                        />

                        <div className="block uppercase tracking-wider text-blue-800 text-base mb-2 mt-4">
                            Channels and publishers
                        </div>
                        <Select
                            className="text-base"
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            isLoading={!channelsAndPublishers}
                            placeholder={
                                !channelsAndPublishers
                                    ? "Loading Channels and publishers"
                                    : "Select Channels and publishers"
                            }
                            isDisabled={true}
                            value={selectedChannelsAndPublishers}
                            onChange={handleChannelsAndPublishersChange}
                            name="channelsandpublishers"
                            options={allPossibleChannelsAndPublishedInSelectFormat}
                        />
                    </div>
                </div>
            );
        }
        return (
            <Button
                data-test-id="create-account"
                styleType={PRIMARY_BUTTON}
                onClick={onCreateAccountClick}
                styles="my-4"
            >
                <span className="ml-2">Create Account</span>
            </Button>
        );
    }

    return (
        <>
            <label className="block uppercase tracking-wider text-blue-800 text-base ">
                <span>Koddi ID:</span>
            </label>
            <span className="text-lg text-red">{koddiId} &nbsp;</span>
            {renderKoddiBlock()}
        </>
    );
};

export default Koddi;
