const formatUrlWithParts = (url: string, parts: string) => {
    if (url.indexOf("?") === -1 && parts.length > 0) {
        return url + "?" + parts;
    }
    return url + parts;
};

const buildUrl = (base: string, options: any) => {
    let parts = "";

    for (let key in options) {
        let possiblyNested = options[key];

        if (typeof possiblyNested === "object" && !Array.isArray(possiblyNested)) {
            for (let value in options[key]) {
                let valueToQuery = options[key][value];

                if (typeof valueToQuery !== "string") {
                    valueToQuery = JSON.stringify(valueToQuery);
                }

                parts +=
                    (parts !== "" ? "&" : "") +
                    (encodeURI(key) + "[" + encodeURI(value) + "]=" + encodeURI(valueToQuery));
            }
        } else if (Array.isArray(possiblyNested)) {
            if (possiblyNested.length === 0) {
                continue;
            }
            parts += (parts !== "" ? "&" : "") + encodeURI(key) + "=" + possiblyNested.join(",");
        } else if (options[key] !== null && options[key] !== undefined && options[key] !== "") {
            parts += (parts !== "" ? "&" : "") + (encodeURI(key) + "=" + encodeURI(options[key]));
        }
    }
    return formatUrlWithParts(base, parts);
};

export default buildUrl;
