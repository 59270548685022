import React, { Fragment, useEffect } from "react";
import PageTitle from "../PageTitle/PageTitle";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import { Link } from "react-router-dom";
import * as Interfaces from "../../../interfaces/shared/IItemCollection";

const CollectionList: React.FC<Interfaces.ICollectionListProps> = ({
    listTitle,
    collections,
    loaded,
    fetchCollections
}) => {
    useEffect(() => {
        fetchCollections();
    }, []);

    if (!loaded) {
        return <FullPageLoader message="Loading Collection Lists..." />;
    }

    return (
        <Fragment>
            <div className="px-4">
                <PageTitle title={listTitle} />
                <table className="w-full text-center">
                    <thead className="bg-blue-500 border-bottom-thin border-medium-gray text-white">
                        <tr>
                            <th className="text-center">
                                <div className="text-lg font-medium py-2">List</div>
                            </th>
                            <th className="text-center">
                                <div className="text-lg font-medium py-2">Actions</div>
                            </th>
                        </tr>
                    </thead>
                    {collections ? (
                        <tbody className="text-base">
                            {Object.values(collections).map((collection) => (
                                <tr
                                    key={collection.id}
                                    className="bg-gray-100 hover:bg-gray-200 border border-gray-400"
                                >
                                    <td className="py-4">{collection.name}</td>
                                    <td className="py-4">
                                        <Link to={`${collection.id}`}>
                                            <span>View</span>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tr className="bg-gray-100 hover:bg-gray-200 border border-gray-400">
                            <td colSpan={2} className="py-8 text-center text-base">
                                No Results
                            </td>
                        </tr>
                    )}
                </table>
            </div>
        </Fragment>
    );
};

export default CollectionList;
