import React from "react";
import ReactTable, { ReactTableDefaults, Instance, TableProps } from "react-table";
import TableLoader from "./TableLoader";

interface ILoadingProps {
    loadingMessage: string;
    loadingTable: boolean;
}

const Table: React.FC<ILoadingProps & Partial<TableProps>> = ({
    loadingMessage,
    loadingTable,
    resizable = true,
    data,
    columns,
    column = { ...ReactTableDefaults.column, minWidth: 50 },
    loading = loadingTable,
    LoadingComponent,
    className = "",
    ...props
}) => {
    return (
        <ReactTable
            minRows={3}
            data={data}
            resizable={resizable}
            columns={columns}
            className={`v1 -striped rounded overflow-hidden -highlight ${className ? className : ""}`}
            column={{ ...ReactTableDefaults.column, minWidth: 50 }}
            LoadingComponent={() => {
                if (loadingTable) {
                    return <TableLoader message={loadingMessage} />;
                }

                return null;
            }}
            loading={loadingTable}
            {...props}
        />
    );
};

export default Table;
