import React from "react";
import CampaignTableRow from "./CampaignTableRow";
import { IExclusionsList, IMappedExclusionById } from "../../../interfaces/Exclusions";
import { Formik, FormikProps, FormikActions } from "formik";
import IEntity from "../../../interfaces/IEntity";
import {
    IUpdatedFormValues,
    INegativeKeywordColl,
    INegativeKeywordByCampaignId
} from "../../../interfaces/NegativeKeywordColl";
import { mapCheckedValues, mapUncheckedValues } from "../../../utils/Shared";
import Button from "../Button";
import LoaderSpinner from "../Loaders/LoaderSpinner";
import Message from "../../../utils/Message";

interface IProps {
    columnName: string;
    campaigns: any;
    formValues: IMappedExclusionById | INegativeKeywordByCampaignId;
    checkboxOptions: IEntity<IExclusionsList | INegativeKeywordColl>;
    clientId: number;
    onApplyDefaults(
        values: INegativeKeywordByCampaignId | IMappedExclusionById,
        initialValues: INegativeKeywordByCampaignId | IMappedExclusionById,
        campaigns: any,
        setFieldValue: any
    ): void;
    onUpdateValue(
        data: IUpdatedFormValues,
        formikActions: FormikActions<INegativeKeywordByCampaignId | IMappedExclusionById>,
        clientId: number
    ): void;
    isLoading?: boolean;
}

const CampaignTable: React.FunctionComponent<IProps> = ({
    columnName,
    campaigns,
    formValues,
    checkboxOptions,
    clientId,
    onUpdateValue,
    onApplyDefaults,
    isLoading = false
}) => {
    const handleSubmitForm = (
        values: INegativeKeywordByCampaignId | IMappedExclusionById,
        formikActions: FormikActions<INegativeKeywordByCampaignId | IMappedExclusionById>
    ) => {
        const checkedValues = mapCheckedValues(values, clientId);
        const unCheckedValues = mapUncheckedValues(formValues, values);
        const valuesToUpdate = { checkedValues, unCheckedValues };
        onUpdateValue(valuesToUpdate, formikActions, clientId);
    };

    return (
        <>
            <div className="bg-white p-4 rounded shadow mb-4">
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    onSubmit={(
                        values: INegativeKeywordByCampaignId | IMappedExclusionById,
                        formikActions: FormikActions<INegativeKeywordByCampaignId | IMappedExclusionById>
                    ) => {
                        handleSubmitForm(values, formikActions);
                    }}
                    render={({
                        isSubmitting,
                        dirty,
                        isValid,
                        handleSubmit,
                        values,
                        setFieldValue,
                        errors
                    }: FormikProps<INegativeKeywordByCampaignId | IMappedExclusionById>) => {
                        return (
                            <>
                                <div className="flex flex-row justify-between">
                                    <Button
                                        type="button"
                                        styleType={"primary"}
                                        styles="self-end mb-4 text-base"
                                        onClick={() => onApplyDefaults(values, formValues, campaigns, setFieldValue)}
                                    >
                                        Apply Defaults
                                    </Button>
                                    {isLoading && <LoaderSpinner className="" />}
                                </div>
                                <div className="text-base">
                                    <form onSubmit={handleSubmit} className="relative">
                                        <table className="table-fixed w-full">
                                            <thead>
                                                <tr>
                                                    <th className="border px-4 py-2">Campaign</th>
                                                    <th className="border px-4 py-2">{columnName}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(campaigns).map((key) => (
                                                    <CampaignTableRow
                                                        key={key}
                                                        checkboxOptions={checkboxOptions}
                                                        campaign={campaigns[key]}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="flex flex-right items-center">
                                            <Button
                                                disabled={isSubmitting || !dirty || !isValid}
                                                type="submit"
                                                styleType={"primary"}
                                                styles={`mt-4 ${
                                                    isSubmitting || !isValid ? "opacity-50 " : "hover-bg-blue-600"
                                                }`}
                                            >
                                                Save
                                            </Button>
                                            {isLoading || (isSubmitting && <LoaderSpinner className="mt-4 ml-5" />)}
                                        </div>
                                    </form>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        </>
    );
};

export default CampaignTable;
