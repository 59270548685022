export default {
    REQUEST_LABELS: "REQUEST_LABELS",
    REQUEST_REFRESH_LABELS: "REQUEST_REFRESH_LABELS",
    REQUEST_LABELS_SUCCESS: "REQUEST_LABELS_SUCCESS",
    REQUEST_LABELS_FAIL: "REQUEST_LABELS_FAIL",

    REQUEST_REFRESH_MICROSOFT_LABELS: "REQUEST_REFRESH_MICROSOFT_LABELS",
    REQUEST_MICROSOFT_LABELS: "REQUEST_MICROSOFT_LABELS",
    REQUEST_MICROSOFT_LABELS_FAIL: "REQUEST_MICROSOFT_LABELS_FAIL",
    REQUEST_MICROSOFT_LABELS_SUCCESS: "REQUEST_MICROSOFT_LABELS_SUCCESS"
};
