import React from "react";

interface IProps {
    errors: string | string[] | null;
}

const FieldErrors: React.FunctionComponent<IProps> = ({ errors }) => {
    if (!errors) {
        return null;
    }
    if (typeof errors === "string") {
        return <div className="text-red-600 pb-4">{errors} </div>;
    }
    return (
        <React.Fragment>
            {errors.map((error) => (
                <div key={error} className="text-red-600 pb-4">
                    {errors}
                </div>
            ))}
        </React.Fragment>
    );
};

export default FieldErrors;
