import { camelize, decamelizeKeys, pascalize } from "humps";
import { FormikErrors, FormikTouched } from "formik";
import { parseErrors } from "../middleware/api";
import {
    IInventoryFilter,
    IKeywordOptions,
    IDraggable,
    IExpandedTextAdTemplate,
    ISpecialOfferTemplateAd
} from "../interfaces/DynamicCampaigns/IDynamicCampaign";
import { isEmpty, reduce, toNumber, uniqueId } from "lodash";
import { call, CallEffect } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import uuid from "uuid";
import { IInventoryFieldValues } from "../interfaces/InventoryFields";
import { ISampleData } from "../interfaces/DynamicCampaigns/ITemplatePart";
import IReactSelectValue from "../interfaces/IReactSelectValue";
import {
    InventoryFields,
    InformationFields,
    AdGroupFields,
    EtaFields,
    KeywordFields
} from "../interfaces/DynamicCampaigns/IDynamicCampaign";
import ICollapsible from "../interfaces/ICollapsible";
import IDynamicCampaignFormValue from "../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import IEntity from "../interfaces/IEntity";
import IMicrosoftCampaign from "../interfaces/IMicrosoftCampaign";
import IGoogleCampaign from "../interfaces/IGoogleCampaign";
import { IEtaTemplateBoilerplate } from "../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";

interface IItems {
    [key: string]: any;
}

interface IGeneratorInput {
    value: string;
}

/**
 * Q: What on earth is this method?
 * A: It's an artifact of an unknown backwards incomparabilty between ETAs and RSAs
 * Its sole purpose is to convert h1, h2, h3, d1, d2, etc
 * into their respective pinned ad field counterpart.
 * @param field
 */
export const mapOldPartFieldToPinnedPartAndPartFields = (
    field: string
): {
    field: string;
    pinnedField: string | null;
} => {
    let pinnedField = null;

    if (field.startsWith("h") || field.startsWith("d")) {
        pinnedField = field;
    }
    // Reset the field name
    if (field.startsWith("h")) {
        field = "headline";
    } else if (field.startsWith("d")) {
        field = "description";
    }

    return {
        field,
        pinnedField
    };
};

export const mapInputFieldsToApiRequest = (data: { [key: string]: any }) => {
    return decamelizeKeys(
        Object.keys(data).reduce((acc, key: string) => {
            acc[key] = data[key];
            if (data[key] === "") {
                acc[key] = null;
            }
            return acc;
        }, {} as any)
    );
};

export const wrapper = function* (func: any): Generator<CallEffect, object, { result: object }> {
    let result = {};
    try {
        const response = yield func;
        result = [false, response.result];
    } catch (e) {
        result = [parseErrors(e.errors)];
    }

    return result;
};

export const characterLimit = (field: string): number => {
    // This will handle description1 and description2 from the dynamic campaign form
    // as well as description1[0] and description2[30] from the SOT form.
    if (field.startsWith("description")) {
        return 90;
    }

    return 30;
};

export const mapErrorResponses = (responses: any, attemptedValue: any) => {
    if (!responses.length) {
        return [attemptedValue];
    }
    const index = responses.findIndex((response: any) => response.id === attemptedValue.id);
    if (index >= 0) {
        responses[index] = { ...responses[index], ...attemptedValue };
    } else {
        responses.push(attemptedValue);
    }
    return responses;
};

export const mapSuccessResponses = (responses: any[], successfullResponse: any, update: object) => {
    if (!responses.length) {
        return [{ ...successfullResponse, ...update }];
    }
    const index = responses.findIndex((response) => response.id === successfullResponse.id);
    if (index >= 0) {
        responses[index] = { ...responses[index], ...update };
    } else {
        responses.push({ ...successfullResponse, ...update });
    }
    return responses;
};

export const orderItems = <T extends IDraggable>(dragIndex: number, hoverIndex: number, items: T[]): T[] => {
    if (dragIndex < hoverIndex) {
        return items.map((item: T, i: number) => {
            if (i === dragIndex) {
                item.order = i + 1;
                item.dirty = true;
            } else if (i === hoverIndex) {
                item.order = i - 1;
                item.dirty = true;
            }
            return item;
        });

        // moving up
    } else if (dragIndex > hoverIndex) {
        return items.map((item: T, i: number) => {
            if (i === dragIndex) {
                item.order = i - 1;
                item.dirty = true;
            } else if (i === hoverIndex) {
                item.order = i + 1;
                item.dirty = true;
            }
            return item;
        });
    } else {
        return items;
    }
};

interface IFormItem {
    dirty?: boolean;
    new?: boolean;
    deleted?: boolean;
    id?: number;
}

export const mapCrudOperations = (items: any[], url: string, updateProp?: { [key: string]: number }) => {
    return items.map((item: IFormItem) => {
        if (!item.dirty) {
            return [false, item];
        }

        if (!isEmpty(updateProp)) {
            item = { ...item, ...updateProp };
        }

        if (item.new) {
            // just remove the new and deleted ones all together no need to do request
            if (item.deleted) {
                return [false, item];
            }
            return wrapper(call(callApi, url, {}, "POST", mapInputFieldsToApiRequest(item)));
        }
        if (item.deleted) {
            return wrapper(call(callApi, `${url}/${item.id}`, {}, "DELETE"));
        }
        return wrapper(call(callApi, `${url}/${item.id}`, {}, "PATCH", mapInputFieldsToApiRequest(item)));
    });
};

export const mapApiResponses = (items: IItems, errorKey: string) => {
    const [errors, saved] = items.reduce(
        ([errors, responses]: [IItems, Array<{}>], [error, response]: [{}, {}], index: number) => {
            if (error) {
                errors[`${errorKey}[${index}]`] = error;
            } else if (!isEmpty(response)) {
                responses.push(response);
            }
            return [errors, responses];
        },
        [{}, []]
    );
    return [Object.entries(errors).length > 0 ? errors : false, saved];
};

export const validateUrl = (value: string): string | undefined => {
    return value ? undefined : "Required";
};

export const validatePath = (value: string): string | undefined => {
    const slashCount = value.split("/").length;
    let error;
    if (!value) {
        error = "Required";
    } else if (slashCount > 2) {
        error = 'Path may only contain a single "/".';
    }
    return error;
};

export default {
    mapInputFieldsToApiRequest,
    validateUrl
};

const buildKeyword = (label: string, line: string): string => {
    switch (label) {
        case "broad":
            return `${line}`;
        case "phrase":
            return `"${line}"`;
        case "exact":
            return `[${line}]`;
        default:
            return "";
    }
};

export const generatePreview = (
    keywordTypes: IKeywordOptions,
    keywordValues: IGeneratorInput[],
    preview: string = ""
): string => {
    return (preview +=
        Object.entries(keywordTypes)
            .map(([label, isSelected]) =>
                isSelected ? keywordValues.map((line) => buildKeyword(label, line.value)).join("\n") : ""
            )
            .join("\n")
            .trim() + "\n\n");
};

const prefillAdGroupOptions = {
    dynamicUsedIndividual: {
        inventoryGroupFields: ["Model"],
        inventoryGroupComparator: "EQUAL",
        inventoryGroupCount: 1
    },
    dynamicUsedResult: {
        inventoryGroupFields: ["Model"],
        inventoryGroupComparator: "GREATER_THAN",
        inventoryGroupCount: 1
    },
    dynamicNew: {
        inventoryGroupFields: ["Model"],
        inventoryGroupComparator: "GREATER_THAN",
        inventoryGroupCount: 1
    }
};

const prefillInventoryFilters = (brand?: string) => {
    const options = {
        dynamicUsedIndividual: {
            inventoryFilters: [
                {
                    id: uuid.v1(),
                    new: true,
                    field: "Type",
                    comparator: "IN",
                    value: "Pre-Owned, Certified Pre-Owned"
                }
            ]
        },
        dynamicUsedResult: {
            inventoryFilters: [
                {
                    id: uuid.v1(),
                    new: true,
                    field: "Type",
                    comparator: "IN",
                    value: "Pre-owned, Certified Pre-Owned"
                }
            ]
        },
        dynamicNew: {
            inventoryFilters: [
                {
                    id: uuid.v1(),
                    new: true,
                    field: "Type",
                    comparator: "EQUAL",
                    value: "New"
                }
            ]
        }
    };
    if (brand) {
        options.dynamicNew.inventoryFilters.push({
            id: uuid.v1(),
            new: true,
            field: "Make",
            comparator: "EQUAL",
            value: brand
        });
    }
    return options;
};

interface IGetPrepopulated {
    inventoryFilters: IInventoryFilter[];
    inventoryGroupFields: string[];
    inventoryGroupComparator: string;
    inventoryGroupCount: string | number;
}

export const getPrepopulatedFields = (name: string): null | IGetPrepopulated => {
    if (name === "Dynamic Used Individual") {
        return {
            ...prefillAdGroupOptions.dynamicUsedIndividual,
            ...prefillInventoryFilters().dynamicUsedIndividual
        };
    }

    if (name === "Dynamic Used Results") {
        return {
            ...prefillAdGroupOptions.dynamicUsedResult,
            ...prefillInventoryFilters().dynamicUsedResult
        };
    }
    if (name.startsWith("Dynamic New")) {
        const fields = {
            ...prefillAdGroupOptions.dynamicNew,
            ...prefillInventoryFilters().dynamicNew
        };

        const blackList = ["New", "Lease", "Intent"];
        const lastWord = name.split(" ").pop();

        if (blackList.includes(lastWord!)) {
            return fields;
        }

        return {
            ...fields,
            ...prefillInventoryFilters(lastWord).dynamicNew
        };
    }
    return null;
};

export const determineInventoryFiltersPrefillFields = (
    currentValues: IInventoryFilter[] | null,
    potentialValues: IInventoryFilter[]
): IInventoryFilter[] => {
    if (!currentValues) {
        return potentialValues;
    }
    const newValues = potentialValues.filter((potentialValue) => {
        let doesExist = false;
        currentValues.forEach(({ deleted, field, comparator, value: fieldValue }) => {
            if (
                !deleted &&
                potentialValue.field === field &&
                potentialValue.comparator === comparator &&
                potentialValue.value === fieldValue
            ) {
                doesExist = true;
            }
        });
        return !doesExist;
    });
    return [...currentValues, ...newValues];
};

export const parseInventorySampleData = (inventoryFieldValues?: IInventoryFieldValues | null): ISampleData | {} => {
    if (!inventoryFieldValues || isEmpty(inventoryFieldValues)) {
        return {};
    }
    return Object.keys(inventoryFieldValues).reduce((acc, curr) => {
        const values = inventoryFieldValues[curr];
        if (values && values[0] !== "") {
            acc[pascalize(curr)] = values[0];
        }
        return acc;
    }, {} as { [key: string]: string | number });
};

export const buildSelectOptions = (
    fieldValue: string | null | undefined,
    inventoryOptions: IInventoryFieldValues
): IReactSelectValue[] => {
    if (!fieldValue) {
        return [];
    }
    const key = camelize(fieldValue);
    if (!inventoryOptions || !inventoryOptions[key]) {
        return [];
    }
    return (inventoryOptions[key] as string[]).map((value) => {
        return { label: value, value };
    });
};

export const updateSelectInput = (currentValue: string | null, inputValue: string): string => {
    if (!currentValue) {
        return inputValue;
    }
    return [...new Set([...currentValue.split(","), inputValue])].join(",");
};

export const formatToReactSelectValue = (initialValue: string | null): IReactSelectValue[] => {
    if (!initialValue) {
        return [];
    }
    return String(initialValue)
        .split(",")
        .map((value) => ({ label: value, value }));
};

export const formatToCsv = (values?: readonly IReactSelectValue[] | IReactSelectValue | null): string => {
    if (!values) {
        return "";
    }
    // it could be a single react value
    if (!Array.isArray(values)) {
        return (values as IReactSelectValue).value;
    }
    // the value could be a number and would appear as a duplicate
    const stringValues = values.map(({ value }) => String(value));
    // need to remove duplicates when selecting values
    return [...new Set(stringValues)].join(",");
};

const inventoryFields: InventoryFields[] = ["primaryKeyField", "refreshType", "inventoryFilters"];
const informationFields: InformationFields[] = ["name", "adwordsCampaignId", "microsoftCampaignId", "adRotation"];
const adGroupFields: AdGroupFields[] = [
    "maxCpc",
    "inventoryGroupFields",
    "inventoryGroupComparator",
    "inventoryGroupCount",
    "inventoryGroupFilterField",
    "inventoryGroupFilterComparator",
    "inventoryGroupFilterValue",
    "adGroupBidModifiers"
];
const keywordFields: KeywordFields[] = ["keywordTemplateRelationships", "keywordBidModifiers"];
const etaFields: EtaFields[] = ["expandedTextAdTemplates"];

export const hasErrors = (
    fields: InformationFields[] | InventoryFields[] | AdGroupFields[] | KeywordFields[] | EtaFields[],
    errors: string | FormikErrors<IDynamicCampaignFormValue> | undefined,
    touched: FormikTouched<IDynamicCampaignFormValue>
) => {
    return fields.some((name: string) => Boolean(typeof errors === "object" && errors[name] && touched[name]));
};

export const mapErrorsToSection = async (
    errors: string | FormikErrors<IDynamicCampaignFormValue> | undefined,
    touched: FormikTouched<IDynamicCampaignFormValue>,
    sections: ICollapsible
) => {
    sections.information.hasErrors = hasErrors(informationFields, errors, touched);
    sections.inventory.hasErrors = hasErrors(inventoryFields, errors, touched);
    sections.adGroup.hasErrors = hasErrors(adGroupFields, errors, touched);
    sections.keywords.hasErrors = hasErrors(keywordFields, errors, touched);
    sections.eta.hasErrors = hasErrors(etaFields, errors, touched);
};

export const flatten = (source: any, flattened: any = {}, keySoFar = "") => {
    function getNextKey(key: any) {
        return `${keySoFar}${keySoFar ? "." : ""}${key}`;
    }
    if (typeof source === "object") {
        for (const key in source) {
            flatten(source[key], flattened, getNextKey(key));
        }
    } else {
        flattened[keySoFar] = source;
    }
    return flattened;
};

export const transformKeywordTemplateErrors = (templateErrors: any) => {
    if (templateErrors === false) {
        return false;
    }
    return reduce(
        templateErrors,
        (result, value, key) => {
            if (typeof key === "string" && key.indexOf("keywords.") >= 0) {
                let transformedValue: string;
                if (value.indexOf("field") >= 0) {
                    transformedValue = value.replace(
                        `The ${key} field is`,
                        `Keywords on line ${toNumber(key.substr(9)) + 1} are`
                    );
                } else {
                    transformedValue = value.replace(`The ${key}`, `Keywords on line ${toNumber(key.substr(9)) + 1}`);
                }

                if (result.hasOwnProperty("keywords") && typeof result.keywords === "string") {
                    return {
                        ...result,
                        keywords: [result.keywords, transformedValue]
                    };
                }

                return {
                    ...result,
                    keywords: result.hasOwnProperty("keywords")
                        ? [...result.keywords, transformedValue]
                        : [transformedValue]
                };
            }

            return { ...result, [`${key}`]: value };
        },
        {} as any
    );
};

export const getFieldErrors = (
    field: string,
    errors: FormikErrors<IDynamicCampaignFormValue>
): Array<string | FormikErrors<any>> => {
    const errs = errors[field];
    if (!errs) {
        return [];
    } else {
        return [errs];
    }
};

export const formatCampaignsAsOptions = (
    campaigns: IEntity<IMicrosoftCampaign> | IEntity<IGoogleCampaign>
): IReactSelectValue[] => {
    if (!campaigns || isEmpty(campaigns)) {
        return [];
    }
    return Object.values(campaigns).map((value) => ({
        label: value.name,
        value: String(value.id)
    }));
};

export const newEtaTemplateFromExistingOrBoilerplate = (
    original: IExpandedTextAdTemplate | IEtaTemplateBoilerplate | ISpecialOfferTemplateAd,
    order?: number
) => {
    const isExpandedTextAdTemplate = (
        template: IExpandedTextAdTemplate | IEtaTemplateBoilerplate | ISpecialOfferTemplateAd
    ): template is IExpandedTextAdTemplate | ISpecialOfferTemplateAd => {
        return (template as IExpandedTextAdTemplate).label !== undefined;
    };

    return {
        id: uuid.v1(),
        new: true,
        dirty: true,
        order,
        label: isExpandedTextAdTemplate(original) ? original.label : original.name,
        parts:
            original.parts
                ?.filter((p) => !p.deleted)
                .map((part) => {
                    const newPartConditionals = part.conditionals?.map((conditional) => ({
                        id: uniqueId("temp-"),
                        value: conditional.value,
                        field: conditional.field,
                        comparator: conditional.comparator,
                        new: true,
                        dirty: true,
                        valid: true,
                        deleted: false
                    }));
                    return {
                        id: uuid.v1(),
                        new: true,
                        dirty: true,
                        field: part.field,
                        pinnedField: part.pinnedField,
                        value: part.value,
                        conditionals: newPartConditionals,
                        order: part.order
                    };
                }) ?? [],
        conditionals:
            original.conditionals?.map((conditional) => {
                const { value, field, comparator } = conditional;
                return {
                    id: uuid.v1(),
                    value,
                    field,
                    comparator,
                    new: true,
                    dirty: true,
                    valid: true,
                    deleted: false
                };
            }) ?? []
    };
};
