import React from "react";
import {
    IExpandedTextAdTemplate,
    ITemplatePart,
    IConditional
} from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import ExpandedTextAd from "./ExpandedTextAd";
import uuid from "uuid";
import Button, { SECONDARY_BUTTON } from "../../../Shared/Button";
import AddIcon from "../../../Shared/Icons/AddIcon";
import EtaTemplateBoilerplateLoader from "../EtaTemplateBoilerplate/EtaTemplateBoilerplateLoader";
import { IEtaTemplateBoilerplate } from "../../../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";
import { newEtaTemplateFromExistingOrBoilerplate } from "../../../../utils/DynamicCampaignUtils";
import { connect } from "react-redux";
import etaActions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";

const defaultParts: ITemplatePart[] = [
    {
        id: uuid.v1(),
        new: true,
        dirty: true,
        field: "headline",
        pinnedField: "h1",
        value: "",
        conditionals: [],
        order: 0
    },
    {
        id: uuid.v1(),
        new: true,
        dirty: true,
        field: "headline",
        pinnedField: "h2",
        value: "",
        conditionals: [],
        order: 0
    },
    {
        id: uuid.v1(),
        new: true,
        dirty: true,

        field: "headline",
        pinnedField: "h3",
        value: "",
        conditionals: [],
        order: 0
    },
    {
        id: uuid.v1(),
        new: true,
        dirty: true,
        field: "description",
        pinnedField: "d1",
        value: "",
        conditionals: [],
        order: 0
    },
    {
        id: uuid.v1(),
        new: true,
        dirty: true,
        field: "description",
        pinnedField: "d2",
        value: "",
        conditionals: [],
        order: 0
    },
    {
        id: uuid.v1(),
        new: true,
        dirty: true,

        field: "path",
        value: "",
        conditionals: [],
        order: 0
    },
    {
        id: uuid.v1(),
        new: true,
        dirty: true,

        field: "final_url",
        value: "",
        conditionals: [],
        order: 0
    }
];
const defaultExpandedTextAd: IExpandedTextAdTemplate = {
    id: uuid.v1(),
    conditionals: [],
    new: true,
    dirty: true,
    order: 0,
    label: "",
    individualizedParameters: "",
    parts: defaultParts
};

interface IProps {
    values: IExpandedTextAdTemplate[];
    setFieldValue(fieldName: string, value: any): void;
    errors: any;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
    editForm: boolean;
}
const ExpandedTextAds: React.FC<IProps & typeof mapDispatchToProps> = ({
    errors,
    values,
    setFieldValue,
    editForm,
    loadTemplates,
    addTemplate,
    duplicateTemplate
}) => {
    const handleDuplicate = (etaIndex: number) => {
        const original = values[etaIndex];
        const duplicatedEta = newEtaTemplateFromExistingOrBoilerplate(original, values.length);
        setFieldValue("expandedTextAdTemplates", [...values, { ...duplicatedEta }]);
        duplicateTemplate(`${!!editForm ? "edit" : "create"}`);
    };

    const handleLoadEtaTemplatesFromBoilerplate = (etaTemplateBoilerplates: Array<IEtaTemplateBoilerplate>) => {
        const etaTemplatesToLoad = etaTemplateBoilerplates.map((etaTemplateBoilerplate) => {
            return newEtaTemplateFromExistingOrBoilerplate(etaTemplateBoilerplate, values.length);
        });
        setFieldValue("expandedTextAdTemplates", [...values, ...etaTemplatesToLoad]);
        loadTemplates(etaTemplateBoilerplates.length, `${!!editForm ? "edit" : "create"}`);
    };

    return (
        <React.Fragment>
            {values.map((eta: IExpandedTextAdTemplate, idx: number) => {
                return (
                    <ExpandedTextAd
                        idx={idx}
                        key={eta.id}
                        values={eta}
                        setParts={(parts: ITemplatePart[]) => {
                            setFieldValue(`expandedTextAdTemplates[${idx}].parts`, parts);
                            setFieldValue(`expandedTextAdTemplates[${idx}].dirty`, true);
                        }}
                        setConditionals={(conditionals: IConditional[]) => {
                            setFieldValue(`expandedTextAdTemplates[${idx}].conditionals`, conditionals);
                            setFieldValue(`expandedTextAdTemplates[${idx}].dirty`, true);
                        }}
                        setFieldValue={(key: string, value: any) => {
                            setFieldValue(key, value);
                        }}
                        onDuplicate={(etaIndex) => {
                            handleDuplicate(etaIndex);
                        }}
                        onRemove={() => {
                            if (eta.new === true) {
                                setFieldValue(`expandedTextAdTemplates`, [
                                    ...values.slice(0, idx),
                                    ...values.slice(idx + 1)
                                ]);
                                return;
                            }
                            setFieldValue(`expandedTextAdTemplates[${idx}].deleted`, true);
                            setFieldValue(`expandedTextAdTemplates[${idx}].dirty`, true);
                        }}
                        errors={errors}
                    />
                );
            })}

            <div className="mt-4">
                <Button
                    type="button"
                    styleType={SECONDARY_BUTTON}
                    styles="mr-4"
                    onClick={() => {
                        setFieldValue("expandedTextAdTemplates", [
                            ...values,
                            { ...defaultExpandedTextAd, id: uuid.v1() }
                        ]);
                        addTemplate(`${editForm ? "edit" : "create"}`);
                    }}
                >
                    <div className="flex items-center">
                        <AddIcon className="w-6 h-6" />
                        <span className="ml-2">Expanded Text Ad</span>
                    </div>
                </Button>
                <EtaTemplateBoilerplateLoader loadEtaTemplatesFromBoilerplate={handleLoadEtaTemplatesFromBoilerplate} />
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = {
    addTemplate: (form: string) => etaActions.addNewTemplate({ form }),
    loadTemplates: (templates: number, form: string) => etaActions.loadBoilerplate({ templates, form }),
    duplicateTemplate: (form: string) => etaActions.duplicateTemplate({ form })
};

export default connect(null, mapDispatchToProps)(ExpandedTextAds);
