import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BellIcon from "../Shared/Icons/BellIcon";

interface IProps {
    notificationsCount: number;
}

const NotificationAlert: React.FunctionComponent<IProps> = ({ notificationsCount }) => {
    return (
        <Link
            to="/notifications"
            className="cursor-pointer no-underline hover:no-underline focus:no-underline flex mr-8 items-center"
        >
            <BellIcon className="w-6 h-6 stroke-current text-blue-100" />
            {notificationsCount > 0 ? (
                <span
                    className="
                    flex justify-center items-center leading-none bg-red-500 max-w-8 max-h-8 -mt-6 -ml-3 rounded text-red-100 text-xs"
                    style={{ padding: "3px 4px 3px 3px" }}
                >
                    {notificationsCount > 99 ? "99+" : notificationsCount}
                </span>
            ) : (
                ""
            )}
        </Link>
    );
};

const mapStateToProps = (state: any) => {
    const notificationsCount = state.notifications ? state.notifications.pagination.totalRecords : 0;

    return {
        notificationsCount
    };
};

export default connect(mapStateToProps)(NotificationAlert);
