import { utcToZonedTime } from "date-fns-tz";
import { TIME_ZONE } from "../constants/ClientConstants";

const convertUTCTimeToChicago = (value: string): Date | null => {
    let dateValue;
    if (value && !value.includes("+")) {
        dateValue = utcToZonedTime(value.concat("+00:00"), TIME_ZONE);
    } else if (value && value.includes("+")) {
        dateValue = utcToZonedTime(value, TIME_ZONE);
    } else {
        dateValue = null;
    }
    return dateValue;
};

export default convertUTCTimeToChicago;
