import { INegativeKeywordCollForm, INegativeKeywordByCampaignId } from "../interfaces/NegativeKeywordColl";
import { isEmpty } from "lodash";
import { FormikProps } from "formik";
import { ICampaignById } from "../interfaces/Campaigns";
import { IMappedExclusionById, IMappedExclusion } from "../interfaces/Exclusions";

export const mapApiToForm = (campaigns: any, formValues: any): IMappedExclusionById | INegativeKeywordByCampaignId => {
    return Object.keys(campaigns).reduce((acc: any, key) => {
        if (isEmpty(formValues)) {
            acc[key] = [];
        } else {
            acc[key] = Object.keys(formValues)
                .filter((j) => formValues[j].googleCampaignId === String(key))
                .map((k: any) => {
                    const item = formValues[k];
                    const { googleCampaignId, clientId, collectionId, id } = item;
                    return {
                        id,
                        googleCampaignId,
                        clientId,
                        collectionId
                    };
                });
        }
        return acc;
    }, {});
};

export const mapCheckedValues = (values: IMappedExclusionById | INegativeKeywordByCampaignId, clientId: number) => {
    return Object.keys(values)
        .reduce((acc, key) => {
            acc = [...acc, ...values[key].filter((item) => item.new)];
            return acc;
        }, [] as any)
        .map((item: IMappedExclusion) => {
            return { ...item, collectionId: Number(item.collectionId), clientId };
        });
};

export const mapUncheckedValues = (
    initialValues: IMappedExclusionById | INegativeKeywordByCampaignId,
    currentValues: IMappedExclusionById | INegativeKeywordByCampaignId
) => {
    // check if the keyword list collection is in the currentList of selected values
    function checkForCollectionInCurrentValues(collection: IMappedExclusion, currentList: IMappedExclusion[]) {
        return !currentList.some((item) => item.collectionId === collection.collectionId);
    }

    // iterate through initial values to find the ones that is no longer in the list.
    return Object.keys(initialValues).reduce((acc, campaignId) => {
        const removedItems = initialValues[campaignId]
            .filter((collection) => checkForCollectionInCurrentValues(collection, currentValues[campaignId]))
            .map((item) => {
                return {
                    ...item,
                    shouldDelete: true
                };
            });
        acc = [...acc, ...removedItems];
        return acc;
    }, [] as any);
};

export const findInArray = (arr: any[], value: string, key: string) => {
    if (!arr) {
        return false;
    }
    return arr.some((item: any) => String(item[key]) === value);
};

export const onCheckboxChange = (
    fieldValue: IMappedExclusion[] | INegativeKeywordCollForm[],
    checkboxValue: string,
    name: string,
    form: FormikProps<INegativeKeywordByCampaignId | IMappedExclusionById>
) => {
    if (findInArray(fieldValue, checkboxValue, "collectionId")) {
        const nextValue = fieldValue.filter((v: any) => String(v.collectionId) !== checkboxValue);
        form.setFieldValue(name, nextValue);
    } else {
        if (form.initialValues[name]) {
            const previousExclusion = form.initialValues[name].find(
                (exclusion: any) => String(exclusion.collectionId) === checkboxValue
            );
            if (previousExclusion) {
                const nextValue = fieldValue.concat(previousExclusion);
                return form.setFieldValue(name, nextValue);
            }
        }
        const newValue = {
            new: true,
            collectionId: Number(checkboxValue),
            googleCampaignId: name,
            clientId: null // will be assigned later
        };
        const nextValue = fieldValue.concat(newValue);
        form.setFieldValue(name, nextValue);
    }
};

export const displayCamapaignsFilter = (campaignsById: ICampaignById): ICampaignById => {
    return Object.keys(campaignsById)
        .filter((key) => campaignsById[key].type === "DISPLAY")
        .reduce((acc: ICampaignById, key: string) => {
            acc[key] = campaignsById[key];
            return acc;
        }, {});
};
