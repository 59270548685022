import { combineReducers } from "redux";
import * as actions from "../actions/campaignActions";
import { createSelector } from "reselect";
import { orderBy, filter } from "lodash";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

export interface AdWordsCampaign {
    id: number;
    type: "DISPLAY" | "SEARCH";
    status: "ENABLED" | "PAUSED";
    name: string;
    allocatedBudget: number;
    labels: string[];
}

const entitiesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case actions.LOAD_CAMPAIGN:
        case actions.LOAD_CAMPAIGNS:
            if (action.refresh) {
                // If the campaigns are coming from a method that forced the refresh
                // Then we just want to set the state, otherwise things will never get removed if they no longer exist.
                return {
                    ...action.response.entities.adwordsCampaigns
                };
            }

            return { ...state, ...action.response.entities.adwordsCampaigns };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const loading = (state = false, action: any) => {
    switch (action.type) {
        case actions.REQUEST_LOAD_ADWORDS_CAMPAIGNS:
            return true;
        case actions.REQUEST_CAMPAIGN_FAILURE:
        case actions.LOAD_CAMPAIGNS:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    loading
});

const getCampaigns = (state: any) => state.adwordsCampaigns.entities;
const getMicrosoftCampaigns = (state: any) => state.microsoftCampaigns.entities;
export const getAllOrderedByStatusAndName = createSelector(getCampaigns, (campaigns) =>
    orderBy(campaigns, ["status", "name", "type"])
);

export const getAllMicrosoftOrderedByStatusAndName = createSelector(getMicrosoftCampaigns, (campaigns) =>
    orderBy(campaigns, ["status", "name", "type"])
);

export const getAllSearchCampaigns = createSelector(getCampaigns, (campaigns) => filter(campaigns, { type: "SEARCH" }));
