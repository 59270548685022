import React, { SVGProps } from "react";
import Svg from "./Svg";

const CarsDotComColorIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path
            d="M16.125 23h-3.667a.458.458 0 0 1-.458-.458v-3.667c0-.253.205-.458.458-.458h3.667c.254 0 .458.204.458.458v3.667a.458.458 0 0 1-.458.458Z"
            fill="#EA7603"
        />
        <path
            d="M10.625 17.5H1.458A.458.458 0 0 1 1 17.042v-6.417c0-.254.205-.458.458-.458h9.167c.254 0 .458.204.458.458v6.417a.458.458 0 0 1-.458.458Z"
            fill="#0093C5"
        />
        <path
            d="M24.375 17.5H12.458a.458.458 0 0 1-.458-.458V5.125c0-.254.205-.458.458-.458h11.917c.254 0 .458.204.458.458v11.917a.458.458 0 0 1-.458.458Z"
            fill="#A74DCE"
        />
        <path
            d="M10.625 9.25H6.958a.458.458 0 0 1-.458-.458V1.458c0-.253.205-.458.458-.458h3.667c.254 0 .458.205.458.458v7.334a.458.458 0 0 1-.458.458Z"
            fill="#C09BEC"
        />
    </Svg>
);

export default CarsDotComColorIcon;
