import IEntities from "../../interfaces/IEntity";
import { IEtaTemplateBoilerplate } from "../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";
import actions from "../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import IAppState from "../../interfaces/IAppState";
import { AnyAction, Reducer } from "redux";
import { createSelector } from "reselect";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";
const initialState = {
    entities: {},
    data: [],
    loading: false,
    loadedAt: null,
    deleting: [],
    error: {},
    saving: false
};
const reducer: Reducer<{
    entities: IEntities<IEtaTemplateBoilerplate>;
    data: number[];
    loading: boolean;
    loadedAt: null | number;
    deleting: number[];
    error: { id?: number; value?: string };
    saving: boolean;
}> = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.index.request.type:
            return { ...state, loading: true, error: {} };

        case actions.index.success.type:
            const {
                payload: { entities, data }
            } = action as ReturnType<typeof actions.index.success>;
            return { ...state, entities, data, loading: false, loadedAt: Date.now() };

        case actions.index.failure.type:
            const indexFailureAction = action as ReturnType<typeof actions.index.failure>;
            return { ...state, loading: false, error: { value: indexFailureAction.payload.error } };

        case actions.store.request.type:
            return { ...state, error: {}, saving: true };

        case actions.store.success.type:
            return { ...state, saving: false };

        case actions.store.failure.type:
            const storeFailureAction = action as ReturnType<typeof actions.store.failure>;
            return { ...state, error: { value: storeFailureAction.payload.error }, saving: false };

        case actions.destroy.request.type:
            const destroyRequestAction = action as ReturnType<typeof actions.destroy.request>;
            return { ...state, deleting: [...state.deleting, destroyRequestAction.payload.boilerplate.id] };

        case actions.destroy.success.type:
            const destroySuccessAction = action as ReturnType<typeof actions.destroy.success>;
            const successId = destroySuccessAction.payload.id;
            const deletingIndex = state.deleting.indexOf(successId);
            const dataIndex = state.data.indexOf(successId);
            if (state.entities[successId] !== undefined) {
                delete state.entities[successId];
            }

            return {
                ...state,
                entities: { ...state.entities },
                ...(dataIndex >= 0 && {
                    data: [...state.data.slice(0, dataIndex), ...state.data.slice(dataIndex + 1)]
                }),
                deleting: [...state.deleting.slice(0, deletingIndex), ...state.deleting.slice(deletingIndex + 1)],
                error: state.error?.id === successId ? {} : state.error
            };

        case actions.destroy.failure.type:
            const destroyFailureAction = action as ReturnType<typeof actions.destroy.failure>;
            const failureId = destroyFailureAction.payload.id;
            const failureIndex = state.deleting.indexOf(failureId);
            return {
                ...state,
                deleting: [...state.deleting.slice(0, failureIndex), ...state.deleting.slice(failureIndex + 1)],
                error: { id: failureId, value: destroyFailureAction.payload.error }
            };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;

export const selectEtaTemplateBoilerplateIds = (state: IAppState): number[] => state.etaTemplateBoilerplate?.data ?? [];

export const selectEtaTemplateBoilerplateEntities = (state: IAppState): IEntities<IEtaTemplateBoilerplate> =>
    state.etaTemplateBoilerplate?.entities ?? {};

export const selectEtaTemplateBoilerplateDeleting = (state: IAppState): number[] =>
    state.etaTemplateBoilerplate?.deleting ?? [];

export const selectEtaTemplateBoilerplates = createSelector(
    [selectEtaTemplateBoilerplateIds, selectEtaTemplateBoilerplateDeleting, selectEtaTemplateBoilerplateEntities],
    (ids, deleting, entities) => {
        if (deleting.length > 0) {
            return ids.filter((id) => !deleting.includes(id)).map((id) => entities[id]);
        }
        return ids.map((id) => entities[id]);
    }
);

export const selectEtaTemplateBoilerplateIsDeleting = (state: IAppState, id: number): boolean =>
    state.etaTemplateBoilerplate?.deleting.includes(id);
