import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import PermissionField from "./PermissionField";

import IEntity from "../../interfaces/IEntity";
import IUserRole from "../../interfaces/IUserRole";
import IUserForm from "../../interfaces/IUserForm";
import ISelectedUser from "../../interfaces/ISelectedUser";

export const fieldGroupStyles = "flex flex-wrap mb-4 w-full";
export const labelStyles = "block uppercase tracking-wider text-gray-800 font-bold mb-2";
const fieldStyles =
    "appearance-none block w-full bg-gray-200 text-gray-800 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white border-gray-200 focus:border-gray";
const halfWidthField = "w-full sm:w-1/2 ";
const rightField = "pl-2";
const leftField = "pr-2";
const errorStyles = "text-red-500 text-sm italic uppercase";
const buttonStyles = "bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
const fieldSectionStyles = "mb-8";
const sectionLabelStyles = "block uppercase tracking-wider text-gray-600 font-bold mb-2 font-size-sm";

interface IProps {
    initialValues: IUserForm;
    roles: IEntity<IUserRole>;
    selectedUser?: ISelectedUser;
    isSubmitting: boolean;
    saveUser: (values: IUserForm, roles: IEntity<IUserRole>, selectedUser?: ISelectedUser) => void;
    error: any;
}

const UserSchema = Yup.object().shape({
    firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    isAdmin: Yup.string().required("Required")
});

const UserForm: React.SFC<IProps> = ({ initialValues, error, roles, selectedUser, saveUser, isSubmitting }) => {
    const formikRef = useRef(null) as any;

    useEffect(() => {
        const hasError = !isSubmitting && error && formikRef.current.state.submitCount > 0;

        if (hasError) {
            formikRef.current.setErrors(error.errors);
        }
    }, [isSubmitting]);

    return (
        <Formik
            ref={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={UserSchema}
            onSubmit={(values) => saveUser(values, roles, selectedUser)}
            render={({ values, dirty, isValid }) => {
                return (
                    <Form>
                        {/* Profile Section*/}
                        <div className={fieldSectionStyles}>
                            <h3 className={sectionLabelStyles}>Basic Information</h3>

                            {/* First Name */}
                            <div className={fieldGroupStyles}>
                                <label className={labelStyles} htmlFor="firstName">
                                    <span>First Name</span>
                                    <span>*</span>
                                </label>
                                <Field disabled={isSubmitting} name="firstName" className={fieldStyles} />
                                <ErrorMessage component="span" className={errorStyles} name="firstName" />
                            </div>

                            {/* Last Name */}
                            <div className={fieldGroupStyles}>
                                <label className={labelStyles} htmlFor="lastName">
                                    <span>Last Name</span>
                                    <span>*</span>
                                </label>
                                <Field disabled={isSubmitting} name="lastName" className={fieldStyles} />
                                <ErrorMessage component="span" className={errorStyles} name="lastName" />
                            </div>

                            {/* Email */}
                            <div className={fieldGroupStyles}>
                                <label className={labelStyles} htmlFor="email">
                                    <span>Email</span>
                                    <span>*</span>
                                </label>
                                <Field disabled={isSubmitting} name="email" className={fieldStyles} />
                                <ErrorMessage component="span" className={errorStyles} name="email" />
                            </div>
                        </div>

                        {/* Account Details */}
                        <div className={`px-3`}>
                            <h3 className={sectionLabelStyles}>Account Details</h3>

                            {/* Account Type */}
                            <div className={`${fieldGroupStyles} flex-col`}>
                                <label htmlFor="" className={labelStyles}>
                                    <span>Account Type</span>
                                    <span>*</span>
                                </label>
                                <div className="flex flex-wrap mx-6 ">
                                    <div className={`${halfWidthField} ${leftField}`}>
                                        <label className={labelStyles} htmlFor="isUser">
                                            <Field
                                                type="radio"
                                                name="isAdmin"
                                                value="false"
                                                checked={values.isAdmin === "false"}
                                                disabled={isSubmitting}
                                                className="px-3 leading-tight cursor-pointer"
                                            />
                                            <span className="mx-2">User</span>
                                        </label>
                                    </div>
                                    <div className={`${halfWidthField} ${rightField}`}>
                                        <label className={labelStyles} htmlFor="isAdmin">
                                            <Field
                                                name="isAdmin"
                                                type="radio"
                                                value="true"
                                                checked={values.isAdmin === "true"}
                                                disabled={isSubmitting}
                                                className="mr-2 leading-tight cursor-pointer"
                                            />
                                            <span className="mx-2">Admin</span>
                                        </label>
                                    </div>
                                </div>
                                <ErrorMessage component="span" className={errorStyles} name="userType" />
                            </div>
                        </div>

                        <div className={fieldSectionStyles}>
                            <div className={sectionLabelStyles}>Account Permissions</div>
                            <PermissionField role="all" {...{ values, isSubmitting }} />
                            <PermissionField role="budget_manager" {...{ values, isSubmitting }} />
                            <PermissionField role="readonly_budgets" {...{ values, isSubmitting }} />
                            <PermissionField role="supervisor" {...{ values, isSubmitting }} />
                        </div>

                        {/* Buttons  */}
                        <div className="flex items-center justify-between">
                            <div className="flex ">
                                <button
                                    disabled={isSubmitting || !dirty}
                                    className={`${buttonStyles} ${
                                        isSubmitting || !isValid ? "opacity-50 " : "hover:bg-blue-600 "
                                    }`}
                                    type="submit"
                                >
                                    {!selectedUser ? "Create User" : "Save Changes"}
                                </button>
                            </div>
                            <Link
                                to="/users"
                                className="inline-block align-baseline font-bold text-blue-500 hover:text-blue-800 mx-4"
                            >
                                Cancel
                            </Link>
                        </div>
                    </Form>
                );
            }}
        />
    );
};

export default UserForm;
