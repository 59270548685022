import React, { SVGProps } from "react";
import Svg from "./Svg";

const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M14.524 2H8.478c-1.118 0-2.05.88-2.05 1.999v.43H6.05C4.931 4.429 4 5.309 4 6.427V21c0 1.118.931 2.049 2.05 2.049h10.523c1.118 0 1.998-.931 1.998-2.05v-.379h.43c1.119 0 1.999-.93 1.999-2.05V8.477L14.524 2zm0 2.257l4.22 4.22h-4.22v-4.22zm2.428 16.742c0 .237-.172.43-.38.43H6.05a.45.45 0 0 1-.43-.43V6.427c0-.207.192-.38.43-.38h.38v12.928c0 1.118.526 1.644 1.644 1.644h8.88v.38zm2.429-2.43c0 .239-.172.431-.38.431H8.478a.45.45 0 0 1-.43-.43V3.999c0-.208.192-.38.43-.38h4.427v6.476h6.476v8.475z" />
    </Svg>
);

export default CopyIcon;
