import React from "react";
import Field from "../Elements/Field";
import SelectInput from "../Elements/SelectInput";
import { FormikErrors, FormikTouched } from "formik";

interface IOptions {
    label: string;
    value: string;
}

interface IProps {
    name: string;
    value: string | undefined;
    touched?: boolean | FormikTouched<any>;
    options: IOptions[];
    label?: string;
    errors?: Array<string | FormikErrors<any>> | string;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    className?: string;
    style?: any;
    readOnly?: boolean;
    required?: boolean;
    autoFocus?: boolean;
}

const SelectField: React.FunctionComponent<IProps> = ({
    name,
    label,
    value,
    options,
    handleChange,
    handleBlur,
    placeholder,
    className,
    style,
    required = false,
    readOnly = false,
    touched = handleBlur === undefined,
    errors = [],
    autoFocus = false
}) => {
    if (placeholder === undefined) {
        placeholder = label;
    }

    return (
        <Field label={label} errors={errors} touched={touched} required={required} className={className} style={style}>
            <SelectInput
                autoFocus={autoFocus}
                value={value}
                ariaLabel={label ? label : name}
                options={options}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                readOnly={readOnly}
                isValid={touched === false || !errors.length}
            />
        </Field>
    );
};

export default SelectField;
