import React from "react";
import TrashIcon from "../../Shared/Icons/TrashIcon";

interface IProps {
    onRemove: () => void;
    className?: string;
}
const DeleteButton: React.FC<IProps> = ({ onRemove, className = "" }) => {
    return (
        <button
            type="button"
            className={`bg-transparent text-gray-600 hover:text-red-600 h-full outline-none ${className}`}
            onClick={(e) => {
                e.preventDefault();
                onRemove();
            }}
        >
            <TrashIcon className="w-6 h-6" />
        </button>
    );
};

export default DeleteButton;
