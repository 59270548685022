import React from "react";
import Button from "../Shared/Button";
import { useNavigate } from "react-router-dom";

interface IProps {
    clientId: number;
}

const EditClientButton = ({ clientId }: IProps) => {
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate(`/clients/${clientId}`)} type={"button"} styleType={"primary"}>
            Edit
        </Button>
    );
};

export default EditClientButton;
