/**
 * Created by fjiles@dealerinspire.com on 3/3/17.
 */
export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const CLEAR_FLASH_MESSAGES = "CLEAR_FLASH_MESSAGES";

interface IMessage {
    type: string;
    text: string;
}

export const addFlashMessage = (message: IMessage) => ({
    type: ADD_FLASH_MESSAGE,
    message
});

export const clearAllFlashMessages = () => ({
    type: CLEAR_FLASH_MESSAGES
});
