import { callApi } from "../../middleware/api";
import { decamelizeKeys } from "humps";
import { takeEvery, all, call, put } from "redux-saga/effects";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";
import NegativeKeywordConstants from "../../constants/NegativeKeywords";
import { FormikActions } from "formik";
import {
    INegativeKeywordByCampaignId,
    IUpdatedFormValues,
    INegativeKeywordCollForm
} from "../../interfaces/NegativeKeywordColl";
import { addFlashMessage } from "../../actions/flashMessageActions";
import ApiConstants from "../../constants/ApiConstants";
import KeywordCollActions from "../../actions/client/NegativeKeywordCollActions";

interface IUpdateSuccessProps {
    type: string;
    payload: { clientId: number; formikActions: FormikActions<INegativeKeywordByCampaignId> };
}

interface IUpdateFailProps {
    type: string;
    payload: { error: any; formikActions: FormikActions<INegativeKeywordByCampaignId>; clientId: number };
}

interface IUpdateProps {
    type: string;
    payload: {
        negativeKeywordCollCampaigns: IUpdatedFormValues;
        formikActions: FormikActions<INegativeKeywordByCampaignId>;
        clientId: number;
    };
}

interface IFetchNegativeKeywordCollsResponse {}

export function* fetchNegativeKeywordColls(): IFetchNegativeKeywordCollsResponse {
    const url = `/negative-keyword-colls`;
    try {
        const response = yield call(callApi, url, CLIENT_SCHEMAS.NEGATIVE_KEYWORD.NEGATIVE_KEYWORD_COLLS_ARRAY);
        const { negativeKeywordColls } = response.entities;
        yield put(KeywordCollActions.requestNegativeKeywordCollsSuccess(negativeKeywordColls));
    } catch (error) {
        yield put(KeywordCollActions.requestNegativeKeywordCollsFail(error));
    }
}

interface IFetchNegativeKeywordCollCampaignsResponse {}

export function* fetchNegativeKeywordCollCampaigns({
    payload
}: {
    type: string;
    payload: number;
}): IFetchNegativeKeywordCollCampaignsResponse {
    const url = `/negative-keyword-coll-campaigns?filter[client_id]=${payload}&limit=1000`;
    try {
        const response = yield call(callApi, url, CLIENT_SCHEMAS.NEGATIVE_KEYWORD.NEGATIVE_KEYWORD_COLL_CAMPAIGN_ARRAY);
        const { negativeKeywordCollCampaigns } = response.entities;
        yield put(KeywordCollActions.requestNegativeKeywordCollCampaignsSuccess(negativeKeywordCollCampaigns));
    } catch (error) {
        yield put(KeywordCollActions.requestNegativeKeywordCollCampaignsFail(error));
    }
}

export function* updateNegativeKeywordCollCampaigns({ payload }: IUpdateProps) {
    const { clientId, negativeKeywordCollCampaigns, formikActions } = payload;
    const { checkedValues, unCheckedValues } = negativeKeywordCollCampaigns;
    try {
        yield all([...handleNew(checkedValues), ...handleRemove(unCheckedValues)]);
        yield put(KeywordCollActions.updateNegativeKeywordCollCampaignsSuccess(formikActions, clientId));
    } catch (error) {
        yield put(KeywordCollActions.updateNegativeKeywordCollCampaignsFail(error, formikActions, clientId));
    }
}

export function* updateSuccess({ payload }: IUpdateSuccessProps) {
    const { formikActions, clientId } = payload;
    yield put(KeywordCollActions.requestNegativeKeywordCollCampaigns(clientId));
    yield put(addFlashMessage({ type: "success", text: "Negative Keyword Collections Updated Successfully." }));
    yield formikActions.setSubmitting(false);
}

export function* updateFail({ payload }: IUpdateFailProps) {
    const { formikActions, error, clientId } = payload;
    yield put(KeywordCollActions.requestNegativeKeywordCollCampaigns(clientId));
    yield put(addFlashMessage({ type: "danger", text: error.message }));
    yield formikActions.setSubmitting(false);
    yield formikActions.setErrors(error);
}

export const handleNew = (keywordColls: INegativeKeywordCollForm[]) => {
    return keywordColls.map((item) => {
        return call(
            callApi,
            ApiConstants.NEGATIVE_KEYWORD_COLL_CAMPAIGN_URL(),
            CLIENT_SCHEMAS.NEGATIVE_KEYWORD.NEGATIVE_KEYWORD_COLL_CAMPAIGN_ARRAY,
            "POST",
            decamelizeKeys(item)
        );
    });
};

export const handleRemove = (keywordColls: INegativeKeywordCollForm[]) => {
    return keywordColls.map((item) => {
        return call(callApi, ApiConstants.NEGATIVE_KEYWORD_COLL_CAMPAIGN_URL(item.id), {}, "DELETE");
    });
};

function* sagas() {
    yield takeEvery(NegativeKeywordConstants.REQUEST_NEGATIVE_KEYWORD_COLLS, fetchNegativeKeywordColls);
    yield takeEvery(
        NegativeKeywordConstants.REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS,
        fetchNegativeKeywordCollCampaigns
    );
    yield takeEvery(
        NegativeKeywordConstants.REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS,
        updateNegativeKeywordCollCampaigns
    );
    yield takeEvery(NegativeKeywordConstants.REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_SUCCESS, updateSuccess);
    yield takeEvery(NegativeKeywordConstants.REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_FAIL, updateFail);
}

export default sagas;
