import withClient from "../../../withClient";
import * as React from "react";
import IClient from "../../../../interfaces/IClient";
import IEntity from "../../../../interfaces/IEntity";
import { useEffect } from "react";
import { connect } from "react-redux";
import { deleteTrackingParameter } from "../../../../actions/trackingParameterActions";
import IAppState from "../../../../interfaces/IAppState";
import ITrackingParameter from "../../../../interfaces/ITrackingParameter";
import GoogleColorIcon from "../../../Shared/Icons/GoogleColoredIcon";
import MicrosoftColorIcon from "../../../Shared/Icons/MicrosoftColorIcon";
import SimpleActionCell from "../../../Shared/SimpleActionCell";
import { Table } from "../../../Shared/Table";

interface IProps {
    trackingParameters: IEntity<ITrackingParameter>;
    deleteTrackingParameter: (trackingParameter: ITrackingParameter) => void;
    loading: boolean;
}

const CampaignsWithTrackingParameters = ({ trackingParameter }: { trackingParameter: ITrackingParameter }) => {
    const quantityOfCampaigns = Object.keys(trackingParameter.trackedCampaigns).length;

    switch (quantityOfCampaigns) {
        case 0:
            return <div className="italic">No linked campaigns</div>;
        case 1:
            return <div>Linked to 1 campaign</div>;

        default:
            return <div>Linked to {trackingParameter.trackedCampaigns.length} campaigns</div>;
    }
};

const IndexPage: React.FunctionComponent<IProps> = ({
    trackingParameters,
    deleteTrackingParameter,
    loading
}: IProps) => {
    return (
        <div>
            <Table
                data={Object.values(trackingParameters)}
                columns={[
                    {
                        Cell: ({ original }: { original: ITrackingParameter }) => (
                            <SimpleActionCell
                                onCancel={(original: ITrackingParameter) => {}}
                                onDelete={(original: ITrackingParameter) => {
                                    deleteTrackingParameter(original);
                                }}
                                message={"Are you sure you want to delete this tracking parameter?"}
                                original={original}
                            />
                        ),
                        accessor: "",
                        Header: "Actions",
                        id: "ActionColumn",
                        filterable: false,
                        sortable: false,
                        maxWidth: 145,
                        headerClassName: ""
                    },
                    {
                        id: "name",
                        accessor: "name",
                        Header: "Name",
                        filterable: false,
                        maxWidth: 400,
                        headerClassName: ""
                    },
                    {
                        Cell: ({ original }: { original: ITrackingParameter }) => (
                            <div>
                                {original.platform === "google" && <GoogleColorIcon className="w-6 h-6" />}
                                {original.platform === "microsoft" && <MicrosoftColorIcon className="w-6 h-6" />}
                            </div>
                        ),
                        id: "platform",
                        Header: "Platform",
                        maxWidth: 145,
                        headerClassName: ""
                    },
                    {
                        id: "campaignNames",
                        accessor: "campaignNames",
                        filterable: false,
                        sortable: false,
                        Header: "Linked Campaigns",
                        headerClassName: "",
                        Cell: ({ original: trackingParameter }: { original: ITrackingParameter }) => {
                            return <CampaignsWithTrackingParameters trackingParameter={trackingParameter} />;
                        }
                    }
                ]}
                loadingTable={loading}
                loadingMessage={"Loading Tracking Parameters"}
            />
        </div>
    );
};

const mapStateToProps = (
    state: IAppState,
    props: any
): {
    trackingParameters: IEntity<ITrackingParameter> | {};
    loading: boolean;
} => {
    return {
        trackingParameters: state.trackingParameters.entities || {},
        loading: state.trackingParameters.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteTrackingParameter(trackingParameter: ITrackingParameter) {
            dispatch(deleteTrackingParameter(trackingParameter));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
