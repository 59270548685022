import React from "react";
import DeleteIcon from "../../Shared/Icons/DeleteIcon";
import { IEditHistoryActionItem } from "../../../interfaces/EditHistory/IEditHistoryActionItem";
import moment from "moment";

const ItemDeleted = ({ activity }: IEditHistoryActionItem) => (
    <div className={`flex items-center border my-2 rounded`}>
        <div className="w-6 ml-2 bg-red-500 rounded-full">
            <DeleteIcon className="w-6 text-white p-1" />
        </div>
        <div className={`flex flex-col justify-between p-2 text-base`}>
            <div className="flex items-center text-sm">
                <div className="underline mr-1">{activity?.subjectType}</div> was deleted
            </div>
            <div className="text-xs text-gray-600">
                {[activity.causer?.firstName, activity.causer?.lastName].join(" ").trim()} •{" "}
                {moment(activity.createdAt).format("MMMM Do YYYY, h:mm a")}
            </div>
        </div>
    </div>
);

export default ItemDeleted;
