import { NavigateFunction } from "react-router-dom";
import { call, CallEffect, put, takeEvery } from "redux-saga/effects";
import { decamelizeKeys, camelizeKeys } from "humps";
import * as actions from "../../actions/dynamicCampaigns/dynamicCampaignActions";
import DynamicCampaignConstants from "../../constants/DynamicCampaignConstants";
import Urls from "../../constants/ApiConstants";
import { callApi, doDeleteRequest, parseErrors } from "../../middleware/api";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";
import { IConditional } from "../../interfaces/DynamicCampaigns/IDynamicCampaign";
import ISagaProps from "../../interfaces/ISagaProps";
import { saveAdGroupBidModifiers } from "./adGroupBidModifiersSagas";
import { saveExpandedTextAdTemplates, fetchInventorySampleData } from "./expandedTextAdTemplates";
import { FormikActions } from "formik";
import IDynamicCampaignFormValue from "../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import { IFetchAllResponse } from "../../interfaces/DynamicCampaigns/IDynamicCampaign";
import handleKeywordTemplateRelationships from "./DynamicCampaigns/KeywordTemplateRelationships/keywordTemplateRelationshipSagas";
import handleKeywordBidModifiers from "./DynamicCampaigns/KeywordBidModifiers/keywordBidModifierSagas";
import { flatten } from "../../utils/DynamicCampaignUtils";
import { detachSpecialOfferTemplate } from "../../actions/specialOfferTemplateActions";
import { ISpecialOfferTemplateAttachedToDynamicCampaignPayload } from "../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";

interface IFetchAllAction {
    clientId: number;
    type: string;
}

interface IFetchDynamicCampaignAction {
    type: string;
    payload: {
        clientId: number;
        dynamicCampaignId: number;
    };
}

interface IDeleteCampaignAction {
    clientId: number;
    id: number;
    type: string;
}

function* fetchAll({ clientId }: IFetchAllAction) {
    const resp: IFetchAllResponse = yield call(
        callApi,
        Urls.ALL_DYNAMIC_CAMPAIGNS_URL(clientId),
        CLIENT_SCHEMAS.DYNAMIC_CAMPAIGN.DYNAMIC_CAMPAIGNS_ARRAY
    );
    yield put(actions.loadAll(resp));
}

export function* fetchDynamicCampaign({
    payload: { clientId, dynamicCampaignId }
}: IFetchDynamicCampaignAction): Generator<any, any, any> {
    try {
        const response = yield call(
            callApi,
            Urls.FETCH_DYNAMIC_CAMPAIGN_URL(clientId, dynamicCampaignId),
            CLIENT_SCHEMAS.DYNAMIC_CAMPAIGN.DYNAMIC_CAMPAIGN
        );
        yield put(actions.fetchDynamicCampaignSuccess(response));
        return response;
    } catch (e) {
        yield put(actions.fetchDynamicCampaignFailure(dynamicCampaignId));
    }
}

function* deleteDynamicCampaign({ clientId, id }: IDeleteCampaignAction) {
    yield call(doDeleteRequest, Urls.DYNAMIC_CAMPAIGN_URL(clientId, id));
    yield put(actions.deleteItem(id));
}

interface ICreateInventoryFilter {
    clientId: number;
    dynamicCampaignId: number;
    inventoryFilter: IConditional;
}

interface ICreateProps {
    type: string;
    payload: { clientId: number; dynamicCampaign: IDynamicCampaignFormValue };
    formAction: FormikActions<IDynamicCampaignFormValue>;
    navigate: NavigateFunction;
}

function* createInventoryFilter({
    payload
}: ISagaProps<ICreateInventoryFilter>): Generator<CallEffect, Array<boolean | any> | Array<any | {}>> {
    const { clientId, dynamicCampaignId, inventoryFilter } = payload;
    const url = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/inventory-filters`;
    try {
        const resp: any = yield call(
            callApi,
            url,
            CLIENT_SCHEMAS.DYNAMIC_CAMPAIGN.INVENTORY_FILTER,
            "POST",
            decamelizeKeys(inventoryFilter)
        );
        const id = resp.result;
        return [false, resp.entities.inventoryFilters[id]];
    } catch (e) {
        return [parseErrors(e.errors), camelizeKeys(inventoryFilter)];
    }
}

function* updateInventoryFilter({
    payload
}: ISagaProps<ICreateInventoryFilter>): Generator<CallEffect, Array<boolean | any> | Array<any | {}>> {
    const { clientId, dynamicCampaignId, inventoryFilter } = payload;
    const id = inventoryFilter.id;
    const url = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/inventory-filters/${id}`;
    try {
        const resp: any = yield call(
            callApi,
            url,
            CLIENT_SCHEMAS.DYNAMIC_CAMPAIGN.INVENTORY_FILTER,
            "PUT",
            decamelizeKeys(inventoryFilter)
        );
        return [false, resp.entities.inventoryFilters[resp.result]];
    } catch (e) {
        return [parseErrors(e.errors), camelizeKeys(inventoryFilter)];
    }
}

function* deleteInventoryFilter({
    payload
}: ISagaProps<ICreateInventoryFilter>): Generator<CallEffect, any[] | boolean> {
    const { clientId, dynamicCampaignId, inventoryFilter } = payload;
    const id = inventoryFilter.id;
    const url = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/inventory-filters/${id}`;

    try {
        yield call(doDeleteRequest, url);
        return true;
    } catch (e) {
        return [];
    }
}

interface IHandleInventoryISagaProps {
    dynamicCampaignId: number;
    clientId: number;
    inventoryFilters: IConditional[];
}
export function* handleInventoryFilters({
    payload
}: ISagaProps<IHandleInventoryISagaProps>): Generator<CallEffect, Array<boolean | any> | Array<any | {}>> {
    const savedInventoryFilters: IConditional[] = [];
    const savingErrors: Array<{}> = [];
    // save inventory filters

    for (const inventoryFilter of payload.inventoryFilters) {
        const { deleted, new: isNew, ...formData } = inventoryFilter;
        const props = {
            payload: {
                dynamicCampaignId: payload.dynamicCampaignId,
                clientId: payload.clientId,
                inventoryFilter: formData
            }
        };
        if (deleted === true) {
            if (isNew !== true) {
                // delete the inventory filter yo
                yield call(deleteInventoryFilter, props);
            }
        } else {
            if (isNew === true) {
                const [errors, newFilter]: any = yield call(createInventoryFilter, props);
                savedInventoryFilters.push(newFilter);
                if (errors) {
                    savingErrors.push(errors);
                }
            } else {
                const [errors, newFilter]: any = yield call(updateInventoryFilter, props);
                savedInventoryFilters.push(newFilter);
                if (errors) {
                    savingErrors.push(errors);
                }
            }
        }
    }
    return [savingErrors.length > 0 ? savingErrors : false, savedInventoryFilters, savedInventoryFilters];
}

interface IErrorsGrouped {
    [field: string]: {};
}
const buildErrors = (errors: IErrorsGrouped): IErrorsGrouped => {
    const errorsGrouped: { [field: string]: {} } = {};
    Object.entries(errors).map(([field, fieldErrors]: [string, {}]) => {
        if (fieldErrors) {
            errorsGrouped[field] = fieldErrors;
        }
    });

    return errorsGrouped;
};

const modifyDcValues = (body: any) => {
    const dcValues: any = decamelizeKeys(body);
    if (dcValues.use_di_inventory_feed === true) {
        delete dcValues.urls;
    }
    if (dcValues.refresh_type === "inventory_update") {
        dcValues.refresh_value = null;
        dcValues.refresh_period = null;
    }

    return dcValues;
};

function* create({ payload, formAction, navigate }: ICreateProps): any {
    const url = Urls.CREATE_DYNAMIC_CAMPAIGN_URL(payload.clientId);

    const {
        inventoryFilters,
        adGroupBidModifiers,
        expandedTextAdTemplates,
        keywordBidModifiers,
        keywordTemplateRelationships,
        ...body
    } = payload.dynamicCampaign;
    const dcValues: any = modifyDcValues(body);

    try {
        const resp = yield call(callApi, url, CLIENT_SCHEMAS.DYNAMIC_CAMPAIGN.DYNAMIC_CAMPAIGN, "POST", dcValues);
        const id = resp.result;
        const dynamicCampaign = { ...body, ...resp.entities.dynamicCampaigns[id] };
        const [inventoryFilterErrors, savedInventoryFilters] = yield call(handleInventoryFilters, {
            payload: { dynamicCampaignId: id, clientId: payload.clientId, inventoryFilters }
        });
        dynamicCampaign.inventoryFilters = savedInventoryFilters;

        const [adGroupBidModifierErrors, savedAdGroupBidModifiers] = yield call(saveAdGroupBidModifiers, {
            payload: { dynamicCampaignId: id, clientId: payload.clientId, adGroupBidModifiers },
            type: ""
        });
        dynamicCampaign.adGroupBidModifiers = savedAdGroupBidModifiers;

        const [expandedTextAdTemplatesErrors, savedExpandedTextAdTemplates] = yield call(saveExpandedTextAdTemplates, {
            payload: { expandedTextAdTemplates, dynamicCampaignId: id, clientId: payload.clientId },
            type: ""
        });
        dynamicCampaign.expandedTextAdTemplates = savedExpandedTextAdTemplates;

        const [keywordTemplateErrors, savedKeywordTemplateRelationships] = yield call(
            handleKeywordTemplateRelationships,
            {
                dynamicCampaignId: id,
                clientId: payload.clientId,
                keywordTemplateRelationships
            }
        );
        dynamicCampaign.keywordTemplateRelationships = savedKeywordTemplateRelationships;

        const [keywordBidModifierErrors, savedKeywordBidModifiers] = yield handleKeywordBidModifiers({
            dynamicCampaignId: id,
            clientId: payload.clientId,
            bids: keywordBidModifiers
        });
        dynamicCampaign.keywordBidModifiers = savedKeywordBidModifiers;

        const errors = buildErrors({
            inventoryFilters: inventoryFilterErrors,
            adGroupBidModifiers: adGroupBidModifierErrors,
            keywordTemplateRelationships: keywordTemplateErrors,
            ...(keywordTemplateErrors && flatten({ keywordTemplateRelationships: keywordTemplateErrors })),
            ...(keywordBidModifierErrors && flatten({ keywordBidModifiers: keywordBidModifierErrors })),
            ...expandedTextAdTemplatesErrors
        });

        yield call(formAction.resetForm, dynamicCampaign);
        dynamicCampaign.errors = errors;

        yield put(actions.createDynamicCampaignSuccess(dynamicCampaign));
        navigate(`/client/${payload.clientId}/dynamic-campaigns/${id}`);
    } catch (e) {
        yield put(actions.createDynamicCampaignFail(e));
        yield call(formAction.setErrors, { ...e.errors });
        yield call(formAction.setSubmitting, false);
        yield call(formAction.setStatus, null);
    }
}

interface IDynamicCampaignUpdate {
    type: string;
    payload: {
        type: string;
        clientId: number;
        dynamicCampaign: IDynamicCampaignFormValue;
        specialOfferTemplates: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[];
    };
    formActions: FormikActions<IDynamicCampaignFormValue>;
}

// todo: this could be refactored with create
function* update({ payload, formActions }: IDynamicCampaignUpdate): any {
    const url = Urls.DYNAMIC_CAMPAIGN_URL(payload.clientId, payload.dynamicCampaign.id as number);

    const {
        inventoryFilters,
        adGroupBidModifiers,
        expandedTextAdTemplates,
        specialOfferTemplates,
        keywordTemplateRelationships,
        keywordBidModifiers,
        ...body
    } = payload.dynamicCampaign;

    const dcValues: any = modifyDcValues(body);

    try {
        const resp = yield call(callApi, url, CLIENT_SCHEMAS.DYNAMIC_CAMPAIGN.DYNAMIC_CAMPAIGN, "PUT", dcValues);

        const id = resp.result;
        const dynamicCampaign = resp.entities.dynamicCampaigns[id];

        const [inventoryFilterErrors, savedInventoryFilters] = yield call(handleInventoryFilters, {
            payload: { dynamicCampaignId: id, clientId: payload.clientId, inventoryFilters }
        });
        dynamicCampaign.inventoryFilters = savedInventoryFilters;

        const [adGroupBidModifierErrors, savedAdGroupBidModifiers] = yield call(saveAdGroupBidModifiers, {
            payload: { dynamicCampaignId: id, clientId: payload.clientId, adGroupBidModifiers },
            type: ""
        });
        dynamicCampaign.adGroupBidModifiers = savedAdGroupBidModifiers;

        const [expandedTextAdTemplatesErrors, savedExpandedTextAdTemplates] = yield call(saveExpandedTextAdTemplates, {
            payload: { expandedTextAdTemplates, dynamicCampaignId: id, clientId: payload.clientId },
            type: ""
        });
        dynamicCampaign.expandedTextAdTemplates = savedExpandedTextAdTemplates;

        dynamicCampaign.specialOfferTemplates = specialOfferTemplates;

        const [keywordTemplateErrors, savedKeywordTemplateRelationships] = yield call(
            handleKeywordTemplateRelationships,
            {
                dynamicCampaignId: id,
                clientId: payload.clientId,
                keywordTemplateRelationships
            }
        );
        dynamicCampaign.keywordTemplateRelationships = savedKeywordTemplateRelationships;

        const [keywordBidModifierErrors, savedKeywordBidModifiers] = yield handleKeywordBidModifiers({
            dynamicCampaignId: id,
            clientId: payload.clientId,
            bids: keywordBidModifiers
        });
        dynamicCampaign.keywordBidModifiers = savedKeywordBidModifiers;

        for (const index in payload.specialOfferTemplates) {
            if (!payload.specialOfferTemplates[index]) {
                continue;
            }

            const data: ISpecialOfferTemplateAttachedToDynamicCampaignPayload = payload.specialOfferTemplates[index];
            yield put(detachSpecialOfferTemplate(data.clientId, data.sotId, data.dynamicCampaignId));
        }

        const errors = buildErrors({
            inventoryFilters: inventoryFilterErrors,
            adGroupBidModifiers: adGroupBidModifierErrors,
            ...(keywordTemplateErrors && flatten({ keywordTemplateRelationships: keywordTemplateErrors })),
            ...(keywordBidModifierErrors && flatten({ keywordBidModifiers: keywordBidModifierErrors })),
            ...expandedTextAdTemplatesErrors
        });

        if (Object.keys(errors).length > 0) {
            yield call(formActions.setErrors, errors);
            yield call(formActions.setSubmitting, false);
            yield call(formActions.setStatus, "Failed to save");

            return;
        }

        yield call(formActions.resetForm, dynamicCampaign);
        yield put(actions.updateDynamicCampaignSuccess(dynamicCampaign));
        yield call(formActions.setStatus, "Saved");
    } catch (e) {
        yield put(actions.updateDynamicCampaignFail(payload.dynamicCampaign.id as number, e));
        yield call(formActions.setSubmitting, false);
        yield call(formActions.setStatus, null);
        yield call(formActions.setErrors, { noField: e.message });
    }

    yield call(formActions.setSubmitting, false);
}

interface IDuplicateDynamicCampaignAction {
    type: string;
    payload: {
        name: string;
        clientId: number;
        dynamicCampaignId: number;
        adwordsCampaignId: number;
    };
    formActions: {
        resetModal(): void;
        displayErrors(errors: any): void;
    };
}

export function* duplicateDynamicCampaign({
    payload,
    formActions
}: IDuplicateDynamicCampaignAction): Generator<any, any, any> {
    const { name, clientId, adwordsCampaignId, dynamicCampaignId } = payload;
    const url = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/replicate`;
    const body: { name: string; adwords_campaign_id: number } = { name, adwords_campaign_id: adwordsCampaignId };
    try {
        const response = yield call(callApi, url, {}, "POST", body);
        // fetch the the duplicated dynamicCampaign so we get all the expanded relationships
        yield call(fetchDynamicCampaign, {
            type: "",
            payload: { dynamicCampaignId: response.result.id, clientId }
        });
        yield put(actions.duplicateDynamicCampaignSuccess());
        yield call(formActions.resetModal);
    } catch (e) {
        yield call(formActions.displayErrors, e);
    }
}

interface IPushDynamicCampaign {
    type: string;
    payload: {
        clientId: number;
        dynamicCampaignId: number;
    };
    formActions: {
        setErrors(e: any): void;
        setSubmitting(isSubmitting: boolean): void;
        setStatus(val: any): void;
    };
}

function* pushDynamicCampaign({ payload, formActions }: IPushDynamicCampaign) {
    const url = `/clients/${payload.clientId}/dynamic-campaigns/${payload.dynamicCampaignId}/push`;
    try {
        yield call(callApi, url, {}, "POST");
        yield put(actions.pushDynamicCampaignSuccess(payload.dynamicCampaignId));
        yield call(formActions.setStatus, null);
    } catch (e) {
        yield put(actions.pushDynamicCampaignFailure());
        yield call(formActions.setStatus, null);
        yield call(formActions.setErrors, { noField: e.message });
    }
}

function* purgeDynamicCampaign({ payload, formActions }: IPushDynamicCampaign) {
    const url = `/clients/${payload.clientId}/dynamic-campaigns/${payload.dynamicCampaignId}/purge`;
    try {
        yield call(callApi, url, {}, "POST");
        yield put(actions.purgeDynamicCampaignSuccess());
        yield call(formActions.setStatus, {
            success:
                "The purge process may take a few minutes to complete. Please confirm that all ad groups have been removed from the campaign in Google Ads and/or Microsoft Advertising before you re-push the campaign."
        });
    } catch (e) {
        yield put(actions.purgeDynamicCampaignFailure());
        yield call(formActions.setStatus, null);
        yield call(formActions.setErrors, { noField: e.message });
    }
}

function* unlockDynamicCampaign({ payload, formActions }: IPushDynamicCampaign) {
    const url = `/clients/${payload.clientId}/dynamic-campaigns/${payload.dynamicCampaignId}/unlock`;
    try {
        yield call(callApi, url, {}, "POST");
        yield put(actions.unlockDynamicCampaignSuccess(payload.dynamicCampaignId));
        yield call(formActions.setStatus, {
            success: "The campaign has been unlocked."
        });
    } catch (e) {
        yield put(actions.unlockDynamicCampaignFailure());
        yield call(formActions.setStatus, null);
        yield call(formActions.setErrors, { noField: e.message });
    }
}

function* sagas() {
    yield takeEvery(DynamicCampaignConstants.REQUEST_LOAD_DYNAMIC_CAMPAIGNS, fetchAll);
    yield takeEvery(DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN, fetchDynamicCampaign);
    yield takeEvery(DynamicCampaignConstants.REQUEST_DELETE_DYNAMIC_CAMPAIGN, deleteDynamicCampaign);
    yield takeEvery(DynamicCampaignConstants.REQUEST_CREATE_DYNAMIC_CAMPAIGN, create);
    yield takeEvery(DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN, update);
    yield takeEvery(DynamicCampaignConstants.REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN, duplicateDynamicCampaign);
    yield takeEvery(DynamicCampaignConstants.REQUEST_INVENTORY_SAMPLE_DATA, fetchInventorySampleData);
    yield takeEvery(DynamicCampaignConstants.PUSH_DYNAMIC_CAMPAIGN, pushDynamicCampaign);
    yield takeEvery(DynamicCampaignConstants.REQUEST_PURGE_DYNAMIC_CAMPAIGN, purgeDynamicCampaign);
    yield takeEvery(DynamicCampaignConstants.REQUEST_UNLOCK_DYNAMIC_CAMPAIGN, unlockDynamicCampaign);
}

export default sagas;

export { fetchAll, create, createInventoryFilter };
