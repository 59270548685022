import React from "react";
import ExportLog from "./ExportLog";
import { isEmpty } from "lodash";

interface IAggregated {
    [key: string]: {
        item: any;
        errors: { [key: string]: string | string[] };
        rejections: { [key: string]: string | string[] };
    };
}

interface IProps {
    errorsAndRejections: IAggregated;
}

const ExportLogs: React.FunctionComponent<IProps> = ({ errorsAndRejections }) => {
    return (
        <div className="m-auto relative bg-white flex flex-col w-full shadow rounded px-4 pt-4 pb-4">
            <div className="font-bold text-2xl text-center text-gray-800 pt-4 pb-4">Results</div>
            <div className="flex block uppercase tracking-wider font-bold my-2 text-base">
                <div className="w-1/4">Vehicle ID</div>
                <div className="w-1/4">Fields with Errors</div>
                <div className="w-1/4">Fields Rejected</div>
            </div>
            {!isEmpty(errorsAndRejections)
                ? Object.keys(errorsAndRejections).map((key: string, index: number) => (
                      <ExportLog key={key} log={errorsAndRejections[key]} />
                  ))
                : ""}
            {!(Object.keys(errorsAndRejections).length > 0) ? (
                <div
                    className={`border-l-4 border-r border-t border-b mt-4 p-4 flex items-center text-gray-800`}
                    style={{ borderLeftColor: "green" }}
                >
                    <div className="flex-grow text-base font-medium">
                        The latest export has no errors or rejections.
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default ExportLogs;
