import React from "react";
import Field from "../Elements/Field";
import TextInput from "../Elements/TextInput";
import { FormikErrors, FormikTouched } from "formik";

interface IProps {
    name: string;
    value?: string | undefined;
    label?: string;
    sublabel?: string;
    touched?: boolean | FormikTouched<any>;
    errors?: Array<string | FormikErrors<any>> | string;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    handleKeyDown?: any;
    disabled?: boolean;
    className?: string;
    style?: any;
    inputStyle?: { [key: string]: string };
    readOnly?: boolean;
    required?: boolean;
    type?: string;
    autoFocus?: boolean;
}

const TextField: React.FunctionComponent<IProps> = ({
    name,
    label,
    sublabel,
    value,
    handleChange,
    handleBlur,
    handleKeyDown,
    disabled,
    placeholder,
    className,
    style,
    inputStyle,
    required = false,
    readOnly = false,
    touched = handleBlur === undefined,
    errors = [],
    type = "text",
    autoFocus = false
}) => {
    if (placeholder === undefined) {
        placeholder = label;
    }

    return (
        <Field label={label} errors={errors} touched={touched} required={required} className={className} style={style}>
            {sublabel && <div className="text-xs -mt-2 mb-2">{sublabel}</div>}
            <TextInput
                autoFocus={autoFocus}
                ariaLabel={label}
                value={value}
                name={name}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                onBlur={handleBlur}
                placeholder={placeholder}
                readOnly={readOnly}
                inputStyle={inputStyle}
                type={type}
                isValid={touched === false || !errors.length}
            />
        </Field>
    );
};

export default TextField;
