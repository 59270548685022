import * as Constants from "../../constants/InventoryExportsConstants";
import { IInventoryExport, IFacebookAutoExport } from "../../interfaces/IRemarketing";
import { IInventoryExportState } from "../../sagas/Client/remarketingSagas";
import { IGoogleAutoExportFormValues } from "../../components/Client/Remarketing/GoogleExport";
import { FormikActions } from "formik";
import IFacebookAutoExportType from "../../interfaces/IFacebookAutoExport";
import IEntity from "../../interfaces/IEntity";
import {
    IFetchGoogleLogsAction,
    IFetchGoogleLogsSuccessAction,
    IFetchGoogleLogsFailureAction
} from "../../interfaces/IGoogleAutoExport";

export const createRemarketingInventoryExport = (
    inventoryExport: IInventoryExportState,
    clientId: number,
    setErrors: (errors: any) => void
) => ({
    type: Constants.REQUEST_CREATE_INVENTORY_EXPORT,
    inventoryExport,
    clientId,
    setErrors
});

export const fetchAllRemarketingInventoryExports = (clientId: number) => ({
    type: Constants.REQUEST_LOAD_INVENTORY_EXPORTS,
    clientId
});

export const loadRemarketingInventoryExport = (inventoryExport: IInventoryExport) => ({
    type: Constants.LOAD_INVENTORY_EXPORT,
    inventoryExport
});

export const updateAction = (
    inventoryExport: IInventoryExportState,
    clientId: number,
    setErrors: (errors: any) => void
) => ({
    type: Constants.REQUEST_UPDATE_INVENTORY_EXPORT,
    inventoryExport,
    clientId,
    setErrors
});

export const requestRemove = (clientId: number, exportId: number) => ({
    type: Constants.REQUEST_REMOVE_INVENTORY_EXPORT,
    clientId,
    exportId
});

export const removeAction = (exportId: number) => ({
    type: Constants.REMOVE_INVENTORY_EXPORT,
    exportId
});

export const savingCompleteAction = (exportId: number) => ({
    type: Constants.SAVING_COMPLETE,
    exportId
});

export const updateInventoryExportSuccess = (inventoryExport: IInventoryExport) => ({
    type: Constants.UPDATE_INVENTORY_EXPORT_SUCCESS,
    payload: { inventoryExport }
});

export const createInventoryExportSuccess = (inventoryExport: IInventoryExport) => ({
    type: Constants.CREATE_INVENTORY_EXPORT_SUCCESS,
    payload: { inventoryExport }
});

export const createFacebookExport = (
    facebookExport: IFacebookAutoExportType,
    inventoryExportId: number,
    clientId: number,
    formikActions: FormikActions<IFacebookAutoExportType>
) => ({
    type: Constants.REQUEST_CREATE_FACEBOOK_EXPORT,
    payload: { facebookExport, inventoryExportId, clientId, formikActions }
});

export const createFacebookExportSuccess = (facebookExport: IFacebookAutoExport) => ({
    type: Constants.CREATE_FACEBOOK_EXPORT_SUCCESS,
    payload: { facebookExport }
});

export const createFacebookExportFail = () => ({
    type: Constants.CREATE_FACEBOOK_EXPORT_FAIL
});

export const updateFacebookExport = (
    facebookExport: IFacebookAutoExportType,
    inventoryExportId: number,
    clientId: number,
    formikActions: FormikActions<IFacebookAutoExportType>
) => ({
    type: Constants.REQUEST_UPDATE_FACEBOOK_EXPORT,
    payload: { facebookExport, inventoryExportId, clientId, formikActions }
});

export const updateFacebookExportSuccess = (facebookExport: IFacebookAutoExport) => ({
    type: Constants.UPDATE_FACEBOOK_EXPORT_SUCCESS,
    payload: { facebookExport }
});

export const updateFacebookExportFail = () => ({
    type: Constants.UPDATE_FACEBOOK_EXPORT_FAIL
});

export const deleteFacebookExport = (facebookExportId: number, clientId: number) => ({
    type: Constants.REQUEST_DELETE_FACEBOOK_EXPORT,
    payload: { facebookExportId, clientId }
});

export const deleteFacebookExportSuccess = (id: number) => ({
    type: Constants.DELETE_FACEBOOK_EXPORT_SUCCESS,
    payload: { id }
});

export const deleteFacebookExportFail = () => ({
    type: Constants.DELETE_FACEBOOK_EXPORT_FAIL
});

export const createGoogleExport = (
    googleExport: IGoogleAutoExportFormValues,
    inventoryExportId: number,
    clientId: number,
    formikActions: FormikActions<IGoogleAutoExportFormValues>
) => ({
    type: Constants.REQUEST_CREATE_GOOGLE_EXPORT,
    payload: { googleExport, inventoryExportId, clientId, formikActions }
});

export const createGoogleExportSuccess = (googleExport: IGoogleAutoExportFormValues) => ({
    type: Constants.CREATE_GOOGLE_EXPORT_SUCCESS,
    payload: { googleExport }
});

export const createGoogleExportFail = () => ({
    type: Constants.CREATE_GOOGLE_EXPORT_FAIL
});

export const updateGoogleExport = (
    googleExport: IGoogleAutoExportFormValues,
    inventoryExportId: number,
    clientId: number,
    formikActions: FormikActions<IGoogleAutoExportFormValues>
) => ({
    type: Constants.REQUEST_UPDATE_GOOGLE_EXPORT,
    payload: { googleExport, inventoryExportId, clientId, formikActions }
});

export const updateGoogleExportSuccess = (googleExport: IGoogleAutoExportFormValues) => ({
    type: Constants.UPDATE_GOOGLE_EXPORT_SUCCESS,
    payload: { googleExport }
});

export const updateGoogleExportFail = () => ({
    type: Constants.UPDATE_GOOGLE_EXPORT_FAIL
});

export const deleteGoogleExport = (googleExportId: number, clientId: number) => ({
    type: Constants.REQUEST_DELETE_GOOGLE_EXPORT,
    payload: { googleExportId, clientId }
});

export const deleteGoogleExportSuccess = (googleExportId: number) => ({
    type: Constants.DELETE_GOOGLE_EXPORT_SUCCESS,
    payload: { googleExportId }
});

export const deleteGoogleExportFail = () => ({
    type: Constants.DELETE_GOOGLE_EXPORT_FAIL
});

export const fetchGoogleLogs = (clientId: number, exportId: number): IFetchGoogleLogsAction => ({
    type: Constants.FETCH_GOOGLE_LOGS,
    payload: {
        clientId,
        exportId
    }
});

export const fetchGoogleLogsSuccess = (logs: IEntity<any>, allIds: number[]): IFetchGoogleLogsSuccessAction => ({
    type: Constants.FETCH_GOOGLE_LOGS_SUCCESS,
    payload: {
        logs,
        allIds
    }
});

export const fetchGoogleLogsFailure = (error: any): IFetchGoogleLogsFailureAction => ({
    type: Constants.FETCH_GOOGLE_LOGS_FAILURE,
    payload: {
        error
    }
});
