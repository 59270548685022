import React from "react";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "../../../Shared/Icons/ChevronRightIcon";

interface IProps {
    title: string;
    subTitles: string[];
    path: string;
    isTouched: boolean;
    isValid: boolean;
    isDisabled: boolean;
    changePageRef: any;
}

const subTitleStyles = {
    maxHeight: 0
};

const subTitleActiveStyles = {
    maxHeight: "500px",
    transition: "max-height 0.25s ease-in"
};

const sidebarItemClasses =
    "flex flex-col no-underline whitespace-no-wrap focus:outline-none focus:no-underline sidebar-item text-gray-800 border-b-2 border-gray-300";

const DealerSetupSidebarItem: React.FunctionComponent<IProps> = ({
    path,
    title,
    subTitles,
    isTouched,
    isValid,
    isDisabled,
    changePageRef
}) => {
    const location = useLocation();
    const isCurrent = path === location.pathname.split("/").pop();
    let isOpen = isCurrent;

    const Indicator = () => {
        const classNames: [string] = ["circle"];

        if (isCurrent) {
            classNames.push("current");
        } else {
            if (!isDisabled && isTouched) {
                if (isValid) {
                    classNames.push("valid");
                } else {
                    classNames.push("invalid");
                }
            }
        }

        return <span className={classNames.join(" ")} />;
    };

    const changePage = (e: any) =>
        !isDisabled ? changePageRef.current && changePageRef.current.changePage() : e.preventDefault();

    return (
        <Link
            to={path}
            className={isCurrent ? `${sidebarItemClasses} active` : sidebarItemClasses}
            onClick={changePage}
        >
            <div
                className={`w-full h-full flex items-top px-2 py-4 ${
                    isDisabled ? "opacity-25 cursor-not-allowed" : ""
                } ${isCurrent ? "bg-gray-100" : ""}`}
            >
                <div className="w-8 mx-2 mt-1">
                    <Indicator />
                </div>
                <div className="flex flex-col flex-grow">
                    <div className="m-0 text-left w-full text-base">{title}</div>
                    {isCurrent && (
                        <div
                            className={`flex flex-col mt-4 ${isCurrent ? "overflow-hidden" : ""}`}
                            style={isCurrent ? subTitleActiveStyles : subTitleStyles}
                        >
                            {subTitles.map((t) => (
                                <p key={t} className={`block w-full my-1 `}>
                                    {t}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
                <div className="mr-4 text-center">
                    <ChevronRightIcon
                        className="h-6 w-6"
                        style={{ transition: ".2s ease", ...(isOpen ? { transform: "rotate(90deg)" } : {}) }}
                    />
                </div>
            </div>
        </Link>
    );
};

export default DealerSetupSidebarItem;
