export default {
    REQUEST_CARS_BUDGETS: "REQUEST_CARS_BUDGETS",
    REQUEST_CARS_BUDGETS_SUCCESS: "REQUEST_CARS_BUDGETS_SUCCESS",
    REQUEST_CARS_BUDGETS_FAIL: "REQUEST_CARS_BUDGETS_FAIL",

    REQUEST_CLIENT_CARS_BUDGETS: "REQUEST_CLIENT_CARS_BUDGETS",
    REQUEST_CLIENT_CARS_BUDGETS_SUCCESS: "REQUEST_CLIENT_CARS_BUDGETS_SUCCESS",
    REQUEST_CLIENT_CARS_BUDGETS_FAIL: "REQUEST_CLIENT_CARS_BUDGETS_FAIL",

    REQUEST_CARS_BUDGETS_SPINNER: "REQUEST_CARS_BUDGETS_SPINNER",
    LOADING_CARS_BUDGETS: "LOADING_CARS_BUDGETS"
};
