import React, { useEffect } from "react";
import { TabLinks } from "../../Shared";
import { connect } from "react-redux";
import GoogleCampaignContainer from "./AdWords/CampaignContainer";
import MicrosoftCampaignContainer from "./Microsoft/CampaignContainer";
import { isUserInternal } from "../../../reducers/currentUser";
import { Link, Routes, Route } from "react-router-dom";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import AddIcon from "../../Shared/Icons/AddIcon";
import IndexPage from "./TrackingParameters/IndexPage";
import IEntity from "../../../interfaces/IEntity";
import IAppState from "../../../interfaces/IAppState";
import ITrackingParameter from "../../../interfaces/ITrackingParameter";
import IClient from "../../../interfaces/IClient";
import NewTrackingParameter from "./TrackingParameters/Form/NewTrackingParameter";
import EditTrackingParameter from "./TrackingParameters/Form/EditTrackingParameter";
import { fetchTrackingParameters } from "../../../actions/trackingParameterActions";
import { IOption } from "../../../interfaces/IDisplayCampaign";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import RefreshCampaignsButton from "../../Shared/Campaign/RefreshCampaignsButton";
import { createParametersClicked } from "../../../actions/campaignActions";
import InventorySearchAdsList from "../InventorySearchAds/InventorySearchAdsList";

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.entities[state.clients.currentClient],
        isInternalUser: isUserInternal(state.currentUser),
        trackingParameters: state.trackingParameters,
        platformOptions: {
            google: { value: "google", label: "Google Tracking Parameters" },
            microsoft: { value: "microsoft", label: "Microsoft Tracking Parameters" }
        },
        googleAdCampaignsLoading: state.adwordsCampaigns.loading
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchTrackingParameters: (client: IClient) => {
        if (client && client?.id) {
            dispatch(fetchTrackingParameters(client.id));
        }
    },
    trackCreateParametersClicked: (clientId: number, client: IClient) => {
        dispatch(createParametersClicked(clientId, client));
    }
});

interface IProps {
    client: IClient;
    clientId: number;
    fetchTrackingParameters: (client: IClient) => void;
    trackCreateParametersClicked: (clientId: number, client: IClient) => void;

    trackingParameters: {
        entities: IEntity<ITrackingParameter>;
        loading: boolean;
    };
    platformOptions: {
        [key: string]: IOption;
    };
}

const CampaignsPageRouter: React.FunctionComponent<IProps> = ({
    client,
    clientId,
    fetchTrackingParameters,
    trackCreateParametersClicked,
    trackingParameters,
    platformOptions
}) => {
    useEffect(() => {
        if (!client) {
            return;
        }

        fetchTrackingParameters(client);
    }, [client, fetchTrackingParameters]);

    if (trackingParameters.loading) {
        return <FullPageLoader message={`Loading tracking parameters for ${client.name}`} />;
    }

    return (
        <div className="mx-4">
            <div className="flex flex-col w-full">
                <PageTitle title={client.name + " Campaigns"}>
                    <div className="flex flex-wrap items-center flex-1">
                        <RefreshCampaignsButton
                            shouldRefreshAdwords={!!client.adwordsId}
                            shouldRefreshMicrosoft={!!client.bingId}
                        />

                        <Link
                            to={`/client/${client.id}/campaigns/tracking-parameters/new`}
                            className="px-4 py-2 bg-blue-500 hover:text-white active:text-white rounded text-blue-100 flex items-center"
                            onClick={() => trackCreateParametersClicked(clientId, client)}
                        >
                            <AddIcon className="w-6 h-6" />

                            <span className="ml-2 block tracking-wide">Create Tracking Parameters</span>
                        </Link>
                    </div>
                </PageTitle>
                <div className="mx-2">
                    <TabLinks
                        tabs={[
                            {
                                label: "Google",
                                path: "google",
                                show: !!client.adwordsId
                            },
                            {
                                label: "Microsoft",
                                path: "microsoft",
                                show: !!client.bingId
                            },
                            {
                                label: "Tracking Parameters",
                                path: "tracking-parameters",
                                show: !!client.adwordsId || !!client.bingId
                            },
                            {
                                label: "Inventory Search Ads",
                                path: "inventory-search-ads",
                                show: !!client?.koddiId
                            }
                        ]}
                    />
                </div>
                <div className="bg-white shadow rounded">
                    <Routes>
                        <Route element={<GoogleCampaignContainer />} path={"google"} />
                        <Route element={<MicrosoftCampaignContainer />} path={"microsoft"} />
                        <Route element={<IndexPage />} path={"tracking-parameters"} />
                        <Route element={<InventorySearchAdsList />} path={"inventory-search-ads"} />
                        <Route
                            element={<NewTrackingParameter platformOptions={platformOptions} />}
                            path={"tracking-parameters/new"}
                        />
                        <Route
                            element={<EditTrackingParameter platformOptions={platformOptions} />}
                            path={"tracking-parameters/:trackingParameterId"}
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsPageRouter);
