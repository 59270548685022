import React, { SVGProps } from "react";
import Svg from "./Svg";

const DisplayIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M20.083 18.75c.917 0 1.667-.75 1.667-1.667V6.667C21.75 5.75 21 5 20.083 5H5.917C5 5 4.25 5.75 4.25 6.667v10.416c0 .917.75 1.667 1.667 1.667H3C3 19.667 5.5 20 6.75 20h12.5c1.25 0 3.75-.333 3.75-1.25h-2.917zM5.917 6.667h14.166v10.625H5.917V6.667zM13 19.479a.836.836 0 0 1-.833-.833c0-.458.375-.834.833-.834.458 0 .833.376.833.834a.836.836 0 0 1-.833.833z" />
    </Svg>
);

export default DisplayIcon;
