export default {
    FETCH_USERS: "FETCH_USERS",
    FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
    FETCH_USERS_FAIL: "FETCH_USERS_FAIL",

    REQUEST_DELETE_USER: "REQUEST_DELETE_USER",
    REQUEST_DELETE_USER_SUCCESS: "REQUEST_DELETE_USER_SUCCESS",
    REQUEST_DELETE_USER_FAIL: "REQUEST_DELETE_USER_FAIL",

    REQUEST_CREATE_USER: "REQUEST_CREATE_USER",
    REQUEST_CREATE_USER_SUCCESS: "REQUEST_CREATE_USER_SUCCESS",
    REQUEST_CREATE_USER_FAIL: "REQUEST_CREATE_USER_FAIL",

    REQUEST_USER_ROLES: "REQUEST_USER_ROLES",
    REQUEST_USER_ROLES_SUCCESS: "REQUEST_USER_ROLES_SUCCESS",
    REQUEST_USER_ROLES_FAIL: "REQUEST_USER_ROLES_FAIL",

    REQUEST_USER: "REQUEST_USER",
    REQUEST_USER_SUCCESS: "REQUEST_USER_SUCCESS",
    REQUEST_USER_FAIL: "REQUEST_USER_FAIL",

    REQUEST_UPDATE_USER: "REQUEST_UPDATE_USER",
    REQUEST_UPDATE_USER_SUCCESS: "REQUEST_UPDATE_USER_SUCCESS",
    REQUEST_UPDATE_USER_FAIL: "REQUEST_UPDATE_USER_FAIL",

    REQUEST_ACTIVATE_USER: "REQUEST_ACTIVATE_USER",
    REQUEST_ACTIVATE_USER_SUCCESS: "REQUEST_ACTIVATE_USER_SUCCESS",
    REQUEST_ACTIVATE_USER_FAIL: "REQUEST_ACTIVATE_USER_FAIL",

    REQUEST_SAVE_SETTINGS: "REQUEST_SAVE_SETTINGS",
    REQUEST_SAVE_SETTINGS_SUCCESS: "REQUEST_SAVE_SETTINGS_SUCCESS",
    REQUEST_SAVE_SETTINGS_FAIL: "REQUEST_SAVE_SETTINGS_FAIL",

    REQUEST_RESET_PASSWORD_EMAIL: "REQUEST_RESET_PASSWORD_EMAIL",
    REQUEST_RESET_PASSWORD_EMAIL_SUCCESS: "REQUEST_RESET_PASSWORD_EMAIL_SUCCESS",
    REQUEST_RESET_PASSWORD_EMAIL_FAIL: "REQUEST_RESET_PASSWORD_EMAIL_FAIL",

    REQUEST_RESET_PASSWORD: "REQUEST_RESET_PASSWORD",
    REQUEST_RESET_PASSWORD_SUCCESS: "REQUEST_RESET_PASSWORD_SUCCESS",
    REQUEST_RESET_PASSWORD_FAIL: "REQUEST_RESET_PASSWORD_FAIL"
};
