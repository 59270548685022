import React, { useState } from "react";
import Label from "../../Shared/Form/Label";
import Button, { SECONDARY_BUTTON, PRIMARY_BUTTON } from "../../Shared/Button";
import * as Yup from "yup";
import { ConditionalOperators, getDcParameterFields } from "../../../utils/InventoryUtils";
import { IConditional } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import InventoryFilter from "./InventoryFilter";
import AddIcon from "../../Shared/Icons/AddIcon";

const fields = getDcParameterFields();
interface IEtaProps {
    conditionals: IConditional[];
    setConditionals: (conditionals: IConditional[]) => void;
    templateIndex: number;
    hide: any;
    label: string;
}

const displayErrors = (field: string, errors: any) => {
    const fieldErrors = errors.filter((error: any) => error.path === field);
    return fieldErrors.length > 0
        ? fieldErrors[0].errors.map((message: string, index: number) => (
              <div key={index} className="text-red-600 pb-4">
                  {message}
              </div>
          ))
        : null;
};

const ConditionalsSchema = Yup.object().shape({
    comparator: Yup.string()
        .oneOf(
            ConditionalOperators.map((c) => c.value),
            "Invalid Comparator!"
        )
        .max(199, "Too Long!")
        .required("Comparator Required"),
    value: Yup.string().max(199, "Too Long!").required("Value Required"),
    field: Yup.string().oneOf(getDcParameterFields(), "Invalid Field!").required("Field Required")
});

const EtaConditionals: React.FunctionComponent<IEtaProps> = ({
    hide,
    conditionals,
    setConditionals,
    templateIndex,
    label
}) => {
    const [conditions, setConditions] = useState<IConditional[]>(conditionals);

    const handleSetConditionals = (confirmedConditions: IConditional[]) => {
        let allPass = true;

        confirmedConditions.forEach((condition: IConditional, index: number) => {
            if (condition.deleted) {
                return;
            }
            try {
                ConditionalsSchema.validateSync(condition, { abortEarly: false });
                updateCondition(index, { ...condition, errors: [] });
            } catch (error) {
                updateCondition(index, { ...condition, errors: [...error.inner] });
                allPass = false;
            }
        });

        if (allPass) {
            setConditionals(confirmedConditions);
            hide();
        }
    };

    const addCondition = () => {
        const newCondition: IConditional = {
            field: "",
            comparator: "",
            value: "",
            id: Date.now(),
            new: true,
            dirty: true
        };
        setConditions((currentConditions: IConditional[]) => {
            return [...currentConditions, newCondition];
        });
    };

    const updateCondition = (idx: number, { field, value, comparator, deleted = false, errors = [] }: IConditional) => {
        setConditions((currentConditions: IConditional[]) => {
            const updatedCondition = {
                ...currentConditions[idx],
                field,
                value,
                comparator,
                deleted,
                errors,
                dirty: true
            };
            return [...currentConditions.slice(0, idx), updatedCondition, ...currentConditions.slice(idx + 1)];
        });
    };

    const removeCondition = (idx: number, condition: IConditional) => {
        const updated = {
            ...condition,
            deleted: true
        };
        updateCondition(idx, updated);
    };

    return (
        <div className="h-100 w-100 bg-white z-51">
            <Label label={label} />

            {conditions.map((conditional: IConditional, index: number) => {
                const { errors = [] } = conditional;
                const fieldName = `expandedTextAdTemplates[${templateIndex}][conditionals][${index}]`;
                return (
                    <React.Fragment key={conditional.id}>
                        <InventoryFilter
                            fields={fields}
                            condition={conditional}
                            onChange={(item: IConditional) => {
                                updateCondition(index, item);
                            }}
                            onRemove={() => {
                                removeCondition(index, conditional);
                            }}
                            setFieldTouched={() => {}}
                            names={{
                                comparator: `${fieldName}.comparator`,
                                field: `${fieldName}.field`,
                                value: `${fieldName}.value`
                            }}
                            errors={{}}
                        />
                        {displayErrors("field", errors)}
                        {displayErrors("comparator", errors)}
                        {displayErrors("value", errors)}
                    </React.Fragment>
                );
            })}

            <Button onClick={addCondition} type="button" styleType={SECONDARY_BUTTON} styles="my-4">
                <div className="flex items-center">
                    <AddIcon className="w-6 h-6" />
                    <span className="ml-2">Add Template Conditional</span>
                </div>
            </Button>

            <div className="flex flex-right items-center mb-2">
                <Button onClick={hide} type="button" styleType={SECONDARY_BUTTON}>
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSetConditionals(conditions)}
                    type="button"
                    styleType={PRIMARY_BUTTON}
                    styles="ml-4"
                >
                    Okay
                </Button>
            </div>
        </div>
    );
};

export default EtaConditionals;
