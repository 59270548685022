import * as React from "react";
import { connect, FormikContext } from "formik";
import IDynamicCampaignFormValue from "../../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import SettingsIcon from "../../../../../Shared/Icons/SettingsIcon";
import KeywordTemplateConditionals from "../KeywordTemplateConditionals/KeywordTemplateConditionals";
import ModalToggle from "../../../../../Shared/Dialogs/ModalToggle";

type Props = {
    keywordTemplateRelationshipIndex: number;
};

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const ConditionalsButton = ({
    keywordTemplateRelationshipIndex,
    formik: {
        values: {
            keywordTemplateRelationships: {
                [keywordTemplateRelationshipIndex]: {
                    conditionals,
                    keywordTemplate: { isMaster, conditionals: masterConditionals }
                }
            }
        }
    }
}: Props & ContextProps) => {
    const fieldPath = `keywordTemplateRelationships[${keywordTemplateRelationshipIndex}]`;

    let iconColor = "text-gray-500";

    if (
        (isMaster && masterConditionals?.length) ||
        (!isMaster && !!conditionals?.filter((conditional) => !conditional.deleted).length)
    ) {
        iconColor = "text-green-500";
    }

    return (
        <ModalToggle
            toggleButton={(openModal) => (
                <button
                    type="button"
                    data-testid={`conditional-button-${fieldPath}`}
                    className={`px-4 py-2 hover:text-green-600 hover:bg-gray-200 ${iconColor}`}
                    onClick={openModal}
                    title="Conditionals"
                >
                    <SettingsIcon className="w-6 h-6" />
                </button>
            )}
            modalContent={(closeModal) => (
                <KeywordTemplateConditionals
                    keywordTemplateRelationshipIndex={keywordTemplateRelationshipIndex}
                    closeModal={closeModal}
                />
            )}
        />
    );
};

export default connect<Props, any>(ConditionalsButton);
