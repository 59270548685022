import React from "react";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import { Link } from "react-router-dom";
import AddIcon from "../../Shared/Icons/AddIcon";
import { primaryClasses, baseStyles } from "../../Shared/Button";

interface IProps {
    showButton: boolean;
}

const Header: React.FC<IProps> = ({ showButton }) => {
    return (
        <PageTitle title="Dynamic Campaigns">
            {showButton && (
                <Link to={`new`} className={`${primaryClasses} ${baseStyles} flex items-center`}>
                    <AddIcon className="w-6 h-6" />
                    <span className="ml-2">New Dynamic Campaign</span>
                </Link>
            )}
        </PageTitle>
    );
};

export default Header;
