import * as actions from "../../actions/salesData";
import { AnyAction, combineReducers } from "redux";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";

const loading = (state = false, action: AnyAction) => {
    switch (action.type) {
        case actions.REQUEST_SALES_DATA:
            return true;
        default:
            return false;
    }
};

const sales = (state = [], action: AnyAction) => {
    switch (action.type) {
        case actions.LOAD_SALES_DATA:
            return action.resp.salesData;
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};
const mapCenter = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.LOAD_SALES_DATA:
            return action.resp.mapCenter;
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};
const filterCounts = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.LOAD_SALES_DATA:
            return action.resp.parameterOptions;
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    sales,
    mapCenter,
    filterCounts
});
