import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RefreshIcon from "../Icons/RefreshIcon";
import { useDispatch, useStore } from "react-redux";
import IClient from "../../../interfaces/IClient";
import { fetchCampaigns, fetchMicrosoftCampaigns, refreshCampaignClicked } from "../../../actions/campaignActions";
import IAppState from "../../../interfaces/IAppState";
import { shouldComponentUpdate } from "react-window";

const mapStateToProps = (state: IAppState, props: any) => {
    return {
        client: state.clients.entities[state.clients.currentClient],
        adCampaignsLoading: state.adwordsCampaigns.loading
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    forceFetchCampaigns: (client: IClient) => dispatch(fetchCampaigns(client.id, true)),
    forceFetchMicrosoftCampaigns: (client: IClient) => dispatch(fetchMicrosoftCampaigns(client.id, true)),
    trackRefreshCampaignClicked: (clientId: number, client: IClient) =>
        dispatch(refreshCampaignClicked(clientId, client))
});

interface IProps {
    clientId: number;
    client: IClient;
    shouldRefreshAdwords?: boolean;
    shouldRefreshMicrosoft?: boolean;
    forceFetchCampaigns: (client: IClient) => void;
    forceFetchMicrosoftCampaigns: (client: IClient) => void;
    adCampaignsLoading: boolean;
    trackRefreshCampaignClicked: (clientId: number, client: IClient) => void;
}

const RefreshCampaignsButton: React.FunctionComponent<IProps> = ({
    clientId,
    client,
    shouldRefreshAdwords,
    shouldRefreshMicrosoft,
    forceFetchCampaigns,
    forceFetchMicrosoftCampaigns,
    trackRefreshCampaignClicked,
    adCampaignsLoading
}) => {
    const [buttonMessage, setButtonMessage] = useState("");

    useEffect(() => {
        if (shouldRefreshAdwords && shouldRefreshMicrosoft) {
            setButtonMessage("Refresh All Campaigns");
        } else if (shouldRefreshAdwords) {
            setButtonMessage("Refresh Adwords Campaigns");
        } else {
            setButtonMessage("Refresh Microsoft Campaigns");
        }
    }, [shouldRefreshAdwords, shouldRefreshMicrosoft]);

    return (
        <a
            className="bg-white hover:bg-black-700 border border-gray-400 text-gray-800 p-1 mr-4 cursor-pointer rounded flex items-center"
            onClick={() => {
                trackRefreshCampaignClicked(clientId, client);
                if (shouldRefreshAdwords) {
                    forceFetchCampaigns(client);
                }
                if (shouldRefreshMicrosoft) {
                    forceFetchMicrosoftCampaigns(client);
                }
            }}
        >
            <RefreshIcon className={`w-6 h-6 inline-block ${adCampaignsLoading ? "rotate-fast" : ""}`} />
            <span className="ml-2 mr-2 block tracking-wide">{buttonMessage}</span>
        </a>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshCampaignsButton);
