import React, { SVGProps } from "react";
import Svg from "./Svg";

const EllipsisIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M14.5 6.25A2.256 2.256 0 0 0 12.25 4 2.256 2.256 0 0 0 10 6.25a2.256 2.256 0 0 0 2.25 2.25 2.256 2.256 0 0 0 2.25-2.25zm0 13.5a2.256 2.256 0 0 0-2.25-2.25A2.256 2.256 0 0 0 10 19.75 2.256 2.256 0 0 0 12.25 22a2.256 2.256 0 0 0 2.25-2.25zm0-6.75a2.256 2.256 0 0 0-2.25-2.25A2.256 2.256 0 0 0 10 13a2.256 2.256 0 0 0 2.25 2.25A2.256 2.256 0 0 0 14.5 13z" />
    </Svg>
);

export default EllipsisIcon;
