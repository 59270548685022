import { schema } from "normalizr";

const keywordTemplate = new schema.Entity("keywordTemplate");
const keywordBid = new schema.Entity("bids");
const dcKeywordTemplates = new schema.Entity("dcKeywordTemplates", {
    keywordTemplate
});
const keywordTemplateConditionals = new schema.Entity("conditionals");

const Schemas = {
    KEYWORD_TEMPLATES: { data: [dcKeywordTemplates] },
    TEMPLATE_CONDITIONALS: { data: [keywordTemplateConditionals] },
    BIDS: { data: [keywordBid] }
};

export default Schemas;
