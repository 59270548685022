import React, { FunctionComponent, ChangeEvent } from "react";
import IClient from "../../../../interfaces/IClient";
import TextField from "../../../Shared/Form/Blocks/TextField";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import Button, { DANGER_BUTTON, NEGATIVE_BUTTON } from "../../../Shared/Button";
import CloseIcon from "../../../Shared/Icons/CloseIcon";

interface ICrmEmail {
    client: IClient;
    idx: number;
    email: string;
    errors: any;
    onValueChange(event: ChangeEvent<any>): void;
}

interface IConfirmDeleteEmail {
    onRemove(): void;
}

const ConfirmDeleteEmail: FunctionComponent<IConfirmDeleteEmail> = ({ onRemove }) => {
    const handleRemove = (hide: () => void) => {
        onRemove();
        hide();
    };
    return (
        <ModalToggle
            style={{ width: "25%" }}
            toggleButton={(open: () => void) => (
                <button
                    onClick={open}
                    type="button"
                    className="px-4 py-2 mt-4 mb-2 bg-red-500 hover:bg-red-700 text-white"
                >
                    <CloseIcon className="w-6 h-6" />
                </button>
            )}
            modalContent={(hide: () => void) => {
                return (
                    <>
                        <h2 className="text-2xl font-semibold">Are you sure you want to delete this email?</h2>
                        <p className="py-6">This will remove the email.</p>
                        <div className="flex justify-end">
                            <Button onClick={hide} styleType={NEGATIVE_BUTTON}>
                                Cancel
                            </Button>
                            <Button onClick={() => handleRemove(hide)} styleType={DANGER_BUTTON}>
                                Okay
                            </Button>
                        </div>
                    </>
                );
            }}
        />
    );
};

const CrmEmail: FunctionComponent<ICrmEmail> = ({ client, onValueChange, idx, email, errors }) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const emails = client.crmEmail || [];
        emails[idx] = e.target.value;
        onValueChange({ target: { value: emails, name: "crmEmail" } } as ChangeEvent<any>);
    };

    const onRemove = () => {
        onValueChange({
            target: {
                name: "crmEmail",
                value: [...client.crmEmail?.slice(0, idx), ...client.crmEmail?.slice(idx + 1)]
            }
        } as ChangeEvent<any>);
    };

    return (
        <>
            <div className="flex">
                <TextField name={`crmEmail[${idx}]`} value={email} handleChange={onChange} className="w-full" />
                <ConfirmDeleteEmail onRemove={onRemove} />
            </div>
        </>
    );
};

export default CrmEmail;
