import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IAppState from "../../interfaces/IAppState";

const LOGOUT_WARNING_THRESHOLD = 15 * 60; // In seconds
const WARNING_TO_DANGER_THRESHOLD = 10 * 60; // In Seconds

const TokenLogoutWarning: React.FC<{
    authenticationExpiresAt: Moment | null;
    getCurrentTime: () => Date | Moment;
    logout: () => void;
}> = ({ authenticationExpiresAt, getCurrentTime, logout }) => {
    // This will only ever show if it's less than 15 to start, so it'll be yellow for 5 minutes, then turn red for the last 10.
    const [timeLeftInSeconds, setTimeLeftInSeconds] = useState(null as number | null);
    const [message, setMessage] = useState("");
    useEffect(() => {
        if (!authenticationExpiresAt) {
            return;
        }

        const interval = setInterval(() => {
            const diffInSeconds = Math.max(0, authenticationExpiresAt?.diff(getCurrentTime(), "seconds") ?? 0);
            setTimeLeftInSeconds(diffInSeconds);

            const time = diffInSeconds > 60 ? Math.round(diffInSeconds / 60) : diffInSeconds;
            let timeLeftLabel = "minutes";
            if (diffInSeconds <= 60) {
                if (diffInSeconds === 1) {
                    timeLeftLabel = "second";
                } else {
                    timeLeftLabel = "seconds";
                }
            } else if (time === 1) {
                timeLeftLabel = "minute";
            }

            setMessage(`You have ${time} ${timeLeftLabel} left until you're logged out`);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [authenticationExpiresAt]);

    if (timeLeftInSeconds === null || timeLeftInSeconds > LOGOUT_WARNING_THRESHOLD) {
        return <div />;
    }

    const logoutColor = timeLeftInSeconds > WARNING_TO_DANGER_THRESHOLD ? "orange" : "red";

    return (
        <div className={`fixed left-0 right-0 top-0 h-16 z-90 mt-20`}>
            <div
                className={`rounded bg-white gap-2 max-w-2xl mx-auto flex flex-col justify-between border-2 border-${logoutColor}-500 p-2`}
            >
                <div className="font-semibold m-0 text-base">You're about to be logged out!</div>
                <div className="m-0 leading-snug">
                    {message}, <span className="font-bold">please save your work</span>.
                </div>
                <div className="flex justify-end text-sm">
                    <button
                        className={`inline-block text-white pointer-events-auto py-1 px-2 bg-blue-500 rounded flex items-center gap-1 hover:bg-blue-600`}
                        data-testid={"logout-button-for-expired-token"}
                        onClick={() => {
                            // eslint-disable-next-line no-restricted-globals
                            const confirmation = confirm(
                                "Please make sure you have saved your work, you will be returned to this page after logging in."
                            );
                            if (!confirmation) {
                                return;
                            }
                            logout();
                        }}
                    >
                        Keep me signed in
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect((state: IAppState) => ({
    authenticationExpiresAt: state.authentication.authenticationExpiresAt
}))(TokenLogoutWarning);
