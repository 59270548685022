import { call, all } from "redux-saga/effects";
import { callApi } from "../../middleware/api";
import { decamelizeKeys } from "humps";
import { isEmpty } from "lodash";
import { IAdGroupBidModifier, IConditional } from "../../interfaces/DynamicCampaigns/IDynamicCampaign";

import { wrapper } from "../../utils/DynamicCampaignUtils";

interface ICondtionsPayload {
    conditionals: IConditional[];
    dynamicCampaignId: number;
    adGroupBidModifierId: number;
    clientId: number;
}

interface IAdGroupBidModifiersPayload {
    adGroupBidModifiers: IAdGroupBidModifier[];
    clientId: number;
    dynamicCampaignId: number;
}

interface IAction<T> {
    type: string;
    payload: T;
}

function* saveAdGroupBidModifiers({ payload }: IAction<IAdGroupBidModifiersPayload>): any {
    const { clientId, dynamicCampaignId, adGroupBidModifiers } = payload;
    const baseUrl = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/ad-group-bid-modifiers`;
    const modifierResponses: IAdGroupBidModifier[] = [];
    const errors: { [key: string]: string } = {};

    for (const [index, item] of adGroupBidModifiers.entries()) {
        const { conditionals, ...modifier } = item;
        modifier.dynamicCampaignId = dynamicCampaignId;

        if (!item.dirty) {
            modifierResponses.push(item);
        } else {
            switch (true) {
                case modifier.deleted && modifier.new: {
                    break;
                }
                case modifier.deleted: {
                    const [modifierError, modifierResponse] = yield wrapper(
                        call(callApi, `${baseUrl}/${modifier.id}`, {}, "DELETE")
                    );
                    if (modifierError) {
                        errors[`adGroupBidModifiers[${index}]`] = modifierError;
                        modifierResponses.push(item);
                    }
                    break;
                }
                case modifier.new: {
                    const [modifierError, modifierResponse] = yield wrapper(
                        call(callApi, baseUrl, {}, "POST", decamelizeKeys(modifier))
                    );
                    if (modifierError) {
                        errors[`adGroupBidModifiers[${index}]`] = modifierError;
                        modifierResponses.push(item);
                    } else {
                        const modifierWithConditions = yield handleConditions(
                            modifierError,
                            modifierResponse,
                            conditionals,
                            clientId
                        );
                        modifierResponses.push(modifierWithConditions);
                    }
                    break;
                }
                default: {
                    const [modifierError, modifierResponse] = yield wrapper(
                        call(callApi, `${baseUrl}/${modifier.id}`, {}, "PATCH", decamelizeKeys(modifier))
                    );
                    if (modifierError) {
                        errors[`adGroupBidModifiers[${index}]`] = modifierError;
                        modifierResponses.push(item);
                    } else {
                        const modifierWithConditions = yield handleConditions(
                            modifierError,
                            modifierResponse,
                            conditionals,
                            clientId
                        );
                        modifierResponses.push(modifierWithConditions);
                    }
                }
            }
        }
    }
    return [Object.entries(errors).length > 0 ? errors : false, modifierResponses];
}

function* saveConditionals({ payload }: IAction<ICondtionsPayload>) {
    const { clientId, adGroupBidModifierId, dynamicCampaignId, conditionals } = payload;
    const baseUrl = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/ad-group-bid-modifiers/${adGroupBidModifierId}/conditionals`;
    const saves = conditionals.map((conditional: IConditional) => {
        conditional.adGroupBidModifierId = adGroupBidModifierId;
        if (conditional.new) {
            if (conditional.deleted) {
                return false;
            }
            return wrapper(call(callApi, baseUrl, {}, "POST", decamelizeKeys(conditional)));
        }
        if (conditional.deleted) {
            return wrapper(call(callApi, `${baseUrl}/${conditional.id}`, {}, "DELETE"));
        }
        return wrapper(call(callApi, `${baseUrl}/${conditional.id}`, {}, "PATCH", decamelizeKeys(conditional)));
    });
    const results: [] = yield all(saves);
    const [errors, saved] = results.reduce(
        ([errors, responses]: any, [error, response]: [{}, any], index: number) => {
            if (error) {
                responses.push(conditionals[index]);
                errors[`adGroupBidModifiers[${index}]`] = error;
            } else if (!isEmpty(response)) {
                responses.push(response);
            }
            return [errors, responses];
        },
        [{}, []]
    );
    return [Object.entries(errors).length > 0 ? errors : false, saved];
}

function* handleConditions(modifierError: any, modifier: any, conditionals: IConditional[], clientId: number): any {
    if (modifierError) {
        return modifierError;
    }
    const [conditionalErrors, savedConditionals] = yield call(saveConditionals, {
        type: "",
        payload: {
            clientId,
            conditionals,
            dynamicCampaignId: modifier.dynamicCampaignId,
            adGroupBidModifierId: modifier.id
        }
    });
    return {
        ...modifier,
        conditionals: conditionalErrors ? conditionalErrors : savedConditionals
    };
}

export { saveAdGroupBidModifiers };
