import Api from "../components/SalesMap/SalesDataStore";
import defaultRanges from "../components/SalesMap/DefaultDateRanges";
import moment from "moment";
import _ from "lodash";
import Schemas from "../middleware/schemas/adwordsBidAdjustments";
import { CALL_API, doDeleteRequest, callApi } from "../middleware/api";
import IAppState from "../interfaces/IAppState";
import { Range } from "react-date-range";
import IClient from "../interfaces/IClient";

export const LOAD_FILTER_OPTIONS = "LOAD_FILTER_OPTIONS";
export const TOGGLE_FILTER_OPTION = "TOGGLE_FILTER_OPTION";
export const REQUEST_SALES_DATA = "REQUEST_SALES_DATA";
export const LOAD_SALES_DATA = "LOAD_SALES_DATA";
export const REQUEST_LOCATION_DATA = "REQUEST_LOCATION_DATA";
export const REQUEST_LOCATION_DATA_FAILED = "REQUEST_LOCATION_DATA_FAILED";
export const LOAD_LOCATION_DATA = "LOAD_LOCATION_DATA";
export const LOAD_LOCATION_STATS = "LOAD_LOCATION_STATS";
export const UPDATE_MAP_BOUNDS = "UPDATE_MAP_BOUNDS";
export const UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE";
export const PULL_DATA_FROM_DI_CLICKED = "PULL_DATA_FROM_DI_CLICKED";

const getDateConditions = (range: Range) => {
    return {
        parameter: "deal_date",
        comparator: "between",
        value: `${range?.startDate?.format("YYYY-MM-DD")},${range?.endDate?.format("YYYY-MM-DD")}`
    };
};

export const updateDateRange = (dateRange: Range) => ({
    type: UPDATE_DATE_RANGE,
    dateRange
});

export const updateMapBounds = (center: { lat: number; lng: number }, zoom: number) => ({
    type: UPDATE_MAP_BOUNDS,
    center,
    zoom
});
export const fetchBidAdjustments = (clientId: number, campaignId: string) => ({
    [CALL_API]: {
        types: [REQUEST_LOCATION_DATA, LOAD_LOCATION_DATA, REQUEST_LOCATION_DATA_FAILED],
        endpoint: `/clients/${clientId}/adwords-campaigns/${campaignId}/locations`,
        schema: Schemas.ADWORDS_BID_ADJUSTMENT_ARRAY,
        method: "GET"
    }
});

const getFiltersFromFilters = (filters: IFilterOptions) => {
    const results = Object.keys(filters).map((item) => {
        const objects = filters[item];
        const checked = _.reduce(
            objects,
            (result, value, key) => {
                if (value) {
                    result.push(key);
                }
                return result;
            },
            [] as string[]
        );

        return { key: item, checked };
    });
    return results
        .filter((item) => !_.isEmpty(item.checked))
        .map((item) => {
            return {
                parameter: item.key,
                value: item.checked.join(),
                comparator: "in"
            };
        });
};

const api = new Api();

export interface IFilterOptions {
    type: {
        New: boolean;
        Used: boolean;
    };
    year: { [key: number]: boolean };
    make: { [key: string]: boolean };
    model: { [key: string]: boolean };
    trim: { [key: string]: boolean };
    buyer_city: { [key: string]: boolean };
    buyer_zip: { [key: string]: boolean };
    [key: string]: { [key: string]: boolean };
}

export const loadFilterOptions = (options: IFilterOptions) => {
    return {
        type: LOAD_FILTER_OPTIONS,
        options
    };
};
export const fetchFilterOptions = (clientId: number) => {
    return (dispatch: any) => {
        api.fetchFilters(clientId).then((resp) => {
            const converted: IFilterOptions = {} as IFilterOptions;
            Object.keys(resp).forEach((field) => {
                converted[field] = resp[field].reduce((result: { [key: string]: boolean }, value: string) => {
                    result[value] = false;
                    return result;
                }, {});
            });
            dispatch(loadFilterOptions(converted));
        });
    };
};

export const toggleFilterOption = (filter: string, value: string) => ({
    type: TOGGLE_FILTER_OPTION,
    filter,
    value
});

interface ISalesData {
    salesData: Array<{ sales: number; zip: string; lat: number; lng: number }>;
    mapCenter: { lat: number; lng: number };
    parameterOptions: IFilterOptions;
}

export const loadSalesData = (resp: ISalesData) => ({
    type: LOAD_SALES_DATA,
    resp
});

export const loadLocationStats = (clientId: number, campaignId: number, dateRange: Range) => {
    return (dispatch: any, getState: () => IAppState) => {
        if (dateRange === undefined || Object.keys(dateRange).length === 0) {
            dateRange = {
                startDate: defaultRanges["Last Year"].startDate(moment()),
                endDate: defaultRanges["Last Year"].endDate(moment())
            };
        }

        const range = {
            startDate: dateRange.startDate!.format("YYYY-MM-DD"),
            endDate: dateRange.endDate!.format("YYYY-MM-DD")
        };

        api.fetchLocationStats(clientId, campaignId, range).then((resp) => {
            dispatch({ type: LOAD_LOCATION_STATS, resp });
        });
    };
};

export const fetchSalesData = (clientId: number) => {
    return (dispatch: any, getState: () => IAppState) => {
        const conditions = getDateConditions(getState().salesDataFilters.dateRange);
        const filters = getState().salesDataFilters.parameters;
        const updated = [conditions, ...getFiltersFromFilters(filters)];
        api.fetchSalesData(clientId, updated).then((resp) => {
            dispatch(loadSalesData(resp));
        });
    };
};

export const requestSalesData = () => ({
    type: REQUEST_SALES_DATA
});

export const purgeSalesData = (clientId: number) => {
    return (dispatch: any, getState: () => IAppState) => {
        doDeleteRequest(`/clients/${clientId}/sales`).then(() => {
            window.location.reload();
        });
    };
};

export const pullDiSalesData = (clientId: number) => {
    return () => {
        callApi(`/clients/${clientId}/sales-import-di-dashboard`, {}, "POST");
    };
};

export const pullDiSalesDataClicked = (clientId: number, client: IClient) => ({
    type: PULL_DATA_FROM_DI_CLICKED,
    clientId,
    client
});
