import React from "react";
import FieldErrors from "../Fields/FieldErrors";
import IDealerInfoFields from "../../../../interfaces/DealerSetup/IDealerInfoFields";

const DealerInfoSection: React.FunctionComponent<IDealerInfoFields> = ({ fields }) => {
    return (
        <div className="m-4">
            <div className="font-bold mb-4 w-1/2">General</div>
            <div className="flex mb-8">
                <div className="w-1/2">
                    <div className="font-bold mb-4">Name</div>
                    <div>{fields.name.value}</div>
                    <FieldErrors errors={fields.name.errors} />
                </div>
            </div>
            <div className="flex mb-8">
                <div className="w-1/3">
                    <div className="font-bold mb-4">City</div>
                    <div>{fields.city.value.city}</div>
                    <div className="font-bold mb-4">Variations</div>

                    {fields.city.value.variations.map((v) => (
                        <div key={v}>{v}</div>
                    ))}
                    <FieldErrors errors={fields.city.errors} />
                </div>
                <div className="w-1/3">
                    <div className="font-bold mb-4 ">State/Providence</div>
                    <div>{fields.state.value}</div>
                    <FieldErrors errors={fields.state.errors} />
                </div>

                <div className="w-1/3">
                    <div className="font-bold mb-4 ">Zip Code</div>
                    <div>{fields.zip.value}</div>
                    <FieldErrors errors={fields.zip.errors} />
                </div>
            </div>
        </div>
    );
};

export default DealerInfoSection;
