import React, { FunctionComponent, ChangeEvent, useState } from "react";
import IClient from "../../../../interfaces/IClient";
import TextField from "../../../Shared/Form/Blocks/TextField";
import Button, { PRIMARY_BUTTON } from "../../../Shared/Button";
import RefreshIcon from "../../../Shared/Icons/RefreshIcon";
import { callApi } from "../../../../middleware/api";

interface Props {
    client: IClient;
    onValueChange(event: ChangeEvent<HTMLInputElement>): void;
}

interface GoogleAdsAccount {
    id: number;
    name: string;
}

function makeGoogleAdsAccount(client: IClient): GoogleAdsAccount | null {
    if (!client.adwordsId || !client.adwordsName) {
        return null;
    }

    return { id: client.adwordsId, name: client.adwordsName };
}

const GoogleAds: FunctionComponent<Props> = ({ client, onValueChange }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [account, setAccount] = useState<GoogleAdsAccount | null>(makeGoogleAdsAccount(client));

    const handleCreateAccount = async () => {
        setLoading(true);
        try {
            const response: IClient = (await callApi(`/clients/${client.id}/google-ads-accounts`, {}, "POST")).result;
            setAccount(makeGoogleAdsAccount(response));
        } finally {
            setLoading(false);
        }
    };

    const createAccountButton = () => (
        <Button styleType={PRIMARY_BUTTON} disabled={loading} onClick={handleCreateAccount} styles="my-4">
            <span className="flex items-center text-base">
                <RefreshIcon className={`w-6 h-6 ${loading && "rotate-fast"}`} />
                <span className="ml-2">{loading ? "Creating" : "Create"} Account</span>
            </span>
        </Button>
    );

    const attributes = (account: GoogleAdsAccount) => (
        <div>
            <TextField
                label="Google Ads Name"
                value={account.name}
                handleChange={onValueChange}
                readOnly={true}
                name="googleAdsName"
            />
            <TextField
                label="Google Ads Id"
                value={String(account.id)}
                handleChange={onValueChange}
                readOnly={true}
                name="googleAdsId"
            />
        </div>
    );

    return (
        <div className="flex">
            <div className="w-full md:w-3/4 lg:w-1/2">{!account ? createAccountButton() : attributes(account)}</div>
        </div>
    );
};

export default GoogleAds;
