import { AnyAction, combineReducers } from "redux";
import * as actions from "../actions/salesData";
import { createSelector } from "reselect";
import _ from "lodash";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";
const loading = (state = false, action: AnyAction) => {
    switch (action.type) {
        case actions.REQUEST_LOCATION_DATA:
            return true;
        default:
            return false;
    }
};

const entities = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.LOAD_LOCATION_DATA:
            return { ...action.response.entities.adwordsBidAdjustment };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const bidAdjustmentSelector = (state: { adwordsBidAdjustments: { entities: any } }) =>
    state.adwordsBidAdjustments.entities;
export const bidAdjustmentsWithLocationSelector = createSelector(bidAdjustmentSelector, (adjustments) =>
    _.filter(adjustments, (adjustment) => adjustment.location !== null)
);

export default combineReducers({ loading, entities });
