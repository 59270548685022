import React from "react";

import AdTag from "./AdTag";
import {
    mobileWrapperStyles,
    phoneStyles,
    displayUrlHolderStyles,
    textStyles,
    headlineStyles,
    linkStyle,
    dotStyles,
    adContainerStyles
} from "./styles";

interface IProps {
    h1: string;
    h2: string;
    h3?: string;
    displayUrl: string;
    finalUrl: string;
    d1: string;
    d2?: string;
}

const MobilePreview: React.FC<IProps> = ({ finalUrl, h1, h2, h3, displayUrl, d1, d2 }) => {
    return (
        <div style={{ width: "440px" }}>
            <div style={mobileWrapperStyles}>
                <div style={phoneStyles}>
                    <div style={dotStyles} className="dot" />
                    <div className="cre-a" style={adContainerStyles}>
                        <div>
                            <div className="cre-f cre-j" style={{ ...textStyles, ...headlineStyles }}>
                                <a style={linkStyle} href={finalUrl}>
                                    {h1} {h2 && `| ${h2}`}
                                    {h3 && ` | ${h3}`}
                                </a>
                            </div>
                        </div>
                        <div className="cre-k" style={displayUrlHolderStyles}>
                            <AdTag />
                            <span> {displayUrl}</span>
                        </div>
                        <div
                            className="cre-i"
                            style={{ borderBottom: "1px solid #ebebeb", margin: "9px -16px 10px" }}
                        />
                        <div className="cre-e cre-j" style={{ ...textStyles, fontSize: "14px", overflow: "hidden" }}>
                            {d1} {d2}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobilePreview;
