import React from "react";
import { IKeywordBidModifier } from "../../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import IDynamicCampaignFormValue from "../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import { ConditionalOperators } from "../../../../../utils/InventoryUtils";
import SelectField from "../../../../Shared/Form/Blocks/SelectField";
import TextField from "../../../../Shared/Form/Blocks/TextField";
import CheckboxInput from "../../../../Shared/Form/Elements/CheckboxInput";
import { get } from "lodash";
import { FormikErrors, FormikTouched } from "formik";
import TrashIcon from "../../../../Shared/Icons/TrashIcon";

interface IKeywordBid {
    bid: IKeywordBidModifier;
    index: number;
    fieldName: string;
    touched: FormikTouched<IDynamicCampaignFormValue>;
    errors: FormikErrors<IDynamicCampaignFormValue>;
    handleBlur(field: string): void;
    onFieldChange(key: string, value: any): void;
    onRemoveBid: (index: number, bid: IKeywordBidModifier) => void;
}

const modifierOptions = [
    { value: "increase-bid-by-percentage", label: "Increase bid by %" },
    { value: "decrease-bid-by-percentage", label: "Decrease bid by %" },
    { value: "decrease-bid-by-currency", label: "Decrease bid by $" },
    { value: "increase-bid-by-currency", label: "Increase bid by $" },
    { value: "set-bid-to", label: "Set bid to" }
];

const KeywordBid: React.FunctionComponent<IKeywordBid> = ({
    index,
    bid,
    fieldName,
    onFieldChange,
    onRemoveBid,
    handleBlur,
    touched,
    errors
}) => {
    const modifierKey = `${fieldName}.modifier`;
    const amountKey = `${fieldName}.amount`;
    const comparatorKey = `${fieldName}.comparator`;
    const valueKey = `${fieldName}.value`;
    const broadKey = `${fieldName}.broad`;
    const phraseKey = `${fieldName}.phrase`;
    const exactKey = `${fieldName}.exact`;

    const getFieldErrors = (field: string) => {
        const fieldErrors = get(errors, `${field}`, false);
        return fieldErrors ? [fieldErrors] : [];
    };

    const handleCheckboxChange = (name: any, value: any) => {
        onFieldChange(name, value);
    };

    return bid.deleted ? null : (
        <div className="flex flex-col" aria-live="polite">
            <div className="flex items-baseline ">
                <SelectField
                    autoFocus={!bid.modifier}
                    className="mr-4 w-2/6"
                    name={modifierKey}
                    options={modifierOptions}
                    handleChange={(item: IKeywordBidModifier) => {
                        onFieldChange(modifierKey, item.value);
                    }}
                    handleBlur={() => handleBlur(modifierKey)}
                    value={bid.modifier || ""}
                    required={true}
                    touched={get(touched, modifierKey, false)}
                    errors={getFieldErrors(modifierKey)}
                />

                <TextField
                    className="w-2/12"
                    name={amountKey}
                    value={String(bid.amount) || ""}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onFieldChange(amountKey, event.target.value)
                    }
                    handleBlur={() => handleBlur(amountKey)}
                    touched={get(touched, amountKey, false)}
                    errors={getFieldErrors(amountKey)}
                />

                <div className="w-2/12 px-4 pb-2 flex justify-end items-center">When Keyword</div>

                <SelectField
                    className="mr-4 w-2/6"
                    name={comparatorKey}
                    options={ConditionalOperators}
                    handleChange={(item: IKeywordBidModifier) => {
                        onFieldChange(comparatorKey, item.value);
                    }}
                    handleBlur={() => handleBlur(comparatorKey)}
                    value={bid.comparator || ""}
                    required={true}
                    touched={get(touched, comparatorKey, false)}
                    errors={getFieldErrors(comparatorKey)}
                />

                <TextField
                    className="mr-4 w-2/6"
                    name={valueKey}
                    value={bid.value || ""}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onFieldChange(valueKey, event.target.value)
                    }
                    handleBlur={() => handleBlur(valueKey)}
                    touched={get(touched, valueKey, false)}
                    errors={getFieldErrors(valueKey)}
                />
                <button
                    className="w-1/12 text-gray-500 hover:text-gray-600"
                    onClick={() => {
                        onRemoveBid(index, bid);
                    }}
                    type="button"
                >
                    <TrashIcon className="w-6 h-6" />
                </button>
            </div>

            <div className="flex w-1/3 justify-between py-4">
                <CheckboxInput
                    label="Broad"
                    name={broadKey}
                    checked={bid.broad}
                    onChange={(e: any) => handleCheckboxChange(broadKey, e.target.checked)}
                />
                <CheckboxInput label="Phrase" name={phraseKey} checked={bid.phrase} />
                <CheckboxInput label="Exact" name={exactKey} checked={bid.exact} />
            </div>
        </div>
    );
};

export default KeywordBid;
