import { connect } from "react-redux";
import SalesDataImport from "./SalesDataImport";
import ISalesDataImport from "../../../interfaces/ISalesDataImport";
import { uploadSalesDataImport } from "../../../actions/client/salesDataImportActions";

const mapDispatchToProps = (dispatch: any) => ({
    onSave: (payload: ISalesDataImport) => dispatch(uploadSalesDataImport(payload))
});

const mapStateToProps = (state: any) => {
    return {
        clientId: state.clients.currentClient
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesDataImport);
