import { connect } from "react-redux";
import * as Actions from "../../../actions/shared/itemCollectionActions";
import * as Interfaces from "../../../interfaces/shared/IItemCollection";
import NegativeKeywordsConstants from "../../../constants/NegativeKeywords";
import Collection from "../../Shared/ItemCollection/Collection";
import { pick, concat } from "lodash";
import IAppState from "../../../interfaces/IAppState";

const mapStateToProps = (state: IAppState, { collectionId }: any) => {
    const collection = state.negativeKeywords.collections.entities[collectionId];
    const items = state.negativeKeywords.items;
    const searchTerm = `_${items.searchTerm}`;

    return {
        info: {
            collectionName: collection !== undefined ? collection.name : null,
            collectionDescription: "Negative Keywords",
            itemDescription: "Keyword",
            itemsDescription: "Keywords"
        },
        searchTerm: items.searchTerm,
        items: concat(
            Object.values(pick(items.entities, items.added)),
            Object.values(pick(items.entities, items.searchTerms[searchTerm].results))
        ),
        deleted: items.deleted,
        errors: items.errors,
        currentPage: items.searchTerms[searchTerm].loadedPages,
        hasNextPage: items.searchTerms[searchTerm].totalPages > items.searchTerms[searchTerm].loadedPages,
        isNextPageLoading: items.searchTerms[searchTerm].loading,
        uploading: state.negativeKeywords.items.uploading
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchCollection: (collectionId: number, page: number, searchTerm: string) => {
        const nextPage = page + 1;
        dispatch(
            Actions.fetchItemCollection(
                NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER,
                collectionId,
                nextPage,
                searchTerm
            )
        );
    },
    fetchCollections: () => {
        dispatch(Actions.fetchCollectionList(NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER));
    },
    searchItems: (collectionId: number, searchTerm: string) => {
        dispatch(
            Actions.searchItemCollection(NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER, collectionId, searchTerm)
        );
    },
    deleteItem: (id: number) => {
        dispatch(Actions.removeItemFromCollection(NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER, id));
    },
    createItem: (collectionId: number, name: string) => {
        dispatch(Actions.addItemToCollection(NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER, name, collectionId));
    },
    dismissError: (id: number, type: Interfaces.ActionErrorType) => {
        dispatch(Actions.dismissItemActionError(NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER, id, type));
    },
    uploadCSV: (id: number, fileInput: any) => {
        dispatch(Actions.uploadCSV(NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER, id, fileInput));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
