import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import ClientEditContainer from "../components/Clients/Form/ClientEditContainer";
import ClientNewContainer from "../components/Clients/Form/ClientNewContainer";
import FlashMessageList from "../components/FlashMessage";
import { fetchClientRoles } from "../actions/clientActions";
import NotFound404 from "../components/Shared/NotFound404";
import ClientsIndexContainer from "../components/Clients/List/ClientIndexContainer";

// this needs a refactor for performance but keeping it the same for now for time sake and make sure we don't break something
// should not need to load all this data
const App = ({ fetchClientRoles }) => {
    useEffect(() => {
        fetchClientRoles();
    }, []);
    return (
        <div className="h-full">
            <FlashMessageList />
            <Routes>
                <Route element={<ClientsIndexContainer />} path="/" />
                <Route element={<ClientNewContainer />} path="/new" />
                <Route element={<ClientEditContainer />} path="/:clientId" />
                <Route element={<NotFound404 />} path="*" />
            </Routes>
        </div>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchClientRoles: () => {
            dispatch(fetchClientRoles());
        }
    };
};

export default hot(module)(connect(null, mapDispatchToProps)(App));
