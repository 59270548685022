import { getApiKey, setApiKey } from "../api";
import { Reducer } from "redux";
import {
    AuthenticationConstants,
    IAuthenticationCallback,
    IAuthenticationCallbackFailed,
    IAuthenticationCallbackSuccess,
    IAuthenticationSetTimeLeftCallback
} from "../actions/authenticationActions";
import { tokenExpiresAt } from "../hooks/useFuelAuthentication";

const authenticationReducer: Reducer<
    any,
    IAuthenticationCallback &
        IAuthenticationCallbackFailed &
        IAuthenticationCallbackSuccess &
        IAuthenticationSetTimeLeftCallback
> = (
    state = {
        loading: false,
        token: getApiKey() || "",
        errors: [],
        authenticated: false,
        authenticationExpiresAt: null
    },
    action
) => {
    switch (action.type) {
        case AuthenticationConstants.REQUEST_AUTH_CODE_VERIFICATION:
            return {
                loading: true,
                token: null,
                errors: [],
                authenticated: false,
                authenticationExpiresAt: null
            };
        case AuthenticationConstants.REQUEST_AUTH_CODE_VERIFICATION_FAILED:
            return {
                loading: false,
                token: null,
                errors: [action.errors],
                authenticated: false,
                authenticationExpiresAt: null
            };
        case AuthenticationConstants.REQUEST_AUTH_CODE_VERIFICATION_SUCCESS:
            setApiKey(action.payload.token);
            return {
                loading: false,
                token: action.payload.token,
                errors: [],
                authenticated: true,
                authenticationExpiresAt: tokenExpiresAt(action.payload.token)
            };
        case AuthenticationConstants.SET_AUTHENTICATION_EXPIRES_AT:
            return {
                ...state,
                authenticationExpiresAt: action.payload.timeLeftInUserSession
            };

        default:
            return {
                ...state,
                authenticated: Boolean(state.token)
            };
    }
};

export default authenticationReducer;
