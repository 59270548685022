import * as React from "react";
import CopyIcon from "../../../../../Shared/Icons/CopyIcon";
import { connect, FormikContext } from "formik";
import IDynamicCampaignFormValue from "../../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";

type Props = {
    keywordTemplateRelationshipIndex: number;
};

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const DuplicateButton = ({
    keywordTemplateRelationshipIndex,
    formik: {
        values: {
            id,
            keywordTemplateRelationships: keywordTemplateRelationships,
            keywordTemplateRelationships: {
                [keywordTemplateRelationshipIndex]: {
                    conditionals,
                    keywordTemplate: { keywords }
                }
            }
        },
        setFieldValue
    }
}: Props & ContextProps) => {
    const fieldPath = `keywordTemplateRelationships[${keywordTemplateRelationshipIndex}]`;
    const newFieldPath = `keywordTemplateRelationships[${keywordTemplateRelationships.length}]`;

    const handleDuplicateKeywordTemplate = () => {
        setFieldValue(newFieldPath, {
            dynamicCampaignId: id,
            conditionals: conditionals.map(({ parameter, comparator, value }) => ({
                parameter,
                comparator,
                value,
                dirty: true,
                new: true
            })),
            keywordTemplate: {
                keywords,
                dirty: true,
                new: true
            },
            dirty: true,
            new: true
        });
    };

    return (
        <button
            data-testid={`duplicate-button-${fieldPath}`}
            onClick={() => handleDuplicateKeywordTemplate()}
            type="button"
            className={`px-4 py-2 border-l text-gray-500 hover:text-green-600 hover:bg-gray-200`}
            title="Duplicate"
        >
            <CopyIcon className="w-6 h-6" />
        </button>
    );
};

export default connect<Props, any>(DuplicateButton);
