import React from "react";
import useConditionals, {
    IConditionable,
    IConditionableParameter,
    IConditional,
    IConditionalParameter
} from "../../../hooks/useConditionals";
import TrashIcon from "../Icons/TrashIcon";
import Button from "../Button";
import AddIcon from "../Icons/AddIcon";
import { Input } from "../Hooks/Form/Input";
import { Select } from "../Hooks/Form/Select";
import useConditionalParams from "../../../hooks/useConditionalParams";
import TimeIcon from "../Icons/TimeIcon";
import RefreshIcon from "../Icons/RefreshIcon";
import ModalToggle from "../Dialogs/ModalToggle";
import SettingsIcon from "../Icons/SettingsIcon";
import { INamingConvention } from "../../../hooks/namingConventions/useNamingConvention";

interface IConditionalFormProps {
    namingConvention: INamingConvention;
    existingConditionals: IConditionableParameter;
    conditionalType: string;
    id?: number;
    showMustAllConditionsPass?: boolean;
    allConditionsMustPassValue: boolean;
    onAllConditionsMustPassClick: (value: boolean) => void;
    onFinish: () => void;
    onCancel?: (e: any) => void;
}

interface IConditionalFormModalProps extends IConditionalFormProps {
    namingConvention: INamingConvention;
    disabled?: boolean;
    subTitle: string;
    hasConditionals?: boolean;
    iconSize?: number;
    conditionals?: IConditionalParameter[];
    buttonClassName?: string;
    allConditionsMustPassValue: boolean;
    onAllConditionsMustPassClick: (value: boolean) => void;
}

export const ConditionalFormModal = ({
    hasConditionals,
    disabled,
    subTitle,
    iconSize = 6,
    conditionals,
    buttonClassName,
    namingConvention,
    ...props
}: IConditionalFormModalProps) => {
    const textStyle = hasConditionals
        ? `text-green-500 ${disabled ? "opacity-75" : " hover:text-green-700"}`
        : `text-gray-500 ${disabled ? "opacity-75" : " hover:text-gray-700"}`;

    const cursorStyles = disabled ? "cursor-not-allowed" : "cursor-pointer";
    const size = iconSize === 6 ? "w-6 h-6" : "w-8 h-8";

    return (
        <ModalToggle
            modalRoot={"modal-root"}
            style={{ width: "80%" }}
            toggleButton={(open: () => void) => {
                return (
                    <button
                        type="button"
                        disabled={disabled}
                        className={`${buttonClassName} ${cursorStyles} ${textStyle}`.trim()}
                        onClick={() => !disabled && open()}
                        title={
                            disabled
                                ? "Please save the form to add conditionals."
                                : conditionals?.length + " conditions"
                        }
                    >
                        <SettingsIcon className={`${size} inline-block ${cursorStyles} ${textStyle}`} />
                    </button>
                );
            }}
            modalContent={(hide: () => void) => (
                <div className={"flex flex-col "}>
                    <div className="text-xl text-gray-500 w-full text-left">{"Naming Convention conditionals"}</div>
                    <div className="text-2xl w-full">{subTitle}</div>
                    <div className=" w-full">
                        <ConditionalForm namingConvention={namingConvention} {...props} onCancel={hide} />
                    </div>
                </div>
            )}
        />
    );
};

const ConditionalForm = ({
    existingConditionals,
    conditionalType,
    namingConvention,
    id,
    showMustAllConditionsPass,
    allConditionsMustPassValue,
    onAllConditionsMustPassClick,
    onFinish,
    onCancel
}: IConditionalFormProps) => {
    const { loading, handleSubmit, fields, register, remove, append, restore, conditionalsToRemove, errors } =
        useConditionals({
            defaultValues: existingConditionals,
            conditionalId: id,
            conditionalType,
            onFinish
        });
    const { ConditionalOperators, conditionalParameterList } = useConditionalParams();

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
            }}
            className={"flex flex-col w-full p-5"}
        >
            {!!errors?.createError?.exceptionMessage && (
                <div className="text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                    <span className={"font-bold"}>create:</span> {errors?.createError.exceptionMessage}
                    <div className="text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                        {errors?.createError?.errors &&
                            Object.keys(errors?.createError?.errors).map((errorKey) => {
                                const errorMessage = errors?.createError?.errors[errorKey];
                                return (
                                    <div key={errorKey}>
                                        <span className={"font-bold"}>{errorKey}:</span> {errorMessage}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
            {!!errors?.updateError.exceptionMessage && (
                <div className="text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                    <span className={"font-bold"}>update:</span> {errors?.updateError.exceptionMessage}
                    <div className="text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                        {errors?.updateError?.errors &&
                            Object.keys(errors?.updateError?.errors).map((errorKey) => {
                                const errorMessage = errors?.updateError?.errors[errorKey];
                                return (
                                    <div key={errorKey}>
                                        {errorKey}: {errorMessage}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
            {!!fields.length && (
                <>
                    {fields.map((field: IConditional, index: number) => (
                        <div className={"lg:flex-row flex flex-col w-full gap-2"} key={"conditionals" + index}>
                            <div className={"flex-1"}>
                                <div className={"flex flex-col"}>
                                    <label htmlFor="parameter" className="ml-3">
                                        <span>Parameter</span>
                                        <span className="text-red-600">*</span>
                                    </label>
                                    <Select
                                        register={register}
                                        name={`conditionals.${index}.parameter`}
                                        options={conditionalParameterList[namingConvention.type]}
                                    />
                                </div>
                            </div>
                            <div className={"flex-1"}>
                                <div className={"flex flex-col"}>
                                    <label htmlFor="parameter" className="ml-3">
                                        <span>Comparator</span>
                                        <span className="text-red-600">*</span>
                                    </label>

                                    <Select
                                        register={register}
                                        name={`conditionals.${index}.comparator`}
                                        options={ConditionalOperators}
                                    />
                                </div>
                            </div>
                            <div className={"flex-1"}>
                                <div className={"flex flex-col"}>
                                    <label htmlFor="parameter" className="ml-3">
                                        <span>Value</span>
                                        <span className="text-red-600">*</span>
                                    </label>
                                    <Input register={register} name={`conditionals.${index}.value`} />
                                </div>
                            </div>
                            <div className={"flex mt-0 lg:mt-8 justify-center"}>
                                <button type="button" onClick={() => remove(index)}>
                                    <TrashIcon className={"w-6 h-6 text-red-500"} />
                                </button>
                            </div>
                        </div>
                    ))}
                </>
            )}
            {!fields.length && <div className="text-center">No conditionals found.</div>}
            <div className="mt-2">
                <Button
                    disabled={loading}
                    type={"button"}
                    styles={"flex gap-2 items-center"}
                    styleType={"secondary"}
                    onClick={() => {
                        append({
                            parameter: "client.oem_program",
                            comparator: "EQUAL",
                            value: ""
                        });
                    }}
                >
                    <AddIcon className={"w-4 h-4"} />
                    Add conditional
                </Button>
            </div>
            {conditionalsToRemove.length > 0 && (
                <div>
                    <div className={"w-full text-lg ml-3 mt-4"}>Restore</div>
                    {conditionalsToRemove.map((conditionalId, index) => {
                        const condition = Object.values(existingConditionals.conditionals).filter(
                            (condition: IConditionalParameter) => condition?.id === conditionalId
                        )[0];
                        return (
                            <div className={"flex w-full gap-2 text-gray-700"} key={"conditionalsToRemove" + index}>
                                <div className={"flex-1"}>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor="parameter" className="ml-3">
                                            <span>Parameter</span>
                                        </label>
                                        <input
                                            value={condition?.parameter ?? ""}
                                            disabled={true}
                                            className={"p-2 border-gray-500 border rounded-lg"}
                                        />
                                    </div>
                                </div>
                                <div className={"flex-1"}>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor="parameter" className="ml-3">
                                            <span>Comparator</span>
                                        </label>

                                        <input
                                            value={condition?.comparator ?? ""}
                                            disabled={true}
                                            className={"p-2 border-gray-500 border rounded-lg"}
                                        />
                                    </div>
                                </div>
                                <div className={"flex-1"}>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor="parameter" className="ml-3">
                                            <span>Value</span>
                                        </label>
                                        <input
                                            value={condition?.value ?? ""}
                                            disabled={true}
                                            className={"p-2 border-gray-500 border rounded-lg"}
                                        />
                                    </div>
                                </div>
                                <div className={"mt-8"}>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            restore(
                                                condition?.id ? Number(condition.id) : conditionalId,
                                                existingConditionals
                                            )
                                        }
                                    >
                                        <TimeIcon className={"w-6 h-6 text-blue-500"} />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            {showMustAllConditionsPass && (
                <div className={"flex flex-col gap-2 mt-4"}>
                    <div className="font-bold">Must all conditions pass?</div>
                    <label className="flex gap-2">
                        <input
                            type="radio"
                            name={`must_all_conditions_pass`}
                            onClick={() => onAllConditionsMustPassClick(true)}
                            checked={!!allConditionsMustPassValue}
                        />
                        All must pass for the convention to apply (default)
                    </label>
                    <label className="flex gap-2">
                        <input
                            type="radio"
                            name={`must_all_conditions_pass`}
                            onClick={() => onAllConditionsMustPassClick(false)}
                            checked={!allConditionsMustPassValue}
                        />
                        Only one condition is required to pass for the convention to apply
                    </label>
                </div>
            )}
            <div className="flex justify-end items-center mt-4 gap-4">
                <Button styleType={"secondary"} onClick={onCancel} styles={"text-gray-800"}>
                    Cancel
                </Button>
                <Button
                    styleType={"primary"}
                    type="submit"
                    disabled={loading || (!fields.length && !conditionalsToRemove.length)}
                >
                    Save
                    {loading ? <RefreshIcon className="w-6 h-6 rotate-fast float-right ml-3" /> : ""}
                </Button>
            </div>
        </form>
    );
};

export default ConditionalForm;
