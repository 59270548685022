// @flow
import { combineReducers } from "redux";
import * as Actions from "../actions/notificationActions";
import { reduce } from "lodash";
import { createSelector } from "reselect";

export type PaginationState = {
    totalLoaded: number;
    totalRecords: number;
    lastPageLoaded: number;
    nextUrl?: string;
    fetching: boolean;
    removing: number;
};
const defaultState = {
    removing: 0,
    totalRecords: 0,
    totalLoaded: 0,
    lastPageLoaded: 0,
    fetching: false
};
const paginationReducer = (state: PaginationState = { ...defaultState }, action: any) => {
    switch (action.type) {
        case Actions.LOAD_NOTIFICATIONS:
            const { totalRecords, nextUrl, loadedRecords, lastPageLoaded } = action.pagination;
            return {
                ...state,
                totalRecords,
                nextUrl,
                lastPageLoaded,
                totalLoaded: state.totalLoaded + loadedRecords
            };

        case Actions.REQUEST_LOAD_NOTIFICATIONS:
            return { ...state, fetching: true };
        case Actions.DONE_LOADING_PAGES:
            return { ...state, fetching: false };

        case Actions.MARK_ALL_AS_READ:
            return { ...state, totalRecords: 0, removing: state.totalRecords };
        case Actions.FAILED_MARK_ALL_AS_READ:
            return {
                ...state,
                totalRecords: state.removing + state.totalRecords,
                removing: 0
            };
        case Actions.REMOVE_ALL_NOTIFICATIONS:
            return defaultState;
        case Actions.NOTIFICATION_FILTER_CHANGED:
            return { ...defaultState, fetching: true };
        case Actions.MARK_AS_READ:
            return {
                ...state,
                totalRecords: state.totalRecords - 1,
                removing: state.removing + 1
            };
        case Actions.FAILED_MARK_AS_READ:
            return {
                ...state,
                totalRecords: state.totalRecords + 1,
                removing: state.removing - 1
            };

        case Actions.REMOVE_NOTIFICATION:
            return { ...state, removing: state.removing - 1 };
        default:
            return state;
    }
};
const entitiesReducer = (state: any = {}, action: any): { [key: string]: Notification } => {
    switch (action.type) {
        case Actions.LOAD_NOTIFICATIONS:
            // To preserve the pagination behavior, we only want to pass back the loaded notifications.
            // Otherwise we end up with an infinite scrolling kind of situation...
            return action.notifications || {};
        case Actions.REMOVE_NOTIFICATION:
            delete state[action.id];
            return { ...state };

        case Actions.UPDATE_NOTIFICATION: {
            const notification = action.notification;
            return {
                ...state,
                [notification.id]: { ...state[notification.id], ...notification }
            };
        }
        case Actions.MARK_AS_READ: {
            const notification = { ...action.notification, deleting: true };
            return {
                ...state,
                [notification.id]: { ...state[notification.id], ...notification }
            };
        }
        case Actions.FAILED_MARK_AS_READ: {
            const notification = { ...action.notification, deleting: false };
            return {
                ...state,
                [notification.id]: { ...state[notification.id], ...notification }
            };
        }
        case Actions.REMOVE_ALL_NOTIFICATIONS:
        case Actions.NOTIFICATION_FILTER_CHANGED: {
            return {};
        }
        case Actions.FAILED_MARK_ALL_AS_READ: {
            return reduce(
                state,
                (result: any, value: any, key: string) => {
                    if (value.deleting === true) value.deleting = false;
                    result[key] = value;
                    return result;
                },
                {}
            );
        }
        case Actions.MARK_ALL_AS_READ: {
            Object.keys(state).map((id) => (state[id].deleting = true));
            return { ...state };
        }
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    pagination: paginationReducer
});
const getNotifications = (state: any) => state.notifications.entities;
export const getNonDeleted = createSelector(getNotifications, (notifications) =>
    reduce(
        notifications,
        (result: any, notification) => {
            if (notification.deleting !== true) result[notification.id] = notification;
            return result;
        },
        {}
    )
);
