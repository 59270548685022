import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { callApi, doDeleteRequest, getPagedResults, multipartCallApi } from "../middleware/api";
import schemas from "../middleware/schemas/placementExclusions";
import * as Actions from "../actions/shared/itemCollectionActions";
import Constants from "../constants/shared/ItemCollectionConstants";
import ExclusionsConstants from "../constants/ExclusionsConstants";
import { addFlashMessage } from "../actions/flashMessageActions";
import IPagedResponse from "../interfaces/IPagedResponse";

export function* fetchExclusionLists() {
    const url = `/exclusion-colls`;
    try {
        const response: IPagedResponse = yield call(callApi, url, schemas.PLACEMENT_EXCLUSION_COLLECTIONS);
        yield put(
            Actions.fetchCollectionListSuccess(
                ExclusionsConstants.ACTION_TYPE_IDENTIFIER,
                response.entities.placementExclusionCollections
            )
        );
    } catch (error) {
        console.log(error);
    }
}

export function* fetchExclusionCollection({ payload }: any) {
    const { collectionId, page, searchTerm } = payload;
    let url: string;

    if (searchTerm === "") {
        url = `/exclusions?filter[collection_id]=${collectionId}&page=${page}`;
    } else {
        url = `/exclusions?filter[collection_id]=${collectionId}&search[exclusion]=like,*${searchTerm}*&page=${page}`;
    }

    try {
        const response: IPagedResponse = yield call(getPagedResults, url, schemas.PLACEMENT_EXCLUSION_COLLECTION);
        yield put(
            Actions.fetchItemCollectionSuccess(
                ExclusionsConstants.ACTION_TYPE_IDENTIFIER,
                response,
                collectionId,
                searchTerm
            )
        );
    } catch (error) {
        const errorMessage = error.errors ? error.errors.exclusion["0"] : error.exceptionMessage;
        yield put(
            Actions.fetchItemCollectionFailure(ExclusionsConstants.ACTION_TYPE_IDENTIFIER, errorMessage, searchTerm)
        );
    }
}

export function* removeExclusionFromCollection({ payload }: any) {
    const url = `/exclusions/${payload}`;
    try {
        yield call(doDeleteRequest, url);
        yield put(Actions.removeItemFromCollectionSuccess(ExclusionsConstants.ACTION_TYPE_IDENTIFIER, payload));
    } catch (error) {
        const errorMessage = error.errors ? error.errors.exclusion["0"] : error.exceptionMessage;
        yield put(
            Actions.removeItemFromCollectionFailure(ExclusionsConstants.ACTION_TYPE_IDENTIFIER, payload, errorMessage)
        );
    }
}

interface IAddExclusionToCollectionResponse {
    entities: { [key: string]: { [key: string]: any } };
    nextPageUrl: string | null;
    result: number;
}

export function* addExclusionToCollection({ payload }: any) {
    const url = `/exclusions`;
    try {
        const response: IAddExclusionToCollectionResponse = yield call(
            callApi,
            url,
            schemas.PLACEMENT_EXCLUSION,
            "POST",
            {
                exclusion: payload.itemName,
                collection_id: payload.collectionId
            }
        );
        yield put(
            Actions.addItemToCollectionSuccess(
                ExclusionsConstants.ACTION_TYPE_IDENTIFIER,
                response.entities.items,
                response.result,
                payload.dispatchedAt
            )
        );
    } catch (error) {
        const errorMessage = error.errors ? error.errors.exclusion["0"] : error.exceptionMessage;
        yield put(
            Actions.addItemToCollectionFailure(
                ExclusionsConstants.ACTION_TYPE_IDENTIFIER,
                payload.dispatchedAt,
                errorMessage
            )
        );
    }
}

export function* uploadPlacementExclusionCSV({ payload: { id, fileInput } }: any) {
    const url = `/exclusion-colls/${id}/placements`;
    const formData = new FormData();
    formData.append("placements", fileInput.files[0]);
    try {
        yield multipartCallApi(url, formData, "POST");
        yield put(
            addFlashMessage({
                type: "success",
                text: `Keywords upload successful.  We'll notify you when they have finished.`
            })
        );
        yield put(Actions.uploadCSVSuccess(ExclusionsConstants.ACTION_TYPE_IDENTIFIER));
    } catch (error) {
        yield put(
            addFlashMessage({
                type: "danger",
                text: `Failed to upload: "${fileInput.files[0].name}".`
            })
        );
        yield put(Actions.uploadCSVFailure(ExclusionsConstants.ACTION_TYPE_IDENTIFIER));
    }
}

function* placementExclusionSagas() {
    yield takeLatest(`${Constants.FETCH_COLLECTION_LIST}_PLACEMENT_EXCLUSIONS`, fetchExclusionLists);
    yield takeLatest(`${Constants.FETCH_ITEM_COLLECTION}_PLACEMENT_EXCLUSIONS`, fetchExclusionCollection);
    yield takeEvery(`${Constants.REMOVE_ITEM_FROM_COLLECTION}_PLACEMENT_EXCLUSIONS`, removeExclusionFromCollection);
    yield takeEvery(`${Constants.ADD_ITEM_TO_COLLECTION}_PLACEMENT_EXCLUSIONS`, addExclusionToCollection);
    yield takeEvery(`${Constants.UPLOAD_CSV}_PLACEMENT_EXCLUSIONS`, uploadPlacementExclusionCSV);
}

export default placementExclusionSagas;
