import React, { useState, FunctionComponent } from "react";
import SalesMap from "./SalesMap";
import { TableLoader } from "../Shared/Table";
import IEntity from "../../interfaces/IEntity";
import ISalesMapStatistic from "../../interfaces/ISalesMapStatistic";
import IAdjustment from "../../interfaces/IAdjustment";
import ToggleSwitch from "../Shared/Form/ToggleSwitch";

interface IGoogleMap {
    bidAdjustments: IAdjustment[];
    campaignId?: string;
    loading: boolean;
    locationStats: IEntity<ISalesMapStatistic>;
    mapCenter: { lat: number; lng: number };
    mapData: Array<{ sales: number; zip: number; lat: number; lng: number }>;
    mapHeight: string;
    mapWidth?: string;
    mapZoom: number;
    selectedAdjustment: null | number;
    updateMapBounds(center: { lat: number; lng: number }, zoom: number): void;
}

const GoogleMap: FunctionComponent<IGoogleMap> = ({
    bidAdjustments,
    campaignId,
    loading,
    locationStats,
    mapCenter,
    mapData,
    mapHeight = "400px",
    mapWidth = "100%",
    mapZoom,
    selectedAdjustment,
    updateMapBounds
}) => {
    const [showMarkers, setShowMarkers] = useState(true);
    const [mapLoading, setMapLoading] = useState(true);

    const toggleMarkers = () => {
        setShowMarkers((current) => {
            return !current;
        });
    };

    const tilesLoaded = () => {
        setMapLoading(false);
    };

    const renderMap = () => {
        return (
            <SalesMap
                bidAdjustments={bidAdjustments}
                campaignId={campaignId}
                mapCenter={mapCenter}
                mapData={mapData}
                showSales={showMarkers}
                mapZoom={mapZoom}
                updateMapBounds={updateMapBounds}
                locationStats={locationStats}
                selectedAdjustment={selectedAdjustment}
                tilesLoaded={tilesLoaded}
            />
        );
    };

    const loadingStill = () => {
        return loading || mapLoading;
    };

    const visibility = mapLoading ? "hidden" : "visible";

    return (
        <>
            {loadingStill() && <TableLoader message="Loading Google Maps Data..." />}
            <div id="map" style={{ width: mapWidth, height: mapHeight, visibility }} className="rounded" />
            {!loadingStill() && (
                <div className="text-base flex w-full justify-end">
                    <ToggleSwitch
                        label="Show Sales"
                        field={"show-sales"}
                        onToggle={toggleMarkers}
                        value={showMarkers}
                    />
                </div>
            )}
            {!loading && renderMap()}
        </>
    );
};

export default GoogleMap;
