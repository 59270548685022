export default {
    REQUEST_EXCLUSIONS_LIST: "REQUEST_EXCLUSIONS_LIST",
    REQUEST_EXCLUSIONS_LIST_SUCCESS: "REQUEST_EXCLUSIONS_LIST_SUCCESS",
    REQUEST_EXCLUSIONS_LIST_FAIL: "REQUEST_EXCLUSIONS_LIST_FAIL",

    REQUEST_EXCLUSIONS_COLL_CAMPAIGNS: "REQUEST_EXCLUSIONS_COLL_CAMPAIGNS",
    REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS: "REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS",
    REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_FAIL: "REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_FAIL",

    REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS: "REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS",
    REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS: "REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS",
    REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_FAIL: "REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_FAIL",
    ACTION_TYPE_IDENTIFIER: "PLACEMENT_EXCLUSIONS",

    DEFAULT_EXCLUSION_ID: 1
};
