import React from "react";
import Field from "../Elements/Field";
import AutoCompleteInput from "../Elements/AutoCompleteInput";
import { FormikErrors, FormikTouched } from "formik";
import { characterLimit } from "../../../../utils/DynamicCampaignUtils";

interface IProps {
    name: string;
    value: string;
    autocompleteFields: string[];
    placeholder: string;
    label?: string;
    touched?: boolean | FormikTouched<any>;
    errors?: (string | FormikErrors<any>)[] | string;
    handleChange?: any;
    handleBlur?: any;
    className?: string;
    style?: any;
    required?: boolean;
    characterLimit?: number;
    suggestionsportalHost?: Element;
    inputTrasparent?: boolean;
}

const AutoCompleteField: React.FunctionComponent<IProps> = ({
    name,
    label,
    value,
    handleChange,
    handleBlur,
    className,
    style,
    autocompleteFields,
    placeholder,
    required = false,
    touched = handleBlur === undefined,
    errors = [],
    suggestionsportalHost,
    inputTrasparent
}) => {
    const isValid = () => {
        const hasNoErrors = !touched || !errors.length;
        const validCharacterLimit = value?.length <= characterLimit(name);
        const hasBrackets = value?.includes("{{");

        return (hasBrackets || validCharacterLimit) && hasNoErrors;
    };

    return (
        <Field label={label} errors={errors} touched={touched} required={required} className={className} style={style}>
            <AutoCompleteInput
                value={value}
                fieldName={name}
                onChange={handleChange}
                onBlur={handleBlur}
                templateOptions={autocompleteFields}
                placeholder={placeholder}
                isValid={isValid()}
                suggestionsportalHost={suggestionsportalHost}
                inputTrasparent={inputTrasparent}
            />
        </Field>
    );
};

export default AutoCompleteField;
