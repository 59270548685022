import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IDynamicCampaignFormValue from "../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import IEntity from "../../../interfaces/IEntity";
import { FormikActions } from "formik";
import Form from "./Form";
import { useParams } from "react-router-dom";
import {
    purgeDynamicCampaign,
    unlockDynamicCampaign,
    unlockDynamicCampaignSuccess
} from "../../../actions/dynamicCampaigns/dynamicCampaignActions";
import { isUserDeveloper as isDeveloper } from "../../../reducers/currentUser";
import { ISpecialOfferTemplateAttachedToDynamicCampaignPayload } from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import IAppState from "../../../interfaces/IAppState";

type IRouteParams = {
    dynamicCampaignId: string;
};
interface IProps {
    googleCampaigns: IEntity<any>;
    microsoftCampaigns: IEntity<any>;
    onSave: (
        dynamicCampaign: IDynamicCampaignFormValue,
        clientId: number,
        actions: FormikActions<IDynamicCampaignFormValue>,
        timeToSubmitMetric: number,
        sotsToDetach?: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[]
    ) => void;
    client: any;
    clientId: number;

    onPushCampaign: (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus" | "setSubmitting">
    ) => void;
}

const Edit: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const { dynamicCampaignId } = useParams<IRouteParams>();
    const currentUser = useSelector(({ currentUser }: IAppState) => currentUser);
    const dynamicCampaign: IDynamicCampaignFormValue = useSelector((state: IAppState) =>
        dynamicCampaignId ? state.dynamicCampaigns.entities[dynamicCampaignId] : ({} as IDynamicCampaignFormValue)
    );
    const { errors } = dynamicCampaign;
    const isUserDeveloper = isDeveloper(currentUser);

    const onPurgeCampaign = (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus">
    ) => dispatch(purgeDynamicCampaign(clientId, dynamicCampaignId, formActions));

    const onUnlockCampaign = (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus">
    ) => dispatch(unlockDynamicCampaign(clientId, dynamicCampaignId, formActions));

    const unlockCampaignOnRunFailure = (dynamicCampaignId: number) =>
        dispatch(unlockDynamicCampaignSuccess(dynamicCampaignId));

    return (
        <div className="px-4 text-sm">
            <Form
                {...props}
                {...{
                    dynamicCampaign,
                    isUserDeveloper,
                    errors,
                    onPurgeCampaign,
                    onUnlockCampaign,
                    unlockCampaignOnRunFailure
                }}
            />
        </div>
    );
};

export default Edit;
