import React from "react";
import Field from "../Elements/Field";
import DateInput from "../Elements/DateInput";
import DatePicker from "react-datepicker";
import { FormikErrors, FormikTouched } from "formik";

interface IDatePicker {
    ariaLabel: string;
    className?: string;
    errors?: Array<string | FormikErrors<any>>;
    handleBlur?: any;
    handleChange?: any;
    inputStyle?: { [key: string]: string };
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    name: string;
    onChange?: any;
    placeholder?: string;
    required?: boolean;
    style?: any;
    touched?: boolean | FormikTouched<any>;
    value: Date | null;
    showMonthYearPicker?: boolean;
    isClearable?: boolean;
}

const DatePickerField: React.FunctionComponent<IDatePicker> = ({
    ariaLabel,
    className,
    errors = [],
    minDate,
    maxDate,
    name,
    onChange,
    placeholder,
    value,
    isClearable = true,
    showMonthYearPicker = false
}) => {
    return (
        <Field errors={errors} label={ariaLabel} className={className}>
            <DatePicker
                selected={value}
                onChange={(val) => onChange(name, val)}
                startDate={value}
                placeholderText={placeholder}
                dateFormat={"yyyy-MM-dd"}
                fixedHeight={true}
                minDate={minDate}
                maxDate={maxDate}
                customInputRef={`#${name}Field`}
                showMonthYearPicker={showMonthYearPicker}
                id={`${name}Field`}
                name={name}
                customInput={
                    <DateInput
                        isClearable={isClearable}
                        onClearClick={() => onChange(name, null)}
                        ariaLabel={ariaLabel}
                    />
                }
            />
        </Field>
    );
};

export default DatePickerField;
