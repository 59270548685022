import React from "react";
import InformationIcon from "../../../Shared/Icons/InformationIcon";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import ClientFacebookLowSpendsModal from "./ClientFacebookLowSpendsModal";

interface IProps {
    original: {
        name: string;
        insightFacebookLowSpends: { campaignId: number; campaignName: string; spend: number }[];
    };
}

const ClientFacebookLowSpendsActionCell = ({ original }: IProps) => {
    return (
        <div className="justify-between flex text-gray-500">
            <button className="relative bg-transparent text-lg" title="Low Spends">
                <ModalToggle
                    style={{ width: "50%", height: "510px" }}
                    toggleButton={(open) => (
                        <InformationIcon
                            data-testid={`open-button`}
                            onClick={open}
                            className="w-6 h-60 text-gray-500 hover:text-blue-400 cursor-pointer mx-auto"
                        />
                    )}
                    modalContent={(hide) => (
                        <ClientFacebookLowSpendsModal
                            insightFacebookLowSpends={original.insightFacebookLowSpends}
                            name={original.name}
                            hide={hide}
                        />
                    )}
                />
            </button>
        </div>
    );
};

export default ClientFacebookLowSpendsActionCell;
