import React from "react";
import ILocationsFields from "../../../../interfaces/DealerSetup/ILocationsFields";
import FieldErrors from "../Fields/FieldErrors";

const LocationsSection: React.FC<ILocationsFields> = ({ fields }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    <div className="font-bold mb-4">Listed Locations</div>

                    {fields.locations.value.ad_groups.map((location) => {
                        const { city, variations } = location.value;
                        return (
                            <div key={city} className="m-4">
                                <div className="font-bold">{city}</div>
                                {variations.map((variation) => {
                                    return <div key={variation}>{variation}</div>;
                                })}
                                {/*<FieldErrors errors={location.errors} />*/}
                            </div>
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default LocationsSection;
