import React, { SVGProps } from "react";
import Svg from "./Svg";

const ClientsIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M3.456 21.994a.626.626 0 0 1-.624-.627c.003-.983.202-1.939.594-2.841.487-.958 1.934-1.48 3.935-2.2l.434-.157a.404.404 0 0 0 .018-.052c.096-.313.033-.756-.01-.88a4.578 4.578 0 0 1-1.201-3.505 3.303 3.303 0 0 1 2.825-3.464.629.629 0 0 1 .707.532.622.622 0 0 1-.531.707 2.045 2.045 0 0 0-1.75 2.183.598.598 0 0 1-.001.11 3.34 3.34 0 0 0 .897 2.618c.397.428.424 1.406.293 1.949-.15.617-.491.859-.751.953l-.504.182c-1.467.529-2.984 1.075-3.23 1.557-.31.716-.472 1.5-.474 2.313a.626.626 0 0 1-.625.623h-.002zM16.208 21.994a.627.627 0 0 1-.625-.618 6.034 6.034 0 0 0-.503-2.353c-.23-.447-1.743-.992-3.208-1.52l-.51-.183c-.26-.095-.6-.338-.75-.956-.13-.542-.104-1.519.287-1.94.444-.448.724-1 .816-1.606a.62.62 0 0 1 .71-.523.617.617 0 0 1 .41.247c.1.135.14.3.115.465a4.09 4.09 0 0 1-1.11 2.24c-.04.137-.093.566 0 .87.007.023.013.04.02.054l.434.156c2 .721 3.445 1.241 3.916 2.164.406.929.612 1.889.623 2.872a.625.625 0 0 1-.618.632h-.007zM18.972 21.994a.625.625 0 0 1 0-1.25h1.348v-.056c0-1.78-1.007-2.14-2.53-2.685a47.176 47.176 0 0 1-.4-.146c-.258-.095-.595-.338-.745-.954-.134-.552-.11-1.545.282-1.976a3.04 3.04 0 0 0 .84-1.774.624.624 0 1 1 1.243.137 4.28 4.28 0 0 1-1.133 2.452c-.042.14-.1.599-.004.91a.577.577 0 0 0 .02.058l.32.115c1.574.563 3.358 1.2 3.358 3.863v.68c0 .345-.28.625-.625.625h-1.974v.001z" />
        <path d="M22.207 13.88a.619.619 0 0 1-.442-.183l-2.744-2.744a4.99 4.99 0 0 1-3.064 1.053c-2.756 0-5-2.244-5-5 0-2.757 2.244-5 5-5 2.757 0 5 2.243 5 5a4.991 4.991 0 0 1-1.052 3.064l2.744 2.744a.627.627 0 0 1-.442 1.067zm-6.25-10.624a3.755 3.755 0 0 0-3.75 3.75 3.755 3.755 0 0 0 3.75 3.75 3.755 3.755 0 0 0 3.75-3.75 3.754 3.754 0 0 0-3.75-3.75z" />
    </Svg>
);

export default ClientsIcon;
