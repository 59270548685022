import React from "react";
import { ItemField, LogHeaderStyle } from "./BudgetDetails";

const ActivityHeader = () => {
    return (
        <div className={LogHeaderStyle}>
            <div className={ItemField}>Date</div>
            <div className={ItemField}>Status</div>
            <div className={`flex-auto`}>Note</div>
            <div className={ItemField}>Budget Change</div>
        </div>
    );
};

export default ActivityHeader;
