import { all, AllEffect, call } from "redux-saga/effects";
import { callApi } from "../../../../../middleware/api";
import IKeywordConditional from "../../../../../interfaces/IKeywordConditional";
import { wrapper } from "../../../../../utils/DynamicCampaignUtils";
import { isEmpty } from "lodash";
import { each } from "lodash";

export default function* handleKeywordTemplateRelationshipConditionals({
    conditionals,
    clientId,
    keywordTemplateRelationshipId
}: {
    conditionals: IKeywordConditional[];
    clientId: number;
    keywordTemplateRelationshipId: number;
}): Generator<any, any, any> {
    const entities: any[] = [];
    const errors: any = {};

    const responses = yield all(
        conditionals.map((keywordConditional) =>
            call(saveKeywordTemplateRelationshipConditional, {
                clientId,
                keywordConditional,
                keywordTemplateRelationshipId
            })
        )
    );

    each(responses, (response: [boolean, any]) => {
        const [error, entity] = response;

        if (entity !== null) {
            entities.push(entity);

            if (error) {
                Object.assign(errors, { [`${entities.length - 1}`]: error });
            }
        }
    });

    return [isEmpty(errors) ? false : errors, entities];
}

export function* saveKeywordTemplateRelationshipConditional({
    keywordConditional,
    clientId,
    keywordTemplateRelationshipId
}: {
    keywordConditional: IKeywordConditional;
    clientId: number;
    keywordTemplateRelationshipId: number;
}): any {
    const { dirty, deleted, new: isNew, ...conditional } = keywordConditional;

    if (!dirty) {
        return [false, keywordConditional];
    }

    if (deleted) {
        if (!isNew) {
            yield call(deleteConditional, { conditional, clientId });
        }
        return [false, null];
    }

    if (isNew) {
        const [createError, createdEntity] = yield call(createConditional, {
            conditional,
            keywordTemplateRelationshipId,
            clientId
        });

        if (createError) {
            return [createError, keywordConditional];
        }

        return [false, createdEntity];
    }

    const [updateError, updatedEntity] = yield call(updateConditional, {
        conditional,
        clientId
    });

    if (updateError) {
        return [updateError, keywordConditional];
    }

    return [false, updatedEntity];
}

export function* createConditional({
    clientId,
    keywordTemplateRelationshipId,
    conditional
}: {
    clientId: number;
    keywordTemplateRelationshipId: number;
    conditional: IKeywordConditional;
}): Generator {
    return yield wrapper(
        call(callApi, `/clients/${clientId}/dc-keyword-template-conditionals`, {}, "POST", {
            keyword_template_relationship_id: keywordTemplateRelationshipId,
            value: conditional.value,
            parameter: conditional.parameter,
            comparator: conditional.comparator
        })
    );
}

export function* updateConditional({
    clientId,
    conditional
}: {
    clientId: number;
    conditional: IKeywordConditional;
}): Generator {
    return yield wrapper(
        call(callApi, `/clients/${clientId}/dc-keyword-template-conditionals/${conditional.id}`, {}, "PATCH", {
            value: conditional.value,
            parameter: conditional.parameter,
            comparator: conditional.comparator
        })
    );
}

export function* deleteConditional({
    clientId,
    conditional
}: {
    clientId: number;
    conditional: IKeywordConditional;
}): Generator {
    return yield wrapper(
        call(callApi, `/clients/${clientId}/dc-keyword-template-conditionals/${conditional.id}`, {}, "DELETE")
    );
}
