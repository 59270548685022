import React, { useEffect, FunctionComponent } from "react";
import { Routes, useParams, Route } from "react-router-dom";
import { hot } from "react-hot-loader";
import { useDispatch, useSelector } from "react-redux";
import * as clientActions from "../actions/clientActions";
import Remarketing from "../components/Client/Remarketing/RemarketingContainer";
import * as campaignActions from "../actions/campaignActions";
import dynamicCampaignActions from "../actions/dynamicCampaignActions";
import DynamicCampaignsRouter from "../components/Client/DynamicCampaigns/DynamicCampaignsRouter";
import ClientBudgetRoutes from "../components/Client/Budgets/ClientBudgetRoutes";
import InventoryModifierContainer from "../components/Client/InventoryModifiers/InventoryModifierContainer";
import SalesDataImportContainer from "../components/Client/SalesDataImport/SalesDataImportContainer";
import ClientDashboard from "../components/Client/Dashboard/ClientDashboard";
import SalesMap from "../components/SalesMap/Container";
import FlashMessageList from "../components/FlashMessage";
import { getCurrentClientObject } from "../reducers/clients";
import FullPageLoader from "../components/Shared/Loaders/FullPageLoader";
import ExclusionsCampaignContainer from "../components/Client/Exclusions/ExclusionCampaignContainer";
import NegativeKeywordCampaignsContainer from "../components/Client/NegativeKeywords/NegativeKeywordCampaignsContainer";
import DealerSetupContainer from "../components/Client/DealerSetup/DealerSetupContainer";
import IAppState from "../interfaces/IAppState";
import CampaignsContainer from "../components/Client/Campaigns/CampaignsContainer";
import NotFound404 from "../components/Shared/NotFound404";
import SpecialOfferTemplatesRouter from "../components/Client/SpecialOfferTemplates/SpecialOfferTemplatesRouter";
import { Link } from "react-router-dom";

const ClientContext = React.createContext({});

type IClientRouteParams = {
    // Usage of interfaces on useParams is restricted, only types are allowed.
    id: string;
};

const ClientRoutes: FunctionComponent = () => {
    const { id: clientId = "" } = useParams<IClientRouteParams>();
    const dispatch = useDispatch();

    const state = useSelector((state: IAppState) => state);
    const fetchClient = (clientId: number) => dispatch(clientActions.fetchClient(clientId.toString()));
    const setClient = (clientId: number) => {
        dispatch(clientActions.setCurrentClient(clientId));
        dispatch(clientActions.fetchClientRoles());
    };
    const fetchDynamicCampaigns = (clientId: number) => {
        return dispatch({
            type: dynamicCampaignActions.REQUEST_DYNAMIC_CAMPAIGNS,
            clientId
        });
    };
    const fetchCampaigns = (clientId: number) => {
        dispatch(campaignActions.fetchCampaigns(clientId));
        dispatch(campaignActions.fetchMicrosoftCampaigns(clientId));
    };

    const currentClientObject = getCurrentClientObject(state);

    useEffect(() => {
        if (!clientId) return;
        setClient(Number(clientId));
        fetchClient(Number(clientId));
        fetchDynamicCampaigns(Number(clientId));
        fetchCampaigns(Number(clientId));
    }, [clientId]);
    return (
        <>
            <FlashMessageList />
            {!currentClientObject ? (
                <FullPageLoader message="Loading Client..." />
            ) : (
                <ClientContext.Provider value={{ client: currentClientObject }}>
                    <div className="h-full">
                        {currentClientObject.hasInventoryIssues && (
                            <div className="bg-yellow-500 px-4 py-2">
                                <h1 className="text-2xl text-yellow-900 gap-2 flex items-center">
                                    This client is experiencing issues with inventory,
                                    <Link to={"/clients?insight=has_inventory_issues"} className={"underline"}>
                                        view inventory insight
                                    </Link>
                                </h1>
                            </div>
                        )}
                        <Routes>
                            <Route index element={<ClientDashboard />} />
                            <Route element={<CampaignsContainer clientId={Number(clientId)} />} path={"campaigns/*"} />
                            <Route element={<SalesMap />} path="sales-map" />
                            <Route element={<Remarketing />} path="remarketing/*" />
                            <Route element={<ClientBudgetRoutes />} path="budgets/*" />
                            <Route element={<DynamicCampaignsRouter />} path="dynamic-campaigns/*" />
                            <Route element={<InventoryModifierContainer />} path="inventory-modifiers" />
                            <Route element={<ExclusionsCampaignContainer />} path="/exclusions/*" />
                            <Route element={<NegativeKeywordCampaignsContainer />} path="negative-keywords/campaigns" />
                            <Route element={<SalesDataImportContainer path={""} />} path="import-sales-data" />
                            <Route element={<SpecialOfferTemplatesRouter />} path={"special-offers/*"} />
                            <Route element={<DealerSetupContainer />} path="dealer-setup/*" />
                            <Route path="*" element={<NotFound404 />} />
                        </Routes>
                    </div>
                </ClientContext.Provider>
            )}
        </>
    );
};

export default hot(module)(ClientRoutes);

export { ClientContext };
