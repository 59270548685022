import React from "react";
import numeral from "numeral";

const DifferenceCell: React.FC<{ type: string; title: string }> = ({ type, title }) => {
    return (
        <div className="cell-wrapper notification-cell">
            <span className={`notification n-${type}`} />
            <span className={`notification-title`}>{numeral(title).format("0,0.00")}</span>
        </div>
    );
};

export default DifferenceCell;
