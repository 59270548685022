import React, { useRef, ChangeEvent, useState, useEffect, FunctionComponent } from "react";
import Button, { PRIMARY_BUTTON, SECONDARY_BUTTON, DANGER_BUTTON } from "../Button";
import InformationIcon from "../Icons/InformationIcon";
import ModalToggle from "../Dialogs/ModalToggle";
import CloseIcon from "../Icons/CloseIcon";
import RefreshIcon from "../Icons/RefreshIcon";
import usePreviousState from "../../../hooks/usePreviousState";

interface IBulkUpload {
    id: number;
    uploading: boolean;
    uploadCSV(id: number, fileInput: any): void;
}

const useResetFile = (uploading: boolean, fileUpload: any, setFileName: any) => {
    const previousUploadingState = usePreviousState(uploading);
    useEffect(() => {
        if (previousUploadingState && !uploading) {
            if (fileUpload.current) {
                fileUpload.current.value = "";
                setFileName("");
            }
        }
    }, [uploading]);
};

interface IConfirmFileUpload {
    hide(): void;
    handleFileUpload: any;
    fileName: string;
}

const ConfirmFileUpload: FunctionComponent<IConfirmFileUpload> = ({
    hide,
    handleFileUpload,
    fileName
}: IConfirmFileUpload) => {
    return (
        <>
            <div className="text-xl">Are you sure you want to upload this file?</div>
            <p className="py-6">This will replace the keywords listed below with the ones in {fileName}.</p>
            <div className="flex flex-right items-center ">
                <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                    Cancel
                </Button>
                <Button styleType={DANGER_BUTTON} onClick={() => handleFileUpload(hide)}>
                    Okay
                </Button>
            </div>
        </>
    );
};

const BulkUpload: React.FunctionComponent<IBulkUpload> = ({ uploadCSV, id, uploading }: IBulkUpload) => {
    const fileUpload = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState("");
    useResetFile(uploading, fileUpload, setFileName);

    const handleFileSelector = () => {
        if (fileUpload.current) {
            fileUpload.current.click();
        }
    };
    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            setFileName(event.target.files[0].name);
        }
    };

    const handleCancel = () => {
        if (fileUpload.current) {
            fileUpload.current.value = "";
            setFileName("");
        }
    };

    const handleFileUpload = (hide: () => void) => {
        hide();
        uploadCSV(id, fileUpload.current);
    };

    return (
        <div className="w-1/3 flex">
            <div className="flex items-end">
                {fileName ? (
                    <ModalToggle
                        style={{ width: "20%" }}
                        className="w-1/4"
                        toggleButton={(open: () => void) => (
                            <Button
                                onClick={open}
                                styles="bg-green-500 hover:bg-green-700 text-white flex items-center"
                                style={{ minWidth: "13rem" }}
                            >
                                {uploading ? <RefreshIcon className="w-6 h-6 rotate-fast" /> : ""}
                                Import{uploading ? "ing" : ""} File
                            </Button>
                        )}
                        modalContent={(hide: () => void) => (
                            <ConfirmFileUpload hide={hide} handleFileUpload={handleFileUpload} fileName={fileName} />
                        )}
                    />
                ) : (
                    <label htmlFor="bulk-upload" className="mx-4">
                        <Button styleType={PRIMARY_BUTTON} onClick={handleFileSelector} style={{ minWidth: "13rem" }}>
                            Upload CSV
                        </Button>
                    </label>
                )}

                <input
                    className={"hidden"}
                    id="bulk-upload"
                    ref={fileUpload}
                    name="bulk-upload"
                    onChange={handleFileSelect}
                    type="file"
                    accept=".tsv,.csv"
                    data-testid="bulk-upload"
                />
            </div>

            <div className="flex items-center w-1/2">
                <ModalToggle
                    toggleButton={(open: () => void) => (
                        <button
                            type="button"
                            onClick={open}
                            className="cursor-pointer"
                            data-testid="information-button"
                        >
                            <InformationIcon className="h-6 w-6 text-blue-500 hover:text-blue-700 " />
                        </button>
                    )}
                    modalContent={(hide: () => void) => {
                        return <div>Some information</div>;
                    }}
                />

                <div className="flex w-full items-end">
                    <div className="flex-1" style={{ minWidth: 0 }}>
                        <div className="truncate">{fileName ? fileName : "No file chosen"}</div>
                    </div>
                    {fileName && (
                        <button onClick={handleCancel} data-testid="cancel-button">
                            <CloseIcon className="w-6 h-6" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BulkUpload;
