import { useState, useEffect, useRef } from "react";

/**
 * Pretty much copied from https://github.com/xnimorz/use-debounce and usehooks.com
 *
 * @param {*} value
 * @param {int} delay
 */
function useDebounce<T>(value: T, delay?: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class.
    // We use it to store the setTimeout ID so that we can cancel it on effect cleanup.
    const handler: any = useRef();

    useEffect(
        () => {
            // Update debounced value after delay
            handler.current = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler.current);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

export default useDebounce;
