import React from "react";
import DeleteButton from "../DeleteButton";
import DuplicateButton from "./DuplicateButton";
import EtaConditionals from "../EtaConditionals";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import { IConditional, IExpandedTextAdTemplate } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import SettingsIcon from "../../../Shared/Icons/SettingsIcon";
import ChevronRightIcon from "../../../Shared/Icons/ChevronRightIcon";
import IAppState from "../../../../interfaces/IAppState";
import { connect } from "react-redux";
import SaveIcon from "../../../Shared/Icons/SaveIcon";
import EtaTemplateBoilerplateSaveModal from "../EtaTemplateBoilerplate/EtaTemplateBoilerplateSaveModal";
import { isSupervisor } from "../../../../reducers/currentUser";
import { IConditionalField } from "../../../../hooks/useConditionals";

interface IProps {
    value: IExpandedTextAdTemplate;
    currentUser?: any;
    idx: number;
    label?: string | null;
    hasConditionals: boolean;
    isOpen: boolean;
    conditionals: IConditionalField[];
    onRemove(): void;
    onDuplicate(idx: number): void;
    setConditionals(conditions: IConditionalField[]): void;
    onToggleOpen(): void;
}

const ActionBar = ({
    value,
    currentUser,
    idx,
    conditionals,
    hasConditionals,
    onRemove,
    onDuplicate,
    setConditionals,
    onToggleOpen,
    isOpen,
    label
}: IProps & ReturnType<typeof mapStateToProps>) => (
    <div className="flex flex-row my-2 justify-between h-auto content-center ">
        <div className="flex flex-row items-center justify-between ">
            <button
                type="button"
                onClick={onToggleOpen}
                className="h-full outline-none text-gray-500 hover:text-blue-600"
                style={{ alignItems: "center", justifyContent: "center" }}
            >
                <ChevronRightIcon
                    className="w-6 h-6"
                    style={{ transition: ".2s ease", ...(isOpen ? { transform: "rotate(90deg)" } : {}) }}
                />
            </button>

            <div className="ml-4 italic">{label || "Untitled ETA"}</div>
        </div>
        <div className="flex items-center w-32 justify-between">
            {isSupervisor(currentUser) && (
                <ModalToggle
                    style={{ width: "50%" }}
                    toggleButton={(open: any) => (
                        <button
                            onClick={open}
                            type="button"
                            className={`bg-transparent text-blue-200 hover:text-green-600 outline-none outline-none`}
                        >
                            <SaveIcon className="w-6 h-6" />
                        </button>
                    )}
                    modalContent={(hide: any) => <EtaTemplateBoilerplateSaveModal etaTemplate={value} hide={hide} />}
                />
            )}
            <DuplicateButton onDuplicate={() => onDuplicate(idx)} />
            <ModalToggle
                toggleButton={(open: any) => (
                    <button
                        onClick={open}
                        type="button"
                        className={`bg-transparent hover:text-green-600 outline-none outline-none ${
                            hasConditionals ? "text-green-500" : "text-blue-200"
                        }`}
                    >
                        <SettingsIcon className="w-6 h-6" />
                    </button>
                )}
                modalContent={(hide: any) => (
                    <EtaConditionals
                        label="Expanded Text Ad Conditionals"
                        conditionals={conditionals}
                        setConditionals={setConditionals}
                        templateIndex={idx}
                        hide={hide}
                    />
                )}
            />
            <DeleteButton onRemove={onRemove} />
        </div>
    </div>
);

const mapStateToProps = (state: IAppState) => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps)(ActionBar);
