import IIndicatorStatus from "../interfaces/IIndicatorStatus";
import { PENDING } from "./StatusIndicatorConstants";

export default {
    REQUEST_LOAD_DYNAMIC_CAMPAIGNS: "REQUEST_LOAD_DYNAMIC_CAMPAIGNS",
    REQUEST_LOAD_DYNAMIC_CAMPAIGNS_FAILURE: "REQUEST_LOAD_DYNAMIC_CAMPAIGNS_FAILURE",
    LOAD_DYNAMIC_CAMPAIGNS: "LOAD_DYNAMIC_CAMPAIGNS",

    REQUEST_DELETE_DYNAMIC_CAMPAIGN: "REQUEST_DELETE_DYNAMIC_CAMPAIGN",
    REQUEST_DELETE_DYNAMIC_CAMPAIGN_SUCCSSS: "REQUEST_DELETE_DYNAMIC_CAMPAIGN_SUCCSSS",

    REQUEST_CREATE_DYNAMIC_CAMPAIGN: "REQUEST_CREATE_DYNAMIC_CAMPAIGN",
    REQUEST_CREATE_DYNAMIC_CAMPAIGN_SUCCESS: "REQUEST_CREATE_DYNAMIC_CAMPAIGN_SUCCESS",
    REQUEST_CREATE_DYNAMIC_CAMPAIGN_FAIL: "REQUEST_CREATE_DYNAMIC_CAMPAIGN_FAIL",

    REQUEST_UPDATE_DYNAMIC_CAMPAIGN: "REQUEST_UPDATE_DYNAMIC_CAMPAIGN",
    REQUEST_UPDATE_DYNAMIC_CAMPAIGN_SUCCESS: "REQUEST_UPDATE_DYNAMIC_CAMPAIGN_SUCCESS",
    REQUEST_UPDATE_DYNAMIC_CAMPAIGN_FAIL: "REQUEST_UPDATE_DYNAMIC_CAMPAIGN_FAIL",

    REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN: "REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN",
    REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN_SUCCESS: "REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN_SUCCESS",
    REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN_FAIL: "REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN_FAIL",

    REQUEST_INVENTORY_SAMPLE_DATA: "REQUEST_INVENTORY_SAMPLE_DATA",
    REQUEST_INVENTORY_SAMPLE_DATA_SUCCESS: "REQUEST_INVENTORY_SAMPLE_DATA_SUCCESS",
    REQUEST_INVENTORY_SAMPLE_DATA_FAIL: "REQUEST_INVENTORY_SAMPLE_DATA_FAIL",

    FETCH_DYNAMIC_CAMPAIGN: "FETCH_DYNAMIC_CAMPAIGN",
    FETCH_DYNAMIC_CAMPAIGN_SUCCESS: "FETCH_DYNAMIC_CAMPAIGN_SUCCESS",
    FETCH_DYNAMIC_CAMPAIGN_FAILURE: "FETCH_DYNAMIC_CAMPAIGN_FAILURE",

    PUSH_DYNAMIC_CAMPAIGN: "PUSH_DYNAMIC_CAMPAIGN",
    PUSH_DYNAMIC_CAMPAIGN_SUCCESS: "PUSH_DYNAMIC_CAMPAIGN_SUCCESS",
    PUSH_DYNAMIC_CAMPAIGN_FAILURE: "PUSH_DYNAMIC_CAMPAIGN_FAILURE",

    REQUEST_PURGE_DYNAMIC_CAMPAIGN: "REQUEST_PURGE_DYNAMIC_CAMPAIGN",
    REQUEST_PURGE_DYNAMIC_CAMPAIGN_SUCCESS: "REQUEST_PURGE_DYNAMIC_CAMPAIGN_SUCCESS",
    REQUEST_PURGE_DYNAMIC_CAMPAIGN_FAILURE: "REQUEST_PURGE_DYNAMIC_CAMPAIGN_FAILURE",

    REQUEST_UNLOCK_DYNAMIC_CAMPAIGN: "REQUEST_UNLOCK_DYNAMIC_CAMPAIGN",
    REQUEST_UNLOCK_DYNAMIC_CAMPAIGN_SUCCESS: "REQUEST_UNLOCK_DYNAMIC_CAMPAIGN_SUCCESS",
    REQUEST_UNLOCK_DYNAMIC_CAMPAIGN_FAILURE: "REQUEST_UNLOCK_DYNAMIC_CAMPAIGN_FAILURE",

    SET_CURRENT_DYNAMIC_CAMPAIGN: "SET_CURRENT_DYNAMIC_CAMPAIGN",
    QUEUE_SOT_DETACH: "QUEUE_SOT_DETACH",
    RESET_STATE: "@@RESET/DYNAMIC_CAMPAIGN"
};

const STATUS_QUEUED = "STATUS_QUEUED";
const STATUS_GETTING_INVENTORY = "STATUS_GETTING_INVENTORY";
const STATUS_PUSHING_AD_GROUPS = "STATUS_PUSHING_AD_GROUPS";
const STATUS_PUSHING_KEYWORDS = "STATUS_PUSHING_KEYWORDS";
const STATUS_PUSHING_ADS = "STATUS_PUSHING_ADS";
const STATUS_FINISHED = "STATUS_FINISHED";

const statusIndicatorSteps: IIndicatorStatus[] = [
    { initialLabel: "Queued", completedLabel: "Queued", state: PENDING, actionType: STATUS_QUEUED },
    {
        initialLabel: "Getting Inventory",
        completedLabel: "Inventory Completed",
        state: PENDING,
        actionType: STATUS_GETTING_INVENTORY
    },
    {
        initialLabel: "Pushing Ad Groups",
        completedLabel: "Ad Groups Completed",
        state: PENDING,
        actionType: STATUS_PUSHING_AD_GROUPS
    },
    {
        initialLabel: "Pushing Keywords",
        completedLabel: "Keywords Completed",
        state: PENDING,
        actionType: STATUS_PUSHING_KEYWORDS
    },
    { initialLabel: "Pushing Ads", completedLabel: "Ads Completed", state: PENDING, actionType: STATUS_PUSHING_ADS },
    { initialLabel: "Finished", completedLabel: "Finished", state: PENDING, actionType: STATUS_FINISHED }
];

export {
    statusIndicatorSteps,
    STATUS_QUEUED,
    STATUS_GETTING_INVENTORY,
    STATUS_PUSHING_AD_GROUPS,
    STATUS_PUSHING_KEYWORDS,
    STATUS_PUSHING_ADS,
    STATUS_FINISHED
};
