import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { DateRange, Range, Calendar } from "react-date-range";
import moment from "moment";
import LoaderSpinner from "../../Shared/Loaders/LoaderSpinner";
import IBudgetFormValues from "../../../interfaces/Budgets/IBudgetFormValues";
import * as Constants from "../../../constants";
import TableLoader from "../../Shared/Table/TableLoader";
import CircleIcon from "../../Shared/Icons/CircleIcon";
import { useForm } from "react-hook-form";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import CurrencyField from "../../Shared/Form/CurrencyField.standalone";
import useLabel from "../../../hooks/budgets/useLabel";
import { IErrors } from "../../../hooks/budgets/useBudget";
import BudgetPushPreview from "../../Shared/Budget/BudgetPushPreview";
interface IProps {
    budget?: IBudgetFormValues;
    title: string;
    loadingBudget: boolean;
    loadingMessage: string;
    isSaving?: boolean;
    cancelPath: string;
    apiErrors: IErrors;
    onSubmit?: (values: IBudgetFormValues) => void;
}

const fieldStyles =
    "appearance-none block w-full bg-gray-200 text-gray-800 border rounded py-4 px-2 leading-tight focus:outline-none focus:bg-white border-gray-200 focus:border-gray";
const formContainerStyles =
    "relative bg-white flex flex-col w-full sm:w-4/5 md:w-full lg:w-2/3 xl:w-1/2 shadow-md rounded p-4 mb-4 text-base";
const fieldGroupStyles = "flex flex-wrap mb-4 w-full";
const labelStyles = "block uppercase tracking-wider text-gray-800 font-bold mb-2";
const currencyFieldStyles = "w-full sm:w-1/2";
const halfWidthField = "w-full sm:w-1/2 ";
const rightField = "pl-2";
const leftField = "pr-2";
const errorStyles = "text-red-500 text-sm italic uppercase";
const buttonStyles = "bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";

const BudgetForm = ({
    budget,
    loadingBudget,
    loadingMessage,
    title,
    cancelPath,
    isSaving,
    apiErrors,
    onSubmit
}: IProps) => {
    const datepickerRef = useRef<HTMLDivElement>() as LegacyRef<HTMLDivElement>;
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [showLaunchedOnPicker, setShowLaunchedOnPicker] = useState<boolean>(false);

    const dateFormat = "MMM Do YYYY";
    const maxTargetSpend = 100000;

    //useForm hook:
    const {
        register, //adds an input to the list of tracked fields.
        handleSubmit, //through this handler, form action is attached to the submit event.
        formState: { errors, isValid, touchedFields },
        getValues, //query current field values in run time.
        watch, //react to changes on specified fields through this function.
        setValue, //change the value of a field in run time.
        trigger
    } = useForm<IBudgetFormValues>({
        defaultValues: {
            ...budget,
            budgetType: budget?.typeId || Constants.BUDGET_TYPE_ID_MONTHLY,
            monthlyBudget: budget?.targetSpend,
            startDate: (!!budget?.eventStartsAt && moment(budget?.eventStartsAt).format(dateFormat)) || "",
            endDate: (!!budget?.eventEndsAt && moment(budget?.eventEndsAt).format(dateFormat)) || "",
            proratedStartDate: budget?.activation?.proratedStartDate,
            digadServiceLaunchedOn:
                (!!budget?.activation?.digadServiceLaunchedOn &&
                    moment.utc(budget?.activation?.digadServiceLaunchedOn).format(dateFormat)) ||
                ""
        },
        mode: "onChange",
        reValidateMode: "onChange"
    });

    useOnClickOutside(datepickerRef, () => {
        setShowPicker(false);
        setShowLaunchedOnPicker(false);
    }); //used to hide datepicker by clicking anywhere outside of it.

    //watched values:
    const budgetType = Number(watch("budgetType"));
    const label = watch("label");
    const labelIdValue = watch("labelId");
    const isManagedManually = watch("isManagedManually");
    const proratedStartDate = watch("proratedStartDate");
    const [showProratedTab, toggleProrated] = useState<boolean>(!!proratedStartDate);

    //manually registering pattern (currency) fields to prevent after validation rerendering, which causes losing focus on the input after first onChange.
    const labelIdField = register("labelId", { required: true });
    const monthlyBudget = register("monthlyBudget", {
        required: true,
        min: 0,
        max: maxTargetSpend,
        pattern: /^[-+]?[0-9]*\.?[0-9]+$/,
        onBlur: () => trigger("monthlyBudget")
    });
    const breachBuffer = register("breachBuffer", {
        required: false,
        min: 0,
        max: maxTargetSpend,
        pattern: /^[-+]?[0-9]*\.?[0-9]+$/
    });
    //labels
    const {
        isLoading: loadingLabels,
        labelOptions,
        sortedArrayLabels,
        handleFetchRefreshLabels,
        findById,
        isInactive
    } = useLabel();
    //handlers
    const formSubmit = (values: IBudgetFormValues) => {
        if (isValid) {
            onSubmit && onSubmit(values);
            setValue("changeReason", "");
        }
    };
    const handleDateRangeChange = (range: Range) => {
        const { startDate, endDate } = range;
        startDate && setValue("startDate", moment(startDate).format(dateFormat));
        endDate && setValue("endDate", moment(endDate).format(dateFormat));
    };
    const clearDates = () => {
        // clears dates on monthlyType click
        setValue("startDate", "");
        setValue("endDate", "");
        setValue("proratedStartDate", "");
    };
    const budgetTypeClickHandler = () => {
        toggleProrated(false);
        clearDates();
    };
    const proratedClickHandler = () => {
        toggleProrated(true);
        clearDates();
    };
    //effects
    useEffect(() => {
        setValue("label", findById(budget?.labelId));
    }, [loadingLabels, budget]);

    useEffect(() => {
        // maps selected label to label object
        setValue("label", findById(labelIdValue));
    }, [labelIdValue]);

    const additionalErrors = (errors: any) =>
        !!errors && Object.keys(errors).length
            ? Object.keys(errors)
                  //.filter((key) => !Object.keys(getValues()).includes(key))
                  .map((key) => (
                      <div key={key} className="error-message items-center flex flex-1">
                          <CircleIcon className="h-4 w-4" />
                          <span className={"ml-2"}>{errors[key]}</span>
                      </div>
                  ))
            : null;

    const now = moment();
    return (
        <div className={formContainerStyles}>
            <h2 className="font-bold text-2xl self-center text-gray-700 uppercase">{title}</h2>
            <form>
                {/* Radio Buttons */}
                <div className={`${fieldGroupStyles} flex-col`}>
                    <label htmlFor="" className={labelStyles}>
                        <span>Budget Type</span>
                        <span>*</span>
                    </label>
                    <div className="flex flex-wrap mx-6">
                        <div className={`${halfWidthField} ${leftField}`}>
                            <label
                                className="flex cursor-pointer block uppercase tracking-wider text-gray-800 font-bold content-center"
                                htmlFor="typeMonthly"
                            >
                                <input
                                    {...register("budgetType", { required: true })}
                                    id="typeMonthly"
                                    data-testid={"typeMonthly"}
                                    disabled={isSaving}
                                    type="radio"
                                    className="mr-2 px-3 leading-tight cursor-pointer"
                                    value={Constants.BUDGET_TYPE_ID_MONTHLY}
                                    onClick={budgetTypeClickHandler}
                                    checked={budgetType === Constants.BUDGET_TYPE_ID_MONTHLY && !showProratedTab}
                                />
                                <span className="mx-2">Monthly</span>
                            </label>
                        </div>
                        <div className={`${halfWidthField} ${rightField}`}>
                            <label className={labelStyles} htmlFor="typeProrated">
                                <input
                                    {...register("budgetType", { required: true })}
                                    id="typeProrated"
                                    data-testid={"typeProrated"}
                                    disabled={isSaving}
                                    type="radio"
                                    className="mr-2 leading-tight cursor-pointer"
                                    value={Constants.BUDGET_TYPE_ID_MONTHLY}
                                    onClick={proratedClickHandler}
                                    checked={showProratedTab}
                                />
                                <span className="mx-2">Prorated</span>
                            </label>
                        </div>
                        <div className={`${halfWidthField}`}>
                            <label className={labelStyles} htmlFor="typeEvent">
                                <input
                                    {...register("budgetType", { required: true })}
                                    id="typeEvent"
                                    data-testid={"typeEvent"}
                                    disabled={isSaving}
                                    type="radio"
                                    className="mr-2 leading-tight cursor-pointer"
                                    value={Constants.BUDGET_TYPE_ID_EVENT}
                                    onClick={budgetTypeClickHandler}
                                    checked={budgetType === Constants.BUDGET_TYPE_ID_EVENT}
                                />
                                <span className="mx-2">Event</span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Event Date Picker */}
                {budgetType === Constants.BUDGET_TYPE_ID_EVENT && (
                    <div className={`${fieldGroupStyles} -mt-2`}>
                        <div className="w-full sm:w-1/2  mb-8 md:mb-0 sm:pr-2">
                            <label className={labelStyles} htmlFor="startDate">
                                <span>Start of Day</span>
                                <span>*</span>
                            </label>
                            <input
                                data-testid="startDate"
                                disabled={isSaving}
                                className={fieldStyles}
                                onClick={() => setShowPicker(true)}
                                placeholder="mm/dd/yyyy"
                                readOnly={true}
                                {...register("startDate", { required: true })}
                            />
                            {errors.startDate && <span className={errorStyles}>required</span>}
                        </div>

                        <div className="w-full sm:w-1/2 sm:pl-2">
                            <label className={labelStyles} htmlFor="endDate">
                                <span>End of Day</span>
                                <span>*</span>
                            </label>
                            <input
                                data-testid="endDate"
                                disabled={isSaving}
                                className={fieldStyles}
                                onClick={() => setShowPicker(true)}
                                placeholder="mm/dd/yyyy"
                                readOnly={true}
                                {...register("endDate", { required: true })}
                            />
                            {errors.endDate && <span className={errorStyles}>required</span>}
                        </div>

                        {!!showPicker && (
                            <div
                                className="max-w-md mt-24 z-10 absolute rounded overflow-hidden shadow-lg"
                                ref={datepickerRef}
                            >
                                <DateRange
                                    linkedCalendars={true}
                                    data-testid={"DateRange"}
                                    onChange={(range) => handleDateRangeChange(range)}
                                    theme={{
                                        Calendar: {
                                            width: 200
                                        },
                                        PredefinedRanges: {
                                            marginLeft: 10,
                                            marginTop: 10
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}

                {/* Prorated Date Picker */}
                {showProratedTab && (
                    <div className={`${fieldGroupStyles} -mt-2`}>
                        <div className="w-full sm:w-1/2  mb-8 md:mb-0 sm:pr-2">
                            <label className={labelStyles} htmlFor="startDate">
                                <span>Prorated start date</span>
                                <span>*</span>
                            </label>
                            <input
                                data-testid="proratedStartDate"
                                disabled={isSaving}
                                className={fieldStyles}
                                onClick={() => setShowPicker(true)}
                                placeholder="mm/dd/yyyy"
                                readOnly={true}
                                {...register("proratedStartDate", { required: true })}
                            />
                            {errors.proratedStartDate && <span className={errorStyles}>required</span>}
                        </div>

                        {!!showPicker && (
                            <div
                                className="max-w-md mt-24 z-10 absolute rounded overflow-hidden shadow-lg"
                                ref={datepickerRef}
                            >
                                <Calendar
                                    data-testid={"proratedCalendar"}
                                    date={now}
                                    minDate={now}
                                    onChange={(date) => {
                                        date &&
                                            setValue("proratedStartDate", (date as moment.Moment).format(dateFormat));
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}

                {/* Change Reason */}
                <div className={`${fieldGroupStyles} ${budgetType === Constants.BUDGET_TYPE_ID_EVENT ? "" : "-mt-2"}`}>
                    <label className={labelStyles} htmlFor="changeReason">
                        <span>Change Reason</span>
                        <span>*</span>
                    </label>
                    <input
                        data-testid="change-reason"
                        {...register("changeReason", { required: true })}
                        disabled={isSaving}
                        className={fieldStyles}
                        defaultValue={budget?.changeReason}
                    />
                    {errors.changeReason && <span className={errorStyles}>required</span>}
                </div>

                {/* Change Request Link */}
                <div className={fieldGroupStyles}>
                    <label className={labelStyles} htmlFor="changeRequestLink">
                        <span>Change Request Link</span>
                        <span>*</span>
                    </label>
                    <input
                        {...register("changeRequestLink", { required: true })}
                        disabled={isSaving}
                        className={fieldStyles}
                        defaultValue={budget?.changeRequestLink}
                    />
                    {errors.changeRequestLink && <span className={errorStyles}>required</span>}
                </div>

                {/* Labels */}
                <div className={`${fieldGroupStyles} flex-col`}>
                    <label className={labelStyles} htmlFor="label">
                        <span>Label</span>
                        <span>*</span>
                    </label>

                    <div className="flex">
                        <div className={`${halfWidthField} ${leftField} self-baseline `}>
                            <div className="relative">
                                <select
                                    disabled={isSaving || loadingLabels}
                                    defaultValue={budget?.labelId}
                                    value={labelIdValue}
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-800 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray"
                                    {...labelIdField}
                                >
                                    <option value="" />
                                    {labelOptions}
                                </select>
                                {loadingLabels ? (
                                    <LoaderSpinner className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800" />
                                ) : (
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={`${halfWidthField} ${rightField} self-baseline`}>
                            <button type="button" onClick={handleFetchRefreshLabels} className={`${buttonStyles} `}>
                                Refresh Labels
                            </button>
                        </div>
                    </div>

                    {!loadingLabels && isInactive(label) && (
                        <span className={errorStyles}>
                            Currently selected Label is not an "Active" label in AdWords'
                        </span>
                    )}
                    {!loadingLabels && !sortedArrayLabels.length && (
                        <span className={errorStyles}>Client has no labels. Please create one and refresh.</span>
                    )}
                    {errors.labelId && <span className={errorStyles}>required</span>}
                </div>

                {/* Monthly Budget */}
                <div className={fieldGroupStyles}>
                    <CurrencyField
                        testId={"monthly-budget-input"}
                        disabled={isSaving}
                        className={currencyFieldStyles}
                        defaultValue={budget?.targetSpend}
                        label="Monthly Budget"
                        max={maxTargetSpend}
                        isRequired={true}
                        touched={touchedFields}
                        errors={errors.monthlyBudget}
                        field={monthlyBudget}
                        onChange={monthlyBudget.onChange}
                    />
                </div>

                {/* Breach Buffer */}
                <div className={fieldGroupStyles}>
                    <CurrencyField
                        testId={"breach-buffer-input"}
                        disabled={isSaving}
                        defaultValue={budget?.breachBuffer}
                        className={currencyFieldStyles}
                        label="Breach Buffer"
                        max={maxTargetSpend}
                        errors={errors.breachBuffer}
                        touched={touchedFields}
                        isRequired={false}
                        field={breachBuffer}
                    />
                </div>

                <div className={fieldGroupStyles}>
                    <label className={labelStyles} htmlFor="digadServiceLaunchedOn">
                        <span>DigAd Services Launched On</span>
                        <span className="text-xs text-gray"> Optional Ford</span>
                    </label>
                    <input
                        data-testid="digad_service_launched_on"
                        disabled={isSaving}
                        readOnly={true}
                        className={fieldStyles}
                        onClick={() => setShowLaunchedOnPicker(true)}
                        placeholder="mm/dd/yyyy"
                        {...register("digadServiceLaunchedOn", { required: false })}
                    />
                </div>

                {!!showLaunchedOnPicker && (
                    <div className="max-w-md z-10 absolute rounded overflow-hidden shadow-lg" ref={datepickerRef}>
                        <Calendar
                            data-testid={"digadServiceLaunchedOn"}
                            date={now}
                            onChange={(date) => {
                                date && setValue("digadServiceLaunchedOn", (date as moment.Moment).format(dateFormat));
                            }}
                        />
                    </div>
                )}

                {/* Managed Manually */}
                <div className={fieldGroupStyles}>
                    <div className="w-full">
                        <label className={labelStyles} htmlFor="isManagedManually">
                            <input
                                disabled={isSaving}
                                type="checkbox"
                                checked={isManagedManually ? true : false}
                                className="mr-2 leading-tight"
                                {...register("isManagedManually")}
                            />
                            <span> Managed Manually</span>
                        </label>
                    </div>
                </div>

                {/* Buttons  */}
                <div className="flex items-center justify-between">
                    <div className="flex ">
                        <BudgetPushPreview
                            disabled={isSaving || !isValid}
                            buttonText="Save Budget"
                            budget={budget}
                            form={getValues()}
                            action={handleSubmit(formSubmit)}
                            isSaving={isSaving}
                        ></BudgetPushPreview>
                        {isSaving && <LoaderSpinner className="ml-2 m-auto" />}
                    </div>
                    <Link
                        to={cancelPath}
                        className="inline-block align-baseline font-bold text-blue-500 hover:text-blue-800 mx-4 px-4"
                    >
                        Cancel
                    </Link>
                </div>

                {/* Error message fallback */}

                <div className="flex-row items-center pt-4 px-2">{additionalErrors(apiErrors.errors)}</div>
            </form>
            {loadingBudget && <TableLoader className="self-center" message={loadingMessage} />}
        </div>
    );
};

export default BudgetForm;
