import React from "react";

const SearchFilter: React.FC<{
    searchText: string;
    onSearchChange: (e: any) => void;
}> = ({ searchText, onSearchChange }) => {
    return (
        <div className="filter">
            <input
                type="text"
                className="py-1 px-4 rounded border border-gray-300"
                value={searchText}
                onChange={onSearchChange}
                placeholder="Search"
            />
        </div>
    );
};

export default SearchFilter;
