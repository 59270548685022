import React from "react";
import CopyIcon from "../../../Shared/Icons/CopyIcon";

interface IProps {
    onDuplicate: () => void;
}
const DuplicateButton: React.FC<IProps> = ({ onDuplicate }) => {
    return (
        <button
            type="button"
            className="bg-transparent text-blue-200 hover:text-blue-600 h-full outline-none"
            onClick={(e) => {
                e.preventDefault();
                onDuplicate();
            }}
        >
            <CopyIcon className="w-6 h-6" />
        </button>
    );
};

export default DuplicateButton;
