import { connect } from "react-redux";
import InventoryModifierList from "./InventoryModifierList";
import { requestInventoryModifiers, saveInventoryModifiers } from "../../../actions/InventoryModifierActions";
import IInventoryModifier from "../../../interfaces/IInventoryModifier";
import IEntity from "../../../interfaces/IEntity";

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSave: (clientId: number, inventoryModifiers: IInventoryModifier[]) =>
            dispatch(saveInventoryModifiers(clientId, inventoryModifiers)),
        fetchModifiers: (clientId: number) => dispatch(requestInventoryModifiers(clientId))
    };
};

const inventoryModifierSelector = (modifiers: IEntity<IInventoryModifier>) => {
    return Object.keys(modifiers).reduce((acc, key) => {
        return [...acc, modifiers[key]];
    }, [] as IInventoryModifier[]);
};

const mapStateToProps = (state: any) => {
    return {
        clientId: state.clients.currentClient,
        inventoryModifiers: inventoryModifierSelector(state.inventoryModifiers.inventoryModifiers),
        saving: state.inventoryModifiers.saving,
        loading: state.inventoryModifiers.loading,
        loadedAt: state.inventoryModifiers.loadedAt
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryModifierList);
