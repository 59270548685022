import React from "react";
import AdTag from "./AdTag";
import { displayUrlHolderStyles, deskTopContainerStyles, desktopLinkStyle } from "./styles";

interface IProps {
    h1: string;
    h2: string;
    h3?: string;
    displayUrl: string;
    finalUrl: string;
    d1: string;
    d2?: string;
}

const DesktopPreview: React.FC<IProps> = ({ finalUrl, h1, h2, h3, displayUrl, d1, d2 }) => {
    return (
        <div style={{ background: "#fff", padding: "7px 5px !important", marginTop: "20px" }} className={"rounded-lg"}>
            <div style={deskTopContainerStyles}>
                <div>
                    <a href={finalUrl} style={desktopLinkStyle}>
                        {h1} {h2 && `| ${h2}`}
                        {h3 && ` | ${h3}`}
                    </a>
                </div>
                <div style={displayUrlHolderStyles}>
                    <AdTag />
                    <span> {displayUrl}</span>
                </div>
                <div style={{ fontSize: "13px", color: "#545454" }}>
                    {d1} {d2}
                </div>
            </div>
        </div>
    );
};

export default DesktopPreview;
