import React from "react";
import Button, { DANGER_BUTTON, SECONDARY_BUTTON } from "../../../Shared/Button";
import { IEtaTemplateBoilerplate } from "../../../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";

interface IProps {
    etaTemplateBoilerplate: IEtaTemplateBoilerplate;
    remove: (eta: IEtaTemplateBoilerplate) => void;
    hide: () => void;
}
const EtaTemplateBoilerplateDeleteModal = ({ etaTemplateBoilerplate, remove, hide }: IProps) => {
    const handleRemove = () => {
        remove(etaTemplateBoilerplate);
        hide();
    };

    return (
        <div className={`w-full flex justify-around items-center h-full`}>
            <div className={`flex flex-col justify-around items-center conent-center`} style={{ height: "200px" }}>
                <div className={`text-xl`}>Are you sure you want to delete this template?</div>
                <div>{etaTemplateBoilerplate.name}</div>
                <div className={`flex flex-row items-center`}>
                    <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                        Cancel
                    </Button>
                    <Button styleType={DANGER_BUTTON} onClick={() => handleRemove()}>
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EtaTemplateBoilerplateDeleteModal;
