import React from "react";
import ModalToggle from "../../Shared/Dialogs/ModalToggle";
import Button, { SECONDARY_BUTTON, DANGER_BUTTON, SECONDARY_DANGER_BUTTON } from "../../Shared/Button";

const UnlockButton = ({ hidden, onConfirm }: { hidden: boolean; onConfirm: () => void }) => {
    if (hidden) {
        return <div />;
    }

    return (
        <ModalToggle
            style={{ width: "25%" }}
            className="w-1/4"
            toggleButton={(open: () => void) => (
                <Button onClick={open} styleType={SECONDARY_DANGER_BUTTON} styles="mx-4">
                    Unlock
                </Button>
            )}
            modalContent={(hide: () => void) => {
                const handleConfirm = (): void => {
                    onConfirm();
                    hide();
                };

                return (
                    <div>
                        <div className="text-xl">Are you sure you want to unlock this campaign?</div>
                        <p className="py-6">
                            This will unlock the campaign, and can cause serious issues if jobs are currently being
                            processed.{" "}
                        </p>
                        <div className="flex flex-right items-center ">
                            <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                                Cancel
                            </Button>
                            <Button styleType={DANGER_BUTTON} onClick={handleConfirm}>
                                Confirm
                            </Button>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default UnlockButton;
