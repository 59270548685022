import { AnyAction, combineReducers } from "redux";
import actions from "../../actions/dynamicCampaignActions";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";
/**
 * Created by fjiles@dealerinspire.com on 1/20/17.
 */
const defaultSampleData = {
    model_lowest_price: "$XX,XXX",
    model_lease_payments: "$XXX",
    model_monthly_payments: "$XXX",
    model_discounts: "$XXX",
    model_lowest_miles: "XX,XXX",
    duplicate_count: "XX",
    trims_count: "X",
    colors_count: "X"
};

const sampleDataReducer = (state = defaultSampleData, action: AnyAction) => {
    switch (action.type) {
        case actions.LOAD_CAMPAIGN_PARAMETERS:
            return { ...defaultSampleData, ...action.sampleData };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};
const valuesReducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.LOAD_CAMPAIGN_PARAMETERS:
            return { ...action.parameters };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};
const loading = (state = false, action: AnyAction) => {
    switch (action.type) {
        case actions.REQUEST_CAMPAIGN_PARAMETERS:
            return true;
        case actions.LOAD_CAMPAIGN_PARAMETERS:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    values: valuesReducer,
    loading,
    sampleData: sampleDataReducer
});
