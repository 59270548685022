import React, { FunctionComponent } from "react";
import _ from "lodash";
import ReactTable, { RowInfo, Column } from "react-table";
import IEntity from "../../../interfaces/IEntity";
import { Table, TableLoader } from "../../Shared/Table";
import IAdjustment from "../../../interfaces/IAdjustment";
import ISalesMapStatistic from "../../../interfaces/ISalesMapStatistic";

interface IAdjustmentsTable {
    adjustments: IAdjustment[];
    stats: IEntity<ISalesMapStatistic>;
    loading: boolean;
    onAdjustmentSelected(adjustmentId: number): void;
    selectedAdjustment: number | null;
}

const columns: Column[] = [
    {
        Header: "Location",
        // we want the value stored with the name key not location but the header should be location
        accessor: "name",
        id: "location"
    },

    {
        Header: "Adjustment",
        accessor: "bidModifierDisplay",
        id: "bidModifierDisplay"
    },
    {
        Header: "Cost",
        accessor: "cost",
        id: "cost"
    },
    {
        Header: "Conversions",
        accessor: "conversions",
        id: "conversions"
    },
    {
        Header: "CTR",
        accessor: "ctr",
        id: "ctr"
    },
    {
        Header: "AvgPos",
        accessor: "avgPosition",
        id: "avgPosition"
    }
];

const AdjustmentsTable: FunctionComponent<IAdjustmentsTable> = ({
    adjustments,
    stats,
    loading,
    onAdjustmentSelected,
    selectedAdjustment
}) => {
    if (Object.keys(adjustments).length === 0) {
        return <h3 className="text-xl font-semibold py-8">No Adjustments Found</h3>;
    }

    const data = adjustments
        .filter(({ location }) => Boolean(location))
        .map((adjustment) => {
            const stat = stats[adjustment.id] || {};
            return {
                ...adjustment,
                cost: stat.cost ? stat.cost : "-",
                conversions: stat.conversions ? stat.conversions : "-",
                ctr: stat.ctr ? stat.ctr : "-",
                avgPosition: stat.avgPosition ? stat.avgPosition : "-"
            };
        });

    return (
        <Table
            data={data}
            columns={columns}
            className="mt-4"
            getTrProps={(state: any, rowInfo: RowInfo | undefined) => {
                if (rowInfo && rowInfo.row) {
                    return {
                        onClick: () => onAdjustmentSelected(rowInfo.original.id),
                        ...(selectedAdjustment === rowInfo.original.id ? { className: "bg-yellow-500" } : ""),
                        style: {
                            cursor: "pointer"
                        }
                    };
                }
                return {};
            }}
            loadingTable={loading}
            loadingMessage={"Loading bid adjustments"}
        />
    );
};

export default AdjustmentsTable;
