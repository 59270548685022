import React, { Fragment, useEffect } from "react";
import moment from "moment";
import { Link, useLocation, useParams } from "react-router-dom";
import PageTitle from "../../../Shared/PageTitle/PageTitle";
import ExportLogs from "./ExportLogs";
import Detail from "../../DynamicCampaigns/Log/Detail";
import FullPageLoader from "../../../Shared/Loaders/FullPageLoader";
import { Notification } from "../../../Shared";
import IEntity from "../../../../interfaces/IEntity";
import IFacebookLog from "../../../../interfaces/IFacebookLog";
import IGoogleLog from "../../../../interfaces/IGoogleLog";
import Button, { SECONDARY_BUTTON, baseStyles, primaryClasses } from "../../../Shared/Button";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import PreviousLogs from "./PreviousLogs";
import ChevronLeftIcon from "../../../Shared/Icons/ChevronLeftIcon";
import { useDispatch, useSelector } from "react-redux";
import IFacebookAutoExportType from "../../../../interfaces/IFacebookAutoExport";
import aggregateErrorsAndRejectionsByVIN from "../../../../utils/aggregateErrorsAndRejectionsByVIN";
import getEntityByKeyValue from "../../../../utils/getEntityByKeyValue";
import IAppState from "../../../../interfaces/IAppState";
import { fetchGoogleLogs } from "../../../../actions/remarketing/inventoryExportsActions";
import { fetchFacebookLogs } from "../../../../actions/remarketing/facebookAutoExportActions";
import IGoogleAutoExport from "../../../../interfaces/IGoogleAutoExport";

interface IProps {
    clientId?: number;
    exportType: string;
}

type IErrorsAndRejections = IEntity<{
    item: any;
    errors: { [key: string]: any };
    rejections: { [key: string]: string | string[] };
}>;

interface ExportLogListConfig {
    [key: string]: any;
}

const PageHeader = () => {
    return (
        <PageTitle title={"Remarketing Export Logs"}>
            <Link to={"../"} className={`${baseStyles} ${primaryClasses} block flex`}>
                <ChevronLeftIcon className="w-6 h-6" />
                <span className="ml-4">Back to Remarketing Exports</span>
            </Link>
        </PageTitle>
    );
};

const ExportLogList: React.FunctionComponent<IProps> = ({ clientId, exportType }) => {
    const dispatch = useDispatch();
    const { exportId } = useParams();
    const { googleAutoExportLogs, googleAutoExports, facebookAutoExportLogs, facebookAutoExports } =
        useSelector((state: IAppState) => state) || {};
    const config: ExportLogListConfig = {
        google: {
            fetchAction: fetchGoogleLogs,
            exportLogs: googleAutoExportLogs,
            autoExports: googleAutoExports,
            aggregationProp: "id2Template",
            latestLog: (googleAutoExportLogs && googleAutoExportLogs?.entities[googleAutoExportLogs.allIds[0]]) || null
        },
        facebook: {
            fetchAction: fetchFacebookLogs,
            exportLogs: facebookAutoExportLogs,
            autoExports: facebookAutoExports,
            aggregationProp: "vehicleId",
            latestLog:
                (facebookAutoExportLogs && facebookAutoExportLogs.entities[facebookAutoExportLogs.allIds[0]]) || null
        }
    };
    const {
        fetchAction,
        latestLog,
        autoExports: { entities: autoExports = {} } = {},
        exportLogs: { entities: exportLogs = {}, loading = true, errors = {} } = {}
    } = config[exportType] || {};

    const fetchLogs = (clientId: number, exportId: number) => dispatch(fetchAction(clientId, exportId));

    const errorsAndRejections: IErrorsAndRejections =
        (latestLog && aggregateErrorsAndRejectionsByVIN(latestLog, "id2Template")) || {};

    const inventoryExport = getEntityByKeyValue<IFacebookAutoExportType | IGoogleAutoExport>(
        autoExports,
        "remarketingInventoryExportId",
        Number(exportId)
    );

    const defaultLogValues = {
        completedAt: null,
        rows: 0,
        rejectedRows: 0,
        validRows: 0,
        invalidRows: 0,
        ...latestLog
    };
    const { completedAt, rows, validRows, rejectedRows, invalidRows } = defaultLogValues;

    useEffect(() => {
        clientId && inventoryExport?.id && fetchLogs(clientId, inventoryExport.id);
    }, [clientId]);

    if (loading) {
        return (
            <Fragment>
                <div className="my-4" data-testid="loadingmessage">
                    <FullPageLoader message={"Loading Remarketing Export Logs..."} />
                </div>
            </Fragment>
        );
    }

    if (!loading && !!Object.keys({ ...errors }).length) {
        return (
            <Fragment>
                <PageHeader />
                <div className="my-4">
                    <Notification
                        color="red"
                        title="Error:"
                        message="There was a problem loading the export logs."
                        children={null}
                    />
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <PageHeader />
            <div className="flex my-4">
                <div className="w-1/4">
                    <div className="m-auto relative bg-white flex flex-col w-full shadow rounded px-4 pb-4 mb-8">
                        <div className="font-bold text-2xl text-center text-gray-800 pt-8 pb-4">Latest Export</div>
                        <div className={`text-gray-800 font-bold pb-6 text-lg`}>Executed At</div>
                        <Detail name="Date" value={completedAt ? moment(completedAt).format("l") : "-"} />
                        <Detail name="Time" value={completedAt ? moment(completedAt).format("LT") : "-"} />
                        <div className={`text-gray-800 font-bold pt-12 pb-6 text-lg`}>Result</div>
                        <Detail name="Updated or Added" value={validRows} />
                        <Detail name="Found" value={rows} />
                        <Detail name="Failed Conditionals" value={rejectedRows} />
                        <Detail name="Failed Validation" value={invalidRows} />
                    </div>

                    <ModalToggle
                        className="max-w-4xl"
                        toggleButton={(open: () => void) => (
                            <Button
                                onClick={open}
                                styleType={SECONDARY_BUTTON}
                                styles={"font-bold shadow"}
                                style={{ color: "#3490dc", border: "none" }}
                            >
                                View All Logs
                            </Button>
                        )}
                        modalContent={(hide: () => void) => (
                            <PreviousLogs logs={exportLogs} exportMapping={inventoryExport} hide={hide} />
                        )}
                    />
                </div>
                <div className="w-3/4">
                    <div className="ml-4">
                        <ExportLogs errorsAndRejections={errorsAndRejections} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ExportLogList;
