import React from "react";
import Button, { NEGATIVE_BUTTON, PRIMARY_BUTTON } from "../Button";
import ModalToggle from "../Dialogs/ModalToggle";
import SettingsIcon from "../Icons/SettingsIcon";
import { IConditionable } from "../../../hooks/useConditionals";
import CopyIcon from "../Icons/CopyIcon";

interface IProps {
    modalTitle: string;
    subTitle?: string;
    cancelText?: string;
    saveText?: string;
    onSave?: (conditions: IConditionable) => void;
    className?: string;
    children: any;
    Icon: any;
}

const TableActionModal = ({
    modalTitle,
    subTitle,
    onSave,
    cancelText = "Cancel",
    saveText = "Save",
    className = "",
    children,
    Icon
}: IProps) => {
    return (
        <ModalToggle
            style={{ width: "80%" }}
            toggleButton={(open: () => void) => (
                <button className={`group rounded hover:bg-gray-200 ${className}`} onClick={open}>
                    <Icon className="w-6 h-6 inline-block" />
                </button>
            )}
            modalContent={(hide: () => void) => {
                const handleSave = (): void => {
                    // onSave();
                    hide();
                };

                return (
                    <div className={"flex flex-col "}>
                        <div className="text-xl text-gray-500 w-full text-left">{modalTitle}</div>
                        <div className="text-2xl w-full">{subTitle}</div>
                        <div className=" w-full">{children}</div>

                        {onSave && (
                            <div className="mt-4 w-full flex gap-4 items-center">
                                <Button onClick={hide} styleType={NEGATIVE_BUTTON}>
                                    {cancelText}
                                </Button>
                                <Button onClick={handleSave} styleType={PRIMARY_BUTTON}>
                                    {saveText}
                                </Button>
                            </div>
                        )}
                    </div>
                );
            }}
        />
    );
};

export default TableActionModal;
