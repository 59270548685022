import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { array, number, object, string } from "yup";
import VariationsInput from "../Fields/VariationsInput";
import ILocations from "../../../../interfaces/DealerSetup/ILocationsFields";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";

interface IProps {
    path: string;
    formValues: IFormValues;

    savePage(path: string, validatePage: () => void): void;
}

const Locations = forwardRef(({ formValues, savePage, path }: IProps, ref: React.Ref<{}>) => {
    const defaults: ILocations = {
        fields: {
            locations: {
                value: {
                    ad_groups: [
                        {
                            value: {
                                city: "",
                                variations: []
                            }
                        }
                    ],
                    negative_keyword_lists: []
                }
            }
        }
    };

    const schema = object({
        locations: object({
            value: object({
                ad_groups: array().of(
                    object({
                        city: string().required("The City field is required.").min(1),
                        variations: array().of(string())
                    })
                ),

                negative_keyword_lists: array().of(number())
            })
        })
    });

    const [values, handleFieldChange, changePage] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleLocationChange = (index: number, value: any) => {
        handleFieldChange(`locations.value.ad_groups[${index}]`, value);
    };

    const handleAddLocation = () => {
        handleFieldChange(
            `locations.value.ad_groups[${values.fields.locations.value.ad_groups.length}]`,
            defaults.fields.locations.value.ad_groups[0],
            true
        );
    };

    const handleDeleteLocation = (index: number) => {
        values.fields.locations.value.ad_groups.splice(index, 1);
        handleFieldChange(`locations`, values.fields.locations, true);
    };

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Locations Campaign Setup</div>

            <div className="text-lg font-bold mt-4 pb-3 border-b">Dealer Locations</div>

            {values.fields.locations.value.ad_groups.map((field: any, index: number) => (
                <VariationsInput
                    index={index}
                    key={index}
                    label={"Location"}
                    name="city"
                    value={field.value.city || defaults.fields.locations.value.ad_groups[0].value.city}
                    variations={field.value.variations || defaults.fields.locations.value.ad_groups[0].value.variations}
                    errors={field.errors}
                    className={index > 0 ? "border-t border-gray-400 w-2/3 mt-4" : ""}
                    deletable={values.fields.locations.length > 1}
                    handleChange={handleLocationChange}
                    handleDelete={handleDeleteLocation}
                />
            ))}
            <p className="text-gray-dark">
                These variations will be in the same AdGroup (Example: If Fort Myers is the City, you would add "Ft.
                Myers" as a keyword variation).
            </p>

            <button className="text-base font-bold text-blue-500 my-4 pb-3" onClick={handleAddLocation}>
                + Add Location
            </button>

            {pager()}
        </div>
    );
});

export default Locations;
