import DynamicCampaignConstants from "../../constants/DynamicCampaignConstants";
import { IDynamicCampaign, IFetchAllResponse } from "../../interfaces/DynamicCampaigns/IDynamicCampaign";
import IDynamicCampaignFormValue from "../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import { FormikActions } from "formik";
import { IInventorySamplePayload } from "../../interfaces/InventoryFields";
import IEntity from "../../interfaces/IEntity";
import { ISpecialOfferTemplateAttachedToDynamicCampaignPayload } from "../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import { NavigateFunction } from "react-router";

const fetchAll = (clientId?: number) => ({
    type: DynamicCampaignConstants.REQUEST_LOAD_DYNAMIC_CAMPAIGNS,
    clientId
});

const loadAll = (response: IFetchAllResponse) => ({
    type: DynamicCampaignConstants.LOAD_DYNAMIC_CAMPAIGNS,
    response
});

const fetchDynamicCampaign = (clientId: number, dynamicCampaignId: number) => ({
    type: DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN,
    payload: { clientId, dynamicCampaignId }
});

interface IFetchDynamicCampaignSuccess {
    entities: {
        dynamicCampaigns: IEntity<IDynamicCampaign>;
    };
    result: number;
}

const fetchDynamicCampaignSuccess = (dynamicCampaign: IFetchDynamicCampaignSuccess) => ({
    type: DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN_SUCCESS,
    payload: dynamicCampaign
});

const fetchDynamicCampaignFailure = (dynamicCampaignId: number) => ({
    type: DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN_FAILURE,
    payload: dynamicCampaignId
});

const requestDeleteItem = (clientId: number, id: number) => ({
    type: DynamicCampaignConstants.REQUEST_DELETE_DYNAMIC_CAMPAIGN,
    clientId,
    id
});

const deleteItem = (id: number) => ({
    type: DynamicCampaignConstants.REQUEST_DELETE_DYNAMIC_CAMPAIGN_SUCCSSS,
    id
});

const requestCreateDynamicCampaign = (
    dynamicCampaign: IDynamicCampaignFormValue,
    clientId: number,
    actions: FormikActions<IDynamicCampaignFormValue>,
    timeToSubmitMetric: number,
    navigate?: NavigateFunction
) => ({
    type: DynamicCampaignConstants.REQUEST_CREATE_DYNAMIC_CAMPAIGN,
    payload: { dynamicCampaign, clientId },
    formAction: actions,
    meta: { timeToSubmitMetric },
    navigate: navigate
});

const createDynamicCampaignSuccess = (dynamicCampaign: IDynamicCampaign) => ({
    type: DynamicCampaignConstants.REQUEST_CREATE_DYNAMIC_CAMPAIGN_SUCCESS,
    payload: { dynamicCampaign }
});

const createDynamicCampaignFail = (error: any) => ({
    type: DynamicCampaignConstants.REQUEST_CREATE_DYNAMIC_CAMPAIGN_FAIL,
    payload: {
        error
    }
});

const requestUpdateDynamicCampaign = (
    dynamicCampaign: IDynamicCampaignFormValue,
    clientId: number,
    actions: FormikActions<IDynamicCampaignFormValue>,
    timeToSubmitMetric: number,
    specialOfferTemplates: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[]
) => ({
    type: DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN,
    payload: { dynamicCampaign, clientId, specialOfferTemplates },
    formActions: actions,
    meta: { timeToSubmitMetric }
});

const updateDynamicCampaignSuccess = (dynamicCampaign: IDynamicCampaign) => ({
    type: DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN_SUCCESS,
    payload: { dynamicCampaign }
});

const updateDynamicCampaignFail = (id: number, error: any) => ({
    type: DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN_FAIL,
    payload: {
        id,
        error
    }
});

const requestDuplicateDynamicCampaign = (
    dynamicCampaignId: number,
    clientId: number,
    name: string,
    adwordsCampaignId: string,
    action: {
        resetModal(): void;
        displayErrors(error: any): void;
    }
) => ({
    type: DynamicCampaignConstants.REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN,
    payload: { dynamicCampaignId, clientId, name, adwordsCampaignId },
    formActions: action
});

const duplicateDynamicCampaignSuccess = () => ({
    type: DynamicCampaignConstants.REQUEST_DUPLICATE_DYNAMIC_CAMPAIGN_SUCCESS
});

const requestInventorySampleData = (clientId: number) => ({
    type: DynamicCampaignConstants.REQUEST_INVENTORY_SAMPLE_DATA,
    payload: { clientId }
});

const requestInventorySampleDataSuccess = (data: IInventorySamplePayload) => ({
    type: DynamicCampaignConstants.REQUEST_INVENTORY_SAMPLE_DATA_SUCCESS,
    payload: data
});

const requestInventorySampleDataFail = (error: any) => ({
    type: DynamicCampaignConstants.REQUEST_INVENTORY_SAMPLE_DATA_FAIL,
    payload: error
});

const pushDynamicCampaign = (
    clientId: number,
    dynamicCampaignId: number,
    formActions: {
        setErrors(e: any): void;
        setSubmitting(isSubmitting: boolean): void;
        setStatus(status?: any): void;
    }
) => ({
    type: DynamicCampaignConstants.PUSH_DYNAMIC_CAMPAIGN,
    payload: { clientId, dynamicCampaignId },
    formActions
});

const pushDynamicCampaignSuccess = (dynamicCampaignId: number) => ({
    type: DynamicCampaignConstants.PUSH_DYNAMIC_CAMPAIGN_SUCCESS,
    payload: { dynamicCampaignId }
});

const pushDynamicCampaignFailure = () => ({
    type: DynamicCampaignConstants.PUSH_DYNAMIC_CAMPAIGN_FAILURE
});

const purgeDynamicCampaign = (
    clientId: number,
    dynamicCampaignId: number,
    formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus">
) => ({
    type: DynamicCampaignConstants.REQUEST_PURGE_DYNAMIC_CAMPAIGN,
    payload: { clientId, dynamicCampaignId },
    formActions
});

const purgeDynamicCampaignSuccess = () => ({
    type: DynamicCampaignConstants.REQUEST_PURGE_DYNAMIC_CAMPAIGN_SUCCESS
});

const purgeDynamicCampaignFailure = () => ({
    type: DynamicCampaignConstants.REQUEST_PURGE_DYNAMIC_CAMPAIGN_FAILURE
});

const unlockDynamicCampaign = (
    clientId: number,
    dynamicCampaignId: number,
    formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus">
) => ({
    type: DynamicCampaignConstants.REQUEST_UNLOCK_DYNAMIC_CAMPAIGN,
    payload: { clientId, dynamicCampaignId },
    formActions
});

const unlockDynamicCampaignSuccess = (dynamicCampaignId: number) => ({
    type: DynamicCampaignConstants.REQUEST_UNLOCK_DYNAMIC_CAMPAIGN_SUCCESS,
    payload: { dynamicCampaignId }
});

const unlockDynamicCampaignFailure = () => ({
    type: DynamicCampaignConstants.REQUEST_UNLOCK_DYNAMIC_CAMPAIGN_FAILURE
});

export {
    fetchAll,
    loadAll,
    fetchDynamicCampaign,
    fetchDynamicCampaignSuccess,
    fetchDynamicCampaignFailure,
    requestDeleteItem,
    deleteItem,
    createDynamicCampaignFail,
    createDynamicCampaignSuccess,
    requestCreateDynamicCampaign,
    updateDynamicCampaignFail,
    updateDynamicCampaignSuccess,
    requestUpdateDynamicCampaign,
    requestDuplicateDynamicCampaign,
    duplicateDynamicCampaignSuccess,
    requestInventorySampleData,
    requestInventorySampleDataSuccess,
    requestInventorySampleDataFail,
    pushDynamicCampaign,
    pushDynamicCampaignSuccess,
    pushDynamicCampaignFailure,
    purgeDynamicCampaign,
    purgeDynamicCampaignSuccess,
    purgeDynamicCampaignFailure,
    unlockDynamicCampaign,
    unlockDynamicCampaignSuccess,
    unlockDynamicCampaignFailure
};
