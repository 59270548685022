import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import schemas from "../middleware/schemas/trackingParameters";
import TrackingParameterConstants from "../constants/TrackingParameterConstants";
import {
    fetchTrackingParameterListFailure,
    fetchTrackingParameterListSuccess,
    createTrackingParameterListSuccess,
    createTrackingParameterFailed,
    linkCampaignToTrackingParameterSuccess,
    linkCampaignToTrackingParameterFailed,
    linkCampaignToTrackingParameter as linkCampaignToTrackingParameterAction,
    unlinkCampaignToTrackingParameter as unlinkCampaignToTrackingParameterAction,
    deleteTrackingParameterListFailure,
    deleteTrackingParameterListSuccess,
    unlinkCampaignToTrackingParameterSuccess,
    unlinkCampaignToTrackingParameterFailed,
    updateCampaignToTrackingParameterFailed,
    updateCampaignToTrackingParameterSuccess
} from "../actions/trackingParameterActions";
import ITrackingParameter, {
    ICreateTrackingParameter,
    ICreateTrackingParameterResponse,
    ILinkCampaignToTrackingParameters,
    ILinkCampaignToTrackingParametersResponse,
    ITrackingParametersForClientPayload,
    IUnlinkCampaignToTrackingParameters,
    IUpdateTrackingParameter
} from "../interfaces/ITrackingParameter";
import { ITrackingParametersForParametersPayload } from "../interfaces/actions/ITrackingParameterActions";
import IAppState from "../interfaces/IAppState";
import { ITrackingParameterCampaignRelation } from "../interfaces/Campaigns";

export function* fetchTrackingParameters({ payload }: ITrackingParametersForClientPayload) {
    const url = `/clients/${payload.clientId}/platform-tracking-parameters?expand[trackedCampaigns]=*`;

    try {
        const response: { entities: ITrackingParameter[] } = yield call(callApi, url, schemas.TRACKING_PARAMETER_LIST);

        const data = {
            trackedCampaigns: {},
            trackingParameters: {},
            ...response.entities
        };

        yield put(fetchTrackingParameterListSuccess(data));
    } catch (e) {
        yield put(fetchTrackingParameterListFailure(e.message));
    }
}

export function* deleteTrackingParameters({
    payload: { trackingParameter }
}: {
    type: string;
    payload: { trackingParameter: ITrackingParameter };
}) {
    const url = `/clients/${trackingParameter.clientId}/platform-tracking-parameters/${trackingParameter.id}`;

    try {
        yield call(callApi, url, schemas.TRACKING_PARAMETER, "DELETE");

        yield put(deleteTrackingParameterListSuccess(trackingParameter.id));
    } catch (e) {
        yield put(deleteTrackingParameterListFailure(e.message));
    }
}

export function* linkCampaignToTrackingParameter({
    payload: { trackingParameter, campaignId }
}: ILinkCampaignToTrackingParameters) {
    if (!campaignId) {
        return;
    }
    const url = `/clients/${trackingParameter.clientId}/platform-tracking-parameters/${trackingParameter.id}/campaigns`;
    try {
        // Create the tracking parameter first.
        const response: ILinkCampaignToTrackingParametersResponse = yield call(
            callApi,
            url,
            schemas.TRACKED_CAMPAIGNS,
            "POST",
            {
                campaign_id: campaignId
            }
        );

        const newCampaignParameterLink: ITrackingParameterCampaignRelation =
            response.entities.trackedCampaigns[response.result];

        yield put(linkCampaignToTrackingParameterSuccess(newCampaignParameterLink, trackingParameter));
    } catch (e) {
        yield put(linkCampaignToTrackingParameterFailed(e.message));
    }
}

export function* unlinkCampaignToTrackingParameter({
    payload: { trackingParameter, relatedId }
}: IUnlinkCampaignToTrackingParameters) {
    const url = `/clients/${trackingParameter.clientId}/platform-tracking-parameters/${trackingParameter.id}/campaigns/${relatedId}`;
    try {
        // Create the tracking parameter first.
        yield call(callApi, url, schemas.TRACKING_PARAMETER, "DELETE");

        yield put(unlinkCampaignToTrackingParameterSuccess(trackingParameter.id, relatedId));
    } catch (e) {
        yield put(unlinkCampaignToTrackingParameterFailed(e.message));
    }
}

export function* createTrackingParameter({
    payload: {
        formData: { name, clientId, parameters, platform, trackedCampaigns },
        callback
    }
}: ICreateTrackingParameter) {
    const url = `/clients/${clientId}/platform-tracking-parameters`;
    try {
        // Create the tracking parameter first.
        const response: ICreateTrackingParameterResponse = yield call(
            callApi,
            url,
            schemas.TRACKING_PARAMETER,
            "POST",
            {
                name,
                parameters,
                platform
            }
        );

        const trackingParameter: ITrackingParameter = response.entities.trackingParameters[response.result];

        yield put(createTrackingParameterListSuccess(trackingParameter, { trackedCampaigns }));

        // Will have to look into how to create trackedCampaigns from the API.
        for (let key in trackedCampaigns) {
            yield put(linkCampaignToTrackingParameterAction(trackedCampaigns[key], trackingParameter));
        }

        yield call(callback, trackingParameter);
    } catch (e) {
        yield put(createTrackingParameterFailed(e.message));
    }
}

export function* findAllLinkedCampaignByCampaignIdInState(
    trackingParameterId: number,
    relatedId: number[]
): Generator<ITrackingParameterCampaignRelation[]> {
    return yield select((state: IAppState): ITrackingParameterCampaignRelation | undefined => {
        return Object.values(state.trackingParameters.trackedCampaigns).filter(
            (campaign: ITrackingParameterCampaignRelation) => {
                return (
                    relatedId.includes(campaign.id || 0) && campaign.platformTrackingParameterId === trackingParameterId
                );
            }
        ) as any;
    }) as any;
}

export function* updateTrackingParameter({
    payload: {
        fields: { trackedCampaigns, ...fields },
        trackingParameter
    }
}: IUpdateTrackingParameter) {
    const url = `/clients/${trackingParameter.clientId}/platform-tracking-parameters/${trackingParameter.id}`;
    try {
        const campaignRelationsBeingTracked: ITrackingParameterCampaignRelation[] =
            yield findAllLinkedCampaignByCampaignIdInState(trackingParameter.id, trackingParameter.trackedCampaigns);
        const campaignIdsBeingTracked = campaignRelationsBeingTracked.map((campaign) => campaign.campaignId);
        // Create the tracking parameter first.

        const response: ICreateTrackingParameterResponse = yield call(
            callApi,
            url,
            schemas.TRACKING_PARAMETER,
            "PUT",
            fields
        );

        const createdParameter: ITrackingParameter = response.entities.trackingParameters[response.result];

        yield put(updateCampaignToTrackingParameterSuccess(createdParameter));

        for (let key in trackedCampaigns) {
            if (campaignIdsBeingTracked.includes(trackedCampaigns[key])) {
                // If we already have a relation... Don't duplicate it.
                continue;
            }
            yield put(linkCampaignToTrackingParameterAction(trackedCampaigns[key], createdParameter));
        }

        for (let key in campaignIdsBeingTracked) {
            // To remove a campaign here, we want it to not exist in our campaignRelationsBeingTracked array.
            // We also don't want it in our `trackedCampaigns` array.
            const campaignId = campaignIdsBeingTracked[key];
            if (trackedCampaigns.includes(campaignId)) {
                continue;
            }

            const trackedCampaign: ITrackingParameterCampaignRelation = campaignRelationsBeingTracked.filter(
                (campaign) => campaignId === campaign.campaignId
            )[0];

            if (!trackedCampaign || !trackedCampaign.id) {
                continue;
            }

            // Technically the tracked campaigns have a different ID than the campaign ID that's used to do other things. So
            // this allows us to get that campaign's ID and dispose of them.
            yield put(unlinkCampaignToTrackingParameterAction(trackedCampaign.id, trackingParameter));
        }
    } catch (e) {
        yield put(updateCampaignToTrackingParameterFailed(e.message));
    }
}

function* trackingParametersSaga() {
    yield takeEvery(TrackingParameterConstants.FETCH_TRACKING_PARAMETERS, fetchTrackingParameters);
    yield takeEvery(TrackingParameterConstants.DELETE_TRACKING_PARAMETER, deleteTrackingParameters);
    yield takeEvery(TrackingParameterConstants.CREATE_TRACKING_PARAMETER, createTrackingParameter);
    yield takeEvery(TrackingParameterConstants.LINK_TRACKING_PARAMETER_TO_CAMPAIGN, linkCampaignToTrackingParameter);
    yield takeEvery(
        TrackingParameterConstants.UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN,
        unlinkCampaignToTrackingParameter
    );
    yield takeEvery(TrackingParameterConstants.UPDATE_TRACKING_PARAMETER, updateTrackingParameter);
}

export default trackingParametersSaga;
