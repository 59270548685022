import { call, put, takeEvery } from "redux-saga/effects";
import { callApi, getPagedResults } from "../../middleware/api";
import schemas from "../../middleware/schemas/dcStatuses";
import * as Actions from "../../actions/dynamicCampaignStatusesActions";
import Constants from "../../constants/DynamicCampaignStatusConstants";
import IPagedResponse from "../../interfaces/IPagedResponse";

export function* fetchDynamicCampaignStatus() {
    const url = `/dynamic-campaign-statuses`;
    try {
        const response: IPagedResponse = yield call(getPagedResults, url, schemas.DC_STATUSES);
        yield put(Actions.fetchDynamicCampaignStatusesSuccess(response.result));
    } catch (error) {
        const errorMessage = error.errors ? error.errors.text["0"] : error.exceptionMessage;
        yield put(Actions.fetchDynamicCampaignStatusesFailure(errorMessage));
    }
}

function* DynamicCampaignStatusSagas() {
    yield takeEvery(Constants.FETCH_DC_STATUSES, fetchDynamicCampaignStatus);
}

export default DynamicCampaignStatusSagas;
