import React, { useRef, useState, useEffect } from "react";
import ReactTable from "react-table";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import ITableState from "../../../interfaces/ITableState";
import { debounce } from "lodash";
import TableLoader from "../../Shared/Table/TableLoader";
import { Insight } from "./Table/ClientInsights";
import { Link } from "react-router-dom";
import CheckIcon from "../../Shared/Icons/CheckIcon";
import CloseIcon from "../../Shared/Icons/CloseIcon";
import FuelInsightsIcon from "../../Shared/Icons/FuelInsightsIcon";
import SearchIcon from "../../Shared/Icons/SearchIcon";
import AddIcon from "../../Shared/Icons/AddIcon";
import ClientCsvDownload from "./ClientCsvDownload";
import { connect } from "react-redux";
import IClient from "../../../interfaces/IClient";
import { downloadCsvClicked } from "../../../actions/clientActions";

interface IInsight {
    title: string;
    columns: any[];
    help?: string;
}

interface IProps {
    pageTitle: string;
    dataSource: any[];
    loading: boolean;
    deleting: boolean;
    restoring: boolean;
    pagination: {
        currentPage: number;
        perPage: number;
        lastPage?: number;
    };
    insightList: { [key: string]: IInsight };
    activeInsight: Insight;
    onFetchData(tableState: ITableState, insight: Insight, search: string): void;
    onSetActiveInsight(insight: Insight): void;
    trackDownloadCsv: (clientId: number, client: IClient) => void;
    clientId: number;
    client: IClient;
}

const InsightsTable: React.FunctionComponent<IProps> = ({
    pageTitle,
    loading,
    deleting,
    restoring,
    dataSource,
    insightList,
    activeInsight,
    pagination,
    onFetchData,
    onSetActiveInsight,
    trackDownloadCsv,
    clientId,
    client
}) => {
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const sideBarRef = useRef(null);
    const tableRef: any = useRef(null);

    useEffect(() => {
        onFetchData({ pageSize: tableRef.current.state.pageSize, page: 1 }, activeInsight, search);
        tableRef.current.state.page = 0;
    }, [activeInsight, search]);

    const handleFetchData = (state: ITableState) => {
        onFetchData({ ...state, page: state.page + 1 }, activeInsight, search);
    };

    const handleSearch = debounce((event) => {
        setSearch(event.target.value);
        onFetchData({ page: 1, pageSize: tableRef.current.state.pageSize }, activeInsight, event.target.value);
        tableRef.current.state.page = 0;
    }, 400);

    const renderTableLoader = () => {
        if (loading) {
            return <TableLoader message={"Loading..."} />;
        }
        if (deleting) {
            return <TableLoader message={"Deleting..."} />;
        }
        if (restoring) {
            return <TableLoader message={"Restoring..."} />;
        }
        return null;
    };

    return (
        <div className="flex flex-row mx-4 mb-4 h-full">
            <div className={`flex-grow h-full ${isOpen ? "pr-6" : ""}`}>
                <div className="flex w-full">
                    <PageTitle title={pageTitle}>
                        <div className="flex flex-wrap items-center">
                            <ClientCsvDownload
                                activeInsight={activeInsight}
                                onClick={() => trackDownloadCsv(clientId, client)}
                                search={search}
                            />
                            <Link
                                to={`new`}
                                className="flex items-center justify-around py-2 px-4 bg-blue-500 rounded text-white ml-4"
                            >
                                <AddIcon className="w-6 h-6" />
                                <span className="ml-2">New Client</span>
                            </Link>
                        </div>
                    </PageTitle>
                </div>

                <div className="flex flex-col bg-white shadow rounded p-4">
                    <div className="flex justify-between">
                        <div className="relative flex-grow mr-2">
                            <div className="absolute left-0 my-2 mx-3">
                                <SearchIcon className="text-blue-500 w-6 h-6" />
                            </div>

                            <input
                                className="bg-white w-full text-base px-12 py-2 border border-gray-500 rounded ml-1"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    e.persist();
                                    handleSearch(e);
                                }}
                            />
                        </div>

                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className={`px-4 ml-3 focus:outline-none flex items-center ${
                                isOpen ? "border border-blue-300 rounded bg-blue-100" : ""
                            }`}
                        >
                            <FuelInsightsIcon className="w-6 h-6 text-blue-500" name="INSIGHT" viewBox="0 0 24 30" />
                            <span className="ml-2 font-bold text-base align-top text-blue-900">Fuel Insights</span>
                        </button>
                    </div>
                </div>
                <div className="bg-white shadow mt-4 rounded">
                    {insightList[activeInsight].help}
                    <ReactTable
                        ref={tableRef}
                        className="v1 -striped rounded overflow-hidden"
                        data={dataSource}
                        columns={insightList[activeInsight].columns}
                        loading={loading}
                        manual={true}
                        resizable={false}
                        sortable={false}
                        noDataText={!loading && "No Clients Found"}
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                        defaultPageSize={15}
                        pages={pagination.lastPage}
                        onFetchData={(state: any) => handleFetchData({ ...state, search })}
                        LoadingComponent={renderTableLoader}
                    />
                </div>
            </div>

            {isOpen && (
                <div ref={sideBarRef} className="w-1/6 shadow-lg -mr-6 overflow-hidden bg-white">
                    <div className="bg-blue-200 border-b border-blue-300 flex items-center">
                        <button
                            onClick={() => setIsOpen(false)}
                            className={`w-full text-base rounded text-gray-800 flex items-center pl-4 py-4 outline-none`}
                        >
                            <CloseIcon className="w-6 h-6 align-middle" />
                            <span className="ml-4 font-semibold">Fuel Insights</span>
                        </button>
                    </div>
                    <div className="w-full">
                        {Object.keys(insightList).map((key) => {
                            const { title } = insightList[key];
                            return (
                                <button
                                    key={title}
                                    onClick={() => onSetActiveInsight(key as Insight)}
                                    className={`w-full text-base p-4 flex items-center border-t border-gray-200 text-left`}
                                >
                                    <span
                                        className={`border-2 m-1 rounded-full ${
                                            key === activeInsight
                                                ? "text-green-500 border-green-500"
                                                : "text-gray-500 border-gray-500"
                                        }`}
                                    >
                                        <CheckIcon className="w-4 h-4" />
                                    </span>
                                    <span className="ml-2">{title}</span>
                                </button>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any, props: any) => {
    const clientId = state.clients.currentClient;
    const client = state.clients.entities[clientId];

    return {
        client,
        clientId
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        trackDownloadCsv: (clientId: number, client: IClient) => {
            dispatch(downloadCsvClicked(clientId, client));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsTable);
