import React from "react";
import Select from "react-select";
import { find } from "lodash";

interface IOptions {
    label: string;
    value: string;
}

interface IProps {
    name?: string;
    ariaLabel?: string;
    options: IOptions[];
    value: string | undefined;
    onChange?: any;
    onBlur?: any;
    placeholder?: string;
    className?: string;
    readOnly?: boolean;
    isValid?: boolean;
    autoFocus?: boolean;
}

const defaultClasses = `w-full text-base`;
const errorClasses = `border-red`;
const readOnlyClasses = `text-gray-800 bg-gray-400`;

const SelectInput: React.FunctionComponent<IProps> = ({
    name,
    ariaLabel,
    options,
    value,
    onChange,
    onBlur,
    placeholder,
    className,
    readOnly = false,
    isValid = true,
    autoFocus = false
}) => {
    const defaultClassName = `${defaultClasses} ${readOnly && readOnlyClasses} ${!isValid && errorClasses}`;

    const customStyles = {
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: "39px",
            padding: 0
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            padding: "0 .75rem",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        }),
        input: (provided: any, state: any) => ({
            ...provided
        })
    };

    const selectValue: IOptions | null = find(options, ["value", value]) || null;

    return (
        <Select
            autoFocus={autoFocus}
            openMenuOnFocus={autoFocus}
            name={name}
            aria-label={ariaLabel}
            classNamePrefix={`${name}`}
            options={Object.values(options)}
            getOptionValue={(option: IOptions) => option.value}
            getOptionLabel={(option: IOptions) => option.label}
            value={selectValue}
            onChange={onChange}
            onBlur={onBlur}
            className={`${defaultClassName} ${className}`}
            placeholder={placeholder}
            styles={customStyles}
            isDisabled={readOnly}
        />
    );
};

export default SelectInput;
