import React, { useState, useRef, FunctionComponent, useEffect } from "react";
import { ACTIVE } from "../../constants/StatusIndicatorConstants";
import IIndicatorStatus from "../../interfaces/IIndicatorStatus";
import mapEventActionToStatus from "../../utils/mapEventActionToStatus";
import StatusStep from "./StatusStep";
import TimerQueue from "../../utils/TimerQueue";
import useStickyScroll from "../../hooks/useStickyScroll";
import useEchoListener from "../../hooks/useEchoListener";
import { Link } from "react-router-dom";

interface IProps {
    id: number | undefined;
    statuses: IIndicatorStatus[];
    initialAction: string | null;
    finishedComponent: any;
    className?: string;
    addListener(arg: any, cb: (event: any) => void): void;
    removeListener(arg: any): void;
    unlockCampaignOnRunFailure?: (dynamicCampaignId: number) => void;
}

const renderSteps = (indicatorSteps: IIndicatorStatus[]) =>
    indicatorSteps.map(({ initialLabel, completedLabel, state }) => (
        <StatusStep key={initialLabel} initialLabel={initialLabel} completedLabel={completedLabel} status={state} />
    ));

const renderStats = (Component: any, id: number | undefined) => <Component id={id} />;

const StatusIndicator: FunctionComponent<IProps> = ({
    id,
    statuses,
    initialAction,
    finishedComponent: Component,
    className = "",
    addListener,
    removeListener,
    unlockCampaignOnRunFailure
}) => {
    const Queue = new TimerQueue(1000);
    const ref = useRef<HTMLDivElement>(null);
    const isSticky = useStickyScroll(ref);
    const [currentStatus, setCurrentStatus] = useState<string>(initialAction || "");
    const [hasFailed, setHasFailed] = useState<boolean>((initialAction || "").includes("FAILED"));

    useEffect(() => {
        setHasFailed(currentStatus.includes("FAILED"));
    }, [currentStatus]);

    useEchoListener(addListener, removeListener, handleStatusEvent, id);

    // we have to use state here because sometime these values are updated from events rather than props
    const [indicatorSteps, setIndicatorSteps] = useState<IIndicatorStatus[]>(
        mapEventActionToStatus(statuses, initialAction)
    );

    function handleStatusEvent(event: { data: { [key: string]: string } }) {
        Queue.add(() => {
            setIndicatorSteps(mapEventActionToStatus(statuses, event.data.status));
        });

        setCurrentStatus(event.data.status);

        if (event.data.status.includes("FAILED") && unlockCampaignOnRunFailure && id) {
            // Update the dynamic campaign
            unlockCampaignOnRunFailure(id);
        }
    }

    // if the last one is active it means all events have been received.
    const isFinished = indicatorSteps.length && indicatorSteps[indicatorSteps.length - 1].state === ACTIVE;
    return (
        <div className="flex flex-col w-full">
            <div
                data-testid="statusIndicator"
                ref={ref}
                className={`shadow-md flex -mx-4 h-32 bg-gray-100 items-center justify-center ${className} ${
                    isSticky ? "sticky top-0 z-50 shadow" : ""
                }`}
            >
                {isFinished ? renderStats(Component, id) : renderSteps(indicatorSteps)}
            </div>

            {hasFailed && (
                <div className={"-mx-4"}>
                    <div className="border border-red-500 bg-red-100 text-red-900 py-2 px-4 text-lg text-center">
                        Your campaign failed to push, please see your{" "}
                        <Link to="log" className="font-bold underline">
                            campaign logs
                        </Link>{" "}
                        for additional information
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatusIndicator;
