import ExclusionsConstants from "../constants/ExclusionsConstants";
import { IExclusionsState, ExclusionActionTypes } from "../interfaces/Exclusions";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

const initialState: IExclusionsState = {
    entities: {},
    loadedAt: null,
    error: null
};

function exclusionCollCampaignReducer(state = initialState, action: ExclusionActionTypes): IExclusionsState {
    switch (action.type) {
        case ExclusionsConstants.REQUEST_EXCLUSIONS_COLL_CAMPAIGNS:
            return { ...state, loadedAt: null, entities: {}, error: null };
        case ExclusionsConstants.REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_FAIL:
            return { ...state, loadedAt: null, error: action.payload };
        case ExclusionsConstants.REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS:
            return { ...state, loadedAt: new Date(), entities: action.payload, error: null };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
}

export default exclusionCollCampaignReducer;
