export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SAVE_FAILED = "SAVE_FAILED";

export const clear = () => ({
    type: CLEAR_ERRORS
});

export const issueFailedSave = (resp: any) => ({
    type: SAVE_FAILED,
    ...resp
});
