import ISalesMapStatistic from "../../interfaces/ISalesMapStatistic";
import IAdjustment from "../../interfaces/IAdjustment";

const DI_SALES_MARKER = "https://maps.google.com/mapfiles/ms/icons/red-dot.png";

export const renderDescription = (name: string, locationId: number, stats: ISalesMapStatistic) => {
    let statsDisplay = "";
    if (stats) {
        statsDisplay = `<div>
                <span>
                    <strong>Conv:</strong> ${stats.conversions} 
                </span>
                <span>
                    <strong>Cost:</strong>  $${stats.cost}
                </span>
                <span>
                    <strong>CTR:</strong> ${stats.ctr} 
                </span>
                <span>
                    <strong>AvgPos:</strong> ${stats.avgPosition} 
                </span>
                </div>
                `;
    }
    return `
            <div className="location-stats-window">
                <h2 className="text-2xl font-semibold py-8">${name}</h2>
               ${statsDisplay} 
            </div>`;
};

export const addMarker = (data: any, image: any) => {
    let latLong = new window.google.maps.LatLng(data.lat, data.lng);

    return new window.google.maps.Marker({
        //map: this.map,
        position: latLong,
        icon: image
    });
};

export const renderData = (data: any, container: any[]) => {
    for (let i = 0; i < data.sales; i++) {
        container.push(addMarker(data, DI_SALES_MARKER));
    }
};
export const mapRegionStyle = {
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    fillColor: "blue"
};

interface IGeoJson {
    properties: {
        name: string;
        color?: string;
    };
    geometry: {
        coordinates: any[];
        radius: number;
    };
}

export const createRadius = (geoJson: IGeoJson, clickCallback: any) => {
    const cir = new window.google.maps.Circle({
        ...mapRegionStyle,
        fillColor: geoJson.properties.color,
        center: {
            lat: geoJson.geometry.coordinates[0],
            lng: geoJson.geometry.coordinates[1]
        },
        radius: geoJson.geometry.radius * 1000,
        clickable: true
    });

    cir.addListener("click", clickCallback);

    return cir;
};

export const renderRadiusAdjustments = (
    circles: any,
    locationStats: (id: number) => ISalesMapStatistic,
    map: any,
    infoWindow: any
) => {
    let radius: { [key: string]: any } = {};
    circles.reverse().forEach((adjustment: IAdjustment) => {
        const geoJson = adjustment.location.geoJson;

        const cir = createRadius(geoJson, (e: any) => {
            const stats = locationStats(adjustment.id);
            infoWindow.setContent(renderDescription(geoJson.properties.name, adjustment.id, stats));
            infoWindow.setPosition(e.latLng);
            infoWindow.open(map);
        });
        cir.setMap(map);

        radius[adjustment.id] = cir;
    });

    return radius;
};

export const renderGeoJsonAdjustments = (
    adjustments: IAdjustment[],
    locationStats: (id: number) => ISalesMapStatistic,
    map: any,
    infoWindow: any
) => {
    let markers: { [key: string]: any } = {};
    adjustments.forEach((adjustment) => {
        const geoJson = adjustment.location.geoJson;
        const fillColor = geoJson.properties.color;
        const marker = map.data.addGeoJson(geoJson, { fillColor });
        map.data.addListener("click", (e: any) => {
            const stats = locationStats(adjustment.id);
            infoWindow.setPosition(e.latLng);
            infoWindow.open(map);
            infoWindow.setContent(renderDescription(e.feature.getProperty("name"), adjustment.id, stats));
        });

        markers[adjustment.id] = marker;
    });

    return markers;
};
