import React from "react";
import { Table } from "../../Shared/Table";
import { CellInfo } from "react-table";
import Button, { primaryClasses, baseStyles } from "../../Shared/Button";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import useInventorySearchAds from "../../../hooks/inventorySearchAds/useInventorySearchAds";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";

interface IProps {}

const InventorySearchAdsList: React.FC<IProps> = () => {
    const {
        campaigns,
        createCampaign,
        creatingCampaign,
        fetchingCampaigns,
        fetchingCampaignsError,
        creatingCampaignError
    } = useInventorySearchAds();

    const createError = Object.keys(creatingCampaignError).length > 0;

    const columns = [
        {
            id: "campaign",
            Header: "Campaign",
            Cell({ original: { campaignName } }: CellInfo) {
                return campaignName;
            },
            width: 135,
            className: "my-1"
        },
        {
            id: "campaignId",
            Cell({ original: { campaignId } }: CellInfo) {
                return campaignId;
            },
            accessor: "name",
            Header: "Campaign ID",
            className: "my-1"
        }
    ];

    return (
        <div className="mx-4 mb-5">
            <PageTitle title="Dealership Name"></PageTitle>

            {fetchingCampaignsError?.error && (
                <Button
                    styles={`${primaryClasses} ${baseStyles} flex items-center mb-5`}
                    onClick={() => createCampaign()}
                    disabled={fetchingCampaigns || creatingCampaign}
                >
                    + Create campaign
                    {creatingCampaign && <RefreshIcon className={`ml-2 w-6 h-6 inline-block rotate-fast`} />}
                </Button>
            )}
            {!!createError && !creatingCampaign && (
                <span className={`w-full block text-red-500 text-base italic`}>
                    There was an error creating a campaign.
                </span>
            )}
            <div className="shadow bg-white rounded overflow-hidden">
                <Table
                    data={campaigns}
                    columns={columns}
                    loadingTable={false}
                    loadingMessage={"Loading Campaigns"}
                    noDataText={"There are no campaigns for this client."}
                    className="text-gray-700"
                />
            </div>
        </div>
    );
};

export default InventorySearchAdsList;
