import { put, call, all } from "redux-saga/effects";
import { callApi } from "../../middleware/api";
import {
    IExpandedTextAdTemplate,
    ITemplatePart,
    IConditional
} from "../../interfaces/DynamicCampaigns/IDynamicCampaign";
import {
    wrapper,
    mapInputFieldsToApiRequest,
    mapApiResponses,
    mapCrudOperations,
    parseInventorySampleData
} from "../../utils/DynamicCampaignUtils";
import {
    requestInventorySampleDataSuccess,
    requestInventorySampleDataFail
} from "../../actions/dynamicCampaigns/dynamicCampaignActions";
import {
    IInventorySampleDataResponse,
    IInventoryFields,
    IInventoryFieldValues
} from "../../interfaces/InventoryFields";
import { camelizeKeys } from "humps";

interface IUpdateProp {
    [key: string]: number;
}

interface ICondtionsPayload {
    conditionals: IConditional[];
    updateProp: IUpdateProp;
    url: string;
}

interface ITemplatesPayload {
    expandedTextAdTemplates: IExpandedTextAdTemplate[];
    clientId: number;
    dynamicCampaignId: number;
}

interface IAction<T> {
    type: string;
    payload: T;
}

interface IPartsPayload {
    parts: ITemplatePart[];
    clientId?: number;
    dynamicCampaignExpandedTextAdTemplateId: number;
}
interface IInventorySampleDataProps {
    type: string;
    payload: { clientId: number };
}

// Main eta saga
function* saveExpandedTextAdTemplates({ payload }: IAction<ITemplatesPayload>): any {
    const { clientId, dynamicCampaignId, expandedTextAdTemplates } = payload;
    const baseUrl = `/clients/${clientId}/dc-eta-templates`;
    const templateConditionalsUrl = `/clients/${clientId}/dc-eta-template-conditionals`;
    const templateResponses: IExpandedTextAdTemplate[] = []; // this holds all our template responses
    const errors: { [key: string]: string } = {};
    for (const [index, item] of expandedTextAdTemplates.entries()) {
        const { parts, conditionals, ...template } = item;
        template.dynamicCampaignId = dynamicCampaignId;
        if (!item.dirty) {
            templateResponses.push(item);
        } else {
            switch (true) {
                case template.deleted && template.new: {
                    break;
                }
                case template.deleted: {
                    const [templateError, templateResponse] = yield wrapper(
                        call(callApi, `${baseUrl}/${template.id}`, {}, "DELETE")
                    );
                    if (templateError) {
                        errors[`expandedTextAdTemplates[${index}]`] = templateError;
                        Object.keys(templateError).forEach((key) => {
                            errors[`expandedTextAdTemplates[${index}][${key}]`] = templateError[key];
                        });
                    }
                    break;
                }
                case template.new: {
                    const [templateError, templateResponse] = yield wrapper(
                        call(callApi, baseUrl, {}, "POST", mapInputFieldsToApiRequest(template))
                    );

                    if (templateError) {
                        Object.keys(templateError).forEach((key) => {
                            errors[`expandedTextAdTemplates[${index}][${key}]`] = templateError[key];
                        });
                        templateResponses.push(item);
                    } else {
                        const [partErrors, savedParts] = yield call(saveParts, {
                            type: "",
                            payload: { clientId, parts, dynamicCampaignExpandedTextAdTemplateId: templateResponse.id }
                        });

                        if (partErrors) {
                            Object.keys(partErrors).forEach((key) => {
                                // the field name of the part in formik
                                errors[`expandedTextAdTemplates[${index}][parts][${key}]`] = partErrors[key];
                            });
                            templateResponses.push({ ...item, id: templateResponse.id, new: false });
                        } else {
                            templateResponse.parts = savedParts;

                            const templateWithConditions = yield handleConditions(
                                templateResponse,
                                conditionals,
                                templateConditionalsUrl,
                                { dynamicCampaignExpandedTextAdTemplateId: templateResponse.id }
                            );
                            templateResponses.push(templateWithConditions);
                        }
                    }
                    break;
                }
                default: {
                    const [templateError, templateResponse] = yield wrapper(
                        call(callApi, `${baseUrl}/${template.id}`, {}, "PATCH", mapInputFieldsToApiRequest(template))
                    );

                    if (templateError) {
                        Object.keys(templateError).forEach((key) => {
                            errors[`expandedTextAdTemplates[${index}][${key}]`] = templateError[key];
                        });
                        // send the item that failed back
                        templateResponses.push(item);
                    } else {
                        const [partErrors, savedParts] = yield call(saveParts, {
                            type: "",
                            payload: { clientId, parts, dynamicCampaignExpandedTextAdTemplateId: templateResponse.id }
                        });

                        if (partErrors) {
                            Object.keys(partErrors).forEach((key) => {
                                // the field name of the part in formik
                                errors[`expandedTextAdTemplates[${index}].parts[${key}].value`] = partErrors[key];
                            });
                            templateResponses.push({ ...item, id: templateResponse.id, new: false });
                        } else {
                            // set the parts for the successfully saved template
                            templateResponse.parts = savedParts;

                            const templateWithConditions = yield handleConditions(
                                templateResponse,
                                conditionals,
                                templateConditionalsUrl,
                                { dynamicCampaignExpandedTextAdTemplateId: templateResponse.id }
                            );

                            templateResponses.push(templateWithConditions);
                        }
                    }
                }
            }
        }
    }
    return [Object.entries(errors).length > 0 ? errors : false, templateResponses];
}

export function* saveParts({ payload }: IAction<IPartsPayload>): any {
    const { clientId, dynamicCampaignExpandedTextAdTemplateId, parts } = payload;
    const baseUrl = `/clients/${clientId}/dc-eta-template-parts`;
    const partConditionalsUrl = `/clients/${clientId}/dc-eta-template-part-conditionals`;
    const partResponses: ITemplatePart[] = [];
    const errors: { [key: number]: string | string[] } = {};
    for (const [index, item] of parts.entries()) {
        const { conditionals, ...part } = item;
        part.dynamicCampaignExpandedTextAdTemplateId = dynamicCampaignExpandedTextAdTemplateId;
        if (!item.dirty) {
            partResponses.push(item);
        } else {
            switch (true) {
                case part.deleted && part.new: {
                    break;
                }
                case part.deleted: {
                    const [error, response] = yield wrapper(call(callApi, `${baseUrl}/${part.id}`, {}, "DELETE"));
                    break;
                }
                // if the part is new
                case part.new: {
                    const [partError, partResponse] = yield wrapper(
                        call(callApi, baseUrl, {}, "POST", mapInputFieldsToApiRequest(part))
                    );

                    if (!partError) {
                        const partWithConditionals = yield handleConditions(
                            partResponse,
                            conditionals,
                            partConditionalsUrl,
                            { dynamicCampaignExpandedTextAdTemplatePartId: partResponse.id }
                        );
                        partResponses.push(partWithConditionals);
                    } else {
                        partResponses.push(item);
                        errors[index] = Object.values(partError);
                    }
                    break;
                }
                default: {
                    const [partError, partResponse] = yield wrapper(
                        call(callApi, `${baseUrl}/${part.id}`, {}, "PATCH", mapInputFieldsToApiRequest(part))
                    );
                    if (!partError) {
                        const partWithConditionals = yield handleConditions(
                            partResponse,
                            conditionals,
                            partConditionalsUrl,
                            { dynamicCampaignExpandedTextAdTemplatePartId: partResponse.id }
                        );
                        partResponses.push(partWithConditionals);
                    } else {
                        partResponses.push(item);
                        errors[index] = Object.values(partError);
                    }
                }
            }
        }
    }
    return [Object.entries(errors).length > 0 ? errors : false, partResponses];
}

export function* saveConditionals({ payload }: IAction<ICondtionsPayload>): Generator<any, any, any> {
    const { conditionals, url, updateProp } = payload;
    const saves = mapCrudOperations(conditionals, url, updateProp);
    const results = yield all(saves);
    return mapApiResponses(results, "expandedTextAdTemplates");
}

function* handleConditions(
    parentItem: { conditionals: IConditional[] },
    conditionals: IConditional[],
    url: string,
    updateProp: IUpdateProp
): any {
    const [conditionalErrors, savedConditionals] = yield call(saveConditionals, {
        type: "",
        payload: { conditionals, url, updateProp }
    });

    parentItem.conditionals = !conditionalErrors ? savedConditionals : conditionals;
    return parentItem;
}

function* fetchInventorySampleData({ payload }: IInventorySampleDataProps) {
    const { clientId } = payload;
    const url = `/clients/${clientId}/inventory-sample-data`;
    try {
        const response: IInventorySampleDataResponse = yield call(callApi, url, {});
        const { inventorySample, inventoryFieldValues } = camelizeKeys(response.result) as {
            inventorySample: IInventoryFields[];
            inventoryFieldValues: IInventoryFieldValues;
        };
        const sampleData = parseInventorySampleData(inventoryFieldValues);
        yield put(requestInventorySampleDataSuccess({ sampleData, inventorySample, inventoryFieldValues }));
    } catch (error) {
        yield put(requestInventorySampleDataFail(error));
    }
}

export { saveExpandedTextAdTemplates, fetchInventorySampleData };
