import UsersConstants from "../constants/UsersConstants";
import IUser from "../interfaces/IUser";
import IEntity from "../interfaces/IEntity";
import IUserRole from "../interfaces/IUserRole";
import IUserApi from "../interfaces/IUserApi";
import IUserSettings from "../interfaces/IUserSettings";
import { NavigateFunction } from "react-router";

const fetchUsers = (searchValue?: string) => ({
    type: UsersConstants.FETCH_USERS,
    payload: {
        searchValue
    }
});

const fetchUsersSuccess = (users: IEntity<IUser>) => ({
    type: UsersConstants.FETCH_USERS_SUCCESS,
    payload: {
        users
    }
});

const fetchUsersFail = () => ({
    type: UsersConstants.FETCH_USERS_FAIL
});

const requestDeleteUser = (user: IUser) => ({
    type: UsersConstants.REQUEST_DELETE_USER,
    payload: {
        user
    }
});

const requestDeleteUserSuccess = (userId: number) => ({
    type: UsersConstants.REQUEST_DELETE_USER_SUCCESS,
    payload: {
        userId
    }
});

const requestDeleteUserFail = (error: any) => ({
    type: UsersConstants.REQUEST_DELETE_USER_FAIL,
    payload: {
        error
    }
});

const requestCreateUser = (user: IUserApi, sendActivationEmail: boolean) => ({
    type: UsersConstants.REQUEST_CREATE_USER,
    payload: { user, sendActivationEmail }
});

const createUserSuccess = () => ({
    type: UsersConstants.REQUEST_CREATE_USER_SUCCESS
});

const createUserFail = (error: any) => ({
    type: UsersConstants.REQUEST_CREATE_USER_FAIL,
    payload: {
        error
    }
});

const requestUserRoles = () => ({
    type: UsersConstants.REQUEST_USER_ROLES
});

const requestUserRolesSuccess = (roles: IEntity<IUserRole>) => ({
    type: UsersConstants.REQUEST_USER_ROLES_SUCCESS,
    payload: roles
});

const requestUserRolesFail = (error: any) => ({
    type: UsersConstants.REQUEST_USER_ROLES_FAIL,
    payload: {
        error
    }
});

const requestUser = (userId: string | number) => ({
    type: UsersConstants.REQUEST_USER,
    payload: userId
});

const requestUserSuccess = (user: IUser) => ({
    type: UsersConstants.REQUEST_USER_SUCCESS,
    payload: user
});

const requestUserFail = (error: any) => ({
    type: UsersConstants.REQUEST_USER_FAIL,
    payload: {
        error
    }
});

const requestUpdateUser = (user: IUserApi, navigate?: NavigateFunction) => ({
    type: UsersConstants.REQUEST_UPDATE_USER,
    payload: {
        user
    },
    navigate
});

const updateUserSuccess = () => ({
    type: UsersConstants.REQUEST_UPDATE_USER_SUCCESS
});

const updateUserFail = (error: any) => ({
    type: UsersConstants.REQUEST_UPDATE_USER_FAIL,
    payload: {
        error
    }
});

const requestActivateUser = (password: string, token: string) => ({
    type: UsersConstants.REQUEST_ACTIVATE_USER,
    payload: { password, token }
});

const requestActivateUserSuccess = () => ({
    type: UsersConstants.REQUEST_ACTIVATE_USER_SUCCESS
});

const requestActivateUserFail = (error: any) => ({
    type: UsersConstants.REQUEST_ACTIVATE_USER_FAIL,
    payload: { error }
});

const requestSaveSettings = (formValues: IUserSettings) => ({
    type: UsersConstants.REQUEST_SAVE_SETTINGS,
    payload: formValues
});

const requestSaveSettingsSuccess = () => ({
    type: UsersConstants.REQUEST_SAVE_SETTINGS_SUCCESS
});

const requestSaveSettingsFail = (error: any) => ({
    type: UsersConstants.REQUEST_SAVE_SETTINGS_FAIL,
    payload: { error }
});

const requestPasswordReset = (password: string, token: string) => ({
    type: UsersConstants.REQUEST_RESET_PASSWORD,
    payload: { password, token }
});

const requestPasswordResetSuccess = () => ({
    type: UsersConstants.REQUEST_RESET_PASSWORD_SUCCESS
});

const requestPasswordResetFail = (error: any) => ({
    type: UsersConstants.REQUEST_RESET_PASSWORD_FAIL,
    payload: { error }
});

const requestPasswordResetEmail = (email: string) => ({
    type: UsersConstants.REQUEST_RESET_PASSWORD_EMAIL,
    payload: { email }
});

const requestPasswordResetEmailSuccess = () => ({
    type: UsersConstants.REQUEST_RESET_PASSWORD_EMAIL_SUCCESS
});

const requestPasswordResetEmailFail = (error: any) => ({
    type: UsersConstants.REQUEST_RESET_PASSWORD_EMAIL_FAIL,
    payload: { error }
});

export default {
    fetchUsers,
    fetchUsersSuccess,
    fetchUsersFail,

    requestDeleteUser,
    requestDeleteUserSuccess,
    requestDeleteUserFail,

    requestCreateUser,
    createUserSuccess,
    createUserFail,

    requestUserRoles,
    requestUserRolesSuccess,
    requestUserRolesFail,

    requestUser,
    requestUserSuccess,
    requestUserFail,

    requestUpdateUser,
    updateUserSuccess,
    updateUserFail,

    requestActivateUser,
    requestActivateUserSuccess,
    requestActivateUserFail,

    requestSaveSettings,
    requestSaveSettingsSuccess,
    requestSaveSettingsFail,

    requestPasswordReset,
    requestPasswordResetFail,
    requestPasswordResetSuccess,

    requestPasswordResetEmail,
    requestPasswordResetEmailFail,
    requestPasswordResetEmailSuccess
};
