import React, { SVGProps } from "react";
import Svg from "./Svg";

const QuestionMarkIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M8.29674 7.50876C8.25891 8.10021 7.72693 8.55588 7.09933 8.52994C6.47174 8.50401 5.9837 8.0062 6.00042 7.41388L6.00111 7.39514C6.00471 7.3171 6.01616 7.20397 6.03972 7.06042C6.07588 6.84014 6.13284 6.59962 6.21514 6.34372C6.446 5.62583 6.83319 4.91147 7.41255 4.24752C7.68588 3.93429 7.99652 3.63968 8.34596 3.36694C9.54877 2.42818 11.008 2.02198 12.6526 2.00072C13.8621 1.98666 15.0091 2.17849 15.786 2.53714C16.6083 2.91845 17.3321 3.38492 17.9525 4.03291C18.8306 4.95004 19.3333 6.09086 19.3333 7.44259C19.3333 8.86329 18.8748 10.0268 17.9903 11.0323L17.9516 11.0759C17.3196 11.7844 16.6674 12.2708 15.2519 13.1925C13.8306 14.118 13.3656 14.9207 13.3565 16.1134L13.3563 16.1497C13.3563 16.7508 12.8417 17.2381 12.2069 17.2381C11.5721 17.2381 11.0575 16.7508 11.0575 16.1497C11.0575 14.2318 11.8798 12.7652 13.8881 11.4391L14.1702 11.2544C15.2725 10.5303 15.7771 10.143 16.21 9.65484L16.224 9.63906C16.7694 9.01906 17.0345 8.34638 17.0345 7.44259C17.0345 6.65376 16.7579 6.02606 16.2494 5.49494C15.8543 5.08233 15.3579 4.7624 14.7768 4.49291C14.3618 4.30136 13.5592 4.16712 12.6824 4.17732C11.5211 4.19233 10.5599 4.45988 9.80813 5.04665C9.57249 5.23057 9.36547 5.42691 9.18472 5.63405C8.80898 6.06464 8.56055 6.523 8.41428 6.97783C8.36458 7.13238 8.33128 7.273 8.31129 7.39476L8.30638 7.42615C8.30344 7.446 8.30114 7.46369 8.29942 7.47908L8.2972 7.50105L8.29674 7.50876Z" />
        <path d="M12.6667 22C13.7187 22 14.5715 21.1472 14.5715 20.0952C14.5715 19.0432 13.7187 18.1904 12.6667 18.1904C11.6148 18.1904 10.762 19.0432 10.762 20.0952C10.762 21.1472 11.6148 22 12.6667 22Z" />
    </Svg>
);

export default QuestionMarkIcon;
