import ApiConstants from "../../constants/ApiConstants";
import IBudgetFormValues from "../../interfaces/Budgets/IBudgetFormValues";
import * as api from "../../middleware/api";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";
import { useDispatch, useSelector } from "react-redux";
import BudgetConstants from "../../constants/budgets/BudgetConstants";
import IConvertedBudget from "../../interfaces/Budgets/IConvertedBudget";
import { convertBudgetToApi, IBudgetTab } from "../../utils/BudgetUtils";
import * as BudgetActions from "../../actions/budgets/budgetActions";
import ICreateActivationResponse from "../../interfaces/Budgets/ICreateActivationResponse";
import ICreateBudgetResponse from "../../interfaces/Budgets/ICreateBudgetResponse";
import IExecuteResponse from "../../interfaces/Budgets/IExecuteResponse";
import { useEffect, useState } from "react";
import IAppState from "../../interfaces/IAppState";
import { useLocation, useNavigate } from "react-router-dom";
import { addFlashMessage } from "../../actions/flashMessageActions";
import { ISingleBudgetResponse } from "../../interfaces/Budgets/ISingleBudgetResponse";
import { canScheduleCancellation as canScheduleCancel } from "../../reducers/currentUser";
import { defineTabs } from "../../utils/BudgetUtils";
import IPagedResponse from "../../interfaces/IPagedResponse";
import IClient from "../../interfaces/IClient";
import IBudgetApi, { IBudgetExpandedClient } from "../../interfaces/Budgets/IBudgetApi";
import { hasUpdateActionReadyToRun, wasRecentlyExecuted } from "../../components/Shared/Table/TableUtils";
import IBudgetSystemLog from "../../interfaces/Budgets/IBudgetSystemLog";
import IEntity from "../../interfaces/IEntity";
import useBudgetParams from "./useBudgetParams";
import IBudgetChangeLog from "../../interfaces/Budgets/IBudgetChangeLog";
import { logSelector } from "../../reducers/budgets";
import useTrackingBudgetEvents from "./useTrackBudgetEvents";
import buildUrl from "../../utils/UrlUtils";
import IHttpQueryParameters, { IHttpPaginatedQueryParameters } from "../../interfaces/IHttpQueryParameters";
import useDebounce from "../useDebounce";

type useBudgetReturn = {
    budget: IBudgetFormValues | undefined;
    budgets: IEntity<any>;
    runBudget: (id: number) => void;
    isSaving: boolean;
    isReadOnly: boolean;
    loadingBudget: boolean;
    canScheduleCancellation: boolean;
    loadingMessage: string;
    links: IBudgetTab[];
    loadingStream: boolean;
    loadingTable: boolean;
    notification: { isVisiible: boolean };
    showConfirm: boolean;
    showClientColumn: boolean;
    showCancellationWarning: boolean;
    apiErrors: any;
    client: IClient;
    clientId: number | null;
    details: IBudgetExpandedClient;
    newBudgetPath: string;
    changeLogs: IBudgetChangeLog[] | null;
    systemLogs: IBudgetSystemLog[] | null;
    denyDeleteHandler: () => void;
    fetchBudgetsHandler: ({ limit, page }: { limit: number; page: number }) => Promise<void>;
    fetchDeletedHandler: () => void;
    createBudgetHandler: (formValues: IBudgetFormValues) => Promise<void>;
    updateBudgetHandler: (formValues: IBudgetFormValues) => Promise<void>;
    deleteBudgetHandler: (budget: any) => void;
    confirmDeleteHandler: () => void;
    formSubmitHandler: (formValues: IBudgetFormValues) => Promise<void>;
    removeDeletedHandler: () => void;
    pushBudgetHandler: (budget: IBudgetApi) => void;
    hideNotificationHandler: () => void;
    fetchSystemLogsHandler: () => void;
    fetchDetailsHandler: () => void;
    fetchBudgetLogsHandler: () => void;
    setLimit: (limit: number) => void;
    setPage: (page: number) => void;
    limit: number;
    page: number;
    pages: number;
    footer: IBudgetTableFooter;
    filterOptions: IFilterOption[];
    setFilterOptions: (filters: IFilterOption[]) => void;
    searchQuery: string;
    setSearch: (search: string) => void;
    setBudgetTableSort: (sort: string | undefined) => void;
};

export interface IBudgetTableFooter {
    budget: number | null;
    client: IClient;
    difference: number | null;
    id: string | number; // uses campaign id
    idealSpends: number | null;
    manager: string | null;
    mtdSpends: number | null;
    pace: number | null;
    yesterdaySpends: number | null;
    todaySpends: number | null;
}

export interface IErrors {
    errors?: { [errorField: string]: string[] };
    exceptionClass?: string;
    exceptionTrace?: any;
    message?: string;
}
const filterUrlOptions = {
    "All Active": {
        filter: {
            active: true
        }
    }, // default filter, no need to add any filter options
    Deleted: {
        filter: {
            onlyTrashed: true
        }
    },
    Video: {
        filter: {
            label: "video"
        }
    },
    "All Paused": {
        filter: {
            status_id: 6
        }
    },
    Breached: {
        filter: {
            status_id: 4
        }
    },
    "Managed Manually": {
        filter: {
            is_managed_manually: 1
        }
    },
    Event: {
        filter: {
            type_id: 2
        }
    }
};

export interface IFilterOption {
    label: string;
    selected: boolean;
}
function useBudget(): useBudgetReturn {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //redux and route params
    const { isReadOnly, client, clientId, currentUser, clients, budgetId, actionType, budgetType, budgetServices } =
        useBudgetParams();
    const { notification } = useSelector(({ budgets }: IAppState) => budgets);

    const { entitiesKey, schema, titleName, id: serviceId } = budgetServices[budgetType];
    //hook state
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [loadingStream, setLoadingStream] = useState<boolean>(false);
    const [loadingBudget, setLoadingBudget] = useState<boolean>(["edit", "details"].includes(actionType));
    const [loadingTable, setLoadingtable] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("");
    const [budget, setBudget] = useState<IBudgetFormValues>();
    const [budgets, setBudgets] = useState<any[]>([]);
    const [changeLogs, setChangeLogs] = useState<IBudgetChangeLog[] | null>(null);
    const [systemLogs, setSystemLogs] = useState<IBudgetSystemLog[] | null>(null);
    const [details, setDetails] = useState<IBudgetExpandedClient>({} as IBudgetExpandedClient);
    const [budgetToDelete, setBudgetToDelete] = useState<any>("");
    const [apiErrors, setApiErrors] = useState<IErrors>({});
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(20);
    const [page, setPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(1);
    const [footer, setFooter] = useState<any>({});
    const filterValues = [
        { label: "All Active", selected: true },
        { label: "Deleted", selected: false },
        { label: "Video", selected: false },
        { label: "All Paused", selected: false },
        { label: "Breached", selected: false },
        { label: "Managed Manually", selected: false },
        { label: "Event", selected: false }
    ];
    const [filterOptions, setFilterOptions] = useState<IFilterOption[]>(filterValues);
    const [searchQuery, setSearch] = useState("");
    const [sort, setBudgetTableSort] = useState<string | undefined>(undefined);

    //budget page params
    const canScheduleCancellation = canScheduleCancel(currentUser);

    const links: IBudgetTab[] = defineTabs({
        ...clients,
        uri: useLocation().pathname
    }); //browser location for tabs
    const showClientColumn = !clientId ?? false;
    const showCancellationWarning = !!client && !!client.cancelAt;
    // Analytics event tracking
    const trackEvents = useTrackingBudgetEvents();

    const filter: IHttpQueryParameters = filterOptions
        .filter((option) => option.selected)
        // Need to reduce the filter options into a potentially multidimentional object
        .reduce((acc, option) => {
            // @ts-ignore
            const endOptions = filterUrlOptions[option.label];

            return Object.keys(endOptions).reduce((carry, key) => {
                return {
                    ...carry,
                    [key]: {
                        // @ts-ignore
                        ...(carry[key] ?? {}),
                        ...endOptions[key]
                    }
                };
            }, acc);
        }, {} as IHttpQueryParameters);

    //init
    useEffect(() => {
        setLoadingtable(true);

        if (searchQuery) {
            filter.filter = {
                ...(filter?.filter ?? {}),
                search: searchQuery
            };
        }

        if (sort) {
            filter.sort = sort;
        }

        !budgetId &&
            !!budgetType &&
            fetchBudgetsFromApi(
                buildBudgetApiUrl({
                    limit,
                    page,
                    urlOptions: filter
                })
            );
        // only fetch footer summary if we aren't on all budgets page. All budgets is a summary of all platforms, not just 1.
        !budgetId && !!budgetType && budgetType !== "all" && fetchBudgetFooterSummary(clientId, filter);
        !budgetId && !!budgetType && budgetType === "all" && actionType === "details" && fetchDetailsHandler();
        !!budgetId && fetchSingleBudget();
    }, [page, limit, filterOptions, searchQuery, sort]);

    useEffect(() => {
        // When the budget types change, we should reset our state
        setBudgets([]);
        setPage(1);
        setSearch("");
        setFilterOptions(filterValues);
        setBudgetTableSort(undefined);
    }, [budgetType]);

    //delete confirmation modal toggle
    useEffect(() => setShowConfirm(!!budgetToDelete), [budgetToDelete]);

    //api calls & handlers
    const createBudgetHandler = async (formValues: IBudgetFormValues) => {
        if (!clientId) return;
        trackEvents.createEvent(BudgetActions.createBudget(formValues, clientId, serviceId));
        setLoadingMessage("Creating Budget...");
        setApiErrors({});
        setIsSaving(true);
        const convertedBudget: IConvertedBudget = convertBudgetToApi(formValues, serviceId); // convert budget to formValues

        const budgetResponse = await create(clientId, convertedBudget); // create new budget

        const activationResponse = await createActivation(
            budgetResponse.result.clientId,
            budgetResponse.result.id,
            convertedBudget
        ); // create new activation

        if (activationResponse?.result?.serviceId === BudgetConstants.BUDGET_SERVICE_ID_ADWORDS) {
            const { nextUpdateActionId } = activationResponse?.result?.budget || { nextUpdateActionId: 0 };

            const executeResponse = await runBudget(nextUpdateActionId); // execute budget
            if (!!executeResponse) navigate("../");
        }
        setIsSaving(false);
    };

    const updateBudgetHandler = async (formValues: IBudgetFormValues) => {
        if (!clientId || !budgetId) return;
        trackEvents.updateEvent(BudgetActions.updateBudget(formValues, clientId, serviceId, String(budgetId)));
        setLoadingMessage("Updating Budget...");
        setApiErrors({});
        setIsSaving(true);
        // create new activation
        const activationResponse = await createActivation(
            clientId,
            budgetId,
            convertBudgetToApi(formValues, serviceId),
            false
        );

        const nextUpdateActionId = activationResponse?.result?.budget?.nextUpdateActionId || 0;
        if (activationResponse?.result?.serviceId === BudgetConstants.BUDGET_SERVICE_ID_ADWORDS) {
            //execute budget
            api.callApi(
                `${ApiConstants.EXECUTE_ADWORDS_BUDGET_URL(nextUpdateActionId)}`,
                CLIENT_SCHEMAS.BUDGETS.EXECUTE_RESPONSE,
                "post"
            );
            navigate(`../..`);
        }
        setIsSaving(false);
    };

    const execute = async (budget: IBudgetApi) => {
        const { nextUpdateActionId } = budget;
        try {
            setLoadingMessage("Executing budget...");
            flashMessage({ type: "success", text: "Executing budget..." });
            await api.callApi(
                `${ApiConstants.EXECUTE_ADWORDS_BUDGET_URL(nextUpdateActionId)}`,
                CLIENT_SCHEMAS.BUDGETS.EXECUTE_RESPONSE,
                "post"
            );
            const apiUrl = ApiConstants.BUDGET_URL({
                budgetId: budget.id,
                clientId: budget.clientId,
                serviceId: null
            });
            await api.callApi(apiUrl, CLIENT_SCHEMAS.BUDGETS.ADWORDS_BUDGET);
            flashMessage({ type: "success", text: "Execute Budget Success!" });
        } catch (e: any) {
            setApiErrors({ errors: { ...apiErrors.errors, ...e.errors } });
            !budgetId && flashMessage({ text: e.message });
        } finally {
            setIsSaving(false);
            setLoadingtable(false);
        }
    };

    const flashMessage = ({ type = "danger", text = "Something went wrong!" } = {}) => {
        dispatch(addFlashMessage({ type, text }));
    };

    const create = async (clientId: number, formValues: any): Promise<ICreateBudgetResponse> => {
        try {
            return await api.callApi(`/clients/${clientId}/budgets`, formValues, "post", formValues);
        } catch (e: any) {
            setApiErrors({ errors: { ...apiErrors.errors, ...e.errors } });
            flashMessage();
            return {} as ICreateBudgetResponse;
        }
    };

    const createActivation = async (
        clientId: number,
        budgetId: number,
        budgetFormValues: any,
        rollback: boolean = true
    ): Promise<ICreateActivationResponse> => {
        try {
            const resp = await api.callApi(
                `/clients/${clientId}/budgets/${budgetId}/activations`,
                {},
                "post",
                budgetFormValues
            );
            const message = `Successfully ${actionType === "edit" ? "updated" : "created"} budget!`;
            flashMessage({ type: "success", text: message });
            return resp;
        } catch (e: any) {
            setApiErrors({ errors: { ...apiErrors.errors, ...e.errors } });
            flashMessage();
            if (rollback) {
                await api.callApi(`/clients/${clientId}/budgets/${budgetId}`, {}, "delete"); //unhandled response... ?
            }
            return {} as ICreateActivationResponse;
        }
    };

    const formSubmitHandler = async (values: IBudgetFormValues) => {
        const action = budgetId ? updateBudgetHandler : createBudgetHandler;
        action(values);
    };

    const fetchSingleBudget = async () => {
        const query = {
            budgetId,
            clientId,
            serviceId: null
        };
        const apiUrl = ApiConstants.BUDGET_URL(query);
        try {
            setLoadingBudget(true);
            setLoadingMessage("Loading Budget...");
            const response: ISingleBudgetResponse = await api.callApi(
                apiUrl,
                CLIENT_SCHEMAS.BUDGETS.SINGLE_BUDGET_RESPONSE
            );
            budgetId && setBudget(response.entities.budget[budgetId] as IBudgetFormValues);
        } catch (e: any) {
            flashMessage();
        } finally {
            setLoadingBudget(false);
        }
    };

    const fetchDetailsHandler = async () => {
        if (!budgetId) return;
        const url = `/budgets?filter[id]=${budgetId}&expand[lastUpdateAction.campaignLogs]=*&expand[client]=*&expand[activation]=*`;
        try {
            const response: IPagedResponse = await api.callApi(url, CLIENT_SCHEMAS.BUDGETS.ADWORDS_BUDGETS_ARRAY);
            const { budgets } = response.entities;
            const budget = budgets[response.result.data[0]];
            setDetails(budget);
        } catch (error: any) {
            flashMessage();
        }
    };
    interface IBudgetSystemLogsResponse {
        entities: { systemLogs: IEntity<IBudgetSystemLog> };
    }

    const fetchSystemLogsHandler = async () => {
        if (!clientId || !budgetId) return;
        const url = `/clients/${clientId}/budgets/${budgetId}/system-change-logs`;
        try {
            const {
                entities,
                entities: { systemLogs }
            }: IBudgetSystemLogsResponse = await api.getPagedResults(url, CLIENT_SCHEMAS.BUDGETS.SYSTEM_LOG_ARRAY, 50);
            if (Object.keys(entities).length === 0) return;
            setSystemLogs(logSelector(systemLogs));
        } catch (error: any) {
            flashMessage();
        }
    };

    const fetchBudgetLogsHandler = async () => {
        if (!clientId || !budgetId) return;
        const url = `/clients/${clientId}/budgets/${budgetId}/user-change-logs`;
        try {
            const {
                entities,
                entities: { changeLogs }
            } = await api.getPagedResults(url, CLIENT_SCHEMAS.BUDGETS.LOG_ARRAY, 50);
            if (Object.keys(entities).length === 0) return;
            setChangeLogs(logSelector(changeLogs));
        } catch (error: any) {
            flashMessage();
        }
    };

    const buildBudgetApiUrl = ({
        limit,
        page,
        urlOptions = { filter: {}, search: {}, sort: "" }
    }: IHttpPaginatedQueryParameters): string => {
        console.log("sort", sort);

        if (budgetType === "facebook") {
            if (clientId) {
                urlOptions.filter = {
                    ...(urlOptions.filter ?? {}),
                    facebook_account_id: client?.facebookAccountId
                };
                urlOptions.sort = "id";
            }

            return buildUrl(`/facebook-budgets`, {
                limit,
                page,
                include: ["facebookAccount.clients", "facebookAccount.clients.manager", "facebookAccount.clients.pfm"],
                ...urlOptions
            });
        }
        if (budgetType === "koddi") {
            if (clientId) {
                urlOptions.filter = {
                    ...(urlOptions.filter ?? {}),
                    koddi_id: client?.koddiId
                };
            }

            return buildUrl(`/koddi-budgets`, {
                limit,
                page,
                ...urlOptions
            });
        }
        if (budgetType === "cars") {
            return buildUrl(!!clientId ? `/clients/${clientId}/cars-budgets` : "/cars-budgets", {
                include: ["client.manager", "client.pfm"],
                page,
                limit,
                ...urlOptions
            });
        }
        if (budgetType === "programmatic-video") {
            return buildUrl(!!clientId ? `/clients/${clientId}/prog-video-budgets` : "/prog-video-budgets", {
                page,
                limit,
                ...urlOptions
            });
        }

        if (budgetType === "all") {
            return buildUrl(!!clientId ? `/clients/${clientId}/all-budgets` : "/all-budgets", {
                include: ["manager", "pdf", "supervisor"],
                page,
                limit,
                ...urlOptions
            });
        }

        return ApiConstants.BUDGET_URL({ clientId, serviceId, page, limit, urlOptions });
    };

    const fetchBudgetsFromApi = async (apiUrl: string): Promise<void> => {
        try {
            setLoadingMessage(`Loading ${titleName} Budgets...`);

            const { entities, result }: IPagedResponse = await api.callApi(apiUrl, schema);
            console.log(entities, result);
            setPages(result.lastPage);
            setBudgets(result.data.map((id) => entities[entitiesKey][id]));
            setLoadingtable(false);
            setLoadingMessage("");
        } catch (e: any) {
            flashMessage({ text: e.message });
            setLoadingtable(false);
            setLoadingStream(false);
        } finally {
            setLoadingMessage("");
        }
    };

    const fetchDeletedHandler = async () => {
        const query = {
            clientId: clientId ? clientId : null,
            serviceId: serviceId,
            findDeleted: true
        };
        const apiUrl = ApiConstants.BUDGET_URL(query);

        await fetchBudgetsFromApi(apiUrl);
    };

    const fetchBudgetFooterSummary = async (clientId: number | null, options: IHttpQueryParameters) => {
        setFooter({});
        const apiUrl = buildUrl(
            !!clientId ? `/clients/${clientId}/budget-summaries/` + budgetType : `/budget-summaries/` + budgetType,
            filter
        );

        // We don't want to use the schema here because the response is not a paged response
        const response = await api.callApi(apiUrl, schema);

        const { result }: IPagedResponse = response;

        setFooter(result);
    };

    const denyDeleteHandler = () => setBudgetToDelete(null);

    const pushBudget = async (budget: IBudgetApi) => {
        setLoadingtable(true);

        const pushAction = BudgetActions.pushBudget(budget);
        trackEvents.pushEvent(pushAction);
        let budgetToExecute: IBudgetApi = budget;

        if (!hasUpdateActionReadyToRun(budget) && !wasRecentlyExecuted(budget)) {
            budgetToExecute = await reactivate(budget);
            if (!budgetToExecute) return;
        }
        await execute(budgetToExecute);
    };

    const runBudget = async (id: number) => {
        try {
            const response = api.callApi(
                `${ApiConstants.EXECUTE_ADWORDS_BUDGET_URL(id)}`,
                CLIENT_SCHEMAS.BUDGETS.EXECUTE_RESPONSE,
                "post"
            );
            return { entities: response } as IExecuteResponse;
        } catch (e: any) {
            return { entities: {} } as IExecuteResponse;
        }
    };

    const reactivate = async ({ clientId, id: budgetId, activationId }: IBudgetApi) => {
        try {
            setLoadingMessage("Reactivating budget...");
            const reactivateResponse: IPagedResponse = await api.callApi(
                `${ApiConstants.REACTIVATE_ADWORDS_BUDGET_URL(clientId, budgetId, activationId)}`,
                CLIENT_SCHEMAS.BUDGETS.REACTIVATE_RESPONSE,
                "post"
            );

            const reactivatedBudget =
                reactivateResponse.entities.budget[reactivateResponse.result as unknown as number];
            flashMessage({ type: "success", text: "Budget reactivation success." });
            return reactivatedBudget;
        } catch (e: any) {
            flashMessage({ text: "Budget reactivation failed." });
        } finally {
            setLoadingtable(false);
        }
    };

    const deleteBudget = async () => {
        try {
            setBudgetToDelete(null);
            setLoadingMessage("Deleting Budget.");
            setLoadingtable(true);
            const { client = { id: clientId }, id: budgetId = 0 } = budgetToDelete;
            if (!clientId || !budgetId) return;

            await api.doDeleteRequest(`${ApiConstants.DELETE_ADWORDS_BUDGET_URL(clientId, budgetId)}`);
            flashMessage({ type: "success", text: "Budget deleted successfully." });
            trackEvents.removeEvent(BudgetActions.deleteBudget(budgetToDelete));
        } catch (e: any) {
            flashMessage({ text: e.message });
        } finally {
            await fetchBudgetsFromApi(
                buildBudgetApiUrl({
                    limit,
                    page,
                    urlOptions: filter
                })
            );
            setLoadingtable(true);
        }
    };

    const removeDeletedBudgets = () =>
        buildBudgetApiUrl({
            limit,
            page,
            urlOptions: filter
        });

    const deleteBudgetHandler = (budget: any) => setBudgetToDelete(budget);

    const confirmDeleteHandler = () => deleteBudget();
    const removeDeletedHandler = () => removeDeletedBudgets();
    const pushBudgetHandler = (budget: IBudgetApi) => pushBudget(budget);
    const hideNotificationHandler = () => {
        api.abortNetworkRequests();
    };

    return {
        budget,
        budgets,
        details,
        isReadOnly,
        runBudget,
        canScheduleCancellation,
        isSaving,
        showConfirm,
        loadingBudget,
        links,
        showClientColumn,
        client,
        clientId,
        loadingStream,
        loadingTable,
        showCancellationWarning,
        notification,
        loadingMessage,
        newBudgetPath: "create",
        apiErrors,
        changeLogs,
        systemLogs,
        setLimit,
        setPage,
        limit,
        page,
        pages,
        footer,
        pushBudgetHandler,
        removeDeletedHandler,
        createBudgetHandler,
        async fetchBudgetsHandler({ limit, page }: { limit: number; page: number }) {
            return await fetchBudgetsFromApi(
                buildBudgetApiUrl({
                    limit,
                    page,
                    urlOptions: filter
                })
            );
        },
        fetchDeletedHandler,
        updateBudgetHandler,
        deleteBudgetHandler,
        confirmDeleteHandler,
        hideNotificationHandler,
        fetchSystemLogsHandler,
        denyDeleteHandler,
        formSubmitHandler,
        fetchDetailsHandler,
        fetchBudgetLogsHandler,
        filterOptions,
        setFilterOptions,
        searchQuery,
        setSearch,
        setBudgetTableSort
    };
}

export default useBudget;
