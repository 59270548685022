import React from "react";
import DatePickerField from "../../../Shared/Form/Blocks/DatePickerField";
import IClient from "../../../../interfaces/IClient";
import { zonedTimeToUtc } from "date-fns-tz";
import convertUTCTimeToChicago from "../../../../utils/convertUTCTimeToChicago";
import { TIME_ZONE } from "../../../../constants/ClientConstants";
import WarningIcon from "../../../Shared/Icons/WarningIcon";
interface ICancellationTab {
    client: IClient;
    onValueChange(event: React.ChangeEvent<any>): void;
    errors: {
        [key: string]: string[];
    };
}

const CancellationTab: React.FunctionComponent<ICancellationTab> = ({ client, onValueChange, errors }) => {
    const handleOnChange = (name: string, value: Date | null) => {
        // will be null if onClearClick
        if (!value) {
            return onValueChange({ target: { name, value } } as React.ChangeEvent<any>);
        }
        value.setHours(23);
        const utcValue = zonedTimeToUtc(value, TIME_ZONE).toISOString().split(".")[0];
        const mockEvent = { target: { name, value: utcValue } } as any;
        onValueChange(mockEvent);
    };
    const formattedValue = convertUTCTimeToChicago(client.cancelAt || "");

    return (
        <div>
            <h2 className="text-xl font-bold my-2">Schedule Cancellation</h2>
            <div className="w-full md:w-3/4 lg:w-1/2">
                <DatePickerField
                    name="cancelAt"
                    value={formattedValue}
                    onChange={handleOnChange}
                    errors={errors.cancelAt}
                    placeholder="YYYY-MM-DD"
                    minDate={new Date()}
                    ariaLabel="Date"
                    style={{ maxWidth: "200px" }}
                />
            </div>
            <div className="text-gray-500 flex items-center">
                <WarningIcon className="w-6 h-6" />
                <span className="ml-2">Cancellation will happen at 11 pm Central Time</span>
            </div>
        </div>
    );
};

export default CancellationTab;
