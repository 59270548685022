import { ActionErrorType } from "../../interfaces/shared/IItemCollection";

export default {
    FETCH_COLLECTION_LIST: "FETCH_COLLECTION_LIST",
    FETCH_COLLECTION_LIST_SUCCESS: "FETCH_COLLECTION_LIST_SUCCESS",
    FETCH_COLLECTION_LIST_FAILURE: "FETCH_COLLECTION_LIST_FAILURE",
    FETCH_ITEM_COLLECTION: "FETCH_ITEM_COLLECTION",
    FETCH_ITEM_COLLECTION_SUCCESS: "FETCH_ITEM_COLLECTION_SUCCESS",
    FETCH_ITEM_COLLECTION_FAILURE: "FETCH_ITEM_COLLECTION_FAILURE",
    ADD_ITEM_TO_COLLECTION: "ADD_ITEM_TO_COLLECTION",
    ADD_ITEM_TO_COLLECTION_SUCCESS: "ADD_ITEM_TO_COLLECTION_SUCCESS",
    ADD_ITEM_TO_COLLECTION_FAILURE: "ADD_ITEM_TO_COLLECTION_FAILURE",
    REMOVE_ITEM_FROM_COLLECTION: "REMOVE_ITEM_FROM_COLLECTION",
    REMOVE_ITEM_FROM_COLLECTION_SUCCESS: "REMOVE_ITEM_FROM_COLLECTION_SUCCESS",
    REMOVE_ITEM_FROM_COLLECTION_FAILURE: "REMOVE_ITEM_FROM_COLLECTION_FAILURE",
    DISMISS_ITEM_ACTION_ERROR: "DISMISS_ITEM_ACTION_ERROR",
    SEARCH_ITEM_COLLECTION: "SEARCH_ITEM_COLLECTION",
    ADD_ACTION_ERROR_TYPE: ActionErrorType.Add,
    REMOVE_ACTION_ERROR_TYPE: ActionErrorType.Remove,
    UPLOAD_CSV: "UPLOAD_CSV",
    UPLOAD_CSV_SUCCESS: "UPLOAD_CSV_SUCCESS",
    UPLOAD_CSV_FAILURE: "UPLOAD_CSV_FAILURE"
};
