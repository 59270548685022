import React from "react";
import { connect, useDispatch } from "react-redux";
import { createClient, deleteClient, refreshExternalData } from "../../../actions/clientActions";
import { fetchManufacturerList } from "../../../actions/ManufacturerActions";
import IClient from "../../../interfaces/IClient";
import { canScheduleCancellation, isUserInternal } from "../../../reducers/currentUser";
import ClientForm from "./ClientForm";
import { useNavigate } from "react-router";

const Container: React.FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSave = (client: IClient) => dispatch({ ...createClient(client), navigate });
    return <ClientForm {...props} onSave={onSave} />;
};

const mapStateToProps = (state: any) => {
    const newClient = {
        address: undefined,
        adwordsId: undefined,
        adwordsName: undefined,
        agencyId: undefined,
        automobileManufacturers: [],
        bingId: undefined,
        bingName: undefined,
        bingNumber: undefined,
        cancelAt: undefined,
        carsSellerId: undefined,
        clientId: undefined,
        clientServicesPod: undefined,
        createdAt: undefined,
        crmEmail: [],
        city: undefined,
        country: undefined,
        dashboardApiKey: undefined,
        dealerAddress1: undefined,
        dealerAddress2: undefined,
        dealerCity: undefined,
        dealerCode: undefined,
        dealerCountry: undefined,
        dealerCountryCode: undefined,
        dealerName: undefined,
        dealerPhone: undefined,
        dealerState: undefined,
        dealerZip: undefined,
        deletedAt: undefined,
        diSite: undefined,
        domain: undefined,
        engineId: undefined,
        errors: {},
        facebookAccount: undefined,
        facebookAccountId: undefined,
        facebookPage: undefined,
        facebookPageId: undefined,
        facebookWebhooks: undefined,
        gaAccountId: undefined,
        gaAccountName: undefined,
        gaProfileId: undefined,
        gaProfileName: undefined,
        id: undefined,
        inventoryApiKey: undefined,
        inventoryFeedId: undefined,
        lat: undefined,
        lft: undefined,
        lng: undefined,
        locationCode: undefined,
        mainPocEmail: undefined,
        mainPocName: undefined,
        managedByUserId: undefined,
        manufacturers: [],
        name: undefined,
        negativeKeywords: undefined,
        oemEndDate: undefined,
        oemGoLiveDate: undefined,
        oemProgram: undefined,
        parentId: undefined,
        rgt: undefined,
        roles: [],
        salesforceId: undefined,
        saving: false,
        sfDealerCode: undefined,
        shortNameForDealer: undefined,
        state: undefined,
        territory: undefined,
        timeZone: undefined,
        updatedAt: undefined,
        webProvider: undefined,
        zip: undefined
    };
    return {
        client: {
            ...newClient,
            ...{
                errors: state.errors?.errors,
                agencyId: state.currentUser?.agencyId
            }
        },
        clients: state.clients.entities,
        loading: state.clients.loading,
        facebookAccounts: state.facebookAccounts,
        facebookPages: state.facebookPages,
        territories: state.territories,
        clientRoles: state.clientRoles,
        automobileManufacturers: state.automobileManufacturers.entities,
        isInternalUser: isUserInternal(state.currentUser),
        canScheduleCancellation: canScheduleCancellation(state.currentUser)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchManufacturers: () => {
            dispatch(fetchManufacturerList());
        },
        onRefreshSalesforceData: (client: IClient) => {
            dispatch(refreshExternalData(client));
        },
        onDelete: (client: IClient) => {
            if (client.id) {
                dispatch(deleteClient(client.id));
            }
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Container);
