import IEntity from "../../interfaces/IEntity";
import Constants from "../../constants/shared/ItemCollectionConstants";
import * as Interfaces from "../../interfaces/shared/IItemCollection";
import { Action } from "redux";

export const fetchCollectionList = (typeIdentifier: string): Action => ({
    type: `${Constants.FETCH_COLLECTION_LIST}_${typeIdentifier}`
});

export const fetchCollectionListSuccess = (
    typeIdentifier: string,
    collections: IEntity<Interfaces.ICollection>
): Interfaces.IFetchCollectionListSuccess => ({
    type: `${Constants.FETCH_COLLECTION_LIST_SUCCESS}_${typeIdentifier}`,
    payload: collections
});

export const fetchItemCollection = (
    typeIdentifier: string,
    collectionId: number,
    page: number,
    searchTerm: string
): Interfaces.IFetchItemCollection => ({
    type: `${Constants.FETCH_ITEM_COLLECTION}_${typeIdentifier}`,
    payload: {
        collectionId,
        page,
        searchTerm
    }
});

export const fetchItemCollectionSuccess = (
    typeIdentifier: string,
    response: any,
    collectionId: number,
    searchTerm: string
): Interfaces.IFetchItemCollectionSuccess => ({
    type: `${Constants.FETCH_ITEM_COLLECTION_SUCCESS}_${typeIdentifier}`,
    payload: {
        response,
        collectionId,
        searchTerm
    }
});

export const fetchItemCollectionFailure = (
    typeIdentifier: string,
    errorMessage: string,
    searchTerm: string
): Interfaces.IFetchItemCollectionFailure => ({
    type: `${Constants.FETCH_ITEM_COLLECTION_FAILURE}_${typeIdentifier}`,
    payload: {
        errorMessage,
        searchTerm
    }
});

export const searchItemCollection = (
    typeIdentifier: string,
    collectionId: number,
    searchTerm: string
): Interfaces.ISearchItemCollection => ({
    type: `${Constants.SEARCH_ITEM_COLLECTION}_${typeIdentifier}`,
    payload: {
        collectionId,
        searchTerm
    }
});

export const addItemToCollection = (
    typeIdentifier: string,
    itemName: string,
    collectionId: number
): Interfaces.IAddItemToCollection => ({
    type: `${Constants.ADD_ITEM_TO_COLLECTION}_${typeIdentifier}`,
    payload: {
        itemName,
        collectionId,
        dispatchedAt: Date.now()
    }
});

export const addItemToCollectionSuccess = (
    typeIdentifier: string,
    item: IEntity<Interfaces.IItem>,
    itemId: number,
    dispatchedAt: number
): Interfaces.IAddItemToCollectionSuccess => ({
    type: `${Constants.ADD_ITEM_TO_COLLECTION_SUCCESS}_${typeIdentifier}`,
    payload: {
        item,
        itemId,
        dispatchedAt
    }
});

export const addItemToCollectionFailure = (
    typeIdentifier: string,
    dispatchedAt: number,
    errorMessage: string
): Interfaces.IAddItemToCollectionFailure => ({
    type: `${Constants.ADD_ITEM_TO_COLLECTION_FAILURE}_${typeIdentifier}`,
    payload: {
        dispatchedAt,
        errorMessage
    }
});

export const removeItemFromCollection = (
    typeIdentifier: string,
    itemId: number
): Interfaces.IRemoveItemFromCollection => ({
    type: `${Constants.REMOVE_ITEM_FROM_COLLECTION}_${typeIdentifier}`,
    payload: itemId
});

export const removeItemFromCollectionSuccess = (
    typeIdentifier: string,
    itemId: number
): Interfaces.IRemoveItemFromCollectionSuccess => ({
    type: `${Constants.REMOVE_ITEM_FROM_COLLECTION_SUCCESS}_${typeIdentifier}`,
    payload: itemId
});

export const removeItemFromCollectionFailure = (
    typeIdentifier: string,
    itemId: number,
    errorMessage: string
): Interfaces.IRemoveItemFromCollectionFailure => ({
    type: `${Constants.REMOVE_ITEM_FROM_COLLECTION_FAILURE}_${typeIdentifier}`,
    payload: {
        itemId,
        errorMessage
    }
});

export const dismissItemActionError = (
    typeIdentifier: string,
    itemId: number,
    errorType: Interfaces.ActionErrorType
): Interfaces.IDismissItemActionError => ({
    type: `${Constants.DISMISS_ITEM_ACTION_ERROR}_${typeIdentifier}`,
    payload: {
        itemId,
        errorType
    }
});

export const uploadCSV = (typeIdentifier: string, id: number, fileInput: any): Interfaces.IUploadCsv => ({
    type: `${Constants.UPLOAD_CSV}_${typeIdentifier}`,
    payload: {
        id,
        fileInput
    }
});

export const uploadCSVSuccess = (typeIdentifier: string): Interfaces.IUploadCsvSuccess => ({
    type: `${Constants.UPLOAD_CSV_SUCCESS}_${typeIdentifier}`
});

export const uploadCSVFailure = (typeIdentifier: string): Interfaces.IUploadCsvFailure => ({
    type: `${Constants.UPLOAD_CSV_SUCCESS}_${typeIdentifier}`
});
