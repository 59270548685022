import React from "react";
import IEntity from "../../../interfaces/IEntity";
import { IInventoryExport } from "../../../interfaces/IRemarketing";
import { Table } from "../../Shared/Table";
import { CellInfo } from "react-table";
import PencilIcon from "../../Shared/Icons/PencilIcon";
import TableActionLink from "../../Shared/Table/TableActionLink";
import TableActionDeleteButton from "../../Shared/Table/TableActionDeleteButton";
import GoogleColorIcon from "../../Shared/Icons/GoogleColoredIcon";
import FacebookColorIcon from "../../Shared/Icons/FacebookColorIcon";

interface IProps {
    clientId?: number;
    inventoryExports: IEntity<IInventoryExport>;
    removeExportMapping(clientId?: number, id?: number): void;
}

const RemarketingList: React.FunctionComponent<IProps> = ({ clientId, inventoryExports, removeExportMapping }) => {
    const columns = [
        {
            id: "actions",
            Header: "Actions",
            width: 75,
            Cell({ original }: any) {
                return (
                    <div className="flex items-center text-gray-500">
                        <TableActionLink to={`${original.id}/edit`} Icon={PencilIcon} />
                        <TableActionDeleteButton
                            deletionMessage="Are you sure you want to delete this export?"
                            onDelete={() => removeExportMapping(clientId, original.id)}
                            className="lg:ml-2"
                        />
                    </div>
                );
            }
        },
        {
            id: "name",
            accessor: "name",
            Header: "Name"
        },
        {
            id: "existingExports",
            Header: "Existing Exports",
            Cell({ original }: CellInfo) {
                const hasGoogleExports = original?.googleAutoExports && original.googleAutoExports.length > 0;
                const hasFacebookExports = original?.facebookAutoExports && original.facebookAutoExports.length > 0;

                return (
                    <div className="flex items-center">
                        {hasGoogleExports && <GoogleColorIcon className="w-6 h-6" />}
                        {hasFacebookExports && (
                            <FacebookColorIcon className={`w-6 h-6 ${hasGoogleExports ? "ml-2" : ""}`} />
                        )}
                    </div>
                );
            },
            className: "my-1"
        }
    ];

    return (
        <div className="shadow bg-white rounded overflow-hidden">
            <Table
                data={Object.values(inventoryExports)}
                columns={columns}
                loadingTable={false}
                loadingMessage={"Loading Remarketing Inventory Exports..."}
            />
        </div>
    );
};

export default RemarketingList;
