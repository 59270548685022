import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormikActions } from "formik";
import moment from "moment";
import IAppState from "../../../interfaces/IAppState";
import IFacebookAutoExportType from "../../../interfaces/IFacebookAutoExport";
import { IInventoryExport } from "../../../interfaces/IRemarketing";
import { IClient } from "../../../interfaces/IClient";
import Button, { NEGATIVE_BUTTON, SECONDARY_BUTTON, secondaryClasses, baseStyles } from "../../Shared/Button";
import ModalToggle from "../../Shared/Dialogs/ModalToggle";
import TableHeader from "../../Shared/TableHeader";
import FacebookExport from "./FacebookExport";
import GoogleExport, { IGoogleAutoExportFormValues } from "./GoogleExport";
import getEntityByKeyValue from "../../../utils/getEntityByKeyValue";
import { getInventoryFieldsDI } from "../../../utils/InventoryUtils";
import { updateFacebookExport, createFacebookExport } from "../../../actions/remarketing/inventoryExportsActions";
import {
    createGoogleExport,
    updateGoogleExport,
    deleteGoogleExport,
    deleteFacebookExport
} from "../../../actions/remarketing/inventoryExportsActions";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import { IInventoryExportState } from "../../../sagas/Client/remarketingSagas";

interface IProps {
    inventoryExport: IInventoryExport | IInventoryExportState;
    facebookExport: IFacebookAutoExportType | null;
    googleExport: any;
    remarketingId: number;
    clientId: number;
    client: IClient;
    facebookDeleting: boolean;
    googleDeleting: boolean;
    removeFacebookExport(id: number, clientId: number): void;
    removeGoogleExport(id: number, clientId: number): void;
    handleSaveGoogleExport(
        googleExport: IGoogleAutoExportFormValues,
        inventoryExportId: number,
        clientId: number,
        formikActions: FormikActions<IGoogleAutoExportFormValues>
    ): void;
    handleSaveFacebookExport(
        facebookExport: IFacebookAutoExportType,
        inventoryExportId: number,
        clientId: number,
        formikActions: FormikActions<IFacebookAutoExportType>
    ): void;
}

const tableRowStyles = "flex items-center border-t border-solid border-gray-500 w-full p-4";
const tableHeaderItems = [{ title: "Remarketing Export" }, { title: "Last Updated" }, { title: "" }];

const RemarketingExportTable: React.FunctionComponent<IProps> = ({
    facebookExport,
    googleExport,
    inventoryExport,
    clientId,
    remarketingId,
    removeFacebookExport,
    removeGoogleExport,
    facebookDeleting,
    googleDeleting,
    handleSaveGoogleExport,
    handleSaveFacebookExport,
    client
}) => {
    const [inventoryVariables, setInventoryVariables] = useState<string[]>([]);
    const canCreateFacebook = !!client.facebookAccountId && !!client.lat && !!client.lng;
    const canCreateGoogleAds = !!client.adwordsId;
    const displayTable = canCreateFacebook || canCreateGoogleAds;

    useEffect(() => {
        if (inventoryExport.inventorySource === "CARS") {
            setInventoryVariables(getInventoryFieldsDI());
        }

        if (inventoryExport.inventorySource === "DI") {
            setInventoryVariables(getInventoryFieldsDI());
        }
    }, [inventoryExport]);

    return (
        <div className="bg-white my-4 shadow rounded overflow-hidden">
            {displayTable && (
                <>
                    <TableHeader items={tableHeaderItems} />
                    {canCreateFacebook && (
                        <div className={`${tableRowStyles}`}>
                            <div className="w-1/4">Facebook Export</div>
                            <div className="w-1/4">
                                {facebookExport && <div>{moment(facebookExport.updatedAt).format("MMM D h:mm a")}</div>}
                            </div>
                            <div className="flex-grow flex justify-end">
                                {facebookExport && (
                                    <Link
                                        to="../logs/facebook"
                                        relative="path"
                                        className={`${secondaryClasses} ${baseStyles}`}
                                    >
                                        View Logs
                                    </Link>
                                )}
                                <ModalToggle
                                    className="max-w-4xl"
                                    toggleButton={(open: () => void) => (
                                        <Button
                                            styleType={SECONDARY_BUTTON}
                                            onClick={open}
                                            disabled={facebookDeleting}
                                            styles="ml-4"
                                        >
                                            {facebookExport ? "Edit" : "Create"}
                                        </Button>
                                    )}
                                    modalContent={(hide: () => void) => (
                                        <FacebookExport
                                            inventoryVariables={inventoryVariables}
                                            inventoryExport={inventoryExport}
                                            facebookExport={facebookExport}
                                            onSaveFacebookExport={handleSaveFacebookExport}
                                            clientId={clientId}
                                            inventoryExportId={remarketingId}
                                            hide={hide}
                                        />
                                    )}
                                />
                                {facebookExport && (
                                    <Button
                                        styleType={NEGATIVE_BUTTON}
                                        disabled={facebookDeleting}
                                        onClick={() => {
                                            removeFacebookExport(facebookExport.id as number, clientId);
                                        }}
                                        styles="ml-4"
                                    >
                                        {facebookDeleting ? (
                                            <div className="flex items-center">
                                                <RefreshIcon className="w-6 h-6 rotate-fast" />
                                                <span className="ml-2">Deleting</span>
                                            </div>
                                        ) : (
                                            "Delete"
                                        )}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                    {!canCreateFacebook && (
                        <>
                            <div
                                data-testid="facebook-error-message"
                                className="py-2 m-2 px-4 text-yellow-600 bg-yellow-100 border border-yellow-600 rounded"
                            >
                                <div className="font-bold">Facebook Export.</div>
                                <div>
                                    <p>
                                        There's a problem with the client's geolocation or Facebook Account in FUEL{" "}
                                        <a className={"font-bold underline"} href={`../../../../clients/${clientId}`}>
                                            Please verify the billing address in FUEL and that they have a Facebook
                                            Account.
                                        </a>{" "}
                                        If they recently gained a salesforce account id, refresh their salesforce data.
                                    </p>{" "}
                                    If this doesn't fix this error after a 15 minutes, please reach out to{" "}
                                    <i className={"font-bold"}>#help-fuel-media</i> with this client’s information.
                                </div>
                            </div>
                        </>
                    )}

                    {canCreateGoogleAds && (
                        <div className={`${tableRowStyles} bg-light-gray`}>
                            <div className="w-1/4">Google Ads</div>
                            <div className="w-1/4">
                                {googleExport && <div>{moment(googleExport.updatedAt).format("MMM D h:mm a")}</div>}
                            </div>
                            <div className="flex-grow flex justify-end">
                                {googleExport && (
                                    <Link
                                        to={`../${remarketingId}/logs/google`}
                                        className={`${secondaryClasses} ${baseStyles}`}
                                    >
                                        View Logs
                                    </Link>
                                )}
                                <ModalToggle
                                    className="max-w-4xl"
                                    toggleButton={(open: () => void) => (
                                        <Button
                                            styleType={SECONDARY_BUTTON}
                                            onClick={open}
                                            disabled={googleDeleting}
                                            styles="ml-4"
                                        >
                                            {googleExport ? "Edit" : "Create"}
                                        </Button>
                                    )}
                                    modalContent={(hide: () => void) => (
                                        <GoogleExport
                                            clientId={clientId}
                                            onSaveGoogleExport={handleSaveGoogleExport}
                                            inventoryExportId={remarketingId}
                                            inventoryVariables={inventoryVariables}
                                            googleExport={googleExport}
                                            hide={hide}
                                        />
                                    )}
                                />
                                {googleExport && (
                                    <Button
                                        styleType={NEGATIVE_BUTTON}
                                        disabled={googleDeleting}
                                        onClick={() => {
                                            removeGoogleExport(googleExport.id as number, clientId);
                                        }}
                                        styles={"ml-4"}
                                    >
                                        {googleDeleting ? (
                                            <div className="flex items-center">
                                                <RefreshIcon className="w-6 h-6 rotate-fast" />
                                                <span className="ml-2">Deleting</span>
                                            </div>
                                        ) : (
                                            "Delete"
                                        )}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

interface IOwnProps {
    remarketingId: number;
}

const mapStateToProps = (state: IAppState, { remarketingId }: IOwnProps) => {
    return {
        facebookDeleting: state.facebookAutoExports.deleting,
        googleDeleting: state.googleAutoExports.deleting,
        facebookExport: getEntityByKeyValue<IFacebookAutoExportType>(
            state.facebookAutoExports.entities,
            "remarketingInventoryExportId",
            remarketingId
        ),
        googleExport: getEntityByKeyValue<IFacebookAutoExportType>(
            state.googleAutoExports.entities,
            "remarketingInventoryExportId",
            remarketingId
        )
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    removeFacebookExport: (facebookExportId: number, clientId: number) => {
        dispatch(deleteFacebookExport(facebookExportId, clientId));
    },
    removeGoogleExport: (id: number, clientId: number) => {
        dispatch(deleteGoogleExport(id, clientId));
    },
    handleSaveFacebookExport: (
        facebookExport: IFacebookAutoExportType,
        inventoryExportId: number,
        clientId: number,
        formikActions: FormikActions<IFacebookAutoExportType>
    ) => {
        facebookExport.id
            ? dispatch(updateFacebookExport(facebookExport, inventoryExportId, clientId, formikActions))
            : dispatch(createFacebookExport(facebookExport, inventoryExportId, clientId, formikActions));
    },
    handleSaveGoogleExport: (
        googleExport: IGoogleAutoExportFormValues,
        inventoryExportId: number,
        clientId: number,
        formikActions: FormikActions<IGoogleAutoExportFormValues>
    ) => {
        googleExport.id
            ? dispatch(updateGoogleExport(googleExport, inventoryExportId, clientId, formikActions))
            : dispatch(createGoogleExport(googleExport, inventoryExportId, clientId, formikActions));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(RemarketingExportTable);
