import React, { SVGProps } from "react";
import Svg from "./Svg";

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M22.278 18.723L13.98 5.196c-.552-.901-1.456-.889-2.008.013L3.674 18.717c-.552.902-.14 1.599.917 1.599h16.77c1.056 0 1.468-.692.917-1.593zM12.969 8.19c.531 0 .943.587.915 1.305l-.168 4.323c-.027.717-.365 1.304-.749 1.304-.385 0-.723-.587-.75-1.304l-.166-4.323c-.027-.718.386-1.305.918-1.305zm0 10.188c-.67 0-1.13-.49-1.116-1.16 0-.685.46-1.16 1.116-1.16.683 0 1.115.476 1.13 1.16 0 .67-.447 1.16-1.13 1.16z" />
    </Svg>
);

export default WarningIcon;
