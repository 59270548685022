import { combineReducers, Reducer } from "redux";
import CarsBudgetConstants from "../constants/budgets/CarsConstants";

const entitiesReducer: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case CarsBudgetConstants.REQUEST_CARS_BUDGETS:
        case CarsBudgetConstants.REQUEST_CARS_BUDGETS_FAIL:
        case CarsBudgetConstants.REQUEST_CLIENT_CARS_BUDGETS:
        case CarsBudgetConstants.REQUEST_CLIENT_CARS_BUDGETS_FAIL:
            return {};
        case CarsBudgetConstants.REQUEST_CARS_BUDGETS_SUCCESS:
        case CarsBudgetConstants.REQUEST_CLIENT_CARS_BUDGETS_SUCCESS:
            return { ...action.payload.budgets };
        default:
            return state;
    }
};

const loading: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case CarsBudgetConstants.REQUEST_CARS_BUDGETS:
        case CarsBudgetConstants.REQUEST_CLIENT_CARS_BUDGETS:
            return true;
        case CarsBudgetConstants.REQUEST_CARS_BUDGETS_SUCCESS:
        case CarsBudgetConstants.REQUEST_CARS_BUDGETS_FAIL:
        case CarsBudgetConstants.REQUEST_CLIENT_CARS_BUDGETS_SUCCESS:
        case CarsBudgetConstants.REQUEST_CLIENT_CARS_BUDGETS_FAIL:
            return false;
        default:
            return state;
    }
};

const loadingMessage: Reducer<string> = (state = "", action) => {
    switch (action.type) {
        case CarsBudgetConstants.REQUEST_CARS_BUDGETS_SPINNER:
            return action.payload.loadingMessage;
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    loading,
    loadingMessage
});
