import React, { SVGProps } from "react";
import Svg from "./Svg";

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path
            fill="#3b5998"
            d="M2.01 12.5c-.195 3.425 2.414 9.467 8.885 10.42.888.131 2.398.113 3.224 0 2.456-.334 8.613-2.932 8.893-10.43 0-3.884-2.896-10.378-10.464-10.49C9.062 2 2.128 4.573 2.01 12.5z"
        />
        <path
            fill="#fff"
            d="M8.116 15.578h2.79v7.343c.888.13 2.4.112 3.225 0v-7.343h2.456l.459-3.087H14.13v-2.004c0-.686.248-1.6 1.612-1.643h1.402V6.256c-2.044-.2-1.18-.2-2.816-.2-1.142 0-3.268.9-3.422 3.684v2.751h-2.79v3.087z"
        />
    </Svg>
);

export default FacebookIcon;
