import React, { RefObject } from "react";
import Tippy from "@tippyjs/react";

// @see https://github.com/atomiks/tippyjs-react

const Tooltip: React.FC<
    Partial<{
        reference: Element | RefObject<Element> | null | undefined;
        shouldShow: boolean;
        className: string;
        targetEl?: any;
        text?: string;
    }>
> = ({ reference, shouldShow = false, className = "", children }) => (
    <Tippy
        className={`p-2 ${className}`}
        visible={shouldShow}
        reference={reference}
        content={children}
        theme="light-border"
    />
);

export default Tooltip;
