import React, { SVGProps } from "react";
import Svg from "./Svg";

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path
            d="M11.415.919C5.624.919.913 5.64.913 11.444c0 2.499.883 4.908 2.492 6.807l16.022-.003a10.528 10.528 0 0 0 2.489-6.804c0-5.804-4.71-10.525-10.501-10.525zM3.196 19.167a.456.456 0 0 1-.342-.155A11.443 11.443 0 0 1 0 11.444C0 5.136 5.12.004 11.415.004c6.294 0 11.414 5.132 11.414 11.44 0 2.789-1.013 5.475-2.85 7.564a.456.456 0 0 1-.343.155l-16.44.004z"
            mask="url(#b)"
        />
        <path d="M12.415 9.604c-.71 1.868-1.526 4.214-1.526 4.819a1.526 1.526 0 0 0 3.052 0c0-.605-.817-2.95-1.526-4.819m0 7.252a2.44 2.44 0 0 1-2.442-2.433c0-1.146 1.81-5.732 2.016-6.251a.458.458 0 0 1 .851 0c.207.519 2.016 5.105 2.016 6.25a2.44 2.44 0 0 1-2.441 2.434M12.445 5.625a.457.457 0 0 1-.458-.457V3.456a.457.457 0 0 1 .916 0v1.712a.457.457 0 0 1-.458.457M7.807 6.846a.457.457 0 0 1-.396-.227l-.856-1.473a.452.452 0 0 1 .167-.62.459.459 0 0 1 .624.166l.856 1.473a.452.452 0 0 1-.395.68M4.391 10.264a.449.449 0 0 1-.226-.061l-1.473-.857a.459.459 0 0 1-.166-.624.452.452 0 0 1 .62-.167l1.473.856a.459.459 0 0 1-.228.853M3.168 14.903H1.456a.457.457 0 0 1 0-.916h1.712a.457.457 0 0 1 0 .916M2.92 20.396a.458.458 0 0 1-.227-.852l1.472-.856a.452.452 0 0 1 .62.167.458.458 0 0 1-.166.624l-1.474.856a.449.449 0 0 1-.226.061M17.022 6.846a.452.452 0 0 1-.395-.68l.856-1.474a.458.458 0 0 1 .624-.166.452.452 0 0 1 .167.62l-.856 1.473a.457.457 0 0 1-.396.227M20.438 10.264a.459.459 0 0 1-.227-.853l1.472-.856a.453.453 0 0 1 .62.167.459.459 0 0 1-.166.624l-1.473.857a.449.449 0 0 1-.226.06M23.373 14.903H21.66a.457.457 0 0 1 0-.916h1.712a.457.457 0 0 1 0 .916M21.91 20.396a.449.449 0 0 1-.227-.06l-1.472-.857a.458.458 0 0 1-.167-.624.452.452 0 0 1 .62-.167l1.473.856a.458.458 0 0 1-.227.852" />
    </Svg>
);

export default DashboardIcon;
