import React, { SVGProps } from "react";
import Svg from "./Svg";

const KoddiIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <g clipPath="url(#a)">
            <path d="M21.73 22.255c-.87.223-3.335-1.235-5.76-5.153-.424-.683-.463-.814.203-1.306.204-.15 2.874-2.19 3.589-3.717.81-1.728.468-3.408-1.023-4.598a2.711 2.711 0 0 0-.967-.531c-.183-.05-.336-.09-.267.263.144.699.005 1.218-.458 1.895-.603.892-4.307 3.914-6.05 4.519.761-2.533 2.588-7.469 2.482-9.537-.075-1.46-.886-2.996-2.41-3.693a4.012 4.012 0 0 0-2.525-.32c-.292.053-.267.145-.132.356.328.507.478 1.366.49 1.965a7.183 7.183 0 0 1-.275 2.037c-1.003 3.636-2.06 7.271-3.05 10.905-.499 1.829-.737 3.934-.46 5.56.372 2.182 1.108 3.3 2.373 3.99.336.185.507.157.53-.205.077-1.218.844-5.35 2.119-7.428l.42.557c1.065 1.47 2.096 2.965 3.267 4.354.805.952 1.397 1.718 2.56 2.267 2.313 1.094 4.53.36 5.538-1.822.115-.245.134-.44-.194-.358Z" />
        </g>
    </Svg>
);

export default KoddiIcon;
