import { call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import schemas from "../middleware/schemas/sitelinkTemplates";
import {
    fetchSitelinkTemplateListFailed,
    fetchSitelinkTemplateListSuccess,
    FETCH_SITELINK_TEMPLATES
} from "../actions/SitelinkTemplateActions";
import IPagedResponse from "../interfaces/IPagedResponse";

export function* fetchSitelinkTemplates() {
    const url = `/sitelink-templates?limit=1000`;

    try {
        const response: IPagedResponse = yield call(callApi, url, schemas.SITELINK_TEMPLATE_LIST);

        yield put(fetchSitelinkTemplateListSuccess(response.entities));
    } catch (e) {
        console.error(e);
        yield put(fetchSitelinkTemplateListFailed(e.message));
    }
}

export default function* sitelinkTemplateSagas() {
    yield takeEvery(FETCH_SITELINK_TEMPLATES, fetchSitelinkTemplates);
}
