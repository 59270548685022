/**
 * Created by fjiles@dealerinspire.com on 3/13/17.
 */
import { LOAD_LOCATION_STATS } from "../../actions/salesData";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";
import { AnyAction } from "redux";

const reducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case LOAD_LOCATION_STATS: {
            return action.resp.data;
        }
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

export default reducer;
