import React, { FunctionComponent } from "react";
import LogEntry from "./LogEntry";
import IEntity from "../../../../interfaces/IEntity";
import IFacebookLog from "../../../../interfaces/IFacebookLog";
import IFacebookAutoExport from "../../../../interfaces/IFacebookAutoExport";
import Button, { SECONDARY_BUTTON } from "../../../Shared/Button";
import IGoogleAutoExport from "../../../../interfaces/IGoogleAutoExport";

interface ILogContainer {
    logs: IEntity<IFacebookLog>;
    exportMapping: any;
    hide(): void;
}

const PreviousLogs: FunctionComponent<ILogContainer> = ({ logs, exportMapping, hide }) => {
    return (
        <>
            <div className="text-lg mb-4">All Logs</div>
            <div className="overflow-y-auto border-t-2" style={{ maxHeight: "575px" }}>
                {Object.keys(logs)
                    .sort((a, b) => {
                        return Number(new Date(logs[b].completedAt)) - Number(new Date(logs[a].completedAt));
                    })
                    .map((key: any) => {
                        const log = logs[key];
                        return <LogEntry key={log.id} log={log} exportMapping={exportMapping} />;
                    })}
            </div>
            <div className="flex flex-right items-center my-4">
                <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                    Okay
                </Button>
            </div>
        </>
    );
};

export default PreviousLogs;
