import React from "react";

interface ITextareaInput {
    name?: string;
    value: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: any;
    placeholder?: string;
    className?: string;
    readOnly?: boolean;
    isValid?: boolean;
    rows?: number;
}

const defaultClasses = `w-full px-3 py-3 text-base border rounded`;
const errorClasses = `border-red`;
const readOnlyClasses = `text-gray-800 bg-gray-400 cursor-not-allowed opacity-75 outline-none`;

const TextareaInput: React.FunctionComponent<ITextareaInput> = ({
    name,
    value,
    onChange,
    onBlur,
    placeholder,
    className,
    readOnly,
    isValid,
    rows
}) => {
    const defaultClassName = `${defaultClasses} ${readOnly && readOnlyClasses} ${!isValid && errorClasses}`;
    return (
        <textarea
            data-testid={`textarea-${name}`}
            value={value}
            name={name}
            className={`${defaultClassName} ${className}`}
            readOnly={readOnly}
            onChange={onChange}
            rows={rows}
            onBlur={onBlur}
            placeholder={placeholder}
        />
    );
};

export default TextareaInput;
