import React, { useState, useEffect, Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ModalToggle from "../Shared/Dialogs/ModalToggle";
import MessagesModal from "../Profile/Notifications/MessagesModal";

interface IProps {
    currentUser: any;
    markNotificationAsRead: any;
    updateNotifications: any;
}

interface INotification {
    details?: string[];
    link?: string;
}

interface IRenderLink {
    notification: INotification;
    onClick(): void;
}

const RenderLink: FunctionComponent<IRenderLink> = ({ notification, onClick }) => {
    return (
        <>
            {notification.details && (
                <button className="inline-block m-0 mr-4 text-blue-600">
                    <ModalToggle
                        style={{ width: "50%", height: "510px" }}
                        toggleButton={(open) => <span onClick={open}>View Details</span>}
                        modalContent={(hide) => <MessagesModal hide={hide} details={notification.details!} />}
                    />
                </button>
            )}

            {notification.link && (
                <Link to={notification.link} className="inline-block m-0 mr-4 text-blue-600" onClick={() => onClick}>
                    View More
                </Link>
            )}
        </>
    );
};

function resolveColor(level: string): string {
    if (level === "error") {
        return "red";
    }

    if (level === "warning") {
        return "yellow";
    }

    return "green";
}

const NotificationList: React.SFC<IProps> = ({ currentUser, markNotificationAsRead, updateNotifications }) => {
    const [notifications, setNotifications] = useState<INotification[]>([]);

    const removeNotification = (index: number, markAsRead: boolean) => {
        const updatedNotifications = [...notifications];
        const removedNotification = updatedNotifications[index];
        updatedNotifications.splice(index, 1);
        setNotifications(updatedNotifications);
        if (markAsRead) {
            markNotificationAsRead(removedNotification);
        }
    };

    const addNotification = (notification: INotification) => {
        const updatedNotifications = [...notifications];
        updatedNotifications.push(notification);
        setNotifications([...notifications, notification]);
    };

    // We need to have `notifications` as a dependency in here because otherwise when `addNotification` is called,
    // it uses the initial state of `notifications` (an empty array). We also have to `stopListening` before we can
    // call `listen` otherwise we get multiple listeners that result in a loop that crashes the browser.
    useEffect(() => {
        if (currentUser === null) {
            return;
        }

        // Unfortunately, it does not appear as though there is a helper to stop listening to notifications.
        (window as any).Echo.private(`user.${currentUser.id}`)
            .stopListening(".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated")
            .notification((notification: any) => {
                addNotification(notification);
            });
    }, [currentUser, notifications]);

    useEffect(updateNotifications, [notifications]);

    return (
        <Fragment>
            {notifications.length > 0 && (
                <aside className="fixed bottom-0 right-0 z-50 p-4 w-2/5">
                    <TransitionGroup component={null}>
                        {notifications.map((notification: any, index: any) => {
                            if (notification === null) {
                                return null;
                            }

                            const color = resolveColor(notification.level);

                            return (
                                <CSSTransition key={index} component={null} timeout={500} classNames="notification">
                                    <div
                                        className={`bg-white shadow-lg border-l-2 border-${color}-500 p-4 mt-4 rounded`}
                                    >
                                        <div className="font-semibold m-0 text-base">{notification.title}</div>
                                        <div className="m-0 my-2 text-sm leading-snug">{notification.message}</div>
                                        <div className="flex justify-between text-sm">
                                            <div>
                                                <RenderLink
                                                    notification={notification}
                                                    onClick={() => removeNotification(index, false)}
                                                />
                                            </div>
                                            <button
                                                className="inline-block m-0 text-red-600"
                                                onClick={() => removeNotification(index, false)}
                                            >
                                                Dismiss
                                            </button>
                                        </div>
                                    </div>
                                </CSSTransition>
                            );
                        })}
                    </TransitionGroup>
                </aside>
            )}
        </Fragment>
    );
};

export default NotificationList;
