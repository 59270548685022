import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "../components/Auth/Login";

const NoUser = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/login");
    }, []);
};

const UnAuthenticatedRouter = () => {
    return (
        <>
            <div className="bg-white" />
            <div className="main-content-area w-full h-full bg-gray-200">
                <Routes>
                    <Route element={<Login />} path="/login" />
                    <Route element={<NoUser />} path="*" />
                </Routes>
            </div>
        </>
    );
};

export default UnAuthenticatedRouter;
