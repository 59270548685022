import Constants from "../constants/DynamicCampaignStatusConstants";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

const initialState = {
    entities: {},
    loading: false,
    errors: null
};

function dynamicCampaignStatusReducer(state: any = initialState, action: any) {
    switch (action.type) {
        case Constants.FETCH_DC_STATUSES:
            return {
                ...state,
                loading: true,
                errors: null
            };
        case Constants.FETCH_DC_STATUSES_FAILURE:
            return { ...state, errors: action.payload, loading: false };
        case Constants.FETCH_DC_STATUSES_SUCCESS:
            return { ...state, entities: action.payload, errors: null, loading: false };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
}

export default dynamicCampaignStatusReducer;
