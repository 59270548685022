import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import useApi from "./useApi";

export interface IConditional {
    destroy?: boolean;
    conditionalId?: number;
    conditionalType?: string;

    value: string | null;
    comparator: string | null;
    id?: string | number | undefined;

    createdAt?: string;
    updatedAt?: string | null;
    deletedAt?: string | null;

    new?: boolean | undefined;
    deleted?: boolean;
    dirty?: boolean;
    errors?: any[];
}
export interface IConditionalParameter extends IConditional {
    parameter: string | null;
}
export interface IConditionalField extends IConditional {
    field: string | null;
}

export interface IConditionable {
    conditionals: IConditional[];
}

export interface IConditionableField {
    conditionals: IConditionalField[];
}

export interface IConditionableParameter {
    conditionals: IConditionalParameter[];
}

function useConditionals({
    defaultValues,
    onFinish,
    conditionalType,
    conditionalId
}: {
    defaultValues: IConditionable;
    onFinish: () => void;
    conditionalType?: string;
    conditionalId?: number;
}) {
    const [loading, setLoading] = useState(false);
    const [conditionalsToRemove, setConditionalForRemoval] = useState<number[]>([]);

    const { control, register, getValues, ...form } = useForm({
        defaultValues,
        mode: "all"
    });

    const { fields, ...fieldArray } = useFieldArray({
        control,
        name: "conditionals"
    });

    const {
        request: createConditional,
        loading: creating,
        error: createError
    } = useApi("/conditionals", {
        method: "POST",
        schema: {}
    });

    const {
        request: updateConditional,
        loading: updating,
        error: updateError
    } = useApi("/conditionals/:id", {
        method: "PUT",
        schema: {}
    });

    const {
        request: deleteConditional,
        loading: deleting,
        error: deletingError
    } = useApi("/conditionals/:id", {
        method: "DELETE",
        schema: {}
    });

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const formValues: (IConditional | { id: number; destroy: boolean })[] = getValues().conditionals;
            await Promise.all(
                formValues
                    .concat(conditionalsToRemove.map((id: number) => ({ id, destroy: true })))
                    .map(async (conditional, index) => {
                        const shouldDestroy = conditional?.destroy ?? false;
                        if (shouldDestroy) {
                            await deleteConditional({
                                id: conditional.id
                            });
                            return;
                        }

                        if (!conditional.id) {
                            await createConditional({
                                body: {
                                    conditionable_type: conditionalType,
                                    conditionable_id: conditionalId,
                                    ...conditional
                                }
                            });
                            return;
                        }

                        await updateConditional({
                            id: conditional.id,
                            body: conditional
                        });
                    })
            );
            setConditionalForRemoval([]);
            await onFinish();
            // fetch whatever the conditions were attached to... :thinking:
            // I'm not sure how to handle possible errors like this though.... :thinking:
        } catch (error) {
            setLoading(false);
            // Handle errors
        }
    };

    const append = (condition: IConditionalParameter) => {
        fieldArray.append(condition);
    };

    const remove = (index: number) => {
        const possibleRemovedConditional = defaultValues.conditionals?.[index] ?? null;
        if (possibleRemovedConditional?.id) {
            // We only want to mark if for removal if it's a conditional that exists in the database/if the ID is set.
            setConditionalForRemoval(conditionalsToRemove.concat([Number(possibleRemovedConditional.id)]));
        }
        fieldArray.remove(index);
    };

    const restore = (index: number, existingConditions: IConditionableParameter) => {
        setConditionalForRemoval(conditionalsToRemove.filter((conditionId) => conditionId !== index));
        append(existingConditions.conditionals[index]);
    };

    return {
        loading: loading || updating || creating || deleting,
        setLoading,
        conditionalsToRemove,
        setConditionalForRemoval,
        handleSubmit,
        append,
        remove,
        fields,
        register,
        restore,
        errors: {
            createError: createError?.error ?? {},
            updateError: updateError?.error ?? {},
            deletingError: deletingError?.error ?? {}
        }
    };
}

export default useConditionals;
