import React from "react";
import WarningIcon from "../../../Shared/Icons/WarningIcon";
import CloseIcon from "../../../Shared/Icons/CloseIcon";
import GoogleIcon from "../../../Shared/Icons/GoogleIcon";
import MicrosoftIcon from "../../../Shared/Icons/MicrosoftIcon";
import { camelizeKeys } from "humps";
import moment from "moment";
import { statusIndicatorSteps } from "../../../../constants/DynamicCampaignConstants";
import InformationIcon from "../../../Shared/Icons/InformationIcon";
import InfoNotice from "./InfoNotice";

interface IProps {
    message: any;
    platform?: string;
    warning?: boolean;
    type?: string;
}

const Notice: React.FunctionComponent<IProps> = ({ message, platform = null, type = "error" }) => {
    let color;
    if (type === "warning") {
        color = "#f6993f";
    } else if (type === "error") {
        color = "#e3342f";
    } else {
        color = "#2f8fe3";
    }

    return (
        <div
            className={`border-l-4 border-r border-t border-b mt-6 p-4 flex items-center text-gray-800`}
            style={{ borderLeftColor: color }}
        >
            {type === "warning" && (
                <>
                    <React.Fragment>
                        <div className="flex-none w-12 text-center">
                            <WarningIcon className="w-6 h-6" style={{ color }} />
                        </div>
                        <div className="flex-none w-24 text-base font-medium">Warning</div>
                    </React.Fragment>
                    <div className="flex-grow text-base font-medium">{message}</div>
                </>
            )}

            {type === "error" && (
                <>
                    <React.Fragment>
                        <div className="flex-none w-12 text-center">
                            {!platform && <CloseIcon className="w-6 h-6" style={{ color }} />}
                            {platform === "Google" && <GoogleIcon style={{ color }} className="w-6 h-6" />}
                            {platform === "Microsoft" && <MicrosoftIcon className="w-6 h-6" style={{ color }} />}
                        </div>
                        <div className="flex-none w-24 text-base font-medium">Error</div>
                    </React.Fragment>
                    <div className="flex-grow text-base font-medium">{message}</div>
                </>
            )}

            {type === "info" && <InfoNotice message={message} color={color} />}
        </div>
    );
};

export default Notice;
