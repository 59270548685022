import React, { ChangeEvent, useState } from "react";
import * as Yup from "yup";
import { ConditionalOperators, getDcParameterFields } from "../../../../utils/InventoryUtils";
import { IConditional } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import InventoryFilter from "../InventoryFilter";
import Button, { SECONDARY_BUTTON, PRIMARY_BUTTON } from "../../../Shared/Button";
import TextField from "../../../Shared/Form/Blocks/TextField";
import AddIcon from "../../../Shared/Icons/AddIcon";
import SettingsIcon from "../../../Shared/Icons/SettingsIcon";

interface IBidModifierProps {
    fields: any;
    description: string;
    conditionals: IConditional[];
    setConditionals: (conditionals: IConditional[]) => void;
    setDescription: (value: string) => void;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
    descriptionError: string | null;
    modifierIndex: number;
    hide: any;
}

const displayErrors = (field: string, errors: any) => {
    const fieldErrors = errors.filter((error: any) => error.path === field);
    return fieldErrors.length > 0
        ? fieldErrors[0].errors.map((message: string, index: number) => (
              <div key={index} className="text-red-500 text-sm italic uppercase pb-4">
                  {message}
              </div>
          ))
        : null;
};

const conditionalFields = getDcParameterFields();

const ConditionalsSchema = Yup.object().shape({
    comparator: Yup.string()
        .oneOf(
            ConditionalOperators.map((c) => c.value),
            "Invalid Comparator!"
        )
        .max(256, "Too Long!")
        .required("Comparator Required"),
    value: Yup.string().max(256, "Too Long!").required("Value Required"),
    field: Yup.string().oneOf(conditionalFields, "Invalid Field!").required("Field Required")
});

const BidModifierConditionals: React.FunctionComponent<IBidModifierProps> = ({
    fields,
    description,
    conditionals,
    setConditionals,
    setDescription,
    setFieldTouched,
    descriptionError,
    modifierIndex,
    hide
}) => {
    const [conditions, setConditions] = useState<IConditional[]>(conditionals);

    const handleSetConditionals = (confirmedConditions: IConditional[], descriptionError: string | null) => {
        let allPass = true;
        confirmedConditions.forEach((condition: IConditional, index: number) => {
            if (condition.deleted) {
                return;
            }
            try {
                ConditionalsSchema.validateSync(condition, { abortEarly: false });
                updateCondition(index, { ...condition, errors: [] });
                delete condition.errors;
            } catch (error) {
                updateCondition(index, { ...condition, errors: [...error.inner] });
                allPass = false;
            }
        });
        if (allPass && !descriptionError) {
            setConditionals(confirmedConditions);
            hide();
        }
    };

    const addCondition = () => {
        const newCondition: IConditional = {
            field: "",
            comparator: "",
            value: "",
            new: true,
            dirty: true
        };
        setConditions((currentConditions: IConditional[]) => {
            return [...currentConditions, newCondition];
        });
    };

    const updateCondition = (
        index: number,
        { field, value, comparator, deleted = false, errors = [] }: IConditional
    ) => {
        setConditions((currentConditions: IConditional[]) => {
            const updatedCondition = {
                ...currentConditions[index],
                field,
                value,
                comparator,
                deleted,
                errors,
                dirty: true
            };
            return [...currentConditions.slice(0, index), updatedCondition, ...currentConditions.slice(index + 1)];
        });
    };

    const removeCondition = (index: number, condition: IConditional) => {
        const updated = {
            ...condition,
            deleted: true
        };
        updateCondition(index, updated);
    };

    return (
        <div className="h-100 w-100 bg-white z-51">
            <span className="text-2xl mt-2 text-blue-800">
                <SettingsIcon className="w-8 h-8 mb-1 text-gray-500 hover:text-gray-600 inline-block" /> Bidding
                Conditional Logic
            </span>

            <TextField
                autoFocus
                name={fields.name}
                label={"Description"}
                value={description !== null ? description : ""}
                handleBlur={() => setFieldTouched(fields.name)}
                handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDescription(e.target.value);
                }}
            />

            {descriptionError && <div className="w-full text-red-500 italic my-5">{descriptionError}</div>}

            {conditions.map((conditional: IConditional, index: number) => {
                const { errors = [] } = conditional;
                const fieldName = `adGroupBidModifier[${modifierIndex}][conditionals][${index}]`;
                return (
                    <React.Fragment key={index}>
                        <InventoryFilter
                            fields={conditionalFields}
                            condition={conditional}
                            onChange={(item: IConditional) => {
                                updateCondition(index, item);
                            }}
                            onRemove={() => {
                                removeCondition(index, conditional);
                            }}
                            setFieldTouched={() => {}}
                            names={{
                                comparator: `${fieldName}.comparator`,
                                field: `${fieldName}.field`,
                                value: `${fieldName}.value`
                            }}
                            errors={{}}
                        />
                        {displayErrors("field", errors)}
                        {displayErrors("comparator", errors)}
                        {displayErrors("value", errors)}
                    </React.Fragment>
                );
            })}

            <Button onClick={addCondition} type="button" styleType={SECONDARY_BUTTON} styles="my-4">
                <AddIcon className="w-6 h-6 inline-block" />
                Add Conditional
            </Button>

            <div className="flex flex-right items-center mb-2">
                <Button onClick={hide} type="button" styleType={SECONDARY_BUTTON}>
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSetConditionals(conditions, descriptionError)}
                    type="button"
                    styleType={PRIMARY_BUTTON}
                    styles="ml-2"
                >
                    Okay
                </Button>
            </div>
        </div>
    );
};

export default BidModifierConditionals;
