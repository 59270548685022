import { InsightUrlType } from "../ts/types";
import buildUrl from "../utils/UrlUtils";
export const LOAD_CLIENTS = "LOAD_CLIENTS";
export const FAILED_LOAD_CLIENTS = "FAILED_LOAD_CLIENTS";

export const LOAD_DELETED_CLIENTS = "LOAD_DELETED_CLIENTS";
export const FAILED_LOAD_DELETED_CLIENTS = "FAILED_LOAD_DELETED_CLIENTS";

export const LOAD_CLIENT_ROLES = "LOAD_CLIENT_ROLES";
export const REQUEST_LOAD_CLIENT_ROLES = "REQUEST_LOAD_CLIENT_ROLES";
export const FAILED_LOAD_CLIENT_ROLES = "FAILED_LOAD_CLIENT_ROLES";
export const REQUEST_RESTORE_CLIENT = "REQUEST_RESTORE_CLIENT";

export const REQUEST_LOAD_CLIENT = "REQUEST_LOAD_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const REQUEST_CREATE_CLIENT = "REQUEST_CREATE_CLIENT";
export const REQUEST_SAVE_CLIENT = "REQUEST_SAVE_CLIENT";
export const FAILED_SAVE_CLIENT = "FAILED_SAVE_CLIENT";
export const REFRESH_EXTERNAL_DATA = "REFRESH_EXTERNAL_DATA";
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
export const REQUEST_DELETE_CLIENT = "REQUEST_DELETE_CLIENT";
export const REQUEST_DELETE_PAGED_CLIENT = "REQUEST_DELETE_PAGED_CLIENT";
export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const FETCH_CLIENTS_NO_SALESFORCE = "FETCH_CLIENTS_NO_SALESFORCE";
export const REQUEST_PAGED_CLIENTS = "REQUEST_PAGED_CLIENTS";
export const LOAD_PAGED_CLIENTS = "LOAD_PAGED_CLIENTS";
export const REQUEST_PAGED_DELETED_CLIENTS = "REQUEST_PAGED_DELETED_CLIENTS";
export const FACEBOOK_LOW_SPENDS = "FACEBOOK_LOW_SPENDS";
export const FACEBOOK_REMARKETING_ERRORS = "FACEBOOK_REMARKETING_ERRORS";
export const NO_SALESFORCE_ID = "NO_SALESFORCE_ID";
export const DELETED = "DELETED";
export const HAS_INVENTORY_ISSUES = "HAS_INVENTORY_ISSUES";
export const NO_INSIGHTS = "NO_INSIGHTS";
export const CLIENT_BILLING = "CLIENT_BILLING";
export const BILLING = "BILLING";
export const FACEBOOK_PERMISSION = "FACEBOOK_PERMISSION";
export const GOOGLE_ANALYTICS_PERMISSION = "GOOGLE_ANALYTICS_PERMISSION";
export const GOOGLE_ADS_PERMISSION = "GOOGLE_ADS_PERMISSION";
export const MICROSOFT_PERMISSION = "MICROSOFT_PERMISSION";

export const TIME_ZONE = "America/Chicago";

export const PLATFORMS_ACTIVE_ID = "PlatformsActive";
export const GOOGLE_ADS_ID = "adwordsId";
export const MICROSOFT_ADS_ID = "bingId";
export const FACEBOOK_ADS_ID = "facebookAccountId";

export const RESET_CLIENT_STATE = "@@RESET/CLIENT";
export const DOWNLOAD_INVENTORY_CLICKED = "DOWNLOAD_INVENTORY_CLICKED";
export const DOWNLOAD_CSV_CLICKED = "DOWNLOAD_CSV_CLICKED";
export const CLIENT_SELECTOR_REFRESH_CLICKED = "CLIENT_SELECTOR_REFRESH_CLICKED";

const generateClientQueryParamsForInsight = (
    baseUrl: string,
    page: number,
    search: string,
    limit: number | null = null,
    extraExpands: { [key: string]: string } = {}
): string =>
    buildUrl(baseUrl, {
        page,
        limit,
        ...(search
            ? {
                  search: {
                      name: `like,*${search}*`
                  }
              }
            : {}),
        expand: {
            roles: "*",
            facebookAccount: "*",
            manager: "*",
            automobileManufacturers: "*",
            ...extraExpands
        }
    });
// todo: we need a base URL constructor
export const CLIENT_DROPDOWN_SEARCH_URL = (page: number, query: string, refresh: boolean, limit?: number): string =>
    buildUrl("/clients", {
        page,
        limit,
        query,
        refresh,
        expand: {
            roles: "*",
            facebookAccount: "*",
            manager: "*",
            automobileManufacturers: "*"
        }
    });

export const NO_INSIGHTS_URL: InsightUrlType = (page, query, limit = null) =>
    buildUrl("/clients", {
        page,
        limit,
        query,
        expand: {
            roles: "*",
            facebookAccount: "*",
            manager: "*",
            automobileManufacturers: "*"
        }
    });
export const HAS_INVENTORY_ISSUES_URL: InsightUrlType = (page, search, limit = null) =>
    generateClientQueryParamsForInsight("/insights/has-inventory-issues", page, search, limit);
export const NO_SALESFORCE_ID_URL: InsightUrlType = (page, search, limit = null) =>
    generateClientQueryParamsForInsight("/insights/no-salesforce-id", page, search, limit);

export const FACEBOOK_LOW_SPENDS_URL: InsightUrlType = (page, search, limit = null) =>
    generateClientQueryParamsForInsight("/insights/facebook-low-spends", page, search, limit);

export const FACEBOOK_REMARKETING_ERRORS_URL: InsightUrlType = (page, search, limit = null) =>
    generateClientQueryParamsForInsight("/insights/facebook-remarketing-errors", page, search, limit);

export const DELETED_URL: InsightUrlType = (page, search, limit = null) =>
    generateClientQueryParamsForInsight("/insights/deleted-clients", page, search, limit);

export const BILLING_URL: InsightUrlType = (page, search, limit = null) =>
    generateClientQueryParamsForInsight("/insights/billing-client", page, search, limit);

export const CLIENT_PAYING_URL: InsightUrlType = (page, search, limit = null) =>
    generateClientQueryParamsForInsight("/insights/client-paying", page, search, limit);

export const PERMISSION_URL =
    (type: string): InsightUrlType =>
    (page, search, limit = null) =>
        generateClientQueryParamsForInsight(`/insights/${type}-permission-issue`, page, search, limit);

export const NO_SEARCH = "NO_SEARCH";
export const NO_SORT = "NO_SORT";

/**
 * This is essentially a mapping of the fields in the database to the labels of the inputs.
 */
export const CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES: { [key: string]: string } = {
    name: "Name",
    dashboardApiKey: "DI Dashboard API Key",
    dashboardApiKeyUpdatedAt: "DI Dashboard API Key Updated At",
    carsSellerId: "Cars Seller ID",
    adwordsId: "Google Ads Client ID",
    adwordsName: "Google Ads Client Name",
    bingId: "Microsoft Ads Client ID",
    bingNumber: "Microsoft Ads Client Number",
    bingName: "Microsoft Ads Client Name",
    dfaAdvertiserId: "DFA Advertiser ID",
    timeZone: "Timezone",
    parentId: "Parent MCC",
    negativeKeywords: "Negative Keywords",
    diSite: "DI Site",
    domain: "Domain",
    inventoryApiKey: "DI Inventory API Key",
    inventoryFeedId: "DI Inventory Feed ID",
    gaAccountId: "Google Analytics Account ID",
    gaAccountName: "Google Analytics Account Name",
    gaProfileId: "Google Analytics Profile ID",
    gaProfileName: "Google Analytics Profile Name",
    dealerCode: "OEM Dealer Code",
    crmEmail: "CRM Emails (facebook leads)",
    facebookWebhooks: "Should receive Facebook webhooks",
    facebookPageId: "Facebook Page ID",
    facebookAccountId: "Facebook Account ID",
    oemProgram: "OEM Program",
    oemEndDate: "OEM Program End Date",
    oemGoLiveDate: "OEM Program Go Live Date",
    createdAt: "Created At",
    updatedAt: "Updated At",
    cancelAt: "Canceled At",
    deletedAt: "Deleted At",
    deletedByUserId: "Deleted By User ID",
    locationCode: "Location Code",
    salesforceId: "Salesforce ID",
    engineId: "Engine ID",
    engineIdUpdatedAt: "Engine ID Updated At",
    shortNameForDealer: "Short Name",
    managedByUserId: "Manager User ID",
    agencyId: "Agency ID",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip",
    country: "Country"
};
