import React from "react";
import {
    IDynamicCampaign,
    IExpandedTextAdTemplate,
    ISpecialOfferTemplateAd
} from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import SpecialOfferTemplateAd from "./SpecialOfferTemplateAd";
import { newEtaTemplateFromExistingOrBoilerplate } from "../../../../utils/DynamicCampaignUtils";
import { connect } from "react-redux";
import etaActions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import * as specialOfferTemplateActions from "../../../../actions/specialOfferTemplateActions";
import { Dispatch } from "redux";
import IAppState from "../../../../interfaces/IAppState";
import dynamicCampaignActions from "../../../../actions/dynamicCampaignActions";

interface IProps {
    values: ISpecialOfferTemplateAd[];
    etas: IExpandedTextAdTemplate[];
    setFieldValue(fieldName: string, value: any): void;
}

interface IPropsFromRedux {
    clientId: number;
    duplicateTemplate(form: string): void;
    queueSotDetach(clientId: number, sotId: number, dynamicCampaignId: number): void;
}

const SpecialOfferTemplateAds: React.FC<IProps & IPropsFromRedux> = ({
    values,
    setFieldValue,
    etas,
    duplicateTemplate,
    queueSotDetach,
    clientId
}) => {
    const handleDuplicate = (sotIndex: number) => {
        const original = values[sotIndex];
        const duplicatedSot = newEtaTemplateFromExistingOrBoilerplate(original, values.length);
        setFieldValue("expandedTextAdTemplates", [...etas, { ...duplicatedSot }]);
        duplicateTemplate("create");
    };
    return (
        <React.Fragment>
            {values &&
                values.map((sot: ISpecialOfferTemplateAd, idx) => {
                    return (
                        <SpecialOfferTemplateAd
                            key={sot.id}
                            values={sot}
                            idx={idx}
                            onDuplicate={(sotIndex) => {
                                handleDuplicate(sotIndex);
                            }}
                            detachSpecialOfferTemplateFromDynamicCampaign={() => {
                                queueSotDetach(
                                    clientId,
                                    sot.specialOfferTemplateId as number,
                                    sot.dynamicCampaignId as number
                                );
                                setFieldValue(
                                    `specialOfferTemplates`,
                                    values.filter((template, id) => id !== idx)
                                );
                            }}
                        />
                    );
                })}
        </React.Fragment>
    );
};

const mapStateToProps = (state: IAppState) => ({
    clientId: state.clients.currentClient
});

const mapDispatchToProps = (dispatch: Dispatch, props: IProps) => {
    return {
        duplicateTemplate: (form: string) => dispatch(etaActions.duplicateTemplate({ form })),
        queueSotDetach(clientId: number, sotId: number, dynamicCampaignId: number) {
            dispatch(dynamicCampaignActions.queueSotDetach(clientId, sotId, dynamicCampaignId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOfferTemplateAds);
