/**
 * Created by fjiles@dealerinspire.com on 3/3/17.
 */

import React from "react";
import { connect } from "react-redux";
import { clearAllFlashMessages } from "../actions/flashMessageActions";
import _ from "lodash";
import Message from "../utils/Message";
import { Dispatch } from "redux";
import IAppState from "../interfaces/IAppState";

const FlashMessage: React.FC<{ message: { type: string; text: string } }> = ({ message }) => {
    return (
        <Message
            error={["error", "danger"].includes(message.type)}
            success={["success"].includes(message.type)}
            message={message.text}
        />
    );
};
const FlashMessageList: React.FC<
    Partial<{
        messages: {
            type: string;
            text: string;
        }[];
        timeout: number | undefined;
        clearMessages: () => void;
    }>
> = ({ messages, clearMessages, timeout = 5000 }) => {
    if (messages?.length === 0) {
        return null;
    }
    const wait = _.debounce((clear) => {
        clear();
    }, timeout);
    wait(clearMessages);
    return (
        <div className={"mx-4 mt-4 -mb-4"}>
            {messages?.map((message, idx) => (
                <FlashMessage key={idx} message={message} />
            ))}
        </div>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        messages: state.flashMessages
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearMessages: () => {
        dispatch(clearAllFlashMessages());
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(FlashMessageList);
