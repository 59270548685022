import InventoryModifierConstants from "../constants/InventoryModifierConstants";
import IEntity from "../interfaces/IEntity";
import IInventoryModifier from "../interfaces/IInventoryModifier";

const requestInventoryModifiers = (clientId: number) => ({
    type: InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS,
    payload: { clientId }
});
const requestInventoryModifiersSuccess = (inventoryModifiers: IEntity<IInventoryModifier>) => ({
    type: InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS_SUCCESS,
    payload: inventoryModifiers
});
const requestInventoryModifiersFail = (error: any) => ({
    type: InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS_FAILURE,
    payload: { error }
});

const saveInventoryModifiers = (clientId: number, inventoryModifiers: IInventoryModifier[]) => ({
    type: InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS,
    payload: { clientId, inventoryModifiers }
});
const saveInventoryModifiersSuccess = (inventoryModifiers: IEntity<IInventoryModifier>) => ({
    type: InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS_SUCCESS,
    payload: { inventoryModifiers }
});
const saveInventoryModifiersFail = (errors: any, inventoryModifiers: IEntity<IInventoryModifier>) => ({
    type: InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS_FAILURE,
    payload: { errors, inventoryModifiers }
});

export {
    requestInventoryModifiers,
    requestInventoryModifiersSuccess,
    requestInventoryModifiersFail,
    saveInventoryModifiers,
    saveInventoryModifiersSuccess,
    saveInventoryModifiersFail
};
