import Constants from "../constants/NegativeKeywords";
import IFormValues from "../interfaces/DealerSetup/IFormValues";
import { IDynamicCampaignField } from "../interfaces/DealerSetup/IDynamicCampaign";
import { IGenericCampaignField } from "../interfaces/DealerSetup/IGenericCampaign";
import { IRemarketingFields } from "../interfaces/DealerSetup/IRemarketingFields";
import IClient from "../interfaces/IClient";
import IEntity from "../interfaces/IEntity";
import { INegativeKeywordColl } from "../interfaces/NegativeKeywordColl";
import { mazdaCampaignsThatShouldGetFuelMazdaNegativeKeywords } from "../components/Client/NegativeKeywords/NegativeKeywordCampaignsContainer";

export const handleApplyDefaultsForWizard = (
    formValues: IFormValues,
    savePage: (page: string, values: any) => void,
    client: IClient,
    negativeKeywordCollections: IEntity<INegativeKeywordColl>
) => {
    const findApplicableDefaults = (name: string, originalValue: number[], oem?: string): number[] => {
        // Original Logic for this was found here:
        // fuel-web/src/components/Client/NegativeKeywords/NegativeKeywordCampaignsContainer.tsx
        // Please update that file, with the applicable regex. - AK
        const nameMatch = (regex: RegExp, text: string) => {
            return text.search(regex) > -1;
        };

        const applicableManufacturerDefaults: number[] = [];

        if (nameMatch(Constants.NEW_COLLECTION_REGEX, name)) {
            applicableManufacturerDefaults.push(Constants.NEW_COLLECTION_ID);
        }
        if (nameMatch(Constants.USED_COLLECTION_REGEX, name)) {
            applicableManufacturerDefaults.push(Constants.USED_COLLECTION_ID);
        }
        if (nameMatch(Constants.SERVICE_COLLECTION_REGEX, name)) {
            applicableManufacturerDefaults.push(Constants.SERVICE_COLLECTION_ID);
        } else {
            // service campaigns do not get general everything else does
            applicableManufacturerDefaults.push(Constants.GENERAL_COLLECTION_ID);
        }

        return [
            ...new Set([
                ...originalValue
                    .concat(applicableManufacturerDefaults)
                    .concat(findApplicableOemDefaultsForCurrentClient(name))
            ])
        ];
    };

    const findApplicableOemDefaultsForCurrentClient = (campaignName: string): number[] => {
        if (!client.oemProgram) {
            return [];
        }

        if (["mazdaRtgmPlus", "mazdaca"].includes(client.oemProgram)) {
            if (mazdaCampaignsThatShouldGetFuelMazdaNegativeKeywords.includes(campaignName)) {
                const collectionsForMazda = Object.values(negativeKeywordCollections).filter(
                    (negativeKeywordCollection) => negativeKeywordCollection.name === "Fuel Mazda Negative Keywords"
                );

                return collectionsForMazda.map((collection) => collection.id);
            }

            return [];
        }

        const keywordsThatMatchTheOemProgram = Object.values(negativeKeywordCollections).filter((collection) =>
            collection.name.toLowerCase().includes(client.oemProgram)
        );

        return keywordsThatMatchTheOemProgram.map((collection) => collection.id);
    };

    if (!formValues.name.isDisabled && formValues.name.fields.name.isValid) {
        savePage("name", {
            ...formValues.name,
            fields: {
                ...formValues.name.fields,
                negative_keyword_lists: {
                    value: findApplicableDefaults(
                        "Brand - " + formValues.name.fields.name.value.name,
                        formValues.name.fields.negative_keyword_lists.value
                    )
                }
            }
        });
    }

    if (!formValues.dealer.isDisabled) {
        savePage("dealer", {
            ...formValues.dealer,
            fields: {
                ...formValues.dealer.fields,
                negative_keyword_lists: {
                    // The dealer campaign is suppose to get the OEM manufacturers lists if there are any specific ones.
                    // Those are resolved via regex in the `findApplicableDefaults` which just does a fuzzy match for the oem.
                    value: findApplicableDefaults(
                        client.automobileManufacturers.map((manufacturer) => manufacturer.name).join(" ") + " Campaign",
                        formValues.dealer.fields.negative_keyword_lists.value
                    )
                }
            }
        });
    }

    if (!formValues.locations.isDisabled) {
        savePage("locations", {
            ...formValues.locations,
            fields: {
                ...formValues.locations.fields,
                locations: {
                    ...formValues.locations.fields.locations,
                    value: {
                        ...formValues.locations.fields.locations.value,
                        negative_keyword_lists: findApplicableDefaults(
                            "Locations",
                            formValues.locations.fields.locations.value.negative_keyword_lists
                        )
                    }
                }
            }
        });
    }

    if (!formValues.competitors.isDisabled) {
        savePage("competitors", {
            ...formValues.competitors,
            fields: {
                ...formValues.competitors.fields,
                competitors: {
                    value: {
                        ...formValues.competitors.fields.competitors.value,
                        negative_keyword_lists: findApplicableDefaults(
                            "Competitors",
                            formValues.competitors.fields.competitors.value.negative_keyword_lists
                        )
                    }
                }
            }
        });
    }

    if (!formValues.dynamic.isDisabled) {
        formValues.dynamic.fields.campaigns.forEach((_, index) => {
            const campaigns: IDynamicCampaignField[] = formValues.dynamic.fields.campaigns;

            campaigns[index].value.negative_keyword_lists = findApplicableDefaults(
                campaigns[index].value.name,
                campaigns[index].value.negative_keyword_lists
            );

            savePage("dynamic", {
                ...formValues.dynamic,
                fields: {
                    ...formValues.dynamic.fields,
                    campaigns
                }
            });
        });
    }

    if (!formValues.services.isDisabled) {
        savePage("services", {
            ...formValues.services,
            fields: {
                ...formValues.services.fields,
                services: {
                    ...formValues.services.fields.services,
                    value: {
                        ...formValues.services.fields.services.value,
                        negative_keyword_lists: findApplicableDefaults(
                            "Services",
                            formValues.services.fields.services.value.negative_keyword_lists
                        )
                    }
                }
            }
        });
    }

    if (!formValues.generic.isDisabled) {
        formValues.generic.fields.campaigns.forEach((_, index) => {
            const campaigns: IGenericCampaignField[] = formValues.generic.fields.campaigns;

            campaigns[index].value.negative_keyword_lists = findApplicableDefaults(
                campaigns[index].value.name,
                campaigns[index].value.negative_keyword_lists
            );

            savePage("generic", {
                ...formValues.generic,
                fields: {
                    ...formValues.generic.fields,
                    campaigns
                }
            });
        });
    }

    if (!formValues.conquest.isDisabled) {
        savePage("conquest", {
            ...formValues.conquest,
            fields: {
                ...formValues.conquest.fields,
                conquest: {
                    ...formValues.conquest.fields.conquest,
                    value: {
                        ...formValues.conquest.fields.conquest.value,
                        negative_keyword_lists: findApplicableDefaults(
                            "Conquests",
                            formValues.conquest.fields.conquest.value.negative_keyword_lists
                        )
                    }
                }
            }
        });
    }

    if (!formValues.remarketing.isDisabled) {
        formValues.remarketing.fields.forEach((_, index) => {
            const fields: IRemarketingFields[] = formValues.remarketing.fields;

            fields[index].negative_keyword_lists = {
                value: findApplicableDefaults(fields[index].name.value, fields[index].negative_keyword_lists.value)
            };

            savePage("remarketing", {
                ...formValues.remarketing,
                fields
            });
        });
    }
};
