import React from "react";
import {
    DELETED,
    NO_INSIGHTS,
    NO_SALESFORCE_ID,
    FACEBOOK_LOW_SPENDS,
    FACEBOOK_REMARKETING_ERRORS,
    CLIENT_BILLING,
    BILLING,
    PLATFORMS_ACTIVE_ID,
    DELETED_URL,
    NO_SALESFORCE_ID_URL,
    FACEBOOK_LOW_SPENDS_URL,
    BILLING_URL,
    CLIENT_PAYING_URL,
    NO_INSIGHTS_URL,
    FACEBOOK_PERMISSION,
    PERMISSION_URL,
    GOOGLE_ANALYTICS_PERMISSION,
    GOOGLE_ADS_PERMISSION,
    MICROSOFT_PERMISSION,
    FACEBOOK_REMARKETING_ERRORS_URL,
    HAS_INVENTORY_ISSUES,
    HAS_INVENTORY_ISSUES_URL
} from "../../../../constants/ClientConstants";
import ClientActionCell from "./ClientActionCell";
import ClientDeletedActionCell from "./ClientDeletedActionCell";
import ClientPlatformsCell from "./ClientPlatformsCell";
import ClientFacebookLowSpendsActionCell from "./ClientFacebookLowSpendsActionCell";
import ClientRemarketingExportErrorActionCell from "./ClientRemarketingExportErrorActionCell";
import CreditCardIcon from "../../../Shared/Icons/CreditCardIcon";
import DealerInspireIcon from "../../../Shared/Icons/DealerInspireIcon";
import IClient from "../../../../interfaces/IClient";
import IColumn from "../../../../interfaces/IColumn";
import ISortData from "../../../../interfaces/ISortData";
import moment from "moment";
import { InsightUrlType } from "../../../../ts/types";
import ClientPermissionCell from "./ClientPermissionCell";
import ClientInventoryActionCell from "./ClientInventoryActionCell";

export type Insight =
    | typeof NO_SALESFORCE_ID
    | typeof DELETED
    | typeof NO_INSIGHTS
    | typeof FACEBOOK_LOW_SPENDS
    | typeof FACEBOOK_REMARKETING_ERRORS
    | typeof CLIENT_BILLING
    | typeof BILLING
    | typeof FACEBOOK_PERMISSION
    | typeof MICROSOFT_PERMISSION
    | typeof GOOGLE_ADS_PERMISSION
    | typeof GOOGLE_ANALYTICS_PERMISSION
    | typeof HAS_INVENTORY_ISSUES;

function getCell(props: any, insight?: string) {
    if (insight === DELETED) {
        return <ClientDeletedActionCell {...props} />;
    }

    if (insight === FACEBOOK_LOW_SPENDS) {
        return <ClientFacebookLowSpendsActionCell {...props} />;
    }

    if (insight === HAS_INVENTORY_ISSUES) {
        return <ClientInventoryActionCell {...props} />;
    }

    if (insight === FACEBOOK_REMARKETING_ERRORS) {
        return <ClientRemarketingExportErrorActionCell {...props} />;
    }

    if (
        insight &&
        [FACEBOOK_PERMISSION, MICROSOFT_PERMISSION, GOOGLE_ADS_PERMISSION, GOOGLE_ANALYTICS_PERMISSION].includes(
            insight
        )
    ) {
        return <ClientPermissionCell {...props} />;
    }

    return <ClientActionCell {...props} />;
}

export const buildActionColumn = (insight?: string): IColumn => {
    return {
        Cell: (props: any) => getCell(props, insight),
        accessor: "",
        Header: "Actions",
        id: "ActionColumn",
        filterable: false,
        sortable: false,
        maxWidth: 145
    };
};

export const buildClientNameColumn = (): any => {
    return {
        Header: "Client Name",
        accessor: "name",
        id: "name"
    };
};

export const buildRemarketingExportColumn = (): any => {
    return {
        Header: "Facebook Remarketing Export Name",
        accessor: "remarketingExportName",
        id: "RemarketingExportName"
    };
};

export const buildSalesforceIdColumn = (className?: string): IColumn => {
    return {
        Header: "Salesforce ID",
        accessor: "salesforceId",
        id: "SalesforceId",
        headerClassName: className ? className : ""
    };
};

export const buildPlatformsActiveColumn = () => {
    return {
        Cell: ClientPlatformsCell,
        id: PLATFORMS_ACTIVE_ID,
        Header: "Platforms Active",
        accessor: ({ adwordsId, bingId, facebookAccountId }: IClient): ISortData => ({
            adwordsId: Boolean(adwordsId),
            bingId: Boolean(bingId),
            facebookAccountId: Boolean(facebookAccountId)
        })
    };
};

export const buildDealerIdColumn = (): IColumn => {
    return {
        id: "DealerId",
        accessor: "dealerCode",
        Header: "Dealer ID"
    };
};

export const buildOEMColumn = (): IColumn => {
    return {
        id: "OEM",
        accessor: "oemProgram",
        Header: "OEM"
    };
};

export const buildAccountManagerColumn = (): IColumn => {
    return {
        id: "AccountManager",
        accessor: "managedByUser",
        Header: "Account Manager"
    };
};
export const buildClientBillingFacebookBillingColumn = (): IColumn => ({
    id: "facebookAdsInvoiced",
    accessor: "facebookAdsInvoiced",
    Header: "Facebook Billing",
    Cell({ original }: { original: IClient }) {
        return (
            <div>
                {original.facebookAdsInvoicedCheckedAt && (
                    <div
                        className="flex items-center"
                        title={"Last updated: " + moment(original.facebookAdsInvoicedCheckedAt).format("lll")}
                    >
                        {original.facebookAdsInvoiced ? (
                            <DealerInspireIcon className="w-6 h-6" />
                        ) : (
                            <CreditCardIcon className="w-6 h-6 text-red-500" />
                        )}
                    </div>
                )}
                {!original.facebookAdsInvoicedCheckedAt && <div className="text-gray-600 text-xs">not checked yet</div>}
            </div>
        );
    }
});
export const buildClientBillingGoogleBillingColumn = (): IColumn => ({
    id: "googledsInvoiced",
    accessor: "googleAdsInvoiced",
    Header: "Google Billing",
    Cell({ original }: { original: IClient }) {
        return (
            <div>
                {original.googleAdsInvoicedCheckedAt && (
                    <div
                        className="flex items-center"
                        title={"Last updated: " + moment(original.googleAdsInvoicedCheckedAt).format("lll")}
                    >
                        {original.googleAdsInvoiced ? (
                            <DealerInspireIcon className="w-6 h-6" />
                        ) : (
                            <CreditCardIcon className="w-6 h-6 text-red-500" />
                        )}
                    </div>
                )}
                {!original.googleAdsInvoicedCheckedAt && <div className="text-gray-600 text-xs">not checked yet</div>}
            </div>
        );
    }
});

export const buildClientBillingMicrosoftBillingColumn = (): IColumn => ({
    id: "microsoftAdsInvoiced",
    accessor: "microsoftAdsInvoiced",
    Header: "Microsoft Billing",
    Cell({ original }: { original: IClient }) {
        return (
            <div>
                {original.microsoftAdsInvoicedCheckedAt && (
                    <div
                        className="flex items-center"
                        title={"Last updated: " + moment(original.microsoftAdsInvoicedCheckedAt).format("lll")}
                    >
                        {original.microsoftAdsInvoiced ? (
                            <DealerInspireIcon className="w-6 h-6" />
                        ) : (
                            <CreditCardIcon className="w-6 h-6 text-red-500" />
                        )}
                    </div>
                )}
                {!original.microsoftAdsInvoicedCheckedAt && (
                    <div className="text-gray-600 text-xs">not checked yet</div>
                )}
            </div>
        );
    }
});

const buildInventoryColumns = (): IColumn[] => [
    {
        Header: "Inventory API Key",
        accessor: "inventoryApiKey",
        id: "inventoryApiKey"
    },
    {
        Header: "Inventory Feed ID",
        accessor: "inventoryFeedId",
        id: "inventoryFeedId"
    }
];

const defaultClientColumns = (type?: string) => [
    buildActionColumn(type),
    buildClientNameColumn(),
    buildPlatformsActiveColumn(),
    buildDealerIdColumn(),
    buildOEMColumn(),
    buildAccountManagerColumn(),
    buildSalesforceIdColumn()
];

export const clientInsights = {
    [NO_INSIGHTS]: {
        title: "All",
        columns: defaultClientColumns()
    },
    [DELETED]: {
        title: "Deleted",
        columns: defaultClientColumns(DELETED)
    },
    [HAS_INVENTORY_ISSUES]: {
        title: "Has Inventory Issue(s)",
        columns: [
            buildActionColumn(HAS_INVENTORY_ISSUES),
            buildClientNameColumn(),
            buildDealerIdColumn(),
            buildOEMColumn(),
            ...buildInventoryColumns(),
            buildSalesforceIdColumn("bg-red")
        ]
    },
    [NO_SALESFORCE_ID]: {
        title: "No Salesforce ID",
        columns: [
            buildActionColumn(),
            buildClientNameColumn(),
            buildPlatformsActiveColumn(),
            buildDealerIdColumn(),
            buildOEMColumn(),
            buildAccountManagerColumn(),
            buildSalesforceIdColumn("bg-red")
        ]
    },
    [FACEBOOK_LOW_SPENDS]: {
        title: "Facebook Low Spends",
        columns: defaultClientColumns(FACEBOOK_LOW_SPENDS)
    },
    [FACEBOOK_REMARKETING_ERRORS]: {
        title: "Facebook Remarketing Export Zero Pushes",
        columns: [
            buildActionColumn(FACEBOOK_REMARKETING_ERRORS),
            buildClientNameColumn(),
            buildRemarketingExportColumn()
        ],
        help: (
            <>
                <div className="p-1">
                    <span>For more info, see&nbsp;</span>
                    <a
                        target="_blank"
                        style={{ color: "blue" }}
                        href="https://carsenterprise.atlassian.net/wiki/spaces/FUEL/pages/3476553771/Facebook+Remarketing+Export+Zero+Pushes"
                    >
                        Confluence
                    </a>
                </div>
            </>
        )
    },
    [BILLING]: {
        title: "Billing",
        columns: [
            buildActionColumn(),
            buildClientNameColumn(),
            buildClientBillingFacebookBillingColumn(),
            buildClientBillingGoogleBillingColumn(),
            buildClientBillingMicrosoftBillingColumn()
        ]
    },
    [CLIENT_BILLING]: {
        title: "Client Billing",
        columns: [
            buildActionColumn(),
            buildClientNameColumn(),
            buildClientBillingFacebookBillingColumn(),
            buildClientBillingGoogleBillingColumn(),
            buildClientBillingMicrosoftBillingColumn()
        ]
    },
    [FACEBOOK_PERMISSION]: {
        title: "No Facebook Permissions",
        columns: defaultClientColumns(FACEBOOK_PERMISSION)
    },
    [GOOGLE_ANALYTICS_PERMISSION]: {
        title: "No GA Permissions",
        columns: defaultClientColumns(GOOGLE_ANALYTICS_PERMISSION)
    },
    [GOOGLE_ADS_PERMISSION]: {
        title: "No Google Ads Permissions",
        columns: defaultClientColumns(GOOGLE_ADS_PERMISSION)
    },
    [MICROSOFT_PERMISSION]: {
        title: "No Microsoft Ads Permissions",
        columns: defaultClientColumns(MICROSOFT_PERMISSION)
    }
};

export const getClientInsightUrl = (insight: Insight): InsightUrlType => {
    switch (insight) {
        case DELETED:
            return DELETED_URL;
        case NO_SALESFORCE_ID:
            return NO_SALESFORCE_ID_URL;
        case FACEBOOK_LOW_SPENDS:
            return FACEBOOK_LOW_SPENDS_URL;
        case FACEBOOK_REMARKETING_ERRORS:
            return FACEBOOK_REMARKETING_ERRORS_URL;
        case BILLING:
            return BILLING_URL;
        case CLIENT_BILLING:
            return CLIENT_PAYING_URL;
        case FACEBOOK_PERMISSION:
            return PERMISSION_URL("facebook");
        case MICROSOFT_PERMISSION:
            return PERMISSION_URL("microsoft");
        case GOOGLE_ADS_PERMISSION:
            return PERMISSION_URL("adwords");
        case GOOGLE_ANALYTICS_PERMISSION:
            return PERMISSION_URL("ga");

        case HAS_INVENTORY_ISSUES:
            return HAS_INVENTORY_ISSUES_URL;

        case NO_INSIGHTS:
        default:
            return NO_INSIGHTS_URL;
    }
};
