import React, { SVGProps } from "react";
import Svg from "./Svg";

const SaveIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M18.517 3H5.218A2.217 2.217 0 0 0 3 5.218v15.517c0 1.22.987 2.218 2.218 2.218h15.517c1.22 0 2.218-.997 2.218-2.218V7.436L18.517 3zm-5.543 17.73a3.321 3.321 0 0 1-3.325-3.325 3.321 3.321 0 0 1 3.325-3.324 3.321 3.321 0 0 1 3.325 3.324 3.321 3.321 0 0 1-3.325 3.325zm3.325-11.08H5.219V5.217h11.08V9.65z" />
    </Svg>
);

export default SaveIcon;
