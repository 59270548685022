import { transformKeywordTemplateErrors, wrapper } from "../../../../../utils/DynamicCampaignUtils";
import { call } from "redux-saga/effects";
import { callApi } from "../../../../../middleware/api";

export function* createTemplate({ keywords }: { keywords: string[] }): any {
    const [templateErrors, savedKeywordTemplate] = yield wrapper(
        call(callApi, `/keyword-templates`, {}, "POST", { keywords })
    );
    return [transformKeywordTemplateErrors(templateErrors), savedKeywordTemplate];
}

export function* updateTemplate({
    keywordTemplateId,
    keywords
}: {
    keywordTemplateId: number;
    keywords: string[];
}): any {
    const [templateErrors, savedKeywordTemplate] = yield wrapper(
        call(callApi, `/keyword-templates/${keywordTemplateId}`, {}, "PATCH", { keywords })
    );
    return [transformKeywordTemplateErrors(templateErrors), savedKeywordTemplate];
}

export function* deleteTemplate({ keywordTemplateId }: { keywordTemplateId: number }): any {
    const [templateErrors, savedKeywordTemplate] = yield wrapper(
        call(callApi, `/keyword-templates/${keywordTemplateId}`, {}, "DELETE")
    );
    return [transformKeywordTemplateErrors(templateErrors), savedKeywordTemplate];
}
