import * as Yup from "yup";
import filterDeleted from "../../../utils/filterDeleted";
import arrayHasUniqueValues from "../../../utils/arrayHasUniqueValues";
import ts from "typescript/lib/tsserverlibrary";

const ConditionSchema = Yup.object().shape({
    field: Yup.string().when("deleted", { is: (val) => val !== true, then: Yup.string().required("Required") }),
    comparator: Yup.string().when("deleted", { is: (val) => val !== true, then: Yup.string().required("Required") }),
    value: Yup.string().when("deleted", { is: (val) => val !== true, then: Yup.string().required("Required") })
});

const AdGroupBidModifierSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .when("deleted", {
            is: (val) => val !== true,
            then: Yup.string().max(100, "The description may not be greater than 100 characters.").nullable()
        }),
    amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("deleted", {
            is: (val) => val !== true,
            then: Yup.number()
                .typeError("The amount must be a number.")
                .required("The amount is required.")
                .min(0, "The amount must be at least 0.")
                .max(100, "The amount may not be greater than 100.")
                .nullable()
        })
});

const KeywordBidModifiersSchema = Yup.object().shape({
    modifier: Yup.string().when("deleted", { is: (val) => val !== true, then: Yup.string().required("Required") }),
    value: Yup.string()
        .nullable()
        .when("deleted", {
            is: (val) => val !== true,
            then: Yup.string().max(256, "The value may not be greater than 256 characters.")
        }),
    amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("deleted", {
            is: (val) => val !== true,
            then: Yup.number()
                .typeError("The amount must be a number.")
                .required("The amount is required.")
                .min(0, "The amount must be at least 0.")
                .max(100, "The amount may not be greater than 100.")
        })
});

const KeywordTemplateSchema = Yup.object().shape({
    keywordTemplate: Yup.object().when("deleted", {
        is: (val) => val !== true,
        then: Yup.object().shape({
            keywords: Yup.array()
                .test("keywords", "Required", (value): boolean => {
                    if (!value) return false;
                    return value?.filter(Boolean).length > 0;
                })
                .test(
                    "keywords",
                    "Each line should be unique.",
                    arrayHasUniqueValues as Yup.TestFunction<unknown[] | null | undefined, object>
                )
                .required("Required")
        })
    })
});

const TemplatePartSchema = Yup.object().shape({
    value: Yup.string().when("deleted", { is: (val) => val !== true, then: Yup.string().required("Required") })
});

const ExpandedTextAdTemplateSchema = Yup.object().shape({
    parts: Yup.array().of(TemplatePartSchema)
});

const SpecialOfferTemplateSchema = Yup.object().shape({
    parts: Yup.array().of(TemplatePartSchema)
});

export const Schema = Yup.object().shape({
    adwordsCampaignId: Yup.string().nullable(),
    microsoftCampaignId: Yup.string().nullable(),
    name: Yup.string().required("Name is required"),
    inventoryFilters: Yup.array().of(ConditionSchema),
    expandedTextAdTemplates: Yup.array()
        .of(ExpandedTextAdTemplateSchema)
        .when("specialOfferTemplates", (sot: any, schema: any) => {
            return sot === undefined || sot.length <= 0 ? schema.min(1) : schema.min(0);
        }),
    specialOfferTemplates: Yup.array().of(SpecialOfferTemplateSchema).min(0),
    maxCpc: Yup.number().required().positive().moreThan(0, "Default CPC must be GREATER THAN 0"),
    inventoryGroupFields: Yup.array().of(Yup.string()).min(1).required().label("Fields"),
    inventoryGroupCount: Yup.number().required().min(0).label("Value"),
    inventoryGroupComparator: Yup.string().required(),
    adGroupBidModifiers: Yup.array().of(AdGroupBidModifierSchema),
    keywordBidModifiers: Yup.lazy((value: any) => {
        if (value !== undefined && value.length > 0 && filterDeleted(value).length) {
            return Yup.array().of(KeywordBidModifiersSchema);
        }
        return Yup.mixed().notRequired();
    }),
    keywordTemplateRelationships: Yup.lazy((value: any) => {
        if (value !== undefined && value.length > 0 && filterDeleted(value).length) {
            return Yup.array().of(KeywordTemplateSchema);
        }
        return Yup.mixed().notRequired();
    })
});
