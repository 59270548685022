export default {
    REQUEST_BUDGETS_FAIL: "REQUEST_BUDGETS_FAIL",
    REQUEST_LOAD_BUDGETS: "REQUEST_LOAD_BUDGETS",
    LOAD_BUDGETS: "LOAD_BUDGETS",
    REQUEST_LOAD_BUDGET_SUCCESS: "REQUEST_LOAD_BUDGET_SUCCESS",

    REQUEST_CLIENT_ADWORDS_BUDGETS: "REQUEST_CLIENT_ADWORDS_BUDGETS",

    REQUEST_REACTIVATE_BUDGET_SUCCESS: "REQUEST_REACTIVATE_BUDGET_SUCCESS",
    REQUEST_REACTIVATE_BUDGET_FAIL: "REQUEST_REACTIVATE_BUDGET_FAIL",

    REQUEST_EXECUTE_BUDGET_SUCCESS: "REQUEST_EXECUTE_BUDGET_SUCCESS",
    REQUEST_EXECUTE_BUDGET_FAIL: "REQUEST_EXECUTE_BUDGET_FAIL",

    REQUEST_DELETE_BUDGET: "REQUEST_DELETE_BUDGET",
    REQUEST_DELETE_BUDGET_SUCCESS: "REQUEST_DELETE_BUDGET_SUCCESS",
    REQUEST_DELETE_BUDGET_FAIL: "REQUEST_DELETE_BUDGET_FAIL",

    REQUEST_DELETE_BUDGET_CONFIRM: "REQUEST_DELETE_BUDGET_CONFIRM",
    REQUEST_DELETE_BUDGET_CANCEL: "REQUEST_DELETE_BUDGET_CANCEL",

    REQUEST_PUSH_BUDGET: "REQUEST_PUSH_BUDGET",
    REQUEST_PUSH_BUDGET_FAIL: "REQUEST_PUSH_BUDGET_FAIL",

    SHOW_DELETE_CONFIRM: "SHOW_DELETE_CONFIRM",
    HIDE_DELETE_CONFIRM: "HIDE_DELETE_CONFIRM",

    HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
    SHOW_NOTIFICATION: "SHOW_NOTIFICATION",

    REQUEST_BUDGET_SPINNER: "REQUEST_BUDGET_SPINNER",
    REQUEST_DELETED_BUDGETS: "REQUEST_DELETED_BUDGETS",
    REQUEST_DELETED_BUDGETS_SUCCESS: "REQUEST_DELETED_BUDGETS_SUCCESS",
    REMOVE_DELETED_BUDGETS: "REMOVE_DELETED_BUDGETS",
    REMOVED_DELETED_BUDGETS: "REMOVED_DELETED_BUDGETS",
    LOAD_BUDGETS_STREAM: "LOAD_BUDGETS_STREAM",
    LOAD_BUDGETS_STREAM_SUCCESS: "LOAD_BUDGETS_STREAM_SUCCESS",
    LOAD_BUDGETS_STREAM_END: "LOAD_BUDGETS_STREAM_END",

    REQUEST_CREATE_BUDGET: "REQUEST_CREATE_BUDGET",
    REQUEST_CREATE_BUDGET_SUCCESS: "REQUEST_CREATE_BUDGET_SUCCESS",
    REQUEST_CREATE_BUDGET_FAIL: "REQUEST_CREATE_BUDGET_FAIL",
    REQUEST_BUDGET_SUCCESS: "REQUEST_BUDGET_SUCCESS",

    REQUEST_UPDATE_BUDGET: "REQUEST_UPDATE_BUDGET",

    LOADING_BUDGET: "LOADING_BUDGET",
    REQUEST_SINGLE_CLIENT_BUDGET: "REQUEST_SINGLE_CLIENT_BUDGET",

    REQUEST_BUDGET_LOGS: "REQUEST_BUDGET_LOGS",
    REQUEST_BUDGET_LOGS_SUCCESS: "REQUEST_BUDGET_LOGS_SUCCESS",
    REQUEST_BUDGET_LOGS_FAIL: "REQUEST_BUDGET_LOGS_FAIL",

    REQUEST_SYSTEM_LOGS: "REQUEST_SYSTEM_LOGS",
    REQUEST_SYSTEM_LOGS_SUCCESS: "REQUEST_SYSTEM_LOGS_SUCCESS",
    REQUEST_SYSTEM_LOGS_FAIL: "REQUEST_SYSTEM_LOGS_FAIL",

    REQUEST_BUDGET_DETAILS: "REQUEST_BUDGET_DETAILS",
    REQUEST_BUDGET_DETAILS_SUCCESS: "REQUEST_BUDGET_DETAILS_SUCCESS",
    REQUEST_BUDGET_DETAILS_FAIL: "REQUEST_BUDGET_DETAILS_FAIL",
    DOWNLOAD_CSV_CLICKED: "DOWNLOAD_CSV_CLICKED",

    SYSTEM_LOG: "SYSTEM_LOG",
    CHANGE_LOG: "CHANGE_LOG",

    TYPE_ID_MONTHLY: 1,
    TYPE_ID_EVENT: 2,

    BUDGET_SERVICE_ID_ADWORDS: 1,
    BUDGET_SERVICE_ID_MICROSOFT: 2
};
