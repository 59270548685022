import React, { useState, useEffect } from "react";
import DateRange from "./DateRange";
import GoogleMap from "./GoogleMap";
import FiltersContainer from "./Filtering/FiltersContainer";
import DetailContainer from "./DetailContainer";
import IClient from "../../interfaces/IClient";
import Button, { PRIMARY_BUTTON, SECONDARY_BUTTON, DANGER_BUTTON } from "../Shared/Button";
import RefreshIcon from "../Shared/Icons/RefreshIcon";
import ModalToggle from "../Shared/Dialogs/ModalToggle";
import PageTitle from "../Shared/PageTitle/PageTitle";
import IEntity from "../../interfaces/IEntity";
import ISalesMapStatistic from "../../interfaces/ISalesMapStatistic";
import IGoogleCampaign from "../../interfaces/IGoogleCampaign";
import IAdjustment from "../../interfaces/IAdjustment";
import { Range } from "react-date-range";
import moment from "moment";
import defaultRanges from "../SalesMap/DefaultDateRanges";
import { connect } from "react-redux";
import { pullDiSalesDataClicked } from "../../actions/salesData";

interface IProps {
    clientId: number;
    client: IClient;
    currentClient: IClient | null;
    dateRange: Range;
    salesLoading: boolean;
    adjustmentsLoading: boolean;
    mapData: { sales: number; zip: number; lat: number; lng: number }[];
    mapCenter?: { lat: number; lng: number };
    mapZoom: number;
    bidAdjustments: IAdjustment[];
    locationStats: IEntity<ISalesMapStatistic>;
    campaigns: IEntity<IGoogleCampaign>;
    isReadOnly: boolean;
    pullDiSalesData(clientId: number): void;
    purgeSalesData(clientId: number): void;
    fetchFilterOptions(clientId: number): void;
    refreshMap(clientId: number): void;
    updateDateRange(dateRange: Range, clientId: number): void;
    updateLocationStats(clientId: number, campaignId: number | string, dateRange?: Range): void;
    updateMapBounds(center: { lat: unknown; lng: unknown }, zoom: number): void;
    campaignChanged(clientId: number, campaign: IGoogleCampaign): void;
    trackPullDiSalesData: (clientId: number, client: IClient) => void;
}

const SalesMapContainer: React.FC<IProps> = ({
    clientId,
    client,
    currentClient,
    dateRange,
    salesLoading,
    adjustmentsLoading,
    mapData,
    mapCenter,
    mapZoom,
    bidAdjustments,
    locationStats,
    campaigns,
    isReadOnly,
    pullDiSalesData,
    purgeSalesData,
    fetchFilterOptions,
    refreshMap,
    updateDateRange,
    updateLocationStats,
    updateMapBounds,
    campaignChanged,
    trackPullDiSalesData
}) => {
    const [campaignId, setCampaignId] = useState<string | undefined>("");
    const [selectedAdjustmentId, setSelectedAdjustmentId] = useState<number | null>(null);
    const [pullingDiSalesData, setPullingDiSalesData] = useState(false);

    let loadDiSalesButton = null;
    let loadDiSalesMessage = null;

    useEffect(() => {
        fetchFilterOptions(clientId);
        onFilterChanged();

        if (dateRange === undefined || Object.keys(dateRange).length === 0) {
            dateRangeChanged({
                startDate: defaultRanges["Last Year"].startDate(moment()),
                endDate: defaultRanges["Last Year"].endDate(moment())
            });
        }
    }, []);

    const onFilterChanged = () => {
        refreshMap(clientId);
    };

    const localPullDiSalesData = () => {
        trackPullDiSalesData(clientId, client);
        setPullingDiSalesData(true);
        pullDiSalesData(clientId);
    };

    const dateRangeChanged = (newRange: Range) => {
        updateDateRange(newRange, clientId);
        if (campaignId) {
            updateLocationStats(clientId, campaignId, newRange);
        }
    };

    const localPurgeSalesData = () => {
        purgeSalesData(clientId);
    };

    const onAdjustmentSelected = (id: number) => {
        setSelectedAdjustmentId(id);
    };

    const onCampaignChanged = (campaign: IGoogleCampaign) => {
        const localCampaignId = campaign ? campaign.id : "";
        setCampaignId(localCampaignId);
        if (localCampaignId !== null) {
            campaignChanged(clientId, campaign);
            updateLocationStats(clientId, localCampaignId);
        }
    };

    if (!clientId) {
        return (
            <div className="py-2 px-4 text-red-600 bg-red-100 border border-red-600 rounded m-4">
                Please select a client
            </div>
        );
    }

    if (currentClient && currentClient.dashboardApiKey && !isReadOnly) {
        loadDiSalesButton = (
            <Button
                onClick={localPullDiSalesData}
                disabled={pullingDiSalesData}
                styleType={PRIMARY_BUTTON}
                styles={"text-sm"}
                style={{ marginRight: 0, marginLeft: 0 }}
            >
                <RefreshIcon className="w-6 h-6 inline align-middle" /> Load DI Dashboard Data
            </Button>
        );
    }

    if (pullingDiSalesData) {
        loadDiSalesMessage = (
            <div className="py-2 mt-6 px-4 text-green-600 bg-green-100 border border-green-600 rounded mt-6 mb-0 ">
                Reloading DI Dashboard data. This may take some time.
            </div>
        );
    }

    if (!mapCenter) {
        return (
            <div className="mx-4">
                {loadDiSalesMessage}
                <div className="py-2 mt-6 px-4 text-yellow-600 bg-yellow-100 border border-yellow-600 rounded">
                    <div className="font-bold">No Sales Data to display for this client.</div>

                    <div>
                        If you <span className="italic">just</span> uploaded data, please know that FUEL puts these
                        uploads into a Queue. It may take several minutes for the data to be processed.
                    </div>
                </div>
                {loadDiSalesButton}
            </div>
        );
    }

    return (
        <div className="px-4">
            <PageTitle title="Sales Map">
                <div className="flex flex-wrap items-center justify-between">
                    {loadDiSalesMessage}
                    {loadDiSalesButton}
                </div>
            </PageTitle>
            <div className="flex flex-wrap justify-between bg-white p-4 rounded">
                <div className="flex-grow relative rounded overflow-hidden">
                    <GoogleMap
                        mapHeight="600px"
                        loading={salesLoading || adjustmentsLoading}
                        bidAdjustments={bidAdjustments}
                        campaignId={campaignId}
                        mapCenter={mapCenter}
                        mapData={mapData}
                        mapZoom={mapZoom}
                        updateMapBounds={updateMapBounds}
                        locationStats={locationStats}
                        selectedAdjustment={selectedAdjustmentId}
                    />
                </div>
                <div className="w-1/5 flex flex-col">
                    <div className="rounded mb-4 bg-gray-100 text-gray-700 shadow-sm p-4 ml-4">
                        <DateRange dateRange={dateRange} updateMap={dateRangeChanged} />

                        <FiltersContainer filterSalesData={onFilterChanged} />
                    </div>
                    {!isReadOnly ? (
                        <ModalToggle
                            style={{ width: "20%" }}
                            className="w-1/4"
                            toggleButton={(open: () => void) => (
                                <Button styleType={DANGER_BUTTON} onClick={open} styles="text-sm ml-4">
                                    Purge Sales Data
                                </Button>
                            )}
                            modalContent={(hide: () => void) => (
                                <div>
                                    <div className="text-xl">Are you sure you want to purge the sales data?</div>
                                    <p className="py-6">
                                        This will completely remove all the sales data for this client.
                                    </p>
                                    <div className="flex flex-right items-center ">
                                        <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                                            Cancel
                                        </Button>
                                        <Button styleType={DANGER_BUTTON} onClick={localPurgeSalesData}>
                                            Okay
                                        </Button>
                                    </div>
                                </div>
                            )}
                        />
                    ) : null}
                </div>

                <div className="w-full">
                    <DetailContainer
                        adjustmentsLoading={adjustmentsLoading}
                        onCampaignChanged={onCampaignChanged}
                        selectedCampaignId={campaignId}
                        campaigns={campaigns}
                        adjustments={bidAdjustments}
                        locationStats={locationStats}
                        onAdjustmentSelected={onAdjustmentSelected}
                        selectedAdjustment={selectedAdjustmentId}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any, props: any) => {
    const clientId = state.clients.currentClient;
    const client = state.clients.entities[clientId];

    return {
        client,
        clientId
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        trackPullDiSalesData: (clientId: number, client: IClient) => {
            dispatch(pullDiSalesDataClicked(clientId, client));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesMapContainer);
