import FocusTrap from "focus-trap-react";
import React, { CSSProperties, useRef } from "react";
import ReactDOM from "react-dom";

import useOnClickOutside from "../../../hooks/useOnClickOutside";

interface IProps {
    hide(): void;
    className?: string;
}

const Modal = ({
    children,
    hide,
    className,
    style,
    modalRoot
}: {
    children: any;
    hide: () => void;
    className?: string;
    style?: CSSProperties;
    modalRoot?: any;
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => hide());

    return ReactDOM.createPortal(
        <div className={`fixed inset-0 z-50 overflow-auto flex `} style={{ background: "rgba(0,0,0,.5)" }}>
            {children && (
                <FocusTrap
                    focusTrapOptions={{
                        fallbackFocus: "body"
                    }}
                >
                    <div
                        ref={ref}
                        className={`fixed shadow-inner md:relative bottom-0 inset-x-0 align-top m-auto justify-end md:justify-center p-4 bg-white md:rounded w-4/5 md:h-auto md:shadow flex flex-col ${className}`}
                        style={style}
                    >
                        {children}
                    </div>
                </FocusTrap>
            )}
        </div>,
        document.getElementById(modalRoot ?? "modal-root") || document.createElement("div")
    );
};

export default Modal;
