import CheckIcon from "../../../Shared/Icons/CheckIcon";
import React from "react";
import { couldThisInventoryGroupHaveAProblem, highestField, lowestField } from "../../../../utils/MathHelpers";

interface IProps {
    inventoryGroup: any;
    groupPreview: any;
    setSelectedVehicleGroup: any;
    selectedVehicleGroup: any;
}

const DisplayTheVehicleInventoryGroup = ({
    inventoryGroup,
    groupPreview,
    setSelectedVehicleGroup,
    selectedVehicleGroup
}: IProps) => {
    const variables: {
        [key: string]: number | string;
    } = {
        "Highest Price: $": highestField(inventoryGroup, "OurPrice").toLocaleString(),
        "Lowest Price: $": lowestField(inventoryGroup, "OurPrice").toLocaleString(),
        "Max Discount: $": highestField(inventoryGroup, "Discounts").toLocaleString(),
        "Lowest Lease Price: $": lowestField(inventoryGroup, "LeasePayment").toLocaleString(),
        "Lowest Miles: ": lowestField(inventoryGroup, "Miles")
    };

    return (
        <button
            onClick={() => {
                setSelectedVehicleGroup(inventoryGroup);
            }}
            type="button"
            className={`w-64 flex flex-col justify-between text-left ${
                couldThisInventoryGroupHaveAProblem(inventoryGroup) ? "bg-red-200" : "bg-white"
            } shadow`}
        >
            <div className="flex flex-col w-full">
                <div className="p-2 w-full">
                    <div className="flex flex-wrap justify-between w-full">
                        <div className="text-gray-600 text-sm font-thin">{groupPreview.Year}</div>
                        <div className="text-gray-700 text-sm">{inventoryGroup.length} vehicles</div>
                    </div>
                    <div className="font-bold">
                        {groupPreview.Make} {groupPreview.Model}
                    </div>
                </div>
                <div className="w-full h-48 flex justify-center items-center bg-gray-700 rounded overflow-hidden">
                    <img
                        className={"w-full"}
                        src={groupPreview.Thumbnail}
                        alt={`${groupPreview.Make} ${groupPreview.Model} ${groupPreview.Trim}`}
                    />
                </div>

                <div className="p-2 flex flex-col justify-start w-full">
                    {Object.keys(variables).map((variableKey: string) => (
                        <div key={variableKey}>
                            {variableKey}
                            {variables[variableKey]}
                        </div>
                    ))}
                </div>
            </div>
            {selectedVehicleGroup && groupPreview.VIN === selectedVehicleGroup[0].VIN && (
                <div className="w-full relative z-50 ">
                    <div className="flex flex-wrap w-full justify-center items-center">
                        <CheckIcon className="w-4 h-4" /> <span className="ml-2">Selected</span>
                    </div>
                </div>
            )}
        </button>
    );
};

export default DisplayTheVehicleInventoryGroup;
