import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound404 from "../../Shared/NotFound404";
import Index from "./Index";
import UpsertRouter from "./UpsertRouter";
import { ISpecialOfferTemplate } from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import IAppState from "../../../interfaces/IAppState";
import { getSpecialOffersMetaTemplatesInOrder } from "../../../reducers/specialOffersMetaTemplateReducer";
import { getSpecialOfferTemplatesInOrder } from "../../../reducers/specialOfferTemplateReducer";
import { Dispatch } from "redux";
import * as actions from "../../../actions/specialOfferTemplateActions";
import { connect } from "react-redux";

interface IReduxStateProps {
    loading: boolean;
    loadedMetaTemplates?: Date;
    loadedTemplates?: Date;
}

interface IReduxDispatchProps {
    fetchSpecialOfferTemplates: (limit: number, page: number) => void;
    fetchSpecialOfferMetaTemplates: (limit: number, page: number) => void;
}

const SpecialOfferTemplatesRouter: React.FunctionComponent<IReduxStateProps & IReduxDispatchProps> = ({
    fetchSpecialOfferMetaTemplates,
    loadedMetaTemplates,
    loadedTemplates
}) => {
    useEffect(() => {
        if (!!loadedTemplates || !!loadedMetaTemplates) {
            return;
        }
        fetchSpecialOfferMetaTemplates(100, 1);
    }, []);

    return (
        //TODO
        <div className="mx-4">
            <Routes>
                <Route element={<Index />} path="/" />
                <Route element={<UpsertRouter />} path="/create" />
                <Route element={<UpsertRouter />} path="/:specialOfferTemplateId" />
                <Route element={<NotFound404 />} />
            </Routes>
        </div>
    );
};
// We should use a state selector to pull in the data.
const mapStateToProps = (state: IAppState): IReduxStateProps => ({
    loading: state.specialOfferTemplates.loading || state.specialOffersMetaTemplates.loading,
    loadedMetaTemplates: state.specialOffersMetaTemplates.loadedAt,
    loadedTemplates: state.specialOfferTemplates.loadedAt
});

const mapDispatchToProps = (dispatch: Dispatch, extraProps: any): IReduxDispatchProps => ({
    fetchSpecialOfferTemplates(limit: number, page: number) {
        dispatch(actions.fetchAllSpecialOfferTemplates(Number(extraProps.id), page, limit));
    },
    fetchSpecialOfferMetaTemplates(limit: number, page: number) {
        dispatch(actions.fetchAllSpecialOffersMetaTemplates(page, limit));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOfferTemplatesRouter);
