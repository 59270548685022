import React, { useRef, useState } from "react";
import { getTypeCellItems } from "./TableUtils";
import Tooltip from "./Tooltip";

const TypeCell: React.FC<{
    typeId: number;
    statusId: number;
    isManagedManually: boolean;
    label: string;
    tooltipText?: string;
    tooltipLabel?: string;
}> = ({ typeId, statusId, isManagedManually, label, tooltipText, tooltipLabel }) => {
    const cellItems = getTypeCellItems(typeId, statusId, isManagedManually, label);
    const ref = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleHover = () => setShowTooltip(!showTooltip);

    return (
        <div className="cell-wrapper flex" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
            {cellItems.map((item, i) => (
                <div ref={ref} key={i} className={i !== 0 ? "ml-2" : ""}>
                    {item.icon}
                </div>
            ))}
            <Tooltip reference={ref} shouldShow={!!showTooltip && !!tooltipText}>
                <div className="font-bold">{tooltipLabel}</div>
                <p>{tooltipText}</p>
            </Tooltip>
        </div>
    );
};

export default TypeCell;
