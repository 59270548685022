import React, { Component, useState } from "react";
import { saveAs } from "file-saver";
import { fetchCsv } from "../../middleware/api";
import RefreshIcon from "../Shared/Icons/RefreshIcon";
import DownloadIcon from "../Shared/Icons/DownloadIcon";
import Button from "../Shared/Button";
import { sanitizeExistingCsvTypeOfReport } from "../../utils/CsvUtils";

interface IProps {
    filename: string;
    url?: string;
    loadingText?: string;
    downloadText?: string;
    customProcess?: any;
    onClick?: () => void;
}

const DownloadCsv = ({
    filename,
    url = "",
    loadingText = "Loading CSV",
    downloadText = "Download CSV",
    customProcess = null,
    onClick
}: IProps) => {
    const [downloading, setDownloading] = useState(false);

    const fetchAndSaveFile = async () => {
        setDownloading(true);
        let data: Blob | null;
        if (customProcess) {
            data = await customProcess();
        } else {
            data = await fetchCsv(url);
        }

        const dataAsString: string = (!!data && sanitizeExistingCsvTypeOfReport(await data.text())) || "";
        data = null;

        // saveAs expects a blob, not a string unfortunately, so after we sanitize it we have to convert it back to a blob
        saveAs(new Blob(dataAsString.split("")), filename);
        setDownloading(false);
    };

    return (
        <Button
            disabled={downloading}
            onClick={() => {
                onClick && onClick();
                fetchAndSaveFile();
            }}
            type={"button"}
            styleType={"primary"}
        >
            {downloading ? (
                <div className="flex items-center">
                    <RefreshIcon className="w-6 h-6 rotate-fast" />
                    <span className="pl-2">{loadingText}</span>
                </div>
            ) : (
                <div className="flex items-center">
                    <DownloadIcon className="w-6 h-6" />
                    <span className="pl-2">{downloadText}</span>
                </div>
            )}
        </Button>
    );
};

export default DownloadCsv;
