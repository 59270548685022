import { connect } from "react-redux";
import Filters from "../Filters";
import * as actions from "../../../actions/salesData";
import IAppState from "../../../interfaces/IAppState";

interface IOwnProps {
    filterSalesData(): void;
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
    return {
        filterOptions: state.salesDataFilters.parameters,
        filterCounts: state.salesData.filterCounts,
        filterSalesData: ownProps.filterSalesData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onFilterChanged: (filter: string, value: string) => {
            dispatch(actions.toggleFilterOption(filter, value));
        }
    };
};

const FiltersContainer = connect(mapStateToProps, mapDispatchToProps)(Filters);

export default FiltersContainer;
