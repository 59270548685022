import { ConnectDropTarget, ConnectDragPreview, ConnectDragSource, DropTargetMonitor } from "react-dnd";
import { XYCoord } from "dnd-core";
import { ISpecialOfferTemplatePart, ITemplatePart } from "../interfaces/DynamicCampaigns/IDynamicCampaign";
import { FormikErrors } from "formik";
import {
    ISpecialOfferTemplateForm,
    ISpecialOfferTemplatePartValue
} from "../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";

export interface IDraggablePart {
    sortParts: (id1: number, id2: number, dragField: string) => boolean;
    connectDragPreview: ConnectDragPreview;
    connectDragSource: ConnectDragSource;
    connectDropTarget: ConnectDropTarget;
    isDragging: boolean;
    item: ITemplatePart | ISpecialOfferTemplatePart;
    index: number;
}

export interface ITemplatePartDraggablePart extends IDraggablePart {
    onDuplicate: () => void;
    fieldValidation?: (value: string) => string;
    allowDuplicate?: boolean;
    templateIndex: number;
    size: string;
    canDelete: boolean;
    errors?: any;
    pinnedFields?: string[];
}

export interface ISpecialOfferTemplateDraggablePart extends IDraggablePart {
    field: string;
    arrayOfValuesForValidation: string[];
    currentValue: ISpecialOfferTemplatePartValue[] | string[];
    required: boolean;
    errors: FormikErrors<ISpecialOfferTemplateForm>;
    templateIndex: string;
    setFieldValue: any;
    setFieldTouched: any;
}

export interface IPartInstance {
    getNode(): HTMLDivElement | null;
}

export const PART_TYPE = "part";
export const partSource = {
    beginDrag: (props: any) => {
        return {
            id: props.item.id,
            field: props.item.field,
            index: props.index
        };
    }
};
export const partTarget = {
    hover(props: IDraggablePart, monitor: DropTargetMonitor, component: IPartInstance) {
        if (!component) {
            return null;
        }
        // node = HTML Div element from imperative API
        const node = component.getNode();
        if (!node) {
            return null;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = node.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        const sorted = props.sortParts(dragIndex, hoverIndex, monitor.getItem().field);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        if (sorted) {
            monitor.getItem().index = hoverIndex;
        }
        return;
    }
};
