import React from "react";
import Select from "react-select";
import TextField from "../../Shared/Form/Blocks/TextField";
import { NumberConditionalOperators, getInventoryFieldsDI } from "../../../utils/InventoryUtils";
import { ErrorMessage } from "formik";
import Field from "../../Shared/Form/Elements/Field";
import IDynamicCampaignFormValue from "../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import TextInput from "../../Shared/Form/Elements/TextInput";

const errorStyles = "text-red-500 italic ";

interface IProps {
    onChange: any;
    values: IDynamicCampaignFormValue;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}
type OptionValue = {
    value: string;
    label: string;
};
const fields = getInventoryFieldsDI();
const InventoryGroup: React.FC<IProps> = ({ values, onChange, setFieldTouched }) => {
    const selectedComparator = NumberConditionalOperators.find(
        (item) => item.value === values.inventoryGroupComparator
    );
    const customSelectStyles = {
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: "39px"
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        })
    };
    const customStyles = {
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: "39px"
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        }),
        input: (provided: any, state: any) => ({
            ...provided
        })
    };

    return (
        <div className="flex">
            <div className="w-1/3">
                <Field errors={[]} label="Fields" required={true}>
                    <Select
                        name="inventoryGroupFields"
                        aria-label="inventoryGroupFields"
                        isMulti={true}
                        options={fields.map((item) => ({ label: item, value: item }))}
                        onBlur={() => setFieldTouched("inventoryGroupFields", true)}
                        onChange={(item) => {
                            const arrayItem = item as OptionValue[];
                            onChange(
                                "inventoryGroupFields",
                                arrayItem.map((val) => val.value)
                            );
                        }}
                        value={values.inventoryGroupFields.map((item) => ({ value: item, label: item }))}
                        styles={customSelectStyles}
                        className="w-full text-base"
                    />
                    <ErrorMessage name="inventoryGroupFields" className={errorStyles} component="span" />
                </Field>
            </div>
            <div className="w-1/3 mx-4">
                <Field errors={[]} label="Condition" required={true}>
                    <Select
                        name="inventoryGroupComparator"
                        aria-label="inventoryGroupComparator"
                        options={NumberConditionalOperators}
                        onBlur={() => setFieldTouched("inventoryGroupComparator", true)}
                        onChange={(item: any) => {
                            onChange("inventoryGroupComparator", item.value);
                        }}
                        styles={customStyles}
                        value={selectedComparator}
                    />
                    <ErrorMessage name="inventoryGroupComparator" className={errorStyles} component="span" />
                </Field>
            </div>
            <div className="w-32">
                <Field errors={[]} label="Value" required={true}>
                    <TextInput
                        name="inventoryGroupCount"
                        ariaLabel="inventoryGroupCount"
                        onBlur={() => setFieldTouched("inventoryGroupCount", true)}
                        onChange={(item: React.ChangeEvent<HTMLInputElement>) => {
                            onChange("inventoryGroupCount", item.target.value);
                        }}
                        value={String(values.inventoryGroupCount)}
                    />
                    <ErrorMessage name="inventoryGroupCount" className={errorStyles} component="span" />
                </Field>
            </div>
        </div>
    );
};

export default InventoryGroup;
