import React, { useEffect, useRef } from "react";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import BidModifierConditionals from "./BidModifierConditionals";
import AmountField from "./AmountField";
import { IAdGroupBidModifier, IConditional } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import SettingsIcon from "../../../Shared/Icons/SettingsIcon";
import TrashIcon from "../../../Shared/Icons/TrashIcon";
import EllipsisIcon from "../../../Shared/Icons/EllipsisIcon";

const modifierFieldOptions = [
    { label: "Increase bid by %", value: "increase-bid-by-percentage", field: "%" },
    { label: "Decrease bid by %", value: "decrease-bid-by-percentage", field: "%" },
    { label: "Increase bid by $", value: "increase-bid-by-currency", field: "$" },
    { label: "Decrease bid by $", value: "decrease-bid-by-currency", field: "$" },
    { label: "Set bid to", value: "set-bid-to", field: "$" }
];

interface IBidModifierProps extends IAdGroupBidModifier {
    index: any;
    errors: any;
    setConditionals: (conditionals: any) => void;
    setFieldTouched: (field: any) => void;
    setFieldValue: (field: any, value: any) => void;
    dragListeners: any;
    sortableProps: any;
    isDragging: boolean;
    order: number;
}

const BidModifier = (props: IBidModifierProps) => {
    const {
        dragListeners,
        sortableProps,
        setConditionals,
        setFieldTouched,
        setFieldValue,
        index,
        errors: allErrors,
        order,
        isDragging,
        ...modifierData
    } = props;

    const { modifier, conditionals, deleted, name, amount } = modifierData;
    const baseName = `adGroupBidModifiers[${index}]`;
    const fields = {
        modifier: `${baseName}.modifier`,
        amount: `${baseName}.amount`,
        name: `${baseName}.name`,
        conditionals: `${baseName}.conditionals`,
        deleted: `${baseName}.deleted`
    };

    const handleChange = (field: any, value: any) => {
        setFieldValue(field, value);
        setFieldValue(`${baseName}.dirty`, true);
    };

    const handleSetDescription = (value: string | null) => {
        return handleChange(fields.name, value?.trim() || null); //ensures not empty spaces are stored.
    };

    const fieldError = (errors: any, field: any) => (errors && field in errors ? errors[field] : null);
    const hasConditionals = conditionals.filter((c: any) => c && !c.deleted).length > 0;
    const errors = (!!allErrors && allErrors[index]) || {};
    const amountError = fieldError(errors, "amount");
    const descriptionError = fieldError(errors, "name");
    const modifierFieldOption =
        modifierFieldOptions.find((option) => option.value === modifier) || modifierFieldOptions[0];

    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setFieldTouched(fields.amount); //this is for triggering validation
        setFieldValue(`${baseName}.dirty`, true);
    }, [order]);

    if (deleted === true) {
        return null;
    }

    return (
        <div
            {...sortableProps}
            className={`inline-flex flex-wrap border border-gray-200 bg-white p-8 mb-8 align-center justify-center ${
                isDragging ? "opacity-50" : ""
            }`}
            data-testid={`bidmodifier-${index}`}
            aria-live="polite"
        >
            <button
                {...dragListeners} //attach dragListeners to the element that will act as drag handle.
                type="button"
                className="flex w-8 cursor-move text-md rounded-l border border-gray-500 text-gray-600 hover:text-gray-800 outline-none"
            >
                <EllipsisIcon className="w-6 h-6 m-1" />
            </button>
            <ModalToggle
                toggleButton={(open: any) => (
                    <button
                        title={name || ""}
                        data-testid={`bid-condition-button-${index}`}
                        onClick={open}
                        type="button"
                        className={`flex-grow flex-1 text-lg rounded-r border-l-0 border border-gray-500 ${
                            hasConditionals
                                ? "text-green-500 hover:text-green-600"
                                : "text-blue-400 hover:text-blue-600"
                        } outline-none`}
                    >
                        <SettingsIcon className="w-6 h-6 inline-block" />
                    </button>
                )}
                modalContent={(hide: any) => (
                    <BidModifierConditionals
                        fields={fields}
                        description={name || ""}
                        conditionals={conditionals}
                        setConditionals={(conditionals: IConditional[]) =>
                            handleChange(fields.conditionals, conditionals)
                        }
                        setDescription={handleSetDescription}
                        setFieldTouched={setFieldTouched}
                        descriptionError={descriptionError}
                        modifierIndex={index}
                        hide={hide}
                    />
                )}
            />
            <select
                className="p-2 border border-gray-400 rounded mx-4 w-2/5"
                value={modifier}
                onChange={(e) => {
                    handleChange(fields.modifier, e.target.value);
                }}
                onBlur={() => setFieldTouched(fields.modifier)}
            >
                {modifierFieldOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <div className="flex flex-wrap items-stretch w-2/5 relative">
                <AmountField
                    field={modifierFieldOption.field}
                    value={amount.toString()}
                    updateValue={(e) => {
                        handleChange(fields.amount, e.target.value);
                    }}
                    onBlur={() => setFieldTouched(fields.amount)}
                />
            </div>
            <button
                type="button"
                className={`flex-grow flex-1 ml-4 text-lg w-32 rounded border border-gray-500 hover:text-red-500 text-gray-500 outline-none`}
                onClick={(e) => {
                    e.preventDefault();
                    handleChange(fields.deleted, true);
                }}
            >
                <TrashIcon className="h-8 w-8 text-xl inline-block" />
            </button>
            <div className="w-full flex flex-col flex-end">
                {!isDragging && amountError && (
                    <span className="w-full text-red-500 text-sm italic uppercase mt-5">{amountError}</span>
                )}
            </div>
        </div>
    );
};

export default BidModifier;
