import React, { useEffect, useState } from "react";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import Notification from "./Notification";
import ReactPaginate from "react-paginate";
import FilterOptions from "./notificationsMap";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import ChevronLeftIcon from "../../Shared/Icons/ChevronLeftIcon";
import ChevronRightIcon from "../../Shared/Icons/ChevronRightIcon";
import VisibleIcon from "../../Shared/Icons/VisibleIcon";
import Button from "../../Shared/Button";
import SelectField from "../../Shared/Form/Blocks/SelectField";
import { INotification } from "../../../interfaces/Notifications/INotification";
import { getNonDeleted } from "../../../reducers/notifications";
import { fetchNotifications, filterChanged, markAllAsRead, markAsRead } from "../../../actions/notificationActions";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        notifications: getNonDeleted(state),
        pagination: state.notifications.pagination,
        loading: state.notifications.pagination.fetching
    };
};

const mapDispatchToProps = {
    fetchNotifications,
    markAsRead,
    markAllAsRead,
    filterChanged
};

const NotificationList = ({
    notifications,
    pagination,
    markAsRead,
    markAllAsRead,
    fetchNotifications,
    filterChanged,
    loading = false
}: ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps) => {
    const perPage = pagination.perPage || 100;
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState("");

    const onFilter = ({ label, value }: { label: string; value: string }) => {
        setFilter(value);

        let options = "";
        if (FilterOptions[label]) {
            options = FilterOptions[label];
        }

        filterChanged(options);
    };

    useEffect(() => {
        fetchNotifications("/user-notifications", currentPage, perPage);
    }, [currentPage]);

    const totalPages = Math.ceil(pagination.totalRecords / perPage);

    if (loading) {
        return <FullPageLoader message="Loading notifications..." />;
    }

    return (
        <div className="px-4 text-base">
            <PageTitle title="Notifications">
                <Button styleType="primary" styles="flex items-center" onClick={markAllAsRead}>
                    <VisibleIcon className="w-6 h-6" />
                    <span className="ml-2">Mark All As Read</span>
                </Button>
            </PageTitle>
            <div className="bg-white p-4 rounded w-full shadow">
                <div className="text-lg font-semibold">Filters</div>

                <SelectField
                    name="Filters"
                    value={filter}
                    options={Object.keys(FilterOptions).map((filter) => ({
                        label: filter,
                        value: FilterOptions[filter]
                    }))}
                    handleChange={onFilter}
                />
            </div>
            {pagination.totalRecords === 0 && loading === false && (
                <div className="italic mt-4 bg-white shadow rounded p-4">No new notifications!</div>
            )}

            <div className="w-full my-4 flex flex-wrap">
                {loading === false && pagination.totalRecords > 0 && (
                    <ul className="shadow rounded overflow-hidden w-full">
                        {Object.values(notifications).map((notification: any) => {
                            return (
                                <Notification
                                    key={notification.id}
                                    title={notification.data.title}
                                    message={notification.data.message}
                                    link={notification.data.link}
                                    level={notification.data.level}
                                    type={notification.data.type}
                                    deleting={notification.deleting}
                                    markAsRead={() => {
                                        markAsRead(notification);
                                    }}
                                    date={notification.createdAt}
                                />
                            );
                        })}
                    </ul>
                )}

                {totalPages > 1 && (
                    <ReactPaginate
                        previousLabel={<ChevronLeftIcon className="w-6 h-6" />}
                        nextLabel={<ChevronRightIcon className="w-6 h-6" />}
                        breakLabel={<a>...</a>}
                        breakClassName={"p-4 bg-gray-100"}
                        pageCount={totalPages}
                        forcePage={currentPage - 1}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        onPageChange={({ selected }) => {
                            // Selected starts at 0
                            setCurrentPage(selected + 1);
                        }}
                        containerClassName="flex flex-wrap py-4 px-4 mt-4 w-full text-center justify-center items-center"
                        activeLinkClassName="bg-blue-200 p-4 underline"
                        pageLinkClassName="bg-gray-100 p-4 underline"
                        disabledClassName="opacity-75 text-gray-600"
                    />
                )}
            </div>
        </div>
    );
};

// It seems like this could be related to the mapping of things? I guess????
// I have no gosh dang idea of what to do here.
export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
