interface IError {
    errors: { [key: string]: any };
    item: { [key: string]: any };
}

interface IRejection {
    rejections: { [key: string]: string | string[] };
    item: { [key: string]: any };
}

interface ILog {
    errors: IError[];
    rejections: IRejection[];
}

interface IAggregated {
    [key: string]: {
        item: { [key: string]: string | string[] };
        errors: { [key: string]: string | string[] };
        rejections: { [key: string]: string | string[] };
    };
}

const aggregateErrorsAndRejectionsByProp = (log: ILog, prop: string): IAggregated => {
    if (!log) {
        return {};
    }
    const { errors, rejections } = log;
    const hash: IAggregated = {};

    for (const error of errors) {
        if (!hash[error.item[prop]]) {
            hash[error.item[prop]] = {
                errors: {},
                rejections: {},
                item: {}
            };
        }
        hash[error.item[prop]].errors = {
            ...hash[error.item[prop]].errors,
            ...error.errors
        };

        hash[error.item[prop]].item = { ...hash[error.item[prop]].item, ...error.item };
    }

    for (const rejection of rejections) {
        if (!hash[rejection.item[prop]]) {
            hash[rejection.item[prop]] = {
                errors: {},
                rejections: {},
                item: {}
            };
        }
        hash[rejection.item[prop]].rejections = {
            ...hash[rejection.item[prop]].rejections,
            ...rejection.rejections
        };

        hash[rejection.item[prop]].item = {
            ...hash[rejection.item[prop]].item,
            ...rejection.item
        };
    }

    return hash;
};

export default aggregateErrorsAndRejectionsByProp;
