interface IDealerSetupRoute {
    title: string;
    subTitles: string[];
    path: string;
}

const dealerSetupRoutes: IDealerSetupRoute[] = [
    {
        title: "Pre-screen",
        subTitles: ["Pre-screen Questions"],
        path: "pre-screen"
    },
    {
        title: "Dealer Info",
        subTitles: ["General", "OEM"],
        path: "dealer-info"
    },
    {
        title: "Name",
        subTitles: ["Dealer Name"],
        path: "name"
    },
    {
        title: "Locations",
        subTitles: ["Dealer Locations"],
        path: "locations"
    },
    {
        title: "Competitors",
        subTitles: ["Dealer Competitors"],
        path: "competitors"
    },
    {
        title: "Dynamic",
        subTitles: ["Dynamic Campaigns"],
        path: "dynamic"
    },
    {
        title: "Services",
        subTitles: ["Listed Services"],
        path: "services"
    },
    {
        title: "Generic",
        subTitles: ["Generic Campaigns"],
        path: "generic"
    },
    {
        title: "Conquests",
        subTitles: ["Listed Conquests"],
        path: "conquest"
    },
    {
        title: "Remarketing",
        subTitles: ["Remarketing Campaigns"],
        path: "remarketing"
    },
    {
        title: "Ad Extensions",
        subTitles: ["Sitelinks"],
        path: "ad-extensions"
    },
    {
        title: "Negative Keywords",
        subTitles: ["Apply Negative Keywords"],
        path: "negative-keywords"
    },
    {
        title: "Review",
        subTitles: [
            "Pre-Screen",
            "Dealer Info",
            "Manufacturers",
            "Locations",
            "Competitors",
            "Services",
            "Ad Extensions",
            "Negative Keywords"
        ],
        path: "review"
    }
];

export default dealerSetupRoutes;
