import { AnyAction, combineReducers } from "redux";
import actions from "../actions/dynamicCampaignActions";
import { createSelector } from "reselect";
import { orderBy } from "lodash";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

const entitiesReducer = (state: { [x: string]: any } = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.LOAD_DYNAMIC_CAMPAIGN:
        case actions.LOAD_DYNAMIC_CAMPAIGNS:
            return { ...state, ...action.response.entities.dynamicTextCampaign };
        case actions.REMOVE_DYNAMIC_CAMPAIGN:
            delete state[action.campaignId];
            return { ...state };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const saving = (state = false) => {
    return state;
};
const dirty = (state = []) => {
    return state;
};
const DynamicCampaignReducer = (state = false, action: AnyAction) => {
    switch (action.type) {
        case actions.REQUEST_DYNAMIC_CAMPAIGN:
        case actions.REQUEST_DYNAMIC_CAMPAIGNS:
            return true;

        case actions.LOAD_DYNAMIC_CAMPAIGN:
        case actions.LOAD_DYNAMIC_CAMPAIGNS:
            return false;

        case RESET_CLIENT_STATE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    loading: DynamicCampaignReducer,
    saving,
    dirty,
    entities: entitiesReducer
});

export const getAll = (state: { dynamicTextCampaigns: { entities: any } }) => state.dynamicTextCampaigns.entities;
export const getAllOrderedByName = createSelector(getAll, (campaigns) =>
    orderBy(campaigns, (campaign) => campaign.name.toLowerCase())
);
