import NegativeKeywordCollConstants from "../../constants/NegativeKeywords";
import IEntity from "../../interfaces/IEntity";
import {
    INegativeKeywordCollCampaign,
    IUpdatedFormValues,
    INegativeKeywordColl,
    NegativeKeywordCollActionTypes,
    NegativeCollCampaignUpdateActionTypes,
    INegativeKeywordByCampaignId
} from "../../interfaces/NegativeKeywordColl";

import { FormikActions } from "formik";

const requestNegativeKeywordColls = (): NegativeKeywordCollActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_NEGATIVE_KEYWORD_COLLS,
    payload: {}
});

const requestNegativeKeywordCollsSuccess = (data: IEntity<INegativeKeywordColl>): NegativeKeywordCollActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_NEGATIVE_KEYWORD_COLLS_SUCCESS,
    payload: data
});

const requestNegativeKeywordCollsFail = (error: any): NegativeKeywordCollActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_NEGATIVE_KEYWORD_COLLS_FAIL,
    payload: error
});

const requestNegativeKeywordCollCampaigns = (clientId: number): NegativeKeywordCollActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS,
    payload: clientId
});

const requestNegativeKeywordCollCampaignsSuccess = (
    data: IEntity<INegativeKeywordCollCampaign>
): NegativeKeywordCollActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_SUCCESS,
    payload: data
});

const requestNegativeKeywordCollCampaignsFail = (error: any): NegativeKeywordCollActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_FAIL,
    payload: error
});

const updateNegativeKeywordCollCampaigns = (
    negativeKeywordCollCampaigns: IUpdatedFormValues,
    formikActions: FormikActions<INegativeKeywordByCampaignId>,
    clientId: number
): NegativeCollCampaignUpdateActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS,
    payload: { negativeKeywordCollCampaigns, formikActions, clientId }
});

const updateNegativeKeywordCollCampaignsSuccess = (
    formikActions: FormikActions<INegativeKeywordByCampaignId>,
    clientId: number
): NegativeCollCampaignUpdateActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_SUCCESS,
    payload: { clientId, formikActions }
});

const updateNegativeKeywordCollCampaignsFail = (
    error: any,
    formikActions: FormikActions<INegativeKeywordByCampaignId>,
    clientId: number
): NegativeCollCampaignUpdateActionTypes => ({
    type: NegativeKeywordCollConstants.REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_FAIL,
    payload: { clientId, formikActions, error }
});

export default {
    requestNegativeKeywordColls,
    requestNegativeKeywordCollsSuccess,
    requestNegativeKeywordCollsFail,

    requestNegativeKeywordCollCampaigns,
    requestNegativeKeywordCollCampaignsSuccess,
    requestNegativeKeywordCollCampaignsFail,

    updateNegativeKeywordCollCampaigns,
    updateNegativeKeywordCollCampaignsSuccess,
    updateNegativeKeywordCollCampaignsFail
};
