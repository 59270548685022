const parameters = [
    "model_lowest_price",
    "model_monthly_payments",
    "model_discounts",
    "model_lease_payments",
    "ID",
    "Type",
    "Stock",
    "VIN",
    "Year",
    "Make",
    "Model",
    "Trim",
    "Body",
    "ExtColorGeneric",
    "Miles",
    "OurPrice",
    "Drivetrain",
    "Certified",
    "DateInStock",
    "MonthlyPayments",
    "Link",
    "Status",
    "VDPViews",
    "Keagan Lynn",
    "LeasePayments",
    "Discounts",
    "ThumbNail",
    "DaysInStock",
    "Model | article"
];

const values = {
    ID: ["645", "719", "720", "721", "799"],
    Type: ["Pre-Owned"],
    Stock: ["SPN1275A", "N2120A", "N2120AB", "N2120ACA"],
    VIN: [
        "1N4AL11D15N440649",
        "2N4AL11D15N440649",
        "3N1AB61E68L711392",
        "3N1AB61E68L711393",
        "4N1AB61E68L711393",
        "4N1AB61E68L711399"
    ],
    Year: ["2005", "2006", "2008", "2021"],
    Make: ["Nissan"],
    Model: ["Altima", "Sentra"],
    Body: ["4dr Car"],
    ExtColorGeneric: ["Silver", "Black", "Orange", "White", "Purple"],
    Miles: ["114,002", "74,801"],
    OurPrice: ["7,995", "7,996", "8,995", "10,095"],
    Drivetrain: ["FWD"],
    DateInStock: ["2015-06-11", "2015-06-26"],
    Link: [
        "https://www.jenkinsnissanofbrunswick.com/inventory/2005-nissan-altima-fwd-4dr-car-1n4al11d15n440649",
        "https://www.jenkinsnissanofbrunswick.com/inventory/2008-nissan-sentra-fwd-4dr-car-3n1ab61e68l711392"
    ],
    Status: ["publish"],
    VDPViews: ["142", "30"],
    LeasePayments: ["20", "30", "40"],
    Discounts: ["10", "20"],
    ThumbNail: ["https://placehold.it/140x100"],
    DaysInStock: ["545", "530"],
    Fred: ["4MATIC\u00ae"],
    Certified: ["1"]
};
export { parameters, values };
