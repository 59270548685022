import { Reducer, combineReducers } from "redux";
import SpecialOfferTemplateConstants from "../constants/SpecialOfferTemplateConstants";
import IEntity from "../interfaces/IEntity";
import { ISpecialOfferMetaTemplate } from "../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import {
    IFetchAllSpecialOfferTemplates,
    IFetchAllSuccessSpecialOfferTemplates
} from "../actions/specialOfferTemplateActions";
import { IFetchAllResponse } from "../sagas/specialOfferTemplatesSagas";

interface ISpecialOffersMetaTemplateEntities {
    order: number[];
    data: IEntity<ISpecialOfferMetaTemplate>;
}

export interface ISpecialOffersMetaTemplateReducerState {
    entities: ISpecialOffersMetaTemplateEntities;
    loading: boolean;
    loadedAt?: Date;
}

export const getSpecialOffersMetaTemplatesInOrder = (
    specialOfferState: ISpecialOffersMetaTemplateReducerState
): ISpecialOfferMetaTemplate[] =>
    specialOfferState.entities.order
        .map((index: number) => specialOfferState.entities.data[index])
        .filter((item) => item);

const entities: Reducer<
    ISpecialOffersMetaTemplateEntities,
    IFetchAllSpecialOfferTemplates | IFetchAllSuccessSpecialOfferTemplates
> = (
    state = {
        data: {},
        order: []
    },
    action
): ISpecialOffersMetaTemplateEntities => {
    switch (action.type) {
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_SUCCESS:
            const items: IFetchAllResponse = {
                result: { data: [] },
                entities: { specialOffersTemplates: {}, dynamicCampaigns: {}, specialOffersMetaTemplates: {} },
                ...action.payload
            };
            return {
                data: {
                    ...state.data,
                    ...items.entities.specialOffersMetaTemplates
                },
                order: [...new Set(state.order.concat(items.result.data))]
            };
        default:
            return {
                ...state
            };
    }
};

const loading: Reducer<any> = (state = false, action) => {
    switch (action.type) {
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES:
            return true;
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_SUCCESS:
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_FAILED:
            return false;
        default:
            return state;
    }
};
const loadedAt: Reducer<any> = (state = null, action) => {
    switch (action.type) {
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_SUCCESS:
            return new Date();
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_FAILED:
            return null;
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    entities,
    loadedAt
});
