import React, { useState } from "react";
import * as Yup from "yup";
import Label from "../../../../../Shared/Form/Label";
import IKeywordConditional from "../../../../../../interfaces/IKeywordConditional";
import KeywordTemplateConditional from "./KeywordTemplateConditional";
import AddIcon from "../../../../../Shared/Icons/AddIcon";
import { connect, FormikContext } from "formik";
import IDynamicCampaignFormValue from "../../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import Button, { SECONDARY_BUTTON } from "../../../../../Shared/Button";
type Props = {
    keywordTemplateRelationshipIndex: number;
    closeModal: () => void;
};

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const KeywordTemplateConditionals = ({
    keywordTemplateRelationshipIndex,
    closeModal,
    formik: {
        values: {
            keywordTemplateRelationships: {
                [keywordTemplateRelationshipIndex]: keywordTemplateRelationship,
                [keywordTemplateRelationshipIndex]: {
                    conditionals,
                    keywordTemplate: { isMaster, conditionals: masterConditionals }
                }
            }
        },
        setFieldValue
    }
}: Props & ContextProps) => {
    const [tempConditionals, setTempConditionals] = useState(conditionals);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const baseFieldPath = `keywordTemplateRelationships[${keywordTemplateRelationshipIndex}]`;
    const fieldPath = `${baseFieldPath}.conditionals`;

    const validateField = (conditionalIndex: number, field: string, value: any) => {
        try {
            Yup.reach(
                Yup.object().shape({
                    comparator: Yup.string().required("This field is required."),
                    value: Yup.string().max(199, "Too Long!").required("This field is required."),
                    parameter: Yup.string().required("This field is required.")
                }),
                field
            ).validateSync(value, { abortEarly: false });

            setErrors((currentErrors) => {
                return { ...currentErrors, [`${fieldPath}[${conditionalIndex}].${field}`]: undefined };
            });

            return true;
        } catch ({ errors }) {
            setErrors((currentErrors) => {
                return { ...currentErrors, [`${fieldPath}[${conditionalIndex}].${field}`]: errors };
            });

            return false;
        }
    };

    const handleUpdateConditionals = () => {
        const validTempConditionals = tempConditionals.map((tempConditional, index) => {
            const { deleted, parameter, comparator, value } = tempConditional;

            if (deleted) {
                return true;
            }

            const conditionalFieldPath = `${fieldPath}[${index}]`;

            setTouched((currentTouched) => {
                return {
                    ...currentTouched,
                    [`${conditionalFieldPath}.parameter`]: true,
                    [`${conditionalFieldPath}.comparator`]: true,
                    [`${conditionalFieldPath}.value`]: true
                };
            });

            return [
                validateField(index, `parameter`, parameter),
                validateField(index, `comparator`, comparator),
                validateField(index, `value`, value)
            ].every((isValid) => isValid);
        });

        if (validTempConditionals.every((isValid) => isValid)) {
            setFieldValue(baseFieldPath, {
                ...keywordTemplateRelationship,
                conditionals: tempConditionals,
                dirty: true
            });
            closeModal();
            return;
        }
    };

    const updateTempConditions = (
        idx: number,
        { parameter, value, comparator, deleted = false }: IKeywordConditional
    ) => {
        setTempConditionals((currentConditions: IKeywordConditional[]) => {
            const updatedCondition = {
                ...currentConditions[idx],
                parameter,
                value,
                comparator,
                deleted,
                dirty: true
            };
            return [...currentConditions.slice(0, idx), updatedCondition, ...currentConditions.slice(idx + 1)];
        });
    };

    const handleRemoveCondition = (index: number, condition: IKeywordConditional) => {
        condition.deleted = true;
        updateTempConditions(index, condition);
    };

    const handleConditionChange = (index: number, condition: IKeywordConditional, key: string) => {
        validateField(index, key, condition[key]);
        updateTempConditions(index, condition);
    };

    const handleBlur = (index: number, field: string, key: string) => {
        validateField(index, key, tempConditionals[index][key]);
        setTouched((currentTouched) => {
            return { ...currentTouched, [field]: true };
        });
    };

    const handleAddCondition = () => {
        setTempConditionals((tempConditionals) => {
            return [
                ...tempConditionals,
                {
                    parameter: "",
                    comparator: "",
                    value: "",
                    new: true,
                    dirty: true
                }
            ];
        });
    };

    return (
        <div className="h-100 w-100 bg-white z-51">
            {isMaster ? <Label label={"Conditional Logic (Read Only)"} /> : <Label label={"Conditional Logic"} />}

            {!isMaster &&
                tempConditionals.map((conditional: IKeywordConditional, index: number) => {
                    const fieldName = `${fieldPath}[${index}]`;
                    return (
                        <KeywordTemplateConditional
                            key={index}
                            index={index}
                            condition={conditional}
                            onChange={(item: IKeywordConditional, key) => handleConditionChange(index, item, key)}
                            onRemove={() => handleRemoveCondition(index, conditional)}
                            handleBlur={(field: string, key: string) => handleBlur(index, field, key)}
                            names={{
                                comparator: `${fieldName}.comparator`,
                                parameter: `${fieldName}.parameter`,
                                value: `${fieldName}.value`
                            }}
                            errors={errors}
                            touched={touched}
                            isClearable={false}
                            isMaster={!!isMaster}
                        />
                    );
                })}

            {isMaster &&
                masterConditionals?.map((conditional, index) => {
                    const fieldName = `${fieldPath}[${index}]`;
                    return (
                        <KeywordTemplateConditional
                            key={index}
                            index={index}
                            condition={conditional}
                            onChange={(item: IKeywordConditional, key) => handleConditionChange(index, item, key)}
                            onRemove={() => handleRemoveCondition(index, conditional)}
                            handleBlur={(field: string, key: string) => handleBlur(index, field, key)}
                            names={{
                                comparator: `${fieldName}.comparator`,
                                parameter: `${fieldName}.parameter`,
                                value: `${fieldName}.value`
                            }}
                            errors={errors}
                            touched={touched}
                            isClearable={false}
                            isMaster={!!isMaster}
                        />
                    );
                })}

            {!isMaster && (
                <Button onClick={handleAddCondition} type="button" styleType={SECONDARY_BUTTON} styles="my-4">
                    <div className="flex items-center">
                        <AddIcon className="w-6 h-6" />
                        <span className="ml-2">Add Conditional</span>
                    </div>
                </Button>
            )}

            <div className={`w-full border-t border-gray mt-4 pt-4`}>
                <div className="flex flex-right">
                    <Button onClick={closeModal} styleType={`secondary`}>
                        Cancel
                    </Button>
                    {!isMaster && (
                        <Button onClick={handleUpdateConditionals} styleType={`primary`} styles="ml-4">
                            Okay
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect<Props, any>(KeywordTemplateConditionals);
