import React, { SVGProps } from "react";
import Svg from "./Svg";

const UnlockIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M13 17.583a1.839 1.839 0 001.833-1.833A1.839 1.839 0 0013 13.917a1.839 1.839 0 00-1.833 1.833c0 1.008.825 1.833 1.833 1.833zm5.5-8.25h-.917V7.5A4.585 4.585 0 0013 2.917 4.585 4.585 0 008.417 7.5h1.741A2.844 2.844 0 0113 4.658 2.844 2.844 0 0115.842 7.5v1.833H7.5a1.839 1.839 0 00-1.833 1.834v9.166c0 1.009.825 1.834 1.833 1.834h11a1.839 1.839 0 001.833-1.834v-9.166A1.839 1.839 0 0018.5 9.333zm0 11h-11v-9.166h11v9.166z" />
    </Svg>
);

export default UnlockIcon;
