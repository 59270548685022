import React from "react";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import UnauthenticatedLayout from "../Shared/UnauthenticatedLayout";
import { auth0Callback } from "../../actions/authenticationActions";
import IAppState from "../../interfaces/IAppState";

interface IProps {
    authenticate: (code: string) => void;
    errors: string[];
}

const LoginPage: React.FunctionComponent<IProps> = ({ authenticate, errors }) => {
    return (
        <UnauthenticatedLayout>
            <LoginForm errors={errors} authenticate={authenticate} />
        </UnauthenticatedLayout>
    );
};

const mapStateToProps = (state: IAppState) => ({
    errors: state.authentication.errors
});

const mapDispatchToProps = (dispatch: any) => ({
    authenticate: (code: string) => {
        dispatch(auth0Callback(code));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
