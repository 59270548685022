import React, { SVGProps } from "react";
import Svg from "./Svg";

const UserGroupIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M8.75 9.99994C7.42392 9.99994 6.15215 9.47316 5.21447 8.53547C4.27678 7.59779 3.75 6.32602 3.75 4.99994C3.75 3.67386 4.27678 2.40209 5.21447 1.46441C6.15215 0.526724 7.42392 -6.0366e-05 8.75 -6.0366e-05C10.0761 -6.0366e-05 11.3479 0.526724 12.2855 1.46441C13.2232 2.40209 13.75 3.67386 13.75 4.99994C13.75 6.32602 13.2232 7.59779 12.2855 8.53547C11.3479 9.47316 10.0761 9.99994 8.75 9.99994V9.99994ZM8.75 11.2499C11.4375 11.2499 14 11.7499 16.375 12.6124L15 19.9999H13.4375L12.5 24.9999H5L4.0625 19.9999H2.5L1.125 12.6124C3.56833 11.7192 6.14852 11.2581 8.75 11.2499V11.2499ZM19.1375 11.4624C20.7875 11.6874 22.375 12.0624 23.8875 12.6124L22.5 19.9999H20.9375L20 24.9999H15.05L15.5125 22.4999H17.075L19.1375 11.4624V11.4624ZM16.25 -6.0366e-05C16.9817 -0.00633347 17.7058 0.148063 18.3713 0.452231C19.0368 0.7564 19.6275 1.20293 20.1015 1.7603C20.5756 2.31767 20.9216 2.9723 21.115 3.67798C21.3084 4.38366 21.3446 5.1232 21.221 5.8444C21.0974 6.5656 20.817 7.25088 20.3996 7.85187C19.9822 8.45286 19.438 8.95492 18.8054 9.32262C18.1728 9.69031 17.4672 9.91467 16.7384 9.97987C16.0096 10.0451 15.2753 9.94951 14.5875 9.69994C15.6675 8.37165 16.2571 6.71191 16.2571 4.99994C16.2571 3.28797 15.6675 1.62823 14.5875 0.29994C15.125 0.12494 15.6625 -6.0366e-05 16.25 -6.0366e-05Z" />
    </Svg>
);

export default UserGroupIcon;
