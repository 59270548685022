import React from "react";
import TableActionButton from "../../../Shared/Table/TableActionButton";
import ChainIcon from "../../../Shared/Icons/ChainIcon";
import { useNavigate } from "react-router-dom";

interface IProps {
    original: {
        url: string;
    };
}

const ClientFacebookRemarketingExportErrorActionCell = ({ original }: IProps) => {
    const navigate = useNavigate();
    return (
        <TableActionButton
            onClick={() => navigate(original.url)}
            Icon={ChainIcon}
            data-testid="remarketing-export-log-button"
            title="Go to remarketing export log"
            className="text-gray-500"
        />
    );
};

export default ClientFacebookRemarketingExportErrorActionCell;
