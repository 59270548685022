import IEditHistoryState from "../interfaces/EditHistory/IEditHistoryState";
import Constants from "../constants/EditHistoryConstants";
import { createSelector } from "reselect";
import { reject, reverse, slice as lodashSlice, sortBy } from "lodash/fp";
import IAppState from "../interfaces/IAppState";
import IActivity from "../interfaces/EditHistory/IActivity";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

const initialState: IEditHistoryState = {
    currentModelId: 0,
    entities: {},
    order: [],
    page: 1,
    total: 0,
    loading: true,
    hasMorePages: false
};

function editHistoryReducer(state = initialState, action: any): IEditHistoryState {
    switch (action.type) {
        case Constants.FETCH_EDIT_HISTORY_FOR_CLIENT_REQUEST:
        case Constants.FETCH_ALL_EDIT_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                currentModelId: action.payload.id,
                page: action.payload.page,
                hasMorePages: false
            };
        case Constants.FETCH_EDIT_HISTORY_SUCCESS:
        case Constants.FETCH_ALL_EDIT_HISTORY_SUCCESS:
            console.log(action.payload);
            return {
                ...state,
                entities: action.payload.entities,
                order: action.payload.order,
                page: action.payload.page,
                total: action.payload.total,
                loading: false,
                hasMorePages: action.payload.nextPageUrl !== null
            };
        case Constants.FETCH_EDIT_HISTORY_FAILED:
            return {
                ...state,
                loading: false,
                hasMorePages: false
            };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
}
export const getEditHistoryInOriginalOrder = createSelector(
    (state: IAppState) => state.editHistory,
    (editState) => {
        console.log("edit state", editState);
        return editState.order.map((originalIndex: number) => editState.entities[originalIndex]);
    }
);

export default editHistoryReducer;
