import { callApi } from "../../middleware/api";

class SalesDataStore {
    async callApi(url: string, method = "GET", body?: any) {
        try {
            const response = await callApi(url, {}, method, body, false);
            return response.result;
        } catch (error) {
            console.log({ error });
        }
    }

    fetchFilters(clientId: number) {
        return this.callApi(`/clients/${clientId}/sales/options`);
    }

    fetchSalesData(clientId: number, conditions: Array<{ parameter: string; value: string; comparator: string }>) {
        const params = conditions.reduce((acc, curr) => {
            acc += `${!acc ? "?" : "&"}search[${curr.parameter}]=${curr.comparator},${curr.value}`;
            return acc;
        }, "" as any);
        return this.callApi(`/clients/${clientId}/sales/aggregate${params}`);
    }

    fetchLocationStats(clientId: number, campaignId: number, { startDate, endDate }: any) {
        return this.callApi(
            `/clients/${clientId}/sales/locations/${campaignId}?start_date=${startDate}&end_date=${endDate}`
        );
    }
}

export default SalesDataStore;
