import Mustache, { Context } from "mustache";

const numberFilter = (str: string) => Number(str.replace(",", "").replace("$", "")).toLocaleString();

const PossibleFilters: {
    [key: string]: (str: string) => string;
} = {
    article: (str: string) =>
        ["a", "e", "i", "o", "u"].filter((vowel) => str.toLowerCase()?.startsWith(vowel)).length > 0
            ? "an " + str
            : "a " + str,
    number: numberFilter,
    money: (str: string) => "$" + numberFilter(str)
};

export default {
    render(template: string, parameters: any) {
        const parts = Mustache.parse(template).filter((templateChunk: any) => {
            return templateChunk[0] === "name"; // Name parts are ones with variables
        });

        parts
            .filter((templateChunk: any) => templateChunk[1].includes("|"))
            .map((templateChunk: any) => {
                const parsedTemplateChunk = templateChunk[1].split("|").map((str: string) => str.trim());

                if (parsedTemplateChunk.length === 1) {
                    return;
                }

                const variableVariable = parsedTemplateChunk.shift();

                let value: string = String(parameters[variableVariable]);

                for (const part in parsedTemplateChunk) {
                    if (!parsedTemplateChunk[part]) {
                        continue;
                    }
                    const filterName: string = parsedTemplateChunk[part];
                    value = PossibleFilters[filterName](value);
                }

                // This dynamically sets a variable with the value of the variable after it's passed through the filters.
                parameters[templateChunk[1]] = value;
            });

        return Mustache.render(template, parameters);
    }
};
