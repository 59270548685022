import React from "react";
import AddIcon from "../../Shared/Icons/AddIcon";

const AddButton: React.FC<{ onClick: () => void; disabled?: boolean; text?: string; className?: string }> = ({
    onClick,
    disabled,
    className,
    text = "Add"
}) => {
    return (
        <button
            className={`bg-transparent ${
                disabled ? "text-gray-500" : "text-blue-600 hover:text-blue-800"
            } flex flex-wrap items-center mt-2 ${className}`}
            onClick={onClick}
            type="button"
            disabled={disabled}
        >
            <AddIcon className="h-3 w-3 inline block outline-none " />
            {text}
        </button>
    );
};

export default AddButton;
