import React, { useState, ChangeEvent } from "react";
import { ISearchItemsProps } from "../../../interfaces/shared/IItemCollection";
import TextField from "../Form/Blocks/TextField";
import Button, { PRIMARY_BUTTON } from "../Button";

const SearchItems: React.FC<ISearchItemsProps> = ({ info, routeId, searchTerm, searchItems, isLoading }) => {
    const [search, setSearch] = useState(searchTerm);

    const onSearchHandler = () => {
        if (search !== searchTerm) {
            searchItems(routeId, search);
        }
    };

    const onKeyDownSearchHandler = (e: any) => {
        if (e.key === "Enter") {
            onSearchHandler();
        }
    };

    return (
        <div className="w-1/3 flex items-end">
            <TextField
                className="flex-1"
                handleChange={(e: ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value)}
                value={search}
                label={`Search ${info.itemsDescription}`}
                name={info.itemsDescription}
                handleKeyDown={onKeyDownSearchHandler}
                placeholder={`Search ${info.itemsDescription}...`}
                disabled={isLoading}
            />
            <div className="flex items-end mx-4">
                <Button styleType={PRIMARY_BUTTON} onClick={onSearchHandler} disabled={isLoading}>
                    Search
                </Button>
            </div>
        </div>
    );
};

export default SearchItems;
