import { useEffect, useState, RefObject } from "react";

function useStickyScroll(ref: RefObject<HTMLDivElement>) {
    const [isSticky, setIsSticky] = useState<boolean>(false);

    const handleScrollEvent = () => {
        if (!ref.current) {
            return;
        }
        window.pageYOffset >= ref.current.offsetTop ? setIsSticky(true) : setIsSticky(false);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollEvent);
        handleScrollEvent();
        return () => {
            window.removeEventListener("scroll", handleScrollEvent);
        };
    }, []);

    return isSticky;
}

export default useStickyScroll;
