import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { array, boolean, number, object, string } from "yup";
import VariationsInput from "../Fields/VariationsInput";
import IName from "../../../../interfaces/DealerSetup/INameFields";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";

interface IProps {
    path: string;
    formValues: IFormValues;
    savePage: any;
}

const Name = forwardRef(({ formValues, savePage, path }: IProps, ref: React.Ref<{}>) => {
    const defaults: IName = {
        fields: {
            name: {
                value: {
                    name: "",
                    variations: []
                }
            },
            cityVariations: {
                value: false
            },
            generateExactPhrase: {
                value: false
            },
            negative_keyword_lists: {
                value: []
            }
        }
    };

    const schema = object({
        name: object({
            name: string().required("The Name field is required.").min(1),
            variations: array().of(string())
        }),
        cityVariations: boolean(),
        generateExactPhrase: boolean(),
        negative_keyword_lists: array().of(number())
    });

    const [values, handleFieldChange, changePage] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleNameChange = (index: number, value: any) => {
        handleFieldChange(`name`, value);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        handleFieldChange(name, !values.fields[name].value);
    };

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Name Campaign Setup</div>

            <div className="text-lg font-bold mt-4 pb-3 border-b">Dealer Name</div>

            <VariationsInput
                index={0}
                label={"Name"}
                name="name"
                value={values.fields.name.value.name}
                variations={values.fields.name.value.variations}
                errors={values.fields.name.errors}
                deletable={false}
                handleChange={handleNameChange}
                variationLabel={"Name Ad Groups"}
                handleDelete={() => {
                    return;
                }}
            />
            <p className="text-gray-dark">
                Ex: Bill Luke CDJR, Bill Luke Chrysler, Bill Luke Dodge, Bill Luke Jeep, Bill Luke Ram
            </p>

            <div className="my-4">
                <input
                    id="cityVariations"
                    checked={values.fields.cityVariations.value}
                    type="checkbox"
                    name="cityVariations"
                    onChange={handleCheckboxChange}
                    className="text-base border rounded rounded-l-none focus:outline-none "
                />
                <label htmlFor="cityVariations" className="text-base ml-2 cursor-pointer">
                    Include City Variations?
                </label>
            </div>

            <div className="my-4">
                <input
                    id="generateExactPhrase"
                    checked={values.fields.generateExactPhrase.value}
                    type="checkbox"
                    name="generateExactPhrase"
                    onChange={handleCheckboxChange}
                    className="text-base border rounded rounded-l-none focus:outline-none "
                />
                <label htmlFor="generateExactPhrase" className="text-base ml-2 cursor-pointer">
                    Generate Exact & Phrase Match Only
                </label>
            </div>

            {pager()}
        </div>
    );
});

export default Name;
