import React, { FunctionComponent, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Route404 from "../../Shared/NotFound404";
import List from "./List";
import New from "./New";
import Edit from "./Edit";
import LogContainer from "./Log/LogContainer";
import {
    fetchAll,
    pushDynamicCampaign,
    requestCreateDynamicCampaign,
    requestDeleteItem,
    requestInventorySampleData,
    requestUpdateDynamicCampaign
} from "../../../actions/dynamicCampaigns/dynamicCampaignActions";
import IDynamicCampaignFormValue from "../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import { FormikActions } from "formik";
import { fetchDynamicCampaignStatuses } from "../../../actions/dynamicCampaignStatusesActions";
import { get } from "lodash";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import IEntity from "../../../interfaces/IEntity";
import { IAdwordsCampaign } from "../../../interfaces/Campaigns";
import { ISpecialOfferTemplateAttachedToDynamicCampaignPayload } from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import IAppState from "../../../interfaces/IAppState";

interface IProps {}

const DynamicCampaignRouter: FunctionComponent<IProps> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        currentClient: clientId,
        entities: { [clientId]: client, dynamicCampaigns, adWordsCampaigns, microsoftCampaigns },
        dynamicCampaigns: { loadedAt },
        inventorySampleData: { loadedAt: loadedSampleData }
    } = useSelector((state: IAppState) => ({
        currentClient: state.clients.currentClient,
        entities: {
            [state.clients.currentClient]: state.clients.entities[state.clients.currentClient],
            dynamicCampaigns: state.dynamicCampaigns.entities,
            adWordsCampaigns: state.adwordsCampaigns.entities,
            microsoftCampaigns: state.microsoftCampaigns.entities
        },
        dynamicCampaigns: state.dynamicCampaigns,
        inventorySampleData: state.inventorySampleData
    }));

    const fetchAllHandler = (clientId: number) => {
        dispatch(fetchAll(clientId));
    };

    const fetchSampleDataHandler = (clientId: number) => {
        dispatch(requestInventorySampleData(clientId));
    };

    const deleteCampaignHandler = (clientId: number, id: number) => {
        dispatch(requestDeleteItem(clientId, id));
    };
    const fetchDynamicCampaignStatusesHandler = () => {
        dispatch(fetchDynamicCampaignStatuses());
    };
    const onCreateHandler = (
        dynamicCampaign: IDynamicCampaignFormValue,
        clientId: number,
        actions: FormikActions<IDynamicCampaignFormValue>,
        timeToSubmitMetric: number,
        specialOfferTemplates?: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[]
    ) => dispatch(requestCreateDynamicCampaign(dynamicCampaign, clientId, actions, timeToSubmitMetric, navigate));

    const onUpdateHandler = (
        dynamicCampaign: IDynamicCampaignFormValue,
        clientId: number,
        actions: FormikActions<IDynamicCampaignFormValue>,
        timeToSubmitMetric: number,
        specialOfferTemplates?: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[]
    ) =>
        dispatch(
            requestUpdateDynamicCampaign(
                dynamicCampaign,
                clientId,
                actions,
                timeToSubmitMetric,
                specialOfferTemplates || []
            )
        );

    const onPushCampaignHandler = (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus" | "setSubmitting">
    ) => {
        dispatch(pushDynamicCampaign(clientId, dynamicCampaignId, formActions));
    };

    useEffect(() => {
        if (loadedAt === null) {
            fetchAllHandler(clientId);
            fetchDynamicCampaignStatusesHandler();
        }
        if (!loadedSampleData && get(client, "inventoryApiKey", false)) {
            fetchSampleDataHandler(clientId);
        }
    }, [clientId, loadedAt]);

    if (!loadedAt) {
        return <FullPageLoader message="Loading Dynamic Campaigns..." />;
    }

    return (
        <Routes>
            <Route element={<Route404 />} path="*" />
            <Route
                path="/"
                element={
                    <List
                        client={client}
                        dynamicCampaigns={dynamicCampaigns}
                        adWordsCampaigns={adWordsCampaigns as unknown as IEntity<IAdwordsCampaign>}
                        microsoftCampaigns={microsoftCampaigns}
                        deleteDynamicCampaign={deleteCampaignHandler}
                        loading={false}
                    />
                }
            />
            <Route
                path="new"
                element={
                    <New
                        googleCampaigns={adWordsCampaigns}
                        microsoftCampaigns={microsoftCampaigns}
                        onSave={onCreateHandler}
                        client={client}
                        clientId={clientId}
                        onPushCampaign={onPushCampaignHandler}
                    />
                }
            />
            <Route
                element={
                    <Edit
                        googleCampaigns={adWordsCampaigns}
                        microsoftCampaigns={microsoftCampaigns}
                        onSave={onUpdateHandler}
                        client={client}
                        clientId={clientId}
                        onPushCampaign={onPushCampaignHandler}
                    />
                }
                path=":dynamicCampaignId"
            />
            <Route element={<LogContainer />} path=":dynamicCampaignId/log" />
        </Routes>
    );
};

export default DynamicCampaignRouter;
