import React, { SVGProps } from "react";
import Svg from "./Svg";

const WalletIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M19.135 3.114H6.545a3.181 3.181 0 0 0-2.175.95.677.677 0 0 0-.17.186 3.144 3.144 0 0 0-.745 2.103v10.42a4.048 4.048 0 0 0 2.866 3.786l8.219 2.24a2.541 2.541 0 0 0 .933.084c1.398-.133 2.429-1.378 2.3-2.711v-2.06h1.297c.917 0 1.78-.353 2.436-.997a3.456 3.456 0 0 0 1.04-2.454V6.523a3.416 3.416 0 0 0-3.41-3.41zm0 1.363c1.128 0 2.046.918 2.046 2.046v3.409h-3.482A4.13 4.13 0 0 0 14.92 6.78L6.815 4.477h12.32zm-3.79 17.048a1.18 1.18 0 0 1-.439-.04L6.703 19.25a2.675 2.675 0 0 1-1.885-2.492V6.337c-.008-.342.08-.664.238-.942l9.465 2.69a2.744 2.744 0 0 1 1.887 2.534l.002 6.773v2.845c.062.65-.416 1.228-1.065 1.288zm3.709-4.775H17.77v-5.455h3.409v3.36a2.117 2.117 0 0 1-2.126 2.095z" />
        <path d="M13.34 13.34a1.023 1.023 0 1 0 .002 2.047 1.023 1.023 0 0 0-.001-2.046z" />
    </Svg>
);

export default WalletIcon;
