import React from "react";

interface IProps {
    title: string;

    children?: React.ReactNode;
}

const PageTitle: React.FC<IProps> = ({ title, children }) => (
    <div className="my-4 w-full border-b border-gray-400 pb-4">
        <header className="flex justify-between items-center w-full">
            <div className="text-2xl text-gray-800 font-medium py-0 capitalize">{title}</div>
            {children ? <div>{children}</div> : null}
        </header>
    </div>
);

export default PageTitle;
