import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ErrorMessage } from "formik";
import Field from "../../Shared/Form/Elements/Field";
import { ConditionalOperators, getInventoryFieldsDI } from "../../../utils/InventoryUtils";
import { formatToReactSelectValue, buildSelectOptions, formatToCsv } from "../../../utils/DynamicCampaignUtils";
import { IConditional } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import { IInventoryFieldValues } from "../../../interfaces/InventoryFields";
import SelectField from "../../Shared/Form/Blocks/SelectField";
import TextInput from "../../Shared/Form/Elements/TextInput";
import TrashIcon from "../../Shared/Icons/TrashIcon";
import IAppState from "../../../interfaces/IAppState";
import MultiSelectInput from "../../Shared/Form/Elements/MultiSelectInput";

const errorStyles = "text-red-500 italic";
const multiFields = ["IN", "NOTIN", "IN_LIKE", "NOTIN_LIKE"];
const inventoryFieldsDI = getInventoryFieldsDI();

interface IProps {
    errors: any;
    condition: IConditional;
    names: { [field: string]: string };
    fields?: string[];
    inventoryFieldValues: IInventoryFieldValues;
    index?: number;
    onChange: (condition: IConditional) => void;
    onRemove?: () => void;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
    autoFocus?: boolean;
}

const InventoryFilter: React.FC<IProps> = ({
    condition,
    onChange,
    onRemove,
    setFieldTouched,
    names,
    inventoryFieldValues,
    fields = inventoryFieldsDI,
    autoFocus = true,
    errors
}) => {
    const updateValue = (field: string, value: string) => {
        onChange({ ...condition, [field]: value });
        setFieldTouched(names[field], true);
    };
    const isDate = condition.field === "Date";
    const isMulti = typeof condition.comparator === "string" && multiFields.includes(condition.comparator);
    const conditionValue = isDate ? condition.value : formatToReactSelectValue(condition.value);
    const options = buildSelectOptions(condition.field, inventoryFieldValues);

    useEffect(() => {
        if (!isMulti) {
            const values = condition.value?.split(",");
            if (values && values.length > 1) {
                condition.value = condition.value = values[0];
            }
        }
    }, [condition.comparator, isMulti]);

    const handleFilterValueChange = (value: any) => {
        if (value === null) {
            return;
        }
        // if it's multiSelect we want to format it as csv
        // otherwise just set it to the string
        if (isMulti) {
            updateValue("value", formatToCsv(value));
        } else {
            updateValue("value", String(value.value));
        }
    };

    if (condition.deleted === true) {
        return null;
    }

    return (
        <div className="flex items-center" aria-live="polite">
            <div className="w-1/3">
                <SelectField
                    autoFocus={autoFocus}
                    name={names.field}
                    value={condition.field as string}
                    label="Field"
                    required={true}
                    options={fields.map((item) => ({ label: item, value: item }))}
                    handleBlur={() => setFieldTouched(names.field, true)}
                    handleChange={({ value }: IReactSelectValue) => updateValue("field", value)}
                    errors={errors}
                />
                <ErrorMessage name={names.field} className={errorStyles} component="span" />
            </div>
            <div className="w-1/3 mx-4">
                <SelectField
                    name={names.comparator}
                    value={condition.comparator as string}
                    label="Condition"
                    required={true}
                    options={ConditionalOperators}
                    handleBlur={() => setFieldTouched(names.comparator, true)}
                    handleChange={({ value }: IReactSelectValue) => {
                        updateValue("comparator", value);
                    }}
                />
                <ErrorMessage name={names.comparator} className={errorStyles} component="span" />
            </div>
            <div className="w-1/3">
                {isDate ? (
                    <Field label={"Value"} errors={[]} required={true}>
                        <TextInput
                            autoFocus={autoFocus}
                            name={names.value}
                            value={condition.value as string}
                            type={isDate ? "date" : undefined}
                            onBlur={() => setFieldTouched(names.value, true)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateValue("value", e.target.value)}
                        />
                    </Field>
                ) : (
                    <Field errors={errors?.value} label={"Value"} required={true}>
                        <MultiSelectInput
                            isMulti={isMulti}
                            name={names.value}
                            className="w-full text-base"
                            onBlur={() => setFieldTouched(names.value)}
                            options={options}
                            onChange={handleFilterValueChange}
                            placeholder="Type then press enter..."
                            value={conditionValue as any}
                            ariaLabel={"Value"}
                        />
                    </Field>
                )}
            </div>
            {onRemove && (
                <button
                    className="bg-transparent text-gray-500 hover:text-red-600 p-0 ml-8 h-full align-baseline mt-12"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        onRemove();
                    }}
                >
                    <TrashIcon className="w-6 h-6 text-gray-800 hover:text-gray-600 inline-block" />
                </button>
            )}
        </div>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        inventoryFieldValues: state.inventorySampleData.entities.inventoryFieldValues
    };
};

export default connect(mapStateToProps)(InventoryFilter);
