import React, { Fragment } from "react";
import SelectField from "../../Shared/Form/Blocks/SelectField";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import IDynamicCampaignFormValue from "../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";

interface IProps {
    setFieldValue(key: string, value: any): void;
    setFieldTouched(key: string, value: boolean): void;
    values: IDynamicCampaignFormValue;
}

const refreshPeriods = ["minutes", "hours", "days", "weeks", "months"];
const refreshValues = [...Array(60).keys()].slice(1);

const refreshSelectorOptions = [
    { label: "On Inventory Update", value: "inventory_update" },
    { label: "Every", value: "custom" }
];
const refreshValueOptions = refreshValues.map((value) => ({ label: String(value), value: String(value) }));
const refreshPeriodsOptions = refreshPeriods.map((value) => ({ label: value, value }));

const RefreshSelector: React.FC<IProps> = ({ values, setFieldValue, setFieldTouched }) => {
    const isCustom = values.refreshType === "custom";
    return (
        <Fragment>
            <SelectField
                label="Update Ads"
                name="refreshType"
                required={true}
                options={refreshSelectorOptions}
                value={values.refreshType}
                handleChange={(item: IReactSelectValue) => setFieldValue("refreshType", item.value)}
                handleBlur={() => setFieldTouched("refreshType", true)}
                className={`ml-4 mr-2 ${isCustom ? "w-1/3" : "w-full"}`}
            />
            {isCustom && (
                <Fragment>
                    <SelectField
                        label=""
                        name="refreshValue"
                        options={refreshValueOptions}
                        value={String(values.refreshValue)}
                        handleChange={(item: IReactSelectValue) => setFieldValue("refreshValue", item.value)}
                        handleBlur={() => setFieldTouched("refreshValue", true)}
                        className="w-1/3 mx-2"
                    />
                    <SelectField
                        label=""
                        name="refreshPeriod"
                        options={refreshPeriodsOptions}
                        value={values.refreshPeriod}
                        handleChange={(item: IReactSelectValue) => setFieldValue("refreshPeriod", item.value)}
                        handleBlur={() => setFieldTouched("refreshPeriod", true)}
                        className="w-1/3 mx-2"
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default RefreshSelector;
