import { schema } from "normalizr";

const manufacturerList = new schema.Entity("manufacturers");

const manufacturer = new schema.Entity("manufacturers", {});

export default {
    MANUFACTURER_LIST: { data: [manufacturerList] },
    MANUFACTURER: manufacturer
};
