import React, { Fragment, FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../../Shared/PageTitle/PageTitle";
import Notices from "./Notices";
import Detail from "./Detail";
import { IPreviousAction } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import { fetchDynamicCampaign } from "../../../../actions/dynamicCampaigns/dynamicCampaignActions";
import FullPageLoader from "../../../Shared/Loaders/FullPageLoader";
import { Notification } from "../../../Shared";
import NotRunningError from "../NotRunningError";
import ChevronLeftIcon from "../../../Shared/Icons/ChevronLeftIcon";
import PencilIcon from "../../../Shared/Icons/PencilIcon";
import IAppState from "../../../../interfaces/IAppState";

type IRouteParams = {
    id: string;
    dynamicCampaignId: string;
};

interface IProps {}

const LogContainer: FunctionComponent<IProps> = () => {
    const { dynamicCampaignId = "", id: clientId = "" } = useParams<IRouteParams>();
    const dispatch = useDispatch();
    const client = useSelector(({ clients }: IAppState) => clients.entities[clientId]);
    const dynamicCampaigns = useSelector(({ dynamicCampaigns }: IAppState) => dynamicCampaigns);

    const dynamicCampaign = dynamicCampaigns.entities[dynamicCampaignId];
    const { loading } = dynamicCampaigns;

    useEffect(() => {
        dispatch(fetchDynamicCampaign(Number(clientId), Number(dynamicCampaignId)));
    }, []);

    const PageHeader = () => (
        <PageTitle title={`${dynamicCampaign ? dynamicCampaign.name : "Dynamic Campaign"} - Log`}>
            <div className="flex flex-wrap">
                <Link
                    to={`/client/${clientId}/dynamic-campaigns`}
                    className={`button px-4 py-2 rounded bg-blue-500 text-blue-100 flex items-center`}
                >
                    <ChevronLeftIcon className="w-6 h-6" />
                    <span className="ml-2">All campaigns</span>
                </Link>
                <Link
                    to={`/client/${clientId}/dynamic-campaigns/${dynamicCampaignId}`}
                    className={`button px-4 py-2 ml-4 rounded bg-blue-500 text-blue-100 flex items-center`}
                >
                    <PencilIcon className="w-6 h-6" />
                    <span className="ml-2">Edit Campaign</span>
                </Link>
            </div>
        </PageTitle>
    );

    if (loading || dynamicCampaign === undefined) {
        return (
            <Fragment>
                <div className="my-8">
                    <FullPageLoader message={"Loading Dynamic Campaign Logs..."} />
                </div>
            </Fragment>
        );
    }

    if (dynamicCampaign === null) {
        return (
            <Fragment>
                <PageHeader />

                <NotRunningError client={client} />

                <div className="my-4">
                    <Notification
                        color="red"
                        title="Error:"
                        message="There was a problem loading the dynamic campaign."
                        children={null}
                    />
                </div>
            </Fragment>
        );
    }

    const action: IPreviousAction = dynamicCampaign.previousAction || {
        id: 0,
        executedAt: undefined,
        errors: null,
        warnings: null,
        info: null,
        adGroupsAdded: null,
        adGroupsRemoved: null,
        adGroupsPaused: null,
        adGroupsUpdated: null
    };

    return (
        <div className="px-4">
            <PageHeader />

            <NotRunningError client={client} />

            <div className="flex my-4">
                <div className="w-1/4">
                    <div className="m-auto relative bg-white flex flex-col w-full shadow-md rounded px-4 pt-6 pb-4 mb-8">
                        <div className="font-bold text-2xl text-center text-gray-800 pt-8 pb-4">Latest Action</div>
                        <div className={`text-gray-800 font-bold pb-6 text-lg`}>Executed At</div>
                        <Detail name="Date" value={action.executedAt ? moment(action.executedAt).format("l") : "-"} />
                        <Detail name="Time" value={action.executedAt ? moment(action.executedAt).format("LT") : "-"} />
                        <div className={`text-gray-800 font-bold pt-12 pb-6 text-lg`}>Ad Groups</div>
                        <Detail name="Added" value={action.adGroupsAdded ? action.adGroupsAdded : 0} />
                        <Detail name="Updated" value={action.adGroupsUpdated ? action.adGroupsUpdated : 0} />
                        <Detail name="Paused" value={action.adGroupsPaused ? action.adGroupsPaused : 0} />
                        <Detail name="Removed" value={action.adGroupsRemoved ? action.adGroupsRemoved : 0} />
                    </div>
                </div>
                <div className="w-3/4">
                    <div className="ml-4">
                        <Notices
                            warnings={action.warnings ? action.warnings : []}
                            errors={action.errors ? action.errors : []}
                            info={action.info ? action.info : []}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogContainer;
