import React from "react";
import { capitalize } from "lodash";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import Button, { SECONDARY_BUTTON } from "../../../Shared/Button";
import ItemProperties from "./ItemProperties";

interface IProps {
    log: {
        item: { [key: string]: any };
        errors: {
            [key: string]: string | string[];
        };
        rejections: {
            [key: string]: string | string[];
        };
    };
}

const ExportLog: React.FunctionComponent<IProps> = ({ log }) => {
    return (
        <div
            className={`border-l-4 border-r border-t border-b mt-6 p-4 flex items-center text-gray-800`}
            style={{ borderLeftColor: "#2b6cb0" }}
        >
            <div className="w-1/4 text-base font-medium">{log.item.vehicleId || log.item.id2Template}</div>
            <div className="w-1/4 text-xs">
                {Object.keys(log.errors).map((key, i) => {
                    return (
                        <span key={i}>
                            {capitalize(key.split(".")[0])}
                            {Object.keys(log.errors).length - 1 !== i ? ", " : ""}
                        </span>
                    );
                })}
            </div>
            <div className="w-1/4 text-xs">
                {Object.keys(log.rejections).map((key, i) => {
                    return (
                        <span key={i}>
                            {capitalize(key.split(".")[0])}
                            {Object.keys(log.rejections).length - 1 !== i ? ", " : ""}
                        </span>
                    );
                })}
            </div>
            <div className="w-1/4 flex justify-end">
                <ModalToggle
                    style={{ maxHeight: "90vh" }}
                    className="w-1/4"
                    toggleButton={(open: () => void) => (
                        <button
                            onClick={open}
                            className="border rounded-full rounded-full bg-gray-600 h-8 w-8 text-white flex justify-center align-center font-xs hover:bg-gray-700"
                        >
                            <span className="">i</span>
                        </button>
                    )}
                    modalContent={(hide: () => void) => (
                        <>
                            <div className="text-xl">Details</div>
                            <p className="py-6">
                                <span className="font-bold">Vehicle Id: </span>
                                <span>{log.item.vehicleId || log.item.id2Template}</span>
                            </p>

                            {Object.keys(log.errors).map((key, i) => {
                                return (
                                    <p key={i}>
                                        <span>{log.errors[key]}</span>
                                    </p>
                                );
                            })}

                            {Object.keys(log.rejections).map((key, i) => {
                                return (
                                    <p key={i}>
                                        <span>{log.rejections[key]}</span>
                                    </p>
                                );
                            })}

                            <ItemProperties
                                item={log.item}
                                errors={[
                                    ...Object.keys(log.rejections).map((key) => key.split(".")[0]),
                                    ...Object.keys(log.errors).map((key) => key.split(".")[0])
                                ]}
                            />

                            <div className="flex flex-right items-center my-4">
                                <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                                    Close
                                </Button>
                            </div>
                        </>
                    )}
                />
            </div>
        </div>
    );
};

export default ExportLog;
