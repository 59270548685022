import Constants from "../constants/OEMReportConstants";
import * as Interfaces from "../interfaces/IReport";
import { IReportFormValues } from "../interfaces/IReport";

export const fetchReportList = (userId: any, limit: any, page: number) => ({
    type: Constants.FETCH_REPORT_LIST,
    payload: { userId, limit, page }
});

export const fetchReportListSuccessEmpty = (reportJobs: any): Interfaces.IFetchReport => ({
    type: Constants.FETCH_REPORT_LIST_SUCCESS_EMPTY,
    payload: reportJobs
});

export const fetchReportListSuccess = (reportJobs: any): Interfaces.IFetchReport => ({
    type: Constants.FETCH_REPORT_LIST_SUCCESS,
    payload: reportJobs
});

export const fetchReportListsFailure = (reportJobs: any): Interfaces.IFetchReport => ({
    type: Constants.FETCH_REPORT_LIST_FAILURE,
    payload: reportJobs
});

export const createReportJob = (reportFormValues: IReportFormValues) => ({
    type: Constants.CREATE_REPORT_JOBS,
    payload: reportFormValues
});

export const createReportJobSuccess = (reportJob: []): Interfaces.ICreateReportSuccess => ({
    type: Constants.CREATE_REPORT_JOBS_SUCCESS,
    payload: reportJob
});

export const createReportJobFailure = (errors: any) => ({
    type: Constants.CREATE_REPORT_JOBS_FAILURE,
    payload: errors
});
