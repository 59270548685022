// inspired by: https://codereview.stackexchange.com/questions/148363/time-delayed-function-queue

class TimerQueue {
    queue: Array<() => void> = [];
    delay: number = 300;
    running: boolean = false;
    timeout: NodeJS.Timeout | null = null;

    constructor(delay?: number) {
        if (delay) {
            this.delay = delay;
        }
    }

    add(func: () => void) {
        this.queue.push(func);
        if (!this.running) {
            this.start();
        }
    }

    clear() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = null;
        this.running = false;
        this.queue = [];
    }

    start() {
        this.running = true;
        const next = this.queue.shift();
        if (!next) {
            return this.clear();
        }
        this.timeout = setTimeout(() => {
            next();
            this.timeout = null;
            this.start();
        }, this.delay);
    }
}

export default TimerQueue;
