import React, { FunctionComponent, useState, useRef } from "react";
import { Calendar } from "react-date-range";
import moment from "moment";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import CloseIcon from "./Icons/CloseIcon";

interface IDatePicker {
    date: string | null;
    onValueChange: any;
    clearable: boolean;
    name: string;
    format?: string;
    minDate?: string;
    style?: any;
    ariaLabel?: string;
}

const DatePicker: FunctionComponent<IDatePicker> = ({
    date,
    onValueChange,
    clearable = false,
    name,
    format = "YYYY-MM-DD",
    minDate,
    style,
    ariaLabel
}) => {
    const ref = useRef(null);
    const [showPicker, setShowPicker] = useState(false);
    const dateObject = date && moment(date, format, true);
    const formattedDate = dateObject && dateObject.isValid() ? dateObject.format(format) : date;

    useOnClickOutside(ref, () => setShowPicker(false));

    return (
        <div ref={ref} className="relative" style={style}>
            <input
                autoComplete="off"
                type="text"
                name={name}
                className="w-full px-3 py-3 text-base border border-gray-500 rounded"
                value={formattedDate || ""}
                onChange={onValueChange}
                aria-label={ariaLabel}
                onClick={() => setShowPicker(true)}
                onFocus={() => setShowPicker(true)}
            />
            {date && clearable && (
                <div className="absolute inset-y-0 right-0 mt-2 mx-4 py-2">
                    <button
                        className="cursor-pointer text-gray-600"
                        onClick={() => {
                            setShowPicker(false);
                            onValueChange({ target: { name, value: null } });
                        }}
                    >
                        <CloseIcon className="w-6 h-6" />
                    </button>
                </div>
            )}
            {showPicker && (
                <div className="bg-blue-900 absolute my-4 p-4 rounded shadow" style={{ zIndex: 1000 }}>
                    <Calendar
                        date={date && dateObject && dateObject.isValid() ? dateObject : moment()}
                        minDate={minDate}
                        onChange={(e: any) => {
                            setShowPicker(false);
                            onValueChange({ target: { name, value: e.format(format) } });
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default DatePicker;
