import Echo from "laravel-echo";
import { getApiKey } from "../api";
import Config from "../config";
const configs = Config.getInstance();

declare global {
    interface Window {
        Pusher: any;
        Echo: any;
    }
}

// tslint:disable-next-line:no-var-requires
window.Pusher = require("pusher-js");

function InitEcho() {
    window.Echo = new Echo({
        auth: {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getApiKey()}`
            }
        },
        authEndpoint: `${configs.get("API_URL")}/broadcasting/auth`,
        broadcaster: "pusher",
        cluster: configs.get("PUSHER_CLUSTER"),
        forceTLS: true,
        key: configs.get("PUSHER_KEY")
    });
}

function DisconnectEcho() {
    if (window.Echo) {
        window.Echo.disconnect();
    }
}

export { InitEcho, DisconnectEcho };
