import { call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import UsersConstants from "../constants/UsersConstants";
import UsersActions from "../actions/UsersActions";
import { addFlashMessage } from "../actions/flashMessageActions";
import { fetchCurrentUser } from "./userSagas";
import { NavigateFunction } from "react-router-dom";

import SCHEMAS from "../middleware/schemas";
import IUser from "../interfaces/IUser";
import IUserApi from "../interfaces/IUserApi";
import IUserSettings from "../interfaces/IUserSettings";
import buildUrl from "../utils/UrlUtils";
import IPagedResponse from "../interfaces/IPagedResponse";

interface IDeleteUser {
    type: string;
    payload: {
        user: IUser;
    };
}

interface ICreateUser {
    sendActivationEmail: boolean;
    user: IUserApi;
    navigate?: NavigateFunction;
}

interface IUserPayload<T> {
    type: string;
    payload: T;
    navigate?: NavigateFunction;
}

interface IUpdateUser {
    user: IUserApi;
}

export function* fetchUsers(action?: any): Generator<any, any, any> {
    const url = buildUrl("/users", {
        expand: {
            roles: "*"
        },
        ...(action?.payload?.searchValue
            ? {
                  search: {
                      email: "like,*" + action?.payload?.searchValue + "*"
                  }
              }
            : {}),
        limit: 100
    });

    try {
        const response = yield call(callApi, url, SCHEMAS.USER_ARRAY);
        const { user } = response.entities;
        yield put(UsersActions.fetchUsersSuccess(user || {}));
    } catch (e) {
        yield put(UsersActions.fetchUsersFail());
    }
}

export function* deleteUser({ payload }: IDeleteUser) {
    const url = `/users/${payload.user.id}`;
    try {
        yield call(callApi, url, SCHEMAS.USER_ARRAY, "DELETE", payload.user);
        yield put(UsersActions.requestDeleteUserSuccess(payload.user.id));
    } catch (e) {
        yield put(UsersActions.requestDeleteUserFail(e));
    }
}

export function* createUser({ payload, navigate }: IUserPayload<ICreateUser>) {
    const usersUrl = `/users`;

    try {
        yield call(callApi, usersUrl, SCHEMAS.USER_ARRAY, "POST", payload.user);
        yield put(UsersActions.createUserSuccess());
        navigate && navigate("/users");
    } catch (e) {
        yield put(UsersActions.createUserFail(e));
    }
}

export function* fetchUserRoles(): Generator<any, any, any> {
    const url = `/user-roles?limit=100`;
    try {
        const response: IPagedResponse = yield call(callApi, url, SCHEMAS.USER_ROLES_ARRAY);
        const { roles } = response.entities;
        yield put(UsersActions.requestUserRolesSuccess(roles));
    } catch (e) {
        yield put(UsersActions.requestUserRolesFail(e));
    }
}

export function* fetchUser({ payload }: any): Generator<any, any, any> {
    const url = `/users/${payload}?expand[roles]=*`;
    try {
        const response = yield call(callApi, url, SCHEMAS.USER);
        const { user } = response.entities;
        yield put(UsersActions.requestUserSuccess(user[response.result]));
    } catch (e) {
        yield put(UsersActions.requestUserFail(e));
    }
}

export function* updateUser({ payload, navigate }: IUserPayload<IUpdateUser>) {
    const usersUrl = `/users/${payload.user.id}`;
    try {
        yield call(callApi, usersUrl, SCHEMAS.USER_ARRAY, "PATCH", payload.user);
        yield put(UsersActions.updateUserSuccess());
        navigate && navigate("/users");
    } catch (e) {
        yield put(UsersActions.updateUserFail(e));
    }
}

export function* saveSettings({ payload }: IUserPayload<IUserSettings>) {
    const url = `/users/${payload.id}`;
    delete payload.id;
    try {
        yield call(callApi, url, {}, "PATCH", payload);
        yield call(fetchCurrentUser);
        yield put(UsersActions.requestSaveSettingsSuccess());
        const message = { type: "success", text: `Your settings have successfully been changed.` };
        yield put(addFlashMessage(message));
    } catch (e) {
        yield put(UsersActions.requestSaveSettingsFail(e));
        yield put(addFlashMessage({ type: "danger", text: e.message }));
    }
}

export function* activateUser({ payload }: any) {
    const url = `/auth/activate`;
    try {
        yield call(callApi, url, {}, "POST", payload);
        yield put(UsersActions.requestActivateUserSuccess());
        yield put(addFlashMessage({ type: "success", text: "Successfully activated account!" }));
        yield window.location.assign(window.location.origin);
    } catch (e) {
        yield put(UsersActions.requestActivateUserFail(e));
        yield put(addFlashMessage({ type: "danger", text: e.message }));
    }
}

export function* sendResetPasswordEmail({ payload }: IUserPayload<{ type: string; email: string }>) {
    const url = `/auth/send-password-reset-email`;
    try {
        yield call(callApi, url, {}, "POST", payload);
        yield put(UsersActions.requestPasswordResetEmailSuccess());
        yield put(
            addFlashMessage({
                type: "success",
                text: "If you do not receive password reset instructions via email, please contact support."
            })
        );
    } catch (e) {
        yield put(UsersActions.requestPasswordResetEmailFail(e));
    }
}

export function* resetUserPassword({ payload }: IUserPayload<{ password: string; token: string }>) {
    const url = `/auth/reset-password`;
    try {
        yield call(callApi, url, {}, "POST", payload);
        yield put(UsersActions.requestPasswordResetSuccess());
        yield put(addFlashMessage({ type: "success", text: "Successfully reset password!" }));
        yield window.location.assign(window.location.origin);
    } catch (e) {
        yield put(UsersActions.requestPasswordResetFail(e));
        yield put(addFlashMessage({ type: "danger", text: e.message }));
    }
}

function* usersSaga() {
    yield takeEvery(UsersConstants.FETCH_USERS, fetchUsers);
    yield takeEvery(UsersConstants.REQUEST_USER, fetchUser);
    yield takeEvery(UsersConstants.REQUEST_USER_ROLES, fetchUserRoles);
    yield takeEvery(UsersConstants.REQUEST_CREATE_USER, createUser);
    yield takeEvery(UsersConstants.REQUEST_UPDATE_USER, updateUser);
    yield takeEvery(UsersConstants.REQUEST_DELETE_USER, deleteUser);
    yield takeEvery(UsersConstants.REQUEST_SAVE_SETTINGS, saveSettings);
    yield takeEvery(UsersConstants.REQUEST_ACTIVATE_USER, activateUser);
    yield takeEvery(UsersConstants.REQUEST_RESET_PASSWORD, resetUserPassword);
    yield takeEvery(UsersConstants.REQUEST_RESET_PASSWORD_EMAIL, sendResetPasswordEmail);
}
export default usersSaga;
