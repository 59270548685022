import IFacebookAutoExportType, {
    IFetchFacebookLogsSuccessAction,
    IFetchFacebookLogsFailureAction,
    IFetchFacebookLogsAction
} from "../../interfaces/IFacebookAutoExport";
import {
    FETCH_FACEBOOK_LOGS,
    FETCH_FACEBOOK_LOGS_FAILURE,
    FETCH_FACEBOOK_LOGS_SUCCESS
} from "../../constants/InventoryExportsConstants";
import IEntity from "../../interfaces/IEntity";
import IFacebookLog from "../../interfaces/IFacebookLog";

export const REQUEST_LOAD_FB_AUTO_EXPORT = "REQUEST_LOAD_FB_AUTO_EXPORT";
export const LOAD_FB_AUTO_EXPORT = "LOAD_FB_AUTO_EXPORT";
export const REQUEST_FB_AUTO_EXPORT_FAILURE = "REQUEST_FB_AUTO_EXPORT_FAILURE";
export const REQUEST_LOAD_FB_AUTO_EXPORTS = "REQUEST_LOAD_FB_AUTO_EXPORTS";
export const LOAD_FB_AUTO_EXPORTS = "LOAD_FB_AUTO_EXPORTS ";
export const REQUEST_FB_AUTO_EXPORTS_FAILURE = "REQUEST_FB_AUTO_EXPORTS_FAILURE";
export const REQUEST_CREATE_FB_AUTO_EXPORT = "REQUEST_CREATE_FB_AUTO_EXPORT";

export const createfacebookAutoExport = (facebookAutoExport: IFacebookAutoExportType) => ({
    type: REQUEST_CREATE_FB_AUTO_EXPORT,
    facebookAutoExport
});

export const loadFacebookAutoExport = (facebookAutoExport: IFacebookAutoExportType) => ({
    type: LOAD_FB_AUTO_EXPORT,
    facebookAutoExport
});

export const fetchFacebookLogs = (clientId: number, exportId: number): IFetchFacebookLogsAction => ({
    type: FETCH_FACEBOOK_LOGS,
    payload: {
        clientId,
        exportId
    }
});

export const fetchFacebookLogsSuccess = (
    logs: IEntity<IFacebookLog>,
    allIds: number[]
): IFetchFacebookLogsSuccessAction => ({
    type: FETCH_FACEBOOK_LOGS_SUCCESS,
    payload: {
        logs,
        allIds
    }
});

export const fetchFacebookLogsFailure = (error: any): IFetchFacebookLogsFailureAction => ({
    type: FETCH_FACEBOOK_LOGS_FAILURE,
    payload: {
        error
    }
});
