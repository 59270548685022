import React, { SVGProps } from "react";
import Svg from "./Svg";

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M18.5 9.333h-.917V7.5A4.585 4.585 0 0013 2.917 4.585 4.585 0 008.417 7.5v1.833H7.5a1.839 1.839 0 00-1.833 1.834v9.166c0 1.009.825 1.834 1.833 1.834h11a1.839 1.839 0 001.833-1.834v-9.166A1.839 1.839 0 0018.5 9.333zm-5.5 8.25a1.839 1.839 0 01-1.833-1.833c0-1.008.825-1.833 1.833-1.833s1.833.825 1.833 1.833A1.839 1.839 0 0113 17.583zm2.842-8.25h-5.684V7.5A2.844 2.844 0 0113 4.658 2.844 2.844 0 0115.842 7.5v1.833z" />
    </Svg>
);

export default LockIcon;
