import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import schemas from "../middleware/schemas/manufacturer";
import { fetchManufacturerListFailure, fetchManufacturerListSuccess } from "../actions/ManufacturerActions";
import AutomobileManufacturerConstants from "../constants/AutomobileManufacturerConstants";

interface IFetchManufacturerListSuccessResponse {
    entities: any;
}

export function* fetchManufacturerList() {
    const url = `/automobile-manufacturers?limit=100`;
    try {
        const response: IFetchManufacturerListSuccessResponse = yield call(callApi, url, schemas.MANUFACTURER_LIST);
        yield put(fetchManufacturerListSuccess(response.entities.manufacturers));
    } catch (error) {
        yield put(fetchManufacturerListFailure(error.message));
    }
}

function* dealerSetupSagas() {
    yield takeLatest(AutomobileManufacturerConstants.REQUEST_AUTOMOBILE_MANUFACTURERS, fetchManufacturerList);
}

export default dealerSetupSagas;
