/**
 * Created by fjiles@dealerinspire.com on 2/10/17.
 */
import * as actions from "../../actions/salesData";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";
import { AnyAction } from "redux";
const reducer = (state = { mapZoom: 6, mapCenter: undefined }, action: AnyAction) => {
    switch (action.type) {
        case actions.UPDATE_MAP_BOUNDS:
            return { mapZoom: action.zoom, mapCenter: action.center };
        case RESET_CLIENT_STATE:
            return {
                mapZoom: 6,
                mapCenter: undefined
            };
        default:
            return state;
    }
};

export default reducer;
