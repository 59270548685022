import moment from "moment";
import CalendarIcon from "../Icons/CalendarIcon";
import NotVisibleIcon from "../Icons/NotVisibleIcon";
import VideoIcon from "../Icons/VideoIcon";
import PauseIcon from "../Icons/PauseIcon";
import IBudgetApi, { IBudgetExpandedClient } from "../../../interfaces/Budgets/IBudgetApi";
import IKoddiBudget from "../../../interfaces/Budgets/IKoddiBudget";

import IClient from "../../../interfaces/IClient";
const ID_OK = 1;
const ID_CREATED = 2;
const ID_UNDER_PERFORMING = 3;
const ID_BREACH = 4;
const ID_ERROR = 5;
const ID_ALL_PAUSED = 6;
const ID_EVENT_ENDED = 7;
const ID_NO_CAMPAIGNS = 8;
const ID_BREACH_FAILED = 9;
const ID_BREACH_RECOVERY = 10;
const ID_STALE = 11;
const ID_TYPE_EVENT = 2;

// filter table data by text input
const textFilter = (
    searchText: string,
    budgets: {
        [key: string]: IBudgetExpandedClient;
    }
) => {
    if (!searchText) {
        return budgets;
    }
    const text = searchText.toLowerCase();
    return Object.keys(budgets)
        .filter((key) => filterConditions(text, budgets[key]))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key]: budgets[key]
            };
        }, {});
};

// callback for text filter
const filterConditions = (searchText: string, budget: IBudgetExpandedClient) => {
    const { name, clientServicesPod } =
        budget?.facebookAccount?.clients && budget?.facebookAccount?.clients.length > 0
            ? budget?.facebookAccount?.clients[0]
            : (budget.client as IClient);
    const { facebookCampaignName } = budget;
    const { label } = budget?.activation ? budget?.activation : budget;
    const manager = budget.client ? getManagerFullName(budget.client) : "";

    const fuzzySearch = (field: string[]) =>
        field && field.filter((value) => value && value.toLowerCase().includes(searchText)).length > 0;

    return fuzzySearch([name ?? "", clientServicesPod ?? "", label ?? "", facebookCampaignName ?? "", manager]);
};

const koddiTextFilter = (
    searchText: string,
    budgets: {
        [key: string]: IKoddiBudget;
    }
) => {
    if (!searchText || !budgets) {
        return budgets;
    }
    const lowerSearchText = searchText.toLowerCase();

    return Object.keys(budgets)
        .filter((key) => filterKoddiConditions(lowerSearchText, budgets[key]))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key]: budgets[key]
            };
        }, {});
};

const filterKoddiConditions = (searchText: string, budget: IKoddiBudget) => {
    const { clientName, csPod, campaignName, manager } = budget;
    const fuzzySearch = (field: string[]) =>
        field && field.filter((value) => value && value.toLowerCase().includes(searchText)).length > 0;

    return fuzzySearch([clientName ?? "", csPod ?? "", campaignName ?? "", manager ?? ""]);
};

// filters data based on array of select options
const filterBySelected = (options: any[], data: any) => {
    const count = options.filter((option) => option.selected).length;

    // if there is a count and the count does not equal the total number of potential options,
    // filter the data, otherwise return it all
    // filtering is inclusive
    if (count && count !== options.length) {
        return Object.keys(data).reduce((acc: { [x: string]: any }, key) => {
            const budget = data[key];
            if (options.find((option) => option.label === "Deleted").selected && getDeleted(budget.deletedAt)) {
                acc[key] = budget;
            }
            if (
                !!budget.activation &&
                options.find((option) => option.label === "Video").selected &&
                isVideo(budget.activation.label)
            ) {
                acc[key] = budget;
            }
            if (options.find((option) => option.label === "Breached").selected && getBreached(budget.statusId)) {
                acc[key] = budget;
            }
            if (options.find((option) => option.label === "All Paused").selected && getAllPaused(budget.statusId)) {
                acc[key] = budget;
            }
            if (
                !!budget.activation &&
                options.find((option) => option.label === "Managed Manually").selected &&
                budget.activation.isManagedManually
            ) {
                acc[key] = budget;
            }
            if (
                !!budget.activation &&
                options.find((option) => option.label === "Event").selected &&
                getIsEvent(budget.activation.typeId)
            ) {
                acc[key] = budget;
            }
            return acc;
        }, {});
    }
    return data;
};

// build filter button title based on selected filters
const buildTitle = (options: any[]) => {
    const optionLength = options.length;
    const activeOptions = options.filter((option) => !!option.selected);
    if (activeOptions.length === optionLength || !activeOptions.length) {
        return "All Budgets";
    } else if (activeOptions.length === 1) {
        return activeOptions[0].label;
    } else if (activeOptions.length === 2) {
        return activeOptions.map((option) => option.label).join(" + ");
    } else if (activeOptions.length > 2) {
        return `${activeOptions[0].label} + ${activeOptions.length - 1}`;
    }
};

// returns icons for the type column based on budget data
const getTypeCellItems = (typeId: number, statusId: number, isManagedManually: boolean, label: string) => {
    const cellItems = [];

    if (getIsEvent(typeId)) {
        const color = getCompleted(statusId) ? "text-red-500" : "text-green-500";
        cellItems.push({ icon: <CalendarIcon className={`w-6 h-6 ${color}`} /> });
    }

    if (isManagedManually) {
        cellItems.push({ icon: <NotVisibleIcon className="w-6 h-6 text-gray-500" /> });
    }

    if (isVideo(label)) {
        cellItems.push({ icon: <VideoIcon className="w-6 h-6 text-gray-500" /> });
    }

    if (getBreached(statusId)) {
        cellItems.push({ icon: <PauseIcon className="w-6 h-6 text-yellow-600" /> });
    }

    if (getAllPaused(statusId)) {
        cellItems.push({ icon: <PauseIcon className="w-6 h-6 text-red-500" /> });
    }

    return cellItems;
};

const getDaysInPeriod = (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
    if (!startDate || !endDate) {
        return moment().daysInMonth();
    }

    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, "days");
};

const isVideo = (label: string) => typeof label === "string" && label.toLowerCase().includes("video");
const getAllPaused = (status: number) => status === ID_ALL_PAUSED;
const getCompleted = (status: number) => status === ID_EVENT_ENDED;
const getDeleted = (deleted: any) => !!deleted;
const getIsEvent = (type: number) => type === ID_TYPE_EVENT;
const getBreached = (status: number) => status === ID_BREACH;
const getDiff = (idealSpends: number, mtdSpend: number) => mtdSpend - idealSpends;

// returns the total for column
const getColumnTotal = (data: any, column: React.ReactText) => {
    return data.reduce((acc: number, value: { [x: string]: any }) => {
        if (value[column]) {
            if (typeof value[column] === "string") {
                value[column] = value[column].replace(/,/g, "");
            }

            acc += parseFloat(value[column]);
        }
        return acc;
    }, 0);
};

// returns the mean average for the column
const getColumnMeanAverage = (data: any, column: React.ReactText) => {
    if (data.length < 1) {
        return 0;
    }

    return getColumnTotal(data, column) / data.length;
};

// returns the amount spent during this event or month not including today
const getMTDSpends: ({
    todaySpends,
    eventSpends,
    monthSpends
}: {
    todaySpends: any;
    eventSpends: any;
    monthSpends: any;
}) => number = ({ todaySpends, eventSpends, monthSpends }) => {
    // if there is no todaySpends, return eventSpends if it's not null or monthSpends if its not null or 0

    const todaySpendsValue = typeof todaySpends === "number" ? todaySpends : 0;
    const periodSpends = typeof eventSpends === "number" ? eventSpends : monthSpends;
    return typeof periodSpends === "number" ? periodSpends - todaySpendsValue : 0;
};
const getLastChange = (oldValue: number, newValue: number) => newValue - oldValue;
const getIdealDailyBudget = (budget: number, days: number) => +(budget / days).toFixed(2);

// returns difference deatils to display in table columns
const getDiffDetails = (diff: number, idealDailyBudget: number) => {
    const abs = Math.abs(diff);

    if (diff > 0 && abs >= idealDailyBudget * 2) {
        // error - diff is really ahead
        // the difference is ahead by twice as much as the ideal daily budget
        return { title: diff, type: "error" };
    } else if (diff < 0 && abs > idealDailyBudget) {
        // warn - diff is behind
        // the difference is behind the daily budget
        return { title: diff, type: "warn" };
    } else if (diff > 0 && abs >= idealDailyBudget) {
        // warn - diff is ahead
        // the differnce is ahead but not by more than the amount of the daily budget
        return { title: diff, type: "warn" };
    } else {
        // diff is okay
        // diff is not that far behind or ahead
        return { title: diff, type: "success" };
    }
};

// gets status deatils to display in table columns
const getStatusDetails = (status: number) => {
    switch (status) {
        case ID_OK:
            return { id: ID_OK, title: "Okay", type: "success" };
        case ID_CREATED:
            return { id: ID_CREATED, title: "Created", type: "new" };
        case ID_UNDER_PERFORMING:
            return {
                id: ID_UNDER_PERFORMING,
                title: "Under Performing",
                type: "warn"
            };
        case ID_BREACH:
            return { id: ID_BREACH, title: "Breach", type: "error" };
        case ID_ERROR:
            return { id: ID_ERROR, title: "Error", type: "error" };
        case ID_ALL_PAUSED:
            return { id: ID_ALL_PAUSED, title: "All Paused", type: "warn" };
        case ID_EVENT_ENDED:
            return { id: ID_EVENT_ENDED, title: "Event Ended", type: "error" };
        case ID_NO_CAMPAIGNS:
            return { id: ID_NO_CAMPAIGNS, title: "No Campaigns", type: "warn" };
        case ID_BREACH_FAILED:
            return { id: ID_BREACH_FAILED, title: "Breach (Failed)", type: "error" };
        case ID_BREACH_RECOVERY:
            return { id: ID_BREACH_RECOVERY, title: "Breach (Recovering)", type: "warn" };
        case ID_STALE:
            return { id: ID_STALE, title: "Stale", type: "error" };
        default:
            return { id: 0, title: "Not Defined", type: "error" };
    }
};

const hasUpdateActionReadyToRun = (budget: IBudgetApi) => {
    return budget.nextUpdateActionShouldRunAt && moment(budget.nextUpdateActionShouldRunAt) <= moment();
};

const wasRecentlyExecuted = (budget: IBudgetApi) => {
    if (!budget.lastUpdateActionExecutedAt) {
        return false;
    }
    const recentMins = 1;
    const lastExecutedDateObj = moment(budget.lastUpdateActionExecutedAt);
    const diffInMinutes = lastExecutedDateObj.diff(moment(), "minutes"); // rounded down
    const updatedMinsAgo = Math.abs(diffInMinutes);

    return updatedMinsAgo <= recentMins;
};

const getManagerFullName = (client: Partial<IClient>) => {
    return !client || !client.manager ? "" : `${client.manager.firstName} ${client.manager.lastName}`;
};

const getPerformanceManagerFullName = (client: Partial<IClient>) => {
    return !client || !client.pfm ? "" : `${client.pfm.firstName} ${client.pfm.lastName}`;
};

const getFacebookIdealSpend = (budget: number) => {
    return budget ? (budget / moment().daysInMonth()) * +moment().format("D") : 0;
};

const getParentAccountName = (client: Partial<IClient>) => {
    return !client || !client.parentSalesforceName
        ? ""
        : `${client.parentSalesforceName} ${client.parentSalesforceName}`;
};

export {
    wasRecentlyExecuted,
    getManagerFullName,
    getPerformanceManagerFullName,
    getParentAccountName,
    hasUpdateActionReadyToRun,
    getStatusDetails,
    getDiffDetails,
    getIdealDailyBudget,
    getLastChange,
    getMTDSpends,
    getColumnTotal,
    getColumnMeanAverage,
    isVideo,
    getAllPaused,
    getCompleted,
    getDeleted,
    getIsEvent,
    getBreached,
    getDiff,
    getDaysInPeriod,
    textFilter,
    filterBySelected,
    buildTitle,
    getTypeCellItems,
    getFacebookIdealSpend,
    koddiTextFilter
};
