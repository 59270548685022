import { omit, union, set, without, forIn, pull } from "lodash";
import Constants from "../../constants/shared/ItemCollectionConstants";
import * as Interfaces from "../../interfaces/shared/IItemCollection";
import { Reducer, AnyAction } from "redux";

const fetchItems = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${action.payload.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    // clear state when switching to a new collection
    if (state.collectionId && action.payload.collectionId !== state.collectionId) {
        return {
            ...state,
            searchTerms: {
                _: {
                    results: [],
                    loadedPages: 0,
                    totalPages: 0,
                    loading: true,
                    loadingError: null
                }
            },
            entities: {},
            searchTerm: action.payload.searchTerm,
            collectionId: 0,
            added: [],
            deleted: [],
            errors: {}
        };
    }

    return {
        ...state,
        searchTerms: {
            ...results,
            [searchTerm]: {
                ...results[searchTerm],
                loading: true
            }
        },
        searchTerm: action.payload.searchTerm
    };
};

const fetchItemsSuccess = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${action.payload.searchTerm}`;
    const result = action.payload.response.result;

    return {
        ...state,
        searchTerms: {
            ...state.searchTerms,
            [searchTerm]: {
                results: union(state.searchTerms[searchTerm].results, result.data),
                loadedPages: result.currentPage,
                totalPages: result.lastPage,
                loading: false,
                loadingError: null
            }
        },
        entities: { ...state.entities, ...action.payload.response.entities.items },
        collectionId: action.payload.collectionId
    };
};

const fetchItemsFailure = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${action.payload.searchTerm}`;

    return {
        ...state,
        searchTerms: {
            ...state.searchTerms,
            [searchTerm]: {
                ...state.searchTerms[searchTerm],
                loading: false,
                loadingError: `Failed to fetch page ${state.searchTerms[searchTerm].loadedPages + 1}`
            }
        }
    };
};

const searchItems = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${action.payload.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    // If any items have been added or removed we clear the search term state so the searches will run again
    if (state.added.length || state.deleted.length) {
        return {
            ...state,
            searchTerms: {
                [searchTerm]: {
                    results: [],
                    loadedPages: 0,
                    totalPages: 0,
                    loading: true,
                    loadingError: null
                }
            },
            searchTerm: action.payload.searchTerm,
            added: [],
            deleted: [],
            errors: {}
        };
    }

    // New search term
    if (state.searchTerms[searchTerm] === undefined) {
        return {
            ...state,
            searchTerms: {
                ...results,
                [searchTerm]: {
                    results: [],
                    loadedPages: 0,
                    totalPages: 0,
                    loading: true,
                    loadingError: null
                }
            },
            searchTerm: action.payload.searchTerm
        };
    }

    return {
        ...state,
        searchTerm: action.payload.searchTerm
    };
};

const addItem = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${state.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    return {
        ...state,
        searchTerms: {
            ...results,
            [searchTerm]: {
                ...results[searchTerm],
                loading: true
            }
        },
        entities: {
            [action.payload.dispatchedAt]: {
                id: action.payload.dispatchedAt,
                name: action.payload.itemName,
                createdAt: action.payload.dispatchedAt,
                new: true
            },
            ...state.entities
        },
        added: [action.payload.dispatchedAt, ...state.added]
    };
};

const addItemSuccess = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${state.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    return {
        ...state,
        searchTerms: {
            ...results,
            [searchTerm]: {
                ...results[searchTerm],
                loading: false
            }
        },
        entities: { ...action.payload.item, ...omit(state.entities, action.payload.dispatchedAt) },
        added: [action.payload.itemId, ...without(state.added, action.payload.dispatchedAt)]
    };
};

const addItemFailure = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${state.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    return {
        ...state,
        searchTerms: {
            ...results,
            [searchTerm]: {
                ...results[searchTerm],
                loading: false
            }
        },
        errors: {
            [`${action.payload.dispatchedAt}`]: {
                message: action.payload.errorMessage,
                type: Interfaces.ActionErrorType.Add
            },
            ...state.errors
        }
    };
};

const removeItem = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${state.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    return {
        ...state,
        searchTerms: {
            ...results,
            [searchTerm]: {
                ...results[searchTerm],
                loading: true
            }
        },
        deleted: [...state.deleted, action.payload]
    };
};

const removeItemSuccess = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${state.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    return {
        ...state,
        searchTerms: {
            ...results,
            [searchTerm]: {
                ...results[searchTerm],
                results: without(results[searchTerm].results, action.payload),
                loading: false
            }
        },
        entities: omit(state.entities, action.payload),
        added: without(state.added, action.payload)
    };
};

const removeItemFailure = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    const searchTerm = `_${state.searchTerm}`;
    const results: Interfaces.ISearchTerms = state.searchTerms;

    return {
        ...state,
        searchTerms: {
            ...results,
            [searchTerm]: {
                ...results[searchTerm],
                loading: false
            }
        },
        errors: {
            [`${action.payload.itemId}`]: {
                message: action.payload.errorMessage,
                type: Interfaces.ActionErrorType.Remove
            },
            ...state.errors
        }
    };
};

const dismissItemActionError = (state: Interfaces.IItemsState, action: AnyAction): Interfaces.IItemsState => {
    if (action.payload.errorType === Interfaces.ActionErrorType.Add) {
        return {
            ...state,
            entities: omit(state.entities, action.payload.itemId),
            errors: omit(state.errors, action.payload.itemId),
            added: without(state.added, action.payload.itemId)
        };
    }
    return {
        ...state,
        errors: omit(state.errors, action.payload.itemId),
        deleted: without(state.deleted, action.payload.itemId)
    };
};

export default function items(typeIdentifier: string): Reducer<Interfaces.IItemsState> {
    const initialState: Interfaces.IItemsState = {
        searchTerms: {
            _: {
                results: [],
                loadedPages: 0,
                totalPages: 0,
                loading: false,
                loadingError: null
            }
        },
        entities: {},
        searchTerm: "",
        collectionId: 0,
        deleted: [],
        added: [],
        errors: {},
        uploading: false
    };

    return function reducer(state = initialState, action: AnyAction): Interfaces.IItemsState {
        switch (action.type) {
            case `${Constants.FETCH_ITEM_COLLECTION}_${typeIdentifier}`:
                return fetchItems(state, action);
            case `${Constants.FETCH_ITEM_COLLECTION_SUCCESS}_${typeIdentifier}`:
                return fetchItemsSuccess(state, action);
            case `${Constants.FETCH_ITEM_COLLECTION_FAILURE}_${typeIdentifier}`:
                return fetchItemsFailure(state, action);
            case `${Constants.SEARCH_ITEM_COLLECTION}_${typeIdentifier}`:
                return searchItems(state, action);
            case `${Constants.ADD_ITEM_TO_COLLECTION}_${typeIdentifier}`:
                return addItem(state, action);
            case `${Constants.ADD_ITEM_TO_COLLECTION_SUCCESS}_${typeIdentifier}`:
                return addItemSuccess(state, action);
            case `${Constants.ADD_ITEM_TO_COLLECTION_FAILURE}_${typeIdentifier}`:
                return addItemFailure(state, action);
            case `${Constants.REMOVE_ITEM_FROM_COLLECTION}_${typeIdentifier}`:
                return removeItem(state, action);
            case `${Constants.REMOVE_ITEM_FROM_COLLECTION_SUCCESS}_${typeIdentifier}`:
                return removeItemSuccess(state, action);
            case `${Constants.REMOVE_ITEM_FROM_COLLECTION_FAILURE}_${typeIdentifier}`:
                return removeItemFailure(state, action);
            case `${Constants.DISMISS_ITEM_ACTION_ERROR}_${typeIdentifier}`:
                return dismissItemActionError(state, action);
            case `${Constants.UPLOAD_CSV}_${typeIdentifier}`:
                return { ...state, uploading: true };
            case `${Constants.UPLOAD_CSV_SUCCESS}_${typeIdentifier}`:
            case `${Constants.UPLOAD_CSV_FAILURE}_${typeIdentifier}`:
                return { ...state, uploading: false };
            default:
                return state;
        }
    };
}
