import React, { useEffect } from "react";
import LeftNavBar from "./Navbar/LeftNavBar";
import { hot, setConfig } from "react-hot-loader";
import { connect } from "react-redux";
import Header from "./Header";
import UnAuthenticatedRouter from "../apps/UnAuthenticatedRouter";
import { isUserInternal } from "../reducers/currentUser";
import App from "../apps/App";
import NotificationListContainer from "./Notifications/NotificationListContainer";
import { getApiKey, logout, routesWithoutAuthentication } from "../api";
import useFuelAuthentication from "../hooks/useFuelAuthentication";
import LoaderSpinner from "./Shared/Loaders/LoaderSpinner";
import { Dispatch } from "redux";
import { fetchCurrentUser } from "../actions/userActions";
import { fetchClient, setCurrentClient } from "../actions/clientActions";
import IAppState from "../interfaces/IAppState";
import IUser from "../interfaces/IUser";
import { IAuthenticationState } from "../interfaces/IAuthentication";
import useLocalStorage from "../hooks/useLocalStorage";
import { Moment } from "moment";
import { setAuthenticatedTimeLeft } from "../actions/authenticationActions";
import TokenLogoutWarning from "./Auth/TokenLogoutWarning";
import moment from "moment";

setConfig({ pureSFC: true });

const AuthenticatedPage = ({ currentUser }: { currentUser: IUser }) => {
    const internalUser = isUserInternal(currentUser);
    const [collapsed, setCollapsed] = useLocalStorage("menu-collapsed", false);

    return (
        <div className="flex flex-col w-full text-gray-800 h-full">
            <TokenLogoutWarning getCurrentTime={() => moment()} logout={logout} />
            <a
                className="py-2 absolute z-100 w-full bg-blue-700 text-center text-white text-lg skip-link"
                href="#main-content"
            >
                Skip to content
            </a>
            <Header user={currentUser} isInternalUser={internalUser} />
            <div className="flex-1 flex min-h-full bg-gray-200 overflow-hidden" style={{ maxWidth: "100vw" }}>
                <LeftNavBar user={currentUser} />
                <div
                    id="main-content"
                    className="flex-1 h-full"
                    style={collapsed ? {} : { maxWidth: "calc(100vw - 250px)" }}
                >
                    <App />
                </div>
            </div>
            <NotificationListContainer />
        </div>
    );
};

interface IProps {
    currentUser: IUser;
    fetchCurrentUser: () => void;
    setCurrentClient: (id: number) => void;
    fetchCurrentClient: (id: string) => void;
    id: number | null;
    authentication: IAuthenticationState;
    setAuthenticatedTimeLeft: (authenticationTimeLeft: Moment | null) => void;
}

const devBorderColor = () => {
    if (window.location.host === "fuel.dealerinspire.com") {
        return "";
    }

    if (window.location.host === "test.fuel.dealerinspire.com") {
        return "border-yellow-600";
    }

    return "border-red-600";
};
const Page: React.FunctionComponent<IProps> = ({
    currentUser,
    fetchCurrentUser,
    setCurrentClient,
    fetchCurrentClient,
    id,
    authentication,
    setAuthenticatedTimeLeft
}) => {
    const [isTokenValid, checkingForExpiredToken] = useFuelAuthentication(setAuthenticatedTimeLeft);
    const [user, setUser] = useLocalStorage("CURRENT_USER", currentUser || undefined);

    const devNavBarColors = devBorderColor() !== "" ? devBorderColor() + " border-l-8 border-r-8" : "";

    useEffect(() => {
        if (!isTokenValid) return;
        if (!id) return;

        setCurrentClient(id);
        fetchCurrentClient(id.toString());
    }, [isTokenValid, id, setCurrentClient, fetchCurrentClient, authentication.token, authentication.authenticated]);

    useEffect(() => {
        if (!isTokenValid) return;
        if (routesWithoutAuthentication.includes(window.location.pathname)) return;

        fetchCurrentUser();
    }, [isTokenValid, fetchCurrentUser, authentication.token, authentication.authenticated]);

    useEffect(() => {
        if (!currentUser) return;
        setUser(currentUser);
    }, [currentUser?.id]);

    if (checkingForExpiredToken) {
        return (
            <div className="flex flex-wrap w-screen items-center justify-center h-screen bg-gray-200">
                <LoaderSpinner iconDimensions={"8"} message={"Loading FUEL..."} />
            </div>
        );
    }

    if (!authentication.authenticated || !user || !user.roles) {
        return (
            <div className={`flex flex-wrap w-full h-full ${devNavBarColors}`}>
                <UnAuthenticatedRouter />
            </div>
        );
    }

    return (
        <div className={`flex flex-wrap w-full h-full ${devNavBarColors}`}>
            <AuthenticatedPage currentUser={user} />
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    currentUser: state.currentUser,
    authentication: state.authentication
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCurrentUser() {
        dispatch(fetchCurrentUser());
    },
    setCurrentClient(clientId: number) {
        dispatch(setCurrentClient(clientId));
    },
    fetchCurrentClient(clientId: string) {
        if (clientId) {
            dispatch(fetchClient(clientId));
        }
    },
    setAuthenticatedTimeLeft(timeLeftInUserSession: Moment | null) {
        dispatch(setAuthenticatedTimeLeft(timeLeftInUserSession));
    }
});

const PageContainer = connect(mapStateToProps, mapDispatchToProps)(Page);

export default hot(module)(PageContainer);
