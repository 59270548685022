import Button, { PRIMARY_BUTTON } from "../../../Shared/Button";
import RefreshIcon from "../../../Shared/Icons/RefreshIcon";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IClient from "../../../../interfaces/IClient";
import { subscribePageToApp } from "../../../../actions/facebookActions";
import IAppState from "../../../../interfaces/IAppState";
import CheckIcon from "../../../Shared/Icons/CheckIcon";
import { isSupervisor } from "../../../../reducers/currentUser";

interface IProps {
    client: IClient;
    subscribePageToApp: (client: IClient, facebookPageId: number, callback: () => void) => void;
    error?: string;
    loading: boolean;
}

const FacebookSubscribePageToAppButton = ({
    client,
    subscribePageToApp,
    error,
    loading,
    userHasPermissionToViewTheButton
}: IProps & { userHasPermissionToViewTheButton: boolean }) => {
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        if (!complete) {
            return;
        }
        const timeout = setTimeout(() => {
            setComplete(false);
            clearTimeout(timeout);
        }, 5000);

        return function cleanup() {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [setComplete, complete]);

    return (
        <div>
            {userHasPermissionToViewTheButton && (
                <div className="w-full">
                    <Button
                        styleType={PRIMARY_BUTTON}
                        styles="my-4 border-blue-500"
                        onClick={() => {
                            if (client.facebookPageId)
                                subscribePageToApp(client, client.facebookPageId, () => {
                                    setComplete(true);
                                });
                        }}
                        disabled={loading}
                    >
                        <span className={`flex items-center text-base w-full`}>
                            {!complete && <RefreshIcon className={`w-6 h-6 ${loading ? "rotate-fast" : ""}`} />}
                            {complete && <CheckIcon className="w-6 h-6 text-green-200" />}
                            <span className="ml-2">
                                Subscrib{loading ? "ing" : complete ? "ed" : "e"} FB Page to App
                            </span>
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    error: state.subscribeToFacebookApp.error,
    loading: state.subscribeToFacebookApp.loading,
    userHasPermissionToViewTheButton: isSupervisor(state.currentUser)
});

const mapDispatchToProps = {
    subscribePageToApp
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookSubscribePageToAppButton);
