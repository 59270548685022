import React, { SVGProps } from "react";
import Svg from "./Svg";

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M20.409 8.033l-.83-.842a.521.521 0 0 0-.383-.168.52.52 0 0 0-.382.168l-6.527 6.62-6.527-6.62a.521.521 0 0 0-.382-.168.52.52 0 0 0-.382.168l-.83.842a.536.536 0 0 0 0 .775l7.74 7.85c.11.112.237.168.381.168a.52.52 0 0 0 .382-.168l7.74-7.85a.536.536 0 0 0 0-.775z" />
    </Svg>
);

export default ChevronDownIcon;
