import React, { SVGProps } from "react";
import Svg from "./Svg";

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M16.125 13.833c-.484 0-.875.374-.875.834V18c0 .46-.392.833-.875.833H11.75v-12.5c0-.711-.476-1.347-1.192-1.584l-.259-.082h4.076c.483 0 .875.374.875.833V8c0 .46.391.833.875.833S17 8.46 17 8V5.5C17 4.122 15.822 3 14.375 3H4.969c-.034 0-.061.014-.094.018C4.833 3.015 4.793 3 4.75 3 3.785 3 3 3.747 3 4.667v15c0 .711.476 1.347 1.192 1.584l5.266 1.671c.178.053.356.078.542.078.965 0 1.75-.748 1.75-1.667V20.5h2.625C15.822 20.5 17 19.378 17 18v-3.333c0-.46-.391-.834-.875-.834z" />
        <path d="M22.76 10.864l-3.273-3.6a.765.765 0 0 0-.891-.196c-.305.14-.505.468-.505.832v2.7h-3.273c-.451 0-.818.403-.818.9s.367.9.818.9h3.273v2.7c0 .363.2.692.505.831.306.14.658.062.891-.195l3.273-3.6a.964.964 0 0 0 0-1.272z" />
    </Svg>
);

export default LogoutIcon;
