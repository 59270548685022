import React, { FunctionComponent } from "react";
import uuid from "uuid";
import BidModifier from "./BidModifier";
import Button, { SECONDARY_BUTTON } from "../../../Shared/Button";
import { IAdGroupBidModifier } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import AddIcon from "../../../Shared/Icons/AddIcon";
import SortableList from "../../../Shared/Form/SortableList/SortableList";

const sortModifiers = (modifiers: IAdGroupBidModifier[]) => {
    return modifiers.sort((a, b) => {
        return a.order > b.order ? 1 : -1;
    });
};

interface IBidModifierList {
    modifiers: IAdGroupBidModifier[];
    errors: any;
    setFieldValue: any;
    setFieldTouched: any;
}

const BidModifierList: FunctionComponent<IBidModifierList> = ({
    modifiers,
    errors,
    setFieldValue,
    setFieldTouched
}) => {
    const handleSort = (sortedModifiers: any) => {
        setFieldValue("adGroupBidModifiers", [...sortedModifiers]);
    };

    const modifiersList: any[] = sortModifiers(modifiers).map((modifier, index) => {
        return {
            ...{ ...modifier },
            setFieldTouched,
            setFieldValue,
            errors
        };
    });

    const handleAddNewModifier = () => {
        const newModifier = {
            id: uuid.v1(),
            name: null,
            modifier: "increase-bid-by-percentage",
            amount: 0.0,
            new: true,
            order: modifiers.length,
            conditionals: [],
            dirty: true
        };
        setFieldValue("adGroupBidModifiers", [...modifiersList, newModifier]);
    };

    return (
        <>
            <div className="flex flex-col w-3/4">
                <SortableList ItemComponent={BidModifier} items={modifiersList} onSort={handleSort} />
            </div>
            <Button styleType={SECONDARY_BUTTON} onClick={handleAddNewModifier} type="button">
                <AddIcon className="w-6 h-6 inline-block mb-1" /> Add Bid Modifier
            </Button>
        </>
    );
};

export default BidModifierList;
