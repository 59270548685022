import React, { SVGProps } from "react";
import Svg from "./Svg";

const DynamicCampaignsIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M20.333 22.375a.625.625 0 0 1-.625-.625v-2.883c0-.92-.659-1.698-1.566-1.849l-1.661-.276a.623.623 0 0 1-.523-.617v-2.5a.625.625 0 0 0-1.25 0V18a.623.623 0 0 1-.903.56l-.982-.49a.738.738 0 0 0-.792 1.237l2.443 1.955a.626.626 0 0 1-.78.975l-2.442-1.953a1.992 1.992 0 0 1-.691-2.035 1.986 1.986 0 0 1 2.82-1.296l.078.038v-3.366c0-1.034.841-1.875 1.875-1.875s1.875.84 1.875 1.875v1.97l1.139.19a3.115 3.115 0 0 1 2.611 3.083v2.882c0 .345-.28.625-.626.625zM7.207 8.625a.625.625 0 0 1 0-1.25h6.25a.625.625 0 0 1 0 1.25h-6.25zM7.207 11.125a.625.625 0 0 1 0-1.25h6.25a.625.625 0 0 1 0 1.25h-6.25zM7.207 13.625a.625.625 0 0 1 0-1.25h3.125a.625.625 0 0 1 0 1.25H7.207z" />
        <path d="M4.707 18.625a1.877 1.877 0 0 1-1.875-1.875V5.5c0-1.034.841-1.875 1.875-1.875h16.25c1.035 0 1.875.84 1.875 1.875v10a.625.625 0 0 1-1.25 0v-10a.625.625 0 0 0-.625-.625h-1.875v6.875a.625.625 0 0 1-1.25 0V4.875H4.707a.625.625 0 0 0-.624.625v11.25c0 .345.28.625.624.625h4.376a.625.625 0 0 1 0 1.25H4.707z" />
    </Svg>
);

export default DynamicCampaignsIcon;
