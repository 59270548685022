import React, { FunctionComponent } from "react";
import { IClient } from "../../../../interfaces/IClient";
import TextField from "../../../Shared/Form/Blocks/TextField";

interface IBilling {
    client: IClient;
}

const Billing: FunctionComponent<IBilling> = ({ client }) => {
    const getIsInvoicedValue = (isInvoiced: boolean) => {
        return isInvoiced ? "DI Invoicing" : "Client Paying";
    };

    return (
        <div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2">
                    <TextField
                        label="FACEBOOK BILLING"
                        name="facebookBilling"
                        value={getIsInvoicedValue(client.facebookAdsInvoiced)}
                        readOnly={true}
                        className="mr-4"
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <TextField
                        label="GOOGLE BILLING"
                        name="googleBilling"
                        value={getIsInvoicedValue(client.googleAdsInvoiced)}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2">
                    <TextField
                        label="BING BILLING"
                        name="bingBilling"
                        value={getIsInvoicedValue(client.microsoftAdsInvoiced)}
                        readOnly={true}
                        className="mr-4"
                    />
                </div>
            </div>
        </div>
    );
};

export default Billing;
