import React from "react";
import { Field } from "formik";

interface ICheckboxInput {
    name: string;
    label: string;
    checked: boolean;
    type?: string;
    onChange?: any;
    onBlur?: any;
    className?: string;
    readOnly?: boolean;
}

const CheckboxInput: React.FunctionComponent<ICheckboxInput> = ({ name, label, checked }) => {
    return (
        <label className="cursor-pointer" htmlFor={name}>
            <Field id={name} type="checkbox" name={name} checked={checked} className="mr-2 leading-tight" />
            <span className="ml-4">{label}</span>
        </label>
    );
};

export default CheckboxInput;
