import React, { SVGProps } from "react";
import Svg from "./Svg";

const CircleIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path
            d="M13.75 11.25V6.25H11.25V11.25H6.25V13.75H11.25V18.75H13.75V13.75H18.75V11.25H13.75ZM12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25Z"
            fill="black"
        />
    </Svg>
);

export default CircleIcon;
