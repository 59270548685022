export default {
    REQUEST_FACEBOOK_BUDGETS: "REQUEST_FACEBOOK_BUDGETS",
    REQUEST_FACEBOOK_BUDGETS_SUCCESS: "REQUEST_FACEBOOK_BUDGETS_SUCCESS",
    REQUEST_FACEBOOK_BUDGETS_FAIL: "REQUEST_FACEBOOK_BUDGETS_FAIL",

    REQUEST_CLIENT_FACEBOOK_BUDGETS: "REQUEST_CLIENT_FACEBOOK_BUDGETS",
    REQUEST_CLIENT_FACEBOOK_BUDGETS_SUCCESS: "REQUEST_CLIENT_FACEBOOK_BUDGETS_SUCESS",
    REQUEST_CLIENT_FACEBOOK_BUDGETS_FAIL: "REQUEST_CLIENT_FACEBOOK_BUDGETS_FAIL",

    REQUEST_FACEBOOK_BUDGETS_SPINNER: "REQUEST_FACEBOOK_BUDGETS_SPINNER",
    LOADING_FACEBOOK_BUDGETS: "LOADING_FACEBOOK_BUDGETS"
};
