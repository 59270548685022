import React from "react";
import Condition from "../../Shared/Condition";
import { getCarsInventoryFields, getInventoryFieldsDI } from "../../../utils/InventoryUtils";
import { IRemarketingCondition } from "../../../interfaces/IRemarketing";
import Label from "../../Shared/Form/Label";
import Button, { PRIMARY_BUTTON } from "../../Shared/Button";

const diFields = getInventoryFieldsDI();
const standardFields = getCarsInventoryFields();

interface IValidationErrorProps {
    errors: Array<{ message: string }>;
}

const ValidationErrors = ({ errors }: IValidationErrorProps) => {
    return (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {errors.map((error) => {
                if (error) {
                    return Object.values(error).map((message) => (
                        <p className="block" key={message}>
                            {message}
                        </p>
                    ));
                }
                return null;
            })}
        </div>
    );
};

interface IProps {
    conditions: IRemarketingCondition[];
    addCondition(): void;
    removeCondition(e: any): void;
    updateCondition(idx: number, item: IRemarketingCondition): void;
    errors: any;
    inventorySource: string | null;
}

/**
 * This is here to hold this work until we put it back in the form
 */
class Form extends React.Component<IProps> {
    render() {
        const { conditions, addCondition, removeCondition, updateCondition, errors, inventorySource } = this.props;

        const fields = inventorySource !== "CARS" ? diFields : standardFields;

        return (
            <div className="ml-8 -mt-4">
                <Label label="Conditions" />
                {errors && <ValidationErrors errors={errors} />}
                <div className="flex flex-wrap items-center">
                    {conditions &&
                        conditions.map((condition, idx) => {
                            return (
                                <Condition
                                    valid={errors === undefined || errors[idx] === undefined}
                                    key={idx}
                                    idx={idx}
                                    condition={condition}
                                    parameters={fields}
                                    options={{}}
                                    onUpdate={updateCondition}
                                    removeCondition={removeCondition}
                                />
                            );
                        })}
                    {conditions.length === 0 && <div className="italic text-gray-700">No conditionals</div>}

                    <div className="w-full flex justify-end mt-4">
                        <Button styleType={PRIMARY_BUTTON} onClick={() => addCondition()}>
                            Add
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
