import React from "react";
import FullPageLoader from "../../../Shared/Loaders/FullPageLoader";
import ManualBudgetInfo from "./ManualBudgetInfo";
import { Container } from "../BudgetDetails/BudgetDetails";
import ManualBudgetActivations from "./ManualBudgetActivations";
import BudgetLayout from "../BudgetLayout";
import NotFound from "../../../Shared/NotFound404";
import useManualBudget from "../../../../hooks/budgets/useManualBudget";
import useBudgetParams from "../../../../hooks/budgets/useBudgetParams";

interface IProps {}

const ManualBudgetDetails: React.FunctionComponent<IProps> = ({}) => {
    const { client } = useBudgetParams();
    const { loadingBudget: loading, details: manualBudget, apiErrors } = useManualBudget();

    if (loading || !manualBudget) {
        return <FullPageLoader message="Loading Manual Budget Details..." />;
    }

    if (!manualBudget && !apiErrors) {
        return <NotFound message={"The budget you are looking for could not be found"} />;
    }

    return (
        <BudgetLayout>
            <div className={Container}>
                <div className={`w-1/4 `}>{<ManualBudgetInfo manualBudget={manualBudget} />}</div>
                <div className={`w-3/4`}>{<ManualBudgetActivations manualBudget={manualBudget} />}</div>
            </div>
        </BudgetLayout>
    );
};

export default ManualBudgetDetails;
