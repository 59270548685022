import React, { FunctionComponent } from "react";
import LoaderSpinner from "../Loaders/LoaderSpinner";

interface IProps {
    className?: string;
    message: string;
}

const TableLoader: FunctionComponent<IProps> = ({ className, message }) => {
    return (
        <div
            className={`absolute flex items-center justify-center bg-white-transparent-700 z-10 w-full h-full ${
                className || ""
            }`}
        >
            <LoaderSpinner message={message} />
        </div>
    );
};

export default TableLoader;
