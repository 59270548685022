import React, { SVGProps } from "react";
import Svg from "./Svg";

const RemarketingExportsIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M10.04 23a.625.625 0 0 1 0-1.25h2.5v-1.875H6.29A1.877 1.877 0 0 1 4.417 18V9.875C4.416 8.841 5.256 8 6.29 8h2.5a.625.625 0 0 1 0 1.25h-2.5a.625.625 0 0 0-.625.625v6.25h15v-6.25a.625.625 0 0 0-.625-.625h-2.5a.625.625 0 0 1 0-1.25h2.5c1.034 0 1.875.84 1.875 1.875V18c0 1.034-.841 1.875-1.875 1.875h-6.25v1.875h2.5a.625.625 0 0 1 0 1.25h-6.25zm-4.374-5c0 .345.28.625.625.625h13.75c.345 0 .625-.28.625-.625v-.625h-15V18z" />
        <path d="M13.166 14.25a.625.625 0 0 1-.625-.625v-8.49l-1.434 1.433a.622.622 0 0 1-.883-.001.619.619 0 0 1-.183-.442c0-.167.065-.324.183-.442l2.5-2.499a.617.617 0 0 1 .203-.136l.021-.007a.602.602 0 0 1 .435-.001l.017.006c.08.033.15.079.208.138l2.5 2.5a.619.619 0 0 1 .183.441.624.624 0 0 1-1.067.442l-1.433-1.434v8.492c0 .345-.28.625-.625.625z" />
    </Svg>
);

export default RemarketingExportsIcon;
