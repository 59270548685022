import React, { useState } from "react";
import CreatableSelect, { ActionMeta, MultiValue } from "react-select";
import { IClient } from "../../../../interfaces/IClient";
import { formatToReactSelectValue, formatToCsv, updateSelectInput } from "../../../../utils/DynamicCampaignUtils";
import IReactSelectValue from "../../../../interfaces/IReactSelectValue";
import TextField from "../../../Shared/Form/Blocks/TextField";
import Field from "../../../Shared/Form/Elements/Field";
import moment from "moment";
import TimeIcon from "../../../Shared/Icons/TimeIcon";

interface IProps {
    client: IClient;
    isInternalUser: boolean;
    warnings: {
        dashboardApiKey: any;
        inventoryApiKey: any;
        inventoryFeedId: any;
    };
    errors: {
        [key: string]: string[] | undefined;
    };
    onValueChange(event: React.ChangeEvent<HTMLInputElement> | IMockEvent): void;
}

interface IMockEvent {
    target: { type: string; value: string; name: string };
}

const DealerInspire: React.FunctionComponent<IProps> = ({
    client,
    onValueChange,
    warnings,
    isInternalUser,
    errors
}) => {
    const [rawInputFromInventoryReactSelect, setRawInputFromInventoryReactSelect] = useState("");
    const inventoryFeedIdValue = formatToReactSelectValue(client.inventoryFeedId);

    const customSelectStyles = {
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: "39px"
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        })
    };

    // Handles removing selected item(s)

    const handleSelectChange = (values: MultiValue<IReactSelectValue>, _: ActionMeta<IReactSelectValue>) => {
        const mockEvent = {
            target: {
                type: "",
                value: formatToCsv(values),
                name: "inventoryFeedId"
            }
        };
        onValueChange(mockEvent);
    };

    const handleSelectInputChange = (value: string) => {
        setRawInputFromInventoryReactSelect(value);
    };

    const handleSelectKeydown = (e: KeyboardEvent) => {
        if (!rawInputFromInventoryReactSelect) {
            return;
        }
        if (["Enter", "Tab"].includes(e.key)) {
            const mockEvent = {
                target: {
                    type: "",
                    value: updateSelectInput(client.inventoryFeedId, rawInputFromInventoryReactSelect),
                    name: "inventoryFeedId"
                }
            };
            onValueChange(mockEvent);
            setRawInputFromInventoryReactSelect("");
            e.preventDefault();
        }
    };
    return (
        <div className="flex">
            <div className="w-full md:w-3/4 lg:w-1/2">
                {isInternalUser === true && (
                    <label className="cursor-pointer text-base">
                        <input type="checkbox" checked={client.diSite} onChange={onValueChange} name="diSite" /> DI Site
                    </label>
                )}
                {isInternalUser === true && (
                    <>
                        <TextField
                            value={client.dashboardApiKey || ""}
                            handleChange={onValueChange}
                            name="dashboardApiKey"
                            errors={errors.dashboardApiKey || []}
                            label="DI Dashboard API Key"
                            sublabel="This is the Wordpress API Key from the dashboard, should be a combination of letters and numbers."
                        />
                        {client.dashboardApiKeyUpdatedAt && (
                            <div
                                className="text-sm pt-1 text-gray-600 flex items-center"
                                title={"The time the dashboard api key was last updated."}
                            >
                                <TimeIcon className="w-4 h-6" />
                                <span className="pl-1">{moment(client.dashboardApiKeyUpdatedAt).format("lll")}</span>
                            </div>
                        )}

                        {!client.dashboardApiKeyUpdatedAt && (
                            <div
                                className="text-sm pt-1 text-gray-600 flex items-center"
                                title={"The time the dashboard api key was last updated."}
                            >
                                <span>
                                    This field hasn't been updated since we started tracking it on July 27th, 2020.
                                </span>
                            </div>
                        )}
                    </>
                )}

                <TextField
                    value={client.inventoryApiKey || ""}
                    handleChange={onValueChange}
                    name="inventoryApiKey"
                    label="Inventory API Key"
                    errors={errors.inventoryApiKey || []}
                    sublabel="This is the Dealer ID value, should be numbers only like 1100283."
                />

                <Field label="Inventory Feed Id(s)" errors={errors.inventoryFeedId || []}>
                    <div className="text-xs -mt-2 mb-2 ">
                        {errors.inventoryFeedId
                            ? errors.inventoryFeedId
                            : "These are the Feed IDs that uniquely identify this Client within the chosen Inventory."}
                    </div>
                    <CreatableSelect
                        components={{
                            DropdownIndicator: null
                        }}
                        className="flex-1 rounded text-base"
                        inputValue={rawInputFromInventoryReactSelect}
                        isClearable={true}
                        isMulti={true}
                        menuIsOpen={false}
                        onChange={handleSelectChange}
                        onInputChange={handleSelectInputChange}
                        onKeyDown={handleSelectKeydown as any}
                        placeholder="Enter your feed id, then press enter or tab..."
                        styles={customSelectStyles}
                        value={inventoryFeedIdValue}
                    />
                </Field>
            </div>
        </div>
    );
};

export default DealerInspire;
