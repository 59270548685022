import React from "react";
import { labelStyles } from "../DealerSetupStyles";
import IPreScreen from "../../../../interfaces/DealerSetup/IPreScreenFields";
import FieldErrors from "./FieldErrors";

interface IProps {
    values: IPreScreen;
    onInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const PreScreenCPCField: React.FunctionComponent<IProps> = ({ values, onInputChange }) => (
    <React.Fragment>
        <label htmlFor="defaultCpc" className={`${labelStyles} block`}>
            Default CPC
        </label>
        <div className="cpc-field flex">
            <span className="border border-r-0 i-container w-1/3 text-center py-3 rounded rounded-r-none text-base">
                <span className="font-bold align-top">$</span>
            </span>

            <input
                value={values.fields.defaultCpc.value}
                type="text"
                name="defaultCpc"
                onChange={onInputChange}
                className="border w-2/3 px-4 rounded rounded-l-none focus:outline-none text-base"
            />
        </div>
        <FieldErrors errors={values.fields.defaultCpc.errors} />
    </React.Fragment>
);

export default PreScreenCPCField;
