const campaigns = [
    {
        name: "General Mazda Intent",
        keywords: [
            "+mazda +discount",
            "+mazda +for +sale",
            "+mazda +incentive",
            "+mazda +offer",
            "+mazda +sale +event",
            "+mazda +msrp"
        ],
        negative_keyword_lists: []
    },
    {
        name: "General Mazda Lease",
        keywords: ["+mazda +lease +deal", "+mazda +lease +offer"],
        negative_keyword_lists: []
    },
    {
        name: "All Models Static",
        keywords: [
            `"mazda finance"`,
            `"mazda price"`,
            `"mazda payment"`,
            `"mazda leasing"`,
            `"buy mazda"`,
            `"mazda build and price"`,
            `"mazda msrp"`,
            `"mazda car lease"`,
            `"mazda for sale"`,
            `"mazda deals"`,
            `"mazda incentives"`,
            `"mazda lease deals"`,
            `"mazda cars for sale"`,
            `"mazda finance deals"`,
            `"mazda offers"`,
            `"mazda special offers"`,
            `"mazda specials"`,
            `"new mazda for sale"`,
            `"mazda lease offers"`,
            `"mazda lease specials"`,
            `"mazda dealership"`,
            `"mazda dealership near me"`,
            `"mazda near me"`,
            `"mazda dealer nearest me"`,
            `"closest mazda dealership"`,
            `"local mazda dealerships"`,
            `"mazda around me"`,
            `"mazda car dealership"`,
            `"mazda for sale near me"`,
            `"mazda nearby"`,
            `"mazda showroom near me"`,
            `"nearest mazda dealer"`,
            `"mazda dealership hours"`,
            `"mazda cx-30 lease"`,
            `"mazda cx-30 cost"`,
            `"buy mazda cx-30"`,
            `"mazda cx-30 finance"`,
            `"how much is mazda cx-30"`,
            `"mazda cx-30 new car price"`,
            `"mazda cx-30 price"`,
            `"mazda cx-30 pricing"`,
            `"new mazda cx-30 price"`,
            `"mazda cx-30 msrp"`,
            `"mazda cx-30 payment"`,
            `"mazda cx-30 lease deal"`,
            `"mazda cx-30 cars for sale"`,
            `"mazda cx-30 deal"`,
            `"mazda cx-30 for sale"`,
            `"mazda cx-30 incentive"`,
            `"mazda cx-30 sale"`,
            `"new mazda cx-30 for sale"`,
            `"mazda cx-30 lease offer"`,
            `"mazda cx-30 lease price"`,
            `"mazda cx-30 lease specials"`,
            `"mazda cx-30 dealer"`,
            `"mazda cx-30 dealer near me"`,
            `"mazda cx-30 dealership"`,
            `"mazda cx-30 for sale near me"`,
            `"mazda cx-30 near me"`,
            `"mazda cx-5 lease"`,
            `"mazda cx-5 price"`,
            `"mazda cx-5 cost"`,
            `"how much is mazda cx-5"`,
            `"buy mazda cx-5"`,
            `"mazda cx-5 finance"`,
            `"mazda cx-5 new car price"`,
            `"mazda cx-5 pricing"`,
            `"new mazda cx-5 price"`,
            `"mazda cx-5 msrp"`,
            `"mazda cx-5 payment"`,
            `"mazda cx-5 deal"`,
            `"mazda cx-5 for sale"`,
            `"mazda cx-5 lease deal"`,
            `"mazda cx-5 cars for sale"`,
            `"mazda cx-5 incentive"`,
            `"mazda cx-5 lease offer"`,
            `"mazda cx-5 sale"`,
            `"new mazda cx-5 for sale"`,
            `"mazda cx-5 lease price"`,
            `"mazda cx-5 lease specials"`,
            `"mazda cx-5 dealer"`,
            `"mazda cx-5 dealer near me"`,
            `"mazda cx-5 dealership"`,
            `"mazda cx-5 for sale near me"`,
            `"mazda cx-5 near me"`,
            `"mazda cx-9 lease"`,
            `"mazda cx-9 price"`,
            `"mazda cx-9 cost"`,
            `"how much is mazda cx-9"`,
            `"buy mazda cx-9"`,
            `"mazda cx-9 finance"`,
            `"mazda cx-9 new car price"`,
            `"mazda cx-9 pricing"`,
            `"new mazda cx-9 price"`,
            `"mazda cx-9 msrp"`,
            `"mazda cx-9 payment"`,
            `"mazda cx-9 deal"`,
            `"mazda cx-9 for sale"`,
            `"mazda cx-9 lease deal"`,
            `"mazda cx-9 lease offer"`,
            `"mazda cx-9 cars for sale"`,
            `"mazda cx-9 incentive"`,
            `"mazda cx-9 lease price"`,
            `"mazda cx-9 sale"`,
            `"new mazda cx-9 for sale"`,
            `"mazda cx-9 lease specials"`,
            `"mazda cx-9 dealer"`,
            `"mazda cx-9 dealer near me"`,
            `"mazda cx-9 dealership"`,
            `"mazda cx-9 for sale near me"`,
            `"mazda cx-9 near me"`,
            `"mazda mx-5 miata lease"`,
            `"mazda mx-5 miata cost"`,
            `"how much is mazda mx-5 miata"`,
            `"buy mazda mx-5 miata"`,
            `"mazda mx-5 miata msrp"`,
            `"mazda mx-5 miata finance"`,
            `"mazda mx-5 miata new car price"`,
            `"mazda mx-5 miata price"`,
            `"mazda mx-5 miata pricing"`,
            `"new mazda mx-5 miata price"`,
            `"mazda mx-5 miata payment"`,
            `"mazda mx-5 miata for sale"`,
            `"mazda mx-5 miata lease deal"`,
            `"mazda mx-5 miata lease offer"`,
            `"mazda mx-5 miata cars for sale"`,
            `"mazda mx-5 miata deal"`,
            `"mazda mx-5 miata incentive"`,
            `"mazda mx-5 miata lease price"`,
            `"mazda mx-5 miata sale"`,
            `"new mazda mx-5 miata for sale"`,
            `"mazda mx-5 miata lease specials"`,
            `"mazda mx-5 miata dealer"`,
            `"mazda mx-5 miata dealer near me"`,
            `"mazda mx-5 miata dealership"`,
            `"mazda mx-5 miata for sale near me"`,
            `"mazda mx-5 miata near me"`,
            `"mazda3 lease"`,
            `"mazda3 price"`,
            `"mazda3 cost"`,
            `"how much is mazda3"`,
            `"mazda3 msrp"`,
            `"buy mazda3"`,
            `"mazda3 finance"`,
            `"mazda3 new car price"`,
            `"mazda3 pricing"`,
            `"new mazda3 price"`,
            `"mazda3 payment"`,
            `"mazda3 for sale"`,
            `"mazda3 lease deal"`,
            `"mazda3 lease offer"`,
            `"mazda3 lease price"`,
            `"mazda3 cars for sale"`,
            `"mazda3 deal"`,
            `"mazda3 incentive"`,
            `"mazda3 lease specials"`,
            `"mazda3 sale"`,
            `"mazda3 dealer"`,
            `"mazda3 dealer near me"`,
            `"mazda3 dealership"`,
            `"mazda3 for sale near me"`,
            `"mazda3 near me"`,
            `"mazda mx-30 lease"`,
            `"mazda mx-30 cost"`,
            `"buy mazda mx-30"`,
            `"mazda mx-30 finance"`,
            `"how much is mazda mx-30"`,
            `"mazda mx-30 new car price"`,
            `"mazda mx-30 price"`,
            `"mazda mx-30 pricing"`,
            `"new mazda mx-30 price"`,
            `"mazda mx-30 msrp"`,
            `"mazda mx-30 payment"`,
            `"mazda mx-30 lease deal"`,
            `"mazda mx-30 cars for sale"`,
            `"mazda mx-30 deal"`,
            `"mazda mx-30 for sale"`,
            `"mazda mx-30 incentive"`,
            `"mazda mx-30 sale"`,
            `"new mazda mx-30 for sale"`,
            `"mazda mx-30 lease offer"`,
            `"mazda mx-30 lease price"`,
            `"mazda mx-30 lease specials"`,
            `"mazda mx-30 dealer"`,
            `"mazda mx-30 dealer near me"`,
            `"mazda mx-30 dealership"`,
            `"mazda mx-30 for sale near me"`,
            `"mazda mx-30 near me"`,
            `"mazda cx-50 lease"`,
            `"mazda cx-50 cost"`,
            `"buy mazda cx-50"`,
            `"mazda cx-50 finance"`,
            `"how much is mazda cx-50"`,
            `"mazda cx-50 new car price"`,
            `"mazda cx-50 price"`,
            `"mazda cx-50 pricing"`,
            `"new mazda cx-50 price"`,
            `"mazda cx-50 msrp"`,
            `"mazda cx-50 payment"`,
            `"mazda cx-50 lease deal"`,
            `"mazda cx-50 cars for sale"`,
            `"mazda cx-50 deal"`,
            `"mazda cx-50 for sale"`,
            `"mazda cx-50 incentive"`,
            `"mazda cx-50 sale"`,
            `"new mazda cx-50 for sale"`,
            `"mazda cx-50 lease offer"`,
            `"mazda cx-50 lease price"`,
            `"mazda cx-50 lease specials"`,
            `"mazda cx-50 dealer"`,
            `"mazda cx-50 dealer near me"`,
            `"mazda cx-50 dealership"`,
            `"mazda cx-50 for sale near me"`,
            `"mazda cx-50 near me"`,
            `"mazda cx-90 lease"`,
            `"mazda cx-90 cost"`,
            `"buy mazda cx-90"`,
            `"mazda cx-90 finance"`,
            `"how much is mazda cx-90"`,
            `"mazda cx-90 new car price"`,
            `"mazda cx-90 price"`,
            `"mazda cx-90 pricing"`,
            `"new mazda cx-90 price"`,
            `"mazda cx-90 msrp"`,
            `"mazda cx-90 payment"`,
            `"mazda cx-90 lease deal"`,
            `"mazda cx-90 cars for sale"`,
            `"mazda cx-90 deal"`,
            `"mazda cx-90 for sale"`,
            `"mazda cx-90 incentive"`,
            `"mazda cx-90 sale"`,
            `"new mazda cx-90 for sale"`,
            `"mazda cx-90 lease offer"`,
            `"mazda cx-90 lease price"`,
            `"mazda cx-90 lease specials"`,
            `"mazda cx-90 dealer"`,
            `"mazda cx-90 dealer near me"`,
            `"mazda cx-90 dealership"`,
            `"mazda cx-90 for sale near me"`,
            `"mazda cx-90 near me"`,
            `"mazda cx-90 phev lease"`,
            `"mazda cx-90 phev cost"`,
            `"buy mazda cx-90 phev"`,
            `"mazda cx-90 phev finance"`,
            `"how much is mazda cx-90 phev"`,
            `"mazda cx-90 phev new car price"`,
            `"mazda cx-90 phev price"`,
            `"mazda cx-90 phev pricing"`,
            `"new mazda cx-90 phev price"`,
            `"mazda cx-90 phev msrp"`,
            `"mazda cx-90 phev payment"`,
            `"mazda cx-90 phev lease deal"`,
            `"mazda cx-90 phev cars for sale"`,
            `"mazda cx-90 phev deal"`,
            `"mazda cx-90 phev for sale"`,
            `"mazda cx-90 phev incentive"`,
            `"mazda cx-90 phev sale"`,
            `"new mazda cx-90 phev for sale"`,
            `"mazda cx-90 phev lease offer"`,
            `"mazda cx-90 phev lease price"`,
            `"mazda cx-90 phev lease specials"`,
            `"mazda cx-90 phev dealer"`,
            `"mazda cx-90 phev dealer near me"`,
            `"mazda cx-90 phev dealership"`,
            `"mazda cx-90 phev for sale near me"`,
            `"mazda cx-90 phev near me"`,
            `[mazda finance]`,
            `[mazda price]`,
            `[mazda payment]`,
            `[mazda leasing]`,
            `[buy mazda]`,
            `[mazda build and price]`,
            `[mazda msrp]`,
            `[mazda car lease]`,
            `[mazda for sale]`,
            `[mazda deals]`,
            `[mazda incentives]`,
            `[mazda lease deals]`,
            `[mazda cars for sale]`,
            `[mazda finance deals]`,
            `[mazda offers]`,
            `[mazda special offers]`,
            `[mazda specials]`,
            `[new mazda for sale]`,
            `[mazda lease offers]`,
            `[mazda lease specials]`,
            `[mazda dealership]`,
            `[mazda dealership near me]`,
            `[mazda near me]`,
            `[mazda dealer nearest me]`,
            `[closest mazda dealership]`,
            `[local mazda dealerships]`,
            `[mazda around me]`,
            `[mazda car dealership]`,
            `[mazda for sale near me]`,
            `[mazda nearby]`,
            `[mazda showroom near me]`,
            `[nearest mazda dealer]`,
            `[mazda dealership hours]`,
            `[mazda cx-30 lease]`,
            `[mazda cx-30 cost]`,
            `[buy mazda cx-30]`,
            `[mazda cx-30 finance]`,
            `[how much is mazda cx-30]`,
            `[mazda cx-30 new car price]`,
            `[mazda cx-30 price]`,
            `[mazda cx-30 pricing]`,
            `[new mazda cx-30 price]`,
            `[mazda cx-30 msrp]`,
            `[mazda cx-30 payment]`,
            `[mazda cx-30 lease deal]`,
            `[mazda cx-30 cars for sale]`,
            `[mazda cx-30 deal]`,
            `[mazda cx-30 for sale]`,
            `[mazda cx-30 incentive]`,
            `[mazda cx-30 sale]`,
            `[new mazda cx-30 for sale]`,
            `[mazda cx-30 lease offer]`,
            `[mazda cx-30 lease price]`,
            `[mazda cx-30 lease specials]`,
            `[mazda cx-30 dealer]`,
            `[mazda cx-30 dealer near me]`,
            `[mazda cx-30 dealership]`,
            `[mazda cx-30 for sale near me]`,
            `[mazda cx-30 near me]`,
            `[mazda cx-5 lease]`,
            `[mazda cx-5 price]`,
            `[mazda cx-5 cost]`,
            `[how much is mazda cx-5]`,
            `[buy mazda cx-5]`,
            `[mazda cx-5 finance]`,
            `[mazda cx-5 new car price]`,
            `[mazda cx-5 pricing]`,
            `[new mazda cx-5 price]`,
            `[mazda cx-5 msrp]`,
            `[mazda cx-5 payment]`,
            `[mazda cx-5 deal]`,
            `[mazda cx-5 for sale]`,
            `[mazda cx-5 lease deal]`,
            `[mazda cx-5 cars for sale]`,
            `[mazda cx-5 incentive]`,
            `[mazda cx-5 lease offer]`,
            `[mazda cx-5 sale]`,
            `[new mazda cx-5 for sale]`,
            `[mazda cx-5 lease price]`,
            `[mazda cx-5 lease specials]`,
            `[mazda cx-5 dealer]`,
            `[mazda cx-5 dealer near me]`,
            `[mazda cx-5 dealership]`,
            `[mazda cx-5 for sale near me]`,
            `[mazda cx-5 near me]`,
            `[mazda cx-9 lease]`,
            `[mazda cx-9 price]`,
            `[mazda cx-9 cost]`,
            `[how much is mazda cx-9]`,
            `[buy mazda cx-9]`,
            `[mazda cx-9 finance]`,
            `[mazda cx-9 new car price]`,
            `[mazda cx-9 pricing]`,
            `[new mazda cx-9 price]`,
            `[mazda cx-9 msrp]`,
            `[mazda cx-9 payment]`,
            `[mazda cx-9 deal]`,
            `[mazda cx-9 for sale]`,
            `[mazda cx-9 lease deal]`,
            `[mazda cx-9 lease offer]`,
            `[mazda cx-9 cars for sale]`,
            `[mazda cx-9 incentive]`,
            `[mazda cx-9 lease price]`,
            `[mazda cx-9 sale]`,
            `[new mazda cx-9 for sale]`,
            `[mazda cx-9 lease specials]`,
            `[mazda cx-9 dealer]`,
            `[mazda cx-9 dealer near me]`,
            `[mazda cx-9 dealership]`,
            `[mazda cx-9 for sale near me]`,
            `[mazda cx-9 near me]`,
            `[mazda mx-5 miata lease]`,
            `[mazda mx-5 miata cost]`,
            `[how much is mazda mx-5 miata]`,
            `[buy mazda mx-5 miata]`,
            `[mazda mx-5 miata msrp]`,
            `[mazda mx-5 miata finance]`,
            `[mazda mx-5 miata new car price]`,
            `[mazda mx-5 miata price]`,
            `[mazda mx-5 miata pricing]`,
            `[new mazda mx-5 miata price]`,
            `[mazda mx-5 miata payment]`,
            `[mazda mx-5 miata for sale]`,
            `[mazda mx-5 miata lease deal]`,
            `[mazda mx-5 miata lease offer]`,
            `[mazda mx-5 miata cars for sale]`,
            `[mazda mx-5 miata deal]`,
            `[mazda mx-5 miata incentive]`,
            `[mazda mx-5 miata lease price]`,
            `[mazda mx-5 miata sale]`,
            `[new mazda mx-5 miata for sale]`,
            `[mazda mx-5 miata lease specials]`,
            `[mazda mx-5 miata dealer]`,
            `[mazda mx-5 miata dealer near me]`,
            `[mazda mx-5 miata dealership]`,
            `[mazda mx-5 miata for sale near me]`,
            `[mazda mx-5 miata near me]`,
            `[mazda3 lease]`,
            `[mazda3 price]`,
            `[mazda3 cost]`,
            `[how much is mazda3]`,
            `[mazda3 msrp]`,
            `[buy mazda3]`,
            `[mazda3 finance]`,
            `[mazda3 new car price]`,
            `[mazda3 pricing]`,
            `[new mazda3 price]`,
            `[mazda3 payment]`,
            `[mazda3 for sale]`,
            `[mazda3 lease deal]`,
            `[mazda3 lease offer]`,
            `[mazda3 lease price]`,
            `[mazda3 cars for sale]`,
            `[mazda3 deal]`,
            `[mazda3 incentive]`,
            `[mazda3 lease specials]`,
            `[mazda3 sale]`,
            `[mazda3 dealer]`,
            `[mazda3 dealer near me]`,
            `[mazda3 dealership]`,
            `[mazda3 for sale near me]`,
            `[mazda3 near me]`,
            `[mazda mx-30 lease]`,
            `[mazda mx-30 cost]`,
            `[buy mazda mx-30]`,
            `[mazda mx-30 finance]`,
            `[how much is mazda mx-30]`,
            `[mazda mx-30 new car price]`,
            `[mazda mx-30 price]`,
            `[mazda mx-30 pricing]`,
            `[new mazda mx-30 price]`,
            `[mazda mx-30 msrp]`,
            `[mazda mx-30 payment]`,
            `[mazda mx-30 lease deal]`,
            `[mazda mx-30 cars for sale]`,
            `[mazda mx-30 deal]`,
            `[mazda mx-30 for sale]`,
            `[mazda mx-30 incentive]`,
            `[mazda mx-30 sale]`,
            `[new mazda mx-30 for sale]`,
            `[mazda mx-30 lease offer]`,
            `[mazda mx-30 lease price]`,
            `[mazda mx-30 lease specials]`,
            `[mazda mx-30 dealer]`,
            `[mazda mx-30 dealer near me]`,
            `[mazda mx-30 dealership]`,
            `[mazda mx-30 for sale near me]`,
            `[mazda mx-30 near me]`,
            `[mazda cx-50 lease]`,
            `[mazda cx-50 cost]`,
            `[buy mazda cx-50]`,
            `[mazda cx-50 finance]`,
            `[how much is mazda cx-50]`,
            `[mazda cx-50 new car price]`,
            `[mazda cx-50 price]`,
            `[mazda cx-50 pricing]`,
            `[new mazda cx-50 price]`,
            `[mazda cx-50 msrp]`,
            `[mazda cx-50 payment]`,
            `[mazda cx-50 lease deal]`,
            `[mazda cx-50 cars for sale]`,
            `[mazda cx-50 deal]`,
            `[mazda cx-50 for sale]`,
            `[mazda cx-50 incentive]`,
            `[mazda cx-50 sale]`,
            `[new mazda cx-50 for sale]`,
            `[mazda cx-50 lease offer]`,
            `[mazda cx-50 lease price]`,
            `[mazda cx-50 lease specials]`,
            `[mazda cx-50 dealer]`,
            `[mazda cx-50 dealer near me]`,
            `[mazda cx-50 dealership]`,
            `[mazda cx-50 for sale near me]`,
            `[mazda cx-50 near me]`,
            `[mazda cx-90 lease]`,
            `[mazda cx-90 cost]`,
            `[buy mazda cx-90]`,
            `[mazda cx-90 finance]`,
            `[how much is mazda cx-90]`,
            `[mazda cx-90 new car price]`,
            `[mazda cx-90 price]`,
            `[mazda cx-90 pricing]`,
            `[new mazda cx-90 price]`,
            `[mazda cx-90 msrp]`,
            `[mazda cx-90 payment]`,
            `[mazda cx-90 lease deal]`,
            `[mazda cx-90 cars for sale]`,
            `[mazda cx-90 deal]`,
            `[mazda cx-90 for sale]`,
            `[mazda cx-90 incentive]`,
            `[mazda cx-90 sale]`,
            `[new mazda cx-90 for sale]`,
            `[mazda cx-90 lease offer]`,
            `[mazda cx-90 lease price]`,
            `[mazda cx-90 lease specials]`,
            `[mazda cx-90 dealer]`,
            `[mazda cx-90 dealer near me]`,
            `[mazda cx-90 dealership]`,
            `[mazda cx-90 for sale near me]`,
            `[mazda cx-90 near me]`,
            `[mazda cx-90 phev lease]`,
            `[mazda cx-90 phev cost]`,
            `[buy mazda cx-90 phev]`,
            `[mazda cx-90 phev finance]`,
            `[how much is mazda cx-90 phev]`,
            `[mazda cx-90 phev new car price]`,
            `[mazda cx-90 phev price]`,
            `[mazda cx-90 phev pricing]`,
            `[new mazda cx-90 phev price]`,
            `[mazda cx-90 phev msrp]`,
            `[mazda cx-90 phev payment]`,
            `[mazda cx-90 phev lease deal]`,
            `[mazda cx-90 phev cars for sale]`,
            `[mazda cx-90 phev deal]`,
            `[mazda cx-90 phev for sale]`,
            `[mazda cx-90 phev incentive]`,
            `[mazda cx-90 phev sale]`,
            `[new mazda cx-90 phev for sale]`,
            `[mazda cx-90 phev lease offer]`,
            `[mazda cx-90 phev lease price]`,
            `[mazda cx-90 phev lease specials]`,
            `[mazda cx-90 phev dealer]`,
            `[mazda cx-90 phev dealer near me]`,
            `[mazda cx-90 phev dealership]`,
            `[mazda cx-90 phev for sale near me]`,
            `[mazda cx-90 phev near me]`,
            `[Mazda3 test drives]`,
            `[Mazda3 test drive]`,
            `[new Mazda3 test drives]`,
            `[new Mazda3 test drive]`,
            `Mazda3 test drives`,
            `Mazda3 test drive`,
            `new Mazda3 test drives`,
            `new Mazda3 test drive`,
            `[Mazda3 quotes]`,
            `[Mazda3 quote]`,
            `[Mazda3 rebate]`,
            `[Mazda3 rebates]`,
            `[Mazda3 incentives]`,
            `[Mazda3 deals]`,
            `[Mazda3 specials]`,
            `[Mazda3 special]`,
            `[Mazda3 sales]`,
            `[new Mazda3 quotes]`,
            `[new Mazda3 quote]`,
            `[new Mazda3 rebate]`,
            `[new Mazda3 rebates]`,
            `[new Mazda3 incentives]`,
            `[new Mazda3 incentive]`,
            `[new Mazda3 deals]`,
            `[new Mazda3 deal]`,
            `[new Mazda3 specials]`,
            `[new Mazda3 special]`,
            `[new Mazda3 sales]`,
            `[new Mazda3 sale]`,
            `[new Mazda3 MSRP]`,
            `Mazda3 quotes`,
            `Mazda3 quote`,
            `Mazda3 rebate`,
            `Mazda3 rebates`,
            `Mazda3 incentives`,
            `Mazda3 incentive`,
            `Mazda3 deals`,
            `Mazda3 deal`,
            `Mazda3 specials`,
            `Mazda3 special`,
            `Mazda3 sales`,
            `Mazda3 sale`,
            `Mazda3 MSRP`,
            `Mazda3 price`,
            `new Mazda3 quotes`,
            `new Mazda3 quote`,
            `new Mazda3 rebate`,
            `new Mazda3 rebates`,
            `new Mazda3 incentives`,
            `new Mazda3 incentive`,
            `new Mazda3 deals`,
            `new Mazda3 deal`,
            `new Mazda3 specials`,
            `new Mazda3 special`,
            `new Mazda3 sales`,
            `new Mazda3 sale`,
            `new Mazda3 MSRP`,
            `new Mazda3 price`,
            `[finance Mazda3]`,
            `[finance new Mazda3]`,
            `[purchase Mazda3]`,
            `[purchase new Mazda3]`,
            `[buy new Mazda3]`,
            `finance Mazda3`,
            `finance new Mazda3`,
            `purchase Mazda3`,
            `purchase new Mazda3`,
            `buy Mazda3`,
            `buy new Mazda3`,
            `new Mazda3 lease`,
            `[new Mazda3 lease]`,
            `Mazda3 lease`,
            `[Mazda6 test drives]`,
            `[Mazda6 test drive]`,
            `[new Mazda6 test drives]`,
            `[new Mazda6 test drive]`,
            `Mazda6 test drives`,
            `Mazda6 test drive`,
            `new Mazda6 test drives`,
            `new Mazda6 test drive`,
            `[Mazda6 quotes]`,
            `[Mazda6 quote]`,
            `[Mazda6 rebate]`,
            `[Mazda6 rebates]`,
            `[Mazda6 incentives]`,
            `[Mazda6 incentive]`,
            `[Mazda6 deals]`,
            `[Mazda6 deal]`,
            `[Mazda6 specials]`,
            `[Mazda6 special]`,
            `[Mazda6 sales]`,
            `[Mazda6 sale]`,
            `[Mazda6 MSRP]`,
            `[Mazda6 price]`,
            `[new Mazda6 quotes]`,
            `[new Mazda6 quote]`,
            `[new Mazda6 rebate]`,
            `[new Mazda6 rebates]`,
            `[new Mazda6 incentives]`,
            `[new Mazda6 incentive]`,
            `[new Mazda6 deals]`,
            `[new Mazda6 deal]`,
            `[new Mazda6 specials]`,
            `[new Mazda6 special]`,
            `[new Mazda6 sales]`,
            `[new Mazda6 sale]`,
            `[new Mazda6 MSRP]`,
            `[new Mazda6 price]`,
            `Mazda6 quotes`,
            `Mazda6 quote`,
            `Mazda6 rebate`,
            `Mazda6 rebates`,
            `Mazda6 incentives`,
            `Mazda6 incentive`,
            `Mazda6 deals`,
            `Mazda6 deal`,
            `Mazda6 specials`,
            `Mazda6 special`,
            `Mazda6 sales`,
            `Mazda6 sale`,
            `Mazda6 MSRP`,
            `Mazda6 price`,
            `new Mazda6 quotes`,
            `new Mazda6 quote`,
            `new Mazda6 rebate`,
            `new Mazda6 rebates`,
            `new Mazda6 incentives`,
            `new Mazda6 incentive`,
            `new Mazda6 deals`,
            `new Mazda6 deal`,
            `new Mazda6 specials`,
            `new Mazda6 special`,
            `new Mazda6 sales`,
            `new Mazda6 sale`,
            `new Mazda6 MSRP`,
            `new Mazda6 price`,
            `[finance Mazda6]`,
            `[finance new Mazda6]`,
            `[purchase Mazda6]`,
            `[purchase new Mazda6]`,
            `[buy Mazda6]`,
            `[buy new Mazda6]`,
            `finance Mazda6`,
            `finance new Mazda6`,
            `purchase Mazda6`,
            `purchase new Mazda6`,
            `buy Mazda6`,
            `buy new Mazda6`,
            `new Mazda6 lease`,
            `[new Mazda6 lease]`,
            `Mazda6 lease`,
            `[Mazda6 lease]`,
            `mazda mx5 lease deal`,
            `mazda mx5 lease deals`,
            `mazda mx5 lease offer`,
            `mazda mx5 lease offers`,
            `[mazda mx5 lease deal]`,
            `[mazda mx5 lease deals]`,
            `[mazda mx5 lease offer]`,
            `[mazda mx5 lease offers]`,
            `mazda mx5 for sale`,
            `[mazda mx5 for sale]`,
            `mazda mx5 deal`,
            `mazda mx5 incentive`,
            `mazda mx5 deals`,
            `mazda mx5 incentives`,
            `mazda mx5 finance`,
            `mazda mx5 lease`,
            `mazda mx5 leases`,
            `mazda mx5 msrp`,
            `mazda mx5 payment`,
            `mazda mx5 price`,
            `[mazda mx5 deal]`,
            `[mazda mx5 incentive]`,
            `[mazda mx5 deals]`,
            `[mazda mx5 incentives]`,
            `[mazda mx5 finance]`,
            `[mazda mx5 lease]`,
            `[mazda mx5 leases]`,
            `[mazda mx5 msrp]`,
            `[mazda mx5 payment]`,
            `[mazda mx5 price]`,
            `how much is mazda mx5`,
            `mazda cx3 for sale`,
            `mazda cx5 for sale`,
            `mazda cx9 for sale`,
            `mazda miata for sale`,
            `[mazda cx3 for sale]`,
            `[mazda cx5 for sale]`,
            `[mazda cx9 for sale]`,
            `[mazda miata for sale]`,
            `[CX-30 test drives]`,
            `[CX30 test drives]`,
            `[CX-30 test drive]`,
            `[CX30 test drive]`,
            `[new CX-30 test drives]`,
            `[new CX30 test drives]`,
            `[new CX-30 test drive]`,
            `[new CX30 test drive]`,
            `CX-30 test drives`,
            `CX30 test drives`,
            `CX-30 test drive`,
            `CX30 test drive`,
            `new CX-30 test drives`,
            `new CX30 test drives`,
            `new CX-30 test drive`,
            `new CX30 test drive`,
            `[CX-30 quotes]`,
            `[CX30 quotes]`,
            `[CX-30 quote]`,
            `[CX30 quote]`,
            `[CX-30 rebate]`,
            `[CX30 rebate]`,
            `[CX-30 rebates]`,
            `[CX30 rebates]`,
            `[CX-30 incentives]`,
            `[CX30 incentives]`,
            `[CX-30 incentive]`,
            `[CX30 incentive]`,
            `[CX-30 deals]`,
            `[CX30 deals]`,
            `[CX-30 deal]`,
            `[CX30 deal]`,
            `[CX-30 specials]`,
            `[CX30 specials]`,
            `[CX-30 special]`,
            `[CX30 special]`,
            `[CX-30 sales]`,
            `[CX30 sales]`,
            `[CX-30 sale]`,
            `[CX30 sale]`,
            `[CX-30 MSRP]`,
            `[CX30 MSRP]`,
            `[CX-30 price]`,
            `[CX30 price]`,
            `[new CX-30 quotes]`,
            `[new CX30 quotes]`,
            `[new CX-30 quote]`,
            `[new CX30 quote]`,
            `[new CX-30 rebate]`,
            `[new CX30 rebate]`,
            `[new CX-30 rebates]`,
            `[new CX30 rebates]`,
            `[new CX-30 incentives]`,
            `[new CX30 incentives]`,
            `[new CX-30 incentive]`,
            `[new CX30 incentive]`,
            `[new CX-30 deals]`,
            `[new CX30 deals]`,
            `[new CX-30 deal]`,
            `[new CX30 deal]`,
            `[new CX-30 specials]`,
            `[new CX30 specials]`,
            `[new CX-30 special]`,
            `[new CX30 special]`,
            `[new CX-30 sales]`,
            `[new CX30 sales]`,
            `[new CX-30 sale]`,
            `[new CX30 sale]`,
            `[new CX-30 MSRP]`,
            `[new CX30 MSRP]`,
            `[new CX-30 price]`,
            `[new CX30 price]`,
            `CX-30 quotes`,
            `CX30 quotes`,
            `CX-30 quote`,
            `CX30 quote`,
            `CX-30 rebate`,
            `CX30 rebate`,
            `CX-30 rebates`,
            `CX30 rebates`,
            `CX-30 incentives`,
            `CX30 incentives`,
            `CX-30 incentive`,
            `CX30 incentive`,
            `CX-30 deals`,
            `CX30 deals`,
            `CX-30 deal`,
            `CX30 deal`,
            `CX-30 specials`,
            `CX30 specials`,
            `CX-30 special`,
            `CX30 special`,
            `CX-30 sales`,
            `CX30 sales`,
            `CX-30 sale`,
            `CX30 sale`,
            `CX-30 MSRP`,
            `CX30 MSRP`,
            `CX-30 price`,
            `CX30 price`,
            `new CX-30 quotes`,
            `new CX30 quotes`,
            `new CX-30 quote`,
            `new CX30 quote`,
            `new CX-30 rebate`,
            `new CX30 rebate`,
            `new CX-30 rebates`,
            `new CX30 rebates`,
            `new CX-30 incentives`,
            `new CX30 incentives`,
            `new CX-30 incentive`,
            `new CX30 incentive`,
            `new CX-30 deals`,
            `new CX30 deals`,
            `new CX-30 deal`,
            `new CX30 deal`,
            `new CX-30 specials`,
            `new CX30 specials`,
            `new CX-30 special`,
            `new CX30 special`,
            `new CX-30 sales`,
            `new CX30 sales`,
            `new CX-30 sale`,
            `new CX30 sale`,
            `new CX-30 MSRP`,
            `new CX30 MSRP`,
            `new CX-30 price`,
            `new CX30 price`,
            `[finance CX-30]`,
            `[finance CX30]`,
            `[finance new CX-30]`,
            `[purchase CX-30]`,
            `[finance new CX30]`,
            `[purchase new CX-30]`,
            `[purchase CX30]`,
            `[buy CX-30]`,
            `[buy new CX-30]`,
            `[purchase new CX30]`,
            `finance CX-30`,
            `[buy CX30]`,
            `finance new CX-30`,
            `[buy new CX30]`,
            `purchase CX-30`,
            `finance CX30`,
            `purchase new CX-30`,
            `buy CX-30`,
            `finance new CX30`,
            `buy new CX-30`,
            `purchase CX30`,
            `purchase new CX30`,
            `buy CX30`,
            `buy new CX30`,
            `new CX-30 lease`,
            `[new CX-30 lease]`,
            `CX-30 lease`,
            `[CX-30 lease]`,
            `new CX30 lease`,
            `[new CX30 lease]`,
            `CX30 lease`,
            `[CX30 lease]`
        ],
        negative_keyword_lists: []
    }
];

export default campaigns;
