import React, { useState } from "react";
import moment from "moment";
import { coolDateString } from "../../../utils";
import Header from "./Header";
import NotRunningError from "./NotRunningError";
import DuplicateModal from "./duplicate/DuplicateModal";
import PencilIcon from "../../Shared/Icons/PencilIcon";
import CopyIcon from "../../Shared/Icons/CopyIcon";
import InformationIcon from "../../Shared/Icons/InformationIcon";
import { Table } from "../../Shared/Table";
import { CellInfo } from "react-table";
import IClient from "../../../interfaces/IClient";
import { IDynamicCampaign } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import { IAdwordsCampaign } from "../../../interfaces/Campaigns";
import IEntity from "../../../interfaces/IEntity";
import IMicrosoftCampaign from "../../../interfaces/IMicrosoftCampaign";
import TableActionLink from "../../Shared/Table/TableActionLink";
import TableActionButton from "../../Shared/Table/TableActionButton";
import TableActionDeleteButton from "../../Shared/Table/TableActionDeleteButton";
import GoogleColorIcon from "../../Shared/Icons/GoogleColoredIcon";
import MicrosoftColorIcon from "../../Shared/Icons/MicrosoftColorIcon";

interface IButtonsProps {
    campaign: any;
    openModal: any;
    onDelete: (clientId: number, campaignId: number) => void;
}

const Buttons: React.FC<IButtonsProps> = ({ campaign, openModal, onDelete }) => {
    return (
        <div className="text-gray-500">
            <TableActionLink to={`${campaign.id}`} Icon={PencilIcon} />
            <TableActionLink to={`${campaign.id}/log`} Icon={InformationIcon} className="lg:ml-2" />
            <TableActionButton onClick={() => openModal(campaign)} Icon={CopyIcon} className="lg:ml-2" />
            <TableActionDeleteButton
                deletionMessage={`Are you sure you want to delete the campaign "${campaign.name}"?`}
                onDelete={() => onDelete(campaign.clientId, campaign.id)}
                className="lg:ml-2"
            />
        </div>
    );
};

interface IDateBlockProps {
    date: any;
}

const DateBlock: React.FC<IDateBlockProps> = ({ date }) => {
    return (
        <div className="leading-3">
            <div className="mb-0 font-semibold leading-4">{coolDateString(date)}</div>
            <span className="leading-3">{moment(date).format("hh:mma")}</span>
        </div>
    );
};

interface IProps {
    loading: boolean;
    client: IClient;
    dynamicCampaigns: IEntity<IDynamicCampaign>;
    adWordsCampaigns: IEntity<IAdwordsCampaign>;
    microsoftCampaigns: IEntity<IMicrosoftCampaign>;
    deleteDynamicCampaign: (clientId: number, id: number) => void;
}

const DynamicCampaignList: React.FC<IProps> = ({
    loading,
    client,
    dynamicCampaigns,
    adWordsCampaigns,
    microsoftCampaigns,
    deleteDynamicCampaign
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalCampaign, setModalCampaign] = useState({});

    const closeModal = () => {
        setIsModalOpen(false);
        setModalCampaign({});
    };

    const openModal = (dynamicCampaign: any) => {
        setModalCampaign(dynamicCampaign);
        setIsModalOpen(true);
    };

    const columns = [
        {
            id: "actions",
            Header: "Actions",
            Cell({ original }: CellInfo) {
                return <Buttons campaign={original} openModal={openModal} onDelete={deleteDynamicCampaign} />;
            },
            width: 135,
            className: "my-1"
        },
        {
            id: "name",
            accessor: "name",
            Header: "Name",
            className: "my-1"
        },
        {
            id: "lastPushed",
            Header: "Last Pushed",
            Cell({ column, original: campaign }: CellInfo) {
                return campaign.previousAction ? <DateBlock date={campaign.previousAction.executedAt} /> : "Never";
            },
            width: 135,
            className: "my-1"
        },
        {
            id: "campaigns",
            Header: "Campaigns",
            Cell({ original: campaign }: CellInfo | { original: IAdwordsCampaign | IMicrosoftCampaign }) {
                const campaigns = [];
                if (campaign.adwordsCampaignId && adWordsCampaigns.hasOwnProperty(campaign.adwordsCampaignId)) {
                    campaigns.push({
                        name: adWordsCampaigns[campaign.adwordsCampaignId].name,
                        icon: <GoogleColorIcon className="w-5 h-5" />
                    });
                }

                if (campaign.microsoftCampaignId && microsoftCampaigns.hasOwnProperty(campaign.microsoftCampaignId)) {
                    campaigns.push({
                        name: microsoftCampaigns[campaign.microsoftCampaignId].name,
                        icon: <MicrosoftColorIcon className="w-5 h-5" />
                    });
                }

                return (
                    <div className="flex flex-col">
                        {campaigns.map(({ name, icon }, index) => (
                            <div key={index} className="flex items-center w-full leading-3">
                                {icon}
                                <span className="ml-2">{name}</span>
                            </div>
                        ))}
                    </div>
                );
            }
        },
        {
            id: "status",
            Header: "Status",
            Cell({ original: campaign }: CellInfo | { original: IAdwordsCampaign | IMicrosoftCampaign }) {
                const campaigns = [];
                const statusColor = (status?: string) => {
                    if (status === "ENABLED") {
                        return "bg-green-600";
                    }

                    if (status === "PAUSED") {
                        return "bg-bing-yellow";
                    }

                    return "bg-red-500";
                };
                if (campaign.adwordsCampaignId && adWordsCampaigns.hasOwnProperty(campaign.adwordsCampaignId)) {
                    campaigns.push({
                        status: adWordsCampaigns[campaign.adwordsCampaignId].status as string
                    });
                }

                if (campaign.microsoftCampaignId && microsoftCampaigns.hasOwnProperty(campaign.microsoftCampaignId)) {
                    campaigns.push({
                        status: microsoftCampaigns[campaign.microsoftCampaignId].status as string
                    });
                }

                return (
                    <div className="flex flex-col">
                        {campaigns.map(({ status }, index) => (
                            <div key={index} className="flex items-center w-full leading-3 my-1">
                                <span className={`w-3 h-3 rounded-full ${statusColor(status)}`}></span>
                                <span className="ml-2">{status.toLowerCase()}</span>
                            </div>
                        ))}
                    </div>
                );
            }
        }
    ];

    return (
        <div className="mx-4">
            <Header showButton={true} />

            <NotRunningError client={client} />

            <div className="shadow bg-white rounded overflow-hidden">
                <Table
                    data={Object.values(dynamicCampaigns)}
                    columns={columns}
                    loadingTable={loading}
                    loadingMessage={"Loading Dynamic Campaigns"}
                    noDataText={"There are no dynamic campaigns for this client."}
                    className="extra-padding-for-the-rows text-gray-700"
                />
                <DuplicateModal isOpen={isModalOpen} closeModal={closeModal} dynamicCampaign={modalCampaign} />
            </div>
        </div>
    );
};

export default DynamicCampaignList;
