import React from "react";
import numeral from "numeral";

interface IProps {
    value: number;
}

const PercentageCell: React.FunctionComponent<IProps> = ({ value }) => (
    <div className="cell-wrapper currency-cell">{numeral(value).format("0,0%")}</div>
);

export default PercentageCell;
