import React from "react";
import Button, { SECONDARY_BUTTON } from "../../Shared/Button";

interface IProps {
    hide: () => void;
    details: Array<string>;
}

const MessagesModal = ({ hide, details }: IProps) => {
    return (
        <div className={`w-full flex flex-col justify-between h-full`}>
            <ul className={`w-full flex-grow border border-t-0 overflow-y-scroll mb-8 mx-2`}>
                {details.map((detail, index) => (
                    <li className={`w-full flex justify-between items-center odd:bg-gray-200 p-3`} key={index}>
                        {detail}
                    </li>
                ))}
            </ul>
            <div className="w-full flex justify-end">
                <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                    Close
                </Button>
            </div>
        </div>
    );
};

export default MessagesModal;
