import React, { SVGProps } from "react";
import Svg from "./Svg";

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M21.427 19.09l-4.339-4.34a7.627 7.627 0 1 0-2.106 2.209l4.288 4.288c.298.298.689.446 1.079.446s.78-.148 1.079-.446a1.526 1.526 0 0 0 0-2.157zm-10.8-3.235a5.155 5.155 0 1 1 0-10.31 5.155 5.155 0 0 1 0 10.31z" />
    </Svg>
);

export default SearchIcon;
