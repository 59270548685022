import { schema } from "normalizr";
import { transformActivationApiResponse, transformExecuteApiResponse } from "../transform/Budget";
import BudgetConstants from "../../constants/budgets/BudgetConstants";

const budgetResponse = new schema.Entity("budgets");

const activateResponse = new schema.Entity(
    "budget",
    {},
    { processStrategy: (entity) => transformActivationApiResponse(entity) }
);

const executeResponse = new schema.Entity(
    "budget",
    {},
    { processStrategy: (entity) => transformExecuteApiResponse(entity) }
);

const singleClientBudget = new schema.Entity("budget");
const singleClientManualBudget = new schema.Entity("manualBudget");

const changeLogs = new schema.Entity(
    "changeLogs",
    {},
    {
        processStrategy: (entity) => {
            entity.type = BudgetConstants.CHANGE_LOG;
            return entity;
        },
        idAttribute: "activationId"
    }
);

const systemLogs = new schema.Entity(
    "systemLogs",
    {},
    {
        processStrategy: (entity) => {
            entity.type = BudgetConstants.SYSTEM_LOG;
            return entity;
        },
        idAttribute: "actionId"
    }
);

const carsBudgetResponse = new schema.Entity(
    "carsBudgets",
    {},
    {
        idAttribute: "lineItemId"
    }
);

const allBudgetsResponse = new schema.Entity(
    "allBudgets",
    {},
    {
        idAttribute: "id"
    }
);

const facebookBudgetResponse = new schema.Entity("facebookBudgets", {});

const koddiBudgetResponse = new schema.Entity("koddiBudgets", {});
const programmaticVideoBudgetResponse = new schema.Entity(
    "programmaticVideoBudgets",
    {},
    {
        idAttribute: (value: any, pagination: any, valuePaginationAccessorKey: string) => {
            return value.adGroupId;
        }
    }
);

const Schemas = {
    ADWORDS_BUDGETS_ARRAY: { data: [budgetResponse] },
    ADWORDS_BUDGET: budgetResponse,
    EXECUTE_RESPONSE: executeResponse,
    REACTIVATE_RESPONSE: activateResponse,
    SINGLE_BUDGET_RESPONSE: singleClientBudget,
    LOG_ARRAY: { data: [changeLogs] },
    SYSTEM_LOG_ARRAY: { data: [systemLogs] },
    MANUAL_BUDGET: singleClientManualBudget,
    MANUAL_BUDGETS: { data: [singleClientManualBudget] },
    CARS_BUDGETS_ARRAY: { data: [carsBudgetResponse] },
    CARS_BUDGETS: carsBudgetResponse,
    FACEBOOK_BUDGETS_ARRAY: { data: [facebookBudgetResponse] },
    KODDI_BUDGETS_ARRAY: { data: [koddiBudgetResponse] },
    FACEBOOK_BUDGETS: facebookBudgetResponse,
    PROGRAMMATIC_DISPLAY_BUDGETS: { data: [programmaticVideoBudgetResponse] },
    ALL_BUDGETS: { data: [allBudgetsResponse] }
};

export default Schemas;
