import React from "react";
import IClient from "../../interfaces/IClient";
import DashboardIcon from "../Shared/Icons/DashboardIcon";
import SalesIcon from "../Shared/Icons/SalesIcon";
import WalletIcon from "../Shared/Icons/WalletIcon";
import ClientsIcon from "../Shared/Icons/ClientsIcon";
import ReportsIcon from "../Shared/Icons/ReportsIcon";
import NotVisibleIcon from "../Shared/Icons/NotVisibleIcon";
import UserGroupIcon from "../Shared/Icons/UserGroupIcon";
import CampaignIcon from "../Shared/Icons/CampaignIcon";
import DynamicCampaignsIcon from "../Shared/Icons/DynamicCampaignsIcon";
import RemarketingExportsIcon from "../Shared/Icons/RemarketingExportsIcon";
import PencilOutlineIcon from "../Shared/Icons/PencilOutlineIcon";
import InventoryModifiersIcon from "../Shared/Icons/InventoryModifiersIcon";
import DealerSetupIcon from "../Shared/Icons/DealerSetupIcon";
import useLocalStorage from "../../hooks/useLocalStorage";
import NavItem, { INavItemOption } from "./NavItem";
import IAppState from "../../interfaces/IAppState";
import { connect } from "react-redux";
import IUser from "../../interfaces/IUser";
import { isAdmin, isSupervisor, isUserInternal } from "../../reducers/currentUser";
import ManualSpecialOfferTemplatesIcon from "../Shared/Icons/ManualSpecialOfferTemplatesIcon";
import ActivityLogIcon from "../Shared/Icons/ActivityLogIcon";
import VariableIcon from "../Shared/Icons/VariableIcon";

const buildClientSection = (client?: IClient, user?: IUser, readonly?: boolean) => {
    const isClientSet = (c?: IClient) => !!c;
    const clientSupportsAdWords = (c?: IClient) => !!c?.adwordsId;
    const clientSupportsBing = (c?: IClient) => !!c?.bingId;
    const clientSupportsFacebook = (c?: IClient) => !!c?.facebookAccountId;
    const navItems: {
        [key: string]: {
            section: string;
            basePermission: boolean;
            items: INavItemOption[];
        };
    } = {
        client: {
            section: "",
            basePermission: !readonly && isClientSet(client),
            items: [
                {
                    url: `/client/${client?.id}`,
                    icon: <DashboardIcon className="w-8 h-8 mr-2" />,
                    title: "Dashboard",
                    shouldShow: true
                },
                {
                    url: `/client/${client?.id}/campaigns/google`,
                    icon: <CampaignIcon className="w-8 h-8 mr-2" />,
                    title: "Campaigns",
                    shouldShow: !!(client?.adwordsId || client?.bingId)
                },
                {
                    url: `/client/${client?.id}/campaigns/inventory-search-ads`,
                    icon: <CampaignIcon className="w-8 h-8 mr-2" />,
                    title: "Inventory Search Ads",
                    shouldShow: !!client?.koddiId
                },
                {
                    url: `/client/${client?.id}/campaigns/tracking-parameters`,
                    icon: <CampaignIcon className="w-8 h-8 mr-2" />,
                    title: "Tracking Parameters",
                    shouldShow: clientSupportsAdWords(client) || clientSupportsBing(client)
                },
                {
                    url: `/client/${client?.id}/dynamic-campaigns`,
                    icon: <DynamicCampaignsIcon className="w-8 h-8 mr-2" />,
                    title: "Dynamic Campaigns",
                    shouldShow: clientSupportsAdWords(client) || clientSupportsBing(client)
                },
                {
                    url: `/client/${client?.id}/special-offers`,
                    icon: <ManualSpecialOfferTemplatesIcon className="w-8 h-8 mr-2" />,
                    title: "Special Offers",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/client/${client?.id}/sales-map`,
                    icon: <SalesIcon className="w-8 h-8 mr-2" />,
                    title: "Sales Map",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/client/${client?.id}/import-sales-data`,
                    icon: <SalesIcon className="w-8 h-8 mr-2" />,
                    title: "Import Sales",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/client/${client?.id}/remarketing`,
                    icon: <RemarketingExportsIcon className="w-8 h-8 mr-2" />,
                    title: "Remarketing Exports",
                    shouldShow:
                        (clientSupportsFacebook(client) || clientSupportsAdWords(client)) && isUserInternal(user)
                },
                {
                    url: `/client/${client?.id}/budgets/all`,
                    icon: <WalletIcon className="w-8 h-8 mr-2" />,
                    title: "Budgets",
                    shouldShow: clientSupportsBing(client) || clientSupportsAdWords(client)
                },
                {
                    url: `/client/${client?.id}/negative-keywords/campaigns`,
                    icon: <PencilOutlineIcon className="w-8 h-8 mr-2" />,
                    title: "Negative Keywords",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/client/${client?.id}/exclusions`,
                    icon: <PencilOutlineIcon className="w-8 h-8 mr-2" />,
                    title: "Exclusions",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/client/${client?.id}/inventory-modifiers`,
                    icon: <InventoryModifiersIcon className="w-8 h-8 mr-2" />,
                    title: "Inventory Modifiers",
                    shouldShow: true
                },
                {
                    url: `/client/${client?.id}/dealer-setup`,
                    icon: <DealerSetupIcon className="w-8 h-8 mr-2" />,
                    title: "Dealer Setup",
                    shouldShow: isUserInternal(user)
                }
            ]
        },
        accountManagerNav: {
            section: "",
            basePermission: isClientSet(client) && !!readonly,
            items: [
                {
                    url: `/client/${client?.id}`,
                    icon: <DashboardIcon className="w-8 h-8 mr-2" />,
                    title: "Dashboard",
                    shouldShow: true
                },
                {
                    url: `/client/${client?.id}/sales-map`,
                    icon: <SalesIcon className="w-8 h-8 mr-2" />,
                    title: "Sales Map",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/client/${client?.id}/budgets/all`,
                    icon: <WalletIcon className="w-8 h-8 mr-2" />,
                    title: "Budgets",
                    shouldShow: !!client?.adwordsId || !!client?.bingId
                }
            ]
        },
        adminNav: {
            section: "ADMIN",
            basePermission: !!(!readonly && isAdmin(user)),
            items: [
                {
                    url: `/clients`,
                    icon: <ClientsIcon className="w-8 h-8 mr-2" />,
                    title: "Clients",
                    shouldShow: true
                },
                {
                    url: `/admin/oem-reports`,
                    icon: <ReportsIcon className="w-8 h-8 mr-2" />,
                    title: "OEM Reports",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/admin/negative-keywords`,
                    icon: <NotVisibleIcon className="w-8 h-8 mr-2" />,
                    title: "Negative Keywords",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/admin/placement-exclusions`,
                    icon: <NotVisibleIcon className="w-8 h-8 mr-2" />,
                    title: "Placement Exclusions",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/admin/facebook-report`,
                    icon: <ReportsIcon className="w-8 h-8 mr-2" />,
                    title: "Facebook Report",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/admin/activity-log`,
                    icon: <ActivityLogIcon className="w-8 h-8 ml-1" />,
                    title: "Activity Log",
                    shouldShow: isUserInternal(user)
                },
                {
                    url: `/admin/naming-conventions`,
                    icon: <VariableIcon className="w-8 h-8 ml-1" />,
                    title: "Naming Conventions",
                    shouldShow: isUserInternal(user)
                }
            ]
        },
        fuelAdmin: {
            section: "FUEL Admin",
            basePermission: !!(!readonly && isSupervisor(user)),
            items: [
                {
                    url: "/users",
                    icon: <UserGroupIcon className="w-6 h-6 text-blue-100 ml-2" />,
                    title: "Users",
                    shouldShow: true
                }
            ]
        },
        allClients: {
            section: "All Clients",
            basePermission: true,
            items: [
                {
                    url: `/budgets/adwords`,
                    icon: <WalletIcon className="w-8 h-8 mr-2" />,
                    title: "All Budgets",
                    shouldShow: true
                }
            ]
        }
    };

    return Object.keys(navItems)
        .filter((key: string) => navItems[key].basePermission)
        .map((key: string) => {
            const items = navItems[key];

            return {
                ...items,
                items: items.items.filter((item) => item.shouldShow)
            };
        });
};

// read only, supervisors, admin
interface IProps {
    user: IUser;
    client?: IClient;
    id?: string;
    uri: string;
}

const LeftNavBar: React.FunctionComponent<IProps> = ({ user, uri, client, id }) => {
    const [collapsed] = useLocalStorage("menu-collapsed", false);

    const usersRoles = user.roles.map((role: any) => role.name);

    const readOnly = usersRoles.includes("readonly_budgets");

    const navItems = buildClientSection(client, user, readOnly);

    return (
        <div className="relative" style={{ width: collapsed ? "75px" : "250px" }}>
            <div
                className={`hidden-xs overflow-hidden pb-4 bg-blue-700 min-h-screen text-base h-full`}
                style={{ width: collapsed ? "75px" : "250px" }}
            >
                <div className="text-white flex flex-col" style={{ marginTop: "-1px" }}>
                    {navItems.map((item) => (
                        <div key={item.section + "navbarsection"} className="flex flex-col">
                            {item.section && (
                                <div
                                    className={"py-2 pl-6 text-sm uppercase border-t w-full"}
                                    style={{
                                        borderColor: "rgb(48 108 158)"
                                    }}
                                >
                                    {item.section}
                                </div>
                            )}
                            {item.items.map((item) => (
                                <NavItem key={item.url} item={item} collapsed={collapsed} uri={uri} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IAppState, ownProps: any) => ({
    client: state.clients.currentClient ? state.clients.entities[state.clients.currentClient] : undefined,
    id: ownProps.id ?? null,
    uri: window.location.pathname
});

export default connect(mapStateToProps)(LeftNavBar);
