import React from "react";
import { Card, CardTitle } from "../BudgetDetails/BudgetDetails";
import IManualBudgetApi from "../../../../interfaces/Budgets/IManualBudgetApi";
import numeral from "numeral";

const InfoRow = "flex justify-between mt-4";

interface IProps {
    manualBudget: IManualBudgetApi;
}

const ManualBudgetInfo: React.FunctionComponent<IProps> = ({ manualBudget }) => {
    const { client, lastActivation } = manualBudget;
    const { label, targetSpend, changeRequestLink, type } = lastActivation || {};
    const { name: clientName, clientServicesPod } = client || {};

    return (
        <div className={Card}>
            <div className={CardTitle}>{clientName}</div>

            <div className={`text-gray-800 font-bold mt-4`}>Monthly {type} Budget</div>

            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Label</div>
                <div className={`text-gray-800 font-bold`}>{label}</div>
            </div>
            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Target spends</div>
                <div className={`text-gray-800 font-bold`}>${numeral(targetSpend).format("0,0.00")}</div>
            </div>
            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Client Services Pod</div>
                <div className={`text-gray-800 font-bold`}>{clientServicesPod}</div>
            </div>
            {changeRequestLink && changeRequestLink.startsWith("http") && (
                <div className={InfoRow}>
                    <a
                        href={changeRequestLink}
                        className={`underline text-blue-500 font-bold flex items-center`}
                        target="_blank"
                    >
                        <svg
                            className="w-5 h-5 mr-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            ></path>
                        </svg>
                        <span>Change Reason URL</span>
                    </a>
                </div>
            )}
            {changeRequestLink && !changeRequestLink.startsWith("http") && (
                <div className={InfoRow}>
                    <div>Change Request Link</div>
                    <div>{changeRequestLink}</div>
                </div>
            )}
        </div>
    );
};

export default ManualBudgetInfo;
