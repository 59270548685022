import React, { Fragment } from "react";
import IFormErrors from "../interfaces/IFormErrors";
import humps from "humps";

const FormErrorBoundary: React.FunctionComponent<IFormErrors> = ({ errors, field, children }) => {
    const hasErrors = () => errors.hasOwnProperty(field) || errors.hasOwnProperty(humps.camelize(field));

    return (
        <Fragment>
            <div className={hasErrors() ? "border-red border rounded" : ""}>{children}</div>
            {hasErrors() && (
                <div className="text-red-500 font-size-xs uppercase">
                    {errors[field] || errors[humps.camelize(field)]}
                </div>
            )}
        </Fragment>
    );
};

export default FormErrorBoundary;
