import { combineReducers, Reducer } from "redux";
import { createSelector } from "reselect";

import ManualBudgetConstants from "../constants/budgets/ManualBudgetConstants";
import IAppState from "../interfaces/IAppState";
import IEntity from "../interfaces/IEntity";
import IClient from "../interfaces/IClient";
import IManualBudgetApi from "../interfaces/Budgets/IManualBudgetApi";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

export interface IManualFormStatus {
    errors: any;
    message: string;
    isSubmitting: boolean;
    missing: boolean;
}

const getBudgets = (state: IAppState) => state.manualBudgets.entities;
const getQueryOptionsForBudgets = (state: IAppState) => state.manualBudgets.shouldShowDeleted;
const getClients = (state: IAppState) => state.clients.entities;

export const getBudgetsForTableFromState = createSelector(
    [getBudgets, getClients, getQueryOptionsForBudgets],
    (budgets: any, _clients: IEntity<IClient>, showDeleted: boolean) => {
        return Object.keys(budgets)
            .filter((budgetId) => budgetId)
            .map((budgetId) => {
                return {
                    ...budgets[budgetId]
                };
            })
            .filter((budget) => {
                if (showDeleted) {
                    return budget.deletedAt !== null;
                }

                return budget.deletedAt === null;
            });
    }
);

const reduceTheBudgetsBasedOnTheTypeToKeepTheStateClean = (state: IEntity<IManualBudgetApi>, payload: any) => {
    const possibleFirstItemKey = Object.keys(state)[0];
    const possibleFirstItemOfTheNewStateKey = Object.keys(payload?.budgets || {})[0];

    // If the new state doesn't have any keys or this value is empty, then we received an empty response
    if (!possibleFirstItemOfTheNewStateKey) {
        return {};
    }

    const possibleFirstItem = state[possibleFirstItemKey] ?? null;
    const possibleFirstItemOfNewState = payload.budgets[possibleFirstItemOfTheNewStateKey] ?? null;

    if (!possibleFirstItem) {
        // No old state, or an the state is empty state so we should just update it.
        return {
            ...payload.budgets
        };
    }

    const existingStatesType = possibleFirstItem?.lastActivation?.type;
    const newStatesType = possibleFirstItemOfNewState.lastActivation.type;

    if (existingStatesType === newStatesType) {
        // If the type of the last activation match merge them together
        return {
            ...state,
            ...payload.budgets
        };
    }

    return {
        ...payload.budgets
    };
};

const entitiesReducer: Reducer<any> = (state: IEntity<IManualBudgetApi>, { type, payload }) => {
    if (!state) {
        state = {};
    }

    switch (type) {
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_SUCCESS:
            return reduceTheBudgetsBasedOnTheTypeToKeepTheStateClean(state, payload);
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS_SUCCESS:
            return {
                ...state,
                ...{ [payload.budget.id]: payload.budget }
            };
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET_SUCCESS:
            return {
                ...state,
                ...{
                    [payload.activation.budgetManualHeaderId]: {
                        ...state[payload.activation.budgetManualHeaderId],
                        lastActivation: payload.activation
                    }
                }
            };
        case ManualBudgetConstants.REQUEST_DELETE_MANUAL_BUDGET_SUCCESS:
            delete state[payload.budgetId];
            return { ...state };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const loading: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET:
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGET_DETAILS:
            return true;
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET_SUCCESS:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET_FAIL:
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET_SUCCESS:
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET_FAIL:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGET_DETAILS_SUCCESS:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGET_DETAILS_FAIL:
            return false;
        default:
            return state;
    }
};

const shouldShowDeleted: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS:
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS_SUCCESS:
            return true;
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS:
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET_SUCCESS:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET_FAIL:
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET_SUCCESS:
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET_FAIL:
            return false;
        case RESET_CLIENT_STATE:
            return false;
        default:
            return state;
    }
};

const loadingMessage: Reducer<string> = (state = "", action) => {
    switch (action.type) {
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_SUCCESS:
        case RESET_CLIENT_STATE:
            return "";
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET:
            return "Creating Budget";
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET:
            return "Updating Budget";
        default:
            return state;
    }
};

const initialStatusState = {
    errors: {},
    message: "",
    isSubmitting: false,
    missing: false
};
const formStatus: Reducer<IManualFormStatus> = (state = initialStatusState, action) => {
    switch (action.type) {
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET:
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET:
            return {
                ...state,
                isSubmitting: true
            };
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET_SUCCESS:
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET_SUCCESS:
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS_SUCCESS:
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                missing: false,
                ...action.payload
            };
        case ManualBudgetConstants.REQUEST_ALL_MANUAL_BUDGETS_FAIL:
        case ManualBudgetConstants.REQUEST_CREATE_MANUAL_BUDGET_FAIL:
        case ManualBudgetConstants.REQUEST_UPDATE_MANUAL_BUDGET_FAIL:
        case ManualBudgetConstants.REQUEST_ALL_DELETED_MANUAL_BUDGETS_FAIL:
            return {
                ...state,
                isSubmitting: false,
                errors: action.payload.errors
            };
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGETS_FAIL:
            return {
                ...state,
                isSubmitting: false,
                missing: true
            };
        case RESET_CLIENT_STATE:
            return initialStatusState;
        default:
            return state;
    }
};
const details: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGET_DETAILS:
        case RESET_CLIENT_STATE:
            return {};
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGET_DETAILS_SUCCESS:
            return action.payload;
        case ManualBudgetConstants.REQUEST_SINGLE_MANUAL_BUDGET_DETAILS_FAIL:
        default:
            return state;
    }
};

const manualBudgetActivations: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case ManualBudgetConstants.REQUEST_MANUAL_BUDGET_ACTIVATIONS:
        case RESET_CLIENT_STATE:
            return {};
        case ManualBudgetConstants.REQUEST_MANUAL_BUDGET_ACTIVATIONS_SUCCESS:
            return action.payload;
        case ManualBudgetConstants.REQUEST_MANUAL_BUDGET_ACTIVATIONS_FAIL:
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    loading,
    loadingMessage,
    formStatus,
    shouldShowDeleted,
    details,
    manualBudgetActivations
});
