import React, { useRef } from "react";
import moment from "moment";
import CODES from "../../../constants/OEMReportConstants";
import { TableLoader } from "../../Shared/Table";
import ReactTable from "react-table";
import ITableState from "../../../interfaces/ITableState";
import { useSelector } from "react-redux";

export interface IReportListProps {
    onFetchReports: (state: ITableState) => any;
}
interface IProps {
    reportType: string;
    reportParamOemProgram: string;
    reportParamFromDate: string;
    processingStatus: string;
    createdAt: string;
    processingEndedAt: string;
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const formatTheReport = ({
    reportType,
    reportParamOemProgram,
    reportParamFromDate,
    processingStatus,
    createdAt,
    processingEndedAt
}: IProps) => ({
    reportType,
    reportParamOemProgram,
    reportParamFromDate,
    processingStatus: CODES[`REPORT_CODE_${processingStatus}`],
    createdAt: moment(createdAt).format(DATE_FORMAT),
    processingEndedAt: processingEndedAt ? moment(processingEndedAt).format(DATE_FORMAT) : null
});

const columns = [
    {
        id: "reportParamOemProgram",
        accessor: "reportParamOemProgram",
        Header: "OEM"
    },
    {
        id: "reportType",
        accessor: "reportType",
        Header: "Report"
    },
    {
        id: "reportParamFromDate",
        accessor: "reportParamFromDate",
        Header: "Report Date"
    },
    {
        id: "processingStatus",
        accessor: "processingStatus",
        Header: "Status"
    },
    {
        id: "createdAt",
        accessor: "createdAt",
        Header: "Created"
    },
    {
        id: "processingEndedAt",
        accessor: "processingEndedAt",
        Header: "Completed"
    }
];

const ReportList: React.FC<IReportListProps> = ({ onFetchReports }) => {
    const tableRef = useRef(null);
    const defaultPageSize = 15;
    const { OEMReports } = useSelector((state: any) => state);
    const { loading = true, pagination = {}, entities: reports = [] } = OEMReports || {};
    const { lastPage = 0, pageSize = defaultPageSize } = pagination || {};

    const formattedReports: IProps[] = reports?.length ? reports.map(formatTheReport || []) : [];

    return (
        <div className="mt-4">
            <ReactTable
                ref={tableRef}
                className="v1 -striped rounded overflow-hidden"
                data={formattedReports}
                columns={columns}
                loading={loading}
                manual={true}
                resizable={false}
                sortable={false}
                noDataText={!loading && "No Reports Found"}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={pageSize}
                pages={lastPage}
                onFetchData={(state: any) => onFetchReports(state)}
                LoadingComponent={() => loading && <TableLoader message={"Loading..."} />}
            />
        </div>
    );
};

export default ReportList;
