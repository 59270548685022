import React, { FunctionComponent, useEffect, useState } from "react";
import { Table, SelectFilter } from "../../Shared/Table";
import ToggleSwitch from "../../Shared/Form/ToggleSwitch";
import { ConfirmDialog, Notification } from "../../Shared";
import { buildTitle } from "../../Shared/Table/TableUtils";
import {
    buildBudgetColumns,
    buildFacebookBudgetColumns,
    buildCarsBudgetColumns,
    buildManualBudgetColumns,
    buildKoddiBudgetColumns,
    buildProgrammaticVideoBudgetColumns,
    buildAllBudgetColumns
} from "./BudgetColumns";
import LoaderSpinner from "../../Shared/Loaders/LoaderSpinner";
import IBudgetProps from "../../../interfaces/Budgets/IBudgetProps";
import { Column } from "react-table";
import { omitBy } from "lodash";
import useBudgetParams from "../../../hooks/budgets/useBudgetParams";
import { IFilterOption } from "../../../hooks/budgets/useBudget";
import SearchInput from "../../Shared/SearchInput";

interface IState {
    showManagers: boolean;
    searchText: string;
    filterOptions: any;
    tempOptions: any;
    showDropdown: boolean;
}
const filterValues = [
    { label: "All Active", selected: true },
    { label: "Deleted", selected: false },
    { label: "Video", selected: false },
    { label: "All Paused", selected: false },
    { label: "Breached", selected: false },
    { label: "Managed Manually", selected: false },
    { label: "Event", selected: false }
];

const Budget: FunctionComponent<IBudgetProps> = (props) => {
    const {
        budgets,
        loadingMessage,
        loadingTable,
        showConfirm,
        notification,
        showClientColumn,
        isReadOnly,
        loadingStream,
        confirmDelete,
        denyDelete,
        deleteBudget,
        hideNotification,
        pushBudget,
        setPage,
        setLimit,
        pages,
        limit,
        page,
        footer,
        filterOptions,
        setFilterOptions,
        setSearch,
        searchQuery,
        setBudgetTableSort
    } = props;

    // @ts-ignore
    const budgetSelector = (budgetId: number) => budgets.filter(({ id }) => id === budgetId)[0];

    const { budgetType } = useBudgetParams();
    const handleDeleteBudget = (budgetId: number) => {
        deleteBudget(budgetSelector(budgetId));
    };
    const handlePushBudget = (budgetId: number) => {
        pushBudget(budgetSelector(budgetId));
    };

    const [showManagers, setShowManagers] = useState<boolean>(false);
    const [tempOptions, setTempOptions] = useState<IFilterOption[]>(filterValues);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const handleClickOutside = () => {
        setTempOptions(filterOptions);
        setShowDropdown(false);
    };

    const handleApplyFilters = (filters: IFilterOption[]) => {
        setFilterOptions(filters);
        setShowDropdown(false);
    };

    const handleCancelFilters = () => {
        setTempOptions(filterOptions);
        setShowDropdown(false);
    };

    // toggles the drop from displaying
    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    // toggles individual filter options
    const handleToggleOption = (selectedIndex: number) => {
        setTempOptions((tempOptions) => {
            return tempOptions.map((item: IFilterOption, index: number) => {
                if (index !== selectedIndex) {
                    return item;
                }
                return {
                    ...item,
                    selected: !tempOptions[selectedIndex].selected
                };
            });
        });
    };

    // toggle optional columns
    const handleManagerToggle = () => setShowManagers(!showManagers);

    const title = buildTitle(filterOptions);

    const buildColumns: { [key: string]: any } = {
        facebook: buildFacebookBudgetColumns,
        cars: buildCarsBudgetColumns,
        adwords: buildBudgetColumns,
        koddi: buildKoddiBudgetColumns,
        microsoft: buildBudgetColumns,
        dsp: buildManualBudgetColumns,
        social: buildManualBudgetColumns,
        "programmatic-video": buildProgrammaticVideoBudgetColumns,
        all: buildAllBudgetColumns
    };

    const isMicrosoftOrGoogle = ["microsoft", "adwords"].includes(budgetType);
    const tableColumns = buildColumns[budgetType](
        showClientColumn,
        handlePushBudget,
        handleDeleteBudget,
        isReadOnly,
        showManagers,
        footer
    );
    let data = budgets;

    if (!["facebook", "cars", "koddi", "programmatic-video", "all"].includes(budgetType)) {
        data = omitBy(budgets, ({ client }) => !client?.id) as unknown as any; //filter budgets whose client has been deleted
    }

    return (
        <div className="bg-white shadow rounded overflow-hidden">
            <div className="flex p-4 justify-between">
                <div className="flex flex-1 items-center">
                    <SearchInput
                        url={window.location.pathname}
                        onChange={(value) => setSearch(value)}
                        useSearchHistory={true}
                        value={searchQuery}
                        queryParamName={"search"}
                    />
                    {isMicrosoftOrGoogle && (
                        <SelectFilter
                            title={title}
                            isVisible={showDropdown}
                            filterOptions={tempOptions}
                            onClickOutside={handleClickOutside}
                            onToggleOption={handleToggleOption}
                            onToggleDropdown={handleToggleDropdown}
                            onApplyFilters={handleApplyFilters}
                            onCancelFilters={handleCancelFilters}
                        />
                    )}
                </div>
                {notification.isVisible && (
                    <Notification
                        color={notification.color}
                        title={notification.title}
                        message={notification.message}
                        onClose={hideNotification}
                        children={null}
                    />
                )}

                {!loadingTable && loadingStream && (
                    <Notification color="blue" title="Loading Additional Budgets" message="" onClose={hideNotification}>
                        <LoaderSpinner className="inline-flex px-4 my-0 py-0" message="" />
                    </Notification>
                )}

                <div className="flex flex-1 justify-end">
                    <ToggleSwitch
                        label="Show More"
                        value={showManagers}
                        onToggle={handleManagerToggle}
                        DOMId={"toggle-more"}
                    />
                </div>
            </div>

            <div className="text-sm">
                <Table
                    data={Object.values(data)}
                    resolveData={(data) => data}
                    manual={true}
                    columns={tableColumns as Column[]}
                    loadingTable={loadingTable}
                    loadingMessage={loadingMessage}
                    onPageChange={(p) => setPage(p + 1)}
                    onPageSizeChange={(s) => setLimit(s)}
                    pageSize={limit}
                    page={page - 1}
                    pages={pages}
                    onSortedChange={(sortRules, columnClicked, additive) => {
                        setBudgetTableSort(
                            sortRules.reduce(
                                (acc, item) => (item.desc ? "-" + columnClicked.sortUsing : columnClicked.sortUsing),
                                ""
                            )
                        );
                    }}
                    defaultSortDesc={true}
                />
            </div>
            {showConfirm && (
                <ConfirmDialog
                    title="Confirm Delete Budget"
                    message="Are you sure you want to delete this budget?"
                    confirmText={null}
                    onCancel={denyDelete}
                    onConfirm={confirmDelete}
                />
            )}
        </div>
    );
};

export default Budget;
