import React, { SVGProps } from "react";
import Svg from "./Svg";

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M17.424 13.184h-4.24v4.24h4.24v-4.24zM16.01 4v1.413H8.946V4h-2.12v1.413h-1.06C4.794 5.413 4 6.208 4 7.179v12.01c0 .972.794 1.767 1.766 1.767H19.19c.971 0 1.766-.795 1.766-1.766V7.18c0-.972-.795-1.767-1.766-1.767h-1.06V4h-2.12zm3.179 15.19H5.766V9.829H19.19v9.36z" />
    </Svg>
);

export default CalendarIcon;
