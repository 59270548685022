import React from "react";
import moment from "moment";

import { LogPreviewContainer, ItemField, UserLog } from "./BudgetDetails";

const ChangeLogPreview = ({ log }: any) => {
    const { updatedAt, note } = log;
    return (
        <div className={`${LogPreviewContainer} ${UserLog}`}>
            <div className={ItemField}>{moment(updatedAt).format("MM/DD/YYYY")}</div>
            <div className={ItemField}>{"\u2013"}</div>
            <div className={`flex-auto`}>{note}</div>
            <div className={`${ItemField} text-center`}>{"\u2013"}</div>
        </div>
    );
};

export default ChangeLogPreview;
