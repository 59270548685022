import React from "react";
import AddIcon from "../components/Shared/Icons/AddIcon";
import CopyIcon from "../components/Shared/Icons/CopyIcon";
import DeleteIcon from "../components/Shared/Icons/DeleteIcon";
import EditIcon from "../components/Shared/Icons/EditIcon";
import TimeIcon from "../components/Shared/Icons/TimeIcon";
import UnlockIcon from "../components/Shared/Icons/UnlockIcon";
import { IActivityLog } from "../interfaces/IEditHistory";
import { CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES } from "../constants/ClientConstants";

export const getSubject = (entity: IActivityLog) => {
    const typeName = entity?.subjectType?.split("\\");
    return entity?.subject?.name ?? typeName[typeName.length - 1] + " " + entity.subjectId;
};
export const getCauser = (entity: IActivityLog) =>
    entity?.causer ? entity.causer.firstName + " " + entity.causer.lastName : "FUEL System";
export const getDescription = (entity: any) => {
    if (entity.description === "deleted") {
        return <div className={"text-lg"}>{getSubject(entity)} deleted</div>;
    }

    if (entity.description === "restored") {
        return <div className={"text-lg"}>{getSubject(entity)} restored</div>;
    }

    if (entity.description === "created") {
        return <div className={"text-lg"}>{getSubject(entity)} created</div>;
    }

    if (entity.description === "updated") {
        const propertyKeys = Object.keys(entity.properties?.old || {});
        const propertyNamesForSection = propertyKeys.map(
            (property) => CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES[property] || property
        );

        const bits = propertyNamesForSection.join(", ");
        return (
            <div className={"text-lg"}>
                The <span className="underline">{bits}</span>{" "}
                {
                    // This is just for grammatical correctness. I normally don't like ternaries, but
                    // I find this to be quite acceptable...
                    propertyNamesForSection.length > 1 ? `fields were` : `field was`
                }{" "}
                {entity.description}
            </div>
        );
    }

    return (
        <div className={"text-lg"}>
            {getSubject(entity)} {entity.description}
        </div>
    );
};

export const getColor = (entity: IActivityLog) => {
    switch (entity.description) {
        case "created":
            return "bg-blue-500";
        case "deleted":
            return "bg-red-500";
        case "disconnected from Google Ads":
            return "bg-red-600";
        case "purged":
            return "bg-orange-500";
        case "pushed":
            return "bg-yellow-500";
        case "replicated from":
            return "bg-yellow-500";
        case "replicated to":
            return "bg-yellow-500";
        case "restored":
            return "bg-blue-500";
        case "unlocked":
            return "bg-orange-500";
        case "updated":
            return "bg-green-500";
        default:
            if (entity.description.startsWith("set up for GM Conversions")) {
                return "bg-cerulean-500";
            }
            return "bg-blue-500";
    }
};
export const getIcon = (entity: IActivityLog) => {
    switch (entity.description) {
        case "created":
            return <AddIcon className={"w-8 h-8 text-white p-1"} />;
        case "deleted":
        case "disconnected from Google Ads":
        case "purged":
            return <DeleteIcon className={"w-8 h-8 text-white p-1"} />;
        case "pushed":
        // return <Icon className={'w-8 h-8 text-white p-1'} />;
        case "replicated from":
            return <CopyIcon className={"w-8 h-8 text-white p-1"} />;
        case "replicated to":
            return <CopyIcon className={"w-8 h-8 text-white p-1"} />;
        case "restored":
            return <TimeIcon className={"w-8 h-8 text-white p-1"} />;
        case "unlocked":
            return <UnlockIcon className={"w-8 h-8 text-white p-1"} />;
        case "updated":
            return <EditIcon className={"w-8 h-8 text-white p-1"} />;
        default:
            if (entity.description.startsWith("set up for GM Conversions")) {
                return <EditIcon className={"w-8 h-8 text-white p-1"} />;
            }
            return (
                <svg
                    className="w-8 h-8 text-white p-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                        clipRule="evenodd"
                    />
                </svg>
            );
    }
};

export const generateDots = (items: any[]) => {
    const dotsArray = [];
    for (const i in items) {
        if (!items[i]) {
            continue;
        }

        dotsArray.push(<span key={"dot-at-" + i}>•</span>);
    }

    dotsArray.shift();
    return dotsArray;
};
export const interlaceElements = ([x, ...xs]: any, ys: any[] = []): any[] =>
    x === undefined ? ys : [x, ...interlaceElements(ys, xs)];

export const generateDeepLink = function (entity: IActivityLog): React.ReactNode {
    if (entity.subjectType === "App\\Models\\Client\\DynamicCampaign\\DynamicCampaign") {
        if (entity.description.toLowerCase().includes("lock")) {
            return (
                <a target="_blank" href={`/client/${entity.subject?.clientId}/dynamic-campaigns/${entity.subjectId}`}>
                    Edit Campaign In FUEL
                </a>
            );
        }

        return (
            <a target="_blank" href={`/client/${entity.subject?.clientId}/dynamic-campaigns/${entity.subjectId}/log`}>
                View Campaign In FUEL
            </a>
        );
    }
    if (entity.subjectType === "App\\Models\\Client\\Client") {
        if (entity.description.toLowerCase().includes("dealer setup")) {
            return (
                <a target="_blank" href={`/client/${entity.subjectId}/dealer-setup`}>
                    View Client's Dealer Setup in FUEL
                </a>
            );
        }

        return (
            <a target="_blank" href={`/client/${entity.subjectId}`}>
                View Client Dashboard in FUEL
            </a>
        );
    }
    if (entity.subjectType === "App\\Models\\Client\\Client") {
        if (entity.description.toLowerCase().includes("dealer setup")) {
            return <a href={`/client/${entity.subjectId}/dealer-setup`}>View Client's Dealer Setup in FUEL</a>;
        }

        return (
            <a target="_blank" href={`/client/${entity.subjectId}`}>
                View Client Dashboard in FUEL
            </a>
        );
    }

    if (entity.subjectType === "App\\Models\\Client\\DynamicCampaign\\SpecialOffers\\SpecialOffersTemplate") {
        return (
            <a target="_blank" href={`/client/${entity.subject?.clientId}/special-offers/${entity.subjectId}`}>
                View Special Offer in FUEL
            </a>
        );
    }
    return false;
};
