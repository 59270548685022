import React from "react";
import numeral from "numeral";

interface IProps {
    value: number;
    isPercent?: null | boolean;
}

const FooterCell: React.FunctionComponent<IProps> = ({ value, isPercent = false }) => (
    <div className="cell-wrapper currency-cell footer">{numeral(value).format(isPercent ? "0,0%" : "0,0.00")}</div>
);

export default FooterCell;
