import { combineReducers } from "redux";
import * as actions from "../../actions/remarketing/facebookAutoExportActions";
import * as Constants from "../../constants/InventoryExportsConstants";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";

export const buildMapping = (inventoryExport: any) => ({
    availability: "{{Status}}",
    bodyStyle: "{{Body}}",
    condition: "{{Type}}",
    description: "Now {{OurPrice | money}}",
    drivetrain: "{{Drivetrain}}",
    exteriorColor: "{{ExtColorGeneric}}",
    fuelType: "Gasoline",
    image: "{{Images}}",
    make: "{{Make}}",
    model: "{{Model}}",
    mileage: "{{Miles}}",
    price: "{{OurPrice}}",
    salePrice: "{{FinalPrice}}",
    stateOfVehicle: "{{Type}}",
    title: "{{Year}} {{Make}} {{Model}} {{Trim}}",
    transmission: "Automatic",
    url:
        inventoryExport.inventorySource === "CARS"
            ? "https://turbo.cars.com/vdp/{{ID}}#back-to-listings?aff=ddconsu&source=facebook"
            : "{{Link}}",
    vehicleId: inventoryExport.inventorySource === "CARS" ? "{{ID}}" : "{{VIN}}",
    vin: "{{VIN}}",
    year: "{{Year}}",
    trim: "{{Trim}}",
    dateFirstOnLot: "{{DateInStock}}"
});

interface IEntitiesReducer {}
const entitiesReducer = (state: { [key: number]: any } = {}, action: any) => {
    switch (action.type) {
        case actions.LOAD_FB_AUTO_EXPORT:
            return { ...state, [action.facebookAutoExport.id]: action.facebookAutoExport };
        case actions.LOAD_FB_AUTO_EXPORTS:
        case Constants.LOAD_INVENTORY_EXPORTS:
            return { ...state, ...action.response.entities.facebookAutoExports };

        case Constants.DELETE_FACEBOOK_EXPORT_SUCCESS:
            delete state[action.payload.id];
            return { ...state };

        case Constants.CREATE_FACEBOOK_EXPORT_SUCCESS:
        case Constants.UPDATE_FACEBOOK_EXPORT_SUCCESS:
            return { ...state, [action.payload.facebookExport.id]: action.payload.facebookExport };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const loading = (state = false, action: any) => {
    switch (action.type) {
        case actions.REQUEST_LOAD_FB_AUTO_EXPORTS:
        case Constants.REQUEST_CREATE_FACEBOOK_EXPORT:
        case Constants.REQUEST_UPDATE_FACEBOOK_EXPORT:
            return true;
        case actions.REQUEST_FB_AUTO_EXPORTS_FAILURE:
        case actions.LOAD_FB_AUTO_EXPORTS:
        case Constants.CREATE_FACEBOOK_EXPORT_FAIL:
        case Constants.CREATE_FACEBOOK_EXPORT_SUCCESS:
        case Constants.UPDATE_FACEBOOK_EXPORT_FAIL:
        case Constants.UPDATE_FACEBOOK_EXPORT_SUCCESS:
            return false;
        default:
            return state;
    }
};

const deleting = (state = false, action: any) => {
    switch (action.type) {
        case Constants.REQUEST_DELETE_FACEBOOK_EXPORT:
            return true;
        case Constants.DELETE_FACEBOOK_EXPORT_FAIL:
        case Constants.DELETE_FACEBOOK_EXPORT_SUCCESS:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    loading,
    deleting
});
