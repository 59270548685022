import { combineReducers } from "redux";
import * as actions from "../actions/campaignActions";
import { createSelector } from "reselect";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

export type MicrosoftCampaign = {
    id: string;
    name: string;
};

const entities = (state = {}, action: any) => {
    switch (action.type) {
        case actions.LOAD_MICROSOFT_CAMPAIGNS:
            return { ...action.response.entities.microsoftCampaigns };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const loading = (state = false, action: any) => {
    switch (action.type) {
        case actions.REQUEST_LOAD_MICROSOFT_CAMPAIGNS:
            return true;
        case actions.LOAD_MICROSOFT_CAMPAIGNS:
        case actions.REQUEST_LOAD_MICROSOFT_CAMPAIGNS_FAILURE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    entities,
    loading
});

const getCampaigns = (state: any) => state.microsoftCampaigns.entities;

export const getAll = createSelector(getCampaigns, (campaigns) => campaigns);
