import {
    createClient,
    deleteClient,
    restoreClient,
    deletePagedClient,
    saveClient,
    downloadInventoryClicked,
    clientSelectorRefreshClicked
} from "../../../../actions/clientActions";
import trackEvent from "../../../../utils/Analytics/trackEvent";

function* createEvent(action: ReturnType<typeof createClient>) {
    yield trackEvent({
        category: "Client Create Page",
        action: "client_create",
        label: "Create"
    });
}

function* updateEvent(action: ReturnType<typeof saveClient>) {
    yield trackEvent({
        category: "Client Edit Page",
        action: `client_update_${action.client.id}`,
        label: "Update"
    });
}

function* editPageRemoveEvent(action: ReturnType<typeof deleteClient>) {
    yield trackEvent({
        category: "Client Edit Page",
        action: `client_delete_${action.id}`,
        label: "Delete"
    });
}

function* indexPageRemoveEvent(action: ReturnType<typeof deletePagedClient>) {
    yield trackEvent({
        category: "Client Index Page",
        action: `client_delete_${action.payload.clientId}`,
        label: "Delete"
    });
}

function* restoreEvent(action: ReturnType<typeof restoreClient>) {
    yield trackEvent({
        category: "Client Index Page",
        action: `client_restore_${action.payload.client.id}`,
        label: "Restore"
    });
}

function* downloadInventory(action: ReturnType<typeof downloadInventoryClicked>) {
    yield trackEvent({
        category: "Client Index Page",
        action: `client_download_inventory_click_${action.clientId}`,
        label: "Download Inventory"
    });
}

function* downloadCsvClicked(action: ReturnType<typeof downloadInventoryClicked>) {
    yield trackEvent({
        category: "Clients Index Page",
        action: `client_download_csv_click_${action.clientId}`,
        label: "Download CSV"
    });
}

function* clientSelectorRefresh(action: ReturnType<typeof clientSelectorRefreshClicked>) {
    yield trackEvent({
        category: "Clients Selector",
        action: `client_selector_refresh_click_${action.clientId}`,
        label: "Refresh"
    });
}
export default {
    createEvent,
    updateEvent,
    editPageRemoveEvent,
    indexPageRemoveEvent,
    restoreEvent,
    downloadInventory,
    downloadCsvClicked,
    clientSelectorRefresh
};
