import React, { useState, useRef } from "react";
import * as Interfaces from "../../../interfaces/shared/IItemCollection";
import RefreshIcon from "../Icons/RefreshIcon";
import TrashIcon from "../Icons/TrashIcon";
import WarningIcon from "../Icons/WarningIcon";
import Svg from "../Icons/Svg";

const Item: React.FC<Interfaces.IItemProps> = ({
    style,
    description,
    item,
    deleted,
    deleteItem,
    error,
    dismissError
}) => {
    const [deleting, setDeleting] = useState(deleted);
    const nameSpan = useRef(null);
    const errorStatus: string = error ? "error" : "";
    const loadingStatus: string = (deleting || item.new) && !error ? "loading" : "";

    const handleClick = (): void => {
        if (error) {
            dismissError(item.id, error.type);
            setDeleting(false);
            return;
        }
        if (!deleting && !item.new) {
            deleteItem(item.id);
            setDeleting(true);
        }
    };

    return (
        <div className={`w-full block`} style={style} key={item.name}>
            <div className="">
                <div
                    className={`h-12 relative flex flex-wrap bg-gray-100 rounded shadow ml-4 ${loadingStatus} ${errorStatus}`}
                >
                    <span
                        ref={nameSpan}
                        className="text-base flex text-center items-center justify-center flex-1 h-12 text-gray-800 overflow-x-auto overflow-y-hidden"
                    >
                        {item.name}
                    </span>
                    <div className="border-l-2 border-gray-400 px-2 my-2" onClick={() => handleClick()}>
                        <div className="relative w-full h-full flex items-center">
                            {error && (
                                <div
                                    className="absolute z-10 right-0 mr-24"
                                    style={{
                                        transform: "translate(0, -50%)",
                                        top: "50%",
                                        right: "0"
                                    }}
                                >
                                    <Svg
                                        className="text-red-600 z-0 absolute w-6 h-6"
                                        style={{
                                            transform: "translate(50%, -50%) rotate(90deg)",
                                            top: "50%",
                                            right: "0"
                                        }}
                                    >
                                        <path d="M12.5 0.414795L23.3253 18.8537L1.67468 18.8537L12.5 0.414795Z" />
                                    </Svg>

                                    <div className="relative z-10 border-red-600 whitespace-pre px-4 tracking-tight p-4 leading-none border rounded text-red-600 bg-red-100">
                                        {error?.message}
                                    </div>
                                </div>
                            )}
                            {deleting && !error && (
                                <RefreshIcon
                                    className={`w-8 h-8 mx-auto rotate-fast ${
                                        item.new ? "text-green-500" : "text-red-500"
                                    }`}
                                />
                            )}
                            {!deleting && !error && (
                                <TrashIcon className="w-8 h-8 mx-auto text-red-200 group-hover:text-red-500" />
                            )}
                            {error && (
                                <WarningIcon className="w-8 h-8 mx-auto text-yellow-600 group-hover:text-yellow-800" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Item;
