import React, { SVGProps } from "react";
import Svg from "./Svg";

const PencilIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M17.228 11.299l-.16-.16-.126-.126-2.339-2.339c-.285-.285-.573-.57-.858-.858a49.14 49.14 0 0 1-.29-.292l-.285-.285-1.06 1.06-1.53 1.53-1.745 1.745-1.743 1.743-1.514 1.514-1.063 1.063-.291.291c-.112.112-.21.201-.215.383-.02.526 0 1.056.002 1.583l.001 2.063v.123a.41.41 0 0 0 .405.405c.525 0 1.049 0 1.573.002l2.069.002c.063 0 .121.001.182-.007a.455.455 0 0 0 .255-.134l.201-.2.922-.922 1.427-1.427 1.708-1.709 1.766-1.765 1.6-1.6 1.207-1.208c.064-.063.128-.128.193-.19-.1-.092-.195-.188-.292-.285zM20.407 7.04l-2.15-2.152c-.172-.171-.347-.344-.519-.518a1.242 1.242 0 0 0-.352-.258c-.357-.162-.8-.158-1.13.072-.18.125-.33.295-.486.45l-.784.783c-.32.32-.641.638-.962.957l3.778 3.778.285.285.285.285 1.739-1.738.295-.296.015-.015.016-.016a1.157 1.157 0 0 0-.03-1.618z" />
    </Svg>
);

export default PencilIcon;
