import React from "react";

interface IProps {
    name: string;
    value: string | number;
}

const Detail: React.FunctionComponent<IProps> = ({ name, value }) => (
    <div className="flex justify-between py-2">
        <div className={`text-base uppercase font-bold text-gray-600`}>{name}</div>
        <div className={`text-base text-gray-800 font-bold`}>
            <span>{value}</span>
        </div>
    </div>
);

export default Detail;
