import ExclusionsConstants from "../../constants/ExclusionsConstants";
import { FormikActions } from "formik";
import {
    IMappedExclusionById,
    IUpdatedExclusions,
    ExclusionCollUpdateActionTypes,
    ExclusionActionTypes,
    ExclusionCollActionTypes,
    IExclusionsList
} from "../../interfaces/Exclusions";
import IEntity from "../../interfaces/IEntity";

const requestExclusionList = (): ExclusionActionTypes => ({
    type: ExclusionsConstants.REQUEST_EXCLUSIONS_LIST,
    payload: {}
});

const requestExclusionListSuccess = (exclusions: IEntity<IExclusionsList>): ExclusionActionTypes => ({
    type: ExclusionsConstants.REQUEST_EXCLUSIONS_LIST_SUCCESS,
    payload: exclusions
});

const requestExclusionListFail = (error: any): ExclusionActionTypes => ({
    type: ExclusionsConstants.REQUEST_EXCLUSIONS_LIST_FAIL,
    payload: error
});

const requestExclusionCollCampaigns = (clientId: number): ExclusionCollActionTypes => ({
    type: ExclusionsConstants.REQUEST_EXCLUSIONS_COLL_CAMPAIGNS,
    payload: clientId
});

const requestExclusionCollCampaignsSuccess = (exclusionCollCampaigns: any): ExclusionCollActionTypes => ({
    type: ExclusionsConstants.REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS,
    payload: exclusionCollCampaigns
});

const requestExclusionCollCampaignsFail = (error: any): ExclusionCollActionTypes => ({
    type: ExclusionsConstants.REQUEST_EXCLUSIONS_COLL_CAMPAIGNS_FAIL,
    payload: error
});

const updateExclusionCollCampaigns = (
    updatedExclusions: IUpdatedExclusions,
    formikActions: FormikActions<IMappedExclusionById>,
    clientId: number
): ExclusionCollUpdateActionTypes => ({
    type: ExclusionsConstants.REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS,
    payload: { updatedExclusions, formikActions, clientId }
});

const updateExclusionCollCampaignsSuccess = (
    formikActions: FormikActions<IMappedExclusionById>,
    clientId: number
): ExclusionCollUpdateActionTypes => ({
    type: ExclusionsConstants.REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS,
    payload: { clientId, formikActions }
});

const updateExclusionCollCampaignsFail = (
    error: any,
    formikActions: FormikActions<IMappedExclusionById>,
    clientId: number
): ExclusionCollUpdateActionTypes => ({
    type: ExclusionsConstants.REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_FAIL,
    payload: { clientId, formikActions, error }
});

export default {
    requestExclusionList,
    requestExclusionListSuccess,
    requestExclusionListFail,

    requestExclusionCollCampaigns,
    requestExclusionCollCampaignsSuccess,
    requestExclusionCollCampaignsFail,

    updateExclusionCollCampaigns,
    updateExclusionCollCampaignsSuccess,
    updateExclusionCollCampaignsFail
};
