import { redirect } from "react-router-dom";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import IJwt from "../interfaces/IJwt";
import { getApiKey } from "../api";

export const getJwtObject = (jwt: string): IJwt => {
    const jwtParts = jwt.split(".");
    const jwtPayload = jwtParts[1];
    // The line below is needed in order for `atob()` to read the base64 encoded string correctly.
    // @see https://bitbucket.org/dealerinspire/fuel/pull-requests/846
    const jwtPayloadAtobCleaned = jwtPayload.replace("-", "+").replace("_", "/");
    return JSON.parse(atob(jwtPayloadAtobCleaned));
};

const isTokenExpired = (timeLeft: Moment | null): boolean => {
    if (!timeLeft) {
        return true;
    }

    return timeLeft.isBefore(moment());
};

export const tokenExpiresAt = (token?: string) => {
    if (!token) {
        return null;
    }
    const jwtObject = getJwtObject(token);
    return moment(jwtObject.exp * 1000);
};

const revalidateTokenIfNeeded = async (timeLeft: Moment | null) => {
    if (isTokenExpired(timeLeft)) {
        redirect("/login");
        return null;
    }

    return timeLeft;
};

export default (setTimeLeftInRedux: (authenticationTimeLeft: Moment | null) => void) => {
    const currentToken = getApiKey();
    const [loading, setLoading] = useState(true);
    const [timeLeft, setTimeLeft] = useState(tokenExpiresAt(currentToken));

    useEffect(() => {
        if (!currentToken) {
            setLoading(false);
            return;
        }

        revalidateTokenIfNeeded(timeLeft).finally(() => setLoading(false));
    }, [currentToken, timeLeft]);

    useEffect(() => {
        if (!currentToken) {
            return;
        }

        const timeLeftAmount = tokenExpiresAt(currentToken);
        setTimeLeft(timeLeftAmount);
        setTimeLeftInRedux(timeLeftAmount);
    }, [currentToken]);

    return [!isTokenExpired(timeLeft), loading];
};
