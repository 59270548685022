import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import Constants from "../constants/DealerSetupConstants";
import * as Actions from "../actions/DealerSetupActions";
import { dealerSetupData, hasSavedState } from "../reducers/dealerSetup";
import { IFetchAllResponse } from "./specialOfferTemplatesSagas";

export function* fetchDealerSetupData({ payload }: any) {
    const url = `/clients/${payload}/campaign-setup-data`;
    try {
        const response: IFetchAllResponse = yield call(callApi, url, {});
        const data = JSON.parse(response.result.state);
        yield put(Actions.fetchDealerSetupDataSuccess(data));
    } catch (error) {
        yield put(Actions.fetchDealerSetupDataFailure());
    }
}

function* formatDealerSetupData(): any {
    const state = yield select(dealerSetupData);

    return {
        state: JSON.stringify(state)
    };
}

export function* saveForm({ payload }: any) {
    const url = `/clients/${payload}/campaign-setup-data`;
    const data: Generator = yield formatDealerSetupData();
    const update: Generator = yield select(hasSavedState);
    const method = update ? "PATCH" : "POST";

    try {
        yield call(callApi, url, {}, method, data);
        yield put(Actions.saveFormSuccess());
        yield call(processCampaigns, payload);
    } catch (error) {
        yield put(Actions.saveFormFailure());
    }
}

export function* processCampaigns(clientId: number) {
    yield put(Actions.processCampaignsRequest());
    const url = `/clients/${clientId}/campaign-setup-jobs`;
    try {
        yield call(callApi, url, {}, "POST");
        yield put(Actions.processCampaignsSuccess());
    } catch (error) {
        yield put(Actions.processCampaignsFailure());
    }
}

function* dealerSetupSagas() {
    yield takeLatest(Constants.FETCH_DEALER_SETUP_DATA_REQUEST, fetchDealerSetupData);
    yield takeEvery(Constants.SAVE_FORM_REQUEST, saveForm);
}

export default dealerSetupSagas;
