import React, { Fragment } from "react";
import useScrollPosition from "../hooks/useScrollPosition";
import IMessage from "../interfaces/IMessage";

const Message: React.FunctionComponent<IMessage> = ({ message, success, error }) => {
    const defaultStyle = "mb-8 border py-2 px-4 rounded";

    const colorErrorStyle = `border-red-600 bg-red-200 text-red-600`;
    const colorSuccessStyle = `border-green-600 bg-green-200 text-green-800`;
    const colorDefaultStyle = `border-blue-600 bg-blue-200 text-blue-800`;
    const colorStyle = success ? colorSuccessStyle : error ? colorErrorStyle : colorDefaultStyle;
    const fixed = "fixed top-0 mt-5 z-100";

    const scrollPosition = useScrollPosition();

    const position = scrollPosition > 260 ? fixed : "";

    return (
        <Fragment>{message && <div className={`${defaultStyle} ${colorStyle} ${position}`}>{message}</div>}</Fragment>
    );
};

export default Message;
