import React, { useEffect, useState } from "react";
import { Notification } from "../../Shared";
import { useLocation, useNavigate } from "react-router-dom";
import { buildUsersColumns } from "./UsersColumns";
import IUser from "../../../interfaces/IUser";
import IEntity from "../../../interfaces/IEntity";
import SearchInput from "../../Shared/SearchInput";
import UsersActions from "../../../actions/UsersActions";
import { connect } from "react-redux";
import { Table } from "../../Shared/Table";
import SearchIcon from "../../Shared/Icons/SearchIcon";

interface IProps {
    error?: any;
    loadedAt?: Date | null;
    users?: IEntity<IUser>;
    onDeleteUser?: (user: IUser) => void;
    fetchUsers?: (value?: string) => void;
}

const Index = ({ error, loadedAt, users, fetchUsers, onDeleteUser }: IProps) => {
    const navigate = useNavigate();
    const [isRemoving, setIsRemoving] = useState<any>(null);
    const searchQueryParamName = "user_search";
    const params = new URLSearchParams(useLocation().search);
    const searchValue = params.get(searchQueryParamName) ?? "";

    const [search, setSearch] = useState<string>(searchValue);

    useEffect(() => {
        if (!fetchUsers) {
            return;
        }

        fetchUsers(search);
    }, [fetchUsers, search]);

    const handleEdit = (user: IUser) => {
        navigate(`/users/edit/${user.id}`);
    };

    const handleDelete = (user: IUser) => {
        if (!onDeleteUser) {
            return;
        }

        onDeleteUser(user);
        setIsRemoving(user.id);
    };

    const tableColumns = buildUsersColumns(handleEdit, handleDelete);

    return (
        <div className="rounded flex flex-col w-full">
            <div className="flex flex-wrap justify-between p-4 mb-4 bg-white shadow rounded">
                <SearchInput
                    url={window.location.pathname}
                    onChange={(value) => setSearch(value)}
                    useSearchHistory={true}
                    queryParamName={searchQueryParamName}
                    InputComponent={(inputProps: any) => (
                        <div className="relative flex-grow mr-2">
                            <div className="absolute left-0 my-2 mx-3">
                                <SearchIcon className="text-blue-500 w-6 h-6" />
                            </div>
                            <input
                                type="text"
                                value={search}
                                placeholder="Search for a user by email address."
                                autoFocus={true}
                                disabled={!loadedAt}
                                className="rounded border border-gray-300 py-2 pl-10 w-full"
                                {...inputProps}
                            />
                        </div>
                    )}
                />
            </div>

            <div className="bg-white shadow">
                <Table
                    data={Object.values(users || {})}
                    columns={tableColumns}
                    defaultPageSize={10}
                    loadingTable={!loadedAt}
                    loadingMessage={"Loading users..."}
                />
            </div>
            {error && isRemoving && (
                <Notification color={"red"} title="Error" message={error.message} children={null} />
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    loadedAt: state.users.loadedAt,
    users: state.users.entities,
    error: state.users.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUsers: (value?: string) => {
        dispatch(UsersActions.fetchUsers(value));
    },
    onDeleteUser: (user: IUser) => {
        dispatch(UsersActions.requestDeleteUser(user));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
