import { call, put, takeEvery } from "redux-saga/effects";
import { callApi, IResponse } from "../middleware/api";
import AuthenticationConstants from "../constants/AuthenticationConstants";
import { auth0CallbackFailed, auth0CallbackSuccess, IAuthenticationCallback } from "../actions/authenticationActions";
import { schema } from "normalizr";
import { setApiKey } from "../api";
import { fetchCurrentUser } from "../actions/userActions";
import IPagedResponse from "../interfaces/IPagedResponse";

export function* auth0Callback({ payload }: IAuthenticationCallback) {
    try {
        const response: IPagedResponse = yield call(
            callApi,
            `/auth/auth0-callback`,
            new schema.Entity(
                "token",
                {},
                {
                    idAttribute: "tokenType"
                }
            ),
            "POST",
            {
                code: payload.code
            }
        );

        const token = response.entities.token.Bearer.token;

        yield put(auth0CallbackSuccess(token));
        yield call(setApiKey, token);
        yield put(fetchCurrentUser());
    } catch (error) {
        if (error.errors) {
            yield put(auth0CallbackFailed(error.errors));
        } else {
            yield put(auth0CallbackFailed([error.message]));
        }
    }
}

function* authenticationSagas() {
    yield takeEvery(AuthenticationConstants.REQUEST_AUTH_CODE_VERIFICATION, auth0Callback);
}

export default authenticationSagas;
