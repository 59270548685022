import React, { FunctionComponent } from "react";
import AdjustmentsTable from "./Details/AdjustmentsTable";
import SelectField from "../Shared/Form/Blocks/SelectField";
import IEntity from "../../interfaces/IEntity";
import IReactSelectValue from "../../interfaces/IReactSelectValue";
import IAdjustment from "../../interfaces/IAdjustment";
import ISalesMapStatistic from "../../interfaces/ISalesMapStatistic";
import IGoogleCampaign from "../../interfaces/IGoogleCampaign";

interface IDetailContainer {
    campaigns: IEntity<IGoogleCampaign>;
    selectedCampaignId?: string;
    selectedAdjustment: null | number;
    locationStats: IEntity<ISalesMapStatistic>;
    adjustmentsLoading: boolean;
    adjustments: IAdjustment[];
    onCampaignChanged(campaign: IGoogleCampaign): void;
    onAdjustmentSelected(adjustmentId: number): void;
}

const DetailContainer: FunctionComponent<IDetailContainer> = ({
    adjustments,
    adjustmentsLoading,
    campaigns,
    locationStats,
    onAdjustmentSelected,
    onCampaignChanged,
    selectedAdjustment,
    selectedCampaignId
}) => {
    const handleCampaignChange = ({ value }: IReactSelectValue) => {
        onCampaignChanged(campaigns[value]);
    };

    return (
        <>
            <div className="text-2xl">Bid Adjustments</div>
            <div className="flex justify-between">
                <SelectField
                    className="w-1/2"
                    label="Select Campaign"
                    name="campaignSelector"
                    value={selectedCampaignId ? String(selectedCampaignId) : ""}
                    handleChange={handleCampaignChange}
                    options={
                        Object.keys(campaigns).length
                            ? Object.keys(campaigns).map((id) => {
                                  return {
                                      label: campaigns[id].name,
                                      value: id
                                  };
                              })
                            : []
                    }
                />
            </div>
            {selectedCampaignId && (
                <AdjustmentsTable
                    onAdjustmentSelected={onAdjustmentSelected}
                    loading={adjustmentsLoading}
                    adjustments={adjustments}
                    selectedAdjustment={selectedAdjustment}
                    stats={locationStats}
                />
            )}
        </>
    );
};

export default DetailContainer;
