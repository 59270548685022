import { schema } from "normalizr";

const negativeKeywordCollections = new schema.Entity("negativeKeywordCollections");

const negativeKeywords = new schema.Entity(
    "items",
    {},
    {
        processStrategy: (data) => {
            return {
                ...data,
                name: data.displayText ? data.displayText : data.text
            };
        }
    }
);

export default {
    NEGATIVE_KEYWORD_COLLECTIONS: { data: [negativeKeywordCollections] },
    NEGATIVE_KEYWORD_COLLECTION: { data: [negativeKeywords] },
    NEGATIVE_KEYWORD: negativeKeywords
};
