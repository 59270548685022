import React from "react";
import Bugsnag from "@bugsnag/browser";
import Config from "./config";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const configs = Config.getInstance();

Bugsnag.start({
    apiKey: configs.get("BUGSNAG_KEY") || "",
    releaseStage: configs.get("BUGSNAG_STAGE"),
    // @ts-ignore
    notifyReleaseStages: ["production", "staging"],
    plugins: [new BugsnagPluginReact(React)]
});

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary();

export default ErrorBoundary;

export { Bugsnag };
