import React, { useState } from "react";

const PacmanLoader: React.FC<{
    message: string;
}> = ({ message }) => {
    const [toggled, toggle] = useState(false);
    const style = toggled ? { backgroundColor: "pink" } : {};
    return (
        <div className="pacman">
            <h1 className="text-center">{message}</h1>
            <div className="pacman-top" style={style} onClick={() => toggle(!toggled)} />
            <div className="pacman-bottom" style={style} onClick={() => toggle(!toggled)} />
            <div className="feed" />
        </div>
    );
};

export default PacmanLoader;
