import { call } from "redux-saga/effects";
import { callApi } from "../middleware/api";

const FETCH_ALL_SPECIAL_OFFER_TEMPLATES = "FETCH_ALL_SPECIAL_OFFER_TEMPLATES";
const FETCH_ALL_SPECIAL_OFFER_TEMPLATES_SUCCESS = "FETCH_ALL_SPECIAL_OFFER_TEMPLATES_SUCCESS";
const FETCH_ALL_SPECIAL_OFFER_TEMPLATES_FAILED = "FETCH_ALL_SPECIAL_OFFER_TEMPLATES_FAILED";
const FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES = "FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES";
const FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_SUCCESS = "FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_SUCCESS";
const FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_FAILED = "FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_FAILED";

const FETCH_SPECIAL_OFFER_TEMPLATE = "FETCH_SPECIAL_OFFER_TEMPLATE";
const FETCH_SPECIAL_OFFER_TEMPLATE_SUCCESS = "FETCH_SPECIAL_OFFER_TEMPLATE_SUCCESS";
const FETCH_SPECIAL_OFFER_TEMPLATE_FAILED = "FETCH_SPECIAL_OFFER_TEMPLATE_FAILED";

const DELETE_SPECIAL_OFFER_TEMPLATES = "DELETE_SPECIAL_OFFER_TEMPLATES";
const DELETE_SPECIAL_OFFER_TEMPLATES_SUCCESS = "DELETE_SPECIAL_OFFER_TEMPLATES_SUCCESS";
const DELETE_SPECIAL_OFFER_TEMPLATES_FAILED = "DELETE_SPECIAL_OFFER_TEMPLATES_FAILED";

const CREATE_SPECIAL_OFFER_TEMPLATE = "CREATE_SPECIAL_OFFER_TEMPLATE";
const CREATE_SPECIAL_OFFER_TEMPLATE_SUCCESS = "CREATE_SPECIAL_OFFER_TEMPLATE_SUCCESS";
const CREATE_SPECIAL_OFFER_TEMPLATE_FAILED = "CREATE_SPECIAL_OFFER_TEMPLATE_FAILED";

const UPDATE_SPECIAL_OFFER_TEMPLATE = "UPDATE_SPECIAL_OFFER_TEMPLATE";
const UPDATE_SPECIAL_OFFER_TEMPLATE_SUCCESS = "UPDATE_SPECIAL_OFFER_TEMPLATE_SUCCESS";
const UPDATE_SPECIAL_OFFER_TEMPLATE_FAILED = "UPDATE_SPECIAL_OFFER_TEMPLATE_FAILED";

const DETACH_SPECIAL_OFFER_TEMPLATE = "DETACH_SPECIAL_OFFER_TEMPLATE";
const DETACH_SPECIAL_OFFER_TEMPLATE_FAILED = "DETACH_SPECIAL_OFFER_TEMPLATE_FAILED";
const DETACH_SPECIAL_OFFER_TEMPLATE_SUCCESS = "DETACH_SPECIAL_OFFER_TEMPLATE_SUCCESS";

export default {
    FETCH_ALL_SPECIAL_OFFER_TEMPLATES,
    FETCH_ALL_SPECIAL_OFFER_TEMPLATES_SUCCESS,
    FETCH_ALL_SPECIAL_OFFER_TEMPLATES_FAILED,
    FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES,
    FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_SUCCESS,
    FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_FAILED,
    FETCH_SPECIAL_OFFER_TEMPLATE,
    FETCH_SPECIAL_OFFER_TEMPLATE_SUCCESS,
    FETCH_SPECIAL_OFFER_TEMPLATE_FAILED,
    DELETE_SPECIAL_OFFER_TEMPLATES,
    DELETE_SPECIAL_OFFER_TEMPLATES_SUCCESS,
    DELETE_SPECIAL_OFFER_TEMPLATES_FAILED,
    CREATE_SPECIAL_OFFER_TEMPLATE,
    CREATE_SPECIAL_OFFER_TEMPLATE_SUCCESS,
    CREATE_SPECIAL_OFFER_TEMPLATE_FAILED,
    UPDATE_SPECIAL_OFFER_TEMPLATE,
    UPDATE_SPECIAL_OFFER_TEMPLATE_SUCCESS,
    UPDATE_SPECIAL_OFFER_TEMPLATE_FAILED,
    DETACH_SPECIAL_OFFER_TEMPLATE,
    DETACH_SPECIAL_OFFER_TEMPLATE_SUCCESS,
    DETACH_SPECIAL_OFFER_TEMPLATE_FAILED
};
