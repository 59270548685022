import React, { useState, useEffect } from "react";
import { groupBy } from "lodash";
import uuid from "uuid";
import _ from "lodash";
import { IGroupedParts, ITemplatePart, IConditional } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import TemplateParts from "./TemplateParts";
import Label from "../../../Shared/Form/Label";
import HeadlinePart from "./HeadlinePart";
import PathPart from "./PathPart";
import DescriptionPart from "./DescriptionPart";
import TemplateLabel from "./TemplateLabel";
import TextAdPreview from "./TextAdPreview";
import { IExpandedTextAdTemplate } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import {
    mapOldPartFieldToPinnedPartAndPartFields,
    validatePath,
    validateUrl
} from "../../../../utils/DynamicCampaignUtils";
import ActionBar from "./ActionBar";
import { isEmpty } from "lodash";
import { IConditionableParameter } from "../../../../hooks/useConditionals";

interface IExpandedTextAdProps {
    values: IExpandedTextAdTemplate;
    errors: any;
    idx: number;
    onRemove(): void;
    onDuplicate(etaIndex: number): void;
    setFieldValue(fieldName: string, value: any): void;
    setParts(parts: ITemplatePart[]): void;
    setConditionals(conditions: IConditional[]): void;
}

const ExpandedTextAd: React.FC<IExpandedTextAdProps> = ({
    values,
    onRemove,
    onDuplicate,
    setFieldValue,
    setParts,
    setConditionals,
    idx,
    errors
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!isEmpty(errors) && errors.expandedTextAdTemplates && errors.expandedTextAdTemplates.length - 1 === idx) {
            setIsOpen(true);
        }
    }, [errors]);

    // sort into groups by field
    const groupedParts = groupBy(
        values.parts.map((item: ITemplatePart, i: number): ITemplatePart => {
            item.index = i;
            return item;
        }),
        (value) => value.field
    ) as unknown as IGroupedParts;

    const sortAndReturnTheLargestOrderNumber = (field: string) => {
        /**
         * Due to some unknown magic, the order of the fields post-migration are out of the whole stack of
         * parts, so we need to mimic the previous behavior to ensure compatability. Since it's automatically sorted by `order`
         * we just need to grab the largest number in `order` for the set of pinned fields, then add 1 to it
         * to ensure it will go to the bottom of the list.
         */
        const fieldAndPinned = mapOldPartFieldToPinnedPartAndPartFields(field);
        const itemOrders = values.parts
            .filter((part) => fieldAndPinned.pinnedField === part.pinnedField && fieldAndPinned.field === part.field)
            .sort((a, b) => a.order - b.order)
            .map((item) => item.order);
        const largestOrderNumber = (itemOrders.length && Math.max(...itemOrders)) || 0;

        return {
            fieldAndPinned,
            largestOrderNumber
        };
    };

    const buildNewTemplatePart = (field: string, order: number): void => {
        const { fieldAndPinned, largestOrderNumber } = sortAndReturnTheLargestOrderNumber(field);

        const newPart: ITemplatePart = {
            id: uuid.v1(),
            new: true,
            dirty: true,
            field: fieldAndPinned.field,
            pinnedField: null,
            value: "",
            conditionals: [],
            order: largestOrderNumber + 1
        };
        setParts([...values.parts, newPart]);
    };

    const duplicateTemplatePart = (part: ITemplatePart, order: number): void => {
        const newPart: ITemplatePart = {
            id: uuid.v1(),
            new: true,
            dirty: true,
            field: part.field,
            pinnedField: part.pinnedField,
            value: part.value,
            conditionals: part.conditionals,
            order
        };

        newPart.conditionals.forEach((conditional) => {
            conditional.id = _.uniqueId("temp-");
            conditional.new = true;
            conditional.dirty = true;
        });
        setParts([...values.parts, newPart]);
    };

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    // add each group to display
    if (values.deleted) {
        return null;
    }
    const hasConditionals = values.conditionals && values.conditionals.filter((c) => c && !c.deleted).length > 0;

    return (
        <div className="flex flex-col mt-4 bg-white p-4 rounded shadow">
            <ActionBar
                value={values}
                idx={idx}
                label={values.label}
                conditionals={values.conditionals}
                isOpen={isOpen}
                hasConditionals={hasConditionals}
                onRemove={onRemove}
                onDuplicate={onDuplicate}
                onToggleOpen={handleToggleOpen}
                setConditionals={setConditionals}
            />
            <div
                className={`flex flex-col ${!isOpen ? "overflow-hidden" : ""}`}
                style={{ transition: ".2s ease", ...(isOpen ? { maxHeight: "100%" } : { maxHeight: "0vh" }) }}
            >
                <div className="flex flex-row">
                    <div className="w-3/5 ">
                        <Label label="Label" required={false} />
                        <TemplateLabel autoFocus={isOpen} templateIndex={idx} />

                        <Label label="Headline" required={true} />
                        <TemplateParts
                            values={values}
                            setFieldValue={setFieldValue}
                            templateIndex={idx}
                            Component={HeadlinePart}
                            onAdd={(order: number) => {
                                buildNewTemplatePart("h1", order);
                            }}
                            onDuplicate={(part: ITemplatePart, order: number) => {
                                duplicateTemplatePart(part, order);
                            }}
                            parts={[...(groupedParts.headline || [])]}
                            errors={errors}
                            maxLength={150}
                        />
                        <Label label="Description" required={true} />
                        <TemplateParts
                            values={values}
                            setFieldValue={setFieldValue}
                            templateIndex={idx}
                            Component={DescriptionPart}
                            onAdd={(order: number) => {
                                buildNewTemplatePart("d1", order);
                            }}
                            onDuplicate={(part: ITemplatePart, order: number) => {
                                duplicateTemplatePart(part, order);
                            }}
                            parts={[...(groupedParts.description || [])]}
                            errors={errors}
                            maxLength={40}
                        />
                        <Label label="Path" required={true} />
                        <TemplateParts
                            values={values}
                            fieldValidation={validatePath}
                            setFieldValue={setFieldValue}
                            templateIndex={idx}
                            Component={PathPart}
                            onAdd={(order: number) => {
                                buildNewTemplatePart("path", order);
                            }}
                            onDuplicate={(part: ITemplatePart, order: number) => {
                                duplicateTemplatePart(part, order);
                            }}
                            parts={groupedParts.path}
                            errors={errors}
                        />
                        <Label label="Final Url" required={true} />
                        <TemplateParts
                            values={values}
                            fieldValidation={validateUrl}
                            setFieldValue={setFieldValue}
                            Component={HeadlinePart}
                            templateIndex={idx}
                            onAdd={(order: number) => {
                                buildNewTemplatePart("final_url", order);
                            }}
                            onDuplicate={(part: ITemplatePart, order: number) => {
                                duplicateTemplatePart(part, order);
                            }}
                            parts={groupedParts.final_url}
                            errors={errors}
                        />
                    </div>

                    <div className="w-2/5 mt-4">
                        <TextAdPreview {...groupedParts} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpandedTextAd;
