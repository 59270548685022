import React, { SVGProps } from "react";
import Svg from "./Svg";

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M9.007 17.171L3.833 11.99 2 13.738l7.007 6.991L24 5.748 22.167 4z" />
    </Svg>
);

export default CheckIcon;
