import React, { FunctionComponent } from "react";
import PacMan from "../PacmanLoader";
const NotFound: FunctionComponent<{ message?: string; default?: boolean }> = ({
    message = "Sorry we do not recognize the url you are trying to access."
}: {
    message?: string;
}) => {
    return (
        <>
            <div className="mt-24 flex justify-center">
                <div>
                    <div className="text-2xl font-mono text-blue-800">404 Not Found</div>
                    <div className="text-red-500 text-2xl mb-0">{message}</div>
                    <div className=" text-gray-800">
                        <div className="text-lg font-mono">{window.location.href}</div>
                    </div>

                    <PacMan message="" />
                </div>
            </div>
            <div />
        </>
    );
};

export default NotFound;
