import React from "react";
import { Field } from "formik";
import LoaderSpinner from "../../Shared/Loaders/LoaderSpinner";
import { ISalesDataFormInputs } from "../../../interfaces/ISalesDataImport";
import Label from "../../Shared/Form/Label";
import FormErrorBoundary from "../../../utils/FormErrorBoundary";

interface IProps {
    decoding: boolean;
    loading: boolean;
    selectableFields: ISalesDataFormInputs;
    disabled: boolean;
    options: string[];
    errors: any;
}
const fieldStyles = "w-full bg-white p-2 border border-gray-300 rounded inline-block h-12";

const decodeLabel = (field: string) => field.split("|")[0];

const DynamicSelectFields: React.FunctionComponent<IProps> = ({
    decoding,
    loading,
    selectableFields,
    disabled,
    options,
    errors
}) => {
    return (
        <React.Fragment>
            {decoding && <LoaderSpinner message="Decoding the file" />}
            {!decoding && !loading && (
                <React.Fragment>
                    {Object.keys(selectableFields).map((fieldName: string) => (
                        <div className="mb-4" key={fieldName}>
                            <Label label={decodeLabel(selectableFields[fieldName])} />

                            <FormErrorBoundary errors={errors} field={fieldName}>
                                <Field
                                    component="select"
                                    disabled={disabled}
                                    name={fieldName}
                                    className={fieldStyles}
                                    data-testid={fieldName}
                                >
                                    <option value="">Select a {fieldName}</option>
                                    {options.map((header: string, index: number) => (
                                        <option value={index} key={index} data-testid={"sales-option-" + fieldName}>
                                            {header}
                                        </option>
                                    ))}
                                </Field>
                            </FormErrorBoundary>
                        </div>
                    ))}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default DynamicSelectFields;
