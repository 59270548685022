import React, { LegacyRef, RefObject, useEffect, useRef } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { getDcETAParameterFields } from "../../../../utils/InventoryUtils";
import DeleteButton from "../DeleteButton";
import CopyIcon from "../../../Shared/Icons/CopyIcon";
import PinIcon from "../../../Shared/Icons/PinIcon";
import useToggle from "../../../../hooks/useToggle";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface IProps {
    allowDuplicate?: boolean;
    canDelete: boolean;
    size: string;
    fieldName: string;
    onRemove: (fieldName: string) => void;
    onDuplicate: () => void;
    onChange: (event: { target: { value: string } }) => void;
    onPin?: (value: string | null) => void;
    onBlur: () => void;
    className: string;
    value: string;
    errors: any;
    isValid: boolean;
    fieldType?: string;
    pinnedFields?: string[];
    pinnedField?: string | null;
}
const mappedFields = getDcETAParameterFields().map((item) => ({ id: item, display: item }));

const pinConfig: any = {
    headline: { allValues: [1, 2, 3], prefix: "h" },
    description: { allValues: [1, 2], prefix: "d" }
};

const TemplatePartInput: React.FC<IProps> = ({
    onBlur,
    value,
    className,
    onRemove,
    onDuplicate,
    onChange,
    onPin,
    fieldName,
    size,
    canDelete,
    errors,
    isValid,
    fieldType = "",
    pinnedField = null,
    allowDuplicate = true
}) => {
    const [positionSelectorVisible, togglePositionSelector] = useToggle(false);
    const inputRef = useRef() as RefObject<HTMLInputElement>;
    const isPinnable = ["headline", "description"].includes(fieldType);
    const defaultStyle = ({ canDelete }: { canDelete: boolean }) => ({
        control: {
            backgroundColor: "#fff",

            fontSize: 14,
            fontWeight: "normal"
        },

        highlighter: {
            overflow: "hidden"
        },

        input: {
            margin: 0
        },

        "&singleLine": {
            control: {
                display: "inline-block",

                width: "100%"
            },

            highlighter: {
                padding: 1,
                border: "2px inset transparent",
                maxWidth: 100
            },

            input: {
                padding: canDelete ? "13.2px 59px 13.2px 13.2px" : "13.2px 39.6px 13.2px 13.2px",
                borderRadius: "0 .25rem .25rem 0",

                border: isValid ? "1px solid #E2E2E2" : "1px solid #E3342F",
                backgroundColor: isValid ? "#FFF" : "#FEF7F7"
            }
        },

        suggestions: {
            top: "100%",
            backgroundColor: "hsl(0,0%,100%)",
            borderRadius: "4px",
            boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
            marginBottom: "8px",
            marginTop: "8px",
            position: "absolute",
            width: "100%",
            zIndex: "1",
            boxSizing: "border-box",
            maxHeight: "300px",
            list: {
                maxHeight: "300px",
                overflowY: "auto",
                paddingBottom: "4px",
                paddingTop: "4px",
                position: "relative",
                boxSizing: "border-box"
            },
            item: {
                backgroundColor: "transparent",
                color: "inherit",
                cursor: "default",
                display: "block",
                fontSize: "inherit",
                padding: "8px 12px",
                width: "100%",
                userSelect: "none",
                boxSizing: "border-box",
                "&focused": {
                    backgroundColor: "#DEEBFF"
                }
            }
        }
    });

    useEffect(() => {
        if (!value) inputRef?.current?.focus();
    }, []);

    return (
        <div className={`relative ${size} mb-4`}>
            <div className={`${isValid ? "" : "has-error"}`}>
                <MentionsInput
                    inputRef={inputRef}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    singleLine={true}
                    className="m w-full"
                    classNames={{
                        m__input: `${className}`
                    }}
                    data-testid={`${fieldName}-input`}
                    style={defaultStyle({ canDelete })}
                >
                    <Mention
                        markup="{{__id__}}"
                        regex={new RegExp("{{(w| |d|:)}}")}
                        trigger={"{{"}
                        data={mappedFields}
                        displayTransform={(item: any) => `{{${item}}}`}
                    />
                </MentionsInput>
                {errors && errors.length > 0
                    ? errors.map((message: string, index: number) => (
                          <div key={index} className="text-red-600 pb-4">
                              {message}
                          </div>
                      ))
                    : null}
            </div>
            <div className={"flex absolute right-0 top-0 mt-3 mr-2"}>
                {isPinnable && (
                    <button
                        type="button"
                        className={`relative ${
                            !!pinnedField ? "bg-blue-100" : ""
                        } bg-transparent text-blue-200 hover:text-blue-600 p-0 pr-1h-full outline-none flex flex-row rounded`}
                        title="Pin Part"
                        onClick={(e) => {
                            e.preventDefault();
                            togglePositionSelector();
                        }}
                    >
                        <PinIcon
                            className={`w-8 h-6 text-gray-500 ${
                                positionSelectorVisible ? "text-gray-600" : ""
                            } hover:text-gray-600 pt-1`}
                        />
                        <span className={"absolute right-0 top-0 mr-1 mt-1 text-gray-600"}>{`${
                            pinnedField ? pinnedField.split("")[1] : ""
                        }`}</span>
                        <PositionSelector
                            value={pinnedField}
                            visible={positionSelectorVisible}
                            onChange={(value: string | null) => onPin && onPin(value)}
                            fieldType={fieldType}
                            onClickOutside={() => togglePositionSelector(false)}
                        />
                    </button>
                )}
                {canDelete && <DeleteButton onRemove={() => onRemove(fieldName)} />}
                {allowDuplicate && (
                    <button
                        type="button"
                        className="bg-transparent text-blue-200 hover:text-blue-600 p-0 h-full outline-none"
                        title="Duplicate"
                        onClick={(e) => {
                            e.preventDefault();
                            onDuplicate();
                        }}
                    >
                        <CopyIcon className="w-6 h-6 text-gray-500 hover:text-gray-600" />
                    </button>
                )}
            </div>
        </div>
    );
};

interface IPositionSelectorProps {
    visible?: boolean;
    value: string | null;
    onChange: (value: string | null) => void;
    onClickOutside: () => void;
    fieldType: string;
}

const PositionSelector = ({
    visible = false,
    value,
    onChange = () => {},
    onClickOutside = () => {},
    fieldType = "headline"
}: IPositionSelectorProps) => {
    const containerRef = useRef<HTMLDivElement | null>();
    const arrowRef = useRef<HTMLDivElement | null>();
    useOnClickOutside(containerRef, onClickOutside, []);
    const containerSize: any = containerRef?.current?.getBoundingClientRect();
    const optionItemStyles = "text-gray-600 pl-2 hover:bg-blue-100";
    const selectedItem = "bg-blue-100";
    const cssTop = containerSize?.height ? -containerSize?.height / 2.5 : null;

    if (!pinConfig[fieldType]) return <></>;

    const avaliableValues: any = pinConfig[fieldType].allValues;
    return (
        <div
            ref={containerRef as LegacyRef<HTMLDivElement>}
            className={"text-left hover:none bg-white border absolute w-16 flex flex-col p-2 shadow gap-2 rounded"}
            style={{
                visibility: visible ? "visible" : "hidden",
                ...(!!cssTop ? { top: cssTop } : {}),
                transform: "translateX(69%)",
                zIndex: 9999
            }}
        >
            <div
                ref={arrowRef as LegacyRef<HTMLDivElement>}
                className="absolute -top-2 left-1/2 w-5 h-5 bg-white border-l border-b border-gray-400"
                style={{
                    ...(!!cssTop ? { top: Math.abs(cssTop) } : {}),
                    transform: `translateX(-100%) rotate(45deg)`,
                    zIndex: 9999
                }}
            ></div>
            {avaliableValues?.map((val: string, index: number) => {
                const stringValue = `${pinConfig[fieldType].prefix}${val}`;
                return (
                    <span
                        key={index}
                        className={`${optionItemStyles} ${value === stringValue ? selectedItem : ""}`}
                        onClick={() => onChange(stringValue)}
                    >
                        {val}
                    </span>
                );
            })}
            <span
                className={`${optionItemStyles} ${!value ? `${selectedItem} hover:bg-gray-200` : ""}`}
                onClick={() => onChange(null)}
            >
                none
            </span>
        </div>
    );
};

export default TemplatePartInput;
