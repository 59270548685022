import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import Button, { DANGER_BUTTON, NEGATIVE_BUTTON } from "../Button";

interface IConfirmDialog {
    title: string;
    message: ReactElement<string> | string;
    confirmText: string | null;
    onConfirm: () => void;
    onCancel: () => void;
}

class ConfirmDialog extends React.Component<IConfirmDialog> {
    el = this.createContainer();
    dialogRoot = document.getElementById("root");
    state: {
        node: HTMLElement | null;
    } = {
        node: null
    };

    createContainer() {
        const el = document.createElement("div");
        el.setAttribute("id", "dialog-container");
        return el;
    }

    handleClickOutside = (e: { target: any }) => {
        if (this.state.node && !this.state.node.contains(e.target)) {
            return this.props.onCancel();
        }
    };

    componentDidMount() {
        this.el.addEventListener("mousedown", this.handleClickOutside);
        this.dialogRoot?.appendChild(this.el);
        this.setState({ node: ReactDOM.findDOMNode(this) });
    }

    componentWillUnmount() {
        this.el.removeEventListener("mousedown", this.handleClickOutside);
        this.dialogRoot?.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            <div className="fixed inset-0 z-50 overflow-auto flex">
                <div
                    className={`fixed shadow-inner md:relative bottom-0 inset-x-0 align-top m-auto justify-end md:justify-center p-4 bg-white md:rounded w-1/3 lg:w-1/4 md:h-auto md:shadow flex flex-col`}
                >
                    <div>
                        <div className="text-2xl font-semibold text-center">{this.props.title || "Confirm Action"}</div>
                        <div className="pt-4 ">{this.props.message || "Are you sure?"}</div>
                    </div>
                    <div className="mt-4 w-full flex justify-between items-center">
                        <Button onClick={this.props.onCancel} styleType={NEGATIVE_BUTTON}>
                            Cancel
                        </Button>
                        <Button onClick={this.props.onConfirm} styleType={DANGER_BUTTON}>
                            {this.props.confirmText || "Delete"}
                        </Button>
                    </div>
                </div>
            </div>,
            this.el
        );
    }
}

export default ConfirmDialog;
