import { schema } from "normalizr";

const placementExclusionCollections = new schema.Entity("placementExclusionCollections");

const placementExclusions = new schema.Entity(
    "items",
    {},
    {
        processStrategy: (data) => {
            return {
                ...data,
                name: data.displayExclusion ? data.displayExclusion : data.exclusion
            };
        }
    }
);

export default {
    PLACEMENT_EXCLUSION_COLLECTIONS: { data: [placementExclusionCollections] },
    PLACEMENT_EXCLUSION_COLLECTION: { data: [placementExclusions] },
    PLACEMENT_EXCLUSION: placementExclusions
};
