import React from "react";
import logo from "../assets/fuel-logo.svg";

const Error = () => {
    return (
        <div className="mt-32 flex justify-center">
            <div>
                <a href="/">
                    <img alt="logo" src={logo} className="mb-2" width="300px" />
                </a>
                <h2 className="text-red-500 text-2xl mb-0">Sorry you have encountered an error.</h2>
                <div className=" text-gray-800">
                    <p className="text-lg">
                        We have notified support with information about this error. Please try again.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Error;
