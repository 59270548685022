import * as actions from "../actions/userActions";
import { DI_AGENCY_ID } from "../constants/AgencyConstants";
import { AnyAction } from "redux";
import IUser from "../interfaces/IUser";
import IUserRole from "../interfaces/IUserRole";

const reducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.LOGOUT_CURRENT_USER:
            return false;
        case actions.LOAD_CURRENT_USER:
            return { ...state, ...action.user };
        default:
            return state;
    }
};

const isUserReadOnly = (user: { roles: any[] }) => {
    if (user && user.roles) {
        return user.roles.findIndex((role) => role.name === "readonly_budgets") !== -1;
    }

    return false;
};

const isSupervisor = (user: IUser | undefined) => {
    if (!user) {
        return false;
    }

    return user.roles.some((role: IUserRole) => {
        return role.name === "supervisor";
    });
};

const isAdmin = (user: IUser | undefined) => {
    if (!user) {
        return false;
    }

    return user.isAdmin;
};

const canSetupGmConversions = (user: IUser) => {
    return isSupervisor(user) || isAdmin(user);
};

const canSetupOemConversions = (user: IUser) => {
    return isSupervisor(user) || isAdmin(user);
};

const canScheduleCancellation = (user: IUser) => {
    return isSupervisor(user);
};

const isUserInternal = (user: IUser | undefined) => {
    if (user && user.agencyId) {
        return user.agencyId === DI_AGENCY_ID;
    }

    return false;
};

const isUserDeveloper = (user?: IUser) => {
    if (!user) {
        return false;
    }

    if (
        user.roles.some((role: IUserRole) => {
            return role.name === "developer";
        })
    ) {
        return true;
    }

    return false;
};

export default reducer;

export {
    isUserReadOnly,
    isUserInternal,
    canScheduleCancellation,
    isUserDeveloper,
    isSupervisor,
    isAdmin,
    canSetupGmConversions,
    canSetupOemConversions
};
