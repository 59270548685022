import React, { FunctionComponent, useEffect } from "react";
import { Routes, useNavigate, Route } from "react-router-dom";
import NotFound404 from "../components/Shared/NotFound404";

interface IRedirectProps {
    to: string;
}
const Redirect: FunctionComponent<IRedirectProps> = ({ to }: IRedirectProps) => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    }, []);
    return null;
};

const UnAuthenticatedRouter: FunctionComponent = () => {
    return (
        <>
            <div className="bg-white" />
            <div className="main-content-area w-full h-screen bg-gray-200">
                <Routes>
                    <Route element={<Redirect to="/" />} path="/login" />
                    <Route element={<Redirect to="/" />} path="/reset" />
                    <Route element={<Redirect to="/" />} path="/request-reset" />
                    <Route element={<Redirect to="/" />} path="/activate" />
                    <Route element={<NotFound404 />} path="*" />
                </Routes>
            </div>
        </>
    );
};

export default UnAuthenticatedRouter;
