import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import UserForm from "./UserForm";
import FullPageLoader from "../Shared/Loaders/FullPageLoader";
import UsersActions from "../../actions/UsersActions";
import UsersUtils from "../../utils/UsersUtils";
import { TableLoader } from "../Shared/Table";
import useLoading from "../../hooks/useLoading";

import IEntity from "../../interfaces/IEntity";
import IUserRole from "../../interfaces/IUserRole";
import IUserForm from "../../interfaces/IUserForm";
import IUsersAppState from "../../interfaces/IUserAppState";
import { useNavigate } from "react-router";

export const fieldGroupStyles = "flex flex-wrap mb-4 w-full";
export const labelStyles = "block uppercase tracking-wider text-gray-800 font-bold mb-2";
const formContainerStyles =
    "m-auto relative bg-white flex flex-col w-full sm:w-4/5 md:w-full lg:w-2/3 xl:w-1/2 shadow-md rounded p-4 mb-4";

interface IAppState<T> {
    [key: string]: T;
}

interface IProps {
    roles: IEntity<IUserRole>;
    isSubmitting: boolean;
    error: any;
    fetchUserRoles: () => void;
}

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: "false",
    readonly_budgets: false,
    all: false,
    budget_manager: false,
    sendActivationEmail: true
};

const NewUser: React.FunctionComponent<IProps> = ({ fetchUserRoles, roles, isSubmitting, error }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useLoading(Object.keys(roles).length);

    useEffect(() => {
        if (Object.keys(roles).length === 0) {
            fetchUserRoles();
        }
    }, [Object.keys(roles).length]);

    if (isLoading) {
        return <FullPageLoader message="Loading" />;
    }

    const createUser = (values: IUserForm, roles: IEntity<IUserRole>) => {
        const mappedValuesToUser = UsersUtils.mapFormToUser(values, roles);
        dispatch({ ...UsersActions.requestCreateUser(mappedValuesToUser, values.sendActivationEmail), navigate });
    };
    return (
        <div className={formContainerStyles}>
            <h2 className="font-bold self-center text-gray-800 uppercase">Create New User</h2>
            <UserForm {...{ initialValues, error, roles, isSubmitting }} saveUser={createUser} />
            {isSubmitting && (
                <TableLoader message="Createing User" className="absolute self-center h-full top-0 left-0" />
            )}
        </div>
    );
};

const mapStateToProps = (state: IAppState<IUsersAppState>) => ({
    roles: state.users.roles,
    loading: state.users.loading,
    isSubmitting: state.users.isSubmitting,
    error: state.users.error
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUserRoles: () => {
        dispatch(UsersActions.requestUserRoles());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
