import React, { SVGProps } from "react";
import Svg from "./Svg";

const RefreshIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M11.991 3.57143V5.95238C10.8122 5.95027 9.65933 6.29823 8.6785 6.95214C7.69768 7.60605 6.93311 8.53647 6.48171 9.62544C6.03031 10.7144 5.91241 11.9129 6.14297 13.0689C6.37353 14.225 6.94215 15.2866 7.77676 16.119L6.09819 17.7976C4.93263 16.6322 4.13886 15.1473 3.81726 13.5307C3.49567 11.9141 3.6607 10.2384 4.29148 8.71565C4.92226 7.19286 5.99046 5.89133 7.36098 4.97566C8.7315 4.05999 10.3428 3.57131 11.991 3.57143ZM19.0744 7.20238C20.2399 8.36782 21.0337 9.85273 21.3553 11.4693C21.6769 13.0859 21.5119 14.7616 20.8811 16.2843C20.2503 17.8071 19.1821 19.1087 17.8116 20.0243C16.4411 20.94 14.8298 21.4287 13.1815 21.4286V19.0476C14.3603 19.0497 15.5132 18.7018 16.4941 18.0479C17.4749 17.3939 18.2395 16.4635 18.6909 15.3746C19.1423 14.2856 19.2602 13.0871 19.0296 11.9311C18.799 10.775 18.2304 9.71345 17.3958 8.88095L19.0744 7.20238ZM13.1815 25L8.41962 20.2381L13.1815 15.4762V25ZM11.991 9.52381V0L16.753 4.7619L11.991 9.52381Z" />
    </Svg>
);

export default RefreshIcon;
