import React from "react";
import LoaderSpinner from "./LoaderSpinner";

interface IProps {
    message: string;
}

const PageLoading = ({ message }: IProps) => {
    return (
        <div className="text-primary text-strong h-screen w-full visible flex items-center">
            <LoaderSpinner message={message} className="text-left mx-auto w-full justify-center" />
        </div>
    );
};

PageLoading.defaultProps = {
    message: "Loading"
};
export default PageLoading;
