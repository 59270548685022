import React from "react";
import { FormikErrors } from "formik";

interface IProps {
    errors: Array<string | FormikErrors<any>>;
    className?: string;
}

const Errors: React.FC<IProps> = ({ errors, className }) => {
    if (errors === null || errors.length === 0) {
        return <React.Fragment />;
    }

    return (
        <div className={`w-full ${className}`}>
            {Array.isArray(errors) &&
                errors.map((error, key) => (
                    <span key={key} className={`w-full block pt-3 text-red-500 text-base italic`}>
                        {error}
                    </span>
                ))}
        </div>
    );
};

export default Errors;
