import React, { SVGProps } from "react";
import Svg from "./Svg";

const InformationIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M12.5 3C7.257 3 3 7.257 3 12.5S7.257 22 12.5 22s9.5-4.257 9.5-9.5S17.743 3 12.5 3zm.76 14.25h-1.52a.2.2 0 0 1-.2-.2v-5.31c0-.11.09-.2.2-.2h1.52c.11 0 .2.09.2.2v5.31a.2.2 0 0 1-.2.2zm0-7.582h-1.52a.2.2 0 0 1-.2-.2V7.95c0-.11.09-.2.2-.2h1.52c.11 0 .2.09.2.2v1.518a.2.2 0 0 1-.2.2z" />
    </Svg>
);

export default InformationIcon;
