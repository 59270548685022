import React, { useState, useEffect } from "react";
import IInventoryModifier from "../../../interfaces/IInventoryModifier";
import { getInventoryFieldsDI } from "../../../utils/InventoryUtils";
import TrashIcon from "../../Shared/Icons/TrashIcon";
import TextField from "../../Shared/Form/Blocks/TextField";
import SelectField from "../../Shared/Form/Blocks/SelectField";

interface IProps {
    idx: number;
    modifier: IInventoryModifier;
    onUpdateModifier: (idx: number, modifier: IInventoryModifier) => void;
    onRemoveModifier: (idx: number, modifier: IInventoryModifier) => void;
}

const InventoryModifier: React.FunctionComponent<IProps> = ({ idx, modifier, onUpdateModifier, onRemoveModifier }) => {
    const [fields, setFields] = useState<string[]>([]);

    const { errors = [] } = modifier;

    useEffect(() => {
        setFields(getInventoryFieldsDI());
    }, []);

    const handleSelectFieldChange = ({ value }: { value: string; label: string }) => {
        onUpdateModifier(idx, { ...modifier, field: value, errors: [] });
    };

    const handleModifierValueChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { value, name } = event.currentTarget;
        onUpdateModifier(idx, { ...modifier, [name]: value, errors: [] });
    };

    const displayErrors = (field: string) => {
        const fieldErrors = errors.filter((error) => error.path === field);
        return fieldErrors.length > 0
            ? fieldErrors[0].errors.map((message: string, index: number) => (
                  <div key={index} className="text-red-600 absolute">
                      {message}
                  </div>
              ))
            : null;
    };

    return !!modifier.deleted ? null : (
        <div className="flex items-start -mt-4">
            <div className="w-full md:flex-1">
                <TextField
                    label="Replace"
                    className="mr-4"
                    handleChange={handleModifierValueChange}
                    value={modifier.search}
                    name="search"
                    placeholder="Needle"
                    required={true}
                    errors={displayErrors("search") || []}
                />
            </div>
            <div className="w-full md:flex-1">
                <TextField
                    label="With"
                    className="mr-4"
                    handleChange={handleModifierValueChange}
                    value={modifier.replace}
                    name="replace"
                    placeholder="Replacement"
                    required={true}
                    errors={displayErrors("replace") || []}
                />
            </div>
            <div className="w-full md:flex-1">
                <SelectField
                    label="For"
                    handleChange={handleSelectFieldChange}
                    value={modifier.field}
                    options={fields.map((value) => ({
                        value,
                        label: value
                    }))}
                    name="field"
                    required={true}
                    errors={displayErrors("field") || []}
                />
            </div>

            <button
                onClick={() => onRemoveModifier(idx, modifier)}
                className="bg-transparent text-gray-500 hover:text-red-500 p-0 ml-4 mr-2 mt-12 pt-2"
            >
                <TrashIcon className="w-6 h-6" />
            </button>
        </div>
    );
};

export default InventoryModifier;
