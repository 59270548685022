import { Reducer } from "redux";
import IBudgetLabel from "../interfaces/Budgets/IBudgetLabel";
import IEntity from "../interfaces/IEntity";
import LabelConstants from "../constants/LabelConstants";

interface ILabelsState {
    entities: IEntity<IBudgetLabel>;
    loading: boolean;
}

const initialState = {
    entities: {},
    loading: false
};

const labelsReducer: Reducer<ILabelsState> = (state = initialState, action) => {
    switch (action.type) {
        case LabelConstants.REQUEST_LABELS:
        case LabelConstants.REQUEST_REFRESH_LABELS:
        case LabelConstants.REQUEST_MICROSOFT_LABELS:
        case LabelConstants.REQUEST_REFRESH_MICROSOFT_LABELS:
            return { ...state, loading: action.payload.loading };
        case LabelConstants.REQUEST_LABELS_SUCCESS:
        case LabelConstants.REQUEST_LABELS_FAIL:
        case LabelConstants.REQUEST_MICROSOFT_LABELS_SUCCESS:
        case LabelConstants.REQUEST_MICROSOFT_LABELS_FAIL:
            return {
                ...state,
                entities: action.payload.labels,
                loading: action.payload.loading
            };
        default:
            return state;
    }
};

export default labelsReducer;
