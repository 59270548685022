import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import * as Yup from "yup";
import IInventoryModifier from "../../../interfaces/IInventoryModifier";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import InventoryModifier from "./InventoryModifier";
import { getInventoryFieldsDI } from "../../../utils/InventoryUtils";
import AddIcon from "../../Shared/Icons/AddIcon";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import Button, { PRIMARY_BUTTON } from "../../Shared/Button";

interface IProps {
    clientId: number;
    inventoryModifiers: IInventoryModifier[];
    handleSave: (clientId: number, inventoryModifiers: IInventoryModifier[]) => void;
    fetchModifiers: (clientId: number) => void;
    saving: boolean;
    loading: boolean;
    loadedAt: string;
}

const InventoryModifierSchema = Yup.object().shape({
    search: Yup.string().max(256, "Too Long!").required("Required"),
    replace: Yup.string().max(256, "Too Long!").required("Required"),
    field: Yup.string().oneOf(getInventoryFieldsDI(), "Invalid Field!").required("Required")
});

const InventoryModifierList: React.FunctionComponent<IProps> = ({
    clientId,
    inventoryModifiers,
    handleSave,
    fetchModifiers,
    saving,
    loading,
    loadedAt
}) => {
    const [modifiers, setModifiers] = useState<IInventoryModifier[]>([]);
    const [hasChanges, setHasChanges] = useState(true);

    useEffect(() => {
        setModifiers(inventoryModifiers);
    }, [inventoryModifiers]);

    useEffect(() => {
        const dirtyModifiers = modifiers.filter((modifier: IInventoryModifier) => !!modifier.dirty || !!modifier.new);
        setHasChanges(dirtyModifiers.length > 0);
    }, [modifiers]);

    useEffect(() => {
        if (!loadedAt) {
            fetchModifiers(clientId);
        }
    }, [clientId, loadedAt]);

    const onSave = () => {
        let allPass = true;

        modifiers.forEach((modifier: any, index: number) => {
            if (modifier.deleted) {
                return;
            }

            try {
                InventoryModifierSchema.validateSync(modifier, { abortEarly: false });
                handleUpdateBid(index, { ...modifier, errors: [] });
            } catch (error) {
                handleUpdateBid(index, { ...modifier, errors: [...error.inner] });
                allPass = false;
            }
        });

        if (allPass) {
            handleSave(clientId, modifiers);
        }
    };

    const handleAddBid = () => {
        const newModifier = {
            id: _.uniqueId("temp-"),
            search: "",
            replace: "",
            field: "",
            deleted: false,
            new: true,
            valid: false
        };

        setModifiers((currentModifiers: any) => {
            return [...currentModifiers, newModifier];
        });
    };

    const handleUpdateBid = (
        idx: number,
        { search, replace, field, deleted = false, errors = [] }: IInventoryModifier
    ) => {
        const valid = search.length > 0 && replace.length > 0 && field.length > 0;
        const updated = {
            ...modifiers[idx],
            search,
            replace,
            field,
            deleted,
            errors,
            valid,
            dirty: true
        };
        setModifiers((currentModifiers) => {
            return [...currentModifiers.slice(0, idx), updated, ...currentModifiers.slice(idx + 1)];
        });
    };

    const handleRemoveBid = (idx: number, modifier: IInventoryModifier) => {
        const updated = {
            ...modifier,
            deleted: true
        };
        handleUpdateBid(idx, updated);
    };

    const list = (
        <Fragment>
            {modifiers.map((modifier, idx) => {
                return (
                    <InventoryModifier
                        key={modifier.id}
                        onUpdateModifier={handleUpdateBid}
                        onRemoveModifier={handleRemoveBid}
                        {...{ idx, modifier }}
                    />
                );
            })}

            <div className="flex items-center mt-4">
                <Button styleType={PRIMARY_BUTTON} onClick={onSave} disabled={!hasChanges}>
                    {saving ? (
                        <RefreshIcon className="w-6 h-6 rotate-fast" />
                    ) : (
                        <span className="text-base">Save Changes</span>
                    )}
                </Button>

                {hasChanges ? <span className="ml-6 text-base">You have unsaved changes!</span> : null}
            </div>
        </Fragment>
    );

    if (loading) {
        return <FullPageLoader message="Loading Inventory Modifiers..." />;
    }

    return (
        <div className="mx-4">
            <PageTitle title="Inventory Modifiers">
                <Button styleType={PRIMARY_BUTTON} onClick={handleAddBid} styles="flex items-center">
                    <AddIcon className="w-6 h-6" />
                    <span className="ml-2">New Modifier</span>
                </Button>
            </PageTitle>

            <div className="shadow bg-white rounded p-4">
                {modifiers.length > 0 && list}
                {modifiers.length === 0 && (
                    <div className="text-gray-700 italic">No inventory modifiers for this client</div>
                )}
            </div>
        </div>
    );
};

export default InventoryModifierList;
