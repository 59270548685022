import React, { SVGProps } from "react";
import Svg from "./Svg";

const ReportsIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M12.832 23c-5.514 0-10-4.486-10-10s4.486-10 10-10c5.515 0 10 4.486 10 10s-4.485 10-10 10zm0-18.75c-4.825 0-8.75 3.925-8.75 8.75s3.926 8.75 8.75 8.75c4.825 0 8.75-3.925 8.75-8.75s-3.925-8.75-8.75-8.75z" />
        <path d="M14.84 17.754c-.53 0-1.002-.334-1.18-.832l-2.742-7.774-1.88 4.71a.623.623 0 0 1-.58.392h-2.5a.625.625 0 0 1 0-1.25h2.076l1.727-4.316a1.246 1.246 0 0 1 1.63-.69c.327.133.584.4.704.733l2.744 7.777 1.823-3.19a.628.628 0 0 1 .542-.314h2.5a.625.625 0 0 1 0 1.25h-2.137l-1.643 2.874a1.253 1.253 0 0 1-1.084.63z" />
    </Svg>
);

export default ReportsIcon;
