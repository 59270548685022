import React, { SVGProps } from "react";
import Svg from "./Svg";

const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M5 4.823v15.479a1 1 0 0 0 1.538.843l12.14-7.74a1 1 0 0 0 0-1.686L6.537 3.98A1 1 0 0 0 5 4.823z" />
    </Svg>
);

export default PlayIcon;
