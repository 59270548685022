import Schemas from "../middleware/schemas";
import { CALL_API, doDeleteRequest, callApi } from "../middleware/api";
import _ from "lodash";
import { createTemplatePart as makeTemplatePart } from "../reducers/dynamicAds/textTemplateParts";
import { saveTemplatePartConditional } from "./dynamicAds/textTemplatePartConditionalActions";
import { getPathFromDisplayUrl } from "../utils";
import DynamicCampaignConstants from "../constants/DynamicCampaignConstants";
import { IDynamicCampaign } from "../interfaces/DynamicCampaigns/IDynamicCampaign";

const REQUEST_DYNAMIC_CAMPAIGN = "REQUEST_DYNAMIC_CAMPAIGN";
const LOAD_DYNAMIC_CAMPAIGN = "LOAD_DYNAMIC_CAMPAIGN";
const REQUEST_DYNAMIC_CAMPAIGNS = "LEGACY_REQUEST_DYNAMIC_CAMPAIGNS";
const LOAD_DYNAMIC_CAMPAIGNS = "LEGACY_LOAD_DYNAMIC_CAMPAIGNS";
const REQUEST_DYNAMIC_CAMPAIGN_FAILURE = "LEGACY_REQUEST_DYNAMIC_CAMPAIGN_FAILURE";
const LOAD_DYNAMIC_CAMPAIGN_TEMPLATES = "LOAD_DYNAMIC_CAMPAIGN_TEMPLATES";
const REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES = "REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES";
const REQUEST_CREATE_DYNAMIC_CAMPAIGN_TEMPLATE = "REQUEST_CREATE_DYNAMIC_CAMPAIGN_TEMPLATE";
const ADD_DYNAMIC_CAMPAIGN_TEMPLATE = "ADD_DYNAMIC_CAMPAIGN_TEMPLATE";
const CREATE_DYNAMIC_CAMPAIGN_TEMPLATE = "CREATE_DYNAMIC_CAMPAIGN_TEMPLATE";
const REMOVE_DYNAMIC_CAMPAIGN_TEMPLATE = "REMOVE_DYNAMIC_CAMPAIGN_TEMPLATE";
const REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES_FAILURE = "REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES_FAILURE";
const UPDATE_DYNAMIC_CAMPAIGN_TEMPLATE = "UPDATE_DYNAMIC_CAMPAIGN_TEMPLATE";
const REQUEST_SAVE_DYNAMIC_CAMPAIGN_TEMPLATE = "REQUEST_SAVE_DYNAMIC_CAMPAIGN_TEMPLATE";
const SAVE_DYNAMIC_CAMPAIGN_TEMPLATE = "SAVE_DYNAMIC_CAMPAIGN_TEMPLATE";
const START_BATCH_SAVE = "START_BATCH_SAVE";
const END_BATCH_SAVE = "END_BATCH_SAVE";
const REQUEST_TEMPLATE_CONDITIONAL = "REQUEST_TEMPLATE_CONDITIONAL";
const CREATE_TEMPLATE_CONDITIONAL = "CREATE_TEMPLATE_CONDITIONAL";
const UPDATE_TEMPLATE_CONDITIONAL = "UPDATE_TEMPLATE_CONDITIONAL";
const REMOVE_TEMPLATE_CONDITIONAL = "REMOVE_TEMPLATE_CONDITIONAL";
const ADD_TEXT_TEMPLATE_PART = "ADD_TEXT_TEMPLATE_PART";
const SAVE_TEXT_TEMPLATE_PART = "SAVE_TEXT_TEMPLATE_PART";
const CREATE_TEXT_TEMPLATE_PART = "CREATE_TEXT_TEMPLATE_PART";
const REMOVE_TEXT_TEMPLATE_PART = "REMOVE_TEXT_TEMPLATE_PART";
const UPDATE_ORDER_TEXT_TEMPLATE_PART = "UPDATE_ORDER_TEXT_TEMPLATE_PART";

const LOAD_TEXT_TEMPLATE_PARTS = "LOAD_TEXT_TEMPLATE_PARTS";
const REQUEST_TEXT_TEMPLATE_PARTS = "REQUEST_TEXT_TEMPLATE_PARTS";

const LOAD_CAMPAIGN_PARAMETERS = "LOAD_CAMPAIGN_PARAMETERS";
const REQUEST_CAMPAIGN_PARAMETERS = "REQUEST_CAMPAIGN_PARAMETERS";
const REMOVE_DYNAMIC_CAMPAIGN = "LEGACY_REMOVE_DYNAMIC_CAMPAIGN";

const REQUEST_DELETE_DYNAMIC_CAMPAIGN = "LEGACY_REQUEST_DELETE_DYNAMIC_CAMPAIGN";

interface ITemplate {
    label: string;
    id: number;
    individualizedParameters: any;
    urlEncoding: string;
}

interface IConditional {
    id: number;
    feedTemplateId: number;
    conditional: string;
    field: string;
    value: string;
}

const deleteDynamicCampaign = (clientId: number, campaignId: string) => ({
    clientId,
    campaignId,
    type: REQUEST_DELETE_DYNAMIC_CAMPAIGN
});

const removeDynamicCampaign = (campaignId: string) => ({
    campaignId,
    type: REMOVE_DYNAMIC_CAMPAIGN
});

const fetchCampaignParameters = (clientId: number, campaignId: string) => ({
    clientId,
    campaignId,
    type: REQUEST_CAMPAIGN_PARAMETERS
});

const fetchCampaigns = (clientId: number) => ({
    [CALL_API]: {
        types: [REQUEST_DYNAMIC_CAMPAIGN, LOAD_DYNAMIC_CAMPAIGNS, REQUEST_DYNAMIC_CAMPAIGN_FAILURE],
        endpoint: `/clients/${clientId}/deprecated-dynamic-campaigns`,
        schema: Schemas.DYNAMIC_TEXT_CAMPAIGN_ARRAY,
        method: "GET"
    }
});
const fetchCampaign = (clientId: number, id: number) => ({
    [CALL_API]: {
        types: [REQUEST_DYNAMIC_CAMPAIGN, LOAD_DYNAMIC_CAMPAIGN, REQUEST_DYNAMIC_CAMPAIGN_FAILURE],
        endpoint: `/clients/${clientId}/deprecated-dynamic-campaigns/${id}`,
        schema: Schemas.DYNAMIC_TEXT_CAMPAIGN,
        method: "GET"
    }
});

const fetchTemplatesForCampaign = (clientId: number, id: number) => ({
    [CALL_API]: {
        types: [
            REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES,
            LOAD_DYNAMIC_CAMPAIGN_TEMPLATES,
            REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES_FAILURE
        ],
        endpoint: `/clients/${clientId}/dynamic-campaign-ad-templates?expand[dynamicTextAdTemplateParts.dynamicTextAdTemplatePartConditionals]=*&expand[dynamicTextAdTemplateParts]=*&expand[dynamicCampaignAdTemplateConditionals]=*&filter[feed_id]=${id}`,
        schema: Schemas.DYNAMIC_CAMPAIGN_TEMPLATE_ARRAY,
        method: "GET"
    }
});

const saveTextAdTemplate = (clientId: number, template: ITemplate) => {
    const { label, id, individualizedParameters, urlEncoding } = { ...template };
    return {
        [CALL_API]: {
            types: [
                REQUEST_SAVE_DYNAMIC_CAMPAIGN_TEMPLATE,
                SAVE_DYNAMIC_CAMPAIGN_TEMPLATE,
                REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES_FAILURE
            ],
            endpoint: `/clients/${clientId}/dynamic-campaign-ad-templates/${id}`,
            schema: Schemas.DYNAMIC_CAMPAIGN_TEMPLATE,
            method: "PUT",
            data: {
                label,
                individualized_parameters: individualizedParameters,
                url_encoding: urlEncoding
            }
        }
    };
};

const createTextAdTemplate = (clientId: number, campaignId: string) => ({
    [CALL_API]: {
        types: [
            REQUEST_CREATE_DYNAMIC_CAMPAIGN_TEMPLATE,
            CREATE_DYNAMIC_CAMPAIGN_TEMPLATE,
            REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES_FAILURE
        ],
        endpoint: `/clients/${clientId}/dynamic-campaign-ad-templates`,
        schema: Schemas.DYNAMIC_CAMPAIGN_TEMPLATE,
        method: "POST",
        data: {
            feed_id: campaignId,
            ad_type: "eta",
            order: 0
        }
    }
});
const startSaving = () => {
    return { type: START_BATCH_SAVE };
};
const doneSaving = () => {
    return { type: END_BATCH_SAVE };
};
const updateTemplate = (template: ITemplate) => ({
    type: UPDATE_DYNAMIC_CAMPAIGN_TEMPLATE,
    template
});
const removeTextAdTemplate = (clientId: number, idx: number, id: number, dispatch: any) => {
    doDeleteRequest(`/clients/${clientId}/dynamic-campaign-ad-templates/${id}`, (resp: any) => {
        dispatch({ type: REMOVE_DYNAMIC_CAMPAIGN_TEMPLATE, id, idx });
    });
};

const removeTemplateConditional = (clientId: number, idx: number, conditional: IConditional, dispatch: any) => {
    return doDeleteRequest(`/clients/${clientId}/dc-ad-template-conditionals/${conditional.id}`, (resp: any) => {
        dispatch({
            type: REMOVE_TEMPLATE_CONDITIONAL,
            id: conditional.id,
            idx,
            templateId: conditional.feedTemplateId
        });
    });
};

const createTemplateConditional = (clientId: number, conditional: IConditional) => ({
    [CALL_API]: {
        types: [REQUEST_TEMPLATE_CONDITIONAL, CREATE_TEMPLATE_CONDITIONAL, REQUEST_DYNAMIC_CAMPAIGN_FAILURE],
        endpoint: `/clients/${clientId}/dc-ad-template-conditionals`,
        schema: Schemas.DTC_TEMPLATE_CONDITIONAL,
        tempId: conditional.id,
        method: "POST",
        data: {
            feed_template_id: conditional.feedTemplateId,
            conditional: conditional.conditional,
            field: conditional.field,
            value: conditional.value
        }
    }
});

const updateTemplateConditional = (clientId: number, conditional: IConditional) => ({
    [CALL_API]: {
        types: [REQUEST_TEMPLATE_CONDITIONAL, UPDATE_TEMPLATE_CONDITIONAL, REQUEST_DYNAMIC_CAMPAIGN_FAILURE],
        endpoint: `/clients/${clientId}/dc-ad-template-conditionals/${conditional.id}`,
        schema: Schemas.DTC_TEMPLATE_CONDITIONAL,
        method: "PUT",
        data: {
            conditional: conditional.conditional,
            field: conditional.field,
            value: conditional.value
        }
    }
});

const addTemplatePart = (templatePart: any) => {
    return {
        type: ADD_TEXT_TEMPLATE_PART,
        response: templatePart
    };
};

const saveTemplatePart = (templatePart: any) => {
    return {
        type: SAVE_TEXT_TEMPLATE_PART,
        response: {
            result: templatePart.id,
            entities: {
                dynamicTextAdTemplateParts: { [templatePart.id]: templatePart }
            }
        }
    };
};
const updateTemplatePart = (clientId: number, templatePart: any) => ({
    [CALL_API]: {
        types: [REQUEST_TEXT_TEMPLATE_PARTS, SAVE_TEXT_TEMPLATE_PART, REQUEST_DYNAMIC_CAMPAIGN_FAILURE],
        endpoint: `/clients/${clientId}/dta-template-parts/${templatePart.id}`,
        schema: Schemas.DYNAMIC_TEXT_AD_TEMPLATE_PART,
        method: "PUT",
        data: {
            feed_template_id: templatePart.feedTemplateId,
            order: templatePart.order,
            field: templatePart.field === "finalUrl" ? "final_url" : templatePart.field,
            value: templatePart.value
        }
    }
});
const updateTemplatePartOrder = (part: any, order: any, template: any) => ({
    type: UPDATE_ORDER_TEXT_TEMPLATE_PART,
    part,
    order,
    template
});
const createTemplatePart = (clientId: number, templatePart: any) => ({
    [CALL_API]: {
        types: [REQUEST_TEXT_TEMPLATE_PARTS, SAVE_TEXT_TEMPLATE_PART, REQUEST_DYNAMIC_CAMPAIGN_FAILURE],
        endpoint: `/clients/${clientId}/dta-template-parts`,
        schema: Schemas.DYNAMIC_TEXT_AD_TEMPLATE_PART,
        tempId: templatePart.id,
        method: "POST",
        data: {
            feed_template_id: templatePart.feedTemplateId,
            order: templatePart.order,
            field: templatePart.field === "finalUrl" ? "final_url" : templatePart.field,
            value: templatePart.value
        }
    }
});

const removeTemplatePart = (templatePart: any) => {
    return {
        type: REMOVE_TEXT_TEMPLATE_PART,
        templatePart
    };
};
const deleteTemplatePart = (clientId: number, part: any, dispatch: any) => {
    if (part.new === true) {
        return dispatch(removeTemplatePart(part));
    }

    return doDeleteRequest(`/clients/${clientId}/dta-template-parts/${part.id}`, (resp: any) => {
        dispatch(removeTemplatePart(part));
    });
};

const cloneTextAdTemplate = (clientId: number, template: any, originalTemplate: any) => {
    return (dispatch: any, getState: any) => {
        originalTemplate.duplicating = true;
        dispatch(updateTemplate(originalTemplate));
        const body = {
            ad_type: template.adType,
            feed_id: template.feedId,
            destination_url_prefix: template.destinationUrlPrefix,
            individualized_parameters: template.individualizedParameters,
            label: template.label,
            order: template.order,
            url_encoding: template.urlEncoding
        };

        return callApi(
            `/clients/${clientId}/dynamic-campaign-ad-templates`,
            Schemas.DYNAMIC_CAMPAIGN_TEMPLATE,
            "POST",
            body
        ).then(
            (response: any) => {
                const templateId = response.result.id;
                const newTemplate = response.entities.dynamicCampaignTemplate[templateId];
                newTemplate.loading = true;
                dispatch({
                    type: CREATE_DYNAMIC_CAMPAIGN_TEMPLATE,
                    response
                });
                const state = getState();
                const conditions = state.dtcTemplateConditionals;
                _.filter(conditions, {
                    feedTemplateId: originalTemplate.id
                }).forEach((condition) => {
                    const item = { ...condition };
                    item.feedTemplateId = templateId;
                    dispatch(createTemplateConditional(clientId, item));
                });

                const parts = state.dynamicTextAdParts.entities;
                const templatePartConditions = state.dtaTemplatePartConditionals;
                const oldParts = _.filter(parts, { feedTemplateId: template.id });
                oldParts.forEach((item) => {
                    const data: {
                        value: string;
                        name: string;
                        valid: boolean;
                        order: number;
                        part: string;
                    } = {
                        value: item.value,
                        name: item.name,
                        valid: item.valid,
                        order: item.order,
                        part: ""
                    };
                    switch (item.field) {
                        case "headline":
                            data.part = "headline1";
                            break;
                        case "description1":
                            data.part = "headline2";
                            data.value = item.value.replace("!", "."); // can't have ! in headline
                            break;
                        case "description2":
                            data.part = "description";
                            break;
                        case "display_url":
                            data.part = "path";
                            data.value = getPathFromDisplayUrl(item.value);
                            data.valid = data.value.length > 0;
                            break;
                        case "final_url":
                            data.part = "finalUrl";
                            break;
                        default:
                            data.part = item.field;
                            break;
                    }

                    const newLine = makeTemplatePart(data.part, { id: templateId }, data);
                    _.filter(templatePartConditions, {
                        templatePartId: item.id
                    }).forEach((item) => {
                        const newItem = { ...item };
                        newItem.templatePartId = newLine.result;
                        newItem.dirty = true;
                        newItem.new = true;
                        newItem.id = _.uniqueId("new-");
                        dispatch(saveTemplatePartConditional(newItem));
                    });

                    dispatch(addTemplatePart(newLine));
                });
                newTemplate.loading = false;
                dispatch(updateTemplate(newTemplate));

                originalTemplate.duplicating = false;
                dispatch(updateTemplate(originalTemplate));
            },
            (response: any) => {}
        );
    };
};

const queueSotDetach = (clientId: number, sotId: number, dynamicCampaignId: number) => ({
    type: DynamicCampaignConstants.QUEUE_SOT_DETACH,
    payload: { clientId, sotId, dynamicCampaignId }
});

export default {
    REQUEST_DYNAMIC_CAMPAIGN,
    LOAD_DYNAMIC_CAMPAIGN,
    REQUEST_DYNAMIC_CAMPAIGNS,
    LOAD_DYNAMIC_CAMPAIGNS,
    REQUEST_DYNAMIC_CAMPAIGN_FAILURE,
    LOAD_DYNAMIC_CAMPAIGN_TEMPLATES,
    REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES,
    REQUEST_CREATE_DYNAMIC_CAMPAIGN_TEMPLATE,
    ADD_DYNAMIC_CAMPAIGN_TEMPLATE,
    CREATE_DYNAMIC_CAMPAIGN_TEMPLATE,
    REMOVE_DYNAMIC_CAMPAIGN_TEMPLATE,
    REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES_FAILURE,
    UPDATE_DYNAMIC_CAMPAIGN_TEMPLATE,
    REQUEST_SAVE_DYNAMIC_CAMPAIGN_TEMPLATE,
    SAVE_DYNAMIC_CAMPAIGN_TEMPLATE,
    START_BATCH_SAVE,
    END_BATCH_SAVE,
    REQUEST_TEMPLATE_CONDITIONAL,
    CREATE_TEMPLATE_CONDITIONAL,
    UPDATE_TEMPLATE_CONDITIONAL,
    REMOVE_TEMPLATE_CONDITIONAL,
    ADD_TEXT_TEMPLATE_PART,
    SAVE_TEXT_TEMPLATE_PART,
    CREATE_TEXT_TEMPLATE_PART,
    REMOVE_TEXT_TEMPLATE_PART,
    UPDATE_ORDER_TEXT_TEMPLATE_PART,
    LOAD_TEXT_TEMPLATE_PARTS,
    REQUEST_TEXT_TEMPLATE_PARTS,
    LOAD_CAMPAIGN_PARAMETERS,
    REQUEST_CAMPAIGN_PARAMETERS,
    REMOVE_DYNAMIC_CAMPAIGN,
    REQUEST_DELETE_DYNAMIC_CAMPAIGN,
    deleteDynamicCampaign,
    removeDynamicCampaign,
    fetchCampaignParameters,
    fetchCampaigns,
    fetchCampaign,
    fetchTemplatesForCampaign,
    saveTextAdTemplate,
    createTextAdTemplate,
    startSaving,
    doneSaving,
    updateTemplate,
    removeTextAdTemplate,
    removeTemplateConditional,
    createTemplateConditional,
    updateTemplateConditional,
    addTemplatePart,
    saveTemplatePart,
    updateTemplatePart,
    updateTemplatePartOrder,
    createTemplatePart,
    removeTemplatePart,
    deleteTemplatePart,
    cloneTextAdTemplate,
    queueSotDetach
};
