import React, { useImperativeHandle, useState } from "react";
import { endsWith, startsWith, every, findIndex, mapValues, set } from "lodash";
import * as Yup from "yup";
import dealerSetupRoutes from "./DealerSetupRoutes";
import DealerSetupButton, { onClickFunction } from "./DealerSetupButton";
import IFormValues from "../../../interfaces/DealerSetup/IFormValues";
import { defaults as mergeDefaults, defaultsDeep, defaultTo, mergeWith } from "lodash";

interface IField {
    value: any;
    isTouched: boolean;
    isValid: boolean;
    errors: null | string[];
}

type HandleFieldChange = (key: string, value: any, withoutValidation?: boolean) => void;
type ChangePage = () => () => (path: string, validatePage: () => void) => void;

const useDealerSetupForm = (
    defaults: any,
    formValues: IFormValues,
    schema: any,
    ref: any = null,
    path: string = "",
    savePage: any = null
): [any, HandleFieldChange, ChangePage] => {
    const validateField = (key: string, value: any) => {
        try {
            Yup.reach(schema, key).validateSync(value);

            return {
                value,
                isTouched: true,
                isValid: true,
                errors: null
            };
        } catch ({ errors }) {
            return {
                value,
                isTouched: true,
                isValid: false,
                errors: Array.isArray(errors) ? errors : [errors]
            };
        }
    };

    const validatePage = () => {
        if (Array.isArray(state.fields)) {
            const validatedFields = state.fields.map((fieldGroup: any) => {
                return validateFields(fieldGroup);
            });
            const isValid = every(validatedFields, (fieldGroup: any) => {
                return isPageValid(fieldGroup);
            });

            return {
                fields: validatedFields,
                isValid,
                isTouched: true
            };
        } else if (state.fields["Contact/About Us"]) {
            // <don't copy this code>
            // In this one case I'm doing this because of the required structure for sitelinks.
            const validatedFields = Object.keys(state.fields)
                .map((fieldGroup: string) => {
                    return {
                        [fieldGroup]: validateFields(state.fields[fieldGroup])
                    };
                })
                .reduce((items, item) => ({ ...items, ...item }), {});
            const isValid = every(validatedFields, (fieldGroup: any) => {
                return isPageValid(fieldGroup);
            });

            return {
                fields: validatedFields,
                isValid,
                isTouched: true
            };
            // </don't copy this code>
        } else {
            const validatedFields = validateFields(state.fields);
            const isValid = isPageValid(validatedFields);

            return {
                fields: { ...validatedFields },
                isValid,
                isTouched: true
            };
        }
    };

    const validateFields = (fields: any) => {
        return mapValues(fields, (field: IField | IField[], key: string) => {
            if (Array.isArray(field)) {
                return field.map((subField: IField, index: number) => {
                    return validateField(`${key}[${index}]`, subField.value);
                });
            } else {
                return validateField(key, field.value);
            }
        });
    };

    const isPageValid = (fields: any) => {
        return every(fields, (field: any) => {
            if (Array.isArray(field)) {
                return every(field, "isValid");
            } else {
                return field.isValid;
            }
        });
    };

    const handleFieldChange = (key: string, value: any, withoutValidation: boolean = false) => {
        let fieldsPath;

        if (startsWith(key, "[")) {
            fieldsPath = "fields";
        } else {
            fieldsPath = "fields.";
        }

        if (withoutValidation) {
            return setState(
                set(
                    {
                        ...state
                    },
                    `${fieldsPath}${key}`,
                    value
                )
            );
        }

        return setState(
            set(
                {
                    ...state
                },
                `${fieldsPath}${endsWith(key, "[]") ? key.slice(0, -2) : key}`,
                validateField(startsWith(key, "[") ? key.slice(key.indexOf("]") + 1) : key, value)
            )
        );
    };

    const mergeDefaultFormValeus = (values: any, defaultValues: any) => {
        return mergeWith(values, defaultValues, (objValue: any, srcValue: any, key: any): any => {
            if (key === "fields" && Array.isArray(srcValue)) {
                if (Array.isArray(objValue)) {
                    return objValue.forEach((value, index) => mergeDefaults(value, srcValue[index] || srcValue[0]));
                }
                return srcValue;
            }

            if (key === "fields") {
                return;
            }

            if (Array.isArray(srcValue)) {
                return defaultTo(objValue, srcValue);
            }

            return defaultsDeep(objValue, srcValue);
        });
    };

    const changePage = () => savePage(path, validatePage());

    // Used so we can call change page from the sidebar navigation
    useImperativeHandle(ref, () => ({
        changePage
    }));

    const [state, setState] = useState(mergeDefaultFormValeus(formValues[path], defaults));

    return [state, handleFieldChange, changePage];
};

const usePager = (path: string, formValues: IFormValues, onClick: onClickFunction | null = null) => {
    const pager = (
        leftButton: React.ReactElement | null = null,
        rightButton: React.ReactElement | null = null,
        errors: React.ReactElement | null = null
    ): React.ReactElement => {
        const currentPathIndex = findIndex(dealerSetupRoutes, ["path", path]);

        if (leftButton === null) {
            const prevPath: string = dealerSetupRoutes.reduce((prev: string, route: any, index: number): string => {
                if (currentPathIndex > index && !formValues[route.path].isDisabled) {
                    prev = route.path;
                }
                return prev;
            }, "");

            leftButton = <DealerSetupButton path={prevPath} label="Prev" onClick={onClick} />;
        }

        if (rightButton === null) {
            const nextPath: string = dealerSetupRoutes.reduceRight(
                (next: string, route: any, index: number): string => {
                    if (currentPathIndex < index && !formValues[route.path].isDisabled) {
                        next = route.path;
                    }
                    return next;
                },
                ""
            );

            rightButton = <DealerSetupButton path={nextPath} label="Next" color="blue" onClick={onClick} />;
        }

        return (
            <div className="border-t py-4 mt-4 flex flex-wrap items-center">
                {leftButton}
                <div className="mx-4">{rightButton}</div>
                {errors}
            </div>
        );
    };

    return pager;
};

export { useDealerSetupForm, usePager };

export const changeFcaCampaignType = (campaignType: string, currentName: string = ""): string => {
    let append = "";
    let remove = "";

    if (campaignType === "always") {
        append = "_a";
        remove = "_h";
    } else {
        append = "_h";
        remove = "_a";
    }

    let newName = currentName;

    if (currentName.includes(remove)) {
        newName = currentName.replace(remove, append);
    } else if (currentName.includes(append)) {
        // do nothing, the correct value is already appended
        return currentName;
    } else {
        newName = currentName += append;
    }

    return newName;
};

export const hasFcaCampaignType = (index: number, type: string, campaignName: string) => {
    if (type === "always") {
        return campaignName.includes("_a");
    }

    return campaignName.includes("_h");
};
