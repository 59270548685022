import React, { useState } from "react";
import TextField from "../Form/Blocks/TextField";
const buttonStyles = "bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
const errorStyles = "text-red-500 text-sm italic uppercase";
const buttonDisabledStyles = "bg-blue-200 text-white font-bold py-2 px-4 rounded cursor-not-allowed";
interface IDuplicateFormProps {
    onSubmit: (newName: string) => void;
}
export default ({ onSubmit }: IDuplicateFormProps) => {
    const [value, setValue] = useState<string>("");

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };

    const handleClick = () => !!value && onSubmit(value);

    return (
        <div>
            <div className={"w-full mb-5"}>
                <TextField autoFocus={false} label={"name"} name={"name"} value={value} handleChange={handleChange} />
            </div>
            <button
                onClick={handleClick}
                className={`${!value ? buttonDisabledStyles : buttonStyles}`}
                disabled={!value}
            >
                Duplicate
            </button>
            {!value && <span className={`ml-5 ${errorStyles}`}>required</span>}
        </div>
    );
};
