/**
 * Created by fjiles@dealerinspire.com on 1/9/17.
 */
import { schema } from "normalizr";
import { client } from "./client";
const siteLink = new schema.Entity("siteLinks");
siteLink.define({
    client
});

const siteLinksArray = { data: [siteLink] };

export { siteLink, siteLinksArray };
