/**
 * Created by fjiles@dealerinspire.com on 2/21/17.
 */
import * as FacebookReportConstants from "../constants/FacebookReportConstants";
import IClient from "../interfaces/IClient";

export const GENERATE_OEM_CONVERSIONS = "GENERATE_OEM_CONVERSIONS";
export const SUCCESSFULLY_GENERATED_OEM_CONVERSIONS = "SUCCESSFULLY_GENERATED_OEM_CONVERSIONS";
export const FAILED_TO_GENERATE_OEM_CONVERSIONS = "FAILED_TO_GENERATE_OEM_CONVERSIONS";

export const SUBSCRIBE_PAGE_TO_APP = "SUBSCRIBE_PAGE_TO_APP";
export const SUBSCRIBE_PAGE_TO_APP_SUCCESS = "SUBSCRIBE_PAGE_TO_APP_SUCCESS";
export const SUBSCRIBE_PAGE_TO_APP_FAILED = "SUBSCRIBE_PAGE_TO_APP_FAILED";

export const generateOemConversionsForPixel = (
    accountId: number,
    pixelId: string,
    oem: string,
    callback: () => void
) => ({
    type: GENERATE_OEM_CONVERSIONS,
    payload: {
        accountId,
        pixelId,
        oem,
        callback
    }
});

export const generateOemConversionsForPixelSuccess = () => ({
    type: SUCCESSFULLY_GENERATED_OEM_CONVERSIONS
});

export const generateOemConversionsForPixelFailure = (message: string) => ({
    type: FAILED_TO_GENERATE_OEM_CONVERSIONS,
    payload: {
        message
    }
});

export const subscribePageToApp = (client: IClient, facebookPageId: number, callback: () => void) => ({
    type: SUBSCRIBE_PAGE_TO_APP,
    payload: {
        client,
        facebookPageId,
        callback
    }
});

export const subscribePageToAppSuccess = () => ({
    type: SUBSCRIBE_PAGE_TO_APP_SUCCESS
});

export const subscribePageToAppFailed = (message: string) => ({
    type: SUBSCRIBE_PAGE_TO_APP_FAILED,
    payload: {
        message
    }
});

export const reportDownloadClicked = (clientId: number, client: IClient) => ({
    type: FacebookReportConstants.FACEBOOK_REPORT_DOWNLOAD_CLICKED,
    clientId,
    client
});
