export default {
    REQUEST_ALL_MANUAL_BUDGETS: "REQUEST_ALL_MANUAL_BUDGETS",
    REQUEST_ALL_MANUAL_BUDGETS_SUCCESS: "REQUEST_ALL_MANUAL_BUDGETS_SUCCESS",
    REQUEST_ALL_MANUAL_BUDGETS_FAIL: "REQUEST_MANUAL_BUDGETS_FAIL",

    REQUEST_ALL_CLIENT_MANUAL_BUDGETS: "REQUEST_ALL_CLIENT_MANUAL_BUDGETS",
    REQUEST_ALL_CLIENT_MANUAL_BUDGETS_SUCCESS: "REQUEST_ALL_CLIENT_MANUAL_BUDGETS_SUCCESS",
    REQUEST_ALL_CLIENT_MANUAL_BUDGETS_FAIL: "REQUEST_ALL_CLIENT_MANUAL_BUDGETS_FAIL",

    REQUEST_ALL_DELETED_MANUAL_BUDGETS: "REQUEST_ALL_DELETED_MANUAL_BUDGETS",
    REQUEST_ALL_DELETED_MANUAL_BUDGETS_SUCCESS: "REQUEST_ALL_DELETED_MANUAL_BUDGETS_SUCCESS",
    REQUEST_ALL_DELETED_MANUAL_BUDGETS_FAIL: "REQUEST_ALL_DELETED_MANUAL_BUDGETS_FAIL",

    REQUEST_ALL_CLIENT_DELETED_MANUAL_BUDGETS: "REQUEST_ALL_CLIENT_DELETED_MANUAL_BUDGETS",
    REQUEST_ALL_CLIENT_DELETED_MANUAL_BUDGETS_SUCCESS: "REQUEST_ALL_CLIENT_DELETED_MANUAL_BUDGETS_SUCCESS",
    REQUEST_ALL_CLIENT_DELETED_MANUAL_BUDGETS_FAIL: "REQUEST_ALL_CLIENT_DELETED_MANUAL_BUDGETS_FAIL",

    REQUEST_SINGLE_MANUAL_BUDGETS: "REQUEST_SINGLE_MANUAL_BUDGETS",
    REQUEST_SINGLE_MANUAL_BUDGETS_SUCCESS: "REQUEST_SINGLE_MANUAL_BUDGETS_SUCCESS",
    REQUEST_SINGLE_MANUAL_BUDGETS_FAIL: "REQUEST_SINGLE_MANUAL_BUDGETS_FAIL",

    REQUEST_CREATE_MANUAL_BUDGET: "REQUEST_CREATE_MANUAL_BUDGET",
    REQUEST_CREATE_MANUAL_BUDGET_SUCCESS: "REQUEST_CREATE_MANUAL_BUDGET_SUCCESS",
    REQUEST_CREATE_MANUAL_BUDGET_FAIL: "REQUEST_CREATE_MANUAL_BUDGET_FAIL",

    REQUEST_UPDATE_MANUAL_BUDGET: "REQUEST_UPDATE_MANUAL_BUDGET",
    REQUEST_UPDATE_MANUAL_BUDGET_SUCCESS: "REQUEST_UPDATE_MANUAL_BUDGET_SUCCESS",
    REQUEST_UPDATE_MANUAL_BUDGET_FAIL: "REQUEST_UPDATE_MANUAL_BUDGET_FAIL",

    REQUEST_DELETE_MANUAL_BUDGET: "REQUEST_DELETE_MANUAL_BUDGET",
    REQUEST_DELETE_MANUAL_BUDGET_SUCCESS: "REQUEST_DELETE_MANUAL_BUDGET_SUCCESS",
    REQUEST_DELETE_MANUAL_BUDGET_FAIL: "REQUEST_DELETE_MANUAL_BUDGET_FAIL",

    REQUEST_SINGLE_MANUAL_BUDGET_DETAILS: "REQUEST_MANUAL_BUDGET_DETAILS",
    REQUEST_SINGLE_MANUAL_BUDGET_DETAILS_FAIL: "REQUEST_MANUAL_BUDGET_DETAILS_FAIL",
    REQUEST_SINGLE_MANUAL_BUDGET_DETAILS_SUCCESS: "REQUEST_MANUAL_BUDGET_DETAILS_SUCCESS",

    REQUEST_MANUAL_BUDGET_ACTIVATIONS: "REQUEST_MANUAL_BUDGET_ACTIVATIONS",
    REQUEST_MANUAL_BUDGET_ACTIVATIONS_SUCCESS: "REQUEST_MANUAL_BUDGET_ACTIVATIONS_SUCCESS",
    REQUEST_MANUAL_BUDGET_ACTIVATIONS_FAIL: "REQUEST_MANUAL_BUDGET_ACTIVATIONS_FAIL",

    TYPE_DSP: "DSP",
    TYPE_SOCIAL: "Social"
};
