import { useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelActions from "../../actions/labelActions";
import ApiConstants from "../../constants/ApiConstants";
import IBudgetLabel from "../../interfaces/Budgets/IBudgetLabel";
import IAppState from "../../interfaces/IAppState";
import IPagedResponse from "../../interfaces/IPagedResponse";
import { callApiAndFetchEveryPaginatedPage } from "../../middleware/api";
import Schemas from "../../middleware/schemas/labels";

type useBudgetReturn = {
    handleFetchLabels: () => void;
    handleFetchRefreshLabels: () => void;
    findById: (id: string) => IBudgetLabel;
    isInactive: (label: IBudgetLabel) => boolean;
    sortedArrayLabels: IBudgetLabel[];
    labelOptions: JSX.Element[];
    isLoading: boolean;
};

function useLabel(): useBudgetReturn {
    const dispatch = useDispatch();
    const { FETCH_LABELS_URL, FETCH_REFRESH_LABELS_URL, FETCH_MS_LABELS_URL, FETCH_REFRESH_MS_LABELS_URL } =
        ApiConstants;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const clientId = useSelector((state: IAppState) => state.clients.currentClient);
    const labels = useSelector((state: IAppState) => state.labels.entities);
    const isAdwords = !!useMatch("/client/:clientId/budgets/adwords/*");
    const isMicrosoft = !!useMatch("/client/:clientId/budgets/microsoft/*");

    useEffect(() => {
        fetchLabels();
    }, []);

    const fetchLabels = async () => {
        const apiUrl = isAdwords ? FETCH_LABELS_URL : FETCH_MS_LABELS_URL;
        try {
            setIsLoading(true);
            const response: IPagedResponse = await callApiAndFetchEveryPaginatedPage(
                apiUrl(clientId.toString()),
                Schemas.LABELS_ARRAY
            );
            dispatch(LabelActions.fetchLabelsSuccess(response.entities.labels));
        } catch (e) {
            dispatch(LabelActions.fetchLabelsFailed());
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRefreshLabels = async () => {
        const apiUrl = isAdwords ? FETCH_REFRESH_LABELS_URL : FETCH_REFRESH_MS_LABELS_URL;
        try {
            setIsLoading(true);
            const response: IPagedResponse = await callApiAndFetchEveryPaginatedPage(
                apiUrl(clientId.toString()),
                Schemas.LABELS_ARRAY
            );

            dispatch(LabelActions.fetchLabelsSuccess(response.entities.labels));
        } catch (e) {
            dispatch(LabelActions.fetchLabelsFailed());
        } finally {
            setIsLoading(false);
        }
    };

    const createLabelOptions = (): JSX.Element[] =>
        toSortedArray().map((label: IBudgetLabel) => {
            const { id, name } = label;
            return (
                <option key={`${id}-${name}`} value={id}>
                    {name}
                </option>
            );
        });

    const toSortedArray = (): IBudgetLabel[] => {
        return (
            (labels &&
                Object.keys(labels)
                    .sort((a: string, b: string) => {
                        return labels[a].name.localeCompare(labels[b].name);
                    })
                    .reduce((acc: IBudgetLabel[], key) => {
                        if (acc.indexOf(labels[key])) {
                            acc.push(labels[key]);
                        }
                        return acc;
                    }, [])) ||
            []
        );
    };

    const findById = (id: string): IBudgetLabel => {
        return toSortedArray().find((label: any) => String(label.id) === id) || ({} as IBudgetLabel);
    };

    const isInactive = (label: IBudgetLabel): boolean => {
        return !!label?.id && !labels[label.id];
    };

    return {
        handleFetchLabels: fetchLabels,
        handleFetchRefreshLabels: fetchRefreshLabels,
        findById,
        isInactive,
        labelOptions: createLabelOptions(),
        sortedArrayLabels: toSortedArray(),
        isLoading
    };
}

export default useLabel;
