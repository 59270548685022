import React, { SVGProps } from "react";
import Svg from "./Svg";

const ManualSpecialOfferTemplatesIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9112 14.4411C17.085 14.0651 17.5401 13.8969 17.9277 14.0655L21.9116 15.7986L21.9152 15.8002C22.4216 16.0235 23.0017 16.46 23 17.1599C22.9983 17.8585 22.418 18.2932 21.9101 18.5162C21.9097 18.5164 21.9093 18.5166 21.9089 18.5167L14.7971 21.6634L14.797 21.6634C14.2623 21.9 13.6085 22 12.9979 22C12.3873 22 11.7335 21.9 11.1988 21.6634L11.1987 21.6634L4.08536 18.5161C4.08523 18.516 4.0851 18.516 4.08497 18.5159C3.57812 18.2923 3 17.8563 3 17.1581C3 16.4598 3.57842 16.0238 4.08536 15.8002L4.08871 15.7987L8.06883 14.0656C8.45636 13.8969 8.9115 14.0649 9.08542 14.4409C9.25934 14.8169 9.08618 15.2585 8.69865 15.4272L4.72357 17.1581L11.8369 20.3054C11.8369 20.3054 11.8369 20.3055 11.837 20.3055C12.1141 20.428 12.5344 20.5075 12.9979 20.5075C13.4614 20.5075 13.8817 20.428 14.1588 20.3055C14.1589 20.3055 14.1589 20.3054 14.1589 20.3054L21.2731 17.1578L21.2746 17.1571L17.2984 15.4273C16.9108 15.2587 16.7374 14.8172 16.9112 14.4411Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9112 9.44112C17.085 9.06506 17.5401 8.89688 17.9277 9.06549L21.9116 10.7986L21.9152 10.8002C22.4216 11.0235 23.0017 11.46 23 12.1599C22.9983 12.8585 22.418 13.2932 21.9101 13.5162C21.9097 13.5164 21.9093 13.5166 21.9089 13.5167L14.7971 16.6634L14.797 16.6634C14.2623 16.9 13.6085 17 12.9979 17C12.3873 17 11.7335 16.9 11.1988 16.6634L11.1987 16.6634L4.08536 13.5161C4.08523 13.516 4.0851 13.516 4.08497 13.5159C3.57812 13.2923 3 12.8563 3 12.1581C3 11.4598 3.57842 11.0238 4.08536 10.8002L4.08871 10.7987L8.06883 9.0656C8.45636 8.89686 8.9115 9.06487 9.08542 9.44087C9.25934 9.81687 9.08618 10.2585 8.69865 10.4272L4.72357 12.1581L11.8369 15.3054C11.8369 15.3054 11.8369 15.3055 11.837 15.3055C12.1141 15.428 12.5344 15.5075 12.9979 15.5075C13.4614 15.5075 13.8817 15.428 14.1588 15.3055C14.1589 15.3055 14.1589 15.3054 14.1589 15.3054L21.2731 12.1578L21.2746 12.1571L17.2984 10.4273C16.9108 10.2587 16.7374 9.81718 16.9112 9.44112Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.41499 7.31355L8.70164 8.79076C8.76207 8.81792 8.81729 8.85194 8.8668 8.89148L11.8369 10.2492L11.837 10.2492C12.1141 10.3759 12.5344 10.458 12.9979 10.458C13.4614 10.458 13.8817 10.3759 14.1588 10.2492L14.1589 10.2492L17.1469 8.88332C17.1936 8.8473 17.2452 8.81613 17.3013 8.79088L20.3146 7.43523L21.2688 6.99904L20.5848 6.68636L17.2984 5.20924C17.2379 5.18208 17.1827 5.14806 17.1332 5.10852L14.1631 3.7508L14.163 3.75077C13.8859 3.62412 13.4656 3.54198 13.0021 3.54198C12.5387 3.54198 12.1183 3.62412 11.8412 3.75077L11.8411 3.7508L8.85315 5.11668C8.80642 5.1527 8.75478 5.18387 8.69865 5.20912L5.68521 6.56485L4.73119 7.00096L5.41499 7.31355ZM21.2186 5.28132L14.8013 2.34777L14.8012 2.34774C14.2665 2.10337 13.6127 2 13.0021 2C12.3915 2 11.7377 2.10337 11.203 2.34774L11.2029 2.34777L5.05178 5.15967L4.08871 5.59295L4.08536 5.59446C3.57842 5.82546 3 6.27593 3 6.99747C3 6.99854 3 6.99961 3 7.00069C3 7.00069 3 7.0007 3 7.0007C2.99829 7.72385 3.57844 8.17483 4.08476 8.40553L4.08836 8.40716L4.7812 8.71856L11.1987 11.6522L11.1988 11.6523C11.7335 11.8966 12.3873 12 12.9979 12C13.6085 12 14.2623 11.8966 14.797 11.6523L14.7971 11.6522L20.9481 8.84039L21.9113 8.40705L21.9146 8.40554C22.4216 8.17454 23 7.72407 23 7.00253C23 7.00152 23 7.00051 23 6.99949C23 6.99943 23 6.99936 23 6.9993C23.0017 6.27615 22.4216 5.82517 21.9152 5.59446L21.9116 5.59283L21.2186 5.28132Z"
            fill="currentColor"
        />
    </Svg>
);

export default ManualSpecialOfferTemplatesIcon;
