import React, { FunctionComponent } from "react";
import { getDurationString } from "../../../../utils";
import moment from "moment";
import IFacebookLog from "../../../../interfaces/IFacebookLog";
import IFacebookAutoExport from "../../../../interfaces/IFacebookAutoExport";

const style = {
    backgroundColor: "white",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "40px",
    padding: "5px 10px"
};

interface ILogEntry {
    log: IFacebookLog;
    exportMapping: IFacebookAutoExport | null;
}

const LogEntry: FunctionComponent<ILogEntry> = ({ log, exportMapping }) => {
    const startMoment = moment(log.startedAt);
    const endMoment = moment(log.completedAt);
    return (
        <div className="mb-2" style={style}>
            <div>
                <h4 className="uppercase text-gray-500 mb-8">Generated</h4>
                <p className="mb-0">{endMoment.format("MMM Do [at] h:mm a")}</p>
                <p className="mb-0 text-gray-500">({getDurationString(startMoment, endMoment)})</p>
                <p className="mb-0 text-xs text-gray-500">{exportMapping && exportMapping.fileLocation}</p>
            </div>
            <div>
                <h4 className="uppercase text-gray-500">Result</h4>

                <p className="mb-0">
                    {log.validRows} <span className="text-xs">vehicles updated or added</span>
                </p>
                <p className="mb-0">
                    {log.rows} <span className="text-xs">vehicles found</span>
                </p>
                <div className="mb-0 flex">
                    <p className="mb-0">
                        {log.rejectedRows} <span className="text-xs">vehicles failed conditionals</span>
                    </p>
                </div>
                <div className="mb-0 flex">
                    <p>
                        {log.invalidRows} <span className="text-xs">vehicles failed validation</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LogEntry;
