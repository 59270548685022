import React, { FunctionComponent } from "react";
import ToggleSwitch from "../../../Shared/Form/ToggleSwitch";
import IClient from "../../../../interfaces/IClient";

const labelMap: { [key: string]: string } = {
    has_country_exclusion: "Country Exclusion"
};

interface IFeatures {
    client: IClient;
    roles: any;
    onValueChange(event: string): void;
}

const Features: FunctionComponent<IFeatures> = ({ client, roles, onValueChange }) => {
    return (
        <div className="w-full md:w-3/4 lg:w-1/2">
            {Object.keys(roles).map((name) => {
                if (labelMap.hasOwnProperty(name)) {
                    return (
                        <div className="flex text-xs" key={name}>
                            <ToggleSwitch
                                onToggle={() => onValueChange(name as any)}
                                field={`role[${name}]`}
                                value={client.roles.includes(name)}
                            />
                            {labelMap[name]}
                        </div>
                    );
                }
                return;
            })}
        </div>
    );
};

export default Features;
