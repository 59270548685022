import React from "react";
import { hot } from "react-hot-loader";
import { Routes, Route } from "react-router-dom";
import Report from "../components/Budgets/Report/Index";
import ManualBudgetList from "../components/Client/Budgets/Manual/ManualBudgetList";
import BudgetList from "../components/Client/Budgets/BudgetList";
import NotFound404 from "../components/Shared/NotFound404";
import Redirect from "../components/Shared/Loaders/Redirect";

const BudgetRoutes = () => (
    <Routes>
        <Route index element={<Redirect to="adwords" />} />
        <Route path="adwords" element={<BudgetList />} />
        <Route path="microsoft" element={<BudgetList />} />
        <Route path="koddi" element={<BudgetList />} />
        <Route path="reports" element={<Report />} />
        <Route path="cars" element={<BudgetList />} />
        <Route path="facebook" element={<BudgetList />} />
        <Route path="programmatic-video" element={<BudgetList />} />
        <Route path="all" element={<BudgetList />} />
        <Route path=":type" element={<ManualBudgetList />} />
    </Routes>
);

export default hot(module)(BudgetRoutes);
