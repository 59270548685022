import { combineReducers, Reducer } from "redux";
import * as Constants from "../../constants/InventoryExportsConstants";
import IEntity from "../../interfaces/IEntity";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";

const entities: Reducer<IEntity<any>> = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_GOOGLE_LOGS_SUCCESS:
            return action.payload.logs;
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const allIds: Reducer<number[]> = (state = [], action) => {
    switch (action.type) {
        case Constants.FETCH_GOOGLE_LOGS_SUCCESS:
            return action.payload.allIds;
        default:
            return state;
    }
};

const errors: Reducer<any> = (state = null, action) => {
    switch (action.type) {
        case Constants.FETCH_GOOGLE_LOGS_FAILURE:
            return action.payload.errors;
        case Constants.FETCH_GOOGLE_LOGS:
        case Constants.FETCH_GOOGLE_LOGS_SUCCESS:
        case RESET_CLIENT_STATE:
            return null;
        default:
            return state;
    }
};

const loading: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case Constants.FETCH_GOOGLE_LOGS:
            return true;
        case Constants.FETCH_GOOGLE_LOGS_SUCCESS:
        case Constants.FETCH_GOOGLE_LOGS_FAILURE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({ entities, allIds, loading, errors });
