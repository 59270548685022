import React, { SVGProps } from "react";
import Svg from "./Svg";

const PinIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M12.6317 10.8221C12.4876 11.1788 12.1413 11.4121 11.7565 11.4116L0.956131 11.4039C0.570726 11.4084 0.220997 11.1782 0.0726955 10.8222C-0.0756058 10.4663 0.00732208 10.0562 0.282176 9.78628L2.56257 7.50588L1.09219 1.48313C1.00528 1.15806 1.0597 0.811559 1.24204 0.529085C1.42437 0.246611 1.71765 0.054453 2.04954 0L10.637 0.00506109C10.9689 0.0599053 11.2624 0.252409 11.4451 0.535098C11.6278 0.817788 11.6826 1.16435 11.5961 1.48932L10.1328 7.51035L12.4117 9.78922C12.6887 10.0567 12.7757 10.4654 12.6317 10.8221ZM4.85067 13.414L5.45794 21.0843L5.45091 21.0773C5.48083 21.4216 5.70692 21.718 6.03121 21.8379C6.3555 21.9577 6.71964 21.8796 6.96584 21.6374C7.11875 21.4914 7.21474 21.2957 7.23668 21.0854L7.83913 13.4115L4.85067 13.414Z" />
    </Svg>
);

export default PinIcon;
