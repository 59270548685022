import React from "react";

const adBlockStyle = {
    backgroundColor: "#fff",
    borderRadius: "2px",
    borderColor: "#006621",
    borderWidth: "1px",
    color: "#006621",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "14px",
    marginLeft: 0,
    marginRight: "2px",
    padding: "0 2px",
    verticalAlign: "baseline"
};

const AdTag = () => <span style={adBlockStyle}>Ad</span>;

export default AdTag;
