import React, { useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/svg/ios-arrow-down.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import SettingsIcon from "../Shared/Icons/SettingsIcon";
import LogoutIcon from "../Shared/Icons/LogoutIcon";
import NavItem from "../Navbar/NavItem";
import IUser from "../../interfaces/IUser";

const userLinks = [
    {
        url: "/users/settings",
        href: "/users/settings",
        icon: <SettingsIcon className="w-6 h-6" />,
        title: "My Account",
        type: "NavLink",
        shouldShow: true
    },
    {
        url: "/logout",
        icon: <LogoutIcon className="w-6 h-6" />,
        title: "Logout",
        type: "NavLink",
        href: "/logout",
        shouldShow: true
    }
];

const Menu = () => {
    const style = { zIndex: 100 };
    return (
        <div style={style} className="bg-blue-700 right-0 mr-4 absolute rounded shadow overflow-hidden">
            <ul className="mb-0">
                {userLinks.map((link) => (
                    <NavItem item={link} key={link.href} collapsed={false} />
                ))}
            </ul>
        </div>
    );
};

const ProfileImage: React.FC<{ user: IUser }> = ({ user }) => {
    const value = user.firstName[0] + user.lastName[0];
    return (
        <div className="bg-blue-200 flex text-blue-800 rounded-full w-10 h-10 items-center justify-center">
            {value.toUpperCase()}
        </div>
    );
};

const CurrentUser: React.FC<{
    user: IUser;
    isInternalUser: boolean;
}> = ({ user, isInternalUser }) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [open, setOpen] = useState(false);
    useOnClickOutside(ref, () => setOpen(false));

    return (
        <div className="relative text-sm cursor-pointer" ref={ref} onClick={() => setOpen(!open)}>
            <div className="flex mr-4 items-center text-blue-100 tracking-wider">
                <ProfileImage user={user} />
                <div className="flex items-center ml-2">
                    {user.firstName} {user.lastName} <ArrowDown className="ml-2 fill-current h-4" />
                </div>
            </div>

            {open === true && <Menu />}
        </div>
    );
};

export default CurrentUser;
