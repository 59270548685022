import { call, put, select, takeEvery, CallEffect, PutEffect } from "redux-saga/effects";
import { Action } from "redux";
import { callApi, doDeleteRequest } from "../../../../middleware/api";
import actions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import SCHEMA from "../../../../middleware/schemas/etaTemplateBoilerplate";
import IPagedResponse from "../../../../interfaces/IPagedResponse";
import IEntity from "../../../../interfaces/IEntity";
import { IEtaTemplateBoilerplate } from "../../../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";
import { saveConditionals, saveParts } from "../../expandedTextAdTemplates";
import { IConditional, ITemplatePart } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";

function* index() {
    try {
        const response: IPagedResponse<{ etaTemplateBoilerplate: IEntity<IEtaTemplateBoilerplate> }> = yield call(
            callApi,
            `/dc-eta-boilerplates?expand[parts.conditionals]=*&expand[conditionals]=*&sort[name]=asc&limit=500`,
            SCHEMA.ETA_TEMPLATE_BOILERPLATE,
            "GET"
        );
        yield put(
            actions.index.success({ entities: response.entities.etaTemplateBoilerplate, data: response.result.data })
        );
    } catch (e) {
        yield put(actions.index.failure({ error: `There was an issue loading templates.` }));
    }
}

function* store({
    payload: { name, eta, clientId }
}: ReturnType<typeof actions.store.request>): Generator<CallEffect | PutEffect<Action>, void, { result: any }> {
    try {
        const response = yield call(callApi, `/dc-eta-boilerplates`, {}, "POST", {
            name
        });

        const newEtaTemplateBoilerplate: IEtaTemplateBoilerplate = response.result;

        try {
            const newParts: ITemplatePart[] = eta.parts.map((part) => ({
                ...part,
                dynamicCampaignExpandedTextAdTemplateId: newEtaTemplateBoilerplate.id,
                parentType: "dsc-eta-template-boilerplate",
                new: true,
                dirty: true,
                conditionals: part.conditionals.map((conditional) => ({
                    ...conditional,
                    new: true,
                    dirty: true
                }))
            }));

            const newConditionals: IConditional[] = eta.conditionals.map((conditional) => ({
                ...conditional,
                dynamicCampaignExpandedTextAdTemplateId: newEtaTemplateBoilerplate.id,
                parentType: "dsc-eta-template-boilerplate",
                new: true,
                dirty: true
            }));

            yield call(saveParts, {
                type: "",
                payload: {
                    clientId,
                    parts: newParts,
                    dynamicCampaignExpandedTextAdTemplateId: newEtaTemplateBoilerplate.id
                }
            });

            yield call(saveConditionals, {
                type: "",
                payload: {
                    conditionals: newConditionals,
                    url: `/clients/${clientId}/dc-eta-template-conditionals`,
                    updateProp: {}
                }
            });
        } catch (e) {
            yield put(
                actions.destroy.request({
                    boilerplate: newEtaTemplateBoilerplate
                })
            );
            yield put(
                actions.store.failure({
                    error: `There was an issue saving the template. Please check for invalid parts or conditionals`
                })
            );
            return;
        }

        yield put(actions.store.success());
    } catch (e) {
        yield put(
            actions.store.failure({
                error: e?.errors?.name?.[0] ?? `There was an issue saving the template`
            })
        );
    }
}

function* destroy({ payload: { boilerplate } }: ReturnType<typeof actions.destroy.request>) {
    try {
        const { id } = boilerplate;
        yield call(doDeleteRequest, `/dc-eta-boilerplates/${id}`);
        yield put(actions.destroy.success({ id: boilerplate.id }));
    } catch (e) {
        const { id, name } = boilerplate;
        yield put(actions.destroy.failure({ id, error: `There was an issue deleting ${name}` }));
    }
}

export default function* sagas() {
    yield takeEvery(actions.index.request, index);
    yield takeEvery(actions.store.request, store);
    yield takeEvery(actions.destroy.request, destroy);
}
