import { combineReducers, Reducer } from "redux";
import { REQUEST_LOAD_ADWORDS_ADGROUPS, LOAD_ADWORDS_ADGROUPS } from "../../actions/campaignActions";
import { filter } from "lodash/fp";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";
import IEntity from "../../interfaces/IEntity";

const loading: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case REQUEST_LOAD_ADWORDS_ADGROUPS:
            return true;
        default:
            return false;
    }
};
const entities: Reducer<IEntity<any>> = (state = {}, action) => {
    switch (action.type) {
        case LOAD_ADWORDS_ADGROUPS:
            return { ...state, ...action.adGroups };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};
export default combineReducers({ loading, entities });

export const getAdGroupForCampaign: Reducer = (state, props) =>
    filter({ campaignId: props.campaignId })(state.adWordsAdGroups);
