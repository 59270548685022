import React, { SVGProps } from "react";
import Svg from "./Svg";

const ChainIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M4.902 13A3.1 3.1 0 0 1 8 9.902h3.929V8H8c-2.759 0-5 2.241-5 5s2.241 5 5 5h3.929v-1.902H8A3.1 3.1 0 0 1 4.902 13zm3.812.982h8.572v-1.964H8.714v1.964zM18 8h-3.929v1.902H18A3.1 3.1 0 0 1 21.098 13 3.1 3.1 0 0 1 18 16.098h-3.929V18H18c2.759 0 5-2.241 5-5s-2.241-5-5-5z" />
    </Svg>
);

export default ChainIcon;
