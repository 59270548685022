import React, { useState, useRef } from "react";
import { Field, FieldProps } from "formik";
import { IConditional, ISpecialOfferTemplatePart } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import SettingsIcon from "../../../Shared/Icons/SettingsIcon";
import EtaConditionals from "../EtaConditionals";
import CharacterCount from "../../../Shared/Form/CharacterCount";
import { Tooltip } from "../../../Shared/Table";
import { ConditionalOperatorsForEtaPreview } from "../ExpandedTextAds/TemplatePart";
import ConditionalsTooltipContent from "../ExpandedTextAds/ConditionalsTooltipContent";
import { characterLimit } from "../../../../utils/DynamicCampaignUtils";
import PinIcon from "../../../Shared/Icons/PinIcon";

interface IProps {
    templateIndex: number;
    index: number;
    item: ISpecialOfferTemplatePart;
    size: string;
}

const SpecialOfferTemplatePart: React.FC<IProps> = ({ templateIndex, item, size }) => {
    const [showConditionalsTooltip, setShowConditionalsTooltip] = useState(false);
    const baseFieldName = `specialOfferTemplates[${templateIndex}]`;
    const fieldName = `${baseFieldName}.parts[${item.index}]`;
    const conditionalsBtnRef = useRef(null);

    const toggleConditionalsBtnHover = () => setShowConditionalsTooltip(!showConditionalsTooltip);

    const hasConditionals = item.conditionals.filter((c: IConditional) => c && !c.deleted).length > 0;

    const hideCount = item.field === "final_url" || item.field === "path" || item.value.includes("{{");

    const buttonClasses = "bg-blue-100 border border-blue-100 hover:bg-blue-200 flex justify-center content-center p-3";
    return (
        <React.Fragment>
            <Field
                key={item.id}
                name={`${fieldName}[value]`}
                type="text"
                render={(props: FieldProps) => {
                    const { field } = props;
                    const pinnedField = item.pinnedField;
                    return (
                        <div className="mt-2">
                            <div className="flex items-center">
                                <ModalToggle
                                    toggleButton={(open: any) => (
                                        <div className="group inline-block relative">
                                            <button
                                                ref={conditionalsBtnRef}
                                                onMouseEnter={toggleConditionalsBtnHover}
                                                onMouseLeave={toggleConditionalsBtnHover}
                                                type="button"
                                                className={`${buttonClasses} ${
                                                    hasConditionals
                                                        ? "text-green-500 hover:text-green-600"
                                                        : "text-blue-400 hover:text-blue-600"
                                                }`}
                                            >
                                                <SettingsIcon className="w-6 h-6" />
                                            </button>

                                            {hasConditionals && (
                                                <Tooltip
                                                    reference={conditionalsBtnRef}
                                                    shouldShow={showConditionalsTooltip}
                                                >
                                                    <ConditionalsTooltipContent item={item} />
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                    modalContent={() => console.log(null)}
                                />

                                <div className={`relative ${size}`}>
                                    <input
                                        type="text"
                                        className={`w-full border-gray-500 border px-3 py-3 ${
                                            pinnedField ? "pr-10" : ""
                                        }`}
                                        disabled={true}
                                        value={field.value}
                                    />

                                    {pinnedField && (
                                        <div className="absolute right-0 top-0 mt-3 mr-2">
                                            <PinIcon className={`w-8 h-6 text-gray-500 pt-1`} />
                                            <span className={"absolute right-0 top-0 mr-1 mt-1 text-gray-600"}>{`${
                                                pinnedField ? pinnedField.split("")[1] : ""
                                            }`}</span>
                                        </div>
                                    )}
                                </div>
                                <CharacterCount
                                    value={field.value}
                                    characterLimit={characterLimit(item.field)}
                                    hideCount={hideCount}
                                />
                            </div>
                        </div>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default SpecialOfferTemplatePart;
