import {
    createBudget,
    updateBudget,
    deleteBudget,
    pushBudget,
    downloadCsvClicked
} from "../../actions/budgets/budgetActions";

import { BUDGET_TYPE_ID_MONTHLY } from "../../constants";
import useTrackEvent from "../useTrackEvent";

type useTrackingBudgetEventsReturn = {
    createEvent: (action: ReturnType<typeof createBudget>) => void;
    updateEvent: (action: ReturnType<typeof updateBudget>) => void;
    removeEvent: (action: ReturnType<typeof deleteBudget>) => void;
    pushEvent: (action: ReturnType<typeof pushBudget>) => void;
    downloadCsv: (action: ReturnType<typeof downloadCsvClicked>) => void;
};

function useTrackingBudgetEvents(): useTrackingBudgetEventsReturn {
    const trackEvent = useTrackEvent();

    const createEvent = (action: ReturnType<typeof createBudget>) => {
        const budgetType = action.payload.formValues.budgetType === BUDGET_TYPE_ID_MONTHLY ? "monthly" : "event";

        trackEvent({
            category: "Budget Create Page",
            action: `budget_create_${budgetType}`,
            label: "Create"
        });
    };

    const updateEvent = (action: ReturnType<typeof updateBudget>) => {
        trackEvent({
            category: "Budget Edit Page",
            action: `budget_update_${action.payload.clientId}_${action.payload.budgetId}`,
            label: "Update"
        });
    };

    const removeEvent = (action: ReturnType<typeof deleteBudget>) => {
        trackEvent({
            category: "Budget Index Page",
            action: `budget_delete_${action.budget.client.id}_${action.budget.id}`,
            label: "Delete"
        });
    };

    const pushEvent = (action: ReturnType<typeof pushBudget>) => {
        trackEvent({
            category: "Budget Index Page",
            action: `budget_push_${action.budget.clientId}_${action.budget.id}`,
            label: "Push"
        });
    };

    const downloadCsv = (action: ReturnType<typeof downloadCsvClicked>) => {
        trackEvent({
            category: "Budget Layout Page",
            action: `download_csv_clicked_${action.clientId}`,
            label: "Download CSV"
        });
    };

    return {
        createEvent,
        updateEvent,
        removeEvent,
        pushEvent,
        downloadCsv
    };
}

export default useTrackingBudgetEvents;
