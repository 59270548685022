const dotStyles = {
    border: "3px solid #e0e0e0",
    borderRadius: "50%",
    height: "15px",
    margin: "-28px auto 18px",
    width: "15px"
} as React.CSSProperties;

const adContainerStyles = {
    backgroundColor: "#fff",
    borderRadius: "2px",
    boxShadow: "0 3px 1px -2px rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.084)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    lineHeight: "20px",
    padding: "11px 16px",
    transition: "box-shadow cubic-bezier(0,0,.2,1) 218ms"
} as React.CSSProperties;

const textStyles = {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal"
} as React.CSSProperties;

const headlineStyles = {
    fontSize: "16px",
    maxHeight: "40px",
    overflow: "hidden"
} as React.CSSProperties;

const linkStyle = {
    color: "#1a0dab",
    textDecoration: "none"
};

const displayUrlHolderStyles = {
    color: "#006621",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
} as React.CSSProperties;

const deskTopContainerStyles = {
    borderRight: "none !important",
    minWidth: "160px",
    width: "528px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-start",
    padding: "8px",
    lineHeight: "18px"
} as React.CSSProperties;

const desktopLinkStyle = {
    color: "#1a0dab",
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textDecoration: "none",
    fontSize: "18px"
} as React.CSSProperties;

const mobileWrapperStyles: React.CSSProperties = {
    border: "solid",
    borderColor: "#bcbcbc",
    borderTopLeftRadius: "32px",
    borderTopRightRadius: "32px",
    borderWidth: "3px 3px 0"
};

const phoneStyles: React.CSSProperties = {
    backgroundColor: "#f5f5f5",
    borderColor: "#fff",
    borderStyle: "solid",
    borderTopRightRadius: "32px",
    borderTopLeftRadius: "32px",
    borderWidth: "32px 8px 0",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-start",
    padding: "8px"
};

export {
    phoneStyles,
    mobileWrapperStyles,
    adContainerStyles,
    dotStyles,
    deskTopContainerStyles,
    desktopLinkStyle,
    displayUrlHolderStyles,
    linkStyle,
    headlineStyles,
    textStyles
};
