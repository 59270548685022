import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import ChevronDown from "./Icons/ChevronDownIcon";
import WarningIcon from "./Icons/WarningIcon";

interface ISectionProps {
    title: string;
    subTitle?: string | JSX.Element;
    children: ReactNode;
    errors?: any;
    defaultOpenState?: boolean;
    stripe?: boolean;
    toggleAllState?: boolean;
    small?: boolean;
    icon?: any;
}
const CollapsibleSection: FunctionComponent<ISectionProps> = ({
    title,
    children,
    stripe,
    subTitle,
    toggleAllState,
    errors,
    icon,
    defaultOpenState = false,
    small = false
}) => {
    const [isOpen, setOpenState] = useState(defaultOpenState);

    useEffect(() => {
        if (toggleAllState === undefined) {
            return;
        }
        setOpenState(toggleAllState);
    }, [toggleAllState]);

    return (
        <div className="my-2">
            <div className={`border w-full rounded ${small ? "" : "shadow"} ${stripe ? "bg-blue-200" : "bg-white"}`}>
                <div className="flex flex-wrap items-center">
                    {icon && <div className="w-6">{icon}</div>}

                    <div
                        className={`flex flex-grow justify-between ${small ? "p-2" : "p-4"} cursor-pointer`}
                        onClick={() => setOpenState(!isOpen)}
                    >
                        <div className={`${small ? "" : "my-2"} text-blue-800 flex flex-col`}>
                            <span className={`${small ? "text-sm" : "text-2xl"}`}>{title}</span>
                            {!isOpen && subTitle && <span className="text-xs text-gray-600">{subTitle}</span>}
                        </div>

                        <div className="flex justify-end">
                            {errors && (
                                <div className="flex items-center text-red-500 content-center mx-4 text-base">
                                    <WarningIcon className="w-6 h-6" />
                                    <span className="ml-2">Attention Needed</span>
                                </div>
                            )}
                            <div className="flex justify-end items-center">
                                <ChevronDown
                                    className={`${small ? "w-3 h-3" : "w-6 h-6"} text-cerulean-500`}
                                    style={{
                                        transition: ".2s ease",
                                        ...(isOpen ? {} : { transform: "rotate(-90deg)" })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && <div className="px-4 pb-4 w-full rounded-b">{children}</div>}
            </div>
        </div>
    );
};

export default CollapsibleSection;
