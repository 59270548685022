import React from "react";

interface IProps {
    name: string;
    style?: { [key: string]: string | number };
    fill?: string;
    width?: string;
    className?: string;
    height?: string;
    viewBox?: string;
}

const GOOGLE = "GOOGLE";
const MICROSOFT = "MICROSOFT";
const INSIGHT = "INSIGHT";
const svgs: { [key: string]: any } = {
    [GOOGLE]: (
        <>
            <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
            />
            <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
            />
            <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
            />
            <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
            />
        </>
    ),
    [MICROSOFT]: <path fill="#FFC107" d="M9 5L9 37 18 43 39 32 39 21 22 15 25 24 31 26 11 36 19 29 19 8z" />,
    [INSIGHT]: (
        <g fill="none" fillRule="evenodd">
            <path
                fill="#008FCC"
                d="M11.869 10.313c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5m0 2c1.379 0 2.5 1.12 2.5 2.5s-1.121 2.5-2.5 2.5a2.5 2.5 0 0 1 0-5m0-5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5m0 2c3.79 0 7.169 2.13 8.82 5.5a9.759 9.759 0 0 1-8.82 5.5c-3.8 0-7.17-2.13-8.82-5.5a9.77 9.77 0 0 1 8.82-5.5"
            />
            <path
                stroke="#008FCC"
                strokeLinecap="round"
                strokeWidth="2"
                d="M12 4.68V1M19.14 7.388l2.296-2.296M1.603 4.244l2.629 2.629M12.039 25.196v3.68M4.899 22.487l-2.296 2.296M22.436 25.631l-2.63-2.629"
            />
        </g>
    )
};

const SVG: React.FunctionComponent<IProps> = ({
    name = "",
    style = {},
    fill = "#000",
    width = "25px",
    className = "",
    height = "25px",
    viewBox = "0 0 48 48"
}) => {
    return (
        <svg
            x="0px"
            y="0px"
            width={width}
            style={style}
            height={height}
            viewBox={viewBox}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            {svgs[name]}
        </svg>
    );
};

export default SVG;
