import React, { useEffect, Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { fetchAllEditHistory } from "../../../actions/editHistory";
import { Dispatch } from "redux";
import { getEditHistoryInOriginalOrder } from "../../../reducers/editHistory";
import IAppState from "../../../interfaces/IAppState";
import { CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES } from "../../../constants/ClientConstants";
import moment from "moment";
import ChevronDownIcon from "../../Shared/Icons/ChevronDownIcon";
import TextField from "../../Shared/Form/Blocks/TextField";
import ChevronRightIcon from "../../Shared/Icons/ChevronRightIcon";
import AsyncSelect from "react-select/async";
import { callApi } from "../../../middleware/api";
import buildUrl from "../../../utils/UrlUtils";
import Button from "../../Shared/Button";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { IOption } from "../../../interfaces/IDisplayCampaign";
import LoadingSpinner from "../../Shared/Loaders/LoaderSpinner";
import IUser from "../../../interfaces/IUser";
import IClient from "../../../interfaces/IClient";
import { IDynamicCampaign } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import { IActivityFilters, IActivityLog } from "../../../interfaces/IEditHistory";
import {
    generateDeepLink,
    generateDots,
    getColor,
    getDescription,
    getIcon,
    interlaceElements
} from "../../../utils/EditHistoryUtils";

const LogItem = ({ entity }: { entity: IActivityLog }) => {
    interface IItemProperty {
        field: string;
        oldValue: any;
        newValue: any;
    }
    const [isOpen, setOpenState] = useState(false);
    if (!entity) return null;

    const oldProperties: { [key: string]: string } = entity.properties.old || {};
    const newProperties: { [key: string]: string } = entity.properties?.attributes || {};

    const propertyKeys = Object.keys(oldProperties);
    const properties: IItemProperty[] = [];

    propertyKeys.forEach((propertyName) => {
        properties.push({
            field: propertyName,
            oldValue: oldProperties[propertyName],
            newValue: newProperties[propertyName]
        });
    });

    const relationsName = Object.keys(entity)
        .filter((key) => !["properties"].includes(key))
        .filter((key) => typeof entity[key] === "object")
        .filter((key) => {
            let name = entity?.[key]?.name;

            if (!name && entity?.[key]?.firstName) {
                name = entity?.[key]?.firstName + " " + entity?.[key]?.lastName;
            }
            return name ?? false;
        })
        .map((key) => {
            let name = entity?.[key]?.name;

            if (!name && entity?.[key]?.firstName) {
                name = entity?.[key]?.firstName + " " + entity?.[key]?.lastName;
            }
            return (
                <span key={name + key} className={""}>
                    {name ?? ""}
                </span>
            );
        })
        .concat([<span key={"created_at"}>{moment(entity.createdAt).format("MMMM Do YYYY, h:mm a")}</span>]);

    const dotsArray = generateDots(relationsName);
    const link = generateDeepLink(entity);

    const showDropdownOption = !["created", " deleted", "restored", "deleted"].includes(entity.description) || link;

    return (
        <div className="my-2">
            <div className={`border w-full rounded bg-white`}>
                <div className="flex flex-wrap items-center">
                    <div className={`${getColor(entity)} w-8 ml-2 rounded-full`}>{getIcon(entity)}</div>

                    <div
                        className={`flex flex-1 justify-between p-2 ${link ? "cursor-pointer" : ""}`}
                        onClick={() => setOpenState(!isOpen)}
                    >
                        <div className={`text-blue-800 flex flex-col`}>
                            {getDescription(entity)}
                            <div className="text-gray-600 divide-x divide-dotted space-x-2">
                                {interlaceElements(relationsName, dotsArray)}
                            </div>
                        </div>

                        {showDropdownOption && (
                            <div className="flex justify-end">
                                <div className="flex justify-end items-center">
                                    <ChevronDownIcon
                                        className={`w-6 h-6 text-cerulean-500`}
                                        style={{
                                            transition: ".2s ease",
                                            ...(isOpen ? {} : { transform: "rotate(-90deg)" })
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isOpen && link && <div className="flex flex-col mx-4 underline text-cerulean-700">{link}</div>}
                {showDropdownOption && isOpen && (
                    <div className="px-4 pb-4 w-full">
                        <div className="flex flex-col">
                            {properties.map((item, i) => (
                                <div
                                    key={"item-properties" + i}
                                    className="flex flex-wrap justify-between items-end text-sm"
                                >
                                    <TextField
                                        label={CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES[item.field] ?? item.field}
                                        name={item.field}
                                        value={item.oldValue || "empty"}
                                        disabled={true}
                                        className="flex-grow text-red-500"
                                        inputStyle={{
                                            borderColor: "#f56565",
                                            backgroundColor: "#fff5f5",
                                            textDecoration: item.oldValue ? "line-through" : "none"
                                        }}
                                    />
                                    <div className="w-12 flex items-baseline justify-center mb-3">
                                        <ChevronRightIcon className="w-4" />
                                    </div>
                                    <TextField
                                        label={CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES[item.field] ?? item.field}
                                        name={item.field}
                                        value={item.newValue || "empty"}
                                        disabled={true}
                                        className="flex-grow text-green-600"
                                        inputStyle={{ borderColor: "#48bb78", backgroundColor: "#f0fff4" }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const Index = ({
    fetchEditHistory,
    entities,
    hasMorePages,
    loading,
    totalPages
}: {
    fetchEditHistory: (options: IActivityFilters) => void;
    entities: IActivityLog[];
    hasMorePages: boolean;
    loading: boolean;
    totalPages: number;
}) => {
    const [previousClients, setPreviousClients] = useLocalStorage("previously_viewed_clients", []);
    const [page, setPage] = useState(1);
    const [searchForClient, setSearchForClient] = useState(null as IOption | null);
    const [searchForUser, setSearchForUser] = useState(null as IOption | null);
    const [searchForDynamicCampaign, setSearchForDynamicCampaign] = useState(null as IOption | null);
    const [dynamicCampaigns, setDynamicCampaigns] = useState([] as IOption[]);
    // This any might have to stay....
    const [filters, setFilters] = useState({
        page,
        limit: 100,
        sort: {
            created_at: "desc"
        },
        expand: {
            subject: "*",
            causer: "*"
        },
        filter: {}
    } as IActivityFilters);

    const fetchClients = async (searchValue: string): Promise<IOption[]> => {
        const data = await callApi(
            buildUrl("/clients", {
                search: {
                    name: "like,*" + searchValue + "*"
                },
                limit: 50
            }),
            {}
        );

        return data.result.data.map((page: IClient) => ({
            value: page.id,
            label: page.name
        }));
    };

    const fetchUsers = async (searchValue: string, callOnceDataLoaded: (data: IOption[]) => void) => {
        const data = await callApi(
            buildUrl("/users", {
                search: {
                    email: "like,*" + searchValue + "*"
                },
                limit: 50
            }),
            {}
        );

        callOnceDataLoaded(
            data.result.data.map((user: IUser) => ({
                value: user.id,
                label: [user.firstName, user.lastName].filter((f) => f).join(" ")
            }))
        );
    };
    const fetchDynamicCampaigns = async (searchValue: string): Promise<IOption[]> => {
        if (!searchForClient) {
            return [];
        }
        const data = await callApi(
            buildUrl(`/clients/${searchForClient.value}/dynamic-campaigns`, {
                search: {
                    name: "like,*" + searchValue + "*"
                },
                limit: 50
            }),
            {}
        );

        return data.result.data.map((campaign: IDynamicCampaign) => ({
            value: campaign.id,
            label: campaign.name
        }));
    };

    useEffect(() => {
        fetchEditHistory({
            ...filters,
            page
        });
    }, [filters, page]);

    useEffect(() => {
        const asyncSetDynamicCampaigns = async () => {
            const dynamicCampaigns = await fetchDynamicCampaigns("");
            setDynamicCampaigns(dynamicCampaigns);
        };
        if (searchForClient) {
            asyncSetDynamicCampaigns();
        }
    }, [searchForClient]);

    const subHeaderElements = [
        searchForClient?.label,
        searchForDynamicCampaign?.label,
        searchForUser?.label ? "Caused by " + searchForUser.label : null
    ].filter((k) => k);

    const dotsArray = generateDots(subHeaderElements);

    return (
        <div className={"m-4"}>
            <div className="p-4 rounded-lg bg-white shadow">
                <div className="flex w-full justify-between mb-6 mt-2">
                    <div className="flex flex-col">
                        <div className="text-2xl">Activity Log</div>
                        <div className="text-gray-600 divide-x divide-dotted flex gap-2">
                            This is activity tracked throughout FUEL, due to how activity is tracked we can't always tie
                            edits to their client.
                        </div>
                        <div className="text-gray-600 divide-x divide-dotted flex gap-2">
                            {interlaceElements(subHeaderElements, dotsArray)}
                        </div>
                    </div>
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="rounded-full flex items-center text-cerulean-700 hover:text-cerulean-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                                <span className="sr-only">Open options</span>
                                <svg
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className={"ml-1 tracking-wider font-bold"}>Filters</span>
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                style={{ width: "300px" }}
                                className="border border-gray-100 min-w-64 z-20 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                                <div className="p-2">
                                    <div className="w-full">
                                        <div className={"-mb-4"}></div>
                                        <div className={"flex flex-col gap-2 mt-4"}>
                                            <div className="text-gray-800">Client</div>
                                            <AsyncSelect
                                                autoFocus
                                                className="flex-1"
                                                name="subject"
                                                value={searchForClient ?? null}
                                                loadOptions={fetchClients}
                                                onChange={(item: any) => {
                                                    const value = item?.value ?? null;
                                                    setFilters({
                                                        ...filters,
                                                        filter: {
                                                            ...(value
                                                                ? {
                                                                      ...filters.filter,
                                                                      subject_type: "App\\Models\\Client\\Client",
                                                                      subject_id: value
                                                                  }
                                                                : {})
                                                        }
                                                    });
                                                    setSearchForClient(item);
                                                    setSearchForDynamicCampaign(null);
                                                    setPage(1);
                                                }}
                                                isClearable={true}
                                                noOptionsMessage={() => "Search for a client by name"}
                                                placeholder={"Search for a client by name"}
                                                defaultOptions={previousClients.map((client: IClient) => ({
                                                    label: client.name,
                                                    value: client.id
                                                }))}
                                            />
                                        </div>

                                        {searchForClient && (
                                            <div className={"flex flex-col gap-2 mt-4"}>
                                                <div className="text-gray-800">Dynamic Campaigns</div>
                                                <AsyncSelect
                                                    className="flex-1"
                                                    name="subject"
                                                    value={searchForDynamicCampaign ?? null}
                                                    loadOptions={fetchDynamicCampaigns}
                                                    onChange={(item: any) => {
                                                        const value = item?.value ?? null;
                                                        setFilters({
                                                            ...filters,
                                                            filter: {
                                                                ...(value
                                                                    ? {
                                                                          ...filters.filter,
                                                                          subject_type:
                                                                              "App\\Models\\Client\\DynamicCampaign\\DynamicCampaign",
                                                                          subject_id: value
                                                                      }
                                                                    : {})
                                                            }
                                                        });
                                                        setSearchForDynamicCampaign(item);
                                                        setPage(1);
                                                    }}
                                                    isClearable={true}
                                                    noOptionsMessage={() => "Search Dynamic campaigns"}
                                                    placeholder={"Search for a dynamic campaign"}
                                                    defaultOptions={dynamicCampaigns}
                                                />
                                            </div>
                                        )}

                                        <div className={"flex flex-col gap-2 mt-4"}>
                                            <div className="text-gray-800">Users who caused activity</div>
                                            <AsyncSelect
                                                className="flex-1"
                                                name="causer"
                                                value={searchForUser ?? null}
                                                loadOptions={(
                                                    searchValue: string,
                                                    populateData: (data: IOption[]) => void
                                                ) => {
                                                    fetchUsers(searchValue, populateData);
                                                    setPage(1);
                                                }}
                                                onChange={(item: any) => {
                                                    const value = item?.value ?? null;
                                                    setFilters({
                                                        ...filters,
                                                        filter: {
                                                            ...(value
                                                                ? {
                                                                      ...filters.filter,
                                                                      causer_type: "App\\Models\\User",
                                                                      causer_id: value
                                                                  }
                                                                : {})
                                                        }
                                                    });
                                                    setSearchForUser(item);
                                                    setPage(1);
                                                }}
                                                isClearable={true}
                                                noOptionsMessage={() => "Search for a user by email"}
                                                placeholder={"Search for a user by email"}
                                            />
                                        </div>

                                        <div className={"mt-4"}>
                                            <Button
                                                onClick={() => {
                                                    setPage(1);
                                                    fetchEditHistory({
                                                        ...filters,
                                                        page
                                                    });
                                                }}
                                                styleType={"primary"}
                                                type={"button"}
                                            >
                                                Apply Filters
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>

                {loading && (
                    <div className="w-full h-48 flex items-center justify-center">
                        <LoadingSpinner message="Loading Activity Log" />
                    </div>
                )}

                {!loading &&
                    entities.map((entity: IActivityLog) => <LogItem key={`${entity.id}-entity-log`} entity={entity} />)}

                {!hasMorePages && !loading && (
                    <div className="my-4 flex items-center justify-center w-full italic">No more activity...</div>
                )}
                <div className="flex flex-col items-center justify-center w-full mt-4">
                    <div className="flex flex-wrap gap-4 items-center">
                        <Button
                            onClick={() => setPage(page - 1)}
                            styleType={"primary"}
                            type={"button"}
                            styles={"text-sm"}
                            disabled={page - 1 < 1}
                        >
                            Previous page {page - 1 > 0 ? page - 1 : ""}
                        </Button>

                        <span className="text-sm ">
                            Page {page} / {totalPages}
                        </span>

                        <Button
                            onClick={() => setPage(page + 1)}
                            styleType={"primary"}
                            type={"button"}
                            styles={"text-sm"}
                            disabled={page == totalPages}
                        >
                            Next page {page == totalPages ? "" : page + 1}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    order: state.editHistory.order,
    entities: getEditHistoryInOriginalOrder(state) as IActivityLog[],
    loading: state.editHistory.loading,
    page: state.editHistory.page,
    totalPages: Math.ceil(state.editHistory.total / 100),
    hasMorePages: state.editHistory.page < Math.ceil(state.editHistory.total / 100)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchEditHistory(options: any) {
        dispatch(fetchAllEditHistory(options));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
