import { doDeleteRequest } from "../../middleware/api";

export const CREATE_TEMPLATE_PART_CONDITIONAL = "CREATE_TEMPLATE_PART_CONDITIONAL";
export const SAVE_TEMPLATE_PART_CONDITIONAL = "SAVE_TEMPLATE_PART_CONDITIONAL";
export const REMOVE_TEMPLATE_PART_CONDITIONAL = "REMOVE_TEMPLATE_PART_CONDITIONAL";

export const saveTemplatePartConditional = (conditional: any) => {
    return {
        type: SAVE_TEMPLATE_PART_CONDITIONAL,
        response: {
            entities: {
                dtaTemplatePartConditionals: {
                    [conditional.id]: conditional
                }
            }
        }
    };
};

export const deleteTemplatePartConditional = (clientId: number, conditional: any, dispatch: any) => {
    return doDeleteRequest(`/clients/${clientId}/dta-template-part-conditionals/${conditional.id}`, (resp: any) => {
        dispatch({ type: REMOVE_TEMPLATE_PART_CONDITIONAL, conditional });
    });
};
