import * as React from "react";
import IEntity from "../../../../../interfaces/IEntity";
import { INegativeKeywordColl } from "../../../../../interfaces/NegativeKeywordColl";
import NegativeKeywordsCampaignField from "../../Fields/NegativeKeywordsCampaignField";
import { connect } from "react-redux";
import IAppState from "../../../../../interfaces/IAppState";

interface IProps {
    values: number[];
    handleChangeField(values: number[]): void;
    name: string;
    subtitle?: string;
    negativeKeywordCollections: IEntity<INegativeKeywordColl>;
}

const NegativeKeywordsCampaignReviewSection = ({
    values,
    handleChangeField,
    name,
    subtitle,
    negativeKeywordCollections
}: IProps) => {
    return (
        <div className="border-t border-gray-300 pt-6 my-8">
            <NegativeKeywordsCampaignField
                negativeKeywordCollections={negativeKeywordCollections}
                name={name}
                subtitle={subtitle}
                handleChangeField={handleChangeField}
                values={values}
            />
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    negativeKeywordCollections: state.negativeKeywordColls.entities
});

export default connect(mapStateToProps)(NegativeKeywordsCampaignReviewSection);
