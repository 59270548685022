import React from "react";
import { connect } from "react-redux";
import { restoreClient } from "../../../../actions/clientActions";
import IClient from "../../../../interfaces/IClient";
import IAppState from "../../../../interfaces/IAppState";
import HistoryIcon from "../../../Shared/Icons/HistoryIcon";
import TableActionButton from "../../../Shared/Table/TableActionButton";
import IUser from "../../../../interfaces/IUser";
import { isSupervisor } from "../../../../reducers/currentUser";

interface IOwnProps {
    original: IClient;
    page: number;
    pageSize: number;
}

interface IClientDeletedActionCell extends IOwnProps {
    restoreClient(client: IClient, page: number, pageSize: number, insight: string, search: string): void;
    insight: string;
    search: string;
    currentUser: IUser;
}

const ClientDeletedActionCell: React.FunctionComponent<IClientDeletedActionCell> = ({
    original,
    restoreClient,
    page,
    pageSize,
    insight,
    search,
    currentUser
}) => {
    if (!isSupervisor(currentUser)) {
        return <></>;
    }

    return (
        <TableActionButton
            onClick={() => restoreClient(original, page + 1, pageSize, insight, search)}
            Icon={HistoryIcon}
            data-testid="client-restore-button"
            title="Restore client"
            className="text-gray-500"
        />
    );
};

const mapStateToProps = (state: IAppState, { original }: IOwnProps) => {
    return {
        insight: state.clients.paginatedClients.insight,
        search: state.clients.paginatedClients.search,
        currentUser: state.currentUser
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        restoreClient: (client: IClient, page: number, pageSize: number, insight: string, search: string) => {
            dispatch(restoreClient({ client, page, pageSize, insight, search }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDeletedActionCell);
