import React, { Component } from "react";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import moment from "moment";
import DownloadButton from "./DownloadButton";
const DATE_FORMAT = "YYYY-MM-DD";

class Index extends Component {
    state = {
        startDate: moment().subtract(1, "months").startOf("month").format(DATE_FORMAT),
        endDate: moment().subtract(1, "months").endOf("month").format(DATE_FORMAT)
    };

    getUrlForReport() {
        return `/reports/facebook-performance?filter[start_date]=${this.state.startDate}&filter[end_date]=${this.state.endDate}`;
    }

    render() {
        return (
            <div className="mx-4">
                <PageTitle title="Facebook Report" />
                <div>
                    <div className="mb-4">
                        For Dates {this.state.startDate} - {this.state.endDate}
                    </div>
                    <DownloadButton
                        reportUrl={this.getUrlForReport()}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                </div>
            </div>
        );
    }
}

export default Index;
