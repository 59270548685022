import React, { Fragment } from "react";
import ManualBudgetActivationPreview from "./ManualBudgetActivationPreview";
import { IManualBudgetActivation } from "./ManualBudgetActivations";

interface IProps {
    manualBudgetActivation: IManualBudgetActivation;
}

const ManualBudgetActivation: React.FunctionComponent<IProps> = ({ manualBudgetActivation }: any) => {
    return (
        <Fragment>
            <ManualBudgetActivationPreview manualBudgetActivation={manualBudgetActivation} />
        </Fragment>
    );
};

export default ManualBudgetActivation;
