const getCsvHeaders = (fileInput: any): Promise<Array<string>> => {
    return new Promise((resolve, reject) => {
        const file = fileInput.files[0];
        if (!file) {
            return reject();
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onerror = reject;
        reader.onloadend = (evt: any) => {
            const data: ArrayBuffer = evt.target.result;
            const byteLength = data.byteLength;
            const ui8a = new Uint8Array(data, 0);
            let byteCount = 0;

            // The elements in the array buffer correspond to the number of bytes of memory that could be consumed. IE 1 element in the array = 1 byte.
            // To be performant (especially with larger files) we only care about the bytes for the headers
            for (let i = 0; i < byteLength; i++) {
                if (String.fromCharCode(ui8a[i]).match(/[^\r\n]+/g) === null) {
                    break;
                }

                byteCount++;
            }

            // If we cut the array off to limit the number of bytes we can save loads of memory and cpu cycles for performance (it also prevents the page from crashing)
            let headerBytes = data.slice(0, byteCount);
            // Then we have to convert the array buffer of the header's information to a UTF-8 string.
            // And yes, it has to be UTF-8 to prevent other encoding issues with various formats.
            let headerstring = new TextDecoder("UTF-8").decode(headerBytes);

            resolve(
                headerstring
                    // Split using regex to support CSVs and TSVs
                    .split(/\,|\t/g)
                    // This is regex is to remove extra " on the column names.
                    .map((i: string) => i.trim().replace(/((^\")|(\"$))/g, ""))
            );
        };
    });
};

export { getCsvHeaders };
