import React from "react";
import numeral from "numeral";
import { Card, CardTitle } from "./BudgetDetails";
import BudgetConstants from "../../../../constants/budgets/BudgetConstants";
import { getStatusDetails } from "../../../Shared/Table/TableUtils";
import { IBudgetExpandedClient } from "../../../../interfaces/Budgets/IBudgetApi";

const InfoRow = "flex justify-between py-4";

interface IProps {
    campaigns: any;
    budget: IBudgetExpandedClient;
}

const BudgetInfo: React.FunctionComponent<IProps> = ({ campaigns, budget }) => {
    const { client, activation, statusId, lastUpdateAction } = budget;

    const { name: clientName, clientServicesPod } = client || {};
    const { label, targetSpend, typeId } = activation || {};
    const { type, title } = getStatusDetails(statusId);

    return (
        <div className={Card}>
            <h2 className={CardTitle}>{clientName}</h2>

            <div className={`text-gray-800 font-bold pb-6`}>
                {Number(typeId) === BudgetConstants.TYPE_ID_MONTHLY ? "Monthly" : "Event"} Budget
            </div>

            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Status</div>
                <div className={`text-gray-800 font-bold`}>
                    <span className={`budget-status ${type}`} />
                    <span>{title}</span>
                </div>
            </div>

            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Monthly Spends</div>
                <div className={`text-gray-600r font-bold`}>${numeral(targetSpend).format("0,0.00")}</div>
            </div>

            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Label</div>
                <div className={`text-gray-800 font-bold`}>{label}</div>
            </div>
            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Client Services Pod</div>
                <div className={`text-gray-800 font-bold`}>{clientServicesPod}</div>
            </div>
            <div className={InfoRow}>
                <div className={`font-size-sm uppercase font-bold text-gray-600`}>Campaigns</div>
                <div className="flex-col">
                    {Object.keys(campaigns).length > 0 &&
                        lastUpdateAction &&
                        lastUpdateAction.campaignLogs.map(({ campaignId }: any) => {
                            return (
                                <div key={campaignId}>
                                    <div className={`text-gray-800 font-bold`}>
                                        {campaigns[campaignId] ? campaigns[campaignId].name : campaignId}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default BudgetInfo;
