export const REQUEST_LOAD_INVENTORY_EXPORT = "REQUEST_LOAD_INVENTORY_EXPORT";
export const LOAD_INVENTORY_EXPORT = "LOAD_INVENTORY_EXPORT";
export const REQUEST_INVENTORY_EXPORT_FAILURE = "REQUEST_INVENTORY_EXPORT_FAILURE";

export const REQUEST_LOAD_INVENTORY_EXPORTS = "REQUEST_LOAD_INVENTORY_EXPORTS";
export const LOAD_INVENTORY_EXPORTS = "LOAD_INVENTORY_EXPORTS ";
export const REQUEST_INVENTORY_EXPORTS_FAILURE = "REQUEST_INVENTORY_EXPORTS_FAILURE";

export const REQUEST_CREATE_INVENTORY_EXPORT = "REQUEST_CREATE_INVENTORY_EXPORT";
export const REQUEST_UPDATE_INVENTORY_EXPORT = "REQUEST_UPDATE_INVENTORY_EXPORT";

export const UPDATE_INVENTORY_EXPORT_SUCCESS = "UPDATE_INVENTORY_EXPORT_SUCCESS";
export const UPDATE_INVENTORY_EXPORT_FAIL = "UPDATE_INVENTORY_EXPORT_FAIL";

export const CREATE_INVENTORY_EXPORT_FAIL = "CREATE_INVENTORY_EXPORT_FAIL";
export const CREATE_INVENTORY_EXPORT_SUCCESS = "CREATE_INVENTORY_EXPORT_SUCCESS";

export const REQUEST_REMOVE_INVENTORY_EXPORT = "REQUEST_REMOVE_INVENTORY_EXPORT";
export const REMOVE_INVENTORY_EXPORT = "REMOVE_INVENTORY_EXPORT";

export const REQUEST_CREATE_FACEBOOK_EXPORT = "REQUEST_CREATE_FACEBOOK_EXPORT";
export const CREATE_FACEBOOK_EXPORT_SUCCESS = "CREATE_FACEBOOK_EXPORT_SUCCESS";
export const CREATE_FACEBOOK_EXPORT_FAIL = "CREATE_FACEBOOK_EXPORT_FAIL";

export const REQUEST_UPDATE_FACEBOOK_EXPORT = "REQUEST_UPDATE_FACEBOOK_EXPORT";
export const UPDATE_FACEBOOK_EXPORT_SUCCESS = "UPDATE_FACEBOOK_EXPORT_SUCCESS";
export const UPDATE_FACEBOOK_EXPORT_FAIL = "UPDATE_FACEBOOK_EXPORT_FAIL";

export const REQUEST_DELETE_FACEBOOK_EXPORT = "REQUEST_DELETE_FACEBOOK_EXPORT";
export const DELETE_FACEBOOK_EXPORT_SUCCESS = "DELETE_FACEBOOK_EXPORT_SUCCESS";
export const DELETE_FACEBOOK_EXPORT_FAIL = "DELETE_FACEBOOK_EXPORT_FAIL";

export const REQUEST_GOOGLE_AUTO_EXPORTS = "REQUEST_GOOGLE_AUTO_EXPORTS";
export const REQUEST_GOOGLE_AUTO_EXPORTS_SUCCESS = "REQUEST_GOOGLE_AUTO_EXPORTS_SUCCESS";
export const REQUEST_GOOGLE_AUTO_EXPORTS_FAIL = "REQUEST_GOOGLE_AUTO_EXPORTS_FAIL";

export const REQUEST_CREATE_GOOGLE_EXPORT = "REQUEST_CREATE_GOOGLE_EXPORT";
export const CREATE_GOOGLE_EXPORT_SUCCESS = "CREATE_GOOGLE_EXPORT_SUCCESS";
export const CREATE_GOOGLE_EXPORT_FAIL = "CREATE_GOOGLE_EXPORT_FAIL";

export const REQUEST_UPDATE_GOOGLE_EXPORT = "REQUEST_UPDATE_GOOGLE_EXPORT";
export const UPDATE_GOOGLE_EXPORT_SUCCESS = "UPDATE_GOOGLE_EXPORT_SUCCESS";
export const UPDATE_GOOGLE_EXPORT_FAIL = "UPDATE_GOOGLE_EXPORT_FAIL";

export const REQUEST_DELETE_GOOGLE_EXPORT = "REQUEST_DELETE_GOOGLE_EXPORT";
export const DELETE_GOOGLE_EXPORT_SUCCESS = "DELETE_GOOGLE_EXPORT_SUCCESS";
export const DELETE_GOOGLE_EXPORT_FAIL = "DELETE_GOOGLE_EXPORT_FAIL";

export const SAVING_COMPLETE = "SAVING_COMPLETE";

export const FETCH_FACEBOOK_LOGS = "FETCH_FACEBOOK_LOGS";
export const FETCH_FACEBOOK_LOGS_SUCCESS = "FETCH_FACEBOOK_LOGS_SUCCESS";
export const FETCH_FACEBOOK_LOGS_FAILURE = "FETCH_FACEBOOK_LOGS_FAILURE";
export const FACEBOOK_LOGS_URL = (clientId: number, exportId: number) => {
    return `/clients/${clientId}/fb-auto-remarketing-exports/${exportId}/logs`;
};

export const FETCH_GOOGLE_LOGS = "FETCH_GOOGLE_LOGS";
export const FETCH_GOOGLE_LOGS_SUCCESS = "FETCH_GOOGLE_LOGS_SUCCESS";
export const FETCH_GOOGLE_LOGS_FAILURE = "FETCH_GOOGLE_LOGS_FAILURE";
export const GOOGLE_LOGS_URL = (clientId: number, exportId: number) => {
    return `/clients/${clientId}/ad-words-remarketing-exports/${exportId}/logs`;
};
