import React from "react";
import { connect } from "react-redux";
import IClient from "../../../../interfaces/IClient";
import { deletePagedClient } from "../../../../actions/clientActions";
import IAppState from "../../../../interfaces/IAppState";
import PencilIcon from "../../../Shared/Icons/PencilIcon";
import TableActionDeleteButton from "../../../Shared/Table/TableActionDeleteButton";
import TableActionLink from "../../../Shared/Table/TableActionLink";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import InformationIcon from "../../../Shared/Icons/InformationIcon";
import {
    FACEBOOK_PERMISSION,
    GOOGLE_ADS_PERMISSION,
    GOOGLE_ANALYTICS_PERMISSION,
    MICROSOFT_PERMISSION
} from "../../../../constants/ClientConstants";

interface IOwnProps {
    original: IClient;
}

interface IProps extends IOwnProps {
    insight: string;
}

const ClientPermissionCell: React.FunctionComponent<IProps> = ({ original, insight }) => {
    const { id, deletedAt } = original;

    if (deletedAt) {
        return null;
    }

    return (
        <div className="justify-between flex text-gray-500">
            <TableActionLink to={`${id}`} Icon={PencilIcon} />
            <div className="justify-between flex text-gray-500">
                <button className="relative ml-4 bg-transparent text-lg" title="Error Messages">
                    <ModalToggle
                        style={{ width: "50%", height: "510px" }}
                        toggleButton={(open) => (
                            <InformationIcon
                                data-testid={`open-button`}
                                onClick={open}
                                className="w-6 h-60 text-gray-500 hover:text-blue-400 cursor-pointer mx-auto"
                            />
                        )}
                        modalContent={(hide) => (
                            <div>
                                <div>FUEL Encountered a permission based error for {original.name}.</div>
                                <div>Please verify that this information is correct</div>

                                {insight === FACEBOOK_PERMISSION && (
                                    <div className="border-l-4 border-blue-300 pl-4 my-4">
                                        <div>Facebook Account ID: {original.facebookAccount?.accountId}</div>
                                        <div>Facebook Name: {original.facebookAccount?.name}</div>
                                    </div>
                                )}

                                {insight === MICROSOFT_PERMISSION && (
                                    <div className="border-l-4 border-blue-300 pl-4 my-4">
                                        <div>Bing ID: {original.bingId}</div>
                                        <div>Bing Name: {original.bingName}</div>
                                        <div>Bing Number: {original.bingNumber}</div>
                                    </div>
                                )}

                                {insight === GOOGLE_ADS_PERMISSION && (
                                    <div className="border-l-4 border-blue-300 pl-4 my-4">
                                        <div>CID: {original.adwordsId}</div>
                                        <div>Name: {original.adwordsName}</div>
                                    </div>
                                )}

                                {insight === GOOGLE_ANALYTICS_PERMISSION && (
                                    <div className="border-l-4 border-blue-300 pl-4 my-4">
                                        <div>Analytics Account ID: {original.gaAccountId}</div>
                                        <div>Analytics Account Name: {original.gaAccountName}</div>
                                        <div>Analytics Profile ID: {original.gaProfileId}</div>
                                        <div>Analytics Profile Name: {original.gaProfileName}</div>
                                    </div>
                                )}

                                <div>If all of this information looks correct, please contact #help-fuel-media 🛠️</div>
                            </div>
                        )}
                    />
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IAppState, { original }: IOwnProps) => {
    return {
        insight: state.clients.paginatedClients.insight,
        search: state.clients.paginatedClients.search
    };
};

const mapDispatchToProps = {
    deleteClient: deletePagedClient
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPermissionCell);
