import React from "react";
import DealerSetupReviewSection from "../DealerSetupReviewSection";
import { usePager } from "../DealerSetupUtils";
import DealerSetupButton from "../DealerSetupButton";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";

interface IProps {
    path: string;
    formValues: IFormValues;
    clientId: number;
    message: any;
    saveForm: any;
    processing: boolean;
    success: boolean;
}

const Review: React.FC<IProps> = ({ formValues, clientId, message, processing, success, saveForm, path }) => {
    const pager = usePager(path, formValues);

    const isValid = Object.keys(formValues)
        .filter((page) => !["review", "negative-keywords", "dealer"].includes(page))
        .every((page) => {
            return formValues[page].isDisabled || (formValues[page].isTouched && formValues[page].isValid);
        });

    return (
        <div className="p-4">
            <div className="text-xl font-bold pb-3 mb-10">Setup Review</div>

            <DealerSetupReviewSection formValues={formValues} title="Pre-Screen" path="pre-screen" />
            <DealerSetupReviewSection formValues={formValues} title="Dealer Info" path="dealer-info" />
            <DealerSetupReviewSection formValues={formValues} title="Name" path="name" />
            <DealerSetupReviewSection formValues={formValues} title="Locations" path="locations" />
            <DealerSetupReviewSection formValues={formValues} title="Competitors" path="competitors" />
            <DealerSetupReviewSection formValues={formValues} title="Services" path="services" />
            <DealerSetupReviewSection formValues={formValues} title="Dynamic" path="dynamic" />
            <DealerSetupReviewSection formValues={formValues} title="Generic Campaigns" path="generic" />
            <DealerSetupReviewSection formValues={formValues} title="Remarketing Campaigns" path="remarketing" />
            <DealerSetupReviewSection formValues={formValues} title="Ad Extensions" path="ad-extensions" />
            <DealerSetupReviewSection formValues={formValues} title="Negative Keywords" path="negative-keywords" />

            {!success ? (
                pager(
                    null,
                    <DealerSetupButton
                        label={message.type === "SUCCESS" ? message.value : "Build Campaigns"}
                        color="green"
                        disabled={!isValid || processing}
                        processing={processing}
                        onClick={() => saveForm(clientId)}
                    />,
                    message && message.type === "ERROR" ? (
                        <div className="inline-block ml-4 text-red-600">{message.value}</div>
                    ) : null
                )
            ) : (
                <div className="border-t py-4">
                    <div className="text-lg text-green-600">{message.value}</div>
                </div>
            )}
        </div>
    );
};

export default Review;
