import { useState, useCallback } from "react";

const useToggle = (initialState: boolean = false): [boolean, any] => {
    // Initialize the state
    const [state, setState] = useState<boolean>(initialState);
    // Define and memorize toggler function in case we pass down the comopnent,
    // This function change the boolean value to it's opposite value
    const toggle = useCallback((value?: any): void => {
        if (value !== undefined) {
            setState(value);
        } else {
            setState((state) => !state);
        }
    }, []);
    return [state, toggle];
};

export default useToggle;
