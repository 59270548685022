import { schema } from "normalizr";
import _ from "lodash";
import { decamelizeKeys } from "humps";
import ADWORDS from "./client/adwords";
import MICROSOFT from "./client/microsoft";
import REMARKETING from "./remarketing";
import BUDGETS from "./budgets";
import MANUAL_BUDGETS from "./manualBudgets";
import CARS_BUDGETS from "./carsBudgets";
import DYNAMIC_CAMPAIGN from "./client/dynamicCampaign";
import KEYWORD from "./client/keyword";
import INVENTORY_MODIFIERS from "./client/inventoryModifier";
import IClient from "../../interfaces/IClient";

const clientRole = new schema.Entity("clientRoles", {}, { idAttribute: "name" });
const clientRoleArray = { data: [clientRole] };

const negativeKeywordColls = new schema.Entity("negativeKeywordColls");
const negativeKeywordCollCampaigns = new schema.Entity("negativeKeywordCollCampaigns");

const NEGATIVE_KEYWORD = {
    NEGATIVE_KEYWORD_COLLS_ARRAY: { data: [negativeKeywordColls] },
    NEGATIVE_KEYWORD_COLL_CAMPAIGN_ARRAY: { data: [negativeKeywordCollCampaigns] }
};

const client = new schema.Entity(
    "clients",
    { roles: [clientRole] },
    {
        processStrategy: (client) => {
            if (client.crmEmail === null) {
                client.crmEmail = [];
            }
            if (client.manager) {
                const { firstName, lastName } = client.manager;
                client.managedByUser = `${firstName ? firstName : ""} ${lastName ? lastName : ""}`;
            }
            return { ...client, saving: false, errors: {} };
        }
    }
);
const clientArray = { data: [client] };

const sanatizeObject = (
    client: IClient,
    changedFields: string | string[] | undefined = undefined,
    roles: { [x: string]: any } = {}
) => {
    const readOnly = [
        "errors",
        "saving",
        "updatedAt",
        "createdAt",
        "id",
        "timeZone",
        "timeZoneOffset",
        "lft",
        "rgt",
        "dealerAddress1",
        "dealerAddress2",
        "dealerCity",
        "dealerState",
        "dealerZip",
        "dealerPhone",
        "dealerName",
        "facebookAccount",
        "facebookPage"
    ];

    const body: { [x: string]: any } = {};

    _.forOwn(client, (value, key) => {
        if ((changedFields === undefined || changedFields.indexOf(key) !== -1) && readOnly.indexOf(key) === -1) {
            // string values get trimmed and converted to null if empty
            if (_.isString(value)) {
                value = value.trim();
                if (value === "") {
                    value = null;
                }
            }
            body[key] = value;
        }
    });

    // map this back to the role id
    if (body.hasOwnProperty("roles")) {
        body.roles = body.roles.map((role: string | number) => roles[role].id);
    }

    // we need to ensure its format like this 2020-01-11 05:00:00
    if (body.cancelAt && changedFields?.includes("cancelAt")) {
        body.cancelAt = body.cancelAt.replace("T", " ");
    }

    return decamelizeKeys(body);
};

export { client, clientArray, clientRoleArray, sanatizeObject };

export default {
    CLIENT: client,
    CLIENT_ARRAY: clientArray,
    CLIENT_ROLE_ARRAY: clientRoleArray,
    ADWORDS,
    MICROSOFT,
    REMARKETING,
    BUDGETS,
    MANUAL_BUDGETS,
    CARS_BUDGETS,
    DYNAMIC_CAMPAIGN,
    INVENTORY_MODIFIERS,
    KEYWORD,
    NEGATIVE_KEYWORD
};
