import React, { SVGProps } from "react";
import Svg from "./Svg";

const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M20.409 15.816l-.83.842a.521.521 0 0 1-.383.168.52.52 0 0 1-.382-.168l-6.527-6.62-6.527 6.62a.521.521 0 0 1-.382.168.52.52 0 0 1-.382-.168l-.83-.843a.536.536 0 0 1 0-.774l7.74-7.85a.522.522 0 0 1 .381-.168.52.52 0 0 1 .382.168l7.74 7.85a.536.536 0 0 1 0 .775z" />
    </Svg>
);

export default ChevronUpIcon;
