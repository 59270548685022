import React, { useRef, useState, MouseEvent } from "react";
import TextField from "../../../../Shared/Form/Blocks/TextField";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import { getDcParameterFieldsForKeywords } from "../../../../../utils/InventoryUtils";
import CloseIcon from "../../../../Shared/Icons/CloseIcon";
import ChevronDownIcon from "../../../../Shared/Icons/ChevronDownIcon";
import TrashIcon from "../../../../Shared/Icons/TrashIcon";

interface IProps {
    value: string;
    index: number;
    onWordInputChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    onRemoveInput: (index: number) => void;
    onSelectParam: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
}

const dcPramFields = getDcParameterFieldsForKeywords();

const renderParamOptions = (onClick: (e: MouseEvent<HTMLButtonElement>) => void) => {
    return dcPramFields.map((field, i) => {
        return (
            <button
                key={field}
                role="button"
                className="block px-4 py-2 hover:bg-select-focus cursor-pointer text-base w-full text-left"
                onClick={onClick}
                tabIndex={i}
            >
                {field}
            </button>
        );
    });
};

const GeneratorInput: React.FunctionComponent<IProps> = ({
    value,
    onWordInputChange,
    index,
    onRemoveInput,
    onSelectParam
}) => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    useOnClickOutside(ref, () => setOpen(false));

    const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onSelectParam(event, index);
        setOpen(!open);
    };

    return (
        <div className="flex" aria-live="polite" data-testid="generatorInput">
            <button
                onClick={() => setOpen(!open)}
                type="button"
                data-testid="parameter-dropdown"
                className={`px-4 py-2 mt-4 mb-2 border-t border-l border-b border-gray-400 text-gray-500 hover:bg-gray-200 rounded-l`}
            >
                <ChevronDownIcon className="w-6 h-6" />
            </button>
            {open && (
                <div
                    className="absolute py-2 w-1/6 mt-16 bg-white rounded shadow-md z-10 overflow-auto border"
                    style={{ maxHeight: "300px" }}
                >
                    {renderParamOptions(handleSelectClick)}
                </div>
            )}
            <TextField
                autoFocus
                name="keywordWord"
                value={value}
                className="flex-1 rounded-r"
                inputStyle={{ borderRadius: "0" }}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => onWordInputChange(event, index)}
            />
            <button
                data-testid={`remove-button`}
                onClick={() => onRemoveInput(index)}
                type="button"
                className={`px-4 text-gray-500 hover:text-red-600 hover:bg-gray-200`}
                title="Remove"
            >
                <TrashIcon className="w-6 h-6" />
            </button>
        </div>
    );
};

export default GeneratorInput;
