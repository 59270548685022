import React from "react";

interface IProps {
    name?: string;
    value: string | undefined;
    type?: string;
    ariaLabel?: string;
    onChange?: any;
    onKeyDown?: any;
    disabled?: boolean;
    onBlur?: any;
    placeholder?: string;
    className?: string;
    readOnly?: boolean;
    isValid?: boolean;
    inputStyle?: { [key: string]: string };
    autoFocus?: boolean;
}

const defaultClasses = `w-full px-2 py-2 text-base border border-gray-500 rounded`;
const errorClasses = `border-red`;
const readOnlyClasses = `text-gray-800 bg-gray-400`;

const TextInput: React.FunctionComponent<IProps> = ({
    name,
    value,
    ariaLabel,
    onChange,
    onBlur,
    onKeyDown,
    disabled,
    placeholder,
    className,
    inputStyle,
    readOnly = false,
    isValid = true,
    type = "text",
    autoFocus = false
}) => {
    const defaultClassName = `${defaultClasses} ${readOnly && readOnlyClasses} ${!isValid && errorClasses}`;

    return (
        <input
            name={name}
            aria-label={ariaLabel ? ariaLabel : ""}
            data-testid={name}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            type={type}
            className={`${defaultClassName} ${className}`}
            placeholder={placeholder}
            readOnly={readOnly}
            style={inputStyle}
            autoFocus={autoFocus}
        />
    );
};

export default TextInput;
