import React from "react";

export const PRIMARY_BUTTON = "primary";
export const SECONDARY_BUTTON = "secondary";
export const NEGATIVE_BUTTON = "negative";
export const DISABLED_BUTTON = "disabled";
export const DANGER_BUTTON = "danger";
export const SUCCESS_BUTTON = "success";
export const SECONDARY_DANGER_BUTTON = "secondaryDanger";

type ButtonType =
    | typeof PRIMARY_BUTTON
    | typeof SECONDARY_BUTTON
    | typeof DISABLED_BUTTON
    | typeof NEGATIVE_BUTTON
    | typeof DANGER_BUTTON
    | typeof SECONDARY_DANGER_BUTTON
    | typeof SUCCESS_BUTTON;

interface IProps {
    disabled?: boolean;
    styles?: string;
    styleType?: ButtonType;
    type?: "button" | "submit" | "reset";
    onClick?: (event?: any) => void;
    style?: { [key: string]: number | string };
    testId?: string;
    busy?: boolean;
}

export const baseStyles = "rounded px-4 py-2";

export const primaryClasses = "bg-blue-500 hover:bg-blue-600 text-white";
export const secondaryClasses = "bg-white hover:bg-gray-200 text-gray-800 border-2 border-gray";
export const negativeClasses = "bg-gray-100 hover:bg-gray text-gray-800 border-2";
export const dangerClasses = "bg-red-600 hover:bg-red-700 text-white border-2";
export const secondaryDangerClasses =
    "bg-white hover:bg-red-100 text-red-600 hover:text-red-800 border-2 border-red-600 hover:border-red-800";
export const disabledClasses = "opacity-50 cursor-not-allowed";
export const successClasses = "bg-green-500 hover:bg-green-600 text-white";

export const styleOptions = {
    primary: primaryClasses,
    secondary: secondaryClasses,
    negative: negativeClasses,
    disabled: disabledClasses,
    danger: dangerClasses,
    secondaryDanger: secondaryDangerClasses,
    success: successClasses
};
/**
 *
 * @param children The text that the button will display or whatever
 * @param disabled Sets the buttons disabled attribute. default = false
 * @param onClick Click event attached to button.
 * @param styleType Accepts a ButtonType string and sets the styles accordingly default  PRIMARY_BUTTON
 * @param type Accepts standard button types
 * @param styles Accepts tailwind(css) classes to customize
 */
const Button: React.FunctionComponent<IProps> = ({
    children,
    onClick,
    disabled = false,
    styleType,
    type = "button",
    styles,
    style,
    testId
}) => {
    const optionalStyles = styleType ? styleOptions[styleType] : "";
    const customStyles = styles ? styles : "";
    const disabledStyles = disabled ? styleOptions[DISABLED_BUTTON] : "";
    const buttonStyles = `${baseStyles} ${optionalStyles} ${disabledStyles} ${customStyles}`;

    return (
        <button
            data-testid={testId}
            type={type}
            className={buttonStyles}
            disabled={disabled}
            onClick={onClick}
            style={style}
        >
            {children}
        </button>
    );
};

export default Button;
