import React, { Fragment } from "react";
import KeywordBid from "./KeywordBid";
import { FormikErrors, FormikTouched } from "formik";
import IDynamicCampaignFormValue from "../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import { IKeywordBidModifier } from "../../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import Button, { SECONDARY_BUTTON } from "../../../../Shared/Button";
import AddIcon from "../../../../Shared/Icons/AddIcon";

interface IBids {
    dynamicCampaignId?: number;
    keywordBidModifiers: IKeywordBidModifier[];
    errors: FormikErrors<IDynamicCampaignFormValue>;
    touched: FormikTouched<IDynamicCampaignFormValue>;
    setFieldValue(fieldName: string, value: any): void;
    setFieldTouched(fieldName: string, value: boolean): void;
}

const KeywordBids: React.FunctionComponent<IBids> = ({
    dynamicCampaignId,
    keywordBidModifiers = [],
    setFieldValue,
    setFieldTouched,
    touched,
    errors
}) => {
    const baseField = `keywordBidModifiers`;

    const handleFieldChange = (index: number, key: string, value: any) => {
        setFieldValue(key, value);
        setFieldValue(`${baseField}[${index}].dirty`, true);
    };

    const handleRemoveBid = (index: number, bid: IKeywordBidModifier) => {
        setFieldValue(`${baseField}[${index}].deleted`, true);
        setFieldValue(`${baseField}[${index}].dirty`, true);
    };

    const handleAddBid = () => {
        const newBid: IKeywordBidModifier = {
            dynamicCampaignId,
            amount: "" as unknown as number,
            modifier: "",
            comparator: "",
            value: "",
            modifiedBroad: true,
            broad: true,
            phrase: true,
            exact: true,
            deleted: false,
            new: true
        };
        setFieldValue(`${baseField}[${keywordBidModifiers.length}]`, newBid);
    };

    return (
        <Fragment>
            {keywordBidModifiers.map((bid: IKeywordBidModifier, index: number) => (
                <KeywordBid
                    key={index}
                    bid={bid}
                    fieldName={`${baseField}[${index}]`}
                    touched={touched}
                    errors={errors}
                    onRemoveBid={handleRemoveBid}
                    handleBlur={(field: string) => setFieldTouched(field, true)}
                    onFieldChange={(key: string, value: any) => handleFieldChange(index, key, value)}
                    index={index}
                />
            ))}
            <div className="flex justify-start">
                <Button onClick={handleAddBid} type="button" styleType={SECONDARY_BUTTON} styles="my-4">
                    <div className="flex items-center">
                        <AddIcon className="w-6 h-6" />
                        <span className="ml-2">Add Keyword Bid</span>
                    </div>
                </Button>
            </div>
        </Fragment>
    );
};

export default KeywordBids;
