import { combineReducers, AnyAction } from "redux";
import DynamicCampaignConstants from "../constants/DynamicCampaignConstants";
import { IDynamicCampaign } from "../interfaces/DynamicCampaigns/IDynamicCampaign";
import moment from "moment";
import IEntity from "../interfaces/IEntity";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

const savingReducer = (state: number[] = [], action: AnyAction) => {
    switch (action.type) {
        case DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN: {
            const id = action.payload.dynamicCampaign.id;
            const idx = state.indexOf(id);
            if (idx === -1) {
                return [...state, id];
            }

            return state;
        }
        case DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN_SUCCESS: {
            const id = action.payload.dynamicCampaign.id;
            const idx = state.indexOf(id);
            if (idx === -1) {
                return state;
            }
            return [...state.slice(0, idx), ...state.slice(idx + 1)];
        }
        case DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN_FAIL: {
            const idx = state.indexOf(action.payload.id);
            if (idx === -1) {
                return state;
            }
            return [...state.slice(0, idx), ...state.slice(idx + 1)];
        }
        case RESET_CLIENT_STATE:
            return [];
        default:
            return state;
    }
};

interface IEntitiesState {
    [key: string]: IDynamicCampaign;
}

const entitiesReducer = (state: IEntitiesState = {}, action: AnyAction) => {
    switch (action.type) {
        case DynamicCampaignConstants.LOAD_DYNAMIC_CAMPAIGNS:
            return { ...action.response.entities.dynamicCampaigns };
        case DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN_SUCCESS:
            return {
                ...state,
                [action.payload.result]: action.payload.entities.dynamicCampaigns[action.payload.result]
            };
        case DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN_FAILURE:
            return { ...state, [action.payload]: null };
        case DynamicCampaignConstants.REQUEST_DELETE_DYNAMIC_CAMPAIGN_SUCCSSS:
            delete state[action.id];
            return { ...state };
        case DynamicCampaignConstants.REQUEST_CREATE_DYNAMIC_CAMPAIGN_SUCCESS:
        case DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN_SUCCESS:
            return {
                ...state,
                [action.payload.dynamicCampaign.id]: {
                    ...state[action.payload.dynamicCampaign.id],
                    ...action.payload.dynamicCampaign
                }
            };
        case DynamicCampaignConstants.PUSH_DYNAMIC_CAMPAIGN_SUCCESS:
            return {
                ...state,
                [action.payload.dynamicCampaignId]: {
                    ...state[action.payload.dynamicCampaignId],
                    lockedAt: moment.now()
                }
            };
        case DynamicCampaignConstants.REQUEST_UNLOCK_DYNAMIC_CAMPAIGN_SUCCESS:
            return {
                ...state,
                [action.payload.dynamicCampaignId]: {
                    ...state[action.payload.dynamicCampaignId],
                    lockedAt: null,
                    lockedByActionId: null,
                    lockedByAction: null
                }
            };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const loading = (state: boolean = false, action: AnyAction) => {
    switch (action.type) {
        case DynamicCampaignConstants.LOAD_DYNAMIC_CAMPAIGNS:
        case DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN_SUCCESS:
        case DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN_FAILURE:
            return false;
        case DynamicCampaignConstants.REQUEST_LOAD_DYNAMIC_CAMPAIGNS:
        case DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN:
            return true;
        case RESET_CLIENT_STATE:
            return false;
        default:
            return state;
    }
};

const detach = (state = [] as any, action: AnyAction) => {
    switch (action.type) {
        case DynamicCampaignConstants.QUEUE_SOT_DETACH:
            state.push(action.payload);

            return state;
        case DynamicCampaignConstants.FETCH_DYNAMIC_CAMPAIGN:
        case DynamicCampaignConstants.REQUEST_UPDATE_DYNAMIC_CAMPAIGN_SUCCESS:
        case RESET_CLIENT_STATE:
            return [];
        default:
            return state;
    }
};

const loadedAt = (state: null | string = null, action: AnyAction) => {
    switch (action.type) {
        case DynamicCampaignConstants.LOAD_DYNAMIC_CAMPAIGNS:
            return new Date();
        case DynamicCampaignConstants.REQUEST_LOAD_DYNAMIC_CAMPAIGNS:
            return null;
        case RESET_CLIENT_STATE:
            return null;
        default:
            return state;
    }
};

export interface IDynamicCampaignState {
    entities: IEntity<IDynamicCampaign>;
    detach: {
        clientId: number;
        sotId: number;
        dynamicCampaignId: number;
    }[];
    loading: boolean;
    loadedAt: null | Date;
    saving: boolean;
}

export default combineReducers({
    entities: entitiesReducer,
    detach,
    loading,
    loadedAt,
    saving: savingReducer
});
