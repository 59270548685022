import React from "react";
import { ITemplatePart } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import AddButton from "../AddButton";
import filterDeleted from "../../../../utils/filterDeleted";
import { unionBy, orderBy } from "lodash";

interface ITemplatePartProps {
    parts: ITemplatePart[] | undefined;
    values: any;
    Component: any;
    onAdd: (order: number) => void;
    onDuplicate: (part: ITemplatePart, order: number) => void;
    fieldValidation?: (value: string) => string | undefined;
    maxLength?: number;
    allowEmpty?: boolean;
    templateIndex: number;
    setFieldValue: any;
    errors?: any;
}

const TemplateParts: React.FC<ITemplatePartProps> = ({
    fieldValidation,
    Component,
    templateIndex,
    parts = [],
    onAdd,
    onDuplicate,
    setFieldValue,
    maxLength = 0,
    values,
    errors,
    allowEmpty = false
}) => {
    const fieldName = `expandedTextAdTemplates[${templateIndex}]`;
    const orderedActiveParts = orderBy(filterDeleted(parts), ["order", "new", "id"], ["asc", "desc", "asc"]);
    const canDelete = allowEmpty || orderedActiveParts.length > 1;
    const addAllowed = maxLength > 0 ? orderedActiveParts.length < maxLength : true;

    const sortParts = (dragIndex: number, hoverIndex: number, dragField: string) => {
        // Make sure we're the part we're dragging belongs to the same field as the parts we're hovering over
        if (parts[0].field !== dragField) {
            return false;
        }

        const partToMove = orderedActiveParts.splice(dragIndex, 1);

        const reorderedParts = [
            ...orderedActiveParts.slice(0, hoverIndex),
            ...partToMove,
            ...orderedActiveParts.slice(hoverIndex)
        ].map((value: any, index) => {
            if (value.order === index) {
                return value;
            }
            return { ...value, order: index, dirty: true };
        });

        setFieldValue(`${fieldName}.parts`, unionBy(reorderedParts, values.parts, "id"));
        setFieldValue(`${fieldName}.dirty`, true);

        return true;
    };
    const maxReached = !!maxLength && orderedActiveParts.length >= maxLength;

    return (
        <React.Fragment>
            {orderedActiveParts.map((item: ITemplatePart, i: number) => {
                return (
                    <Component
                        item={item}
                        index={i}
                        key={item.id}
                        fieldValidation={fieldValidation}
                        templateIndex={templateIndex}
                        canDelete={canDelete}
                        sortParts={sortParts}
                        onDuplicate={() => {
                            if (!maxReached) onDuplicate(item, orderedActiveParts.length);
                        }}
                        errors={errors}
                        allowDuplicate={!maxReached}
                    />
                );
            })}
            <AddButton
                disabled={!addAllowed}
                onClick={() => {
                    if (!maxReached) onAdd(orderedActiveParts ? orderedActiveParts.length : 0);
                }}
                text={`Add ${maxLength ? `(${orderedActiveParts.length}/${maxLength})` : ""}`}
            />
        </React.Fragment>
    );
};

export default TemplateParts;
