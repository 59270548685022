import _ from "lodash";
import Mustache from "./utils/Mustache";
import moment, { Moment } from "moment";
import { ISampleData } from "./interfaces/DynamicCampaigns/ITemplatePart";
import { IClient } from "./interfaces/IClient";
import Config from "./config";
import { ITemplatePart } from "./interfaces/DynamicCampaigns/IDynamicCampaign";
const configs = Config.getInstance();

const getTemplateValue = (word: string, values: ISampleData | {}) => {
    try {
        const converted = Mustache.render(word, values);
        if (word !== "" && converted === "") {
            return "XXXX";
        } else if (converted === "") {
            return word;
        } else {
            return converted;
        }
    } catch (e) {
        return word;
    }
};

const templatize = (template: string, values: ISampleData | {}): string => {
    return template.replace(/{{[^{{]+}}/g, (word: string) => {
        if (word.includes("| money")) {
            const temp = word.replace("| money", "");
            const value = getTemplateValue(temp, values);
            return `$${value}`;
        } else if (word.includes("| number")) {
            const temp = word.replace("| number", "");
            const value = getTemplateValue(temp, values);
            return value.replace(/\B(?=([\d|\w]{3})+(?!\d))/g, ",");
        } else {
            const value = getTemplateValue(word, values);
            return value;
        }
    });
};

/**
 * Check for template variables that are not in the values
 * @param {string} template
 * @param {object} values
 * @returns {boolean}
 */
const hasMissingVariables = (template: string, values: ISampleData | {}): boolean => {
    if (template !== "") {
        const variables = template.match(/{{(.+?)}}/g);

        // if we have variables in the template
        if (variables !== null && values !== undefined) {
            const templateVars = Object.keys(values).map((key) => `{{${key}}}`);

            // no replacements at all so it fails
            if (templateVars.length === 0) {
                return true;
            }

            // remove all we have templateVars for
            _.pullAll(variables, templateVars);

            return variables.length > 0;
        }
    }

    return false;
};
/**
 * Get the first valid compiled template from templates
 *
 * @param {array} templates
 * @param {object} values
 * @param {number|undefined} length
 * @returns {string}
 */
const getFirstValidTemplate = (
    templates: string[],
    values: ISampleData | {},
    length: number | undefined = undefined
): string => {
    for (const key in templates) {
        if ({}.hasOwnProperty.call(templates, key)) {
            const template = templates[key];
            // generate template and check length is valid
            const compiledTemplate = templatize(template, values);
            if (length === undefined || (compiledTemplate !== "" && compiledTemplate.length <= length!)) {
                return compiledTemplate;
            }
        }
    }
    // if none pass fall back to empty string for now
    return "";
};

const getValidTemplatesForRsa = (
    templates: ITemplatePart[],
    values: ISampleData | {},
    length: number | undefined = undefined
): string[] => {
    const compiledTemplates = [];

    for (const key in templates) {
        if ({}.hasOwnProperty.call(templates, key)) {
            const template = templates[key].value;
            // generate template and check length is valid
            const compiledTemplate = templatize(template, values);
            if (length === undefined || (compiledTemplate !== "" && compiledTemplate.length <= length!)) {
                compiledTemplates.push(compiledTemplate);
            }
        }
    }
    // if none pass fall back to empty string for now
    return compiledTemplates;
};

/**
 * Return the first valid options for path with are two values separated by a /
 * @param {array} templates
 * @param {object} values
 * @param {number} length
 * @returns {string}
 */
const getFirstValidPathTemplate = (templates: string[], values: ISampleData | {}): string => {
    const length = 30;
    for (const key in templates) {
        if ({}.hasOwnProperty.call(templates, key)) {
            const template = templates[key];
            // check for variables that are not in feed
            if (hasMissingVariables(template, values) === false) {
                // generate template and check length is valid
                const compiledTemplate = templatize(template, values);
                // check parts length
                const parts = compiledTemplate.split("/");
                // todo: check for 2 parts or one only?
                if (
                    compiledTemplate !== "" &&
                    parts[0].length <= length &&
                    (parts.length === 1 || parts[1].length <= length)
                ) {
                    return compiledTemplate;
                }
            }
        }
    }

    // if none pass fall back to empty string for now
    return "";
};

const getDisplayUrlFromPathAndFinalUrl = (path: any, finalUrl: any) => {
    let domain = finalUrl.replace(/(http|https):\/\//i, "").split("/")[0];
    if (path) {
        domain = domain + "/" + path;
    }

    return domain;
};

const getPathFromDisplayUrl = (displayUrl: any) => {
    let schemaFree = displayUrl.replace(/(http|https):\/\//i, "").split("/");
    switch (schemaFree.length) {
        case 0:
        case 1:
            return "/";
        case 2:
            return schemaFree[1] + "/";
        default:
            return schemaFree[1] + "/" + schemaFree[2];
    }
};

const formatMoneyToString = (value: number, leadingZero: boolean = true): string => {
    const rounded = value.toFixed(2);
    return `${rounded}`;
};

/**
 *
 * @param {string} dateString
 */
const coolDateString = (dateString: Moment) => {
    let date = moment().startOf("day");
    let logDate = moment(dateString).startOf("day");
    let diff = date.diff(logDate, "days");
    switch (diff) {
        case -1:
            return "Tomorrow";
        case 1:
            return "Yesterday";
        case 0:
            return "Today";
        default:
            return logDate.format("MM/DD");
    }
};

/**
 * Get a string with the proper values to display for a duration
 * @param {moment} start
 * @param {moment} end
 */
const getDurationString = (start: Moment, end: Moment): string => {
    const diff = moment.duration(end.diff(start));
    if (diff.as("minutes") >= 1) {
        return parseInt(diff.as("minutes") as unknown as string, 10) + " mins";
    }

    return parseInt(diff.as("seconds") as unknown as string, 10) + " secs";
};

const getNewBudgetUrl = (props: any) => {
    if (props.location.pathname.includes("bing")) {
        return `${window.location.origin}/budget/${props.clientId}/create?service=bing`;
    } else if (props.location.pathname.includes("adwords")) {
        return `${window.location.origin}/budget/${props.clientId}/create?service=adwords`;
    } else {
        return null;
    }
};
export {
    coolDateString,
    getDurationString,
    formatMoneyToString,
    templatize,
    getDisplayUrlFromPathAndFinalUrl,
    getFirstValidTemplate,
    getValidTemplatesForRsa,
    hasMissingVariables,
    getFirstValidPathTemplate,
    getPathFromDisplayUrl,
    getNewBudgetUrl
};
