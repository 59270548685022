import React, { SVGProps } from "react";
import Svg from "./Svg";

const GoogleIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M22.714 10.4599H21.875V10.4167H12.5V14.5833H18.387C17.5281 17.0088 15.2203 18.75 12.5 18.75C9.04842 18.75 6.24998 15.9516 6.24998 12.5C6.24998 9.04843 9.04842 6.24999 12.5 6.24999C14.0932 6.24999 15.5427 6.85104 16.6463 7.83281L19.5927 4.88645C17.7323 3.1526 15.2437 2.08333 12.5 2.08333C6.74738 2.08333 2.08331 6.74739 2.08331 12.5C2.08331 18.2526 6.74738 22.9167 12.5 22.9167C18.2526 22.9167 22.9166 18.2526 22.9166 12.5C22.9166 11.8016 22.8448 11.1198 22.714 10.4599Z" />
        <path d="M3.28436 7.65156L6.70676 10.1615C7.6328 7.86874 9.87551 6.24999 12.5 6.24999C14.0932 6.24999 15.5427 6.85104 16.6463 7.83281L19.5927 4.88645C17.7323 3.1526 15.2437 2.08333 12.5 2.08333C8.49895 2.08333 5.02915 4.34218 3.28436 7.65156Z" />
        <path d="M12.5 22.9167C15.1906 22.9167 17.6354 21.887 19.4839 20.2125L16.2599 17.4844C15.2141 18.2766 13.9141 18.75 12.5 18.75C9.79062 18.75 7.4901 17.0224 6.62344 14.6115L3.22656 17.2287C4.95052 20.6021 8.45156 22.9167 12.5 22.9167Z" />
        <path d="M22.7812 10.4167H21.875H12.5V14.5833H18.387C17.9745 15.7484 17.225 16.7531 16.2583 17.4849L16.2599 17.4839L19.4839 20.212C19.2557 20.4193 22.9167 17.7083 22.9167 12.5C22.9167 11.8016 22.912 11.0766 22.7812 10.4167Z" />
    </Svg>
);

export default GoogleIcon;
