import React from "react";
import ClientSelector from "./ClientSelector";
import NotificationAlert from "./NotificationAlert";
import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";
import logo from "../../assets/fuel-logo-white.svg";
import ChevronLeftIcon from "../Shared/Icons/ChevronLeftIcon";
import useLocalStorage from "../../hooks/useLocalStorage";
import IUser from "../../interfaces/IUser";

const Header: React.FC<{
    user: IUser;
    isInternalUser: boolean;
}> = ({ user, isInternalUser }) => {
    const [collapsed, setCollapsed] = useLocalStorage("menu-collapsed", false);
    return (
        <div className="flex justify-between text-white bg-blue-900 h-16 w-full items-center">
            <div className={`relative flex items-center justify-start h-16 ml-4`}>
                <Link to="/budgets/adwords">
                    <img src={logo} alt="Fuel Logo" className={`h-10`} />
                </Link>
                <div className="top-0 right-0 absolute z-50 -mr-24">
                    <button
                        className={`${
                            collapsed ? "bg-blue-700 border-blue-800" : "bg-blue-800 border-blue-700"
                        } outline-none focus:outline-none border-2  p-1 rounded-full mt-4 -mr-8`}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        <ChevronLeftIcon
                            className="w-4 h-4 text-white"
                            style={{ transform: collapsed ? "rotate(180deg)" : "" }}
                        />
                    </button>
                </div>
            </div>
            <div className="flex justify-end">
                <ClientSelector />
                <NotificationAlert />
                {user && <UserMenu user={user} isInternalUser={isInternalUser} />}
            </div>
        </div>
    );
};

Header.defaultProps = {
    isInternalUser: true
};

export default Header;
