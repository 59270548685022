import React from "react";
import { connect } from "react-redux";
import IAppState from "../../../../interfaces/IAppState";
import IEntity from "../../../../interfaces/IEntity";
import IAdExtensionFields, { ISitelinkTemplate } from "../../../../interfaces/DealerSetup/IAdExtensionFields";

interface IProps {
    fields: any;
}

interface IProps2 {
    sitelinkTemplates: IEntity<ISitelinkTemplate>;
}
const AdExtensionsSection: React.FC<IProps & IProps2> = ({ fields, sitelinkTemplates }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    <div>
                        {Object.keys(fields).map((categoryName) => {
                            const sitelinkTemplate = sitelinkTemplates[fields[categoryName].sitelink_template_id.value];
                            return (
                                <div key={sitelinkTemplate.id} className="my-2 border-b">
                                    <div style={{ color: "#9b9c9f" }} className="text-xs">
                                        {sitelinkTemplate.categoryName}
                                    </div>
                                    <div className="font-bold text-lg">{sitelinkTemplate.headline1}</div>
                                    <div className="text-sm">
                                        {sitelinkTemplate.description1} {sitelinkTemplate.description2}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state: IAppState) => ({
    sitelinkTemplates: state.sitelinkTemplates.entities
});

export default connect(mapStateToProps, null)(AdExtensionsSection);
