import React from "react";
import { ISpecialOfferTemplateAd } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import ChevronRightIcon from "../../../Shared/Icons/ChevronRightIcon";
import DuplicateButton from "../ExpandedTextAds/DuplicateButton";
import RemovePart from "../ExpandedTextAds/RemovePart";
import DetachSpecialOfferButton from "./DetachSpecialOfferButton";
import moment from "moment";
import TimeIcon from "../../../Shared/Icons/TimeIcon";

interface IProps {
    value: ISpecialOfferTemplateAd;
    currentUser?: any;
    idx: number;
    label?: string | null;
    isOpen: boolean;
    onToggleOpen(): void;
    onDuplicate(idx: number): void;
    onDetach(): void;
}

const ActionBar: React.FC<IProps> = ({
    onDetach,
    value,
    currentUser,
    idx,
    label,
    isOpen,
    onToggleOpen,
    onDuplicate
}) => {
    return (
        <div className="flex flex-row my-2 justify-between h-auto content-center ">
            <div className="flex flex-row items-center justify-between ">
                <button
                    type="button"
                    onClick={onToggleOpen}
                    className="h-full outline-none text-gray-500 hover:text-blue-600"
                    style={{ alignItems: "center", justifyContent: "center" }}
                >
                    <ChevronRightIcon
                        className="w-6 h-6"
                        style={{ transition: ".2s ease", ...(isOpen ? { transform: "rotate(90deg)" } : {}) }}
                    />
                </button>

                <div className="ml-4 italic">{label || "Untitled ETA"}</div>
                <div className="ml-8 py-1 px-2 rounded-md bg-gray-700 text-white items-center justify-center">
                    {"SPECIAL OFFER"}
                </div>
            </div>
            <div className="flex items-center justify-end">
                <div
                    className={`${
                        moment(value.specialOfferTemplate.expiresAt).isAfter(moment())
                            ? "text-green-600"
                            : "text-red-600"
                    } tracking-wide leading-tight mr-4 flex items-center`}
                >
                    <TimeIcon className="w-6 h-6 mr-2" />
                    {moment(value.specialOfferTemplate.expiresAt).format("LL")}
                </div>
                <DuplicateButton onDuplicate={() => onDuplicate(idx)} />
                <DetachSpecialOfferButton onRemove={() => onDetach()} />
            </div>
        </div>
    );
};

export default ActionBar;
