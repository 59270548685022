// determines if array hold unique values, but does not take into account empty strings
const arrayHasUniqueValues = (array: string[]) => {
    const hash = {} as any;
    for (const line of array) {
        if (line !== "") {
            if (hash[line.trim()]) {
                return false;
            }
            hash[line.trim()] = true;
        }
    }
    return true;
};

export default arrayHasUniqueValues;
