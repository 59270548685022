import React, { FunctionComponent, ChangeEvent, useEffect, useState } from "react";
import CrmEmail from "./CrmEmail";
import IClient from "../../../../interfaces/IClient";
import TextField from "../../../Shared/Form/Blocks/TextField";
import Label from "../../../Shared/Form/Elements/Label";
import Button, { PRIMARY_BUTTON } from "../../../Shared/Button";
import Errors from "../../../Shared/Form/Elements/Errors";
import Select from "react-select";
import IAutomobileManufacturer from "../../../../interfaces/IAutomobileManufacturer";
import Address from "./Address";
import moment from "moment";
import TimeIcon from "../../../Shared/Icons/TimeIcon";

interface ISelectOption {
    label: string;
    value: string;
}

type SelectOptionValue = ISelectOption[] | ISelectOption | undefined | null;

interface IGeneral {
    client: IClient;
    isInternalUser: boolean;
    manufacturers: any;
    onValueChange(event: ChangeEvent<HTMLInputElement>): void;
    updateStateAndCountry(state: string, country: string): void;
    errors: {
        [key: string]: string[];
    };
}

const General: FunctionComponent<IGeneral> = ({
    client,
    manufacturers,
    onValueChange,
    isInternalUser,
    updateStateAndCountry,
    errors
}) => {
    const clientManufacturers = client.automobileManufacturers ? client.automobileManufacturers : [];

    const [selectedManufacturers, setManufacturers] = useState(
        clientManufacturers.map((manufacturer: IAutomobileManufacturer) => ({
            value: String(manufacturer.id),
            label: manufacturer.name
        }))
    );

    let sortedManufacturers: IAutomobileManufacturer[] = Object.values(manufacturers);
    sortedManufacturers = sortedManufacturers.sort((a: IAutomobileManufacturer, b: IAutomobileManufacturer): number =>
        a.name.localeCompare(b.name)
    );

    const allPossibleManufacturersInSelectFormat: ISelectOption[] = Object.keys(sortedManufacturers).map(
        (key: any) => ({
            value: String(sortedManufacturers[key].id),
            label: sortedManufacturers[key].name
        })
    );

    const handleManufacturersChange = (inputValue: any) => {
        const value = inputValue && Array.isArray(inputValue) ? inputValue.map((val) => val.value) : inputValue;
        onValueChange({
            target: {
                name: "automobileManufacturers",
                value
            }
        } as ChangeEvent<HTMLInputElement>);

        setManufacturers(inputValue);
    };

    return (
        <div className="flex flex-wrap justify-between">
            <div className="w-full lg:w-1/2 lg:pr-4">
                <TextField
                    label="Name"
                    name="name"
                    value={client.name || ""}
                    handleChange={onValueChange}
                    required={true}
                    errors={errors.name}
                />

                <TextField
                    label="Short Name"
                    name="shortNameForDealer"
                    value={client.shortNameForDealer || ""}
                    handleChange={onValueChange}
                    errors={errors.shortNameForDealer}
                />
                {isInternalUser === true && (
                    <div className="flex flex-wrap mb-3">
                        <div className="w-full lg:w-1/2 xl:w-1/2">
                            <TextField
                                label="Salesforce ID"
                                name="salesforceId"
                                className="lg:mr-4 "
                                value={client.salesforceId || ""}
                                handleChange={onValueChange}
                                required={true}
                                errors={errors.salesforceId}
                            />
                        </div>

                        <div className="w-full lg:w-1/2 xl:w-1/2">
                            <TextField
                                label="Salesforce Top-Level Parent ID"
                                name="salesforceParentId"
                                className="lg:mr-4 "
                                value={client.parentSalesforceId || ""}
                                handleChange={onValueChange}
                                readOnly={true}
                            />
                        </div>

                        <div className="w-full lg:w-1/2 xl:w-1/2">
                            <TextField
                                label="Salesforce Top-Level Parent Name"
                                name="salesforceParentName"
                                className="lg:mr-4 "
                                value={client.parentSalesforceName || ""}
                                handleChange={onValueChange}
                                readOnly={true}
                            />
                        </div>

                        <div className="w-full lg:w-1/2 xl:w-1/2">
                            <TextField
                                label="Engine ID"
                                name="engineId"
                                className="xl:mr-4"
                                value={(client.engineId || "") as string}
                                handleChange={onValueChange}
                                errors={errors.engineId}
                            />
                            {client.engineIdUpdatedAt && (
                                <div
                                    className="text-sm pt-1 text-gray-600 flex items-center"
                                    title={"The time the Engine ID was last updated."}
                                >
                                    <TimeIcon className="w-4 h-6" />
                                    <span className="pl-1">{moment(client.engineIdUpdatedAt).format("lll")}</span>
                                </div>
                            )}
                            {!client.engineIdUpdatedAt && (
                                <div
                                    className="text-sm pt-1 text-gray-600 flex items-center"
                                    title={"The time the Engine ID was last updated."}
                                >
                                    <span>
                                        This field hasn't been updated since we started tracking it on July 27th, 2020.
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="w-full xl:w-1/2">
                            <TextField
                                label="Cars.com Seller ID"
                                name="carsSellerId"
                                className="lg:mr-4"
                                value={client.carsSellerId ? String(client.carsSellerId) : ""}
                                handleChange={onValueChange}
                                errors={errors.carsSellerId}
                            />
                        </div>
                    </div>
                )}

                <TextField
                    label="Url"
                    name="domain"
                    value={client.domain || ""}
                    handleChange={onValueChange}
                    errors={errors.domain}
                />

                <div className="block uppercase tracking-wider text-blue-800 text-base mb-2 mt-4">Manufacturers</div>
                <Select
                    className="text-base"
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    isLoading={!manufacturers}
                    placeholder={!manufacturers ? "Loading Manufacturers" : "Select Manufacturers"}
                    isDisabled={!manufacturers}
                    value={selectedManufacturers}
                    onChange={handleManufacturersChange}
                    name="manufacturers"
                    options={allPossibleManufacturersInSelectFormat}
                />

                {isInternalUser === true && (
                    <div className="flex flex-col mt-2">
                        <Label label={"CRM Emails"} isValid={!errors.crmEmail || errors.crmEmail.length === 0} />
                        {errors && <Errors errors={errors.crmEmail ? [errors.crmEmail[0]] : []} />}

                        {client.crmEmail &&
                            client.crmEmail.map((email, idx) => {
                                return (
                                    <CrmEmail
                                        errors={errors.crmEmail && errors.crmEmail[idx]}
                                        key={idx}
                                        client={client}
                                        onValueChange={onValueChange}
                                        idx={idx}
                                        email={email || ""}
                                    />
                                );
                            })}
                        <span className="text-xs mt-2">
                            *These email addresses will receive the facebook leads for the associated Facebook page.
                        </span>
                        <Button
                            styleType={PRIMARY_BUTTON}
                            styles="mt-4 text-base"
                            onClick={() => {
                                onValueChange({
                                    target: { value: [...client.crmEmail, ""], name: "crmEmail" }
                                } as ChangeEvent<any>);
                            }}
                        >
                            Add
                        </Button>
                    </div>
                )}
            </div>

            <div className="w-full lg:w-1/2 lg:pl-4">
                <Address
                    address={client.address}
                    city={client.city}
                    state={client.state}
                    zip={client.zip}
                    country={client.country}
                    errors={client.errors}
                    onChange={onValueChange}
                    updateStateAndCountry={updateStateAndCountry}
                />

                {isInternalUser && (
                    <div>
                        <hr className="mt-5 mb-5" />
                        <Label label="Billing Address" />
                        <TextField
                            name="dealerAddress1"
                            readOnly={true}
                            value={client.dealerAddress1 || ""}
                            handleChange={onValueChange}
                            label="Address"
                        />

                        <TextField
                            name="dealerCity"
                            readOnly={true}
                            value={client.dealerCity || ""}
                            handleChange={onValueChange}
                            label="City"
                        />
                        <div className="flex">
                            <div className="w-1/3">
                                <TextField
                                    name="dealerState"
                                    readOnly={true}
                                    value={client.dealerState || ""}
                                    handleChange={onValueChange}
                                    label={client.dealerCountryCode === "CA" ? "Province" : "State"}
                                    className="mr-4"
                                />
                            </div>
                            <div className="w-1/3">
                                <TextField
                                    name="dealerZip"
                                    readOnly={true}
                                    value={client.dealerZip || ""}
                                    handleChange={onValueChange}
                                    label={client.dealerCountryCode === "CA" ? "Postal Code" : "Zip"}
                                    className="mr-4"
                                />
                            </div>
                            <div className="w-1/3">
                                <TextField
                                    name="dealerCountry"
                                    readOnly={true}
                                    value={client.dealerCountry || ""}
                                    handleChange={onValueChange}
                                    label="Country"
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-1/2">
                                <TextField
                                    name="territory"
                                    value={client.territory || ""}
                                    readOnly={true}
                                    label="Territory"
                                    className="mr-4"
                                />
                            </div>
                            <div className="w-1/2">
                                <TextField
                                    name="clientServicesPod"
                                    value={client.clientServicesPod || ""}
                                    readOnly={true}
                                    label="Client Services Pod"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default General;
