import React, { SVGProps } from "react";
import Svg from "./Svg";

const BellIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path
            fill="none"
            d="M5.52237 16.8438V10.3774C5.52237 6.39017 8.41102 4.63684 10.6725 4.03922M5.52237 16.8438C3.42491 16.8438 2.97109 18.1309 2.98518 18.7534C2.98518 20.2032 4.23269 20.5234 4.87764 20.5234H10.0285M5.52237 16.8438H19.5218M19.5218 16.8438V10.3774C19.5218 8.5021 17.8016 5.45172 15.2109 4.30678M19.5218 16.8438C21.7388 16.8438 22.2169 18.1186 22.1831 18.745C22.1358 20.1947 20.9664 20.5234 20.0961 20.5234H15.0514M10.0285 20.5234C10.0285 22.8926 12.0042 23.0466 12.6137 23.0466C13.2231 23.0466 14.6779 22.8205 15.0514 20.5234M10.0285 20.5234H15.0514M10.6725 4.03922C11.1981 3.90032 11.9953 3.77711 12.5941 3.77711C13.8061 3.77711 14.3266 3.97828 15.2109 4.30678M10.6725 4.03922C10.6725 1.55217 12.2687 1.08798 13.0668 1.16677C15.0153 1.16677 15.308 3.26011 15.2109 4.30678"
        />
    </Svg>
);

export default BellIcon;
