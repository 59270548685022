import { AnyAction } from "redux";
import dealerSetupRoutes from "../components/Client/DealerSetup/DealerSetupRoutes";
import Constants from "../constants/DealerSetupConstants";
import { some, each, split, keyBy, camelCase, mapValues, get, defaults, isEmpty } from "lodash";
import IEntity from "../interfaces/IEntity";
import {
    IGenericCampaignApiShape,
    IGenericCampaignFields,
    IGenericCampaignField
} from "../interfaces/DealerSetup/IGenericCampaign";
import { IPreScreenApi, ICampaignOptions, IPreScreenFields } from "../interfaces/DealerSetup/IPreScreenFields";
import { IConquestFields, IConquestApiShape } from "../interfaces/DealerSetup/IConquest";
import IFormValues from "../interfaces/DealerSetup/IFormValues";
import { IDealerApi, IDealerFields } from "../interfaces/DealerSetup/IDealerFields";
import { IDealerInfoApi, IDealerInfoFields } from "../interfaces/DealerSetup/IDealerInfoFields";
import { INameApi, INameFields } from "../interfaces/DealerSetup/INameFields";
import { ILocationApi, ILocationFields } from "../interfaces/DealerSetup/ILocationsFields";
import { IServicesOptions, IServicesApi, IServicesFields } from "../interfaces/DealerSetup/IServicesFields";
import { ICompetitorsApi, ICompetitorsFields } from "../interfaces/DealerSetup/ICompetitorsFields";
import { IDynamicCampaignFields } from "../interfaces/DealerSetup/IDynamicCampaign";
import { preScreenCampaigns } from "../components/Client/DealerSetup/Pages/PreScreen";
import { IRemarketingFields, IRemarketingApi } from "../interfaces/DealerSetup/IRemarketingFields";
import { IAdExtension } from "../interfaces/actions/IAdExtension";
import IAdExtensionFields, { IAdExtensionField } from "../interfaces/DealerSetup/IAdExtensionFields";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

interface IFormatDealerSetupDataProps {
    prescreen: IPreScreenApi;
    dealer: IDealerInfoApi;
    campaigns: {
        name: INameApi;
        dealer: IDealerApi;
        locations: ILocationApi;
        competitors: ICompetitorsApi;
        services: IServicesApi;
        generic: IGenericCampaignApiShape;
        dynamic: {
            name: string;
            negative_keyword_lists: number[];
        }[];
        conquest: IConquestApiShape;
        remarketing: any;
    };
    ad_extensions: IAdExtension[];
}

export interface IDealerSetupState {
    formValues: IFormValues;
    hasSavedState?: boolean;
    processing: boolean;
    success: boolean;
    message: any;
}

export const initialState: IDealerSetupState = dealerSetupRoutes.reduce(
    (accumulator: any, currentValue) => {
        return {
            ...accumulator,
            formValues: {
                ...accumulator.formValues,
                [currentValue.path]: {
                    isTouched: false,
                    isValid: false,
                    isDisabled: false
                }
            },
            hasSavedState: undefined,
            processing: false,
            success: false,
            message: {}
        };
    },
    {
        formValues: {
            dealer: {
                isTouched: false,
                isValid: true,
                isDisabled: false,
                fields: {
                    negative_keyword_lists: {
                        value: [] as number[]
                    }
                }
            }
        }
    }
);

interface IFormPage<T = any> {
    fields: T;
    isTouched: boolean;
    isValid: boolean;
    isDisabled: boolean;
}

export interface IDealerSetupState {
    formValues: IFormValues;
    hasSavedState?: boolean;
    processing: boolean;
    success: boolean;
    message: any;
}

function reducer(state = initialState, action: AnyAction): IDealerSetupState {
    switch (action.type) {
        case Constants.SAVE_FORM_VALUES:
            return { ...state, formValues: { ...state.formValues, [action.payload.path]: action.payload.values } };

        case Constants.TOGGLE_FORM_PAGES:
            state = disablePages(state, action.payload);
            return {
                ...state,
                formValues: { ...state.formValues }
            };

        case Constants.FETCH_DEALER_SETUP_DATA_SUCCESS:
            const savedState = disablePages(parseDealerSetupData(action.payload, state));
            return { ...savedState, hasSavedState: true };

        case Constants.FETCH_DEALER_SETUP_DATA_FAILURE:
            return { ...state, hasSavedState: false };

        case Constants.SAVE_FORM_REQUEST:
            return { ...state, processing: true, message: { type: "SUCCESS", value: "Saving Dealer Setup..." } };

        case Constants.SAVE_FORM_SUCCESS:
            return {
                ...state,
                hasSavedState: true
            };

        case Constants.SAVE_FORM_FAILURE:
            return {
                ...state,
                processing: false,
                message: { type: "ERROR", value: "Error: Failed to save the form data. Please try again." }
            };

        case Constants.PROCESS_CAMPAIGNS_REQUEST:
            return {
                ...state,
                processing: true,
                message: { type: "SUCCESS", value: "Processing Campaign Options..." }
            };

        case Constants.PROCESS_CAMPAIGNS_FAILURE:
            return {
                ...state,
                processing: false,
                message: { type: "ERROR", value: "Error: Failed to process the form data. Please try again." }
            };

        case Constants.PROCESS_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                processing: false,
                success: true,
                message: {
                    type: "SUCCESS",
                    value: "Fuel is building the campaigns! This may take a few minutes to complete. Please check AdWords for the results."
                }
            };
        case RESET_CLIENT_STATE:
            return initialState;

        default:
            return state;
    }
}

export default reducer;

export const hasSavedState = (state: { dealerSetup: IDealerSetupState }) => state.dealerSetup.hasSavedState;

const disablePages = (
    savedState: IDealerSetupState,
    selectedCampaigns: ICampaignOptions | null = null
): IDealerSetupState => {
    const values = savedState.formValues;
    const campaigns = selectedCampaigns || values["pre-screen"].fields.campaigns.value;
    each(values, (value, key) => {
        switch (key) {
            case "name":
                values[key].isDisabled = !some([campaigns.name], Boolean);
                break;
            case "locations":
                values[key].isDisabled = !some([campaigns.serviceLocations, campaigns.locations], Boolean);
                break;
            case "serviceLocations":
                values[key].isDisabled = !some([campaigns.name], Boolean);
                break;
            case "competitors":
                values[key].isDisabled = !some([campaigns.competitors], Boolean);
                break;
            case "services":
                values[key].isDisabled = !some([campaigns.services], Boolean);
                break;
            case "dynamic":
                values[key].isDisabled = !some([campaigns.dynamic], Boolean);
                break;
            case "generic":
                values[key].isDisabled = !some([campaigns.generic], Boolean);
                break;
            case "conquest":
                values[key].isDisabled = !some([campaigns.conquest], Boolean);
                break;
            case "remarketing":
                values[key].isDisabled = !some([campaigns.remarketing], Boolean);
                break;
            case "ad-extensions":
                values[key].isDisabled = !some([campaigns.adExtensions], Boolean);
                break;
            case "negative-keywords":
                values[key].isDisabled =
                    [
                        campaigns.name,
                        campaigns.serviceLocations,
                        campaigns.name,
                        campaigns.competitors,
                        campaigns.services,
                        campaigns.dynamic,
                        campaigns.generic,
                        campaigns.conquest,
                        campaigns.remarketing
                    ].filter((item) => item).length === 0;
                break;
            case "dealer":
                values[key].isDisabled = !some([campaigns.dealer], Boolean);
                break;
            default:
                break;
        }
    });
    savedState.formValues = values;
    return savedState;
};

/*
 Selector and functions to convert the redux state into the format/shape the API expects
 */
export const dealerSetupData = (state: { dealerSetup: IDealerSetupState }) => {
    return {
        prescreen: formatPreScreen(state.dealerSetup.formValues["pre-screen"].fields),
        dealer: formatDealer(state.dealerSetup.formValues["dealer-info"].fields),
        campaigns: {
            name: formatNameCampaign(state.dealerSetup.formValues.name.fields || {}),
            dealer: formatDealerCampaign(state.dealerSetup.formValues.dealer.fields || {}),
            locations: formatLocationsCampaign(state.dealerSetup.formValues.locations.fields || {}),
            services: formatServicesCampaign(state.dealerSetup.formValues.services.fields || {}),
            competitors: formatCompetitorsCampaign(state.dealerSetup.formValues.competitors.fields || {}),
            generic: formatGenericCampaign(state.dealerSetup.formValues.generic.fields),
            dynamic: formatDynamicCampaign(state.dealerSetup.formValues.dynamic.fields),
            conquest: formatConquestsCampaign(state.dealerSetup.formValues.conquest.fields),
            remarketing: formatRemarketingCampaign(state.dealerSetup.formValues.remarketing.fields)
        },
        ad_extensions: formatAdExtensions(state.dealerSetup.formValues["ad-extensions"] || {})
    };
};

const formatPreScreen = ({ defaultCpc, campaigns }: IPreScreenFields): IPreScreenApi => ({
    defaultCpc: defaultCpc.value,
    campaigns: [
        { name: "name", checked: campaigns.value.name ? true : false },
        { name: "dealer", checked: campaigns.value.dealer ? true : false },
        { name: "locations", checked: campaigns.value.locations ? true : false },
        { name: "competitors", checked: campaigns.value.competitors ? true : false },
        { name: "dynamic-new", checked: campaigns.value.dynamicNew ? true : false },
        {
            name: "dynamic-used-individual",
            checked: campaigns.value.dynamicUsedIndividual ? true : false
        },
        {
            name: "dynamic-used-results",
            checked: campaigns.value.dynamicUsedResults ? true : false
        },
        { name: "services", checked: campaigns.value.services ? true : false },
        { name: "dynamic", checked: campaigns.value.dynamic ? true : false },
        { name: "generic", checked: campaigns.value.generic ? true : false },
        { name: "conquest", checked: campaigns.value.conquest ? true : false },
        { name: "remarketing", checked: campaigns.value.remarketing ? true : false },
        { name: "service-locations", checked: campaigns.value.serviceLocations ? true : false },
        { name: "ad-extensions", checked: campaigns.value.adExtensions ? true : false }
    ]
});

const formatDealer = ({ name, city, state, zip }: IDealerInfoFields): IDealerInfoApi => ({
    name: name.value,
    location: {
        city: city.value.city,
        state: state.value,
        zipcode: zip.value,
        variations: city.value.variations
    }
});

const formatNameCampaign = ({
    name,
    cityVariations,
    generateExactPhrase,
    negative_keyword_lists
}: INameFields): INameApi => ({
    name: get(name, "value.name", ""),
    variations: get(name, "value.variations", []),
    include_city_variations: get(cityVariations, "value", false),
    generate_exact_phrase: get(generateExactPhrase, "value", false),
    negative_keyword_lists: get(negative_keyword_lists, "value", [])
});

// Just leaving this empty for now... As of the time I'm changing this the other information is
const formatDealerCampaign = ({ negative_keyword_lists }: IDealerFields): IDealerApi => ({
    negative_keyword_lists: negative_keyword_lists.value
});

const formatLocationsCampaign = ({ locations }: ILocationFields): ILocationApi => {
    if (!locations) {
        return {
            locations: {
                ad_groups: [],
                negative_keyword_lists: []
            }
        };
    }

    return {
        locations: {
            ad_groups:
                locations && Array.isArray(locations.value.ad_groups)
                    ? locations.value.ad_groups.map((location) => ({
                          city: get(location, "value.city", ""),
                          variations: get(location, "value.variations", [])
                      }))
                    : [],
            negative_keyword_lists: locations.value.negative_keyword_lists
        }
    };
};

const formatCompetitorsCampaign = ({ competitors }: ICompetitorsFields): ICompetitorsApi => {
    if (!competitors) {
        return {
            competitors: {
                ad_groups: [],
                negative_keyword_lists: []
            }
        };
    }

    return {
        competitors: {
            ad_groups:
                competitors.value.ad_groups && Array.isArray(competitors.value.ad_groups)
                    ? competitors.value.ad_groups.map((competitor) => ({
                          name: get(competitor, "value.name", ""),
                          variations: get(competitor, "value.variations", [])
                      }))
                    : [],
            negative_keyword_lists: competitors.value.negative_keyword_lists
        }
    };
};

const formatDynamicCampaign = (fields: IDynamicCampaignFields): string[] => {
    const campaigns = ensureProp(fields, "campaigns");
    return campaigns.map(({ value }: any) => {
        return value;
    });
};

/**
 * Formats Generic Campaign data into the shape we want for the api.
 */
const formatGenericCampaign = (props?: IGenericCampaignFields): IGenericCampaignApiShape => {
    const campaigns = ensureProp(props, "campaigns");

    return {
        campaigns: campaigns.map(({ value }: IGenericCampaignField) => {
            const { name, keywords, negative_keyword_lists } = value;
            return {
                name,
                keywords,
                negative_keyword_lists
            };
        })
    };
};

const formatConquestsCampaign = (props?: IConquestFields): IConquestApiShape => {
    const conquest = ensureProp(props, "conquest");

    if (Array.isArray(conquest)) {
        return {
            conquest: {
                ad_groups: [],
                negative_keyword_lists: []
            }
        };
    }

    const { value } = conquest;

    const adGroups = value?.ad_groups ?? [];

    return {
        conquest: {
            ad_groups: adGroups.map(({ value }: any) => {
                const { name, variations } = value;
                return { name, variations };
            }),
            negative_keyword_lists: value.negative_keyword_lists
        }
    };
};

const formatRemarketingCampaign = (
    props?: any
): {
    campaigns: IRemarketingFields[];
} => {
    if (!props) {
        return { campaigns: [] };
    }
    return {
        campaigns: props.map(
            ({
                name,
                address,
                city,
                state,
                zip,
                country,
                distanceUnit,
                radius,
                negative_keyword_lists
            }: IRemarketingFields) => {
                return {
                    name: name.value,
                    address: address.value,
                    city: city.value,
                    state: state.value,
                    zip: zip.value,
                    country: country.value,
                    distanceUnit: distanceUnit.value,
                    radius: radius.value,
                    negative_keyword_lists: negative_keyword_lists.value
                };
            }
        )
    };
};

const formatAdExtensions = (page: IFormPage<IAdExtensionFields>) =>
    Object.values(page?.fields || {}).map((field) => ({
        sitelink_template_id: field.sitelink_template_id.value,
        final_url: field.final_url.value
    }));

const formatServicesCampaign = ({ services }: IServicesFields): IServicesApi => ({
    services: {
        ad_groups: [
            { name: "Brand Services", selected: get(services, "value.brandServices", false) },
            { name: "Brand Repairs", selected: get(services, "value.brandRepairs", false) },
            { name: "Brand Oil Change", selected: get(services, "value.brandOilChange", false) },
            { name: "Brand Brakes", selected: get(services, "value.brandBrakes", false) },
            { name: "Brand Tires", selected: get(services, "value.brandTires", false) },
            { name: "Tires", selected: get(services, "value.tires", false) },
            { name: "Brand Specials", selected: get(services, "value.brandSpecials", false) }
        ],
        negative_keyword_lists: get(services, "value.negative_keyword_lists", [])
    }
});

/*
 Functions to convert the fetched state into the format/shape our form expects in the redux store
 */
const parseDealerSetupData = (
    { prescreen, dealer, campaigns, ad_extensions }: IFormatDealerSetupDataProps,
    state: IDealerSetupState
) => {
    state.formValues["pre-screen"].fields = parsePreScreenData(prescreen, campaigns.dealer);
    state.formValues["dealer-info"].fields = parseDealerInfoData(dealer);
    state.formValues.name.fields = parseNameCampaignData(campaigns.name);
    state.formValues.dealer.fields = parseDealerData(campaigns.dealer);
    state.formValues.locations.fields = parseLocationsCampaignData(campaigns.locations);
    state.formValues.competitors.fields = parseCompetitorsCampaignData(campaigns.competitors);
    state.formValues.services.fields = parseServicesCampaignData(campaigns.services);
    state.formValues.dynamic.fields = parseDynamicCampaignData(campaigns.dynamic);
    state.formValues.generic.fields = parseGenericCampaignData(campaigns.generic);
    state.formValues.conquest.fields = parseConquestsCampaignData(campaigns.conquest);
    state.formValues.remarketing.fields = parseRemarketingCampaignData(campaigns.remarketing);
    state.formValues["ad-extensions"].fields = parseAdExtensions(ad_extensions);
    return state;
};

interface IParseAdExtensionsReturn {
    [key: string | number]: IAdExtensionField;
}

const parseAdExtensions = (adExtensions: IAdExtension[]): IParseAdExtensionsReturn => {
    const arrayOfExtensions = adExtensions ?? [];

    // Typescript really doesnt' like this due to the lack of types and the dynamic ness... Typescript can EAT IT.
    // @ts-ignore
    return arrayOfExtensions.map((entry: any) => ({
        ...Object.keys(entry)
            .map((key: string) => {
                return {
                    [key]: {
                        isTouched: false,
                        isValid: false,
                        errors: [] as string[],
                        value: entry[key]
                    }
                };
            })
            .reduce(
                (entries, entry) => ({
                    ...entries,
                    ...entry
                }),
                {}
            )
    }));
};

const parsePreScreenData = ({ defaultCpc, campaigns }: IPreScreenApi, {}: IDealerApi): any => {
    const filtered = campaigns.filter((campaign: any) => camelCase(campaign.name) in preScreenCampaigns);
    // We have to set any missing defaults here so that the sidebar can be enabled/disabled properly on first load
    const fields = {
        campaigns: {
            value: defaults(
                mapValues(
                    keyBy(filtered, (campaign) => camelCase(campaign.name)),
                    "checked"
                ),
                preScreenCampaigns
            )
        }
    };

    if (defaultCpc) {
        Object.assign(fields, { defaultCpc: { value: defaultCpc } });
    }

    return fields;
};

const parseDealerInfoData = ({ name, location }: IDealerInfoApi): any => {
    const fields = {};

    if (name) {
        Object.assign(fields, { name: { value: name } });
    }

    if (location) {
        Object.assign(fields, {
            city: { value: { city: location.city, variations: location.variations } },
            state: { value: location.state },
            zip: { value: location.zipcode }
        });
    }

    return fields;
};

/**
 * Converts the Generic Campaign api response data into shape we want for the form.
 * @param data IGenericCampaignApiShape or undefined
 */
export const parseGenericCampaignData = (data?: IGenericCampaignApiShape): any => {
    if (!data) {
        return {};
    }

    return {
        campaigns: data.campaigns.reduce((acc, values, i) => {
            acc[i] = { value: values };
            return acc;
        }, [] as IGenericCampaignField[])
    };
};

const parseNameCampaignData = ({
    name,
    variations,
    include_city_variations,
    generate_exact_phrase,
    negative_keyword_lists
}: INameApi): INameFields => {
    const fields = {};
    if (name) {
        Object.assign(fields, { name: { value: { name, variations } } });
    }
    if (typeof include_city_variations === "boolean") {
        Object.assign(fields, { cityVariations: { value: include_city_variations } });
    }
    if (typeof generate_exact_phrase === "boolean") {
        Object.assign(fields, { generateExactPhrase: { value: generate_exact_phrase } });
    }

    Object.assign(fields, {
        negative_keyword_lists: {
            value: negative_keyword_lists
        }
    });

    return fields as INameFields;
};
const parseDealerData = ({ negative_keyword_lists }: IDealerApi): IDealerFields => ({
    negative_keyword_lists: {
        value: negative_keyword_lists
    }
});

const parseLocationsCampaignData = (data: ILocationApi): ILocationFields => {
    const { locations = { ad_groups: [], negative_keyword_lists: [] } } = data || {};

    if (isEmpty(data)) {
        return {} as ILocationFields;
    }

    return {
        locations: {
            value: {
                ad_groups: locations.ad_groups.map((location) => ({
                    value: location
                })),
                negative_keyword_lists: locations.negative_keyword_lists
            }
        }
    };
};

const parseConquestsCampaignData = (data?: IConquestApiShape): IConquestFields => {
    if (!data) {
        return {} as IConquestFields;
    }

    return {
        conquest: {
            value: {
                ad_groups: data.conquest.ad_groups.map((values) => ({
                    value: values
                })),
                negative_keyword_lists: data.conquest.negative_keyword_lists
            }
        }
    };
};

const parseCompetitorsCampaignData = (data?: ICompetitorsApi): ICompetitorsFields => {
    if (!data) {
        return {} as ICompetitorsFields;
    }

    return {
        competitors: {
            value: {
                ad_groups: data.competitors.ad_groups.map((competitor) => ({
                    value: competitor
                })),
                negative_keyword_lists: data.competitors.negative_keyword_lists
            }
        }
    };
};

const parseServicesCampaignData = (data?: IServicesApi): IServicesFields => {
    if (!data) {
        return {} as IServicesFields;
    }

    const services = {
        services: {
            value: mapValues(
                keyBy(data.services.ad_groups, (service) => camelCase(service.name)),
                "selected"
            ) as IServicesOptions
        }
    };

    services.services.value.negative_keyword_lists = data.services.negative_keyword_lists;

    return services;
};

const parseDynamicCampaignData = (
    data: { name: string; negative_keyword_lists: number[] }[]
): IDynamicCampaignFields => {
    if (!data) {
        return {} as IDynamicCampaignFields;
    }

    return {
        campaigns: data.map((value) => ({
            value
        }))
    };
};

const parseRemarketingCampaignData = (data?: IRemarketingApi): any => {
    if (!data) {
        return [];
    }

    return data.campaigns.map((fields: IRemarketingFields) => mapValues(fields, (field) => ({ value: field })));
};

const ensureProp = (props: any, thingToEnsureExists: string) => {
    if (!props) {
        return [];
    }

    const thingThatShouldExist = props[thingToEnsureExists];

    if (!thingThatShouldExist) {
        return [];
    }

    return thingThatShouldExist;
};
