import { normalize, Schema } from "normalizr";
import { camelizeKeys } from "humps";
import { getApiKey, getApiUrl, logout } from "../api";
import { redirect } from "react-router-dom";
import IEntity from "../interfaces/IEntity";

export interface IResponse {
    data: any;
    firstPageUrl: string;
    lastPageUrl: string;
    prevPageUrl: string | null;
    nextPageUrl: string | String | null;
    currentPage: number;
    from: number;
    lastPage: number;
    path: string;
    perPage: number;
    to: number;
    total: number;
}

// Extracts the next page URL from API response.
const getNextPageUrl = (response: IResponse) => {
    if (typeof response.nextPageUrl === "string" || response.nextPageUrl instanceof String) {
        // we really only want to break the cache for the first page
        return response.nextPageUrl.replace("refresh=true", "refresh=false");
    }

    return response.nextPageUrl;
};

let networkController = new AbortController();
let signal = networkController.signal;

export const abortNetworkRequests = () => {
    networkController.abort();

    networkController = new AbortController();
    signal = networkController.signal;
};

/**
 *
 * @param {string} fullUrl
 * @param {object} requestConfig
 * @returns {Promise.<object>}
 */
export const doRequest = (
    fullUrl: any,
    requestConfig: any,
    shouldCamelize: boolean = true,
    rejectOnAuthError: boolean = false
): any => {
    // set api key here in case it was changed or refreshed in another request cycle
    requestConfig.headers.Authorization = "Bearer " + getApiKey();
    return fetch(
        fullUrl,
        Object.assign(requestConfig, {
            signal
        })
    )
        .then((response) => {
            // handle 204 no content
            if (response.status === 204) {
                return { json: {}, response };
            }

            // normal response
            return response.json().then((json) => ({ json, response }));
        })
        .then(({ json, response }) => {
            const camalizedJson: { message: string } = shouldCamelize ? camelizeKeys(json) : json;
            if (!response.ok) {
                // check for token expired and refresh
                if (response.status === 401) {
                    if (rejectOnAuthError) {
                        abortNetworkRequests();
                        return Promise.reject(camalizedJson);
                    }

                    localStorage.setItem("INTENDED_REDIRECT", JSON.stringify(window.location.pathname));
                    switch (camalizedJson.message) {
                        case "Token is expired":
                        case "Token has expired":
                        case "Wrong number of segments":
                        case "Invalid token signature":
                        case "Invalid token":
                        case "The token has been blacklisted":
                        case "Token Signature could not be verified.":
                            alert("Your token signature could not be verified, you are being logged out .");
                        default:
                            abortNetworkRequests();
                            logout();
                    }
                }

                // rate limit hit
                if (response.status === 429) {
                    // take a sleep and try request again
                    //todo: fix this
                    alert("you are rate limited");
                }
                // no idea reject
                return Promise.reject(camalizedJson);
            }

            return camalizedJson;
        });
};

export const getPagedResults = (endpoint: string, schema: Schema, perPageLimit = 1000) => {
    const method = "GET";
    const apiRoot = getApiUrl();
    const requestConfig = {
        mode: "cors",
        method,
        headers: {
            // Authorization header is added at request time to be able to handle refresh
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    // build out url if we got it with the root already don't add it
    const url = endpoint.indexOf(apiRoot) === -1 ? apiRoot + endpoint : endpoint;
    // add in limit
    const fullUrl = endpoint.indexOf("?") === -1 ? `${url}?limit=${perPageLimit}` : `${url}&limit=${perPageLimit}`;
    const loadPage = (json: string) => {
        return json;
    };

    // do the initial request
    return doRequest(fullUrl, requestConfig)
        .then(loadPage)
        .then((json: string) => {
            return Object.assign({}, normalize(json, schema), { nextPageUrl: null });
        });
};

export const doDeleteRequest = (endpoint: string, next?: any) => {
    const apiRoot = getApiUrl();
    const requestConfig = {
        mode: "cors",
        method: "DELETE",
        headers: {
            // Authorization header is added at request time to be able to handle refresh
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    const fullUrl = endpoint.indexOf(apiRoot) === -1 ? apiRoot + endpoint : endpoint;
    // legacy support if we are passed a call back do that
    if (next) {
        return doRequest(fullUrl, requestConfig).then(next);
    }

    // return promise
    return doRequest(fullUrl, requestConfig);
};

interface IRequestConfig {
    mode: RequestMode;
    method: string;
    headers: { [key: string]: string };
    body?: string;
}

export const normalizrRequestMiddleware = (schema: Schema) => (json: IResponse) => {
    return Object.assign({}, normalize(json, schema), { nextPageUrl: null });
};

export const fetchEveryPagePaginatorMiddleware = (data: any[], schema: Schema) => (json: any) => {
    const nextPageUrl = getNextPageUrl(json);
    // single request
    if (nextPageUrl === undefined) {
        return json;
    }

    data = data.concat(json.data);
    json.data = data;
    // do we need to load another page?
    if (nextPageUrl) {
        return callApiUsingMiddleware(nextPageUrl, "GET", undefined, true, false, [
            // We won't need to pass in a normalizer middleware again because noramlizer should run last.
            fetchEveryPagePaginatorMiddleware(data, schema)
        ]);
    }

    return json;
};

export const callApiUsingMiddleware = (
    endpoint: string,
    method = "GET",
    body?: object,
    shouldCamelize = true,
    rejectOnAuthError: boolean = false,
    middleware: ((response: IResponse) => any)[] = []
) => {
    const apiRoot = getApiUrl();
    const requestConfig: IRequestConfig = {
        mode: "cors",
        method,
        headers: {
            // Authorization header is added at request time to be able to handle refresh
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    if (body !== undefined) {
        requestConfig.body = JSON.stringify(body);
    }

    const fullUrl = endpoint.indexOf(apiRoot) === -1 ? apiRoot + endpoint : endpoint;
    // do the initial request
    return middleware.reduce(
        (response, middleware) => response.then(middleware),
        doRequest(fullUrl, requestConfig, shouldCamelize, rejectOnAuthError)
    );
};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
// from https://github.com/reactjs/redux/blob/master/examples/real-world/src/middleware/api.js
export const callApi = (
    endpoint: string,
    schema: Schema,
    method = "GET",
    body?: object,
    shouldCamelize = true,
    rejectOnAuthError: boolean = false
) => {
    return callApiUsingMiddleware(endpoint, method, body, shouldCamelize, rejectOnAuthError, [
        normalizrRequestMiddleware(schema)
    ]);
};

export const callApiWithoutNormalizr = (endpoint: string, method = "GET", body?: object) => {
    return callApiUsingMiddleware(endpoint, method, body, false);
};
export const callApiAndFetchEveryPaginatedPage = (endpoint: string, schema: Schema, method = "GET") => {
    const middleware = [fetchEveryPagePaginatorMiddleware([], schema), normalizrRequestMiddleware(schema)];
    return callApiUsingMiddleware(endpoint, method, undefined, true, false, middleware);
};

export const multipartCallApi = (endpoint: string, body: object, method = "POST") => {
    const apiRoot = getApiUrl();
    const requestConfig = {
        mode: "cors",
        method,
        body,
        headers: {
            Accept: "application/json"
        }
    };

    const fullUrl = endpoint.indexOf(apiRoot) === -1 ? apiRoot + endpoint : endpoint;

    // do the initial request
    return doRequest(fullUrl, requestConfig);
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol("Call API");

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store: any) => (next: any) => (action: any) => {
    const callAPI = action[CALL_API];
    if (typeof callAPI === "undefined") {
        return next(action);
    }

    let { endpoint } = callAPI;
    const { schema, types, data, method, tempId } = callAPI;

    if (typeof endpoint === "function") {
        endpoint = endpoint(store.getState());
    }

    if (typeof endpoint !== "string") {
        throw new Error("Specify a string endpoint URL.");
    }
    if (!schema) {
        throw new Error("Specify one of the exported Schemas.");
    }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error("Expected an array of three action types.");
    }
    if (!types.every((type) => typeof type === "string")) {
        throw new Error("Expected action types to be strings.");
    }

    const actionWith = (respData: any) => {
        const finalAction = Object.assign({}, action, respData);
        delete finalAction[CALL_API];
        return finalAction;
    };

    const [requestType, successType, failureType] = types;
    next(actionWith({ type: requestType }));
    const onError = (error: any, request: any) => {
        return next(
            actionWith({
                type: failureType,
                errors: error.errors,
                exceptionMessage: error.exceptionMessage,
                request,
                error: error.message || "Something bad happened"
            })
        );
    };
    const resolve = (response: any) => {
        const action = actionWith({
            response,
            tempId,
            type: successType
        });
        next(action);
    };
    return callApi(endpoint, schema, method, data).then(resolve, onError);
};

export const fetchCsv = async function (path: string): Promise<any> {
    const requestConfig: IRequestConfig = {
        mode: "cors",
        method: "GET",
        headers: {
            // Authorization header is added at request time to be able to handle refresh
            Authorization: "Bearer " + getApiKey(),
            Accept: "text/csv",
            "Content-Type": "application/json"
        }
    };
    const response = await fetch(`${getApiUrl()}${path}`, requestConfig);
    // if we get a 401 check for expired token
    if (response.status === 401) {
        const json = await response.json();
        if (json.message === "Token has expired") {
            return redirect("/login");
        }

        throw new Error("401 Unauthenticated");
    }

    return response.blob();
};
export const normalizeApiResponse = (json: object, schema: Schema) => normalize(camelizeKeys(json), schema);

export const parseErrors = (errors: object) => {
    return Object.entries(errors).reduce((prev, [field, value]) => {
        prev[field] = value.join(",");
        return prev;
    }, {} as { [key: string]: string });
};
