import React, { useEffect, useState } from "react";
import TemplateBoilerplateRow from "./EtaTemplateBoilerplateRow";
import { connect } from "react-redux";
import actions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import IAppState from "../../../../interfaces/IAppState";
import Button, { SECONDARY_BUTTON, SUCCESS_BUTTON } from "../../../Shared/Button";
import LoaderSpinner from "../../../Shared/Loaders/LoaderSpinner";
import { IEtaTemplateBoilerplate } from "../../../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";

interface IProps {
    etaTemplateBoilerplates: IEtaTemplateBoilerplate[];
    load: (ids: number[]) => void;
    hide: () => void;
}
const EtaTemplateBoilerplateModal = ({
    etaTemplateBoilerplates,
    load,
    hide,
    loading,
    loadedAt,
    error,
    fetchAll
}: IProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps) => {
    useEffect(() => {
        fetchAll();
    }, []);

    const [checked, setChecked] = useState<number[]>([]);

    const handleChange = (event: { target: { name: string; checked: boolean } }) => {
        if (!event.target.checked) {
            setChecked(checked.filter((id) => id !== Number(event.target.name)));
            return;
        }
        setChecked([...checked, Number(event.target.name)]);
    };

    const handleLoad = () => {
        load(checked);
        hide();
    };

    return (
        <div className={`w-full flex flex-col justify-between h-full`}>
            <div id="secondary-modal-root"></div>
            <div className={`text-2xl`}>Expanded Text Ad Templates</div>
            {etaTemplateBoilerplates.length < 1 ? (
                <div className={`w-full flex-grow flex justify-around items-center border overflow-y-scroll my-8 mx-2`}>
                    {loading === true || loadedAt === null ? (
                        <LoaderSpinner message={`Loading Templates`} />
                    ) : (
                        <div>No Templates Available</div>
                    )}
                </div>
            ) : (
                <ul className={`w-full flex-grow border overflow-y-scroll my-8 mx-2`}>
                    {etaTemplateBoilerplates.map((etaTemplateBoilerplate) => (
                        <TemplateBoilerplateRow
                            key={etaTemplateBoilerplate.id}
                            etaTemplateBoilerplate={etaTemplateBoilerplate}
                            checked={checked.includes(etaTemplateBoilerplate.id)}
                            onCheck={handleChange}
                        />
                    ))}
                </ul>
            )}
            <div className="w-full flex items-center">
                <div className={`text-red-500 px-3 flex-grow flex justify-center`}>
                    <div>{error?.value ? `Error: ${error.value}` : ""}</div>
                </div>
                <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                    Cancel
                </Button>
                <Button styleType={SUCCESS_BUTTON} onClick={() => handleLoad()} styles="ml-4">
                    Load Templates
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    loading: state.etaTemplateBoilerplate?.loading ?? false,
    loadedAt: state.etaTemplateBoilerplate?.loadedAt ?? null,
    error: state.etaTemplateBoilerplate?.error ?? {}
});

const mapDispatchToProps = {
    fetchAll: () => actions.index.request()
};

export default connect(mapStateToProps, mapDispatchToProps)(EtaTemplateBoilerplateModal);
