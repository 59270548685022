import "@bugsnag/plugin-react";
import { DragDropContextProvider } from "react-dnd";
import { AppContainer, hot } from "react-hot-loader";
import { Provider } from "react-redux";
import HTML5Backend from "react-dnd-html5-backend";
import { AnyAction } from "redux";
import ErrorBoundary from "../bugsnag";
import Error from "../components/500Error";
import AnalyticsTracking from "./AnalyticsTracking";
import Page from "./Page";

interface IAppCont {
    store?: any | AnyAction;
    pageProps?: {
        useNavLinks: boolean | undefined;
        id: number | null;
    };
}

const AppCont = ({ store, pageProps = { useNavLinks: true, id: null } }: IAppCont) => {
    const children = (
        <>
            <AnalyticsTracking />
            <DragDropContextProvider backend={HTML5Backend}>
                <Page {...pageProps} />
            </DragDropContextProvider>
        </>
    );
    return (
        <AppContainer>
            <Provider store={store}>
                {ErrorBoundary ? <ErrorBoundary FallbackComponent={Error}>{children}</ErrorBoundary> : children}
            </Provider>
        </AppContainer>
    );
};

export default hot(module)(AppCont);
