import React from "react";
import TrashIcon from "../Icons/TrashIcon";
import Button, { DANGER_BUTTON, NEGATIVE_BUTTON } from "../Button";
import ModalToggle from "../Dialogs/ModalToggle";

interface IProps {
    deletionMessage: string;
    subTitle?: string;
    cancelText?: string;
    deleteText?: string;
    onDelete: () => void;
    className?: string;
}

const TableActionDeleteButton = ({
    deletionMessage,
    onDelete,
    subTitle = undefined,
    cancelText = "Cancel",
    deleteText = "Delete",
    className = ""
}: IProps) => {
    return (
        <ModalToggle
            style={{ width: "25%" }}
            toggleButton={(open: () => void) => (
                <button className={`group rounded hover:bg-gray-200 ${className}`} onClick={open}>
                    <TrashIcon className="w-6 h-6 group-hover:text-red-500 inline-block" />
                </button>
            )}
            modalContent={(hide: () => void) => {
                const handleDelete = (): void => {
                    onDelete();
                    hide();
                };

                return (
                    <div className={"flex flex-col items-center justify-center"}>
                        <div className="text-2xl font-semibold text-center">{deletionMessage}</div>
                        {subTitle && <div className="pt-4">{subTitle}</div>}

                        <div className="mt-4 w-full flex justify-between items-center">
                            <Button onClick={hide} styleType={NEGATIVE_BUTTON}>
                                {cancelText}
                            </Button>
                            <Button onClick={handleDelete} styleType={DANGER_BUTTON}>
                                {deleteText}
                            </Button>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default TableActionDeleteButton;
