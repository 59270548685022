import React from "react";
import { Card } from "../BudgetDetails/BudgetDetails";
import ManualBudgetActivityHeader from "./ManualBudgetActivityHeader";
import ManualBudgetActivation from "./ManualBudgetActivation";
import IManualActivationApi from "../../../../interfaces/Budgets/IManualActivationApi";
import IManualBudgetApi from "../../../../interfaces/Budgets/IManualBudgetApi";

export interface IManualBudgetActivation {
    id: number;
    note: string;
    previousTargetSpend: number;
    targetSpend: number;
}

interface IProps {
    manualBudget: IManualBudgetApi;
}

const sortActivations = (activations: IManualActivationApi[]) => {
    return activations.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
};

const ManualBudgetActivations: React.FunctionComponent<IProps> = ({ manualBudget }) => {
    const { manualBudgetActivations } = manualBudget;
    const sortedActivations = sortActivations(manualBudgetActivations ? manualBudgetActivations : []);

    return (
        <div className="md:ml-4">
            <div className={`${Card}`}>
                <div className={"flex justify-between items-center"}>
                    <span className="font-bold">Budget Activity</span>
                </div>
                <div className="mt-4">
                    <ManualBudgetActivityHeader />
                    {sortedActivations &&
                        sortedActivations.map((manualBudgetActivation) => {
                            return (
                                <ManualBudgetActivation
                                    key={`manualBudgetActivation-${manualBudgetActivation.id}`}
                                    manualBudgetActivation={manualBudgetActivation}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default ManualBudgetActivations;
