import React, { useState } from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { IMappedExclusionById } from "../../../interfaces/Exclusions";
import { findInArray, onCheckboxChange } from "../../../utils/Shared";
import { INegativeKeywordByCampaignId } from "../../../interfaces/NegativeKeywordColl";
import ToggleSwitch from "../Form/ToggleSwitch";
import useNegativeKeywords from "../../../hooks/useNegativeKeywords";
import usePlacementExclusions from "../../../hooks/usePlacementExclusions";
import { useLocation } from "react-router-dom";

interface IProps {
    value: string;
    name: string;
    label: string;
}

const CampaignCheckbox: React.FC<IProps> = ({ value, name, label }) => {
    const location: string[] = useLocation().pathname.split("/");
    const controllerHook = location.includes("exclusions") ? usePlacementExclusions : useNegativeKeywords; //will choose the controller hook based on location.
    const { handleNew, handleRemove, toggling } = controllerHook();
    const [errorStatus, setErrorStatus] = useState(false);

    const handleToggle = async (field: any, form: FormikProps<INegativeKeywordByCampaignId | IMappedExclusionById>) => {
        const isNew = !findInArray(field, value, "collectionId");

        const resp = isNew ? await handleNew(name, value) : await handleRemove(field, value);

        if (!resp.errors) {
            setErrorStatus(false);
            onCheckboxChange(field, value, name, form);
        } else {
            setErrorStatus(true);
        }
    };

    return (
        <Field name={name}>
            {({ field, form }: FieldProps<IMappedExclusionById | INegativeKeywordByCampaignId>) => {
                const checked = findInArray(field.value, value, "collectionId");

                return (
                    <div className="form-group text-left">
                        <label className="cursor-pointer">
                            <ToggleSwitch
                                field={field.name}
                                value={checked}
                                label={label}
                                onToggle={() => handleToggle(field.value, form)}
                                errorStatus={errorStatus}
                                toggling={toggling}
                            />
                        </label>
                    </div>
                );
            }}
        </Field>
    );
};

export default CampaignCheckbox;
