import React from "react";
import { connect } from "react-redux";
import { getCurrentClientObject } from "../reducers/clients";
import IAppState from "../interfaces/IAppState";

const getDisplayName = (WrappedComponent: { displayName: any; name: any }) =>
    WrappedComponent.displayName || WrappedComponent.name || "Component";

/**
 * Wrap component and inject clientId and currentClient into component props
 * @param {Component} ChildComponent
 */
const withClient = (ChildComponent: any) => {
    const WithChildComponent = (props: JSX.IntrinsicAttributes) => <ChildComponent {...props} />;
    WithChildComponent.displayName = `withClient(${getDisplayName(ChildComponent)})`;

    const mapStateToProps = (state: IAppState, ownProps: any) => {
        return {
            clientId: state.clients.currentClient,
            currentClient: getCurrentClientObject(state)
        };
    };

    return connect(mapStateToProps)(WithChildComponent);
};

export default withClient;
