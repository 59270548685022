export default {
    REQUEST_NEGATIVE_KEYWORD_COLLS: "REQUEST_NEGATIVE_KEYWORD_COLLS",
    REQUEST_NEGATIVE_KEYWORD_COLLS_SUCCESS: "REQUEST_NEGATIVE_KEYWORD_COLLS_SUCCESS",
    REQUEST_NEGATIVE_KEYWORD_COLLS_FAIL: "REQUEST_NEGATIVE_KEYWORD_COLLS_FAIL",

    REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS: "REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS",
    REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_SUCCESS: "REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_SUCCESS",
    REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_FAIL: "REQUEST_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_FAIL",

    REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS: "REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS",
    REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_SUCCESS: "REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_SUCCESS",
    REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_FAIL: "REQUEST_UPDATE_NEGATIVE_KEYWORD_COLL_CAMPAIGNS_FAIL",

    ACTION_TYPE_IDENTIFIER: "NEGATIVE_KEYWORDS",

    NEW_COLLECTION_REGEX: /\bnew\b/i,
    USED_COLLECTION_REGEX: /\bused|cpo|certified\b/i,
    SERVICE_COLLECTION_REGEX: /\bservice|parts?\b/i,

    GENERAL_COLLECTION_ID: 6,
    NEW_COLLECTION_ID: 7,
    USED_COLLECTION_ID: 8,
    SERVICE_COLLECTION_ID: 9
};
