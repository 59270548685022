import React, { useState } from "react";
import { connect } from "react-redux";
import { FormikProps, Formik, Form } from "formik";
import ReportOptions from "./ReportOptions";
import OEMProgram from "../../Shared/OEMProgram";
import { format } from "date-fns";
import { Moment } from "moment";
import moment from "moment";
import * as Yup from "yup";
import { IReportFormValues } from "../../../interfaces/IReport";
import { ConfirmDialog } from "../../Shared";
import Button from "../../Shared/Button";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import DatePickerField from "../../Shared/Form/Blocks/DatePickerField";
import { addFlashMessage } from "../../../actions/flashMessageActions";

const LIMIT = 30;
const DATE_FORMAT = "yyyy-MM-dd";

export const errorStyles = "text-red-500 text-sm italic ml-1";
interface IProps {
    reportHandler: (formValues: IReportFormValues) => void;
    isSubmitting: boolean;
    addMessage: any;
}

const ReportForm: React.FunctionComponent<IProps> = ({ reportHandler, isSubmitting, addMessage }) => {
    const [showMonthPicker, setMonthsPicker] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [shouldConfirm, setShouldConfirm] = useState(false);
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [hasOneDate, setOneDate] = useState(false);

    const initialValues = {
        startDate,
        endDate,
        key: "selection",
        reportType: "",
        oemProgram: ""
    };

    const formatDate = (date: Date) => {
        try {
            return format(date, DATE_FORMAT);
        } catch (e) {
            return "";
        }
    };

    const thereAreMoreReportsThanOurLimit = (
        startDate: Moment | null,
        endDate: Moment | null,
        limit: number,
        reportType: string
    ) => {
        const start = moment(startDate);
        const end = moment(endDate);
        const days = end.diff(start, reportType as any);
        if (days > limit) {
            setShouldConfirm(true);
            return true;
        }
        setShouldConfirm(false);
        return false;
    };

    const handleSubmit = (values: IReportFormValues, { resetForm, setFieldValue }: any) => {
        const reportType = values.reportType.search("monthly") !== -1 ? "months" : "days";

        const weShouldConfirm = thereAreMoreReportsThanOurLimit(values.startDate, values.endDate, LIMIT, reportType);

        if (weShouldConfirm && !hasConfirmed) {
            setShouldConfirm(true);
            return;
        }

        reportHandler(values);
        setShouldConfirm(false);
        setHasConfirmed(false);
        setMonthsPicker(false);
        setStartDate(null);
        setEndDate(null);
        resetForm(initialValues);
    };

    const ReportSchema = Yup.object().shape({
        startDate: Yup.string().required("Required"),
        endDate: Yup.string().required("Required"),
        reportType: Yup.string().required("Required"),
        oemProgram: Yup.string().required("Required")
    });

    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                validationSchema={ReportSchema}
                initialValues={initialValues}
                render={({
                    errors,
                    isValid,
                    values,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                    touched,
                    resetForm
                }: FormikProps<IReportFormValues>) => {
                    return (
                        <Form autoComplete="off" aria-autocomplete={"none"}>
                            <div className="flex flex-wrap justify-between">
                                <div className={`flex-1 relative`}>
                                    <OEMProgram
                                        client={{
                                            oemProgram: values.oemProgram
                                        }}
                                        disabled={isSubmitting}
                                        onValueChange={handleChange}
                                    />
                                    {touched.oemProgram && errors.oemProgram && (
                                        <div className={errorStyles}>{errors.oemProgram}</div>
                                    )}
                                </div>
                                <div className={`flex-1 relative`}>
                                    <div className="ml-4">
                                        <ReportOptions
                                            onChange={(e: any) => {
                                                const reportName = e.target.value;
                                                const isMonthly = reportName.split("-").includes("monthly");

                                                if (isMonthly) {
                                                    setMonthsPicker(true);
                                                }
                                                setFieldValue("endDate", formatDate(new Date()));
                                                setFieldValue("reportType", reportName);
                                            }}
                                            disabled={isSubmitting}
                                            value={values.reportType}
                                        />
                                        {touched.reportType && errors.reportType && (
                                            <div className={errorStyles}>{errors.reportType}</div>
                                        )}
                                    </div>
                                </div>
                                <div className={`flex-1 relative`}>
                                    <div className="ml-4">
                                        <DatePickerField
                                            ariaLabel={"Start Date"}
                                            name={"startDate"}
                                            value={startDate}
                                            maxDate={new Date()}
                                            showMonthYearPicker={showMonthPicker}
                                            onChange={(fieldName: any, date: any) => {
                                                setStartDate(date);
                                                setFieldValue(fieldName, formatDate(date));
                                            }}
                                            placeholder={DATE_FORMAT}
                                        />

                                        {touched.startDate && errors.startDate && (
                                            <div className={errorStyles}>{errors.startDate}</div>
                                        )}
                                    </div>
                                </div>
                                {!hasOneDate && (
                                    <div className={`flex-1 relative`}>
                                        <div className="ml-4">
                                            <DatePickerField
                                                ariaLabel={"End Date"}
                                                name={"endDate"}
                                                value={endDate}
                                                maxDate={new Date()}
                                                showMonthYearPicker={showMonthPicker}
                                                onChange={(fieldName: any, date: any) => {
                                                    setEndDate(date);
                                                    setFieldValue(fieldName, formatDate(date));
                                                }}
                                                placeholder={DATE_FORMAT}
                                            />
                                            {touched.endDate && errors.endDate && (
                                                <div className={errorStyles}>{errors.endDate}</div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className={`ml-4 mt-10 pt-1`}>
                                    <Button
                                        styles={`flex items-center ${
                                            isSubmitting || !isValid ? "opacity-50 " : "hover:bg-blue-600 "
                                        }`}
                                        styleType="primary"
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        <span>Re-Run Report</span>
                                        {isSubmitting ? <RefreshIcon className="ml-1 rotate-fast w-6 h-6" /> : ""}
                                    </Button>
                                </div>
                                {shouldConfirm && (
                                    <ConfirmDialog
                                        title="Confirm re-run reports"
                                        message={`You are going to re-run more than ${LIMIT} reports. Are you sure?`}
                                        onCancel={() => {
                                            setHasConfirmed(false);
                                            setShouldConfirm(false);
                                        }}
                                        onConfirm={() => {
                                            setHasConfirmed(true);
                                            handleSubmit(values, { resetForm, setFieldValue });
                                        }}
                                        confirmText="Rerun"
                                    />
                                )}
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    addMessage: (message: any) => dispatch(addFlashMessage(message))
});

export default connect(null, mapDispatchToProps)(ReportForm);
