import React from "react";
import CloseIcon from "../../Icons/CloseIcon";
import CalendarIcon from "../../Icons/CalendarIcon";

interface IProps {
    name?: string;
    value?: string;
    type?: string;
    ariaLabel?: string;
    onChange?: any;
    onClick?: any;
    onFocus?: any;
    onBlur?: any;
    placeholder?: string;
    className?: string;
    readOnly?: boolean;
    isValid?: boolean;
    isClearable?: boolean;
    onClearClick: () => void;
    inputStyle?: { [key: string]: string };
    id?: string;
}

const defaultClasses = `w-full px-2 py-2 text-base text-gray-800 border placeholder-gray-700 border-gray-400 rounded`;
const errorClasses = `border-red`;
const readOnlyClasses = `text-gray-800 bg-gray-400`;

const DateInput: React.FunctionComponent<IProps> = ({
    name,
    value,
    ariaLabel,
    onChange,
    onBlur,
    onClick,
    onFocus,
    isClearable,
    onClearClick,
    placeholder,
    className,
    inputStyle,
    readOnly = false,
    isValid = true,
    type = "text",
    id
}) => {
    const defaultClassName = `${defaultClasses} ${(readOnly && readOnlyClasses) || ""} ${
        (!isValid && errorClasses) || ""
    }`;

    return (
        <div className={`relative ${className}`}>
            <button className="focus:outline-none cursor-pointer" onClick={onFocus}>
                <CalendarIcon className="w-6 h-6 fill-current absolute inset-y-0 left-0 mt-2 ml-2" />
            </button>
            <input
                name={name}
                data-testid={name}
                value={value}
                onChange={onChange}
                onClick={onClick}
                onBlur={onBlur}
                onFocus={onFocus}
                type={type}
                className={`${defaultClassName} ${className} pl-10`}
                placeholder={placeholder}
                readOnly={readOnly}
                style={inputStyle}
                autoComplete="off"
                aria-label={ariaLabel}
                id={id}
            />

            {isClearable && value && value.length > 0 && (
                <button
                    onClick={onClearClick}
                    className="absolute inset-y-0 right-0 flex items-center text-gray-500 px-5 py-3"
                >
                    <CloseIcon className="w-8 w-8 text-red-500" />
                </button>
            )}
        </div>
    );
};

export default DateInput;
