import IBudgetApi, { IBudgetExpandedClient } from "../../interfaces/Budgets/IBudgetApi";
import IEntity from "../../interfaces/IEntity";
import IActivationApi from "../../interfaces/Budgets/IActivationApi";

export const transformActivationApiResponse = (entity: IActivationApi) => {
    const budget = { ...entity.budget };
    const activation = { ...entity };
    delete activation.budget;
    delete activation.pendingActions;

    return Object.freeze({
        ...budget,
        activation
    });
};

export const transformBudgetApiResponse = (entity: IBudgetExpandedClient) => {
    const budget = { ...entity };
    delete budget.activation;
    delete budget.client;

    const activation = { ...entity.activation };
    delete activation.strategyId;
    const client = { ...entity.client };
    return Object.freeze({
        ...budget,
        activation,
        client
    });
};

export const transformExecuteApiResponse = (entity: IBudgetExpandedClient) => {
    const budget = { ...entity?.activation?.budget };
    const client = { ...entity?.activation?.client };
    const activation = { ...entity.activation };
    delete activation.lastUpdateAction;
    delete activation.strategyId;
    delete activation.budget;
    delete activation.client;
    return Object.freeze({
        ...budget,
        client,
        activation
    });
};
