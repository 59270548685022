import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { array, number, object, string } from "yup";
import VariationsInput from "../Fields/VariationsInput";
import ICompetitors from "../../../../interfaces/DealerSetup/ICompetitorsFields";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";

interface IProps {
    path: string;
    formValues: IFormValues;
    savePage(path: string, validatePage: () => void): void;
}

const Competitors = forwardRef(({ formValues, savePage, path }: IProps, ref: React.Ref<{}>) => {
    const defaults: ICompetitors = {
        fields: {
            competitors: {
                value: {
                    ad_groups: [
                        {
                            value: {
                                name: "",
                                variations: []
                            }
                        }
                    ],
                    negative_keyword_lists: []
                }
            }
        }
    };

    const schema = object({
        competitors: object({
            value: object({
                ad_groups: array().of(
                    object({
                        name: string().required("The Name field is required.").min(1),
                        variations: array().of(string())
                    })
                ),
                negative_keyword_lists: array().of(number())
            })
        })
    });

    const [values, handleFieldChange, changePage] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleCompetitorChange = (index: number, value: any) => {
        handleFieldChange(`competitors.value.ad_groups[${index}]`, value);
    };

    const handleAddCompetitor = () => {
        handleFieldChange(
            `competitors.value.ad_groups[${values.fields.competitors.value.ad_groups.length}]`,
            defaults.fields.competitors.value.ad_groups[0],
            true
        );
    };

    const handleDeleteCompetitor = (index: number) => {
        values.fields.competitors.value.ad_groups.splice(index, 1);
        handleFieldChange(`competitors`, values.fields.competitors, true);
    };

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Competitors Campaign Setup</div>

            <div className="text-lg font-bold mt-4 pb-3 border-b">Dealer Competitors</div>

            {values.fields.competitors.value.ad_groups.map((field: any, index: number) => (
                <VariationsInput
                    index={index}
                    key={index}
                    label={"Competitor"}
                    name="name"
                    value={field.value.name || defaults.fields.competitors.value.ad_groups[0].value.name}
                    variations={
                        field.value.variations || defaults.fields.competitors.value.ad_groups[0].value.variations
                    }
                    className={index > 0 ? "border-t border-gray-400 w-2/3 mt-4" : ""}
                    errors={field.errors}
                    deletable={values.fields.competitors.value.ad_groups.length > 1}
                    handleChange={handleCompetitorChange}
                    handleDelete={handleDeleteCompetitor}
                />
            ))}
            <p className="text-gray-dark">These variations will be in the same AdGroup.</p>

            <button className="text-base font-bold text-blue-500 my-4 pb-3" onClick={handleAddCompetitor}>
                + Add Competitor
            </button>

            {pager()}
        </div>
    );
});

export default Competitors;
