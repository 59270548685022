import { IManualBudgetFormValues } from "../../interfaces/Budgets/IBudgetFormValues";
import * as api from "../../middleware/api";
import { useDispatch } from "react-redux";
import {
    convertFormFieldToManualBudget,
    defineTabs,
    getBudgetsForTable,
    getFilteredBudgets,
    IBudgetTab
} from "../../utils/BudgetUtils";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IManualBudgetApi from "../../interfaces/Budgets/IManualBudgetApi";
import IPagedResponse from "../../interfaces/IPagedResponse";
import IConvertedManualBudget from "../../interfaces/Budgets/IConvertedManualBudget";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";
import buildUrl from "../../utils/UrlUtils";
import useBudgetParams from "./useBudgetParams";
import { addFlashMessage } from "../../actions/flashMessageActions";
import { canScheduleCancellation } from "../../reducers/currentUser";

type useManualBudgetReturn = {
    budget: IManualBudgetFormValues | undefined;
    budgets: IManualBudgetApi[];
    details: IManualBudgetApi | undefined;
    budgetToDelete: IManualBudgetApi | null | undefined;
    budgetType: string;
    isSaving: boolean;
    isReadOnly: boolean;
    loadingBudget: boolean;
    loadingMessage: string;
    missingBudget: boolean;
    apiErrors: any;
    budgetTitle: string;
    pageTitle: string;
    cancelPath: string;
    showConfirm: boolean;
    showDeleted: boolean;
    canScheduleCancel: boolean;
    links: IBudgetTab[];
    fetchAll: () => void;
    fetchAllDeleted: () => void;
    createManualDspBudget: (formValues: IManualBudgetFormValues) => Promise<void>;
    formSubmitHandler: (formValues: IManualBudgetFormValues) => Promise<void>;
    deleteBudgetHandler: (budgetId: number) => void;
    deleteCancelHandler: () => void;
    deleteConfirmHandler: () => void;
    toggleDeletedHandler: () => void;
    searchQueryChangeHandler: (searchQuery: string) => void;
};

interface IFetchManualDspBudgetResponse {
    entities: { [key: string]: { [key: string]: any } };
    nextPageUrl: string | null;
    result: number;
}
export interface IErrors {
    errors?: { [errorField: string]: string[] };
    exceptionClass?: string;
    exceptionTrace?: any;
    message?: string;
}

function useManualBudget(): useManualBudgetReturn {
    const navigate = useNavigate();
    const dispatch = useDispatch(); //redux dispatcher
    const location = useLocation();

    const budgetTypes: { [key: string]: string } = {
        dsp: "DSP",
        "dsp-fdaf": "DSP-FDAF",
        social: "Social",
        "cars-premium-display": "CARS-Premium-Display"
    };
    const {
        budgetType,
        budgetId,
        clientId,
        budgetServices,
        currentUser,
        client: currentClient,
        actionType,
        isReadOnly,
        clients
    } = useBudgetParams();

    const updateMode: boolean = !!budgetId;
    const cancelPath: string = `/admin/oem-reports/${budgetType}`;
    const params = new URLSearchParams(location.search);
    const showDeletedQueryString = params.get("showDeleted");
    const queryParameterShowDeleted: string = Array.isArray(showDeletedQueryString)
        ? showDeletedQueryString[0]
        : showDeletedQueryString ?? "false";
    // State
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [loadingBudget, setLoadingBudget] = useState<boolean>(actionType === "edit" ? true : false);
    const [loadingMessage, setLoadingMessage] = useState<string>("");
    const [budget, setBudget] = useState<IManualBudgetFormValues>();
    const [budgets, setBudgets] = useState<IManualBudgetApi[]>([]);
    const [deletedBudgets, setDeletedBudgets] = useState<IManualBudgetApi[]>([]);
    const [budgetToDelete, setBudgetToDelete] = useState<IManualBudgetApi | null>();

    const [details, setDetails] = useState<IManualBudgetApi>();
    const [apiErrors, setApiErrors] = useState<IErrors>({});
    const [missingBudget, setMissingBudget] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showDeleted, setShowDeleted] = useState(JSON.parse(queryParameterShowDeleted) as boolean);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const canScheduleCancel: boolean = canScheduleCancellation(currentUser);

    // Strings
    const updateTitle = `Update ${budgetServices[budgetType].titleName} Budget`;
    const createTitle = `Create ${budgetServices[budgetType].titleName} Budget`;
    const budgetTitle: string = updateMode ? updateTitle : createTitle;
    const pageTitle: string = `${currentClient?.name} ${updateMode ? updateTitle : createTitle}`.trim();

    const links = defineTabs({
        entities: clients.entities,
        currentClient: clients.currentClient,
        uri: location.pathname
    });

    const flashMessage = ({ type = "danger", text = "Something went wrong!" } = {}) => {
        dispatch(addFlashMessage({ type, text }));
    };

    //delete confirmation modal toggle
    useEffect(() => setShowConfirm(!!budgetToDelete ? true : false), [budgetToDelete]);

    const fetchAll = async () => {
        setLoadingBudget(true);
        setBudgets([]);
        const urlBudgetType = budgetTypes[budgetType].toLocaleLowerCase();
        let url = `/manual-budgets-of-type/${urlBudgetType}`; //all clients
        if (clientId) url = `/clients/${clientId}/manual-budgets-of-type/${budgetType.toLowerCase()}`;

        const filter = Object.assign(
            showDeleted
                ? {
                      onlyTrashed: 1
                  }
                : {},
            searchQuery
                ? {
                      search: searchQuery
                  }
                : {}
        );

        const apiUrl = buildUrl(url, {
            include: ["client.manager", "client.pfm", "lastActivation"],
            filter
        });

        try {
            const {
                entities: { manualBudget }
            }: IFetchManualDspBudgetResponse = await api.getPagedResults(
                apiUrl,
                CLIENT_SCHEMAS.BUDGETS.MANUAL_BUDGETS,
                1000
            );
            manualBudget && setBudgets(getBudgetsForTable(manualBudget, showDeleted));
        } catch (e) {
            setApiErrors({ errors: { ...apiErrors.errors, ...e.errors } });
            flashMessage();
        } finally {
            setLoadingBudget(false);
        }
    };

    const fetchAllDeleted = async () => {
        setLoadingBudget(true);
        setDeletedBudgets([]);
        let url = `/manual-budgets-of-type/${budgetType.toLowerCase()}`;

        if (clientId) url = `/clients/${clientId}/manual-budgets-of-type/${budgetType.toLowerCase()}`;

        const apiUrl = buildUrl(url, {
            expand: {
                client: "*",
                "client.manager": "*",
                "client.pfm": "*",
                lastActivation: "*"
            },
            search: {
                deleted_at: "notnull"
            }
        });

        try {
            const {
                entities: { manualBudget }
            }: IFetchManualDspBudgetResponse = await api.getPagedResults(
                apiUrl,
                CLIENT_SCHEMAS.BUDGETS.MANUAL_BUDGETS,
                1000
            );
            manualBudget && setDeletedBudgets(getBudgetsForTable(manualBudget, showDeleted));
        } catch (e) {
            flashMessage();
        } finally {
            setLoadingBudget(false);
        }
    };

    const fetchSingleManualBudget = async () => {
        setLoadingBudget(true);
        setMissingBudget(false);
        try {
            const url = buildUrl(`/clients/${clientId}/manual-budgets/${budgetId}`, {
                expand: {
                    client: "*",
                    "client.manager": "*",
                    "client.pfm": "*",
                    "lastActivation.createdBy": "*",
                    "manualBudgetActivations.createdBy": "*"
                }
            });
            const response: IFetchManualDspBudgetResponse = await api.getPagedResults(
                url,
                CLIENT_SCHEMAS.BUDGETS.MANUAL_BUDGET
            );
            const budget = response.entities.manualBudget[response.result];
            !budget?.lastActivation?.id && setMissingBudget(true);

            if (budget?.lastActivation?.type?.toLowerCase() !== budgetType.toLowerCase())
                // 404 if the user is trying to take a DSP budget -> Social form or vice versa.
                return flashMessage();

            setBudget(budget.lastActivation);
        } catch (e) {
            setApiErrors({ errors: { ...apiErrors.errors, ...e.errors } });
        } finally {
            setLoadingBudget(false);
        }
    };

    const fetchDetails = async () => {
        if (!budgetId) return;
        const url = buildUrl(`/clients/${clientId}/manual-budgets/${budgetId}`, {
            expand: {
                client: "*",
                "client.manager": "*",
                "client.pfm": "*",
                "lastActivation.createdBy": "*",
                "manualBudgetActivations.createdBy": "*"
            }
        });
        try {
            const { entities, result }: IFetchManualDspBudgetResponse = await api.callApi(
                url,
                CLIENT_SCHEMAS.MANUAL_BUDGETS.MANUAL_BUDGET
            );
            const budget = entities.manualBudgets[result];
            !!budget && setDetails(budget);
            !budget && setMissingBudget(budget);
        } catch (error: any) {
            console.log(error);
        }
    };

    const createManualDspBudget = async (formValues: IManualBudgetFormValues) => {
        setIsSaving(true);
        try {
            const response: IPagedResponse = await api.callApi(`/clients/${clientId}/manual-budgets`, {}, "post", {});
            const manualBudget = response.result as IManualBudgetApi;
            try {
                delete formValues.id;
                const activationBody = convertFormFieldToManualBudget(formValues, budgetTypes[budgetType]);
                await createActivation(clientId ?? 0, manualBudget.id || 0, activationBody);

                flashMessage({ type: "success", text: "Successfully created budget!" });
                navigate(`/client/${clientId}/budgets/${budgetType.toLowerCase()}/${manualBudget.id}/edit`);
            } catch (e) {
                await api.callApi(`/clients/${clientId}/manual-budgets/${manualBudget.id}`, {}, "delete");
                flashMessage();
            }
        } catch (e) {
            setApiErrors({ errors: { ...apiErrors.errors, ...e.errors } });
            flashMessage();
        } finally {
            setIsSaving(false);
        }
    };

    const updateManualDspBudget = async (formValues: IManualBudgetFormValues) => {
        setIsSaving(true);
        try {
            const activationBody = convertFormFieldToManualBudget(
                formValues,
                budgetTypes[budgetType]
            ) as IConvertedManualBudget;
            await createActivation(clientId ?? 0, Number(budgetId), activationBody);
            flashMessage({ type: "success", text: "Successfully updated budget!" });
        } catch (e) {
            setApiErrors({ errors: { ...apiErrors.errors, ...e.errors } });
            flashMessage();
        } finally {
            setIsSaving(false);
        }
    };

    const createActivation = async (clientId: number, budgetId: number, budgetFormValues: IConvertedManualBudget) => {
        const response: IPagedResponse = await api.callApi(
            `/clients/${clientId}/manual-budgets/${budgetId}/manual-budget-activations`,
            {},
            "post",
            budgetFormValues
        );
        return response;
    };

    const deleteBudget = async () => {
        if (!budgetToDelete) return;
        const { id: budgetId, clientId } = budgetToDelete;
        setBudgetToDelete(null);
        setLoadingMessage("Deleting Budget.");
        setLoadingBudget(true);
        setIsSaving(true);
        try {
            await api.callApi(`/clients/${clientId}/manual-budgets/${budgetId}`, {}, "DELETE");
            flashMessage({ type: "success", text: "Successfully deleted budget!" });
            fetchAll();
        } catch (e) {
            flashMessage();
        } finally {
            setIsSaving(false);
            setLoadingBudget(false);
        }
    };

    const deleteConfirmHandler = async () => deleteBudget();
    const deleteBudgetHandler = (budgetId: number) => {
        const budget = budgets.filter((budget: IManualBudgetApi) => budget.id === budgetId)[0];
        setBudgetToDelete(budget);
    };
    const deleteCancelHandler = () => setBudgetToDelete(null);
    const toggleDeletedHandler = () => setShowDeleted(!showDeleted);

    const formSubmitHandler = async (values: IManualBudgetFormValues) => {
        const action = budgetId ? updateManualDspBudget : createManualDspBudget;
        action(values);
    };

    const searchQueryChangeHandler = (searchQuery: string) => setSearchQuery(searchQuery);

    useEffect(() => {
        if (actionType === "edit" && !!budgetId) fetchSingleManualBudget();
        if (actionType === "details") fetchDetails();
    }, [budgetId, actionType]);

    useEffect(() => {
        setBudgets([]);
        setDeletedBudgets([]);
        fetchAll();
    }, [searchQuery, showDeleted, currentClient, budgetType]);

    return {
        fetchAll,
        fetchAllDeleted,
        createManualDspBudget,
        formSubmitHandler,
        deleteBudgetHandler,
        deleteCancelHandler,
        deleteConfirmHandler,
        toggleDeletedHandler,
        searchQueryChangeHandler,
        showConfirm,
        links,
        showDeleted,
        budget,
        budgets,
        budgetToDelete,
        details,
        cancelPath,
        canScheduleCancel,
        budgetType,
        missingBudget,
        budgetTitle,
        isReadOnly,
        pageTitle,
        isSaving,
        loadingBudget,
        loadingMessage,
        apiErrors
    };
}

export default useManualBudget;
