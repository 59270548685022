// If for some unknown reason you are missing the tailwind.generated.css file
// Run npm run build:tailwind, and ensure the prestart and prebuild commands exist
import "./styles/tailwind.generated.css";
import { buildClientStore as reducers } from "./store";
import { renderApp } from "./appRenderer";
import configureStore from "./configureStore";
import createSagaMiddleware from "redux-saga";
import clientsSaga from "./sagas/clientSagas";
import dynamicCampapaignsSagas from "./sagas/Client/dynamicCampaignSagas";
import facebookSagas from "./sagas/facebookSagas";
import userSagas from "./sagas/userSagas";
import usersSagas from "./sagas/usersSagas";
import remarketingSagas from "./sagas/Client/remarketingSagas";
import exclusionsSagas from "./sagas/Client/exclusionsSagas";
import placementExclusionSagas from "./sagas/placementExclusionSagas";
import OEMReportsSagas from "./sagas/OEMReportsSagas";
import negativeKeywordSagas from "./sagas/negativeKeywordSagas";
import { all } from "redux-saga/effects";
import notificationsSaga from "./sagas/notificationSagas";
import inventoryModifierSagas from "./sagas/Client/inventoryModifierSagas";
import clientNegativeKeywordSagas from "./sagas/Client/negativeKeywordSagas";
import salesDataSaga from "./sagas/Client/salesDataImportSaga";
import automobileManufacturersSagas from "./sagas/automobileManufacturersSagas";
import dealerSetupSagas from "./sagas/dealerSetupSagas";
import dynamicCampaignStatusSagas from "./sagas/Client/dynamicCampaignStatusSagas";
import templateBoilerplateSagas from "./sagas/Client/DynamicCampaigns/EtaTemplateBoilerplate/etaTemplateBoilerplateSagas";
import eventTrackingSagas from "./sagas/Analytics/eventTrackingSagas";
import trackingParametersSaga from "./sagas/trackingParametersSagas";
import editHistorySagas from "./sagas/editHistorySagas";
import sitelinkTemplateSagas from "./sagas/sitelinkTemplateSagas";
import { InitEcho } from "./utils/EchoUtils";
import specialOfferTemplateSagas from "./sagas/specialOfferTemplatesSagas";
import authenticationSagas from "./sagas/authenticationSagas";

const sagaMiddleware = createSagaMiddleware();

InitEcho();

const state = {
    currentUser: null
};

const appReducer = configureStore(reducers, state, [sagaMiddleware]);

sagaMiddleware.run(function* () {
    return yield all([
        clientsSaga(),
        dynamicCampapaignsSagas(),
        facebookSagas(),
        remarketingSagas(),
        userSagas(),
        usersSagas(),
        exclusionsSagas(),
        negativeKeywordSagas(),
        notificationsSaga(),
        inventoryModifierSagas(),
        clientNegativeKeywordSagas(),
        salesDataSaga(),
        placementExclusionSagas(),
        dealerSetupSagas(),
        OEMReportsSagas(),
        dynamicCampaignStatusSagas(),
        templateBoilerplateSagas(),
        eventTrackingSagas(),
        automobileManufacturersSagas(),
        trackingParametersSaga(),
        editHistorySagas(),
        sitelinkTemplateSagas(),
        specialOfferTemplateSagas(),
        sitelinkTemplateSagas(),
        authenticationSagas()
    ]);
});

renderApp(appReducer);
