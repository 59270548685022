import React from "react";
import { IEditHistoryActionItem } from "../../../interfaces/EditHistory/IEditHistoryActionItem";
import moment from "moment";
import TimeIcon from "../../Shared/Icons/TimeIcon";

const ItemRestored = ({ activity }: IEditHistoryActionItem) => (
    <div className={`flex flex-wrap items-center border my-2 rounded`}>
        <div className="w-6 ml-2 bg-orange-500 rounded-full">
            <TimeIcon className="w-6 text-white" />
        </div>
        <div className={`flex flex-col justify-between p-2 text-base`}>
            <div className="flex items-center text-sm">
                <div className="underline mr-1">{activity.subject?.name}</div> was restored
            </div>
            <div className="text-xs text-gray-600">
                {[activity.causer?.firstName, activity.causer?.lastName].join(" ").trim()} •{" "}
                {moment(activity.createdAt).format("MMMM Do YYYY, h:mm a")}
            </div>
        </div>
    </div>
);

export default ItemRestored;
