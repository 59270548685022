import React from "react";
import { MentionsInput, Mention } from "react-mentions";

interface IProps {
    fieldName: string;
    onChange: (event: { target: { value: string } }) => void;
    onBlur: () => void;
    className?: string;
    value: string;
    templateOptions: string[];
    placeholder: string;
    isValid: boolean;
    suggestionsportalHost?: Element;
    inputTrasparent?: boolean;
}

const AutoCompleteInput: React.FC<IProps> = ({
    value,
    onBlur,
    isValid,
    onChange,
    className,
    fieldName,
    placeholder,
    templateOptions,
    suggestionsportalHost,
    inputTrasparent
}) => {
    const defaultStyle = {
        control: {
            ...(inputTrasparent ? {} : { backgroundColor: "#fff" }),
            fontSize: "1rem",
            fontWeight: "normal"
        },

        highlighter: {
            overflow: "hidden"
        },
        input: {
            margin: 0
        },
        "&singleLine": {
            control: {
                display: "inline-block",

                width: "100%"
            },

            highlighter: {
                padding: 1,
                border: "2px inset transparent",
                maxWidth: 100
            },

            input: {
                padding: ".50rem 2rem .5rem .5rem",
                borderRadius: ".25rem",
                border: isValid ? "1px solid #B8C2CC" : "1px solid #E3342F",
                backgroundColor: isValid ? "#FFF" : "#FEF7F7",
                textOverflow: "ellipsis"
            }
        },

        suggestions: {
            top: "100%",
            backgroundColor: "hsl(0,0%,100%)",
            borderRadius: "4px",
            boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
            marginBottom: "8px",
            marginTop: "8px",
            zIndex: "1",
            position: "absolute",
            ...(suggestionsportalHost ? { left: 0 } : {}),
            width: "100%",
            boxSizing: "border-box",
            maxHeight: "300px",
            list: {
                maxHeight: "300px",
                overflowY: "auto",
                paddingBottom: "4px",
                paddingTop: "4px",
                position: "relative",
                boxSizing: "border-box"
            },
            item: {
                backgroundColor: "transparent",
                color: "inherit",
                cursor: "default",
                display: "block",
                fontSize: "inherit",
                padding: "8px 12px",
                width: "100%",
                userSelect: "none",
                boxSizing: "border-box",
                "&focused": {
                    backgroundColor: "#DEEBFF"
                }
            }
        }
    };

    return (
        <MentionsInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            singleLine={true}
            className="m w-full"
            classNames={{
                m__input: `${className || ""} ${isValid ? "" : "border-red"}`
            }}
            data-testid={`${fieldName}-input`}
            placeholder={placeholder}
            style={defaultStyle}
            {...(!!suggestionsportalHost ? { suggestionsPortalHost: suggestionsportalHost } : {})}
        >
            <Mention
                markup="{{__id__}}"
                regex={new RegExp("{{(w*)}}")}
                trigger={/({{(\w*))$/}
                data={templateOptions.map((option) => ({
                    id: option,
                    display: option
                }))}
                displayTransform={(item: any) => `{{${item}}}`}
            />
        </MentionsInput>
    );
};

export default AutoCompleteInput;
