import { IAny } from "../interfaces/IAny";

export const sanitizeCsv = (value: string | number): string => {
    if (typeof value === "string" && value.startsWith("=")) {
        value = "\\" + value;
    }

    if (!value) {
        return "";
    }

    return `"${value}"`;
};

export const buildReportFromArraysOfValues = (values: any[], csvNewLineCharacter = "\n"): string => {
    return values.join(csvNewLineCharacter);
};

export const buildReportFromValuesMappedByHeader = (
    values: IAny[],
    headers: { key: string; label?: string }[],
    csvSeparator = ",",
    csvNewLineCharacter = "\n"
): string => {
    const reportRows = values.map((value: IAny) =>
        headers
            .reduce((csvRow: string[], header) => {
                let csvValue = value[header.key];
                if (header.key === "facebookAccountId") {
                    csvValue = value?.facebookAccount?.accountId;
                }
                return csvRow.concat([sanitizeCsv(csvValue ?? null)]);
            }, [])
            .join(csvSeparator)
    );

    const rows = buildReportFromArraysOfValues(reportRows, csvNewLineCharacter);
    const keys = headers.map((key) => key?.label).join(csvSeparator);

    return `${keys}\n${rows}`;
};

export const forcefullySanitizeWholeCsv = (data: string, csvSeparator = ",", csvNewLineCharacter = "\n"): string => {
    // This splits up the report into an array of rows, with arrays of columns inside it.
    const values = data.split("\n").map((row) => row.split(/\,|\t/g));

    const reportData = values.map((value: string[]): string => value.map(sanitizeCsv).join(csvSeparator));

    return buildReportFromArraysOfValues(reportData, csvNewLineCharacter);
};

export const sanitizeExistingCsvTypeOfReport = (data: string): string =>
    data.replace(/[^fa-zA-Z0-9\ \'-\.\$\(\)\/–_&»<>#:’,\"!\n]/g, "");
