import React, { ChangeEvent } from "react";
import SelectField from "./Form/Blocks/SelectField";
import TextField from "./Form/Blocks/TextField";
import { IRemarketingCondition } from "../../interfaces/IRemarketing";
import ModalToggle from "./Dialogs/ModalToggle";
import Button, { DANGER_BUTTON, SECONDARY_BUTTON } from "./Button";
import TrashIcon from "./Icons/TrashIcon";

interface ConditionType {
    value: string;
    comparator: string;
    parameter: string;
}
interface IProps {
    onUpdate(idx: number, item: IRemarketingCondition): void;
    removeCondition(e: any, hide: any): void;
    onValueChange?(item: IItem): void;
    onParameterChange?(item: IItem): void;
    onComparatorChange?(item: IItem): void;
    onComparatorChange?(item: IItem): void;
    setValue?(value: string): void;
    deleted?: boolean;
    parameterName?: string;
    comparatorName?: string;
    valueName?: string;
    valid: boolean;
    conditions?: any;
    condition: ConditionType;
    parameters: string[];
    [options: string]: any;
    idx: number;
    key: number;
}

interface IItem {
    value: string;
}

interface IAllowedComparators {
    [key: string]: boolean;
}
class Condition extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.removeCondition = this.removeCondition.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onParameterChange = this.onParameterChange.bind(this);
        this.onComparatorChange = this.onComparatorChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
    }

    static defaultProps = {
        conditions: [
            { name: "", value: "" },
            { name: "In (Comma Separated List)", value: "IN" },
            { name: "Not In (Comma Separated List)", value: "NOTIN" },
            { name: "Equal To", value: "EQUAL" },
            { name: "Not Equal To", value: "NOT_EQUAL" },
            { name: "Greater Than", value: "GREATER_THAN" },
            { name: "Less than", value: "LESS_THAN" },
            { name: "Greater Than or Equal To", value: "GREATER_THAN_EQUAL" },
            { name: "Less Than or Equal To", value: "LESS_THAN_EQUAL" },
            { name: "Ends With", value: "ENDS_WITH" },
            { name: "Starts With", value: "STARTS_WITH" },
            { name: "Contains", value: "LIKE" },
            { name: "Does Not Contain", value: "NOTLIKE" },
            { name: "Contains Any (Comma Separated List)", value: "IN_LIKE" },
            { name: "Contains None (Comma Separated List)", value: "NOTIN_LIKE" }
        ],
        valueName: "value",
        comparatorName: "comparator",
        parameterName: "parameter",
        valid: true
    };

    removeCondition(hide: () => void) {
        this.props.removeCondition(this.props.idx, this.props.condition);
        hide();
    }

    onValueChange(item: ChangeEvent<HTMLInputElement>) {
        this.props.condition.value = item.target.value;
        this.props.onUpdate(this.props.idx, this.props.condition);
    }

    onParameterChange(item: IItem) {
        this.props.condition.parameter = item.value;
        this.props.onUpdate(this.props.idx, this.props.condition);
    }

    onComparatorChange(item: IItem) {
        this.props.condition.comparator = item.value;
        this.props.onUpdate(this.props.idx, this.props.condition);
    }

    setValue(value: string) {
        if (
            this.props.condition.value &&
            this.props.condition.value.length > 0 &&
            this.props.condition.comparator.search(/IN/i) > -1
        ) {
            value = this.props.condition.value + "," + value;
        }
        this.props.condition.value = value;
        this.props.onUpdate(this.props.idx, this.props.condition);
    }

    renderError() {
        if (Condition.hasSpecialDealDateError(this.props.condition)) {
            return (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <p className="block">
                        You can not use {this.props.condition.comparator} with the special date values
                    </p>
                </div>
            );
        }

        return null;
    }

    static hasSpecialDealDateError(condition: any) {
        const allowedComparators: IAllowedComparators = {
            EQUAL: true,
            NOT_EQUAL: true,
            GREATER_THAN: true,
            LESS_THAN: true,
            LESS_THAN_EQUAL: true,
            GREATER_THAN_EQUAL: true
        };
        if (condition.parameter !== "deal_date") {
            return false;
        }

        if (!condition.value.match(/(-\d+\s*((day)|(month)|(year))s?)/i)) {
            return false;
        }

        if (allowedComparators[condition.comparator]) {
            return false;
        }

        return true;
    }

    render() {
        if (this.props.deleted === true) {
            return null;
        }
        const validationClass = this.props.valid === false ? "has-error" : "";
        return (
            <React.Fragment>
                <div className="flex w-full items-baseline">
                    {this.renderError()}
                    <div className={`section ${validationClass} flex-auto`}>
                        <SelectField
                            name={this.props.parameterName || ""}
                            label="Field"
                            required={true}
                            options={this.props.parameters.map((param) => ({ label: param, value: param }))}
                            value={this.props.condition.parameter}
                            handleChange={this.onParameterChange}
                        />
                    </div>
                    <div className={`section ${validationClass} flex-auto ml-4`}>
                        <SelectField
                            value={this.props.condition.comparator}
                            label="Condition"
                            required={true}
                            options={
                                this.props.conditions
                                    ? this.props.conditions.map((param: any) => ({
                                          label: param.value,
                                          value: param.value
                                      }))
                                    : ""
                            }
                            name={this.props.comparatorName || ""}
                            handleChange={this.onComparatorChange}
                        />
                    </div>
                    <div className={`section ${validationClass} flex flex-auto items-end ml-4`}>
                        <TextField
                            className="flex-1"
                            name={this.props.valueName || ""}
                            label="Value"
                            required={true}
                            handleChange={this.onValueChange}
                            value={this.props.condition.value}
                        />
                        <div>
                            <ModalToggle
                                style={{ width: "20%" }}
                                className="w-1/4"
                                toggleButton={(open: () => void) => (
                                    <button
                                        type="button"
                                        className={`bg-transparent text-gray-600 hover:text-red-600 h-full outline-none mt-2 ml-2`}
                                        onClick={open}
                                    >
                                        <TrashIcon className="w-6 h-6" />
                                    </button>
                                )}
                                modalContent={(hide: () => void) => (
                                    <div>
                                        <div className="text-xl">Are you sure you want to delete this condition?</div>
                                        <div className="flex flex-right items-center pt-4">
                                            <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                                                Cancel
                                            </Button>
                                            <Button
                                                styleType={DANGER_BUTTON}
                                                onClick={() => {
                                                    this.removeCondition(hide);
                                                }}
                                                styles="ml-4"
                                            >
                                                Okay
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Condition;
