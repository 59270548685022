import { createParametersClicked, refreshCampaignClicked } from "../../../../actions/campaignActions";
import trackEvent from "../../../../utils/Analytics/trackEvent";

function* refreshCampaign(action: ReturnType<typeof refreshCampaignClicked>) {
    yield trackEvent({
        category: "Campaigns Index Page",
        action: `refresh_campaign_clicked_${action.clientId}`,
        label: "Refresh Campaign"
    });
}

function* createTrackingParameters(action: ReturnType<typeof createParametersClicked>) {
    yield trackEvent({
        category: "Campaigns Index Page",
        action: `create_tracking_parameters_${action.clientId}`,
        label: "Create Tracking Parameters"
    });
}

export default {
    createTrackingParameters,
    refreshCampaign
};
