/**
 * Created by fjiles@dealerinspire.com on 3/3/17.
 */
import { ADD_FLASH_MESSAGE, CLEAR_FLASH_MESSAGES } from "../actions/flashMessageActions";
import { AnyAction } from "redux";
export default (state = [], action: AnyAction) => {
    switch (action.type) {
        case ADD_FLASH_MESSAGE:
            return [...state, action.message];
        case CLEAR_FLASH_MESSAGES:
            return [];
        default:
            return state;
    }
};
