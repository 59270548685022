import React from "react";
import { Field } from "formik";
import { fieldGroupStyles, labelStyles } from "./NewUser";
import IUserForm from "../../interfaces/IUserForm";

interface IProps {
    role: string;
    isSubmitting: any;
    values: IUserForm;
}

const PermissionField = ({ role, isSubmitting, values }: IProps) => (
    <div className={`${fieldGroupStyles} px-2`}>
        <label className={labelStyles} htmlFor={role}>
            <Field
                type="checkbox"
                disabled={isSubmitting}
                name={role}
                checked={values[role]}
                className="cursor-pointer leading-tight"
            />
            <span className="ml-2">{role.replace(/_/g, " ")}</span>
        </label>
    </div>
);

export default PermissionField;
