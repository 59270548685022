import * as Constants from "../constants/ClientConstants";
import { AnyAction } from "redux";

const reducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case Constants.LOAD_CLIENT_ROLES:
            return { ...state, ...action.response.entities.clientRoles };
        default:
            return state;
    }
};

export const getAllRoles = (state: { clientRoles: any }) => state.clientRoles;

export default reducer;

export const clientRoleConstants = {
    HAS_COUNTRY_EXCLUSIONS: "has_country_exclusion",
    HAS_BULK_PLACEMENT_EXCLUSIONS: "has_bulk_placement_exclusions",
    HAS_COUNTRY_EXCLUSION_US: "has_country_exclusion_us",
    HAS_COUNTRY_EXCLUSION_CA: "has_country_exclusion_ca"
};
