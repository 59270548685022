import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchPagedClients } from "../../../actions/clientActions";
import IAppState from "../../../interfaces/IAppState";
import ClientIndexPage from "./ClientIndexPage";
import IClient from "../../../interfaces/IClient";
import ITableState from "../../../interfaces/ITableState";
import { clientInsights, Insight } from "./Table/ClientInsights";
import {
    getCurrentPaginatedClients,
    getCurrentPaginatedClientsTotalPages,
    hasCurrentPaginatedClientsResults
} from "../../../reducers/clients";
import { NO_INSIGHTS } from "../../../constants/ClientConstants";
import { useLocation } from "react-router";

interface IProps {
    clients: IClient[];
    loading: boolean;
    deleting: boolean;
    restoring: boolean;
    pagination: {
        currentPage: number;
        perPage: number;
        lastPage?: number;
    };
    fetchClients(tableState: ITableState, insight: Insight, search: string): void;
}

const ClientIndexContainer: React.FunctionComponent<IProps> = ({
    clients,
    loading,
    deleting,
    restoring,
    pagination,
    fetchClients
}) => {
    const params = new URLSearchParams(useLocation().search);
    let defaultInsightFromUrl =
        (Object.fromEntries(params.entries())?.insight?.toUpperCase() as Insight) ?? NO_INSIGHTS;
    const [activeInsight, setActiveInsight] = useState<Insight>(defaultInsightFromUrl);

    const changeActiveInsightAndPushToBrowserHistory = (insight: Insight) => {
        setActiveInsight(insight);
        if (insight === NO_INSIGHTS) {
            window.history.replaceState({}, "", `${window.location.pathname}`);
        } else {
            params.set("insight", insight.toLowerCase());
            window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
        }
    };

    return (
        <Fragment>
            <ClientIndexPage
                pageTitle={`Clients - ${clientInsights[activeInsight].title}`}
                dataSource={clients}
                loading={loading}
                deleting={deleting}
                restoring={restoring}
                insightList={clientInsights}
                activeInsight={activeInsight}
                pagination={pagination}
                onFetchData={fetchClients}
                onSetActiveInsight={changeActiveInsightAndPushToBrowserHistory}
            />
        </Fragment>
    );
};

const mapStateToProps = (state: IAppState) => {
    const { page, pageSize } = state.clients.paginatedClients;
    return {
        loading: !hasCurrentPaginatedClientsResults(state),
        deleting: state.clients.deleting,
        restoring: state.clients.restoring,
        pagination: {
            currentPage: page,
            perPage: pageSize,
            lastPage: getCurrentPaginatedClientsTotalPages(state)
        },
        clients: getCurrentPaginatedClients(state)
    };
};

const mapDispatchToProps = {
    fetchClients: fetchPagedClients
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientIndexContainer);
