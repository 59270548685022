import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const NotRunningError: FunctionComponent<{ client: any }> = ({ client }) => {
    if (client && (client.inventoryApiKey === null || client.inventoryApiKey.length === 0)) {
        return (
            <div className="mb-4 border border-red-400 rounded shadow overflow-hidden">
                <div className="py-2 px-4 text-white bg-red-400">
                    <h2 className="m-0 text-xl font-bold">Dynamic Campaigns are not running for this client</h2>
                </div>
                <div className="px-4 py-2 text-red-600 bg-red-100">
                    This client does not have an Inventory API Key configured. To start running Dynamic Campaigns for
                    this client, please set their API Key.
                    <Link
                        to={`/clients/${client.id}`}
                        className="ml-4 text-red-600 hover:text-red-600 focus:text-red-600 underline hover:underline"
                    >
                        Edit Client
                    </Link>
                </div>
            </div>
        );
    }

    return null;
};

export default NotRunningError;
