import React, { FunctionComponent, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, FormikActions } from "formik";
import {
    ISpecialOfferMetaTemplate,
    ISpecialOfferTemplate,
    ISpecialOfferTemplateForm,
    ISpecialOfferTemplatePartValue
} from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import IClient from "../../../interfaces/IClient";
import { connect, useDispatch, useSelector } from "react-redux";
import IAppState from "../../../interfaces/IAppState";
import { Dispatch } from "redux";
import IEntity from "../../../interfaces/IEntity";
import { IDynamicCampaign } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import SpecialOfferTemplatesForm from "./SpecialOfferTemplatesForm";
import { fetchAll } from "../../../actions/dynamicCampaigns/dynamicCampaignActions";
import {
    createSpecialOfferTemplate,
    fetchSpecialOfferTemplate,
    updateSpecialOfferTemplate as updateSpecialOfferTemplateAction
} from "../../../actions/specialOfferTemplateActions";
import * as actions from "../../../actions/specialOfferTemplateActions";
import SpecialOfferTemplateValidationSchema from "./SpecialOfferTemplateValidationSchema";
interface IProps {
    currentClient: IClient;
    specialOfferTemplate?: ISpecialOfferTemplate;
    fetchSpecialOfferTemplate: (clientId: number, specialOfferTemplateId: number) => void;
    fetchSpecialOfferMetaTemplates: (limit: number, page: number) => void;
    loading: boolean;
    metaParameterId?: number;
    metaParameter?: ISpecialOfferMetaTemplate;
    dynamicCampaigns: {
        loading: boolean;
        entities: IEntity<IDynamicCampaign>;
    };
    updateSpecialOfferTemplate: (
        template: ISpecialOfferTemplate,
        dynamicCampaigns: IEntity<IDynamicCampaign>,
        form: ISpecialOfferTemplateForm,
        formActions: Pick<FormikActions<ISpecialOfferTemplateForm>, "setErrors">
    ) => void;
    fetchDynamicCampaigns: (clientId?: number) => void;
}

const UpsertRouter: FunctionComponent<IProps> = ({
    fetchSpecialOfferTemplate,
    fetchSpecialOfferMetaTemplates,
    fetchDynamicCampaigns
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { specialOfferTemplateId } = useParams();
    const isCreateForm = !specialOfferTemplateId;
    const headerText = isCreateForm ? "Create Special Offer Template" : "Edit Special Offer Template";

    const {
        specialOfferTemplates: {
            entities: { data: specialOfferTemplatesData },
            loading: loadingSpecialOfferTemplates
        },
        specialOffersMetaTemplates: {
            entities: { data: specialOffersMetaTemplates },
            loading: loadingSpecialOffersMetaTemplates
        },
        clients: { entities: clients, currentClient: currentClientId },
        dynamicCampaigns
    } = useSelector((state: IAppState) => state);

    const specialOfferTemplate = specialOfferTemplateId ? specialOfferTemplatesData[specialOfferTemplateId] : undefined;
    if (!!specialOfferTemplate && !specialOfferTemplate?.conditionals) {
        specialOfferTemplate.conditionals = []; // fix/FUEL-5912 conditionals must be initialized as []
    }

    const params = new URLSearchParams(location.search);
    const searchQueryParam = Object.fromEntries(params.entries());
    const metaParameterId = Number(specialOfferTemplate ? specialOfferTemplate.metaTemplateId : searchQueryParam?.meta);
    const metaParameter = specialOffersMetaTemplates[metaParameterId];
    const currentClient = clients[currentClientId];
    const loading = loadingSpecialOfferTemplates || loadingSpecialOffersMetaTemplates || dynamicCampaigns.loading;

    const initialValues: ISpecialOfferTemplateForm = Object.assign(
        {
            clientId: Number(currentClient.id),
            metaTemplateId: Number(metaParameterId),
            expiresAt: undefined,
            name: metaParameter?.name || "",
            finalUrl: currentClient.domain || "",
            parameters:
                [
                    metaParameter?.parameters?.reduce(
                        (parameters, parameter: string): { [x: string]: string } => ({
                            ...parameters,
                            [parameter]: ""
                        }),
                        {}
                    )
                ] || [],
            headlines: metaParameter?.headlines ?? [],
            descriptions: metaParameter?.descriptions || [],
            path: metaParameter?.path || []
        },
        specialOfferTemplate
    );

    useEffect(() => {
        if (!specialOfferTemplateId) {
            return;
        }

        fetchSpecialOfferTemplate(Number(currentClient.id), Number(specialOfferTemplateId));
    }, [specialOfferTemplateId, fetchSpecialOfferTemplate]);

    useEffect(() => {
        fetchDynamicCampaigns(currentClient.id);
    }, [currentClient, fetchDynamicCampaigns]);

    useEffect(() => {
        if (metaParameter) {
            return;
        }
        fetchSpecialOfferMetaTemplates(100, 1);
    }, [metaParameter, fetchSpecialOfferMetaTemplates]);

    const submitSpecialOfferTemplate = (
        form: ISpecialOfferTemplateForm,
        formActions: Pick<FormikActions<ISpecialOfferTemplateForm>, "setErrors">
    ) => {
        dispatch(
            createSpecialOfferTemplate(form, formActions, (specialOfferTemplate: ISpecialOfferTemplate) => {
                navigate(`/client/${specialOfferTemplate.clientId}/special-offers/${specialOfferTemplate.id}`);
            })
        );
    };

    const updateSpecialOfferTemplate = (
        specialOfferTemplate: ISpecialOfferTemplate,
        dynamicCampaigns: IEntity<IDynamicCampaign>,
        form: ISpecialOfferTemplateForm,
        formActions: Pick<FormikActions<ISpecialOfferTemplateForm>, "setErrors">
    ) => {
        dispatch(
            updateSpecialOfferTemplateAction(
                specialOfferTemplate,
                dynamicCampaigns,
                form,
                formActions,
                (specialOfferTemplate: ISpecialOfferTemplate) => {
                    navigate(`/client/${specialOfferTemplate.clientId}/special-offers`);
                }
            )
        );
    };

    return (
        <div className="flex flex-col w-full">
            <div className="text-4xl my-4">{headerText}</div>
            <Formik
                onSubmit={(values: ISpecialOfferTemplateForm, formActions) => {
                    if (isCreateForm) {
                        submitSpecialOfferTemplate(values, formActions);
                        return;
                    }

                    if (!specialOfferTemplate) {
                        return;
                    }

                    updateSpecialOfferTemplate(specialOfferTemplate, dynamicCampaigns.entities, values, formActions);
                }}
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={SpecialOfferTemplateValidationSchema}
                render={({ ...formProps }) => {
                    return (
                        <SpecialOfferTemplatesForm
                            dynamicCampaigns={dynamicCampaigns}
                            metaParameter={metaParameter}
                            loading={loading}
                            specialOfferTemplate={specialOfferTemplate}
                            {...formProps}
                        />
                    );
                }}
            />
        </div>
    );
};
const mapStateToProps = (state: IAppState, ownProps: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchSpecialOfferTemplate(clientId: number, specialOfferTemplateId: number) {
        dispatch(fetchSpecialOfferTemplate(clientId, specialOfferTemplateId));
    },
    fetchSpecialOfferMetaTemplates(limit: number, page: number) {
        dispatch(actions.fetchAllSpecialOffersMetaTemplates(page, limit));
    },
    fetchDynamicCampaigns(clientId?: number) {
        dispatch(fetchAll(clientId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UpsertRouter);
