import React from "react";
import RefreshIcon from "../Icons/RefreshIcon";

interface IProps {
    message?: string;
    className?: string;
    iconDimensions?: string;
}

const LoaderSpinner: React.SFC<IProps> = ({ message = "", className = "", iconDimensions = "8" }) => (
    <div className={`${className} text-xl flex items-center text-blue-600`}>
        <RefreshIcon className={`w-${iconDimensions} h-${iconDimensions} rotate-fast fill-current`} />
        <span className="ml-2" data-testid="loading-message">
            {message}
        </span>
    </div>
);

export default LoaderSpinner;
