import Constants from "../constants/EditHistoryConstants";
import IActivity from "../interfaces/EditHistory/IActivity";
import IPaginator from "../interfaces/IPaginator";
import IEntity from "../interfaces/IEntity";
import {
    IFetchEditHistoryForModelFailed,
    IFetchEditHistoryForModelSuccess,
    IFetchEditHistoryForClient,
    IFetchAllEditHistory
} from "../interfaces/EditHistory/Actions";

export const fetchEditHistoryForClient = (id: number, page: number): IFetchEditHistoryForClient => ({
    type: Constants.FETCH_EDIT_HISTORY_FOR_CLIENT_REQUEST,
    payload: {
        id,
        page
    }
});

export const fetchAllEditHistory = (options: any): IFetchAllEditHistory => ({
    type: Constants.FETCH_ALL_EDIT_HISTORY_REQUEST,
    payload: {
        options
    }
});

export const fetchEditHistoryForModelSuccess = (
    entities: IEntity<IActivity>,
    order: number[],
    page: number,
    total: number
): IFetchEditHistoryForModelSuccess => ({
    type: Constants.FETCH_EDIT_HISTORY_SUCCESS,
    payload: {
        entities,
        order,
        page,
        total
    }
});

export const fetchAllEditHistorySuccess = (
    entities: IEntity<IActivity>,
    order: number[],
    page: number,
    total: number
): IFetchEditHistoryForModelSuccess => ({
    type: Constants.FETCH_ALL_EDIT_HISTORY_SUCCESS,
    payload: {
        entities,
        order,
        page,
        total
    }
});

export const fetchEditHistoryForModelFailed = (model: string, id: number): IFetchEditHistoryForModelFailed => ({
    type: Constants.FETCH_EDIT_HISTORY_FAILED,
    payload: {
        model,
        id
    }
});
