import { schema } from "normalizr";
const dynamicCampaign = new schema.Entity("dynamicCampaigns");
const inventoryFilters = new schema.Entity("inventoryFilters");

const Schemas = {
    DYNAMIC_CAMPAIGN: dynamicCampaign,
    DYNAMIC_CAMPAIGNS_ARRAY: { data: [dynamicCampaign] },
    INVENTORY_FILTER: inventoryFilters,
    INVENTORY_FILTERS_ARRAY: { data: [inventoryFilters] }
};

export default Schemas;
