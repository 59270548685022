import React from "react";
import { connect, FormikContext } from "formik";
import IDynamicCampaignFormValue from "../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import Button, { SECONDARY_BUTTON } from "../../../../Shared/Button";
import AddIcon from "../../../../Shared/Icons/AddIcon";
import KeywordTemplate from "./KeywordTemplate";

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const KeywordTemplates = ({
    formik: {
        values: { keywordTemplateRelationships },
        setFieldValue
    }
}: ContextProps) => {
    const handleAddKeywordTemplate = () => {
        setFieldValue(`keywordTemplateRelationships[${keywordTemplateRelationships.length}]`, {
            keywordTemplate: { keywords: [] },
            conditionals: [],
            dirty: true,
            deleted: false,
            new: true
        });
    };

    return (
        <div>
            {keywordTemplateRelationships.map(
                ({ deleted }, index) =>
                    !deleted && <KeywordTemplate key={index} keywordTemplateRelationshipIndex={index} />
            )}

            <div className="flex justify-start">
                <Button onClick={() => handleAddKeywordTemplate()} styleType={SECONDARY_BUTTON} styles="my-4">
                    <div className="flex items-center">
                        <AddIcon className="w-6 h-6" />
                        <span className="ml-2">Add Keyword Template</span>
                    </div>
                </Button>
            </div>
        </div>
    );
};

export default connect<any, any>(KeywordTemplates);
