import * as React from "react";
import { connect, FormikContext, getIn } from "formik";
import IDynamicCampaignFormValue from "../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import TextareaField from "../../../../Shared/Form/Blocks/TextareaField";
import ConditionalsButton from "./KeywordTemplateActionButtons/ConditionalsButton";
import UnlinkButton from "./KeywordTemplateActionButtons/UnlinkButton";
import InsertVariableButton from "./KeywordTemplateActionButtons/InsertVariableButton";
import DuplicateButton from "./KeywordTemplateActionButtons/DuplicateButton";
import RemoveButton from "./KeywordTemplateActionButtons/RemoveButton";

type Props = {
    keywordTemplateRelationshipIndex: number;
};

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const KeywordTemplate = ({
    keywordTemplateRelationshipIndex,
    formik: {
        values: {
            keywordTemplateRelationships: {
                [keywordTemplateRelationshipIndex]: {
                    keywordTemplate: { keywords, isMaster, name }
                }
            }
        },
        handleChange,
        handleBlur,
        errors,
        touched
    }
}: Props & ContextProps) => {
    const fieldPath = `keywordTemplateRelationships[${keywordTemplateRelationshipIndex}]`;
    const keywordFieldName = `${fieldPath}.keywordTemplate.keywords`;

    const handleKeywordTemplateChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        handleChange({
            target: { name: keywordFieldName, value: event.target.value.split("\n") }
        });
        handleChange({ target: { name: `${fieldPath}.dirty`, value: true } });
    };

    return (
        <div>
            {isMaster ? (
                <div className="flex flex-row justify-between -mb-4">
                    <div className="pt-4 text-cerulean-500 uppercase font-sans font-bold text-xs">
                        Linked to keyword template: {name}
                    </div>
                    <div>
                        <ConditionalsButton keywordTemplateRelationshipIndex={keywordTemplateRelationshipIndex} />
                        <UnlinkButton keywordTemplateRelationshipIndex={keywordTemplateRelationshipIndex} />
                    </div>
                </div>
            ) : (
                <div className="flex flex-row justify-end -mb-4 items-center">
                    <ConditionalsButton keywordTemplateRelationshipIndex={keywordTemplateRelationshipIndex} />
                    <InsertVariableButton keywordTemplateRelationshipIndex={keywordTemplateRelationshipIndex} />
                    <DuplicateButton keywordTemplateRelationshipIndex={keywordTemplateRelationshipIndex} />
                    <RemoveButton keywordTemplateRelationshipIndex={keywordTemplateRelationshipIndex} />
                </div>
            )}
            <TextareaField
                placeholder={"Enter one keyword per line."}
                name={keywordFieldName}
                value={keywords?.join("\n") || ""}
                required={true}
                readOnly={!!isMaster}
                onChange={handleKeywordTemplateChange}
                onBlur={handleBlur}
                errors={getIn(errors, keywordFieldName, [])}
                touched={getIn(touched, keywordFieldName, [])}
                rows={10}
            />
        </div>
    );
};

export default connect<Props, any>(KeywordTemplate);
