import React, { ChangeEvent, FC, useState } from "react";
import StateList from "../../../../utils/StateList";
import SelectField from "../../../Shared/Form/Blocks/SelectField";
import TextField from "../../../Shared/Form/Blocks/TextField";

interface OnChangeHandler {
    (event: ChangeEvent<HTMLInputElement>): void;
}

interface Errors {
    [attribute: string]: string[];
}

interface Props {
    address: string | undefined;
    city: string | undefined;
    state: string | undefined;
    zip: string | undefined;
    country: string | undefined;
    errors: Errors;
    onChange: OnChangeHandler;
    updateStateAndCountry: (state: string, country: string) => void;
}

interface Option {
    label: string;
    value: string;
    country: string;
}

const stateProvinceOptions = (): Option[] => {
    const options: Option[] = [];

    Object.entries(StateList).forEach(([countryCode, country]) => {
        country.states.forEach((state) => {
            options.push({ label: state.name, value: state.abbreviation, country: countryCode });
        });
    });

    return options;
};

const Address: FC<Props> = ({ address, city, state, zip, country, errors, onChange, updateStateAndCountry }) => {
    const [countryField, setCountryField] = useState(country);
    const [stateField, setStateField] = useState(state);

    return (
        <div>
            <TextField
                name="address"
                value={address || ""}
                required={false}
                handleChange={onChange}
                errors={errors?.address}
                label="Address"
            />

            <TextField
                name="city"
                value={city || ""}
                required={false}
                handleChange={onChange}
                errors={errors?.city}
                label="City"
            />
            <div className="flex">
                <div className="w-1/3">
                    <SelectField
                        options={stateProvinceOptions()}
                        name="state"
                        value={stateField || ""}
                        required={false}
                        handleChange={(e: Option) => {
                            setStateField(e.value);
                            setCountryField(e.country);

                            updateStateAndCountry(e.value, e.country);
                        }}
                        errors={errors?.state}
                        label={countryField === "CA" ? "Province" : "State"}
                        className="mr-4"
                    />
                </div>
                <div className="w-1/3">
                    <TextField
                        name="zip"
                        value={zip || ""}
                        required={false}
                        handleChange={onChange}
                        errors={errors?.zip}
                        label={countryField === "CA" ? "Postal Code" : "Zip"}
                        className="mr-4"
                    />
                </div>
                <div className="w-1/3">
                    <TextField
                        name="country"
                        value={countryField || ""}
                        readOnly={true}
                        errors={errors?.country}
                        label="Country"
                        className="mr-4"
                    />
                </div>
            </div>
        </div>
    );
};

export default Address;
