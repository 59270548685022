import * as actions from "../../actions/dynamicAds/textTemplatePartConditionalActions";
import dynamicCampaignActions from "../../actions/dynamicCampaignActions";
import _ from "lodash";
import { AnyAction } from "redux";
const getDataFromResponse = (action: AnyAction) => {
    return action.response.entities.dtaTemplatePartConditionals;
};
const templatesConditionalsReducer = (state: { [x: string]: any } = {}, action: AnyAction) => {
    switch (action.type) {
        // case actions.UPDATE_TEMPLATE_PART_CONDITIONAL:
        case actions.CREATE_TEMPLATE_PART_CONDITIONAL:
            if (action.tempId) {
                delete state[action.tempId];
            }
            return { ...state, ...getDataFromResponse(action) };
        case actions.SAVE_TEMPLATE_PART_CONDITIONAL:
        case dynamicCampaignActions.LOAD_DYNAMIC_CAMPAIGN_TEMPLATES:
            return { ...state, ...getDataFromResponse(action) };
        case actions.REMOVE_TEMPLATE_PART_CONDITIONAL:
            delete state[action.conditional.id];
            return { ...state };
        case dynamicCampaignActions.SAVE_TEXT_TEMPLATE_PART:
            if (undefined === action.tempId) {
                return state;
            }
            const result = _.reduce(
                state,
                (result, value, key) => {
                    if (value.templatePartId === action.tempId) {
                        value.templatePartId = action.response.result;
                    }
                    return { ...result, [key]: value };
                },
                state
            );

            return result;
        default:
            return state;
    }
};
export default templatesConditionalsReducer;

const findByTemplatePartId = (state: any, id: any) => {
    return _.filter(state, { templatePartId: id });
};

export { findByTemplatePartId };
