import React from "react";
import moment from "moment";
import numeral from "numeral";

import { LogPreviewContainer, ItemField, UserLog } from "../BudgetDetails/BudgetDetails";

const ManualBudgetActivationPreview = ({ manualBudgetActivation }: any) => {
    const { createdAt, note, previousTargetSpend, targetSpend, createdBy } = manualBudgetActivation;
    const { firstName, lastName } = createdBy;

    return (
        <div className={`${LogPreviewContainer} ${UserLog}`}>
            <div className={ItemField}>{moment(createdAt).format("MM/DD/YYYY")}</div>
            <div className={ItemField}>{`${firstName} ${lastName}`}</div>
            <div className={`flex-auto`}>{note}</div>
            <div className={ItemField}>
                <s>${numeral(previousTargetSpend).format("0,0.00")}</s> - ${numeral(targetSpend).format("0,0.00")}
            </div>
        </div>
    );
};

export default ManualBudgetActivationPreview;
