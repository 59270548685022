import React, { useEffect } from "react";
import PageTitle from "../PageTitle/PageTitle";
import AddItem from "./AddItem";
import SearchItems from "./SearchItems";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import Item from "./Item";
import * as Interfaces from "../../../interfaces/shared/IItemCollection";
import { FixedSizeGrid as Grid } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import BulkUpload from "./BulkUpload";
import FlashMessageList from "../../FlashMessage";
import { useParams } from "react-router-dom";

const Collection: React.FC<Interfaces.ICollectionProps> = ({
    info,
    searchTerm,
    items,
    deleted,
    errors,
    currentPage,
    hasNextPage,
    isNextPageLoading,
    fetchCollection,
    fetchCollections,
    searchItems,
    createItem,
    deleteItem,
    dismissError,
    uploading,
    uploadCSV
}) => {
    const { collectionId } = useParams();
    const routeId = Number(collectionId);
    const loaded = (items[0] && items[0].collectionId == routeId) || !items[0] || false;

    useEffect(() => {
        fetchCollection(routeId, 0, searchTerm);
        if (!info.collectionName) fetchCollections();
    }, [routeId, loaded]);

    useEffect(() => {
        if (loaded && isNextPageLoading && !items.length) {
            fetchCollection(routeId, currentPage, searchTerm);
        }
    }, [searchTerm, routeId]);

    const itemCount = hasNextPage ? items.length + 1 : items.length;

    const loadMoreItems =
        isNextPageLoading || !hasNextPage ? () => {} : () => fetchCollection(routeId, currentPage, searchTerm);

    const isItemLoaded = (index: number) => {
        return !hasNextPage || index < items.length;
    };

    const Row = ({ data, columnIndex, rowIndex, style }: any) => {
        const gridIndex = rowIndex * 3 + columnIndex;

        if (gridIndex >= data.items.length) {
            return (
                <div style={style}>
                    {hasNextPage ? <FullPageLoader message={"Loading Extra Collection Data..."} /> : ""}
                </div>
            );
        }

        return (
            <Item
                style={style}
                description={data.info.itemDescription}
                item={data.items[gridIndex]}
                deleted={data.deleted.includes(data.items[gridIndex].id)}
                deleteItem={data.deleteItem}
                error={data.errors[`${data.items[gridIndex].id}`]}
                dismissError={data.dismissError}
            />
        );
    };

    const EmptyResults = ({ loading }: any) => {
        if (loading) {
            return <FullPageLoader message={"Loading Search Results..."} />;
        }
        return <div className="w-full text-center text-base">No Results</div>;
    };

    if (!loaded) {
        return <FullPageLoader message="Loading Collections..." />;
    }

    return (
        <div className="mx-4">
            <FlashMessageList />
            <PageTitle
                title={
                    info.collectionName
                        ? `${info.collectionDescription} | ${info.collectionName}`
                        : `${info.collectionDescription}`
                }
            />
            <div className="flex flex-col flex-no-wrap flex-grow bg-white rounded shadow ">
                <div className="flex flex-grow-0 items-end p-4">
                    <AddItem info={info} routeId={routeId} createItem={createItem} />
                    <SearchItems
                        info={info}
                        routeId={routeId}
                        searchTerm={searchTerm}
                        searchItems={searchItems}
                        isLoading={isNextPageLoading}
                    />
                    <BulkUpload uploadCSV={uploadCSV} id={routeId} uploading={uploading} />
                </div>
                <div className="flex-1 flex-grow-0 mt-4">
                    <hr className="block border-gray-400" />
                </div>
                <div className="flex-grow">
                    <div className="block w-full pt-4">
                        {items.length ? (
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={loadMoreItems}
                                threshold={50}
                            >
                                {({ onItemsRendered, registerChild }: any) => {
                                    const newItemsRendered = (gridData: any) => {
                                        const endCol = gridData.overscanColumnStopIndex + 1;
                                        const startRow = gridData.overscanRowStartIndex;
                                        const endRow = gridData.overscanRowStopIndex;

                                        const visibleStartIndex = startRow * endCol;
                                        const visibleStopIndex = endRow * endCol;

                                        onItemsRendered({
                                            visibleStartIndex,
                                            visibleStopIndex
                                        });
                                    };
                                    return (
                                        <AutoSizer style={{ height: "calc(100vh - 19rem)" }}>
                                            {({ width, height }: any) => (
                                                <Grid
                                                    onItemsRendered={newItemsRendered}
                                                    ref={registerChild}
                                                    itemData={{
                                                        info,
                                                        items,
                                                        deleted,
                                                        errors,
                                                        deleteItem,
                                                        dismissError
                                                    }}
                                                    height={height}
                                                    width={width}
                                                    columnWidth={Math.floor(width / 3) - 5}
                                                    columnCount={3}
                                                    rowHeight={70}
                                                    rowCount={Math.ceil(itemCount / 3)}
                                                >
                                                    {Row}
                                                </Grid>
                                            )}
                                        </AutoSizer>
                                    );
                                }}
                            </InfiniteLoader>
                        ) : (
                            <EmptyResults loading={isNextPageLoading} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Collection;
