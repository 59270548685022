import TextField from "../../../Shared/Form/Blocks/TextField";
import Button, { PRIMARY_BUTTON } from "../../../Shared/Button";
import RefreshIcon from "../../../Shared/Icons/RefreshIcon";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IClient from "../../../../interfaces/IClient";
import { generateOemConversionsForPixel } from "../../../../actions/facebookActions";
import IAppState from "../../../../interfaces/IAppState";
import CheckIcon from "../../../Shared/Icons/CheckIcon";
import { canSetupOemConversions } from "../../../../reducers/currentUser";

interface IProps {
    client: IClient;
    generateOemConversions: (accountId: number, pixelId: string, oem: string, callback: () => void) => void;
    error?: string;
    loading: boolean;
}

const FacebookOemConversionsGenerateButton = ({
    client,
    generateOemConversions,
    error,
    loading,
    userHasPermissionToViewTheButton
}: IProps & { userHasPermissionToViewTheButton: boolean }) => {
    const [pixelId, setPixelId] = useState("");
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        if (!complete) {
            return;
        }
        const timeout = setTimeout(() => {
            setComplete(false);
            clearTimeout(timeout);
        }, 5000);

        return function cleanup() {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [setComplete, complete]);

    const readableOemName = (oem: string) => {
        switch (oem) {
            case "gm":
            case "gmca":
                return "GM";
            case "ford":
            case "lincoln":
                return "Ford Direct";
            default:
                return oem;
        }
    };

    return (
        <div>
            {userHasPermissionToViewTheButton && (
                <div className="w-full md:w-3/4 lg:w-1/2">
                    <TextField
                        label="Facebook Pixel ID"
                        sublabel={`This will not be saved to the client, only logged on the client's edit history. Generating ${readableOemName(
                            client.oemProgram
                        )} Conversions can be done for multiple pixels.`}
                        name="facebook_pixel_id"
                        value={pixelId}
                        handleChange={(event: any) => setPixelId(event.target.value)}
                        disabled={loading}
                        errors={[error || ""].filter((i) => i)}
                    />

                    <Button
                        styleType={PRIMARY_BUTTON}
                        styles="my-4 border-blue-500"
                        onClick={() => {
                            if (client?.facebookAccount?.accountId)
                                generateOemConversions(
                                    client?.facebookAccount?.accountId,
                                    pixelId,
                                    client.oemProgram,
                                    () => {
                                        setPixelId("");
                                        setComplete(true);
                                    }
                                );
                        }}
                        disabled={loading}
                    >
                        <span className={`flex items-center text-base`}>
                            {!complete && <RefreshIcon className={`w-6 h-6 ${loading ? "rotate-fast" : ""}`} />}
                            {complete && <CheckIcon className="w-6 h-6 text-green-200" />}
                            <span className="ml-2">
                                Generat{loading ? "ing" : complete ? "ed" : "e"} {readableOemName(client.oemProgram)}{" "}
                                Conversions
                            </span>
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    error: state.facebookOemConversions.error,
    loading: state.facebookOemConversions.loading,
    userHasPermissionToViewTheButton: canSetupOemConversions(state.currentUser)
});

const mapDispatchToProps = (dispatch: any) => ({
    generateOemConversions: (accountId: number, pixelId: string, oem: string, callback: () => void) => {
        dispatch(generateOemConversionsForPixel(accountId, pixelId, oem, callback));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookOemConversionsGenerateButton);
