import React from "react";
import InventoryFilter from "./InventoryFilter";
import { IConditional } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import Button, { SECONDARY_BUTTON } from "../../Shared/Button";
import AddIcon from "../../Shared/Icons/AddIcon";

interface IInventoryGroupFilter {
    inventoryGroupFilterField: string | null;
    inventoryGroupFilterComparator: string | null;
    inventoryGroupFilterValue: string | null;
}

interface IProps {
    field: string | null;
    comparator: string | null;
    value: string | null;
    errors: any;
    onChange?: (values: IInventoryGroupFilter) => void;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
    autoFocus?: boolean;
}

const InventoryGroupFilter: React.FC<IProps> = ({
    field,
    comparator,
    value,
    onChange,
    errors,
    setFieldTouched,
    autoFocus = false
}) => {
    const condition: IConditional = {
        field,
        comparator,
        value
    };
    const handleAddFilter = () => {
        onChange &&
            onChange({
                inventoryGroupFilterValue: "",
                inventoryGroupFilterField: "",
                inventoryGroupFilterComparator: ""
            });
    };

    const updateValues = (condition: IConditional) => {
        onChange &&
            onChange({
                inventoryGroupFilterValue: condition.value,
                inventoryGroupFilterField: condition.field,
                inventoryGroupFilterComparator: condition.comparator
            });
    };
    return field === null && comparator === null && value === null ? (
        <React.Fragment>
            <p className="text-base text-blue-800 w-3/4">
                Use this to pause certain ad groups if any vehicle meets this condition. (Example: pause the Used Honda
                Accord ad group if they have any CPO Honda Accords in Stock, so these can funnel into a CPO campaign)
            </p>

            <Button
                type="button"
                styleType={SECONDARY_BUTTON}
                onClick={handleAddFilter}
                styles="mt-4"
                data-testid="add-inventory-filter"
            >
                <div className="flex items-center">
                    <AddIcon className="w-6 h-6" />
                    <span className="ml-2">Add Inventory Filter</span>
                </div>
            </Button>
        </React.Fragment>
    ) : (
        <InventoryFilter
            onRemove={
                onChange
                    ? () => {
                          updateValues({
                              comparator: null,
                              field: null,
                              value: null
                          });
                      }
                    : undefined
            }
            condition={condition}
            errors={[]}
            onChange={updateValues}
            setFieldTouched={setFieldTouched}
            names={{
                field: "inventoryGroupFilterField",
                value: "inventoryGroupFilterValue",
                comparator: "inventoryGroupFilterComparator"
            }}
            autoFocus={autoFocus}
        />
    );
};

export default InventoryGroupFilter;
