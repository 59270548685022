import React, { ChangeEvent, useState } from "react";
import { IAddItemProps } from "../../../interfaces/shared/IItemCollection";
import TextField from "../Form/Blocks/TextField";
import Button, { PRIMARY_BUTTON } from "../Button";

const AddItem: React.FC<IAddItemProps> = ({ info, routeId, createItem }) => {
    const [newItem, setNewItem] = useState("");

    const onAddHandler = () => {
        if (newItem.length) {
            createItem(routeId, newItem);
            setNewItem("");
        }
    };

    const onKeyDownAddHandler = (e: any) => {
        if (e.key === "Enter") {
            onAddHandler();
        }
    };

    return (
        <div className="w-1/3 flex items-end">
            <TextField
                className="flex-1"
                handleChange={(e: ChangeEvent<HTMLInputElement>): void => setNewItem(e.target.value)}
                value={newItem}
                label={`Add ${info.itemsDescription}`}
                name={info.itemsDescription}
                handleKeyDown={onKeyDownAddHandler}
                placeholder={`Add ${info.itemsDescription}...`}
            />
            <div className="flex items-end mx-4">
                <Button styleType={PRIMARY_BUTTON} onClick={onAddHandler}>
                    Add
                </Button>
            </div>
        </div>
    );
};

export default AddItem;
