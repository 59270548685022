import IBudgetFormValues from "../../interfaces/Budgets/IBudgetFormValues";
import BudgetConstants from "../../constants/budgets/BudgetConstants";
import IClient from "../../interfaces/IClient";

export const fetchAllBudgets = () => ({
    type: BudgetConstants.REQUEST_LOAD_BUDGETS
});

export const deleteBudget = (budget: any) => ({
    type: BudgetConstants.REQUEST_DELETE_BUDGET,
    budget
});

export const confirmDelete = () => ({
    type: BudgetConstants.REQUEST_DELETE_BUDGET_CONFIRM,
    payload: {
        loadingMessage: "Deleting budget"
    }
});

export const cancelDelete = () => ({
    type: BudgetConstants.REQUEST_DELETE_BUDGET_CANCEL
});

export const hideDeleteDialog = () => ({
    type: BudgetConstants.HIDE_DELETE_CONFIRM
});

export const hideNotification = () => ({
    type: BudgetConstants.HIDE_NOTIFICATION
});

export const showNotification = (notification: any) => ({
    type: BudgetConstants.SHOW_NOTIFICATION,
    notification
});

export const pushBudget = (budget: any) => ({
    type: BudgetConstants.REQUEST_PUSH_BUDGET,
    budget
});

export const fetchClientAdwordsBudgets = (clientId: number) => ({
    type: BudgetConstants.REQUEST_CLIENT_ADWORDS_BUDGETS,
    clientId
});

export const fetchDeletedBudgets = () => ({
    type: BudgetConstants.REQUEST_DELETED_BUDGETS
});

export const fetchDeletedSuccess = (response: any) => ({
    type: BudgetConstants.REQUEST_DELETED_BUDGETS_SUCCESS,
    response
});

export const removeDeletedBudgets = (budgets: any) => ({
    type: BudgetConstants.REMOVE_DELETED_BUDGETS,
    budgets
});

export const fetchDeletedClientBudgets = (clientId: number) => ({
    type: BudgetConstants.REQUEST_DELETED_BUDGETS,
    clientId
});

export const createBudget = (formValues: IBudgetFormValues, clientId: number, serviceId: number) => ({
    type: BudgetConstants.REQUEST_CREATE_BUDGET,
    payload: {
        serviceId,
        formValues,
        clientId,
        isSubmitting: true,
        message: "Creating Budget"
    }
});

export const updateBudget = (formValues: IBudgetFormValues, clientId: number, serviceId: number, budgetId: string) => ({
    type: BudgetConstants.REQUEST_UPDATE_BUDGET,
    payload: {
        formValues,
        clientId,
        budgetId,
        serviceId,
        isSubmitting: true,
        message: "Updating budget."
    }
});

export const createBudgetSuccess = () => ({
    type: BudgetConstants.REQUEST_CREATE_BUDGET_SUCCESS,
    payload: {
        message: "Successfully created budget!",
        errors: {},
        isSubmitting: false
    }
});

export const createBudgetFail = (message: string, errors: any) => ({
    type: BudgetConstants.REQUEST_CREATE_BUDGET_FAIL,
    payload: {
        message,
        errors,
        isSubmitting: false
    }
});

export const loading = (message: string = "Loading budgets") => ({
    type: BudgetConstants.LOADING_BUDGET,
    payload: {
        message
    }
});

export const fetchSingleBudget = (clientId: number, budgetId: string) => ({
    type: BudgetConstants.REQUEST_SINGLE_CLIENT_BUDGET,
    payload: {
        clientId,
        budgetId,
        loadingMessage: "Loading Budget",
        loading: true
    }
});

export const fetchBudgetFail = () => ({
    type: BudgetConstants.REQUEST_BUDGETS_FAIL,
    payload: {
        loading: false
    }
});

export const fetchBudgetSuccess = (budgets: any) => ({
    type: BudgetConstants.REQUEST_BUDGET_SUCCESS,
    payload: {
        budgets,
        loading: false,
        loadingMessage: ""
    }
});

export const loadingSpinner = (loadingMessage: string = "Loading Budgets") => ({
    type: BudgetConstants.REQUEST_BUDGET_SPINNER,
    payload: {
        loadingMessage
    }
});

export const requestBudgetLogs = (clientId: number, budgetId: string) => ({
    type: BudgetConstants.REQUEST_BUDGET_LOGS,
    payload: {
        clientId,
        budgetId
    }
});

export const requestBudgetLogsSuccess = (changeLogs: any) => ({
    type: BudgetConstants.REQUEST_BUDGET_LOGS_SUCCESS,
    payload: changeLogs
});

export const requestBudgetLogsFail = (error: any) => ({
    type: BudgetConstants.REQUEST_BUDGET_LOGS_FAIL,
    payload: {
        error
    }
});

export const requestSystemLogs = (clientId: number, budgetId: string) => ({
    type: BudgetConstants.REQUEST_SYSTEM_LOGS,
    payload: { clientId, budgetId }
});

export const requestSystemLogsSuccess = (logs: null | object) => ({
    type: BudgetConstants.REQUEST_SYSTEM_LOGS_SUCCESS,
    payload: logs
});

export const requestSystemLogsFail = (error: any) => ({
    type: BudgetConstants.REQUEST_SYSTEM_LOGS_FAIL,
    payload: {
        error
    }
});

export const requestBudgetDetails = (budgetId: string) => ({
    type: BudgetConstants.REQUEST_BUDGET_DETAILS,
    payload: {
        budgetId
    }
});

export const requestBudgetDetailsSuccess = (budget: any) => ({
    type: BudgetConstants.REQUEST_BUDGET_DETAILS_SUCCESS,
    payload: budget
});

export const requestBudgetDetailsFail = (error: any) => ({
    type: BudgetConstants.REQUEST_BUDGET_DETAILS_FAIL,
    payload: {
        error
    }
});

export const downloadCsvClicked = (clientId: number, client: IClient) => ({
    type: BudgetConstants.DOWNLOAD_CSV_CLICKED,
    clientId,
    client
});
