import * as actions from "../../actions/facebookActions";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";
import { AnyAction } from "redux";

const OEMS_WITH_CUSTOM_CONVERSIONS = ["gm", "gmca", "ford", "lincoln"];

const reducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.SUCCESSFULLY_GENERATED_OEM_CONVERSIONS:
        case actions.FAILED_TO_GENERATE_OEM_CONVERSIONS:
            return {
                ...state,
                loading: false,
                error: action.payload?.message
            };
        case actions.GENERATE_OEM_CONVERSIONS:
            return {
                ...state,
                loading: true,
                error: action.payload?.message
            };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const oemHasCustomConversions = (oem: string) => {
    return OEMS_WITH_CUSTOM_CONVERSIONS.includes(oem);
};

export default reducer;

export { oemHasCustomConversions };
