import {
    ISpecialOfferTemplate,
    ISpecialOfferTemplateForm
} from "../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import { FormikActions } from "formik";
import { IDynamicCampaign } from "../interfaces/DynamicCampaigns/IDynamicCampaign";
import IEntity from "../interfaces/IEntity";
import constants from "../constants/SpecialOfferTemplateConstants";
import { IFetchAllResponse } from "../sagas/specialOfferTemplatesSagas";

export const fetchAllSpecialOfferTemplates = (
    clientId: number,
    page: number,
    limit: number,
    specialOfferTemplateId?: any
) => ({
    type: constants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES,
    payload: {
        clientId,
        page,
        limit,
        specialOfferTemplateId
    }
});

export const fetchAllSpecialOfferTemplateSuccess = (response: IFetchAllResponse) => ({
    type: constants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES_SUCCESS,
    payload: {
        ...response
    } as IFetchAllResponse
});

export const fetchAllSpecialOfferTemplateFailed = (
    clientId: number,
    page: number,
    limit: number,
    specialOfferTemplateId?: any
) => ({
    type: constants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES_FAILED,
    payload: {
        clientId,
        page,
        limit,
        specialOfferTemplateId
    }
});

export const fetchSpecialOfferTemplate = (clientId: number, templateId: number) => ({
    type: constants.FETCH_SPECIAL_OFFER_TEMPLATE,
    payload: {
        clientId,
        templateId
    }
});

export const fetchSpecialOfferTemplateSuccess = (response: IFetchAllResponse) => ({
    type: constants.FETCH_SPECIAL_OFFER_TEMPLATE_SUCCESS,
    payload: {
        ...response
    } as IFetchAllResponse
});

export const fetchSpecialOfferTemplateFailed = () => ({
    type: constants.FETCH_SPECIAL_OFFER_TEMPLATE_FAILED
});

export const fetchAllSpecialOffersMetaTemplates = (page: number, limit: number) => ({
    type: constants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES,
    payload: {
        page,
        limit
    }
});

export const fetchAllSpecialOffersMetaTemplateSuccess = (response: IFetchAllResponse) => ({
    type: constants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_SUCCESS,
    payload: {
        ...response
    }
});

export const fetchAllSpecialOffersMetaTemplateFailed = (clientId: number, page: number, limit: number) => ({
    type: constants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES_FAILED,
    payload: {
        page,
        limit
    }
});

export type IFetchAllSpecialOfferTemplates = ReturnType<typeof fetchAllSpecialOfferTemplates>;
export type IFetchAllSuccessSpecialOfferTemplates = ReturnType<typeof fetchAllSpecialOfferTemplateSuccess>;
export type IFetchAllFailedSpecialOfferTemplates = ReturnType<typeof fetchAllSpecialOfferTemplateFailed>;

export type IFetchSpecialOfferTemplate = ReturnType<typeof fetchSpecialOfferTemplate>;

export type IFetchAllSpecialOffersMetaTemplates = ReturnType<typeof fetchAllSpecialOffersMetaTemplates>;
export type IFetchAllSuccessSpecialOffersMetaTemplates = ReturnType<typeof fetchAllSpecialOffersMetaTemplateSuccess>;

export const deleteSpecialOfferTemplate = (clientId: number, specialOfferTemplateId: number) => ({
    type: constants.DELETE_SPECIAL_OFFER_TEMPLATES,
    payload: {
        clientId,
        specialOfferTemplateId
    }
});

export type IDeleteSpecialOfferTemplate = ReturnType<typeof deleteSpecialOfferTemplate>;

export const deleteSpecialOfferTemplateSuccess = (specialOfferTemplateId: number) => ({
    type: constants.DELETE_SPECIAL_OFFER_TEMPLATES_SUCCESS,
    payload: {
        specialOfferTemplateId
    }
});

export const deleteSpecialOfferTemplateFailed = (errors: any) => ({
    type: constants.DELETE_SPECIAL_OFFER_TEMPLATES_FAILED,
    payload: {
        errors
    }
});

export const createSpecialOfferTemplate = (
    formData: ISpecialOfferTemplateForm,
    formActions: Pick<FormikActions<ISpecialOfferTemplateForm>, "setErrors">,
    callback: (specialOfferTemplate: ISpecialOfferTemplate) => void
) => ({
    type: constants.CREATE_SPECIAL_OFFER_TEMPLATE,
    payload: {
        formData,
        formActions,
        callback
    }
});

export type ICreateSpecialOfferTemplate = ReturnType<typeof createSpecialOfferTemplate>;

export const createSpecialOfferTemplateSuccess = (specialOfferTemplate: ISpecialOfferTemplate) => ({
    type: constants.CREATE_SPECIAL_OFFER_TEMPLATE_SUCCESS,
    payload: {
        specialOfferTemplate
    }
});

export const createSpecialOfferTemplateFailed = (errors: any) => ({
    type: constants.CREATE_SPECIAL_OFFER_TEMPLATE_FAILED,
    errors
});

export const updateSpecialOfferTemplate = (
    specialOfferTemplate: ISpecialOfferTemplate,
    dynamicCampaigns: IEntity<IDynamicCampaign>,
    formData: ISpecialOfferTemplateForm,
    formActions: Pick<FormikActions<ISpecialOfferTemplateForm>, "setErrors">,
    callback: (specialOfferTemplate: ISpecialOfferTemplate) => void
) => ({
    type: constants.UPDATE_SPECIAL_OFFER_TEMPLATE,
    payload: {
        specialOfferTemplate,
        currentDynamicCampaigns: dynamicCampaigns,
        formData,
        formActions,
        callback
    }
});

export type IUpdateSpecialOfferTemplate = ReturnType<typeof updateSpecialOfferTemplate>;

export const updateSpecialOfferTemplateSuccess = (specialOfferTemplate: ISpecialOfferTemplate) => ({
    type: constants.UPDATE_SPECIAL_OFFER_TEMPLATE_SUCCESS,
    payload: {
        specialOfferTemplate
    }
});

export const updateSpecialOfferTemplateFailed = (errors: any) => ({
    type: constants.UPDATE_SPECIAL_OFFER_TEMPLATE_FAILED,
    errors
});

export const detachSpecialOfferTemplate = (
    clientId: number,
    specialOfferTemplateId: number,
    dynamicCampaignId: number
) => ({
    type: constants.DETACH_SPECIAL_OFFER_TEMPLATE,
    payload: {
        clientId,
        specialOfferTemplateId,
        dynamicCampaignId
    }
});

export type IDetachSpecialOfferTemplateAction = ReturnType<typeof detachSpecialOfferTemplate>;
