import React from "react";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import { Link } from "react-router-dom";
import AddIcon from "../../Shared/Icons/AddIcon";

interface IProps {
    showButton?: boolean;
}

const Header = ({ showButton }: IProps) => {
    return (
        <PageTitle title="Remarketing Exports">
            {showButton === true && (
                <Link to={`new`} className="bg-blue-500 text-blue-100 flex items-center px-4 py-2 rounded">
                    <AddIcon className="w-6 h-6" />
                    <span className="ml-2">New Export</span>
                </Link>
            )}
        </PageTitle>
    );
};

export default Header;
