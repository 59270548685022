import IUserForm from "../interfaces/IUserForm";
import IUserRole from "../interfaces/IUserRole";
import IEntity from "../interfaces/IEntity";
import IUserApi from "../interfaces/IUserApi";
import IUser from "../interfaces/IUser";
import ISelectedUser from "../interfaces/ISelectedUser";
import ISettingsForm from "../interfaces/ISettingsForm";
import IUserSettings from "../interfaces/IUserSettings";
import { decamelizeKeys } from "humps";

const ROLE_ID_LOGIN = 1;
const ROLE_ID_SCHEDULED_TASK_RUNNER = 5;

interface IMappedRoles {
    [key: string]: boolean;
}

interface ISettingsFormPost extends ISettingsForm {
    password?: string;
    passwordConfirm?: string;
}

const getSelectedRoles = (formValues: IUserForm, roles: IEntity<IUserRole>): number[] => {
    return Object.keys(formValues).reduce<number[]>(
        (arr, fieldName) => {
            const role = Object.keys(roles).find((roleId) => roles[roleId].name === fieldName);
            if (role && formValues[fieldName] && arr.indexOf(roles[role].id) < 0) {
                arr.push(roles[role].id);
            }
            return arr;
        },
        [ROLE_ID_LOGIN, ROLE_ID_SCHEDULED_TASK_RUNNER]
    );
};

const mapFormToUser = (formValues: IUserForm, roles: IEntity<IUserRole>, selectedUser?: ISelectedUser): IUserApi => {
    const { email, firstName, lastName, isAdmin } = formValues;
    const selectedRoles = getSelectedRoles(formValues, roles);
    const isAdminBoolean = isAdmin === "true" ? true : false;
    const id = selectedUser ? selectedUser.id : null;
    const hasEmail = !selectedUser ? email : selectedUser.email !== email ? email : null;

    return decamelizeKeys({
        firstName,
        lastName,
        isAdmin: isAdminBoolean,
        roles: selectedRoles,
        ...(id && { id }),
        ...(hasEmail && { email: hasEmail })
    }) as IUserApi;
};

const mapRolesToForm = (roles: IUserRole[]): IMappedRoles => {
    return roles.reduce((acc: IMappedRoles, role: IUserRole) => {
        acc[role.name] = true;
        return acc;
    }, {});
};

const mapUserToForm = (user: IUser): ISelectedUser | null => {
    if (!user) {
        return null;
    }
    const { email, firstName, lastName, isAdmin, roles, id } = user;

    return {
        id,
        email,
        firstName,
        lastName,
        isAdmin: String(isAdmin),
        ...mapRolesToForm(roles)
    };
};

const mapSettingsToForm = (user: IUser): ISettingsForm | null => {
    if (!user) {
        return null;
    }
    const { firstName, lastName, email } = user;
    return { firstName, lastName, email };
};

const mapFormToSettings = (formValues: ISettingsFormPost, user: any): IUserSettings => {
    const { id, email } = user;
    const { firstName, lastName, password, email: newEmail } = formValues;
    const returnObject = { id } as IUserSettings;

    if (firstName.toLowerCase().trim() !== user.firstName.toLowerCase().trim()) {
        returnObject.first_name = firstName;
    }

    if (lastName.toLowerCase().trim() !== user.lastName.toLowerCase().trim()) {
        returnObject.last_name = lastName;
    }

    if (password) {
        returnObject.password = password;
    }

    if (email.toLowerCase().trim() !== newEmail.toLowerCase().trim()) {
        returnObject.email = newEmail;
    }

    return returnObject;
};

export default {
    getSelectedRoles,
    mapFormToUser,
    mapUserToForm,

    mapSettingsToForm,
    mapFormToSettings
};
