import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { useLocation, useNavigate } from "react-router-dom";
import buildUrl from "../../utils/UrlUtils";
import SearchFilter from "./Table/SearchFilter";
import { JsxElement } from "typescript";
interface IComponent extends JsxElement {
    onChange: (text: string) => void;
    value: string;
}

interface IProps {
    url: string;
    onChange: (searchQuery: string) => void;
    useSearchHistory: boolean;
    queryParamName: string;
    InputComponent?: IComponent | any;
    extraQueryParams?:
        | any
        | {
              showDeleted?: boolean;
          };
    debounceTimeout?: number;
    value?: string;
}

const SearchInput = ({
    url,
    onChange,
    useSearchHistory = true,
    queryParamName = "search",
    InputComponent,
    extraQueryParams,
    value,
    debounceTimeout = 400,
    ...props
}: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const searchQueryParam = Object.fromEntries(params.entries());
    const searchQueryValue: string = (searchQueryParam[queryParamName] as string) ?? "";

    const [rawSearchQuery, setSearchQuery] = useState(searchQueryValue);
    const searchQuery: string = useDebounce(rawSearchQuery, debounceTimeout) as string;
    useEffect(() => {
        if (useSearchHistory) {
            navigate(
                buildUrl(url, {
                    ...extraQueryParams,
                    [queryParamName]: searchQuery
                })
            );
        }

        onChange(searchQuery);
    }, [searchQuery, extraQueryParams?.showDeleted]);

    if (typeof InputComponent === "undefined") {
        return (
            <SearchFilter searchText={rawSearchQuery} onSearchChange={(event) => setSearchQuery(event.target.value)} />
        );
    }

    return (
        <InputComponent
            value={rawSearchQuery}
            onChange={(event: any) => setSearchQuery(event.target.value)}
            {...props}
        />
    );
};

export default SearchInput;
