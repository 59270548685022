export default {
    FETCH_TRACKING_PARAMETERS: "FETCH_TRACKING_PARAMETERS",
    FETCH_TRACKING_PARAMETERS_SUCCESS: "FETCH_TRACKING_PARAMETERS_SUCCESS",
    FETCH_TRACKING_PARAMETERS_FAILED: "FETCH_TRACKING_PARAMETERS_FAILED",

    DELETE_TRACKING_PARAMETER: "DELETE_TRACKING_PARAMETER",
    DELETE_TRACKING_PARAMETER_SUCCESS: "DELETE_TRACKING_PARAMETER_SUCCESS",
    DELETE_TRACKING_PARAMETER_FAILED: "DELETE_TRACKING_PARAMETER_FAILED",

    CREATE_TRACKING_PARAMETER: "CREATE_TRACKING_PARAMETER",
    CREATE_TRACKING_PARAMETER_SUCCESS: "CREATE_TRACKING_PARAMETER_SUCCESS",
    CREATE_TRACKING_PARAMETER_FAILED: "CREATE_TRACKING_PARAMETER_FAILED",

    LINK_TRACKING_PARAMETER_TO_CAMPAIGN: "LINK_TRACKING_PARAMETER_TO_CAMPAIGN",
    LINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS: "LINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS",
    LINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED: "LINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED",

    UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN: "UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN",
    UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS: "UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS",
    UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED: "UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED",

    UPDATE_TRACKING_PARAMETER: "UPDATE_TRACKING_PARAMETER",
    UPDATE_TRACKING_PARAMETER_SUCCESS: "UPDATE_TRACKING_PARAMETER_SUCCESS",
    UPDATE_TRACKING_PARAMETER_FAILED: "UPDATE_TRACKING_PARAMETER_FAILED"
};
