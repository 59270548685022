import { DYNAMIC_CAMPAIGN_CHANNEL, DYNAMIC_CAMPAIGN_STATUS_EVENT } from "./constants/EchoConstants";

export function addDynamicCampaignStatusListener(dynamicCampaignId: number, cb: (event: any) => void) {
    (window as any).Echo.private(DYNAMIC_CAMPAIGN_CHANNEL(dynamicCampaignId)).listen(DYNAMIC_CAMPAIGN_STATUS_EVENT, cb);
}

export function removeDynamicCampaignStatusListener(dynamicCampaignId: number) {
    (window as any).Echo.private(DYNAMIC_CAMPAIGN_CHANNEL(dynamicCampaignId)).stopListening(
        DYNAMIC_CAMPAIGN_STATUS_EVENT
    );
}
