import React, { FunctionComponent } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import UsersTable from "./Table";
import PageTitle from "../Shared/PageTitle/PageTitle";
import NewUser from "./NewUser";
import EditUser from "./EditUser";
import FlashMessageList from "../FlashMessage";
import UserSettings from "./UserSettings";

const UsersContainer: FunctionComponent = () => {
    const location = useLocation();
    return (
        <>
            <div className="mx-4 mb-4">
                <PageTitle title="Users">
                    {location!.pathname === "/users" && (
                        <div className="flex items-center">
                            <Link
                                to="new"
                                className="bg-blue-500 hover:bg-blue-700 text-white text-base py-2 px-4 rounded"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mb-1 h-6 w-6 inline-block text-white fill-current"
                                    viewBox="0 0 25 25"
                                >
                                    <path d="M19.867 13.633h-6.234v6.234a1.133 1.133 0 01-2.266 0v-6.234H5.133a1.133 1.133 0 010-2.266h6.234V5.133a1.133 1.133 0 112.266 0v6.234h6.234a1.133 1.133 0 010 2.266z" />
                                </svg>
                                <span className="ml-2 text-white">New User</span>
                            </Link>
                        </div>
                    )}
                </PageTitle>
                <FlashMessageList />
                <Routes>
                    <Route element={<UsersTable />} path="/" />
                    <Route element={<NewUser />} path="new" />
                    <Route element={<EditUser />} path="edit/:userId" />
                    <Route element={<UserSettings />} path="settings" />
                </Routes>
            </div>
        </>
    );
};

export default UsersContainer;
