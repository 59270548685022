import IEntity from "../interfaces/IEntity";
import { ITrackingParametersForClientPayload } from "../interfaces/actions/ITrackingParameterActions";
import ITrackingParameter, {
    IArrayOfCampaigns,
    ILinkCampaignToTrackingParameters,
    IRequiresClientId,
    IUnlinkCampaignToTrackingParameters
} from "../interfaces/ITrackingParameter";
import TrackingParameterConstants from "../constants/TrackingParameterConstants";
import ITrackingParameterFormValues from "../interfaces/ITrackingParameterFormValues";
import { ITrackingParameterCampaignRelation } from "../interfaces/Campaigns";

export const fetchTrackingParameters = (clientId: number): ITrackingParametersForClientPayload => ({
    type: TrackingParameterConstants.FETCH_TRACKING_PARAMETERS,
    payload: {
        clientId
    }
});

export const deleteTrackingParameter = (trackingParameter: ITrackingParameter) => ({
    type: TrackingParameterConstants.DELETE_TRACKING_PARAMETER,
    payload: {
        trackingParameter
    }
});

export const fetchTrackingParameterListSuccess = ({
    trackedCampaigns,
    trackingParameters
}: {
    trackedCampaigns: IEntity<ITrackingParameterCampaignRelation>;
    trackingParameters: IEntity<ITrackingParameter>;
}) => ({
    type: TrackingParameterConstants.FETCH_TRACKING_PARAMETERS_SUCCESS,
    payload: {
        trackedCampaigns,
        trackingParameters
    }
});

export const fetchTrackingParameterListFailure = (errorMessage: string) => ({
    type: TrackingParameterConstants.FETCH_TRACKING_PARAMETERS_FAILED,
    payload: {
        errorMessage
    }
});

export const deleteTrackingParameterListSuccess = (trackingParameterId: number) => ({
    type: TrackingParameterConstants.DELETE_TRACKING_PARAMETER_SUCCESS,
    payload: {
        trackingParameterId
    }
});

export const deleteTrackingParameterListFailure = (message: string) => ({
    type: TrackingParameterConstants.DELETE_TRACKING_PARAMETER_FAILED,
    payload: {
        message
    }
});

export const createTrackingParameterListSuccess = (
    trackingParameter: ITrackingParameter,
    { trackedCampaigns }: { trackedCampaigns: string[] }
) => ({
    type: TrackingParameterConstants.CREATE_TRACKING_PARAMETER_SUCCESS,
    payload: {
        trackingParameter,
        trackedCampaigns
    }
});

export const createTrackingParameter = (
    formData: ITrackingParameterFormValues & IArrayOfCampaigns & IRequiresClientId,
    callback: (trackingParameter: ITrackingParameter) => void
) => ({
    type: TrackingParameterConstants.CREATE_TRACKING_PARAMETER,
    payload: {
        formData,
        callback
    }
});

export const createTrackingParameterFailed = (errorMessage: string) => ({
    type: TrackingParameterConstants.CREATE_TRACKING_PARAMETER_FAILED,
    payload: {
        errorMessage
    }
});

export const linkCampaignToTrackingParameter = (
    campaignId: string,
    trackingParameter: ITrackingParameter
): ILinkCampaignToTrackingParameters => ({
    type: TrackingParameterConstants.LINK_TRACKING_PARAMETER_TO_CAMPAIGN,
    payload: {
        campaignId,
        trackingParameter
    }
});

export const linkCampaignToTrackingParameterSuccess = (
    campaign: ITrackingParameterCampaignRelation,
    trackingParameter: ITrackingParameter
) => ({
    type: TrackingParameterConstants.LINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS,
    payload: {
        trackingParameter,
        campaign
    }
});

export const linkCampaignToTrackingParameterFailed = (errorMessage: string) => ({
    type: TrackingParameterConstants.LINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED,
    payload: {
        errorMessage
    }
});

export const unlinkCampaignToTrackingParameter = (
    relatedId: number,
    trackingParameter: ITrackingParameter
): IUnlinkCampaignToTrackingParameters => ({
    type: TrackingParameterConstants.UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN,
    payload: {
        relatedId,
        trackingParameter
    }
});

export const unlinkCampaignToTrackingParameterSuccess = (trackingParameterId: number, relationId: number) => ({
    type: TrackingParameterConstants.UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS,
    payload: {
        trackingParameterId,
        relationId
    }
});

export const unlinkCampaignToTrackingParameterFailed = (errorMessage: string) => ({
    type: TrackingParameterConstants.UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED,
    payload: {
        errorMessage
    }
});

export const updateCampaignToTrackingParameter = (
    fields: ITrackingParameterFormValues,
    trackingParameter: ITrackingParameter
) => ({
    type: TrackingParameterConstants.UPDATE_TRACKING_PARAMETER,
    payload: {
        fields,
        trackingParameter
    }
});

export const updateCampaignToTrackingParameterSuccess = (trackingParameter: ITrackingParameter) => ({
    type: TrackingParameterConstants.UPDATE_TRACKING_PARAMETER_SUCCESS,
    payload: {
        trackingParameter
    }
});

export const updateCampaignToTrackingParameterFailed = (errorMessage: string) => ({
    type: TrackingParameterConstants.UPDATE_TRACKING_PARAMETER_FAILED,
    payload: {
        errorMessage
    }
});
