import React from "react";
import { connect } from "react-redux";
import IClient from "../../../../interfaces/IClient";
import { deletePagedClient } from "../../../../actions/clientActions";
import IAppState from "../../../../interfaces/IAppState";
import PencilIcon from "../../../Shared/Icons/PencilIcon";
import TableActionDeleteButton from "../../../Shared/Table/TableActionDeleteButton";
import TableActionLink from "../../../Shared/Table/TableActionLink";

interface IOwnProps {
    original: IClient;
    page: number;
    pageSize: number;
}

interface IProps extends IOwnProps {
    deleteClient: (id: number, page: number, pageSize: number, insight: string, search: string) => void;
    insight: string;
    search: string;
}

const ClientActionCell: React.FunctionComponent<IProps> = ({
    original,
    deleteClient,
    page,
    pageSize,
    insight,
    search
}) => {
    const { id, deletedAt } = original;

    const handleDeleteClient = () => {
        deleteClient(id, page + 1, pageSize, insight, search);
    };

    if (deletedAt) {
        return null;
    }

    return (
        <div className="justify-between flex text-gray-500">
            <TableActionLink to={`${id}`} Icon={PencilIcon} />
            <TableActionDeleteButton
                deletionMessage="Are you sure you want to delete this client?"
                onDelete={() => handleDeleteClient()}
                className={"ml:ml-4"}
            />
        </div>
    );
};

const mapStateToProps = (state: IAppState, { original }: IOwnProps) => {
    return {
        insight: state.clients.paginatedClients.insight,
        search: state.clients.paginatedClients.search
    };
};

const mapDispatchToProps = {
    deleteClient: deletePagedClient
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientActionCell);
