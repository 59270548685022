import React, { useImperativeHandle, useRef } from "react";
import { DragSource, DropTarget, DropTargetConnector, DragSourceConnector, DragSourceMonitor } from "react-dnd";
import { parameters } from "../../../parameters";
import Errors from "../../Shared/Form/Elements/Errors";
import AutoCompleteField from "../../Shared/Form/Blocks/AutoCompleteField";
import CharacterCount from "../../Shared/Form/CharacterCount";
import TrashIcon from "../../Shared/Icons/TrashIcon";
import EllipsisIcon from "../../Shared/Icons/EllipsisIcon";
import {
    IPartInstance,
    ISpecialOfferTemplateDraggablePart,
    PART_TYPE,
    partSource,
    partTarget
} from "../../../utils/dragAndDropParts";
import { characterLimit } from "../../../utils/DynamicCampaignUtils";

const style = {
    cursor: "move"
};

const partsThatRequireAtLeastOneEntry = ["h1", "h2", "description1", "path"];

const SpecialOfferTemplatePart = React.forwardRef(
    (
        {
            connectDragPreview,
            connectDragSource,
            connectDropTarget,
            isDragging,
            item,
            field,
            arrayOfValuesForValidation,
            currentValue,
            index,
            required,
            errors,
            setFieldValue,
            setFieldTouched
        }: ISpecialOfferTemplateDraggablePart,
        ref
    ) => {
        const opacity = isDragging ? 0.3 : 1;
        const marginLeft = isDragging ? -10 : 0;
        const elementRef = useRef(null);
        const dragRef = useRef(null);
        connectDragSource(dragRef);
        connectDropTarget(elementRef);
        connectDragPreview(elementRef);

        useImperativeHandle<unknown, IPartInstance>(ref, () => ({
            getNode: () => elementRef.current
        }));

        const renderInformationUiErrors = (index: number, field: string) => {
            // @ts-ignore
            const err = errors?.[field]?.[index];

            if (!err) {
                return <div />;
            }

            if (typeof err === "string") {
                return <div>{<Errors errors={[err]} />}</div>;
            }

            if (err.pinnedField) {
                return <div>{<Errors errors={[err.pinnedField]} />}</div>;
            }
            if (err.value) {
                return <div>{<Errors errors={[err.value]} />}</div>;
            }

            return <div>{<Errors errors={[]} />}</div>;
        };

        const shouldShowCharacterCount = !arrayOfValuesForValidation[index].includes("{{");
        const buttonClasses =
            "bg-blue-100 border border-blue-100 hover:bg-blue-200 flex justify-center content-center py-2 mt-4";

        return (
            <div key={index} ref={elementRef} className="relative w-full" style={{ opacity, marginLeft }}>
                <div className="w-full flex">
                    <button
                        ref={dragRef}
                        type="button"
                        className={`${buttonClasses} text-blue-400 hover:text-blue-600`}
                        style={{ ...style }}
                    >
                        <EllipsisIcon className="w-6 h-6" />
                    </button>
                    <AutoCompleteField
                        name={`${field}[${index}]`}
                        value={arrayOfValuesForValidation[index]}
                        key={`${field}[${index}]`}
                        handleChange={(e: any) => {
                            let value = e.target.value;
                            if ([`headlines`, `descriptions`].includes(field)) {
                                value = { value: e.target.value, pinnedField: null };
                            }
                            setFieldValue(`${field}[${index}]`, value);
                            setFieldTouched(`${field}[${index}]`);
                        }}
                        required={required}
                        autocompleteFields={parameters}
                        placeholder="{{Make}}"
                        className={`flex-grow mr-8`}
                    />

                    <div className={`w-16 flex items-center pt-3 `}>
                        <CharacterCount
                            value={arrayOfValuesForValidation[index] ?? ""}
                            characterLimit={characterLimit(field)}
                            hideCount={!shouldShowCharacterCount}
                        />
                    </div>
                </div>
                {renderInformationUiErrors(index, field)}
                {((partsThatRequireAtLeastOneEntry.includes(field) && arrayOfValuesForValidation.length > 1) ||
                    !partsThatRequireAtLeastOneEntry.includes(field)) && (
                    <button
                        type="button"
                        onClick={() => {
                            setFieldValue(
                                `${field}`,
                                // @ts-ignore
                                currentValue.filter((item: any, i: number) => i !== index)
                            );
                        }}
                        className={`absolute top-0 right-0 mt-6 mr-16 pr-1 text-blue-200 hover:text-red-500`}
                    >
                        <TrashIcon className="w-6 -mr-2" />
                    </button>
                )}
            </div>
        );
    }
);

export default DropTarget(PART_TYPE, partTarget, (connect: DropTargetConnector) => {
    return {
        connectDropTarget: connect.dropTarget()
    };
})(
    DragSource(PART_TYPE, partSource, (connect: DragSourceConnector, monitor: DragSourceMonitor) => {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging()
        };
    })(SpecialOfferTemplatePart)
);
