import { schema } from "normalizr";

const adwordsCampaign = new schema.Entity("adwordsCampaigns");
const adwordsAdGroups = new schema.Entity("adwordsAdGroups");
const adwordsBulkExclusion = new schema.Entity("adwordsBulkExclusion");
const exclusionList = new schema.Entity("exclusionList");
const exclusionCollCampaigns = new schema.Entity("exclusionCollCampaigns");
const adwordsGeneralExclusion = new schema.Entity("adwordsGeneralExclusion");

const Schemas = {
    ADWORDS_BULK_EXCLUSION: adwordsBulkExclusion,
    ADWORDS_BULK_EXCLUSION_ARRAY: { data: [adwordsBulkExclusion] },
    ADWORDS_GENERAL_EXCLUSION: adwordsGeneralExclusion,
    ADWORDS_GENERAL_EXCLUSION_ARRAY: { data: [adwordsGeneralExclusion] },
    EXCLUSION_LIST_ARRAY: { data: [exclusionList] },
    EXCLUSION_COLL_CAMPAIGN_ARRAY: { data: [exclusionCollCampaigns] },
    CAMPAIGN: adwordsCampaign,
    CAMPAIGN_ARRAY: { data: [adwordsCampaign] },
    AD_GROUP: adwordsAdGroups,
    AD_GROUP_ARRAY: { data: [adwordsAdGroups] }
};

export default Schemas;
