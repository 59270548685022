import React, { useState } from "react";
import { fetchCsv } from "../../../middleware/api";
import FileSaver from "file-saver";
import Button from "../../Shared/Button";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import DownloadIcon from "../../Shared/Icons/DownloadIcon";
import { connect } from "react-redux";
import IClient from "../../../interfaces/IClient";
import { reportDownloadClicked } from "../../../actions/facebookActions";

interface IProps {
    client: IClient;
    clientId: number;
    reportUrl: string;
    endDate: string;
    startDate: string;
    trackRequestDownload: (clientId: number, client: IClient) => void;
}

const DownloadButton: React.FC<IProps> = ({
    clientId,
    client,
    reportUrl,
    startDate,
    endDate,
    trackRequestDownload
}) => {
    const [loadingCsv, setLoadingCsv] = useState(false);

    const loadCsv = async () => {
        trackRequestDownload(clientId, client);
        setLoadingCsv(true);
        const data = await fetchCsv(reportUrl);
        const replaceDash = new RegExp("-", "g");
        const fileDate = `${startDate.replace(replaceDash, "")}-${endDate.replace(replaceDash, "")}`;
        FileSaver.saveAs(data, `fb-performance-report-${fileDate}.csv`);
        setLoadingCsv(false);
    };

    return (
        <Button
            disabled={loadingCsv}
            onClick={() => {
                loadCsv();
            }}
            type={"button"}
            styleType={"primary"}
        >
            {loadingCsv ? (
                <span>
                    <RefreshIcon className="w-6 h-6 inline-block rotate-fast" /> Loading Csv{" "}
                </span>
            ) : (
                <span>
                    <DownloadIcon className="w-6 h-6 inline-block " /> Download CSV
                </span>
            )}
        </Button>
    );
};

const mapStateToProps = (state: any, props: any) => {
    const clientId = state.clients.currentClient;
    const client = state.clients.entities[clientId];

    return {
        client,
        clientId
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        trackRequestDownload: (clientId: number, client: IClient) => {
            dispatch(reportDownloadClicked(clientId, client));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
