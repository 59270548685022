import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { boolean, number, object } from "yup";
import IPreScreen, { ICampaignOptions } from "../../../../interfaces/DealerSetup/IPreScreenFields";
import PreScreenCPCField from "../Fields/PreScreenCPCField";
import CheckboxFields from "../Fields/CheckboxFields";
import DealerSetupButton from "../DealerSetupButton";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";

interface IProps {
    path: string;
    formValues: IFormValues;
    savePage(path: string, validatePage: () => void): void;
    toggleFormPages(values: { value: ICampaignOptions }): void;
}

const PreScreen = forwardRef(({ formValues, savePage, toggleFormPages, path }: IProps, ref: React.Ref<{}>) => {
    const schema = object({
        campaigns: object({
            name: boolean(),
            dealer: boolean(),
            locations: boolean(),
            competitors: boolean(),
            dynamic: boolean(),
            services: boolean(),
            serviceLocations: boolean(),
            generic: boolean(),
            conquest: boolean(),
            remarketing: boolean(),
            adExtensions: boolean()
        }).test("campaigns", "Please check one checkbox", (obj) => !!obj && Object.values(obj).some((value) => value)),
        defaultCpc: number().typeError("CPC must be a number!").min(1)
    });

    const defaults: IPreScreen = {
        fields: {
            campaigns: {
                value: {
                    ...preScreenCampaigns
                }
            },
            defaultCpc: {
                value: 6
            }
        }
    };

    const [values, handleFieldChange, changePage] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, id } = event.target;
        if (type === "checkbox") {
            handleFieldChange(name, {
                ...values.fields.campaigns.value,
                [id]: !values.fields.campaigns.value[id]
            });

            toggleFormPages({
                value: {
                    ...values.fields.campaigns.value,
                    [id]: values.fields.campaigns.value[id]
                }
            });
        } else {
            handleFieldChange(name, value);
        }
    };

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Pre-Screen</div>

            <div className="text-lg font-bold my-4 pb-2 border-b">Pre-Screen Questions</div>

            <CheckboxFields
                label="Which campaigns would you like to build?"
                groupName="campaigns"
                value={values.fields.campaigns.value}
                errors={values.fields.campaigns.errors}
                onInputChange={handleInputChange}
            />
            <p className="text-gray-dark py-3">
                Use <strong>Generic</strong> for custom static campaigns, General Mazda Intent, General Mazda Lease &
                All Models.
            </p>
            <PreScreenCPCField values={values} onInputChange={handleInputChange} />

            {pager(<DealerSetupButton path="../" label="Cancel" />)}
        </div>
    );
});

export const preScreenCampaigns = {
    name: true,
    dealer: true,
    locations: true,
    competitors: true,
    dynamic: true,
    services: true,
    serviceLocations: true,
    generic: true,
    conquest: true,
    remarketing: true,
    adExtensions: true
};

export default PreScreen;
