import { isEmpty } from "lodash";
import { FormikProps } from "formik";
import { ICampaignById } from "../interfaces/Campaigns";
import { IMappedExclusionById, IMappedExclusion } from "../interfaces/Exclusions";

export const displayCamapaignsFilter = (campaignsById: ICampaignById): ICampaignById => {
    return Object.keys(campaignsById)
        .filter((key) => campaignsById[key].type === "DISPLAY")
        .reduce((acc: ICampaignById, key: string) => {
            acc[key] = campaignsById[key];
            return acc;
        }, {});
};

export const mapApiToForm = (campaigns: any, negativeKeywordCollForm: any): IMappedExclusionById => {
    return Object.keys(campaigns).reduce((acc: any, key) => {
        if (isEmpty(negativeKeywordCollForm)) {
            acc[key] = [];
        } else {
            acc[key] = Object.keys(negativeKeywordCollForm)
                .filter((j) => negativeKeywordCollForm[j].googleCampaignId === String(key))
                .map((k: any) => {
                    const item = negativeKeywordCollForm[k];
                    const { googleCampaignId, clientId, collectionId, id } = item;
                    return {
                        id,
                        googleCampaignId,
                        clientId,
                        collectionId
                    };
                });
        }
        return acc;
    }, {});
};

export const mapCheckedValues = (values: IMappedExclusionById, clientId: number) => {
    return Object.keys(values)
        .reduce((acc, key) => {
            acc = [...acc, ...values[key].filter((item) => item.new)];
            return acc;
        }, [] as any)
        .map((item: IMappedExclusion) => {
            return { ...item, collectionId: Number(item.collectionId), clientId };
        });
};

export const mapUncheckedValues = (values: IMappedExclusionById, checkedValues: IMappedExclusionById) => {
    return Object.keys(values).reduce((acc, key) => {
        const removedItems = values[key]
            .filter((value) => {
                return !Object.keys(checkedValues).some((k) => {
                    return checkedValues[k].some((newVal) => newVal.collectionId === value.collectionId);
                });
            })
            .map((item) => {
                return {
                    ...item,
                    shouldDelete: true
                };
            });
        acc = [...acc, ...removedItems];
        return acc;
    }, [] as any);
};

export const findInArray = (arr: any[], value: string, key: string) => {
    if (!arr) {
        return false;
    }
    return arr.some((item: any) => String(item[key]) === value);
};

export const onCheckboxChange = (
    fieldValue: IMappedExclusion[],
    checkboxValue: string,
    name: string,
    form: FormikProps<IMappedExclusionById>
) => {
    // uncheck
    if (findInArray(fieldValue, checkboxValue, "collectionId")) {
        const nextValue = fieldValue.filter((v: any) => String(v.collectionId) !== checkboxValue);
        return form.setFieldValue(name, nextValue);
    }

    // re-check
    if (form.initialValues[name]) {
        const previousExclusion = form.initialValues[name].find(
            (exclusion: any) => String(exclusion.collectionId) === checkboxValue
        );
        if (previousExclusion) {
            const nextValue = fieldValue.concat(previousExclusion);
            return form.setFieldValue(name, nextValue);
        }
    }

    // check
    const newValue = {
        new: true,
        collectionId: Number(checkboxValue),
        googleCampaignId: String(name),
        clientId: null // will be assigned later
    };
    const nextValue = fieldValue.concat(newValue);
    form.setFieldValue(name, nextValue);
};
