import { redirect } from "react-router-dom";
import { call, CallEffectNamedFn, Func1, put, takeEvery } from "redux-saga/effects";
import * as actions from "../actions/userActions";
import { logout, routesWithoutAuthentication } from "../api";
import { Bugsnag } from "../bugsnag";
import { abortNetworkRequests, callApi } from "../middleware/api";
import SCHEMAS from "../middleware/schemas";

export function* fetchCurrentUser(): Generator<any, any, any> {
    const url = `/users/me?expand[roles]=*`;

    try {
        const resp = yield call(callApi, url, SCHEMAS.USER);
        const userId = resp.result;
        const user = resp.entities.user[userId];

        const { id, email, firstName, lastName, ...userMetaData } = user;
        Bugsnag.setUser(id, email, firstName + " " + lastName);
        Object.entries(userMetaData).map(([key, value]) => {
            Bugsnag.addMetadata("user", key, value);
        });

        yield put(actions.setCurrentUser(user));
    } catch (e) {
        yield call(abortNetworkRequests);

        if (!routesWithoutAuthentication.includes(window.location.pathname)) {
            yield call(redirect as unknown as CallEffectNamedFn<{ [x: string]: Func1<string> }, string>, "/login");
        }

        console.error(e);
    }
}

export function* logoutTheUser() {
    yield call(logout);
}

function* userSaga() {
    yield takeEvery(actions.FETCH_CURRENT_USER, fetchCurrentUser);
    yield takeEvery(actions.LOGOUT_CURRENT_USER, logoutTheUser);
}

export default userSaga;
