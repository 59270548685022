import React, { SVGProps } from "react";
import Svg from "./Svg";

const SalesIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M13.5 7.2a.9.9 0 1 0-1.801 0 .9.9 0 0 0 1.8 0z" />
        <path d="M11.86 13.486a1.193 1.193 0 0 0 .886.245c.318-.039.602-.199.8-.452C15.03 11.38 16.8 8.784 16.8 7.2 16.8 4.884 14.916 3 12.6 3a4.205 4.205 0 0 0-4.2 4.2c0 1.587 1.77 4.182 3.255 6.082.06.075.129.144.206.204zM12.6 4.2c1.654 0 3 1.346 3 3 0 .928-1.15 2.974-3 5.34-1.85-2.364-3-4.41-3-5.34 0-1.654 1.346-3 3-3z" />
        <path d="M21.606 19.814l-2.76-8.412a1.808 1.808 0 0 0-1.34-1.166.6.6 0 0 0-.24 1.175c.208.042.379.19.444.376l.66 2.012H15c-.018 0-.032.008-.049.01-.017.001-.033-.005-.05-.002-.025.004-.044.019-.067.026a.584.584 0 0 0-.11.041.6.6 0 0 0-.24.236.583.583 0 0 0-.046.102.583.583 0 0 0-.024.12c-.003.022-.014.043-.014.067 0 .017.008.031.01.047.001.018-.005.034-.002.052l.482 2.902h-4.583l.484-2.902c.003-.016-.003-.032-.001-.05.001-.016.01-.03.01-.048 0-.024-.012-.045-.014-.069a.586.586 0 0 0-.024-.118.583.583 0 0 0-.047-.102.6.6 0 0 0-.064-.095.612.612 0 0 0-.285-.183c-.024-.007-.043-.022-.068-.026-.017-.003-.033.003-.05.002-.018-.001-.032-.009-.049-.009H6.827l.656-2a.602.602 0 0 1 .444-.388.6.6 0 1 0-.246-1.174 1.816 1.816 0 0 0-1.334 1.176l-2.749 8.387a1.801 1.801 0 0 0 1.698 2.4h14.608a1.798 1.798 0 0 0 1.702-2.386zM15.708 15h3.055l.787 2.4h-3.442l-.4-2.4zm-.618 3.6l.4 2.4H9.706l.4-2.4h4.984zM9.491 15l-.4 2.4H5.648l.786-2.4h3.057zm-4.395 5.966a.605.605 0 0 1-.362-.779l.52-1.587H8.89l-.4 2.4H5.295a.603.603 0 0 1-.199-.034zm14.807.035h-3.195l-.4-2.401h3.636l.525 1.6a.6.6 0 0 1-.566.8z" />
    </Svg>
);

export default SalesIcon;
