import React, { SVGProps } from "react";
import Svg from "./Svg";

const ChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M16.178 20.046l.842-.83a.521.521 0 0 0 .169-.383.52.52 0 0 0-.169-.381l-6.62-6.527 6.62-6.527a.521.521 0 0 0 .169-.382.52.52 0 0 0-.169-.382l-.842-.83a.536.536 0 0 0-.775 0l-7.85 7.739a.522.522 0 0 0-.168.382.52.52 0 0 0 .169.381l7.85 7.74a.536.536 0 0 0 .774 0z" />
    </Svg>
);

export default ChevronLeftIcon;
