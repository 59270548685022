import React from "react";
import Button, { SECONDARY_BUTTON } from "../../../Shared/Button";

interface IProps {
    name: string;
    insightFacebookLowSpends: { campaignId: number; campaignName: string; spend: number }[];
    hide: () => void;
}

const ClientFacebookLowSpendsModal = ({ name, insightFacebookLowSpends, hide }: IProps) => {
    return (
        <div className={`w-full flex flex-col justify-between h-full`}>
            <div className={`text-2xl`}>
                Facebook Low Spends - <span className={`text-xl`}>{name}</span>
            </div>
            <ul className={`w-full flex-grow border border-t-0 overflow-y-scroll mb-8 mx-2`}>
                {insightFacebookLowSpends &&
                    insightFacebookLowSpends.map((lowSpend, index) => (
                        <li className={`w-full flex justify-between items-center odd:bg-gray-200 p-3`} key={index}>
                            {lowSpend.campaignName} ({lowSpend.campaignId}) had a spend of ${lowSpend.spend} in the last
                            7 days.
                        </li>
                    ))}
            </ul>
            <div className="w-full flex justify-end">
                <Button styleType={SECONDARY_BUTTON} onClick={hide}>
                    Close
                </Button>
            </div>
        </div>
    );
};

export default ClientFacebookLowSpendsModal;
