import React from "react";
import InventoryFilter from "./InventoryFilter";
import { IConditional } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import Button, { SECONDARY_BUTTON } from "../../Shared/Button";
import uuid from "uuid";
import AddIcon from "../../Shared/Icons/AddIcon";

interface IProps {
    values: any;
    setFieldValue: any;
    errors: any;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
    autoFocus?: boolean;
}

const InventoryFilters: React.FC<IProps> = ({ values, setFieldValue, errors, setFieldTouched, autoFocus = true }) => {
    const inventoryFilters = values.inventoryFilters;
    const handleAddFilter = () => {
        const newItem: IConditional = {
            id: uuid.v1(),
            field: "",
            comparator: "",
            value: "",
            new: true
        };
        setFieldValue("inventoryFilters", [...values.inventoryFilters, newItem]);
    };
    return inventoryFilters.length === 0 ? (
        <React.Fragment>
            <p className="text-base text-blue-800">
                Use this to filter your campaign by Vehicle Type, Make, Model, Year, or any variable in the feed.
            </p>
            <Button
                type="button"
                styleType={SECONDARY_BUTTON}
                onClick={handleAddFilter}
                styles="mt-4 flex items-center"
            >
                <AddIcon className="w-6 h-6 " /> <span className="ml-2">Add Inventory Filter</span>
            </Button>
        </React.Fragment>
    ) : (
        <React.Fragment>
            {inventoryFilters.map((inventoryFilter: any, index: number) => (
                <InventoryFilter
                    index={index}
                    condition={inventoryFilter}
                    key={inventoryFilter.id}
                    onChange={(item: IConditional) => {
                        setFieldValue(`inventoryFilters[${index}]`, item);
                    }}
                    onRemove={() => {
                        setFieldValue(`inventoryFilters[${index}].deleted`, true);
                    }}
                    setFieldTouched={setFieldTouched}
                    names={{
                        comparator: `inventoryFilters[${index}].comparator`,
                        field: `inventoryFilters[${index}].field`,
                        value: `inventoryFilters[${index}].value`
                    }}
                    errors={errors?.[`inventoryFilters`]?.[index] ?? {}}
                    autoFocus={autoFocus}
                />
            ))}
            <Button
                type="button"
                styleType={SECONDARY_BUTTON}
                onClick={handleAddFilter}
                styles="mt-4 flex items-center"
            >
                <AddIcon className="w-6 h-6" /> <span className="ml-2">Add Inventory Filter</span>
            </Button>
        </React.Fragment>
    );
};

export default InventoryFilters;
