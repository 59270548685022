import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import DealerSetupSidebar from "./Sidebar/DealerSetupSidebar";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import DealerInfo from "./Pages/DealerInfo";
import PreScreen from "./Pages/PreScreen";
import Review from "./Pages/Review";
import Services from "./Pages/Services";
import Locations from "./Pages/Locations";
import Competitors from "./Pages/Competitors";
import Name from "./Pages/Name";
import * as Actions from "../../../actions/DealerSetupActions";
import { getCurrentClientObject } from "../../../reducers/clients";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import Dynamic from "./Pages/Dynamic";
import GenericCampaigns from "./Pages/GenericCampaigns";
import Conquests from "./Pages/Conquests";
import IFormValues from "../../../interfaces/DealerSetup/IFormValues";
import Remarketing from "./Pages/Remarketing";
import IAppState from "../../../interfaces/IAppState";
import useTimeToSubmitMetric from "../../../hooks/useTimeToSubmitMetric";
import Alert from "../../Shared/Alert";
import NegativeKeywords from "./Pages/NegativeKeywords";
import NegativeKeywordCollActions from "../../../actions/client/NegativeKeywordCollActions";
import { INegativeKeywordColl } from "../../../interfaces/NegativeKeywordColl";
import IEntity from "../../../interfaces/IEntity";
import AdExtensions from "./Pages/AdExtensions";
import * as SitelinkTemplateActions from "../../../actions/SitelinkTemplateActions";
import { IClient } from "../../../interfaces/IClient";
import Redirect from "../../../components/Shared/Loaders/Redirect";
interface IReduxState {
    dealerSetup: {
        formValues: IFormValues;
        hasSavedState: boolean;
        message: string;
        processing: boolean;
        success: boolean;
    };
    clients: { currentClient: number };
}

interface IProps {
    formValues: IFormValues;
    clientId: number;
    client: IClient;
    hasSavedState: boolean;
    message: string;
    processing: boolean;
    success: boolean;
    negativeKeywordCollections: {
        entities: IEntity<INegativeKeywordColl>;
        loadedAt?: Date;
    };
    sitelinkTemplatesLoading: boolean;
    fetchSitelinkTemplates: () => void;
    sitelinksLoaded?: Date;
}

interface IDealerSetupContainerProps extends IProps {
    savePage(path: string, values: any): void;
    saveForm(clientId: number, timeToSubmitMetric: number): void;
    toggleFormPages(values: any): void;
    fetchDealerSetupData(clientId: number): void;
    fetchNegativeKeywordCollections(): void;
}

const DealerSetupContainer: React.FunctionComponent<IDealerSetupContainerProps> = ({
    formValues,
    savePage,
    saveForm,
    client,
    clientId,
    message,
    processing,
    success,
    hasSavedState,
    toggleFormPages,
    fetchDealerSetupData,
    negativeKeywordCollections,
    fetchNegativeKeywordCollections,
    sitelinkTemplatesLoading,
    fetchSitelinkTemplates,
    sitelinksLoaded
}) => {
    const changePageRef = useRef<any>();
    useEffect(() => {
        if (sitelinksLoaded) {
            return;
        }

        fetchSitelinkTemplates();
    }, []);

    useEffect(() => {
        if (hasSavedState === undefined) {
            fetchDealerSetupData(clientId);
        }
    }, []);

    useEffect(() => {
        if (negativeKeywordCollections.loadedAt) {
            return;
        }

        fetchNegativeKeywordCollections();
    }, []);

    const getTimeToSubmitMetric = useTimeToSubmitMetric();

    if (hasSavedState === undefined || !negativeKeywordCollections.loadedAt || !sitelinksLoaded) {
        return <FullPageLoader message="Loading Dealer Setup Wizard" />;
    }

    const clientMissingAddress = !client.address || !client.zip || !client.city || !client.state;
    const shouldShowClientMissingFieldsError =
        clientMissingAddress || client.automobileManufacturers.length <= 0 || !client.domain || !client.adwordsId;

    return (
        <div className="flex flex-wrap h-full">
            <div className="w-full border-b shadow-sm bg-white">
                <h1 className="text-2xl font-bold text-gray-800 pl-4 py-4">Dealer Setup</h1>
            </div>
            <div className="w-full h-full flex flex-wrap bg-gray-100">
                <DealerSetupSidebar formValues={formValues} changePageRef={changePageRef} />

                <div className="w-3/4  h-full">
                    {shouldShowClientMissingFieldsError && (
                        <div className="mx-8 -mb-4">
                            <Alert title="Required client fields missing!" type="error">
                                <div>
                                    <div className="w-full">
                                        <p>
                                            This client is missing required fields.{" "}
                                            <Link className="underline hover:text-red-800" to={"/clients/" + clientId}>
                                                Please edit the client
                                            </Link>{" "}
                                            to add:
                                        </p>
                                    </div>
                                    <div className="w-full mt-3">
                                        <ul className="list-disc px-8">
                                            {(!client.address || !client.city || !client.zip) && (
                                                <li>Address, City, State, ZIP/Postal Code</li>
                                            )}
                                            {!client.domain && <li>Website URL</li>}
                                            {client.automobileManufacturers.length === 0 && <li>Manufacturers</li>}
                                            {!client.adwordsId && <li>Google Ads Account ID</li>}
                                        </ul>
                                    </div>
                                </div>
                            </Alert>
                        </div>
                    )}

                    <div className="mx-4 h-full">
                        <Routes>
                            <Route element={<Redirect to={"pre-screen"} />} path={"/"} />
                            <Route
                                path="pre-screen"
                                element={
                                    <PreScreen
                                        path="pre-screen"
                                        formValues={formValues}
                                        savePage={savePage}
                                        toggleFormPages={toggleFormPages}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="dealer-info"
                                element={
                                    <DealerInfo
                                        path="dealer-info"
                                        formValues={formValues}
                                        client={client}
                                        savePage={savePage}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="name"
                                element={
                                    <Name path="name" formValues={formValues} savePage={savePage} ref={changePageRef} />
                                }
                            />
                            <Route
                                path="locations"
                                element={
                                    <Locations
                                        path="locations"
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="competitors"
                                element={
                                    <Competitors
                                        path="competitors"
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="services"
                                element={
                                    <Services
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                        path="services"
                                    />
                                }
                            />
                            <Route
                                path="dynamic"
                                element={
                                    <Dynamic
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                        client={client}
                                        path="dynamic"
                                    />
                                }
                            />
                            <Route
                                path="generic"
                                element={
                                    <GenericCampaigns
                                        path="generic"
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                        client={client}
                                    />
                                }
                            />
                            <Route
                                path="conquest"
                                element={
                                    <Conquests
                                        path="conquest"
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="remarketing"
                                element={
                                    <Remarketing
                                        path="remarketing"
                                        formValues={formValues}
                                        client={client}
                                        savePage={savePage}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="negative-keywords"
                                element={
                                    <NegativeKeywords
                                        path="negative-keywords"
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="ad-extensions"
                                element={
                                    <AdExtensions
                                        path="ad-extensions"
                                        formValues={formValues}
                                        savePage={savePage}
                                        ref={changePageRef}
                                    />
                                }
                            />
                            <Route
                                path="review"
                                element={
                                    <Review
                                        path="review"
                                        formValues={formValues}
                                        clientId={clientId}
                                        message={message}
                                        saveForm={(clientId: number) => saveForm(clientId, getTimeToSubmitMetric())}
                                        processing={processing}
                                        success={success}
                                    />
                                }
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    formValues: state.dealerSetup.formValues,
    clientId: state.clients.currentClient,
    client: getCurrentClientObject(state) as IClient,
    hasSavedState: state.dealerSetup.hasSavedState,
    message: state.dealerSetup.message,
    processing: state.dealerSetup.processing,
    success: state.dealerSetup.success,
    negativeKeywordCollections: state.negativeKeywordColls,
    sitelinkTemplatesLoading: state.sitelinkTemplates.loading,
    sitelinksLoaded: state.sitelinkTemplates.loadedAt
});

const mapDispatchToProps = (dispatch: any) => ({
    savePage: (path: string, values: any) => {
        dispatch(Actions.saveFormValues(path, values));
    },
    toggleFormPages: (values: any) => {
        dispatch(Actions.toggleFormPages(values));
    },
    saveForm: (clientId: number, timeToSubmitMetric: number) => {
        dispatch(Actions.saveForm(clientId, timeToSubmitMetric));
    },
    fetchDealerSetupData: (clientId: number) => {
        dispatch(Actions.fetchDealerSetupData(clientId));
    },
    fetchNegativeKeywordCollections: () => {
        dispatch(NegativeKeywordCollActions.requestNegativeKeywordColls());
    },
    fetchSitelinkTemplates() {
        dispatch(SitelinkTemplateActions.requestSitelinkTemplates());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerSetupContainer);
