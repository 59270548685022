import React, { useRef, useState } from "react";
import { Tooltip } from "../../Shared/Table";

const HeaderCell: React.FC<{
    label: string;
    tooltipText: string;
}> = ({ label, tooltipText, ...props }) => {
    const ref = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleHover = () => setShowTooltip(!showTooltip);
    return (
        <>
            <div ref={ref} className="header-container" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                <div className="header-title">{label}</div>
            </div>
            <Tooltip reference={ref} shouldShow={showTooltip}>
                <div className="font-bold">{label}</div>
                <p>{tooltipText}</p>
            </Tooltip>
        </>
    );
};

export default HeaderCell;
