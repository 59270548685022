import Constants from "../constants/DynamicCampaignStatusConstants";
import * as Interfaces from "../interfaces/IDCStatuses";

export const fetchDynamicCampaignStatuses = () => ({
    type: Constants.FETCH_DC_STATUSES
});

export const fetchDynamicCampaignStatusesSuccess = (dcStatuses: any): Interfaces.IDCStatuses => ({
    type: Constants.FETCH_DC_STATUSES_SUCCESS,
    payload: dcStatuses
});

export const fetchDynamicCampaignStatusesFailure = (dcStatuses: any): Interfaces.IDCStatuses => ({
    type: Constants.FETCH_DC_STATUSES_FAILURE,
    payload: dcStatuses
});
