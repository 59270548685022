import React, { SVGProps } from "react";
import Svg from "./Svg";

const CampaignIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M20.9796 1.49463H4.02039C3.20197 1.49463 2.53851 2.17551 2.53851 3.01542V13.6609C2.53851 14.5008 3.20197 15.1817 4.02039 15.1817H20.9796C21.798 15.1817 22.4615 14.5008 22.4615 13.6609V3.01542C22.4615 2.17551 21.798 1.49463 20.9796 1.49463ZM4.02039 2.50849H20.9796C21.2524 2.50849 21.4736 2.73545 21.4736 3.01542V13.6609C21.4736 13.9409 21.2524 14.1679 20.9796 14.1679H4.02039C3.74758 14.1679 3.52643 13.9409 3.52643 13.6609V3.01542C3.52643 2.73545 3.74758 2.50849 4.02039 2.50849Z" />
        <path d="M12.0883 14.4166C12.3308 14.4166 12.5325 14.6262 12.5743 14.9028L12.5823 15.0093V18.3781C12.5823 18.7055 12.3611 18.9709 12.0883 18.9709C11.8458 18.9709 11.6441 18.7612 11.6023 18.4847L11.5944 18.3781V15.0093C11.5944 14.6819 11.8155 14.4166 12.0883 14.4166Z" />
        <path d="M11.1571 18.2523C11.6372 17.6764 12.3955 17.638 12.9127 18.1371L13.0197 18.2523L16.554 22.4935C16.7469 22.7249 16.7469 23.1003 16.554 23.3317C16.3825 23.5375 16.1163 23.5604 15.9238 23.4003L15.8554 23.3317L12.3212 19.0907C12.211 18.9585 12.0421 18.9396 11.9151 19.034L11.8556 19.0906L8.32133 23.3317C8.12843 23.5632 7.81567 23.5632 7.62277 23.3317C7.4513 23.126 7.43224 22.8066 7.56561 22.5756L7.62277 22.4935L11.1571 18.2523Z" />
        <path d="M17.4015 4.92935C17.5143 4.67153 17.8071 4.55746 18.0555 4.67456C18.2763 4.77865 18.3876 5.03036 18.3304 5.26609L18.301 5.35341L16.5617 9.32806C16.4239 9.64402 16.1296 9.85554 15.7962 9.87835C15.5045 9.89831 15.2242 9.77094 15.0407 9.54199L14.9682 9.43777L13.7645 7.43731L12.1262 10.5572C11.9761 10.8435 11.6956 11.026 11.3876 11.046L11.271 11.0457C10.9577 11.0242 10.6805 10.8363 10.5341 10.5547L10.4855 10.4448L9.18258 6.9287L8.5318 8.50819C8.40336 8.81365 8.13039 9.02272 7.81882 9.06449L7.70038 9.07218H3.03247C2.75967 9.07218 2.53851 8.84263 2.53851 8.55947C2.53851 8.30777 2.71325 8.09843 2.94368 8.05501L3.03247 8.04675L7.64736 8.04641L8.35553 6.33172C8.48484 6.01957 8.76458 5.80731 9.08375 5.76921L9.20507 5.76323C9.53235 5.76983 9.82724 5.95872 9.98161 6.25122L10.0327 6.36561L11.3432 9.90448L12.9652 6.81967C13.0999 6.56298 13.3406 6.38808 13.6142 6.34163L13.7332 6.32972C14.0147 6.32092 14.2816 6.44838 14.4584 6.67032L14.5284 6.77109L15.7246 8.75908L17.4015 4.92935Z" />
    </Svg>
);

export default CampaignIcon;
