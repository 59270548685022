import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TableLoader } from "../Shared/Table";

import IUser from "../../interfaces/IUser";
import ISettingsForm from "../../interfaces/ISettingsForm";
import Button, { PRIMARY_BUTTON } from "../Shared/Button";

const formContainerStyles =
    "m-auto relative bg-white flex flex-col w-full sm:w-4/5 md:w-full lg:w-2/3 xl:w-1/2 shadow-md rounded p-4 mb-4";
const fieldGroupStyles = "flex flex-wrap mb-4 w-full";
const labelStyles = "block uppercase tracking-wider text-gray-800 font-bold mb-2";
const fieldStyles =
    "appearance-none block w-full bg-gray-200 text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-200 focus:border-gray";
const errorStyles = "text-red-500 text-sm italic uppercase";
const buttonStyles = "bg-blue-500 text-white font-bold py-2 px-4 mx-4 rounded focus:outline-none focus:shadow-outline";
const fieldSectionStyles = "mb-12";
const sectionLabelStyles = "block uppercase tracking-wider text-gray-700 font-bold mb-2 font-size-sm";

interface IProps {
    saveSettings: (formValues: ISettingsForm, user: IUser) => void;
    currentUser: IUser;
    formValues: ISettingsForm;
    isSubmitting: boolean;
    error: any;
}

const UserSchema = Yup.object().shape({
    firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    email: Yup.string().email("Invalid email").required("Required")
});

const UserSettingsForm: React.FunctionComponent<IProps> = ({
    error,
    isSubmitting,
    saveSettings,
    currentUser,
    formValues
}) => {
    const formikRef = useRef(null) as any;

    useEffect(() => {
        const hasError = !isSubmitting && error && formikRef.current.state.submitCount > 0;

        if (hasError) {
            formikRef.current.setErrors(error.errors);
        }
    }, [isSubmitting]);

    return (
        <div className={formContainerStyles}>
            <Formik
                ref={formikRef}
                enableReinitialize={true}
                initialValues={{ ...formValues }}
                validationSchema={UserSchema}
                onSubmit={(values) => saveSettings(values, currentUser)}
                render={({ values, dirty, isValid }) => {
                    return (
                        <Form>
                            {/* Profile Section*/}
                            <div className={fieldSectionStyles}>
                                <h3 className={sectionLabelStyles}>Account Settings</h3>

                                {/* First Name */}
                                <div className={fieldGroupStyles}>
                                    <label className={labelStyles} htmlFor="firstName">
                                        <span>First Name</span>
                                        <span>*</span>
                                    </label>
                                    <Field disabled={isSubmitting} name="firstName" className={fieldStyles} />
                                    <ErrorMessage component="span" className={errorStyles} name="firstName" />
                                </div>

                                {/* Last Name */}
                                <div className={fieldGroupStyles}>
                                    <label className={labelStyles} htmlFor="lastName">
                                        <span>Last Name</span>
                                        <span>*</span>
                                    </label>
                                    <Field disabled={isSubmitting} name="lastName" className={fieldStyles} />
                                    <ErrorMessage component="span" className={errorStyles} name="lastName" />
                                </div>

                                {/* Email */}
                                <div className={fieldGroupStyles}>
                                    <label className={labelStyles} htmlFor="email">
                                        <span>Email</span>
                                        <span>*</span>
                                    </label>
                                    <Field disabled={isSubmitting} name="email" className={fieldStyles} />
                                    <ErrorMessage component="span" className={errorStyles} name="email" />
                                </div>
                            </div>

                            {/* Buttons  */}
                            <div className="flex items-center justify-between">
                                <div className="flex ">
                                    <Button disabled={isSubmitting || !dirty} styleType={PRIMARY_BUTTON} type="submit">
                                        Sav{isSubmitting ? "ing" : "e"} Changes
                                    </Button>
                                </div>
                                <Link
                                    to="/users"
                                    className="inline-block align-baseline font-bold text-blue-500 hover:text-blue-800"
                                >
                                    Cancel
                                </Link>
                            </div>
                        </Form>
                    );
                }}
            />

            {isSubmitting && (
                <TableLoader message="Updating Account Settings" className="self-center h-full top-0 left-0" />
            )}
        </div>
    );
};

export default UserSettingsForm;
