import React, { useEffect } from "react";
import { connect } from "react-redux";
import ExclusionsActions from "../../../actions/client/exclusionsActions";
import IEntity from "../../../interfaces/IEntity";
import { FormikActions } from "formik";
import { IAdwordsCampaignsStore, ICampaignById } from "../../../interfaces/Campaigns";
import {
    IExclusionsList,
    IExclusionsListStore,
    IExclusionsCollCampaignStore,
    IUpdatedExclusions,
    IMappedExclusion,
    IMappedExclusionById
} from "../../../interfaces/Exclusions";
import { displayCamapaignsFilter, mapApiToForm } from "../../../utils/ExclusionUtils";
import CampaignTable from "../../Shared/Campaign/CampaignTable";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import Constants from "../../../constants/ExclusionsConstants";
import IClient from "../../../interfaces/IClient";

interface IReduxState {
    adwordsCampaigns: IAdwordsCampaignsStore;
    exclusionList: IExclusionsListStore;
    exclusionCollCampaigns: IExclusionsCollCampaignStore;
    clients: any;
}

interface IProps {
    campaigns: ICampaignById;
    clientId: number;
    client: IClient;
    exclusionList: IEntity<IExclusionsList>;
    exclusionCollCampaignsLoadedAt: null | Date;
    exclusionCollCampaigns: IEntity<any>;
    exclusionListLoadedAt: null | Date;
    fetchExclusionCollCampaigns(clientId: number): void;
    fetchExclusionList(): void;
    updateExclusions(
        updatedExclusions: IUpdatedExclusions,
        formikActions: FormikActions<IMappedExclusionById>,
        clientId: number
    ): void;
}

const ExclusionCampaignContainer: React.FunctionComponent<IProps> = ({
    campaigns,
    fetchExclusionList,
    fetchExclusionCollCampaigns,
    exclusionList,
    clientId,
    client,
    exclusionCollCampaignsLoadedAt,
    exclusionCollCampaigns,
    exclusionListLoadedAt,
    updateExclusions
}) => {
    useEffect(() => {
        if (!exclusionCollCampaignsLoadedAt) {
            fetchExclusionCollCampaigns(clientId);
        }
    }, []);

    useEffect(() => {
        if (!exclusionListLoadedAt) {
            fetchExclusionList();
        }
    }, []);

    if (!exclusionCollCampaignsLoadedAt || !exclusionListLoadedAt) {
        return (
            <React.Fragment>
                <FullPageLoader message="Loading Placement Exclusions..." />
            </React.Fragment>
        );
    }
    const handleApplyDefaults = (
        values: IMappedExclusionById,
        initialValues: IMappedExclusionById,
        campaigns: ICampaignById,
        setFieldValue: any
    ) => {
        const alreadyExists = (list: IMappedExclusion[], id: number) => {
            return list.some((value) => value.collectionId === id);
        };

        const getPreviousOrNewValue = (initialValues: IMappedExclusionById, googleCampaignId: string, id: number) => {
            const previouslySelectedValue = initialValues[googleCampaignId].find((value) => value.collectionId === id);
            return previouslySelectedValue
                ? previouslySelectedValue
                : { clientId, collectionId: id, googleCampaignId, new: true };
        };

        Object.entries(campaigns).forEach(([googleCampaignId, googleCampaign]) => {
            const { name } = googleCampaign;
            const currentValue = values[googleCampaignId];
            const newValues: any[] = [];
            if (!alreadyExists(currentValue, Constants.DEFAULT_EXCLUSION_ID)) {
                newValues.push(getPreviousOrNewValue(initialValues, googleCampaignId, Constants.DEFAULT_EXCLUSION_ID));
            }

            if (client.oemProgram) {
                Object.values(exclusionList)
                    .filter((collection: any) => collection.name.toLowerCase().includes(client.oemProgram))
                    .filter((collection: any) => !alreadyExists(currentValue, collection.id))
                    .forEach((collection: any) =>
                        newValues.push(getPreviousOrNewValue(initialValues, googleCampaignId, collection.id))
                    );
            }

            const nextValue = currentValue.concat(newValues);
            setFieldValue(googleCampaignId, nextValue);
        });
    };

    const exclusionValues: IMappedExclusionById = mapApiToForm(campaigns, exclusionCollCampaigns);
    return (
        <React.Fragment>
            <div className="px-4">
                <PageTitle title="Placement Exclusions" />
                <CampaignTable
                    columnName="Exclusion List"
                    clientId={clientId}
                    campaigns={campaigns}
                    checkboxOptions={exclusionList}
                    formValues={exclusionValues}
                    onUpdateValue={updateExclusions}
                    onApplyDefaults={handleApplyDefaults}
                />
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IReduxState) => {
    return {
        campaigns: displayCamapaignsFilter(state.adwordsCampaigns.entities),
        exclusionList: state.exclusionList.entities,
        clientId: state.clients.currentClient,
        client: state.clients.entities[state.clients.currentClient],
        exclusionListLoadedAt: state.exclusionList.loadedAt,
        exclusionCollCampaigns: state.exclusionCollCampaigns.entities,
        exclusionCollCampaignsLoadedAt: state.exclusionCollCampaigns.loadedAt
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchExclusionList: () => {
        dispatch(ExclusionsActions.requestExclusionList());
    },
    fetchExclusionCollCampaigns: (clientId: number) => {
        dispatch(ExclusionsActions.requestExclusionCollCampaigns(clientId));
    },
    updateExclusions: (
        updatedExclusions: IUpdatedExclusions,
        formikActions: FormikActions<IMappedExclusionById>,
        clientId: number
    ) => {
        dispatch(ExclusionsActions.updateExclusionCollCampaigns(updatedExclusions, formikActions, clientId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ExclusionCampaignContainer);
