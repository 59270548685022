import React from "react";
import QuestionMarkIcon from "../../Shared/Icons/QuestionMarkIcon";
import moment from "moment";
import { IEditHistoryActionItem } from "../../../interfaces/EditHistory/IEditHistoryActionItem";

const ItemGenericAction = ({ activity, index }: IEditHistoryActionItem) => (
    <div className={`flex flex-wrap items-center border my-2 rounded`}>
        <div className="h-6 ml-2 bg-purple-500 rounded-full flex items-center justify-center">
            <QuestionMarkIcon className="h-6 text-white p-1" />
        </div>
        <div className={`flex flex-col justify-between p-2 text-base`}>
            <div className="flex items-center text-sm">
                <div className="underline mr-1">{activity?.subject?.name}</div> was {activity.description}
            </div>
            <div className="text-xs text-gray-600">
                {[activity.causer?.firstName, activity.causer?.lastName].join(" ").trim()} •{" "}
                {moment(activity.createdAt).format("MMMM Do YYYY, h:mm a")}
            </div>
        </div>
    </div>
);

export default ItemGenericAction;
