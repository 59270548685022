import React, { Fragment } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Form, { TYPE_CREATE } from "./Form";
import { buildInventoryExport } from "../../../reducers/remarketing/inventoryExports";
import { createRemarketingInventoryExport } from "../../../actions/remarketing/inventoryExportsActions";
import { IInventoryExportState } from "../../../sagas/Client/remarketingSagas";

const NewRemarketing: React.FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = ({
    onSave,
    clientId,
    client,
    inventoryExport,
    saving
}) => {
    return (
        <Fragment>
            <Header showButton={false} />
            <Form
                onSave={onSave}
                clientId={clientId}
                client={client}
                inventoryExport={inventoryExport}
                saving={saving}
                type={TYPE_CREATE}
                cancelPath="../"
            />
        </Fragment>
    );
};
const mapStateToProps = (state: any) => {
    const client = state.clients.entities[state.clients.currentClient];
    const inventoryExport: IInventoryExportState = buildInventoryExport(client);
    return {
        clientId: state.clients.currentClient,
        client,
        inventoryExport,
        saving: false
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onSave: (inventoryExport: IInventoryExportState, clientId: number, setErrors: (errors: any) => void) => {
            dispatch(createRemarketingInventoryExport(inventoryExport, clientId, setErrors));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRemarketing);

export { buildInventoryExport };
