import React, { useImperativeHandle, useRef, useState } from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { IConditional } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import TemplatePartInput from "./TemplatePartInput";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import EtaConditionals from "../EtaConditionals";
import { getDcParameterFields } from "../../../../utils/InventoryUtils";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { DragSource, DropTarget, DropTargetConnector, DragSourceConnector, DragSourceMonitor } from "react-dnd";
import CharacterCount from "../../../Shared/Form/CharacterCount";
import { get, findIndex, filter, orderBy, unionBy } from "lodash";
import SettingsIcon from "../../../Shared/Icons/SettingsIcon";
import ChevronDownIcon from "../../../Shared/Icons/ChevronDownIcon";
import EllipsisIcon from "../../../Shared/Icons/EllipsisIcon";
import { Tooltip } from "../../../Shared/Table";
import ConditionalsTooltipContent from "./ConditionalsTooltipContent";
import {
    IPartInstance,
    ITemplatePartDraggablePart,
    PART_TYPE,
    partSource,
    partTarget
} from "../../../../utils/dragAndDropParts";
import { characterLimit } from "../../../../utils/DynamicCampaignUtils";

const style = {
    cursor: "move"
};

export const ConditionalOperatorsForEtaPreview = [
    { label: "In", value: "IN" },
    { label: "Not In List", value: "NOTIN" },
    { label: "Equal To", value: "EQUAL" },
    { label: "Not Equal To", value: "NOT_EQUAL" },
    { label: "Greater Than", value: "GREATER_THAN" },
    { label: "Less than", value: "LESS_THAN" },
    { label: "Greater Than or Equal To", value: "GREATER_THAN_EQUAL" },
    { label: "Less Than or Equal To", value: "LESS_THAN_EQUAL" },
    { label: "Ends With", value: "ENDS_WITH" },
    { label: "Starts With", value: "STARTS_WITH" },
    { label: "Contains", value: "LIKE" },
    { label: "Does Not Contain", value: "NOTLIKE" },
    { label: "Contains Any In This List", value: "IN_LIKE" },
    { label: "Contains No Item In This List", value: "NOTIN_LIKE" },
    { label: "", value: "" }
];

const TemplatePart = React.forwardRef(
    (
        {
            canDelete,
            connectDragPreview,
            connectDragSource,
            connectDropTarget,
            isDragging,
            item,
            size,
            templateIndex,
            onDuplicate,
            fieldValidation,
            errors,
            allowDuplicate = true
        }: ITemplatePartDraggablePart,
        ref
    ) => {
        const [openParams, setOpenParams] = useState(false);
        const [showConditionalsTooltip, setShowConditionalsTooltip] = useState(false);
        const baseFieldName = `expandedTextAdTemplates[${templateIndex}]`; // so we can updated parent eta state
        const fieldName = `${baseFieldName}.parts[${item.index}]`;

        const opacity = isDragging ? 0.3 : 1;
        const marginLeft = isDragging ? -10 : 0;
        const elementRef = useRef(null);
        const dragRef = useRef(null);
        const paramRef = useRef(null);
        const conditionalsBtnRef = useRef(null);
        connectDragSource(dragRef);
        connectDropTarget(elementRef);
        connectDragPreview(elementRef);
        useOnClickOutside(paramRef, () => setOpenParams(false));

        const handleOnBlur = (form: any) => {
            form.setFieldTouched(`${fieldName}.value`, true);
        };

        const handleOnPin = (form: any, value: string | null) => {
            form.setFieldValue(fieldName, {
                ...item,
                pinnedField: value,
                dirty: true
            });
            form.setFieldValue(`${baseFieldName}.dirty`, true);
        };

        const handleParamsSelect = (event: React.MouseEvent, currentValue: string, form: any) => {
            event.preventDefault();
            const { textContent } = event.currentTarget;
            const newValue = `${item.value} {{${textContent}}}`;
            // set the eta part value and dirty
            form.setFieldValue(fieldName, {
                ...item,
                value: newValue,
                dirty: true
            });
            // set the eta as dirty
            form.setFieldValue(`${baseFieldName}.dirty`, true);
            setOpenParams(!openParams);
        };

        const handleRemovePart = (form: any) => {
            const parts = get(form.values, `${baseFieldName}.parts`, []);
            const indexToRemove = findIndex(parts, ["id", item.id]);

            if (item.new) {
                parts.splice(indexToRemove, 1);
            } else {
                parts[indexToRemove] = {
                    ...item,
                    deleted: true,
                    dirty: true
                };
            }

            const partsToReorder = filter(parts, ["field", item.field]);
            const reorderedParts = orderBy(
                partsToReorder,
                ["deleted", "order", "new", "id"],
                ["desc", "asc", "desc", "asc"]
            ).map((value: any, index) => {
                if (value.order === index) {
                    return value;
                }
                return { ...value, order: index, dirty: true };
            });

            form.setFieldValue(`${baseFieldName}.parts`, unionBy(reorderedParts, parts, "id"));
            form.setFieldValue(`${baseFieldName}.dirty`, true);
        };

        const handleInputChange = (
            event: React.ChangeEvent<HTMLInputElement> | { target: { value: string } },
            form: any
        ) => {
            const { value } = event.target;
            // set the eta part value and dirty
            form.setFieldValue(fieldName, { ...item, value, dirty: true });
            // set the eta as dirty
            form.setFieldValue(`${baseFieldName}.dirty`, true);
        };

        const handleUpdateConditionals = (conditionals: IConditional[], form: any) => {
            // set the eta part as dirty
            form.setFieldValue(fieldName, { ...item, conditionals, dirty: true });
            // set the eta as dirty
            form.setFieldValue(`${baseFieldName}.dirty`, true);
        };

        const hasConditionals = item.conditionals.filter((c: IConditional) => c && !c.deleted).length > 0;
        useImperativeHandle<unknown, IPartInstance>(ref, () => ({
            getNode: () => elementRef.current
        }));

        const hideCount = item.field === "final_url" || item.field === "path" || item.value.includes("{{");
        const buttonClasses =
            "bg-blue-100 border border-blue-100 hover:bg-blue-200 flex justify-center content-center py-3";
        return item.deleted ? null : (
            <React.Fragment>
                <Field
                    key={item.id}
                    name={`${fieldName}[value]`}
                    type="text"
                    validate={fieldValidation}
                    render={(props: FieldProps) => {
                        const { form, field } = props;

                        const isValid = () => {
                            const hasNoErrors = form.touched[fieldName] === false || !form.errors[fieldName]?.length;
                            if (item.field === "final_url" || item.field === "path") {
                                return hasNoErrors;
                            }

                            return (
                                (item.value?.includes("{{") || field.value.length <= characterLimit(item.field)) &&
                                hasNoErrors
                            );
                        };

                        return (
                            <div className="mt-2">
                                <div ref={elementRef} className="flex items-center" style={{ opacity, marginLeft }}>
                                    <button
                                        ref={dragRef}
                                        type="button"
                                        className={`${buttonClasses} text-blue-400 hover:text-blue-600`}
                                        style={{ ...style }}
                                    >
                                        <EllipsisIcon className="w-6 h-6" />
                                    </button>

                                    <ModalToggle
                                        toggleButton={(open: any) => (
                                            <div className="group inline-block relative">
                                                <button
                                                    ref={conditionalsBtnRef}
                                                    onClick={open}
                                                    onMouseEnter={() => setShowConditionalsTooltip(true)}
                                                    onMouseLeave={() => setShowConditionalsTooltip(false)}
                                                    type="button"
                                                    className={`${buttonClasses} ${
                                                        hasConditionals
                                                            ? "text-green-500 hover:text-green-600"
                                                            : "text-blue-400 hover:text-blue-600"
                                                    }`}
                                                >
                                                    <SettingsIcon className="w-6 h-6" />
                                                </button>

                                                {hasConditionals && (
                                                    <Tooltip
                                                        reference={conditionalsBtnRef}
                                                        shouldShow={showConditionalsTooltip}
                                                    >
                                                        <ConditionalsTooltipContent item={item} />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        )}
                                        modalContent={(hide: any) => (
                                            <EtaConditionals
                                                label="ETA Template Part Conditionals"
                                                conditionals={item.conditionals}
                                                setConditionals={(conditionals) => {
                                                    handleUpdateConditionals(conditionals, form);
                                                }}
                                                templateIndex={templateIndex}
                                                hide={hide}
                                            />
                                        )}
                                    />

                                    <button
                                        type="button"
                                        onClick={() => setOpenParams(!openParams)}
                                        className={`${buttonClasses} text-blue-400 hover:text-blue-600`}
                                    >
                                        <ChevronDownIcon className="w-6 h-6" />
                                    </button>

                                    <TemplatePartInput
                                        canDelete={canDelete}
                                        className={"w-full mr-12 rounded-none"}
                                        fieldName={fieldName}
                                        onChange={(event) => handleInputChange(event, form)}
                                        onPin={(value: string | null) => handleOnPin(form, value)}
                                        onBlur={() => handleOnBlur(form)}
                                        onRemove={() => handleRemovePart(form)}
                                        size={size}
                                        errors={errors[fieldName]}
                                        value={field.value}
                                        fieldType={item.field}
                                        pinnedField={item.pinnedField}
                                        onDuplicate={onDuplicate}
                                        isValid={isValid()}
                                        allowDuplicate={allowDuplicate}
                                    />
                                    <CharacterCount
                                        value={field.value}
                                        characterLimit={characterLimit(item.field)}
                                        hideCount={hideCount}
                                    />
                                </div>
                                {openParams && (
                                    <div ref={paramRef} className="relative">
                                        <div className="absolute z-10 top-0 left-0 shadow-lg bg-white flex rounded border border-gray-300 p-3 flex-col">
                                            <div className="text-sm uppercase text-gray-600">Parameters</div>
                                            {getDcParameterFields().map((param) => {
                                                return (
                                                    <div key={param}>
                                                        <a
                                                            onClick={(event) =>
                                                                handleParamsSelect(event, field.value, form)
                                                            }
                                                            href=""
                                                        >
                                                            {param}
                                                        </a>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                />
                <div className="text-red-600 pb-4">{errors[`${fieldName}.value`] ?? ""}</div>
            </React.Fragment>
        );
    }
);

export default DropTarget(PART_TYPE, partTarget, (connect: DropTargetConnector) => {
    return {
        connectDropTarget: connect.dropTarget()
    };
})(
    DragSource(PART_TYPE, partSource, (connect: DragSourceConnector, monitor: DragSourceMonitor) => {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging()
        };
    })(TemplatePart)
);
