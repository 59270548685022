import React, { useRef, LegacyRef, useContext } from "react";
import useConditionalParams from "../../../hooks/useConditionalParams";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import useToggle from "../../../hooks/useToggle";
import Button from "../../Shared/Button";
import { ConditionalFormModal } from "../../Shared/Conditional/ConditionalForm";
import AutoCompleteField from "../../Shared/Form/Blocks/AutoCompleteField";
import EllipsisIcon from "../../Shared/Icons/EllipsisIcon";
import TrashIcon from "../../Shared/Icons/TrashIcon";
import {
    AddPartButton,
    INamingConventionsFormContextReturn,
    NamingConventionFormContext
} from "./NamingConventionsForm";

import { uniqueId } from "lodash";
import SortableList from "../../Shared/Form/SortableList/SortableList";
import { INamingConvention } from "../../../hooks/namingConventions/useNamingConvention";
interface INamingConventionPartProps {
    id: number;
    index: number;
    name: string | undefined;
    values: any;
    isDragging?: boolean;
    sortableProps?: any;
    conditionals?: any;
    dragListeners?: any;
    parentIndex: number;
    onSaveConditional: () => void;
}

const NamingConventionPart = ({
    namingConvention,
    ...part
}: INamingConventionPartProps & { namingConvention: INamingConvention }) => {
    const {
        id,
        name,
        values,
        index: partIndex,
        parentIndex,
        conditionals,
        isDragging = false,
        dragListeners,
        sortableProps,
        onSaveConditional
    } = part;
    //pull what we need from the naming convention form context.

    const {
        loadingNamingConventions: loading,
        savingNamingConvention = false,
        namingConventionParts = [],
        setValue,
        getValues,
        register,
        watch,
        update,
        replace,
        namingConventionPartsErrors,
        deleteNamingConventionPartValue,
        deleteNamingConventionPart
    } = useContext<INamingConventionsFormContextReturn>(NamingConventionFormContext);

    const error: any = (namingConventionPartsErrors && namingConventionPartsErrors[partIndex]) || null;
    const deleteRef = useRef<HTMLDivElement>() as LegacyRef<HTMLDivElement>;
    const elementRef = useRef<HTMLDivElement>();
    const suggestionsListRef = useRef() as LegacyRef<HTMLDivElement>;
    const autoCompleteFieldRef = useRef<HTMLDivElement>();
    const [deleteRequested, toggleDeleteRequested] = useToggle(false);
    const { conditionalParameterList: parametersList, conditionableModels } = useConditionalParams();

    //useClickOutside is to hide the delete button when clicking somewhere else.
    useOnClickOutside(deleteRef, () => deleteRequested && toggleDeleteRequested(), [deleteRequested]);

    //useFieldArray unique field name: This could be a part or a part value, this logic handles it. Note: casting to const is required by useFieldArray
    const isPartValue = "parentIndex" in part;
    const partKey: string = `namingConventionParts.${isPartValue ? parentIndex : partIndex}`; //part key is the part unique id for useFieldArray.
    const keys = {
        name: `${partKey}.${isPartValue ? `values.${partIndex}.value` : "name"}` as const,
        id: `${partKey}.id` as const,
        mustAllConditionsPass: `${partKey}.mustAllConditionsPass` as const,
        type: "type" as const,
        suggestionsListId: "" //dynamically redefined below
    };
    keys.suggestionsListId = `suggestions-list-${keys.name}`; //name is unique and suggestions-list is only one, so we can use it as id.
    const suggestionsListContainer = document.getElementById(keys.suggestionsListId);
    //register the key (name for parts or value for part values) field into useForm, to track user input.
    register(keys.name, { required: true, shouldUnregister: true });
    //watchers: these consts will always have the up to date value of the field (will also casuse rerender on change) that's is the reason not all fields are watched by default.
    const namingConventionType = watch(keys.type);
    const mustAllConditionsPass = watch(keys.mustAllConditionsPass);
    //currently, the dynamic array of parts is handled by useFieldArray from useForm, while the part values are internally handled here. rather sortable list should handle adding dynamic fields by itself.
    const isUnsaved = (isPartValue && typeof id === "string") || (!isPartValue && !id);
    const disabled = loading || isUnsaved;

    const conditionsMustPassClickHandler = (value: boolean) =>
        setValue(keys.mustAllConditionsPass, value, { shouldDirty: true, shouldTouch: true });
    const parentPart = getValues().namingConventionParts[parentIndex];

    if (suggestionsListContainer && suggestionsListRef) {
        //this code positions MentionsInput suggestions list rendered as a portal
        const top = autoCompleteFieldRef?.current?.offsetTop;
        const left = elementRef?.current?.offsetLeft;
        suggestionsListContainer.style.top = `${(top || 0) + 30}px`;
        suggestionsListContainer.style.left = `${left}px`;
    }

    const conditionableType = isPartValue
        ? conditionableModels.namingConventionPartValue
        : conditionableModels.namingConventionPart;

    const deleteHandler = async () => {
        if (isPartValue) {
            if (!isUnsaved)
                await deleteNamingConventionPartValue({
                    namingConventionPartValueId: id
                });
            const filtered = parentPart.values.filter((partValue: any) => partValue.id !== id);
            update(parentIndex, { ...parentPart, values: filtered });
        } else {
            if (!isUnsaved)
                await deleteNamingConventionPart({
                    namingConventionPartId: id
                });

            const filtered = getValues().namingConventionParts.filter((part: any) => part.id !== id);
            replace(filtered);
        }
    };

    const addNewPartValueHandler = (partIndex: number) => {
        //adds a part value by updating the whole field and appending a new value on the array.
        const { id: namingConventionPartId } = namingConventionParts[partIndex];
        const parentPart = getValues().namingConventionParts[partIndex];

        const newPartValue = {
            conditionals: [],
            createdAt: "",
            id: uniqueId("id-"), //when a new partvalue is added, it will have a fake id until it is saved.
            mustAllConditionsPass: true,
            namingConventionPartId,
            order: values?.length ?? 0,
            updatedAt: "",
            value: ""
        };
        update(partIndex, { ...parentPart, values: [...parentPart.values, newPartValue] });
    };

    const iconButtonStyles = `mt-2 p-2 text-gray-${
        savingNamingConvention ? "400 cursor-default" : "600 cursor-pointer"
    } ${!savingNamingConvention ? "hover:text-gray-800" : ""}`;

    return (
        <div className={`flex flex-col align-content-center ${"className"}`.trim()} {...sortableProps}>
            <div id={keys.suggestionsListId} className="absolute w-1/2 z-20"></div>
            <div className="flex flex-row items-center w-full">
                <button
                    {...dragListeners} //attach dragListeners to the element that will act as drag handle.
                    title="Drag to re order parts and values."
                    type="button"
                    className={`cursor-move drag-handle opacity-75 bg-blue-100 text-blue-400 border rounded-l border-gray-500 py-1`}
                    disabled={false}
                >
                    <EllipsisIcon className="w-6 h-8" />
                </button>

                <ConditionalFormModal
                    disabled={disabled}
                    buttonClassName={"bg-blue-100 border-t border-b border-gray-500 py-1 pr-1 -mr-1"}
                    subTitle={name || ""}
                    existingConditionals={{ conditionals: conditionals }}
                    conditionals={conditionals}
                    hasConditionals={conditionals?.length > 0}
                    conditionalType={conditionableType}
                    id={id}
                    namingConvention={namingConvention}
                    showMustAllConditionsPass={true}
                    allConditionsMustPassValue={mustAllConditionsPass}
                    onAllConditionsMustPassClick={conditionsMustPassClickHandler}
                    onFinish={onSaveConditional}
                    iconSize={8}
                />
                <div className="flex flex-1 relative z-10" ref={autoCompleteFieldRef as LegacyRef<HTMLDivElement>}>
                    <AutoCompleteField
                        key={name}
                        name={name || ""}
                        value={watch(keys.name)}
                        style={{ paddingTop: "0", marginTop: "-0.28rem" }}
                        handleChange={(e: any) =>
                            setValue(keys.name, e.target.value, { shouldDirty: true, shouldTouch: true })
                        }
                        required={true}
                        autocompleteFields={parametersList[namingConventionType as string].map((field) => field.value)}
                        placeholder="{{Make}}"
                        className="flex-1 mr-0 bg-white rounded-lg"
                        suggestionsportalHost={suggestionsListContainer as Element}
                        inputTrasparent={true}
                    />
                </div>
                <div className={"flex items-center ml-2"} ref={deleteRef}>
                    <button
                        type="button"
                        onClick={() => !loading && toggleDeleteRequested()}
                        className={`target:outline-none text-gray-500 ${
                            loading ? "opacity-75 cursor-not-allowed" : "cursor-pointer"
                        } ${!loading ? "hover:text-gray-800" : ""}`}
                    >
                        <TrashIcon className="w-8 h-8 text-xl" />
                    </button>

                    {deleteRequested && (
                        <div className={"flex gap-4 ml-4"}>
                            <Button
                                disabled={loading}
                                type="button"
                                onClick={toggleDeleteRequested}
                                styleType={"secondary"}
                            >
                                Cancel
                            </Button>
                            <Button disabled={loading} type="button" onClick={deleteHandler} styleType={"primary"}>
                                Delete
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {error && (error.name?.ref?.name === name || error?.ref?.name === name) && (
                <span className={"pl-16 pt-2 text-red-500 text-sm italic uppercase"}>This value cannot be empty</span>
            )}

            <div className={`w-full pl-3 mt-4 ${isDragging ? "hidden" : ""}`}>
                <div className="flex flex-2 flex-col ">
                    <div className="flex flex-col gap-4 border-l-2 border-slate-600 pl-4">
                        {!!values?.length && (
                            <SortableList
                                items={values}
                                parentIndex={partIndex}
                                ItemComponent={NamingConventionPart}
                                onSort={(sortedItems) => {
                                    const updated = getValues().namingConventionParts;
                                    updated[partIndex] = { ...updated[partIndex], values: sortedItems };
                                    replace(updated);
                                }}
                                namingConvention={namingConvention}
                            />
                        )}
                        <AddPartButton
                            hide={parentPart?.values.length > 0}
                            disabled={isUnsaved}
                            className={`flex ${iconButtonStyles} ${isUnsaved ? "opacity-75 cursor-not-allowed" : ""}`}
                            onClick={() => !savingNamingConvention && addNewPartValueHandler(partIndex)}
                            buttonText={isUnsaved ? "Please save before adding part values" : "New Part Value"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NamingConventionPart;
