import { Moment } from "moment";

const defaultRanges = {
    "Last 7 Days": {
        startDate: function startDate(now: Moment) {
            return now.add(-7, "days");
        },
        endDate: function endDate(now: Moment) {
            return now;
        }
    },

    "Last 30 Days": {
        startDate: function startDate(now: Moment) {
            return now.add(-30, "days");
        },
        endDate: function endDate(now: Moment) {
            return now;
        }
    },
    "Last Month": {
        startDate: function startDate(now: Moment) {
            return now.add(-1, "months");
        },
        endDate: function endDate(now: Moment) {
            return now;
        }
    },
    "Last 60 Days": {
        startDate: function startDate(now: Moment) {
            return now.add(-60, "days");
        },
        endDate: function endDate(now: Moment) {
            return now;
        }
    },
    "Last 6 Months": {
        startDate: function startDate(now: Moment) {
            return now.add(-6, "months");
        },
        endDate: function endDate(now: Moment) {
            return now;
        }
    },
    "Last Year": {
        startDate: function startDate(now: Moment) {
            return now.add(-1, "years");
        },
        endDate: function endDate(now: Moment) {
            return now;
        }
    }
};

export default defaultRanges;
