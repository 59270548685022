import { ConditionalOperators } from "../utils/InventoryUtils";

function useConditionalParams() {
    const conditionableModels = {
        namingConvention: "App\\Models\\NamingConvention",
        namingConventionPart: "App\\Models\\NamingConventionPart",
        namingConventionPartValue: "App\\Models\\NamingConventionPartValue"
    };
    const clientConditionalFields = [
        {
            value: "client.id",
            label: "Client id"
        },
        {
            value: "client.name",
            label: "Client name"
        },
        {
            value: "client.dashboard_api_key",
            label: "Client dashboard_api_key"
        },
        {
            value: "client.cars_seller_id",
            label: "Client cars_seller_id"
        },
        {
            value: "client.koddi_id",
            label: "Client koddi_id"
        },
        {
            value: "client.adwords_id",
            label: "Client adwords_id"
        },
        {
            value: "client.bing_id",
            label: "Client bing_id"
        },
        {
            value: "client.bing_number",
            label: "Client bing_number"
        },
        {
            value: "client.bing_name",
            label: "Client bing_name"
        },
        {
            value: "client.dfa_advertiser_id",
            label: "Client dfa_advertiser_id"
        },
        {
            value: "client.time_zone",
            label: "Client time_zone"
        },
        {
            value: "client.lft",
            label: "Client lft"
        },
        {
            value: "client.rgt",
            label: "Client rgt"
        },
        {
            value: "client.parent_id",
            label: "Client parent_id"
        },
        {
            value: "client.negative_keywords",
            label: "Client negative_keywords"
        },
        {
            value: "client.di_site",
            label: "Client di_site"
        },
        {
            value: "client.domain",
            label: "Client domain"
        },
        {
            value: "client.inventory_api_key",
            label: "Client inventory_api_key"
        },
        {
            value: "client.inventory_feed_id",
            label: "Client inventory_feed_id"
        },
        {
            value: "client.ga_account_id",
            label: "Client ga_account_id"
        },
        {
            value: "client.ga_account_name",
            label: "Client ga_account_name"
        },
        {
            value: "client.ga_profile_id",
            label: "Client ga_profile_id"
        },
        {
            value: "client.ga_profile_name",
            label: "Client ga_profile_name"
        },
        {
            value: "client.dealer_code",
            label: "Client dealer_code"
        },
        {
            value: "client.crm_email",
            label: "Client crm_email"
        },
        {
            value: "client.facebook_page_id",
            label: "Client facebook_page_id"
        },
        {
            value: "client.facebook_account_id",
            label: "Client facebook_account_id"
        },
        {
            value: "client.oem_program",
            label: "Client oem_program"
        },
        {
            value: "client.oem_slug",
            label: "Client oem_slug"
        },
        {
            value: "client.oem_end_date",
            label: "Client oem_end_date"
        },
        {
            value: "client.oem_go_live_date",
            label: "Client oem_go_live_date"
        },
        {
            value: "client.location_code",
            label: "Client location_code"
        },
        {
            value: "client.salesforce_id",
            label: "Client salesforce_id"
        },
        {
            value: "client.engine_id",
            label: "Client engine_id"
        },
        {
            value: "client.short_name_for_dealer",
            label: "Client short_name_for_dealer"
        },
        {
            value: "client.managed_by_user_id",
            label: "Client managed_by_user_id"
        },
        {
            value: "client.supervisor_user_id",
            label: "Client supervisor_user_id"
        },
        {
            value: "client.cancel_at",
            label: "Client cancel_at"
        },
        {
            value: "client.address",
            label: "Client address"
        },
        {
            value: "client.city",
            label: "Client city"
        },
        {
            value: "client.state",
            label: "Client state"
        },
        {
            value: "client.zip",
            label: "Client zip"
        },
        {
            value: "client.country",
            label: "Client country"
        }
    ];

    const vehicleConditionalFields = [
        {
            label: "Vehicle ID",
            value: "vehicle.ID"
        },
        {
            label: "Vehicle Type",
            value: "vehicle.Type"
        },
        {
            label: "Vehicle Stock",
            value: "vehicle.Stock"
        },
        {
            label: "Vehicle VIN",
            value: "vehicle.VIN"
        },
        {
            label: "Vehicle Year",
            value: "vehicle.Year"
        },
        {
            label: "Vehicle Make",
            value: "vehicle.Make"
        },
        {
            label: "Vehicle Model",
            value: "vehicle.Model"
        },
        {
            label: "Vehicle Trim",
            value: "vehicle.Trim"
        },
        {
            label: "Vehicle Body",
            value: "vehicle.Body"
        },
        {
            label: "Vehicle GenericBodytype",
            value: "vehicle.GenericBodytype"
        },
        {
            label: "Vehicle ChromeMake",
            value: "vehicle.ChromeMake"
        },
        {
            label: "Vehicle ChromeModel",
            value: "vehicle.ChromeModel"
        },
        {
            label: "Vehicle ChromeTrim",
            value: "vehicle.ChromeTrim"
        },
        {
            label: "Vehicle ExtColorGeneric",
            value: "vehicle.ExtColorGeneric"
        },
        {
            label: "Vehicle Miles",
            value: "vehicle.Miles"
        },
        {
            label: "Vehicle OriginalPrice",
            value: "vehicle.OriginalPrice"
        },
        {
            label: "Vehicle OurPrice",
            value: "vehicle.OurPrice"
        },
        {
            label: "Vehicle FinalPrice",
            value: "vehicle.FinalPrice"
        },
        {
            label: "Vehicle Drivetrain",
            value: "vehicle.Drivetrain"
        },
        {
            label: "Vehicle Transmission",
            value: "vehicle.Transmission"
        },
        {
            label: "Vehicle Certified",
            value: "vehicle.Certified"
        },
        {
            label: "Vehicle DateInStock",
            value: "vehicle.DateInStock"
        },
        {
            label: "Vehicle MonthlyPayments",
            value: "vehicle.MonthlyPayments"
        },
        {
            label: "Vehicle Link",
            value: "vehicle.Link"
        },
        {
            label: "Vehicle Status",
            value: "vehicle.Status"
        },
        {
            label: "Vehicle VDPViews",
            value: "vehicle.VDPViews"
        },
        {
            label: "Vehicle Images",
            value: "vehicle.Images"
        },
        {
            label: "Vehicle Thumbnail",
            value: "vehicle.Thumbnail"
        },
        {
            label: "Vehicle CarFaxOneOwner",
            value: "vehicle.CarFaxOneOwner"
        },
        {
            label: "Vehicle LeasePayment",
            value: "vehicle.LeasePayment"
        },
        {
            label: "Vehicle Discounts",
            value: "vehicle.Discounts"
        },
        {
            label: "Vehicle ImageCount",
            value: "vehicle.ImageCount"
        },
        {
            label: "Vehicle FeedId",
            value: "vehicle.FeedId"
        },
        {
            label: "Vehicle Family",
            value: "vehicle.Family"
        },
        {
            label: "Vehicle FuelType",
            value: "vehicle.FuelType"
        },
        {
            label: "Vehicle SpecialField2",
            value: "vehicle.SpecialField2"
        },
        {
            label: "Vehicle SpecialField3",
            value: "vehicle.SpecialField3"
        },
        {
            label: "Vehicle SpecialField4",
            value: "vehicle.SpecialField4"
        },
        {
            label: "Vehicle SpecialField5",
            value: "vehicle.SpecialField5"
        },
        {
            label: "Vehicle LeaseDetails",
            value: "vehicle.LeaseDetails"
        },
        {
            label: "Vehicle FinanceDetails",
            value: "vehicle.FinanceDetails"
        }
    ];
    const campaignConditionalFields = [
        {
            label: "Campaign Name",
            value: "campaignName"
        }
    ];
    const adgroupConditionalFields = [
        {
            label: "AdGroup Name",
            value: "adgroupName"
        }
    ];
    const dynamicCampaignConditionalFields = [
        {
            label: "Dynamic Campaign Name",
            value: "dynamicCampaign.name"
        }
    ];

    const conditionalFieldOptions = [
        ...campaignConditionalFields,
        ...adgroupConditionalFields,
        ...clientConditionalFields,
        ...dynamicCampaignConditionalFields,
        ...vehicleConditionalFields
    ];

    const conditionalParameterList: { [key: string]: any[] } = {
        campaign: [...clientConditionalFields, ...campaignConditionalFields],
        dynamic_campaign_adgroup: [...clientConditionalFields, ...vehicleConditionalFields],
        dealer_setup_adgroup: [...adgroupConditionalFields, ...clientConditionalFields],
        account: clientConditionalFields
    };
    return {
        adgroupConditionalFields,
        conditionableModels,
        clientConditionalFields,
        dynamicCampaignConditionalFields,
        vehicleConditionalFields,
        campaignConditionalFields,
        conditionalFieldOptions,
        conditionalParameterList,
        ConditionalOperators
    };
}

export default useConditionalParams;
