import { schema } from "normalizr";

const manualBudgetResponse = new schema.Entity("manualBudgets");
const manualBudgetActivationResponse = new schema.Entity("manualBudgetActivations");

const Schemas = {
    MANUAL_BUDGETS_ARRAY: { data: [manualBudgetResponse] },
    MANUAL_BUDGET: manualBudgetResponse,
    MANUAL_BUDGET_ACTIVATIONS_ARRAY: { data: [manualBudgetActivationResponse] },
    MANUAL_BUDGET_ACTIVATIONS: manualBudgetActivationResponse
};

export default Schemas;
