import React, { MouseEvent, FunctionComponent, useState } from "react";
import FilterOption from "./FilterOption";
import { capitalize, startCase } from "lodash";
import ChevronDownIcon from "../../Shared/Icons/ChevronDownIcon";
import ChevronUpIcon from "../../Shared/Icons/ChevronUpIcon";

interface IFilterSection {
    label: string;
    filterOptions: { [key: string]: boolean };
    filterCounts?: { [key: string]: number };
    onFilterChange(label: string, field: string): void;
}

const FilterSection: FunctionComponent<IFilterSection> = ({ onFilterChange, label, filterOptions, filterCounts }) => {
    const [expanded, setExpanded] = useState(false);

    const onToggle = (e: MouseEvent) => {
        e.preventDefault();
        setExpanded((currentExpanded) => {
            return !currentExpanded;
        });
    };

    const handleFilterChange = (field: string) => {
        onFilterChange(label, field);
    };

    if (filterCounts === undefined) {
        return null;
    }

    return (
        <section>
            <header onClick={onToggle} className="flex items-center cursor-pointer">
                {expanded ? <ChevronUpIcon className="w-6 h-6" /> : <ChevronDownIcon className="w-6 h-6" />}
                <span className="ml-2 py-1">{capitalize(startCase(label))}</span>
            </header>
            <section>
                {expanded &&
                    Object.keys(filterOptions).map((field) => (
                        <FilterOption
                            onClick={() => handleFilterChange(field)}
                            key={field}
                            label={field}
                            count={filterCounts[field] === undefined ? "" : filterCounts[field]}
                            checked={filterOptions[field]}
                        />
                    ))}
            </section>
        </section>
    );
};

export default FilterSection;
