import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import React, { FunctionComponent } from "react";
import useManualBudget from "../../../../hooks/budgets/useManualBudget";
import convertUTCTimeToChicago from "../../../../utils/convertUTCTimeToChicago";
import { ConfirmDialog, TabLinks } from "../../../Shared";
import Alert from "../../../Shared/Alert";
import ToggleSwitch from "../../../Shared/Form/ToggleSwitch";
import SearchInput from "../../../Shared/SearchInput";
import { Table } from "../../../Shared/Table";
import { buildManualBudgetColumns } from "../BudgetColumns";
import BudgetLayout from "../BudgetLayout";
import useBudgetParams from "../../../../hooks/budgets/useBudgetParams";
interface Iprops {}

const ManualBudgetList: FunctionComponent<Iprops> = () => {
    const { budgetType, clientId, client, budgetServices, isOemReports } = useBudgetParams();
    const {
        budgets,
        loadingBudget: loadingTable,
        budgetToDelete,
        showConfirm,
        showDeleted,
        canScheduleCancel,
        links,
        deleteBudgetHandler,
        deleteCancelHandler,
        deleteConfirmHandler,
        toggleDeletedHandler,
        searchQueryChangeHandler
    } = useManualBudget(); //access manual budget API

    const location = useLocation();

    const tableColumns = buildManualBudgetColumns(
        budgetType?.toLowerCase() ?? "",
        clientId ? clientId : undefined,
        (clientIdWeDontCareAbout: number, budgetId: number) => {
            deleteBudgetHandler(budgetId);
        },
        showDeleted
    );

    return (
        <BudgetLayout>
            {!!client?.cancelAt && (
                <Alert title="Warning! Client is scheduled for cancellation." type="warning">
                    <>
                        <p data-testid={"cancellation-alert"}>
                            This client is scheduled for cancellation on{" "}
                            {format(convertUTCTimeToChicago(client.cancelAt) as Date, "MMMM d, yyyy")}.
                        </p>
                        {!!canScheduleCancel && (
                            <Link className="underline" to={`/clients/${clientId}`}>
                                Edit Cancellation
                            </Link>
                        )}
                    </>
                </Alert>
            )}
            {!isOemReports && (
                <div className="mx-2">
                    <TabLinks tabs={links} />
                </div>
            )}
            <div className="bg-white rounded overflow-hidden">
                <div className="p-4 flex items-center w-full">
                    <SearchInput
                        onChange={searchQueryChangeHandler}
                        useSearchHistory={true}
                        url={location.pathname}
                        queryParamName={`${budgetType}Search`}
                        extraQueryParams={{
                            ...(showDeleted
                                ? {
                                      showDeleted
                                  }
                                : {})
                        }}
                    />
                    <div className="-mt-1">
                        <ToggleSwitch
                            label={showDeleted ? "Hide deleted budgets" : "Show deleted budgets"}
                            onToggle={toggleDeletedHandler}
                            value={showDeleted}
                            field={"show-deleted"}
                        />
                    </div>
                </div>
                <div className="text-sm">
                    <Table
                        data={budgets}
                        columns={tableColumns}
                        loadingTable={loadingTable}
                        loadingMessage={`Loading ${budgetServices[budgetType].titleName} Budgets`}
                    />
                </div>

                {showConfirm && (
                    <ConfirmDialog
                        title={"Are you sure you want to delete this budget?"}
                        message={
                            <div>
                                <div>Label: {budgetToDelete?.lastActivation?.label}</div>
                                <div>Target Spend: ${budgetToDelete?.lastActivation?.targetSpend}</div>
                            </div>
                        }
                        onCancel={deleteCancelHandler}
                        onConfirm={deleteConfirmHandler}
                        confirmText={"Delete"}
                    />
                )}
            </div>
        </BudgetLayout>
    );
};
export default ManualBudgetList;
