import { SampleDataActionTypes, ISampleDataState } from "../../interfaces/DynamicCampaigns/ITemplatePart";
import DynamicCampaignConstants from "../../constants/DynamicCampaignConstants";
import mergeInventorySampleData from "../../utils/mergeInventorySampleData";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";

export const defaultSampleDataValue = {
    Body: "XXXX",
    CarFaxOneOwner: "XXXX",
    Certified: "X",
    ChromeMake: "XXXX",
    ChromeModel: "XXXX",
    ChromeTrim: "XXXX",
    DateInStock: "XXXX",
    DaysInStock: "XX",
    DealerAddress: "XXXX",
    DealerCity: "XXXX",
    DealerCountry: "XXXX",
    DealerLat: "XXXX",
    DealerLng: "XXXX",
    DealerState: "XXXX",
    Discounts: "XXXX",
    Drivetrain: "XXXX",
    ExtColorGeneric: "XXXX",
    FeedId: "XXXX",
    FinalPrice: "XX,XXX",
    GenericBodytype: "XXXX",
    ID: "XXXX",
    Images: "XXXX",
    LeasePayment: "XXX",
    Link: "XXXX",
    Make: "XXXX",
    Miles: "XXXX",
    Model: "XXXX",
    MonthlyPayments: "XXX",
    OriginalPrice: "XX,XXX",
    OurPrice: "XX,XXX",
    SpecialField2: "XXXX",
    SpecialField3: "XXXX",
    SpecialField4: "XXXX",
    SpecialField5: "XXXX",
    Status: "XXXX",
    Stock: "XXXX",
    Thumbnail: "XXXX",
    Transmission: "XXXX",
    Trim: "XXXX",
    Type: "XXXX",
    VDPViews: "XXXX",
    VIN: "XXXX",
    Year: "XXXX",
    model_lowest_price: "XX,XXX",
    model_lease_payments: "XXX",
    model_monthly_payments: "XXX",
    model_discounts: "XXX",
    model_lowest_miles: "XX,XXX",
    duplicate_count: "XX",
    trims_count: "X",
    colors_count: "X",
    dealer_name: "XXXX",
    model_highest_year: "XXXX",
    Date: "XXXX",
    ImageCount: "XX",
    Price: "XX,XXX"
};

export const initialState: ISampleDataState = {
    entities: {
        sampleData: defaultSampleDataValue,
        inventorySample: [],
        inventoryFieldValues: {}
    },
    loadedAt: null,
    error: null
};

function inventorySampleDataReducer(state = initialState, action: SampleDataActionTypes): ISampleDataState {
    switch (action.type) {
        case DynamicCampaignConstants.REQUEST_INVENTORY_SAMPLE_DATA:
            return { ...state, loadedAt: null, error: null };
        case DynamicCampaignConstants.REQUEST_INVENTORY_SAMPLE_DATA_FAIL:
            return { ...state, loadedAt: null, error: action.payload };
        case DynamicCampaignConstants.REQUEST_INVENTORY_SAMPLE_DATA_SUCCESS:
            const { sampleData, inventorySample, inventoryFieldValues } = action.payload;
            return {
                ...state,
                loadedAt: new Date(Date.now()),
                entities: {
                    sampleData: mergeInventorySampleData(state.entities.sampleData, sampleData),
                    inventorySample: [...state.entities.inventorySample, ...inventorySample],
                    inventoryFieldValues: { ...state.entities.inventoryFieldValues, ...inventoryFieldValues }
                },
                error: null
            };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
}

export default inventorySampleDataReducer;
