import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import ModalToggle from "../Dialogs/ModalToggle";
import Button from "../Button";
import useBudgetParams from "../../../hooks/budgets/useBudgetParams";
import IAppState from "../../../interfaces/IAppState";
interface IProps {
    disabled: boolean;
    buttonText: string;
    budget: any;
    form: any;
    children?: any;
    isSaving?: boolean;
    action: (t: any) => void;
}
const UPPER_DANGER_BOUND_CHANGE_LIMIT_AMOUNT = 2000;

const BudgetPushPreview = ({ buttonText, form, budget, action, disabled }: IProps) => {
    const campaigns = useSelector((state: IAppState) => state.adwordsCampaigns.entities);
    const campaignsWithLabel = Object.values(campaigns).filter(
        (campaign: any) => campaign.labels?.filter((label: any) => String(label.id) === form?.labelId).length > 0
    );
    const [totalDifference, setTotalDifference] = useState((form.targetSpend ?? 0) - Number(form.monthlyBudget));

    const [currentBudget, setCurrentBudget] = useState(form.targetSpend ?? 0);
    const [newCurrentBudget, setNewCurrentBudget] = useState(form.monthlyBudget);

    const [hasThereBeenAChange, setHasThereBeenAChange] = useState(
        String(form.monthlyBudget) !== String(form.targetSpend)
    );

    useEffect(() => {
        setCurrentBudget(form.targetSpend ?? 0);
        setNewCurrentBudget(form.monthlyBudget);
        setTotalDifference((form.targetSpend ?? 0) - Number(form.monthlyBudget));

        setHasThereBeenAChange(String(form.targetSpend ?? 0) !== String(form.monthlyBudget));
    }, [form?.newDailyBudget, form?.monthlyBudget, form?.targetSpend, form?.labelId, form?.label, form?.campaigns]);

    const { budgetType } = useBudgetParams();
    return (
        <ModalToggle
            toggleButton={(open) => (
                <Button onClick={open} disabled={disabled} styleType="primary">
                    {buttonText}
                </Button>
            )}
            modalContent={(close) => (
                <div className="p-4">
                    <div className="grid grid-cols-3 w-full gap-4">
                        <div></div>
                        <h2 className="text-base uppercase text-gray-600">{budgetType} Budget Change Preview</h2>
                        <div></div>
                    </div>
                    <div className="w-1/3 mx-auto border-t border-gray-700 "></div>

                    <div className="flex flex-col  w-full">
                        <div>
                            <div className={"grid grid-cols-3 col-end-1 w-full gap-4 text-black py-1"}>
                                <div></div>
                                <div className="flex items-center justify-between">
                                    <div className={"text-lg text-right"}>Current Budget</div>
                                    <div className={"text-2xl text-right"}>
                                        {"$" + Number(currentBudget ?? 0).toLocaleString()}
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        {hasThereBeenAChange && (
                            <div>
                                <div className={"grid grid-cols-3 col-end-1 w-full gap-4 text-black"}>
                                    <div></div>
                                    <div className="flex items-center justify-between border-t border-gray-400 py-1">
                                        <div className={"text-lg text-right"}>New Budget</div>
                                        <div className={"text-2xl text-right"}>
                                            {"$" + Number(form.monthlyBudget).toLocaleString()}
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        )}

                        {hasThereBeenAChange && budget?.id && (
                            <div className="grid grid-cols-3 col-end-1 w-full gap-4">
                                <div></div>
                                <div className="flex text-xl items-center justify-between border-t border-gray-400 py-1">
                                    <div>Difference</div>

                                    <div
                                        className={`text-right ${
                                            hasThereBeenAChange
                                                ? totalDifference < 0
                                                    ? "text-green-600"
                                                    : "text-red-600"
                                                : ""
                                        }`}
                                    >
                                        $
                                        {Math.abs(totalDifference).toLocaleString() +
                                            " " +
                                            (hasThereBeenAChange
                                                ? newCurrentBudget > currentBudget
                                                    ? "increase"
                                                    : "decrease"
                                                : "")}
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        )}

                        <div className="grid grid-cols-3 col-end-1 w-full gap-4 text-gray-700">
                            <div></div>
                            <div className="flex text-base items-center justify-between border-t border-gray-400 py-1">
                                <div>Label</div>

                                <div className={`text-right`}>{form?.label?.name}</div>
                            </div>
                            <div></div>
                        </div>

                        <div className="grid grid-cols-3 col-end-1 w-full gap-4 text-gray-700">
                            <div></div>
                            <div className="flex text-base justify-between border-t border-gray-400 pt-2">
                                <div>Campaigns</div>

                                <div className="text-right whitespace-pre text-base flex flex-col w-2/3">
                                    {campaignsWithLabel.map((campaign: any) => (
                                        <div key={campaign.id + "campaign-name-preview"}>{campaign.name}</div>
                                    ))}
                                    {campaignsWithLabel.length === 0 && (
                                        <span className="italic">No campaigns attached to label</span>
                                    )}
                                </div>
                            </div>
                            <div></div>
                        </div>
                        {Math.abs(totalDifference ?? 0) > UPPER_DANGER_BOUND_CHANGE_LIMIT_AMOUNT && (
                            <div className="text-base w-1/3 mx-auto gap-4 pt-2">
                                <div
                                    className={`flex rounded-b border border-red-500 bg-red-100 text-red-600  px-4 py-2`}
                                >
                                    <div className="flex-grow uppercase">large adjustment:</div>
                                    <div className="text-right">
                                        ${Math.abs(totalDifference).toLocaleString()}{" "}
                                        {hasThereBeenAChange
                                            ? newCurrentBudget > currentBudget
                                                ? "increase"
                                                : "decrease"
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end gap-4 grid grid-cols-3 mt-4">
                        <div></div>
                        <div className={"flex justify-between"}>
                            <Button onClick={close} styleType="secondary">
                                Close
                            </Button>
                            <Button
                                onClick={() => {
                                    action(form);
                                }}
                                styleType="primary"
                            >
                                Save Budget
                            </Button>
                        </div>
                        <div></div>
                    </div>
                </div>
            )}
        ></ModalToggle>
    );
};

export default BudgetPushPreview;
