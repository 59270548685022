import React from "react";

import SalesDataImportForm from "./SalesDataImportForm";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import ISalesDataImport from "../../../interfaces/ISalesDataImport";

interface IProps {
    onSave: (payload: ISalesDataImport) => void;
    clientId: number;
    path: string;
}

const SalesDataImport: React.FunctionComponent<IProps> = ({ onSave, clientId }) => (
    <React.Fragment>
        <div className="px-4">
            <PageTitle title="Sales Data Imports" />
            <SalesDataImportForm onSave={onSave} clientId={clientId} />
        </div>
    </React.Fragment>
);

export default SalesDataImport;
