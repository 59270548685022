import { Moment } from "moment";

export const AuthenticationConstants = {
    REQUEST_AUTH_CODE_VERIFICATION: "REQUEST_AUTH_CODE_VERIFICATION",
    REQUEST_AUTH_CODE_VERIFICATION_SUCCESS: "REQUEST_AUTH_CODE_VERIFICATION_SUCCESS",
    REQUEST_AUTH_CODE_VERIFICATION_FAILED: "REQUEST_AUTH_CODE_VERIFICATION_FAILED",
    SET_AUTHENTICATION_EXPIRES_AT: "SET_AUTHENTICATION_EXPIRES_AT",
    LOGOUT: "LOGOUT"
};

export const auth0Callback = (code: string) => ({
    type: AuthenticationConstants.REQUEST_AUTH_CODE_VERIFICATION,
    payload: { code }
});

export const auth0CallbackFailed = (errors: string[]) => ({
    type: AuthenticationConstants.REQUEST_AUTH_CODE_VERIFICATION_FAILED,
    errors
});

export const auth0CallbackSuccess = (token: string) => ({
    type: AuthenticationConstants.REQUEST_AUTH_CODE_VERIFICATION_SUCCESS,
    payload: { token }
});

export const setAuthenticatedTimeLeft = (timeLeftInUserSession: Moment | null) => ({
    type: AuthenticationConstants.SET_AUTHENTICATION_EXPIRES_AT,
    payload: {
        timeLeftInUserSession
    }
});

export type IAuthenticationCallback = ReturnType<typeof auth0Callback>;
export type IAuthenticationCallbackFailed = ReturnType<typeof auth0CallbackFailed>;
export type IAuthenticationCallbackSuccess = ReturnType<typeof auth0CallbackSuccess>;
export type IAuthenticationSetTimeLeftCallback = ReturnType<typeof setAuthenticatedTimeLeft>;
