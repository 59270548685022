import React from "react";
import IName from "../../../../interfaces/DealerSetup/INameFields";
import FieldErrors from "../Fields/FieldErrors";

const NameSection: React.FC<IName> = ({ fields }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    <div className="mb-8">
                        <div className="font-bold">Name</div>
                        <div>{fields.name.value.name}</div>
                        <FieldErrors errors={fields.name.errors} />
                    </div>

                    <div className="mb-8">
                        <div className="font-bold">Variations</div>
                        {fields.name.value.variations.sort().map((v: string) => (
                            <div key={v} className="mb-1">
                                {v}
                            </div>
                        ))}
                        <FieldErrors errors={fields.cityVariations.errors} />
                    </div>
                    <div className="font-bold">Include City Variations?</div>
                    <div>{fields.cityVariations.value ? "Yes" : "No"}</div>

                    <div className="font-bold">Generate Exact & Phrase Match Only?</div>
                    <div>{fields.generateExactPhrase.value ? "Yes" : "No"}</div>
                </div>
            )}
        </React.Fragment>
    );
};

export default NameSection;
