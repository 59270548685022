import { schema } from "normalizr";
interface IRemarketingExportResponse {
    id: number;
    businessDataExportId: number;
    currency: string;
    fuelInventory: null | string;
    fuelInventoryId: null | number;
    facebookAutoRemarketingExportMappings: [];
    adwordsRemarketingExportMappings: [];
    conditions: [] | null;
    ignoredImagesSizes: null;
    ignoredImages: string[];
    inventorySource: string;
    mileageUnit: string;
    name: string;
    updatedAt: string;
    createdAt: string;
}
interface IRemarketingExportResponseMapped {
    id: number;
    businessDataExportId: number;
    currency: string;
    fuelInventory: null | string;
    fuelInventoryId: null | number;
    facebookAutoExports: [];
    googleAutoExports: [];
    conditions: [] | null;
    ignoredImagesSizes: null;
    ignoredImages: string[] | null;
    inventorySource: string;
    mileageUnit: string;
    name: string;
    updatedAt: string;
    createdAt: string;
}
const facebookAutoExport = new schema.Entity("facebookAutoExports");
const facebookAutoExportLog = new schema.Entity("facebookAutoExportLogs");

const googleAutoExports = new schema.Entity("googleAutoExports");
const googleAutoExportLogs = new schema.Entity("googleAutoExportLogs");

const remarketingInventoryExport = new schema.Entity(
    "remarketingInventoryExports",
    {
        facebookAutoExports: [facebookAutoExport],
        googleAutoExports: [googleAutoExports]
    },
    {
        processStrategy: ({
            id,
            businessDataExportId,
            currency,
            fuelInventory,
            fuelInventoryId,
            facebookAutoRemarketingExportMappings,
            adwordsRemarketingExportMappings,
            conditions,
            ignoredImagesSizes,
            ignoredImages,
            inventorySource,
            mileageUnit,
            name,
            updatedAt,
            createdAt
        }: IRemarketingExportResponse): IRemarketingExportResponseMapped => {
            return {
                id,
                businessDataExportId,
                currency,
                fuelInventory,
                fuelInventoryId,
                ignoredImagesSizes,
                inventorySource,
                mileageUnit,
                name,
                updatedAt,
                createdAt,
                ignoredImages,
                conditions,
                facebookAutoExports: facebookAutoRemarketingExportMappings,
                googleAutoExports: adwordsRemarketingExportMappings
            };
        }
    }
);

const Schemas = {
    REMARKETING_INVENTORY_EXPORT_ARRAY: { data: [remarketingInventoryExport] },
    REMARKETING_INVENTORY_EXPORT: remarketingInventoryExport,
    FACEBOOK_AUTO_EXPORT_ARRAY: { data: [facebookAutoExport] },
    FACEBOOK_AUTO_EXPORT: facebookAutoExport,
    FACEBOOK_AUTO_EXPORT_LOG_ARRAY: { data: [facebookAutoExportLog] },
    FACEBOOK_AUTO_EXPORT_LOG: facebookAutoExportLog,

    GOOGLE_AUTO_EXPORT_ARRAY: { data: [googleAutoExports] },
    GOOGLE_AUTO_EXPORT: googleAutoExports,
    GOOGLE_AUTO_EXPORT_LOG_ARRAY: { data: [googleAutoExportLogs] },
    GOOGLE_AUTO_EXPORT_LOG: googleAutoExportLogs
};

export default Schemas;
