import { all, call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../../middleware/api";

import InventoryModifierConstants from "../../constants/InventoryModifierConstants";
import * as InventoryModifierActions from "../../actions/InventoryModifierActions";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";
import IInventoryModifier from "../../interfaces/IInventoryModifier";
import { addFlashMessage } from "../../actions/flashMessageActions";
import IPagedResponse from "../../interfaces/IPagedResponse";

function* fetchInventoryModifiers({ payload }: any) {
    const url = `/clients/${payload.clientId}/inventory-field-transformers`;

    try {
        const response: IPagedResponse = yield call(
            callApi,
            url,
            CLIENT_SCHEMAS.INVENTORY_MODIFIERS.INVENTORY_MODIFIERS_ARRAY
        );
        const { inventoryModifier } = response.entities;
        yield put(InventoryModifierActions.requestInventoryModifiersSuccess(inventoryModifier || {}));
    } catch (error) {
        yield put(InventoryModifierActions.requestInventoryModifiersFail(error));
    }
}

const parseErrors = (errors: { [key: string]: string[] }) => {
    return Object.entries(errors).reduce((prev: { [key: string]: string }, [field, value]) => {
        prev[field] = value.join(",");
        return prev;
    }, {});
};

function* wrapper(func: any) {
    let result = {};
    try {
        const response: IPagedResponse = yield func;
        result = [false, response.result];
    } catch (e) {
        result = [parseErrors(e.errors)];
    }

    return result;
}

function doNothing(response: any) {
    return response;
}

interface IAction<T> {
    type: string;
    payload: T;
}

interface IInventoryModifierPayload {
    clientId: number;
    inventoryModifiers: IInventoryModifier[];
}

function* saveInventoryModifiers({ payload }: IAction<IInventoryModifierPayload>) {
    const { clientId, inventoryModifiers } = payload;
    const baseUrl = `/clients/${clientId}/inventory-field-transformers`;

    const saves = inventoryModifiers.map((modifier: IInventoryModifier) => {
        if (modifier.dirty) {
            if (modifier.new) {
                if (modifier.deleted) {
                    return call(doNothing, [false, modifier]);
                }
                return wrapper(
                    call(callApi, baseUrl, {}, "POST", {
                        search: modifier.search,
                        replace: modifier.replace,
                        field: modifier.field
                    })
                );
            }

            if (modifier.deleted) {
                return wrapper(call(callApi, `${baseUrl}/${modifier.id}`, {}, "DELETE"));
            }

            return wrapper(
                call(callApi, `${baseUrl}/${modifier.id}`, {}, "PATCH", {
                    search: modifier.search,
                    replace: modifier.replace,
                    field: modifier.field
                })
            );
        }
        return call(doNothing, [false, modifier]);
    });

    const results: [] = yield all(saves);

    const [errors, savedModifiers] = results.reduce(
        ([errors, responses]: any, [error, response]: [{}, any], index: number) => {
            if (error) {
                responses.push(inventoryModifiers[index]);
                errors[`inventoryModifiers[${index}]`] = error;
            } else {
                responses.push(response);
            }
            return [errors, responses];
        },
        [{}, []]
    );

    if (Object.entries(errors).length > 0) {
        yield put(InventoryModifierActions.saveInventoryModifiersFail(errors, savedModifiers));
        yield put(addFlashMessage({ type: "danger", text: "Some Changes Couldn't be Saved" }));
    } else {
        yield put(InventoryModifierActions.saveInventoryModifiersSuccess(savedModifiers));
    }
}

function* inventoryModifierSagas() {
    yield takeEvery(InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS, fetchInventoryModifiers);
    yield takeEvery(InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS, saveInventoryModifiers);
}

export default inventoryModifierSagas;
