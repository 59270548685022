import React from "react";
import Label from "./Label";
import Errors from "./Errors";
import { FormikErrors, FormikTouched } from "formik";

interface IProps {
    label?: string;
    touched?: boolean | FormikTouched<any>;
    errors: Array<string | FormikErrors<any>> | string;
    className?: string;
    style?: any;
    required?: boolean;
    children: any;
}

const Field: React.FunctionComponent<IProps> = ({
    label,
    className,
    style,
    required = false,
    touched,
    errors = [],
    children
}) => {
    return (
        <div className={`pt-4 ${className || ""}`} style={style}>
            {label !== undefined && (
                <Label label={label} required={required} isValid={touched === false || !errors.length} />
            )}
            {children}
            {touched !== false && <Errors errors={Array.isArray(errors) ? errors : [errors]} />}
        </div>
    );
};

export default Field;
