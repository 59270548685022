import React from "react";
import FieldErrors from "../Fields/FieldErrors";
import IDealerInfoFields from "../../../../interfaces/DealerSetup/IDealerInfoFields";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";
import { connect } from "react-redux";
import IAppState from "../../../../interfaces/IAppState";
import { INegativeKeywordColl } from "../../../../interfaces/NegativeKeywordColl";
import IEntity from "../../../../interfaces/IEntity";

const NegativeKeywordsSection = ({
    fields,
    negativeKeywordLists
}: {
    fields: IFormValues;
    negativeKeywordLists: IEntity<INegativeKeywordColl>;
}) => {
    const mapNegativeKeywordListsToFromNegativeKeywords = (keywordIds: number[]) =>
        keywordIds.map((keywordIndex) => negativeKeywordLists[keywordIndex].name).join(", ");

    return (
        <div className="m-4">
            {!fields.name.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Name</div>
                    <div className="flex mt-4">
                        <div className="w-1/3">{fields.name.fields.name.value.name}</div>
                        <div className="w-2/3">
                            {mapNegativeKeywordListsToFromNegativeKeywords(
                                fields.name.fields.negative_keyword_lists.value
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!fields.dealer.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Dealer</div>
                    <div className="flex mt-4">
                        <div className="w-1/3">Dealer Campaigns</div>
                        <div className="w-2/3">
                            {mapNegativeKeywordListsToFromNegativeKeywords(
                                fields.dealer.fields.negative_keyword_lists.value
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!fields.locations.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Locations</div>
                    <div className="flex mt-4">
                        <div className="w-1/3">Locations</div>
                        <div className="w-2/3">
                            {mapNegativeKeywordListsToFromNegativeKeywords(
                                fields.locations.fields.locations.value.negative_keyword_lists
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!fields.competitors.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Competitors</div>
                    <div className="flex mt-4">
                        <div className="w-1/3">Competitors</div>
                        <div className="w-2/3">
                            {mapNegativeKeywordListsToFromNegativeKeywords(
                                fields.competitors.fields.competitors.value.negative_keyword_lists
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!fields.dynamic.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Dynamic</div>
                    {fields.dynamic.fields.campaigns.map((field) => (
                        <div className="flex mt-4" key={field.value.name}>
                            <div className="w-1/3">{field.value.name}</div>
                            <div className="w-2/3">
                                {mapNegativeKeywordListsToFromNegativeKeywords(field.value.negative_keyword_lists)}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {!fields.services.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Services</div>
                    <div className="flex mt-4">
                        <div className="w-1/3">Services</div>
                        <div className="w-2/3">
                            {mapNegativeKeywordListsToFromNegativeKeywords(
                                fields.services.fields.services.value.negative_keyword_lists
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!fields.generic.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Generic</div>
                    {fields.generic.fields.campaigns.map((field) => (
                        <div className="flex mt-4" key={field.value.name}>
                            <div className="w-1/3">{field.value.name}</div>
                            <div className="w-2/3">
                                {mapNegativeKeywordListsToFromNegativeKeywords(field.value.negative_keyword_lists)}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {!fields.conquest.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Conquests</div>
                    <div className="flex mt-4">
                        <div className="w-1/3">Conquests</div>
                        <div className="w-2/3">
                            {mapNegativeKeywordListsToFromNegativeKeywords(
                                fields.conquest.fields.conquest.value.negative_keyword_lists
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!fields.remarketing.isDisabled && (
                <div>
                    <div className="font-bold mt-8 w-1/2">Remarketing</div>
                    {fields.remarketing.fields.map((field) => (
                        <div className="flex mt-4" key={field.name.value}>
                            <div className="w-1/3">{field.name.value}</div>
                            <div className="w-2/3">
                                {mapNegativeKeywordListsToFromNegativeKeywords(field.negative_keyword_lists.value)}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    negativeKeywordLists: state.negativeKeywordColls.entities
});

export default connect(mapStateToProps, null)(NegativeKeywordsSection);
