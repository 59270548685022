import { all, call } from "redux-saga/effects";
import handleKeywordTemplateRelationshipConditionals from "./keywordTemplateRelationshipConditionals/keywordTemplateRelationshipConditionalSagas";
import { each, isEmpty } from "lodash";
import { createTemplate, deleteTemplate, updateTemplate } from "./KeywordTemplates/keywordTemplateSagas";
import { wrapper } from "../../../../utils/DynamicCampaignUtils";
import { callApi } from "../../../../middleware/api";
import IKeywordRelationship from "../../../../interfaces/IKeywordRelationship";
import IPagedResponse from "../../../../interfaces/IPagedResponse";

export default function* handleKeywordTemplateRelationships({
    clientId,
    dynamicCampaignId,
    keywordTemplateRelationships
}: {
    dynamicCampaignId: number;
    clientId: number;
    keywordTemplateRelationships: IKeywordRelationship[];
}) {
    const entities: any[] = [];
    const errors: any = {};

    const responses: IPagedResponse = yield all(
        keywordTemplateRelationships.map((keywordTemplateRelationship) =>
            call(saveKeywordTemplate, {
                clientId,
                dynamicCampaignId,
                keywordTemplateRelationship
            })
        )
    );

    each(responses, (response: [boolean, any]) => {
        const [error, entity] = response;

        if (entity !== null) {
            entities.push(entity);

            if (error) {
                Object.assign(errors, { [`${entities.length - 1}`]: error });
            }
        }
    });

    return [isEmpty(errors) ? false : errors, entities];
}

export function* saveKeywordTemplate({
    clientId,
    dynamicCampaignId,
    keywordTemplateRelationship
}: {
    dynamicCampaignId: number;
    clientId: number;
    keywordTemplateRelationship: IKeywordRelationship;
}): Generator {
    const {
        id: keywordTemplateRelationshipId,
        dirty,
        deleted,
        new: isNew,
        keywordTemplate: { id: keywordTemplateId, isMaster }
    } = keywordTemplateRelationship;

    if (!dirty) {
        return [false, keywordTemplateRelationship];
    }

    if (deleted) {
        if (keywordTemplateRelationshipId && isMaster) {
            yield call(deleteKeywordTemplateRelationship, { clientId, keywordTemplateRelationshipId });
            return [false, null];
        }

        if (!isNew && keywordTemplateId) {
            yield call(deleteTemplate, { keywordTemplateId: keywordTemplateId });
            return [false, null];
        }

        return [false, null];
    }

    if (isNew) {
        return yield call(createKeywordTemplateRelationship, {
            dynamicCampaignId,
            clientId,
            keywordTemplateRelationship
        });
    }

    return yield call(updateKeywordTemplateRelationship, { clientId, keywordTemplateRelationship });
}

export function* updateKeywordTemplateRelationship({
    clientId,
    keywordTemplateRelationship
}: {
    clientId: number;
    keywordTemplateRelationship: IKeywordRelationship;
}): any {
    const {
        id: keywordTemplateRelationshipId,
        conditionals,
        keywordTemplate,
        keywordTemplate: { id: keywordTemplateId, keywords },
        dirty,
        deleted,
        new: isNew,
        ...relationship
    } = keywordTemplateRelationship;

    const [[templateErrors, savedKeywordTemplate], [conditionalErrors, savedConditionals]] = yield all([
        call(updateTemplate, {
            keywordTemplateId: keywordTemplateId as number,
            keywords: keywords.filter((keyword) => keyword.trim())
        }),
        call(handleKeywordTemplateRelationshipConditionals, {
            conditionals,
            keywordTemplateRelationshipId: keywordTemplateRelationshipId as number,
            clientId
        })
    ]);

    return [
        templateErrors || conditionalErrors
            ? {
                  ...(templateErrors && { keywordTemplate: templateErrors }),
                  ...(conditionalErrors && { conditionals: conditionalErrors })
              }
            : false,
        {
            id: keywordTemplateRelationshipId,
            ...relationship,
            keywordTemplate: templateErrors
                ? { ...keywordTemplate, keywords: keywords.filter((keyword) => keyword.trim()) }
                : savedKeywordTemplate,
            conditionals: savedConditionals,
            ...((templateErrors || conditionalErrors) && { dirty: true })
        }
    ];
}
export function* createKeywordTemplateRelationship({
    dynamicCampaignId,
    clientId,
    keywordTemplateRelationship
}: {
    dynamicCampaignId: number;
    clientId: number;
    keywordTemplateRelationship: IKeywordRelationship;
}): any {
    const {
        conditionals,
        keywordTemplate: { keywords }
    } = keywordTemplateRelationship;

    const [templateErrors, savedKeywordTemplate] = yield call(createTemplate, {
        keywords: keywords.filter((keyword) => keyword.trim())
    });

    if (templateErrors) {
        return [
            { keywordTemplate: templateErrors },
            {
                ...keywordTemplateRelationship,
                keywordTemplate: {
                    ...keywordTemplateRelationship.keywordTemplate,
                    keywords: keywordTemplateRelationship.keywordTemplate.keywords.filter((keyword) => keyword.trim())
                }
            }
        ];
    }

    const [templateRelationshipErrors, savedTemplateRelationship] = yield wrapper(
        call(callApi, `/clients/${clientId}/dc-keyword-templates`, {}, "POST", {
            keyword_template_id: savedKeywordTemplate.id,
            dynamic_campaign_id: dynamicCampaignId
        })
    );

    if (templateRelationshipErrors) {
        return [
            { keywordTemplate: { keywords: "Error saving Keyword Template to Dynamic Campaign" } },
            keywordTemplateRelationship
        ];
    }

    const [conditionalErrors, savedConditionals] = yield call(handleKeywordTemplateRelationshipConditionals, {
        conditionals,
        keywordTemplateRelationshipId: savedTemplateRelationship.id,
        clientId
    });

    return [
        conditionalErrors ? { conditionals: conditionalErrors } : false,
        {
            ...savedTemplateRelationship,
            conditionals: savedConditionals,
            keywordTemplate: savedKeywordTemplate,
            ...(conditionalErrors && { dirty: true })
        }
    ];
}

export function* deleteKeywordTemplateRelationship({
    clientId,
    keywordTemplateRelationshipId
}: {
    clientId: number;
    keywordTemplateRelationshipId: number;
}) {
    yield wrapper(
        call(callApi, `/clients/${clientId}/dc-keyword-templates/${keywordTemplateRelationshipId}`, {}, "DELETE")
    );
}
