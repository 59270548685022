import React from "react";
import * as yup from "yup";
import IFacebookAutoExportType from "../../../interfaces/IFacebookAutoExport";
import Button, { PRIMARY_BUTTON, NEGATIVE_BUTTON } from "../../Shared/Button";
import { Formik, Form, ErrorMessage, FormikActions } from "formik";
import { IInventoryExport } from "../../../interfaces/IRemarketing";
import InventoryMentionsInput from "./InventoryMentionsInput";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import { IInventoryExportState } from "../../../sagas/Client/remarketingSagas";
import { camelize } from "humps";

interface IProps {
    clientId: number;
    facebookExport: IFacebookAutoExportType | null;
    inventoryExport: IInventoryExport | IInventoryExportState;
    inventoryExportId: number;
    inventoryVariables: string[];
    hide(): void;
    onSaveFacebookExport(
        facebookExport: IFacebookAutoExportType,
        inventoryExportId: number,
        clientId: number,
        formikActions: FormikActions<IFacebookAutoExportType>
    ): void;
}

export const defaultValues = {
    availability: "{{Status}}",
    bodyStyle: "{{Body}}",
    condition: "{{Type}}",
    description: "Now {{OurPrice | money}}",
    drivetrain: "{{Drivetrain}}",
    exteriorColor: "{{ExtColorGeneric}}",
    fuelType: "Gasoline",
    image: "{{Images}}",
    make: "{{Make}}",
    model: "{{Model}}",
    mileage: "{{Miles}}",
    price: "{{OurPrice}}",
    salePrice: "{{FinalPrice}}",
    stateOfVehicle: "{{Type}}",
    title: "{{Year}} {{Make}} {{Model}} {{Trim}} {{OurPrice | money}}",
    transmission: "Automatic",
    url: "{{Link}}",
    vehicleId: "{{VIN}}",
    vin: "{{VIN}}",
    year: "{{Year}}",
    trim: "{{Trim}}",
    fileLocation: "",
    dateFirstOnLot: "{{DateInStock}}"
};

const schema = yup.object().shape({
    title: yup.string().required().max(255),
    description: yup.string().required().max(2000),
    url: yup.string().required().max(255)
});

const carsInventorySourceValues = {
    url: "https://turbo.cars.com/vdp/{{ID}}#back-to-listings?aff=ddconsu&source=facebook",
    vehicleId: "{{ID}}"
};

const fuelInventorySourceValues = {
    url: "{{Link}}",
    vehicleId: "{{VIN}}"
};

const inputStyles = "border py-2 px-4 rounded block w-full";
const errorStyles = "text-red-600 pt-4";
const groupStyles = "w-full md:w-1/2 md:mb-0";
const labelStyles = "mt-4 block";

const FacebookExport: React.FunctionComponent<IProps> = ({
    facebookExport,
    inventoryExport,
    hide,
    clientId,
    inventoryExportId,
    onSaveFacebookExport,
    inventoryVariables
}) => {
    const fileLocationDisplay =
        facebookExport && facebookExport.fileLocation
            ? `fuel-ftp.dealerinspire.com/files/exports/${facebookExport.fileLocation}`
            : "";

    // The old system updates the mapped values when the source changes and overwrites previously defined values.  This functionality copies the old functionality.
    // This is odd when the values are displayed in a modal because the user opening it may be lead to believe the values displayed are the ones stored.
    const conditionalValues =
        inventoryExport.inventorySource === "CARS" ? carsInventorySourceValues : fuelInventorySourceValues;
    const initialValues = { ...defaultValues, ...conditionalValues, ...facebookExport };
    return (
        <React.Fragment>
            <div className="text-2xl font-bold text-gray-800 border-b">Facebook Auto Export Mapping</div>
            <Formik
                initialValues={{ ...initialValues, apiErrors: null }}
                onSubmit={(values, formikActions) => {
                    onSaveFacebookExport(values, inventoryExportId, clientId, formikActions);
                }}
                validationSchema={schema}
                render={({ values, isSubmitting, submitForm, status = "unsaved", setFieldValue, setFieldTouched }) => {
                    if (status === "saved") {
                        hide();
                    }
                    const handleOnChange = (name: string, value: any) => {
                        setFieldValue(name, value);
                    };

                    const handleOnBlur = (fieldName: string) => {
                        setFieldTouched(fieldName);
                    };
                    return (
                        <Form>
                            <ErrorMessage component="span" className={errorStyles} name="apiErrors" />
                            <div>
                                <div className={`${groupStyles} md:w-2/3`}>
                                    <label className={labelStyles} htmlFor="fileLocation">
                                        File
                                    </label>
                                    <input
                                        type="text"
                                        id="fileLocation"
                                        value={`${fileLocationDisplay}`}
                                        className={`${inputStyles} bg-gray-400`}
                                        disabled={true}
                                    />
                                </div>

                                <div className={groupStyles} data-testid="title">
                                    <label className={labelStyles} htmlFor="title">
                                        Title
                                    </label>
                                    <InventoryMentionsInput
                                        className={inputStyles}
                                        fieldName="title"
                                        values={values}
                                        onInputChange={handleOnChange}
                                        onInputBlur={handleOnBlur}
                                        optionValues={inventoryVariables}
                                    />
                                    <ErrorMessage component="span" className={errorStyles} name="title" />
                                </div>

                                <div className={groupStyles} data-testid="description">
                                    <label className={labelStyles} htmlFor="description">
                                        Description
                                    </label>
                                    <InventoryMentionsInput
                                        className={inputStyles}
                                        fieldName="description"
                                        singleLine={false}
                                        values={values}
                                        onInputChange={handleOnChange}
                                        onInputBlur={handleOnBlur}
                                        optionValues={inventoryVariables}
                                    />
                                    <ErrorMessage component="span" className={errorStyles} name="description" />
                                </div>

                                <div className={groupStyles} data-testid="url">
                                    <label className={labelStyles} htmlFor="url">
                                        Url
                                    </label>
                                    <InventoryMentionsInput
                                        className={inputStyles}
                                        fieldName="url"
                                        values={values}
                                        onInputChange={handleOnChange}
                                        onInputBlur={handleOnBlur}
                                        optionValues={inventoryVariables}
                                    />
                                    <ErrorMessage component="span" className={errorStyles} name="url" />
                                </div>
                            </div>

                            <hr className="my-4 border-t-4 border-gray-200" />

                            <div className="grid grid-cols-2 grid-rows-auto auto-rows-max gap-4">
                                {[
                                    "vehicle_id",
                                    "make",
                                    "model",
                                    "year",
                                    "mileage",
                                    "image",
                                    "vin",
                                    "drivetrain",
                                    "body_style",
                                    "fuel_type",
                                    "transmission",
                                    "condition",
                                    "price",
                                    "trim",
                                    "exterior_color",
                                    "sale_price",
                                    "availability",
                                    "state_of_vehicle",
                                    "date_first_on_lot"
                                ]
                                    .sort()
                                    .map((fieldKey: string) => (
                                        <div
                                            key={`fb-export${fieldKey}`}
                                            className={""}
                                            data-testid={camelize(fieldKey)}
                                        >
                                            <label className={`block`} htmlFor={camelize(fieldKey)}>
                                                {fieldKey}
                                            </label>
                                            <InventoryMentionsInput
                                                className={inputStyles}
                                                fieldName={camelize(fieldKey)}
                                                values={values}
                                                onInputChange={handleOnChange}
                                                onInputBlur={handleOnBlur}
                                                optionValues={inventoryVariables}
                                            />
                                            <ErrorMessage
                                                component="span"
                                                className={errorStyles}
                                                name={camelize(fieldKey)}
                                            />
                                        </div>
                                    ))}
                            </div>

                            <div className="flex justify-end mt-8">
                                <Button styleType={PRIMARY_BUTTON} disabled={isSubmitting} onClick={submitForm}>
                                    {!isSubmitting && <span>Save</span>}
                                    {isSubmitting && (
                                        <span className="flex items-center">
                                            <RefreshIcon className="w-6 h-6 rotate-fast" />
                                            <span className="ml-2">Saving</span>
                                        </span>
                                    )}{" "}
                                </Button>
                                <Button styleType={NEGATIVE_BUTTON} disabled={isSubmitting} onClick={hide}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default FacebookExport;
