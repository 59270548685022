import React, { useState, FunctionComponent } from "react";
import { Range, DateRange } from "react-date-range";
import DateFilter from "./DateFilter";
import defaultRanges from "./DefaultDateRanges";

interface IReportRange {
    dateRange: Range;
    updateMap(dateRange: Range): void;
}

const ReportRange: FunctionComponent<IReportRange> = ({ updateMap, dateRange }) => {
    const [showRangePicker, setShowRangePicker] = useState(false);
    const [range, setRange] = useState(dateRange);
    const format = "MM/D/YY";

    const handleUpdateMap = (dateRange: Range) => {
        updateMap(dateRange);
    };

    const handleChange = (payload: Range) => {
        let showPicker = showRangePicker;
        if (
            payload.endDate !== range.endDate &&
            payload.endDate !== range.startDate &&
            payload.startDate !== payload.endDate
        ) {
            handleUpdateMap(payload);
            showPicker = false;
        }
        setShowRangePicker(showPicker);
        setRange(payload);
    };

    const toggleRangePicker = () => {
        setShowRangePicker((current) => {
            return !current;
        });
    };

    return (
        <section>
            <DateFilter
                toggleCalendar={toggleRangePicker}
                start={dateRange.startDate && dateRange.startDate.format(format).toString()}
                end={dateRange.endDate && dateRange.endDate.format(format).toString()}
            />
            {showRangePicker && (
                <DateRange
                    linkedCalendars={true}
                    ranges={defaultRanges}
                    onChange={handleChange}
                    startDate={range.startDate}
                    endDate={range.endDate}
                    theme={{
                        Calendar: { width: 200 },
                        PredefinedRanges: { marginLeft: 10, marginTop: 10 }
                    }}
                />
            )}
        </section>
    );
};

export default ReportRange;
