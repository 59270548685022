import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import WarningIcon from "../../Shared/Icons/WarningIcon";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import ModalToggle from "../../Shared/Dialogs/ModalToggle";
import MessagesModal from "./MessagesModal";
import CheckIcon from "../../Shared/Icons/CheckIcon";
import VisibleIcon from "../../Shared/Icons/VisibleIcon";

interface IProps {
    title?: string;
    message?: string;
    link?: string;
    date: string;
    markAsRead: () => void;
    readAt?: string;
    level?: string;
    deleting?: boolean;
    details?: string[];
    type: string;
}

interface IRenderLink {
    link?: string;
    details: string[];
    className: string;
}

const RenderLink = ({ link, details, className }: IRenderLink) => {
    return (
        <div className={`flex items-center ${className}`}>
            {details && details.length > 0 && (
                <button className="inline-block m-0 mr-4 text-blue-600">
                    <ModalToggle
                        style={{ width: "50%", height: "510px" }}
                        toggleButton={(open) => <span onClick={open}>View Details</span>}
                        modalContent={(hide) => <MessagesModal hide={hide} details={details} />}
                    />
                </button>
            )}

            <Link to={link || ""} className="inline-block m-0 mr-4 text-blue-600">
                View More
            </Link>
        </div>
    );
};

function resolveColor(level: string) {
    if (level === "error") {
        return "red";
    }

    if (level === "warning") {
        return "yellow";
    }

    return "green";
}

const Notification = ({
    title,
    message,
    link,
    date,
    markAsRead,
    level = "error",
    deleting = false,
    details = []
}: IProps) => {
    const color = resolveColor(level);

    return (
        <li
            className={`${deleting === true ? "hide" : ""} flex flex-wrap ${
                deleting ? "bg-gray-100" : "bg-white"
            } border-gray-300 border-t p-4`}
        >
            <div className="mt-2 ">
                {level === "info" && (
                    <div className={`bg-${color}-400 p-1 rounded-full w-8 h-8`}>
                        <CheckIcon className={`w-6 h-6 text-${color}-100`} />
                    </div>
                )}
                {level !== "info" && <WarningIcon className={`w-8 h-8 text-${color}-500`} />}
            </div>
            <div className="flex flex-wrap flex-1 ml-4">
                <div className="flex flex-wrap w-full justify-between items-center mr-8">{title}</div>
                <div className="w-full">{message}</div>

                <div className="flex items-center w-full text-sm">
                    <RenderLink className="" link={link} details={details} />
                    <div className="text-gray-500">{moment.utc(date).local().format("MM/DD h:mm a")}</div>
                </div>
            </div>
            <span title="Mark as read" className="cursor-pointer flex items-center mx-4">
                {deleting !== true ? (
                    <VisibleIcon className="w-6 h-6" onClick={markAsRead} />
                ) : (
                    <RefreshIcon className="w-6 h-6 rotate-fast" />
                )}
            </span>
        </li>
    );
};

export default Notification;
