import IIndicatorStatus from "../interfaces/IIndicatorStatus";
import { PENDING, COMPLETED, ACTIVE, ERROR } from "../constants/StatusIndicatorConstants";

function isFailedAction(action: string | null) {
    if (!action) {
        return false;
    }

    return action.endsWith("_FAILED");
}

function getNonFailedStatusStep(action: string | null) {
    if (!isFailedAction(action)) {
        return action;
    }

    return !!action ? action.replace("_FAILED", "") : null;
}

// this returns an array of cloned objects to break the reference
function mapEventActionToStatus(statuses: IIndicatorStatus[], action: string | null) {
    if (
        !action ||
        !statuses.some(({ actionType }) => actionType === action || actionType === getNonFailedStatusStep(action))
    ) {
        return statuses.map(({ ...status }) => status);
    }
    let setActive = false;
    return statuses.map(({ ...status }) => {
        // if the step has been completed
        if (status.actionType !== action && !setActive) {
            status.state = COMPLETED;
        }
        // if the status is currently being executed
        if (status.actionType !== action && setActive) {
            status.state = PENDING;
        }
        // if the status has yet to initiate
        if (status.actionType === action) {
            status.state = ACTIVE;
            setActive = true;
        }

        if (isFailedAction(action) && status.actionType === getNonFailedStatusStep(action)) {
            status.state = ERROR;
            setActive = true;
        }

        return status;
    });
}

export default mapEventActionToStatus;
