import React from "react";
import BudgetLayout from "./BudgetLayout";
import useBudget from "../../../hooks/budgets/useBudget";
import BudgetForm from "./BudgetForm";
import useBudgetParams from "../../../hooks/budgets/useBudgetParams";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";

interface IProps {}

const BudgetFormContainer: React.FunctionComponent<IProps> = () => {
    const budgetTitle: string = ``;
    const { budgetType, actionType } = useBudgetParams();
    const { loadingBudget, loadingMessage, budget, isSaving, apiErrors, formSubmitHandler } = useBudget();

    const indexPath: string = actionType === "create" ? "../" : "../..";

    if (loadingBudget) return <FullPageLoader message="Loading Budget..." />;

    return (
        <BudgetLayout>
            <div className="w-full flex justify-center">
                <BudgetForm
                    {...{
                        loadingBudget,
                        loadingMessage,
                        budget,
                        isSaving,
                        apiErrors
                    }}
                    cancelPath={indexPath}
                    title={budgetTitle}
                    onSubmit={formSubmitHandler}
                />
            </div>
        </BudgetLayout>
    );
};

export default BudgetFormContainer;
