import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";
import INegativeKeywordFields from "../../../../interfaces/DealerSetup/INegativeKeywordFields";
import NegativeKeywordsCampaignReviewSection from "./NegativeKeywordSections/NegativeKeywordsCampaignReviewSection";
import { IDynamicCampaignField } from "../../../../interfaces/DealerSetup/IDynamicCampaign";
import { IGenericCampaignField } from "../../../../interfaces/DealerSetup/IGenericCampaign";
import { IRemarketingFields } from "../../../../interfaces/DealerSetup/IRemarketingFields";
import Button from "../../../Shared/Button";
import { handleApplyDefaultsForWizard } from "../../../../utils/ApplyNegativeKeywordDefaults";
import IClient from "../../../../interfaces/IClient";
import { connect } from "react-redux";
import IAppState from "../../../../interfaces/IAppState";
import IEntity from "../../../../interfaces/IEntity";
import { INegativeKeywordColl } from "../../../../interfaces/NegativeKeywordColl";

interface IProps {
    path: string;
    client: IClient;
    negativeKeywordCollections: IEntity<INegativeKeywordColl>;
    formValues: IFormValues;
    savePage(path: string, validatePage: any): void;
}

/**
 * This page seems to be a little special since it's a review section for portions of every other page.
 * We need to have each section actually exist as their own component so the logic that allows us to spit out each section
 * can be relative to their new formats.
 */
const NegativeKeywords = forwardRef(
    ({ formValues, savePage, path, client, negativeKeywordCollections }: IProps, ref: React.Ref<{}>) => {
        const defaults: INegativeKeywordFields = {
            fields: []
        };
        const schema = null;
        const [values, handleFieldChange, changePage] = useDealerSetupForm(
            defaults,
            formValues,
            schema,
            ref,
            path,
            savePage
        );
        const pager = usePager(path, formValues, changePage);

        const pagesThatHaveNotBeenVisited = Object.keys(formValues)
            .filter((key) => !["review", "negative-keywords", "dealer"].includes(key))
            .filter((key) => !formValues[key].isDisabled)
            .filter((key) => !formValues[key].isTouched);

        return (
            <div className="p-4">
                <div className="text-xl font-bold">Negative Keyword Setup</div>

                {pagesThatHaveNotBeenVisited.length > 0 && (
                    <div className="bg-yellow-100 text-yellow-700 p-4 mt-4 border border-yellow-600 rounded">
                        You must visit the following pages before continuing: {pagesThatHaveNotBeenVisited.join(", ")}
                    </div>
                )}

                {pagesThatHaveNotBeenVisited.length === 0 && (
                    <div>
                        {!formValues.name.isDisabled && (
                            <NegativeKeywordsCampaignReviewSection
                                values={formValues.name.fields.negative_keyword_lists.value}
                                handleChangeField={(negativeKeywordLists: number[]) => {
                                    savePage("name", {
                                        ...formValues.name,
                                        fields: {
                                            ...formValues.name.fields,
                                            negative_keyword_lists: {
                                                value: negativeKeywordLists
                                            }
                                        }
                                    });
                                }}
                                name="Name Campaign"
                                subtitle="Name"
                            />
                        )}

                        {!formValues.locations.isDisabled && (
                            <NegativeKeywordsCampaignReviewSection
                                values={formValues.locations.fields.locations.value.negative_keyword_lists}
                                handleChangeField={(negativeKeywordLists: number[]) => {
                                    savePage("locations", {
                                        ...formValues.locations,
                                        fields: {
                                            ...formValues.locations.fields,
                                            locations: {
                                                ...formValues.locations.fields.locations,
                                                value: {
                                                    ...formValues.locations.fields.locations.value,
                                                    negative_keyword_lists: negativeKeywordLists
                                                }
                                            }
                                        }
                                    });
                                }}
                                name="Locations Campaign"
                                subtitle="Locations"
                            />
                        )}
                        {!formValues.competitors.isDisabled && (
                            <NegativeKeywordsCampaignReviewSection
                                values={formValues.competitors.fields.competitors.value.negative_keyword_lists}
                                handleChangeField={(negativeKeywordLists: number[]) => {
                                    savePage("competitors", {
                                        ...formValues.competitors,
                                        fields: {
                                            ...formValues.competitors.fields,
                                            competitors: {
                                                value: {
                                                    ...formValues.competitors.fields.competitors.value,
                                                    negative_keyword_lists: negativeKeywordLists
                                                }
                                            }
                                        }
                                    });
                                }}
                                name="Competitors Campaign"
                                subtitle="Competitors"
                            />
                        )}
                        {!formValues.dealer.isDisabled && (
                            <NegativeKeywordsCampaignReviewSection
                                values={formValues.dealer.fields.negative_keyword_lists.value}
                                handleChangeField={(negativeKeywordLists: number[]) => {
                                    savePage("dealer", {
                                        ...formValues.dealer,
                                        fields: {
                                            ...formValues.dealer.fields,
                                            negative_keyword_lists: {
                                                value: negativeKeywordLists
                                            }
                                        }
                                    });
                                }}
                                name="Dealer Campaign"
                                subtitle="Dealer"
                            />
                        )}

                        {!formValues.dynamic.isDisabled &&
                            formValues.dynamic.fields.campaigns.map((dynamicCampaignField, index) => (
                                <NegativeKeywordsCampaignReviewSection
                                    key={index + "Dynamic"}
                                    subtitle="Dynamic"
                                    values={formValues.dynamic.fields.campaigns[index].value.negative_keyword_lists}
                                    handleChangeField={(negativeKeywordLists: number[]) => {
                                        const campaigns: IDynamicCampaignField[] = formValues.dynamic.fields.campaigns;

                                        campaigns[index].value.negative_keyword_lists = negativeKeywordLists;

                                        savePage("dynamic", {
                                            ...formValues.dynamic,
                                            fields: {
                                                ...formValues.dynamic.fields,
                                                campaigns
                                            }
                                        });
                                    }}
                                    name={dynamicCampaignField.value.name}
                                />
                            ))}

                        {!formValues.services.isDisabled && (
                            <NegativeKeywordsCampaignReviewSection
                                values={formValues.services.fields.services.value.negative_keyword_lists}
                                handleChangeField={(negativeKeywordLists: number[]) => {
                                    savePage("services", {
                                        ...formValues.services,
                                        fields: {
                                            ...formValues.services.fields,
                                            services: {
                                                ...formValues.services.fields.services,
                                                value: {
                                                    ...formValues.services.fields.services.value,
                                                    negative_keyword_lists: negativeKeywordLists
                                                }
                                            }
                                        }
                                    });
                                }}
                                name="Services Campaign"
                                subtitle="Services"
                            />
                        )}

                        {!formValues.generic.isDisabled &&
                            formValues.generic.fields.campaigns.map((genericField, index) => (
                                <NegativeKeywordsCampaignReviewSection
                                    key={index + "Generic"}
                                    values={formValues.generic.fields.campaigns[index].value.negative_keyword_lists}
                                    handleChangeField={(negativeKeywordLists: number[]) => {
                                        const campaigns: IGenericCampaignField[] = formValues.generic.fields.campaigns;

                                        campaigns[index].value.negative_keyword_lists = negativeKeywordLists;

                                        savePage("generic", {
                                            ...formValues.generic,
                                            fields: {
                                                ...formValues.generic.fields,
                                                campaigns
                                            }
                                        });
                                    }}
                                    name={genericField.value.name}
                                    subtitle="Generic"
                                />
                            ))}

                        {!formValues.conquest.isDisabled && (
                            <NegativeKeywordsCampaignReviewSection
                                values={formValues.conquest.fields.conquest.value.negative_keyword_lists}
                                handleChangeField={(negativeKeywordLists: number[]) => {
                                    savePage("conquest", {
                                        ...formValues.conquest,
                                        fields: {
                                            ...formValues.conquest.fields,
                                            conquest: {
                                                ...formValues.conquest.fields.conquest,
                                                value: {
                                                    ...formValues.conquest.fields.conquest.value,
                                                    negative_keyword_lists: negativeKeywordLists
                                                }
                                            }
                                        }
                                    });
                                }}
                                name="Conquest Campaign"
                                subtitle="Conquests"
                            />
                        )}

                        {!formValues.remarketing.isDisabled &&
                            formValues.remarketing.fields.map((remarketingField, index) => (
                                <NegativeKeywordsCampaignReviewSection
                                    values={formValues.remarketing.fields[index].negative_keyword_lists.value}
                                    key={index + "Remarketing"}
                                    handleChangeField={(negativeKeywordLists: number[]) => {
                                        const fields: IRemarketingFields[] = formValues.remarketing.fields;

                                        fields[index].negative_keyword_lists = {
                                            value: negativeKeywordLists
                                        };

                                        savePage("remarketing", {
                                            ...formValues.remarketing,
                                            fields
                                        });
                                    }}
                                    name={remarketingField.name.value}
                                    subtitle="Remarketing"
                                />
                            ))}

                        <div className="flex justify-end items-center mt-4 border-t border-gray-300 pt-4">
                            <Button
                                type={"button"}
                                styleType="secondary"
                                onClick={() =>
                                    handleApplyDefaultsForWizard(
                                        formValues,
                                        savePage,
                                        client,
                                        negativeKeywordCollections
                                    )
                                }
                            >
                                Apply manufacturer defaults
                            </Button>
                        </div>
                    </div>
                )}

                {pager()}
            </div>
        );
    }
);

const mapStateToProps = (state: IAppState) => ({
    client: state.clients.entities[state.clients.currentClient],
    negativeKeywordCollections: state.negativeKeywordColls.entities
});

export default connect(mapStateToProps, undefined, undefined, {
    forwardRef: true
})(NegativeKeywords);
