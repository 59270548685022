import React, { SVGProps } from "react";
import Svg from "./Svg";

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M19.867 13.633h-6.234v6.234a1.133 1.133 0 0 1-2.266 0v-6.234H5.133a1.133 1.133 0 0 1 0-2.266h6.234V5.133a1.133 1.133 0 1 1 2.266 0v6.234h6.234a1.133 1.133 0 0 1 0 2.266z" />
    </Svg>
);

export default AddIcon;
