import React from "react";
import { connect } from "react-redux";
import { ConditionalOperators, getDcParameterFields } from "../../../../../../utils/InventoryUtils";
import {
    buildSelectOptions,
    formatToCsv,
    formatToReactSelectValue
} from "../../../../../../utils/DynamicCampaignUtils";
import IReactSelectValue from "../../../../../../interfaces/IReactSelectValue";
import { IInventoryFieldValues } from "../../../../../../interfaces/InventoryFields";
import SelectField from "../../../../../Shared/Form/Blocks/SelectField";
import TextInput from "../../../../../Shared/Form/Elements/TextInput";
import Creatable from "react-select";
import Field from "../../../../../Shared/Form/Elements/Field";
import { get } from "lodash";
import IKeywordConditional, { IConditional } from "../../../../../../interfaces/IKeywordConditional";
import IAppState from "../../../../../../interfaces/IAppState";
import TrashIcon from "../../../../../Shared/Icons/TrashIcon";

export const CSV_VALUES = ["IN", "NOTIN", "IN_LIKE", "NOTIN_LIKE"];

interface INames {
    parameter: string;
    value: string;
    comparator: string;
}

interface IProps {
    errors: any;
    touched: any;
    condition: IKeywordConditional | IConditional;
    names: INames;
    fields?: string[];
    inventoryFieldValues: IInventoryFieldValues;
    index?: number;
    onChange: (condition: IKeywordConditional, key: string) => void;
    onRemove: () => void;
    handleBlur: any;
    isClearable?: boolean;
    isMaster: boolean;
}

const inventoryFieldsDC = getDcParameterFields();

const KeywordTemplateConditional: React.FC<IProps> = ({
    condition,
    onChange,
    onRemove,
    handleBlur,
    names,
    inventoryFieldValues,
    fields = inventoryFieldsDC,
    errors,
    touched,
    isClearable = true,
    isMaster
}) => {
    const updateValue = (field: string, value: string) =>
        onChange({ ...condition, [field]: value, dirty: true }, field);
    const selectedComparator = ConditionalOperators.find((item) => item.value === condition.comparator);
    const options = buildSelectOptions(condition.parameter, inventoryFieldValues);

    type SelectOptionType = IReactSelectValue | null;
    type MultiSelectOptionType = readonly IReactSelectValue[] | null;

    const handleSelectChange = (values: SelectOptionType | MultiSelectOptionType, actionMeta: any) => {
        updateValue("value", formatToCsv(values));
    };

    if ("deleted" in condition && condition.deleted === true) {
        return null;
    }

    const customSelectStyles = {
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: "39px"
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        })
    };

    return (
        <div className="flex items-start">
            <div className="w-full md:flex-1">
                <SelectField
                    className="mr-4"
                    name={names.parameter}
                    handleBlur={() => handleBlur(names.parameter, "parameter")}
                    options={fields.map((item) => ({ label: item, value: item }))}
                    handleChange={(item: IKeywordConditional) => {
                        const value = (item && item.value) || "";
                        if (value !== condition.parameter) {
                            onChange({ ...condition, parameter: value }, "parameter");
                            return;
                        }
                        updateValue("parameter", value);
                    }}
                    value={condition.parameter || ""}
                    label="Field"
                    required={true}
                    touched={get(touched, names.parameter, false)}
                    errors={get(errors, names.parameter, false) && [get(errors, names.parameter)]}
                    readOnly={isMaster}
                />
            </div>
            <div className="w-full md:flex-1">
                <SelectField
                    name={names.comparator}
                    options={ConditionalOperators}
                    handleBlur={() => handleBlur(names.comparator, "comparator")}
                    handleChange={(item: IKeywordConditional) => {
                        if (!CSV_VALUES.includes(item.value || "") && condition.value !== null) {
                            onChange(
                                {
                                    ...condition,
                                    comparator: (item && item.value) || "",
                                    value: condition.value.split(",")[0]
                                },
                                "value"
                            );
                            return;
                        }
                        updateValue("comparator", (item && item.value) || "");
                    }}
                    value={(selectedComparator && selectedComparator.value) || ""}
                    label="Condition"
                    required={true}
                    className="mr-4"
                    touched={get(touched, names.comparator, false)}
                    errors={get(errors, names.comparator, false) && [get(errors, names.comparator)]}
                    readOnly={isMaster}
                />
            </div>
            <div className="w-full md:flex-1">
                {condition.parameter === "Date" ? (
                    <Field label={"Value"} errors={[]} required={true}>
                        <TextInput
                            name={names.value}
                            value={condition.value as string}
                            type="date"
                            onBlur={() => handleBlur(names.value, "value")}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateValue("value", e.target.value)}
                            readOnly={isMaster}
                        />
                    </Field>
                ) : (
                    <Field
                        required
                        label={"Value"}
                        errors={get(errors, names.value, false) && [get(errors, names.value)]}
                        touched={get(touched, names.value, false)}
                    >
                        <Creatable
                            isMulti
                            name={names.value}
                            components={{
                                DropdownIndicator: null
                            }}
                            classNamePrefix={`${names.value}`}
                            className="w-full text-base"
                            isClearable={isClearable}
                            options={options}
                            onChange={handleSelectChange}
                            onBlur={() => handleBlur(names.value, "value")}
                            placeholder="Type then press enter..."
                            styles={customSelectStyles}
                            value={formatToReactSelectValue(condition.value)}
                            aria-label="Value"
                            isDisabled={isMaster}
                        />
                    </Field>
                )}
            </div>
            {!isMaster && (
                <div>
                    <button
                        className="bg-transparent text-blue-800 hover:text-red-500 p-0 ml-4 mt-20"
                        type="button"
                        onClick={onRemove}
                    >
                        <TrashIcon className="w-6 h-6" />
                    </button>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        inventoryFieldValues: state.inventorySampleData.entities.inventoryFieldValues
    };
};

export default connect(mapStateToProps)(KeywordTemplateConditional);
