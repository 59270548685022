import React from "react";
import DesktopPreview from "../../../AdWords/AdPreview/DesktopPreview";
import MobilePreview from "../../../AdWords/AdPreview/MobilePreview";
import { IGroupedParts, ITemplatePart } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import { connect } from "react-redux";
import { getFirstValidTemplate, getDisplayUrlFromPathAndFinalUrl, getFirstValidPathTemplate } from "../../../../utils";
import { ISampleData } from "../../../../interfaces/DynamicCampaigns/ITemplatePart";
import filterDeleted from "../../../../utils/filterDeleted";

interface IProps extends IGroupedParts {
    clientId: number;
    loadedAt: null | Date;
    sampleData: ISampleData;
}

const TextAdPreview: React.FC<IProps> = ({
    headline = [],
    description = [],
    final_url = [],
    path = [],
    sampleData
}) => {
    const [d1, d2] = filterDeleted(description);
    const [h1, h2, h3] = filterDeleted(headline);

    const headline1Values = [h1?.value || ""];
    const headline2Values = [h2?.value || ""];
    const headline3Values = [h3?.value || ""];
    const descriptionValues = [d1?.value || ""];
    const description2Values = [d2?.value || ""];
    const pathValues = filterDeleted(path).map((p: ITemplatePart) => p.value);
    const urlValues = filterDeleted(final_url).map((p: ITemplatePart) => p.value);
    const finalUrl = getFirstValidTemplate(urlValues, sampleData);
    const previewProps = {
        h1: getFirstValidTemplate(headline1Values, sampleData, 30),
        h2: getFirstValidTemplate(headline2Values, sampleData, 30),
        h3: getFirstValidTemplate(headline3Values, sampleData, 30),
        d1: getFirstValidTemplate(descriptionValues, sampleData, 90),
        d2: getFirstValidTemplate(description2Values, sampleData, 90),
        finalUrl,
        displayUrl: getDisplayUrlFromPathAndFinalUrl(getFirstValidPathTemplate(pathValues, sampleData), finalUrl)
    };

    return (
        <React.Fragment>
            <MobilePreview {...previewProps} />
            <DesktopPreview {...previewProps} />
        </React.Fragment>
    );
};

interface IState {
    inventorySampleData: {
        entities: {
            sampleData: ISampleData;
        };
        loadedAt: Date | null;
    };
    clients: {
        currentClient: number;
    };
}

interface IMappedProps {
    sampleData: ISampleData;
    loadedAt: Date | null;
    clientId: number;
}

const mapStateToProps = (state: IState): IMappedProps => {
    return {
        sampleData: state.inventorySampleData.entities.sampleData,
        loadedAt: state.inventorySampleData.loadedAt,
        clientId: state.clients.currentClient
    };
};

export default connect(mapStateToProps)(TextAdPreview);
