import React, { SVGProps } from "react";
import Svg from "./Svg";

const PencilOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M3.458 22.997a.628.628 0 0 1-.605-.783l1.516-5.783a.312.312 0 0 1 .014-.04.567.567 0 0 1 .062-.13.71.71 0 0 1 .098-.124L16.607 4.076c.686-.69 1.6-1.07 2.576-1.073.98 0 1.895.378 2.584 1.063l.016.016a3.647 3.647 0 0 1-.05 5.17L9.685 21.299a.527.527 0 0 1-.057.048.595.595 0 0 1-.226.116l-5.785 1.515a.637.637 0 0 1-.16.02zm.875-1.5l4.054-1.062-.897-2.092-2.095-.895-1.062 4.049zm5.124-1.739l8.87-8.868-1.25-1.25-8.37 8.367.75 1.751zm-1.635-2.633l8.37-8.368-1.25-1.25-8.871 8.87 1.752.748zm11.39-7.118l.856-.857-3.385-3.383-.856.856 3.385 3.384zm1.739-1.743c.401-.438.625-.998.631-1.593a2.383 2.383 0 0 0-.682-1.704l-.014-.015a2.387 2.387 0 0 0-1.694-.7l-.006-.312v.313a2.386 2.386 0 0 0-1.616.63l3.38 3.381z" />
    </Svg>
);

export default PencilOutlineIcon;
