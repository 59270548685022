import React, { SVGProps } from "react";
import Svg from "./Svg";

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M17.333 6.992a5.589 5.589 0 0 0-10.94 1.597v.08a3.992 3.992 0 0 0 .799 7.904h2.395a.798.798 0 0 0 0-1.597H7.192a2.395 2.395 0 0 1-.038-4.79h.097a.858.858 0 0 0 .62-.28.798.798 0 0 0 .18-.653 3.992 3.992 0 1 1 7.875-1.257.798.798 0 0 0 .96.663c.225-.048.454-.071.684-.07a3.194 3.194 0 1 1 0 6.387h-1.596a.798.798 0 0 0 0 1.597h1.596a4.792 4.792 0 0 0-.237-9.581z" />
        <path d="M12.78 11.782a.798.798 0 0 0-.798.799v6.854l-1.032-1.032a.799.799 0 1 0-1.13 1.13l2.395 2.395a.798.798 0 0 0 1.13 0l2.395-2.395a.799.799 0 1 0-1.13-1.13l-1.031 1.032V12.58a.798.798 0 0 0-.799-.799z" />
    </Svg>
);

export default DownloadIcon;
