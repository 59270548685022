import { Link } from "react-router-dom";
import { format } from "date-fns";
import _ from "lodash";
import moment from "moment";
import React, { ChangeEvent, Component, FunctionComponent } from "react";
import validate from "validate.js";
import IAutomobileManufacturer from "../../../interfaces/IAutomobileManufacturer";
import IClient, { IClientDealerSetup, INewClient } from "../../../interfaces/IClient";
import IClientRole from "../../../interfaces/IClientRole";
import IEntity from "../../../interfaces/IEntity";
import IFacebookAccount from "../../../interfaces/IFacebookAccount";
import IFacebookPage from "../../../interfaces/IFacebookPage";
import convertUTCTimeToChicago from "../../../utils/convertUTCTimeToChicago";
import joinTrimAndFilterArrayOfParts from "../../../utils/JoinTrimAndFilterArrayOfParts";
import EditHistoryCollapsibleSection from "../../EditHistory/EditHistoryCollapsibleSection";
import Alert from "../../Shared/Alert";
import Button, { DANGER_BUTTON, NEGATIVE_BUTTON, PRIMARY_BUTTON, SECONDARY_BUTTON } from "../../Shared/Button";
import CollapsibleSection from "../../Shared/CollapsibleSection";
import ModalToggle from "../../Shared/Dialogs/ModalToggle";
import CloseIcon from "../../Shared/Icons/CloseIcon";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import Billing from "./Tabs/Billing";
import BingForm from "./Tabs/Bing";
import CancellationTab from "./Tabs/CancellationTab";
import DIForm from "./Tabs/DealerInspire";
import DV360 from "./Tabs/DV360";
import Facebook from "./Tabs/Facebook";
import FeaturesTab from "./Tabs/Features";
import GeneralFormFields from "./Tabs/General";
import GoogleAdsForm from "./Tabs/GoogleAds";
import GAForm from "./Tabs/GoogleAnalytics";
import OEM from "./Tabs/OEM";
import Koddi from "./Tabs/InventorySearchAds";
import { addCustomValidation, doClientFormValidation, validation } from "./validation";

const tabWithFields: { [key: string]: string } = {
    name: "general",
    shortNameForDealer: "general",
    url: "general",
    salesforceId: "general",
    carsSellerId: "general",
    crmEmail: "general",
    territory: "general",
    clientServicesPod: "general",
    dashboardApiKey: "di",
    inventoryApiKey: "di",
    inventoryFeedId: "di",
    dealerCode: "oem",
    locationCode: "oem",
    oemGoLiveDate: "oem",
    oemEndDate: "oem",
    facebookPageId: "facebook",
    gaProfileId: "ga",
    bingId: "bing",
    cancelAt: "cancelAt"
};

interface IErrorTabs {
    general: boolean;
    di: boolean;
    oem: boolean;
    facebook: boolean;
    ga: boolean;
    bing: boolean;
    cancelAt: boolean;
    adwords: boolean;
    features: boolean;
    dv360: boolean;
}

interface IProps {
    clientId?: string;
    client: IClient | IClientDealerSetup | INewClient;
    clients: IEntity<IClient>;
    loading: boolean;
    facebookAccounts: IEntity<IFacebookAccount>;
    facebookPages: IEntity<IFacebookPage>;
    clientRoles: { [key: string]: IClientRole };
    isInternalUser: boolean;
    canScheduleCancellation: boolean;
    onRefreshSalesforceData(client: IClient): void;
    onSave(client: IClient, changedProperties: { [key: string]: any }): void;
    onDelete(client: IClient): void;
    onFetchClient?: (clientId: string) => void;
    fetchManufacturers: () => void;
    automobileManufacturers: IEntity<IAutomobileManufacturer>;
}

interface IState {
    client: IClient | IClientDealerSetup | INewClient;
    changedProperties: string[];
    saving: boolean;
    externalDataRefresh: boolean;
    warnings: {
        dashboardApiKey: any;
        inventoryApiKey: any;
        inventoryFeedId: any;
        [key: string]: any;
    };
    errors: any;
    canDisplayKoddi: boolean;
    createKoddiRequested: boolean;
}

interface IConfirmDeleteModal {
    client: any;
    onDelete(client: any): void;
}

const ConfirmDeleteModal: FunctionComponent<IConfirmDeleteModal> = ({ onDelete, client }) => {
    return (
        <ModalToggle
            style={{ width: "25%" }}
            toggleButton={(open: () => void) => (
                <Button onClick={open} styleType={DANGER_BUTTON} styles="flex items-center">
                    <CloseIcon className="w-6 h-6" />
                    <span>Delete</span>
                </Button>
            )}
            modalContent={(hide: () => void) => {
                return (
                    <>
                        <h2 className="text-2xl font-semibold">Are you sure you want to delete this client?</h2>
                        <p className="py-6">This will remove the client.</p>
                        <div className="flex justify-end">
                            <Button onClick={hide} styleType={NEGATIVE_BUTTON}>
                                Cancel
                            </Button>
                            <Button onClick={() => onDelete(client)} styleType={DANGER_BUTTON}>
                                Okay
                            </Button>
                        </div>
                    </>
                );
            }}
        />
    );
};

class ClientForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            client: { ...props.client },
            changedProperties: [],
            saving: false,
            externalDataRefresh: false,
            warnings: {} as any,
            errors: {} as any,
            canDisplayKoddi: false,
            createKoddiRequested: false
        };
        addCustomValidation(validate);
        this.onValueChange = this.onValueChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onLinkBing = this.onLinkBing.bind(this);
        this.onLinkFacebook = this.onLinkFacebook.bind(this);
        this.onLinkFacebookPage = this.onLinkFacebookPage.bind(this);
        this.onLinkGA = this.onLinkGA.bind(this);
        this.updateClient = this.updateClient.bind(this);
        this.onRefreshExternalData = this.onRefreshExternalData.bind(this);
        this.onRoleChanged = this.onRoleChanged.bind(this);
        this.updateStateAndCountry = this.updateStateAndCountry.bind(this);
    }

    componentDidMount() {
        if (!!this.props.clientId && !!this.props.onFetchClient) {
            this.props.onFetchClient(this.props.clientId);
        }

        if (Object.keys(this.props.automobileManufacturers).length === 0) {
            this.props.fetchManufacturers();
        }
    }

    onRefreshExternalData() {
        this.setState(() => {
            return { externalDataRefresh: true };
        });
        this.props.onRefreshSalesforceData(this.props.client as IClient);
    }

    onRoleChanged(id: string) {
        const currentRoles = this.state.client.roles;
        const idx = currentRoles.indexOf(id);
        let roles = [];
        if (idx !== -1) {
            roles = [...currentRoles.slice(0, idx), ...currentRoles.slice(idx + 1)];
        } else {
            roles = [...currentRoles, id];
        }

        this.updateClient({ roles });
    }

    onValueChange(e: ChangeEvent<HTMLInputElement>) {
        const field = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        this.updateClient({
            [field]: value,
            //ISA form should be back to "create account" button if any of these fields is changed.
            ...(field === "salesforceId" || field === "carsSellerId" ? { createKoddiRequested: false } : {})
        });
    }

    generateClientName(client: { country: string; name: string }) {
        if (!client.country) {
            return client.name;
        }

        if (client.country === "CA") {
            if (client.name.includes("($CAD)")) {
                return client.name;
            }

            return client.name.trim() + " ($CAD)";
        }

        return client.name.replace("($CAD)", "").trim();
    }

    updateStateAndCountry(state: string, country: string) {
        const clientName = this.generateClientName({
            country,
            name: this.state.client.name || ""
        });

        this.updateClient({ state, country, name: clientName });
    }

    calculateChangedFields(
        changes: { [key: string]: any },
        currentState: { [key: string]: any },
        props: { [key: string]: any }
    ) {
        let currentChanges = currentState.changedProperties;
        Object.keys(changes).forEach((field) => {
            const value = changes[field];
            const index = currentState.changedProperties.indexOf(field);

            if (props.client[field] !== value) {
                if (index === -1) {
                    // check if it has not already changed and add it
                    currentChanges = [...currentChanges, field];
                }
            } else if (index !== -1) {
                // has to have changed before
                currentChanges.splice(index, 1);
            }
        });

        return currentChanges;
    }

    updateClient(changes: { [key: string]: any }) {
        const client = { ...this.state.client, ...changes };
        const { errors, warnings } = doClientFormValidation(this.props.clients, client as IClient, changes, validate);

        this.setState((previousState, props) => {
            const changedProperties = this.calculateChangedFields(changes, previousState, props);
            return {
                client,
                changedProperties,
                warnings,
                errors
            } as any;
        });
    }

    onLinkGA(gaAccountName = "", gaAccountId = "", gaProfileName = "", gaProfileId = "") {
        this.updateClient({
            gaAccountName,
            gaAccountId,
            gaProfileId,
            gaProfileName
        });
    }

    onLinkBing(bingId = "" as unknown as number, bingName = "", bingNumber = "") {
        this.updateClient({ bingId, bingName, bingNumber });
    }

    onLinkFacebook(facebookAccount?: IFacebookAccount) {
        const facebookAccountId = facebookAccount ? facebookAccount.id : null;
        this.updateClient({ facebookAccountId, facebookAccount });
    }

    onLinkFacebookPage(facebookPage?: IFacebookPage) {
        const facebookPageId = facebookPage ? facebookPage.pageId : null;
        this.updateClient({ facebookPageId, facebookPage });
    }

    static validateForm(client: IClient) {
        return validate(client, validation(client));
    }

    onSave() {
        // only save if valid, and we have changes to push
        const errors = ClientForm.validateForm(this.state.client as IClient);

        if (errors) {
            this.setState({ client: { ...this.state.client }, errors });
            return;
        }
        if (this.state.changedProperties.length > 0) {
            this.setState({
                saving: !!this.state.client.id ? false : true, //local 'saving' flag, used when creating a new client. While client.saving is for update.
                client: { ...this.state.client, saving: true } as any
            });
            this.props.onSave(this.state.client as IClient, this.state.changedProperties);
        }
    }

    handleCreateKoddiAccountClick(e?: any) {
        const client: any = { ...this.state.client, createKoddiRequested: true };
        this.setState({ client: { ...client } });
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.client && prevProps.client !== this.props.client) {
            const {
                dealerAddress1,
                dealerAddress2,
                dealerCity,
                dealerState,
                dealerZip,
                dealerPhone,
                dealerName,
                territory,
                clientServicesPod,
                dealerCountryCode,
                dealerCountry,
                salesforceId,
                engineId,
                carsSellerId
            } = this.props.client;

            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    dealerAddress1,
                    dealerAddress2,
                    dealerCity,
                    dealerName,
                    dealerPhone,
                    dealerState,
                    dealerZip,
                    territory,
                    clientServicesPod,
                    dealerCountry,
                    dealerCountryCode,
                    salesforceId,
                    engineId,
                    carsSellerId
                },
                externalDataRefresh: false
            }));

            this.setState((prevState) => {
                if (
                    !prevState.client ||
                    prevState.client.saving ||
                    (this.props.client?.id && this.props.client.updatedAt !== prevState.client.updatedAt)
                ) {
                    return {
                        ...prevState,
                        changedProperties: [],
                        client: { ...prevState.client, ...this.props.client }
                    };
                }
                return prevState;
            });
        }
    }

    static invalidTabs(errors: { [key: string]: string[] }) {
        return _.reduce(
            errors,
            (result: { [key: string]: any }, value, key) => {
                if (tabWithFields[key]) {
                    result[tabWithFields[key]] = true;
                }

                return result;
            },
            {}
        );
    }

    render() {
        const { client, isInternalUser } = this.props;

        if (!client) return <FullPageLoader message="Loading Client..." />;

        const canSave =
            !client.saving &&
            !this.state.saving &&
            _.isEmpty(this.state.errors) &&
            this.state.changedProperties.length > 0;
        const errorTabs = ClientForm.invalidTabs(this.state.errors) as IErrorTabs;

        const clientAddress = joinTrimAndFilterArrayOfParts(
            [
                this.state.client.dealerAddress1,
                this.state.client.dealerAddress2,
                this.state.client.dealerCity ? this.state.client.dealerCity + "," : "",
                this.state.client.dealerState,
                this.state.client.dealerZip
            ],
            " "
        );

        return (
            <div className="p-4">
                {client.cancelAt && (
                    <Alert title="Warning! Client is scheduled for cancellation." type="warning">
                        <>
                            <p>
                                This client is scheduled for cancellation on{" "}
                                {format(convertUTCTimeToChicago(client.cancelAt) as Date, "MMMM d, yyyy")}.
                            </p>
                        </>
                    </Alert>
                )}
                {client.name && (
                    <PageTitle title={client.name ?? ""}>
                        {this.props.client.salesforceId && (
                            <Button
                                disabled={this.state.externalDataRefresh}
                                styleType={PRIMARY_BUTTON}
                                styles="flex items-center"
                                onClick={this.onRefreshExternalData}
                            >
                                <RefreshIcon
                                    className={`w-6 h-6 ${this.state.externalDataRefresh ? "rotate-fast" : ""}`}
                                />
                                Refresh Sales Force Data
                            </Button>
                        )}
                    </PageTitle>
                )}
                <CollapsibleSection
                    title="General"
                    subTitle={clientAddress}
                    defaultOpenState={false}
                    errors={errorTabs.general}
                >
                    <GeneralFormFields
                        client={this.state.client as IClient}
                        onValueChange={this.onValueChange}
                        updateStateAndCountry={this.updateStateAndCountry}
                        isInternalUser={isInternalUser}
                        manufacturers={this.props.automobileManufacturers}
                        errors={this.state.errors}
                    />
                </CollapsibleSection>

                <CollapsibleSection
                    title="DI Information"
                    subTitle={joinTrimAndFilterArrayOfParts([this.state.client.inventoryApiKey])}
                    defaultOpenState={false}
                    errors={errorTabs.di}
                >
                    <DIForm
                        warnings={this.state.warnings}
                        client={this.state.client as IClient}
                        onValueChange={this.onValueChange}
                        isInternalUser={isInternalUser}
                        errors={this.state.errors}
                    />
                </CollapsibleSection>

                {client.id && (
                    <CollapsibleSection
                        title="Google Ads"
                        subTitle={joinTrimAndFilterArrayOfParts([this.state.client.adwordsName])}
                        defaultOpenState={false}
                        errors={errorTabs.adwords}
                    >
                        <GoogleAdsForm client={this.state.client as IClient} onValueChange={this.onValueChange} />
                    </CollapsibleSection>
                )}
                {this.props.isInternalUser && (
                    <>
                        <CollapsibleSection
                            title="Bing"
                            subTitle={joinTrimAndFilterArrayOfParts([this.state.client.bingName])}
                            defaultOpenState={false}
                            errors={errorTabs.bing}
                        >
                            <BingForm client={this.state.client as IClient} onLink={this.onLinkBing} />
                        </CollapsibleSection>

                        <CollapsibleSection
                            data-test-id="inventory-search-ads"
                            title="Inventory Search Ads"
                            defaultOpenState={false}
                            errors={!!this.state.errors?.displayKoddi}
                            subTitle={(this.state.client.koddiId && `Koddi ID ${this.state.client.koddiId}`) || ""}
                        >
                            <Koddi
                                data-testid={"koddi-collapsible"}
                                allowEdit={true}
                                client={this.state.client as IClient}
                                onValueChange={this.onValueChange}
                                onCreateAccountClick={this.handleCreateKoddiAccountClick.bind(this)}
                                errors={this.state.errors}
                                //hasAccount={this.state.canDisplayKoddi}
                            />
                        </CollapsibleSection>

                        <CollapsibleSection
                            title="Facebook"
                            subTitle={joinTrimAndFilterArrayOfParts([
                                this.state.client.facebookAccount?.name,
                                this.state.client.facebookPage?.name
                            ])}
                            defaultOpenState={false}
                            errors={errorTabs.facebook}
                        >
                            <Facebook
                                onLink={this.onLinkFacebook}
                                onLinkPage={this.onLinkFacebookPage}
                                client={this.state.client as IClient}
                            />
                        </CollapsibleSection>
                        <CollapsibleSection title="DV360" subTitle="" defaultOpenState={false} errors={errorTabs.dv360}>
                            <DV360
                                client={this.state.client as IClient}
                                isInternalUser={isInternalUser}
                                onValueChange={this.onValueChange}
                            />
                        </CollapsibleSection>
                        <CollapsibleSection
                            title="OEM"
                            subTitle={joinTrimAndFilterArrayOfParts([this.state.client.oemProgram])}
                            defaultOpenState={false}
                            errors={errorTabs.oem}
                        >
                            <OEM client={this.state.client as IClient} onValueChange={this.onValueChange} />
                        </CollapsibleSection>
                        <CollapsibleSection title="Billing" subTitle={""} defaultOpenState={false}>
                            <Billing client={this.state.client as IClient} />
                        </CollapsibleSection>
                        <CollapsibleSection title="Features" subTitle={""} defaultOpenState={false}>
                            <FeaturesTab
                                client={this.state.client as IClient}
                                onValueChange={this.onRoleChanged}
                                roles={this.props.clientRoles}
                            />
                        </CollapsibleSection>
                    </>
                )}
                {this.props.canScheduleCancellation && (
                    <CollapsibleSection
                        title="Cancellation"
                        subTitle={joinTrimAndFilterArrayOfParts([this.state.client.cancelAt])}
                        defaultOpenState={false}
                    >
                        <CancellationTab
                            client={this.state.client as IClient}
                            onValueChange={this.onValueChange}
                            errors={this.state.errors}
                        />
                    </CollapsibleSection>
                )}
                {client.id && (
                    <CollapsibleSection
                        title="Edit History"
                        subTitle={`Last edit was ${moment(this.state.client.updatedAt).format("MMMM Do YYYY, h:mm a")}`}
                        defaultOpenState={false}
                    >
                        <hr className="-mt-4 mb-4" />
                        <EditHistoryCollapsibleSection client={this.state.client as IClient} />
                    </CollapsibleSection>
                )}
                <div className="flex justify-between my-4 text-base">
                    <div>
                        <Button styleType={PRIMARY_BUTTON} onClick={this.onSave} disabled={!canSave}>
                            Save
                        </Button>
                        &nbsp;
                        <Link to="/clients">
                            <Button styleType={SECONDARY_BUTTON}>Cancel</Button>
                        </Link>
                    </div>

                    {this.props.client.id && this.props.client.id > 0 && (
                        <ConfirmDeleteModal onDelete={this.props.onDelete} client={this.props.client} />
                    )}
                </div>
            </div>
        );
    }
}

export default ClientForm;
