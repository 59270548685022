import React from "react";
import PropTypes from "prop-types";
import { getStatusDetails } from "./TableUtils";

const StatusCell: React.FC<{ statusId: number }> = ({ statusId }) => {
    const { type, title } = getStatusDetails(statusId);
    return (
        <div className="cell-wrapper notification-cell">
            <span className={`notification n-${type}`} />
            <span className={`notification-title`}>{title}</span>
        </div>
    );
};

StatusCell.propTypes = {
    statusId: PropTypes.number.isRequired
};

export default StatusCell;
