import React from "react";
import Field from "./Form/Elements/Field";
import ChevronDownIcon from "./Icons/ChevronDownIcon";

interface IProps {
    client: {
        oemProgram?: string;
        errors?: {
            oemProgram?: string;
        };
    };
    onValueChange(event: React.ChangeEvent<HTMLSelectElement>): void;
    disabled?: boolean;
}

const OEMProgram: React.FC<IProps> = ({ client, onValueChange, disabled }) => {
    return (
        <Field errors={[]} label="Program" className="relative">
            <div className="relative">
                <select
                    className="block appearance-none w-full bg-white border border-gray-400 shadow-sm text-gray-700 p-2 pr-8 rounded focus:outline-none focus:border-gray-500 text-base"
                    onChange={onValueChange}
                    disabled={disabled}
                    value={client ? client.oemProgram : ""}
                    name="oemProgram"
                >
                    <option value="">Please select OEM program...</option>
                    <option value="acura">Acura</option>
                    <option value="bmw">BMW</option>
                    <option value="fca">FCA</option>
                    <option value="fcaca">FCA CA</option>
                    <option value="ford">Ford</option>
                    <option value="genesis">Genesis</option>
                    <option value="gm">GM</option>
                    <option value="gmca">GM CA</option>
                    <option value="honda">Honda</option>
                    <option value="infiniti">Infiniti</option>
                    <option value="jlr">JLR</option>
                    <option value="kia">KIA</option>
                    <option value="lexusFixedOps">Lexus Fixed Ops</option>
                    <option value="lincoln">Lincoln</option>
                    <option value="maserati">Maserati</option>
                    <option value="mazdaca">Mazda CA</option>
                    <option value="mazdaRgtmPlus">Mazda RGTM+</option>
                    <option value="mini">Mini</option>
                    <option value="porsche">Porsche</option>
                    <option value="toyota">Toyota</option>
                    <option value="subaru">Subaru</option>
                    <option value="volvoca">Volvo CA</option>
                    <option value="vw">VW</option>
                    <option value="vwca">VWCA</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <ChevronDownIcon className="h-6 w-6" />
                </div>
            </div>
        </Field>
    );
};

export default OEMProgram;
