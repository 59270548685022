/**
 * Sagas for dealing with client requests
 */
import { NavigateFunction, redirect } from "react-router-dom";
import { chunk } from "lodash";
import { Action, Func1 } from "redux";
import {
    all,
    call,
    CallEffect,
    CallEffectNamedFn,
    HelperFunc0,
    put,
    PutEffect,
    select,
    takeEvery
} from "redux-saga/effects";
import {
    LOAD_ADWORDS_ADGROUPS,
    LOAD_CAMPAIGNS,
    LOAD_MICROSOFT_CAMPAIGNS,
    REQUEST_CAMPAIGN_FAILURE,
    REQUEST_LOAD_ADWORDS_ADGROUPS,
    REQUEST_LOAD_ADWORDS_CAMPAIGNS,
    REQUEST_LOAD_MICROSOFT_CAMPAIGNS,
    REQUEST_LOAD_MICROSOFT_CAMPAIGNS_FAILURE
} from "../actions/campaignActions";
import * as actions from "../actions/clientActions";
import { clear, issueFailedSave } from "../actions/errorsActions";
import { addFlashMessage } from "../actions/flashMessageActions";
import { getClientInsightUrl, Insight } from "../components/Clients/List/Table/ClientInsights";
import * as Constants from "../constants/ClientConstants";
import { DELETED, NO_SEARCH, NO_SORT, RESET_CLIENT_STATE } from "../constants/ClientConstants";
import IClient from "../interfaces/IClient";
import IEntity from "../interfaces/IEntity";
import IPagedResponse from "../interfaces/IPagedResponse";
import IPaginatedClients from "../interfaces/IPaginatedClients";
import ITableState from "../interfaces/ITableState";
import { callApi, callApiAndFetchEveryPaginatedPage, doDeleteRequest, getPagedResults } from "../middleware/api";
import SCHEMA from "../middleware/schemas";
import CLIENT_SCHEMAS, { sanatizeObject } from "../middleware/schemas/client";
import { getAllRoles } from "../reducers/clientRoles";
import { getPaginatedClientsResults } from "../reducers/clients";
import { InsightUrlType } from "../ts/types";
import buildUrl from "../utils/UrlUtils";

interface IFetchAdWordsCampaignAdGroups {
    type: string;
    clientId: number;
    campaignId: number;
    refresh: boolean;
}

export function* fetchAdWordsCampaignAdGroups({ clientId, campaignId, refresh }: IFetchAdWordsCampaignAdGroups) {
    let url = `/clients/${clientId}/adwords-campaigns/${campaignId}/ad-groups`;
    if (refresh === true) {
        url = `${url}&refresh=true`;
    }
    try {
        const resp: IPagedResponse = yield call(callApi, url, CLIENT_SCHEMAS.ADWORDS.AD_GROUP_ARRAY);
        const adGroups = resp.entities.adwordsAdGroups;
        yield put({ type: LOAD_ADWORDS_ADGROUPS, adGroups });
    } catch (e) {
        console.log(e);
    }
}

export interface IFetchAdWordsCampaigns {
    type?: string;
    clientId?: number;
    refresh?: boolean;
}
export function* fetchAdWordsCampaigns({ clientId, refresh }: IFetchAdWordsCampaigns) {
    let url = `/clients/${clientId}/adwords-campaigns?limit=100`;
    if (refresh === true) {
        url = `${url}&refresh=true`;
    }

    try {
        const response: IPagedResponse = yield call(
            callApiAndFetchEveryPaginatedPage,
            url,
            CLIENT_SCHEMAS.ADWORDS.CAMPAIGN_ARRAY
        );
        yield put({ type: LOAD_CAMPAIGNS, response, refresh });
    } catch (e) {
        yield put({ type: REQUEST_CAMPAIGN_FAILURE, errors: e.errors });
    }
}

interface IFetchCampaigns {
    type?: string;
    clientId?: number;
    refresh?: boolean;
}

export function* fetchMicrosoftCampaigns({ clientId, refresh }: IFetchCampaigns) {
    const url = buildUrl(`/clients/${clientId}/microsoft/campaigns`, {
        limit: 100,
        refresh
    });

    try {
        const response: IPagedResponse = yield call(
            callApiAndFetchEveryPaginatedPage,
            url,
            CLIENT_SCHEMAS.MICROSOFT.CAMPAIGN_ARRAY
        );
        yield put({ type: LOAD_MICROSOFT_CAMPAIGNS, response });
    } catch (e) {
        yield put({ type: REQUEST_LOAD_MICROSOFT_CAMPAIGNS_FAILURE, errors: e.errors });
    }
}

/**
 * Load client roles available to be assigned to clients
 */
export function* fetchClientRoles() {
    try {
        const response: IPagedResponse = yield call(
            callApi,
            "/client-roles?limit=100",
            CLIENT_SCHEMAS.CLIENT_ROLE_ARRAY
        );
        yield put({ type: Constants.LOAD_CLIENT_ROLES, response });
    } catch (e) {
        yield put({ type: Constants.FAILED_LOAD_CLIENT_ROLES, errors: e.errors });
    }
}

interface IFetchClient {
    type?: "";
    clientId?: number;
}

export function* fetchClient({ clientId }: IFetchClient) {
    try {
        // We need to reset the client state first otherwise other network requests might come back
        // before we have a chance to refrsh everything from the client.
        yield put({
            type: RESET_CLIENT_STATE
        });

        const response: IPagedResponse = yield call(
            callApi,
            `/clients/${clientId}?expand[roles]=*&expand[facebookAccount]=*&expand[facebookPage]=*&expand[manager]=*&expand[pfm]=*&expand[automobileManufacturers]=*`,
            CLIENT_SCHEMAS.CLIENT,
            "GET"
        );
        const updatedClient = clientId ? response?.entities?.clients[clientId] : null;
        yield put(actions.updateClient(updatedClient));
    } catch (e) {
        console.log(e);
        // todo: what do we do because we may not have an objecdt in the store with this id
    }
}

interface IFetchPagedClients {
    type?: string;
    payload?: any;
}

interface IFetchPagedClientsPayload {
    page: number;
    pageSize: number;
    search: string;
    insight: Insight;
}

export function* fetchPagedClients({ payload }: IFetchPagedClients) {
    const { page, pageSize, search, insight } = payload as IFetchPagedClientsPayload;
    const cache: IPaginatedClients = yield select(getPaginatedClientsResults);
    const cachedResults =
        cache?.[insight]?.[search || NO_SEARCH]?.[NO_SORT]?.[`PAGE_SIZE_${pageSize}`]?.pages?.[page] ?? [];

    let requestPageSize: number = pageSize;
    let requestPage: number = page;

    if (cachedResults.length === 0 && pageSize < 100) {
        requestPageSize = pageSize * 10;
        requestPage = Math.ceil(page / 10);
    }
    const url: InsightUrlType = getClientInsightUrl(insight);
    try {
        yield call(clear);

        const response: IPagedResponse<{ clients: IEntity<IClient> }> = yield call(
            getPagedResults,
            url(Math.ceil(requestPage), search),
            CLIENT_SCHEMAS.CLIENT_ARRAY,
            requestPageSize
        );

        const { clients } = response.entities;
        const { currentPage, data, lastPage, total } = response.result;

        if (requestPageSize !== pageSize && total > 0) {
            const chunkedData = chunk(data, pageSize);
            const loadActions = chunkedData.map((dataChunk, index) => {
                const startingPage = currentPage * 10 - 9;

                return put(
                    actions.loadPagedClients({
                        ...payload,
                        entities: clients,
                        results: dataChunk,
                        lastPage: Math.ceil(total / pageSize),
                        page: startingPage + index
                    })
                );
            });
            yield all(loadActions);
            return;
        }

        yield put(
            actions.loadPagedClients({
                ...payload,
                entities: clients,
                results: data,
                lastPage,
                page: currentPage
            })
        );
    } catch (e) {
        yield put({ type: Constants.FAILED_LOAD_CLIENTS, errors: e.errors });
    }
}

interface ISaveClient {
    type?: string;
    client: IClient & { id: number };
    id: number;
    changedFields?: any;
}
interface IClientPayloadBody {
    isa_advertiser_name?: string;
    channelsPublishers?: any;
}

export function* saveClient(action: ISaveClient, navigate: NavigateFunction) {
    const env = (envValue: string, defaultValue: string) => process.env[envValue] || defaultValue;
    const client = action.client;
    const roles: Generator = yield select(getAllRoles);
    const body: IClientPayloadBody = sanatizeObject(client, action.changedFields, roles);
    const clientId = client.id;
    const isaAdvertiserName = `${body.isa_advertiser_name || ""}`;
    delete body.isa_advertiser_name;

    try {
        yield call(clear);
        const response: IPagedResponse = yield call(
            callApi,
            `/clients/${clientId}`,
            CLIENT_SCHEMAS.CLIENT,
            "PUT",
            body
        );
        const updatedClient = response.entities.clients[clientId];
        if (!client.koddiId && !!isaAdvertiserName && !!updatedClient.carsSellerId && !!updatedClient.salesforceId) {
            const koddiPayload = {
                advertiserName: isaAdvertiserName,
                channelsPublishers: JSON.stringify({
                    channels: [
                        {
                            publishers: [
                                {
                                    id: Number(env("REACT_APP_KODDI_PUBLISHER_ID", "43")),
                                    name: "Cars"
                                }
                            ],
                            id: 1
                        }
                    ]
                })
            };

            try {
                yield call(
                    //Koddi account creation.
                    callApi,
                    `/clients/${clientId}/inventory-search-ads-accounts`,
                    CLIENT_SCHEMAS.CLIENT,
                    "POST",
                    koddiPayload
                );
            } catch (e) {
                yield put(
                    addFlashMessage({ type: "danger", text: "Error during Inventory Search Ads account creation" })
                );
            } finally {
                updatedClient.createKoddiRequested = false;
            }
        }

        yield put(actions.updateClient(updatedClient));
        yield put(addFlashMessage({ type: "success", text: `Saved ${updatedClient.name}` }));
        // reload client to get expanded relationship, right now we are doing this because saving updates instead of merging state.
        // when we have time we should fix this to be better maybe.
        yield fetchClient({ type: "", clientId });
    } catch (e) {
        yield put(actions.updateClient({ ...action.client, errors: e.errors }));
    }
}

interface ICreateClient {
    type: string;
    client: IClient;
    navigate?: NavigateFunction;
}
interface ICreateClientResponse {
    entities: {
        clients: {
            [key: number]: IClient;
        };
    };
    result: number;
}

export function* createClient(
    action: ICreateClient
): Generator<CallEffect | PutEffect<Action>, void, ICreateClientResponse> {
    const client = action.client;
    const { navigate } = action;
    const body = sanatizeObject(client);
    try {
        yield call(clear);
        const response = yield call(callApi, `/clients`, CLIENT_SCHEMAS.CLIENT, "POST", body);
        const clientId = response.result;
        const updatedClient = response.entities.clients[clientId];
        yield put(actions.updateClient(updatedClient));
        yield put(addFlashMessage({ type: "success", text: `Saved ${updatedClient.name}` }));
        // yield call(
        //     redirect as unknown as CallEffectNamedFn<{ [x: string]: Func1<string, void> }, string>,
        //     `/clients/${clientId}`
        // );
        navigate && navigate(`/clients/${clientId}`);
    } catch (e) {
        yield put(issueFailedSave(e));
    }
}

interface IDeleteClient {
    type: string;
    id: number;
}

export function* deleteClient({ id }: IDeleteClient): Generator<CallEffect | PutEffect<Action>, void, void> {
    try {
        yield call(doDeleteRequest, `/clients/${id}`);
        yield put(actions.removeClient(id));
        yield call(redirect as unknown as CallEffectNamedFn<{ [x: string]: Func1<string, void> }, string>, "/clients");
    } catch (e) {
        console.log(e);
    }
}

interface IRestoreClient {
    type: string;
    payload: {
        client: IClient & { id: number };
        pageSize?: number;
        page?: number;
        insight?: Insight;
        search?: string;
    };
}

export function* restoreClient({ payload: { client, page, pageSize, insight, search } }: IRestoreClient) {
    try {
        yield call(callApi, `/clients/${client.id}/restore`, CLIENT_SCHEMAS.CLIENT, "POST", {});
        yield put(addFlashMessage({ type: "success", text: `Successfully restored ${client.name}.` }));

        yield put(actions.fetchPagedClients({ page, pageSize } as ITableState, insight ?? DELETED, search ?? ""));
    } catch (e) {
        yield put(addFlashMessage({ type: "danger", text: `Failed to restore ${client.name}!` }));
    }
}

interface IRefreshExternalData {
    type: string;
    client: IClient & { id: number };
}

export function* refreshExternalData(action: IRefreshExternalData) {
    const clientId = action.client.id;
    const command = "client:refresh-external-data";
    const parameters = { clientID: clientId };

    try {
        yield call(callApi, "/artisan-commands", SCHEMA.ARTISAN_COMMAND, "POST", {
            command,
            parameters
        });
        yield put(addFlashMessage({ type: "success", text: `Data refresh completed` }));
        yield fetchClient({ type: "", clientId });
    } catch (e) {
        yield put(
            addFlashMessage({
                type: "error",
                text: `Data refresh failed.  ${e.message}`
            })
        );
    }
}

interface IDeletePagedClient {
    type: string;
    payload: {
        clientId: number;
        page: number;
        pageSize: number;
        insight: Insight;
        search: string;
    };
}

export function* deletePagedClient({ payload: { clientId, page, pageSize, insight, search } }: IDeletePagedClient) {
    try {
        yield call(doDeleteRequest, `/clients/${clientId}`);
        yield put(actions.fetchPagedClients({ page, pageSize }, insight, search));
    } catch (e) {
        console.log(e);
    }
}

function* clientSaga() {
    yield takeEvery(Constants.REQUEST_LOAD_CLIENT, fetchClient);
    yield takeEvery(Constants.REQUEST_DELETE_CLIENT, deleteClient);
    yield takeEvery(Constants.REQUEST_DELETE_PAGED_CLIENT, deletePagedClient);
    yield takeEvery(REQUEST_LOAD_ADWORDS_CAMPAIGNS, fetchAdWordsCampaigns);
    yield takeEvery(REQUEST_LOAD_MICROSOFT_CAMPAIGNS, fetchMicrosoftCampaigns);

    yield takeEvery(Constants.REQUEST_SAVE_CLIENT, saveClient as HelperFunc0<Action<any>>);
    yield takeEvery(Constants.REQUEST_CREATE_CLIENT, createClient);
    yield takeEvery(Constants.REFRESH_EXTERNAL_DATA, refreshExternalData);
    yield takeEvery(REQUEST_LOAD_ADWORDS_ADGROUPS, fetchAdWordsCampaignAdGroups);
    yield takeEvery(Constants.REQUEST_LOAD_CLIENT_ROLES, fetchClientRoles);
    yield takeEvery(Constants.REQUEST_RESTORE_CLIENT, restoreClient);
    yield takeEvery(Constants.REQUEST_PAGED_CLIENTS, fetchPagedClients);
}

export default clientSaga;
