import React, { useEffect, useState } from "react";
import Button, { SECONDARY_BUTTON, SUCCESS_BUTTON } from "../../../Shared/Button";
import { IExpandedTextAdTemplate } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import { getCurrentClientObject } from "../../../../reducers/clients";
import actions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import IAppState from "../../../../interfaces/IAppState";
import { connect } from "react-redux";
import IClient from "../../../../interfaces/IClient";
import LoaderSpinner from "../../../Shared/Loaders/LoaderSpinner";
import TextField from "../../../Shared/Form/Blocks/TextField";

interface IProps {
    etaTemplate: IExpandedTextAdTemplate;
    hide: () => void;
}

const EtaTemplateBoilerplateSaveModal = ({
    error,
    etaTemplate,
    createBoilerplate,
    hide,
    currentClient,
    saving
}: IProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps) => {
    const [name, setName] = useState("");
    const [saved, setSaved] = useState(false);

    useEffect(() => {
        if (saved && !saving && !!error.value) {
            setSaved(false);
        }
    }, [saved, error]);

    useEffect(() => {
        if (saved && !saving && !error.value) {
            setTimeout(() => {
                hide();
            }, 2000);
        }
    }, [saved, saving, error]);

    const handleSave = () => {
        createBoilerplate(name, etaTemplate, (currentClient as IClient).id);
        setSaved(true);
    };

    return (
        <div className={`flex justify-around items-center h-full`}>
            <div className={`flex flex-col w-full`}>
                <div className={`text-xl`}>Save Expanded Text Ad Template</div>
                <div>
                    <TextField
                        className={`w-full`}
                        name={"name"}
                        label={"Template Name"}
                        value={name}
                        handleChange={(event: any) => setName(event.target.value)}
                        readOnly={saving}
                    />
                </div>
                {saved && !saving && !error.value ? (
                    <div className={`w-full text-2xl my-4 flex justify-center text-green-500`}>
                        Success: New template "{name}" was created!
                    </div>
                ) : (
                    <div className={`w-full flex mt-3`}>
                        <div className={`text-red-500 px-3 flex-grow flex justify-center`}>
                            <div>{error?.value ? `Error: ${error.value}` : ""}</div>
                        </div>
                        <Button styleType={SECONDARY_BUTTON} onClick={hide} disabled={saving}>
                            Cancel
                        </Button>
                        <Button styleType={SUCCESS_BUTTON} disabled={saving} onClick={() => handleSave()}>
                            {saving ? <LoaderSpinner message={"Saving"} /> : "Save"}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    currentClient: getCurrentClientObject(state),
    saving: state.etaTemplateBoilerplate.saving,
    error: state.etaTemplateBoilerplate.error
});

const mapDispatchToProps = {
    createBoilerplate: (name: string, eta: IExpandedTextAdTemplate, clientId?: number) =>
        actions.store.request({ name, eta, clientId })
};

export default connect(mapStateToProps, mapDispatchToProps)(EtaTemplateBoilerplateSaveModal);
