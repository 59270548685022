import React, { createContext, useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import useNamingConventions, {
    INamingConvention,
    INamingConventionFormValues
} from "../../../hooks/namingConventions/useNamingConvention";
import useConditionalParams from "../../../hooks/useConditionalParams";
import SelectField from "../../Shared/Form/Blocks/SelectField";
import TextField from "../../Shared/Form/Blocks/TextField";
import Label from "../../Shared/Form/Label";
import AddIcon from "../../Shared/Icons/AddIcon";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import CollapsibleSection from "../../Shared/CollapsibleSection";
import Button from "../../Shared/Button";
import NamingConventionPart from "./NamingConventionPart";
import { IUseApiHandler } from "../../../hooks/useApi";
import mustache from "../../../utils/Mustache";
// @ts-ignore
import dot from "dot-object";
import { uniqueId } from "lodash";
import SortableList from "../../Shared/Form/SortableList/SortableList";

type INamingConventionsFormFieldName = "glue" | "name" | "type" | "enabled";
interface ITypeOption {
    label: string;
    value: string;
}
export interface INamingConventionsFormContextReturn {
    typeOptions: ITypeOption[];
    bottomDisabled: boolean;
    namingConventionPartsErrors: any;
    allowEnable: boolean;
    values: INamingConventionFormValues;
    savingNamingConvention: boolean;
    saveNamingConventionError: any;
    errors: any;
    conditionableModels: any;
    conditionalFieldOptions: any;
    namingConvention: INamingConvention;
    setValue: (item: any, value: any, options: { shouldDirty: boolean; shouldTouch: boolean }) => void;
    register: any;
    watch: any;
    fields: any;
    getValues: any;
    isDirty: any;
    remove: any;
    replace: any;
    swap: any;
    append: any;
    reset: any;
    move: any;
    fetchNamingConvention: any;
    createNamingConventionPartError: any;
    createNamingConventionPartValueError: any;
    namingConventionParts: any;
    loadingNamingConventions: boolean;
    onSubmit: () => void;
    handleSelectChange: (option: ITypeOption, name: INamingConventionsFormFieldName) => void;
    deleteNamingConventionPartValue: IUseApiHandler;
    deleteNamingConventionPart: IUseApiHandler;
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleCheckBoxChange: (e: any) => void;
    publishNamingConvention: IUseApiHandler;
    update: any;
}

//Create a context to share Naming Conventions Data between Parent Form, Parts and PartValues
export const NamingConventionFormContext = createContext<INamingConventionsFormContextReturn>(
    {} as INamingConventionsFormContextReturn
);

//Create a Main Component that puts all NamingConventions functionality into the context provider and return the wrapped form.
export default () => {
    const { conditionableModels, conditionalFieldOptions } = useConditionalParams();
    const {
        register,
        watch,
        reset,
        setValue,
        clearErrors,
        getValues,
        errors,
        namingConventionPartsFieldArray,
        isDirty,
        loadingNamingConventions,
        values,
        namingConvention,
        formSubmitHandler,
        savingNamingConvention,
        saveNamingConventionError,
        deleteNamingConventionPartValue,
        fetchNamingConvention,
        deleteNamingConventionPart,
        createNamingConventionPartError,
        createNamingConventionPartValueError,
        publishNamingConvention
    } = useNamingConventions();

    const fieldNames: INamingConventionsFormFieldName[] = ["name", "glue", "type", "enabled"];
    fieldNames.forEach((fieldName) => {
        register(fieldName, fieldName === "enabled" ? {} : { required: true });
        watch(fieldName);
    });

    useEffect(() => {
        if (!loadingNamingConventions && namingConvention?.id) {
            reset(namingConvention);
        }
    }, [namingConvention, loadingNamingConventions]);
    //Define form config objects

    const typeOptions: ITypeOption[] = [
        { label: "Campaign", value: "campaign" },
        { label: "Account", value: "account" },
        { label: "Dealer Setup Ad Group", value: "dealer_setup_adgroup" },
        { label: "Dynamic Campaign Ad Group", value: "dynamic_campaign_adgroup" }
    ];

    //handlers
    const onSubmit = () => formSubmitHandler({ ...values, enabled: values.enabled || false });

    const handleSelectChange = (option: ITypeOption, name: INamingConventionsFormFieldName) => {
        setValue(name, option.value, { shouldDirty: true, shouldTouch: true });
        clearErrors(name);
    };
    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.name as INamingConventionsFormFieldName, e.target.value, {
            shouldDirty: true,
            shouldTouch: true
        });
        clearErrors(e.target.name as any);
    };
    const handleCheckBoxChange = (e: any) => {
        const {
            target: { name }
        } = e;
        const n: INamingConventionsFormFieldName = name as INamingConventionsFormFieldName;
        setValue(name, !values[n], { shouldDirty: true, shouldTouch: true });
        clearErrors(name);
    };

    if (loadingNamingConventions) return <FullPageLoader message="Loading Naming Convention..." />;

    const contextReturn: INamingConventionsFormContextReturn = {
        typeOptions,
        bottomDisabled: !namingConvention?.id || savingNamingConvention,
        loadingNamingConventions,
        savingNamingConvention,
        namingConventionPartsErrors: errors.namingConventionParts,
        errors,
        allowEnable: !!namingConvention?.conditionals?.length,
        conditionableModels,
        conditionalFieldOptions,
        saveNamingConventionError,
        namingConvention,
        values,
        ...namingConventionPartsFieldArray,
        reset,
        setValue,
        register,
        watch,
        getValues,
        isDirty,
        fetchNamingConvention,
        deleteNamingConventionPart,
        deleteNamingConventionPartValue,
        createNamingConventionPartError,
        createNamingConventionPartValueError,
        namingConventionParts: namingConvention?.namingConventionParts,
        onSubmit,
        handleSelectChange,
        handleFieldChange,
        handleCheckBoxChange,
        publishNamingConvention
    };

    return (
        <NamingConventionFormContext.Provider value={contextReturn}>
            <NamingConventionsForm />
        </NamingConventionFormContext.Provider>
    );
};

export const NamingConventionsForm = () => {
    const {
        onSubmit,
        handleSelectChange,
        handleFieldChange,
        handleCheckBoxChange,
        getValues,
        fields,
        isDirty,
        errors,
        createNamingConventionPartError,
        createNamingConventionPartValueError,
        bottomDisabled,
        allowEnable,
        namingConvention,
        conditionalFieldOptions,
        savingNamingConvention,
        saveNamingConventionError,
        values,
        typeOptions,
        replace,
        publishNamingConvention
    } = useContext<INamingConventionsFormContextReturn>(NamingConventionFormContext);

    const [saveClicked, setSavedClicked] = useState<boolean>(false);

    const navigate = useNavigate();

    const addPartHandler = () => {
        const { id: namingConventionId } = namingConvention;
        const parts = getValues().namingConventionParts;
        const newPart = {
            _id: uniqueId("id-"),
            id: 0,
            namingConventionId,
            name: "",
            order: parts?.length ?? 0,
            mustAllConditionsPass: true,
            createdAt: "",
            updatedAt: "",
            conditionals: [],
            values: []
        };
        replace([...parts, newPart]);
    };

    const previewNamingConventionWithParts = (
        namingConvention: INamingConvention,
        conditionalFieldOptions: any[]
    ): string => {
        // Since we're just looking to preview the existing naming convention, we don't need to pay attention
        // to the conditionals, so let's just grab the first value if there are any, otherwise default to the part.
        const namingConventionInOrder =
            getValues()?.namingConventionParts?.reduce(function (
                allParts: any,
                part: any
            ): {
                [key: string]: string;
            } {
                if (part)
                    return {
                        ...allParts,
                        [part.order]: part.values?.length > 0 ? part.values[0]?.value : part.name
                    };
                return { ...allParts };
            }, {}) ?? {};

        const availableParameters = conditionalFieldOptions.reduce(
            (allFields, option) => ({
                ...allFields,
                [option.value]: option.label
            }),
            {}
        );

        return Object.values(namingConventionInOrder)
            .map((value: any) => {
                try {
                    return mustache.render(value, dot.object(availableParameters));
                } catch (e) {
                    // This is most likely an unclosed tag
                    return false;
                }
            })
            .filter((emptyStringValue) => emptyStringValue !== false && emptyStringValue !== "")
            .join(namingConvention?.glue ?? "");
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                setSavedClicked(true);
                onSubmit();
            }}
        >
            <div className="mx-4 my-4 flex flex-col gap-2">
                <div className="text-4xl font-bold">Naming Conventions Form</div>
                <CollapsibleSection
                    title={"Naming Convention"}
                    subTitle={[values.name, values.type + " type"].join(", ")}
                    errors={saveNamingConventionError.keys.length > 0}
                    defaultOpenState={true}
                >
                    <div className={`px-4 pb-4 border-t border-gray-400 bg-gray-100 -mx-4 -mb-4 rounded-b`}>
                        {!!saveNamingConventionError.keys.length && (
                            <div className="parterror text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                                <div className="text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                                    {saveNamingConventionError.keys.map((errorKey: string) => {
                                        const errorMessage = saveNamingConventionError.errors[errorKey];
                                        return (
                                            <div key={errorKey}>
                                                <span className={"font-bold"}>{errorKey}:</span> {errorMessage}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col">
                            <div className="w-full lg:w-2/3 flex flex-col">
                                <TextField
                                    label="Name"
                                    autoFocus
                                    name="name"
                                    disabled={savingNamingConvention}
                                    value={values.name ?? ""}
                                    className="flex-1 rounded-r"
                                    inputStyle={{ borderRadius: "3" }}
                                    handleChange={handleFieldChange}
                                    errors={errors?.name?.type ? [errors?.name?.type] : ([] as any)}
                                />
                            </div>
                            <div className="w-full lg:w-2/3 flex flex-row">
                                <SelectField
                                    label="Type (where this convention is applied)"
                                    name="type"
                                    options={typeOptions}
                                    readOnly={savingNamingConvention}
                                    value={values.type ?? ""}
                                    handleChange={(option: ITypeOption) => handleSelectChange(option, "type")}
                                    className={"w-full"}
                                    errors={errors?.type?.type ? [errors?.type?.type] : ([] as any)}
                                />
                            </div>
                            <div className="w-full lg:w-2/3 flex flex-row">
                                <TextField
                                    disabled={savingNamingConvention}
                                    label="Glue (how the parts are stuck together)"
                                    autoFocus
                                    name="glue"
                                    value={values.glue ?? ""}
                                    className="flex-1 rounded-r"
                                    inputStyle={{ borderRadius: "3" }}
                                    handleChange={handleFieldChange}
                                    errors={errors?.glue?.type ? [errors?.glue?.type] : ([] as any)}
                                />
                            </div>
                            <div className="w-full lg:w-2/3 flex flex-col">
                                <Label label={"Example Preview"} required={false} />
                                <div className={"mb-2 p-2 bg-gray-500 rounded-sm break-words truncate"}>
                                    {previewNamingConventionWithParts(
                                        {
                                            ...namingConvention,
                                            ...values
                                        },
                                        conditionalFieldOptions
                                    )}
                                    &nbsp;
                                </div>
                            </div>
                            <div className="w-full lg:w-2/3">
                                <label
                                    className={`flex gap-2 items-center p-2 cursor-pointer select-none ${
                                        !allowEnable ? "opacity-50 cursor-not-allowed" : ""
                                    }`}
                                >
                                    <input
                                        disabled={savingNamingConvention || !allowEnable}
                                        name="enabled"
                                        type="checkbox"
                                        checked={!!values.enabled}
                                        className="mr-2"
                                        onChange={handleCheckBoxChange}
                                    />{" "}
                                    Enabled
                                </label>
                                {!allowEnable && (
                                    <span className={"text-orange-600"}>
                                        This Naming Convention will remain disabled until it has at least 1 conditional,
                                        applied from the index page.
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </CollapsibleSection>
                <CollapsibleSection
                    title="Naming Convention Parts"
                    subTitle={previewNamingConventionWithParts(namingConvention, conditionalFieldOptions)}
                    errors={createNamingConventionPartError.keys.length > 0}
                    defaultOpenState={true}
                >
                    <div className="text-2md my-2 text-blue-800 mb-5">
                        These are what make up the naming convention, or how FUEL should assemble the name. Any parts
                        that fail all conditions will be filtered out. All conditions for a part must pass for it to
                        pass.
                    </div>
                    {!!createNamingConventionPartError.keys.length && (
                        <div className="text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                            {createNamingConventionPartError.keys.map((errorKey: string) => (
                                <div key={errorKey}>
                                    <span className={"font-bold"}>{errorKey}:</span>{" "}
                                    {createNamingConventionPartError.errors[errorKey]}
                                </div>
                            ))}
                        </div>
                    )}
                    {!!createNamingConventionPartValueError?.keys?.length && (
                        <div className="text-red-500 text-sm italic flex-row items-center pt-4 px-2">
                            {createNamingConventionPartValueError?.keys?.map((errorKey: string) => (
                                <div key={errorKey}>
                                    <span className={"font-bold"}>{errorKey}:</span>{" "}
                                    {createNamingConventionPartValueError?.errors[errorKey]}
                                </div>
                            ))}
                        </div>
                    )}
                    {!!fields.length && (
                        <div className="flex flex-col w-full lg:w-2/3">
                            <SortableList
                                items={fields}
                                keyName={"_id"}
                                ItemComponent={NamingConventionPart}
                                onSort={(sortedItems) => replace(sortedItems)}
                                namingConvention={namingConvention}
                            />
                        </div>
                    )}
                    <div>
                        <button
                            type={"button"}
                            className={`mt-2 p-2 flex items-center gap-2 text-gray-600 hover:text-gray-800 ${
                                bottomDisabled ? "opacity-75 cursor-not-allowed" : "cursor-pointer"
                            }`}
                            disabled={bottomDisabled}
                            onClick={addPartHandler}
                        >
                            <AddIcon className={"w-6 h-6"} />{" "}
                            {bottomDisabled ? "Please save before adding parts" : "New part"}
                        </button>
                    </div>
                </CollapsibleSection>
                <div className={`gap-4 flex pb-4 justify-end`}>
                    <Button type="button" styleType="secondary" onClick={() => navigate("/admin/naming-conventions")}>
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            (Object.values(errors).filter((v) => v).length > 0 || savingNamingConvention) &&
                            (!!namingConvention?.id || !isDirty)
                        }
                        styleType="primary"
                        type="submit"
                    >
                        Save
                    </Button>
                    {namingConvention.type === "dynamic_campaign_adgroup" && (
                        <Button
                            styles={`bg-green-500 text-white ${
                                !saveClicked || savingNamingConvention || isDirty ? "opacity-50" : "hover:bg-green-600"
                            }`}
                            disabled={!saveClicked || savingNamingConvention || isDirty}
                            type="button"
                            onClick={() => publishNamingConvention({ namingConventionId: namingConvention.id })}
                        >
                            Publish
                        </Button>
                    )}
                </div>
            </div>
            <div id="modal-root"></div>
        </form>
    );
};

interface IAddPartButtonProps {
    isDragging?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled: boolean;
    buttonText: string;
    hide?: boolean;
    className?: string;
}

export const AddPartButton: React.FC<IAddPartButtonProps> = ({
    isDragging = false,
    onClick,
    disabled,
    buttonText,
    hide = false,
    className = ""
}) => {
    if (hide) return <></>;
    return (
        <div className={`pl-1 ${isDragging ? "dragging" : ""}`}>
            <button type={"button"} className={className} disabled={disabled} onClick={onClick}>
                <AddIcon className={"w-6 h-6 mr-2"} /> {buttonText}
            </button>
        </div>
    );
};
