import React, { useEffect } from "react";
import { connect } from "react-redux";
import NegativeKeywordCollActions from "../../../actions/client/NegativeKeywordCollActions";
import CampaignTable from "../../Shared/Campaign/CampaignTable";
import IEntity from "../../../interfaces/IEntity";
import { FormikActions } from "formik";
import { IAdwordsCampaignsStore, ICampaignById } from "../../../interfaces/Campaigns";
import {
    INegativeKeywordColl,
    INegativeKeywordCollCampaign,
    INegativeKeywordCollStore,
    INegativeKeywordCollCampaignStore,
    IUpdatedFormValues,
    INegativeKeywordByCampaignId,
    INegativeKeywordCollForm
} from "../../../interfaces/NegativeKeywordColl";
import { mapApiToForm } from "../../../utils/Shared";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import Constants from "../../../constants/NegativeKeywords";
import { IMappedExclusionById } from "../../../interfaces/Exclusions";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import IClient from "../../../interfaces/IClient";

export const mazdaCampaignsThatShouldGetFuelMazdaNegativeKeywords = [
    "General New Mazda Intent",
    "General New Mazda Lease"
];

interface IReduxState {
    adwordsCampaigns: IAdwordsCampaignsStore;
    negativeKeywordColls: INegativeKeywordCollStore;
    negativeKeywordCollCampaigns: INegativeKeywordCollCampaignStore;
    clients: any;
}

interface IProps {
    campaigns: ICampaignById;
    clientId: number;
    client: IClient;
    negativeKeywordColls: IEntity<INegativeKeywordColl>;
    negativeKeywordCollsLoadedAt: null | Date;
    negativeKeywordCollCampaigns: IEntity<INegativeKeywordCollCampaign>;
    negativeKeywordCollCampaignsLoadedAt: null | Date;
    fetchNegativeKeywordColl(): void;
    fetchNegativeKeywordCollCampaigns(clientId: number): void;
    handleUpdateValues(
        negativeKeywordCollCampaigns: IUpdatedFormValues,
        formikActions: FormikActions<INegativeKeywordByCampaignId>,
        clientId: number
    ): void;
}

const NegativeKeywordCampaignsContainer: React.FunctionComponent<IProps> = ({
    campaigns,
    clientId,
    client,

    negativeKeywordColls,
    negativeKeywordCollsLoadedAt,
    fetchNegativeKeywordColl,

    negativeKeywordCollCampaigns,
    negativeKeywordCollCampaignsLoadedAt,
    fetchNegativeKeywordCollCampaigns,

    handleUpdateValues
}) => {
    useEffect(() => {
        if (!negativeKeywordCollCampaignsLoadedAt) {
            fetchNegativeKeywordCollCampaigns(clientId);
        }
    }, [clientId]);

    useEffect(() => {
        if (!negativeKeywordCollsLoadedAt) {
            fetchNegativeKeywordColl();
        }
    }, []);

    const isLoading: boolean = !negativeKeywordCollCampaignsLoadedAt || !negativeKeywordCollsLoadedAt;

    const negativeKeywordFormValues: IMappedExclusionById | INegativeKeywordByCampaignId = mapApiToForm(
        campaigns,
        negativeKeywordCollCampaigns
    );

    const handleApplyDefaults = (
        values: INegativeKeywordByCampaignId,
        initialValues: INegativeKeywordByCampaignId,
        campaigns: ICampaignById,
        setFieldValue: any
    ) => {
        // The logic here was copied to the NKW page in the wizard. When updates are done for the defaults here, please
        // update them in the file mentioned below. - AK
        // @see src/utils/ApplyNegativeKeywordDefaults.ts
        const nameMatch = (regex: RegExp, text: string) => {
            return text.search(regex) > -1;
        };

        const alreadyExists = (list: INegativeKeywordCollForm[], id: number) => {
            return list.some((value) => value.collectionId === id);
        };

        const getPreviousOrNewKeywordList = (
            initialValues: INegativeKeywordByCampaignId,
            googleCampaignId: string,
            id: number
        ) => {
            const previouslySelectedValue = initialValues[googleCampaignId].find((value) => value.collectionId === id);
            return previouslySelectedValue
                ? previouslySelectedValue
                : { clientId, collectionId: id, googleCampaignId, new: true };
        };

        function getCollectionByName(
            negativeKeywordColls: IEntity<INegativeKeywordColl>,
            negativeKeywordListName: string
        ): INegativeKeywordColl | null {
            const collection = Object.values(negativeKeywordColls).filter(
                (value) => value.name.toLowerCase() === negativeKeywordListName.toLowerCase()
            );

            return collection[0] || null;
        }

        Object.entries(campaigns).forEach(([googleCampaignId, googleCampaign]) => {
            const { name } = googleCampaign;
            const currentValue = values[googleCampaignId];
            const newValues: any[] = [];
            if (nameMatch(Constants.NEW_COLLECTION_REGEX, name)) {
                if (!alreadyExists(currentValue, Constants.NEW_COLLECTION_ID)) {
                    newValues.push(
                        getPreviousOrNewKeywordList(initialValues, googleCampaignId, Constants.NEW_COLLECTION_ID)
                    );
                }
            }
            if (nameMatch(Constants.USED_COLLECTION_REGEX, name)) {
                if (!alreadyExists(currentValue, Constants.USED_COLLECTION_ID)) {
                    newValues.push(
                        getPreviousOrNewKeywordList(initialValues, googleCampaignId, Constants.USED_COLLECTION_ID)
                    );
                }
            }
            if (nameMatch(Constants.SERVICE_COLLECTION_REGEX, name)) {
                if (!alreadyExists(currentValue, Constants.SERVICE_COLLECTION_ID)) {
                    newValues.push(
                        getPreviousOrNewKeywordList(initialValues, googleCampaignId, Constants.SERVICE_COLLECTION_ID)
                    );
                }
            } else {
                // service campaigns do not get general everything else does
                if (!alreadyExists(currentValue, Constants.GENERAL_COLLECTION_ID)) {
                    newValues.push(
                        getPreviousOrNewKeywordList(initialValues, googleCampaignId, Constants.GENERAL_COLLECTION_ID)
                    );
                }
            }

            if (client.oemProgram) {
                if (client.oemProgram === "mazda") {
                    const collectionByName = getCollectionByName(negativeKeywordColls, "Fuel Mazda Negative Keywords");

                    // If the campaign we're currently iterating on matches one of the ones in the list, and there is a collection that exists by the name Fuel mazda negative keywords.
                    if (mazdaCampaignsThatShouldGetFuelMazdaNegativeKeywords.includes(name) && collectionByName) {
                        newValues.push(
                            getPreviousOrNewKeywordList(initialValues, googleCampaignId, collectionByName.id)
                        );
                    }
                } else {
                    Object.values(negativeKeywordColls)
                        .filter((collection) => collection.name.toLowerCase().includes(client.oemProgram))
                        .filter((collection) => !alreadyExists(currentValue, collection.id))
                        .forEach((collection) =>
                            newValues.push(getPreviousOrNewKeywordList(initialValues, googleCampaignId, collection.id))
                        );
                }
            }

            const nextValue = currentValue.concat(newValues);
            setFieldValue(googleCampaignId, nextValue);
        });
    };

    return (
        <div className="px-4">
            <PageTitle title="Negative Keyword Collections" />
            <CampaignTable
                columnName="Negative Keyword Lists"
                clientId={clientId}
                campaigns={campaigns}
                formValues={negativeKeywordFormValues}
                checkboxOptions={negativeKeywordColls}
                onUpdateValue={handleUpdateValues}
                onApplyDefaults={handleApplyDefaults}
                isLoading={isLoading}
            />
        </div>
    );
};

const mapStateToProps = (state: IReduxState) => {
    return {
        campaigns: state.adwordsCampaigns.entities,
        clientId: state.clients.currentClient,
        client: state.clients.entities[state.clients.currentClient],
        negativeKeywordColls: state.negativeKeywordColls.entities,
        negativeKeywordCollsLoadedAt: state.negativeKeywordColls.loadedAt,
        negativeKeywordCollCampaigns: state.negativeKeywordCollCampaigns.entities,
        negativeKeywordCollCampaignsLoadedAt: state.negativeKeywordCollCampaigns.loadedAt
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchNegativeKeywordColl: () => {
        dispatch(NegativeKeywordCollActions.requestNegativeKeywordColls());
    },
    fetchNegativeKeywordCollCampaigns: (clientId: number) => {
        dispatch(NegativeKeywordCollActions.requestNegativeKeywordCollCampaigns(clientId));
    },
    handleUpdateValues: (
        negativeKeywordCollCampaigns: IUpdatedFormValues,
        formikActions: FormikActions<INegativeKeywordByCampaignId>,
        clientId: number
    ) => {
        dispatch(
            NegativeKeywordCollActions.updateNegativeKeywordCollCampaigns(
                negativeKeywordCollCampaigns,
                formikActions,
                clientId
            )
        );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NegativeKeywordCampaignsContainer);
