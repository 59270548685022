import React from "react";
import { fieldGroupStyles, labelStyles } from "../DealerSetupStyles";
import FieldErrors from "./FieldErrors";
import { startCase } from "lodash";

interface IProps {
    label: string;
    value: { [key: string]: boolean | number[] };
    errors: any;
    groupName: string;
    onInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
}
const CheckboxFields: React.FunctionComponent<IProps> = ({ label, groupName, value, errors, onInputChange }) => {
    return (
        <div className={fieldGroupStyles}>
            <label className={labelStyles} htmlFor="changeReason">
                <span>{label}</span>
            </label>

            {Object.keys(value)
                .filter((fieldName) => typeof value[fieldName] === "boolean")
                .map((fieldName) => {
                    // Typescript isn't smart enough to that if I'm array filtering it might change
                    // the type of the array... :angry_eyes:
                    const checked: boolean = Boolean(value[fieldName]);
                    return (
                        <div key={fieldName} className="w-full py-2">
                            <label htmlFor={fieldName} className="cursor-pointer text-base">
                                <input
                                    type="checkbox"
                                    id={fieldName}
                                    name={groupName}
                                    checked={checked}
                                    className="ds-checkbox"
                                    onChange={onInputChange}
                                />
                                <span>{startCase(fieldName)}</span>
                            </label>
                        </div>
                    );
                })}
            <FieldErrors errors={errors} />
        </div>
    );
};
export default CheckboxFields;
