import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { array, boolean, number, object } from "yup";
import CheckboxFields from "../Fields/CheckboxFields";
import IServicesFields from "../../../../interfaces/DealerSetup/IServicesFields";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";

interface IProps {
    path: string;
    formValues: IFormValues;
    savePage(path: string, validatePage: () => void): void;
}

const Services = forwardRef(({ formValues, savePage, path }: IProps, ref: React.Ref<{}>) => {
    const defaults: IServicesFields = {
        fields: {
            services: {
                value: {
                    brandServices: false,
                    brandRepairs: false,
                    brandOilChange: false,
                    brandBrakes: false,
                    brandTires: false,
                    tires: false,
                    brandSpecials: false,
                    negative_keyword_lists: []
                }
            }
        }
    };

    const schema = object({
        services: object({
            value: object({
                brandServices: boolean(),
                brandRepairs: boolean(),
                brandOilChange: boolean(),
                brandBrakes: boolean(),
                brandTires: boolean(),
                tires: boolean(),
                brandSpecials: boolean(),
                negative_keyword_lists: number()
            })
        }).test("services", "Please check at least one checkbox", (obj: any) =>
            Object.values(obj).some((value) => typeof value === "boolean" && value)
        )
    });

    const [values, handleFieldChange, changePage] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, id } = event.target;
        return handleFieldChange(name, {
            ...values.fields.services.value,
            [id]: !values.fields.services.value[id]
        });
    };

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Services Campaign Setup</div>

            <div className="text-lg font-bold my-4 pb-3 border-b">Listed Services</div>

            <CheckboxFields
                label="What services does this dealer offer?"
                groupName="services"
                value={values.fields.services.value}
                errors={values.fields.services.errors}
                onInputChange={handleInputChange}
            />

            {pager()}
        </div>
    );
});

export default Services;
