import React, { useEffect, useState } from "react";
import Button from "../../../../Shared/Button";
import TrashIcon from "../../../../Shared/Icons/TrashIcon";
import SelectField from "../../../../Shared/Form/Blocks/SelectField";
import { IOption } from "../../../../../interfaces/IDisplayCampaign";
import AddIcon from "../../../../Shared/Icons/AddIcon";
import { IAdwordsCampaign, ITrackingParameterCampaignRelation } from "../../../../../interfaces/Campaigns";
import IMicrosoftCampaign from "../../../../../interfaces/IMicrosoftCampaign";
import IEntity from "../../../../../interfaces/IEntity";
import { connect } from "react-redux";
import IAppState from "../../../../../interfaces/IAppState";
import ITrackingParameter from "../../../../../interfaces/ITrackingParameter";

interface IProps {
    externalCampaigns: IEntity<IAdwordsCampaign | IMicrosoftCampaign>;
    trackedCampaigns: string[];
    trackingParameter?: ITrackingParameter;
    onCampaignValueChange: (id: any, campaign: IAdwordsCampaign | IMicrosoftCampaign) => void;
    removeCampaignFromTrackingParameters: (campaign: ITrackingParameterCampaignRelation) => void;
    allTrackedCampaigns?: IEntity<ITrackingParameterCampaignRelation>;
    errors: any;
    disabled: boolean;
}

const LinkedCampaignsContainer: React.FunctionComponent<IProps> = ({
    externalCampaigns,
    trackedCampaigns,
    trackingParameter,
    onCampaignValueChange,
    removeCampaignFromTrackingParameters,
    errors,
    allTrackedCampaigns,
    disabled
}) => {
    const [selectableCampaignOptions, setSelectableCampaignOptions] = useState([] as IOption[]);
    const [campaignsOnTheTrackingParameters, setCampaignsOnTheTrackingParameters] = useState([] as string[]);

    useEffect(() => {
        // This is just so we can dynamically load the external campaigns and have it update automatically when things change.
        setSelectableCampaignOptions(
            Object.values(externalCampaigns).map(
                (p): IOption => ({
                    value: String(p.id),
                    label: p.name
                })
            )
        );
    }, [externalCampaigns]);

    useEffect(() => {
        if (!allTrackedCampaigns) {
            return;
        }
        const trackedRelationIds = trackingParameter?.trackedCampaigns || [];

        const trackedCampaignIdsFiltered: string[] = trackedRelationIds.map((relationId) => {
            const tracked = trackingParameter?.trackedCampaigns || [];
            const campaignsAsObjects: ITrackingParameterCampaignRelation[] = tracked.map(
                (id) => allTrackedCampaigns[id]
            );

            const campaign: ITrackingParameterCampaignRelation = campaignsAsObjects.filter(
                (campaign: ITrackingParameterCampaignRelation) => relationId === campaign.id
            )[0];

            return campaign?.campaignId;
        });

        setCampaignsOnTheTrackingParameters(trackedCampaignIdsFiltered);
    }, [trackingParameter, allTrackedCampaigns]);

    if (!externalCampaigns && Object.keys(externalCampaigns).length === 0) {
        return (
            <div className="bg-red-100 text-red-600 p-4 border-red-500 border">
                <p>
                    There are no external campaigns available for this client. Please try to refresh the page, if that
                    does not load in external campaigns; please ensure that the client has campaigns in their respective
                    platform (Google/Microsoft).
                </p>
                <p>
                    If this message persists even after you have campaigns, please contact{" "}
                    <a
                        className="text-red-600 hover:text-red-700 focus:text-red-700 underline"
                        href="https://carscommerce.slack.com/archives/C0GNCBXHQ/p1697722143957119"
                        target="_blank"
                    >
                        #help-fuel-media
                    </a>
                    .
                </p>
            </div>
        );
    }

    return (
        <div className={`px-4 py-8 bg-blue-200 rounded`}>
            <div className={`text-xl`}>Link Campaigns</div>
            <div className={`w-full`}>
                {trackedCampaigns.map((campaign: string, index: number) => {
                    return (
                        <div key={index}>
                            <TrashIcon
                                onClick={() => {
                                    removeCampaignFromTrackingParameters({ campaignId: campaign, name: "" });
                                }}
                                className="w-6 h-6 text-gray-500 hover:text-red-400 cursor-pointer mt-6 mx-4 float-right flex"
                            />
                            <SelectField
                                key={index}
                                name={"campaign"}
                                value={campaign}
                                touched={false}
                                options={selectableCampaignOptions}
                                errors={errors}
                                className={`flex`}
                                required={true}
                                readOnly={campaignsOnTheTrackingParameters.includes(campaign)}
                                handleChange={({ value, label }: IOption) => {
                                    onCampaignValueChange(index, {
                                        id: value,
                                        name: label
                                    });
                                }}
                            />
                        </div>
                    );
                })}
            </div>

            <Button
                styleType={`secondary`}
                styles={`flex mt-3 -ml-1`}
                onClick={() => {
                    onCampaignValueChange(trackedCampaigns.length, {
                        id: "999999999999",
                        name: "Please select a campaign"
                    });
                }}
                disabled={disabled}
            >
                <AddIcon className="w-6 h-6" />
                <span className="ml-2">Link More Campaigns</span>
            </Button>
            {disabled && (
                <div className="text-red-600 italic mt-4">
                    Please choose a platform before attempting to select a campaign.
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    allTrackedCampaigns: state.trackingParameters.trackedCampaigns
});

export default connect(mapStateToProps, null)(LinkedCampaignsContainer);
