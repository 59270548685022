import React from "react";
import { startCase } from "lodash";
import IPreScreenFields from "../../../../interfaces/DealerSetup/IPreScreenFields";
import FieldErrors from "../Fields/FieldErrors";

const CampaignSection: React.FC<IPreScreenFields> = ({ fields }) => {
    return (
        <div className="m-4">
            <div className="font-bold mb-4">Campaigns</div>
            {fields &&
                Object.entries(fields.campaigns.value)
                    .sort()
                    .map(([key, val]) => {
                        return (
                            val && (
                                <p key={key} className="mb-4">
                                    {startCase(key)}
                                </p>
                            )
                        );
                    })}
            <FieldErrors errors={fields.campaigns.errors} />
            {fields && (
                <div className="my-4">
                    <div className="font-bold">Default CPC</div>
                    <div>{fields.defaultCpc.value}</div>
                    <FieldErrors errors={fields.defaultCpc.errors} />
                </div>
            )}
        </div>
    );
};

export default CampaignSection;
