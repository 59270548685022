import { call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import schemas from "../middleware/schemas/specialOfferTemplates";
import {
    ICreateSpecialOfferTemplate,
    IDeleteSpecialOfferTemplate,
    IFetchAllSpecialOfferTemplates,
    IFetchSpecialOfferTemplate,
    fetchAllSpecialOfferTemplateSuccess,
    fetchAllSpecialOfferTemplateFailed,
    fetchAllSpecialOffersMetaTemplateSuccess,
    fetchAllSpecialOffersMetaTemplateFailed,
    fetchSpecialOfferTemplateSuccess,
    fetchSpecialOfferTemplateFailed,
    deleteSpecialOfferTemplateSuccess,
    deleteSpecialOfferTemplateFailed,
    createSpecialOfferTemplateFailed,
    createSpecialOfferTemplateSuccess,
    IUpdateSpecialOfferTemplate,
    updateSpecialOfferTemplateFailed,
    updateSpecialOfferTemplateSuccess,
    IDetachSpecialOfferTemplateAction
} from "../actions/specialOfferTemplateActions";
import constants from "../constants/SpecialOfferTemplateConstants";
import buildUrl from "../utils/UrlUtils";
import IEntity from "../interfaces/IEntity";
import {
    ISpecialOfferMetaTemplate,
    ISpecialOfferTemplate,
    ISpecialOfferTemplateParameters
} from "../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import { IDynamicCampaign } from "../interfaces/DynamicCampaigns/IDynamicCampaign";
import moment from "moment";
import _ from "lodash";
export interface IFetchAllResponse {
    entities: {
        specialOffersTemplates: IEntity<ISpecialOfferTemplate>;
        dynamicCampaigns: IEntity<IDynamicCampaign>;
        specialOffersMetaTemplates: IEntity<ISpecialOfferMetaTemplate>;
    };
    nextPageUrl?: string;
    result: {
        data: number[];
        state?: any;
    };
    specialOfferTemplateId?: number;
}

function* fetchAllSpecialOfferTemplatesSaga({ payload }: IFetchAllSpecialOfferTemplates) {
    const url = buildUrl(`/clients/${payload.clientId}/special-offers-template`, {
        expand: {
            dynamicCampaigns: "*",
            metaTemplate: "*"
        }
    });

    try {
        const response: IFetchAllResponse = yield call(callApi, url, schemas.SPECIAL_OFFERS_TEMPLATE_LIST);

        yield put(fetchAllSpecialOfferTemplateSuccess(response));
    } catch (e) {
        console.error(e);
        yield put(fetchAllSpecialOfferTemplateFailed(1, 2, 1));
    }
}

function* fetchAllSpecialOffersMetaTemplatesSaga() {
    const url = buildUrl(`/special-offers-meta-template`, {});

    try {
        const response: IFetchAllResponse = yield call(callApi, url, schemas.SPECIAL_OFFERS_META_TEMPLATE_LIST);

        yield put(fetchAllSpecialOffersMetaTemplateSuccess(response));
    } catch (e) {
        console.error(e);
        yield put(fetchAllSpecialOffersMetaTemplateFailed(1, 2, 1));
    }
}

function* fetchSpecialOfferTemplate({ payload }: IFetchSpecialOfferTemplate) {
    const url = buildUrl(
        `/clients/${payload.clientId}/special-offers-template/${payload.templateId}?expand[dynamicCampaigns]=*`,
        {}
    );

    try {
        const response: IFetchAllResponse = yield call(callApi, url, schemas.SPECIAL_OFFERS_TEMPLATE);
        const responseTemplate: ISpecialOfferTemplate = response?.entities?.specialOffersTemplates[payload.templateId];
        const mappedParameters = responseTemplate?.parameters.map((parameter: ISpecialOfferTemplateParameters) => {
            return {
                ...(parameter.year ? { Year: parameter.year } : {}),
                ...(parameter.make ? { Make: parameter.make } : {}),
                ...(parameter.model ? { Model: parameter.model } : {}),
                ...(parameter.trim ? { Trim: parameter.trim } : {}),
                ...(parameter.downPayment ? { down_payment: parameter.downPayment } : {}),
                ...Object.keys(parameter)
                    .filter((key) => !["year", "make", "model", "trim", "downPayment"].includes(key))
                    .reduce((obj, key) => {
                        return {
                            ...obj,
                            [key]: parameter[key]
                        };
                    }, {})
            };
        });

        const newResponse = {
            ...response,
            entities: {
                specialOffersTemplates: {
                    [payload.templateId]: {
                        ...responseTemplate,
                        parameters: mappedParameters
                    }
                } as IEntity<ISpecialOfferTemplate>
            }
        } as IFetchAllResponse;

        yield put(fetchSpecialOfferTemplateSuccess(newResponse));
    } catch (e) {
        console.error(e);
        yield put(fetchSpecialOfferTemplateFailed());
    }
}

function* deleteSpecialOfferTemplate({ payload }: IDeleteSpecialOfferTemplate) {
    const url = buildUrl(`/clients/${payload.clientId}/special-offers-template/${payload.specialOfferTemplateId}`, {});

    try {
        yield call(callApi, url, {}, "DELETE");

        yield put(deleteSpecialOfferTemplateSuccess(payload.specialOfferTemplateId));
    } catch (e) {
        console.error(e);
        yield put(deleteSpecialOfferTemplateFailed(e));
    }
}

interface IFetchEntitiesResponse {
    entities: { [key: string]: { [key: string]: any } };
    nextPageUrl: string | null;
    result: number;
}

function* createSpecialOfferTemplate({
    payload: {
        formData: {
            clientId,
            startDate,
            expiresAt,
            finalUrl,
            headlines,
            descriptions,
            metaTemplateId,
            name,
            parameters,
            path,
            dynamicCampaigns,
            conditionals
        },
        formActions,
        callback
    }
}: ICreateSpecialOfferTemplate) {
    const url = `/clients/${clientId}/special-offers-template`;
    formActions.setErrors({});
    try {
        // Create the Special Offer Template first

        const response: IFetchEntitiesResponse = yield call(callApi, url, schemas.SPECIAL_OFFERS_TEMPLATE, "POST", {
            client_id: clientId,
            meta_template_id: metaTemplateId,
            name,
            final_url: finalUrl,
            parameters,
            headlines: headlines.map((headline) => ({
                value: headline.value,
                pinned_field: headline.pinnedField
            })),
            path,
            descriptions: descriptions.map((description) => ({
                value: description.value,
                pinned_field: description.pinnedField
            })),
            start_date: startDate ? startDate : moment().toDate(),
            expires_at: expiresAt ? expiresAt : moment().toDate(),
            conditionals
        });
        const specialOfferTemplate = response.entities.specialOffersTemplates[response.result];

        // Loop over the Dynamic Campaigns and attach them the new Special Offer Template
        if (dynamicCampaigns) {
            for (const campaign of dynamicCampaigns) {
                const attachUrl = `/clients/${clientId}/special-offers-template/${specialOfferTemplate.id}/dynamic-campaign/${campaign.id}/attach`;
                yield call(callApi, attachUrl, {}, "POST", {});
                //TODO: adding error response for this call.
            }
        }

        yield put(createSpecialOfferTemplateSuccess(specialOfferTemplate));

        yield call(callback, specialOfferTemplate);
    } catch (e) {
        console.error(e);
        formActions.setErrors(e.errors);
        yield put(createSpecialOfferTemplateFailed(e.errors));
    }
}

function* updateSpecialOfferTemplate({
    payload: {
        specialOfferTemplate,
        formData: {
            clientId,
            descriptions,
            startDate,
            expiresAt,
            finalUrl,
            headlines,
            metaTemplateId,
            name,
            parameters,
            path,
            dynamicCampaigns,
            conditionals
        },
        formActions,
        callback
    }
}: IUpdateSpecialOfferTemplate) {
    const url = `/clients/${clientId}/special-offers-template/${specialOfferTemplate.id}`;
    formActions.setErrors({});

    try {
        // Create the Special Offer Template first

        const response: IFetchEntitiesResponse = yield call(callApi, url, schemas.SPECIAL_OFFERS_TEMPLATE, "PUT", {
            client_id: clientId,
            meta_template_id: metaTemplateId,
            name,
            final_url: finalUrl,
            parameters,
            headlines: headlines.map((headline) => ({
                value: headline.value,
                pinned_field: headline.pinnedField
            })),
            path,
            descriptions: descriptions.map((description) => ({
                value: description.value,
                pinned_field: description.pinnedField
            })),
            start_date: startDate ? startDate : moment().toDate(),
            expires_at: expiresAt ? expiresAt : moment().toDate(),
            conditionals
        });
        const updatedSpecialOfferTemplate: ISpecialOfferTemplate =
            response.entities.specialOffersTemplates[response.result];

        // Loop over the Special Offer Template's attached Dynamic Campaigns and detach removed ones
        if (specialOfferTemplate.dynamicCampaigns) {
            for (const campaign of specialOfferTemplate.dynamicCampaigns) {
                if (
                    !_.find(dynamicCampaigns, (f) => {
                        return f.id === campaign.id;
                    })
                ) {
                    const attachUrl = `/clients/${clientId}/special-offers-template/${specialOfferTemplate.id}/dynamic-campaign/${campaign.id}/detach`;
                    yield call(callApi, attachUrl, {}, "POST", {});
                }
            }
        }

        // Loop over the Dynamic Campaigns and attach new ones
        if (dynamicCampaigns) {
            for (const campaign of dynamicCampaigns) {
                if (
                    !_.find(specialOfferTemplate.dynamicCampaigns, (f) => {
                        return f.id === campaign.id;
                    })
                ) {
                    const attachUrl = `/clients/${clientId}/special-offers-template/${specialOfferTemplate.id}/dynamic-campaign/${campaign.id}/attach`;
                    yield call(callApi, attachUrl, {}, "POST", {});
                }
            }
        }

        yield put(updateSpecialOfferTemplateSuccess(updatedSpecialOfferTemplate));

        yield call(callback, specialOfferTemplate);
    } catch (e) {
        console.error(e);
        formActions.setErrors(e.errors);
        yield put(updateSpecialOfferTemplateFailed(e.errors));
    }
}

function* detachDynamicCampaign({
    payload: { clientId, specialOfferTemplateId, dynamicCampaignId }
}: IDetachSpecialOfferTemplateAction) {
    const attachUrl = `/clients/${clientId}/special-offers-template/${specialOfferTemplateId}/dynamic-campaign/${dynamicCampaignId}/detach`;
    yield call(callApi, attachUrl, {}, "POST", {});
}

export default function* specialOfferTemplateSagas() {
    yield takeEvery(constants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES, fetchAllSpecialOfferTemplatesSaga);
    yield takeEvery(constants.FETCH_ALL_SPECIAL_OFFER_META_TEMPLATES, fetchAllSpecialOffersMetaTemplatesSaga);
    yield takeEvery(constants.FETCH_SPECIAL_OFFER_TEMPLATE, fetchSpecialOfferTemplate);
    yield takeEvery(constants.DELETE_SPECIAL_OFFER_TEMPLATES, deleteSpecialOfferTemplate);
    yield takeEvery(constants.CREATE_SPECIAL_OFFER_TEMPLATE, createSpecialOfferTemplate);
    yield takeEvery(constants.UPDATE_SPECIAL_OFFER_TEMPLATE, updateSpecialOfferTemplate);
    yield takeEvery(constants.DETACH_SPECIAL_OFFER_TEMPLATE, detachDynamicCampaign);
}
