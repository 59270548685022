import React from "react";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import withClient from "../../withClient";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import DownloadCsv from "../../Buttons/DownloadCsv";
import EditClientButton from "../../Buttons/EditClientButton";
import CollapsibleSection from "../../Shared/CollapsibleSection";
import joinTrimAndFilterArrayOfParts from "../../../utils/JoinTrimAndFilterArrayOfParts";
import IClient from "../../../interfaces/IClient";
import moment from "moment";
import EditHistoryCollapsibleSection from "../../EditHistory/EditHistoryCollapsibleSection";
import { connect } from "react-redux";
import { downloadInventoryClicked } from "../../../actions/clientActions";
import Koddi from "../../Clients/Form/Tabs/InventorySearchAds";

const FieldGroup = ({ label, value, value2 = null, message, className = "w-full" }: any) => (
    <div className={className}>
        <div className="text-xs uppercase text-gray-600">{label}</div>
        <div className="text-base">
            {!value && <i className="italic">{message}</i>}
            {value}
        </div>
        {value2 && <div className="text-base">{value2}</div>}
    </div>
);
interface IProps {
    currentClient: IClient;
    trackDownloadInventory: (clientId: number, client: IClient) => void;
    clientId: number;
    client: IClient;
}
const ClientSummary = ({ currentClient, client, clientId, trackDownloadInventory }: IProps) => {
    if (!currentClient) {
        return <FullPageLoader message="Loading Client Dashboard..." />;
    }
    const clientAddress = joinTrimAndFilterArrayOfParts(
        [
            currentClient.dealerAddress1,
            currentClient.dealerAddress2,
            currentClient.dealerCity ? currentClient.dealerCity + "," : "",
            currentClient.dealerState,
            currentClient.dealerZip
        ],
        " "
    );

    const facebookSubtitle = joinTrimAndFilterArrayOfParts([
        currentClient.facebookAccount && currentClient.facebookAccount.name,
        currentClient.facebookPage && currentClient.facebookPage.name
    ]);

    const generalInfoSubtitle = joinTrimAndFilterArrayOfParts(
        [clientAddress, currentClient.managedByUser && " - " + currentClient.managedByUser],
        " "
    );

    const adsSubtitle = joinTrimAndFilterArrayOfParts([currentClient.adwordsName, currentClient.bingName]);
    const analyticsSubtitle = joinTrimAndFilterArrayOfParts([currentClient.gaAccountName, currentClient.gaProfileName]);
    const dv360Subtitle = !currentClient.dv360AdvertiserId
        ? "No DV360 Advertiser ID associated with this client."
        : "DV360 Advertiser Information";

    const oemActive = currentClient.oemProgram && currentClient.oemGoLiveDate;
    const oemEndsAt = currentClient.oemEndDate ? currentClient.oemEndDate : "";
    const oemSubtitle = joinTrimAndFilterArrayOfParts([
        currentClient.oemProgram,
        oemActive ? "OEM Program Active" : oemEndsAt
    ]);

    const billingInvoiced = (isInvoiced: boolean): string => {
        return isInvoiced ? "DI Invoicing" : "Client Paying";
    };
    const billingSubtitle = () => {
        const totalDiBilling = [
            currentClient.googleAdsInvoiced,
            currentClient.microsoftAdsInvoiced,
            currentClient.facebookAdsInvoiced
        ].filter((invoiced) => invoiced).length;
        const totalClientBilling = [
            currentClient.googleAdsInvoiced,
            currentClient.microsoftAdsInvoiced,
            currentClient.facebookAdsInvoiced
        ].filter((invoiced) => !invoiced).length;
        return `${totalDiBilling} invoiced, ${totalClientBilling} client billing`;
    };

    return (
        <div className="text-base mx-4">
            <div>
                <PageTitle title={currentClient.name || "This client doesn't have a name"}>
                    <div className="flex gap-4">
                        {currentClient?.id && <EditClientButton clientId={currentClient.id} />}
                        {currentClient.inventoryApiKey && (
                            <DownloadCsv
                                url={`/clients/${currentClient.id}/inventory-download?refresh=true`}
                                filename={`${currentClient.name} inventory.csv`}
                                downloadText="Download inventory"
                                loadingText="Downloading inventory..."
                                onClick={() => trackDownloadInventory(clientId, client)}
                            />
                        )}
                    </div>
                </PageTitle>
            </div>
            <div className="flex flex-col w-full">
                <CollapsibleSection
                    title="General Information"
                    subTitle={generalInfoSubtitle || <i className="italic">No general information</i>}
                    defaultOpenState={false}
                >
                    <div className="flex flex-wrap w-full text-xs border-t border-gray-300 -mt-4 pt-4">
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="client address"
                            value={clientAddress}
                            message="There's no billing address for the client in Salesforce."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="salesforce id"
                            value={currentClient.salesforceId}
                            message="There's no Salesforce ID for the client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="salesforce top-level parent id"
                            value={currentClient.parentSalesforceId}
                            message="There's no Salesforce ID for the top-level parent account."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="salesforce top-level parent name"
                            value={currentClient.parentSalesforceName}
                            message="There's no Salesforce name for the top-level parent account."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Cars seller id"
                            value={currentClient.carsSellerId}
                            message="There's no CARS seller ID for the client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="inventory api key"
                            value={currentClient.inventoryApiKey}
                            message="There's no inventory api key for the client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Manager"
                            value={currentClient.managedByUser}
                            value2={`${currentClient?.pfm?.firstName || ""} ${currentClient?.pfm?.lastName || ""}`}
                            message="There's no manager for the client in Salesforce."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="FUEL Client ID"
                            value={currentClient.id}
                            message="There's no FUEL Client ID for the client."
                        />
                    </div>
                </CollapsibleSection>

                <CollapsibleSection
                    title="Google & Microsoft"
                    subTitle={
                        adsSubtitle || (
                            <i className="italic">No Google Ads or Microsoft Ads account attached to client</i>
                        )
                    }
                    defaultOpenState={false}
                >
                    <div className="flex flex-wrap w-full text-xs border-t border-gray-300 -mt-4 pt-4">
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Google Ads ID"
                            value={currentClient.adwordsId}
                            message="There's no Google Ads account associated with this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Google Ads Name"
                            value={currentClient.adwordsName}
                            message="There's no Google Ads account associated with this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Microsoft Ads ID"
                            value={currentClient.bingId}
                            message="There's no Microsoft Ads account associated with this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Microsoft Ads Name"
                            value={currentClient.bingName}
                            message="There's no Microsoft Ads account associated with this client."
                        />
                    </div>
                </CollapsibleSection>
                {client.koddiId && (
                    <CollapsibleSection
                        data-test-id="inventory-search-ads"
                        title="Inventory Search Ads"
                        defaultOpenState={false}
                        errors={!!client.errors?.displayKoddi}
                        subTitle={(client.koddiId && `Koddi ID ${client.koddiId}`) || ""}
                    >
                        <Koddi
                            data-testid={"koddi-collapsible"}
                            client={client as IClient}
                            onValueChange={() => {}}
                            onCreateAccountClick={() => {}}
                            errors={{}}
                            //hasAccount={this.state.canDisplayKoddi}
                        />
                    </CollapsibleSection>
                )}
                <CollapsibleSection
                    title="Facebook"
                    subTitle={facebookSubtitle || <i className="italic">No Facebook account attached to client</i>}
                    defaultOpenState={false}
                >
                    <div className="flex flex-wrap w-full text-xs border-t border-gray-300 -mt-4 pt-4">
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Facebook Ad Account Name"
                            value={currentClient.facebookAccount?.name}
                            message="There's no Facebook Ad Account associated with this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Facebook Ad Account ID"
                            value={currentClient.facebookAccount?.accountId}
                            message="There's no Facebook Ad Account ID associated with this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Facebook Page Name"
                            value={currentClient.facebookPage?.name}
                            message="There's no Facebook Page associated with this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="Facebook Page ID"
                            value={currentClient.facebookPage?.pageId}
                            message="There's no Facebook Page ID associated with this client."
                        />
                    </div>
                </CollapsibleSection>

                <CollapsibleSection
                    title="DV360"
                    subTitle={dv360Subtitle || <i className="italic">No DV360 Advertiser Id attached to client</i>}
                    defaultOpenState={false}
                >
                    <div className="flex flex-col w-full text-xs border-t border-gray-300 -mt-4 pt-4">
                        {currentClient.dv360AdvertiserId && (
                            <React.Fragment>
                                <FieldGroup
                                    className="w-full md:w-1/2 mt-4"
                                    label="DV360 Advertiser Id"
                                    value={currentClient.dv360AdvertiserId}
                                    message="There's no DV360 Advertiser Id associated with this client."
                                />
                            </React.Fragment>
                        )}
                    </div>
                </CollapsibleSection>

                <CollapsibleSection
                    title="Analytics"
                    subTitle={analyticsSubtitle || <i className="italic">No analytics account attached to client</i>}
                    defaultOpenState={false}
                >
                    <div className="flex flex-col w-full text-xs border-t border-gray-300 -mt-4 pt-8">
                        {currentClient.gaAccountName && (
                            <React.Fragment>
                                <FieldGroup
                                    className="w-full"
                                    label="Analytics Account ID"
                                    value={currentClient.gaAccountId}
                                    message="There's no Google Analytics account associated with this client."
                                />
                                <FieldGroup
                                    className="w-full mt-4"
                                    label="Analytics Account Name"
                                    value={currentClient.gaAccountName}
                                    message="There's no Google Analytics account associated with this client."
                                />
                            </React.Fragment>
                        )}
                        {currentClient.gaProfileName && (
                            <React.Fragment>
                                <FieldGroup
                                    className={`w-full ${currentClient.gaAccountName && "mt-4"}`}
                                    label="Analytics Profile ID"
                                    value={currentClient.gaProfileId}
                                    message="There's no Google Analytics profile associated with this client."
                                />
                                <FieldGroup
                                    className="w-full mt-4"
                                    label="Analytics Profile Name"
                                    value={currentClient.gaProfileName}
                                    message="There's no Google Analytics profile associated with this client."
                                />
                            </React.Fragment>
                        )}
                    </div>
                </CollapsibleSection>

                <CollapsibleSection
                    title="OEM Related"
                    subTitle={oemSubtitle || <i className="italic">Client not in OEM program</i>}
                    defaultOpenState={false}
                >
                    <div className="flex flex-wrap w-full text-xs border-t border-gray-300 -mt-4 pt-4">
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="oem program"
                            value={currentClient.oemProgram}
                            message="There's no OEM program for this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="oem start date"
                            value={currentClient.oemGoLiveDate}
                            message="There's no OEM program start date for this client."
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="oem end date"
                            value={currentClient.oemEndDate}
                            message={`—`}
                        />
                        <FieldGroup
                            className="w-full md:w-1/2 mt-4"
                            label="dealer code"
                            value={currentClient.dealerCode}
                            message="There's no dealer code for this client."
                        />
                    </div>
                </CollapsibleSection>

                <CollapsibleSection title="Billing" subTitle={billingSubtitle()}>
                    <div className="flex flex-col w-full text-xs border-t border-gray-300 -mt-4 pt-8">
                        <FieldGroup
                            className="w-full"
                            label="Facebook Billing"
                            value={currentClient.facebookAdsInvoiced}
                            message={billingInvoiced(currentClient.facebookAdsInvoiced)}
                        />
                        <FieldGroup
                            className="w-full mt-4"
                            label="Google Billing"
                            value={currentClient.googleAdsInvoiced}
                            message={billingInvoiced(currentClient.googleAdsInvoiced)}
                        />
                        <FieldGroup
                            className="w-full mt-4"
                            label="Bing Billing"
                            value={currentClient.microsoftAdsInvoiced}
                            message={billingInvoiced(currentClient.microsoftAdsInvoiced)}
                        />
                    </div>
                </CollapsibleSection>

                <CollapsibleSection
                    title="Edit History"
                    subTitle={`Last edit was ${moment(currentClient.updatedAt).format("MMMM Do YYYY, h:mm a")}`}
                    defaultOpenState={false}
                >
                    <hr className="-mt-4 mb-4" />
                    <EditHistoryCollapsibleSection client={currentClient} />
                </CollapsibleSection>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any, props: any) => {
    const clientId = state.clients.currentClient;
    const client = state.clients.entities[clientId];

    return {
        client,
        clientId
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        trackDownloadInventory: (clientId: number, client: IClient) => {
            dispatch(downloadInventoryClicked(clientId, client));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withClient(ClientSummary));
