import trackEvent from "../../../../utils/Analytics/trackEvent";
import { markAllAsRead } from "../../../notificationSagas";

function* MarkAllAsReadClicked(action: ReturnType<typeof markAllAsRead>) {
    yield trackEvent({
        category: "Notifications Index Page",
        action: `mark_all_as_read_clicked`,
        label: "Mark All As Read"
    });
}

export default {
    MarkAllAsReadClicked
};
