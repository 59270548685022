import React from "react";

interface IProps {
    onClick: any;
    Icon: any;
    className?: string;
    title?: string;
}

const TableActionButton = ({ onClick, Icon, className, ...props }: IProps) => {
    const includeDefaultHover = className ? !className.includes("hover:text") : true;

    return (
        <button className={`group rounded hover:bg-gray-100 ${className}`} onClick={onClick} {...props}>
            <Icon className={`w-6 h-6 inline-block ${includeDefaultHover ? "group-hover:text-gray-600" : ""}`} />
        </button>
    );
};

export default TableActionButton;
