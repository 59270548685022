import useBudgetParams, { budgetServices } from "./useBudgetParams";

type UseBudgetPageTitleReturn = string;

function useBudgetPageTitle(): UseBudgetPageTitleReturn {
    const { clientId, client, budgetType, actionType } = useBudgetParams();
    const displayType = (!!budgetType && budgetServices[budgetType]?.titleName) || "";
    const editPageTitle = `${client?.name} ${actionType === "edit" ? "update" : "create"} ${displayType} Budget`;
    if (displayType == "All Budgets") {
        return `All Budgets - ${client?.name}`;
    }
    const listPageTitle = !clientId ? `All ${displayType} Budgets` : `${client?.name} ${displayType} Budgets`;
    const detailPageTitle = `${client?.name} ${displayType} Budget Details`;
    const pageTitle = `${["edit", "create"].includes(actionType) ? editPageTitle : ""}
    ${actionType === "details" ? detailPageTitle : ""}${!actionType ? listPageTitle : ""}`;
    return pageTitle;
}

export default useBudgetPageTitle;
