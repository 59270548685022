import dynamicCampaignTemplates from "./reducers/dynamicCampaigns/templates";
import dynamicTextCampaigns from "./reducers/dynamicTextCampaigns";
import dtcTemplateConditionals from "./reducers/dynamicCampaigns/conditionals";
import dtaTemplatePartConditionals from "./reducers/dynamicAds/textTemplatePartConditionals";
import dynamicCampaignFeedData from "./reducers/dynamicCampaigns/sampleData";
import inventorySampleData from "./reducers/dynamicCampaigns/InventorySampleData";
import errors from "./reducers/errors";
import clients from "./reducers/clients";
import clientRoles from "./reducers/clientRoles";
import flashMessages from "./reducers/flashMessages";
import adwordsCampaigns from "./reducers/campaigns";
import microsoftCampaigns from "./reducers/microsoftCampaigns";
import remarketingInventoryExports from "./reducers/remarketing/inventoryExports";
import facebookAutoExports from "./reducers/remarketing/facebookAutoExports";
import facebookAutoExportLogs from "./reducers/remarketing/facebookAutoExportLogs";
import googleAutoExportLogs from "./reducers/remarketing/googleAutoExportLogs";
import googleAutoExports from "./reducers/googleAutoExports";

import salesDataFilters from "./reducers/salesMap/filters";
import salesData from "./reducers/salesMap/sales";
import adwordsBidAdjustments from "./reducers/adwordsBidAdjustments";
import locationStats from "./reducers/salesMap/locationStats";
import mapData from "./reducers/salesMap/map";

import facebookOemConversions from "./reducers/facebook/oemConversions";
import subscribeToFacebookApp from "./reducers/facebook/subscribeToFacebookApp";
import adWordsAdGroups from "./reducers/adWords/adGroups";

import currentUser from "./reducers/currentUser";
import users from "./reducers/users";
import notifications from "./reducers/notifications";

import budgets from "./reducers/budgets";
import manualBudgets from "./reducers/manualBudgets";
import carsBudgets from "./reducers/carsBudgets";
import facebookBudgets from "./reducers/facebookBudgets";
import labels from "./reducers/labels";
import automobileManufacturers from "./reducers/automobileManufacturers";
import trackingParameters from "./reducers/trackingParameters";

import dynamicCampaigns from "./reducers/dynamicCampaigns";

import inventoryModifiers from "./reducers/inventoryModifiers";
import exclusionList from "./reducers/exclusionList";
import exclusionCollCampaigns from "./reducers/exclusionCollCampaigns";
import negativeKeywordColls from "./reducers/negativeKeywordColls";
import negativeKeywordCollCampaigns from "./reducers/negativeKeywordCollCampaigns";

import placementExclusions from "./reducers/placementExclusions";
import negativeKeywords from "./reducers/negativeKeywords";
import dealerSetup from "./reducers/dealerSetup";
import OEMReports from "./reducers/OEMReports";
import dynamicCampaignStatuses from "./reducers/dynamicCampaignStatuses";
import etaTemplateBoilerplate from "./reducers/dynamicCampaigns/etaTemplateBoilerplate";
import editHistory from "./reducers/editHistory";

import sitelinkTemplates from "./reducers/sitelinkTemplates";
import specialOfferTemplates from "./reducers/specialOfferTemplateReducer";
import specialOffersMetaTemplates from "./reducers/specialOffersMetaTemplateReducer";
import authentication from "./reducers/authenticationReducer";

export const buildClientStore = {
    automobileManufacturers,
    errors,
    dynamicCampaignTemplates,
    dynamicTextCampaigns,
    dtcTemplateConditionals,
    dtaTemplatePartConditionals,
    dynamicCampaignFeedData,
    dynamicCampaigns,
    clients,
    clientRoles,
    adwordsCampaigns,
    microsoftCampaigns,
    flashMessages,
    salesDataFilters,
    salesData,
    adwordsBidAdjustments,
    mapData,
    locationStats,
    facebookOemConversions,
    subscribeToFacebookApp,
    adWordsAdGroups,
    remarketingInventoryExports,
    facebookAutoExports,
    facebookAutoExportLogs,
    googleAutoExportLogs,
    currentUser,
    users,
    budgets,
    manualBudgets,
    carsBudgets,
    facebookBudgets,
    labels,
    notifications,
    inventoryModifiers,
    exclusionList,
    exclusionCollCampaigns,
    negativeKeywordColls,
    negativeKeywordCollCampaigns,
    placementExclusions,
    negativeKeywords,
    inventorySampleData,
    dealerSetup,
    OEMReports,
    dynamicCampaignStatuses,
    googleAutoExports,
    etaTemplateBoilerplate,
    trackingParameters,
    editHistory,
    specialOfferTemplates,
    specialOffersMetaTemplates,
    sitelinkTemplates,
    authentication
};
