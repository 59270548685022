import React, { SVGProps } from "react";
import Svg from "./Svg";

const NotVisibleIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M12.982 7.6c2.504 0 4.54 2.017 4.54 4.502 0 .584-.117 1.136-.326 1.649l2.65 2.628a10.649 10.649 0 0 0 3.114-4.277 10.74 10.74 0 0 0-13.6-6.124l1.96 1.946a4.488 4.488 0 0 1 1.662-.325zM3.91 5.144L5.98 7.198l.419.414A10.598 10.598 0 0 0 3 12.102a10.73 10.73 0 0 0 9.982 6.753c1.408 0 2.749-.271 3.973-.757l.384.379 2.659 2.628 1.153-1.145L5.058 4l-1.15 1.145zm5.015 4.975l1.408 1.394a2.552 2.552 0 0 0-.071.584c0 1.492 1.216 2.7 2.721 2.7.2 0 .401-.027.588-.072l1.408 1.394a4.497 4.497 0 0 1-1.996.477c-2.503 0-4.539-2.018-4.539-4.503 0-.704.183-1.368.481-1.974zm3.911-.7l2.86 2.838.018-.142c0-1.492-1.216-2.7-2.722-2.7l-.156.005z" />
    </Svg>
);

export default NotVisibleIcon;
