import * as React from "react";
import { useState } from "react";
import { useRef } from "react";
import useOnClickOutside from "../../../../../../hooks/useOnClickOutside";
import ChevronDownIcon from "../../../../../Shared/Icons/ChevronDownIcon";
import { getDcParameterFields, getDcParameterFieldsForKeywords } from "../../../../../../utils/InventoryUtils";
import { connect, FormikContext } from "formik";
import IDynamicCampaignFormValue from "../../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";

type Props = {
    keywordTemplateRelationshipIndex: number;
};

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const InsertVariableButton = ({
    keywordTemplateRelationshipIndex,
    formik: {
        values: {
            keywordTemplateRelationships: {
                [keywordTemplateRelationshipIndex]: keywordTemplateRelationship,
                [keywordTemplateRelationshipIndex]: {
                    keywordTemplate: keywordTemplate,
                    keywordTemplate: { keywords }
                }
            }
        },
        setFieldValue
    }
}: Props & ContextProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

    const handleInsertVariable = (event: React.MouseEvent<HTMLDivElement>) => {
        setFieldValue(`keywordTemplateRelationships[${keywordTemplateRelationshipIndex}]`, {
            ...keywordTemplateRelationship,
            keywordTemplate: {
                ...keywordTemplate,
                keywords: [...keywords, `{{${event.currentTarget.textContent}}}`]
            }
        });
        setIsDropdownOpen(false);
    };

    return (
        <div>
            <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                type="button"
                className={`px-4 py-2 border-l text-gray-500 hover:text-green-600 hover:bg-gray-200`}
                data-toggle="dropdown"
                title="Insert Variable"
            >
                <ChevronDownIcon className="w-6 h-6" />
            </button>
            {isDropdownOpen && (
                <div
                    className="absolute py-2 w-1/6 bg-white rounded shadow-md z-10 overflow-auto border"
                    style={{ maxHeight: "300px" }}
                    ref={dropdownRef}
                >
                    {getDcParameterFieldsForKeywords().map((field, index) => {
                        return (
                            <div
                                key={field}
                                className="block px-4 pt-2 hover:bg-select-focus cursor-pointer text-base"
                                onClick={handleInsertVariable}
                                tabIndex={index}
                            >
                                {field}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default connect<Props, any>(InsertVariableButton);
