import React, { FunctionComponent, useEffect } from "react";
import IClient from "../../interfaces/IClient";
import { connect } from "react-redux";
import IAppState from "../../interfaces/IAppState";
import { Dispatch } from "redux";
import { fetchEditHistoryForClient } from "../../actions/editHistory";
import { getEditHistoryInOriginalOrder } from "../../reducers/editHistory";
import IActivity from "../../interfaces/EditHistory/IActivity";
import LoaderSpinner from "../Shared/Loaders/LoaderSpinner";
import moment from "moment";
import TextField from "../Shared/Form/Blocks/TextField";
import CollapsibleSection from "../Shared/CollapsibleSection";
import ChevronRightIcon from "../Shared/Icons/ChevronRightIcon";
import PencilIcon from "../Shared/Icons/PencilIcon";
import AddIcon from "../Shared/Icons/AddIcon";
import TrashIcon from "../Shared/Icons/TrashIcon";
import TimeIcon from "../Shared/Icons/TimeIcon";
import InformationIcon from "../Shared/Icons/InformationIcon";
import EditHistoryList from "./EditHistoryList";
import { CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES } from "../../constants/ClientConstants";

interface IEditHistory {
    client: IClient;
    loadClientActivities: (clientId: number, page: number) => void;
    entities: IActivity[];
    loading: boolean;
    page: number;
}

const EditHistorySection: FunctionComponent<IEditHistory> = ({
    client,
    loadClientActivities,
    entities,
    loading,
    page
}) => {
    useEffect(() => {
        if (!client || !client.id || !loading) {
            return;
        }

        loadClientActivities(client.id, page);
    }, [loadClientActivities, client.id, page]);

    if (loading) {
        return <LoaderSpinner message={`Loading ${client.name}'s edit history...`} iconDimensions="6" />;
    }

    return (
        <div className="w-full">
            <div className="flex flex-col">
                {entities.length === 0 && <i className="italic text-sm">No edit history yet...</i>}

                <EditHistoryList edits={entities} subject={client} />
            </div>
        </div>
    );
};

const mapStateToProp = (state: IAppState) => ({
    currentModelId: state.editHistory.currentModelId,
    order: state.editHistory.order,
    entities: getEditHistoryInOriginalOrder(state),
    loading: state.editHistory.loading,
    page: state.editHistory.page
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadClientActivities: (clientId: number, page: number) => {
        dispatch(fetchEditHistoryForClient(clientId, page));
    }
});

export default connect(mapStateToProp, mapDispatchToProps)(EditHistorySection);
