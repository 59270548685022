import React, { useEffect, useState } from "react";
import LoaderSpinner from "../../Shared/Loaders/LoaderSpinner";
import {
    ISpecialOfferMetaTemplate,
    ISpecialOfferTemplate,
    ISpecialOfferTemplateForm,
    ISpecialOfferTemplateParameters
} from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import Mustache from "../../../utils/Mustache";
import { Form } from "formik";
import TrashIcon from "../../Shared/Icons/TrashIcon";
import TextField from "../../Shared/Form/Blocks/TextField";
import AddIcon from "../../Shared/Icons/AddIcon";
import CollapsibleSection from "../../Shared/CollapsibleSection";
import moment from "moment";
import DatePickerField from "../../Shared/Form/Blocks/DatePickerField";
import MobilePreview from "../../AdWords/AdPreview/MobilePreview";
import Label from "../../Shared/Form/Label";
import Button from "../../Shared/Button";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import { FormikProps } from "formik/dist/types";
import { useNavigate } from "react-router-dom";
import { IDynamicCampaign } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import IEntity from "../../../interfaces/IEntity";
import Select from "react-select";
import { get, sortBy } from "lodash";
import Errors from "../../Shared/Form/Elements/Errors";
import { buildSelectOptions } from "../../../utils/DynamicCampaignUtils";
import { connect } from "react-redux";
import IAppState from "../../../interfaces/IAppState";
import { IInventoryFieldValues } from "../../../interfaces/InventoryFields";
import { requestInventorySampleData } from "../../../actions/dynamicCampaigns/dynamicCampaignActions";
import IClient from "../../../interfaces/IClient";
import CopyIcon from "../../Shared/Icons/CopyIcon";
import SpecialOfferTemplateParts from "./SpecialOfferTemplateParts";
import MultiSelectInput from "../../Shared/Form/Elements/MultiSelectInput";
import { IOption } from "../../../interfaces/IDisplayCampaign";
import { filterParametersWeCanShowBasedOnMetaTemplate } from "../../../utils/SpecialOfferTemplateUtils";

interface IProps<Value> extends FormikProps<Value> {
    loading: boolean;
    metaParameter?: ISpecialOfferMetaTemplate;
    dynamicCampaigns?: {
        entities: IEntity<IDynamicCampaign>;
        loading: boolean;
    };
    specialOfferTemplate?: ISpecialOfferTemplate;
    inventoryFieldValues: IInventoryFieldValues;
    client: IClient;
    fetchSampleData: (clientId: number) => void;
}
const parametersContainerStyles = "flex gap-4 items-center";

const SpecialOfferTemplatesForm = ({
    values,
    errors,
    handleChange,
    setFieldValue,
    loading,
    metaParameter,
    dynamicCampaigns,
    setFieldTouched,
    inventoryFieldValues,
    client,
    fetchSampleData
}: IProps<ISpecialOfferTemplateForm>) => {
    const navigate = useNavigate();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    useEffect(() => {
        setSaveButtonDisabled(errors && Object.keys(errors).length > 0);
    }, [errors]);

    useEffect(() => {
        if (get(client, "inventoryApiKey", false)) {
            fetchSampleData(client.id);
        }
    }, [client.id]);

    const addMoreParameters = () => {
        setFieldValue(
            `parameters[${values?.parameters.length || 0}]`,
            metaParameter?.parameters?.reduce(
                (parameters, parameter: string): { [x: string]: string } => ({
                    ...parameters,
                    [parameter]: ""
                }),
                {}
            )
        );
    };

    const addCampaignToList = (campaign: IDynamicCampaign) => {
        setFieldValue(`dynamicCampaigns[${values?.dynamicCampaigns?.length || 0}]`, { ...campaign });
    };

    const removeCampaignFromList = (campaign: IDynamicCampaign) => {
        setFieldValue(
            `dynamicCampaigns`,
            Object.values(values?.dynamicCampaigns || {}).filter((dynamicCampaign) => {
                return dynamicCampaign.id !== campaign.id;
            })
        );
    };

    useEffect(() => {
        if (values?.parameters.length === 0) {
            addMoreParameters();
        }
    }, [values?.parameters]);

    if (loading || dynamicCampaigns?.loading) {
        return (
            <LoaderSpinner
                message="Loading Special Offer Template"
                className="text-left mx-auto w-5/6 md:w-1/3 xl:w-1/4"
            />
        );
    }

    const renderValues = (
        templates: string[],
        parameters: ISpecialOfferTemplateParameters | ISpecialOfferTemplateParameters[]
    ): string => {
        if (!templates || templates.length === 0) {
            return "";
        }
        const valuesKey: number = Number(Object.keys(parameters)[0]);
        const parameterValues = (Array.isArray(parameters) ? parameters[valuesKey] : parameters) || {};

        return templates
            .map((template: string) => {
                try {
                    return Mustache.render(template, {
                        ...parameterValues,
                        LeasePrice: parameterValues?.Price,
                        LeaseDownPayment: parameterValues.down_payment,
                        LeaseDuration: parameterValues.Duration,
                        startDate: values?.startDate,
                        expires_at: values.expiresAt
                    });
                } catch (e) {
                    return "";
                }
            })
            .filter((template) => template)[0];
    };

    const labels = (label: string) => {
        const labelWithoutSymbols = label.replace("_", " ");
        return labelWithoutSymbols[0].toUpperCase() + labelWithoutSymbols.substr(1, labelWithoutSymbols.length - 1);
    };

    const startedAtDate = () => {
        if (values?.startDate) {
            return moment(values?.startDate).toDate();
        }

        return null;
    };

    const expiresAtDate = () => {
        if (values?.expiresAt && values?.expiresAt !== "0000-00-00 00:00:00") {
            return moment(values?.expiresAt).toDate();
        }

        return null;
    };

    const renderParameterUIErrors = (index: number, parameter: string) => {
        const uiError = errors?.parameters?.[index]?.[parameter] ? errors?.parameters?.[index]?.[parameter] : null;

        return <div>{uiError && <Errors errors={[uiError as string]} />}</div>;
    };

    const duplicateOfferRow = (index: number, parameters: ISpecialOfferTemplateParameters[]): void => {
        for (const key in parameters[index]) {
            if (parameters[index].hasOwnProperty(key)) {
                let value: string | null | undefined | number = "";
                if (key === "price") {
                    value = null;
                } else if (!["Model", "Trim"].includes(key)) {
                    value = parameters[index][key];
                }
                setFieldValue(`parameters[${values?.parameters.length || 0}][${key}]`, value);
            }
        }
    };

    return (
        <Form className="mb-10">
            <div className={"text-3xl"}>Offers</div>
            <div className={parametersContainerStyles}>
                {values?.parameters.length > 1 && <div className="w-6 h-2" />}
                {metaParameter?.parameters.map((parameter: string, index) => {
                    const width = ["Make", "Trim"].includes(parameter) ? "w-1/4" : "flex-1";
                    return (
                        <div className={width} key={"labels-" + index}>
                            {labels(parameter)}
                        </div>
                    );
                })}
            </div>
            {values?.parameters.map((parameters: ISpecialOfferTemplateParameters, index: number) => (
                <div key={index} className={parametersContainerStyles}>
                    <div className="flex w-8 h-2 ml-2 -mt-3">
                        <button type="button" onClick={() => duplicateOfferRow(index, values.parameters)}>
                            <CopyIcon className={"w-6 h-6 text-gray-600 hover:text-gray-500"} />
                        </button>
                        {values?.parameters.length > 1 && (
                            <button
                                type="button"
                                onClick={() => {
                                    setFieldValue(
                                        `parameters`,
                                        values.parameters.filter((item: any, i: number) => i !== index)
                                    );
                                }}
                            >
                                <TrashIcon className={"w-6 h-6 text-gray-600 hover:text-red-500"} />
                            </button>
                        )}
                    </div>
                    {metaParameter?.parameters.map((parameter: string, i) => {
                        const width = ["Make", "Trim"].includes(parameter) ? "w-1/4" : "flex-1";
                        const parameterErrors = errors ? errors[`parameters.${index}.${parameter}`] : [];

                        const inventoryBasedValues = sortBy(
                            buildSelectOptions(parameter, inventoryFieldValues),
                            (value) => value.value
                        ).filter((value) => value.value);

                        return (
                            <div className={width} key={i + "meta-parameters"}>
                                {Object.keys(inventoryFieldValues)
                                    .map((valueKey) => valueKey.toLowerCase())
                                    .includes(parameter.toLowerCase()) ? (
                                    <MultiSelectInput
                                        name={`parameters.${index}.${parameter}`}
                                        className="w-full text-base"
                                        isMulti={false}
                                        options={
                                            parameter === "Year" ? inventoryBasedValues.reverse() : inventoryBasedValues
                                        }
                                        placeholder="Type then press enter..."
                                        value={
                                            {
                                                value: values?.parameters?.[index]?.[parameter] || ("" as string),
                                                label: values?.parameters?.[index]?.[parameter] || ("" as string)
                                            } as IOption
                                        }
                                        ariaLabel={"Value"}
                                        onChange={(option: any) => {
                                            const { value } = option as { value: string; label: string };
                                            setFieldValue(`parameters[${index}]['${parameter}']`, value);
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        name={`parameters.${index}.${parameter}`}
                                        value={values?.parameters?.[index]?.[parameter] || ("" as string)}
                                        required={true}
                                        errors={(parameterErrors || "") as string}
                                        handleChange={(e: any) => {
                                            handleChange(e);
                                            setFieldTouched(`parameters.${index}.${parameter}`);
                                        }}
                                        placeholder={labels(parameter)}
                                    />
                                )}
                                {renderParameterUIErrors(index, parameter)}
                            </div>
                        );
                    })}
                </div>
            ))}
            <button
                type="button"
                className={`flex items-center mt-4 text-blue-600 ${values.parameters.length === 1 ? "" : "ml-12"}`}
                onClick={addMoreParameters}
            >
                <AddIcon className={"w-4 h-4"} />
                <span className="ml-2">Add</span>
            </button>
            <div className="flex flex-col mt-4">
                <CollapsibleSection
                    title="ETA Conditions"
                    subTitle={
                        "These will be turned into and applied as conditions on the ETAs, and only consist of inventory related fields."
                    }
                    defaultOpenState={true}
                >
                    <div className="flex flex-wrap w-full text-xs border-t border-gray-300 -mt-4 pt-4">
                        <div className="w-2/5 -mt-4 space-y-4 pt-4">
                            <label className={"block uppercase tracking-wider text-blue-800 text-base "}>
                                Inventory Fields that need conditionals
                            </label>
                            <MultiSelectInput
                                options={filterParametersWeCanShowBasedOnMetaTemplate(metaParameter?.parameters ?? [])}
                                onChange={(changedOptions: IOption[]) => {
                                    handleChange({
                                        name: "conditionals",
                                        target: {
                                            name: "conditionals",
                                            value: changedOptions.map((conditional) => conditional.value)
                                        }
                                    });
                                    setFieldTouched(`conditionals`);
                                }}
                                name={"conditionals"}
                                value={
                                    values.conditionals?.map((conditionalName) => ({
                                        value: conditionalName,
                                        label: conditionalName
                                    })) ?? []
                                }
                            />
                        </div>
                    </div>
                </CollapsibleSection>
            </div>
            <div className="flex flex-col mt-4">
                <CollapsibleSection
                    title="Template Information"
                    subTitle={values.name + " | Expires on " + moment(values?.expiresAt).format("LL")}
                    defaultOpenState={true}
                >
                    <div className="flex flex-wrap w-full text-xs border-t border-gray-300 -mt-4 pt-4">
                        <div className="w-2/5 -mt-4">
                            <TextField
                                name="name"
                                label="Template name"
                                value={values.name}
                                required={true}
                                errors={(errors?.name || "") as string}
                                handleChange={handleChange}
                                placeholder="Template Name"
                            />
                            <TextField
                                name={"finalUrl"}
                                label={"Final URL"}
                                value={values.finalUrl}
                                required={true}
                                errors={(errors?.finalUrl || "") as string}
                                handleChange={handleChange}
                                placeholder="https://fake.vin/..."
                            />
                            <DatePickerField
                                ariaLabel={"Start date"}
                                name={"startDate"}
                                value={startedAtDate()}
                                onChange={(field: string, value: Date) => {
                                    setFieldValue(field, value || undefined);
                                }}
                                isClearable={true}
                                placeholder={"Start date"}
                                errors={[(errors?.startDate || "") as string]}
                            />
                            <DatePickerField
                                ariaLabel={"End date"}
                                name={"expiresAt"}
                                value={expiresAtDate()}
                                onChange={(field: string, value: Date) => {
                                    setFieldValue(field, value || undefined);
                                }}
                                isClearable={false}
                                placeholder={"End date"}
                                errors={[(errors?.expiresAt || "") as string]}
                            />

                            <div className="text-base">
                                <Label label={"Attach Campaigns"} />

                                <Select
                                    name={"campaigns"}
                                    onChange={(event: any, action: any) => {
                                        if (action.action === "remove-value") {
                                            removeCampaignFromList(
                                                dynamicCampaigns?.entities[
                                                    action.removedValue.value
                                                ] as IDynamicCampaign
                                            );
                                            return;
                                        }
                                        addCampaignToList(
                                            dynamicCampaigns?.entities[action.option.value] as IDynamicCampaign
                                        );
                                    }}
                                    isMulti={true}
                                    value={Object.values(values?.dynamicCampaigns || {}).map((campaign) => ({
                                        value: campaign.id,
                                        label: campaign.name
                                    }))}
                                    options={Object.values(dynamicCampaigns?.entities || {}).map((campaign) => ({
                                        value: campaign.id,
                                        label: campaign.name
                                    }))}
                                />
                            </div>
                        </div>

                        <div className="w-3/5 flex flex-col justify-center items-center">
                            <MobilePreview
                                h1={renderValues(values.h1, values.parameters)}
                                h2={renderValues(values.h2, values.parameters)}
                                h3={renderValues(values.h3, values.parameters)}
                                d1={renderValues(values.description1, values.parameters)}
                                d2={renderValues(values.description2, values.parameters)}
                                finalUrl={renderValues([values.finalUrl], values.parameters)}
                                displayUrl={renderValues(values.path, values.parameters)}
                            />
                        </div>
                    </div>
                </CollapsibleSection>

                <CollapsibleSection title="Template Information" subTitle={""} defaultOpenState={true}>
                    <SpecialOfferTemplateParts
                        values={values}
                        errors={errors}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                </CollapsibleSection>
                <div className="w-full flex justify-end my-4 gap-4">
                    <Button type="button" onClick={() => navigate("../")} disabled={false} styleType={"secondary"}>
                        Cancel
                    </Button>
                    <Button
                        disabled={saveButtonDisabled}
                        type={"submit"}
                        styleType={"primary"}
                        styles="flex gap-2 items-center"
                    >
                        {loading ? <RefreshIcon className="w-6 h-6 rotate-fast" /> : ""}
                        Sav{loading ? "ing" : "e"}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export { SpecialOfferTemplatesForm };

const mapStateToProps = (state: IAppState) => ({
    inventoryFieldValues: state.inventorySampleData.entities.inventoryFieldValues,
    client: state.clients.entities[state.clients.currentClient]
});
const mapDispatchToProps = (dispatch: any) => ({
    fetchSampleData: (clientId: number) => {
        dispatch(requestInventorySampleData(clientId));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(SpecialOfferTemplatesForm);
