import React from "react";
import IDynamicCampaignFormValue from "../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import IEntity from "../../../interfaces/IEntity";
import { FormikActions } from "formik";
import Form from "./Form";
import { ISpecialOfferTemplateAttachedToDynamicCampaignPayload } from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
export const defaultValues: IDynamicCampaignFormValue = {
    name: "",
    adwordsCampaignId: null,
    microsoftCampaignId: null,
    refreshValue: 4,
    refreshPeriod: "hours",
    primaryKeyField: "VIN",
    maxCpc: 6.0,
    urls: [],
    useDiInventoryFeed: true,
    adRotation: "OPTIMIZE",
    refreshType: "inventory_update",
    enableAdwordsSitelinks: 0,
    inventoryGroupFields: ["Model"],
    inventoryGroupComparator: "GREATER_THAN",
    inventoryGroupCount: 0,
    inventoryGroupFilterField: null,
    inventoryGroupFilterComparator: null,
    inventoryGroupFilterValue: null,
    inventoryFilters: [],
    adGroupBidModifiers: [],
    expandedTextAdTemplates: [],
    specialOfferTemplates: [],
    keywordTemplateRelationships: [],
    keywordBidModifiers: [
        {
            amount: 0.2,
            exact: true,
            value: null,
            broad: false,
            modifiedBroad: false,
            phrase: false,
            modifier: "increase-bid-by-currency",
            comparator: null,
            new: true,
            dirty: true
        },
        {
            amount: 0.2,
            exact: false,
            value: null,
            broad: false,
            modifiedBroad: true,
            phrase: false,
            modifier: "decrease-bid-by-currency",
            comparator: null,
            new: true,
            dirty: true
        },
        {
            amount: 0.15,
            value: "{{ExtColorGeneric}}",
            exact: true,
            broad: true,
            modifiedBroad: true,
            phrase: true,
            modifier: "increase-bid-by-currency",
            comparator: "LIKE",
            new: true,
            dirty: true
        },
        {
            amount: 0.35,
            value: "{{Year}}",
            exact: true,
            broad: true,
            modifiedBroad: true,
            phrase: true,
            modifier: "increase-bid-by-currency",
            comparator: "LIKE",
            new: true,
            dirty: true
        },
        {
            amount: 0.25,
            value: "{{Trim}}",
            exact: true,
            broad: true,
            modifiedBroad: true,
            phrase: true,
            modifier: "increase-bid-by-currency",
            comparator: "LIKE",
            new: true,
            dirty: true
        }
    ]
};

interface IProps {
    googleCampaigns: IEntity<any>;
    microsoftCampaigns: IEntity<any>;
    onSave: (
        dynamicCampaign: IDynamicCampaignFormValue,
        clientId: number,
        actions: FormikActions<IDynamicCampaignFormValue>,
        timeToSubmitMetric: number,
        sotsToDetach?: ISpecialOfferTemplateAttachedToDynamicCampaignPayload[]
    ) => void;
    client: any;
    clientId: number;
    onPushCampaign: (
        clientId: number,
        dynamicCampaignId: number,
        formActions: Pick<FormikActions<IDynamicCampaignFormValue>, "setErrors" | "setStatus" | "setSubmitting">
    ) => void;
}

const New: React.FC<IProps> = (props) => {
    return (
        <div className="px-4 text-base">
            <Form dynamicCampaign={defaultValues} {...props} />
        </div>
    );
};

export default New;
