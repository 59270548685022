import React, { useEffect, useState } from "react";
import RefreshIcon from "../Shared/Icons/RefreshIcon";
import Button, { PRIMARY_BUTTON } from "../Shared/Button";
import { useLocation } from "react-router";

export const fieldGroupStyles = "flex flex-wrap mb-4 w-full";
export const labelStyles = "block uppercase tracking-wider text-gray-800 font-bold mb-2";

interface IProps {
    authenticate: (code: string) => void;
    errors: string[] | string;
}

const env = (envValue: string, defaultValue: string) => process.env[envValue] || defaultValue;

const LoginForm: React.FunctionComponent<IProps> = ({ authenticate, errors }) => {
    const params = new URLSearchParams(useLocation().search);
    const searchQueryParam = Object.fromEntries(params.entries());
    const [code, setCode] = useState((searchQueryParam?.code || "") as string);

    const uri =
        env("REACT_APP_AUTH0_URL", "") +
        "/authorize?response_type=code&client_id=" +
        env("REACT_APP_AUTH0_FUEL_CLIENT_ID", "") +
        "&redirect_uri=" +
        env("REACT_APP_AUTH0_FUEL_REDIRECT_URI", "https://fuel.dealerinspire.com/login") +
        "&scope=" +
        env("REACT_APP_AUTH0_SCOPES", "openid profile email");
    if (!code) {
        window.location.replace(uri);
    }
    useEffect(() => {
        if (!code) {
            return;
        }

        authenticate(code);
    }, [code, authenticate]);

    if (!code) {
        return (
            <div className="mt-4">
                <div className={"flex items-center justify-center"}>
                    <a href={uri} className="underline">
                        Click here if you are not redirected automatically.
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div>
            {errors && errors.length > 0 && (
                <div className="border border-red-600 bg-red-100 py-2 px-4 rounded text-red-600">{errors}</div>
            )}
            <div className="mt-4">
                If you're not redirected after 5 seconds, please
                <a className="ml-1 underline" href="/login">
                    click here
                </a>
            </div>

            <div className="flex items-center justify-between mt-4">
                <div>Please wait...</div>
                <Button disabled={true} type="submit" styleType={PRIMARY_BUTTON} styles="flex gap-2 items-center">
                    <RefreshIcon className="w-6 h-6 rotate-fast" />
                    Authenticating
                </Button>
            </div>
        </div>
    );
};

export default LoginForm;
