import { AnyAction, combineReducers } from "redux";
import { isEmpty } from "lodash";
import * as Constants from "../../constants/InventoryExportsConstants";
import * as facebookAutoActions from "../../actions/remarketing/facebookAutoExportActions";
import { IClient } from "../../interfaces/IClient";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";

export type RemaketingInventoryExportType = {
    id: number;
    inventorySource: "DI" | "CARS";
    currency: "CAD" | "USD";
    mileageUnit: "MI" | "KM";
    name: string;
};

const buildInventoryExport = (client: Partial<IClient>) => {
    return {
        inventorySource: "DI",
        currency: "USD",
        mileageUnit: "MI",
        name: client.carsSellerId ? `Dlr-${client.carsSellerId}` : "", // cars naming convention
        ignoredImages: client.carsSellerId ? [] : ["notfound.jpg"],
        fuelInventoryId: null,
        facebookAutoActions: null,
        conditions: [],
        errors: {}
    };
};

const savingReducer = (state: any = [], action: AnyAction) => {
    switch (action.type) {
        case Constants.REQUEST_UPDATE_INVENTORY_EXPORT: {
            const id: any = action.inventoryExport.id as any;
            const idx = state.indexOf(id);
            if (idx === -1) {
                return [...state, id];
            }

            return state;
        }
        case Constants.SAVING_COMPLETE: {
            let idx = state.indexOf(action.exportId);
            if (idx === -1) {
                return state;
            }
            return [...state.slice(0, idx), ...state.slice(idx + 1)];
        }
        default:
            return state;
    }
};
const itemReducer = (state: any, action: AnyAction) => {
    switch (action.type) {
        case facebookAutoActions.LOAD_FB_AUTO_EXPORT: {
            const id = action.facebookAutoExport.id;
            if (state.facebookAutoExports.includes(id)) {
                return state;
            }
            return {
                ...state,
                facebookAutoExports: [...state.facebookAutoExports, id]
            };
        }
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const entitiesReducer = (state: any = {}, action: AnyAction) => {
    switch (action.type) {
        case Constants.LOAD_INVENTORY_EXPORT:
            return { ...state, [action.inventoryExport.id]: action.inventoryExport };
        case Constants.LOAD_INVENTORY_EXPORTS:
            return { ...state, ...action.response.entities.remarketingInventoryExports };
        case Constants.REMOVE_INVENTORY_EXPORT:
            delete state[action.exportId];
            return { ...state };
        case Constants.REQUEST_UPDATE_INVENTORY_EXPORT: {
            const inventoryExportId = action.inventoryExport.id;
            return { ...state, [inventoryExportId]: itemReducer(state[inventoryExportId], action) };
        }
        case facebookAutoActions.LOAD_FB_AUTO_EXPORT: {
            const inventoryExportId = action.facebookAutoExport.remarketingInventoryExportId;
            return { ...state, [inventoryExportId]: itemReducer(state[inventoryExportId], action) };
        }
        case Constants.CREATE_INVENTORY_EXPORT_SUCCESS:
        case Constants.UPDATE_INVENTORY_EXPORT_SUCCESS:
            return { ...state, [action.payload.inventoryExport.id]: action.payload.inventoryExport };

        case Constants.SAVING_COMPLETE: {
            const exportId = action.exportId;
            return { ...state, [exportId]: itemReducer(state[exportId], action) };
        }
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const loading = (state = false, action: AnyAction) => {
    switch (action.type) {
        case Constants.REQUEST_LOAD_INVENTORY_EXPORTS:
            return true;
        case Constants.REQUEST_INVENTORY_EXPORTS_FAILURE:
        case Constants.LOAD_INVENTORY_EXPORTS:
            return false;
        default:
            return state;
    }
};

const loadedAt: any = (state = [], action: AnyAction) => {
    switch (action.type) {
        case Constants.LOAD_INVENTORY_EXPORTS:
            return new Date();
        case RESET_CLIENT_STATE:
            return null;
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    loading,
    loadedAt,
    saving: savingReducer
});

export { buildInventoryExport };
