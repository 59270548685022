function getEntityByKeyValue<T extends { [k: string]: any }>(
    entities: { [key: string]: T } | null,
    key?: string,
    value?: any
): null | T {
    if (!key || !value || !entities) {
        return null;
    }
    let selectedEntity = null;
    for (const id of Object.keys(entities)) {
        if (entities[id] && entities[id][key] === value) {
            return (selectedEntity = entities[id]);
        }
    }
    return selectedEntity;
}

export default getEntityByKeyValue;
