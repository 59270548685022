import React, { useRef } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import ToggleSwitch from "../Form/ToggleSwitch";

const Dropdown: React.FC<{
    onApplyFilters: (filterOptions: any[]) => void;
    onCancelFilters: () => void;
    onToggleOption: (index: number) => void;
    filterOptions: any[];
    onClickOutside: () => void;
}> = ({ onApplyFilters, onCancelFilters, onToggleOption, filterOptions, onClickOutside }) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => onClickOutside());

    const optionList = filterOptions.map((option, index) => (
        <ToggleSwitch
            key={option.label}
            label={option.label}
            value={option.selected}
            onToggle={() => onToggleOption(index)}
        />
    ));

    return (
        <div className="filter-dropdown rounded overflow-hidden shadow-lg py-4 px-4" ref={ref}>
            <div className="select-options">{optionList}</div>
            <div className="actions justify-between pt-8 ">
                <button className="btn warn" onClick={onCancelFilters}>
                    Cancel
                </button>
                <button className="btn primary" onClick={() => onApplyFilters(filterOptions)}>
                    Apply
                </button>
            </div>
        </div>
    );
};

export default Dropdown;
