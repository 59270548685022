import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import IGoogleCampaign from "../../../interfaces/IGoogleCampaign";
import IClient from "../../../interfaces/IClient";
import { CurrencyCell, Table } from "../../Shared/Table";
import GoogleColorIcon from "../../Shared/Icons/GoogleColoredIcon";
import MicrosoftColorIcon from "../../Shared/Icons/MicrosoftColorIcon";
import VideoIcon from "../../Shared/Icons/VideoIcon";
import DisplayIcon from "../../Shared/Icons/DisplayIcon";
import SearchIcon from "../../Shared/Icons/SearchIcon";
import QuestionMarkIcon from "../../Shared/Icons/QuestionMarkIcon";
import PauseIcon from "../../Shared/Icons/PauseIcon";
import PlayIcon from "../../Shared/Icons/PlayIcon";
import GraphIcon from "../../Shared/Icons/GraphIcon";
import ShoppingIcon from "../../Shared/Icons/ShoppingIcon";
import WindowIcon from "../../Shared/Icons/WindowIcon";
import SearchInput from "../../Shared/SearchInput";
import CloseIcon from "../../Shared/Icons/CloseIcon";

const campaignsPageColumns = [
    {
        id: "type",
        accessor: "type",
        Header: "",
        filterable: false,
        sortable: false,
        resizable: false,
        width: 100,
        headerClassName: "flex justify-end",
        Cell({ original: { type, status, platform } }: { original: IGoogleCampaign }) {
            const CampaignType = () => {
                switch (type) {
                    case "VIDEO":
                        return <VideoIcon className="w-6 h-6 text-blue-700" />;
                    case "DISPLAY":
                    case "Display":
                        return <DisplayIcon className="w-6 h-6 text-blue-700" />;
                    case "SEARCH":
                    case "Search":
                        return <SearchIcon className="w-6 h-6 text-blue-700" />;
                    case "PERFORMANCE_MAX":
                        return <GraphIcon className={"w-6 h-6 text-blue-700"} />;
                    case "SHOPPING":
                        return <ShoppingIcon className={"w-6 h-6 text-blue-700"} />;
                    case "DISCOVERY":
                        return <WindowIcon className={"w-6 h-6 text-blue-700"} />;
                    default:
                        return <QuestionMarkIcon className="w-6 h-6 text-blue-700" />;
                }
            };

            const StatusIcon = () =>
                status === "PAUSED" ? (
                    <PauseIcon className="w-6 h-6 text-gray-600" />
                ) : (
                    <PlayIcon className="w-6 h-6 text-green-600" />
                );
            const PlatformIcon = () =>
                platform === "google" ? (
                    <GoogleColorIcon className={"w-6 h-6"} />
                ) : (
                    <MicrosoftColorIcon className={"w-6 h-6"} />
                );

            return (
                <div className="flex gap-1">
                    <CampaignType />
                    <StatusIcon />
                    <PlatformIcon />
                </div>
            );
        }
    },
    {
        id: "name",
        accessor: "name",
        Header: "Name",
        filterable: false,
        headerClassName: "",
        Cell({ original }: { original: IGoogleCampaign }) {
            return (
                <div className={"flex flex-col my-2"}>
                    <div
                        className={
                            (original.status === "PAUSED" ? "text-gray-800" : "text-green-900") + " tracking-tightest"
                        }
                    >
                        {original.name}
                    </div>
                    <div className={"text-xs flex gap-2"}>
                        {original.labels.map((label) => {
                            return (
                                <div
                                    key={label.name + "-label"}
                                    className={LabelColor(label.name) + " px-1 py-.5 tracking-wide rounded"}
                                >
                                    {label.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    },
    {
        accessor: "id",
        Header: "Campaign ID",
        maxWidth: 150,
        Cell({ original }: { original: IGoogleCampaign }) {
            return (
                <code>
                    <pre>{original.id}</pre>
                </code>
            );
        }
    },
    {
        accessor: "allocatedBudget",
        Header: "Allocated Budget",
        headerClassName: "flex justify-end",
        maxWidth: 175,
        Cell({ original }: { original: IGoogleCampaign }) {
            return <CurrencyCell value={original.allocatedBudget} />;
        }
    }
];
const LabelColor = (label: string): string => {
    if (label === "FUEL Monthly Budget Depleted") {
        return "bg-red-700 text-white";
    }

    if (label === "FUEL Event Budget Completed") {
        return "bg-green-700 text-white";
    }

    if (label === "Paused") {
        return "bg-yellow-700 text-white";
    }

    return "bg-gray-700 text-white";
};

const ListView: React.FC<{
    campaigns: IGoogleCampaign[];
    isInternalUser: boolean;
}> = ({ campaigns, isInternalUser }) => {
    const searchQueryParamName = "campaign_search";
    const params = new URLSearchParams(useLocation().search);
    const searchValue = params.get(searchQueryParamName) ?? "";
    const [campaignsDisplayed, setCampaignsDisplayed] = useState(campaigns);
    const [search, setSearch] = useState<string>(searchValue);

    useEffect(() => {
        setCampaignsDisplayed(
            campaigns.filter((campaign) => {
                if (campaign.id === search) {
                    return true;
                }

                if (campaign.name.toLowerCase().includes(search)) {
                    return true;
                }

                return campaign.labels.filter((label) => label.name.toLowerCase().includes(search)).length > 0;
            })
        );
    }, [search, campaigns]);

    return (
        <div className="w-full">
            <div>
                <SearchInput
                    url={window.location.pathname}
                    onChange={(value) => setSearch(value)}
                    useSearchHistory={true}
                    queryParamName={searchQueryParamName}
                    InputComponent={(inputProps: any) => (
                        <div className="relative flex-grow m-4">
                            <div className="absolute left-0 my-2 mx-3">
                                <SearchIcon className="text-blue-500 w-6 h-6" />
                            </div>
                            <input
                                type="text"
                                value={search}
                                placeholder="Search for a campaign by name or label"
                                autoFocus={true}
                                disabled={false}
                                className="rounded border border-gray-300 py-2 pl-10 w-full"
                                {...inputProps}
                            />
                            {search && (
                                <div className={"absolute right-0 top-0 my-2 mx-3"}>
                                    <button onClick={() => inputProps.clearInput()}>
                                        <CloseIcon className="text-red-600 w-6 h-6" />
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                />
            </div>

            <Table
                data={campaignsDisplayed}
                columns={campaignsPageColumns}
                loadingTable={false}
                loadingMessage={"Loading Tracking Parameters"}
            />
            {campaigns.length === 0 && (
                <div className="w-full p-4 shadow bg-white rounded italic ">
                    This client has no campaigns, if you think they should click the refresh button in the top right, or
                    verify their account in Google Ads.
                </div>
            )}
        </div>
    );
};

const Routing: React.FC<{
    campaigns: IGoogleCampaign[];
    isInternalUser: boolean;
}> = ({ campaigns, isInternalUser }) => {
    return (
        <Routes>
            <Route element={<ListView campaigns={campaigns} isInternalUser={isInternalUser} />} path="/" />
        </Routes>
    );
};

const CampaignList: React.FC<{
    client: IClient;
    campaigns: IGoogleCampaign[];
    fetchCampaigns: (clientId: number, refresh: boolean) => void;
    isInternalUser: boolean;
    loading: boolean;
}> = ({ campaigns, isInternalUser, loading }) => {
    if (loading) {
        return <FullPageLoader message="Loading Campaigns..." />;
    }

    return (
        <div>
            <Routing campaigns={campaigns} isInternalUser={isInternalUser} />
        </div>
    );
};

export default CampaignList;
