import React, { SVGProps } from "react";
import Svg from "./Svg";

const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M8.822 20.046l-.842-.83a.521.521 0 0 1-.169-.383.52.52 0 0 1 .169-.381l6.62-6.527-6.62-6.527a.521.521 0 0 1-.169-.382.52.52 0 0 1 .169-.382l.842-.83a.536.536 0 0 1 .775 0l7.85 7.739c.112.11.168.238.168.382a.52.52 0 0 1-.169.381l-7.85 7.74a.536.536 0 0 1-.774 0z" />
    </Svg>
);

export default ChevronRightIcon;
