import { useSelector } from "react-redux";
import BudgetConstants from "../../constants/budgets/BudgetConstants";
import IAppState from "../../interfaces/IAppState";
import { useLocation, useMatch } from "react-router-dom";
import { isUserReadOnly } from "../../reducers/currentUser";
import IClient from "../../interfaces/IClient";
import { Schema } from "normalizr";
import BUDGET_SCHEMAS from "../../middleware/schemas/budgets";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";

export type useBudgetParamsReturn = {
    isReadOnly: boolean;
    isOemReports: boolean;
    client: IClient;
    clientId: number | null;
    currentUser: any;
    newBudgetPath: string;
    clients: any;
    budgetType: string;
    actionType: string;
    budgetId: number | null;
    budgetServices: IBudgetTypes;
};
export type BUDGET_TYPE = keyof IBudgetTypes;

export interface IBudgetTypes {
    [key: string]: { id: any; titleName: string; entitiesKey: string; schema: Schema<any> };
}

export const budgetServices: IBudgetTypes = {
    adwords: {
        id: BudgetConstants.BUDGET_SERVICE_ID_ADWORDS,
        titleName: "Google",
        entitiesKey: "budgets",
        schema: CLIENT_SCHEMAS.BUDGETS.ADWORDS_BUDGETS_ARRAY
    },
    microsoft: {
        id: BudgetConstants.BUDGET_SERVICE_ID_MICROSOFT,
        titleName: "Microsoft",
        entitiesKey: "budgets",
        schema: CLIENT_SCHEMAS.BUDGETS.ADWORDS_BUDGETS_ARRAY
    },
    facebook: {
        id: null,
        titleName: "Facebook",
        entitiesKey: "facebookBudgets",
        schema: BUDGET_SCHEMAS.FACEBOOK_BUDGETS_ARRAY
    }, //readonly budget
    koddi: {
        id: null,
        titleName: "Koddi",
        entitiesKey: "koddiBudgets",
        schema: BUDGET_SCHEMAS.KODDI_BUDGETS_ARRAY
    }, //readonly budget
    cars: {
        id: null,
        titleName: "CARS",
        entitiesKey: "carsBudgets",
        schema: BUDGET_SCHEMAS.CARS_BUDGETS_ARRAY
    }, //readonly budget
    dsp: { id: null, titleName: "Programmatic Display", entitiesKey: "", schema: BUDGET_SCHEMAS.MANUAL_BUDGETS }, //manual budget (only available in FUEL)
    "dsp-fdaf": {
        id: null,
        titleName: "Programmatic Display - FDAF",
        entitiesKey: "",
        schema: BUDGET_SCHEMAS.MANUAL_BUDGETS
    },
    social: { id: null, titleName: "Social", entitiesKey: "", schema: BUDGET_SCHEMAS.MANUAL_BUDGETS },
    "cars-premium-display": {
        id: null,
        titleName: "Cars Premium Display",
        entitiesKey: "",
        schema: BUDGET_SCHEMAS.MANUAL_BUDGETS
    },
    "programmatic-video": {
        id: null,
        titleName: "Cars Premium Video",
        entitiesKey: "programmaticVideoBudgets",
        schema: BUDGET_SCHEMAS.PROGRAMMATIC_DISPLAY_BUDGETS
    },
    all: {
        id: null,
        titleName: "All Budgets",
        entitiesKey: "allBudgets",
        schema: BUDGET_SCHEMAS.ALL_BUDGETS
    }
};

function useBudgetParams(): useBudgetParamsReturn {
    const location = useLocation();
    //route params are needed to get budget type and action (edit, create or detail)
    //since we access budgets data from multiple locations, we have to try matching on all of them.
    const {
        params: { clientId }
    } = useMatch("/client/:clientId/*") ?? { params: {} };
    const {
        params: { budgetType: allClientsBudgetType }
    } = useMatch("/budgets/:budgetType") ?? { params: {} };
    const {
        params: { budgetType: clientBudgetType }
    } = useMatch("/client/:clientId/budgets/:budgetType/*") ?? { params: {} };
    const {
        params: { budgetType: oemReportsBudgetType }
    } = useMatch("/admin/oem-reports/:budgetType/*") ?? { params: {} };
    const {
        params: { actionType: editActionType, budgetId }
    } = useMatch("/client/:clientId/budgets/:budgetType/:budgetId/:actionType") ?? { params: {} };
    const {
        params: { actionType: createActionType }
    } = useMatch("/client/:clientId/budgets/:budgetType/:actionType") ?? { params: {} };
    const isOemReports = location.pathname.split("/").includes("oem-reports");

    //redux state selectors
    const { clients, currentUser } = useSelector((state: IAppState) => state);

    return {
        isReadOnly: isUserReadOnly(currentUser),
        isOemReports,
        client: clients.entities[clients.currentClient],
        newBudgetPath: "create",
        clientId: Number(clientId),
        currentUser,
        actionType: createActionType || editActionType || "",
        budgetType: allClientsBudgetType || clientBudgetType || oemReportsBudgetType || "adwords",
        clients,
        budgetId: Number(budgetId),
        budgetServices
    };
}

export default useBudgetParams;
