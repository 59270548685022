import React, { Fragment, useEffect, FunctionComponent } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import ListContainer from "./ListContainer";
import NewContainer from "./NewContainer";
import EditContainer from "./EditContainer";
import { fetchAllRemarketingInventoryExports } from "../../../actions/remarketing/inventoryExportsActions";
import FullPageLoader from "../../Shared/Loaders/FullPageLoader";
import IAppState from "../../../interfaces/IAppState";
import { IInventoryExport } from "../../../interfaces/IRemarketing";
import IEntity from "../../../interfaces/IEntity";
import ExportLogList from "./Logs/ExportLogList";

interface IProps {
    inventoryExports: IEntity<IInventoryExport>;
    clientId: number;
    loadedAt: null | Date;
    fetchAll(clientId: number): void;
}

const Loading = () => {
    return (
        <Fragment>
            <FullPageLoader message={"Loading The Remarketing Exports..."} />
        </Fragment>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        clientId: state.clients.currentClient,
        inventoryExports: state.remarketingInventoryExports.entities,
        loadedAt: state.remarketingInventoryExports.loadedAt
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAll: (clientId: number) => {
            return dispatch(fetchAllRemarketingInventoryExports(clientId));
        }
    };
};

const RemarketingPage: FunctionComponent<IProps> = ({ clientId, loadedAt, fetchAll, inventoryExports }) => {
    useEffect(() => {
        fetchAll(clientId);
    }, [clientId]);
    return loadedAt === null ? (
        <Loading />
    ) : (
        <div className="px-4">
            <Routes>
                <Route element={<ListContainer inventoryExports={inventoryExports} />} path="/" />
                <Route element={<NewContainer />} path="new" />
                <Route element={<EditContainer />} path=":id/edit" />
                <Route
                    element={<ExportLogList exportType={"facebook"} clientId={clientId} />}
                    path=":exportId/logs/facebook"
                />
                <Route
                    element={<ExportLogList exportType={"google"} clientId={clientId} />}
                    path=":exportId/logs/google"
                />
            </Routes>
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(RemarketingPage);
