import React, { forwardRef } from "react";
import { useDealerSetupForm, usePager, changeFcaCampaignType, hasFcaCampaignType } from "../DealerSetupUtils";
import { array, object, string } from "yup";
import IDynamicCampaign, { IDynamicCampaignField } from "../../../../interfaces/DealerSetup/IDynamicCampaign";
import { capitalize, get, isEqual } from "lodash";
import FieldErrors from "../Fields/FieldErrors";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";
import TrashIcon from "../../../Shared/Icons/TrashIcon";
import IClient from "../../../../interfaces/IClient";

interface IProps {
    path: string;
    formValues: IFormValues;
    savePage(path: string, validatePage: () => void): void;
    client: IClient;
}

const Dynamic = forwardRef(({ formValues, savePage, path, client }: IProps, ref: React.Ref<unknown>) => {
    const clientManufacturers = client.automobileManufacturers ? client.automobileManufacturers : [];
    const brands: string[] = clientManufacturers.map((f) => f.name);
    const defaults: IDynamicCampaign = {
        fields: {
            campaigns: [...getDefaultBrandCampaigns(brands, client), ...getDefaultCampaigns(client)]
        }
    };

    const schema = object({
        campaigns: array().of(string().required("The Name field is required.").min(1))
    });

    const [values, handleFieldChange, changePage]: [IDynamicCampaign, any, any] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleChange = (index: number, value: any) => {
        handleFieldChange(`campaigns[${index}]`, {
            name: value,
            negative_keyword_lists: values.fields.campaigns[index].value.negative_keyword_lists
        });
    };

    const handleAdd = () => {
        handleFieldChange(
            `campaigns[${values.fields.campaigns.length}]`,
            {
                value: {
                    name: getCampaignValue(""),
                    negative_keyword_lists: []
                }
            },
            true
        );
    };

    const handleApplyManufacturerDefaults = () => {
        handleFieldChange(`campaigns`, defaults.fields.campaigns, true);
    };

    const handleDelete = (index: number) => {
        values.fields.campaigns.splice(index, 1);
        handleFieldChange(`campaigns`, values.fields.campaigns, true);
    };

    const deletable = values.fields.campaigns.length > 1;
    const usingDefaults = isEqual(
        values.fields.campaigns.map(({ value }: any) => value),
        defaults.fields.campaigns
    );

    return (
        <div className="p-4">
            <div className="text-xl font-bold pb-3 border-b">Dynamic Campaign Setup</div>
            {values.fields.campaigns.map(({ value }: any, index: number) => {
                return (
                    <div key={index} className="w-full py-3 flex flex-col">
                        <label className="w-2/3 py-3 font-bold text-base">Name*</label>
                        <div className="w-full flex">
                            <input
                                value={value.name}
                                type="text"
                                placeholder={capitalize(value.name)}
                                onChange={(event) => handleChange(index, event.target.value)}
                                className={`text-base border flex-1 py-2 px-4 rounded ${
                                    deletable ? "rounded-r-none" : ""
                                }`}
                                data-testid="name"
                            />
                            {deletable && (
                                <button
                                    className="flex-none bg-gray-400 text-gray-600 hover:text-gray-800 rounded rounded-l-none px-6"
                                    onClick={() => handleDelete(index)}
                                >
                                    <TrashIcon className="w-6 h-6 text-lg" />
                                </button>
                            )}
                        </div>
                        {/* {client.oemProgram === "fca" && ( */}
                        <div className="w-full flex mt-1">
                            <div className="mr-3">
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        checked={hasFcaCampaignType(
                                            index,
                                            "always",
                                            values.fields.campaigns[index].value.name
                                        )}
                                        id="always"
                                        name={`fcaCampaignType[${index}]`}
                                        className="text-sm pr-3 mb-1"
                                        onChange={(event) =>
                                            handleFieldChange(`campaigns[${index}]`, {
                                                name: changeFcaCampaignType(
                                                    event.target.id,
                                                    values.fields.campaigns[index].value.name
                                                ),
                                                negative_keyword_lists:
                                                    values.fields.campaigns[index].value.negative_keyword_lists
                                            })
                                        }
                                    />
                                    <span className="text-sm ml-3">Always</span>
                                </label>
                            </div>
                            <div>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        checked={hasFcaCampaignType(
                                            index,
                                            "boost",
                                            values.fields.campaigns[index].value.name
                                        )}
                                        id="boost"
                                        name={`fcaCampaignType[${index}]`}
                                        className="text-sm pr-3"
                                        onChange={(event) =>
                                            handleFieldChange(`campaigns[${index}]`, {
                                                name: changeFcaCampaignType(
                                                    event.target.id,
                                                    values.fields.campaigns[index].value.name
                                                ),
                                                negative_keyword_lists:
                                                    values.fields.campaigns[index].value.negative_keyword_lists
                                            })
                                        }
                                    />
                                    <span className="text-sm ml-3">Boost</span>
                                </label>
                            </div>
                        </div>
                        {/* )} */}
                        <FieldErrors errors={values.fields.campaigns[index].errors} />
                    </div>
                );
            })}
            <button className="text-base font-bold text-blue-500 hover:text-blue-600 my-4 pb-3" onClick={handleAdd}>
                + Add Campaign
            </button>
            {usingDefaults || (
                <button
                    className="text-base font-bold text-blue-500 hover:text-blue-600 my-4 pb-3 float-right"
                    onClick={handleApplyManufacturerDefaults}
                >
                    Use Manufacturer Defaults
                </button>
            )}
            {pager()}
        </div>
    );
});

const defaultBrandCampaigns: { [key: string]: string[] } = {
    default: ["Dynamic New", "Dynamic New Intent", "Dynamic New Lease"],
    "Mercedes-Benz": ["Dynamic New Class", "Dynamic New Model"],
    BMW: ["Dynamic New Series", "Dynamic New Trim"]
};

const defaultCampaigns: { [key: string]: string[] } = {
    default: ["Dynamic Used Individual", "Dynamic Used Results"]
};

const getDefaultBrandCampaigns = (brands: string[], client: IClient): IDynamicCampaignField[] => {
    if (!brands.length) {
        return defaultBrandCampaigns.default.map((campaign) => ({
            value: {
                name: getCampaignValue(campaign),
                negative_keyword_lists: []
            }
        }));
    }

    return brands.reduce((brandCampaigns: any, brand: string): IDynamicCampaignField[] => {
        if (brand in defaultBrandCampaigns) {
            return brandCampaigns.concat(
                defaultBrandCampaigns[brand].map(
                    (campaign: string): IDynamicCampaignField => ({
                        value: {
                            name: getCampaignValue(campaign),
                            negative_keyword_lists: []
                        }
                    })
                )
            );
        }

        return brandCampaigns.concat(
            defaultBrandCampaigns.default.map((campaign: string) => ({
                value: {
                    name: getCampaignValue(campaign, brand, client),
                    negative_keyword_lists: []
                }
            }))
        );
    }, []);
};

const getDefaultCampaigns = (client: IClient): IDynamicCampaignField[] => {
    return defaultCampaigns.default.map((campaign) => ({
        value: {
            name: getCampaignValue(campaign, null, client),
            negative_keyword_lists: []
        }
    }));
};

const getCampaignValue = (campaignName: string, brand: string | null = null, client: IClient | null = null): string => {
    if (brand) {
        campaignName = `${campaignName} ${brand}`;
    }

    if (client && client.oemProgram === "fca") {
        campaignName = getFcaCampaignName(campaignName);
    }

    return campaignName;
};

const getFcaCampaignName = (campaignName: string): string => {
    switch (true) {
        case campaignName.toLowerCase().includes("new"):
            return campaignName + "_new";
        case campaignName.toLowerCase().includes("used"):
            return campaignName + "_used";
        case campaignName.toLowerCase().includes("cpo"):
            return campaignName + "_cpo";
        case campaignName.toLowerCase().includes("parts"):
        case campaignName.toLowerCase().includes("service"):
            return campaignName + "_ptsvc";
        default:
            return campaignName;
    }
};

export default Dynamic;
