import React, { SVGProps } from "react";
import Svg from "./Svg";

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M16.908 18.51L12.5 14.103 8.092 18.51a1.133 1.133 0 0 1-1.602-1.602l4.407-4.408L6.49 8.092A1.133 1.133 0 0 1 8.092 6.49l4.408 4.407 4.408-4.407a1.133 1.133 0 1 1 1.602 1.602L14.103 12.5l4.407 4.408a1.133 1.133 0 0 1-1.602 1.602z" />
    </Svg>
);

export default CloseIcon;
