import { schema } from "normalizr";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useApi, { IUseApiHandler } from "../useApi";
import { IConditionable } from "../useConditionals";
import { mapKeys, snakeCase, orderBy } from "lodash";
import {
    FieldErrorsImpl,
    useFieldArray,
    useForm,
    UseFormRegister,
    UseFormWatch,
    UseFormReset,
    UseFormSetValue,
    UseFormClearErrors,
    UseFormGetValues,
    UseFieldArrayReturn
} from "react-hook-form";
interface IOrderable {
    order: number;
}
interface IModel {
    id?: number;
}
export interface INamingConventionPartValue extends IConditionable, IOrderable, IModel {
    value: string;
    mustAllConditionsPass: boolean;
}
export interface INamingConventionPart extends IConditionable, IOrderable, IModel {
    values: INamingConventionPartValue[];
    mustAllConditionsPass: boolean;
    namingConventionId: number;
    name: string;
}
export interface INamingConvention extends IConditionable, IModel {
    name: string;
    glue: string;
    type: string;
    namingConventionParts: INamingConventionPart[];
}
export interface INamingConventionFormValues {
    id?: number;
    name: string;
    glue: string;
    type: string;
    enabled: boolean;
    namingConventionParts?: any[];
}
export interface INamingConventionPartForm {
    id: number;
    namingConventionId: number;
    name: string;
    order: number;
    mustAllConditionsPass: boolean;
    createdAt: string;
    updatedAt: string;
    values: [];
}
export type IuseNamingConventionsReturn = {
    namingConventionPartsFieldArray: UseFieldArrayReturn<INamingConventionFormValues, "namingConventionParts", "id">;
    register: UseFormRegister<INamingConventionFormValues>;
    watch: UseFormWatch<INamingConventionFormValues>;
    reset: UseFormReset<INamingConventionFormValues>;
    setValue: UseFormSetValue<INamingConventionFormValues>;
    clearErrors: UseFormClearErrors<INamingConventionFormValues>;
    getValues: UseFormGetValues<INamingConventionFormValues>;
    errors: Partial<FieldErrorsImpl<INamingConventionFormValues>>;
    values: INamingConventionFormValues;
    dirtyFields: Partial<
        Readonly<{
            id?: boolean | undefined;
            name?: boolean | undefined;
            glue?: boolean | undefined;
            type?: boolean | undefined;
            enabled?: boolean | undefined;
            namingConventionParts?: any[] | undefined;
        }>
    >;
    isDirty: boolean;
    loadingNamingConventions: boolean;
    deletingNamingConventions: boolean;
    duplicatingNamingConvention: boolean;
    updatingBottom: boolean;
    namingConventions: INamingConvention[];
    namingConvention: INamingConvention;
    createNamingConventionPartError: any;
    createNamingConventionPartValueError: any;
    saveNamingConventionError: any;
    savingNamingConvention: boolean;
    savingParts: boolean;
    formSubmitHandler: (formValues: INamingConventionFormValues) => void;
    fetchNamingConventions: IUseApiHandler;
    deleteNamingConvention: IUseApiHandler;
    duplicateNamingConventionHandler: (id: number, newName: string) => void;
    deleteNamingConventionPart: IUseApiHandler;
    deleteNamingConventionPartValue: IUseApiHandler;
    fetchNamingConvention: IUseApiHandler;
    publishNamingConvention: IUseApiHandler;
};

const namingConventionSchema = { data: [new schema.Entity("namingConventions")] };
export default function useNamingConventions(): IuseNamingConventionsReturn {
    const navigate = useNavigate();
    const { namingConventionId } = useParams();
    const [formValues, setFormValues] = useState<INamingConventionFormValues | undefined>();
    const [updatingBottom, setUpdatingBottom] = useState<boolean>(false);
    const [publishing, setPublishing] = useState<boolean>(false);
    const [duplicateParams, setDuplicateParams] = useState<any>({
        newName: "",
        id: null
    });

    const {
        //list
        arrayData: namingConventions,
        request: fetchNamingConventions,
        loading: loadingNamingConventions,
        error: fetchSingleBudgetError
    } = useApi("/naming-convention", {
        schema: namingConventionSchema,
        expand: {
            conditionals: "*",
            "namingConventionParts.values": "*",
            "namingConventionParts.conditionals": "*",
            "namingConventionParts.values.conditionals": "*"
        },
        entityKey: "namingConventions"
    });
    //create naming Convention
    const {
        response: createNamingConventionResponse,
        loading: creatingNamingConvention,
        error: createNamingConventionError
    } = useApi(`/naming-convention`, {
        method: "POST",
        startOn: !namingConventionId && !!formValues, //will auto fire when clicking save...
        body: formValues, //carrying latest form values.
        schema: {},
        onStart: () => setUpdatingBottom(true),
        onFail: () => {
            setUpdatingBottom(false);
            setFormValues(undefined);
        }
    });

    //fetch single naming convention
    const {
        request: fetchNamingConvention,
        response: namingConvention,
        loading: loadingNamingConvention,
        error: namingConventionError
    } = useApi(`/naming-convention/:namingConventionId`, {
        clearOnStart: false,
        urlParams: {
            namingConventionId: namingConventionId || {}
        },
        schema: {},
        expand: {
            conditionals: "*",
            "namingConventionParts.values": "*",
            "namingConventionParts.conditionals": "*",
            "namingConventionParts.values.conditionals": "*"
        },
        fetchOnInit: !!namingConventionId,
        onFinish: () => setUpdatingBottom(false)
    });
    //delete single
    const {
        request: deleteNamingConvention,
        loading: deletingNamingConventions,
        error: deleteNamingConventionError
    } = useApi("/naming-convention/:id", {
        method: "DELETE",
        schema: {},
        onFinish: fetchNamingConventions
    });
    //update naming Convention
    const {
        response: updateNamingConventionResponse,
        loading: savingNamingConvention,
        error: saveNamingConventionError
    } = useApi(`/naming-convention/${namingConventionId}`, {
        method: "PUT",
        startOn: !!namingConventionId && !!formValues, //will auto fire when clicking save...
        body: formValues, //carrying latest form values.
        schema: {},
        onStart: () => setUpdatingBottom(true)
    });
    //duplicate naming convention
    const { loading: duplicatingNamingConvention, error: duplicateNamingConventionError } = useApi(
        `/naming-convention/${duplicateParams.id}/duplicate?name=${duplicateParams.newName}`,
        {
            useBuildUrl: false,
            schema: {},
            startOn: !!duplicateParams.id && !!duplicateParams.newName,
            onSuccess: () => setDuplicateParams({}),
            onFinish: fetchNamingConventions
        }
    );

    //create part
    const {
        request: createNamingConventionPart,
        loading: creatingNamingConventionPart,
        error: createNamingConventionPartError
    } = useApi("/naming-convention-part", {
        method: "POST",
        schema: {}
    });

    //update naming convention part
    const {
        request: updateNamingConventionPart,
        loading: updatingNamingConventionPart,
        error: updateNamingConventionPartError
    } = useApi("/naming-convention-part/:namingConventionPartId", {
        method: "PUT",
        schema: {}
    });

    //create naming convention part value
    const {
        request: createNamingConventionPartValue,
        loading: creatingNamingConventionPartValue,
        error: createNamingConventionPartValueError
    } = useApi("/naming-convention-part-value", {
        method: "POST",
        schema: {}
    });

    //update naming convention part value
    const {
        request: updateNamingConventionPartValue,
        loading: updatingNamingConventionPartValue,
        error: updateNamingConventionPartValueError
    } = useApi("/naming-convention-part-value/:namingConventionPartValueId", {
        method: "PUT",
        schema: {}
    });

    //delete naming convention part
    const {
        request: deleteNamingConventionPart,
        response: deleteNamingConventionPartResponse,
        loading: deletingNamingConventionPart,
        error: deleteNamingConventionPartError
    } = useApi("/naming-convention-part/:namingConventionPartId", {
        method: "DELETE",
        schema: {},
        onStart: () => setUpdatingBottom(true),
        onFinish: () => fetchNamingConvention({ namingConventionId })
    });
    //delete naming convention part value
    const {
        request: deleteNamingConventionPartValue,
        loading: deletingNamingConventionPartValue,
        error: deleteNamingConventionPartValueError
    } = useApi("/naming-convention-part-value/:namingConventionPartValueId", {
        method: "DELETE",
        schema: {},
        onStart: () => setUpdatingBottom(true),
        onFinish: () => fetchNamingConvention({ namingConventionId })
    });

    // publish naming Convention
    const { request: publishNamingConvention } = useApi(`/naming-convention/:namingConventionId/publish`, {
        method: "POST",
        schema: {},
        onStart: () => setPublishing(true),
        onFinish: () => fetchNamingConvention({ namingConventionId })
    });

    useEffect(() => {
        (createNamingConventionResponse?.result || updateNamingConventionResponse?.result) && updateBottom();
    }, [createNamingConventionResponse?.result, updateNamingConventionResponse?.result]); //always updates bottom after top

    function sortedPartsAndValues(namingConvention: any) {
        if (!namingConvention) return {};
        // Create a new object instead of mutating the original object
        return {
            ...namingConvention,
            namingConventionParts: namingConvention.namingConventionParts
                .map((part: any) => ({
                    ...part,
                    values: orderBy(part.values, ["order"], ["asc"])
                }))
                .sort((a: any, b: any) => a.order - b.order)
        };
    }

    const sortedNamingConvention = useMemo(
        () => sortedPartsAndValues(namingConvention?.result),
        [namingConvention?.result]
    );

    const {
        register,
        watch,
        reset,
        control: controlConventionForm,
        setValue,
        clearErrors,
        getValues,
        formState: { errors, dirtyFields, isDirty }
    } = useForm<INamingConventionFormValues>({
        defaultValues: sortedNamingConvention || {},
        mode: "all"
    });
    //register top part inputs.
    const values = getValues();

    const namingConventionPartsFieldArray = useFieldArray({
        control: controlConventionForm,
        shouldUnregister: true,
        name: "namingConventionParts",
        keyName: "_id"
    });
    const updateBottom = async () => {
        setUpdatingBottom(true);
        const namingConventionId =
            createNamingConventionResponse?.result?.id || updateNamingConventionResponse?.result?.id;
        const formParts = [...(formValues?.namingConventionParts || [])];
        const formPartValues = formParts
            .map((part, index) => {
                return part.values.map((partValue: INamingConventionPartValue, valueIndex: number) => {
                    return {
                        ...partValue
                    };
                });
            })
            .flat(1);

        // We need to filter out any parts that aren't present in dirtyFields
        let partValuesToUpdate = [];
        let partValuesToCreate = [];

        let partsToUpdate = [];
        let partsToCreate = [];

        for (const partIndex in formParts) {
            const partHasDirtyFields = dirtyFields?.namingConventionParts?.[partIndex];
            const part = formParts[partIndex];

            if (!part?.id) {
                partsToCreate.push(part);
                continue;
            }

            if (!partHasDirtyFields) continue;

            if (partHasDirtyFields?.name || partHasDirtyFields?.order || partHasDirtyFields?.mustAllConditionsPass) {
                // We don't always want to update the part.
                partsToUpdate.push(part);
            }

            for (const valueIndex in formParts[partIndex].values) {
                const partValueHasDirtyFields = dirtyFields?.namingConventionParts?.[partIndex]?.values?.[valueIndex];
                const value = formParts[partIndex].values[valueIndex];

                if (isNaN(Number(value?.id))) {
                    partValuesToCreate.push(value);
                    continue;
                }

                if (!partValueHasDirtyFields) continue;

                if (
                    partValueHasDirtyFields?.value ||
                    partValueHasDirtyFields?.order ||
                    partValueHasDirtyFields?.mustAllConditionsPass
                ) {
                    // We don't always want to update the part.
                    partValuesToUpdate.push(value);
                }
            }
        }

        try {
            partsToCreate &&
                (await createNamingConventionPart(
                    partsToCreate.map((namingConventionPart) => ({
                        body: {
                            ...mapKeys({ ...namingConventionPart, namingConventionId }, (_, key) => snakeCase(key))
                        }
                    }))
                ));
            partsToUpdate &&
                (await updateNamingConventionPart(
                    partsToUpdate.map(({ id, ...namingConventionPart }) => ({
                        namingConventionPartId: id,
                        body: {
                            ...mapKeys(namingConventionPart, (_, key) => snakeCase(key))
                        }
                    }))
                ));
            partValuesToCreate &&
                (await createNamingConventionPartValue(
                    partValuesToCreate.map((partValue) => ({
                        body: {
                            ...mapKeys(partValue, (_, key) => snakeCase(key))
                        }
                    }))
                ));
            partValuesToUpdate &&
                (await updateNamingConventionPartValue(
                    partValuesToUpdate.map((partValue) => ({
                        namingConventionPartValueId: partValue?.id,
                        body: {
                            ...mapKeys(partValue, (_, key) => snakeCase(key))
                        }
                    }))
                ));

            if (createNamingConventionResponse?.result?.id) {
                navigate("../");
                return;
            }
        } catch (error) {
        } finally {
            await fetchNamingConvention({ namingConventionId });
            setFormValues(undefined);
        }
    };

    const formSubmitHandler = (formValues: INamingConventionFormValues) => setFormValues({ ...formValues }); //always sets a new object.

    const duplicateNamingConventionHandler = (id: number, newName: string) => setDuplicateParams({ id, newName });

    const mergedCreateUpdateError = {
        ...saveNamingConventionError?.error?.errors,
        ...createNamingConventionError?.error?.errors
    };

    return {
        namingConventionPartsFieldArray,
        register,
        watch,
        reset,
        setValue,
        clearErrors,
        getValues,
        errors,
        dirtyFields,
        isDirty,
        values,
        loadingNamingConventions: loadingNamingConventions || (loadingNamingConvention && !updatingBottom),
        deletingNamingConventions,
        duplicatingNamingConvention,
        namingConventions,
        namingConvention: sortedNamingConvention,
        createNamingConventionPartError: {
            //TODO: We need an useApiError hook to handle this in a clean way...
            keys: Object.keys({ ...createNamingConventionPartError?.error?.errors }) || [],
            errors: createNamingConventionPartError?.error?.errors
        },
        createNamingConventionPartValueError: {
            keys: Object.keys({ ...createNamingConventionPartValueError?.error?.errors }) || [],
            errors: createNamingConventionPartValueError?.error?.errors
        },
        saveNamingConventionError: {
            keys: Object.keys(mergedCreateUpdateError) || [],
            errors: mergedCreateUpdateError
        },
        savingNamingConvention: savingNamingConvention || creatingNamingConvention || updatingBottom || publishing,
        updatingBottom,
        savingParts:
            creatingNamingConventionPart ||
            creatingNamingConventionPartValue ||
            updatingNamingConventionPart ||
            updatingNamingConventionPartValue ||
            deletingNamingConventionPartValue ||
            deletingNamingConventionPart,
        formSubmitHandler,
        fetchNamingConventions,
        fetchNamingConvention,
        deleteNamingConvention,
        duplicateNamingConventionHandler,
        deleteNamingConventionPart,
        deleteNamingConventionPartValue,
        publishNamingConvention
    };
}
