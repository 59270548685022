import React, { Fragment } from "react";
import ChangeLogDetail from "./ChangeLogDetail";
import ChangeLogPreview from "./ChangeLogPreview";

const ChangeLog = ({ log }: any) => {
    return (
        <Fragment>
            <ChangeLogPreview log={log} />
            {log.changes.length > 0 && (
                <ChangeLogDetail
                    changes={log.changes}
                    changeRequestLink={log.changeRequestLink}
                    updatedBy={log.updatedBy}
                />
            )}
        </Fragment>
    );
};
export default ChangeLog;
