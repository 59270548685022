import React, { useState } from "react";
import GeneratorInput from "./GeneratorInput";
import { generatePreview } from "../../../../../utils/DynamicCampaignUtils";
import { IKeywordOptions } from "../../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import Button, { PRIMARY_BUTTON, SECONDARY_BUTTON } from "../../../../Shared/Button";
import AddIcon from "../../../../Shared/Icons/AddIcon";
import SettingsIcon from "../../../../Shared/Icons/SettingsIcon";

interface IGeneratorInput {
    value: string;
}

const initialState: IKeywordOptions = {
    broad: false,
    phrase: true,
    exact: true
};

const checkboxLabelStyles = "mr-6 cursor-pointer";

const Generator: React.FunctionComponent = () => {
    const [keywordType, setKeywordType] = useState<IKeywordOptions>(initialState);
    const [generatorInputs, setGeneratorInputs] = useState<IGeneratorInput[]>([{ value: "" }]);
    const [keywordPreview, setKeywordPreview] = useState("");

    const handleSelectParam = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        const { textContent } = event.currentTarget;
        setGeneratorInputs((currentInputs) => {
            return currentInputs.map((input: IGeneratorInput, i) => {
                if (i === index) {
                    input.value += `{{${textContent}}}`;
                }
                return input;
            });
        });
    };

    const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setKeywordType((currentType: IKeywordOptions) => ({
            ...currentType,
            [value]: !currentType[value]
        }));
    };

    const handleWordInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.currentTarget;
        setGeneratorInputs((currentInputs) =>
            currentInputs.map((input: IGeneratorInput, i) => {
                if (i === index) {
                    input.value = value;
                }
                return input;
            })
        );
    };

    const handleGenerateKeywordPreview = (inputs: IGeneratorInput[], keywordTypes: IKeywordOptions) => {
        for (const element of inputs) {
            if (element.value === "") {
                continue;
            }
        }
        const preview = generatePreview(keywordTypes, inputs, keywordPreview);
        setKeywordPreview(preview);
    };

    const handleAddWordInput = () => {
        setGeneratorInputs((currentInputs) => {
            return [...currentInputs, { value: "" }];
        });
    };

    const handleRemoveInput = (index: number) => {
        setGeneratorInputs((currentInputs) => currentInputs.filter((input, i) => i !== index));
    };

    const handleClearGenerated = () => {
        setKeywordPreview("");
    };

    return (
        <div className="flex flex-row">
            <div className="w-1/2 mr-4">
                <div className="flex flex-row">
                    <label className={checkboxLabelStyles}>
                        <input
                            onChange={handleCheckboxClick}
                            type="checkbox"
                            name="keyword-type"
                            value="broad"
                            className="mr-2"
                            checked={keywordType.broad}
                        />
                        <span className="mx-2">Broad</span>
                    </label>
                    <label className={checkboxLabelStyles}>
                        <input
                            onChange={handleCheckboxClick}
                            type="checkbox"
                            name="keyword-type"
                            value="phrase"
                            className="mr-2"
                            checked={keywordType.phrase}
                        />
                        <span className="mx-2">Phrase</span>
                    </label>
                    <label className={checkboxLabelStyles}>
                        <input
                            onChange={handleCheckboxClick}
                            type="checkbox"
                            name="keyword-type"
                            value="exact"
                            checked={keywordType.exact}
                        />
                        <span className="mx-2">Exact</span>
                    </label>
                </div>

                <div className="mt-4">Words</div>
                {generatorInputs.map((input, index) => {
                    return (
                        <GeneratorInput
                            key={index}
                            value={input.value}
                            index={index}
                            onWordInputChange={handleWordInputChange}
                            onRemoveInput={handleRemoveInput}
                            onSelectParam={handleSelectParam}
                        />
                    );
                })}

                <Button onClick={handleAddWordInput} type="button" styleType={PRIMARY_BUTTON} styles="mt-4">
                    <div className="flex items-center">
                        <AddIcon className="w-6 h-6" />
                        <span className="ml-2">Add</span>
                    </div>
                </Button>
            </div>

            <div className="w-1/2 ml-4">
                <pre className="rounded border border-gray-400 bg-gray-200" style={{ minHeight: "200px" }}>
                    {keywordPreview}
                </pre>
                <div className="my-4 flex flex-wrap items-center">
                    <Button onClick={handleClearGenerated} styleType={SECONDARY_BUTTON} type="button">
                        Clear Generated Keywords
                    </Button>
                    <Button
                        onClick={() => handleGenerateKeywordPreview(generatorInputs, keywordType)}
                        type="button"
                        styles={"bg-teal-500 text-white hover:bg-teal-600 ml-4"}
                    >
                        <div className="flex items-center">
                            <SettingsIcon className="w-6 h-6" />
                            <span className="ml-2">Generate</span>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Generator;
