import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import DownloadCsv from "../../Buttons/DownloadCsv";
import AddIcon from "../../Shared/Icons/AddIcon";
import Button, { baseStyles, primaryClasses } from "../../Shared/Button";
import { useDispatch } from "react-redux";
import IClient from "../../../interfaces/IClient";
import { downloadCsvClicked } from "../../../actions/budgets/budgetActions";
import useBudgetParams from "../../../hooks/budgets/useBudgetParams";
import useTrackingBudgetEvents from "../../../hooks/budgets/useTrackBudgetEvents";
import useBudgetPageTitle from "../../../hooks/budgets/usePageTitle";
import { Tooltip } from "../../Shared/Table";
import useToggle from "../../../hooks/useToggle";
interface IProps {
    displayTitle?: boolean;
    children: React.ReactNode | React.ReactNode[];
    trackDownloadCsv?: (clientId: number, client: IClient) => void;
}

const BudgetLayout: React.FunctionComponent<IProps> = ({ children, displayTitle = true }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { newBudgetPath, budgetType, clientId, actionType, client, isReadOnly, isOemReports } = useBudgetParams();
    const trackingEvents = useTrackingBudgetEvents();
    const clientContextRegex = /client\/\d+\//gi;
    const hideCSVDownload = !clientContextRegex.test(window.location.pathname);
    const dateFormat = new Date().toLocaleString();
    const showBackButton: boolean = ["create", "edit", "details"].includes(actionType);
    const showCreateButton: boolean = !actionType;
    const canCreate: boolean =
        !isReadOnly &&
        !!clientId &&
        ["adwords", "microsoft", "dsp", "social", "cars-premium-display", "all"].includes(budgetType) &&
        showCreateButton;

    const trackDownloadCsv = () => {
        if (!clientId || !client) return;
        dispatch(downloadCsvClicked(clientId, client));
        trackingEvents.downloadCsv(downloadCsvClicked(clientId, client));
    };

    const pageTitle = useBudgetPageTitle();
    const newBudgetBtnRef = useRef();
    const [showTooltip, toggleTooltip] = useToggle(false);

    const handleNewBudgetClick = () => {
        if (isOemReports) {
            const newBudgetPath: string = `/client/${client.id}/budgets/${budgetType}/create`;
            navigate(newBudgetPath);
            return;
        }
        navigate(newBudgetPath);
    };

    return (
        <div className="px-4">
            <PageTitle title={pageTitle}>
                <div className="flex items-center">
                    {hideCSVDownload && (
                        <DownloadCsv
                            url={"/budget-dashboard-csv"}
                            filename={`fuel-budget-dashboard-${dateFormat}.csv`}
                            onClick={trackDownloadCsv}
                        />
                    )}

                    {(canCreate || isOemReports) && (
                        <div ref={newBudgetBtnRef as any} onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
                            <Button
                                styleType="primary"
                                styles={`${baseStyles} ${primaryClasses} ml-5 flex items-center text-white`}
                                onClick={handleNewBudgetClick}
                                disabled={!client}
                            >
                                <AddIcon className="h-6 w-6" />
                                <span className="ml-2">New Budget</span>
                            </Button>
                        </div>
                    )}

                    {showBackButton && (
                        <Link
                            className="py-2 px-4 bg-blue-500 rounded text-white"
                            to={["details", "edit"].includes(actionType) ? `../..` : "../"}
                        >
                            Back to budgets
                        </Link>
                    )}

                    <Tooltip reference={newBudgetBtnRef as any} shouldShow={showTooltip && !client}>
                        Select a client to create a budget
                    </Tooltip>
                </div>
            </PageTitle>
            {children}
        </div>
    );
};

export default BudgetLayout;
