import React from "react";

interface IProps {
    items: Array<{ title: string }>;
}

const tableHeaderStyles = "flex bg-blue-500 border-solid border-gray-500 w-full py-2 px-4";

const TableHeader: React.FunctionComponent<IProps> = ({ items }) => (
    <div className={tableHeaderStyles}>
        {items.map(({ title }) => (
            <div key={title} className="w-1/4">
                <div className="text-white m-0">{title}</div>
            </div>
        ))}
    </div>
);

export default TableHeader;
