import trackEvent from "../../../../utils/Analytics/trackEvent";
import { saveForm } from "../../../../actions/DealerSetupActions";

function* saveFormEvent(action: ReturnType<typeof saveForm>) {
    yield trackEvent({
        category: "Dealer Setup",
        action: `dealer_setup_save_form_${action.payload}`,
        label: "Save Form",
        value: action.meta.timeToSubmitMetric
    });
}

export default {
    saveFormEvent
};
