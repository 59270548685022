import React from "react";
import IEntity from "../../../interfaces/IEntity";
import { IExclusionsList } from "../../../interfaces/Exclusions";
import { INegativeKeywordColl } from "../../../interfaces/NegativeKeywordColl";
import CampaignCheckbox from "./CampaignCheckbox";

interface IProps {
    campaign: any;
    checkboxOptions: IEntity<IExclusionsList | INegativeKeywordColl>;
}

const CampaignTableRow: React.FC<IProps> = ({ checkboxOptions, campaign }) => {
    return (
        <tr>
            <td className="border px-4 py-2 bg-gray-100">{campaign.name}</td>
            <td className="border px-4 py-2 bg-gray-100">
                {Object.keys(checkboxOptions).map((key: string) => {
                    const option = checkboxOptions[key];
                    return (
                        <CampaignCheckbox key={key} name={campaign.id} value={String(option.id)} label={option.name} />
                    );
                })}
            </td>
        </tr>
    );
};

export default CampaignTableRow;
