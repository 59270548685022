import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../interfaces/IAppState";
import useApi, { IUseApiHandler } from "../useApi";
import { addFlashMessage } from "../../actions/flashMessageActions";
import IClient from "../../interfaces/IClient";
import IFacebookAccount from "../../interfaces/IFacebookAccount";
import { useCallback, useEffect, useState } from "react";
import { useMatch } from "react-router";
import useDebouncedCallback from "../useDebouncedCallback";

export interface IFacebookAccountOptions {
    value: IFacebookAccount;
    label: string;
}

export type IuseFacebookReturn = {
    currentAccount: null | IFacebookAccount;
    selectedAccount: null | IFacebookAccount;
    setSelectedAccount: any;
    creatingFacebookAccount: boolean;
    facebookAccountsOptions: IFacebookAccountOptions;
    handleAccountSearch: any;
    loadAccountOptions: any;
    loadPagesOptions: any;
    createFacebookAccount: IUseApiHandler;
};

export default function useFacebook() {
    const {
        //getting clientId from the route...
        params: { clientId }
    } = useMatch("/clients/:clientId") ?? { params: {} };
    //getting the client from redux with useSelector and init internal state with the info from the client...
    const dispatch = useDispatch();
    const { clients } = useSelector((state: IAppState) => state);
    const client: IClient = clients.entities[clientId || 0];
    const [selectedAccount, setSelectedAccount] = useState<IFacebookAccount | null>(client?.facebookAccount); //defaults to the current account.

    //fetch accounts
    const { request: fetchAccounts } = useApi("/facebook-accounts", {
        method: "GET",
        useBuildUrl: true
    });
    // fetch pages
    const { request: fetchPages } = useApi("/facebook-pages", {
        method: "GET",
        useBuildUrl: true
    });

    //Create Facebook Account useApi handler
    const {
        data: currentAccount,
        request: createFacebookAccount,
        loading: creatingFacebookAccount
    } = useApi(`/clients/${client?.id}/facebook-ads-accounts`, {
        initialData: client?.facebookAccount, //current account also returned by the hook as currentAccount
        entityKey: "facebookAccount", //key from the response to grab the data from
        onFail: () => flashMessage("danger"),
        onSuccess: () => flashMessage("success"),
        method: "POST",
        schema: {}
    });

    const flashMessage = (type: "success" | "danger") => {
        const text =
            type === "success"
                ? "Successfully created Facebook account."
                : "Error creating Facebook account. Please contact Fuel support.";
        dispatch(addFlashMessage({ type, text }));
    };

    const loadAccountOptions = useCallback(async (searchValue: string, populateData: any) => {
        const resp: any = await fetchAccounts({
            search: {
                name: `like,*${searchValue}*`
            },
            limit: 50
        });
        populateData(
            resp[0].result?.data?.map((account: IFacebookAccount) => ({
                value: account,
                label: account.name
            }))
        );
    }, []);

    const loadPagesOptions = useCallback(async (searchValue: string, populateData: any) => {
        const resp: any = await fetchPages({
            search: {
                name: `like,*${searchValue}*`
            },
            limit: 50
        });
        populateData(
            resp[0].result?.data?.map((account: IFacebookAccount) => ({
                value: account,
                label: account.name
            }))
        );
    }, []);

    const debouncedLoadOptions = useDebouncedCallback(loadAccountOptions, 300);
    const debouncedLoadPages = useDebouncedCallback(loadPagesOptions, 300);

    useEffect(() => {
        //just to always reflect the current account on the drop down.
        if (currentAccount?.id) setSelectedAccount(currentAccount);
    }, [currentAccount]);

    return {
        currentAccount,
        selectedAccount,
        creatingFacebookAccount,
        setSelectedAccount,
        loadAccountOptions: debouncedLoadOptions,
        loadPagesOptions: debouncedLoadPages,
        createFacebookAccount
    };
}
