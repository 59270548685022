import React from "react";
import Errors from "../../Shared/Form/Elements/Errors";
import Label from "../../Shared/Form/Label";
import AddIcon from "../../Shared/Icons/AddIcon";
import { FormikErrors } from "formik";
import {
    ISpecialOfferTemplateForm,
    ISpecialOfferTemplatePartValue
} from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import SpecialOfferTemplatePart from "./SpecialOfferTemplatePart";
import { ISpecialOfferTemplatePart } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import AddButton from "../DynamicCampaigns/AddButton";

interface ISpecialOfferTemplatePartProps {
    values: any;
    errors: FormikErrors<ISpecialOfferTemplateForm>;
    setFieldValue: any;
    setFieldTouched: any;
}

const editablePartsOfTheSoT = [
    {
        field: "headlines",
        name: "Headlines",
        required: true,
        maxItems: 15
    },
    {
        field: "descriptions",
        name: "Descriptions",
        required: true,
        maxItems: 4
    },
    {
        field: "path",
        name: "Path",
        required: true
    }
];

const SpecialOfferTemplateParts: React.FC<ISpecialOfferTemplatePartProps> = ({
    setFieldValue,
    setFieldTouched,
    values,
    errors
}) => {
    return (
        <>
            <div className="grid grid-cols-2 grid-rows-2 grid-flow-col border-t gap-4 border-gray-300 -mt-4 pt-4">
                {editablePartsOfTheSoT.map(({ name, field, required, maxItems }, labelIndex) => {
                    const arrayOfData: string[] | ISpecialOfferTemplatePartValue[] = values[field];
                    const fieldErrors: string | FormikErrors<any> | undefined = errors?.[field];
                    const arrayOfValuesForValidation = arrayOfData.map((value) => {
                        if (typeof value === "string") {
                            return value;
                        }

                        return value.value;
                    });
                    const parts: ISpecialOfferTemplatePart[] = arrayOfData.map((value, index) => {
                        if (typeof value === "string") {
                            return {
                                index,
                                field,
                                value,
                                id: index + 99,
                                order: index,
                                conditionals: []
                            };
                        }

                        return {
                            index,
                            field,
                            value: value.value,
                            order: index,
                            id: index + 99,
                            conditionals: []
                        };
                    });
                    const sortParts = (dragIndex: number, hoverIndex: number, dragField: string) => {
                        if (parts[0].field !== dragField) {
                            return false;
                        }

                        const partToMove = parts.splice(dragIndex, 1);

                        const reorderedParts = [
                            ...parts.slice(0, hoverIndex),
                            ...partToMove,
                            ...parts.slice(hoverIndex)
                        ].map((value: any, index) => {
                            if (value.order === index) {
                                return value;
                            }
                            return { ...value, order: index, dirty: true };
                        });

                        setFieldValue(field, reorderedParts);
                        setFieldTouched(field);

                        return true;
                    };

                    return (
                        <div key={field} className="col-span-1">
                            <Label label={name} required={required} />
                            {fieldErrors && arrayOfValuesForValidation.length === 0 && (
                                <Errors errors={Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors]} />
                            )}

                            {parts.map((item: ISpecialOfferTemplatePart, index) => {
                                return (
                                    <SpecialOfferTemplatePart
                                        arrayOfValuesForValidation={arrayOfValuesForValidation}
                                        item={item}
                                        field={field}
                                        currentValue={values[field]}
                                        required={required}
                                        index={index}
                                        key={item.id}
                                        templateIndex={field}
                                        sortParts={sortParts}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                    />
                                );
                            })}

                            <AddButton
                                className={`flex items-center mt-4 ${values.parameters.length === 1 ? "" : "ml-12"}`}
                                onClick={() => {
                                    if (["headlines", "descriptions"].includes(field)) {
                                        setFieldValue(`${field}[${arrayOfValuesForValidation.length || 0}]`, {
                                            value: "",
                                            pinnedField: null
                                        });
                                        return;
                                    }

                                    setFieldValue(`${field}[${arrayOfValuesForValidation.length || 0}]`, "");
                                }}
                                text={`Add ${maxItems ? `(${arrayOfValuesForValidation.length}/${maxItems})` : ""}`}
                                disabled={(maxItems && arrayOfValuesForValidation.length >= maxItems) || false}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default SpecialOfferTemplateParts;
