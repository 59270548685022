export default function ({
    value,
    label,
    subLabel,
    information,
    classes
}: {
    value: number | string;
    label: string;
    subLabel: string;
    information?: string;
    classes?: string;
}) {
    return (
        <div className={"p-4 shadow rounded " + (classes ?? "bg-white")}>
            <div className={"flex flex-col"}>
                <div className={"flex flex-wrap justify-between"}>
                    <div className={"font-medium text-gray-600"}>{label}</div>
                </div>
                <div className={"text-3xl gap-2 font-bold"}>
                    <span>{(typeof value === "string" ? parseInt(value as string) : value).toLocaleString()}</span>

                    <span className={"text-sm pl-2"}>{subLabel}</span>
                </div>
                {information && <div className={"text-xs -mt-3 text-gray-700"}>{information}</div>}
            </div>
        </div>
    );
}
