import { schema } from "normalizr";

const trackedCampaign = new schema.Entity("trackedCampaigns");

const trackingParameter = new schema.Entity("trackingParameters", {
    trackedCampaigns: [trackedCampaign]
});

export default {
    TRACKING_PARAMETER_LIST: { data: [trackingParameter] },
    TRACKING_PARAMETER: trackingParameter,
    TRACKED_CAMPAIGNS: trackedCampaign
};
