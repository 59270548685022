import { AnyAction, combineReducers } from "redux";
import * as actions from "../../actions/salesData";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";

const parameterReducer = (state: { [x: string]: any } = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.TOGGLE_FILTER_OPTION:
            const filter = action.filter;
            const value = action.value;
            const current = state[filter][value];
            return {
                ...state,
                [filter]: { ...state[filter], [value]: !current }
            };
        case actions.LOAD_FILTER_OPTIONS:
            return { ...action.options };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};
const datesReducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.UPDATE_DATE_RANGE:
            return { ...state, ...action.dateRange };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    parameters: parameterReducer,
    dateRange: datesReducer
});
