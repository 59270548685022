import React, { SVGProps } from "react";
import Svg from "./Svg";

const UnchainIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M18 8h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C21.88 16.61 23 14.95 23 13c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H17v-2zM3 5.27l3.11 3.11A4.991 4.991 0 0 0 3 13c0 2.76 2.24 5 5 5h4v-1.9H8c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L9.73 12H9v2h2.73L14 16.27V18h1.73l4.01 4L21 20.74 4.27 4 3 5.27z" />
    </Svg>
);

export default UnchainIcon;
