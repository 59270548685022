import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Form, { TYPE_EDIT } from "./Form";
import { updateAction } from "../../../actions/remarketing/inventoryExportsActions";
import IAppState from "../../../interfaces/IAppState";
import { IInventoryExportState } from "../../../sagas/Client/remarketingSagas";
import { useLocation, useParams } from "react-router";

type IRouteParams = {
    id: string;
};
interface IProps {
    path?: string;
    id?: string;
}
const EditRemarketing: React.FunctionComponent<IProps> = ({}) => {
    const dispatch = useDispatch();
    const { id: remarketingId } = useParams<IRouteParams>();
    const remarketingInventoryExports = useSelector(
        ({ remarketingInventoryExports }: IAppState) => remarketingInventoryExports
    );
    const clients = useSelector(({ clients }: IAppState) => clients);

    const inventoryExport = remarketingInventoryExports.entities[remarketingId!];
    const saving = remarketingInventoryExports.saving.indexOf(parseInt(remarketingId!, 10)) !== -1;
    const client = clients.entities[clients.currentClient];
    const { currentClient: clientId } = clients;

    const onSave = (inventoryExport: IInventoryExportState, clientId: number, setErrors: (errors: any) => void) => {
        dispatch(updateAction(inventoryExport, clientId, setErrors));
    };

    return (
        <Fragment>
            <Header showButton={false} />
            <Form
                remarketingId={Number(remarketingId)}
                onSave={onSave}
                inventoryExport={inventoryExport}
                clientId={clientId}
                client={client}
                saving={saving}
                type={TYPE_EDIT}
                cancelPath="../.."
            />
        </Fragment>
    );
};

export default EditRemarketing;
