import React from "react";
import { ItemField, LogHeaderStyle } from "../BudgetDetails/BudgetDetails";

const ManualBudgetActivityHeader = () => {
    return (
        <div className={LogHeaderStyle}>
            <div className={ItemField}>
                <div className={"ml-2"}>Date</div>
            </div>
            <div className={ItemField}>
                <div className={"ml-2"}>Creator</div>
            </div>
            <div className={`flex-auto`}>
                <div className={"ml-2"}>Note</div>
            </div>
            <div className={ItemField}>
                <div className={"ml-2"}>Budget Change</div>
            </div>
        </div>
    );
};

export default ManualBudgetActivityHeader;
