import { hot } from "react-hot-loader";
import React, { Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Logout from "../components/Auth/Logout";
import FullPageLoader from "../components/Shared/Loaders/FullPageLoader";
import UnauthenticatedRouteRedirects from "./UnauthenticatedRouteRedirects";

import Clients from "./Clients";
import Budgets from "./BudgetApp";
import Client from "./Client";
import FacebookReport from "../components/Admin/FacebookReport/Index";
import Notifications from "../components/Profile/Notifications/NotificationList";
import UsersContainer from "../components/Users/UsersContainer";
import ActivateRedirect from "../components/Users/ActivateRedirect";
import PlacementExclusionListsContainer from "../components/Admin/PlacementExclusions/PlacementExclusionListsContainer";
import PlacementExclusionsContainer from "../components/Admin/PlacementExclusions/PlacementExclusionsContainer";
import OEMReports from "../components/Admin/OEMReports/Index";
import ActivityLog from "../components/Admin/ActivityLog/Index";
import NamingConventions from "../components/Admin/NamingConventions/Index";
import NamingConventionsForm from "../components/Admin/NamingConventions/NamingConventionsForm";
import NotFound404 from "../components/Shared/NotFound404";
import NegativeKeywordListsContainer from "../components/Admin/NegativeKeywords/NegativeKeywordListsContainer";
import NegativeKeywordsContainer from "../components/Admin/NegativeKeywords/NegativeKeywordsContainer.tsx";
import Redirect from "../components/Shared/Loaders/Redirect";

const Default = () => {
    const navigate = useNavigate();
    const getFromStorage = (key, initialValue) => {
        try {
            const value = window.localStorage.getItem(key)
                ? JSON.parse(window.localStorage.getItem(key))
                : initialValue;
            if (value) return value;
            return initialValue;
        } catch (error) {
            return initialValue; // Return default value if JSON parsing fails
        }
    };

    const redirect = getFromStorage("INTENDED_REDIRECT", "/budgets/adwords");
    localStorage.setItem("INTENDED_REDIRECT", JSON.stringify(null));
    useEffect(() => {
        navigate(redirect);
    }, [navigate, redirect]);
};

const App = () => {
    return (
        <Suspense fallback={<FullPageLoader message="Loading FUEL..." />}>
            <Routes className="h-full">
                <Route path="/" element={<Default />} />
                <Route path="/login" element={<Default />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="admin">
                    <Route path="*" element={<NotFound404 />} />
                    <Route index element={<Redirect to={"/clients"} />} />
                    <Route path="placement-exclusions">
                        <Route index element={<PlacementExclusionListsContainer />} />
                        <Route path=":collectionId" element={<PlacementExclusionsContainer />} />
                    </Route>
                    <Route path="negative-keywords">
                        <Route index element={<NegativeKeywordListsContainer />} />
                        <Route path=":collectionId" element={<NegativeKeywordsContainer />} />
                    </Route>
                    <Route path="facebook-report" element={<FacebookReport />} />
                    <Route path="oem-reports/*" element={<OEMReports />} />
                    <Route path="activity-log" element={<ActivityLog />} />
                    <Route path="naming-conventions">
                        <Route index element={<NamingConventions />} />
                        <Route path="create" element={<NamingConventionsForm />} />
                        <Route path=":namingConventionId/edit" element={<NamingConventionsForm />} />
                        <Route path="*" element={<NotFound404 />} />
                    </Route>
                </Route>
                <Route path="notifications" element={<Notifications />} />
                <Route path="client/:id/*" element={<Client />} /> {/*TODO: Add client not found navigate on fetch*/}
                <Route path="clients/*" element={<Clients />} />
                <Route path="budgets/*" element={<Budgets />} />
                <Route path="users/*" element={<UsersContainer />} />
                <Route path="/activate" element={<ActivateRedirect />} />
                <Route path="*" element={<UnauthenticatedRouteRedirects />} />
            </Routes>
        </Suspense>
    );
};

export default hot(module)(App);
