import _ from "lodash";
import moment from "moment";
import IEntity from "../../../interfaces/IEntity";
import IClient from "../../../interfaces/IClient";
import { DI_AGENCY_ID } from "../../../constants/AgencyConstants";

const validation = (c: IClient) => ({
    name: { presence: { allowEmpty: false }, length: { maximum: 200 } },
    state: { length: { maximum: 3 } },
    country: { length: { maximum: 2 } },
    shortNameForDealer: { length: { maximum: 80 } },
    url: { url: true },
    crmEmail: { crmEmails: true },
    dashboardApiKey: { dashboardApiKey: true },
    inventoryApiKey: { inventoryApiKey: true },
    inventoryFeedId: { length: { maximum: 255 } },
    dealerCode: { dealerCode: true },
    locationCode: { locationCode: true },
    facebookPageId: { facebookPage: true },
    gaProfileId: { gaProfileId: true },
    oemGoLiveDate: { oemGoLiveDate: true },
    oemEndDate: { oemEndDate: true },
    oemSlug: { length: { maximum: 255 } },
    cancelAt: { cancelAt: true },
    isaAdvertiserName: { isaAdvertiserName: true },
    // Salesforce id is only required for DI clients. Not required for other agencies
    salesforceId: {
        ...(c.agencyId !== DI_AGENCY_ID
            ? { presence: false }
            : {
                  presence: {
                      allowEmpty: false
                  }
              }),

        length: {
            maximum: 18,
            minimum: 18
        }
    },
    koddiId: {
        numericality: {
            onlyInteger: true,
            message: "Koddi ID must be an integer"
        },
        length: {
            maximum: 8
        }
    }
});

const checkForDuplicateValue = (
    clients: IEntity<IClient>,
    currentClient: IClient,
    field: string,
    fieldName: string
) => {
    const dups = _.filter(clients, (client) => {
        return currentClient[field] !== "" && currentClient.id !== client.id && currentClient[field] === client[field];
    });
    const warning =
        dups.length === 0
            ? undefined
            : dups.reduce(
                  (val, client) => `${val} <br />${client.name}`,
                  `The following clients are using this ${fieldName} already:`
              );
    return warning;
};

const checkForDuplicateData = (changes: { [key: string]: any }, client: IClient, clients: IEntity<IClient>) => {
    const warnings: { [key: string]: any } = {};
    const errors: { [key: string]: any } = {};
    if (changes.dashboardApiKey) {
        warnings.dashboardApiKey = checkForDuplicateValue(clients, client, "dashboardApiKey", "dashboard api key");
    }
    if (changes.inventoryApiKey) {
        warnings.inventoryApiKey = checkForDuplicateValue(clients, client, "inventoryApiKey", "inventory api key");
    }

    if ((client.bingId as any) !== "" && client.bingId !== null) {
        const err = checkForDuplicateValue(clients, client, "bingId", "Bing Account");
        if (err) {
            errors.bingId = [err];
        }
    }

    return { errors, warnings };
};

/**
 *
 * @param {*} clients
 * @param {*} client
 * @param {*} changes
 * @param {*} validate
 */
const doClientFormValidation = (
    clients: IEntity<IClient>,
    client: IClient,
    changes: { [key: string]: any },
    validate: any
) => {
    const { errors, warnings } = checkForDuplicateData(changes, client, clients);
    return { errors: { ...errors, ...validate(client, validation(client)) }, warnings };
};
const addCustomValidation = (validate: any) => {
    validate.validators.inventoryApiKey = (value: any, options: any, key: any, attributes: any) => {
        if (!_.isEmpty(value)) {
            return validate.single(attributes.inventoryApiKey, {
                numericality: { onlyInteger: true }
            });
        }

        return null;
    };
    validate.validators.dateYearMonthDay = (value: any, options: any, key: any, attributes: any) => {
        return moment(value, "YYYY-MM-DD", true).isValid() ? null : "is not a valid date in YYYY-MM-DD format";
    };

    validate.validators.gaProfileId = (value: any, options: any, key: any, attributes: any) => {
        return null;
    };
    validate.validators.dealerCode = (value: any, options: any, key: any, attributes: any) => {
        if (_.isEmpty(value) && !_.isEmpty(attributes.oemProgram)) {
            return "required for OEM programs";
        }
        return null;
    };
    validate.validators.oemGoLiveDate = (value: any, options: any, key: any, attributes: any) => {
        if (_.isEmpty(value)) {
            if (!_.isEmpty(attributes.oemProgram)) {
                return "required for OEM programs";
            }
        } else {
            return validate.single(value, { dateYearMonthDay: true });
        }

        return null;
    };

    validate.validators.oemEndDate = (value: any, options: any, key: any, attributes: any) => {
        if (!_.isEmpty(value)) {
            const { oemGoLiveDate } = attributes;
            if (!_.isEmpty(oemGoLiveDate)) {
                const endDateInTheFuture = moment(value).diff(oemGoLiveDate) >= 0;
                if (!endDateInTheFuture) return "is required to be set in the future.";
            }
            return validate.single(value, { dateYearMonthDay: true });
        }

        return null;
    };
    validate.validators.locationCode = (value: any, options: any, key: any, attributes: any) => {
        if (_.isEmpty(value)) {
            if (attributes.oemProgram === "mini") {
                return "required for OEM program MINI ";
            }
            if (attributes.oemProgram === "bmw") {
                return "required for OEM program BMW";
            }
        }

        return null;
    };

    validate.validators.dashboardApiKey = (value: any, options: any, key: any, attributes: any) => {
        if (_.isEmpty(value) && attributes.diSite === true) {
            return "required for DI sites";
        }

        const sha1Regex = new RegExp(/^[0-9a-f]{40}$/, "i");
        if (!_.isEmpty(value) && !sha1Regex.test(value)) {
            return "value is not the proper format";
        }

        return null;
    };
    validate.validators.facebookPage = (value: any, options: any, key: any, attributes: any) => {
        if (attributes.facebookWebhooks === true) {
            return validate.single(attributes.facebookPageId, {
                numericality: { onlyInteger: true }
            });
        }

        return null;
    };
    validate.validators.crmEmails = (value: any, options: any, key: any, attributes: any) => {
        let validEmail = false;
        const errors: string[] = [];
        attributes.crmEmail.forEach((email: string) => {
            const message = validate.single(email, { presence: true, email: true });
            if (_.isEmpty(message)) {
                validEmail = true;
            } else {
                errors.push(`${email} is not a valid email`);
            }
        });
        if (attributes.facebookWebhooks === true && validEmail === false) {
            return " requires a valid email when using Facebook leads";
        }

        if (!_.isEmpty(errors)) {
            return errors;
        }

        return null;
    };
    validate.validators.cancelAt = (value: any, options: any, key: any, attributes: any) => {
        if (value && moment().diff(moment(value)) >= 0) {
            return "Date must be set in the future.";
        }
        return null;
    };
    validate.validators.isaAdvertiserName = (value: any, options: any, key: any, attributes: any) => {
        if (!attributes.koddiId) {
            if (attributes.createKoddiRequested) {
                if (_.isEmpty(attributes.isaAdvertiserName)) {
                    return "cannot be blank";
                }
            }
        }
        return null;
    };
};
export { doClientFormValidation, checkForDuplicateData, addCustomValidation, validation };
