import React, { FunctionComponent } from "react";
import FilterSection from "./Filtering/FilterSection";
import { debounce } from "lodash";

interface IFilters {
    filterCounts: { [key: string]: { [key: string]: number } };
    filterOptions: { [key: string]: { [key: string]: boolean } };
    filterSalesData(): void;
    onFilterChanged(filter: string, value: string): void;
}

const wait = debounce((onFilterChanged) => {
    onFilterChanged();
}, 1000);

const Filters: FunctionComponent<IFilters> = ({
    children,
    filterCounts,
    filterOptions,
    filterSalesData,
    onFilterChanged
}) => {
    const filterChanged = (filter: string, value: string) => {
        onFilterChanged(filter, value);
        wait(filterSalesData);
    };
    return (
        <div className="overflow-y-auto mt-4" style={{ maxHeight: "450px" }}>
            {Object.keys(filterOptions).map((label) => (
                <FilterSection
                    onFilterChange={filterChanged}
                    key={label}
                    label={label}
                    filterCounts={filterCounts[label]}
                    filterOptions={filterOptions[label]}
                />
            ))}
        </div>
    );
};

export default Filters;
