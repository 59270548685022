import React from "react";

interface ICharacterCountProps {
    value: string;
    characterLimit: number;
    hideCount: boolean;
}

const CharacterCount = ({ value, characterLimit, hideCount = false }: ICharacterCountProps) =>
    hideCount ? (
        <React.Fragment />
    ) : (
        <div className={`py-3 pl-3 ${value.length > characterLimit ? "text-red-600" : "text-gray-600"}`}>
            {value.length} / {String(characterLimit)}
        </div>
    );

export default CharacterCount;
