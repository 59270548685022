import * as React from "react";
import { connect, FormikContext } from "formik";
import IDynamicCampaignFormValue from "../../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import UnchainIcon from "../../../../../Shared/Icons/UnchainIcon";
import ModalToggle from "../../../../../Shared/Dialogs/ModalToggle";
import Button from "../../../../../Shared/Button";

type Props = {
    keywordTemplateRelationshipIndex: number;
};

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const UnlinkButton = ({
    keywordTemplateRelationshipIndex,
    formik: {
        values: {
            id,
            keywordTemplateRelationships: keywordTemplateRelationships,
            keywordTemplateRelationships: {
                [keywordTemplateRelationshipIndex]: keywordTemplateRelationship,
                [keywordTemplateRelationshipIndex]: {
                    keywordTemplate: { name, keywords, conditionals: masterConditionals }
                }
            }
        },
        setFieldValue
    }
}: Props & ContextProps) => {
    const handleUnlinkKeywordTemplate = () => {
        setFieldValue(`keywordTemplateRelationships`, [
            ...keywordTemplateRelationships.slice(0, keywordTemplateRelationshipIndex),
            { ...keywordTemplateRelationship, dirty: true, deleted: true },
            {
                dynamicCampaignId: id,
                conditionals:
                    masterConditionals?.map(({ parameter, comparator, value }) => ({
                        parameter,
                        comparator,
                        value,
                        dirty: true,
                        new: true
                    })) || [],
                keywordTemplate: {
                    keywords,
                    dirty: true,
                    new: true
                },
                dirty: true,
                new: true
            },
            ...keywordTemplateRelationships.slice(keywordTemplateRelationshipIndex + 1)
        ]);
    };

    return (
        <ModalToggle
            className={`w-2/6`}
            toggleButton={(openModal) => (
                <button
                    data-testid={`unlink-button-keywordTemplateRelationships[${keywordTemplateRelationshipIndex}]`}
                    onClick={openModal}
                    type="button"
                    className={`px-4 py-2 border text-cerulean-500 hover:text-cerulean-600 hover:bg-gray-200`}
                    title="Unlink"
                >
                    <UnchainIcon className="w-6 h-6" />
                </button>
            )}
            modalContent={(closeModal) => (
                <div className={"w-full flex flex-col items-center justify-center"}>
                    <div className="text-2xl text-center">
                        Are you sure you want to unlink this campaign from Keyword Template: {name}?
                    </div>
                    <div className={`mt-4`}>
                        <Button onClick={closeModal} styleType={"negative"}>
                            Cancel
                        </Button>
                        <Button onClick={() => handleUnlinkKeywordTemplate()} styleType={"danger"}>
                            Yes, Unlink
                        </Button>
                    </div>
                </div>
            )}
        />
    );
};

export default connect<Props, any>(UnlinkButton);
