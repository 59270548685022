import IEntity from "../IEntity";
import { Action } from "redux";

export interface IItem {
    collectionId?: number;
    id: number;
    name: string;
    createdAt: string;
    new?: boolean;
}

export interface ICollection {
    id: number;
    name: string;
}

export interface ICollectionsState {
    entities: IEntity<ICollection>;
    errors: any;
    loaded: boolean;
}

export interface ISearchResult {
    results: string[];
    loadedPages: number;
    totalPages: number;
    loading: boolean;
    loadingError: null | string;
}

export interface ISearchTerms {
    [search: string]: ISearchResult;
}

export interface IItemsState {
    searchTerms: ISearchTerms;
    entities: IEntity<IItem>;
    searchTerm: string;
    collectionId: number;
    deleted: number[];
    added: number[];
    errors: IEntity<IError>;
    uploading: boolean;
}

export interface IFetchCollectionListSuccess extends Action {
    payload: IEntity<ICollection>;
}

export interface IFetchCollectionListFailure extends Action {
    payload: string;
}

export interface IFetchItemCollection extends Action {
    payload: {
        collectionId: number;
        page: number;
        searchTerm: string;
    };
}

export interface IFetchItemCollectionSuccess extends Action {
    payload: {
        response: {
            entities: {
                items: IEntity<IItem>;
            };
            result: {
                currentPage: number;
                data: number[];
                lastPage: number;
                total: number;
            };
        };
        collectionId: number;
        searchTerm: string;
    };
}

export interface IFetchItemCollectionFailure extends Action {
    payload: {
        errorMessage: string;
        searchTerm: string;
    };
}

export interface ISearchItemCollection extends Action {
    payload: {
        collectionId: number;
        searchTerm: string;
    };
}

export interface IAddItemToCollection extends Action {
    payload: {
        itemName: string;
        collectionId: number;
        dispatchedAt: number;
    };
}

export interface IAddItemToCollectionSuccess extends Action {
    payload: {
        item: IEntity<IItem>;
        itemId: number;
        dispatchedAt: number;
    };
}

export interface IAddItemToCollectionFailure extends Action {
    payload: {
        errorMessage: string;
        dispatchedAt: number;
    };
}

export interface IRemoveItemFromCollection extends Action {
    payload: number;
}

export interface IRemoveItemFromCollectionSuccess extends Action {
    payload: number;
}

export interface IRemoveItemFromCollectionFailure extends Action {
    payload: {
        itemId: number;
        errorMessage: string;
    };
}

export interface IDismissItemActionError extends Action {
    payload: {
        itemId: number;
        errorType: ActionErrorType;
    };
}

export interface IUploadCsv extends Action {
    payload: {
        id: number;
        fileInput: any;
    };
}

export interface IUploadCsvSuccess extends Action {}
export interface IUploadCsvFailure extends Action {}

export type IFetchCollectionAction = (collectionId: number, page: number, searchTerm: string) => void;
export type IDeleteItemAction = (id: number) => void;
export type ICreateItemAction = (listId: number, name: string) => void;
export type IDismissErrorAction = (id: number, type: string) => void;

export interface IError {
    message: string;
    type: ActionErrorType;
}

export enum ActionErrorType {
    Add = "ADD",
    Remove = "REMOVE"
}

export interface ICollectionInfo {
    collectionName: string | null;
    collectionDescription: string;
    itemDescription: string;
    itemsDescription: string;
}

export interface ICollectionProps {
    info: ICollectionInfo;
    searchTerm: string;
    items: IItem[];
    deleted: number[];
    errors: IEntity<IError>;
    currentPage: number;
    isNextPageLoading: boolean;
    hasNextPage: boolean;
    fetchCollection: IFetchCollectionAction;
    fetchCollections: any;
    searchItems: any;
    deleteItem: any;
    createItem: any;
    dismissError: any;
    uploading: boolean;
    uploadCSV(clientId: number, fileInput: any): void;
}

export interface IItemProps {
    style: any;
    description: string;
    item: IItem;
    deleted: boolean;
    deleteItem: IDeleteItemAction;
    error?: IError;
    dismissError: IDismissErrorAction;
}

export interface ICollectionListProps {
    listTitle: string;
    collections: IEntity<ICollection>;
    loaded: boolean;
    fetchCollections: any;
}

export interface IAddItemProps {
    info: ICollectionInfo;
    routeId: number;
    createItem: ICreateItemAction;
}

export interface ISearchItemsProps {
    info: ICollectionInfo;
    routeId: number;
    searchTerm: string;
    searchItems: any;
    isLoading: any;
}
