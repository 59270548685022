import { connect } from "react-redux";
import SalesMapContainer from "./SalesMapContainer";
import * as actions from "../../actions/salesData";
import { getCurrentClientObject } from "../../reducers/clients";
import { bidAdjustmentsWithLocationSelector } from "../../reducers/adwordsBidAdjustments";
import { isUserReadOnly } from "../../reducers/currentUser";
import IAppState from "../../interfaces/IAppState";
import { Range } from "react-date-range";
import IGoogleCampaign from "../../interfaces/IGoogleCampaign";

const mapStateToProps = (state: IAppState) => {
    return {
        clientId: state.clients.currentClient,
        currentClient: getCurrentClientObject(state),
        dateRange: state.salesDataFilters.dateRange,
        salesLoading: state.salesData.loading,
        adjustmentsLoading: state.adwordsBidAdjustments.loading,
        mapData: state.salesData.sales,
        mapCenter: state.mapData.mapCenter || state.salesData.mapCenter,
        mapZoom: state.mapData.mapZoom,
        bidAdjustments: bidAdjustmentsWithLocationSelector(state),
        locationStats: state.locationStats,
        campaigns: state.adwordsCampaigns.entities,
        isReadOnly: isUserReadOnly(state.currentUser)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        refreshMap: (clientId: number) => {
            dispatch(actions.requestSalesData());
            dispatch(actions.fetchSalesData(clientId));
        },
        fetchFilterOptions: (clientId: number) => {
            dispatch(actions.fetchFilterOptions(clientId));
        },
        campaignChanged: (clientId: number, campaign: IGoogleCampaign) => {
            dispatch(actions.fetchBidAdjustments(clientId, campaign.id));
        },
        updateMapBounds: (center: { lat: number; lng: number }, zoom: number) => {
            dispatch(actions.updateMapBounds(center, zoom));
        },
        updateDateRange: (dateRange: Range, clientId: number) => {
            dispatch(actions.updateDateRange(dateRange));
            dispatch(actions.fetchSalesData(clientId));
        },
        updateLocationStats: (clientId: number, campaignId: number, dateRange: Range) => {
            dispatch(actions.loadLocationStats(clientId, campaignId, dateRange));
        },
        purgeSalesData: (clientId: number) => {
            dispatch(actions.purgeSalesData(clientId));
        },
        pullDiSalesData: (clientId: number) => {
            dispatch(actions.pullDiSalesData(clientId));
        }
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(SalesMapContainer);

export default Container;
