import { all, call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../../middleware/api";
import ExclusionsActions from "../../actions/client/exclusionsActions";
import ExclusionsConstants from "../../constants/ExclusionsConstants";
import CLIENT_SCHEMAS from "../../middleware/schemas/client";
import IEntity from "../../interfaces/IEntity";
import {
    IMappedExclusionById,
    IExclusionsList,
    IUpdatedExclusions,
    IMappedExclusion
} from "../../interfaces/Exclusions";
import { FormikActions } from "formik";
import ApiConstants from "../../constants/ApiConstants";
import { decamelizeKeys } from "humps";
import { addFlashMessage } from "../../actions/flashMessageActions";

interface IUpdateSuccessProps {
    type: string;
    payload: { clientId: number; formikActions: FormikActions<IMappedExclusionById> };
}

interface IUpdateFailProps {
    type: string;
    payload: { error: any; formikActions: FormikActions<IMappedExclusionById>; clientId: number };
}

interface INormalizedResponse<T> {
    entities: IEntity<T>;
}

interface IUpdateExclusionsProps {
    type: string;
    payload: {
        updatedExclusions: IUpdatedExclusions;
        formikActions: FormikActions<IMappedExclusionById>;
        clientId: number;
    };
}

export function* fetchExclusionsList() {
    const url = `/exclusion-colls`;
    try {
        const response: INormalizedResponse<IEntity<IExclusionsList>> = yield call(
            callApi,
            url,
            CLIENT_SCHEMAS.ADWORDS.EXCLUSION_LIST_ARRAY
        );
        const { exclusionList } = response.entities;
        yield put(ExclusionsActions.requestExclusionListSuccess(exclusionList));
    } catch (error) {
        yield put(ExclusionsActions.requestExclusionListFail(error));
    }
}

export function* fetchExclusionsCollCampaigns({ payload }: { type: string; payload: number }) {
    const url = `/exclusion-coll-campaigns?filter[client_id]=${payload}&limit=1000`;
    try {
        const response: INormalizedResponse<any> = yield call(
            callApi,
            url,
            CLIENT_SCHEMAS.ADWORDS.EXCLUSION_COLL_CAMPAIGN_ARRAY
        );
        const { exclusionCollCampaigns } = response.entities;
        yield put(ExclusionsActions.requestExclusionCollCampaignsSuccess(exclusionCollCampaigns));
    } catch (error) {
        yield put(ExclusionsActions.requestExclusionCollCampaignsFail(error));
    }
}

export const handleNewExclusions = (exclusions: IMappedExclusion[]) => {
    return exclusions.map((exclusion) => {
        return call(
            callApi,
            ApiConstants.EXCLUSION_COLL_CAMPAIGN_URL(),
            CLIENT_SCHEMAS.ADWORDS.EXCLUSION_COLL_CAMPAIGN_ARRAY,
            "POST",
            decamelizeKeys(exclusion)
        );
    });
};

export const handleRemoveExclusions = (exclusions: IMappedExclusion[]) => {
    return exclusions.map((exclusion) => {
        return call(callApi, ApiConstants.EXCLUSION_COLL_CAMPAIGN_URL(exclusion.id), {}, "DELETE");
    });
};

export function* updateExclusions({ payload }: IUpdateExclusionsProps) {
    const { clientId, updatedExclusions, formikActions } = payload;
    const { checkedValues, unCheckedValues } = updatedExclusions;
    try {
        yield all([...handleNewExclusions(checkedValues), ...handleRemoveExclusions(unCheckedValues)]);
        yield put(ExclusionsActions.updateExclusionCollCampaignsSuccess(formikActions, clientId));
    } catch (error) {
        yield put(ExclusionsActions.updateExclusionCollCampaignsFail(error, formikActions, clientId));
    }
}

export function* updateExclusionsSuccess({ payload }: IUpdateSuccessProps) {
    const { formikActions, clientId } = payload;
    yield put(ExclusionsActions.requestExclusionCollCampaigns(clientId));
    yield put(addFlashMessage({ type: "success", text: "Exclusions Updated Successfully." }));
    yield formikActions.setSubmitting(false);
}

export function* updateExclusionsFail({ payload }: IUpdateFailProps) {
    const { formikActions, error, clientId } = payload;
    yield put(ExclusionsActions.requestExclusionCollCampaigns(clientId));
    yield put(addFlashMessage({ type: "danger", text: error.message }));
    yield formikActions.setSubmitting(false);
    yield formikActions.setErrors(error);
}

function* sagas() {
    yield takeEvery(ExclusionsConstants.REQUEST_EXCLUSIONS_LIST, fetchExclusionsList);
    yield takeEvery(ExclusionsConstants.REQUEST_EXCLUSIONS_COLL_CAMPAIGNS, fetchExclusionsCollCampaigns);
    yield takeEvery(ExclusionsConstants.REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS, updateExclusions);
    yield takeEvery(ExclusionsConstants.REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_SUCCESS, updateExclusionsSuccess);
    yield takeEvery(ExclusionsConstants.REQUEST_UPDATE_EXCLUSIONS_COLL_CAMPAIGNS_FAIL, updateExclusionsFail);
}

export default sagas;
