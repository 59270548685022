import React from "react";
import Field from "../../Shared/Form/Elements/Field";
import ChevronDownIcon from "../../Shared/Icons/ChevronDownIcon";

interface IProps {
    disabled: boolean;
    onChange: (e: any) => void;
    value: string;
}

const ReportOptions: React.FC<IProps> = ({ value, disabled, onChange }) => {
    return (
        <Field label="Report options" errors={[]}>
            <div className="relative">
                <select
                    className="block appearance-none w-full bg-white border border-gray-400 shadow-sm text-gray-700 p-2 pr-8 rounded focus:outline-none focus:border-gray-500"
                    onChange={onChange}
                    name="reportType"
                    disabled={disabled}
                    value={value}
                >
                    <option value="">Please choose a report...</option>
                    <option value="oem-digad-daily-new">Daily DigAd</option>
                    <option value="fd-daily-budget">Ford Daily Operational</option>
                    <option value="oem-digad-monthly">Monthly DigAd</option>
                    <option value="oem-budget-monthly">Monthly Budget</option>
                    <option value="oem-keyword-monthly">Monthly Keyword</option>
                    <option value="oem-spend-monthly">Monthly Spend New</option>
                    <option value="mazda-comprehensive-monthly">Mazda Comprehensive Monthly</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <ChevronDownIcon className="h-6 w-6" />
                </div>
            </div>
        </Field>
    );
};

export default ReportOptions;
