import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CurrencyField from "../../../Shared/Form/CurrencyField.standalone";
import { IManualBudgetFormValues } from "../../../../interfaces/Budgets/IBudgetFormValues";
import CircleIcon from "../../../Shared/Icons/CircleIcon";
import Button from "../../../Shared/Button";
import RefreshIcon from "../../../Shared/Icons/RefreshIcon";
import { useForm } from "react-hook-form";
import { IErrors } from "../../../../hooks/budgets/useBudget";
import moment from "moment";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { Calendar } from "react-date-range";

interface IProps {
    budget?: IManualBudgetFormValues;
    title: string;
    loadingBudget: boolean;
    loadingMessage: string;
    isSaving: boolean;
    apiErrors: IErrors;
    cancelPath: string;
    onSubmit?: (values: IManualBudgetFormValues) => void;
}
const fieldStyles =
    "appearance-none block w-full bg-gray-200 text-gray-800 border rounded py-4 px-2 leading-tight focus:outline-none focus:bg-white border-gray-200 focus:border-gray";
const formContainerStyles =
    "relative bg-white flex flex-col w-full sm:w-4/5 md:w-full lg:w-2/3 xl:w-1/2 shadow-md rounded p-4 mb-4 text-base";
const fieldGroupStyles = "flex flex-wrap mb-4 w-full";
const labelStyles = "block uppercase tracking-wider text-gray-800 font-bold mb-2";
const currencyFieldStyles = "w-full sm:w-1/2";
const errorStyles = "text-red-500 text-sm italic uppercase";
const maxTargetSpend = 100000;

const ManualBudgetForm = ({ title, budget, isSaving, apiErrors, onSubmit, cancelPath, loadingBudget }: IProps) => {
    const additionalErrors = (errors: any) =>
        !!errors && Object.keys(errors).length
            ? Object.keys(errors).map((key) => (
                  <div key={key} className="error-message items-center flex flex-1">
                      <CircleIcon className="h-4 w-4" />
                      <span className={"ml-2"}>{errors[key]}</span>
                  </div>
              ))
            : null;
    const datepickerRef = useRef<HTMLDivElement>() as LegacyRef<HTMLDivElement>;
    const [showLaunchedOnPicker, setShowLaunchedOnPicker] = useState<boolean>(false);
    const dateFormat = "MMM Do YYYY";
    const now = moment();

    //useForm hook:
    const {
        register, //adds an input to the list of tracked fields.
        handleSubmit, //through this handler, form action is attached to the submit event.
        formState: { errors, isValid, submitCount, isSubmitting, touchedFields, isDirty },
        getValues, //query current field values in run time.
        watch, //react to changes on specified fields through this function.
        setValue //change the value of a field in run time.
    } = useForm<IManualBudgetFormValues>({
        defaultValues: {
            budgetType: "1",
            changeRequestLink: budget?.changeRequestLink,
            monthlyBudget: budget?.targetSpend,
            breachBuffer: budget?.breachBuffer,
            label: budget?.label,
            digadServiceLaunchedOn:
                (!!budget?.digadServiceLaunchedOn && moment.utc(budget?.digadServiceLaunchedOn).format(dateFormat)) ||
                ""
        },
        mode: "onChange"
    });

    useOnClickOutside(datepickerRef, () => setShowLaunchedOnPicker(false));

    const formSubmit = (values: IManualBudgetFormValues) => {
        if (isValid) {
            onSubmit && onSubmit(values);
            setValue("changeReason", "");
        }
    };
    //manually registering pattern (currency) fields to prevent after validation rerendering, which causes losing focus on the input after first onChange.
    const monthlyBudget = register("monthlyBudget", {
        required: true,
        min: 0,
        max: 100000,
        pattern: /^[-+]?[0-9]*\.?[0-9]+$/
    });
    //watchers
    const changeReasonValue = watch("changeReason");

    return (
        <div className={formContainerStyles}>
            <div className="font-bold text-2xl self-center text-gray-700 uppercase">{title}</div>
            <form onSubmit={handleSubmit(formSubmit)}>
                {/* Change Reason */}
                <div className={fieldGroupStyles}>
                    <label className={labelStyles} htmlFor="changeReason">
                        <span>Change Reason</span>
                        <span>*</span>
                    </label>
                    <input
                        {...register("changeReason", { required: true })}
                        disabled={isSubmitting}
                        name="changeReason"
                        className={fieldStyles}
                    />
                    {errors.changeReason && <span className={errorStyles}>required</span>}
                </div>

                {/* Change Request Link */}
                <div className={fieldGroupStyles}>
                    <label className={labelStyles} htmlFor="changeRequestLink">
                        <span>Change Request Link</span>
                        <span>*</span>
                    </label>
                    <input
                        {...register("changeRequestLink", { required: true })}
                        required={true}
                        data-testid="changeRequestLink"
                        disabled={isSubmitting}
                        className={fieldStyles}
                        defaultValue={budget?.changeRequestLink}
                    />
                    {errors.changeRequestLink && <span className={errorStyles}>required</span>}
                </div>

                {/* Labels */}
                <div className={`${fieldGroupStyles} flex-col`}>
                    <label className={labelStyles} htmlFor="label">
                        <span>Label</span>
                        <span>*</span>
                    </label>

                    <input
                        {...register("label", { required: true })}
                        disabled={isSubmitting}
                        className={fieldStyles}
                        name="label"
                        required={true}
                    />
                    {errors.label && <span className={errorStyles}>required</span>}
                </div>

                {/* Monthly Budget */}
                <div className={`${fieldGroupStyles} pt-4`}>
                    <CurrencyField
                        disabled={isSubmitting}
                        className={currencyFieldStyles}
                        defaultValue={budget?.targetSpend}
                        label="Monthly Budget"
                        max={maxTargetSpend}
                        isRequired={true}
                        touched={touchedFields}
                        errors={errors.monthlyBudget}
                        field={monthlyBudget}
                    />
                </div>

                <div className={fieldGroupStyles}>
                    <label className={labelStyles} htmlFor="digadServiceLaunchedOn">
                        <span>DigAd Services Launched On</span>
                        <span className="text-xs text-gray"> Optional Ford</span>
                    </label>
                    <input
                        data-testid="digad_service_launched_on"
                        disabled={isSaving}
                        readOnly={true}
                        className={fieldStyles}
                        onClick={() => setShowLaunchedOnPicker(true)}
                        placeholder="mm/dd/yyyy"
                        {...register("digadServiceLaunchedOn", { required: false })}
                    />
                </div>

                {!!showLaunchedOnPicker && (
                    <div className="max-w-md z-10 absolute rounded overflow-hidden shadow-lg" ref={datepickerRef}>
                        <Calendar
                            data-testid={"digadServiceLaunchedOn"}
                            date={now}
                            onChange={(date) => {
                                date && setValue("digadServiceLaunchedOn", (date as moment.Moment).format(dateFormat));
                            }}
                        />
                    </div>
                )}

                {/* Buttons  */}
                <div className="flex items-center justify-between ">
                    <div className="flex ">
                        <Button
                            disabled={isSubmitting || !isDirty}
                            busy={isSubmitting}
                            type="submit"
                            styleType="primary"
                            styles={"flex items-center"}
                        >
                            {(isSaving || loadingBudget) && (
                                <RefreshIcon className={`w-5 h-5 rotate-fast fill-current`} />
                            )}
                            <span className={isSaving || loadingBudget ? "ml-2" : ""}>
                                {!loadingBudget && !isSaving && <>{budget?.id ? "Update" : "Create"}</>}
                                {loadingBudget ? "Loading" : ""}
                                {isSaving && <>{budget?.id ? "Updating" : "Creating"}</>} Budget
                            </span>
                        </Button>
                    </div>
                    <Link
                        to={cancelPath || ""}
                        className="inline-block align-baseline font-bold text-blue-500 hover:text-blue-800 mx-4 px-4"
                    >
                        Cancel
                    </Link>
                </div>

                {/* Error message fallback */}

                {apiErrors && (
                    <div className="flex-row items-center pt-4 px-2">{additionalErrors(apiErrors.errors)}</div>
                )}
            </form>
        </div>
    );
};

export default ManualBudgetForm;
