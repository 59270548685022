import { Reducer, combineReducers } from "redux";
import InventoryModifierConstants from "../constants/InventoryModifierConstants";
import IInventoryModifier from "../interfaces/IInventoryModifier";
import { isNumeric } from "tslint";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

const inventoryModifiers: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS:
            return {};
        case InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS:
            return action.payload.inventoryModifiers;
        case InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS_SUCCESS:
            return action.payload;
        case InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS_SUCCESS:
        case InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS_FAILURE:
            return action.payload.inventoryModifiers
                .filter((modifier: IInventoryModifier) => {
                    // If there is no ID on the element, it doesn't really exist and shouldn't be stored.
                    return !!modifier.id;
                })
                .map((modifier: IInventoryModifier) => {
                    return { ...modifier, dirty: false, new: false };
                });
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const loadedAt: Reducer<any> = (state = null, action) => {
    switch (action.type) {
        case InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS:
            return new Date();
        default:
            return state;
    }
};

const loading: Reducer<any> = (state = false, action) => {
    switch (action.type) {
        case InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS:
            return true;
        case InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS_SUCCESS:
        case InventoryModifierConstants.REQUEST_INVENTORY_MODIFIERS_FAILURE:
            return false;
        default:
            return state;
    }
};

const saving: Reducer<any> = (state = false, action) => {
    switch (action.type) {
        case InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS:
            return true;
        case InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS_SUCCESS:
        case InventoryModifierConstants.SAVE_INVENTORY_MODIFIERS_FAILURE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    inventoryModifiers,
    loadedAt,
    loading,
    saving
});
