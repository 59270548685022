import React, { SVGProps } from "react";
import Svg from "./Svg";

const VisibleIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M13 6C8.455 6 4.59 8.786 3 12.741a10.753 10.753 0 0 0 10 6.741c4.545 0 8.41-2.786 10-6.74A10.753 10.753 0 0 0 13 6zm0 11.237c-2.5 0-4.545-2.023-4.545-4.496 0-2.473 2.045-4.495 4.545-4.495s4.545 2.022 4.545 4.495c0 2.473-2.045 4.496-4.545 4.496zm0-7.192c-1.5 0-2.728 1.214-2.728 2.696 0 1.482 1.228 2.697 2.728 2.697 1.5 0 2.728-1.215 2.728-2.697S14.5 10.045 13 10.045z" />
    </Svg>
);

export default VisibleIcon;
