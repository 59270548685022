export default {
    CREATE_REPORT_JOBS: "CREATE_REPORT_JOBS",
    CREATE_REPORT_JOBS_SUCCESS: "CREATE_REPORT_JOBS_SUCCESS",
    FETCH_REPORT_LIST_SUCCESS_EMPTY: "FETCH_REPORT_LIST_SUCCESS_EMPTY",
    CREATE_REPORT_JOBS_FAILURE: "CREATE_REPORT_JOBS_FAILURE",
    FETCH_REPORT_LIST: "FETCH_REPORT_LIST",
    FETCH_REPORT_LIST_SUCCESS: "FETCH_REPORT_LIST_SUCCESS",
    FETCH_REPORT_LIST_FAILURE: "FETCH_REPORT_LIST_FAILURE",
    ACTION_TYPE_IDENTIFIER: "OEM_REPORTS",
    REPORT_CODE_100: "CREATED",
    REPORT_CODE_200: "QUEUED",
    REPORT_CODE_300: "PROCESSING",
    REPORT_CODE_400: "COMPLETED",
    REPORT_CODE_500: "VERIFYING",
    REPORT_CODE_600: "VERIFIED",
    REPORT_CODE_800: "FAILED",
    REPORT_CODE_900: "CANCELLED"
} as any;
