import { pullDiSalesDataClicked } from "../../../../actions/salesData";
import trackEvent from "../../../../utils/Analytics/trackEvent";

function* pullDataFromDiClicked(action: ReturnType<typeof pullDiSalesDataClicked>) {
    yield trackEvent({
        category: "Sales Map Index Page",
        action: `pull_data_from_DI_clicked_${action.clientId}`,
        label: "Facebook Report Download"
    });
}

export default {
    pullDataFromDiClicked
};
