import { takeEvery } from "redux-saga/effects";
import { multipartCallApi } from "../../middleware/api";
import SalesDataImportConstants from "../../constants/SalesDataImportConstants";

const uploadSalesDataImports = function* ({ payload }: any) {
    const { headers, fileInput, callback, setErrors, clientId } = payload;

    let formData = new FormData();
    formData.append("sales-file", fileInput.files[0]);

    for (const key in headers) {
        formData.append(`headers[${key}]`, headers[key]);
    }

    try {
        yield multipartCallApi(`/clients/${clientId}/sales-file-upload`, formData, "POST");
        callback();
    } catch (e) {
        setErrors(e);
    }
};

export { uploadSalesDataImports };

function* salesDataSaga() {
    yield takeEvery(SalesDataImportConstants.UPLOAD_SALES_DATA, uploadSalesDataImports);
}

export default salesDataSaga;
