import React from "react";
import { Field, ErrorMessage } from "formik";

const baseStyle =
    "pl-6 appearance-none block w-full text-right text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-200 focus:border-gray";
const errorStyle = `${baseStyle}border-red-500 mb-3`;

interface IErrors {
    [key: string]: any;
}

interface ITouched {
    [key: string]: any;
}

interface IProps {
    className?: string;
    disabled?: boolean;
    label: string;
    max?: number;
    fieldName: string;
    isRequired: boolean;
    touched?: ITouched;
    errors?: IErrors;
    defaultValue?: string;
    register?: (fieldName: string, registerProps?: any) => any;
}

const CurrencyField: React.FunctionComponent<IProps> = ({
    className,
    label,
    fieldName,
    isRequired = false,
    touched,
    errors,
    max,
    disabled,
    register,
    defaultValue
}) => {
    //Formik compatibility
    const Component = (props: any) => (
        <>
            {register ? (
                <input
                    {...props}
                    {...register(fieldName, { required: isRequired, min: 0, max, pattern: /^[-+]?[0-9]*\.?[0-9]+$/ })}
                />
            ) : (
                <Field {...props} />
            )}
        </>
    );
    return (
        <div className={className}>
            <label className="block uppercase tracking-wider text-gray-800 font-bold mb-2" htmlFor="monthlyBudget">
                <span>{label}</span>
                {isRequired ? <span>*</span> : <span className="text-xs text-gray"> Optional</span>}
            </label>
            <div className="relative">
                <Component
                    disabled={disabled}
                    defaultValue={defaultValue}
                    name={fieldName}
                    className={
                        touched && errors
                            ? touched[fieldName] && errors[fieldName]
                                ? errorStyle
                                : baseStyle
                            : baseStyle
                    }
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 ">
                    <span className="uppercase text-gray-800 font-bold ">$</span>
                </div>
            </div>
            {errors && !!register && (
                <>
                    {errors.type === "required" && (
                        <span className="text-red-500 text-sm italic uppercase">Required</span>
                    )}
                    {errors.type === "max" && (
                        <span className="text-red-500 text-sm italic uppercase">
                            Maximum budget allocation is {max}
                        </span>
                    )}
                    {errors.type === "min" && (
                        <span className="text-red-500 text-sm italic uppercase">Minimum budget allocation is 0</span>
                    )}
                    {errors.type === "pattern" && (
                        <span className="text-red-500 text-sm italic uppercase">Only numbers are allowed</span>
                    )}
                </>
            )}
            {errors && !register && (
                <ErrorMessage component="span" className="text-red-500 text-sm italic uppercase" name={fieldName} />
            )}
        </div>
    );
};

interface ICurrencyInputProps {
    disabled?: boolean;
    fieldName: string;
    touched?: ITouched;
    errors?: IErrors;
    register?: (fieldName: string, registerProps?: any) => any;
}
const CurrencyInput: React.FC<ICurrencyInputProps> = ({ disabled, fieldName, touched, errors, register }) => {
    const Component = (props: any) => (register ? <input {...props} {...register(fieldName)} /> : <Field {...props} />); //Formik compatibility
    return (
        <>
            <div className="relative w-32">
                <Component
                    disabled={disabled}
                    name={fieldName}
                    className={
                        touched && errors
                            ? touched[fieldName] && errors[fieldName]
                                ? errorStyle
                                : baseStyle
                            : baseStyle
                    }
                    {...((register && register(fieldName)) || {})}
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 ">
                    <span className="uppercase text-gray-800 font-bold ">$</span>
                </div>
            </div>
            {errors && !!register && <span className="text-red-500 text-sm italic uppercase">Required</span>}
            {errors && !register && (
                <ErrorMessage component="span" className="text-red-500 text-sm italic uppercase" name={fieldName} />
            )}
        </>
    );
};

export default CurrencyField;

export { CurrencyInput };
