import React, { KeyboardEvent, useState } from "react";
import Creatable from "react-select";

interface IOptions {
    label: string;
    value: string;
}

interface IProps {
    name?: string;
    ariaLabel?: string;
    options: IOptions[];
    value: IOptions | IOptions[];
    onChange?: any;
    onBlur?: any;
    isMulti?: boolean;
    placeholder?: string;
    className?: string;
    readOnly?: boolean;
    isValid?: boolean;
}

const defaultClasses = `w-full text-base`;
const errorClasses = `border-red`;
const readOnlyClasses = `text-gray-800 bg-gray-400`;

const MultiSelectInput: React.FunctionComponent<IProps> = ({
    name,
    ariaLabel,
    options,
    value,
    onChange,
    onBlur,
    placeholder,
    className,
    isMulti = true,
    readOnly = false,
    isValid = true
}) => {
    const [inputValue, setInputValue] = useState("");

    const defaultClassName = `${defaultClasses} ${readOnly && readOnlyClasses} ${!isValid && errorClasses}`;

    const customStyles = {
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: "39px",
            padding: 0
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            padding: "0 .75rem",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        }),
        input: (provided: any, state: any) => ({
            ...provided
        })
    };

    // We should handle the on blur event here and add the value to the options list
    // We should also handle the keypress event for enter and tab to add items to the list.

    const handleTabOrEnter = (event: KeyboardEvent) => {
        if (event.key !== "Enter" && event.key !== "Tab") {
            return;
        }
        event.preventDefault();

        addInputValueToOptions();
    };

    const addInputValueToOptions = () => {
        const newValue = {
            label: inputValue,
            value: inputValue
        };

        if (isMulti && Array.isArray(value)) {
            onChange([...value, newValue]);
            setInputValue("");
        } else {
            onChange(newValue);
        }
    };

    const handleBlurValue = (e: any) => {
        if (!inputValue) {
            return;
        }

        if (onBlur) {
            onBlur(e);
        }
        addInputValueToOptions();
    };

    return (
        <Creatable
            name={name}
            aria-label={ariaLabel}
            isMulti={isMulti}
            classNamePrefix={`${name}`}
            options={options}
            getOptionValue={(option: IOptions) => option.value}
            getOptionLabel={(option: IOptions) => option.label}
            value={value}
            onChange={onChange}
            onInputChange={setInputValue}
            onKeyDown={handleTabOrEnter}
            inputValue={inputValue}
            onBlur={handleBlurValue}
            className={`${defaultClassName} ${className}`}
            backspaceRemovesValue={true}
            placeholder={placeholder}
            styles={customStyles}
            isDisabled={readOnly}
            data-testid={name}
            components={{
                DropdownIndicator: null
            }}
            isClearable={false}
        />
    );
};

export default MultiSelectInput;
