import React, { SVGProps } from "react";
import Svg from "./Svg";

const TimeIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M12.992 4C8.576 4 5 7.584 5 12C5 16.416 8.576 20 12.992 20C17.416 20 21 16.416 21 12C21 7.584 17.416 4 12.992 4ZM13 18.4C9.464 18.4 6.6 15.536 6.6 12C6.6 8.464 9.464 5.6 13 5.6C16.536 5.6 19.4 8.464 19.4 12C19.4 15.536 16.536 18.4 13 18.4Z" />
        <path d="M13.25 8H12V13.2459L16.375 16L17 14.9246L13.25 12.5902V8Z" />
    </Svg>
);

export default TimeIcon;
