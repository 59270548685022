import { connect } from "react-redux";
import { markAsRead, fetchNotifications } from "../../actions/notificationActions";
import NotificationList from "./NotificationList";

const mapStateToProps = (state: any) => ({
    currentUser: state.currentUser
});

const mapDispatchToProps = (dispatch: any) => ({
    markNotificationAsRead(notification: any) {
        dispatch(markAsRead(notification));
    },
    updateNotifications() {
        dispatch(fetchNotifications("/user-notifications"));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
