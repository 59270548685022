import { isEmpty } from "lodash";
import React from "react";

const baseStyle =
    "pl-6 appearance-none block w-full text-right text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-200 focus:border-gray";
const errorStyle = `${baseStyle} border-red-500 mb-3`;

interface IErrors {
    [key: string]: any;
}

interface ITouched {
    [key: string]: any;
}

interface IProps {
    className?: string;
    disabled?: boolean;
    label: string;
    max?: number;
    fieldName?: string;
    isRequired: boolean;
    touched?: ITouched;
    errors?: IErrors;
    defaultValue?: string;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
    ref?: any;
    field?: any;
    testId?: string;
}

const CurrencyField: React.FunctionComponent<IProps> = ({
    className,
    label,
    fieldName = "",
    isRequired = false,
    touched,
    errors,
    max,
    disabled,
    defaultValue,
    testId,
    onChange,
    onBlur,
    ref,
    field
}) => {
    const isTouched = !!touched && touched[fieldName];
    const hasErrors = !isEmpty(errors);
    const styles = hasErrors ? errorStyle : baseStyle;
    return (
        <div className={className}>
            <label className="block uppercase tracking-wider text-gray-800 font-bold mb-2" htmlFor="monthlyBudget">
                <span>{label}</span>
                {isRequired ? <span>*</span> : <span className="text-xs text-gray"> Optional</span>}
            </label>
            <div className="relative">
                <input
                    data-testid={`${testId}`}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    name={fieldName}
                    className={styles}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    {...field}
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 ">
                    <span className="uppercase text-gray-800 font-bold ">$</span>
                </div>
            </div>
            {errors && (
                <>
                    {errors.type === "required" && (
                        <span className="text-red-500 text-sm italic uppercase">Required</span>
                    )}
                    {errors.type === "max" && (
                        <span className="text-red-500 text-sm italic uppercase">
                            Maximum budget allocation is {max}
                        </span>
                    )}
                    {errors.type === "min" && (
                        <span className="text-red-500 text-sm italic uppercase">Minimum budget allocation is 0</span>
                    )}
                    {errors.type === "pattern" && (
                        <span className="text-red-500 text-sm italic uppercase">Only numbers are allowed</span>
                    )}
                </>
            )}
        </div>
    );
};

export default CurrencyField;
