import { schema } from "normalizr";
import _ from "lodash";
import { camelize } from "humps";
import { siteLink, siteLinksArray } from "./schemas/sitelinks";
import CLIENT_SCHEMA from "./schemas/client";

import { templateConditional } from "./schemas/dcTemplateConditional";
// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tre`
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

const defaultParameters = [
    "Date",
    "model_discounts",
    "model_lowest_miles",
    "model_lowest_price",
    "model_monthly_payments",
    "model_lease_payments",
    "colors_count",
    "trims_count",
    "duplicate_count"
];
const dynamicTextCampaign = new schema.Entity(
    "dynamicTextCampaign",
    {},
    {
        processStrategy: (value) => {
            const parameters = _.uniq([...value.parameters, ...defaultParameters]);
            return { ...value, parameters };
        }
    }
);
const dynamicCampaignExpandedTextTemplate = new schema.Entity(
    "dynamicCampaignTemplate",
    {},
    {
        processStrategy: (value) => {
            let parts = {};

            if (value.dynamicTextAdTemplateParts !== undefined) {
                parts = {
                    headline1: [],
                    headline2: [],
                    description: [],
                    path: [],
                    finalUrl: []
                };
                const orderedParts = _.orderBy(value.dynamicTextAdTemplateParts, "order");
                parts = {
                    ...parts,
                    ..._.groupBy(orderedParts, (value) => {
                        return camelize(value.field);
                    })
                };
                delete value.dynamicTextAdTemplateParts;
            }

            const label = value.label || "";
            const individualizedParameters = value.individualizedParameters || "";
            return { ...value, individualizedParameters, label, ...parts };
        }
    }
);
const dynamicCampaignTextTemplate = new schema.Entity(
    "dynamicCampaignTemplate",
    {},
    {
        processStrategy: (value) => {
            let parts = {};
            if (value.dynamicTextAdTemplateParts !== undefined) {
                let orderedParts = _.orderBy(value.dynamicTextAdTemplateParts, "order");
                parts = {
                    headline1: [],
                    headline2: [],
                    description: [],
                    displayUrl: [],
                    finalUrl: []
                };
                parts = {
                    ...parts,
                    ..._.groupBy(orderedParts, (value) => {
                        return camelize(value.field);
                    })
                };
                delete value.dynamicTextAdTemplateParts;
            }
            const label = value.label || "";
            const individualizedParameters = value.individualizedParameters || "";
            return { ...value, label, individualizedParameters, ...parts };
        }
    }
);
const dynamicTextAdTemplatePart = new schema.Entity(
    "dynamicTextAdTemplateParts",
    {},
    {
        processStrategy: (value, parent, key) => ({
            new: false,
            dirty: false,
            valid: true,
            conditions: { conditions: [], name: "" },
            ...value,
            name: value.name || ""
        })
    }
);

const templatePartConditional = new schema.Entity(
    "dtaTemplatePartConditionals",
    {},
    {
        processStrategy: (data, parent, key) => {
            const fields = { ...data.value };
            const field = fields.parameter;
            const value = fields.value;
            const conditional = fields.comparator;
            delete data.value;
            return { ...data, field, value, conditional };
        }
    }
);

dynamicCampaignExpandedTextTemplate.define({
    dynamicCampaignAdTemplateConditionals: [templateConditional],
    headline1: [dynamicTextAdTemplatePart],
    headline2: [dynamicTextAdTemplatePart],
    description: [dynamicTextAdTemplatePart],
    finalUrl: [dynamicTextAdTemplatePart],
    path: [dynamicTextAdTemplatePart]
});
dynamicCampaignTextTemplate.define({
    dynamicCampaignAdTemplateConditionals: [templateConditional],
    headline: [dynamicTextAdTemplatePart],
    description1: [dynamicTextAdTemplatePart],
    description2: [dynamicTextAdTemplatePart],
    finalUrl: [dynamicTextAdTemplatePart],
    displayUrl: [dynamicTextAdTemplatePart]
});

dynamicTextAdTemplatePart.define({
    dynamicTextAdTemplatePartConditionals: [templatePartConditional]
});
const territory = new schema.Entity("territories");
const notification = new schema.Entity("notifications");
const gaProfile = new schema.Entity(
    "gaProfiles",
    {},
    {
        idAttribute: "profileId",
        processStrategy: (data) => {
            return {
                ...data,
                profileName: `${data.propertyId} - ${data.profileName}`
            };
        }
    }
);
const fuelInventory = new schema.Entity("inventoryFuelFeeds");

const user = new schema.Entity("user");
const roles = new schema.Entity("roles");
const Schemas = {
    TERRITORY: territory,
    TERRITORY_ARRAY: { data: [territory] },
    INVENTORY_FUEL_FEED: fuelInventory,
    INVENTORY_FUEL_FEED_ARRAY: { data: [fuelInventory] },
    NOTIFICATION: notification,
    NOTIFICATION_ARRAY: { data: [notification] },
    SITE_LINK: siteLink,
    SITE_LINKS_ARRAY: siteLinksArray,
    DYNAMIC_TEXT_CAMPAIGN: dynamicTextCampaign,
    DYNAMIC_TEXT_CAMPAIGN_ARRAY: { data: [dynamicTextCampaign] },
    DYNAMIC_CAMPAIGN_TEMPLATE: new schema.Union(
        {
            eta: dynamicCampaignExpandedTextTemplate,
            text: dynamicCampaignTextTemplate
        },
        "adType"
    ),
    DYNAMIC_CAMPAIGN_TEMPLATE_ARRAY: {
        data: new schema.Array(
            {
                eta: dynamicCampaignExpandedTextTemplate,
                text: dynamicCampaignTextTemplate
            },
            "adType"
        )
    },
    DYNAMIC_TEXT_AD_TEMPLATE_PART: dynamicTextAdTemplatePart,
    DTC_TEMPLATE_CONDITIONAL: templateConditional,
    TEMPLATE_PART_CONDITIONAL: templatePartConditional,
    ARTISAN_COMMAND: new schema.Entity("artisanCommand"),
    GA_PROFILES_ARRAY: { data: [gaProfile] },
    ADWORDS_CAMPAIGN_ARRAY: CLIENT_SCHEMA.ADWORDS.CAMPAIGN_ARRAY,
    USER: user,
    LOGIN: new schema.Entity("token"),
    USER_ARRAY: { data: [user] },
    USER_ROLES_ARRAY: { data: [roles] }
};
export default Schemas;
