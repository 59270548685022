import React, { FunctionComponent } from "react";
import Button, { SECONDARY_BUTTON } from "../Shared/Button";
import CalendarIcon from "../Shared/Icons/CalendarIcon";

interface IDateFilter {
    end?: string;
    start?: string;
    toggleCalendar(): void;
}

const DateFilter: FunctionComponent<IDateFilter> = ({ end, start, toggleCalendar }) => {
    return (
        <Button
            onClick={toggleCalendar}
            styleType={SECONDARY_BUTTON}
            styles="text-base border-blue-500"
            style={{ paddingLeft: ".5rem", paddingRight: ".5rem", margin: 0, width: "100%" }}
        >
            <CalendarIcon className="w-6 h-6 mx-2 inline align-top" />
            <span>{start}</span> - <span>{end}</span>
        </Button>
    );
};

export default DateFilter;
