import * as Yup from "yup";
import { undef } from "@redux-saga/is";

Yup.addMethod(Yup.string, "maxWithoutBrackets", function (max, message) {
    // This block adds custom string validation to the Schema counting characters only if there are no {{}} variables
    // @ts-ignore typescript doesn't allow for using fat functions for isloting scopes... Should check for an alternative method of adding this checking.
    return Yup.string().test("", message, function (value: string) {
        const { path, createError } = this;
        const hasBrackets = value.includes("{{");
        return hasBrackets || value.length <= max || createError({ message });
    });
});

const SpecialOfferTemplateValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, "The Special Offer Template Name must be between 1 and 255 characters.")
        .max(255, "The Special Offer Template Name must be between 1 and 255 characters.")
        .required("This field is required."),
    finalUrl: Yup.string()
        .min(1, "The Special Offer Template Name must be between 1 and 2048 characters.")
        .max(2048, "The Special Offer Template Name must be between 1 and 2048 characters.")
        .required("This field is required."),
    parameters: Yup.array().of(
        Yup.object().shape({
            Year: Yup.lazy((value: any) => {
                if (value !== undefined) {
                    return Yup.number()
                        .typeError("The Year field must be a number.")
                        .min(1800, "The Year field must be at least 1800.")
                        .required("This field is required");
                }
                return Yup.mixed().notRequired();
            }),
            Make: Yup.lazy((value: any) => {
                if (value !== undefined) {
                    return Yup.string()
                        .min(1, "The Make field must be between 1 and 100 characters.")
                        .max(100, "The Make field must be between 1 and 100 characters.")
                        .required("This field is required.");
                }
                return Yup.mixed().notRequired();
            }),
            Model: Yup.lazy((value: any) => {
                if (value !== undefined) {
                    return Yup.string()
                        .min(1, "The Model field must be between 1 and 100 characters.")
                        .max(100, "The Model field must be between 1 and 100 characters.")
                        .required("This field is required.");
                }
                return Yup.mixed().notRequired();
            }),
            Trim: Yup.lazy((value: any) => {
                if (value !== undefined) {
                    return Yup.string()
                        .min(1, "The Trim field must be between 1 and 100 characters.")
                        .max(100, "The Trim field must be between 1 and 100 characters.")
                        .required("This field is required.");
                }
                return Yup.mixed().notRequired();
            }),
            price: Yup.number()
                .typeError("The Price field must be a number.")
                .min(0, "The Price field must be at least 0."),
            cash: Yup.number()
                .typeError("The Cash field must be a number.")
                .min(0, "The Cash field must be at least 0."),
            duration: Yup.number()
                .typeError("The Duration field must be a number.")
                .min(0, "The Duration field must be at least 0."),
            down_payment: Yup.number()
                .typeError("The Down Payment field must be a number.")
                .min(0, "The Down Payment field must be at least 0."),
            apr: Yup.number().typeError("The APR field must be a number.").min(0, "The APR field must be at least 0.")
        })
    ),
    headlines: Yup.array()
        .of(
            Yup.object().shape({
                value: Yup.string()
                    .min(1, "The H1 field must be between 1 and 30 characters.")
                    .maxWithoutBrackets(30, "The H1 field must be between 1 and 30 characters.")
                    .required("This field is required."),
                pinnedField: Yup.string().nullable()
            })
        )
        .min(1, "At least one headline is required.")
        .max(15, "No more than 15 headlines are allowed."),
    descriptions: Yup.array()
        .of(
            Yup.object().shape({
                value: Yup.string()
                    .min(1, "The Description 1 field must be between 1 and 90 characters.")
                    .maxWithoutBrackets(90, "The Description 1 field must be between 1 and 90 characters.")
                    .required("This field is required."),
                pinnedField: Yup.string().nullable()
            })
        )
        .min(1, "At least one description is required.")
        .max(4, "No more than 4 descriptions are allowed."),
    path: Yup.array().of(
        Yup.string()
            .min(1, "The Path field must be between 1 and 30 characters.")
            .maxWithoutBrackets(30, "The Path field must be between 1 and 30 characters.")
            .required("This field is required.")
    ),
    startDate: Yup.date().max(Yup.ref("expiresAt"), "Start date must be before the expires at date.").nullable(),
    expiresAt: Yup.date()
        .when("startDate", (startDate: string | null | undefined) => {
            if (startDate !== null && startDate !== undefined) {
                return Yup.date().min(Yup.ref("startDate"), "Expiration date must be after the start date.");
            }
            return Yup.date();
        })
        .required("This field is required."),
    conditionals: Yup.array().of(Yup.string())
});

export default SpecialOfferTemplateValidationSchema;
