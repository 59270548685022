import { parameters } from "../parameters";

export function filterParametersWeCanShowBasedOnMetaTemplate(metaParameters: string[]) {
    return parameters
        .map((value) => ({
            value,
            label: value
        }))
        .filter((value) => metaParameters?.includes(value.value));
}
