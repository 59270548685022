import React from "react";
import dealerSetupRoutes from "../DealerSetupRoutes";
import DealerSetupSidebarItem from "./DealerSetupSidebarItem";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";

interface IProps {
    formValues: IFormValues;
    changePageRef: any;
}

const DealerSetupSidebar: React.FunctionComponent<IProps> = ({ formValues, changePageRef }) => {
    return (
        <div
            className="w-1/4 h-auto sidebar-container bg-gray-200"
            style={{
                maxWidth: "275px"
            }}
        >
            {dealerSetupRoutes.map((item) => {
                const { title, subTitles, path } = item;
                return (
                    <DealerSetupSidebarItem
                        key={title}
                        path={path}
                        title={title}
                        subTitles={subTitles}
                        isTouched={formValues[path] && formValues[path].isTouched}
                        isValid={formValues[path] && formValues[path].isValid}
                        isDisabled={formValues[path] && formValues[path].isDisabled}
                        changePageRef={changePageRef}
                    />
                );
            })}
        </div>
    );
};

export default DealerSetupSidebar;
