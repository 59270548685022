import React, { SVGProps } from "react";
import Svg from "./Svg";

const VideoIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M18.714 10.888V7.085c0-.598-.5-1.085-1.111-1.085H4.112C3.5 6 3 6.487 3 7.085v10.861c0 .599.5 1.09 1.112 1.09h13.49c.612 0 1.112-.491 1.112-1.085v-3.804L23 18.491V6.545l-4.286 4.343z" />
    </Svg>
);

export default VideoIcon;
