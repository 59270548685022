import React from "react";
import { Link } from "react-router-dom";

interface IProps {
    to: string;
    Icon: any;
    className?: string;
}

const TableActionLink = ({ to, Icon, className = "" }: IProps) => {
    return (
        <Link to={to} className={`group rounded hover:bg-gray-200 ${className}`}>
            <Icon className="w-6 h-6 group-hover:text-gray-600 inline-block" />
        </Link>
    );
};

export default TableActionLink;
