import React from "react";
import { Link } from "react-router-dom";
import RightSideForm from "./RightSideForm";
import { isEmpty, zipObjectDeep, keys, values } from "lodash";
import * as yup from "yup";
import { yupToFormErrors } from "formik";
import { IClient } from "../../../interfaces/IClient";
import RemarketingExportTable from "./RemarketingExportTable";
import { IInventoryExport } from "../../../interfaces/IRemarketing";
import TextField from "../../Shared/Form/Blocks/TextField";
import SelectField from "../../Shared/Form/Blocks/SelectField";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";
import Button from "../../Shared/Button";
import TrashIcon from "../../Shared/Icons/TrashIcon";
import { IInventoryExportState } from "../../../sagas/Client/remarketingSagas";

export const TYPE_EDIT = "edit";
export const TYPE_CREATE = "create";
export type FORM_TYPES = "edit" | "create";

const getAvailableInventoryTypesForClient = (client: IClient, type: FORM_TYPES, fuelInventoryId: any) => {
    const types = [];
    if (client.inventoryApiKey !== null) {
        types.push({ value: "DI", label: "DI Inventory" });
    }
    if (client.carsSellerId !== null) {
        types.push({ value: "CARS", label: "Cars.com" });
    }
    return types;
};

const schema = yup.object().shape({
    name: yup.string().required().max(255),
    inventorySource: yup.string().required(),
    fuelInventoryId: yup.mixed(),
    conditions: yup.array().of(
        yup.object().shape({
            parameter: yup.string().required(),
            comparator: yup.string().required(),
            value: yup.string().trim().required()
        })
    ),
    ignoredImages: yup.array().of(yup.string().required())
});

const validate = async (data: any) => {
    try {
        await schema.validate(data, { abortEarly: false });
    } catch (e) {
        return yupToFormErrors(e);
    }
    return {};
};

interface ICondition {
    parameter: string;
    comparator: string;
    value: string;
}

interface IProps {
    inventoryExport: IInventoryExport | IInventoryExportState;
    clientId: number;
    client: IClient;
    saving: boolean;
    cancelPath: string;
    remarketingId?: number;
    type: FORM_TYPES;
    onSave(value: IState, clientId: number, setErrors: (errors: any) => void): void;
}

interface IState {
    inventorySource: string;
    currency: string;
    mileageUnit: string;
    ignoredImages: string[];
    saving: boolean;
    fuelInventoryId: any;
    fuelInventory: any;
    name: string;
    conditions: { parameter: string; comparator: string; value: string }[] | [];
    errors: {
        inventorySource?: string;
        name?: string;
        fuelInventoryId?: string;
        currency?: string;
        mileageUnit?: string;
        conditions?: string;
        ignoredImages?: string;
        facebookExport?: string;
    };
}

class Form extends React.Component<IProps, IState> {
    state = {
        id: this.props.inventoryExport.id,
        inventorySource: this.props.inventoryExport.inventorySource || "DI",
        currency: this.props.inventoryExport.currency || "USD",
        mileageUnit: this.props.inventoryExport.mileageUnit || "MI",
        errors: {
            inventorySource: undefined,
            name: undefined,
            fuelInventoryId: undefined,
            currency: undefined,
            mileageUnit: undefined,
            conditions: undefined,
            ignoredImages: undefined,
            facebookExport: undefined,
            ...this.props.inventoryExport.errors
        },
        ignoredImages: this.props.inventoryExport.ignoredImages || [],
        saving: false,
        conditions: this.props.inventoryExport.conditions || [],
        fuelInventoryId: this.props.inventoryExport.fuelInventoryId || "",
        fuelInventory: this.props.inventoryExport.fuelInventory || "",
        name: this.props.inventoryExport.name || "",
        deleting: false
    };

    constructor(props: IProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.onCurrencyChange = this.onCurrencyChange.bind(this);
        this.onMileageUnitChange = this.onMileageUnitChange.bind(this);
        this.addCondition = this.addCondition.bind(this);
        this.removeCondition = this.removeCondition.bind(this);
        this.updateCondition = this.updateCondition.bind(this);
        this.addIgnoreImage = this.addIgnoreImage.bind(this);
        this.removeIgnoreImage = this.removeIgnoreImage.bind(this);
        this.updateIgnoreImage = this.updateIgnoreImage.bind(this);
        this.setFuelInventoryId = this.setFuelInventoryId.bind(this);
        this.onInventorySourceChange = this.onInventorySourceChange.bind(this);
        this.setApiErrors = this.setApiErrors.bind(this);
    }

    async validate() {
        const errors = await validate(this.state);
        this.setState({ errors });
    }

    setApiErrors(errors: any) {
        this.setState({ ...this.state, saving: false, errors: zipObjectDeep(keys(errors), values(errors)) });
    }

    async save(e: React.FormEvent) {
        e.preventDefault();
        const errors = await validate(this.state);
        if (Object.keys(errors).length === 0) {
            this.props.onSave(this.state, this.props.clientId, this.setApiErrors);
            this.setState({ saving: true });
        } else {
            this.setState({ errors });
        }
    }

    onChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const updatedClient = { ...this.state, [e.target.name]: e.target.value };
        const errors = {};
        this.setState({ ...updatedClient, errors }, this.validate);
    }

    onCurrencyChange(e: any) {
        const currency = e.value;
        this.setState({ currency }, this.validate);
    }

    onMileageUnitChange(e: any) {
        const mileageUnit = e.value;
        this.setState({ mileageUnit }, this.validate);
    }

    onInventorySourceChange(e: any) {
        const inventorySource = e.value;
        this.setState({ inventorySource }, this.validate);
    }

    componentDidUpdate(prevProps: IProps) {
        const { inventoryExport } = this.props;
        if (inventoryExport && prevProps.inventoryExport !== inventoryExport) {
            this.setState({ saving: Boolean(inventoryExport.saving) });
        }
    }

    addCondition() {
        const conditions = [...this.state.conditions, { parameter: "", comparator: "", value: "" }];
        this.setState({ conditions }, this.validate);
    }
    removeCondition(idx: number) {
        const conditions = [...this.state.conditions.slice(0, idx), ...this.state.conditions.slice(idx + 1)];
        this.setState({ conditions }, this.validate);
    }
    updateCondition(idx: number, item: ICondition) {
        const conditions = [...this.state.conditions.slice(0, idx), item, ...this.state.conditions.slice(idx + 1)];
        this.setState({ conditions }, this.validate);
    }

    addIgnoreImage(e: any) {
        e.preventDefault();
        const ignoredImages = [...this.state.ignoredImages, ""];
        this.setState({ ignoredImages }, this.validate);
    }
    removeIgnoreImage(idx: number) {
        const ignoredImages = [...this.state.ignoredImages.slice(0, idx), ...this.state.ignoredImages.slice(idx + 1)];
        this.setState({ ignoredImages }, this.validate);
    }
    updateIgnoreImage(idx: number, item: string) {
        const ignoredImages = [
            ...this.state.ignoredImages.slice(0, idx),
            item,
            ...this.state.ignoredImages.slice(idx + 1)
        ];
        this.setState({ ignoredImages }, this.validate);
    }

    isSaving() {
        return this.props.saving || this.state.saving;
    }

    setFuelInventoryId(item: any) {
        let value = {
            id: null,
            item: {}
        };
        if (item !== null) {
            value = {
                item,
                id: item.id
            };
        }
        this.setState({ fuelInventoryId: value.id, fuelInventory: value.item }, this.validate);
    }

    render() {
        const canSave = isEmpty(this.state.errors) && this.state.saving !== true;
        return (
            <form onSubmit={this.save} className="text-base">
                <div className="bg-white shadow p-4 rounded">
                    <div className="flex flex-wrap w-full">
                        <div className="w-1/4">
                            <div>
                                <TextField
                                    name="name"
                                    label="Export Name"
                                    required={true}
                                    handleChange={this.onChange}
                                    value={this.state.name || ""}
                                    errors={this.state.errors.name}
                                    className="-mt-4"
                                />

                                <SelectField
                                    data-test={"select-inventory"}
                                    name="inventorySource"
                                    label="Inventory Source"
                                    required={true}
                                    options={getAvailableInventoryTypesForClient(
                                        this.props.client,
                                        this.props.type,
                                        this.state.fuelInventoryId
                                    ).map((source) => {
                                        return {
                                            label: source.label,
                                            value: source.value
                                        };
                                    })}
                                    errors={this.state.errors.inventorySource}
                                    value={this.state.inventorySource}
                                    handleChange={this.onInventorySourceChange}
                                />
                                <SelectField
                                    name="currency"
                                    label="Currency"
                                    required={true}
                                    options={[
                                        { label: "USD", value: "USD" },
                                        { label: "CAD", value: "CAD" }
                                    ]}
                                    errors={this.state.errors.currency}
                                    value={this.state.currency || ""}
                                    handleChange={this.onCurrencyChange}
                                />
                                <SelectField
                                    name="mileageUnit"
                                    label="Mileage Unit"
                                    required={true}
                                    options={[
                                        { label: "Miles", value: "MI" },
                                        { label: "Kilometers", value: "KM" }
                                    ]}
                                    errors={this.state.errors.mileageUnit}
                                    value={this.state.mileageUnit || ""}
                                    handleChange={this.onMileageUnitChange}
                                />

                                {this.state.ignoredImages.map((url, idx) => {
                                    return (
                                        <div
                                            key={`${idx}`}
                                            className={`flex flex-wrap mb-4 ${
                                                this.state.errors.ignoredImages && this.state.errors.ignoredImages![idx]
                                                    ? "border border-red-500 p-2"
                                                    : ""
                                            }`}
                                            style={{ alignItems: "flex-end" }}
                                        >
                                            <TextField
                                                name="ignoreImages"
                                                label="Ignored Images"
                                                required={true}
                                                handleChange={(e: any) => {
                                                    this.updateIgnoreImage(idx, e.target.value);
                                                }}
                                                value={url}
                                                className="flex-1"
                                            />

                                            <button
                                                type="button"
                                                className={`bg-transparent text-gray-600 hover:text-red-600 h-full outline-none w-8 h-8 mb-2 -mr-2 ml-2`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.removeIgnoreImage(idx);
                                                }}
                                            >
                                                <TrashIcon className="w-6 h-6" />
                                            </button>
                                        </div>
                                    );
                                })}
                                <Button styleType="primary" onClick={this.addIgnoreImage}>
                                    Add
                                </Button>
                            </div>
                        </div>
                        <div className="w-3/4 flex flex-col justify-between">
                            <RightSideForm
                                addCondition={this.addCondition}
                                removeCondition={this.removeCondition}
                                updateCondition={this.updateCondition}
                                conditions={this.state.conditions}
                                errors={this.state.errors.conditions}
                                inventorySource={this.state.inventorySource}
                            />
                            <div className="flex justify-end items-center mt-4">
                                <Link to={this.props.cancelPath} className=" text-gray-800 mr-8">
                                    Cancel
                                </Link>
                                <Button styleType={"primary"} type="submit" disabled={!canSave}>
                                    Save
                                    {this.state.saving ? (
                                        <RefreshIcon className="w-6 h-6 rotate-fast float-right ml-3" />
                                    ) : (
                                        ""
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.remarketingId && (
                    <RemarketingExportTable
                        inventoryExport={this.props.inventoryExport}
                        remarketingId={this.props.remarketingId}
                        clientId={this.props.clientId}
                        client={this.props.client}
                    />
                )}
            </form>
        );
    }
}
export default Form;
