import IEntity from "../interfaces/IEntity";
import IAutomobileManufacturer, {
    IFetchManufacturerList,
    IFetchManufacturerListFailure,
    IFetchManufacturerListSuccess
} from "../interfaces/IAutomobileManufacturer";
import Constants from "../constants/AutomobileManufacturerConstants";

export const fetchManufacturerList = (): IFetchManufacturerList => ({
    type: Constants.REQUEST_AUTOMOBILE_MANUFACTURERS
});

export const fetchManufacturerListSuccess = (
    manufacturers: IEntity<IAutomobileManufacturer>
): IFetchManufacturerListSuccess => ({
    type: Constants.REQUEST_AUTOMOBILE_MANUFACTURERS_SUCCESS,
    payload: manufacturers
});

export const fetchManufacturerListFailure = (errorMessage: string): IFetchManufacturerListFailure => ({
    type: Constants.REQUEST_AUTOMOBILE_MANUFACTURERS_FAIL,
    payload: {
        errorMessage
    }
});
