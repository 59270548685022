import React from "react";
import IClient from "../../interfaces/IClient";
import IActivity from "../../interfaces/EditHistory/IActivity";
import ItemUpdated from "./HistoryItems/ItemUpdated";
import ItemGenericAction from "./HistoryItems/ItemGenericAction";
import ItemDeleted from "./HistoryItems/ItemDeleted";
import ItemRestored from "./HistoryItems/ItemRestored";
import ItemCreated from "./HistoryItems/ItemCreated";

interface IProps {
    edits: IActivity[];
    subject: IClient; // This interface list should be expanded, and should never be any.
    // Typing any for the subject is lazy bad practice and should not be tolerated. :angry_eyes:
}

const EditHistoryList = ({ edits, subject }: IProps) => (
    <div>
        {edits.map((edit, index) => {
            // Assign the subject so we can access it from the child actions
            edit.subject = subject;
            switch (edit.description) {
                case "updated":
                    return <ItemUpdated key={index} activity={edit} index={index} />;
                case "deleted":
                    return <ItemDeleted key={index} activity={edit} index={index} />;
                case "restored":
                    return <ItemRestored key={index} activity={edit} index={index} />;
                case "created":
                    return <ItemCreated key={index} activity={edit} index={index} />;
                default:
                    return <ItemGenericAction key={index} activity={edit} index={index} />;
            }
        })}
    </div>
);

export default EditHistoryList;
