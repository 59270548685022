import React, { ChangeEvent, FunctionComponent } from "react";
import { IClient } from "../../../../interfaces/IClient";
import _ from "lodash";
import DatePicker from "../../../Shared/DatePicker";
import OEMProgram from "../../../Shared/OEMProgram";
import TextField from "../../../Shared/Form/Blocks/TextField";
import Field from "../../../Shared/Form/Elements/Field";

interface IOEM {
    client: IClient;
    onValueChange(event: ChangeEvent<HTMLSelectElement | HTMLInputElement | undefined>): void;
}

const OEM: FunctionComponent<IOEM> = ({ client, onValueChange }) => {
    const errors = client.errors;
    const requiresLocationCode = client.oemProgram === "mini" || client.oemProgram === "bmw";
    const requiresOemSlug = client.oemProgram === "ford" || client.oemProgram === "lincoln";
    const oemSet = !_.isEmpty(client.oemProgram);

    return (
        <div className="w-full md:w-3/4 lg:w-1/2">
            <OEMProgram client={client} onValueChange={onValueChange} />
            <TextField
                label="Dealer Code"
                required={oemSet}
                errors={errors.dealerCode}
                name="dealerCode"
                value={client.dealerCode || ""}
                handleChange={onValueChange}
            />
            <TextField
                label="Location Code (BMW/MINI ONLY)"
                errors={errors.locationCode}
                required={requiresLocationCode}
                name="locationCode"
                value={client.locationCode || ""}
                handleChange={onValueChange}
            />
            <TextField
                label="OEM Slug (FORD)"
                errors={errors.oemSlug}
                required={requiresOemSlug}
                name="oemSlug"
                value={client.oemSlug}
                handleChange={onValueChange}
            />

            <div className="flex w-full justify-between">
                <Field label="OEM Go Live" errors={errors.oemGoLiveDate} required={oemSet} className="flex-1">
                    <DatePicker
                        name="oemGoLiveDate"
                        date={client.oemGoLiveDate}
                        onValueChange={onValueChange}
                        clearable={!oemSet}
                    />
                </Field>

                <div className="ml-8 flex-1">
                    <Field label="OEM End Date" errors={errors.oemEndDate} required={false}>
                        <DatePicker
                            name="oemEndDate"
                            date={client.oemEndDate}
                            onValueChange={onValueChange}
                            clearable={true}
                        />
                    </Field>
                </div>
            </div>
        </div>
    );
};

export default OEM;
