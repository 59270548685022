import { useState, useEffect } from "react";

function useScrollPosition(): number {
    const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return scrollPosition;
}

export default useScrollPosition;
