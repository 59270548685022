import React from "react";
import { IFormInput } from "./IFormInput";

export const Select = ({
    register,
    name,
    options,
    value,
    ...props
}: IFormInput<any> & { options: { label: string; value?: string }[] }) => (
    <select {...register(name)} className={"bg-white border-gray-500 border rounded p-2"} {...props}>
        {options.map((field) => (
            <option key={field.value + "conditional"} value={field.value}>
                {field.label}
            </option>
        ))}
    </select>
);
