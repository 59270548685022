const NumberConditionalOperators = [
    { label: "", value: "" },
    { label: "Equal To", value: "EQUAL" },
    { label: "Greater Than", value: "GREATER_THAN" },
    { label: "Less than", value: "LESS_THAN" }
];

const ConditionalOperators = [
    { label: "In (Comma Separated List)", value: "IN" },
    { label: "Not In (Comma Separated List)", value: "NOTIN" },
    { label: "Equal To", value: "EQUAL" },
    { label: "Not Equal To", value: "NOT_EQUAL" },
    { label: "Greater Than", value: "GREATER_THAN" },
    { label: "Less than", value: "LESS_THAN" },
    { label: "Greater Than or Equal To", value: "GREATER_THAN_EQUAL" },
    { label: "Less Than or Equal To", value: "LESS_THAN_EQUAL" },
    { label: "Ends With", value: "ENDS_WITH" },
    { label: "Starts With", value: "STARTS_WITH" },
    { label: "Contains", value: "LIKE" },
    { label: "Does Not Contain", value: "NOTLIKE" },
    { label: "Contains Any (Comma Separated List)", value: "IN_LIKE" },
    { label: "Contains None (Comma Separated List)", value: "NOTIN_LIKE" }
];

/**
 * Fields that are required in all inventory sources
 */
const standardFields = [
    "Body",
    "Drivetrain",
    "ExtColorGeneric",
    "FeedId",
    "OurPrice",
    "FinalPrice",
    "ID",
    "Make",
    "Miles",
    "Model",
    "Price",
    "Status",
    "Trim",
    "Type",
    "Year",
    "VDPViews",
    "VIN"
];

/**
 * Current list of fields in DI Inventory
 */
const DIFields = [
    "DaysInStock",
    "Stock",
    "GenericBodytype",
    "ChromeMake",
    "ChromeModel",
    "ChromeTrim",
    "OriginalPrice",
    "Transmission",
    "Certified",
    "DateInStock",
    "MonthlyPayments",
    "Link",
    "Thumbnail",
    "CarFaxOneOwner",
    "LeasePayment",
    "Discounts",
    "SpecialField1",
    "SpecialField2",
    "SpecialField3",
    "SpecialField4",
    "SpecialField5",
    "SpecialField6",
    "SpecialField7",
    "SpecialField8",
    "SpecialField9",
    "SpecialField10",
    "SpecialField11",
    "SpecialField12",
    "SpecialField13",
    "SpecialField14",
    "SpecialField15",
    "SpecialField16",
    "SpecialField17",
    "SpecialField18",
    "SpecialField19",
    "SpecialField20",
    "ImageCount",
    "Family",
    "FuelType",
    "InTransit",
    ...standardFields
].sort();

const DCFields = [
    "model_lowest_price",
    "dealer_name",
    "model_monthly_payments",
    "model_discounts",
    "model_lease_payments",
    "model_lowest_miles",
    "model_highest_year",
    "colors_count",
    "trims_count",
    "duplicate_count",
    "Date",
    ...DIFields
].sort();

const DCKeywordFields = ["Location", ...DCFields].sort();

const carsDbInventoryFields = [
    "ADDRESS_LINE_1",
    "ADDRESS_LINE_2",
    "AD_BEST_OFFER_IND",
    "AD_FROM_DT",
    "AD_URL",
    "AFFILIATE_SHORT_NAME",
    "AVERAGE_PRICE",
    "BODYSTYLE",
    "CARFAX_URL",
    "CITY",
    "CLASSIFIED_AD_ID",
    "CONTACT_BY_EMAIL_IND",
    "CPO_FLAG",
    "CPO_PROGRAM_NAME",
    "DEALER_FRANCHISES",
    "DEALER_LOGO_URL",
    "DEALER_URL",
    "DOOR_COUNT",
    "DRIVETRAIN",
    "ENGINE",
    "EXTERIOR_COLOR",
    "EXTERNAL_VDP_URL",
    "FAX_PHONE_NBR",
    "FUEL_TYPE",
    "IMAGEURL",
    "INTERIOR_COLOR",
    "LARGE_IMAGE_LINK",
    "LATITUDE",
    "LOCATION",
    "LONGITUDE",
    "MAINT_DATE",
    "MAKE",
    "MARKET_NAME",
    "MILEAGE",
    "MODEL",
    "MSRP",
    "NAVIGATION_SYSTEM",
    "NORM_EXTERIOR_COLOR",
    "NORM_INTERIOR_COLOR",
    "NVI_FLAG",
    "ONE_OWNER_IND",
    "OPTIONAL_FEATURES",
    "PHOTO_COUNT",
    "PRICE",
    "PRICE_DIFFERENCE",
    "PRICE_FLAG",
    "PRIMARY_PHONE",
    "PRIMARY_PHONE_TYPE_DESC",
    "PRIVATE_PARTY_FLAG",
    "PUB1",
    "PUB2",
    "PUB3",
    "PUB4",
    "PUB5",
    "PUB6",
    "SATELLITE_RADIO",
    "SCA_CUSTOMER_IND",
    "SEAT_CAPACITY",
    "SECONDARY_PHONE",
    "SECONDARY_PHONE_TYPE_DESC",
    "SELLER_ID",
    "SELLER_NAME",
    "SELLER_NOTES",
    "STANDARD_FEATURES",
    "STATE",
    "STOCK_NUMBER",
    "STOCK_TYPE",
    "SUPERSIZE_IMAGEURL",
    "SUPERSIZE_LARGE_IMAGE_LINK",
    "TAGLINE",
    "TRANSMISSION",
    "TRANSMISSION_TYPE",
    "TRIM",
    "VEHICLE_CLASS_DSCRP",
    "VEHICLE_CONDITION_DSCRP",
    "VEHICLE_CYLINDER_CNT",
    "VEHICLE_DRIVER_ASSIST_SYS_TYP",
    "VEHICLE_ENG_DISPL_DSCRP",
    "VIDEO_URL",
    "VIN",
    "WHEELBASE",
    "YEAR",
    "ZIP_CODE",
    "ZIP_CODE_EXTENSION"
];
const DCFieldsToExclude = ["OurPrice", "Miles"];
const ETAFields = ["OurPrice | money", "Miles | number"];
/**
 * We use a function because eventually this will pull from somewhere and not be a fixed array
 */
const getInventoryFieldsDI = () => {
    return DIFields;
};

const getFuelInventoryFields = () => {
    return standardFields;
};

const getCarsInventoryFields = () => {
    return standardFields;
};

const getCarsDbInventoryFields = () => {
    return carsDbInventoryFields;
};

const getDcParameterFields = () => {
    return DCFields;
};

const getDcParameterFieldsForKeywords = () => DCKeywordFields;

const getDcETAParameterFields = () => {
    return DCFields.concat(ETAFields)
        .filter((field) => !DCFieldsToExclude.includes(field))
        .sort();
};
export {
    getDcETAParameterFields,
    getDcParameterFields,
    getDcParameterFieldsForKeywords,
    getCarsInventoryFields,
    getInventoryFieldsDI,
    getFuelInventoryFields,
    ConditionalOperators,
    NumberConditionalOperators,
    getCarsDbInventoryFields
};
