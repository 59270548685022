import { useEffect } from "react";
import { useNavigate } from "react-router";

interface IProps {
    to: string;
}

const Redirect = ({ to }: IProps) => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    }, []);
    return null;
};

export default Redirect;
