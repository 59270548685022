import React, { useState } from "react";
import Notice from "./Notice";
import CheckIcon from "../../../Shared/Icons/CheckIcon";
import { sortBy } from "lodash";

interface IProps {
    errors: [];
    warnings: [];
    info: [];
}

const Notices: React.FunctionComponent<IProps> = ({ warnings, errors, info }) => {
    return (
        <div className="m-auto relative bg-white flex flex-col w-full shadow-md rounded px-4 pt-6 pb-4 mb-8">
            <div className="font-bold text-2xl text-center text-gray-800 pt-8 pb-4">Notices</div>
            <div className="flex px-6 block uppercase tracking-widertext-gray-500 font-bold mb-0 mt-2 text-base">
                <div className="w-12 pl-2">Type</div>
                <div className="w-48" />
                <div>Message</div>
            </div>
            {info
                ? sortBy(info, (i: any) => i.timestamp).map((item: any, index: number) => (
                      <Notice key={index} message={item} platform={""} type={"info"} />
                  ))
                : ""}
            {errors
                ? errors.map((error: any, index: number) => (
                      <Notice key={index} message={error.message} platform={error.platform || null} type={"error"} />
                  ))
                : ""}
            {warnings
                ? warnings.map((warning: any, index: number) => (
                      <Notice key={index} message={warning.message} type={"warning"} />
                  ))
                : ""}
            {!errors.length && !warnings.length ? (
                <div
                    className={`border-l-4 border-r border-t border-b mt-6 p-4 flex items-center text-gray-800`}
                    style={{ borderLeftColor: "green" }}
                >
                    <div className="flex-none w-12 text-center">
                        <CheckIcon className="w-6 h-6 text-green-600" />
                    </div>
                    <div className="flex-none w-48 text-base font-medium">Okay</div>
                    <div className="flex-grow text-base font-medium">The latest action had no errors or warnings</div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default Notices;
