import { combineReducers, Reducer } from "redux";
import BudgetConstants from "../constants/budgets/BudgetConstants";
import MicrosoftBudgetConstants from "../constants/budgets/MicrosoftBudgetConstants";
import IEntity from "../interfaces/IEntity";
import IBudgetSystemLog from "../interfaces/Budgets/IBudgetSystemLog";
import IBudgetChangeLog from "../interfaces/Budgets/IBudgetSystemLog";

interface IFormStatus {
    errors: any;
    message: string;
    isSubmitting: boolean;
}

const entitiesReducer: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case BudgetConstants.LOAD_BUDGETS:
            return { ...action.response.entities.budgets };
        case BudgetConstants.LOAD_BUDGETS_STREAM_SUCCESS:
        case BudgetConstants.LOAD_BUDGETS_STREAM_END:
            return {
                ...state,
                ...action.response.entities.budgets
            };
        case BudgetConstants.REMOVED_DELETED_BUDGETS:
            return {
                ...action.budgets
            };
        case BudgetConstants.REQUEST_LOAD_BUDGETS:
        case BudgetConstants.REQUEST_CLIENT_ADWORDS_BUDGETS:

        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS:
            return {};
        case BudgetConstants.REQUEST_LOAD_BUDGET_SUCCESS:
            const { id } = action.response;
            return {
                ...state,
                [id]: action.response
            };
        case BudgetConstants.REQUEST_DELETE_BUDGET_SUCCESS:
            delete state[action.budgetId];
            return { ...state };
        case BudgetConstants.REQUEST_BUDGET_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_DELETED_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_SUCCESS:
            return action.payload.budgets;
        default:
            return state;
    }
};

const initialNotificationState = { isVisible: false };
const notification: Reducer<{ isVisible: boolean }> = (state = initialNotificationState, action) => {
    switch (action.type) {
        case BudgetConstants.SHOW_NOTIFICATION:
            return { ...action.notification };
        case BudgetConstants.HIDE_NOTIFICATION:
            return { ...state, ...initialNotificationState };
        default:
            return state;
    }
};

const processing: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case BudgetConstants.LOAD_BUDGETS_STREAM:
            return true;
        case BudgetConstants.LOAD_BUDGETS_STREAM_END:
            return false;
        default:
            return state;
    }
};

const showConfirm: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case BudgetConstants.SHOW_DELETE_CONFIRM:
            return true;
        case BudgetConstants.HIDE_DELETE_CONFIRM:
        case BudgetConstants.REQUEST_DELETE_BUDGET_CONFIRM:
        case BudgetConstants.REQUEST_DELETE_BUDGET_CANCEL:
            return false;
        default:
            return state;
    }
};

const loading: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case BudgetConstants.REQUEST_CLIENT_ADWORDS_BUDGETS:
        case BudgetConstants.REQUEST_SINGLE_CLIENT_BUDGET:
        case BudgetConstants.REQUEST_CREATE_BUDGET:
        case BudgetConstants.REQUEST_UPDATE_BUDGET:
        case BudgetConstants.REQUEST_DELETED_BUDGETS:
        case BudgetConstants.REQUEST_DELETE_BUDGET_CONFIRM:
        case BudgetConstants.REQUEST_LOAD_BUDGETS:
        case BudgetConstants.REQUEST_PUSH_BUDGET:
        // Microsoft
        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_CREATE_MICROSOFT_BUDGET:
        case MicrosoftBudgetConstants.REQUEST_UPDATE_MICROSOFT_BUDGET:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_DELETED_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS:
            return true;
        case BudgetConstants.REQUEST_BUDGETS_FAIL:
        case BudgetConstants.REQUEST_DELETE_BUDGET_SUCCESS:
        case BudgetConstants.LOAD_BUDGETS_STREAM_END:
        case BudgetConstants.REQUEST_DELETE_BUDGET_FAIL:
        case BudgetConstants.LOAD_BUDGETS_STREAM_SUCCESS:
        case BudgetConstants.REQUEST_EXECUTE_BUDGET_SUCCESS:
        case BudgetConstants.REQUEST_PUSH_BUDGET_FAIL:
        case BudgetConstants.REQUEST_LOAD_BUDGET_SUCCESS:
        case BudgetConstants.LOAD_BUDGETS:
        case BudgetConstants.REQUEST_CREATE_BUDGET_FAIL:
        case BudgetConstants.REQUEST_CREATE_BUDGET_SUCCESS:
        case BudgetConstants.REQUEST_BUDGET_SUCCESS:
        // Microsoft
        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_DELETED_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_DELETED_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS_FAIL:
            return false;
        default:
            return state;
    }
};

const loadingMessage: Reducer<string> = (state = "", action) => {
    switch (action.type) {
        case BudgetConstants.REQUEST_BUDGET_SPINNER:
        case BudgetConstants.REQUEST_DELETE_BUDGET_CONFIRM:
            return action.payload.loadingMessage;
        case BudgetConstants.REQUEST_PUSH_BUDGET_FAIL:
        case BudgetConstants.REQUEST_DELETE_BUDGET_FAIL:
        case BudgetConstants.REQUEST_DELETE_BUDGET_SUCCESS:
        case BudgetConstants.REQUEST_REACTIVATE_BUDGET_SUCCESS:
        case BudgetConstants.REQUEST_EXECUTE_BUDGET_SUCCESS:
        case BudgetConstants.REQUEST_LOAD_BUDGET_SUCCESS:

        case MicrosoftBudgetConstants.REQUEST_CREATE_MICROSOFT_BUDGET_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_CREATE_MICROSOFT_BUDGET_FAIL:

        case MicrosoftBudgetConstants.REQUEST_UPDATE_MICROSOFT_BUDGET_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_UPDATE_MICROSOFT_BUDGET_FAIL:
            return "";
        case MicrosoftBudgetConstants.REQUEST_CREATE_MICROSOFT_BUDGET:
            return "Creating Budget";
        case MicrosoftBudgetConstants.REQUEST_UPDATE_MICROSOFT_BUDGET:
            return "Updating Budget";
        default:
            return state;
    }
};

const initialStatusState = { errors: {}, message: "", isSubmitting: false };
const formStatus: Reducer<IFormStatus> = (state = initialStatusState, action) => {
    switch (action.type) {
        case BudgetConstants.REQUEST_CREATE_BUDGET:
        case BudgetConstants.REQUEST_UPDATE_BUDGET:
            return {
                ...state,
                isSubmitting: action.payload.isSubmitting,
                message: action.payload.message
            };
        case BudgetConstants.REQUEST_CREATE_BUDGET_FAIL:
        case BudgetConstants.REQUEST_CREATE_BUDGET_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case MicrosoftBudgetConstants.REQUEST_UPDATE_MICROSOFT_BUDGET:
            return {
                ...state,
                isSubmitting: true,
                message: "Updating Budget"
            };

        case MicrosoftBudgetConstants.REQUEST_CREATE_MICROSOFT_BUDGET:
            return {
                ...state,
                isSubmitting: true,
                message: "Creating Budget"
            };
        default:
            return state;
    }
};

const changeLogs: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case BudgetConstants.REQUEST_BUDGET_LOGS:
            return {};
        case BudgetConstants.REQUEST_BUDGET_LOGS_SUCCESS:
            return action.payload;
        case BudgetConstants.REQUEST_BUDGET_LOGS_FAIL:
        default:
            return state;
    }
};

const systemLogs: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case BudgetConstants.REQUEST_SYSTEM_LOGS:
            return {};
        case BudgetConstants.REQUEST_SYSTEM_LOGS_SUCCESS:
            return action.payload;
        case BudgetConstants.REQUEST_SYSTEM_LOGS_FAIL:
        default:
            return state;
    }
};

const details: Reducer<any> = (state = {}, action) => {
    switch (action.type) {
        case BudgetConstants.REQUEST_BUDGET_DETAILS:
            return {};
        case BudgetConstants.REQUEST_BUDGET_DETAILS_SUCCESS:
            return action.payload;
        case BudgetConstants.REQUEST_BUDGET_DETAILS_FAIL:
        default:
            return state;
    }
};

export const logSelector = (logs: null | IEntity<IBudgetSystemLog | IBudgetChangeLog>): any => {
    if (!logs) {
        return null;
    }
    return Object.keys(logs).map((key) => {
        return logs[key];
    });
};

const loadedAt: Reducer<Date | null> = (state = null, action) => {
    switch (action.type) {
        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_DELETED_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_DELETED_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_FAIL:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS_SUCCESS:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS_FAIL:
            return new Date();
        case MicrosoftBudgetConstants.REQUEST_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_CLIENT_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_SINGLE_MICROSOFT_BUDGETS:
        case MicrosoftBudgetConstants.REQUEST_DELETED_MICROSOFT_BUDGETS:
            return null;
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    processing,
    loading,
    showConfirm,
    notification,
    loadingMessage,
    formStatus,
    changeLogs,
    systemLogs,
    details,
    loadedAt
});
