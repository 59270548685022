import React, { FunctionComponent, ChangeEvent } from "react";
import { FormikErrors, FormikTouched } from "formik";
import Field from "../Elements/Field";
import TextareaInput from "../Elements/TextareaInput";

interface ITextareaField {
    name: string;
    value: string;
    label?: string;
    touched?: boolean | FormikTouched<any>;
    errors?: Array<string | FormikErrors<any>> | string;
    placeholder?: string;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: any;
    className?: string;
    style?: any;
    readOnly?: boolean;
    required?: boolean;
    rows?: number;
}

const TextareaField: FunctionComponent<ITextareaField> = ({
    name,
    label,
    value,
    rows,
    onChange,
    onBlur,
    placeholder,
    className,
    style,
    required = false,
    readOnly = false,
    touched = onBlur === undefined,
    errors = []
}) => {
    return (
        <Field label={label} errors={errors} touched={touched} required={required} className={className} style={style}>
            <TextareaInput
                value={value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                readOnly={readOnly}
                rows={rows}
            />
        </Field>
    );
};

export default TextareaField;
