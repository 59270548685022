import IClient from "../interfaces/IClient";
import IEntity from "../interfaces/IEntity";
import ITableState from "../interfaces/ITableState";
import * as Constants from "../constants/ClientConstants";
import { Insight } from "../components/Clients/List/Table/ClientInsights";

interface IPagedClientData {
    entities: IEntity<IClient>;
    results: [number];
    currentPage: number;
    lastPage: number;
    page: number;
    pageSize: number;
}

export const deleteClient = (clientId: number) => ({
    type: Constants.REQUEST_DELETE_CLIENT,
    id: clientId
});

export const deletePagedClient = (
    clientId: number | undefined,
    page: number,
    pageSize: number,
    insight: string,
    search: string
) => ({
    type: Constants.REQUEST_DELETE_PAGED_CLIENT,
    payload: {
        clientId,
        page,
        pageSize,
        insight,
        search
    }
});

export const removeClient = (clientId: number) => ({
    type: Constants.REMOVE_CLIENT,
    id: clientId
});

export const setCurrentClient = (clientId: number) => ({
    type: Constants.SET_CURRENT_CLIENT,
    id: clientId
});

export const updateClient = (client: IClient & { id: number }) => ({
    type: Constants.UPDATE_CLIENT,
    client
});

export const saveError = (client: IClient, errors: any) => ({
    type: Constants.FAILED_SAVE_CLIENT,
    errors,
    client
});
export const fetchClient = (clientId: string) => ({
    type: Constants.REQUEST_LOAD_CLIENT,
    clientId
});

export const restoreClient = (payload: {
    client: IClient;
    page?: number;
    pageSize?: number;
    insight: string;
    search: string;
}) => ({
    type: Constants.REQUEST_RESTORE_CLIENT,
    payload
});

export const saveClient = (client: IClient, changedFields: any) => ({
    type: Constants.REQUEST_SAVE_CLIENT,
    client,
    changedFields
});
export const createClient = (client: IClient) => ({
    type: Constants.REQUEST_CREATE_CLIENT,
    client
});

export const refreshExternalData = (client: IClient) => ({
    type: Constants.REFRESH_EXTERNAL_DATA,
    client
});

export const fetchClientRoles = () => ({
    type: Constants.REQUEST_LOAD_CLIENT_ROLES
});

export const fetchPagedClients = (tableState: ITableState, insight: Insight, search: string) => ({
    type: Constants.REQUEST_PAGED_CLIENTS,
    payload: { ...tableState, insight, search }
});

export const loadPagedClients = (data: IPagedClientData) => ({
    type: Constants.LOAD_PAGED_CLIENTS,
    payload: data
});

export const downloadInventoryClicked = (clientId: number, client: IClient) => ({
    type: Constants.DOWNLOAD_INVENTORY_CLICKED,
    clientId,
    client
});

export const downloadCsvClicked = (clientId: number, client: IClient) => ({
    type: Constants.DOWNLOAD_CSV_CLICKED,
    clientId,
    client
});

export const clientSelectorRefreshClicked = (clientId: number, client: IClient) => ({
    type: Constants.CLIENT_SELECTOR_REFRESH_CLICKED,
    clientId,
    client
});
