import Constants from "../constants/DealerSetupConstants";

export const saveFormValues = (path: string, values: any): any => ({
    type: Constants.SAVE_FORM_VALUES,
    payload: { path, values }
});

export const toggleFormPages = (values: any): any => ({
    type: Constants.TOGGLE_FORM_PAGES,
    payload: { ...values.value }
});

export const saveForm = (clientId: number, timeToSubmitMetric: number) => ({
    type: Constants.SAVE_FORM_REQUEST,
    payload: clientId,
    meta: {
        timeToSubmitMetric
    }
});

export const saveFormSuccess = (): any => ({
    type: Constants.SAVE_FORM_SUCCESS
});

export const saveFormFailure = (): any => ({
    type: Constants.SAVE_FORM_FAILURE
});

export const processCampaignsRequest = (): any => ({
    type: Constants.PROCESS_CAMPAIGNS_REQUEST
});

export const processCampaignsSuccess = (): any => ({
    type: Constants.PROCESS_CAMPAIGNS_SUCCESS
});

export const processCampaignsFailure = (): any => ({
    type: Constants.PROCESS_CAMPAIGNS_FAILURE
});

export const fetchDealerSetupData = (clientId: number): any => ({
    type: Constants.FETCH_DEALER_SETUP_DATA_REQUEST,
    payload: clientId
});

export const fetchDealerSetupDataSuccess = (payload: any): any => ({
    type: Constants.FETCH_DEALER_SETUP_DATA_SUCCESS,
    payload
});

export const fetchDealerSetupDataFailure = (): any => ({
    type: Constants.FETCH_DEALER_SETUP_DATA_FAILURE
});
