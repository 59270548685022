import React, { useState } from "react";
import IActivity from "../../../interfaces/EditHistory/IActivity";
import moment from "moment";
import TextField from "../../Shared/Form/Blocks/TextField";
import ChevronRightIcon from "../../Shared/Icons/ChevronRightIcon";
import { CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES } from "../../../constants/ClientConstants";
import PencilIcon from "../../Shared/Icons/PencilIcon";
import ChevronDownIcon from "../../Shared/Icons/ChevronDownIcon";

interface IProps {
    activity: IActivity;
    index: number;
}

const ItemUpdated = ({ activity, index }: IProps) => {
    const [isOpen, setOpenState] = useState(false);
    // For the update event, old and attributes should at least exist. Just not as undefined.
    // These checks are literally just here for typescript :angry_eyes:
    if (activity.properties?.old === undefined) {
        return <div></div>;
    }
    if (activity.properties?.attributes === undefined) {
        return <div></div>;
    }
    const oldProperties: { [key: string]: string } = activity.properties.old;
    const newProperties: { [key: string]: string } = activity.properties.attributes;

    const propertyKeys = Object.keys(activity.properties?.old || {});
    const properties = propertyKeys.map((propertyName) => {
        return {
            field: propertyName,
            oldValue: oldProperties[propertyName],
            newValue: newProperties[propertyName]
        };
    });
    const propertyNamesForSection = propertyKeys.map(
        (property) => CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES[property] || property
    );

    let bits = propertyNamesForSection.join(", ");

    if (!bits) {
        bits = "nothing";
    }

    return (
        <div className="my-2">
            <div className={`border w-full rounded bg-white`}>
                <div className="flex flex-wrap items-center">
                    <div className="w-6 ml-2 bg-blue-500 rounded-full">
                        <PencilIcon className="w-6 text-white p-1" />
                    </div>

                    <div
                        className={`flex flex-1 justify-between p-2 cursor-pointer`}
                        onClick={() => setOpenState(!isOpen)}
                    >
                        <div className={`text-blue-800 flex flex-col text-sm`}>
                            <div>
                                The <span className="underline">{bits}</span>{" "}
                                {
                                    // This is just for grammatical correctness. I normally don't like ternaries, but
                                    // I find this to be quite acceptable...
                                    propertyNamesForSection.length !== 1 ? `fields were` : `field was`
                                }{" "}
                                updated
                            </div>
                            <span className="text-xs text-gray-600">
                                {activity.causer &&
                                    [activity.causer?.firstName, activity.causer?.lastName].join(" ").trim() + " • "}
                                {moment(activity.createdAt).format("MMMM Do YYYY, h:mm a")}
                            </span>
                        </div>

                        <div className="flex justify-end">
                            <div className="flex justify-end items-center">
                                <ChevronDownIcon
                                    className={`w-3 h-3 text-cerulean-500`}
                                    style={{
                                        transition: ".2s ease",
                                        ...(isOpen ? {} : { transform: "rotate(-90deg)" })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <div className="px-4 pb-4 w-full">
                        <div className="flex flex-col">
                            {properties.map((item, i) => (
                                <div key={i} className="flex flex-wrap justify-between items-end text-sm">
                                    <TextField
                                        label={CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES[item.field]}
                                        name={item.field}
                                        value={item.oldValue || "empty"}
                                        disabled={true}
                                        className="flex-grow text-red-500"
                                        inputStyle={{
                                            borderColor: "#f56565",
                                            backgroundColor: "#fff5f5",
                                            textDecoration: item.oldValue ? "line-through" : "none"
                                        }}
                                    />
                                    <div className="w-12 flex items-baseline justify-center mb-3">
                                        <ChevronRightIcon className="w-4" />
                                    </div>
                                    <TextField
                                        label={CLIENT_FIELDS_MAPPED_TO_PRETTY_NAMES[item.field]}
                                        name={item.field}
                                        value={item.newValue || "empty"}
                                        disabled={true}
                                        className="flex-grow text-green-600"
                                        inputStyle={{ borderColor: "#48bb78", backgroundColor: "#f0fff4" }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ItemUpdated;
