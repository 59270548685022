import React, { useEffect, useState } from "react";
import Button from "../../../../Shared/Button";
import { Form, Formik } from "formik";
import ITrackingParameter, {
    IArrayOfCampaigns,
    IArrayOfRemoteCampaigns
} from "../../../../../interfaces/ITrackingParameter";
import TextField from "../../../../Shared/Form/Blocks/TextField";
import SelectField from "../../../../Shared/Form/Blocks/SelectField";
import { IOption } from "../../../../../interfaces/IDisplayCampaign";
import LinkedCampaignsContainer from "./LinkedCampaignsInput";
import * as Yup from "yup";
import { IAdwordsCampaign, ITrackingParameterCampaignRelation } from "../../../../../interfaces/Campaigns";
import IMicrosoftCampaign from "../../../../../interfaces/IMicrosoftCampaign";
import IEntity from "../../../../../interfaces/IEntity";
import QuestionMarkIcon from "../../../../Shared/Icons/QuestionMarkIcon";
import ITrackingParameterFormValues from "../../../../../interfaces/ITrackingParameterFormValues";
import IClient from "../../../../../interfaces/IClient";
import { Link } from "react-router-dom";
import IAppState from "../../../../../interfaces/IAppState";
import { connect } from "react-redux";
import RefreshIcon from "../../../../Shared/Icons/RefreshIcon";
import IGoogleCampaign from "../../../../../interfaces/IGoogleCampaign";

interface IProps {
    currentClient: IClient;
    adwordsCampaigns: IEntity<IAdwordsCampaign> | IEntity<IGoogleCampaign>;
    microsoftCampaigns: IEntity<IMicrosoftCampaign>;
    initialValues: ITrackingParameterFormValues & IArrayOfRemoteCampaigns;
    trackingParameter?: ITrackingParameter;
    onSave: (
        values: ITrackingParameterFormValues & (IArrayOfCampaigns | IArrayOfRemoteCampaigns),
        currentClient: IClient
    ) => void;
    platformOptions: IEntity<IOption>;
    allTrackedCampaigns?: IEntity<ITrackingParameterCampaignRelation>;
    busy?: boolean;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    platform: Yup.string().required("Required"),
    parameters: Yup.string()
        .matches(
            /^[A-Za-z0-9_&=\-\?\s\{\}]*$/,
            'Tracking parameters cannot contain special characters (aside from "&", "_", "-", "=", "{", "}" )'
        )
        .required("Required"),
    trackedCampaigns: Yup.array()
});

const TrackingParametersForm: React.FunctionComponent<IProps> = ({
    platformOptions,
    onSave,
    currentClient,
    trackingParameter,
    initialValues,
    adwordsCampaigns,
    microsoftCampaigns,
    allTrackedCampaigns,
    busy = false
}) => {
    const [platform, setPlatform] = useState(platformOptions[trackingParameter?.platform || "google"]);

    const platformCampaigns: { [key: string]: IEntity<IMicrosoftCampaign | IAdwordsCampaign> } = {
        google: adwordsCampaigns,
        microsoft: microsoftCampaigns
    };
    const [externalCampaigns, setExternalCampaigns] = useState(platformCampaigns[platform.value]);

    useEffect(() => {
        if (!platform) {
            return;
        }

        setExternalCampaigns(platformCampaigns[platform.value]);
    }, [platform, platformCampaigns, setExternalCampaigns, trackingParameter?.platform]);

    useEffect(() => {
        setPlatform(platformOptions[trackingParameter?.platform || "google"]);
    }, [trackingParameter?.platform]);

    return (
        <div className="pb-4 px-4">
            <Formik
                onSubmit={(values) => {
                    onSave(values, currentClient);
                }}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                render={({ values, dirty, errors, isValid, setFieldValue }) => (
                    <Form className={`w-full`}>
                        <div className={`flex mb-8 mr-6`}>
                            <TextField
                                name={"name"}
                                value={values.name}
                                label={"Parameter Name"}
                                errors={[errors.name || ""]}
                                required={true}
                                className={`w-2/5 mr-3`}
                                handleChange={(event: any) => {
                                    setFieldValue("name", event.target.value);
                                }}
                            />
                            <SelectField
                                name={"platform"}
                                value={values.platform}
                                options={Object.values(platformOptions)}
                                handleChange={(option: IOption) => {
                                    setPlatform(option);
                                    setFieldValue("platform", option.value);
                                }}
                                label={"Platform"}
                                errors={[errors.platform || ""]}
                                required={true}
                                className={`w-2/5 pl-3 pr-3`}
                                readOnly={Object.keys(values.trackedCampaigns).length > 0}
                            />
                            {/** For the moment we don't want to let our users change the platform if they have selected trackedCampaigns **/}
                            <div className={`w-3/5 ml-3 relative`}>
                                <TextField
                                    name={"parameters"}
                                    value={values.parameters}
                                    label={"URL Parameters"}
                                    errors={[errors.parameters || ""]}
                                    required={true}
                                    inputStyle={{ paddingLeft: "2em" }}
                                    handleChange={(event: any) => {
                                        setFieldValue("parameters", event.target.value);
                                    }}
                                />
                                <div className="flex h-16 mt-8 items-center absolute top-0">
                                    <QuestionMarkIcon className={`w-6 h-6 ml-2 text-gray-700`} />
                                </div>
                            </div>
                        </div>
                        <LinkedCampaignsContainer
                            externalCampaigns={externalCampaigns}
                            trackingParameter={trackingParameter}
                            trackedCampaigns={values.trackedCampaigns}
                            allTrackedCampaigns={allTrackedCampaigns}
                            onCampaignValueChange={(index: number, campaign: IAdwordsCampaign | IMicrosoftCampaign) => {
                                const campaigns = values.trackedCampaigns;
                                campaigns[index] = campaign.id;
                                // This should keep our campaigns unique, and it should also update things properly.
                                setFieldValue(`trackedCampaigns`, [...new Set([...campaigns])]);
                            }}
                            removeCampaignFromTrackingParameters={(campaign) => {
                                setFieldValue(
                                    `trackedCampaigns`,
                                    values.trackedCampaigns.filter((campaignId) => campaignId !== campaign.campaignId)
                                );
                            }}
                            errors={errors}
                            disabled={values.platform === ""}
                        />

                        <div className={`flex float-right my-4`}>
                            <Link to=".." className="rounded py-3 px-8 mx-4">
                                Cancel
                            </Link>
                            <Button
                                disabled={!isValid || !dirty || busy}
                                styleType={`primary`}
                                styles={`flex items-center`}
                                type={"submit"}
                                onClick={() => {
                                    // Remove the temp campaign when we try to submit this shiz.
                                    setFieldValue(
                                        `trackedCampaigns`,
                                        values.trackedCampaigns.filter((campaignId) => campaignId !== "999999999999")
                                    );
                                }}
                            >
                                {busy ? <RefreshIcon className="w-6 h-6 rotate-fast" /> : ""}
                                <div className={busy ? "ml-2" : ""}>Sav{busy ? "ing" : "e"}</div>
                            </Button>
                        </div>
                    </Form>
                )}
            />
        </div>
    );
};

const mapStateToProps = (state: IAppState) => ({
    busy: state.trackingParameters.busy
});

export default connect(mapStateToProps, null)(TrackingParametersForm);
