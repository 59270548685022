import React from "react";
import { decamelize } from "humps";
import {
    LogDetailContainer,
    ChangeHeaderContainer,
    ChangeItem,
    ChangeContainer,
    ChangeHeader,
    ChangeRow
} from "./BudgetDetails";

interface IProps {
    changes: any;
    updatedBy: string;
    changeRequestLink?: string;
}

const AdditionDetailsContainer = "flex-row pt-4";

const mapApiLabelToUILabel = (key: string) => {
    switch (key) {
        case "target_spend":
            return "Monthly Spend";
        case "label":
            return "Label Name";
        default:
            return key;
    }
};

const ChangeLogDetail: React.FunctionComponent<IProps> = ({ changes, changeRequestLink, updatedBy }) => {
    return (
        <div className={LogDetailContainer}>
            <div className={ChangeContainer}>
                <div className={ChangeHeaderContainer}>
                    <div className={ChangeHeader}>Change</div>
                    <div className={ChangeHeader}>Previous</div>
                    <div className={ChangeHeader}>New</div>
                </div>
                {changes.map((change: any) => {
                    const { attribute, old, new: newVal } = change;
                    {
                        return old || newVal ? (
                            <div className={ChangeRow} key={change.attribute}>
                                <div className={`${ChangeItem} capitalize`}>
                                    {mapApiLabelToUILabel(attribute).replace(/_/g, " ")}
                                </div>
                                <div className={ChangeItem}>{old}</div>
                                <div className={ChangeItem}>{newVal}</div>
                            </div>
                        ) : null;
                    }
                })}

                <div className={AdditionDetailsContainer}>
                    <div className="flex items-center">
                        <div className={`font-size-sm uppercase font-bold`}>Change Made By:</div>
                        <div className="ml-4">{updatedBy} </div>
                    </div>
                    {changeRequestLink && (
                        <div className="flex items-center">
                            <div className={`font-size-sm uppercase font-bold`}>Change Request Link:</div>
                            <div className="ml-4">{changeRequestLink} </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChangeLogDetail;
