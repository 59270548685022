/**
 * Created by fjiles@dealerinspire.com on 2/7/17.
 */

import { schema } from "normalizr";
const adjustment = new schema.Entity(
    "adwordsBidAdjustment",
    {},
    {
        processStrategy: (adjustment) => {
            let geoJson = {
                properties: {
                    name: "",
                    color: "blue"
                }
            };

            const adjustmentAmount = Math.abs(Math.round((1 - adjustment.bidModifier) * 100));
            const bid = adjustment.bidModifier > 1 ? "+" : "-";
            adjustment.bidModifierDisplay = adjustment.bidModifier === null ? "--" : `${bid}${adjustmentAmount}%`;
            if (adjustment.location) {
                geoJson = JSON.parse(adjustment.location.geoJson);
                geoJson.properties.name =
                    adjustment.bidModifier === null
                        ? adjustment.name
                        : `${adjustment.bidModifierDisplay} ${adjustment.name}`;
                geoJson.properties.color = adjustment.isNegative ? "gray" : "blue";
                adjustment.location.geoJson = { ...geoJson };
            }

            return { ...adjustment };
        }
    }
);

const adjustmentArray = { data: [adjustment] };

const Schemas = {
    ADWORDS_BID_ADJUSTMENT: adjustment,
    ADWORDS_BID_ADJUSTMENT_ARRAY: adjustmentArray
};

export default Schemas;
