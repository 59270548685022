import React, { useState, useRef, useEffect } from "react";
import { IDynamicCampaign } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import Button from "../../Shared/Button";
import { callApiWithoutNormalizr } from "../../../middleware/api";
import LoaderSpinner from "../../Shared/Loaders/LoaderSpinner";
import DisplayTheVehicleInventoryGroup from "./InventoryVisualizer/DisplayTheVehicleInventoryGroup";
import ScrollableVehicleContentGroup from "./InventoryVisualizer/ScrollableVehicleContentGroup";
import { couldThisInventoryGroupHaveAProblem } from "../../../utils/MathHelpers";
interface IProps {
    dynamicCampaign: IDynamicCampaign;
    disabled?: boolean;
}
const DynamicCampaignInventoryVisualizer = ({ dynamicCampaign, disabled }: IProps) => {
    const [loading, setLoading] = useState(false);
    const [validVehicles, setValidVehicles] = useState(null as any);
    const [invalidVehicles, setInvalidVehicles] = useState(null as any);
    const [allVehicles, setAllVehicles] = useState(null as any);
    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(null as any);

    useEffect(() => {
        if (disabled) {
            setValidVehicles(null);
            setInvalidVehicles(null);
            setSelectedVehicleGroup(null);
            setAllVehicles(null);
        }
    }, [disabled]);

    return (
        <div className="w-full">
            <Button
                type={"button"}
                styleType={"secondary"}
                styles={"mt-2"}
                disabled={disabled}
                onClick={() => {
                    setLoading(true);
                    callApiWithoutNormalizr(
                        `/clients/${dynamicCampaign.clientId}/dynamic-campaigns/${dynamicCampaign.id}/inventory`
                    ).then((data: any[]) => {
                        const reduceVehiclesByDynamicCampaignKey = (items: any, item: any) => {
                            const keyForItem = JSON.stringify(
                                dynamicCampaign.inventoryGroupFields.reduce(
                                    (keys, key) => ({ ...keys, [key]: item[key] }),
                                    {}
                                )
                            );

                            return {
                                ...items,
                                [keyForItem]: [...(items[keyForItem] ? items[keyForItem] : []), item]
                            };
                        };

                        const vehiclesReducedByKey = data.reduce(reduceVehiclesByDynamicCampaignKey, {});
                        const valid = Object.keys(vehiclesReducedByKey)
                            .filter((groupKey) => !couldThisInventoryGroupHaveAProblem(vehiclesReducedByKey[groupKey]))
                            .reduce(
                                (items: any, groupKey: string) => ({
                                    ...items,
                                    [groupKey]: vehiclesReducedByKey[groupKey]
                                }),
                                {}
                            );
                        const invalid = Object.keys(vehiclesReducedByKey)
                            .filter((groupKey) => couldThisInventoryGroupHaveAProblem(vehiclesReducedByKey[groupKey]))
                            .reduce(
                                (items: any, groupKey: string) => ({
                                    ...items,
                                    [groupKey]: vehiclesReducedByKey[groupKey]
                                }),
                                {}
                            );
                        setAllVehicles(vehiclesReducedByKey);
                        setValidVehicles(valid);
                        setInvalidVehicles(invalid);
                        setLoading(false);
                    });
                }}
            >
                {disabled ? "Please save your campaign to preview inventory" : "Preview the inventory"}
            </Button>

            {loading && (
                <div className="w-full text-center mt-4">
                    <LoaderSpinner message={"Loading inventory..."} />
                </div>
            )}
            {!loading && !!allVehicles && (
                <div className="flex flex-col mt-4 -mx-4">
                    {Object.keys(invalidVehicles).length > 0 && (
                        <div className="flex flex-col">
                            <div className="font-bold text-xl text-gray-800 my-2 mx-4">
                                Inventory AdGroups with potential problems
                            </div>
                            <div className="flex flex-wrap gap-4 px-4 w-full">
                                {Object.keys(invalidVehicles).map((objectMappingKey) => (
                                    <DisplayTheVehicleInventoryGroup
                                        key={`group-${invalidVehicles[objectMappingKey][0].VIN}`}
                                        groupPreview={invalidVehicles[objectMappingKey][0]}
                                        inventoryGroup={invalidVehicles[objectMappingKey]}
                                        selectedVehicleGroup={selectedVehicleGroup}
                                        setSelectedVehicleGroup={setSelectedVehicleGroup}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {selectedVehicleGroup && (
                        <ScrollableVehicleContentGroup
                            vehicleGroup={selectedVehicleGroup}
                            couldThisInventoryGroupHaveAProblem={couldThisInventoryGroupHaveAProblem}
                            setSelectedVehicleGroup={setSelectedVehicleGroup}
                        />
                    )}

                    {Object.keys(validVehicles).length > 0 && (
                        <div className="flex flex-col">
                            <div className="font-bold text-xl text-gray-800 my-2 mx-4">
                                Inventory AdGroups to be created or updated.
                            </div>
                            <div className="flex flex-wrap gap-4 px-4 w-full">
                                {Object.keys(validVehicles).map((objectMappingKey) => (
                                    <DisplayTheVehicleInventoryGroup
                                        key={`group-${validVehicles[objectMappingKey][0].VIN}`}
                                        groupPreview={validVehicles[objectMappingKey][0]}
                                        inventoryGroup={validVehicles[objectMappingKey]}
                                        selectedVehicleGroup={selectedVehicleGroup}
                                        setSelectedVehicleGroup={setSelectedVehicleGroup}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="p-4 w-full">
                        <div>Valid vehicles: {Object.keys(validVehicles).length}</div>
                        <div>Invalid vehicles: {Object.keys(invalidVehicles).length}</div>
                        <div>Total vehicles: {Object.keys(allVehicles).length}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DynamicCampaignInventoryVisualizer;
