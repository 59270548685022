import React, { FunctionComponent, ChangeEvent } from "react";
import { startCase } from "lodash";

interface IFilterOption {
    label: string;
    checked: boolean;
    count: number | string | null;
    onClick(event: ChangeEvent<HTMLInputElement>): void;
}

const FilterOption: FunctionComponent<IFilterOption> = ({ label, checked, count, onClick }) => {
    if (count === 0 || count === "" || count === null) {
        return null;
    }

    return (
        <section className="flex justify-between p-1 text-sm">
            <label className="cursor-pointer">
                <input type="checkbox" checked={checked} onChange={onClick} />
                <span className="ml-4">{startCase(label)}</span>
            </label>
            <span className="rounded bg-gray-500 py-1 px-3 leading-none text-white font-bold flex align-center justify-center text-xs">
                <span>{count}</span>
            </span>
        </section>
    );
};

export default FilterOption;
