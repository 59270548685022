import React, { SVGProps } from "react";
import Svg from "./Svg";

const DealerSetupIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M7.837 22.232a4.036 4.036 0 0 1-1.749-.398.579.579 0 0 1-.16-.93l1.097-1.092a.585.585 0 0 0-.001-.834.586.586 0 0 0-.834 0l-1.097 1.096a.572.572 0 0 1-.505.16.578.578 0 0 1-.423-.32 4.06 4.06 0 0 1 0-3.512 4.05 4.05 0 0 1 2.32-2.088c.441-.156.9-.236 1.363-.236.468 0 .927.08 1.369.24l5.102-5.102a4.08 4.08 0 0 1 3.843-5.447c.606 0 1.194.133 1.747.397a.577.577 0 0 1 .16.93l-1.095 1.096a.59.59 0 0 0 .834.833l1.095-1.097a.58.58 0 0 1 .507-.161c.183.031.342.15.422.32a4.06 4.06 0 0 1 0 3.511 4.049 4.049 0 0 1-2.319 2.088 4.045 4.045 0 0 1-2.732-.004l-5.101 5.102a4.083 4.083 0 0 1-.158 3.123 4.052 4.052 0 0 1-2.317 2.088 4.078 4.078 0 0 1-1.367.237h-.001zm-.417-1.185a2.916 2.916 0 0 0 1.398-.14 2.9 2.9 0 0 0 1.662-1.498c.38-.799.38-1.715 0-2.514a.577.577 0 0 1 .112-.657l5.646-5.646a.571.571 0 0 1 .657-.114 2.892 2.892 0 0 0 2.234.118A2.907 2.907 0 0 0 20.792 9.1a2.91 2.91 0 0 0 .256-1.684l-.423.423c-.329.33-.767.51-1.233.51a1.744 1.744 0 0 1-1.232-2.975l.42-.42a2.91 2.91 0 0 0-1.4.14 2.902 2.902 0 0 0-1.66 1.499 2.912 2.912 0 0 0 .002 2.511c.104.22.059.484-.114.656L9.76 15.408a.571.571 0 0 1-.657.112 2.894 2.894 0 0 0-2.234-.117A2.904 2.904 0 0 0 5.207 16.9a2.91 2.91 0 0 0-.255 1.685l.423-.422c.328-.328.766-.51 1.232-.51.466 0 .904.18 1.232.51.33.33.511.767.511 1.232 0 .466-.18.904-.51 1.233l-.42.419z" />
        <path d="M9.76 11.988c-.154 0-.3-.061-.408-.17l-.136-.136a4.073 4.073 0 0 1-2.732.002 4.056 4.056 0 0 1-2.318-2.09 4.061 4.061 0 0 1-.001-3.505.576.576 0 0 1 .928-.16L6.19 7.025a.587.587 0 0 0 .834 0 .586.586 0 0 0 .002-.834L5.928 5.095a.579.579 0 0 1 .16-.93 4.043 4.043 0 0 1 3.117-.16 4.053 4.053 0 0 1 2.317 2.09c.466.98.521 2.107.158 3.123l.075.075a.571.571 0 0 1 0 .815.571.571 0 0 1-.407.17c-.154 0-.3-.06-.408-.17l-.348-.347a.577.577 0 0 1-.113-.656c.381-.799.381-1.716.001-2.514a2.907 2.907 0 0 0-1.662-1.499 2.92 2.92 0 0 0-1.4-.139l.423.422c.328.33.51.767.51 1.233 0 .465-.182.903-.511 1.232-.33.33-.767.51-1.233.51-.466 0-.904-.18-1.233-.51l-.42-.42c-.085.567.001 1.148.254 1.678a2.905 2.905 0 0 0 1.662 1.497 2.89 2.89 0 0 0 2.234-.116.58.58 0 0 1 .658.114l.408.41a.577.577 0 0 1-.41.984zM18.162 22.23a4.08 4.08 0 0 1-1.369-.237 4.082 4.082 0 0 1-2.473-5.212l-.212-.212a.578.578 0 0 1 .817-.815l.483.484a.577.577 0 0 1 .113.656 2.913 2.913 0 0 0-.002 2.512 2.905 2.905 0 0 0 1.661 1.5 2.926 2.926 0 0 0 1.4.14l-.422-.422c-.329-.33-.51-.767-.51-1.232 0-.466.181-.904.51-1.233.329-.328.767-.51 1.233-.51.466 0 .904.181 1.232.51l.422.423a2.91 2.91 0 0 0-.255-1.686 2.941 2.941 0 0 0-2.643-1.664c-.436 0-.858.096-1.254.285a.57.57 0 0 1-.656-.113l-.484-.484a.578.578 0 0 1 .408-.985c.154 0 .299.06.407.169l.211.21c.44-.157.898-.237 1.366-.237 1.567 0 3.013.91 3.686 2.32a4.062 4.062 0 0 1 0 3.513.578.578 0 0 1-.93.16l-1.095-1.096a.586.586 0 0 0-.834 0 .59.59 0 0 0 0 .833l1.096 1.096a.579.579 0 0 1-.16.93 4.008 4.008 0 0 1-1.746.398z" />
    </Svg>
);

export default DealerSetupIcon;
