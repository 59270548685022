import { useEffect } from "react";

/**
 * Handles click outside of an element to be able to invoke handler
 * Taken from usehooks.com
 * @param {*} ref
 * @param {function} handler
 */
function useOnClickOutside(ref: any, handler: any, deps?: any[]) {
    useEffect(() => {
        const listener = (event: { target: any }) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [...(deps || [])]); // Empty array ensures that effect is only run on mount and unmount
}

export default useOnClickOutside;
