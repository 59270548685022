import trackEvent from "../../../../utils/Analytics/trackEvent";
import etaTemplateBoilerplateActions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";

function* loadEtaTemplatesEvent(action: ReturnType<typeof etaTemplateBoilerplateActions.loadBoilerplate>) {
    if (action.payload.form === "edit") {
        yield trackEvent({
            category: "DSC Edit Page",
            action: `dsc_load_eta_templates`,
            label: "ETA Templates Loaded",
            value: action.payload.templates
        });
    } else {
        yield trackEvent({
            category: "DSC Create Page",
            action: `dsc_load_eta_templates`,
            label: "ETA Templates Loaded",
            value: action.payload.templates
        });
    }
}

function* createEtaTemplateEvent(action: ReturnType<typeof etaTemplateBoilerplateActions.addNewTemplate>) {
    if (action.payload.form === "edit") {
        yield trackEvent({
            category: "DSC Edit Page",
            action: `dsc_create_eta_template`,
            label: "ETA Templates Created",
            value: 1
        });
    } else {
        yield trackEvent({
            category: "DSC Create Page",
            action: `dsc_create_eta_template`,
            label: "ETA Templates Created",
            value: 1
        });
    }
}

function* duplicateEtaTemplateEvent(action: ReturnType<typeof etaTemplateBoilerplateActions.addNewTemplate>) {
    if (action.payload.form === "edit") {
        yield trackEvent({
            category: "DSC Edit Page",
            action: `dsc_duplicate_eta_template`,
            label: "ETA Templates Duplicated",
            value: 1
        });
    } else {
        yield trackEvent({
            category: "DSC Create Page",
            action: `dsc_duplicate_eta_template`,
            label: "ETA Templates Duplicated",
            value: 1
        });
    }
}

function* saveEtaBoilerplateEvent(action: ReturnType<typeof etaTemplateBoilerplateActions.store.request>) {
    yield trackEvent({
        category: "Create ETA Boilerplate",
        action: `dsc_eta_boilerplate_create`,
        label: `${action.payload.name}`
    });
}

function* deleteEtaBoilerplateEvent(action: ReturnType<typeof etaTemplateBoilerplateActions.destroy.request>) {
    yield trackEvent({
        category: "Delete ETA Boilerplate",
        action: `dsc_eta_boilerplate_delete`,
        label: `${action.payload.boilerplate.name}`
    });
}

export default {
    loadEtaTemplatesEvent,
    createEtaTemplateEvent,
    saveEtaBoilerplateEvent,
    deleteEtaBoilerplateEvent,
    duplicateEtaTemplateEvent
};
