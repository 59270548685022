import { schema } from "normalizr";

const carsBudgets = new schema.Entity("carsBudgets");

const Schemas = {
    CARS_BUDGETS_ARRAY: { data: [carsBudgets] },
    CARS_BUDGETS: carsBudgets
};

export default Schemas;
