import { call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import * as actions from "../actions/facebookActions";
import IClient from "../interfaces/IClient";

interface IAction<T> {
    type: string;
    payload: T;
}

export function* generateOemConversionsForPixel({
    payload
}: IAction<{ accountId: number; pixelId: number; oem: string; callback: () => {} }>) {
    const { accountId, pixelId, oem, callback } = payload;

    try {
        yield call(callApi, "/facebook-pixel-setup", {}, "POST", {
            account_id: Number(accountId),
            pixel_id: pixelId,
            oem
        });

        yield put(actions.generateOemConversionsForPixelSuccess());
        yield call(callback);
    } catch (e) {
        let error = "";

        if (e.errors) {
            error = Object.keys(e.errors)
                .map((key) => e.errors[key].join(" "))
                .join(" ");
        } else if (e.exceptionMessage) {
            error = e.exceptionMessage;
        } else {
            error = e.message;
        }

        yield put(actions.generateOemConversionsForPixelFailure(error));
    }
}

export function* subscribeFacebookPageToApp({
    payload
}: IAction<{ client: IClient; facebookPageId: number; callback: () => {} }>) {
    const { client, facebookPageId, callback } = payload;

    try {
        yield call(callApi, "/clients/" + client.id + "/subscribe-to-facebook-page", {}, "POST", {
            facebook_page_id: facebookPageId
        });

        yield put(actions.subscribePageToAppSuccess());
        yield call(callback);
    } catch (e) {
        let error = "";

        if (e.errors) {
            error = Object.keys(e.errors)
                .map((key) => e.errors[key].join(" "))
                .join(" ");
        } else if (e.exceptionMessage) {
            error = e.exceptionMessage;
        } else {
            error = e.message;
        }

        yield put(actions.subscribePageToAppFailed(error));
    }
}

function* facebookSaga() {
    yield takeEvery(actions.GENERATE_OEM_CONVERSIONS, generateOemConversionsForPixel);
    yield takeEvery(actions.SUBSCRIBE_PAGE_TO_APP, subscribeFacebookPageToApp);
}

export default facebookSaga;
