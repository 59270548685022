import React from "react";

interface IProps {
    onToggle(): void;
    value: boolean;
}

const SpecialOfferTemplatePriorityToggle: React.FC<IProps> = ({ onToggle, value }) => {
    return (
        <div className={`toggle-switch-input medium`}>
            <label className="mr-2">Special Offers Prioritization:</label>
            <div className="">
                <input
                    id="toggleSpecialOfferPrioritization"
                    className="toggle-switch"
                    type="checkbox"
                    checked={!!value}
                    onChange={onToggle}
                />
                <label htmlFor="toggleSpecialOfferPrioritization" className="medium" />
            </div>
        </div>
    );
};

export default SpecialOfferTemplatePriorityToggle;
