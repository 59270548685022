import React from "react";
import moment from "moment";
import numeral from "numeral";

import { getStatusDetails } from "../../../Shared/Table/TableUtils";
import { LogPreviewContainer, ItemField, SystemLog } from "./BudgetDetails";

const SystemLogPreview = ({ log }: any) => {
    const { updatedAt, note, oldBudget, newBudget, statusId } = log;
    const { type, title } = getStatusDetails(statusId);

    return (
        <div className={`${LogPreviewContainer} ${SystemLog}`}>
            <div className={ItemField}>{moment(updatedAt).format("MM/DD/YYYY")}</div>
            <div className={ItemField}>
                <span className={`budget-status ${type}`} />
                <span>{title}</span>
            </div>
            <div className={`flex-auto`}>{note}</div>
            <div className={ItemField}>
                <s>${numeral(oldBudget).format("0,0.00")}</s> - ${numeral(newBudget).format("0,0.00")}
            </div>
        </div>
    );
};

export default SystemLogPreview;
