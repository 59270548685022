import React from "react";
import FieldErrors from "../Fields/FieldErrors";
import IGenericCampaign from "../../../../interfaces/DealerSetup/IGenericCampaign";

const GenericSection: React.FC<IGenericCampaign> = ({ fields }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    <div className="font-bold mb-4">Generic Campaigns</div>

                    {fields.campaigns.map((campaign, index) => {
                        const { name, keywords } = campaign.value;
                        return (
                            <div key={index} className="m-4">
                                <div className="font-bold">
                                    Name: <span className="font-normal">{name}</span>
                                </div>

                                <div className="font-bold">
                                    Keywords:
                                    <span>
                                        {keywords.map((keyword, key) => {
                                            return (
                                                <span key={`${keyword}${key}`} className="font-normal">
                                                    {" "}
                                                    {keyword}
                                                    {key !== keywords.length - 1 && ","}{" "}
                                                </span>
                                            );
                                        })}
                                    </span>
                                </div>
                                <FieldErrors errors={campaign.errors} />
                            </div>
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default GenericSection;
