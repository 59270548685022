import React from "react";

interface IProps {
    label: string;
    className?: string;
    htmlFor?: string;
    required?: boolean;
    isValid?: boolean;
}

const Label: React.FC<IProps> = ({ label, className = "", required = false, isValid = true, htmlFor }) => {
    if (label === "") {
        return (
            <label
                className={`block uppercase tracking-wider ${
                    isValid ? "text-blue-800" : "text-red"
                } text-base ${className}`}
            >
                <span className={`text-lg text-red`}>&nbsp;</span>
            </label>
        );
    }

    return (
        <label
            htmlFor={htmlFor}
            className={`block uppercase tracking-wider ${
                isValid ? "text-blue-800" : "text-red"
            } text-base ${className}`}
        >
            {label !== "" ? <span>{label}</span> : <span>&nbsp;</span>}
            {required && label !== "" ? (
                <span className={`text-lg text-red`}>*</span>
            ) : (
                <span className={`text-lg text-red`}>&nbsp;</span>
            )}
        </label>
    );
};

export default Label;
