import React, { SVGProps } from "react";
import Svg from "./Svg";

const HistoryIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M14 4a9 9 0 0 0-9 9H2l3.96 4.03L10 13H7c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0 0 14 22a9 9 0 0 0 0-18zm-1 5.5V14l3.85 2.285a.5.5 0 0 0 .685-.174l.21-.351a.5.5 0 0 0-.175-.685L14.5 13.25V9.5A.5.5 0 0 0 14 9h-.5a.5.5 0 0 0-.5.5z" />
    </Svg>
);

export default HistoryIcon;
