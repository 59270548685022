import React from "react";
import IAppState from "../../../../../interfaces/IAppState";
import ITrackingParameterFormValues from "../../../../../interfaces/ITrackingParameterFormValues";
import ITrackingParameter, {
    IArrayOfCampaigns,
    IArrayOfRemoteCampaigns,
    IRequiresClientId
} from "../../../../../interfaces/ITrackingParameter";
import { createTrackingParameter } from "../../../../../actions/trackingParameterActions";
import { useDispatch, useSelector } from "react-redux";
import withClient from "../../../../withClient";
import TrackingParametersForm from "./TrackingParametersForm";
import IClient from "../../../../../interfaces/IClient";
import { IOption } from "../../../../../interfaces/IDisplayCampaign";
import { useNavigate } from "react-router-dom";
interface IProps {
    currentClient: IClient;
    platformOptions: {
        [key: string]: IOption;
    };
}

const NewTrackingParameter: React.FunctionComponent<IProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const adwordsCampaigns = useSelector(({ adwordsCampaigns: { entities } }: IAppState) => entities);
    const microsoftCampaigns = useSelector(({ microsoftCampaigns: { entities } }: IAppState) => entities);

    const onSave = (
        values: (ITrackingParameterFormValues & IArrayOfCampaigns) | IArrayOfRemoteCampaigns,
        currentClient: IClient
    ): void => {
        const newPayload = {
            ...values,
            clientId: currentClient.id
        } as ITrackingParameterFormValues & IArrayOfCampaigns & IRequiresClientId;
        dispatch(
            createTrackingParameter(newPayload, (trackingParameter: ITrackingParameter) => {
                navigate(`/client/${trackingParameter.clientId}/campaigns/tracking-parameters/${trackingParameter.id}`);
            })
        );
    };

    return (
        <div>
            <TrackingParametersForm
                {...props}
                adwordsCampaigns={adwordsCampaigns}
                microsoftCampaigns={microsoftCampaigns}
                onSave={onSave}
                initialValues={{
                    name: "",
                    platform: "google",
                    parameters: "",
                    trackedCampaigns: []
                }}
            />
        </div>
    );
};

export default withClient(NewTrackingParameter);
