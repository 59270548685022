import IEntity from "../interfaces/IEntity";
import IBudgetLabel from "../interfaces/Budgets/IBudgetLabel";
import LabelConstants from "../constants/LabelConstants";

const fetchLabels = (clientId: number) => ({
    type: LabelConstants.REQUEST_LABELS,
    payload: {
        clientId,
        loading: true
    }
});

const fetchLabelsSuccess = (labels: IEntity<IBudgetLabel>) => ({
    type: LabelConstants.REQUEST_LABELS_SUCCESS,
    payload: {
        labels,
        loading: false
    }
});

const fetchLabelsFailed = () => ({
    type: LabelConstants.REQUEST_LABELS_FAIL,
    payload: {
        labels: {},
        loading: false
    }
});

const refreshLabels = (clientId: number) => ({
    type: LabelConstants.REQUEST_REFRESH_LABELS,
    payload: {
        clientId,
        loading: true
    }
});

const fetchMicrosoftLabels = (clientId: number) => ({
    type: LabelConstants.REQUEST_MICROSOFT_LABELS,
    payload: { clientId, labels: {}, loading: true }
});

const refreshMicrosoftLabels = (clientId: number) => ({
    type: LabelConstants.REQUEST_REFRESH_MICROSOFT_LABELS,
    payload: { clientId, labels: {}, loading: true }
});

const fetchMicrosoftLabelsSuccess = (labels: IEntity<IBudgetLabel>) => ({
    type: LabelConstants.REQUEST_MICROSOFT_LABELS_SUCCESS,
    payload: {
        labels,
        loading: false
    }
});

const fetchMicrosoftLabelsFail = () => ({
    type: LabelConstants.REQUEST_MICROSOFT_LABELS_FAIL,
    payload: {
        labels: {},
        loading: false
    }
});

export default {
    fetchLabels,
    fetchLabelsFailed,
    fetchLabelsSuccess,
    refreshLabels,

    fetchMicrosoftLabels,
    refreshMicrosoftLabels,
    fetchMicrosoftLabelsSuccess,
    fetchMicrosoftLabelsFail
};
