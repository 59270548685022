import React from "react";
import { Link } from "react-router-dom";
import PreScreenSection from "./ReviewSections/PreScreenSection";
import NameSection from "./ReviewSections/NameSection";
import LocationsSection from "./ReviewSections/LocationsSection";
import CompetitorsSection from "./ReviewSections/CompetitorsSection";
import ServicesSection from "./ReviewSections/ServicesSection";
import DealerInfoSection from "./ReviewSections/DealerInfoSection";
import DynamicSection from "./ReviewSections/DynamicSection";
import GenericSection from "./ReviewSections/GenericSection";
import ConquestsSection from "./ReviewSections/ConquestsSection";
import RemarketingSection from "./ReviewSections/RemarketingSection";
import IFormValues from "../../../interfaces/DealerSetup/IFormValues";
import PencilIcon from "../../Shared/Icons/PencilIcon";
import NoVisible from "../../Shared/Icons/NotVisibleIcon";
import WarningIcon from "../../Shared/Icons/WarningIcon";
import CheckIcon from "../../Shared/Icons/CheckIcon";
import NegativeKeywordsSection from "./ReviewSections/NegativeKeywordsSection";
import AdExtensionsSection from "./ReviewSections/AdExtensionsSection";

interface IProps {
    formValues: IFormValues;
    title: string;
    path: string;
}

const renderReviewContent = (path: string, page: any, formValues: IFormValues) => {
    switch (path) {
        case "name":
            return <NameSection fields={page.fields} />;
        case "pre-screen":
            return <PreScreenSection fields={page.fields} />;
        case "locations":
            return <LocationsSection fields={page.fields} />;
        case "competitors":
            return <CompetitorsSection fields={page.fields} />;
        case "services":
            return <ServicesSection fields={page.fields} />;
        case "dealer-info":
            return <DealerInfoSection fields={page.fields} />;
        case "dynamic":
            return <DynamicSection fields={page.fields} />;
        case "generic":
            return <GenericSection fields={page.fields} />;
        case "conquest":
            return <ConquestsSection fields={page.fields} />;
        case "remarketing":
            return <RemarketingSection fields={page.fields} />;
        case "negative-keywords":
            return <NegativeKeywordsSection fields={formValues} negativeKeywordLists={{}} />;
        case "ad-extensions":
            return <AdExtensionsSection fields={page.fields} sitelinkTemplates={{}} />;
        default:
            return <div>Section not setup yet</div>;
    }
};

const DealerSetupReviewSection: React.FunctionComponent<IProps> = ({ formValues, title, path }) => {
    const page = formValues[path];
    const { isTouched, isValid, isDisabled } = page;
    return isDisabled ? null : (
        <div className="mb-8">
            <div className="flex justify-between border-b pb-3">
                <div className="flex content-center">
                    <div className="m-0 text-xl font-bold self-center">{title}</div>
                    {!isTouched && <NoVisible className="text-gray-500 px-4 self-center text-base h-6" />}
                    {!isValid && isTouched && <WarningIcon className="text-red-500 px-4 self-center text-base h-6" />}
                    {isValid && isTouched && <CheckIcon className="text-green-500 px-4 self-center text-base h-6" />}
                </div>
                <Link
                    to={`../${path}`}
                    className="bg-blue-500 hover:bg-blue-600 text-white hover:text-white rounded py-1 px-3 self-center flex items-center"
                >
                    <PencilIcon className="w-6 h-6" />
                    <span className="text-sm font-bold mx-2">Edit</span>
                </Link>
            </div>
            {isTouched && page.fields ? (
                renderReviewContent(path, page, formValues)
            ) : (
                <div className="text-sm m-4 text-red">This section is required but has not been viewed.</div>
            )}
        </div>
    );
};

export default DealerSetupReviewSection;
