import React, { FunctionComponent } from "react";

interface IProps {
    clientName: string;
    clientId: number;
}

const NameCell: FunctionComponent<IProps> = ({ clientId, clientName }) => (
    <div className="cell-wrapper ">
        <a href={`/client/${clientId}/budgets/adwords`}>{clientName}</a>
    </div>
);

export default NameCell;
