import React from "react";
import WarningIcon from "../Shared/Icons/WarningIcon";

const ActivateRedirect = () => {
    return (
        <div className="bg-yellow-100 text-bg-yellow-600 rounded p-8 flex my-8 items-center">
            <WarningIcon className="w-8 h-8 fill-current" />
            <p className="text-xl mx-8 p-0 my-0">
                You can not activate an account while logged in. <br />
                Please <a href="/auth/logout">logout</a> and try again.
            </p>
        </div>
    );
};

export default ActivateRedirect;
