import IEntities from "../../../interfaces/IEntity";
import { IEtaTemplateBoilerplate } from "../../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";
import actionCreator from "../../../utils/Redux/actionCreator";
import { IExpandedTextAdTemplate } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";

export const index = {
    request: actionCreator("ETA_TEMPLATE_BOILERPLATE/INDEX/REQUEST"),
    success: actionCreator<{ entities: IEntities<IEtaTemplateBoilerplate>; data: [number] }>(
        "ETA_TEMPLATE_BOILERPLATE/INDEX/SUCCESS"
    ),
    failure: actionCreator<{ error: string }>("ETA_TEMPLATE_BOILERPLATE/INDEX/FAILURE")
};

export const store = {
    request: actionCreator<{ name: string; eta: IExpandedTextAdTemplate; clientId?: number }>(
        "ETA_TEMPLATE_BOILERPLATE/STORE/REQUEST"
    ),
    success: actionCreator<{ boilerplate: IEtaTemplateBoilerplate }>("ETA_TEMPLATE_BOILERPLATE/STORE/SUCCESS"),
    failure: actionCreator<{ error: string }>("ETA_TEMPLATE_BOILERPLATE/STORE/FAILURE")
};

export const destroy = {
    request: actionCreator<{ boilerplate: IEtaTemplateBoilerplate }>("ETA_TEMPLATE_BOILERPLATE/DESTROY/REQUEST"),
    success: actionCreator<{ id: number }>("ETA_TEMPLATE_BOILERPLATE/DESTROY/SUCCESS"),
    failure: actionCreator<{ id: number; error: string }>("ETA_TEMPLATE_BOILERPLATE/DESTROY/FAILURE")
};

// These actions are only used for event tracking, they aren't actually being handled in any of our reducers
const loadBoilerplate = actionCreator<{ templates: number; form: string }>("ETA_TEMPLATE_BOILERPLATE/LOAD");
const addNewTemplate = actionCreator<{ form: string }>("ETA_TEMPLATE/ADD_NEW");
const duplicateTemplate = actionCreator<{ form: string }>("ETA_TEMPLATE/DUPLICATE");

export default {
    index,
    store,
    destroy,
    loadBoilerplate,
    addNewTemplate,
    duplicateTemplate
};
