import { schema } from "normalizr";

const microsoftCampaign = new schema.Entity("microsoftCampaigns");

const Schemas = {
    CAMPAIGN: microsoftCampaign,
    CAMPAIGN_ARRAY: { data: [microsoftCampaign] }
};

export default Schemas;
