import React, { SVGProps } from "react";
import Svg from "./Svg";

const CreditCardIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M2.99963 17.7727C2.99963 18.3634 3.24987 18.9299 3.69527 19.3477C4.14067 19.7654 4.74475 20 5.37463 20H19.6246C20.2545 20 20.8586 19.7654 21.304 19.3477C21.7495 18.9299 21.9996 18.3634 21.9996 17.7727V11.6477H2.99963V17.7727ZM5.79875 14.75C5.79875 14.4335 5.9328 14.13 6.1714 13.9063C6.41002 13.6825 6.73363 13.5568 7.07107 13.5568H9.10678C9.44422 13.5568 9.76785 13.6825 10.0065 13.9063C10.2451 14.13 10.3791 14.4335 10.3791 14.75V15.5454C10.3791 15.8619 10.2451 16.1654 10.0065 16.3891C9.76785 16.6129 9.44422 16.7387 9.10678 16.7387H7.07107C6.73363 16.7387 6.41002 16.6129 6.1714 16.3891C5.9328 16.1654 5.79875 15.8619 5.79875 15.5454V14.75ZM19.6246 6H5.37463C4.74475 6 4.14067 6.23465 3.69527 6.65235C3.24987 7.07003 2.99963 7.63655 2.99963 8.22727V9.26135H21.9996V8.22727C21.9996 7.63655 21.7495 7.07003 21.304 6.65235C20.8586 6.23465 20.2545 6 19.6246 6Z" />
    </Svg>
);

export default CreditCardIcon;
