import React from "react";
import { MentionsInput, Mention } from "react-mentions";

interface IProps {
    className: string;
    fieldName: string;
    optionValues: string[];
    values: { [key: string]: any };
    singleLine?: boolean;
    onInputBlur: (fieldName: string) => void;
    onInputChange: (fieldName: string, value: any) => void;
}

const defaultStyle = {
    control: {
        backgroundColor: "#fff",

        fontSize: 14,
        fontWeight: "normal"
    },

    highlighter: {
        overflow: "hidden"
    },

    input: {
        margin: 0
    },
    "&multiLine": {
        control: {
            display: "inline-block",
            width: "100%"
        },

        highlighter: {
            padding: 1,
            border: "2px inset transparent",
            maxWidth: 100
        },

        input: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: ".5rem",
            paddingBottom: ".5rem",
            borderRadius: ".5rem",
            border: "1px solid #dae1e7",
            minHeight: 40,
            maxHeight: 200,
            overflow: "auto"
        }
    },

    "&singleLine": {
        control: {
            display: "inline-block",
            width: "100%"
        },

        highlighter: {
            padding: 1,
            border: "2px inset transparent",
            maxWidth: 100
        },

        input: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: ".5rem",
            paddingBottom: ".5rem",
            borderRadius: ".5rem",
            border: "1px solid #dae1e7"
        }
    },

    suggestions: {
        top: "100%",
        backgroundColor: "hsl(0,0%,100%)",
        borderRadius: "4px",
        boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
        marginBottom: "8px",
        marginTop: "8px",
        position: "absolute",
        width: "100%",
        zIndex: "1",
        boxSizing: "border-box",
        maxHeight: "300px",
        list: {
            maxHeight: "300px",
            overflowY: "auto",
            paddingBottom: "4px",
            paddingTop: "4px",
            position: "relative",
            boxSizing: "border-box"
        },
        item: {
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "default",
            display: "block",
            fontSize: "inherit",
            padding: "8px 12px",
            width: "100%",
            userSelect: "none",
            boxSizing: "border-box",
            "&focused": {
                backgroundColor: "#DEEBFF"
            }
        }
    }
};

const InventoryMentionsInput: React.FunctionComponent<IProps> = ({
    className,
    optionValues,
    values,
    singleLine = true,
    fieldName,
    onInputChange,
    onInputBlur
}) => {
    const mappedFields = optionValues.map((item) => ({ id: item, display: item }));
    const value = values[fieldName];
    const onBlur = () => onInputBlur(fieldName);
    const onChange = (event: { target: { value: string } }) => onInputChange(fieldName, event.target.value);
    return (
        <React.Fragment>
            <MentionsInput
                value={value ?? ""}
                onChange={onChange}
                onBlur={onBlur}
                singleLine={singleLine}
                className="m w-full"
                classNames={{
                    m__input: `${className}`
                }}
                style={{ ...defaultStyle }}
            >
                <Mention
                    markup="{{__id__}}"
                    regex={new RegExp("{{(w| |d|:)}}")}
                    trigger={"{{"}
                    data={mappedFields}
                    displayTransform={(item: any) => `{{${item}}}`}
                />
            </MentionsInput>
        </React.Fragment>
    );
};

export default InventoryMentionsInput;
