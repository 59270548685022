import React from "react";
import BudgetLayout from "../BudgetLayout";
import { IManualBudgetFormValues } from "../../../../interfaces/Budgets/IBudgetFormValues";
import { ICreateManualBudgetProps } from "../../../../interfaces/Budgets/IBudgetCreateProps";
import NotFound from "../../../Shared/NotFound404";
import BudgetForm from "./ManualBudgetForm";
import useManualBudget from "../../../../hooks/budgets/useManualBudget";
import FullPageLoader from "../../../Shared/Loaders/FullPageLoader";
import useBudgetParams from "../../../../hooks/budgets/useBudgetParams";
interface IProps extends ICreateManualBudgetProps {
    budgetId: number | null;
    budget: any;
    pageTitle: string;
    links?: any;
    isReadOnly: boolean;
    createBudget: (budget: IManualBudgetFormValues, clientId: number) => void;
    updateBudget: (budget: IManualBudgetFormValues, clientId: number) => void;
    fetchBudget: (clientId: number, budgetId: number) => void;
}

const DspBudgetFormContainer = () => {
    const {
        loadingBudget,
        loadingMessage,
        budget,
        isSaving,
        apiErrors,
        formSubmitHandler,
        budgetTitle,
        pageTitle,
        missingBudget,
        isReadOnly,
        cancelPath
    } = useManualBudget();
    const { actionType } = useBudgetParams();

    const isLoading = loadingBudget || (!loadingBudget && !budget && !missingBudget);

    if (!isLoading && missingBudget) return <NotFound message={"The budget you are looking for could not be found"} />;

    if (actionType === "edit" && isLoading) return <FullPageLoader message="Loading Manual Budget..." />;

    return (
        <>
            <BudgetLayout {...{ pageTitle, isReadOnly: isReadOnly }}>
                <div className="w-full flex justify-center">
                    <BudgetForm
                        {...{
                            budget,
                            loadingBudget,
                            loadingMessage,
                            isSaving,
                            apiErrors,
                            cancelPath
                        }}
                        onSubmit={formSubmitHandler}
                        title={budgetTitle}
                    />
                </div>
            </BudgetLayout>
        </>
    );
};

export default DspBudgetFormContainer;
