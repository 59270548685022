import React, { SVGProps } from "react";

const FuelInsightsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg className={`fill-current ${props.className || ""}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <path d="M11.869 10.313c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5m0 2c1.379 0 2.5 1.12 2.5 2.5s-1.121 2.5-2.5 2.5a2.5 2.5 0 0 1 0-5m0-5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5m0 2c3.79 0 7.169 2.13 8.82 5.5a9.759 9.759 0 0 1-8.82 5.5c-3.8 0-7.17-2.13-8.82-5.5a9.77 9.77 0 0 1 8.82-5.5" />
        <path
            stroke="#008FCC"
            strokeLinecap="round"
            strokeWidth="2"
            d="M12 4.68V1M19.14 7.388l2.296-2.296M1.603 4.244l2.629 2.629M12.039 25.196v3.68M4.899 22.487l-2.296 2.296M22.436 25.631l-2.63-2.629"
        />
    </svg>
);

export default FuelInsightsIcon;
