import React, { SVGProps } from "react";
import Svg from "./Svg";

const PauseIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M6 22h3.002a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1zm8.998-17v16a1 1 0 0 0 1 1H19a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-3.002a1 1 0 0 0-1 1z" />
    </Svg>
);

export default PauseIcon;
