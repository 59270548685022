import React from "react";
import Variations from "./Variations";
import { capitalize } from "lodash";
import FieldErrors from "./FieldErrors";
import TrashIcon from "../../../Shared/Icons/TrashIcon";

interface IProps {
    index: number;
    label: string;
    value: string | undefined;
    name: string;
    variations: string[];
    errors: string[];
    deletable: boolean;
    className?: string;
    handleChange: any;
    handleDelete: any;
    variationLabel?: string;
    description?: string;
    readOnly?: boolean;
}

const VariationsInput: React.FunctionComponent<IProps> = ({
    index,
    label,
    value,
    name,
    variations,
    errors,
    deletable,
    className,
    handleChange,
    handleDelete,
    variationLabel = "Keyword Variations",
    description,
    readOnly = false
}) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(index, { [name]: event.target.value, variations });
    };

    const handleVariationsChange = (values: any) => {
        handleChange(index, { [name]: value, variations: values });
    };

    return (
        <div className={`${className ? className : "w-2/3 mt-4"}`}>
            <div className="w-full py-2 font-bold text-base">{label}</div>
            <div className="w-full py-2 flex">
                <input
                    value={value}
                    readOnly={readOnly}
                    type="text"
                    name={name}
                    placeholder={capitalize(name)}
                    onChange={handleInputChange}
                    className={`text-base border flex-1 py-2 px-4 rounded ${readOnly ? "bg-gray-300" : ""} ${
                        deletable ? "rounded-r-none" : ""
                    }`}
                />
                {deletable ? (
                    <button
                        className="flex-none bg-gray-400 text-gray-600 hover:text-gray-800 rounded rounded-l-none px-6"
                        onClick={() => handleDelete(index)}
                    >
                        <TrashIcon className="w-6 h-6 text-lg" />
                    </button>
                ) : (
                    ""
                )}
            </div>
            <FieldErrors errors={errors} />
            <Variations values={variations} handleChange={handleVariationsChange} label={variationLabel} />
            {description && <p className="text-blue-400">{description}</p>}
        </div>
    );
};

export default VariationsInput;
