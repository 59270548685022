import React, { RefObject, useEffect, useRef } from "react";
import { Field, FieldProps } from "formik";

interface IProps {
    templateIndex: number;
    autoFocus: boolean;
}

const TemplateLabel: React.FunctionComponent<IProps> = ({ templateIndex, autoFocus }) => {
    const templateField = `expandedTextAdTemplates[${templateIndex}]`;
    const fieldName = `${templateField}[label]`;
    const inputRef = useRef() as RefObject<HTMLInputElement>;

    const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
        const { value } = event.currentTarget;
        // set the value of field
        setFieldValue(`${fieldName}`, value);
        // set the template to dirty so request is made
        setFieldValue(`${templateField}.dirty`, true);
    };

    useEffect(() => {
        if (autoFocus) inputRef?.current?.focus();
    }, [autoFocus]);

    return (
        <Field
            name={fieldName}
            type="text"
            render={(props: FieldProps) => {
                const { form, field } = props;
                const { setFieldValue, touched } = form;
                const errors: any = form.errors[fieldName];
                const isInvalid = touched[field.name] && errors[field.name];

                return (
                    <div className={`relative w-1/2 mb-4`}>
                        <input
                            ref={inputRef}
                            type="text"
                            name={fieldName}
                            onChange={(event) => handleLabelChange(event, setFieldValue)}
                            value={field.value}
                            className={`w-full mr-12 border-gray-500 border rounded px-3 py-3 ${
                                isInvalid ? "is-invalid" : ""
                            }`}
                            data-testid={`eta-label-input-${templateIndex}`}
                        />
                        {errors && <div className="text-red-600 pb-4">{errors}</div>}
                    </div>
                );
            }}
        >
            <h1 className="text-2xl font-semibold py-4">TemplateLabel</h1>
        </Field>
    );
};

export default TemplateLabel;
