import React from "react";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";

export type onClickFunction = () => void;

interface IProps {
    path?: string | null;
    label: string;
    color?: string;
    disabled?: boolean;
    processing?: boolean;
    onClick?: onClickFunction | null;
}

const DealerSetupButton: React.FunctionComponent<IProps> = ({
    path = null,
    label,
    color = "gray",
    disabled = false,
    processing = false,
    onClick = null
}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (onClick !== null) {
            onClick();
        }
        if (path !== null) {
            navigate(`../${path}`);
        }
    };

    return (
        <button
            className={`
                ${disabled ? "cursor-not-allowed opacity-50" : ""}
                bg-${color}-500 hover:bg-${color}-600
                rounded text-white py-3 px-12 text-base
            `}
            onClick={() => handleClick()}
            disabled={disabled}
        >
            {processing ? <RefreshIcon className="w-6 h-6 inline-block rotate-fast mr-2" /> : ""}
            {label}
        </button>
    );
};

export default DealerSetupButton;
