import { Reducer } from "redux";
import IEntity from "../interfaces/IEntity";
import { LOAD_INVENTORY_EXPORTS } from "../constants/InventoryExportsConstants";

import {
    REQUEST_GOOGLE_AUTO_EXPORTS,
    REQUEST_GOOGLE_AUTO_EXPORTS_FAIL,
    REQUEST_GOOGLE_AUTO_EXPORTS_SUCCESS,
    REQUEST_CREATE_GOOGLE_EXPORT,
    CREATE_GOOGLE_EXPORT_SUCCESS,
    CREATE_GOOGLE_EXPORT_FAIL,
    REQUEST_UPDATE_GOOGLE_EXPORT,
    UPDATE_GOOGLE_EXPORT_SUCCESS,
    UPDATE_GOOGLE_EXPORT_FAIL,
    REQUEST_DELETE_GOOGLE_EXPORT,
    DELETE_GOOGLE_EXPORT_SUCCESS,
    DELETE_GOOGLE_EXPORT_FAIL
} from "../constants/InventoryExportsConstants";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

interface IGoogleAutoExportState {
    entities: null | IEntity<any>;
    loadedAt: null | Date;
    deleting: boolean;
    errors: null;
}

const initialState = {
    entities: null,
    loadedAt: null,
    deleting: false,
    errors: null
};

const reducer: Reducer<IGoogleAutoExportState> = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_INVENTORY_EXPORTS:
            return { ...state, entities: action.response.entities.googleAutoExports || null, loadedAt: new Date() };
        case REQUEST_GOOGLE_AUTO_EXPORTS:
            return { ...state, errors: null };
        case REQUEST_GOOGLE_AUTO_EXPORTS_FAIL:
            return { ...state, errors: action.errors, loadedAt: new Date() };
        case REQUEST_GOOGLE_AUTO_EXPORTS_SUCCESS:
            return { ...state, entities: action.payload, errors: null, loadedAt: new Date() };
        case REQUEST_DELETE_GOOGLE_EXPORT:
            return { ...state, deleting: true };
        case UPDATE_GOOGLE_EXPORT_SUCCESS:
        case CREATE_GOOGLE_EXPORT_SUCCESS:
            return {
                ...state,
                entities: { ...state.entities, [action.payload.googleExport.id]: action.payload.googleExport }
            };

        case DELETE_GOOGLE_EXPORT_SUCCESS:
            if (!state.entities) {
                return state;
            }
            delete state.entities[action.payload.googleExportId];
            return { ...state, deleting: false, entities: { ...state.entities } };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
