import { Reducer } from "redux";
import IAutomobileManufacturer from "../interfaces/IAutomobileManufacturer";
import IEntity from "../interfaces/IEntity";
import AutomobileManufacturerConstants from "../constants/AutomobileManufacturerConstants";

interface IAutomobileManufacturers {
    entities: IEntity<IAutomobileManufacturer>;
    loading: boolean;
}

const initialState = {
    entities: {},
    loading: false
};

const automobileManufacturersReducer: Reducer<IAutomobileManufacturers> = (state = initialState, action) => {
    switch (action.type) {
        case AutomobileManufacturerConstants.REQUEST_AUTOMOBILE_MANUFACTURERS:
            return { ...state, loading: true };
        case AutomobileManufacturerConstants.REQUEST_AUTOMOBILE_MANUFACTURERS_SUCCESS:
        case AutomobileManufacturerConstants.REQUEST_AUTOMOBILE_MANUFACTURERS_FAIL:
            return {
                ...state,
                entities: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default automobileManufacturersReducer;
