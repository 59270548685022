export default {
    FETCH_MANUFACTURER_LIST: "FETCH_MANUFACTURER_LIST",
    FETCH_MANUFACTURER_LIST_SUCCESS: "FETCH_MANUFACTURER_LIST_SUCCESS",
    FETCH_MANUFACTURER_LIST_FAILURE: "FETCH_MANUFACTURER_LIST_FAILURE",
    SAVE_FORM_VALUES: "SAVE_FORM_VALUES",
    TOGGLE_FORM_PAGES: "TOGGLE_FORM_PAGES",
    SAVE_FORM_REQUEST: "SAVE_FORM_REQUEST",
    SAVE_FORM_SUCCESS: "SAVE_FORM_SUCCESS",
    SAVE_FORM_FAILURE: "SAVE_FORM_FAILURE",
    PROCESS_CAMPAIGNS_REQUEST: "PROCESS_CAMPAIGNS_REQUEST",
    PROCESS_CAMPAIGNS_SUCCESS: "PROCESS_CAMPAIGNS_SUCCESS",
    PROCESS_CAMPAIGNS_FAILURE: "PROCESS_CAMPAIGNS_FAILURE",
    FETCH_DEALER_SETUP_DATA_REQUEST: "FETCH_DEALER_SETUP_DATA_REQUEST",
    FETCH_DEALER_SETUP_DATA_SUCCESS: "FETCH_DEALER_SETUP_DATA_SUCCESS",
    FETCH_DEALER_SETUP_DATA_FAILURE: "FETCH_DEALER_SETUP_DATA_FAILURE"
};
