import { call, CallEffect, put, takeEvery } from "redux-saga/effects";
import { doDeleteRequest, getPagedResults, IResponse } from "../middleware/api";
import SCHEMAS from "../middleware/schemas";
import * as actions from "../actions/notificationActions";
import { NotificationFilterChangedActionType } from "../actions/notificationActions";
import { issueFailedSave } from "../actions/errorsActions";
import { INotification } from "../interfaces/Notifications/INotification";

export function* loadPage(url: string, limit: number): Generator<CallEffect> {
    return yield call(getPagedResults, url, SCHEMAS.NOTIFICATION_ARRAY, limit);
}

interface IFetchNotificationsResponse {
    result: IResponse;
    entities: {
        notifications: any;
    };
}

export function* fetchNotifications({ url = "/user-notifications", page = 1, limit = 100 }: any) {
    try {
        url += (url.includes("?") ? "&" : "?") + "page=" + page;

        const response: IFetchNotificationsResponse = yield call(loadPage, url, limit);
        const pagination = {
            totalRecords: response.result.total,
            nextUrl: response.result.nextPageUrl,
            loadedRecords: response.result.data.length,
            lastPageLoaded: response.result.currentPage
        };

        const notifications = response.entities.notifications;
        yield put({ type: actions.LOAD_NOTIFICATIONS, notifications, pagination });
    } catch (e) {
        yield put({ type: actions.FAILED_LOAD_NOTIFICATIONS, errors: e.errors });
    }

    // tell the pager that we are done loading pages
    yield put({ type: actions.DONE_LOADING_PAGES });
}

export function* filter({ filterValue }: NotificationFilterChangedActionType) {
    let url = "/user-notifications";
    if (filterValue !== "") {
        url = `${url}?search[type]=like,*${filterValue}*`;
    }
    yield call(fetchNotifications, { url });
}

export function* markAsRead({ notification }: any) {
    try {
        yield call(doDeleteRequest, `/user-notifications/${notification.id}`);
        yield put(actions.removeNotification(notification));
    } catch (e) {
        // reset deleting on notification
        yield put(actions.undoMarkAsRead(notification));
        yield put(issueFailedSave({ error: "Failed to archive notification." }));
    }
}

export function* markAllAsRead() {
    try {
        yield call(doDeleteRequest, `/user-notifications`);
        yield put(actions.removeAllNotifications());
    } catch (e) {
        // reset deleting on notification
        yield put(actions.undoMarkAllAsRead());
        yield put(issueFailedSave({ error: "Failed to archive notifications." }));
    }
}

function* notificationsSaga() {
    yield takeEvery(actions.REQUEST_LOAD_NOTIFICATIONS, fetchNotifications);
    yield takeEvery(actions.MARK_AS_READ, markAsRead);
    yield takeEvery(actions.MARK_ALL_AS_READ, markAllAsRead);
    yield takeEvery(actions.NOTIFICATION_FILTER_CHANGED, filter);
}

export default notificationsSaga;
