import client from "../middleware/schemas/client";
import buildUrl from "../utils/UrlUtils";
import { IHttpPaginatedQueryParameters } from "../interfaces/IHttpQueryParameters";

interface IBudgetQuery extends IHttpPaginatedQueryParameters {
    clientId?: number | null;
    budgetId?: number | null;
    serviceId: number | null;
    findDeleted?: boolean;
}
const BUDGET_URL = ({
    clientId,
    serviceId,
    findDeleted,
    budgetId,
    limit,
    page,
    urlOptions = {
        filter: {},
        search: {},
        sort: ""
    }
}: IBudgetQuery) => {
    let base = "/budgets";
    if (clientId) {
        base = `/clients/${clientId}/budgets`;
    }
    // if no client id, must be all clients all budgets index route

    if (serviceId) {
        urlOptions.filter = {
            ...urlOptions.filter,
            service_id: serviceId
        };
    }

    if (findDeleted) {
        urlOptions.filter["onlyTrashed"] = true;
    }

    const include = ["client.manager", "client.pfm", "activation.strategy"];

    if (clientId) {
        include.push("activation.recentChangeActions", "allActivations");
    }

    const singleBudgetQuery = budgetId ? `/${budgetId}` : "";

    return buildUrl(base + singleBudgetQuery, {
        ...urlOptions,
        include,
        limit,
        page
    });
};
const MANUAL_DSP_BUDGET_URL = ({ clientId, budgetId }: { clientId: string; budgetId?: string }) => {
    const base = `/clients/${clientId}/manual-budgets`;
    const include = [
        "client",
        "client.manager",
        "client.pfm",
        "lastActivation",
        "previousActivation",
        "manualBudgetActivations.createdBy"
    ];
    const singleBudgetQuery = budgetId ? `/${budgetId}` : "";

    return buildUrl(base + singleBudgetQuery, {
        include
    });
};

const REACTIVATE_ADWORDS_BUDGET_URL = (clientId: number, budgetId: number, activationId: number) =>
    `/clients/${clientId}/budgets/${budgetId}/activations/${activationId}/reactivate`;
const EXECUTE_ADWORDS_BUDGET_URL = (updateActionId: number) => `/budget-actions/${updateActionId}/execute`;
const DELETE_ADWORDS_BUDGET_URL = (clientId: number, budgetId: number) => `/clients/${clientId}/budgets/${budgetId}`;

const FETCH_LABELS_URL = (clientId: string) => `/clients/${clientId}/adwords-text-labels?page=1&limit=100`;
const FETCH_MS_LABELS_URL = (clientId: string) => `/clients/${clientId}/microsoft/labels?page=1&limit=100`;

const FETCH_REFRESH_MS_LABELS_URL = (clientId: string) => `${FETCH_MS_LABELS_URL(clientId)}&refresh=1`;
const FETCH_REFRESH_LABELS_URL = (clientId: string) => `${FETCH_LABELS_URL(clientId)}&refresh=1`;

const FETCH_DYNAMIC_CAMPAIGN_URL = (clientId: number, id: number): string => {
    const expand = [
        "lockedByAction",
        "previousAction",
        "inventoryFilters",
        "keywordBidModifiers",
        "keywordTemplateRelationships",
        "keywordTemplateRelationships.conditionals",
        "keywordTemplateRelationships.keywordTemplate",
        "keywordTemplateRelationships.keywordTemplate.conditionals",
        "adGroupBidModifiers",
        "adGroupBidModifiers.conditionals",
        "expandedTextAdTemplates",
        "expandedTextAdTemplates.parts",
        "expandedTextAdTemplates.parts.conditionals",
        "expandedTextAdTemplates.conditionals",
        "specialOfferTemplates.specialOfferTemplate",
        "specialOfferTemplates.parts.conditionals"
    ];

    const expandQuery = expand.map((relationship) => `expand[${relationship}]=*`).join("&");
    return `/clients/${clientId}/dynamic-campaigns/${id}?${expandQuery}`;
};
const ALL_DYNAMIC_CAMPAIGNS_URL = (clientId: number): string => {
    const expand = [
        "lockedByAction",
        "previousAction",
        "inventoryFilters",
        "keywordBidModifiers",
        "keywordTemplateRelationships",
        "keywordTemplateRelationships.conditionals",
        "keywordTemplateRelationships.keywordTemplate",
        "keywordTemplateRelationships.keywordTemplate.conditionals",
        "adGroupBidModifiers",
        "adGroupBidModifiers.conditionals",
        "expandedTextAdTemplates",
        "expandedTextAdTemplates.parts",
        "expandedTextAdTemplates.parts.conditionals",
        "expandedTextAdTemplates.conditionals",
        "specialOfferTemplates.specialOfferTemplate",
        "specialOfferTemplates.parts.conditionals"
    ];

    const expandQuery = expand.map((relationship) => `expand[${relationship}]=*`).join("&");
    return `/clients/${clientId}/dynamic-campaigns?limit=100&${expandQuery}`;
};
const CREATE_DYNAMIC_CAMPAIGN_URL = (clientId: number): string => `/clients/${clientId}/dynamic-campaigns`;
const DYNAMIC_CAMPAIGN_URL = (clientId: number, id: number): string => `/clients/${clientId}/dynamic-campaigns/${id}`;

const CREATE_DYNAMIC_CAMPAIGN_INVENTORY_FILTER_URL = (clientId: number, dynamicCampaignId: number): string =>
    `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/inventory-filters`;
const DYNAMIC_CAMPAIGN_INVENTORY_FILTER_URL = (clientId: number, dynamicCampaignId: number, id: number): string =>
    `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/inventory-filters/${id}`;

const EXCLUSION_COLL_CAMPAIGN_URL = (exclusionId?: number) => {
    const baseUrl = `/exclusion-coll-campaigns`;
    const url = exclusionId ? `${baseUrl}/${exclusionId}` : baseUrl;
    return url;
};

const NEGATIVE_KEYWORD_COLL_CAMPAIGN_URL = (collectionId?: number) => {
    const baseUrl = `/negative-keyword-coll-campaigns`;
    const url = collectionId ? `${baseUrl}/${collectionId}` : baseUrl;
    return url;
};

export default {
    REACTIVATE_ADWORDS_BUDGET_URL,
    EXECUTE_ADWORDS_BUDGET_URL,
    DELETE_ADWORDS_BUDGET_URL,

    FETCH_LABELS_URL,
    FETCH_REFRESH_LABELS_URL,

    FETCH_MS_LABELS_URL,
    FETCH_REFRESH_MS_LABELS_URL,

    ALL_DYNAMIC_CAMPAIGNS_URL,
    CREATE_DYNAMIC_CAMPAIGN_URL,
    DYNAMIC_CAMPAIGN_URL,
    FETCH_DYNAMIC_CAMPAIGN_URL,
    CREATE_DYNAMIC_CAMPAIGN_INVENTORY_FILTER_URL,
    DYNAMIC_CAMPAIGN_INVENTORY_FILTER_URL,

    BUDGET_URL,
    EXCLUSION_COLL_CAMPAIGN_URL,
    NEGATIVE_KEYWORD_COLL_CAMPAIGN_URL,
    MANUAL_DSP_BUDGET_URL
};
