import { connect } from "react-redux";
import CollectionList from "../../Shared/ItemCollection/CollectionList";
import * as Actions from "../../../actions/shared/itemCollectionActions";
import NegativeKeywordsConstants from "../../../constants/NegativeKeywords";

const mapStateToProps = (state: any) => ({
    listTitle: "Negative Keywords",
    collections: state.negativeKeywords.collections.entities,
    loaded: state.negativeKeywords.collections.loaded
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchCollections: () => {
            dispatch(Actions.fetchCollectionList(NegativeKeywordsConstants.ACTION_TYPE_IDENTIFIER));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionList);
