import React, { Fragment } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import RemarketingList from "./RemarketingList";
import { requestRemove } from "../../../actions/remarketing/inventoryExportsActions";
import IEntity from "../../../interfaces/IEntity";
import { IInventoryExport } from "../../../interfaces/IRemarketing";
import IClient from "../../../interfaces/IClient";
import withClient from "../../withClient";

interface IProps {
    inventoryExports: IEntity<IInventoryExport>;
    removeExportMapping(clientId: number, id: number): void;
    currentClient: IClient;
}

const ListContainer: React.FunctionComponent<IProps> = ({ inventoryExports, removeExportMapping, currentClient }) => {
    return (
        <Fragment>
            <Header showButton={true} />
            <RemarketingList
                clientId={currentClient.id}
                inventoryExports={inventoryExports}
                removeExportMapping={removeExportMapping}
            />
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeExportMapping: (clientId: number, id: number) => {
            dispatch(requestRemove(clientId, id));
        }
    };
};

export default connect(null, mapDispatchToProps)(withClient(ListContainer));
