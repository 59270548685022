import React from "react";

const labelStyles = "block uppercase tracking-wider text-blue-800 mt-4 mb-2";
interface IProps {
    className?: string;
    label: string;
    required?: boolean;
}
const Label: React.FC<IProps> = ({ className, label, required, ...rest }) => {
    return (
        <label className={`${labelStyles} ${className}`} {...rest}>
            <span>{label}</span>
            {required && <span>*</span>}
        </label>
    );
};

export default Label;

export { labelStyles };
