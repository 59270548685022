import {
    createBudget,
    updateBudget,
    deleteBudget,
    pushBudget,
    downloadCsvClicked
} from "../../../../actions/budgets/budgetActions";
import trackEvent from "../../../../utils/Analytics/trackEvent";
import { BUDGET_TYPE_ID_MONTHLY } from "../../../../constants";

function* createEvent(action: ReturnType<typeof createBudget>) {
    const budgetType = action.payload.formValues.budgetType === BUDGET_TYPE_ID_MONTHLY ? "monthly" : "event";

    yield trackEvent({
        category: "Budget Create Page",
        action: `budget_create_${budgetType}`,
        label: "Create"
    });
}

function* updateEvent(action: ReturnType<typeof updateBudget>) {
    yield trackEvent({
        category: "Budget Edit Page",
        action: `budget_update_${action.payload.clientId}_${action.payload.budgetId}`,
        label: "Update"
    });
}

function* removeEvent(action: ReturnType<typeof deleteBudget>) {
    yield trackEvent({
        category: "Budget Index Page",
        action: `budget_delete_${action.budget.client.id}_${action.budget.id}`,
        label: "Delete"
    });
}

function* pushEvent(action: ReturnType<typeof pushBudget>) {
    yield trackEvent({
        category: "Budget Index Page",
        action: `budget_push_${action.budget.clientId}_${action.budget.id}`,
        label: "Push"
    });
}

function* downloadCsv(action: ReturnType<typeof downloadCsvClicked>) {
    yield trackEvent({
        category: "Budget Layout Page",
        action: `download_csv_clicked_${action.clientId}`,
        label: "Download CSV"
    });
}

export default {
    createEvent,
    updateEvent,
    removeEvent,
    pushEvent,
    downloadCsv
};
