// keep old values if newer values meet conditions - This will ensure the store has the most populated fields of data possible.
function mergeInventorySampleData(original: { [key: string]: any }, update: { [key: string]: any }) {
    for (const key in update) {
        if (
            update[key] !== null ||
            update[key] !== undefined ||
            update[key] !== "" ||
            update[key] !== "XXXX" ||
            (typeof original[key] === "number" && typeof update[key] === "number" && original[key] < update[key])
        ) {
            original[key] = update[key];
        }
    }

    return { ...original };
}

export default mergeInventorySampleData;
