import React, { Component } from "react";
import Async from "react-select/async";
import { callApi } from "../../../../middleware/api";
import IClient from "../../../../interfaces/IClient";

import { debounce } from "lodash";
import Field from "../../../Shared/Form/Elements/Field";
import { SingleValue } from "react-select";

interface IProps {
    client: IClient;
    onLink(id?: number, name?: string, number?: string): void;
}

interface IState {
    searchValue: string;
}

interface IAccount {
    id: number;
    name: string;
    number: string;
}

interface IResponse {
    result: {
        data: IAccount[];
    };
}

class Bing extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            searchValue: ""
        };
        this.onSelect = this.onSelect.bind(this);
    }

    customSelectStyles = {
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: "39px"
        }),
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        })
    };

    filterAccounts = debounce((input: string, callback: (data: any[]) => void) => {
        if (input.length < 3) {
            return callback([]);
        }
        const url = `/microsoft/accounts?search[name]=like,*${input}*`;
        callApi(url, {})
            .then((response: IResponse) => {
                return callback(response.result.data);
            })
            .catch((error: any) => {
                return callback([]);
            });
    }, 300);

    onSelect(value: SingleValue<IAccount>) {
        if (!value) {
            this.props.onLink();
            return;
        }
        const { id, name, number } = value as IAccount;
        this.props.onLink(id, name, number);
    }

    render() {
        const { client } = this.props;
        const { bingName: name, bingNumber: number, bingId: id } = client;
        const selected = {
            id,
            name,
            number
        } as IAccount;
        const errors = client.errors;

        return (
            <div className="flex">
                <div className="w-full md:w-3/4 lg:w-1/2">
                    <Field label="Bing Account" errors={errors.bingId || []}>
                        <Async
                            getOptionLabel={(option) => (option.name ? `${option.name} - ${option.number}` : "")}
                            getOptionValue={(option) => (option.name ? `${option.name} - ${option.number}` : "")}
                            name="bingAccount"
                            value={selected}
                            loadOptions={this.filterAccounts}
                            onChange={this.onSelect}
                            isClearable={true}
                            styles={this.customSelectStyles}
                            noOptionsMessage={() => "Search for an account by name"}
                        />
                    </Field>
                </div>
            </div>
        );
    }
}
export default Bing;
