import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Budget from "./Budget";
import { TabLinks } from "../../Shared";
import BudgetLayout from "./BudgetLayout";

import Alert from "../../Shared/Alert";
import convertUTCTimeToChicago from "../../../utils/convertUTCTimeToChicago";
import useBudget from "../../../hooks/budgets/useBudget";

const BudgetList: React.FunctionComponent = () => {
    const {
        budgets,
        newBudgetPath,
        isReadOnly,
        canScheduleCancellation,
        links,
        client,
        clientId,
        loadingMessage,
        loadingStream,
        loadingTable,
        showClientColumn,
        showCancellationWarning,
        notification,
        showConfirm,
        setPage,
        setLimit,
        fetchDeletedHandler,
        denyDeleteHandler,
        removeDeletedHandler,
        deleteBudgetHandler,
        confirmDeleteHandler,
        hideNotificationHandler,
        pushBudgetHandler,
        page,
        limit,
        footer,
        pages,
        filterOptions,
        setFilterOptions,
        searchQuery,
        setSearch,
        setBudgetTableSort
    } = useBudget();

    return (
        <>
            <BudgetLayout>
                {showCancellationWarning && (
                    <Alert title="Warning! Client is scheduled for cancellation." type="warning">
                        <>
                            <p>
                                This client is scheduled for cancellation on{" "}
                                {format(convertUTCTimeToChicago(client.cancelAt ?? "") as Date, "MMMM d, yyyy")}.
                            </p>
                            {canScheduleCancellation && (
                                <Link className="underline" to={`/clients/${client.id}`}>
                                    Edit Cancellation
                                </Link>
                            )}
                        </>
                    </Alert>
                )}
                <div className="mx-2">
                    <TabLinks tabs={links} />
                </div>
                <Budget
                    {...{
                        budgets,
                        loadingMessage,
                        loadingStream,
                        loadingTable,
                        showConfirm,
                        isReadOnly,
                        clientId: String(clientId),
                        links,
                        notification,
                        showClientColumn
                    }}
                    confirmDelete={confirmDeleteHandler}
                    deleteBudget={deleteBudgetHandler}
                    denyDelete={denyDeleteHandler}
                    fetchDeleted={fetchDeletedHandler}
                    hideNotification={hideNotificationHandler}
                    pushBudget={pushBudgetHandler}
                    removeDeleted={removeDeletedHandler}
                    setPage={setPage}
                    setLimit={setLimit}
                    limit={limit}
                    page={page}
                    pages={pages}
                    footer={footer}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    searchQuery={searchQuery}
                    setSearch={setSearch}
                    setBudgetTableSort={setBudgetTableSort}
                />
            </BudgetLayout>
        </>
    );
};

export default BudgetList;
