import IClient from "../interfaces/IClient";

export const REQUEST_CAMPAIGN = "REQUEST_CAMPAIGN";
export const REQUEST_LOAD_ADWORDS_CAMPAIGNS = "REQUEST_LOAD_ADWORDS_CAMPAIGNS";
export const LOAD_CAMPAIGN = "LOAD_CAMPAIGN";
export const LOAD_CAMPAIGNS = "LOAD_CAMPAIGNS";
export const REQUEST_CAMPAIGN_FAILURE = "REQUEST_CAMPAIGN_FAILURE";

export const REQUEST_LOAD_MICROSOFT_CAMPAIGNS = "REQUEST_LOAD_MICROSOFT_CAMPAIGNS";
export const LOAD_MICROSOFT_CAMPAIGNS = "LOAD_MICROSOFT_CAMPAIGNS";
export const REQUEST_LOAD_MICROSOFT_CAMPAIGNS_FAILURE = "REQUEST_LOAD_MICROSOFT_CAMPAIGNS_FAILURE";

export const REQUEST_LOAD_ADWORDS_ADGROUPS = "REQUEST_LOAD_ADWORDS_ADGROUPS";
export const LOAD_ADWORDS_ADGROUPS = "LOAD_ADWORDS_ADGROUPS";
export const REFRESH_CAMPAIGN_CLICKED = "REFRESH_CAMPAIGN_CLICKED";
export const CREATE_PARAMETERS_CLICKED = "CREATE_PARAMETERS_CLICKED";

export const fetchCampaigns = (clientId: number | undefined, refresh = false) => ({
    type: REQUEST_LOAD_ADWORDS_CAMPAIGNS,
    clientId,
    refresh
});

export const fetchMicrosoftCampaigns = (clientId: number | undefined, refresh = false) => ({
    type: REQUEST_LOAD_MICROSOFT_CAMPAIGNS,
    clientId,
    refresh
});

export const fetchAdWordsCampaignAdGroups = (
    clientId: number | undefined,
    campaignId: string | number,
    refresh = false
) => ({
    type: REQUEST_LOAD_ADWORDS_ADGROUPS,
    clientId,
    campaignId,
    refresh
});

export const refreshCampaignClicked = (clientId: number, client: IClient) => ({
    type: REFRESH_CAMPAIGN_CLICKED,
    clientId,
    client
});

export const createParametersClicked = (clientId: number, client: IClient) => ({
    type: CREATE_PARAMETERS_CLICKED,
    clientId,
    client
});
