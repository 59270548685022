import { ISitelinkTemplate } from "../interfaces/DealerSetup/IAdExtensionFields";
import IEntity from "../interfaces/IEntity";

export const FETCH_SITELINK_TEMPLATES = "FETCH_SITELINK_TEMPLATESs";
export const FETCH_SITELINK_TEMPLATES_FAILED = "FETCH_SITELINK_TEMPLATES_FAILED";
export const FETCH_SITELINK_TEMPLATES_SUCCESS = "FETCH_SITELINK_TEMPLATES_SUCCESS";

export const requestSitelinkTemplates = () => ({
    type: FETCH_SITELINK_TEMPLATES
});

export const fetchSitelinkTemplateListSuccess = (data: IEntity<ISitelinkTemplate>) => ({
    type: FETCH_SITELINK_TEMPLATES_SUCCESS,
    payload: data
});

export const fetchSitelinkTemplateListFailed = (message: string) => ({
    type: FETCH_SITELINK_TEMPLATES_FAILED,
    payload: {
        message
    }
});
