import React from "react";

const Notification: React.FC<
    Partial<{
        title: string;
        color: string;
        message: string;
        onClose: () => void;
    }>
> = ({ title, message, color, onClose = () => null, children }) => {
    return (
        <div
            className={`bg-${color}-100 border border-${color}-400 text-${color}-600 px-4 py-3 rounded relative flex-1 flex items-center`}
            role="alert"
            data-testid="notification-test"
        >
            {children}
            <strong className="font-bold">{title}</strong>
            <span className="block pr-6"> {message}</span>
            {!!onClose && (
                <span onClick={() => onClose()} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg
                        className={`fill-current h-8 w-8 text-${color}`}
                        role="button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <title>Close</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                    </svg>
                </span>
            )}
        </div>
    );
};

export default Notification;
