import { omit } from "lodash";
import Constants from "../../constants/shared/ItemCollectionConstants";
import { ICollectionsState, IItemsState } from "../../interfaces/shared/IItemCollection";
import { Reducer, AnyAction } from "redux";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";

export function collections(typeIdentifier: string): Reducer<ICollectionsState> {
    const initialState: ICollectionsState = {
        entities: {},
        errors: {},
        loaded: false
    };

    return function reducer(state = initialState, action: AnyAction): ICollectionsState {
        switch (action.type) {
            case `${Constants.FETCH_COLLECTION_LIST_SUCCESS}_${typeIdentifier}`:
                return { ...state, entities: action.payload, loaded: true };
            case RESET_CLIENT_STATE:
                return initialState;
            default:
                return state;
        }
    };
}
