import actions from "../../actions/dynamicCampaignActions";
import _ from "lodash";
import { AnyAction, combineReducers } from "redux";
import { RESET_CLIENT_STATE } from "../../constants/ClientConstants";
const templateObjectReducer = (
    state: { [x: string]: any } = {
        headline1: [],
        headline2: [],
        description: [],
        path: [],
        finalUrl: [],
        dynamicCampaignAdTemplateConditionals: []
    },
    action: AnyAction
) => {
    let id;
    switch (action.type) {
        case actions.UPDATE_DYNAMIC_CAMPAIGN_TEMPLATE:
            return { ...state, ...action.template };
        case actions.UPDATE_ORDER_TEXT_TEMPLATE_PART: {
            return { ...state, [action.part]: action.order };
        }
        case actions.CREATE_DYNAMIC_CAMPAIGN_TEMPLATE:
        case actions.ADD_DYNAMIC_CAMPAIGN_TEMPLATE:
        case actions.SAVE_DYNAMIC_CAMPAIGN_TEMPLATE:
            id = action.response.result.id;
            const template = action.response.entities.dynamicCampaignTemplate[id];
            return { ...state, ...template };
        case actions.CREATE_TEMPLATE_CONDITIONAL:
            const result = action.response.result;
            return {
                ...state,
                dynamicCampaignAdTemplateConditionals: [...state.dynamicCampaignAdTemplateConditionals, result]
            };

        case actions.REMOVE_TEMPLATE_CONDITIONAL: {
            const idx = action.idx;
            const conditions = state.dynamicCampaignAdTemplateConditionals;
            return {
                ...state,
                dynamicCampaignAdTemplateConditionals: [...conditions.slice(0, idx), ...conditions.slice(idx + 1)]
            };
        }
        case actions.REMOVE_TEXT_TEMPLATE_PART:
            const field = action.templatePart.field;
            const idx = state[field].indexOf(action.templatePart.id);
            if (idx === -1) {
                return state;
            }
            const newPart = [...state[field].slice(0, idx), ...state[field].slice(idx + 1)];
            return {
                ...state,
                [field]: newPart
            };
        case actions.ADD_TEXT_TEMPLATE_PART:
        case actions.SAVE_TEXT_TEMPLATE_PART:
            id = action.response.result;
            const position = action.response.entities.dynamicTextAdTemplateParts[id].order;
            const part = _.camelCase(action.response.entities.dynamicTextAdTemplateParts[id].field);
            if (state[part].indexOf(id) !== -1) {
                return state;
            }
            const newpart = [...state[part].slice(0, position), id, ...state[part].slice(position)];
            return {
                ...state,
                [part]: newpart
            };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};
const entitiesReducer = (state: { [x: string]: any } = {}, action: AnyAction) => {
    let templateId;
    switch (action.type) {
        case actions.LOAD_DYNAMIC_CAMPAIGN_TEMPLATES:
            return { ...action.response.entities.dynamicCampaignTemplate };
        case actions.CREATE_DYNAMIC_CAMPAIGN_TEMPLATE: {
            const id = action.response.result.id;
            return { ...state, [id]: templateObjectReducer(undefined, action) };
        }
        case actions.REMOVE_DYNAMIC_CAMPAIGN_TEMPLATE:
            delete state[action.id];
            return { ...state };
        case actions.REMOVE_TEMPLATE_CONDITIONAL: {
            return {
                ...state,
                [action.templateId]: templateObjectReducer(state[action.templateId], action)
            };
        }
        case actions.REMOVE_TEXT_TEMPLATE_PART:
            templateId = action.templatePart.feedTemplateId;
            return {
                ...state,
                [templateId]: templateObjectReducer(state[templateId], action)
            };
        case actions.ADD_TEXT_TEMPLATE_PART:
        case actions.SAVE_TEXT_TEMPLATE_PART:
            const partId = action.response.result;
            templateId = action.response.entities.dynamicTextAdTemplateParts[partId].feedTemplateId;
            return {
                ...state,
                [templateId]: templateObjectReducer(state[templateId], action)
            };

        case actions.UPDATE_ORDER_TEXT_TEMPLATE_PART:
        case actions.UPDATE_DYNAMIC_CAMPAIGN_TEMPLATE:
            return {
                ...state,
                [action.template.id]: templateObjectReducer(state[action.template.id], action)
            };

        case actions.SAVE_DYNAMIC_CAMPAIGN_TEMPLATE:
        case actions.ADD_DYNAMIC_CAMPAIGN_TEMPLATE:
            const id = action.response.result.id;
            return {
                ...state,
                [id]: templateObjectReducer(state[id], action)
            };
        case RESET_CLIENT_STATE:
            return {};
        default:
            return state;
    }
};

const dirtyReducer = (state: any[] = [], action: AnyAction) => {
    switch (action.type) {
        case actions.UPDATE_DYNAMIC_CAMPAIGN_TEMPLATE:
            return state.indexOf(action.template.id) === -1 ? [...state, action.template.id] : state;
        case actions.SAVE_DYNAMIC_CAMPAIGN_TEMPLATE:
            const id = action.response.result.id;
            return [..._.without(state, id)];
        case actions.REMOVE_DYNAMIC_CAMPAIGN_TEMPLATE:
            const idx = state.indexOf(action.id);
            if (idx !== -1) {
                state = [...state.slice(0, idx), ...state.slice(idx + 1)];
            }
            return state;
        case RESET_CLIENT_STATE:
            return [];
        default:
            return state;
    }
};

const loadingReducer = (state = false, action: AnyAction) => {
    switch (action.type) {
        case actions.REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES:
            return true;
        case actions.LOAD_DYNAMIC_CAMPAIGN_TEMPLATES:
            return false;
        case RESET_CLIENT_STATE:
            return false;
        default:
            return state;
    }
};

const savingReducer = (state = false, action: AnyAction) => {
    switch (action.type) {
        case actions.START_BATCH_SAVE:
        case actions.REQUEST_CREATE_DYNAMIC_CAMPAIGN_TEMPLATE:
            return true;
        case actions.END_BATCH_SAVE:
        case actions.CREATE_DYNAMIC_CAMPAIGN_TEMPLATE:
            return false;
        case RESET_CLIENT_STATE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    entities: entitiesReducer,
    dirty: dirtyReducer,
    loading: loadingReducer,
    saving: savingReducer
});

export const getETATemplateIdsForCampaign = (
    campaignId: any,
    state: { dynamicCampaignTemplates: { entities: any } }
) => {
    const filtered = _.filter(state.dynamicCampaignTemplates.entities, {
        feedId: campaignId,
        adType: "eta"
    });
    const ordered = _.orderBy(filtered, "order");

    return _.map(ordered, "id");
};
