import React, { useEffect } from "react";
import Button, { SECONDARY_BUTTON } from "../../../Shared/Button";
import TemplateBoilerplateModal from "./EtaTemplateBoilerplateModal";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import IAppState from "../../../../interfaces/IAppState";
import { selectEtaTemplateBoilerplates } from "../../../../reducers/dynamicCampaigns/etaTemplateBoilerplate";
import actions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import { connect } from "react-redux";
import { IEtaTemplateBoilerplate } from "../../../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";
import { findIndex } from "lodash";
import AddIcon from "../../../Shared/Icons/AddIcon";

interface IProps {
    loadEtaTemplatesFromBoilerplate: (etaTemplateBoilerplates: Array<IEtaTemplateBoilerplate>) => void;
}

const EtaTemplateBoilerplateLoader = ({
    loadEtaTemplatesFromBoilerplate,
    etaTemplateBoilerplates,
    fetchAll
}: IProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps) => {
    useEffect(() => {
        fetchAll();
    }, []);

    const handleLoad = (ids: number[]) => {
        loadEtaTemplatesFromBoilerplate(
            ids.map((id: number) => etaTemplateBoilerplates[findIndex(etaTemplateBoilerplates, ["id", id])])
        );
    };

    return (
        <ModalToggle
            style={{ width: "50%", height: "510px" }}
            toggleButton={(open: () => void) => (
                <Button type="button" styleType={SECONDARY_BUTTON} onClick={open}>
                    <div className="flex items-center">
                        <AddIcon className="w-6 h-6" />
                        <span className="ml-2">Load Templates</span>
                    </div>
                </Button>
            )}
            modalContent={(hide: () => void) => (
                <TemplateBoilerplateModal
                    etaTemplateBoilerplates={etaTemplateBoilerplates}
                    load={handleLoad}
                    hide={hide}
                />
            )}
        />
    );
};

const mapStateToProps = (state: IAppState) => ({
    etaTemplateBoilerplates: selectEtaTemplateBoilerplates(state)
});

const mapDispatchToProps = {
    fetchAll: () => actions.index.request()
};

export default connect(mapStateToProps, mapDispatchToProps)(EtaTemplateBoilerplateLoader);
