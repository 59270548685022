import React from "react";
import { startCase } from "lodash";
import IServicesFields from "../../../../interfaces/DealerSetup/IServicesFields";
import FieldErrors from "../Fields/FieldErrors";

const ServicesSection: React.FC<IServicesFields> = ({ fields }) => {
    return (
        <div className="m-4">
            <div className="font-bold mb-4">Listed Services</div>
            {fields &&
                Object.entries(fields).map(([fieldName, fieldValue]) => {
                    if (Array.isArray(fieldValue)) {
                        return null;
                    }

                    return Object.entries(fieldValue.value)
                        .sort()
                        .map(([key, val]) => {
                            return (
                                val && (
                                    <p key={key} className="m-4 ">
                                        {startCase(key)}
                                    </p>
                                )
                            );
                        });
                })}
            <FieldErrors errors={fields.services.errors} />
        </div>
    );
};

export default ServicesSection;
