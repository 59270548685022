import React, { forwardRef } from "react";

import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { array, object, string } from "yup";
import FieldErrors from "../Fields/FieldErrors";
import VariationsInput from "../Fields/VariationsInput";
import IDealerInfo from "../../../../interfaces/DealerSetup/IDealerInfoFields";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";
import { IClient } from "../../../../interfaces/IClient";

interface IProps {
    path: string;
    formValues: IFormValues;
    client: IClient;
    savePage(path: string, validatePage: () => void): void;
}

const DealerInfo = forwardRef(({ client, formValues, savePage, path }: IProps, ref: React.Ref<{}>) => {
    const schema = object({
        name: string(),
        city: object({
            city: string(),
            variations: array().of(string())
        }),
        state: string(),
        zip: string()
    });

    const defaults: IDealerInfo = {
        fields: {
            name: {
                value: client.name || ""
            },
            city: {
                value: {
                    city: client.city || "",
                    variations: []
                }
            },
            state: {
                value: client.state || ""
            },
            zip: {
                value: client.zip || ""
            }
        }
    };

    const [values, handleFieldChange, changePage] = useDealerSetupForm(
        defaults,
        formValues,
        schema,
        ref,
        path,
        savePage
    );

    const pager = usePager(path, formValues, changePage);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldChange(event.target.name, event.target.value);
    };

    const handleCityChange = (index: number, value: any) => {
        handleFieldChange(`city`, value);
    };

    return (
        <div className="p-4">
            <div className="text-xl font-bold">Dealer Information</div>

            <div className="text-lg font-bold my-4 pb-3 border-b">General</div>

            <div className={`w-full mt-4 flex justify-between`}>
                <div className={`flex-auto`}>
                    <div className="w-2/3 py-3 font-bold text-base">Name</div>
                    <div className="w-full py-3 flex">
                        <input
                            value={client.name || ""}
                            readOnly={true}
                            type="text"
                            name="name"
                            onChange={handleInputChange}
                            className={`text-base bg-gray-300 border flex-1 py-2 px-4 rounded`}
                        />
                    </div>
                    <FieldErrors errors={values.fields.name.errors} />
                </div>
            </div>

            <VariationsInput
                index={0}
                label={"City"}
                name="city"
                value={client.city}
                variations={values.fields.city.value.variations || defaults.fields.city.value.variations}
                errors={values.fields.city.errors}
                deletable={false}
                className={`text-base w-full`}
                handleChange={handleCityChange}
                handleDelete={() => {
                    console.log("delete");
                }}
                readOnly={true}
            />
            <p className="text-gray-dark">
                These variations will be in the same AdGroup (Example: If Fort Myers is the City, you would add "Ft.
                Myers" as a keyword variation).
            </p>

            <div className={`w-full my-8 flex flex-wrap`}>
                <div className="w-1/3 flex flex-wrap items-start">
                    <div className="w-full py-3 font-bold text-base">Zip Code</div>
                    <div className="w-full">
                        <div className="mr-2">
                            <input
                                value={client.zip}
                                readOnly={true}
                                type="text"
                                name="zip"
                                onChange={handleInputChange}
                                className={`text-base bg-gray-300 border w-full py-2 px-4 rounded`}
                            />
                            <FieldErrors errors={values.fields.zip.errors} />
                        </div>
                    </div>
                </div>
                <div className="w-1/3 flex flex-wrap items-start">
                    <div className="w-full ml-4 py-3 font-bold text-base">State</div>
                    <div className="relative w-full">
                        <div className="mx-4">
                            <input
                                className={`text-base bg-gray-300 border w-full py-2 px-4 rounded`}
                                name="state"
                                value={client.state}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="mx-4">
                        <FieldErrors errors={values.fields.state.errors} />
                    </div>
                </div>
                <div className="w-1/3 flex flex-wrap items-start">
                    <div className="w-full py-3 mx-4 font-bold text-base">Country</div>
                    <div className="relative w-full">
                        <div className="mx-4">
                            <input
                                className={`text-base bg-gray-300 border w-full py-2 px-4 rounded`}
                                name="countryCode"
                                value={client.country}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {pager()}
        </div>
    );
});

export default DealerInfo;
