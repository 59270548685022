import React from "react";
import { PENDING, COMPLETED, ACTIVE, ERROR } from "../../constants/StatusIndicatorConstants";
import CheckIcon from "./Icons/CheckIcon";
import CloseIcon from "./Icons/CloseIcon";

interface IProps {
    initialLabel: string;
    completedLabel: string;
    status: string;
}

const classes: { [key: string]: string } = {
    [PENDING]: "bg-white text-gray-800 pending",
    [ACTIVE]: "bg-blue-200 text-gray-800 active",
    [COMPLETED]: "bg-cerulean-500 text-white completed text-white",
    [ERROR]: "bg-red-500 text-white step-error text-white"
};

const StatusStep: React.FunctionComponent<IProps> = ({ completedLabel, initialLabel, status }) => {
    const isCompleted = status === COMPLETED;
    const isFailed = status === ERROR;
    const activeLabel = isCompleted ? completedLabel : initialLabel;
    return (
        <div
            className={`relative ml-3 status-step flex items-center justify-center uppercase tracking-normal ${classes[status]}`}
        >
            {isCompleted && <CheckIcon className="w-6 h-6 mr-2" />}
            {isFailed && <CloseIcon className="w-6 h-6 mr-2" />}
            {activeLabel}
        </div>
    );
};

export default StatusStep;
