import { Reducer } from "redux";
import ITrackingParameter from "../interfaces/ITrackingParameter";
import IEntity from "../interfaces/IEntity";
import TrackingParameterConstants from "../constants/TrackingParameterConstants";
import IAppState from "../interfaces/IAppState";
import { ITrackingParameterCampaignRelation } from "../interfaces/Campaigns";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

interface ITrackingParameters {
    entities: IEntity<ITrackingParameter>;
    trackedCampaigns: IEntity<ITrackingParameterCampaignRelation>;
    loading: boolean;
    busy: boolean;
}

const initialState = {
    entities: {},
    trackedCampaigns: {},
    loading: true,
    busy: false
};

const trackingParametersReducer: Reducer<ITrackingParameters> = (state = initialState, action) => {
    switch (action.type) {
        case TrackingParameterConstants.FETCH_TRACKING_PARAMETERS:
            return {
                ...state,
                busy: true,
                loading: true
            };
        case TrackingParameterConstants.FETCH_TRACKING_PARAMETERS_SUCCESS:
            return {
                ...state,
                entities: action.payload.trackingParameters ?? {},
                trackedCampaigns: action.payload.trackedCampaigns ?? {},
                busy: false,
                loading: false
            };
        case TrackingParameterConstants.FETCH_TRACKING_PARAMETERS_FAILED:
            return {
                ...state,
                entities: {},
                trackedCampaigns: {},
                busy: false,
                loading: false
            };
        case TrackingParameterConstants.DELETE_TRACKING_PARAMETER_SUCCESS:
            const entities = state.entities;
            delete entities[action.payload.trackingParameterId];
            return {
                ...state,
                entities,
                busy: false,
                loading: false
            };
        case TrackingParameterConstants.DELETE_TRACKING_PARAMETER_FAILED:
        case TrackingParameterConstants.UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED:
        case TrackingParameterConstants.LINK_TRACKING_PARAMETER_TO_CAMPAIGN_FAILED:
        case TrackingParameterConstants.UPDATE_TRACKING_PARAMETER_SUCCESS:
            return {
                ...state,
                busy: false,
                loading: false
            };
        case TrackingParameterConstants.CREATE_TRACKING_PARAMETER_SUCCESS:
            return {
                ...state,
                busy: false,
                entities: {
                    ...state.entities,
                    [action.payload.trackingParameter.id]: {
                        ...action.payload.trackingParameter,
                        trackedCampaigns: []
                    }
                },
                loading: false
            };
        case TrackingParameterConstants.UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS:
            const trackingParam = state.entities[action.payload.trackingParameterId];
            const newTrackedCampaigns = state.trackedCampaigns;

            delete newTrackedCampaigns[action.payload.relationId];

            return {
                ...state,
                busy: false,
                entities: {
                    ...state.entities,
                    [action.payload.trackingParameterId]: {
                        ...trackingParam,
                        trackedCampaigns: trackingParam.trackedCampaigns.filter(
                            (relationId) => relationId != action.payload.relationId
                        )
                    }
                },
                trackedCampaigns: {
                    ...newTrackedCampaigns
                }
            };
        case TrackingParameterConstants.LINK_TRACKING_PARAMETER_TO_CAMPAIGN_SUCCESS:
            const trackingParameter = state.entities[action.payload.trackingParameter.id];
            const trackedCampaigns = state.entities[action.payload.trackingParameter.id].trackedCampaigns;
            trackedCampaigns.push(action.payload.campaign.id);
            const newTrackedCampaign = action.payload.campaign;
            return {
                ...state,
                busy: false,
                entities: {
                    ...state.entities,
                    [action.payload.trackingParameter.id]: {
                        ...trackingParameter,
                        trackedCampaigns
                    }
                },
                trackedCampaigns: {
                    ...state.trackedCampaigns,
                    [newTrackedCampaign.id]: newTrackedCampaign
                }
            };
        case TrackingParameterConstants.CREATE_TRACKING_PARAMETER:
        case TrackingParameterConstants.UPDATE_TRACKING_PARAMETER:
        case TrackingParameterConstants.DELETE_TRACKING_PARAMETER:
        case TrackingParameterConstants.LINK_TRACKING_PARAMETER_TO_CAMPAIGN:
        case TrackingParameterConstants.UNLINK_TRACKING_PARAMETER_TO_CAMPAIGN:
            return {
                ...state,
                busy: true
            };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
};

export default trackingParametersReducer;
