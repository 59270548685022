import React, { useState, useEffect, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import UserForm from "./UserForm";
import FullPageLoader from "../Shared/Loaders/FullPageLoader";
import UsersActions from "../../actions/UsersActions";
import UsersUtils from "../../utils/UsersUtils";
import { TableLoader } from "../Shared/Table";

import IUsersAppState from "../../interfaces/IUserAppState";
import IEntity from "../../interfaces/IEntity";
import IUserRole from "../../interfaces/IUserRole";
import IUserForm from "../../interfaces/IUserForm";
import ISelectedUser from "../../interfaces/ISelectedUser";

export const fieldGroupStyles = "flex flex-wrap mb-4 w-full";
export const labelStyles = "block uppercase tracking-wider text-gray-800 font-bold mb-2";
const formContainerStyles =
    "m-auto relative bg-white flex flex-col w-full sm:w-4/5 md:w-full lg:w-2/3 xl:w-1/2 shadow-md rounded px-4 pt-6 pb-4 mb-4";

interface IAppState<T> {
    [key: string]: T;
}

interface IProps {}

const baseValues = {
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: "false",
    readonly_budgets: false,
    all: false,
    budget_manager: false,
    sendActivationEmail: true
};

const EditUser: FunctionComponent<IProps> = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [initialFormValues, setInitialFormValues] = useState(baseValues);
    const [isLoading, setIsLoading] = useState(true);

    const { selectedUser, roles, isSubmitting, error } = useSelector((state: IAppState<IUsersAppState>) => {
        const mappedSelectedUser = UsersUtils.mapUserToForm(state.users.selectedUser);
        return {
            selectedUser: mappedSelectedUser,
            roles: state.users.roles,
            isSubmitting: state.users.isSubmitting,
            error: state.users.error
        };
    });

    const fetchUser = (userId: string) => dispatch(UsersActions.requestUser(userId));

    const updateUser = (values: IUserForm, roles: IEntity<IUserRole>, selectedUser?: ISelectedUser) => {
        const mappedValuesToUser = UsersUtils.mapFormToUser(values, roles, selectedUser);
        dispatch(UsersActions.requestUpdateUser(mappedValuesToUser, navigate));
    };

    const fetchUserRoles = () => dispatch(UsersActions.requestUserRoles());

    // fetch user
    useEffect(() => {
        if (selectedUser?.id !== userId) {
            fetchUser(userId as string);
        }
    }, [userId]);

    useEffect(() => {
        if (Object.keys(roles).length === 0) {
            fetchUserRoles();
        }
    }, [Object.keys(roles).length]);

    useEffect(() => {
        // if user has been fetched map it to initial values
        if (selectedUser?.id) {
            setIsLoading(false);
            const mappedFormValues = {
                ...baseValues,
                ...selectedUser,
                sendActivationEmail: false
            };
            setInitialFormValues(mappedFormValues);
            return;
        }
        setInitialFormValues(baseValues);
    }, [selectedUser?.id]);

    if (isLoading) {
        return <FullPageLoader message="Loading User..." />;
    }

    return (
        <div className={formContainerStyles}>
            <h2 className="font-bold self-center text-gray-800 uppercase">Edit User</h2>
            <UserForm
                {...{ error, roles, isSubmitting }}
                selectedUser={selectedUser!}
                initialValues={initialFormValues}
                saveUser={updateUser}
            />
            {isSubmitting && <TableLoader message="Updating User" className="self-center h-full top-0 left-0" />}
        </div>
    );
};

export default EditUser;
