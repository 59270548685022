import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppCont from "./components/AppContainer";

export const renderApp = (store: any, pageProps = { useNavLinks: true, id: null }, containerId = "root") => {
    let rootElement = document.getElementById(containerId);
    if (!rootElement) {
        rootElement = document.createElement(`div#${containerId}`);
        document.body.appendChild(rootElement);
    }

    const root = createRoot(rootElement);
    root.render(
        <BrowserRouter>
            <AppCont store={store} pageProps={pageProps} />{" "}
        </BrowserRouter>
    );
};
