const parseInventoryFieldValueAndCatchTheHtmlIfTheresAProblem = (val: string) => {
    try {
        const value = JSON.parse(val);

        if (!isNaN(Number(value))) {
            return Number(value).toLocaleString();
        }

        return value.hasOwnProperty("toString") ? value.toString().replace(/(<([^>]+)>)/gi, "") : value;
    } catch (e) {
        return val;
    }
};

export const highestField = (items: any[], field: string): number => {
    const value = Math.max.apply(
        Math,
        items.map((item: any) => Number(item[field]))
    );

    if (isNaN(value)) {
        return items
            .map((item) => {
                const value = parseInventoryFieldValueAndCatchTheHtmlIfTheresAProblem(item[field]);

                if (!isNaN(Number(value))) {
                    return Number(value).toLocaleString();
                }

                return value;
            })
            .filter((value?: any) => value)[0];
    }

    return value;
};
export const lowestField = (items: any[], field: string): number => {
    const value = Math.min.apply(
        Math,
        items.map((item: any) => Number(item[field]))
    );

    if (isNaN(value)) {
        return items
            .map((item) => parseInventoryFieldValueAndCatchTheHtmlIfTheresAProblem(item[field]))
            .filter((value?: any) => value)[0];
    }

    return value;
};
export const greaterThanZero = (value: number) => value > 0;
export const couldThisInventoryGroupHaveAProblem = (ig: any) =>
    !(
        (greaterThanZero(highestField(ig, "OurPrice")) && greaterThanZero(lowestField(ig, "OurPrice"))) ||
        greaterThanZero(lowestField(ig, "LeasePayment"))
    );
