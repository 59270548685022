import { INamingConvention } from "../../../hooks/namingConventions/useNamingConvention";
import { IUseApiHandler } from "../../../hooks/useApi";
import { ConditionalFormModal } from "../../Shared/Conditional/ConditionalForm";
import DuplicateForm from "../../Shared/Conditional/DuplicateForm";
import CheckIcon from "../../Shared/Icons/CheckIcon";
import CloseIcon from "../../Shared/Icons/CloseIcon";
import CopyIcon from "../../Shared/Icons/CopyIcon";
import EditIcon from "../../Shared/Icons/EditIcon";
import TableActionDeleteButton from "../../Shared/Table/TableActionDeleteButton";
import TableActionLink from "../../Shared/Table/TableActionLink";
import TableActionModal from "../../Shared/Table/TableActionModal";
import useConditionalParams from "../../../hooks/useConditionalParams";
import { IConditionableParameter } from "../../../hooks/useConditionals";

export interface ITableColumn {
    Header?: string;
    sortable?: boolean;
    width?: number;
    original: INamingConvention;
}

interface IActionColumn {
    onDelete: IUseApiHandler;
    onUpdate: IUseApiHandler;
    onDuplicate: (newName: string) => void;
}

export const ActionColumn = ({ original, onDelete, onUpdate, onDuplicate }: ITableColumn & IActionColumn) => {
    const { conditionableModels } = useConditionalParams();
    const { id, name, type, conditionals } = original;
    return (
        <div data-test-id="action-column">
            <TableActionModal
                Icon={CopyIcon}
                modalTitle={"Duplicating a naming convention"}
                subTitle={`Duplicating ${name}`}
                className="text-gray-500"
            >
                <div>
                    <DuplicateForm onSubmit={onDuplicate} />
                </div>
            </TableActionModal>
            <TableActionLink Icon={EditIcon} to={`/admin/naming-conventions/${id}/edit`} className="text-gray-500" />

            <ConditionalFormModal
                subTitle={name}
                hasConditionals={conditionals.length > 0}
                existingConditionals={{ conditionals } as IConditionableParameter}
                namingConvention={original}
                conditionalType={conditionableModels.namingConvention}
                id={id}
                showMustAllConditionsPass={false}
                allConditionsMustPassValue={false}
                onAllConditionsMustPassClick={() => {}}
                onFinish={() => onUpdate && onUpdate()}
            />

            <TableActionDeleteButton
                deletionMessage={`Are you sure you want to delete ${name}?`}
                subTitle={"This action cannot be undone."}
                className="text-gray-500"
                onDelete={() => onDelete && onDelete({ id })}
            />
        </div>
    );
};

const GlueColumn = ({ value }: any) => {
    function describeCharacter(characters: string) {
        return (
            "(" +
            characters
                .split("")
                .map((character: string) => {
                    switch (character) {
                        case " ":
                            return "space";
                        case "-":
                            return "hyphen";
                        case "_":
                            return "underscore";
                        case "»":
                            return "guillemot right";
                        case "«":
                            return "guillemot left";
                        case '"':
                            return "double quote";
                        case "'":
                            return "single quote";
                        case "“":
                        case "”":
                            return "unicode double quote";
                        case "*":
                            return "astrisk";
                        case "^":
                            return "caret";
                        case "|":
                            return "pipe";
                        case "=":
                            return "equals";
                        case "\xa0":
                            return "NULL";
                        case "​":
                            return "zero width character";
                        default:
                            return character;
                    }
                })
                .join(", ") +
            ")"
        );
    }

    if (!value) return <></>;

    return (
        <code>
            <pre className="bg-opacity-75 bg-gray-300 rounded p-1">
                {value?.original?.glue} {describeCharacter(value?.original?.glue)}
            </pre>
        </code>
    );
};

const EnabledColumn = ({ value }: any) => {
    if (value.original.enabled)
        return (
            <span className="flex items-center gap-2">
                <CheckIcon className="text-green-500 w-4 h-4" />
                Enabled
            </span>
        );
    return (
        <span className="flex items-center gap-2">
            <CloseIcon className="text-red-500 w-4 h-4" />
            Disabled
        </span>
    );
};

const MadeOfColumn = ({ value }: any) => {
    return (
        <span className="flex text-sm flex-wrap p-0 m-0">
            <span>{value.original.namingConventionParts.length} parts</span>
            <span className="mx-1 ">/</span>
            <span>
                {value.original.namingConventionParts.reduce(
                    (sumTotal: number, part: any) => sumTotal + part.values.length,
                    0
                )}{" "}
                values
            </span>
        </span>
    );
};

const columns = [
    {
        id: "name",
        Header: "Name",
        sortable: false,
        Cell(value: any) {
            return value.original.name;
        }
    },
    {
        id: "type",
        Header: "Type",
        sortable: false,
        width: 110,
        Cell(value: any) {
            return value.original.type;
        }
    },
    {
        id: "glue",
        Header: "Glue",
        sortable: false,
        Cell(value: any) {
            return <GlueColumn {...{ value }} />;
        }
    },
    {
        id: "enabled",
        Header: "Enabled",
        sortable: false,
        width: 110,
        Cell(value: any) {
            return <EnabledColumn {...{ value }} />;
        }
    },
    {
        id: "madeof",
        Header: "Contains",
        sortable: false,
        Cell(value: any) {
            return <MadeOfColumn {...{ value }} />;
        }
    }
];

export default columns;
