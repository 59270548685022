import { schema } from "normalizr";

const specialOffersTemplate = new schema.Entity("specialOffersTemplates");
const specialOffersMetaTemplate = new schema.Entity("specialOffersMetaTemplates");

export default {
    SPECIAL_OFFERS_TEMPLATE_LIST: { data: [specialOffersTemplate] },
    SPECIAL_OFFERS_TEMPLATE: specialOffersTemplate,

    SPECIAL_OFFERS_META_TEMPLATE_LIST: { data: [specialOffersMetaTemplate] },
    SPECIAL_OFFERS_META_TEMPLATE: specialOffersMetaTemplate
};
