import React, { ReactNode } from "react";
import WarningIcon from "./Icons/WarningIcon";

interface IAlert {
    title: string;
    children: ReactNode;
    type: string;
}

const alertColors: { [key: string]: string } = {
    warning: "orange",
    error: "red",
    success: "green"
};

const Alert: React.FunctionComponent<IAlert> = ({ title, type, children }) => {
    const colorClass = alertColors[type];
    return (
        <div role="alert" className="my-4 text-base">
            <div className={`flex bg-${colorClass}-500 text-white p-4 content-center`}>
                <WarningIcon className="w-6 h-6" />
                <span className="font-sans ml-2">{title}</span>
            </div>
            <div
                className={`border border-t-0 border-${colorClass}-400 bg-${colorClass}-100 px-4 py-4 text-${colorClass}-700 flex justify-between`}
            >
                {children}
            </div>
        </div>
    );
};

export default Alert;
