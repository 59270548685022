import React from "react";
import LoaderSpinner from "../Loaders/LoaderSpinner";

function generateRandomString(length: number) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const ToggleSwitch: React.FC<
    Partial<{
        onToggle: (value: any) => void;
        field: string;
        value: boolean;
        label: string;
        errorStatus?: boolean;
        toggling?: boolean;
        DOMId?: string;
    }>
> = ({ onToggle, field, value, label = "", errorStatus = false, toggling, DOMId }) => {
    const id = DOMId ? DOMId : "toggle-" + generateRandomString(6);
    return (
        <label htmlFor={id} className="mt-2 ml-2 inline-flex items-center cursor-pointer">
            <span className="relative">
                <span
                    className={`block w-8 h-5 ${toggling ? "opacity-25" : "opacity-1"} bg-${
                        errorStatus ? "red" : "gray"
                    }-400 rounded-full shadow-inner ${value ? "bg-blue-600" : ""}`}
                />
                <span
                    className={`absolute block w-3 h-3 mt-1 ml-1 bg-white rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-white ${
                        value ? " transform translate-x-full" : ""
                    }`}
                >
                    <input
                        id={id}
                        name={field}
                        type="checkbox"
                        className="absolute opacity-0 w-0 h-0"
                        checked={!!value}
                        onChange={onToggle}
                    />
                </span>
            </span>
            <span
                className={`ml-2 text-sm ${toggling ? "opacity-25" : "opacity-1"} ${
                    errorStatus ? "text-red-600" : ""
                }`.trim()}
            >
                {label}
            </span>
            {toggling && <LoaderSpinner className="ml-2" iconDimensions={"4"} />}
        </label>
    );
};

export default ToggleSwitch;
