import React, { Fragment } from "react";
import ChangeLogDetail from "./ChangeLogDetail";
import SystemLogPreview from "./SystemLogPreview";

const SystemLog = ({ log }: any) => {
    return (
        <Fragment>
            <SystemLogPreview log={log} />
        </Fragment>
    );
};
export default SystemLog;
