import Select from "react-select";
import React, { useEffect, useState } from "react";
import { INegativeKeywordColl } from "../../../../interfaces/NegativeKeywordColl";
import IEntity from "../../../../interfaces/IEntity";
import { IOption } from "../../../../interfaces/IDisplayCampaign";

interface INegativeKeywordsForWizardCampaign {
    negativeKeywordCollections: IEntity<INegativeKeywordColl>;
    name: string;
    handleChangeField: (values: number[]) => void;
    subtitle?: string;
    values: number[];
}

const NegativeKeywordsCampaignField = ({
    negativeKeywordCollections,
    name,
    subtitle,
    handleChangeField,
    values
}: INegativeKeywordsForWizardCampaign) => {
    const [options, setOptions] = useState(
        Object.values(negativeKeywordCollections).map((keywordCollection) => ({
            value: keywordCollection.id,
            label: keywordCollection.name
        }))
    );
    const [formValues, setFormValues] = useState([] as { value: number; label: string }[]);

    useEffect(() => {
        setOptions(
            Object.values(negativeKeywordCollections).map((keywordCollection) => ({
                value: keywordCollection.id,
                label: keywordCollection.name
            }))
        );
    }, [negativeKeywordCollections]);

    useEffect(() => {
        if (Object.keys(negativeKeywordCollections).length === 0) {
            return;
        }

        setFormValues(
            values.map((keywordCollectionId: number) => {
                if (!negativeKeywordCollections.hasOwnProperty(keywordCollectionId)) {
                    return {
                        value: 0,
                        label: "Unknown negative keyword... Please refresh your page"
                    };
                }

                return {
                    value: negativeKeywordCollections[keywordCollectionId].id,
                    label: negativeKeywordCollections[keywordCollectionId].name
                };
            })
        );
    }, [negativeKeywordCollections, values]);

    return (
        <div className="flex flex-wrap w-full items-center">
            <div className="w-1/3 text-lg">
                {name}
                {subtitle && <div className="text-xs text-gray-600 pr-4 -mt-2">{subtitle}</div>}
            </div>
            <div className="w-2/3">
                <Select
                    name={"Negative Keyword lists"}
                    aria-label={"Negative Keyword lists"}
                    classNamePrefix={"Negative Keyword lists"}
                    options={options}
                    value={formValues}
                    onChange={(value: any, { action }) => {
                        handleChangeField(value.map((value: IOption) => value.value));
                    }}
                    isMulti={true}
                    placeholder={"Select negative keyword lists..."}
                />
            </div>
        </div>
    );
};

export default NegativeKeywordsCampaignField;
