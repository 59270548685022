import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import FullPageLoader from "../../../Shared/Loaders/FullPageLoader";
import ActivityLogs from "./ActivityLogs";
import BudgetInfo from "./BudgetInfo";
import PageTitle from "../../../Shared/PageTitle/PageTitle";
import useBudget from "../../../../hooks/budgets/useBudget";
import IAppState from "../../../../interfaces/IAppState";
import { Link } from "react-router-dom";
import useBudgetParams from "../../../../hooks/budgets/useBudgetParams";

export const Card = "m-auto relative bg-white flex flex-col w-full shadow rounded p-4";
export const Col = "m-4";
export const Container = "flex my-4";
export const CardTitle = "font-bold self-center text-gray-800 ";
export const LogPreviewContainer = "log-border rounded mt-4 py-4 pl-4 flex text-gray-800";
export const UserLog = "log-user";
export const SystemLog = "log-system";
export const ItemField = "w-1/5 ";
export const LogHeaderStyle = "flex block uppercase tracking-wider text-gray-600 font-bold mb-2 font-size-sm";
export const LogDetailContainer = "log-border-detail rounded-b p-4 justify-center";

export const ChangeContainer = "flex justify-between flex-col";
export const ChangeRow = "flex flex-row justify-between";
export const ChangeHeaderContainer = `${LogHeaderStyle} ${ChangeRow}`;
export const ChangeItem = `flex-1 text-gray-800`;
export const ChangeHeader = `flex-1 text-gray-800`;

interface IProps {}

const BudgetDetails: React.FunctionComponent<IProps> = ({}) => {
    const campaigns = useSelector(({ adwordsCampaigns }: IAppState) => adwordsCampaigns.entities);

    const { details, changeLogs, systemLogs, fetchSystemLogsHandler, fetchBudgetLogsHandler, loadingBudget } =
        useBudget();

    //a monthyl budget with prorated start date should display as Prorated
    if (changeLogs && changeLogs[0]) {
        const changes = changeLogs[0].changes;
        const typeChange = changes.find(({ attribute }) => attribute === "type");
        if (!!details?.activation?.proratedStartDate && typeChange?.new === "Monthly") {
            typeChange.new = `Prorated Monthly ${details?.activation?.proratedStartDate}`;
        }
    }

    useEffect(() => {
        fetchBudgetLogsHandler();
        fetchSystemLogsHandler();
    }, []);

    if (loadingBudget) return <FullPageLoader message="Loading Budget Details..." />;

    return (
        <div className="mx-4">
            <PageTitle title="Budget Details">
                <Link className="py-2 px-4 bg-blue-500 rounded text-white" to={`../..`}>
                    Back to Budgets
                </Link>
            </PageTitle>
            <div className={Container}>
                <div className={`w-1/4 `}>{details && <BudgetInfo campaigns={campaigns} budget={details} />}</div>
                <div className={`w-3/4`}>
                    <ActivityLogs {...{ changeLogs, systemLogs }} />
                </div>
            </div>
        </div>
    );
};

export default BudgetDetails;
