import React from "react";
import IConquest from "../../../../interfaces/DealerSetup/IConquest";
import FieldErrors from "../Fields/FieldErrors";

const ConquestsSection: React.FC<IConquest> = ({ fields }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    <div className="font-bold mb-4">Listed Conquests</div>

                    {fields.conquest.value.ad_groups.map((conquest, index) => {
                        const { name, variations } = conquest.value;
                        return (
                            <div key={index} className="m-4">
                                <div className="font-bold">{name}</div>
                                {variations.map((variation, key) => {
                                    return <div key={`${variation}${key}`}>{variation}</div>;
                                })}
                                <FieldErrors errors={conquest.errors} />
                            </div>
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default ConquestsSection;
