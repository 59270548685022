import React, { useEffect, useState } from "react";
import ReactTable, { Column, ReactTableDefaults, ReactTableFunction, TableProps } from "react-table";
import TableLoader from "./TableLoader";
import IColumn from "../../../interfaces/IColumn";
import ITableState from "../../../interfaces/ITableState";

interface ITable {
    columns: Column<IColumn>[];
    fetchData: (limit: number, page: number) => void;
    loadingMessage: string;
    noDataMessage: string;
    loadingTable: boolean;
    data: any;
}

const AsyncTable = ({ columns, loadingMessage, fetchData, loadingTable, data, noDataMessage }: ITable) => {
    return (
        <ReactTable
            minRows={5}
            data={data}
            resizable={false}
            sortable={true}
            loading={loadingTable}
            pageSizeOptions={[15, 20, 25, 50, 100]}
            columns={columns?.map((column) => {
                // column.headerClassName = "";
                return column;
            })}
            className={`v1 -striped rounded overflow-hidden -highlight`}
            column={{ ...ReactTableDefaults.column, minWidth: 50 }}
            LoadingComponent={() => (loadingTable && <TableLoader message={loadingMessage} />) || <div />}
            noDataText={noDataMessage}
            onFetchData={(state) => fetchData(state.pageSize, state.page)}
        />
    );
};

export default AsyncTable;
