/**
 * Created by fjiles@dealerinspire.com on 12/19/16.
 */

import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import api from "./middleware/api";
import createSagaMiddleware from "redux-saga";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
    }
}

export default (reducers: any, initialState: { [x: string]: unknown } | undefined, middlewares: any[]) => {
    if (undefined === middlewares) {
        middlewares = [createSagaMiddleware()];
    }
    return createStore(
        combineReducers(reducers),
        initialState,
        // @ts-ignore
        compose(
            applyMiddleware(...[thunkMiddleware, api, ...middlewares]),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
        )
    );
};
