import { useEffect } from "react";

type Callback = (event: { data: { [key: string]: string } }) => void;

function useEchoListener(
    addListener: (id: number, callback: Callback) => void,
    removeListener: (arg: any) => void,
    callback: Callback,
    id: number | undefined
) {
    useEffect(() => {
        if (!id) {
            return;
        }
        addListener(id, callback);
        return () => {
            removeListener(id);
        };
    }, [id]);
}

export default useEchoListener;
