import React from "react";
import { Routes, Route } from "react-router-dom";
import BudgetDetails from "./BudgetDetails/BudgetDetails";
import ManualBudgetDetails from "./ManualBudgetDetails/ManualBudgetDetails";
import BudgetFormContainer from "./BudgetFormContainer";
import IClient from "../../../interfaces/IClient";
import BudgetList from "./BudgetList";
import ManualBudgetFormContainer from "./Manual/ManualBudgetFormContainer";
import ManualBudgetList from "./Manual/ManualBudgetList";
import NotFound404 from "../../Shared/NotFound404";

interface IClientBudgetRoutesProps {
    match?: any;
    currentClient?: IClient;
    matchedUrl?: any;
}

const ClientBudgetRoutes: React.FunctionComponent<IClientBudgetRoutesProps> = () => {
    return (
        <Routes>
            <Route path="adwords">
                <Route index element={<BudgetList />}></Route>
                <Route path="create" element={<BudgetFormContainer />} />
                <Route path=":budgetId">
                    <Route path="edit" element={<BudgetFormContainer />} />
                    <Route path="details" element={<BudgetDetails />} />
                    <Route path="*" element={<NotFound404 />} />
                </Route>
                <Route path="*" element={<NotFound404 />} />
            </Route>
            <Route path="microsoft">
                <Route index element={<BudgetList />}></Route>
                <Route path="create" element={<BudgetFormContainer />} />
                <Route path=":budgetId">
                    <Route path="edit" element={<BudgetFormContainer />} />
                    <Route path="details" element={<BudgetDetails />} />
                    <Route path="*" element={<NotFound404 />} />
                </Route>
                <Route path="*" element={<NotFound404 />} />
            </Route>
            <Route path="dsp">
                <Route index element={<ManualBudgetList />} />
                <Route path="create" element={<ManualBudgetFormContainer />} />
                <Route path=":budgetId">
                    <Route path="edit" element={<ManualBudgetFormContainer />} />
                    <Route path="details" element={<ManualBudgetDetails />} />
                    <Route path="*" element={<NotFound404 />} />
                </Route>
            </Route>
            <Route path="social">
                <Route index element={<ManualBudgetList />} />
                <Route path="create" element={<ManualBudgetFormContainer />} />
                <Route path=":budgetId">
                    <Route path="edit" element={<ManualBudgetFormContainer />} />
                    <Route path="details" element={<ManualBudgetDetails />} />
                    <Route path="*" element={<NotFound404 />} />
                </Route>
            </Route>
            <Route path="cars-premium-display">
                <Route index element={<ManualBudgetList />} />
                <Route path="create" element={<ManualBudgetFormContainer />} />
                <Route path=":budgetId">
                    <Route path="edit" element={<ManualBudgetFormContainer />} />
                    <Route path="details" element={<ManualBudgetDetails />} />
                    <Route path="*" element={<NotFound404 />} />
                </Route>
            </Route>
            <Route path="facebook" element={<BudgetList />} />
            <Route path="koddi" element={<BudgetList />} />
            <Route path="cars" element={<BudgetList />} />
            <Route path="dsp" element={<BudgetList />} />
            <Route path="programmatic-video" element={<BudgetList />} />
            <Route path="all" element={<BudgetList />} />
        </Routes>
    );
};

export default ClientBudgetRoutes;
