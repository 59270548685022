import React, { useState } from "react";
import Modal from "./Modal";

interface IProps {
    toggleButton(open: () => void): any;
    modalContent(hide: () => void): any;
    className?: string;
    style?: any;
    modalRoot?: string;
}

const ModalToggle: React.FunctionComponent<IProps> = ({ toggleButton, modalContent, className, style, modalRoot }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hide = () => setIsOpen(false);
    const open = () => setIsOpen(true);

    return (
        <React.Fragment>
            {toggleButton(open)}
            {isOpen && (
                <Modal className={className} style={style} hide={hide} modalRoot={modalRoot}>
                    {modalContent(hide)}
                </Modal>
            )}
        </React.Fragment>
    );
};

export default ModalToggle;
