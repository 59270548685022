import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import IEntity from "../../../interfaces/IEntity";
import IAppState from "../../../interfaces/IAppState";
import { IDynamicCampaign } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import TextLoadingSkeleton from "../../Shared/TextLoadingSkeleton";
import { fetchDynamicCampaign } from "../../../actions/dynamicCampaigns/dynamicCampaignActions";
import CheckIcon from "../../Shared/Icons/CheckIcon";

interface IProps {
    id: number;
    dynamicCampaigns: IEntity<IDynamicCampaign>;
    loading: boolean;
    clientId: number;
    fetchDynamicCampaign(clientId: number, campaignId: number): void;
}

const stat = "flex justify-between";
const statHeader = "uppercase font-normal mt-0 ";
const label = "text-gray-800 font-bold text-left m-0";
const statValue = "text-cerulean-500 font-bold text-right m-0";
const statSection = "w-1/3 flex items-end border-r-2";

const defaultState = {
    executedAt: undefined,
    adGroupsAdded: null,
    adGroupsPaused: null,
    adGroupsRemoved: null,
    adGroupsUpdated: null,
    errors: null,
    warnings: null
};

interface IState {
    executedAt?: string;
    adGroupsAdded: null | number;
    adGroupsPaused: null | number;
    adGroupsRemoved: null | number;
    adGroupsUpdated: null | number;
    errors: null | any[];
    warnings: null | any[];
}

const DynamicCampaignStatusDetails = ({ id, clientId, loading, dynamicCampaigns, fetchDynamicCampaign }: IProps) => {
    const [state, setState] = useState<IState>(defaultState);
    const campaign = dynamicCampaigns[id] ? dynamicCampaigns[id] : null;

    useEffect(() => {
        fetchDynamicCampaign(clientId, id);
    }, []);

    useEffect(() => {
        if (campaign && campaign.previousAction) {
            setState(campaign.previousAction);
        }
    }, [campaign]);

    const { executedAt, adGroupsAdded, adGroupsPaused, adGroupsRemoved, adGroupsUpdated, errors, warnings } = state;

    return (
        <div className="flex container mx-auto p-8 text-sm">
            <div className={`${statSection} items-center`}>
                <div className="flex-1 flex items-center justify-center">
                    <div className="w-10 h-10 rounded-full bg-cerulean-500 p-1">
                        <CheckIcon className="h-8 w-8 text-white" />
                    </div>
                    <div className="flex-grow uppercase m-0 font-bold pl-4">Latest Action Completed</div>
                </div>
                <div className="flex-1 pr-4">
                    <div className={statHeader}>Executed At</div>
                    <div className={stat}>
                        <div className={label}>Date:</div>
                        {loading ? (
                            <TextLoadingSkeleton />
                        ) : (
                            <div className={statValue}>{moment(executedAt).format("MM/DD/YYYY")}</div>
                        )}
                    </div>
                    <div className={stat}>
                        <div className={label}>Time:</div>
                        {loading ? (
                            <TextLoadingSkeleton />
                        ) : (
                            <div className={statValue}>{`${moment(executedAt).format("LT")}`}</div>
                        )}
                    </div>
                </div>
            </div>

            <div className={statSection}>
                <div className="w-1/2 px-4">
                    <div className={statHeader}>Ad Groups</div>
                    <div className={stat}>
                        <div className={label}>Added:</div>
                        {loading ? <TextLoadingSkeleton /> : <div className={statValue}>{adGroupsAdded || 0}</div>}
                    </div>
                    <div className={stat}>
                        <div className={label}>Updated:</div>
                        {loading ? <TextLoadingSkeleton /> : <div className={statValue}>{adGroupsUpdated || 0}</div>}
                    </div>
                </div>
                <div className="w-1/2 px-4">
                    <div className={stat}>
                        <div className={label}>Paused:</div>
                        {loading ? <TextLoadingSkeleton /> : <div className={statValue}>{adGroupsPaused || 0}</div>}
                    </div>
                    <div className={stat}>
                        <div className={label}>Removed:</div>
                        {loading ? <TextLoadingSkeleton /> : <div className={statValue}>{adGroupsRemoved || 0}</div>}
                    </div>
                </div>
            </div>

            <div className="w-1/3 flex items-end">
                <div className="w-1/2 px-4">
                    <div className={statHeader}>Notices</div>
                    <div className={stat}>
                        <div className={label}>Errors:</div>
                        {loading ? (
                            <TextLoadingSkeleton />
                        ) : (
                            <div className={statValue}>{(errors && errors.length) || 0}</div>
                        )}
                    </div>
                    <div className={stat}>
                        <div className={label}>Warnings:</div>
                        {loading ? (
                            <TextLoadingSkeleton />
                        ) : (
                            <div className={statValue}>{(warnings && warnings.length) || 0}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IAppState, { id }: { id: number }) => ({
    id,
    dynamicCampaigns: state.dynamicCampaigns.entities,
    loading: state.dynamicCampaigns.loading,
    clientId: state.clients.currentClient
});

const mapDispatchProps = {
    fetchDynamicCampaign
};

export default connect(mapStateToProps, mapDispatchProps)(DynamicCampaignStatusDetails);
