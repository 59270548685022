/**
 * Created by fjiles@dealerinspire.com on 1/3/17.
 */
import _ from "lodash";
import { mapOldPartFieldToPinnedPartAndPartFields } from "../../utils/DynamicCampaignUtils";

export const createTemplatePart = (
    part: string,
    template: { [x: string]: string | any[]; id: any },
    defaults: { order: number }
) => {
    if (defaults.order === undefined && part) {
        defaults.order = template[part].length + 1;
    }
    const id = _.uniqueId("new-");
    const value = {
        id,
        ...mapOldPartFieldToPinnedPartAndPartFields(part),
        feedTemplateId: template.id,
        value: "",
        name: "",
        new: true,
        valid: false,
        dirty: true
    };
    return {
        result: id,
        entities: {
            dynamicTextAdTemplateParts: {
                [id]: { ...value, ...defaults }
            }
        }
    };
};
