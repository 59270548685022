import moment from "moment";
import { decamelizeKeys } from "humps";
import IBudgetFormValues, { IManualBudgetFormValues } from "../interfaces/Budgets/IBudgetFormValues";
import IBudgetApi from "../interfaces/Budgets/IBudgetApi";
import IBudgetFormErrors from "../interfaces/Budgets/IBudgetFormErrors";
import IBudgetLabel from "../interfaces/Budgets/IBudgetLabel";
import IEntity from "../interfaces/IEntity";
import IPreConvertedBudget from "../interfaces/IPreConvertedBudget";
import IConvertedBudget from "../interfaces/Budgets/IConvertedBudget";
import IAppState from "../interfaces/IAppState";
import * as Constants from "../constants";
import IClient from "../interfaces/IClient";
import IManualBudgetApi from "../interfaces/Budgets/IManualBudgetApi";
import IManualActivationApi from "../interfaces/Budgets/IManualActivationApi";
import IConvertedManualBudget from "../interfaces/Budgets/IConvertedManualBudget";

export const getBudgetsForTable = (budgets: any, showDeleted: boolean) => {
    return Object.keys(budgets)
        .filter((budgetId) => budgetId)
        .map((budgetId) => {
            return {
                ...budgets[budgetId]
            };
        })
        .filter((budget) => {
            if (showDeleted) {
                return budget.deletedAt !== null;
            }

            return budget.deletedAt === null;
        });
};

// This is the meat of the serach function
export const getFilteredBudgets = (budgets: IManualBudgetApi[], searchQuery: string, client: IClient) =>
    budgets.filter((budget: IManualBudgetApi) => {
        const fieldValuesToSearchThrough = [
            budget?.lastActivation?.note,
            budget?.lastActivation?.label,
            budget?.client?.clientServicesPod,
            budget?.client?.managedByUser,
            budget?.client?.name
        ].filter((possibleNullValue) => possibleNullValue) as string[];

        return (
            fieldValuesToSearchThrough.filter((value: string) =>
                value.toLowerCase().includes(searchQuery.toLowerCase())
            ).length > 0
        );
    });

// Converts a budget from form input values to an object accepted by api
export const convertBudgetToApi = (formValues: IBudgetFormValues, serviceId: number): IConvertedBudget => {
    const BUDGET_STRATEGY_ID = 1;

    const converted: IPreConvertedBudget = {
        ...(formValues.breachBuffer && {
            breachBuffer: Number(formValues.breachBuffer)
        }),
        ...(formValues.endDate && {
            eventEndsAt: moment(formValues.endDate, "MMM Do YYYY").format("YYYY-MM-DD")
        }),
        ...(formValues.startDate && {
            eventStartsAt: moment(formValues.startDate, "MMM Do YYYY").format("YYYY-MM-DD")
        }),
        ...(formValues.proratedStartDate && {
            proratedStartDate: moment(formValues.proratedStartDate, "MMM Do YYYY").format("YYYY-MM-DD")
        }),
        ...(formValues.digadServiceLaunchedOn && {
            digadServiceLaunchedOn: moment(formValues.digadServiceLaunchedOn, "MMM Do YYYY").format("YYYY-MM-DD")
        }),
        changeRequestLink: formValues.changeRequestLink,
        isManagedManually: formValues.isManagedManually,
        label: formValues.label.name,
        labelId: String(formValues.label.id),
        note: formValues.changeReason,
        serviceId,
        strategyId: BUDGET_STRATEGY_ID,
        targetSpend: Number(formValues.monthlyBudget),
        typeId: Number(formValues.budgetType)
    };

    return decamelizeKeys(converted) as IConvertedBudget;
};

export const convertFormFieldToManualBudget = (fields: IManualBudgetFormValues, type: string) => {
    return Object.assign(
        {
            change_request_link: fields.changeRequestLink,
            label: fields.label,
            note: fields.changeReason,
            target_spend: fields.monthlyBudget,
            type,
            digad_service_launched_on: moment(fields.digadServiceLaunchedOn, "MMM Do YYYY").format("YYYY-MM-DD")
        },
        fields.id ? { id: fields.id } : {}
    ) as IConvertedManualBudget;
};

export const convertManualBudgetToFormField = (budget: IManualBudgetApi) => {
    if (!budget.lastActivation) {
        return {
            id: "",
            changeReason: "",
            monthlyBudget: "",
            changeRequestLink: "",
            changedAt: null,
            digadServiceLaunchedOn: ""
        };
    }

    const {
        id,
        changeRequestLink,
        label,
        targetSpend: monthlyBudget,
        note: changeReason,
        updatedAt,
        digadServiceLaunchedOn
    }: IManualActivationApi = budget.lastActivation;

    return {
        id,
        changeReason,
        changeRequestLink,
        label,
        monthlyBudget,
        updatedAt,
        digadServiceLaunchedOn
    };
};
export const convertBudgetToFormField = (budget: IBudgetApi) => {
    const { breachBuffer, eventEndsAt, eventStartsAt, isManagedManually, label, labelId, targetSpend, typeId } =
        budget.activation as any;

    return {
        breachBuffer: breachBuffer ? String(breachBuffer) : "",
        budgetType: String(typeId),
        changeRequestLink: "",
        changeReason: "",
        endDate: eventEndsAt ? eventEndsAt : "",
        isManagedManually,
        label: { name: label, id: String(labelId) },
        monthlyBudget: String(targetSpend),
        startDate: eventStartsAt ? eventStartsAt : ""
    };
};

export const convertErrors = ({
    breachBuffer,
    typeId,
    note,
    changeRequestLink,
    eventEndsAt,
    isManagedManually,
    labelId,
    targetSpend,
    eventStartsAt,
    ...rest
}: any) => {
    return {
        breachBuffer,
        budgetType: typeId,
        changeReason: note,
        changeRequestLink,
        endDate: eventEndsAt,
        isManagedManually,
        label: labelId,
        monthlyBudget: targetSpend,
        startDate: eventStartsAt,
        ...rest
    };
};

export const formValidation = (values: IBudgetFormValues | IManualBudgetFormValues, labels: IEntity<IBudgetLabel>) => {
    const errors: IBudgetFormErrors = {};
    if (!values.monthlyBudget) {
        errors.monthlyBudget = "Required";
    }
    if (typeof values.label !== "string") {
        if (!values.label.id) {
            errors.label = "Required";
        }
        if (values.label.id) {
            if (!labels[values.label.id]) {
                errors.label = 'Currently selected Label is not an "Active" label in AdWords';
            }
        }
    }
    if (!values.changeReason) {
        errors.changeReason = "Required";
    }
    if (!values.changeRequestLink) {
        errors.changeRequestLink = "Required";
    }
    if (values.budgetType === String(Constants.BUDGET_TYPE_ID_EVENT) && !values.startDate) {
        errors.startDate = "Required";
    }
    if (values.budgetType === String(Constants.BUDGET_TYPE_ID_EVENT) && !values.endDate) {
        errors.endDate = "Required";
    }

    return errors;
};

export const validateLabelInCampaign = (label: IBudgetLabel, campaigns: IEntity<any>) => {
    return Object.keys(campaigns).find((key: any) => campaigns[key].labels.includes(label.name));
};

export const getCurrentBudget = (state: IAppState, routeProps: { budgetId: string | null }) => {
    if (!routeProps.budgetId) {
        return null;
    }
    const budgetId = routeProps.budgetId;
    const budget = state.budgets.entities[budgetId];
    return budget ? (convertBudgetToFormField(budget) as any) : null;
};

export const getCurrentManualBudget = (state: IAppState, routeProps: { budgetId: number | null }) => {
    if (!routeProps.budgetId) {
        return null;
    }
    const budgetId = routeProps.budgetId;
    const budget = state.manualBudgets.entities[budgetId];
    return budget ? (convertManualBudgetToFormField(budget) as any) : null;
};

interface IBudgetTabs {
    entities: IEntity<IClient>;
    currentClient?: number | null;
    uri: string;
}

export interface IBudgetTab {
    label: string;
    path: string;
    show: boolean;
}

export const defineTabs = ({ entities, currentClient, uri }: IBudgetTabs) => {
    if (!currentClient || uri.startsWith("/budgets")) {
        // If we don't have any current client, or we're on the `/budgets` page
        return [
            { label: "Google", path: "../adwords", show: true },
            { label: "Microsoft", path: "../microsoft", show: true },
            { label: "Facebook", path: "../facebook", show: true },
            { label: "Inventory Search Ads", path: "../koddi", show: true },
            { label: "Cars Premium Display", path: "../cars", show: true },
            { label: "Programmatic Display", path: "../dsp", show: true },
            { label: "Programmatic Video", path: "../programmatic-video", show: false }
        ];
    }

    const { adwordsId, bingId, koddiId, facebookAccount } = entities[currentClient];

    const hasNoAdWordsOrBingId = !adwordsId && !bingId;

    const hasNoFacebookAccount = !facebookAccount || Object.keys(facebookAccount).length === 0;

    // Only if we have an adwords id, or if it has neither an adwords id or bing id.
    const shouldShowAdWordsBudgets = !!adwordsId || hasNoAdWordsOrBingId;
    // Only if we have an bing id, or if it has neither an adwords id or bing id.
    const shouldShowBingBudgets = !!bingId || hasNoAdWordsOrBingId;

    const shouldShowKoddiBudgets = !!koddiId;

    return [
        { label: "All Budgets", path: "../all", show: false },
        { label: "Google", path: "../adwords", show: shouldShowAdWordsBudgets },
        { label: "Microsoft", path: "../microsoft", show: shouldShowBingBudgets },
        { label: "Facebook", path: "../facebook", show: !hasNoFacebookAccount },
        { label: "Inventory Search Ads", path: "../koddi", show: shouldShowKoddiBudgets },
        { label: "Cars Premium Display", path: "../cars", show: true },
        { label: "Programmatic Display", path: "../dsp", show: true },
        { label: "Programmatic Video", path: "../programmatic-video", show: false }
    ];
};

export const getClientName = ({ entities, currentClient, uri }: any) => {
    const clientName =
        currentClient && entities[currentClient] && uri && !uri.startsWith("/budgets")
            ? entities[currentClient].name
            : "";
    return clientName;
};
