/**
 * Created by fjiles@dealerinspire.com on 1/20/17.
 */
import actions from "../actions/dynamicCampaignActions";
import { CLEAR_ERRORS, SAVE_FAILED } from "../actions/errorsActions";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";
import { AnyAction } from "redux";

const initialState = { hasErrors: false, errors: {}, message: "", exceptionMessage: "" };
const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.REQUEST_DYNAMIC_CAMPAIGN_FAILURE:
        case SAVE_FAILED:
        case actions.REQUEST_DYNAMIC_CAMPAIGN_TEMPLATES_FAILURE:
            return {
                ...state,
                hasErrors: true,
                errors: { ...action.errors },
                exceptionMessage: action.exceptionMessage,
                message: action.error
            };
        case actions.START_BATCH_SAVE:
        case CLEAR_ERRORS:
            return { hasErrors: false, errors: {}, message: "", exceptionMessage: "" };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
