import { Component } from "react";
import _ from "lodash";
import { renderGeoJsonAdjustments, renderData, mapRegionStyle, renderRadiusAdjustments } from "./DataMapper";
import IAdjustment from "../../interfaces/IAdjustment";
import IEntity from "../../interfaces/IEntity";
import ISalesMapStatistic from "../../interfaces/ISalesMapStatistic";
// @ts-ignore: typescript not supported. Should upgrade to Google Maps JS API v3 Utilities.
import MarkerClusterer from "node-js-marker-clusterer";

interface IProps {
    bidAdjustments: IAdjustment[];
    campaignId?: string;
    locationStats: IEntity<ISalesMapStatistic>;
    mapCenter: { lat: number; lng: number };
    mapData: Array<{ sales: number; zip: number; lat: number; lng: number }>;
    mapZoom: number;
    selectedAdjustment: null | number;
    showSales: boolean;
    tilesLoaded(): void;
    updateMapBounds(center: { lat: number; lng: number }, zoom: number): void;
}

interface IState {}

class SalesMap extends Component<IProps, IState> {
    map: any;
    radius: any;
    regions: any;
    diSalesData: any;
    diSalesCluster: any;
    constructor(props: IProps) {
        super(props);

        this.map = undefined;
        this.radius = {};
        this.regions = {};
        this.onCenterChanged = this.onCenterChanged.bind(this);
        this.updateSelectedAdjustment = this.updateSelectedAdjustment.bind(this);
    }

    updateSelectedAdjustment(adjustmentId: number, oldId: number) {
        this.map.data.revertStyle();
        if (this.radius[adjustmentId]) {
            const marker = this.radius[adjustmentId];
            marker.set("fillColor", "yellow");
            this.map.setCenter(marker.center);
            this.map.setZoom(8);
        } else if (this.regions[adjustmentId]) {
            this.map.data.overrideStyle(this.regions[adjustmentId][0], {
                fillColor: "yellow"
            });
        }

        if (this.radius[oldId]) {
            this.radius[oldId].set("fillColor", "blue");
        }
    }

    onCenterChanged() {
        this.props.updateMapBounds(this.map.getCenter(), this.map.getZoom());
    }

    render() {
        this.diSalesData = [];

        // checking here for document.getElementById to make sure the map is there before we try and render
        // something is happening when changing routes that causes the render to happen in a weird order and errors because the map div is not rendered yet
        if (this.props.mapCenter && document.getElementById("map") !== null) {
            this.renderMap(this.props.mapCenter, this.props.mapData);
            if (this.map !== undefined) {
                this.renderBidAdjustments();
            }
        }
        return null;
    }

    componentWillUnmount() {
        if (this.map) {
            window.google.maps.event.clearListeners(this.map, "center_changed");
            window.google.maps.event.clearListeners(this.map, "zoom_changed");
        }
    }

    shouldComponentUpdate(newProps: IProps) {
        // right now only reason to update is if the points change
        if (newProps.mapData !== this.props.mapData) {
            return true;
        }

        if (newProps.selectedAdjustment !== this.props.selectedAdjustment) {
            this.updateSelectedAdjustment(
                newProps.selectedAdjustment as number,
                this.props.selectedAdjustment as number
            );
        }

        // rerender if bid adjustments change
        if (newProps.bidAdjustments !== this.props.bidAdjustments || newProps.campaignId !== this.props.campaignId) {
            return true;
        }

        // if we only toggle markers no need to re-render just toggle markers
        if (newProps.showSales !== this.props.showSales) {
            // if we do not have clusters rerender
            if (this.diSalesCluster === undefined) {
                return true;
            }

            if (newProps.showSales) {
                this.diSalesCluster.addMarkers(this.diSalesData);
            } else {
                this.diSalesCluster.clearMarkers();
            }
        }

        return false;
    }

    renderBidAdjustments() {
        this.map.data.setStyle((e: any) => ({
            ...mapRegionStyle,
            fillColor: e.getProperty("color"),
            clickable: true
        }));

        const adjustments = this.props.campaignId !== null ? _.values(this.props.bidAdjustments) : [];
        // filter out the radius and sort by smaller to bigger so that you can click on the small ones
        const circles = _.sortBy(
            _.remove(adjustments, (adjustment: any) => adjustment.type === "Proximity"),
            (o) => o.location.geoJson.geometry.radius
        );

        const infoWindow = new window.google.maps.InfoWindow({});
        const getStats = (id: number) => {
            return this.props.locationStats[id];
        };
        this.radius = renderRadiusAdjustments(circles, getStats, this.map, infoWindow);
        this.regions = renderGeoJsonAdjustments(adjustments, getStats, this.map, infoWindow);
    }

    renderMap(mapCenter: { lat: number; lng: number }, salesData: any) {
        if (mapCenter.lat && mapCenter.lng) {
            let center = this.props.mapCenter;
            let zoom = this.props.mapZoom;
            const targetEl = document.getElementById("map") as HTMLElement;
            this.map = new window.google.maps.Map(targetEl, {
                center,
                zoom
            });
            this.map.addListener("center_changed", this.onCenterChanged);
            this.map.addListener("zoom_changed", this.onCenterChanged);
            this.map.addListener("tilesloaded", () => {
                this.props.tilesLoaded();
            });

            if (this.props.showSales) {
                _.each(salesData, (data) => {
                    renderData(data, this.diSalesData);
                });

                const clusterOptions = {
                    zoomOnClick: false,
                    imagePath: `${window.location.origin}/images/m`
                };
                this.diSalesCluster = new MarkerClusterer(this.map, this.diSalesData, clusterOptions);
                this.diSalesCluster.setGridSize(20);
            }
        }
    }
}

export default SalesMap;
