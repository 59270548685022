import React from "react";
import moment from "moment";
import IClient from "../../../interfaces/IClient";
import IEntity from "../../../interfaces/IEntity";
import {
    PLATFORMS_ACTIVE_ID,
    GOOGLE_ADS_ID,
    MICROSOFT_ADS_ID,
    FACEBOOK_ADS_ID
} from "../../../constants/ClientConstants";
import { clientInsights, getClientInsightUrl, Insight } from "./Table/ClientInsights";
import DownloadCsv from "../../Buttons/DownloadCsv";
import { callApiAndFetchEveryPaginatedPage } from "../../../middleware/api";
import CLIENT_SCHEMAS from "../../../middleware/schemas/client";
import { InsightUrlType } from "../../../ts/types";
import { buildReportFromValuesMappedByHeader } from "../../../utils/CsvUtils";

interface ICsvHeader {
    label: string;
    key: any;
    fn: any;
}

interface IIndexable {
    [key: string]: any;
}

interface Props {
    activeInsight: Insight;
    search: string;
    onClick?: () => void;
}

const ClientCsvDownload: React.FunctionComponent<Props> = ({ activeInsight, search = "", onClick }) => {
    const getBlob = async () => {
        const url: InsightUrlType = getClientInsightUrl(activeInsight);
        const response = await callApiAndFetchEveryPaginatedPage(
            url(1, search, 100),
            CLIENT_SCHEMAS.CLIENT_ARRAY,
            "GET"
        );
        const { clients } = response.entities;
        const csvData = getCsvData(clients);
        return new Blob([csvData], { type: "text/plain;charset=utf-8" });
    };

    const getCsvData = (clients: IEntity<IClient>) => {
        const csvHeaders = clientInsights[activeInsight].columns.reduce(
            (result: ICsvHeader[], column: IIndexable): ICsvHeader[] => {
                if (column.accessor) {
                    if (column.id === PLATFORMS_ACTIVE_ID) {
                        result.push({
                            label: "Google Ads",
                            key: GOOGLE_ADS_ID,
                            fn: column.accessor
                        });
                        result.push({
                            label: "Microsoft Ads",
                            key: MICROSOFT_ADS_ID,
                            fn: column.accessor
                        });
                        result.push({
                            label: "Facebook Ads",
                            key: FACEBOOK_ADS_ID,
                            fn: column.accessor
                        });
                    } else {
                        result.push({
                            label: column.Header,
                            key: column.accessor,
                            fn: null
                        });
                    }
                }
                return result;
            },
            []
        );
        // Adds 'Client Id' column as first column in CSV
        csvHeaders.unshift({
            label: "Client ID",
            key: "id"
        });

        return buildReportFromValuesMappedByHeader(Object.values(clients), csvHeaders, ",", "\n");
    };
    const currentDateString = moment().format("YYYY-MM-DD");
    const insightNameString = String(activeInsight)
        .toLowerCase()
        .split("_")
        .map((word) => word[0].toUpperCase() + word.substr(1))
        .join(" ");
    return (
        <DownloadCsv
            filename={`${insightNameString} Clients Export ${currentDateString}.csv`}
            customProcess={getBlob}
            onClick={onClick}
        />
    );
};

export default ClientCsvDownload;
