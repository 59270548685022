import React from "react";
import { connect, useDispatch } from "react-redux";
import IClient from "../../../../interfaces/IClient";
import { deletePagedClient, fetchPagedClients } from "../../../../actions/clientActions";
import IAppState from "../../../../interfaces/IAppState";
import PencilIcon from "../../../Shared/Icons/PencilIcon";
import TableActionDeleteButton from "../../../Shared/Table/TableActionDeleteButton";
import TableActionLink from "../../../Shared/Table/TableActionLink";
import TableActionButton from "../../../Shared/Table/TableActionButton";
import RefreshIcon from "../../../Shared/Icons/RefreshIcon";
import { fetchCsv } from "../../../../middleware/api";
import { HAS_INVENTORY_ISSUES } from "../../../../constants/ClientConstants";
import ExternalLinkIcon from "../../../Shared/Icons/ExternalLinkIcon";
import buildUrl from "../../../../utils/UrlUtils";

interface IOwnProps {
    original: IClient;
    page: number;
    pageSize: number;
}

interface IProps extends IOwnProps {
    deleteClient: (id: number, page: number, pageSize: number, insight: string, search: string) => void;
    insight: string;
    search: string;
}

const ClientInventoryActionCell: React.FunctionComponent<IProps> = ({
    original,
    deleteClient,
    page,
    pageSize,
    insight,
    search
}) => {
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const api_id = original.inventoryFeedId?.split(",") ?? [];

    const inventoryUrl = buildUrl(
        "https://inventory-test.dealerinspire.com/inventory/search/" + original.inventoryApiKey,
        {
            mapping_format: "Fuel",
            page: 1,
            page_size: 250,
            api_id: api_id.reduce((acc, val, i) => {
                return {
                    ...acc,
                    [i]: val
                };
            }, {})
        }
    );

    return (
        <div className="justify-between flex text-gray-500">
            <a href={inventoryUrl} target="_blank" className={"text-white"} title={"View the inventory feed"}>
                <ExternalLinkIcon className="w-6 h-6 inline-block fill-current text-gray-500" />
            </a>
            <TableActionButton
                title={"Break the cache and refresh the inventory feed"}
                onClick={async () => {
                    setLoading(true);
                    await fetchCsv(`/clients/${original.id}/inventory-download?refresh=true`);
                    dispatch(fetchPagedClients({ page: 1, pageSize: 50 }, HAS_INVENTORY_ISSUES, ""));
                    setLoading(false);
                }}
                Icon={RefreshIcon}
                className={
                    (loading ? "rotate-fast" : "") +
                    " text-red-600 hover:text-red-500 border-none ring-transparent outline-none focus:outline-none"
                }
            />
        </div>
    );
};

const mapStateToProps = (state: IAppState, { original }: IOwnProps) => {
    return {
        insight: state.clients.paginatedClients.insight,
        search: state.clients.paginatedClients.search
    };
};

const mapDispatchToProps = {
    deleteClient: deletePagedClient
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInventoryActionCell);
