import * as React from "react";
import TrashIcon from "../../../../../Shared/Icons/TrashIcon";
import { connect, FormikContext } from "formik";
import IDynamicCampaignFormValue from "../../../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";

type Props = {
    keywordTemplateRelationshipIndex: number;
};

type ContextProps = {
    formik: FormikContext<IDynamicCampaignFormValue>;
};

const RemoveButton = ({
    keywordTemplateRelationshipIndex,
    formik: {
        values: {
            keywordTemplateRelationships: keywordTemplateRelationships,
            keywordTemplateRelationships: {
                [keywordTemplateRelationshipIndex]: keywordTemplateRelationship,
                [keywordTemplateRelationshipIndex]: { new: isNew }
            }
        },
        setFieldValue
    }
}: Props & ContextProps) => {
    const fieldPath = `keywordTemplateRelationships[${keywordTemplateRelationshipIndex}]`;

    const handleRemoveKeywordTemplate = () => {
        if (isNew) {
            setFieldValue(`keywordTemplateRelationships`, [
                ...keywordTemplateRelationships.slice(0, keywordTemplateRelationshipIndex),
                ...keywordTemplateRelationships.slice(keywordTemplateRelationshipIndex + 1)
            ]);

            return;
        }

        setFieldValue(`${fieldPath}`, {
            ...keywordTemplateRelationship,
            dirty: true,
            deleted: true
        });
    };

    return (
        <button
            data-testid={`delete-button-${fieldPath}`}
            onClick={() => handleRemoveKeywordTemplate()}
            type="button"
            className={`px-4 py-2 border-l text-gray-500 hover:text-red-600 hover:bg-gray-200`}
            title="Remove"
        >
            <TrashIcon className="w-6 h-6" />
        </button>
    );
};

export default connect<Props, any>(RemoveButton);
