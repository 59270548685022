import { takeEvery } from "redux-saga/effects";
import dsc from "../../constants/DynamicCampaignConstants";
import budget from "../../constants/budgets/BudgetConstants";
import * as Client from "../../constants/ClientConstants";
import DealerSetup from "../../constants/DealerSetupConstants";
import * as FacebookReport from "../../constants/FacebookReportConstants";
import etaBoilerplate from "../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import dscEventSagas from "./Events/DynamicSearchCampaigns/dscEventSagas";
import etaBoilerplateEventSagas from "./Events/DynamicSearchCampaigns/etaBoilerplateEventSagas";
import budgetEventSagas from "./Events/Budgets/budgetEventSagas";
import clientEventSagas from "./Events/Clients/clientEventSagas";
import dealerSetupEventSagas from "./Events/DealerSetup/dealerSetupEventSagas";
import facebookEventSagas from "./Events/FacebookReport/facebookReportEventSagas";
import * as SalesMap from "../../constants/SalesDataImportConstants";
import salesMapEventSagas from "./Events/SalesMap/salesMapEventSagas";
import campaignsEventSagas from "./Events/Campaigns/campaignsEventSagas";
import * as Campaigns from "../../actions/campaignActions";
import * as Notifications from "../../actions/notificationActions";
import notificationEventSagas from "../../sagas/notificationSagas";
import notificationsEventSagas from "./Events/Notifications/notificationsEventSagas";

const events = {
    // DSC Events
    [dsc.REQUEST_CREATE_DYNAMIC_CAMPAIGN]: dscEventSagas.createEvent,
    [dsc.REQUEST_UPDATE_DYNAMIC_CAMPAIGN]: dscEventSagas.updateEvent,
    [dsc.REQUEST_DELETE_DYNAMIC_CAMPAIGN]: dscEventSagas.removeEvent,
    [dsc.PUSH_DYNAMIC_CAMPAIGN]: dscEventSagas.pushEvent,
    [dsc.REQUEST_PURGE_DYNAMIC_CAMPAIGN]: dscEventSagas.purgeEvent,

    // DSC ETA Template / Boilerplate Events
    [etaBoilerplate.store.request.type]: etaBoilerplateEventSagas.saveEtaBoilerplateEvent,
    [etaBoilerplate.destroy.request.type]: etaBoilerplateEventSagas.deleteEtaBoilerplateEvent,
    [etaBoilerplate.loadBoilerplate.type]: etaBoilerplateEventSagas.loadEtaTemplatesEvent,
    [etaBoilerplate.addNewTemplate.type]: etaBoilerplateEventSagas.createEtaTemplateEvent,
    [etaBoilerplate.duplicateTemplate.type]: etaBoilerplateEventSagas.duplicateEtaTemplateEvent,

    // Budget Events
    [budget.REQUEST_CREATE_BUDGET]: budgetEventSagas.createEvent,
    [budget.REQUEST_UPDATE_BUDGET]: budgetEventSagas.updateEvent,
    [budget.REQUEST_DELETE_BUDGET]: budgetEventSagas.removeEvent,
    [budget.REQUEST_PUSH_BUDGET]: budgetEventSagas.pushEvent,
    [budget.DOWNLOAD_CSV_CLICKED]: budgetEventSagas.downloadCsv,

    // Client Events
    [Client.REQUEST_CREATE_CLIENT]: clientEventSagas.createEvent,
    [Client.REQUEST_SAVE_CLIENT]: clientEventSagas.updateEvent,
    [Client.REQUEST_DELETE_CLIENT]: clientEventSagas.editPageRemoveEvent,
    [Client.REQUEST_DELETE_PAGED_CLIENT]: clientEventSagas.indexPageRemoveEvent,
    [Client.REQUEST_RESTORE_CLIENT]: clientEventSagas.restoreEvent,
    [Client.DOWNLOAD_INVENTORY_CLICKED]: clientEventSagas.downloadInventory,
    [Client.DOWNLOAD_CSV_CLICKED]: clientEventSagas.downloadCsvClicked,
    [Client.CLIENT_SELECTOR_REFRESH_CLICKED]: clientEventSagas.clientSelectorRefresh,

    // Dealer Setup Events
    [DealerSetup.SAVE_FORM_REQUEST]: dealerSetupEventSagas.saveFormEvent,

    // Facebook Report Events
    [FacebookReport.FACEBOOK_REPORT_DOWNLOAD_CLICKED]: facebookEventSagas.downloadReportClicked,

    //Sales Map Events
    [SalesMap.default.PULL_DATA_FROM_DI_CLICKED]: salesMapEventSagas.pullDataFromDiClicked,

    //Campaign Events
    [Campaigns.REFRESH_CAMPAIGN_CLICKED]: campaignsEventSagas.refreshCampaign,
    [Campaigns.CREATE_PARAMETERS_CLICKED]: campaignsEventSagas.createTrackingParameters,

    //Notifications Events
    [Notifications.MARK_ALL_AS_READ]: notificationsEventSagas.MarkAllAsReadClicked
};

function* eventTrackingSagas() {
    yield Object.entries(events).map(([key, saga]: [string, any]) => takeEvery(key, saga));
}

export default eventTrackingSagas;
