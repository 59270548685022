import React from "react";

const SimpleVehicleDisplay = ({ vehicle, issues }: any) => (
    <div className={`w-64 overflow-hidden flex-none ${issues ? "bg-red-200" : "bg-white"}`}>
        <div className="w-full h-48 flex justify-center items-center rounded overflow-hidden">
            <img
                className={"w-full"}
                src={vehicle.Thumbnail}
                alt={`${vehicle.Make} ${vehicle.Model} ${vehicle.Trim}`}
            />
        </div>
        <div className="p-2 w-full flex flex-col">
            <div className="font-bold">
                {vehicle.Year} {vehicle.Make} {vehicle.Model} {vehicle.Trim}
            </div>

            <div className="flex flex-wrap justify-between">
                <div className="font-bold">VIN</div>
                <div>{vehicle.VIN}</div>
            </div>
            <div className="flex flex-wrap justify-between">
                <div className="font-bold">Our Price</div>
                <div>${vehicle.OurPrice.toLocaleString()}</div>
            </div>
            <div className="flex flex-wrap justify-between">
                <div className="font-bold">Lease Payment</div>
                <div>${vehicle.LeasePayment.toLocaleString()}</div>
            </div>
            <div className="flex flex-wrap justify-between">
                <div className="font-bold">Miles</div>
                <div>{vehicle.Miles}</div>
            </div>

            <div className="flex flex-wrap justify-between">
                <div className="font-bold">Discounts</div>
                <div>
                    $
                    {Number.isNaN(Number(vehicle.Discounts))
                        ? vehicle.Discounts
                        : Number(vehicle.Discounts).toLocaleString()}
                </div>
            </div>
        </div>
    </div>
);

export default SimpleVehicleDisplay;
