import { useState, useEffect } from "react";

const useLoading = (...props: any) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (props.every(Boolean)) {
            setLoading(false);
        }
    }, props);
    return loading;
};

export default useLoading;
