import React from "react";
import PencilIcon from "./Icons/PencilIcon";
import TableActionLink from "./Table/TableActionLink";
import TableActionDeleteButton from "./Table/TableActionDeleteButton";

interface IOwnProps {
    original: any;
}

interface IProps extends IOwnProps {
    onCancel: (model: any) => void;
    onDelete: (model: any) => void;
    message: string;
}

const SimpleActionCell: React.FunctionComponent<IProps> = ({ original, message, onCancel, onDelete }) => {
    const { id } = original;

    return (
        <div className="justify-between flex text-gray-500">
            <TableActionLink to={`${id}`} Icon={PencilIcon} />
            <TableActionDeleteButton
                deletionMessage={message}
                onDelete={() => onDelete(original)}
                className="lg:ml-2"
            />
        </div>
    );
};

export default SimpleActionCell;
