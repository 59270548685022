import React, { Fragment, useState } from "react";
import { Calendar } from "react-date-range";
import moment from "moment";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import DownloadCsv from "../../Buttons/DownloadCsv";

function makeUrl(month: string, year: number): string {
    return `/budget-pacing-report?filter[report_month]=${month}&filter[report_year]=${year}`;
}

function makeFilename(month: string, year: number): string {
    return `budget-dashboard-${year}-${month}.csv`;
}

const Index = () => {
    const [currentDate, setCurrentDate] = useState(moment());
    const month = currentDate.format("MM");
    const year = currentDate.year();
    return (
        <Fragment>
            <PageTitle title="Budget Report" />
            <div>
                <Calendar
                    date=""
                    onChange={(date: any) => {
                        setCurrentDate(date);
                    }}
                />
            </div>
            <DownloadCsv url={makeUrl(month, year)} filename={makeFilename(month, year)} />
        </Fragment>
    );
};

export default Index;
