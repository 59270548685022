import React from "react";
import FieldErrors from "../Fields/FieldErrors";
import IDynamicCampaign from "../../../../interfaces/DealerSetup/IDynamicCampaign";

const DynamicSection: React.FC<IDynamicCampaign> = ({ fields }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    <div className="font-bold mb-4">Dynamic Campaign Names</div>
                    {fields.campaigns.map(({ value, errors }, i) => {
                        return (
                            <React.Fragment key={i}>
                                <div className="m-4">{value.name}</div>
                                <FieldErrors errors={errors} />
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default DynamicSection;
