import React, { Fragment } from "react";
import IClient from "../../../../interfaces/IClient";
import SVG from "../../../Shared/SVG";
import FacebookIcon from "../../../Shared/Icons/FacebookColorIcon";

interface IProps {
    original: IClient;
}

const ClientPlatformsCell: React.FunctionComponent<IProps> = ({ original }) => {
    return (
        <div className="flex">
            <div className="flex-1" style={{ minWidth: "33px" }}>
                {original.adwordsId && <SVG className="mr-2" name="GOOGLE" />}
            </div>
            <div className="flex-1" style={{ minWidth: "33px" }}>
                {original.bingId && <SVG className="mr-2" name="MICROSOFT" />}
            </div>
            <div className="flex-1" style={{ minWidth: "33px" }}>
                {original.facebookAccountId && <FacebookIcon className={"w-6 h-6"} />}
            </div>
        </div>
    );
};

export default ClientPlatformsCell;
