import { Bugsnag } from "../bugsnag";
import Config from "../config";
import IGAEventAttributes from "../utils/Analytics/IGAEventAttributes";
const configs = Config.getInstance();

type useTrackEventReturn = (eventArgs: IGAEventAttributes) => void;

function useTrackEvent(): useTrackEventReturn {
    const sendEvent = ({ category, action, label, value }: IGAEventAttributes) => {
        const gaProfile = configs.get("GA_TRACKING_PROFILE");

        if (typeof window === "undefined" || gaProfile === undefined) {
            return;
        }

        if (typeof (window as any).gtag !== "function" || !!gaProfile === false) {
            console.log({
                event_action: action,
                event_category: category,
                event_label: label,
                ...(value !== undefined ? { value: value } : {})
            });
            return;
        }

        (window as any).gtag("event", action, {
            event_category: category,
            event_label: label,
            ...(value !== undefined ? { value: value } : {})
        });
    };

    const trackEvent = (eventArgs: IGAEventAttributes) => {
        try {
            sendEvent(eventArgs);
        } catch (e) {
            Bugsnag.notify(e);
        }
    };

    return trackEvent;
}

export default useTrackEvent;
