import React from "react";
import { Dropdown } from "./index";

interface ISelectFilterProps {
    isVisible: boolean;
    title: string;
    filterOptions: any[];
    onClickOutside: () => void;
    onToggleOption: (index: number) => void;
    onToggleDropdown: () => void;
    onApplyFilters: (filterOptions: any[]) => void;
    onCancelFilters: () => void;
}

class SelectFilter extends React.Component<ISelectFilterProps> {
    render() {
        const {
            isVisible,
            filterOptions,
            onClickOutside,
            onToggleOption,
            onToggleDropdown,
            onApplyFilters,
            onCancelFilters,
            title
        } = this.props;
        return (
            <div className="filter select-filter">
                <button className="styled-button py-1 px-4" onClick={onToggleDropdown}>
                    {title}
                </button>
                {isVisible && (
                    <Dropdown
                        filterOptions={filterOptions}
                        onClickOutside={onClickOutside}
                        onToggleOption={onToggleOption}
                        onApplyFilters={onApplyFilters}
                        onCancelFilters={onCancelFilters}
                    />
                )}
            </div>
        );
    }
}

export default SelectFilter;
