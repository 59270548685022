import Cookies from "cookies-js";
import Config from "./config";
import { InitEcho, DisconnectEcho } from "./utils/EchoUtils";
import { callApi } from "./middleware/api";

const configs = Config.getInstance();

export const API_KEY = "FUEL_API_TOKEN";
export const CURRENT_USER = `CURRENT_USER`;
export const routesWithoutAuthentication = ["/login", "/logout"];

export const getApiKey = (): string => Cookies.get(API_KEY);
export const setApiKey = (key: string | null | undefined) => {
    Cookies.set(API_KEY, key === null ? "" : key, {
        secure: true
    });
    if (key) {
        DisconnectEcho();
        InitEcho();
    }
};

export const getApiUrl = () => {
    return configs.get("API_URL");
};

export const logout = () => {
    // When we logout, the first thing we should do is store the current path so we can return here once we log back in.
    localStorage.setItem("INTENDED_REDIRECT", JSON.stringify(window.location.pathname));
    window.localStorage.removeItem(CURRENT_USER);
    configs.removeOverride();
    setApiKey(null);

    // We only want to redirect if we're not logging in.
    if (window.location.pathname.indexOf("login") === -1) {
        window.location.replace("/logout");
    }
};

export const login = (body: any) => {
    const configs = {
        mode: "cors",
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } as RequestInit;
    return new Promise((resolve, reject) => {
        fetch(`${getApiUrl()}/auth/login`, configs).then((response) => {
            if (!response.ok) {
                if (!routesWithoutAuthentication.includes(window.location.pathname)) {
                    window.location.replace("/logout");
                    alert("You are no longer logged in.  Please logout and back in again.");
                }

                return reject("User is unauthenticated");
            }
            response.json().then((json) => resolve(json));
        });
    });
};

export const invalidateApiToken = (): Promise<void> => {
    const env = (envValue: string, defaultValue: string) => process.env[envValue] || defaultValue;
    return new Promise((resolve, reject) => {
        callApi(`/auth/logout`, {}, "GET", undefined, true, true)
            .then(() => {
                const auth0LogoutUrl =
                    env("REACT_APP_AUTH0_URL", "") +
                    "/v2/logout?client_id=" +
                    env("REACT_APP_AUTH0_FUEL_CLIENT_ID", "");
                window.location.replace(auth0LogoutUrl);
                resolve();
            })
            .catch(reject);
    });
};
