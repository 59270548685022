import React from "react";
import DeleteButton from "../DeleteButton";

interface IProps {
    onRemove: () => void;
}

const DetachSpecialOfferButton: React.FC<IProps> = ({ onRemove }) => (
    <DeleteButton className="ml-2" onRemove={onRemove} />
);

export default DetachSpecialOfferButton;
