import React, { useState } from "react";
import {
    IGroupedParts,
    IGroupedSpecialOfferParts,
    ISpecialOfferTemplateAd,
    ISpecialOfferTemplatePart
} from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import ActionBar from "./ActionBar";
import Label from "../../../Shared/Form/Label";
import SpecialOfferTemplateParts from "./SpecialOfferTemplateParts";
import { groupBy } from "lodash";
import TextAdPreview from "../ExpandedTextAds/TextAdPreview";
import ResponsiveAdPreview from "../Preview/ResponsiveAdPreview";

interface IProps {
    values: ISpecialOfferTemplateAd;
    idx: number;
    onDuplicate(sotIndex: number): void;
    detachSpecialOfferTemplateFromDynamicCampaign(): void;
}

const SpecialOfferTemplateAd: React.FC<IProps> = ({
    values,
    idx,
    onDuplicate,
    detachSpecialOfferTemplateFromDynamicCampaign
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const groupedParts = groupBy(
        values.parts.map((item: ISpecialOfferTemplatePart, i: number): ISpecialOfferTemplatePart => {
            item.index = i;
            return item;
        }),
        (value) => value.field
    ) as unknown as IGroupedSpecialOfferParts;

    return (
        <div className="flex flex-col mt-4 bg-white p-4 rounded shadow">
            <ActionBar
                value={values}
                idx={idx}
                label={values.label}
                isOpen={isOpen}
                onDuplicate={onDuplicate}
                onToggleOpen={handleToggleOpen}
                onDetach={detachSpecialOfferTemplateFromDynamicCampaign}
            />
            <div
                className={`flex flex-col ${!isOpen ? "overflow-hidden" : ""}`}
                style={{ transition: ".2s ease", ...(isOpen ? { maxHeight: "100%" } : { maxHeight: "0vh" }) }}
            >
                <div className="flex flex-row">
                    <div className="w-3/5">
                        <Label label="Label" required={false} />
                        <div className={`relative w-1/2 mb-4`}>
                            <input
                                disabled={true}
                                type="text"
                                name="label"
                                value={values.label ? values.label : ""}
                                className={`w-full mr-12 border-gray-500 border rounded px-3 py-3`}
                            />
                        </div>

                        <Label label="Headlines" required={true} />
                        <SpecialOfferTemplateParts parts={groupedParts.headline} templateIndex={idx} size="w-2/3" />

                        <Label label="Descriptions" required={true} />
                        <SpecialOfferTemplateParts parts={groupedParts.description} templateIndex={idx} size="w-2/3" />

                        <Label label="Path" required={true} />
                        <SpecialOfferTemplateParts parts={groupedParts.path} templateIndex={idx} size="w-2/3" />

                        <Label label="Final Url" required={true} />
                        <SpecialOfferTemplateParts parts={groupedParts.final_url} templateIndex={idx} size="w-2/3" />
                    </div>
                    <div className="w-2/5 mt-4">
                        <ResponsiveAdPreview {...groupedParts} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialOfferTemplateAd;
