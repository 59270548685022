import { Reducer } from "redux";
import IEntity from "../interfaces/IEntity";
import * as SitelinkTemplateActions from "../actions/SitelinkTemplateActions";
import { ISitelinkTemplate } from "../interfaces/DealerSetup/IAdExtensionFields";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

export interface ISitelinkTemplates {
    entities: IEntity<ISitelinkTemplate>;
    loading: boolean;
    busy: boolean;
}

const initialState = {
    entities: {},
    loading: false,
    busy: true
};

const sitelinkTemplatesReducer: Reducer<ISitelinkTemplates> = (state = initialState, action) => {
    switch (action.type) {
        case SitelinkTemplateActions.FETCH_SITELINK_TEMPLATES:
            return {
                ...state,
                busy: true,
                loading: true
            };
        case SitelinkTemplateActions.FETCH_SITELINK_TEMPLATES_SUCCESS:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...action.payload.sitelinkTemplates
                },
                busy: false,
                loading: false,
                loadedAt: new Date()
            };
        case SitelinkTemplateActions.FETCH_SITELINK_TEMPLATES_FAILED:
            return {
                ...state,
                entities: {
                    ...state.entities
                },
                busy: false,
                loading: false,
                loadedAt: new Date()
            };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return {
                ...state
            };
    }
};

export default sitelinkTemplatesReducer;
