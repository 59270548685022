export const LOAD_CURRENT_USER = "LOAD_CURRENT_USER";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const LOGIN_USER = "LOGIN_USER";

interface IUser {
    id?: string;
    email?: string;
    name?: string;
}

export const setCurrentUser = (user: IUser) => ({
    user,
    type: LOAD_CURRENT_USER
});

export const fetchCurrentUser = () => ({
    type: FETCH_CURRENT_USER
});

export const logoutCurrentUser = () => ({
    type: LOGOUT_CURRENT_USER
});
