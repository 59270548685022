import { useState } from "react";
import useApi, { IUseApiHandler } from "../useApi";
import { useSelector } from "react-redux";
import IClient from "../../interfaces/IClient";
import { useMatch } from "react-router";
import IAppState from "../../interfaces/IAppState";
import { IError } from "../../interfaces/shared/IItemCollection";
interface IAdGroup {
    //for future implementation.
    id?: number;
    adGroupName: string;
    campaignId: number;
    advertiserId: number;
}
export interface IISACampaign {
    advertiserId: number;
    budget: string;
    budgetType: string;
    campaignId: number;
    campaignName: string;
    endDate: null | string;
    goal: string;
    goalType: number;
    isAutoBid: boolean;
    pacing: number;
    startDate: string;
    status: string;
}

export type IuseInventorySearchAdsReturn = {
    campaigns: IISACampaign[];
    koddiData: any;
    loading: boolean;
    error: any;
    channelsAndPublishers: any;
    allPossibleChannelsAndPublishedInSelectFormat: any;
    selectedChannelsAndPublishers: any;
    setSelectedChannelsAndPublishers: any;
    creatingCampaign: boolean;
    creatingCampaignError: IError;
    createAccount: IUseApiHandler;
    createCampaign: IUseApiHandler;
    fetchCampaigns: IUseApiHandler;
    fetchingCampaigns: boolean;
    fetchingCampaignsError: any;
};

const publishers = {
    17: {
        id: 17,
        name: "Cars"
    }
};

const channels = {
    1: {
        id: 1,
        name: "Sponsored Listings"
    }
};
const channelsAndPublishers: any = { ...publishers, ...channels };

const allPossibleItemsInSelectFormat = (items: any) => {
    return Object.keys(items).map((key: any) => ({
        value: String(items[key]?.id || ""),
        label: items[key]?.name || ""
    }));
};

const allPossibleChannelsAndPublishedInSelectFormat = allPossibleItemsInSelectFormat(channelsAndPublishers);

export default function useInventorySearchAds(): IuseInventorySearchAdsReturn {
    const {
        //getting clientId from the route...
        params: { clientId }
    } = useMatch("/client/:clientId/*") ?? { params: {} };
    //getting the client from redux with useSelector and init internal state with the info from the client...
    const { clients } = useSelector((state: IAppState) => state);
    const client: IClient = clients.entities[clientId || 0];

    const [selectedChannelsAndPublishers, setSelectedChannelsAndPublishers] = useState(
        allPossibleChannelsAndPublishedInSelectFormat
    );
    const advertiserName = `${client?.name}${client?.carsSellerId ? ` - ${client.carsSellerId}` : ""}${
        client?.salesforceId ? ` - ${client.salesforceId}` : ""
    }`;

    const isInventorySearchAdsTabLocation = !!useMatch(`/client/${clientId}/campaigns/inventory-search-ads`);
    //request to create api
    const {
        arrayData: campaigns,
        request: fetchCampaigns,
        loading: fetchingCampaigns,
        error: fetchingCampaignsError
    } = useApi(`/clients/${clientId}/inventory-search-ads-campaigns`, {
        method: "GET",
        entityKey: "result",
        fetchOnInit: isInventorySearchAdsTabLocation && !!client.koddiId, //triggers on load from inventory search ads tab when koddi id is present on the client.
        schema: {}
    });

    const {
        request: createCampaign,
        loading: creatingCampaign,
        error: creatingCampaignError
    } = useApi(`/clients/${clientId}/inventory-search-ads-campaigns`, {
        method: "POST",
        schema: {},
        body: {},
        onFinish: () => fetchCampaigns()
    });

    const {
        // we are not currently using this method as the account is created at clientForm through the classic sagas workflow. We are keeping this for the future.
        data: koddiData,
        loading,
        error,
        request: createAccount
    } = useApi(`/clients/${clientId}/inventory-search-ads-advertisers`, {
        schema: {},
        method: "POST",
        body: {
            advertiserName,
            channels: [
                {
                    id: 1,
                    name: "Sponsored Listings",
                    publishers: [
                        {
                            id: 43,
                            name: "Cars"
                        }
                    ]
                }
            ]
        }
    });

    return {
        campaigns,
        koddiData,
        loading,
        creatingCampaign,
        creatingCampaignError,
        error,
        channelsAndPublishers,
        allPossibleChannelsAndPublishedInSelectFormat,
        selectedChannelsAndPublishers,
        createAccount,
        createCampaign,
        fetchCampaigns,
        fetchingCampaigns,
        fetchingCampaignsError,
        setSelectedChannelsAndPublishers
    };
}
