import actions from "../../actions/dynamicCampaignActions";
import _ from "lodash";
import { AnyAction } from "redux";
const getDataFromResponse = (action: AnyAction) => {
    return action.response.entities.dtcTemplateConditional;
};
const templatesConditionalsReducer = (state: { [key: string]: any } = {}, action: AnyAction) => {
    switch (action.type) {
        case actions.CREATE_TEMPLATE_CONDITIONAL:
        case actions.UPDATE_TEMPLATE_CONDITIONAL:
        case actions.LOAD_DYNAMIC_CAMPAIGN_TEMPLATES:
            return { ...state, ...getDataFromResponse(action) };
        case actions.REMOVE_TEMPLATE_CONDITIONAL:
            const updated = state;
            delete updated[action.id];
            return { ...updated };
        default:
            return state;
    }
};
export default templatesConditionalsReducer;

const findByTemplateId = (state: any, id: number) => {
    return _.filter(state, { feedTemplateId: id });
};

export { findByTemplateId };
