import React, { useRef } from "react";
import SimpleVehicleDisplay from "./SimpleVechileDisplay";
import CloseIcon from "../../../Shared/Icons/CloseIcon";
import { greaterThanZero } from "../../../../utils/MathHelpers";

interface IProps {
    vehicleGroup: any;
    setSelectedVehicleGroup: any;
    couldThisInventoryGroupHaveAProblem: any;
}

const ScrollableVehicleContentGroup: React.FunctionComponent<IProps> = ({
    vehicleGroup,
    setSelectedVehicleGroup,
    couldThisInventoryGroupHaveAProblem
}) => {
    const sideScrollWindow = useRef(null);

    return (
        <div className="relative">
            <div ref={sideScrollWindow} className="">
                <div
                    className="flex flex-wrap gap-4 overflow-y-scroll w-full mt-4 bg-gray-300 pb-4"
                    style={{ maxHeight: "700px" }}
                >
                    <div className="w-full flex items-center justify-between sticky top-0 shadow bg-gray-300">
                        <div className="text-xl font-semibold p-2">Vehicles in AdGroup</div>
                        <button className="mr-4 mt-2" onClick={() => setSelectedVehicleGroup(null)}>
                            <CloseIcon className="w-6 h-6" />
                        </button>
                    </div>
                    {vehicleGroup.map((vehicle: any) => {
                        return (
                            <SimpleVehicleDisplay
                                key={vehicle.VIN}
                                vehicle={vehicle}
                                issues={
                                    couldThisInventoryGroupHaveAProblem(vehicleGroup) &&
                                    ((!isNaN(Number(vehicle.OurPrice)) && !greaterThanZero(Number(vehicle.OurPrice))) ||
                                        isNaN(vehicle.OurPrice) ||
                                        (isNaN(Number(vehicle.Discounts)) &&
                                            !greaterThanZero(Number(vehicle.Discounts))) ||
                                        isNaN(vehicle.Discounts))
                                }
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ScrollableVehicleContentGroup;
