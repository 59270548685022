import ISagaProps from "../../../../interfaces/ISagaProps";
import { call } from "redux-saga/effects";
import { callApi, parseErrors } from "../../../../middleware/api";
import DCUtils from "../../../../utils/DynamicCampaignUtils";
import { camelizeKeys } from "humps";
import { IKeywordBidModifier } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";

interface IBidProps {
    clientId: number;
    dynamicCampaignId: number;
    bid: IKeywordBidModifier;
}

export default function* handleKeywordBidModifiers({
    dynamicCampaignId,
    clientId,
    bids
}: {
    dynamicCampaignId: number;
    clientId: number;
    bids: IKeywordBidModifier[];
}): any {
    const savedBids = [];
    const savingErrors = [];

    for (const bid of bids) {
        const { dirty, deleted, new: isNew, ...formData } = bid;
        if (dirty) {
            const props = {
                payload: {
                    bid: { ...formData },
                    clientId,
                    dynamicCampaignId
                }
            };
            if (deleted) {
                if (!isNew) {
                    yield call(deleteBidModifier, props);
                }
            } else {
                if (isNew) {
                    const [bidError, newBid] = yield call(createBidModifier, props);
                    savedBids.push(newBid);
                    if (bidError) {
                        savingErrors[savedBids.length - 1] = bidError;
                    }
                } else {
                    const [bidError, newBid] = yield call(updateBidModifier, props);
                    savedBids.push(newBid);
                    if (bidError) {
                        savingErrors[savedBids.length - 1] = bidError;
                    }
                }
            }
        } else {
            savedBids.push(bid);
        }
    }

    return [savingErrors.length > 0 ? savingErrors : false, savedBids];
}

function* createBidModifier({ payload }: ISagaProps<IBidProps>): any {
    const { clientId, dynamicCampaignId, bid } = payload;
    const url = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/keyword-bid-modifiers`;

    try {
        const response = yield call(callApi, url, {}, "POST", DCUtils.mapInputFieldsToApiRequest(bid));

        return [false, response.result];
    } catch (error) {
        return [parseErrors(error.errors), camelizeKeys(bid)];
    }
}

function* updateBidModifier({ payload }: ISagaProps<IBidProps>): any {
    const { clientId, dynamicCampaignId, bid } = payload;
    const url = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/keyword-bid-modifiers/${bid.id}`;

    try {
        const response = yield call(callApi, url, {}, "PATCH", DCUtils.mapInputFieldsToApiRequest(bid));

        return [false, response.result];
    } catch (error) {
        return [parseErrors(error.errors), camelizeKeys(bid)];
    }
}

function* deleteBidModifier({ payload }: ISagaProps<IBidProps>): any {
    const { clientId, dynamicCampaignId, bid } = payload;
    const url = `/clients/${clientId}/dynamic-campaigns/${dynamicCampaignId}/keyword-bid-modifiers/${bid.id}`;

    try {
        yield call(callApi, url, {}, "DELETE");

        return [false];
    } catch (error) {
        return [parseErrors(error.errors), camelizeKeys(bid)];
    }
}
