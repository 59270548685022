import React, { useEffect, useState } from "react";
import IAppState from "../../../../../interfaces/IAppState";
import { Dispatch } from "redux";
import ITrackingParameterFormValues from "../../../../../interfaces/ITrackingParameterFormValues";
import ITrackingParameter, {
    IArrayOfCampaigns,
    IArrayOfRemoteCampaigns
} from "../../../../../interfaces/ITrackingParameter";
import { updateCampaignToTrackingParameter } from "../../../../../actions/trackingParameterActions";
import { connect, useSelector } from "react-redux";
import withClient from "../../../../withClient";
import TrackingParametersForm from "./TrackingParametersForm";
import IClient from "../../../../../interfaces/IClient";
import IEntity from "../../../../../interfaces/IEntity";
import { IAdwordsCampaign, ITrackingParameterCampaignRelation } from "../../../../../interfaces/Campaigns";
import IMicrosoftCampaign from "../../../../../interfaces/IMicrosoftCampaign";
import { IOption } from "../../../../../interfaces/IDisplayCampaign";
import { useParams } from "react-router-dom";

interface IProps {
    currentClient: IClient;
    adwordsCampaigns: IEntity<IAdwordsCampaign>;
    microsoftCampaigns: IEntity<IMicrosoftCampaign>;
    trackingParameters: IEntity<ITrackingParameter>;
    updateCampaignToTrackingParameter: (
        values: (ITrackingParameterFormValues & IArrayOfRemoteCampaigns) | IArrayOfCampaigns,
        trackingParameter: ITrackingParameter
    ) => void;
    platformOptions: {
        [key: string]: IOption;
    };
    trackedCampaigns: IEntity<ITrackingParameterCampaignRelation>;
    trackingParameter: ITrackingParameter;
}
type IRouteParameters = {
    trackingParameterId: string;
};

const EditTrackingParameter: React.FunctionComponent<IProps> = ({
    platformOptions,
    updateCampaignToTrackingParameter,
    trackingParameters,
    trackedCampaigns,
    ...props
}) => {
    const [trackedCampaignIds, setTrackedCampaignIds] = useState([] as string[]);
    const { trackingParameterId = "" } = useParams<IRouteParameters>();
    const trackingParameter = useSelector(
        ({ trackingParameters }: IAppState) => trackingParameters.entities[trackingParameterId]
    );

    useEffect(() => {
        const trackedRelationIds = trackingParameter?.trackedCampaigns || [];

        const trackedCampaignIdsFiltered: string[] = trackedRelationIds.map((relationId) => {
            const tracked = trackingParameter?.trackedCampaigns || [];
            const campaignsAsObjects: ITrackingParameterCampaignRelation[] = tracked.map((id) => trackedCampaigns[id]);
            const campaign: ITrackingParameterCampaignRelation = campaignsAsObjects.filter(
                (campaign: ITrackingParameterCampaignRelation) => relationId === campaign.id
            )[0];

            return campaign?.campaignId;
        });

        setTrackedCampaignIds(trackedCampaignIdsFiltered);
    }, [trackingParameter?.trackedCampaigns, trackedCampaigns]);

    return (
        <div>
            <TrackingParametersForm
                {...props}
                initialValues={{
                    name: trackingParameter?.name || "",
                    platform: trackingParameter?.platform || "",
                    parameters: trackingParameter?.parameters || "",
                    trackedCampaigns: trackedCampaignIds
                }}
                trackingParameter={trackingParameter}
                onSave={(data) => {
                    if (!trackingParameter) {
                        console.error("You cannot update a tracking parameter that doesnt exist");
                        return;
                    }
                    updateCampaignToTrackingParameter(data, trackingParameter);
                }}
                platformOptions={platformOptions}
            />
        </div>
    );
};

const mapStateToProps = (state: IAppState, props: any) => ({
    adwordsCampaigns: state.adwordsCampaigns.entities,
    microsoftCampaigns: state.microsoftCampaigns.entities,
    trackingParameters: state.trackingParameters.entities,
    trackedCampaigns: state.trackingParameters.trackedCampaigns
});

const mapDispatchToProps = (dispatch: any) => ({
    updateCampaignToTrackingParameter(
        data: ITrackingParameterFormValues & IArrayOfCampaigns,
        trackingParameter: ITrackingParameter
    ) {
        dispatch(updateCampaignToTrackingParameter(data, trackingParameter));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withClient(EditTrackingParameter));
