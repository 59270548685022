import React, { useState } from "react";
import CreatableSelect from "react-select";
import { map, uniq } from "lodash";

interface IProps {
    values: string[];
    handleChange: any;
    label?: string;
}

interface IVariation {
    label: string;
    value: string;
}

const Variations: React.FunctionComponent<IProps> = ({ values, handleChange, label }) => {
    const [inputValue, setInputValue] = useState("");

    const variations = values.map(
        (value: string): IVariation => ({
            label: value,
            value
        })
    );

    const handleVariationsChange = (value: any) => {
        handleChange(uniq(map(value, "value")));
    };

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    const handleKeyDown = (event: any) => {
        if (!inputValue) {
            return;
        }
        switch (event.key) {
            case "Enter":
            case "Tab":
                handleChange(uniq([...map(variations, "value"), inputValue]));
                setInputValue("");
                event.preventDefault();
        }
    };

    return (
        <div className="w-full">
            <div className="w-full py-3 flex flex-wrap">
                <div className="flex-none my-4 font-bold text-base w-full">{label}</div>
                <CreatableSelect
                    components={{
                        DropdownIndicator: null
                    }}
                    className="text-base w-full rounded"
                    inputValue={inputValue}
                    isClearable={true}
                    isMulti={true}
                    menuIsOpen={false}
                    onChange={handleVariationsChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Type something and press enter..."
                    value={variations}
                />
            </div>
        </div>
    );
};

export default Variations;
