import React from "react";
import Button, { PRIMARY_BUTTON, NEGATIVE_BUTTON } from "../../Shared/Button";
import { Formik, Form, Field, ErrorMessage, FormikActions } from "formik";
import InventoryMentionsInput from "./InventoryMentionsInput";
import * as yup from "yup";
import RefreshIcon from "../../Shared/Icons/RefreshIcon";

interface IProps {
    inventoryExportId: number;
    clientId: number;
    googleExport: null | IGoogleAutoExportFormValues;
    inventoryVariables: string[];
    hide(): void;
    onSaveGoogleExport(
        googleExport: IGoogleAutoExportFormValues,
        inventoryExportId: number,
        clientId: number,
        formikActions: FormikActions<IGoogleAutoExportFormValues>
    ): void;
}

export interface IGoogleAutoExportFormValues {
    id?: number;
    idTemplate: string;
    id2Template: string;
    itemTitleTemplate: string;
    itemDescriptionTemplate: string;
    contextualKeywordsTemplate: string;
    priceTemplate: string;
    salePriceTemplate: string;
    imageUrlTemplate: string;
    finalUrlTemplate: string;
    itemCategoryTemplate: string;
}

const initialValues: IGoogleAutoExportFormValues = {
    idTemplate: "{{VIN}}",
    id2Template: "{{Stock}}",
    itemTitleTemplate: "{{Year}} {{Make}} {{Model}} {{Trim}}",
    itemDescriptionTemplate: "{{Year}} {{Make}} {{Model}} {{Trim}}",
    contextualKeywordsTemplate: "{{Make}} {{Model}}\n{{Year}} {{Model}}",
    priceTemplate: "{{OurPrice}}",
    salePriceTemplate: "{{OurPrice}}",
    imageUrlTemplate: "{{Images}}",
    finalUrlTemplate: "{{Link}}",
    itemCategoryTemplate: "Automotive"
};

const googleAutoExportSchema = yup.object().shape({
    idTemplate: yup.string().required().max(255),
    id2Template: yup.string().required().max(255),
    itemTitleTemplate: yup.string().required().max(255),
    itemDescriptionTemplate: yup.string().required().max(255),
    contextualKeywordsTemplate: yup.string().required().max(255),
    priceTemplate: yup.string().required().max(255),
    salePriceTemplate: yup.string().required().max(255),
    imageUrlTemplate: yup.string().required().max(255),
    finalUrlTemplate: yup.string().required().max(255),
    itemCategoryTemplate: yup.string().required().max(255)
});

const inputStyles = "border py-2 px-4 rounded block w-full";
const errorStyles = "text-red-600 pb-4";
const columnStyles = "flex flex-wrap -mx-3 mb-4";
const groupStyles = "w-full md:w-1/2 px-4 md:mb-0";
const labelStyles = "mt-4 block";

const GoogleExport: React.FunctionComponent<IProps> = ({
    onSaveGoogleExport,
    inventoryExportId,
    clientId,
    googleExport,
    hide,
    inventoryVariables
}) => {
    return (
        <div className="">
            <h1 className="text-2xl font-bold text-gray-800 border-b">Google Ads Export Mapping</h1>
            <Formik
                validationSchema={googleAutoExportSchema}
                onSubmit={(values, formikActions) => {
                    onSaveGoogleExport(values, inventoryExportId, clientId, formikActions);
                }}
                initialValues={{ ...initialValues, ...googleExport, apiErrors: null }}
                render={({ values, setFieldValue, setFieldTouched, isSubmitting, submitForm, status = "unsaved" }) => {
                    if (status === "saved") {
                        hide();
                    }
                    const handleOnChange = (name: string, value: any) => {
                        setFieldValue(name, value);
                    };

                    const handleOnBlur = (fieldName: string) => {
                        setFieldTouched(fieldName);
                    };

                    return (
                        <Form>
                            <ErrorMessage component="span" className={errorStyles} name="apiErrors" />
                            <div className="text-base">
                                <div className={columnStyles}>
                                    <div className={groupStyles} data-testid="ID">
                                        <label className={labelStyles} htmlFor="idTemplate">
                                            ID
                                        </label>
                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="idTemplate"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage component="span" className={errorStyles} name="idTemplate" />
                                    </div>

                                    <div className={groupStyles} data-testid="ID2">
                                        <label className={labelStyles} htmlFor="id2Template">
                                            ID2
                                        </label>
                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="id2Template"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage component="span" className={errorStyles} name="id2Template" />
                                    </div>

                                    <div className={groupStyles} data-testid="Item Title">
                                        <label className={labelStyles} htmlFor="itemTitleTemplate">
                                            Item Title
                                        </label>
                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="itemTitleTemplate"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            className={errorStyles}
                                            name="id2Templatid2Template"
                                        />
                                    </div>

                                    <div className={groupStyles} data-testid="Item Description">
                                        <label className={labelStyles} htmlFor="itemDescriptionTemplate">
                                            Item Description
                                        </label>
                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="itemDescriptionTemplate"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            className={errorStyles}
                                            name="itemDescriptionTemplate"
                                        />
                                    </div>

                                    <div className={groupStyles} data-testid="Price">
                                        <label className={labelStyles} htmlFor="priceTemplate">
                                            Price
                                        </label>

                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="priceTemplate"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage component="span" className={errorStyles} name="priceTemplate" />
                                    </div>

                                    <div className={groupStyles} data-testid="Sale Price">
                                        <label className={labelStyles} htmlFor="salePriceTemplate">
                                            Sale Price
                                        </label>

                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="salePriceTemplate"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            className={errorStyles}
                                            name="salePriceTemplate"
                                        />
                                    </div>

                                    <div className={groupStyles} data-testid="Image url">
                                        <label className={labelStyles} htmlFor="imageUrlTemplate">
                                            Image url
                                        </label>
                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="imageUrlTemplate"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            className={errorStyles}
                                            name="imageUrlTemplate"
                                        />
                                    </div>

                                    <div className={groupStyles} data-testid="Final Url">
                                        <label className={labelStyles} htmlFor="finalUrlTemplate">
                                            Final Url
                                        </label>
                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="finalUrlTemplate"
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            className={errorStyles}
                                            name="finalUrlTemplate"
                                        />
                                    </div>

                                    <div className={groupStyles} data-testid="Keywords">
                                        <label className={labelStyles} htmlFor="contextualKeywordsTemplate">
                                            Keywords
                                        </label>
                                        <InventoryMentionsInput
                                            className={inputStyles}
                                            fieldName="contextualKeywordsTemplate"
                                            singleLine={false}
                                            values={values}
                                            onInputChange={handleOnChange}
                                            onInputBlur={handleOnBlur}
                                            optionValues={inventoryVariables}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            className={errorStyles}
                                            name="contextualKeywordsTemplate"
                                        />
                                    </div>

                                    <div className={groupStyles} data-testid="Category">
                                        <label className={labelStyles} htmlFor="itemCategoryTemplate">
                                            Category
                                        </label>
                                        <Field
                                            className={`${inputStyles} bg-gray-200`}
                                            disabled={true}
                                            id="itemCategoryTemplate"
                                            name="itemCategoryTemplate"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            component="span"
                                            className={errorStyles}
                                            name="itemCategoryTemplate"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end">
                                <Button styleType={NEGATIVE_BUTTON} disabled={isSubmitting} onClick={hide}>
                                    Cancel
                                </Button>
                                <Button
                                    styleType={PRIMARY_BUTTON}
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                    styles="ml-4"
                                >
                                    {!isSubmitting && <span>Save</span>}
                                    {isSubmitting && (
                                        <span className="flex items-center">
                                            <RefreshIcon className="w-6 h-6 rotate-fast" />
                                            <span className="ml-2">Saving</span>
                                        </span>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    );
};

export default GoogleExport;
