import React from "react";
import ICompetitorsFields from "../../../../interfaces/DealerSetup/ICompetitorsFields";
import FieldErrors from "../Fields/FieldErrors";

const CompetitorsSection: React.FC<ICompetitorsFields> = ({ fields }) => {
    return (
        <React.Fragment>
            {fields && (
                <div className="m-4">
                    <div className="font-bold mb-4">Listed Competitors</div>

                    {fields.competitors.value.ad_groups.map((competitor, index) => {
                        const { name, variations } = competitor.value;
                        return (
                            <div key={index} className="m-4">
                                <div className="font-bold">{name}</div>
                                {variations.map((variation, key) => {
                                    return <div key={`${variation}${key}`}>{variation}</div>;
                                })}
                                <FieldErrors errors={competitor.errors} />
                            </div>
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default CompetitorsSection;
