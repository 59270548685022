import React from "react";
import useNamingConventions from "../../../hooks/namingConventions/useNamingConvention";
import AsyncTable from "../../Shared/Table/AsyncTable";
import tableColumns, {
    ActionColumn,
    ITableColumn
} from "../../../components/Admin/NamingConventions/NamingConventionsColumns";
import { Link } from "react-router-dom";
import { baseStyles, primaryClasses } from "../../Shared/Button";
import AddIcon from "../../Shared/Icons/AddIcon";

export default () => {
    const {
        loadingNamingConventions: loading,
        deletingNamingConventions: deleting,
        duplicatingNamingConvention: duplicating,
        namingConventions = [],
        fetchNamingConventions,
        deleteNamingConvention,
        duplicateNamingConventionHandler
    } = useNamingConventions();

    const ActionCol = {
        id: "ActionColumn",
        header: "Actions",
        sortable: false,
        width: 110,
        Cell: ({ original, original: { id = 0 } }: ITableColumn) => {
            return (
                <ActionColumn
                    onDuplicate={(newName: string) => duplicateNamingConventionHandler(id, newName)}
                    onDelete={(id) => deleteNamingConvention(id)}
                    onUpdate={fetchNamingConventions}
                    {...{
                        original
                    }}
                />
            );
        }
    };

    return (
        <div className="mx-4 my-4 flex flex-col gap-2">
            <div className="flex justify-between">
                <span className="text-4xl font-bold">Naming Conventions</span>
                <Link
                    className={`${baseStyles} ${primaryClasses} flex items-center text-white`}
                    to={"/admin/naming-conventions/create"}
                >
                    <AddIcon className="h-6 w-6" />
                    <span className="ml-2">New Naming Convention</span>
                </Link>
            </div>
            <div>
                <AsyncTable
                    columns={[ActionCol, ...tableColumns]}
                    loadingMessage={`${loading ? "Loading" : ""} ${deleting ? "Deleting" : ""} naming conventions...`}
                    noDataMessage={"No naming conventions"}
                    loadingTable={loading || deleting || duplicating}
                    fetchData={() => fetchNamingConventions()}
                    data={namingConventions}
                />
            </div>
        </div>
    );
};
