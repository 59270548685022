import Constants from "../constants/OEMReportConstants";

const initialState = {
    entities: {},
    isSubmitting: true,
    count: 0,
    loading: true,
    errors: null
};

function reportListReducer(state: any = initialState, action: any) {
    switch (action.type) {
        case Constants.FETCH_REPORT_LIST:
            return {
                ...state,
                isSubmitting: true,
                loading: true,
                errors: null
            };
        case Constants.FETCH_REPORT_LIST_FAILURE:
            return { ...state, errors: action.payload, isSubmitting: false, loading: false };
        case Constants.FETCH_REPORT_LIST_SUCCESS_EMPTY:
            return { ...state, errors: null, isSubmitting: false, loading: false };
        case Constants.FETCH_REPORT_LIST_SUCCESS:
            return {
                ...state,
                entities: action.payload.entities.OEMReports,
                errors: null,
                isSubmitting: false,
                loading: false,
                pagination: {
                    pageSize: action.payload.result.perPage,
                    lastPage: action.payload.result.lastPage,
                    currentPage: action.payload.result.currentPage
                }
            };
        case Constants.CREATE_REPORT_JOBS:
            return {
                ...state,
                isSubmitting: true,
                loading: false,
                errors: null,
                entities: state.entities
            };
        case Constants.CREATE_REPORT_JOBS_SUCCESS:
            return {
                ...state,
                count: state.count + 1,
                isSubmitting: false,
                loading: false,
                entities: [action.payload, ...state.entities],
                errors: null
            };
        case Constants.CREATE_REPORT_JOBS_FAILURE:
            return { ...state, errors: action.payload, isSubmitting: false, loading: false };
        default:
            return state;
    }
}

export default reportListReducer;
