import _, { isEqual } from "lodash";
import { flow, set, update } from "lodash/fp";
import { combineReducers, Reducer } from "redux";
import { createSelector } from "reselect";
import {
    FAILED_LOAD_CLIENTS,
    FAILED_LOAD_DELETED_CLIENTS,
    FAILED_SAVE_CLIENT,
    FETCH_CLIENTS_NO_SALESFORCE,
    LOAD_CLIENTS,
    LOAD_DELETED_CLIENTS,
    LOAD_PAGED_CLIENTS,
    NO_INSIGHTS,
    NO_SEARCH,
    NO_SORT,
    REMOVE_CLIENT,
    REQUEST_DELETE_PAGED_CLIENT,
    REQUEST_PAGED_CLIENTS,
    REQUEST_PAGED_DELETED_CLIENTS,
    REQUEST_RESTORE_CLIENT,
    REQUEST_SAVE_CLIENT,
    RESET_CLIENT_STATE,
    SET_CURRENT_CLIENT,
    UPDATE_CLIENT
} from "../constants/ClientConstants";
import IAppState from "../interfaces/IAppState";
import IClient from "../interfaces/IClient";
import IEntity from "../interfaces/IEntity";
import IPaginatedClients from "../interfaces/IPaginatedClients";

const entities: Reducer<IEntity<IClient>> = (state = {} as IEntity<IClient>, action) => {
    switch (action.type) {
        case LOAD_PAGED_CLIENTS:
            return { ...state, ...action.payload.entities };
        case LOAD_DELETED_CLIENTS:
        case LOAD_CLIENTS:
            return { ...action.response.entities.clients };
        case UPDATE_CLIENT: {
            const client = action.client;
            return {
                ...state,
                [client.id]: { ...state[client.id], ...client, saving: false }
            };
        }
        case FAILED_SAVE_CLIENT: {
            const client = action.client;
            const errors = action.errors;
            return {
                ...state,
                [client.id]: { ...state[client.id], errors, saving: false }
            };
        }
        case REQUEST_SAVE_CLIENT: {
            const client = action.client;
            return {
                ...state,
                [client.id]: { ...state[client.id], errors: {}, saving: true }
            };
        }
        case REMOVE_CLIENT: {
            delete state[action.id];
            return { ...state };
        }
        default:
            return state;
    }
};

const currentClient: Reducer<number | null> = (state = null, action) => {
    switch (action.type) {
        case SET_CURRENT_CLIENT:
            return parseInt(action.id, 10) || null;
        default:
            return state;
    }
};

const loading: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case FETCH_CLIENTS_NO_SALESFORCE:
        case REQUEST_PAGED_DELETED_CLIENTS:
        case REQUEST_PAGED_CLIENTS:
            return true;
        case FAILED_LOAD_CLIENTS:
        case FAILED_LOAD_DELETED_CLIENTS:
        case LOAD_PAGED_CLIENTS:
        case LOAD_CLIENTS:
        case LOAD_DELETED_CLIENTS:
            return false;
        case RESET_CLIENT_STATE:
            return false;
        default:
            return state;
    }
};

const paginatedClients: Reducer<IPaginatedClients> = (
    state = {
        results: {},
        page: 1,
        insight: NO_INSIGHTS,
        search: "",
        sort: undefined,
        pageSize: 15,
        lastPage: undefined
    },
    action
) => {
    switch (action.type) {
        case REMOVE_CLIENT:
            return {
                ...state,
                results: {}
            };
        case REQUEST_PAGED_CLIENTS:
            return {
                ...state,
                page: action.payload.page,
                insight: action.payload.insight,
                pageSize: action.payload.pageSize,
                search: action.payload.search
            };
        case LOAD_PAGED_CLIENTS:
            const { insight, search, sort, pageSize, page, lastPage, results } = action.payload;
            return flow(
                set(
                    `results.${insight}.${search || NO_SEARCH}.${sort || NO_SORT}.PAGE_SIZE_${pageSize}.totalPages`,
                    lastPage
                ),
                update(
                    `results.${insight}.${search || NO_SEARCH}.${sort || NO_SORT}.PAGE_SIZE_${pageSize}.pages`,
                    (pages: Array<[number]> = []) => {
                        if (pages[page]?.length > 0 && !isEqual(pages[page], results)) {
                            return [...pages.slice(0, page), results];
                        }

                        return set(page, results, pages);
                    }
                )
            )(state) as IPaginatedClients;
        default:
            return state;
    }
};

const deleting: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case REQUEST_DELETE_PAGED_CLIENT:
            return true;
        case LOAD_PAGED_CLIENTS:
            return false;
        default:
            return state;
    }
};

const restoring: Reducer<boolean> = (state = false, action) => {
    switch (action.type) {
        case REQUEST_RESTORE_CLIENT:
            return true;
        case LOAD_PAGED_CLIENTS:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    entities,
    loading,
    currentClient,
    paginatedClients,
    deleting,
    restoring
});

const getClientsObject = (state: IAppState) => state.clients.entities;
const getCurrentClientId = (state: IAppState) => state.clients.currentClient;
const getPaginatedClientsInsight = (state: IAppState) => state.clients.paginatedClients.insight;
const getPaginatedClientsSearch = (state: IAppState) => state.clients.paginatedClients.search;
const getPaginatedClientsSort = (state: IAppState) => state.clients.paginatedClients.sort;
const getPaginatedClientsPageSize = (state: IAppState) => state.clients.paginatedClients.pageSize;
const getPaginatedClientsPage = (state: IAppState) => state.clients.paginatedClients.page;
const getPaginatedClientsResults = (state: IAppState) => state.clients.paginatedClients.results;

/****************** Selectors *******************/
const selectClientsByName = createSelector(getClientsObject, (clients) =>
    _.sortBy(clients, (o) => String(o.name).toLowerCase())
);

const getCurrentClientObject = createSelector([getClientsObject, getCurrentClientId], (clients, currentClientId) => {
    return !currentClientId ? null : clients[currentClientId];
});

const hasCurrentPaginatedClientsResults = createSelector(
    [
        getPaginatedClientsResults,
        getPaginatedClientsInsight,
        getPaginatedClientsSearch,
        getPaginatedClientsSort,
        getPaginatedClientsPageSize,
        getPaginatedClientsPage
    ],
    (results, insight, search, sort, pageSize, page) => {
        return !!results[insight]?.[search || NO_SEARCH]?.[sort || NO_SORT]?.[`PAGE_SIZE_${pageSize}`]?.pages?.[page];
    }
);

const getCurrentPaginatedClientsTotalPages = createSelector(
    [
        getPaginatedClientsResults,
        getPaginatedClientsInsight,
        getPaginatedClientsSearch,
        getPaginatedClientsSort,
        getPaginatedClientsPageSize
    ],
    (results, insight, search, sort, pageSize) => {
        return results[insight]?.[search || NO_SEARCH]?.[sort || NO_SORT]?.[`PAGE_SIZE_${pageSize}`]?.totalPages;
    }
);

const getCurrentPaginatedClients = createSelector(
    [
        getClientsObject,
        getPaginatedClientsResults,
        getPaginatedClientsInsight,
        getPaginatedClientsSearch,
        getPaginatedClientsSort,
        getPaginatedClientsPageSize,
        getPaginatedClientsPage
    ],
    (clients, results, insight, search, sort, pageSize, page) => {
        return (
            results[insight]?.[search || NO_SEARCH]?.[sort || NO_SORT]?.[`PAGE_SIZE_${pageSize}`]?.pages?.[page]?.map(
                (key: number) => clients[key]
            ) ?? []
        );
    }
);

export {
    selectClientsByName,
    getClientsObject,
    getCurrentClientObject,
    getPaginatedClientsResults,
    hasCurrentPaginatedClientsResults,
    getCurrentPaginatedClientsTotalPages,
    getCurrentPaginatedClients
};
