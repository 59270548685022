import React, { useState } from "react";
import { Card, CardTitle } from "./BudgetDetails";
import ActivityHeader from "./ActivityHeader";
import ChangeLog from "./ChangeLog";
import SystemLog from "./SystemLog";
import BudgetConstants from "../../../../constants/budgets/BudgetConstants";
import IBudgetSystemLog from "../../../../interfaces/Budgets/IBudgetSystemLog";
import IBudgetChangeLog from "../../../../interfaces/Budgets/IBudgetChangeLog";
import ToggleSwitch from "../../../Shared/Form/ToggleSwitch";

interface ILog {
    updatedAt: string;
    statusId: number;
    status: string;
    note: string;
    oldBudget: number;
    newBudget: number;
    changes: any[];
    type: string;
    activationId?: number;
    actionId?: number;
}
interface IProps {
    changeLogs: null | IBudgetChangeLog[];
    systemLogs: null | IBudgetSystemLog[];
}

export const mergeLogs = (changeLogs: null | IBudgetChangeLog[], systemLogs: null | IBudgetSystemLog[]): ILog[] => {
    if (!changeLogs && !systemLogs) {
        return [];
    }
    return [...(changeLogs ? changeLogs : []), ...(systemLogs ? systemLogs : [])];
};

export const filterAndSortLogs = (logs: ILog[], filters: { [key: string]: boolean }) => {
    return logs.filter((log) => filters[log.type]).sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
};

const ActivityLogs: React.FunctionComponent<IProps> = ({ changeLogs, systemLogs }) => {
    const [logFilters, setLogFilter] = useState({
        [BudgetConstants.CHANGE_LOG]: true,
        [BudgetConstants.SYSTEM_LOG]: true
    });

    const handleToggle = (name: string) => {
        setLogFilter({ ...logFilters, [name]: !logFilters[name] });
    };

    const logs = filterAndSortLogs(mergeLogs(changeLogs, systemLogs), logFilters);

    return (
        <div className="md:ml-4">
            <div className={`${Card}`}>
                <div className={"flex justify-between items-center"}>
                    <span className="font-bold">Budget Activity</span>

                    <div className="flex flex-wrap pb-4 font-bold">
                        <ToggleSwitch
                            value={logFilters[BudgetConstants.CHANGE_LOG]}
                            label="User Changes"
                            field="user-changes"
                            onToggle={() => {
                                handleToggle(BudgetConstants.CHANGE_LOG);
                            }}
                        />

                        <ToggleSwitch
                            value={logFilters[BudgetConstants.SYSTEM_LOG]}
                            label="System Changes"
                            field="system-changes"
                            onToggle={() => {
                                handleToggle(BudgetConstants.SYSTEM_LOG);
                            }}
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <ActivityHeader />
                    {logs.map((log) => {
                        if (log.type === BudgetConstants.CHANGE_LOG) {
                            return <ChangeLog key={`change-${log.activationId}`} log={log} />;
                        }
                        if (log.type === BudgetConstants.SYSTEM_LOG) {
                            return <SystemLog key={`system-${log.actionId}`} log={log} />;
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};
export default ActivityLogs;
