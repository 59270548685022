import { Reducer, combineReducers } from "redux";
import SpecialOfferTemplateConstants from "../constants/SpecialOfferTemplateConstants";
import IEntity from "../interfaces/IEntity";
import { ISpecialOfferTemplate } from "../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import {
    IFetchAllSpecialOfferTemplates,
    IFetchAllSuccessSpecialOfferTemplates
} from "../actions/specialOfferTemplateActions";
import { IFetchAllResponse } from "../sagas/specialOfferTemplatesSagas";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

interface ISpecialOffersTemplateEntities {
    order: number[];
    data: IEntity<ISpecialOfferTemplate>;
}

export interface ISpecialOfferTemplateReducerState {
    entities: ISpecialOffersTemplateEntities;
    create: any;
    loading: boolean;
    errors: any;
    loadedAt?: Date;
}

export const getSpecialOfferTemplatesInOrder = (
    specialOfferState: ISpecialOfferTemplateReducerState
): ISpecialOfferTemplate[] => {
    return specialOfferState.entities.order
        .map((index: number) => specialOfferState.entities.data[index])
        .filter((item) => item);
};

const entities: Reducer<
    ISpecialOffersTemplateEntities,
    IFetchAllSpecialOfferTemplates | IFetchAllSuccessSpecialOfferTemplates
> = (
    state = {
        data: {},
        order: []
    },
    action
): ISpecialOffersTemplateEntities => {
    switch (action.type) {
        case SpecialOfferTemplateConstants.FETCH_SPECIAL_OFFER_TEMPLATE_SUCCESS:
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES_SUCCESS:
            const items: IFetchAllResponse = {
                result: { data: [] },
                entities: {
                    specialOffersTemplates: {},
                    dynamicCampaigns: {},
                    specialOffersMetaTemplates: {}
                },
                ...action.payload
            };
            return {
                data: {
                    ...state.data,
                    ...items.entities.specialOffersTemplates
                },
                order: [...new Set(state.order.concat(items.result.data))]
            };
        case SpecialOfferTemplateConstants.DELETE_SPECIAL_OFFER_TEMPLATES_SUCCESS: {
            const specialOfferTemplateId = action?.payload?.specialOfferTemplateId;
            delete state.data[specialOfferTemplateId];
            return {
                data: {
                    ...state.data
                },
                order: state.order.filter((itemId) => itemId !== specialOfferTemplateId)
            };
        }
        case RESET_CLIENT_STATE:
            return {
                data: {},
                order: []
            };
        default:
            return {
                ...state
            };
    }
};

const loading: Reducer<any> = (state = false, action) => {
    switch (action.type) {
        case SpecialOfferTemplateConstants.FETCH_SPECIAL_OFFER_TEMPLATE:
        case SpecialOfferTemplateConstants.CREATE_SPECIAL_OFFER_TEMPLATE:
        case SpecialOfferTemplateConstants.UPDATE_SPECIAL_OFFER_TEMPLATE:
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES:
        case RESET_CLIENT_STATE:
            return true;
        case SpecialOfferTemplateConstants.FETCH_SPECIAL_OFFER_TEMPLATE_SUCCESS:
        case SpecialOfferTemplateConstants.FETCH_SPECIAL_OFFER_TEMPLATE_FAILED:
        case SpecialOfferTemplateConstants.CREATE_SPECIAL_OFFER_TEMPLATE_SUCCESS:
        case SpecialOfferTemplateConstants.CREATE_SPECIAL_OFFER_TEMPLATE_FAILED:
        case SpecialOfferTemplateConstants.UPDATE_SPECIAL_OFFER_TEMPLATE_SUCCESS:
        case SpecialOfferTemplateConstants.UPDATE_SPECIAL_OFFER_TEMPLATE_FAILED:
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES_SUCCESS:
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES_FAILED:
            return false;
        default:
            return state;
    }
};

const loadedAt: Reducer<any> = (state = null, action) => {
    switch (action.type) {
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES_SUCCESS:
            return new Date();
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES:
        case RESET_CLIENT_STATE:
        case SpecialOfferTemplateConstants.FETCH_ALL_SPECIAL_OFFER_TEMPLATES_FAILED:
            return null;
        default:
            return state;
    }
};

const errors: Reducer<any> = (state = false, action) => {
    switch (action.type) {
        case SpecialOfferTemplateConstants.UPDATE_SPECIAL_OFFER_TEMPLATE_FAILED:
        case SpecialOfferTemplateConstants.CREATE_SPECIAL_OFFER_TEMPLATE_FAILED:
            return {
                ...state,
                ...action.errors
            };
        case RESET_CLIENT_STATE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    loading,
    loadedAt,
    entities,
    errors
});
