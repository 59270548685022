import MicrosoftIcon from "../../../Shared/Icons/MicrosoftIcon";
import GoogleIcon from "../../../Shared/Icons/GoogleIcon";
import InformationIcon from "../../../Shared/Icons/InformationIcon";
import moment from "moment";
import { statusIndicatorSteps } from "../../../../constants/DynamicCampaignConstants";
import React, { useState } from "react";
import { camelizeKeys } from "humps";

const filterActualValue = (item: any, accessor: string) => {
    const camelizedKeys = Object.keys(camelizeKeys({ [accessor]: item }));
    //
    if (camelizedKeys.length <= 0) {
        return null;
    }
    const key = camelizedKeys[0];

    if (key === "date") {
        return moment(item[key]).format("YYYY-MM-DD");
    }

    return item[key];
};

export default ({ message, color }: any) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div
            onClick={() => setOpen(!isOpen)}
            className={"cursor-pointer flex gap-4 w-full h-full items-center whitespace-wrap"}
        >
            <div className="flex flex-wrap items-center w-32">
                {message.platform === "Microsoft" && <MicrosoftIcon className={"w-6 h-6"} style={{ color }} />}
                {message.platform === "Google" && <GoogleIcon className={"w-6 h-6"} style={{ color }} />}
                {message.platform === undefined && <InformationIcon className={"w-6 h-6"} style={{ color }} />}
                <div className="w-8 text-base font-medium ml-6">Info</div>
                <div className="mt-2 w-32 text-sm text-gray-600 tracking-tight">
                    {moment(message.timestamp * 1000).format("MMM DD HH:mm:ss")}
                </div>
            </div>
            <div className={"flex flex-col flex-1 overflow-hidden"}>
                {message.step && (
                    <div className={"font-bold"}>
                        Step: {statusIndicatorSteps.filter((item) => item.actionType === message.step)[0].initialLabel}
                    </div>
                )}
                {message.message && <div>{message.message}</div>}
                {message.label && <div>On ETA labeled: "{message.label}"</div>}
                {message.partPosition !== undefined && <div>ETA Part Order Number: {message.partPosition}</div>}
                {message.item && (
                    <div>
                        Inventory Item: {message.item.year} {message.item.make} {message.item.model} {message.item.trim}
                    </div>
                )}
                {message.conditional && (
                    <div>
                        {message.partPosition ? "ETA Part" : "ETA"} conditional item value "
                        {filterActualValue(message.item, message.conditional.field)}" fails condition{" "}
                        {message.conditional.field} {message.conditional.comparator} "{message.conditional.value}"
                    </div>
                )}
                {message.adGroup && (
                    <div>
                        {message.adGroup.primaryKey && (
                            <div>Primary key {JSON.stringify(message.adGroup.primaryKey)}</div>
                        )}
                        {message.adGroup.name && <div>Ad Group Name {message.adGroup.name}</div>}
                        {message.adGroup.adwordsAdGroupId && (
                            <div>Google AdGroup: {message.adGroup.adwordsAdGroupId}</div>
                        )}
                        {message.adGroup.microsoftAdGroupId && (
                            <div>Microsoft AdGroup: {message.adGroup.microsoftAdGroupId}</div>
                        )}
                    </div>
                )}
                {message.deletingIds && <div>{message.deletingIds?.join(", ")}</div>}
                {message.updatingIds && <div>{message.updatingIds?.join(", ")}</div>}
                {message.creatingIds && <div>{message.creatingIds?.join(", ")}</div>}
                {message.missingParameters && <div>{JSON.stringify(message.missingParameters)}</div>}

                {isOpen && (
                    <div className="flex flex-col gap-2">
                        {Object.keys(message)
                            .filter((key) => !["message"].includes(key))
                            .map((key) => {
                                return (
                                    <div key={key}>
                                        <div className="font-bold text-base">{key}</div>
                                        <div className="w-full text-monospace break-all">
                                            {typeof message[key] === "object"
                                                ? JSON.stringify(message[key], null, 4)
                                                : message[key]}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </div>
    );
};
