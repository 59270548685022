export default {
    REQUEST_MICROSOFT_BUDGETS: "REQUEST_MICROSOFT_BUDGETS",
    REQUEST_MICROSOFT_BUDGETS_SUCCESS: "REQUEST_MICROSOFT_BUDGETS_SUCCESS",
    REQUEST_MICROSOFT_BUDGETS_FAIL: "REQUEST_MICROSOFT_BUDGETS_FAIL",

    REQUEST_CLIENT_MICROSOFT_BUDGETS: "REQUEST_CLIENT_MICROSOFT_BUDGETS",
    REQUEST_CLIENT_MICROSOFT_BUDGETS_SUCCESS: "REQUEST_CLIENT_MICROSOFT_BUDGETS_SUCCESS",
    REQUEST_CLIENT_MICROSOFT_BUDGETS_FAIL: "REQUEST_CLIENT_MICROSOFT_BUDGETS_FAIL",

    REQUEST_DELETED_MICROSOFT_BUDGETS: "REQUEST_DELETED_MICROSOFT_BUDGETS",
    REQUEST_DELETED_MICROSOFT_BUDGETS_SUCCESS: "REQUEST_DELETED_MICROSOFT_BUDGETS_SUCCESS",
    REQUEST_DELETED_MICROSOFT_BUDGETS_FAIL: "REQUEST_DELETED_MICROSOFT_BUDGETS_FAIL",

    REQUEST_SINGLE_MICROSOFT_BUDGETS: "REQUEST_SINGLE_MICROSOFT_BUDGETS",
    REQUEST_SINGLE_MICROSOFT_BUDGETS_SUCCESS: "REQUEST_SINGLE_MICROSOFT_BUDGETS_SUCCESS",
    REQUEST_SINGLE_MICROSOFT_BUDGETS_FAIL: "REQUEST_SINGLE_MICROSOFT_BUDGETS_FAIL",

    REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS: "REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS",
    REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_SUCCESS: "REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_SUCCESS",
    REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_FAIL: "REQUEST_CLIENT_DELETED_MICROSOFT_BUDGETS_FAIL",

    REQUEST_CREATE_MICROSOFT_BUDGET: "REQUEST_CREATE_MICROSOFT_BUDGET",
    REQUEST_CREATE_MICROSOFT_BUDGET_SUCCESS: "REQUEST_CREATE_MICROSOFT_BUDGET_SUCCESS",
    REQUEST_CREATE_MICROSOFT_BUDGET_FAIL: "REQUEST_CREATE_MICROSOFT_BUDGET_FAIL",

    REQUEST_UPDATE_MICROSOFT_BUDGET: "REQUEST_UPDATE_MICROSOFT_BUDGET",
    REQUEST_UPDATE_MICROSOFT_BUDGET_SUCCESS: "REQUEST_UPDATE_MICROSOFT_BUDGET_SUCCESS",
    REQUEST_UPDATE_MICROSOFT_BUDGET_FAIL: "REQUEST_UPDATE_MICROSOFT_BUDGET_FAIL"
};
