import NegativeKeywordConstants from "../constants/NegativeKeywords";
import { INegativeKeywordCollState, NegativeKeywordCollActionTypes } from "../interfaces/NegativeKeywordColl";
import { RESET_CLIENT_STATE } from "../constants/ClientConstants";

const initialState: INegativeKeywordCollState = {
    entities: {},
    loadedAt: null,
    error: null
};

function negativeKeywordCollReducer(
    state = initialState,
    action: NegativeKeywordCollActionTypes
): INegativeKeywordCollState {
    switch (action.type) {
        case NegativeKeywordConstants.REQUEST_NEGATIVE_KEYWORD_COLLS:
            return { ...state, loadedAt: null, entities: {}, error: null };
        case NegativeKeywordConstants.REQUEST_NEGATIVE_KEYWORD_COLLS_FAIL:
            return { ...state, loadedAt: null, error: action.payload };
        case NegativeKeywordConstants.REQUEST_NEGATIVE_KEYWORD_COLLS_SUCCESS:
            return { ...state, loadedAt: new Date(), entities: action.payload, error: null };
        case RESET_CLIENT_STATE:
            return initialState;
        default:
            return state;
    }
}

export default negativeKeywordCollReducer;
