import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentUser } from "../hooks/useCurrentUser";
import Config from "../config";
const configs = Config.getInstance();

declare global {
    interface Window {
        gtag: (type: string, profileId: string, params: object) => void;
    }
}

const sendTracking = (location: { pathname: any; search: any; href: any }, user: { id: any }) => {
    const profileId = configs.get("GA_TRACKING_PROFILE");
    if (typeof window.gtag === "function" && profileId !== undefined) {
        window.gtag("config", profileId, {
            page_path: location.pathname + location.search,
            page_location: location.href,
            user_id: user ? user.id : null
        });
    }
};

const GATracking: React.FC<{ location: any }> = ({ location }) => {
    const user = useCurrentUser(null);
    useEffect(() => {
        sendTracking(location, user);
    }, [location.pathname, location.search]);
    return null;
};
/*
 * Uses the reach router Location component to track changes to the location so we can tell GA about it
 * See https://reach.tech/router/api/Location
 */
const AnalyticsTracking = () => {
    const location = useLocation();
    return <GATracking location={location} />;
};

export default AnalyticsTracking;
