import React, { FunctionComponent } from "react";
import { IClient } from "../../../../interfaces/IClient";
import IFacebookAccount from "../../../../interfaces/IFacebookAccount";
import { oemHasCustomConversions } from "../../../../reducers/facebook/oemConversions";
import Button, { PRIMARY_BUTTON, SECONDARY_BUTTON } from "../../../Shared/Button";
import Field from "../../../Shared/Form/Elements/Field";
import AddIcon from "../../../Shared/Icons/AddIcon";
import UnchainIcon from "../../../Shared/Icons/UnchainIcon";
import FacebookOemConversionsGenerateButton from "./FacebookOemConversionsGenerateButton";
import FacebookSubscribePageToAppButton from "./FacebookSubscribePageToAppButton";
import Async from "react-select/async";
import useFacebook from "../../../../hooks/facebook/useFacebook";

interface IFacebookForm {
    onLinkPage: any;
    onLink: any;
    client: IClient;
}

const FacebookForm: FunctionComponent<IFacebookForm> = ({ client, onLinkPage, onLink }) => {
    const {
        currentAccount,
        selectedAccount,
        setSelectedAccount,
        creatingFacebookAccount,
        createFacebookAccount,
        loadAccountOptions,
        loadPagesOptions
    } = useFacebook();

    const onFacebookAccountChange = (newSelectedAccount: IFacebookAccount | null) => {
        // Set state for account creation/form state
        setSelectedAccount(newSelectedAccount);
        // set account id on client for save/form validation
        onLink(newSelectedAccount);
    };

    const customSelectStyles = {
        control: () => ({
            alignItems: "center",
            backgroundColor: "white",
            borderColor: "#b8c2cc",
            borderRadius: ".25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "41px",
            outline: "0 !important",
            transition: "all 100ms"
        })
    };
    return (
        <div className="flex flex-col">
            <div className="w-full md:w-3/4 lg:w-1/2">
                <Field label="Facebook Ad Account Name" errors={[]}>
                    <Async
                        className="flex-1"
                        name="facebookAccount"
                        value={
                            selectedAccount
                                ? {
                                      label: [selectedAccount.name, selectedAccount.accountId].join(" - "),
                                      value: selectedAccount
                                  }
                                : ""
                        }
                        loadOptions={loadAccountOptions}
                        onChange={(item: any) => onFacebookAccountChange(item?.value)}
                        isClearable={true}
                        styles={customSelectStyles}
                        noOptionsMessage={() => "Search for an account by name"}
                    />
                </Field>

                {selectedAccount?.id && (
                    <Button styleType={SECONDARY_BUTTON} styles="my-4" onClick={() => onFacebookAccountChange(null)}>
                        <span className="flex items-center text-base">
                            <UnchainIcon className="w-6 h-6" />
                            <span className="ml-2">Unlink</span>
                        </span>
                    </Button>
                )}

                {(!selectedAccount?.id || creatingFacebookAccount) && (
                    <div className="mt-4">
                        <div className="flex flex-row justify-between items-center w-24">
                            <span className="flex border w-full"></span>
                            <span className="p-3 text-gray-600">OR</span>
                            <span className="flex border w-full"></span>
                        </div>
                        <Button
                            styleType={PRIMARY_BUTTON}
                            styles="my-4"
                            onClick={() => createFacebookAccount()}
                            disabled={creatingFacebookAccount}
                        >
                            <span className="flex items-center text-base">
                                <AddIcon className="w-6 h-6" />
                                <span className="ml-2">
                                    {creatingFacebookAccount ? "Creating" : "Create"} Facebook Account
                                </span>
                            </span>
                        </Button>
                    </div>
                )}
            </div>
            <div className="w-full md:w-3/4 lg:w-1/2">
                <Field label="Facebook Page Name" errors={[]}>
                    <Async
                        className="flex-1"
                        name="pageName"
                        value={
                            client.facebookPage
                                ? {
                                      label: [client.facebookPage.name, client.facebookPage.pageId].join(" - ") as any,
                                      value: client.facebookPage.pageId
                                  }
                                : ""
                        }
                        loadOptions={loadPagesOptions}
                        onChange={(item: any) => onLinkPage(item?.value)}
                        isClearable={true}
                        styles={customSelectStyles}
                        noOptionsMessage={() => "Search for a page by name"}
                    />
                </Field>

                {client.facebookPage && (
                    <Button styleType={SECONDARY_BUTTON} styles="my-4" onClick={() => onLinkPage()}>
                        <span className="flex items-center text-base">
                            <UnchainIcon className="w-6 h-6" />
                            <span className="ml-2">Unlink</span>
                        </span>
                    </Button>
                )}
            </div>
            {client.facebookPageId && client.crmEmail?.length > 0 && (
                <div className="w-full md:w-3/4 lg:w-1/2 flex flex-col">
                    <span className="uppercase text-base">Facebook leads</span>
                    <span className="cursor-pointer text-xs">
                        Leads will automatically be captured for the facebook page and sent to the emails listed under
                        "CRM Emails" in the "General" section.
                    </span>
                    <FacebookSubscribePageToAppButton client={client} />
                </div>
            )}

            {oemHasCustomConversions(client.oemProgram) && client.facebookPageId && (
                <FacebookOemConversionsGenerateButton client={client} />
            )}
        </div>
    );
};

export default FacebookForm;
