import {
    purgeDynamicCampaign,
    pushDynamicCampaign,
    requestCreateDynamicCampaign,
    requestDeleteItem,
    requestUpdateDynamicCampaign
} from "../../../../actions/dynamicCampaigns/dynamicCampaignActions";
import trackEvent from "../../../../utils/Analytics/trackEvent";

function* createEvent(action: ReturnType<typeof requestCreateDynamicCampaign>) {
    const name = action.payload.dynamicCampaign.name.replace(/ /g, "_").toLowerCase();
    yield trackEvent({
        category: "DSC Create Page",
        action: `dsc_create_${name}`,
        label: "Create",
        value: action.meta.timeToSubmitMetric
    });
}

function* updateEvent(action: ReturnType<typeof requestUpdateDynamicCampaign>) {
    yield trackEvent({
        category: "DSC Edit Page",
        action: `dsc_update_${action.payload.clientId}_${action.payload.dynamicCampaign.id}`,
        label: "Update",
        value: action.meta.timeToSubmitMetric
    });
}

function* removeEvent(action: ReturnType<typeof requestDeleteItem>) {
    yield trackEvent({
        category: "DSC Index Page",
        action: `dsc_delete_${action.clientId}_${action.id}`,
        label: "Delete"
    });
}

function* pushEvent(action: ReturnType<typeof pushDynamicCampaign>) {
    yield trackEvent({
        category: "DSC Edit Page",
        action: `dsc_push_${action.payload.clientId}_${action.payload.dynamicCampaignId}`,
        label: "Push"
    });
}

function* purgeEvent(action: ReturnType<typeof purgeDynamicCampaign>) {
    yield trackEvent({
        category: "DSC Edit Page",
        action: `dsc_purge_${action.payload.clientId}_${action.payload.dynamicCampaignId}`,
        label: "Purge"
    });
}

export default {
    createEvent,
    updateEvent,
    removeEvent,
    pushEvent,
    purgeEvent
};
