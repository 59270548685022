import React, { SVGProps } from "react";
import Svg from "./Svg";

const MicrosoftIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M4.6875 2.60417V19.2708L9.375 22.3958L20.3125 16.6667V10.9375L11.4583 7.8125L13.0208 12.5L16.1458 13.5417L5.72917 18.75L9.89583 15.1042V4.16667L4.6875 2.60417Z" />
    </Svg>
);

export default MicrosoftIcon;
