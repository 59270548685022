import React, { forwardRef, useEffect, useState } from "react";
import { useDealerSetupForm, usePager } from "../DealerSetupUtils";
import { array, number, object, string } from "yup";
import { groupBy } from "lodash";
import { IClientDealerSetup } from "../../../../interfaces/IClient";
import IAppState from "../../../../interfaces/IAppState";
import { connect } from "react-redux";
import { IAdExtensionField, ISitelinkTemplate } from "../../../../interfaces/DealerSetup/IAdExtensionFields";
import IEntity from "../../../../interfaces/IEntity";
import LoaderSpinner from "../../../Shared/Loaders/LoaderSpinner";
import Button from "../../../Shared/Button";
import IFormValues from "../../../../interfaces/DealerSetup/IFormValues";
import TextField from "../../../Shared/Form/Blocks/TextField";

interface IProps {
    path: string;
    formValues: IFormValues;
    client: IClientDealerSetup;
    sitelinkTemplates: IEntity<ISitelinkTemplate>;
    savePage(path: string, validatePage: any): void;
}

const AdExtensions = forwardRef(
    ({ formValues, client, savePage, path, sitelinkTemplates }: IProps, ref: React.Ref<{}>) => {
        const templateGroups = groupBy(
            Object.values(sitelinkTemplates),
            (item: ISitelinkTemplate) => item.categoryName
        );
        const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;
        const pickRandomTemplateFromGroup = (groupName: string): ISitelinkTemplate => {
            const group = templateGroups[groupName];
            return group[random(0, group.length)];
        };
        const allCategories = Object.keys(templateGroups);

        // Then we need to map out the categories, and reduce those category names into a "default" form state
        const defaults = {
            fields: allCategories.reduce(
                (categories, category) => ({
                    ...categories,
                    [category]: {
                        sitelink_template_id: {
                            isTouched: false,
                            isValid: false,
                            errors: [],
                            value: null
                        },
                        final_url: {
                            isTouched: false,
                            isValid: false,
                            errors: [],
                            value: ""
                        }
                    }
                }),
                {}
            )
        };

        // We need a piece of state to store which sitelinks will be the default for a given category so it doesn't rerender infinitely with updates.
        const [defaultCategories, setDefaultCategories] = useState(
            {} as {
                [key: string]: ISitelinkTemplate;
            }
        );

        const shuffleDefaultCategories = () =>
            setDefaultCategories(
                allCategories.reduce(
                    (categories, categoryName) => ({
                        ...categories,
                        [categoryName]: pickRandomTemplateFromGroup(categoryName)
                    }),
                    {}
                )
            );

        // If this client has never been through setup before they won't have a state, so we want to be able to go through the form's state and find all the null valued sitelink_template_id fields and set those randomly.
        const shuffleTemplatesInCategory = () => {
            shuffleDefaultCategories();

            for (const category in values.fields) {
                if (values.fields[category] && defaultCategories[category]) {
                    handleFieldChange(`[${category}].sitelink_template_id`, defaultCategories[category].id);
                }
            }
        };

        useEffect(() => {
            // On page load we need to shuffle the templates that aren't already set to something.
            shuffleTemplatesInCategory();
        }, []);

        const schema = object({
            sitelink_template_id: number().required("The sitelink template is required"),
            final_url: string().required("The final url is required")
        });

        const extensionFields = formValues["ad-extensions"].fields;

        if (Array.isArray(extensionFields)) {
            formValues["ad-extensions"].fields = extensionFields
                .map((field: IAdExtensionField) => ({
                    [sitelinkTemplates[field.sitelink_template_id.value].categoryName]: field
                }))
                .reduce(
                    (fields, field) => ({
                        ...fields,
                        ...field
                    }),
                    {}
                );
        }
        const [values, handleFieldChange, changePage] = useDealerSetupForm(
            defaults,
            formValues,
            schema,
            ref,
            path,
            savePage
        );
        const formExtensions = values.fields.ad_extensions;

        useEffect(() => {
            if (Object.keys(defaultCategories).length === 0) {
                return;
            }

            for (const category in values.fields) {
                if (values.fields[category].sitelink_template_id.value === null) {
                    handleFieldChange(`[${category}].sitelink_template_id`, defaultCategories[category].id);
                }
            }
        }, [defaultCategories]);

        const pager = usePager(path, formValues, changePage);

        // If the default category's useEffect still hasn't ran then we need to return a spinner, it should only be rendered for a fraction of a fraction of a second, but react wants this....
        if (Object.keys(defaultCategories).length === 0) {
            return <LoaderSpinner message="Setting the default sitelinks" />;
        }

        return (
            <div className="p-4">
                <div className="text-2xl font-bold">Ad Extensions</div>
                <div className="border-t border-gray-300 pt-4 mt-4 text-xl">Account Level Ad Extensions</div>
                {Object.keys(values.fields).map((groupName: string, index) => {
                    const fieldValue = values.fields[groupName];
                    const sitelinkTemplate: ISitelinkTemplate = fieldValue.sitelink_template_id.value
                        ? sitelinkTemplates[fieldValue.sitelink_template_id.value]
                        : defaultCategories[groupName];

                    return (
                        <div key={groupName} className="border-t border-gray-300 pt-4 mt-4">
                            <div key={sitelinkTemplate.id} className="flex flex-wrap">
                                <div className="w-1/2">
                                    <div style={{ color: "#9b9c9f" }} className="text-xs">
                                        {sitelinkTemplate.categoryName}
                                    </div>
                                    <div className="font-bold text-lg">{sitelinkTemplate.headline1}</div>
                                    <div className="text-sm">
                                        {sitelinkTemplate.description1} {sitelinkTemplate.description2}
                                    </div>
                                </div>
                                <div className="w-1/2 -mt-4">
                                    <TextField
                                        label="Final Url"
                                        name="final_url"
                                        value={values.fields[groupName].final_url.value}
                                        handleChange={(event: any) => {
                                            handleFieldChange(`[${groupName}].final_url`, event.target.value);
                                        }}
                                        errors={values.fields[groupName].final_url.errors || []}
                                        placeholder="https://billluke.com/..."
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="py-4 my-4 border-t">
                    <div className="flex justify-end">
                        <Button
                            onClick={shuffleTemplatesInCategory}
                            type="button"
                            disabled={false}
                            styleType="secondary"
                            styles="font-semibold"
                        >
                            Randomize Ad Extensions
                        </Button>
                    </div>
                </div>

                {pager()}
            </div>
        );
    }
);

const mapStateToProps = (state: IAppState) => ({
    client: state.clients.entities[state.clients.currentClient],
    sitelinkTemplates: state.sitelinkTemplates.entities
});

export default connect(mapStateToProps, undefined, undefined, {
    forwardRef: true
})(AdExtensions);
