const actionCreator = <T>(
    type: string
): {
    (args?: T): {
        type: string;
        payload: T;
    };
    type: string;
} => {
    const action = (args?: T) => {
        return { type: type, payload: args || ({} as T) };
    };
    action.toString = () => type;
    action.type = type;
    return action;
};

export default actionCreator;
