import React from "react";
import DesktopPreview from "../../../AdWords/AdPreview/DesktopPreview";
import MobilePreview from "../../../AdWords/AdPreview/MobilePreview";
import {
    IGroupedParts,
    IGroupedSpecialOfferParts,
    ITemplatePart
} from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import { connect } from "react-redux";
import {
    getFirstValidTemplate,
    getDisplayUrlFromPathAndFinalUrl,
    getFirstValidPathTemplate,
    getValidTemplatesForRsa
} from "../../../../utils";
import { ISampleData } from "../../../../interfaces/DynamicCampaigns/ITemplatePart";
import filterDeleted from "../../../../utils/filterDeleted";

interface IProps extends IGroupedSpecialOfferParts {
    clientId: number;
    loadedAt: null | Date;
    sampleData: ISampleData;
}

const TextAdPreview: React.FC<IProps> = ({
    headline = [],
    description = [],
    final_url = [],
    path = [],
    sampleData
}) => {
    const pathValues = filterDeleted(path).map((p: ITemplatePart) => p.value);
    const urlValues = filterDeleted(final_url).map((p: ITemplatePart) => p.value);
    const finalUrl = getFirstValidTemplate(urlValues, sampleData);

    const compiledHeadlines = getValidTemplatesForRsa(headline, sampleData, 30);
    const compiledDescriptions = getValidTemplatesForRsa(description, sampleData, 30);

    const previewProps = {
        h1: compiledHeadlines[0] ?? "",
        h2: compiledHeadlines[1] ?? "",
        h3: compiledHeadlines[2] ?? "",
        d1: compiledDescriptions[0] ?? "",
        d2: compiledDescriptions[1] ?? "",
        finalUrl,
        displayUrl: getDisplayUrlFromPathAndFinalUrl(getFirstValidPathTemplate(pathValues, sampleData), finalUrl)
    };

    return (
        <React.Fragment>
            <MobilePreview {...previewProps} />
            <DesktopPreview {...previewProps} />
        </React.Fragment>
    );
};

interface IState {
    inventorySampleData: {
        entities: {
            sampleData: ISampleData;
        };
        loadedAt: Date | null;
    };
    clients: {
        currentClient: number;
    };
}

interface IMappedProps {
    sampleData: ISampleData;
    loadedAt: Date | null;
    clientId: number;
}

const mapStateToProps = (state: IState): IMappedProps => {
    return {
        sampleData: state.inventorySampleData.entities.sampleData,
        loadedAt: state.inventorySampleData.loadedAt,
        clientId: state.clients.currentClient
    };
};

export default connect(mapStateToProps)(TextAdPreview);
