import React from "react";
import {
    IConditional,
    ISpecialOfferTemplatePart,
    ITemplatePart
} from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import { ConditionalOperatorsForEtaPreview } from "./TemplatePart";

interface IProps {
    item: ITemplatePart | ISpecialOfferTemplatePart;
}

const ConditionalsTooltipContent: React.FC<IProps> = ({ item }) => {
    return (
        <>
            <div className="uppercase text-xs font-bold text-gray-600">conditions</div>
            {item.conditionals.map((condition: IConditional, i: number) => {
                const valueToDisplay = condition.value;
                let valuesToDisplay: string[] | null = null;
                if (["IN", "NOTIN", "IN_LIKE", "NOTIN_LIKE"].includes(condition.comparator || "")) {
                    valuesToDisplay = (valueToDisplay || "").split(",").filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
                }

                const key = btoa((condition.field || "0") + valueToDisplay + i);
                return (
                    <div key={key} className={`flex whitespace-no-wrap py-1 items-center`}>
                        <div>{condition.field}</div>
                        <div className="ml-2">/</div>
                        <div className="ml-2">
                            {
                                ConditionalOperatorsForEtaPreview.filter(
                                    (item) => item.value === condition.comparator
                                )[0].label
                            }
                        </div>
                        <div className="ml-2">/</div>
                        {valuesToDisplay !== null && (
                            <div
                                style={{ width: "500px" }}
                                className="ml-1 flex flex-wrap justify-start items-center whitespace-normal"
                            >
                                {valuesToDisplay.map((value) => (
                                    <div key={value + key} className="m-1 px-1 bg-gray-300 text-gray-800 rounded">
                                        {value}
                                    </div>
                                ))}
                            </div>
                        )}
                        {valuesToDisplay === null && (
                            <div className="m-1 px-1 bg-gray-300 text-gray-800 rounded">{valueToDisplay}</div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default ConditionalsTooltipContent;
