import { call, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../middleware/api";
import { IFetchAllEditHistory, IFetchEditHistoryForClient } from "../interfaces/EditHistory/Actions";
import Constants from "../constants/EditHistoryConstants";
import Schemas from "../middleware/schemas/editHistory";
import { fetchAllEditHistorySuccess, fetchEditHistoryForModelSuccess } from "../actions/editHistory";
import buildUrl from "../utils/UrlUtils";
import IPagedResponse from "../interfaces/IPagedResponse";

export function* fetchEditHistoryForClient({ payload: { id, page } }: IFetchEditHistoryForClient) {
    const url = `/clients/${id}/edit-history?page=${page}&limit=100&sorts=created_at,desc`;

    try {
        const resp: IPagedResponse = yield call(callApi, url, Schemas.EDIT_HISTORY_ARRAY);
        const order = resp.result.data;
        const page = resp.result.currentPage;
        const total = resp.result.total;

        yield put(fetchEditHistoryForModelSuccess(resp.entities.editHistories, order, page, total));
    } catch (e) {
        console.log(e);
    }
}

export function* fetchEditHistory({ payload: { options } }: IFetchAllEditHistory) {
    const url = buildUrl(`/edit-history`, {
        ...(options ? options : {})
    });

    try {
        const resp: IPagedResponse = yield call(callApi, url, Schemas.EDIT_HISTORY_ARRAY);

        const order = resp.result.data;
        const page = resp.result.currentPage;
        const total = resp.result.total;
        console.log("result", resp.result);

        yield put(fetchAllEditHistorySuccess(resp.entities.editHistories, order, page, total));
    } catch (e) {
        console.log(e);
    }
}
function* editHistorySagas() {
    yield takeEvery(Constants.FETCH_EDIT_HISTORY_FOR_CLIENT_REQUEST, fetchEditHistoryForClient);
    yield takeEvery(Constants.FETCH_ALL_EDIT_HISTORY_REQUEST, fetchEditHistory);
}

export default editHistorySagas;
