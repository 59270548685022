import React from "react";
import { connect } from "react-redux";
import UsersActions from "../../actions/UsersActions";
import useLoading from "../../hooks/useLoading";
import UsersUtils from "../../utils/UsersUtils";
import FullPageLoader from "../Shared/Loaders/FullPageLoader";
import UserSettingsForm from "./UserSettingsForm";
import ISettingsForm from "../../interfaces/ISettingsForm";
import IUser from "../../interfaces/IUser";
interface IAppState<T> {
    [key: string]: T;
}
interface IProps {
    isSubmitting: boolean;
    error: any;
    loading: boolean;
    currentUser: undefined | IUser;
    formValues: null | ISettingsForm;
    saveSettings: (formValues: ISettingsForm, user: IUser) => void;
}

const UserSettings: React.FunctionComponent<IProps> = ({
    isSubmitting,
    error,
    formValues,
    currentUser,
    saveSettings
}) => {
    const isLoading = useLoading(currentUser, formValues);
    if (isLoading) {
        return <FullPageLoader message="Loading" />;
    }

    return (
        <>
            <UserSettingsForm
                {...{ error, isSubmitting, saveSettings }}
                formValues={formValues!}
                currentUser={currentUser!}
            />
        </>
    );
};

const mapStateToProps = (state: IAppState<any>) => ({
    loading: state.users.loading,
    isSubmitting: state.users.isSubmitting,
    error: state.users.error,
    currentUser: state.currentUser,
    formValues: UsersUtils.mapSettingsToForm(state.currentUser)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveSettings: (values: ISettingsForm, user: IUser) => {
        const settings = UsersUtils.mapFormToSettings(values, user);
        dispatch(UsersActions.requestSaveSettings(settings));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
