import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getCurrentClientObject } from "../../reducers/clients";
import ChevronDownIcon from "../Shared/Icons/ChevronDownIcon";
import RefreshIcon from "../Shared/Icons/RefreshIcon";
import SearchIcon from "../Shared/Icons/SearchIcon";
import useLocalStorage from "../../hooks/useLocalStorage";
import { callApi } from "../../middleware/api";
import { CLIENT_DROPDOWN_SEARCH_URL } from "../../constants/ClientConstants";
import CLIENT_SCHEMAS, { client } from "../../middleware/schemas/client";
import IAppState from "../../interfaces/IAppState";
import IClient from "../../interfaces/IClient";
import { useNavigate } from "react-router-dom";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import useDebounce from "../../hooks/useDebounce";
import { clientSelectorRefreshClicked } from "../../actions/clientActions";

const ClientSelector = ({ currentClient, trackClientRefreshClicked, clientId }: any) => {
    const navigate = useNavigate();
    const [previousClients, setPreviousClients] = useLocalStorage("previously_viewed_clients", []);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);

    const [results, setResults] = useState<IClient[]>([]);
    const [loading, setLoading] = useState(true);

    const debouncedSearch: string = useDebounce(search, 300) as string;

    const doSearch = async (searchValue: string, refresh: boolean = false) => {
        const response = await callApi(
            CLIENT_DROPDOWN_SEARCH_URL(1, searchValue, refresh, 100),
            CLIENT_SCHEMAS.CLIENT_ARRAY
        );

        setResults(
            response.result.data
                .map((id: number) => response.entities.clients[id])
                .map((client: IClient) => ({
                    id: client.id,
                    name: client.name
                }))
        );
    };

    const addToRecentClients = (client: IClient) => {
        try {
            // If the client is already in the list we remove the client, and then re-add them.
            const newList = previousClients.filter((c: IClient) => c.id !== client.id);
            newList.unshift(client);
            if (newList.length > 15) {
                newList.pop();
            }
            setPreviousClients(newList);
        } finally {
            navigate(`/client/${client.id}`);
            setOpen(false);
        }
    };

    useEffect(() => {
        if (!open) {
            setLoading(false);
            return;
        }

        setLoading(true);
        doSearch(debouncedSearch).finally(() => setTimeout(() => setLoading(false), 200));

        return () => setLoading(false);
    }, [debouncedSearch]);

    const clientButton = (client: IClient) => (
        <Combobox.Option
            key={client.id}
            value={client}
            className={({ active }) =>
                ["py-2 px-3 cursor-pointer rounded-sm list-none", active && "bg-blue-200"].join(" ")
            }
        >
            <span>{client.name}</span>
        </Combobox.Option>
    );

    const textInNavbar: () => string = () => {
        if (currentClient?.name) {
            return currentClient.name;
        }

        if (loading) {
            return "Loading...";
        }
        if (!currentClient) {
            return "No Dealership Selected";
        }

        return currentClient.name;
    };

    return (
        <div className="mx-2 tracking-wider">
            <button
                className="relative pr-10 pl-4 z-40 flex hover:bg-blue-800 items-center h-10 rounded text-white cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                }}
            >
                <span className="flex-grow tracking-wider text-sm">{textInNavbar()}</span>
                {!loading && <ChevronDownIcon className="absolute inset-y-auto right-0 mr-2 w-5 h-5" />}
                {loading && (
                    <div className="absolute inset-y-auto right-0 mr-2">
                        <RefreshIcon className="w-5 h-5 rotate-fast" />
                    </div>
                )}
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog className="absolute z-50 inset-0" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={"fixed inset-0"} style={{ backgroundColor: "rgba(0,0,0,0.2)" }} />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 transform scale-95"
                        enterTo="opacity-100 transform scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 transform scale-100"
                        leaveTo="opacity-0 transform scale-95"
                    >
                        <div className="px-4 md:pr-6 absolute top-0 right-0 mt-20 w-full md:w-3/5 2xl:w-1/2 3xl:w-1/3">
                            <Combobox
                                as="div"
                                onChange={(client: any | IClient) => {
                                    addToRecentClients(client);
                                }}
                                value=""
                                className="bg-white text-lg text-sm text-black rounded shadow-lg p-2"
                            >
                                <div className="w-full relative flex items-center">
                                    <SearchIcon className="absolute w-6 h-6 my-1 mx-2" aria-hidden="true" />
                                    <Combobox.Input
                                        className="w-full border-2 border-gray-400 rounded pl-8 pr-2 py-1 leading-snug focus:outline-none"
                                        placeholder="Search..."
                                        onChange={(event) => setSearch(event.target.value)}
                                    />

                                    <button
                                        className="absolute my-1 mx-2 right-0"
                                        onClick={() => {
                                            trackClientRefreshClicked(clientId, client);
                                            setLoading(true);
                                            doSearch(search, true).finally(() => setLoading(false));
                                        }}
                                    >
                                        <RefreshIcon className={`w-6 h-6 ${loading ? "rotate-fast" : ""}`} />
                                    </button>
                                </div>

                                {!search && (
                                    <Combobox.Options
                                        static
                                        as="div"
                                        className="mt-2 clean-scrollbar overflow-y-scroll overflow-x-hidden"
                                        style={{ maxHeight: 400 }}
                                    >
                                        <div className="uppercase font-bold py-2 px-3">Recent Clients</div>
                                        {previousClients.length > 0 && previousClients.map(clientButton)}
                                        {previousClients.length === 0 && (
                                            <div className="italic py-2 px-3">No recent clients</div>
                                        )}
                                    </Combobox.Options>
                                )}

                                {search && (
                                    <Combobox.Options
                                        static
                                        as="div"
                                        className="mt-2 clean-scrollbar overflow-y-scroll overflow-x-hidden"
                                        style={{ maxHeight: 400 }}
                                    >
                                        <div className="uppercase font-bold py-2 px-3">results</div>
                                        {!loading && results.length > 0 && results.map(clientButton)}
                                        {loading && <div className="italic py-2 px-3">Searching...</div>}
                                        {!loading && results.length === 0 && (
                                            <div className="italic py-2 px-3">No results</div>
                                        )}
                                    </Combobox.Options>
                                )}
                            </Combobox>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    trackClientRefreshClicked: (clientId: number, client: IClient) =>
        dispatch(clientSelectorRefreshClicked(clientId, client))
});

const mapStateToProps = (state: IAppState) => ({
    currentClient: getCurrentClientObject(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSelector);
