import { decamelizeKeys } from "humps";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addFlashMessage } from "../actions/flashMessageActions";
import ApiConstants from "../constants/ApiConstants";
import IAppState from "../interfaces/IAppState";
import { INegativeKeywordCollForm } from "../interfaces/NegativeKeywordColl";
import { callApi } from "../middleware/api";
import CLIENT_SCHEMAS from "../middleware/schemas/client";
import { schema } from "normalizr";

type useNegativeKeywordsReturn = {
    toggling: boolean;
    handleNew: (googleCampaignId: string, collectionId: string) => any;
    handleRemove: (field: INegativeKeywordCollForm[], collectionId: string) => any;
};

interface IApiParams {
    [section: string]: {
        url: (collectionId?: number | undefined) => string;
        schema: {
            data: schema.Entity<any>[];
        };
    };
}

function useNegativeKeywords(): useNegativeKeywordsReturn {
    //state
    const [toggling, setToggling] = useState<boolean>(false);
    const clientId = useSelector((state: IAppState) => state.clients.currentClient);
    const dispatch = useDispatch(); //access to Redux dispatcher

    const handleNew = async (googleCampaignId: string, collectionId: string) => {
        const keywordColls: INegativeKeywordCollForm = {
            new: true,
            deleted: false,
            clientId: clientId,
            googleCampaignId: googleCampaignId,
            collectionId: Number(collectionId)
        };
        try {
            setToggling(true);
            return await callApi(
                ApiConstants.NEGATIVE_KEYWORD_COLL_CAMPAIGN_URL(),
                CLIENT_SCHEMAS.NEGATIVE_KEYWORD.NEGATIVE_KEYWORD_COLL_CAMPAIGN_ARRAY,
                "POST",
                decamelizeKeys(keywordColls)
            );
        } catch (error) {
            handleError(error);
            return error;
        } finally {
            setToggling(false);
        }
    };

    const handleRemove = async (field: INegativeKeywordCollForm[], collectionId: string) => {
        const collection = field.find((item) => item.collectionId === Number(collectionId));
        if (collection) {
            try {
                setToggling(true);
                const url = ApiConstants.NEGATIVE_KEYWORD_COLL_CAMPAIGN_URL(Number(collection.id));
                return await callApi(url, {}, "DELETE");
            } catch (error) {
                handleError(error);
                return error;
            } finally {
                setToggling(false);
            }
        }
    };

    const handleError = (error: any) => {
        dispatch(addFlashMessage({ type: "danger", text: error.message }));
    };

    return {
        handleNew,
        handleRemove,
        toggling
    };
}

export default useNegativeKeywords;
