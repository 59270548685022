import React, { Fragment } from "react";
import BudgetConstants from "../../../constants/budgets/BudgetConstants";
import ChevronUpIcon from "../Icons/ChevronUpIcon";
import InformationIcon from "../Icons/InformationIcon";
import EditIcon from "../Icons/EditIcon";
import TableActionButton from "./TableActionButton";
import TableActionLink from "./TableActionLink";
import TrashIcon from "../Icons/TrashIcon";

interface IProps {
    deletedAt: string | null;
    budgetId: number;
    clientId: number;
    serviceId: number | null;
    isReadOnly: boolean;
    pushBudget: (budgetId: number) => void;
    deleteBudget: (budgetId: number) => void;
}

const ActionCell: React.FunctionComponent<IProps> = ({
    deletedAt,
    budgetId,
    pushBudget,
    deleteBudget,
    clientId,
    isReadOnly,
    serviceId
}) => {
    if (!serviceId) {
        return (
            <Fragment>
                <div className="cell-wrapper action-cell text-gray-500">
                    {!isReadOnly && (
                        <Fragment>
                            <TableActionButton onClick={() => pushBudget(budgetId)} Icon={ChevronUpIcon} />
                            <TableActionButton onClick={() => deleteBudget(budgetId)} Icon={TrashIcon} />
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
    const budgetType = serviceId === BudgetConstants.BUDGET_SERVICE_ID_ADWORDS ? "adwords" : "microsoft";
    const basePath = `/client/${clientId}/budgets/${budgetType}/${budgetId}`;
    return (
        <Fragment>
            {!deletedAt && (
                <div className="cell-wrapper action-cell text-gray-500">
                    <TableActionLink to={`${basePath}/details`} Icon={InformationIcon} />

                    {!isReadOnly && (
                        <Fragment>
                            <TableActionLink to={`${basePath}/edit`} Icon={EditIcon} />

                            <TableActionButton onClick={() => pushBudget(budgetId)} Icon={ChevronUpIcon} />

                            <TableActionButton
                                onClick={() => deleteBudget(budgetId)}
                                Icon={TrashIcon}
                                className="hover:text-red-500"
                            />
                        </Fragment>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default ActionCell;
