import React from "react";

interface IAmountFieldProps {
    value: string;
    field: string;
    updateValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const AmountField: React.FunctionComponent<IAmountFieldProps> = ({ value, field, onBlur, updateValue }) => {
    if (field === "%") {
        return (
            <React.Fragment>
                <input
                    autoFocus
                    type="text"
                    className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-l-lg border border-gray-500 px-3 relative outline-none"
                    value={value}
                    onChange={updateValue}
                    onBlur={onBlur}
                />
                <div className="flex -mr-px">
                    <span className="text-base flex items-center leading-normal bg-gray-200 rounded rounded-l-none border border-l-0border-gray-500 px-3 whitespace-no-wrap text-gray-600">
                        %
                    </span>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="flex -mr-px">
                    <span className="text-base flex items-center leading-normal bg-gray-200 rounded rounded-r-none border border-r-0border-gray-500 px-5 whitespace-no-wrap text-gray-600">
                        $
                    </span>
                </div>
                <input
                    autoFocus
                    type="text"
                    className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-lg border border-gray-500 px-3 relative outline-none"
                    value={value}
                    onChange={updateValue}
                    onBlur={onBlur}
                />
            </React.Fragment>
        );
    }
};

export default AmountField;
