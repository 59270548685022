import React, { SVGProps } from "react";
import Svg from "./Svg";

const InventoryModifiersIcon = (props: SVGProps<SVGSVGElement>) => (
    <Svg {...props}>
        <path d="M13.457 22.916c-.22 0-.434-.06-.625-.171-.19.112-.405.17-.625.17h-3.75c-.689 0-1.25-.56-1.25-1.25v-3.75c0-.688.561-1.25 1.25-1.25h1.25v-3.75c0-.688.561-1.25 1.25-1.25h3.75c.69 0 1.25.562 1.25 1.25v3.75h1.25c.69 0 1.25.562 1.25 1.25v3.75c0 .69-.56 1.25-1.25 1.25h-3.75zm0-1.25h3.75v-3.75h-3.75v3.75zm-5 0h3.75v-3.75h-3.75v3.75zm2.5-5h3.75v-3.75h-3.75v3.75z" />
        <path d="M22.207 22.916a.625.625 0 0 1-.625-.625v-13a.624.624 0 0 0-.33-.552l-8.125-4.333a.629.629 0 0 0-.589 0L4.413 8.739a.625.625 0 0 0-.33.552v13a.625.625 0 0 1-1.25 0v-13c0-.694.38-1.328.992-1.655l8.125-4.334a1.885 1.885 0 0 1 1.765 0l8.125 4.334c.613.326.992.96.992 1.655v13c0 .345-.28.625-.625.625z" />
    </Svg>
);

export default InventoryModifiersIcon;
