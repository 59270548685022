import React, { useEffect, useState } from "react";
import Button from "../../Shared/Button";
import AddIcon from "../../Shared/Icons/AddIcon";
import IAppState from "../../../interfaces/IAppState";
import AsyncTable from "../../Shared/Table/AsyncTable";
import { IDynamicCampaign } from "../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import {
    ISpecialOfferMetaTemplate,
    ISpecialOfferTemplate
} from "../../../interfaces/SpecialOfferTemplates/ISpecialOfferTemplate";
import moment from "moment";
import LinkedCampaignsCell from "../../Shared/Table/LinkedCampaignsCell";
import SimpleActionCell from "../../Shared/SimpleActionCell";
import { connect, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import * as actions from "../../../actions/specialOfferTemplateActions";
import { getSpecialOfferTemplatesInOrder } from "../../../reducers/specialOfferTemplateReducer";
import { getSpecialOffersMetaTemplatesInOrder } from "../../../reducers/specialOffersMetaTemplateReducer";
import SearchInput from "../../Shared/SearchInput";
import SearchIcon from "../../Shared/Icons/SearchIcon";
import { sortBy } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

interface IReduxStateProps {
    specialOfferMetaTemplates: ISpecialOfferMetaTemplate[];
    specialOfferTemplates: ISpecialOfferTemplate[];
    loading: boolean;
    loadedMetaTemplates?: Date;
    loadedTemplates?: Date;
}
interface IReduxDispatchProps {
    fetchSpecialOfferMetaTemplates: (limit: number, page: number) => void;
    deleteSpecialOfferTemplate: (template: ISpecialOfferTemplate) => void;
}

const Index: React.FunctionComponent<IReduxStateProps & IReduxDispatchProps> = ({
    specialOfferMetaTemplates,
    specialOfferTemplates,
    loading,
    deleteSpecialOfferTemplate,
    loadedMetaTemplates,
    loadedTemplates
}) => {
    const dispatch = useDispatch();
    const { id: clientId } = useParams();
    const fetchSpecialOfferTemplates = (limit: number, page: number): void => {
        !!clientId && dispatch(actions.fetchAllSpecialOfferTemplates(Number(clientId), page, limit));
    };
    const columns = [
        {
            Cell: ({ original }: { original: ISpecialOfferTemplate }) => (
                <SimpleActionCell
                    onCancel={(original: ISpecialOfferTemplate) => {
                        return;
                    }}
                    onDelete={(original: ISpecialOfferTemplate) => {
                        deleteSpecialOfferTemplate(original);
                    }}
                    message={"Are you sure you want to delete this special offer template?"}
                    original={original}
                />
            ),
            accessor: "",
            Header: "Actions",
            id: "ActionColumn",
            filterable: false,
            sortable: false,
            maxWidth: 145
        },
        {
            id: "name",
            accessor: "name",
            Header: "Name",
            filterable: false
        },
        {
            id: "campaignNames",
            accessor: "campaignNames",
            filterable: false,
            sortable: false,
            Header: "Linked Campaigns",
            headerClassName: "-ml-4",
            Cell: ({ original: specialOfferTemplate }: { original: any }) => {
                const count = specialOfferTemplate?.dynamicCampaigns?.length || 0;
                const label = count + " Linked Campaign" + (count !== 1 ? "s" : "");
                return count > 0 ? (
                    <LinkedCampaignsCell
                        label={label}
                        tooltipText={specialOfferTemplate?.dynamicCampaigns?.map((campaign: IDynamicCampaign) => (
                            <div key={campaign.name + campaign.id} className="leading-loose">
                                {campaign.name}
                            </div>
                        ))}
                    />
                ) : (
                    label
                );
            }
        },
        {
            id: "type",
            accessor: "metaTemplate.name",
            Header: "Type",
            filterable: false,
            headerClassName: "-ml-4"
        },
        {
            id: "expirationDate",
            accessor: "expiresAt",
            Header: "Expiration Date",
            filterable: false,
            maxWidth: 175,
            Cell({ original }: { original: ISpecialOfferTemplate }) {
                const expires = moment(original.expiresAt);
                let color = "bg-yellow-500";
                if (expires.isBefore(moment())) {
                    color = "bg-red-500";
                } else if (expires.isAfter(moment())) {
                    color = "bg-green-500";
                }

                return (
                    <div className="flex flex-wrap items-center ml-2">
                        <span className={`w-2 h-2 ${color} rounded-full`} />
                        <span className="ml-2">{moment(original.expiresAt).format("MM/DD/YYYY")}</span>
                    </div>
                );
            }
        }
    ];
    const [createButtonOpen, setCreateOpen] = useState(false);
    const searchQueryParamName = "meta-template-name";
    const params = new URLSearchParams(useLocation().search);
    const searchValue = params.get(searchQueryParamName);

    const [search, setSearch] = useState(searchValue ?? "");
    const metaTemplateSearchFilter = (metaTemplate: ISpecialOfferMetaTemplate) =>
        metaTemplate.name.toLowerCase().includes(String(search).toLowerCase());
    const [filteredMetaCampaigns, setFilteredMetaCampaigns] = useState(
        sortBy(specialOfferMetaTemplates.filter(metaTemplateSearchFilter), ["name"])
    );

    useEffect(() => {
        setFilteredMetaCampaigns(sortBy(specialOfferMetaTemplates.filter(metaTemplateSearchFilter), ["name"]));
    }, [search, specialOfferMetaTemplates]);

    return (
        <div>
            <div className="flex items-center justify-between my-4">
                <div className="text-4xl font-bold">All Special Offers</div>
                <div className="flex items-center">
                    <div className="group relative">
                        <Button
                            onClick={() => {
                                setCreateOpen(!createButtonOpen);
                            }}
                            disabled={false}
                            styleType={"primary"}
                            styles="flex items-center"
                        >
                            <AddIcon className="w-4 h-4" />
                            <span className="ml-2">Create Template</span>
                        </Button>

                        {createButtonOpen && (
                            <div
                                className="absolute whitespace-no-wrap z-20 top-0 right-0 mt-12 bg-white rounded border border-gray-400 py-2 px-2 flex flex-col"
                                style={{ minWidth: "435px", maxHeight: "600px", overflowY: "scroll" }}
                            >
                                <div className="w-full flex flex-col">
                                    <SearchInput
                                        url={window.location.pathname}
                                        onChange={(value) => setSearch(value)}
                                        queryParamName={searchQueryParamName}
                                        useSearchHistory={true}
                                        debounceTimeout={100}
                                        InputComponent={(props: any) => (
                                            <div className="w-full relative flex items-center">
                                                <SearchIcon className="absolute w-5 h-5 my-1 mx-2" />
                                                <input
                                                    className="w-full border-2 border-gray-400 rounded pl-6 ml-1 pr-2 py-1 leading-snug focus:outline-none"
                                                    autoFocus={true}
                                                    {...props}
                                                />
                                            </div>
                                        )}
                                    ></SearchInput>
                                </div>

                                {filteredMetaCampaigns?.map((type) => (
                                    <Link
                                        className={"leading-loose ml-2 pl-4"}
                                        to={"create?meta=" + type.id + ""}
                                        key={type.id}
                                    >
                                        <span className={"ml-2"}>{type.name}</span>
                                    </Link>
                                ))}

                                {!filteredMetaCampaigns?.length && (
                                    <div className="my-2">
                                        {search && <div>No search results</div>}
                                        {!search && <div>No meta templates</div>}
                                    </div>
                                )}
                            </div>
                        )}
                        {createButtonOpen && (
                            <button
                                onClick={() => setCreateOpen(false)}
                                className={"fixed h-full w-full z-10 top-0 left-0 right-0 bottom 0"}
                                style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div>
                <AsyncTable
                    columns={columns}
                    loadingMessage={"Loading special offer templates..."}
                    noDataMessage={"No special offer templates"}
                    loadingTable={loading && (!loadedTemplates || !loadedMetaTemplates)}
                    fetchData={fetchSpecialOfferTemplates}
                    data={specialOfferTemplates}
                />
            </div>
        </div>
    );
};

// We should use a state selector to pull in the data.
const mapStateToProps = (state: IAppState): IReduxStateProps => ({
    specialOfferMetaTemplates: getSpecialOffersMetaTemplatesInOrder(state.specialOffersMetaTemplates) || [],
    specialOfferTemplates: getSpecialOfferTemplatesInOrder(state.specialOfferTemplates),
    loading: state.specialOfferTemplates.loading || state.specialOffersMetaTemplates.loading,
    loadedMetaTemplates: state.specialOffersMetaTemplates.loadedAt,
    loadedTemplates: state.specialOfferTemplates.loadedAt
});

const mapDispatchToProps = (dispatch: Dispatch, extraProps: any): IReduxDispatchProps => ({
    fetchSpecialOfferMetaTemplates(limit: number, page: number) {
        dispatch(actions.fetchAllSpecialOffersMetaTemplates(page, limit));
    },
    deleteSpecialOfferTemplate(specialOfferTemplate: ISpecialOfferTemplate) {
        dispatch(actions.deleteSpecialOfferTemplate(specialOfferTemplate.clientId, specialOfferTemplate.id));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
