import { connect } from "react-redux";
import CampaignList from "../CampaignList";
import { fetchMicrosoftCampaigns } from "../../../../actions/campaignActions";
import { getAllMicrosoftOrderedByStatusAndName } from "../../../../reducers/campaigns";
import { isUserInternal } from "../../../../reducers/currentUser";

const mapStateToProps = (state: any, props: any) => {
    return {
        campaigns: getAllMicrosoftOrderedByStatusAndName(state),
        loading: state.microsoftCampaigns.loading,
        clientId: state.clients.currentClient,
        isInternalUser: isUserInternal(state.currentUser)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchCampaigns: (clientId: number, refresh = false) => {
            dispatch(fetchMicrosoftCampaigns(clientId, refresh));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
