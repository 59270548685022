import React from "react";
import IClient from "../../../../interfaces/IClient";
import TextField from "../../../Shared/Form/Blocks/TextField";

interface IProps {
    client: IClient;
    isInternalUser: boolean;
    onValueChange(event: React.ChangeEvent<HTMLInputElement> | IMockEvent): void;
}

interface IMockEvent {
    target: { type: string; value: string; name: string };
}

const DV360: React.FunctionComponent<IProps> = ({ client, onValueChange, isInternalUser }) => {
    const errors = client.errors;

    return (
        <div className="flex">
            <div className="w-full md:w-3/4 lg:w-1/2">
                {isInternalUser === true && (
                    <TextField
                        value={client.dv360AdvertiserId || ""}
                        handleChange={onValueChange}
                        name="dv360AdvertiserId"
                        label="DV360 Advertiser Id"
                        errors={errors.dv360AdvertiserId || []}
                        sublabel="This is the DV360 Advertiser ID value."
                    />
                )}
            </div>
        </div>
    );
};

export default DV360;
